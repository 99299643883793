import request from '@/utils/request';
import newRequest from '@/utils/new-request';
import qs from 'qs';

// const baseFinanceURL = process.env.VUE_APP_FINANCE; // 财务系统接口地址

// 客户收款单分页
export const apiCustomerReceiptPage = data => {
  return request.post('/fa/customerReceipt/page', data);
};

// 收款详情
export const apiCustomerReceiptDetailsPage = data => {
  return request.post('/fa/customerReceiptDetails/page', data);
};

// -------------------- 应收-客户账户相关 start -------------------

// 获取客户账户列表
export const apiGetCustomerPage = data => {
  return request({
    url: '/customer/page',
    method: 'post',
    data
  });
};

// 修改账期
export const apiUpdatePaymentCycle = data => {
  return request.post('/customer/updatePaymentCycle', qs.stringify(data));
};

// ------------------- 应收-网点账户相关 start -------------------

// 获取网点账户列表
export const apiGetSiteAccountPage = data => {
  return newRequest({
    url: '/siteAccount/page',
    method: 'post',
    data
  });
};

// 设置网点账期
export const apiSiteAccountSetCycle = data => {
  return newRequest({
    method: 'post',
    url: '/siteAccount/setCycle',
    data
  });
};

// 导出网点账户信息
export const apiSiteAccountExportSiteAccountInfo = data => {
  return newRequest({
    method: 'post',
    url: '/siteAccount/exportSiteAccountInfo',
    data
  });
};

// 导出网点账户流水
export const apiSiteAccountExportExportOfBillFlow = data => {
  return newRequest({
    method: 'post',
    url: '/SiteReceipt/exportOfBillFlow',
    data
  });
};

// ------------------- 应收-网点账户相关 end -------------------
