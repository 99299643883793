<template>
  <div class="app-container">
    <div ref="searchForm" style="margin-bottom: 5px;">
      <el-card class="box-card">
        <el-form
          ref="form" :model="queryForm" label-width="auto" size="small"
          label-position="top"
        >
          <div class="searchContainer">
            <el-row :gutter="24">
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--账单时间-->
                  <el-form-item :label="$t('receivablePayable.BillingDate')">
                    <el-date-picker
                      v-model="queryForm.params.payableTimes"
                      style="width: 100%"
                      type="daterange"
                      :range-separator="$t('operationCenter.to')"
                      :start-placeholder="$t('operationCenter.startDate')"
                      :end-placeholder="$t('operationCenter.endDate')"
                      value-format="yyyy-MM-dd"
                    />
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--对账状态-->
                  <el-form-item :label="$t('receivablePayable.ReconciliationStatus')">
                    <el-select
                      v-model="queryForm.params.auditStatus"
                      filterable
                      multiple
                      :placeholder="$t('operationCenter.PleaseSelect')"
                      clearable
                      style="width: 100%;"
                    >
                      <!--未审核-->
                      <el-option key="1" :label="$t('customerManagements.NotAudit')" :value="0" />
                      <!--已通过-->
                      <el-option key="2" :label="$t('receivablePayable.Passed')" :value="1" />
                      <!--未通过-->
                      <el-option key="3" :label="$t('receivablePayable.DoesNotPass')" :value="2" />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--应付主体-->
                  <el-form-item :label="$t('basicData.PayablePrincipal')">
                    <el-select
                      v-model="queryForm.params.providerId"
                      filterable
                      :placeholder="$t('operationCenter.PleaseSelect')"
                      clearable
                      style="width: 100%;"
                      @change="selectedProvider"
                    >
                      <el-option
                        v-for="item in providerList"
                        :key="item.id"
                        :label="item.providerBody + '（' + item.providerName + '）(' + item.code + ')'"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--网点-->
                  <el-form-item :label="$t('basicData.Outlets')">
                    <QuerySiteCmp
                      ref="querySiteCmp"
                      :val="queryForm.params.siteIds"
                      :provider-id="queryForm.params.providerId"
                      :clearable="true"
                      :multiple="true"
                      :multiple-limit="10"
                      @change="val => queryForm.params.siteIds = val"
                    />
                    <!-- <el-select
                      v-model="queryForm.params.siteId"
                      :placeholder="$t('operationCenter.PleaseSelect')"
                      style="width: 100%;"
                      clearable
                      filterable
                    >
                      请先选择应收主体
                      <el-option
                        v-for="item in outletsList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select> -->
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--业务类型-->
                  <el-form-item :label="$t('collectionCenter.businessType')">
                    <el-select
                      v-model="queryForm.params.businessType"
                      filterable
                      :placeholder="$t('operationCenter.PleaseSelect')"
                      clearable
                      style="width: 100%;"
                    >
                      <!--全部-->
                      <el-option key="0" :label="$t('basicData.all')" :value="null" />
                      <el-option
                        v-for="item in BUSINESS_TYPE"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--账单类型-->
                  <el-form-item :label="$t('collectionCenter.TypeBill')">
                    <el-select
                      v-model="queryForm.params.payableTypes" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%"
                      clearable
                    >
                      <el-option
                        v-for="item in PAYABLE_TYPE" :key="item.value" :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--是否请款-->
                  <el-form-item :label="$t('receivablePayable.areYouRequestingPayment')">
                    <el-select
                      v-model="queryForm.params.requestPaymentStatus " :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%"
                      clearable
                    >
                      <!--全部-->
                      <el-option key="1" :label="$t('GlobalSetObj.all')" value="" />
                      <!--是-->
                      <el-option key="2" :label="$t('GlobalSetObj.yes')" :value="1" />
                      <!--否-->
                      <el-option key="3" :label="$t('GlobalSetObj.No')" :value="0" />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--账单批次号-->
                  <el-form-item :label="$t('receivablePayable.BillingBatchNumber')">
                    <InputTextareaCmp
                      ref="inputTextareaRef" :val="queryForm.params.billBatchNumber" :max-length="200"
                      @input="(_, val) => queryForm.params.billBatchNumber = val"
                    />
                    <!-- <el-input
                      v-model="queryForm.params.billBatchNumber" type="textarea" :autosize="{ minRows: 2, maxRows: 4}"
                      :placeholder="$t('operationCenter.Multiple200')"
                    /> -->
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="searchResources()"
        >
          {{ $t('operationCenter.Procurar') }}
        </el-button>
      </el-card>
    </div>

    <el-card class="box-card">
      <div style="margin-bottom: 10px;">
        <div class="btn-group">
          <div class="btns">
            <!--审核-->
            <el-button
              v-permit:remove="'btn:finance:accountsPayableReview:examine'"
              size="small"
              type="primary"
              @click="operationBtn(2)"
            >{{ $t('receivablePayable.examine') }}</el-button>
            <!--标记请款-->
            <el-button
              v-permit:remove="'btn:finance:accountsPayableReview:examine'"
              size="small"
              type="primary"
              @click="operationBtn(1)"
            >{{ $t('receivablePayable.markPaymentRequest') }}</el-button>
            <!--取消请款-->
            <el-button
              v-permit:remove="'btn:finance:accountsPayableReview:examine'"
              size="small"
              type="primary"
              @click="operationBtn(0)"
            >{{ $t('receivablePayable.cancelPaymentRequest') }}</el-button>
            <!--自定义栏目-->
            <el-button
              style="margin-left: 10px;"
              size="small"
              @click="customColumn()"
            >{{ $t('orderCenterCont.CustomizeListColumn') }}</el-button>

            <TableStatisticiansCmp :table-data="tableData" />
          </div>

          <!-- 导出 -->
          <el-button
            v-permit:remove="'btn:finance:accountsPayableReview:export'" size="small" type="info"
            @click="onExport"
          >{{ $t('CenterForSorting.export') }}<i class="el-icon-download el-icon--right" /></el-button>
        </div>

      </div>

      <div class="pending">
        <el-table
          ref="elTable"
          :key="tableKey"
          :data="tableData"
          class="table-height"
          :height="tableHeightRow.height"
          border
          :row-class-name="rowClass"
          @header-dragend="headerWidthChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          />
          <el-table-column :label="$t('operationCenter.ind')" width="60px" align="center">
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, ind) in currentColumns"
            :key="ind"
            :prop="item.prop"
            :label="item.label"
            :align="item.align"
            :min-width="item.width"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <!--账单批次号-->
              <div v-if="item.prop === 'billBatchNumber'">
                <div class="batchNumber" @click="showBillBatchNumber(scope.row)">{{ scope.row.billBatchNumber }}</div>
              </div>

              <!-- 账单类型 -->
              <div v-else-if="item.prop === 'payableType'">
                {{ PAYABLE_TYPE[scope.row.payableType] && PAYABLE_TYPE[scope.row.payableType].name || ''}}
              </div>

              <!-- 账单状态 -->
              <div v-else-if="item.prop === 'billStatusValue'">
                <!--未审核-->
                <span v-if="scope.row.billStatusValue === 0">{{ $t('customerManagements.NotAudit') }}</span>
                <!--已通过-->
                <span v-else-if="scope.row.billStatusValue === 1">{{ $t('receivablePayable.Passed') }}</span>
                <!--未通过-->
                <span v-else-if="scope.row.billStatusValue === 2">{{ $t('receivablePayable.DoesNotPass') }}</span>
              </div>
              <!--是否请款-->
              <div v-else-if="item.prop === 'requestPaymentStatus'">
                <el-tag :type="scope.row.requestPaymentStatus === 1 ? 'success' : 'danger'">{{ scope.row.requestPaymentStatus === 1 ? $t('GlobalSetObj.yes') : $t('GlobalSetObj.No') }}</el-tag>
              </div>
              <!-- 业务类型 -->
              <div v-else-if="item.prop === 'businessType'">
                {{ BUSINESS_TYPE[scope.row.businessType] && BUSINESS_TYPE[scope.row.businessType].label || '' }}
              </div>
              <div v-else>{{ scope.row[item.prop] }}</div>
            </template>
          </el-table-column>
          <!--操作-->
          <el-table-column
            :label="$t('basicData.operate')"
            align="center"
            width="140"
            fixed="right"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div
                v-if="((scope.row.auditStatus === 0 || scope.row.auditStatus === 2) && $store.state.user.id === scope.row.createUser) || $store.getters.button_permissions.includes('btn:receivableManagementSite:toExamine:recall')"
              >
                <el-button
                  type="danger"
                  size="mini"
                  @click="recall(scope.row)"
                >{{ $t('receivablePayable.withdraw') }}</el-button><!--撤回-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页工具 -->
        <div class="pagination">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getCurrentPage"
            @size-change="getSizePage"
          />
        </div>
      </div>
    </el-card>

    <!--选择栏目-->
    <el-drawer
      :append-to-body="true"
      :visible.sync="showColumn"
      direction="ltr"
      custom-class="drawerClass"
      :title="$t('orderCenterCont.SelectColumn')"
      width="500px"
      @close="closeDrawer()"
    >
      <div style="padding: 20px;height: calc(100vh - 140px);overflow: auto" class="columnTree">
        <el-tree
          ref="elTree"
          :key="dragging"
          :data="columnList"
          :props="props"
          node-key="prop"
          default-expand-all
          check-on-click-node
          :default-checked-keys="checkedColumns"
          show-checkbox
          draggable
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
          @check="handleCheckChange"
          @node-drop="handleDrop"
        />
      </div>
      <div style="padding: 10px 20px;text-align: right;border-top: 1px solid #ccc;">
        <el-button type="primary" @click="determineColumns()">{{ $t('operationCenter.determine') }}</el-button>
      </div>
    </el-drawer>

    <!--  对账批次详情  -->
    <el-drawer
      :title="$t('receivablePayable.ReconcileBatchDetails') + '-' + batchNumber"
      :append-to-body="true"
      :visible.sync="showDetail"
      direction="ltr"
      custom-class="drawerClass"
      :label="$t('receivablePayable.ReconcileBatchDetails')"
      size="80%"
    >
      <batch-number-detail
        :key="batchNumberKey"
        :batch-number="batchNumber"
        :bill-batch-data="billBatchData"
        @closeDetail="closeDetail"
      />
    </el-drawer>

    <!--审核-->
    <el-dialog
      :title="$t('receivablePayable.examine')"
      :visible.sync="showExamine"
      width="600px"
    >
      <div>
        <div>
          <el-form
            :key="showExamine" ref="examineForm" :model="examineForm"
            label-width="auto"
          >
            <!--审核-->
            <el-form-item
              :label="$t('receivablePayable.examine')"
              prop="examine"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-radio-group v-model="examineForm.examine">
                <div style="margin-bottom: 5px;">
                  <!--通过（多条数据合并生成一个收款单）-->
                  <el-radio :label="0">{{ $t('receivablePayable.PassMultipleDataMergingToGenerateOneReceipt') }}</el-radio>
                </div>
                <div style="margin-bottom: 5px;">
                  <!--通过（多条数据生成多个收款单）-->
                  <el-radio :label="1">{{ $t('receivablePayable.GenerateMultipleReceiptsThrough') }}</el-radio>
                </div>
                <div>
                  <!--不通过-->
                  <el-radio :label="2">{{ $t('receivablePayable.DoesNotPass') }}</el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              :label="$t('receivablePayable.remark')"
              prop="remark"
              :rules="{ required: examineForm.examine === 2 ? true : false, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-input v-model="examineForm.remark" type="textarea" :rows="6" />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="examine(1)">{{ $t('basicData.Cancel') }}</el-button>
        <el-button type="primary" @click="examine(2)">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import batchNumberDetail from './cmp/batchNumberDetail';
import TableStatisticiansCmp from './cmp/TableStatisticiansCmp.vue';
import {
  apiPayableSiteBillGetPage,
  apiPayableSiteBillAudit,
  apiPayableSiteBillWithdraw,
  apiCustomColumnTemplateList,
  apiCustomColumnTemplateUpdate,
  apiCustomColumnTemplateInsert,
  apiPayableSiteBillExport,
  apiPayableSiteBillRequestPaymentOperation
} from '@/api/finance/receivables';
import { apiProviderPage } from '@/api/finance/basicData';
import { cloneDeep } from 'lodash-es';
import { goResourceTaskList } from '@/utils/goExportList.js';
import { PAYABLE_TYPE } from '@/views/finance/payableManage/feesPayable/utils.js';
import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp.vue';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { BUSINESS_TYPE } from '@/views/finance/enums.js';
import { TABLE_HEADER } from './utils';
import InputTextareaCmp from '@/views/finance/cmp/Query/InputTextareaCmp.vue';

export default {
  name: 'AccountsPayableReview',

  components: {
    batchNumberDetail,
    TableStatisticiansCmp,
    QuerySiteCmp,
    InputTextareaCmp
  },
  mixins: [estimateTableHeight],
  data() {
    return {
      PAYABLE_TYPE, // 账单类型
      BUSINESS_TYPE, // 业务类型
      queryForm: {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10,
        params: {
          billBatchNumber: '', // 对账单批次号
          auditStatus: [0, 2], // 对账状态
          siteIds: [],
          providerId: '', // 供应商应收主体
          businessType: null, // 业务类型
          payableTypes: '', // 账单类型
          payableTimes: [], // 账单时间
          requestPaymentStatus: '', // 是否请款
        }
      },
      createUser: [],
      showDetail: false,
      tableData: [],
      total: 0,
      height: 100,
      tableKey: new Date().getTime(),
      batchNumberKey: new Date().getTime(),
      batchNumber: '',
      billBatchData: {},
      providerList: [],
      selectData: [],
      rowSelectFlag: false,
      showExamine: false,
      examineForm: {
        examine: 2,
        remark: ''
      },
      currentColumns: [],
      showColumn: false,
      dragging: new Date().getTime(),
      props: {
        label: 'label',
        children: 'children'
      },
      checkedColumns: [],
      checkedSelectColumns: [],
      customColumnId: ''
    };
  },
  computed: {
    columnList() {
      return [
        {
          label: this.$i18n.t('basicData.all'), // '全部',
          prop: '',
          children: [
            ...TABLE_HEADER
          ]
        }
      ];
    }
  },
  created() {
    this.createUser = getUserIdAndNameObj();
    this.getHeaderColumn();
    // 获取应付主体
    this.getProviderPage();
    // 获取账单审核列表
    this.getExaminePage();
  },
  mounted() {
    this.bus.$on('pendingData', () => {
      this.getExaminePage();
    });
  },
  methods: {
    // 找出两个数组中相同的元素
    findCommonElements(arr1, arr2) {
      return arr1.filter((element) => arr2.includes(element));
    },
    // 找出两个数组中不相同的元素
    findDifferentElements(arr1, arr2) {
      // 找出arr1中不存在于arr2的元素
      const uniqueToArr1 = arr1.filter(element => !arr2.includes(element));
      // 找出arr2中不存在于arr1的元素
      const uniqueToArr2 = arr2.filter(element => !arr1.includes(element));
      // 将两个数组中的不同元素合并
      return [...uniqueToArr1, ...uniqueToArr2];
    },
    // 获取当前用户自定义列表表头
    getHeaderColumn() {
      const param = {
        type: 0,
        menuEnum: 'copeAudits'
      };
      apiCustomColumnTemplateList(param).then(res => {
        if (res.code === 200) {
          this.customColumnId = '';
          let selectedColumn = [];
          if (res.data.length > 0) {
            this.customColumnId = res.data[0].id;
            selectedColumn = JSON.parse(res.data[0].templateField);
          }
          this.currentColumns = [];
          let allColumn = [];
          const selectedProp = [];
          const allProp = [];
          selectedColumn.map(list => {
            if (selectedProp.includes(list.prop) === false) {
              selectedProp.push(list.prop);
            }
          });
          this.columnList[0].children.map(item => {
            if (allProp.includes(item.prop) === false) {
              allProp.push(item.prop);
            }
          });
          const commonElements = this.findCommonElements(selectedProp, allProp);
          if (commonElements.length > 0) {
            commonElements.map(list => {
              this.columnList[0].children.map(item => {
                if (list === item.prop) {
                  this.currentColumns.push(item);
                }
              });
            });
          } else {
            this.columnList[0].children.map(item => {
              if (selectedProp.includes(item.prop) === false) {
                selectedProp.push(item.prop);
              }
              this.currentColumns.push(item);
              // if (item.disabled) {
              //   if (selectedProp.includes(item.prop) === false) {
              //     selectedProp.push(item.prop);
              //   }
              //   this.currentColumns.push(item);
              // }
            });
          }
          allColumn = cloneDeep(this.currentColumns);
          const differentElements = this.findDifferentElements(allProp, selectedProp);
          this.columnList[0].children.map(item => {
            if (differentElements.includes(item.prop)) {
              allColumn.push(item);
            }
          });
          // 页面加载的时候设置table的高度
          this.$nextTick(() => {
            this.columnList[0].children = cloneDeep(allColumn);
            this.tableKey = new Date().getTime();
          });
        }
      });
    },
    // 打开自定义栏目弹窗
    customColumn() {
      const selectedColumn = cloneDeep(this.currentColumns);
      this.checkedColumns = [];
      this.checkedSelectColumns = cloneDeep(this.columnList[0].children);
      this.checkedSelectColumns.map(item => {
        if (selectedColumn.length > 0) {
          selectedColumn.map(list => {
            if (item.prop === list.prop) {
              this.checkedColumns.push(item.prop);
            }
          });
        } else {
          this.checkedColumns.push(item.prop);
          // if (item.disabled === true) {
          //   this.checkedColumns.push(item.prop);
          // }
        }
      });
      this.showColumn = true;
      this.dragging = new Date().getTime();
    },
    // 确定保存已选栏目
    determineColumns() {
      const columns = [];
      const neKey = this.$refs.elTree.getCheckedKeys();
      if (neKey.length > 0) {
        this.columnList[0].children.map(list => {
          if (neKey.includes(list.prop)) {
            columns.push(list);
          }
        });
      }
      const params = {
        templateField: JSON.stringify(columns),
        templateName: '应付账单审核',
        type: 0,
        menuEnum: 'copeAudits',
        userId: this.$store.state.user.id
      };
      if (this.customColumnId) {
        params.id = this.customColumnId;
        apiCustomColumnTemplateUpdate(params).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$t('basicData.TheOperationSucceeded'));
            this.getHeaderColumn();
          }
        });
      } else {
        apiCustomColumnTemplateInsert(params).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$t('basicData.TheOperationSucceeded'));
            this.getHeaderColumn();
          }
        });
      }
      this.showColumn = false;
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
        this.tableData.map(list => {
          const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
          this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
        });
      });
    },
    // 关闭自定义栏目弹窗事件将未保存的数据还原
    closeDrawer() {
      this.columnList[0].children = this.checkedSelectColumns;
    },
    // 判断节点是否能被拖拽
    allowDrag(node) {
      return node.level === 2;
    },
    // 判断节点是否可放置
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.level === 2 && type !== 'inner') {
        return true;
      } else {
        return false;
      }
    },
    // 拖拽成功后触发事件
    handleDrop(draggedNode, insertNode, dropLocation, event) {
      this.dragging = new Date().getTime();
    },
    // 栏目勾选
    handleCheckChange(code, node) {
      this.checkedColumns = node.checkedKeys;
    },
    // 翻页
    getCurrentPage(val) {
      this.queryForm.pageNumber = val;
      this.getExaminePage();
    },
    // 每页显示数量变化
    getSizePage(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getExaminePage();
    },
    // 应付主体选项变化
    selectedProvider() {
      // this.getSitePageList();
      this.queryForm.params.siteIds = [];
      // 重置
      // this.$refs.querySiteCmp.reset();
    },

    // 撤回
    recall(row) {
      this.$confirm(this.$i18n.t('receivablePayable.AreYouSureYouWantToWithdraw'), this.$i18n.t('basicData.tips'), { // '确定要撤回?'
        confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
        cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        apiPayableSiteBillWithdraw(row.id).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded'));
            this.getExaminePage();
          }
        });
      });
    },
    // 表头宽度变化
    headerWidthChange() {
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    // 列表高度计算
    tableHeight(name) {
      const searchFormHeight = this.$refs[name].clientHeight; // 可变的查询高度
      const pageHeight = document.documentElement.clientHeight; // 可用窗口的高度
      const tableHeight = Number(pageHeight - searchFormHeight - 190); // 计算完之后剩余table可用的高度
      this.tableKey = new Date().getTime();
      this.$nextTick(() => {
        this.rowSelectFlag = true;
        this.tableData.map(list => {
          const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
          this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
        });
        this.rowSelectFlag = false;
      });
      return tableHeight;
    },
    // 关闭详情
    closeDetail() {
      this.showDetail = false;
    },
    // 给反审行添加背景色
    rowClass({ row, column, rowIndex, columnIndex }) {
      const rowBackground = {};
      if (row.isAuditRollback) {
        rowBackground.background = '#f00';
      }
      return rowBackground;
    },
    // 获取业务主体
    getProviderPage() {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          state: 1
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.code === 200) {
          this.providerList = res.data.records;
        }
      });
    },
    // 获取列表数据
    getExaminePage() {
      const row = this.getQueryData();

      apiPayableSiteBillGetPage(row).then(res => {
        if (res.code === 200) {
          // this.tableData = res.data.records;
          this.tableData = (res.data.records || []).map(item => {
            return Object.freeze(item);
          });
          this.total = res.data.total;
        }
      }).catch(err => {
        console.error(err);
      });
    },

    // 请求参数处理
    getQueryData() {
      const obj = cloneDeep(this.queryForm);

      if (obj.params.payableTypes !== '') {
        obj.params.payableTypes = [obj.params.payableTypes];
      } else {
        obj.params.payableTypes = [];
      }

      if (!Array.isArray(obj.params.payableTimes)) {
        // obj.params.payableTypes = [obj.params.payableTypes];
        obj.params.payableTimes = [];
      }

      obj.params.billBatchNumbers = [];
      // 账单批次号处理
      if (obj.params.billBatchNumber.length >= 1) {
        const val = this.$refs.inputTextareaRef.getValue();
        obj.params.billBatchNumbers = val;
      }
      // obj.params.billBatchNumbers = getParseText(obj.params.billBatchNumber);
      obj.params.billBatchNumber = undefined;

      return obj;
    },

    // 列表数据勾选
    handleSelectionChange(arr) {
      if (this.rowSelectFlag) return;
      this.selectData = arr;
    },
    // 获取对账批次详情
    showBillBatchNumber(data) {
      this.batchNumber = data.billBatchNumber;
      this.billBatchData = data;
      this.showDetail = true;
      this.batchNumberKey = new Date().getTime();
    },
    // 搜索
    searchResources() {
      this.queryForm.pageNumber = 1;
      this.getExaminePage();
    },
    /**
     * 操作按钮
     * @param type 2 发起审核 1 请款 0 取消请款
     */
    operationBtn(type) {
      if (type === 2) {
        if (this.selectData.length === 0) {
          this.$message.warning(this.$i18n.t('receivablePayable.SelectAtLeastOnePieceOfData')); // 最少要选择一条数据
          return;
        }
        this.showExamine = true;
      } else {
        const list = this.selectData.length > 0 ? this.selectData : this.tableData;
        const ids = list.map(item => item.id);
        apiPayableSiteBillRequestPaymentOperation({
          ids,
          requestPaymentStatus: type
        }).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
            this.getExaminePage();
          }
        }).catch(err => {
          console.error(err);
        });
      }
    },
    // 审核保存
    examine(type) {
      if (type === 2) {
        const ids = [];
        const businessTypes = [];
        this.selectData.map(item => {
          if (businessTypes.includes(item.businessType) === false) {
            businessTypes.push(item.businessType);
          }
          ids.push(item.billBatchNumber);
        });
        if (this.examineForm.examine === 0) {
          if (businessTypes.length > 1) {
            this.$message.warning(this.$t('receivablePayable.OnlyDataSameServiceTypeCanSelected'));
            return;
          }
        }
        const param = {
          billBatchNumbers: ids,
          operationType: this.examineForm.examine,
          auditReason: this.examineForm.remark
        };
        this.$refs['examineForm'].validate((valid) => {
          if (valid) {
            apiPayableSiteBillAudit(param).then(res => {
              if (res.code === 200) {
                this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
                this.getExaminePage();
                this.showExamine = false;
                this.examineForm.examine = 2;
                this.examineForm.remark = '';
              }
            }).catch(err => {
              console.error(err);
            });
          }
        });
      } else {
        this.showExamine = false;
        this.examineForm.examine = 2;
        this.examineForm.remark = '';
      }
    },

    // 导出
    onExport() {
      const { selectData } = this;
      // const query = cloneDeep(queryForm.params);
      const { params } = this.getQueryData();

      // 指定导出
      if (selectData.length > 0) {
        params.ids = selectData.map(item => item.id);
      }

      apiPayableSiteBillExport(params).then(res => {
        goResourceTaskList();
      }).catch(err => {
        console.error(err);
      });
    }
  }
};
</script>

<style scoped>
.batchNumber {
  color: #007efd;
  cursor: pointer;
}

.app-container .searchContainer /deep/ .el-date-editor .el-range-separator {
  line-height: 25px;
}

.btn-group {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btns {
  display: flex;
  align-items: center;
}

</style>
<style>
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  border-color: #aaa;
}
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #aaa;
}
.pending .el-table tr.background {
  background-color: #FF0000!important;
  color: #FFF;
}
.pending .el-table tr.background:hover td {
  background-color: #FF0000!important;
  color: #FFF;
}

</style>
