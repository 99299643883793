import { STOCKING_COLLECT_TYPE_OBJ, NORMAL_COLLECT_TYPE_OBJ, ORDER_TYPE_OBJ, COLLECT_STATUS_OBJ } from '@/views/claimsManagement/claimList/utils.js';
import { apiClaimformOrderDetail } from '@/api/claimsManagement/list';
import { Message, Notification } from 'element-ui';
import i18n from '@/lang';

export const basicsRow = {
  customerId: '', // 客户
  applicant: '', // 申请人
  applicantContactInformation: '', // 申请人联系方式
  claimsNode: 2, // 理赔节点
  claimAmount: '', // 理赔金额
  reviewer: '', // 审批人
  orderType: 2, // 揽收类别
  claimReason: '', // 理赔原因
  merchantsId: '', // 商户id
  merchantsName: '', // 商户名称
  attachmentUrl: ''// 附件
};

// 订单
export const orderRow = {
  id: undefined, // 修改的时候才需要
  barCode: '', // 业务单号
  siteId: 0, // 操作网点
  siteName: 0, // 操作网点
  orderNumber: '', // 订单号
  customerNumber: '', // 客户单号
  collectType: '', // 揽收类型
  collectTypeStr: '', // 揽收类型
  totalDeclaredWeight: 0, // 包裹重量
  totalDeclaredValue: 0, // 申报价值
  status: '', // 订单状态
  statusStr: '', // 订单状态
  trajectory: '', // 当前轨迹
  trajectoryTime: '', // 轨迹时间
  remarks: ''// 备注
};

// 添加 导入
export const addQuery = {
  claimsNode: 0, // 理赔节点
  orderType: 0, // 揽收类别
  customerId: '', // 客户
  orderNumbers: []
};

/**
 * 获取订单状态
 * @param {*} claimsNode 理赔节点
 * @param {*} orderType 揽收类别
 * @param {*} status 订单状态
 * @returns
 */
export const getOrderStatus = (claimsNode, orderType, status) => {
  // 转运 + 派送 = 快递订单
  if (claimsNode === 3 || claimsNode === 1) {
    return ORDER_TYPE_OBJ[status]?.name ?? '';
  }

  // 揽收
  if (claimsNode === 2) {
    // 普通揽收
    if (orderType === 2) {
      return NORMAL_COLLECT_TYPE_OBJ[status]?.name ?? '';
    }

    // 备货揽收
    if (orderType === 3) {
      return STOCKING_COLLECT_TYPE_OBJ[status]?.name ?? '';
    }
  }

  return '未定义';
};

// 添加关联订单
export const setAddData = (data) => {
  return (data || []).map(item => {
    const { id, siteId, siteName, orderNumber, customerNumber, collectType, totalDeclaredWeight, totalDeclaredValue, status, trajectory, trajectoryTime, remarks, barCode, claimsNode, orderType, waybillNumber } = item;

    // 揽收类型
    let collectTypeStr = '';
    if (COLLECT_STATUS_OBJ[collectType]) {
      collectTypeStr = COLLECT_STATUS_OBJ[collectType].name;
    }
    // if (COLLECT_TYPE_OBJ[collectType]) {
    //   collectTypeStr = COLLECT_TYPE_OBJ[collectType].name;
    // }

    // 订单状态
    let statusStr = '';
    if (status !== undefined) {
      // statusStr = getOrderStatus(collectType, status);
      statusStr = getOrderStatus(claimsNode, orderType, status);
    }

    return {
      ...orderRow,
      id,
      keyCode: barCode || (claimsNode === 3 ? waybillNumber : orderNumber), // 设置key值，用于虚拟渲染
      barCode, // 条码
      siteId, // 操作网点
      siteName, // 操作网点
      orderNumber, // 订单号
      customerNumber, // 客户单号
      collectType, // 揽收类型
      collectTypeStr, // 揽收类型
      totalDeclaredWeight, // 包裹重量
      totalDeclaredValue: totalDeclaredValue || 0, // 申报价值
      status, // 订单状态
      statusStr, // 订单状态
      trajectory, // 当前轨迹
      trajectoryTime, // 轨迹时间
      remarks, // 备注
      waybillNumber // 运单号
    };
  });
};

// 请求关联订单
export const asyncApiClaimformOrderDetail = (data, isLoading = true) => {
  return apiClaimformOrderDetail(data, isLoading).then(res => {
    const { claimRelatedOrdersVOList = [], outNumber = [], outNumberMap } = res.data;
    // 订单不是当前客户的订单
    if (!claimRelatedOrdersVOList && !outNumberMap) {
      Message.warning(i18n.t('operationCenter.TheOrderWasNotQueried'));
      return [];
    }
    // 部分成功
    const list = [];
    if (outNumberMap && Object.keys(outNumberMap).length > 0) {
      for (const key in outNumberMap) {
        if (outNumberMap[key]) {
          list.push({ code: key, mes: outNumberMap[key] });
        }
      }
      showNotification(list);
    }
    // if (outNumber.length > 0) {
    //   if (outNumber.length > 20) {
    //     const list = outNumber.map(item => {
    //       return {
    //         code: item
    //       };
    //     });
    //     showNotification(list);
    //   } else {
    //     const arr = outNumber.join('、');
    //     Message({
    //       message: `${arr} 订单不是当前客户的订单`,
    //       type: 'warning',
    //       duration: 5 * 1000,
    //       showClose: true
    //     });
    //   }
    // }
    //
    // if (!claimRelatedOrdersVOList && outNumber.length === 0) {
    //   Message.warning(i18n.t('operationCenter.TheOrderWasNotQueried'));
    //   return [];
    // }
    //
    // if (!claimRelatedOrdersVOList || claimRelatedOrdersVOList.length === 0) {
    //   return [];
    // }

    return setAddData(claimRelatedOrdersVOList);
  });
};

const showNotification = (list) => {
  // 关闭之前的通知
  Notification.closeAll();

  // 显示新的通知
  Notification({
    title: i18n.t('compensationManagement.WrongTrackingNumber'),
    message: genetateHTML(list),
    type: 'error',
    dangerouslyUseHTMLString: true,
    duration: 0
  });
};

const genetateHTML = (list) => {
  let html = '<ul>';
  list.map((item, index) => {
    html += `<li>${++index}. ${item.code}${item.mes}</li>`;
  });
  html += '</ul>';
  return html;
};

