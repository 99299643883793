<template>
  <div class="content">
    <el-form
      :inline="false" :model="formInline" class="demo-form-inline"
      label-position="top" size="small"
    >
      <el-row :gutter="20">
        <el-col :span="5">
          <el-form-item :label="$t('customerManagements.CompanyName')">
            <el-input
              v-model="formInline.companyName"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item :label="$t('collectionCenter.compellation')">
            <el-input
              v-model="formInline.name"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item :label="$t('orderCenterCont.province')">
            <el-input
              v-model="formInline.state"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item :label="$t('orderCenterCont.city')">
            <el-input
              v-model="formInline.city"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="CPF">
            <el-input
              v-model="formInline.cpf"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="CNPJ">
            <el-input
              v-model="formInline.cnpj"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item :label="$t('GlobalSetObj.zipCode')">
            <el-input
              v-model="formInline.zipcode"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="display: flex;justify-content: space-between;">
      <div>
        <el-button size="small" type="danger" @click="restSearch">{{
          $t('customerManagements.Resetconditions')
        }}
        </el-button>
        <el-button size="small" type="primary" @click="entrySearch">{{ $t('GlobalSetObj.search') }}</el-button>
      </div>
      <div>
        <el-button size="small" type="primary" @click="newAddress(1)">{{
          $t('customerManagements.newAddress')
        }}
        </el-button>
        <el-button
          :disabled="multipleSelection.length > 1" size="small" type="warning"
          @click="newAddress(2)"
        >
          {{ $t('collectionCenter.modify') }}
        </el-button>
        <el-button size="small" type="danger" @click="delAddress">{{ $t('GlobalSetObj.Delete') }}</el-button>
        <el-button size="small" type="success" @click="exportAddress">{{
          $t('customerManagements.exportAddress')
        }}
        </el-button>
      </div>
    </div>
    <div class="table" style="margin-top: 20px;">
      <el-table
        ref="multipleTable"
        :data="tableData"
        :header-cell-style="{background:'#f2f2f2',color:'black'}"
        border
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
        />
        <el-table-column
          :label="$t('GlobalSetObj.serial')"
          align="center"
          type="index"
          width="55"
        />
        <el-table-column
          v-for="item in tableHeaderList"
          :key="item.id"
          :align="item.colCenter"
          :label="item.label"
          :min-width="item.cloWidth"
          :prop="item.prop"
        />
      </el-table>
    </div>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--  新建收件人  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="titleDialog"
      :visible.sync="addressDialog"
      center
      top="5vh"
      width="900px"
      @close="handleAddressDialog"
    >
      <div>
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          class="demo-ruleForm"
          inline
          label-position="top"
          size="small"
        >
          <el-row :gutter="100">
            <el-col :span="12">
              <!--方案名称-->
              <el-form-item
                :label="$t('customerManagements.schemeName')"
                :rules="{ required: true, message: $t('placeOrder.pleaseInput'), trigger: ['blur','change']}"
                prop="scheme"
                style="width: 100%"
              >
                <el-input
                  v-model.trim="ruleForm.scheme"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="100"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--姓名-->
              <el-form-item
                :label="$t('collectionCenter.compellation')"
                :rules="{ required: true, message: $t('placeOrder.pleaseInput'), trigger: ['blur','change']}"
                prop="name"
                style="width: 100%"
              >
                <el-input
                  v-model.trim="ruleForm.name"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="100"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--公司名称-->
              <el-form-item
                :label="$t('customerManagements.CompanyName')"
                prop="companyName"
                style="width: 100%"
              >
                <el-input
                  v-model.trim="ruleForm.companyName"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="100"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('customerManagements.stateTwo')"
                :rules="{ required: true, message: $t('GlobalSetObj.pleaseChoose'), trigger: ['blur','change']}"
                prop="state"
                style="width: 100%"
              >
                <el-select
                  v-model="ruleForm.state"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  clearable
                  filterable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in stateList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('customerManagements.cityName')"
                :rules="{required: true, message: $t('GlobalSetObj.pleaseInput'), trigger: ['blur','change']}"
                prop="city"
                style="width: 100%"
              >
                <el-input
                  v-model="ruleForm.city"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="30"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('GlobalSetObj.userPhone')"
                prop="mobile"
                style="width: 100%"
              >
                <el-input
                  v-model.trim="ruleForm.phone"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="100"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('orderCenterCont.Area')"
                prop="area"
                style="width: 100%"
              >
                <el-input
                  v-model="ruleForm.area"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="30"
                />
              </el-form-item>
            </el-col>
            <!--  手机号 -->
            <el-col :span="12">
              <el-form-item
                :label="$t('orderCenterCont.MobilePhoneNumber')"
                :rules="isPhone"
                prop="mobile"
                style="width: 100%"
              >
                <el-input
                  v-model="ruleForm.mobile"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="30"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('orderCenterCont.RecipientStreet')"
                prop="street"
                style="width: 100%"
              >
                <el-input
                  v-model.trim="ruleForm.street"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="30"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :rules="cpf"
                label="CPF"
                prop="cpf"
                style="width: 100%"
              >
                <el-input
                  v-model="ruleForm.cpf"
                  :placeholder="$t('collectionCenter.enterpriseTaxNumTips11')"
                  clearable
                  maxlength="16"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :rules="signCnpj"
                label="CNPJ"
                prop="cnpj"
                style="width: 100%"
              >
                <el-input
                  v-model="ruleForm.cnpj"
                  :placeholder="$t('collectionCenter.enterpriseTaxNumTips14')"
                  clearable
                  maxlength="25"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('placeOrder.addressComplement')"
                prop="complement"
                style="width: 100%"
              >
                <el-input
                  v-model="ruleForm.complement"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="30"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('placeOrder.HouseNumber')"
                :rules="{ required: true, message: $t('GlobalSetObj.pleaseInput'), trigger: ['blur','change']}"
                prop="houseNumber"
                style="width: 100%"
              >
                <el-input
                  v-model="ruleForm.houseNumber"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="5"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('placeOrder.zipcode')"
                :rules="isZipCode"
                prop="zipcode"
                style="width: 100%"
              >
                <el-input
                  v-model.trim="ruleForm.zipcode"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('placeOrder.detailedAddress')"
                :rules="[{ required: true, message: $t('placeOrder.pleaseInput'), trigger: ['blur','change'] },
                         { min: 2, max: 200, message: $t('newOrder.TdetailedAddressCharacter'), trigger: 'blur' }]"
                prop="addressDetail"
                style="width: 100%"
              >
                <el-input
                  v-model="ruleForm.addressDetail"
                  :placeholder="$t('newOrder.StreetHouseNumber')"
                  clearable
                  maxlength="200"
                  show-word-limit
                  type="text"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--国家注册号-->
              <el-form-item
                :label="$t('GlobalSetObj.NationalRegistrationNumber')"
                :rules="IeNumber1"
                prop="ieNumber"
                style="width: 100%"
              >
                <el-input
                  v-model.trim="ruleForm.ieNumber"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="50"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" style="margin-right: 20px;" @click="addressDialog = false">{{
          $t('GlobalSetObj.Cancelar')
        }}
        </el-button>
        <el-button size="small" type="primary" @click="addressConfirm">{{ $t('GlobalSetObj.determine') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addAddressMange, addressPage, delAddress, editAddress, exportAddress, getLgAreaListData } from '@/api/orderApi';
import { checkCityComplement, IENumber, isMobile, isZipCodeAsync } from '@/utils/validate';

export default {
  name: 'NewAddressManagement',
  data() {
    return {
      titleDialog: this.$t('customerManagements.newFolder'),
      formInline: {
        companyName: '', // 公司名称
        name: '',
        state: '',
        city: '',
        cpf: '',
        cnpj: '',
        zipcode: ''
      },
      tableHeaderList: [
        {
          id: 21,
          label: this.$t('customerManagements.schemeName'),
          prop: 'scheme',
          cloWidth: '150',
          colCenter: 'center'
        },
        { id: 1, label: this.$t('collectionCenter.compellation'), prop: 'name', cloWidth: '150', colCenter: 'center' },
        {
          id: 2,
          label: this.$t('customerManagements.CompanyName'),
          prop: 'companyName',
          cloWidth: '150',
          colCenter: 'center'
        },
        { id: 3, label: this.$t('GlobalSetObj.MobilePhone'), prop: 'mobile', cloWidth: '150', colCenter: 'center' },
        { id: 4, label: 'CPF', prop: 'cpf', cloWidth: '140', colCenter: 'center' },
        { id: 7, label: this.$t('orderCenterCont.province'), prop: 'state', cloWidth: '120', colCenter: 'center' },
        { id: 8, label: this.$t('orderCenterCont.city'), prop: 'city', cloWidth: '120', colCenter: 'center' },
        { id: 9, label: this.$t('GlobalSetObj.zipCode'), prop: 'zipcode', cloWidth: '120', colCenter: 'center' },
        { id: 10, label: this.$t('orderCenterCont.region'), prop: 'area', cloWidth: '120', colCenter: 'center' },
        {
          id: 11,
          label: this.$t('customerManagements.streetName'),
          prop: 'street',
          cloWidth: '120',
          colCenter: 'center'
        },
        { id: 12, label: this.$t('placeOrder.HouseNumber'), prop: 'houseNumber', cloWidth: '120', colCenter: 'center' },
        {
          id: 14,
          label: this.$t('placeOrder.detailedAddress'),
          prop: 'addressDetail',
          cloWidth: '120',
          colCenter: 'center'
        }
      ],
      tableData: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0
      },
      // 城市
      cityComplement: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: checkCityComplement, trigger: ['blur', 'change'] }
      ],
      isPhone: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        {
          validator: async(rule, value, callback) => {
            if (!isMobile(value)) {
              return callback(new Error(this.$t('placeOrder.input11PhoneNumber')));
            } else {
              callback();
            }
          }
        }
      ],
      isZipCode: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: isZipCodeAsync, trigger: ['blur'] }
      ],
      cpf: [
        // '不能为空'
        { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
        {
          validator: (rule, value, callback) => {
            const reg = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$/;
            if (!reg.test(value)) {
              return callback(new Error(this.$t('collectionCenter.enterpriseTaxNumTips11'))); // 数字长度为11，格式为`xxx.xxx.xxx-yy`
            } else {
              return callback();
            }
          }
        }
      ],
      signCnpj: [
        // '不能为空'
        { required: false, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
        {
          validator: (rule, value, callback) => {
            const reg = /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}\-[0-9]{2}$/;
            if (value) {
              if (!reg.test(value)) {
                return callback(new Error(this.$t('collectionCenter.enterpriseTaxNumTips14'))); // 数字长度为11，格式为`xxx.xxx.xxx-yy`
              } else {
                return callback();
              }
            } else {
              return callback();
            }
          }
        }
      ],
      IeNumber1: [
        { required: false, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: IENumber, trigger: ['blur', 'change'] }
      ],
      addressDialog: false,
      ruleForm: {
        scheme: '',
        companyName: '', // 公司名称
        name: '',
        state: '',
        city: '',
        cpf: '',
        cnpj: '',
        zipcode: '',
        mobile: '',
        area: '',
        street: '',
        houseNumber: '',
        complement: '',
        ieNumber: '',
        addressDetail: '',
        email: '',
        phone: ''
      },
      stateList: [],
      multipleSelection: []
    };
  },
  created() {
    this.getAddress();
    this.getState();
  },
  methods: {
    entrySearch() {
      this.queryForm.pageNumber = 1;
      this.getAddress();
    },
    handleAddressDialog() {
      this.addressDialog = false;
      this.ruleForm = {
        scheme: '',
        companyName: '', // 公司名称
        name: '',
        state: '',
        city: '',
        cpf: '',
        cnpj: '',
        zipcode: '',
        mobile: '',
        area: '',
        street: '',
        houseNumber: '',
        complement: '',
        ieNumber: '',
        addressDetail: '',
        email: '',
        phone: ''
      };
      this.$refs['ruleForm'].resetFields();
      this.$refs.multipleTable.clearSelection();
    },
    restSearch() {
      this.formInline = {
        companyName: '', // 公司名称
        name: '',
        state: '',
        city: '',
        cpf: '',
        zipcode: ''
      };
      this.entrySearch();
    },
    // 新增地址
    newAddress(flag) {
      if (flag === 2) {
        if (this.multipleSelection.length <= 0) {
          // '请勾选要导出的数据'
          this.$message.warning(this.$t('GlobalSetObj.CheckForm'));
          return;
        }
        this.addressDialog = true;
      } else {
        this.addressDialog = true;
      }
    },
    // 删除
    delAddress() {
      if (this.multipleSelection.length <= 0) {
        this.$message.warning(this.$t('GlobalSetObj.CheckForm'));
        return;
      }
      const data = {
        idList: []
      };
      this.multipleSelection.forEach((item) => {
        data.idList.push(item.id);
      });
      this.$confirm(this.$t('collectionCenter.deleteSure'), this.$t('collectionCenter.tips'), {
        confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
        cancelButtonText: this.$t('collectionCenter.cancel'), // '取消'
        type: 'warning'
      }).then(async() => {
        delAddress(data).then(res => {
          if (res.code===200) {
            this.$message.success(this.$t('GlobalSetObj.DeleteSuccessful'));
            this.entrySearch();
          }
        });
      }).catch(() => {
        console.log('取消关闭');
      });
    },
    // 导出
    exportAddress() {
      const data = {
        ...this.formInline
      };
      exportAddress(data).then(res => {
        if (res.code===200) {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                this.$router.push({ path: '/views/resource/exportTaskList' });
              }
            })
            .catch(() => {
            });
        }
      });
    },
    // 确认
    addressConfirm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          const data = this.ruleForm;
          console.log(data, 'SDFSDF');
          if (this.titleDialog === this.$t('customerManagements.newFolder')) {
            addAddressMange(data).then(res => {
              if (res.code===200) {
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                this.addressDialog = false;
                this.$refs.multipleTable.clearSelection();
                this.getAddress();
              }
            });
          } else {
            editAddress(data).then(res => {
              if (res.code===200) {
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                this.addressDialog = false;
                this.$refs.multipleTable.clearSelection();
                this.getAddress();
              }
            });
          }
        }
      });
    },
    getAddress() {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params = this.formInline;
      addressPage(data).then(res => {
        if (res.code===200) {
          console.log(res, 'LKLK');
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val, 'LKJLK');
      if (val.length === 1) {
        this.titleDialog = this.$t('customerManagements.editFolder');
        this.ruleForm = JSON.parse(JSON.stringify(val[0]));
      } else {
        this.titleDialog = this.$t('customerManagements.newFolder');
        this.ruleForm = {
          scheme: '',
          companyName: '', // 公司名称
          name: '',
          state: '',
          city: '',
          cpf: '',
          cnpj: '',
          zipcode: '',
          mobile: '',
          area: '',
          street: '',
          houseNumber: '',
          complement: '',
          ieNumber: '',
          addressDetail: '',
          email: '',
          phone: ''
        };
      }
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.getAddress();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getAddress();
    },
    getState() { // level 0 州    1 城市   2 地区
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.code===200) {
          this.stateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.content {
  padding: 10px;
}

>>> .el-dialog__body {
  padding: 10px 20px;
}
</style>
