<template>
  <div class="app-container">
    <el-table
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('spareNumberPoo.trackingNumber')"
        min-width="150"
        prop="trackingNumber"
      />
      <el-table-column
        :label="$t('spareNumberPoo.SortingTime')"
        min-width="160"
        prop="creatTime"
      />
      <el-table-column
        :label="$t('spareNumberPoo.DropGrille')"
        min-width="120"
        prop="gridNumber"
      />
      <el-table-column
        :label="$t('spareNumberPoo.CarNumber')"
        min-width="150"
        prop="container"
      />
      <el-table-column
        :label="$t('spareNumberPoo.SortingCenter')"
        min-width="180"
        prop="warehouseCode"
      />
      <el-table-column
        :label="$t('spareNumberPoo.deviceID')"
        min-width="180"
        prop="idSorter"
      />
      <el-table-column
        :label="$t('spareNumberPoo.packageWeight')"
        min-width="210"
        prop="weight"
      />
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { sorterMovementsPage } from '@/api/logisticsOrders/index.js';

export default {
  name: 'SortLogs',
  props: {
    queryTrackParam: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      queryForm: {
        total: 0,
        pageSize: 10,
        pageNumber: 1,
        params: {
          waybillNumber: ''
        }
      },
      tableData: []
    };
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getSearch();
  },
  methods: {
    // 查询
    getSearch() {
      this.queryForm.params.waybillNumber = this.queryTrackParam.waybillNumber;
      console.log(this.queryForm, 'this.queryFormthis.queryFormthis.queryForm');
      sorterMovementsPage(this.queryForm).then(res => {
        if (res.code===200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-table__row {
  font-weight: bold;
}
</style>

