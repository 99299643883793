<template>
  <div class="TimeLimitForSigningBox">
    <div style="margin-bottom: 20px;">
      <el-form>
        <el-row class="listQueryTime">
          <el-col :span="10" style="width: auto">
            <el-form-item :label="$t('collectionCenter.lanshouT') + '：'">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                type="daterange"
                value-format="yyyy-MM-dd"
                :default-time="['00:00:00', '23:59:59']"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                :end-placeholder="$t('collectionCenter.EndTime')"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!-- 揽收网点： -->
            <el-form-item :label="$t('collectionCenter.LSSite')">
              <div class="dialogC">
                <el-select
                  v-model="queryForm.params.siteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                  filterable :remote-method="remoteMethod" remote
                >
                  <el-option
                    v-for="(item, index) in siteIdList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:report:receiveDailyReport:find'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:report:receiveDailyReport:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-if="findActiveTime=='hzFind' && exportHZ"
            icon="el-icon-upload2"
            size="mini"
            @click="download"
          >{{ $t('collectionCenter.export') }}</el-button><!--  --><!-- 导出 汇总 -->
          <el-button
            v-if="findActiveTime=='mxFind' && exportMX"
            icon="el-icon-upload2"
            size="mini"
            @click="downloadLogistics"
          >{{ $t('collectionCenter.export') }}</el-button><!--  --><!-- 导出 明细 -->
          <el-button
            v-if="findActiveTime=='hzFind' && allExportHZ"
            v-disableExport
            icon="el-icon-upload"
            size="mini"
            @click="downloadAllHZ"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出——汇总 -->
          <el-button
            v-if="findActiveTime=='mxFind' && allExportMX"
            v-disableExport
            icon="el-icon-upload"
            size="mini"
            @click="downloadAll"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出——明细 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <el-tabs v-model="findActiveTime" type="border-card">
      <!-- 汇总 -->
      <el-tab-pane :label="$t('collectionCenter.summary')" name="hzFind">
        <div>
          <el-table
            ref="hzTable"
            :max-height="600"
            border
            show-summary
            :summary-method="getSummaries"
            :data="tableDate"
            @select="batchSelect"
            @select-all="batchSelectAll"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <!-- 序号 -->
            <el-table-column
              :label="$t('collectionCenter.ind')" type="index" width="60"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="siteName"
              :label="$t('collectionCenter.LanShouSite')"
            /><!-- 揽收网点 -->
            <el-table-column
              align="center"
              prop="collectTime"
              :label="$t('collectionCenter.lanshouT')"
            /><!-- 揽收时间 -->
            <el-table-column
              align="center"
              prop="scanCount"
              :label="$t('collectionCenter.scannedPackagesTotal')"
            ><!-- 扫描包裹总数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, 1)">{{ scope.row.scanCount }}</el-button>
                <span v-else>{{ scope.row.scanCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="collectCount"
              :label="$t('collectionCenter.receivedParcelsTotal')"
            ><!-- 揽收包裹总数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, 2)">{{ scope.row.collectCount }}</el-button>
                <span v-else>{{ scope.row.collectCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="sealingCount"
              :label="$t('collectionCenter.sealingBagTotal')"
            ><!-- 封袋总数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, 3)">{{ scope.row.sealingCount }}</el-button>
                <span v-else>{{ scope.row.sealingCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="loadedCount"
              :label="$t('collectionCenter.totalLoad')"
            ><!-- 装车总数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, 4)">{{ scope.row.loadedCount }}</el-button>
                <span v-else>{{ scope.row.loadedCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="unloadCount"
              :label="$t('collectionCenter.totalUnloading')"
            ><!-- 卸车总数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, 5)">{{ scope.row.unloadCount }}</el-button>
                <span v-else>{{ scope.row.unloadCount }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" :label="$t('collectionCenter.DotHoldUp')">
              <!-- 网点滞留量 -->
              <el-table-column
                align="center"
                prop="fiveCount"
                :label="$t('collectionCenter.within5Hours')"
              ><!-- 5小时内 -->
                <template slot-scope="scope">
                  <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, '', 1)">{{ scope.row.fiveCount }}</el-button>
                  <span v-else>{{ scope.row.fiveCount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="tenCount"
                :label="$t('collectionCenter.within10Hours')"
              ><!-- 10小时内 -->
                <template slot-scope="scope">
                  <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, '', 2)">{{ scope.row.tenCount }}</el-button>
                  <span v-else>{{ scope.row.tenCount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="thanTenCount"
                :label="$t('collectionCenter.over10Hours')"
              ><!-- 超10小时 -->
                <template slot-scope="scope">
                  <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, '', 3)">{{ scope.row.thanTenCount }}</el-button>
                  <span v-else>{{ scope.row.thanTenCount }}</span>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getTablePage"
            @size-change="getPageSize"
          />
        </div>
      </el-tab-pane>
      <!-- 明细 -->
      <el-tab-pane v-if="findActiveTime=='mxFind'" :label="$t('collectionCenter.particulars')" name="mxFind">
        <div>
          <el-table
            ref="mxTable"
            :max-height="600"
            border
            :data="mxtableDate"
            @select="batchSelectMX"
            @select-all="batchSelectAllMX"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <!-- 序号 -->
            <el-table-column
              :label="$t('collectionCenter.ind')" type="index" width="60"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ (queryFormMX.pageNumber - 1) * queryFormMX.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="collectUserName"
              :label="$t('collectionCenter.LanShouPeople')"
            /><!-- 揽收人 -->
            <el-table-column
              align="center"
              prop="orderNumber"
              :label="$t('collectionCenter.lanshouOrderNum')"
            /><!-- 揽收订单号 -->
            <el-table-column
              align="center"
              prop="scanNumber"
              :label="$t('collectionCenter.Barcode')"
            /><!-- 条形码 -->
            <el-table-column
              align="center"
              prop="bagNumber"
              :label="$t('collectionCenter.BagNumber')"
            /><!-- 袋号 -->
            <el-table-column
              align="center"
              prop="collectTime"
              :label="$t('collectionCenter.lanshouT')"
            /><!-- 揽收时间 -->
            <el-table-column
              align="center"
              prop="bagSealingTime"
              :label="$t('collectionCenter.sealingBagTime')"
            /><!-- 封袋时间 -->
            <el-table-column
              align="center"
              prop="loadedTime"
              :label="$t('collectionCenter.loadTime')"
            /><!-- 装车时间 -->
            <el-table-column
              align="center"
              prop="loadedUserName"
              :label="$t('collectionCenter.loader')"
            /><!-- 装车人 -->
            <el-table-column
              align="center"
              prop="unloadUserName"
              :label="$t('collectionCenter.unloader')"
            /><!-- 卸车人 -->
            <el-table-column
              align="center"
              prop="unloadTime"
              :label="$t('collectionCenter.unloadTime')"
            /><!-- 卸车时间 -->
          </el-table>
        </div>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryFormMX.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :page-size.sync="queryFormMX.pageSize"
            :total="totalMX"
            background
            style="margin: 10px 0"
            @current-change="getTablePageMX"
            @size-change="searchClickMX"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  queryCollectDailyReportPage,
  queryReportDetailePage,
  queryReportGatherExportAll,
  queryReportDetaileExportAll,
  searchSiteIdList, siteFuzzyQuery
} from '@/api/lanshou';
import ExcelJS from 'exceljs';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

export default {
  data() {
    return {
      isClickAll1: false,
      isClickAll2: false,
      isClick: false,
      exportHZ: this.$store.getters.button_permissions.includes('btn:collection-center:report:receiveDailyReport:export-hz'),
      exportMX: this.$store.getters.button_permissions.includes('btn:collection-center:report:receiveDailyReport:export-mx'),
      allExportHZ: this.$store.getters.button_permissions.includes('btn:collection-center:report:receiveDailyReport:allExport-hz'),
      allExportMX: this.$store.getters.button_permissions.includes('btn:collection-center:report:receiveDailyReport:allExport-mx'),
      hzTomx: this.$store.getters.button_permissions.includes('btn:collection-center:report:receiveDailyReport:hzTomx'),
      timeValue: [],
      findActiveTime: 'hzFind', // 汇总 hzFind   明细 mxFind
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          collectTimeStart: '',
          collectTimeEnd: '',
          siteId: ''
        }
      },
      siteIdList: [], // 站点list
      tableDate: [],
      total: 0,
      // 导出列
      exportList: [], // 汇总
      exportMXList: [],
      // 明细
      mxtableDate: [],
      totalMX: 0,
      queryFormMX: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          collectTimeStart: '',
          collectTimeEnd: '',
          siteId: '',
          timeOptType: null,
          detailyType: null
        }
      }
    };
  },
  watch: {
    timeValue() {
      if (this.timeValue && this.timeValue.length <= 0) {
        this.queryForm.params.collectTimeStart = '';
        this.queryForm.params.collectTimeEnd = '';
      }
    }
  },
  created() {
    // if (!this.$route.query.t1) {
    this.default();
    // 分页获取网点列表
    this.remoteMethod();
    this.searchClick();
    this.isClick = false;
    this.isClickAll1 = false;
    this.isClickAll2 = false;
    // }
  },
  // activated() {
  //   // 考虑到从‘揽收营运数据’进入页面时携带参数
  //   if (!this.$route.query.t1) {
  //     this.default();
  //   }
  //   if (this.$route.query.t1) {
  //     this.getNewestSite();
  //     setTimeout(() => {
  //       const { t1, t2 } = this.$route.query;
  //       this.timeValue = [t1, t2];
  //       this.queryForm.params = {
  //         collectTimeStart: t1,
  //         collectTimeEnd: t2,
  //         siteId: this.$store.state.user.siteId
  //       };
  //       this.searchClick();
  //     }, 500);
  //   }
  // },
  methods: {
    // getNewestSite() {
    //   searchSiteIdList().then(res => {
    //     if (res.code===200) {
    //       this.siteIdList = [];
    //       res.data.forEach(data => {
    //         this.siteIdList.push({ id: data.id, name: data.name });
    //       });
    //     }
    //   });
    // },
    /**
     * @description 分页查询网点列表
     * @param val
     */
    remoteMethod(val) {
      const value = val || '';
      siteFuzzyQuery(value).then(res => {
        if (res.code===200) {
          this.siteIdList = res.data;
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        return;
      }
      this.queryForm.params.collectTimeStart = val[0] + ' 00:00:00';
      this.queryForm.params.collectTimeEnd = val[1] + ' 23:59:59';
      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-14, 'day').format('YYYY-MM-DD') + ' 23:59:59';
        if (val[1] > endTime) {
          this.$message({
            // 最多可查询到15天的数据
            message: this.$t('collectionCenter.max15DaysDate'),
            type: 'warning'
          });
          this.changeTime();
        }
      }
    },
    // 汇总---------------------------------------
    default() {
      const d = new Date();
      const day = dayjs(d).format('YYYY-MM-DD 00:00:00');
      const today = dayjs(d).format('YYYY-MM-DD 23:59:59');
      this.timeValue = [day, today];
      this.queryForm.params = {
        collectTimeStart: day,
        collectTimeEnd: today,
        siteId: ''
      };
    },
    changeTime() { // 选择扫描时间 / 创建时间
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params.collectTimeStart = today + ' 00:00:00';
      this.queryForm.params.collectTimeEnd = today + ' 23:59:59';
    },
    getTablePage() { // 汇总表查询
      this.exportList = [];
      const queryForm = JSON.parse(localStorage.getItem('RDHZQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      queryCollectDailyReportPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableDate = [];
          this.total = 0;
        }
      }).catch({});
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('RDHZQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      queryCollectDailyReportPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableDate = [];
          this.total = 0;
        }
      }).catch({});
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.all');
          return;
        }
        if (column.label === this.$t('collectionCenter.scannedPackagesTotal') ||
            column.label === this.$t('collectionCenter.receivedParcelsTotal') ||
            column.label === this.$t('collectionCenter.sealingBagTotal') ||
            column.label === this.$t('collectionCenter.totalLoad') ||
            column.label === this.$t('collectionCenter.totalUnloading') ||
            column.label === this.$t('collectionCenter.within5Hours') ||
            column.label === this.$t('collectionCenter.within10Hours') ||
            column.label === this.$t('collectionCenter.over10Hours')) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    },
    batchSelect(selection, row) {
      this.exportList = selection;
    },
    batchSelectAll(selection) {
      this.exportList = selection;
    },
    // 下载签收时效报表模板
    download() { // 汇总导出
      if (this.exportList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      // 导出一次后十秒后才能继续导出
      this.isClick = true;
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const columnsList = [
          {
            header: that.$t('collectionCenter.LanShouSite'), // '揽收网点'
            key: 'siteName', // prop
            width: 15
          },
          {
            header: that.$t('collectionCenter.lanshouT'), // '揽收时间'
            key: 'collectTime', // prop
            width: 15
          },
          {
            header: that.$t('collectionCenter.scannedPackagesTotal'), // '扫描包裹总数'
            key: 'scanCount', // prop
            width: 15
          },
          {
            header: that.$t('collectionCenter.receivedParcelsTotal'), // '揽收包裹总数'
            key: 'collectCount', // prop
            width: 15
          },
          {
            header: that.$t('collectionCenter.sealingBagTotal'), // '封袋总数'
            key: 'sealingCount', // prop
            width: 15
          },
          {
            header: that.$t('collectionCenter.totalLoad'), // '装车总数'
            key: 'loadedCount',
            width: 15
          },
          {
            header: that.$t('collectionCenter.totalUnloading'), // '卸车总数'
            key: 'unloadCount',
            width: 15
          },
          {
            header: that.$t('collectionCenter.DotHoldUp'), // '网点滞留量'
            key: 'xxx',
            width: 60,
            children: [
              {
                header: that.$t('collectionCenter.within5Hours'), // '5小时内'
                key: 'fiveCount',
                width: 20
              },
              {
                header: that.$t('collectionCenter.within10Hours'), // '10小时内'
                key: 'tenCount',
                width: 20
              },
              {
                header: that.$t('collectionCenter.over10Hours'), // '超10小时'
                key: 'thanTenCount',
                width: 20
              }
            ]
          }
        ];
        // 第一行表头
        const names1 = [];
        // 第二行表头
        const names2 = [];
        // 用于匹配数据的 keys
        const headerKeys = [];
        columnsList.forEach(item => {
          if (item.children) {
            item.children.forEach(child => {
              names1.push(item.header);
              names2.push(child.header);
              headerKeys.push(child.key);
            });
          } else {
            const columnNumber = Math.round(item.width / 10);
            for (let i = 0; i < columnNumber; i++) {
              names1.push(item.header);
              names2.push(item.header);
              headerKeys.push(item.key);
            }
          }
        });
        that.handleHeader(Sheet1, columnsList, names1, names2);
        // 添加数据
        that.addData2Table(Sheet1, headerKeys, columnsList);
        wb.xlsx.writeBuffer().then(buffer => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const date = new Date().getTime();
          const saveName = that.$t('collectionCenter.receiveDailyReport') + date + '.xlsx'; // '揽收日报表.xlsx'

          saveAs(blob, saveName);
          // const url = URL.createObjectURL(blob);
          // const link = document.createElement('a');
          // link.href = url;
          // const date = new Date().getTime();
          // const saveName = that.$t('collectionCenter.receiveDailyReport') + date + '.xlsx'; // '揽收日报表.xlsx'
          // link.download = saveName;
          // link.click();
          that.$message.success(that.$t('collectionCenter.ExportSuccessful1'));
        });
      }
      ddd();
    },
    handleHeader(Sheet1, headers, names1, names2) {
      const isMultiHeader = headers?.some(item => item.children);
      if (isMultiHeader) { // 加表头数据
        const rowHeader1 = Sheet1.addRow(names1);
        const rowHeader2 = Sheet1.addRow(names2);
        // 添加表头样式
        this.addHeaderStyle(rowHeader1, { color: '339966' });
        this.addHeaderStyle(rowHeader2, { color: '339966' });
        this.mergeColumnCell(headers, rowHeader1, rowHeader2, names1, names2, Sheet1);
        return;
      }
      // 加表头数据
      const rowHeader = Sheet1.addRow(names1);
      // 表头根据内容宽度合并单元格
      this.mergeRowCell(headers, rowHeader, Sheet1);
      // 添加表头样式
      this.addHeaderStyle(rowHeader, { color: '339966' });
    },
    addHeaderStyle(row, attr) {
      const { color, fontSize, horizontal } = attr || {};
      // eslint-disable-next-line no-param-reassign
      row.height = 20;
      row.eachCell((cell, colNumber) => {
        // eslint-disable-next-line no-param-reassign
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        };
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          size: fontSize ?? 13,
          name: '微软雅黑'
        };
        // eslint-disable-next-line no-param-reassign
        cell.alignment = { vertical: 'middle', wrapText: true, horizontal: horizontal ?? 'left' };
      });
    },
    mergeColumnCell(headers, rowHeader1, rowHeader2, nameRow1, nameRow2, Sheet1) {
      let pointer = -1;
      nameRow1.forEach((name, index) => {
        if (index <= pointer) return;
        const shouldVerticalMerge = name === nameRow2[index];
        const shouldHorizontalMerge = index !== nameRow1.lastIndexOf(name);
        pointer = nameRow1.lastIndexOf(name);
        if (shouldVerticalMerge && shouldHorizontalMerge) {
          Sheet1.mergeCells(
            Number(rowHeader1.number),
            index + 1,
            Number(rowHeader2.number),
            nameRow1.lastIndexOf(name) + 1
          );
        } else if (shouldVerticalMerge && !shouldHorizontalMerge) {
          Sheet1.mergeCells(Number(rowHeader1.number), index + 1, Number(rowHeader2.number), index + 1);
        } else if (!shouldVerticalMerge && shouldHorizontalMerge) {
          Sheet1.mergeCells(
            Number(rowHeader1.number),
            index + 1,
            Number(rowHeader1.number),
            nameRow1.lastIndexOf(name) + 1
          );
          const cell = rowHeader1.getCell(index + 1);
          cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        }
      });
    },
    mergeRowCell(headers, row, Sheet1) {
      // 当前列的索引
      let colIndex = 1;
      headers.forEach(header => {
        const { width, children } = header;
        let colNum = '';
        if (children) {
          colNum = Math.round(width / 5);
          children.forEach(child => {
            colIndex += 1;
          });
        } else {
          // 需要的列数，四舍五入
          colNum = Math.round(width / 10);
          // 如果 colNum > 1 说明需要合并
          if (colNum > 1) {
            Sheet1.mergeCells(Number(row.number), colIndex, Number(row.number), colIndex + colNum - 1);
          }
          colIndex += colNum;
        }
      });
    },
    addData2Table(Sheet1, headerKeys, columnsList) {
      this.exportList.forEach((item) => {
        const rowData = headerKeys?.map(key => item[key]);
        const row = Sheet1.addRow(rowData);
        this.mergeRowCell(columnsList, row, Sheet1);
        row.height = 30;
        // 设置行样式, wrapText: 自动换行
        row.alignment = { vertical: 'middle', wrapText: true, shrinkToFit: false };
        row.font = { size: 12, name: '微软雅黑' };
      });
    },

    cellClick(row, type, time) { // 跳转到明细
      this.findActiveTime = 'mxFind';
      const queryHZ = JSON.parse(localStorage.getItem('RDHZQueryForm'));
      // 查询时间取row当前选中行的时间
      this.queryFormMX = {
        pageNumber: 1,
        pageSize: this.queryFormMX.pageSize,
        params: {
          collectTimeStart: row.collectTime + ' 00:00:00',
          collectTimeEnd: row.collectTime + ' 23:59:59',
          siteId: row.siteId,
          timeOptType: time || null,
          detailyType: type || null
        }
      };
      console.log(this.queryFormMX);
      localStorage.setItem('RDMXQueryForm', JSON.stringify(this.queryFormMX));
      this.getTablePageMX();
    },
    searchClick() {
      if (this.timeValue && this.timeValue.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      if (this.findActiveTime === 'hzFind') {
        this.exportList = [];
        this.queryForm.pageNumber = 1;
        localStorage.setItem('RDHZQueryForm', JSON.stringify(this.queryForm));
        this.getTablePage();
        // // 查询完后清空掉传值，避免切换页面重进时查询参数错误
        // this.$router.push({
        //   path: this.$route.path,
        //   query: {}
        // });
      }
      if (this.findActiveTime === 'mxFind') {
        this.exportMXList = [];
        const queryHZ = JSON.parse(localStorage.getItem('RDMXQueryForm'));
        this.queryFormMX = {
          pageNumber: 1,
          pageSize: this.queryFormMX.pageSize,
          params: {
            collectTimeStart: queryHZ.params.collectTimeStart,
            collectTimeEnd: queryHZ.params.collectTimeEnd,
            siteId: this.queryFormMX.params.siteId,
            timeOptType: queryHZ.params.timeOptType,
            detailyType: queryHZ.params.detailyType
          }
        };
        localStorage.setItem('RDMXQueryForm', JSON.stringify(this.queryFormMX));
        this.getTablePageMX();
      }
    },
    resetClick() {
      this.default();
    },
    // 明细---------------------------------------
    searchClickMX() {
      this.exportMXList = [];
      this.queryFormMX.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('RDMXQueryForm')) || this.queryFormMX;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryFormMX.pageSize,
        params: query.params
      };
      queryReportDetailePage(queryForm).then(res => {
        if (res.code===200) {
          this.mxtableDate = res.data.records;
          this.totalMX = res.data.total;
        }
      }).catch({});
    },
    getTablePageMX() { // 明细表查询
      this.exportMXList = [];
      const queryForm = JSON.parse(localStorage.getItem('RDMXQueryForm')) || this.queryFormMX;
      queryForm.pageNumber = this.queryFormMX.pageNumber;
      queryForm.pageSize = this.queryFormMX.pageSize;
      queryReportDetailePage(queryForm).then(res => {
        if (res.code===200) {
          this.mxtableDate = res.data.records;
          this.totalMX = res.data.total;
        }
      }).catch({});
    },
    choose(selection) { // 多选、全选
      this.exportMXList = selection;
    },
    batchSelectMX(selection, row) {
      this.choose(selection);
    },
    batchSelectAllMX(selection) {
      this.choose(selection);
    },
    downloadLogistics() { // 明细导出
      if (this.exportMXList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      // 导出一次后十秒后才能继续导出
      this.isClick = true;
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        Sheet1.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '009944' }};
        Sheet1.getRow(1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        Sheet1.getRow(1).height = 30;
        Sheet1.getRow(1).font = { bold: true, color: { argb: '000000' }, sz: 30 };
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.views = [
          { state: 'frozen', xSplit: undefined, ySplit: 1 }
        ];
        let Sheet2_data = [];
        Sheet1.columns = [
          {
            header: that.$t('collectionCenter.receiverName'), // '揽收人名称'
            key: 'collectUserName',
            width: 15
          },
          {
            header: that.$t('collectionCenter.lanshouOrderNum'), // '揽收订单号'
            key: 'orderNumber',
            width: 23
          },
          {
            header: that.$t('collectionCenter.Barcode'), // '条形码'
            key: 'scanNumber',
            width: 20
          },
          {
            header: that.$t('collectionCenter.BagNumber'), // '袋号'
            key: 'bagNumber',
            width: 20
          },
          {
            header: that.$t('collectionCenter.lanshouT'), // '揽收时间'
            key: 'collectTime',
            width: 20
          },
          {
            header: that.$t('collectionCenter.sealingBagTime'), // '封袋时间'
            key: 'bagSealingTime',
            width: 20
          },
          {
            header: that.$t('collectionCenter.loadTime'), // '装车时间'
            key: 'loadedTime',
            width: 20
          },
          {
            header: that.$t('collectionCenter.loader'), // '装车人'
            key: 'loadedUserName',
            width: 20
          },
          {
            header: that.$t('collectionCenter.unloader'), // '卸车人'
            key: 'unloadUserName',
            width: 20
          },
          {
            header: that.$t('collectionCenter.unloadTime'), // '卸车时间'
            key: 'unloadTime',
            width: 20
          }
        ];
        Sheet2_data = [];
        that.exportMXList.map((item) => {
          Sheet2_data.push({
            collectUserName: item.collectUserName,
            orderNumber: item.orderNumber,
            scanNumber: item.scanNumber,
            bagNumber: item.bagNumber,
            collectTime: item.collectTime,
            bagSealingTime: item.bagSealingTime,
            loadedTime: item.loadedTime,
            loadedUserName: item.loadedUserName,
            unloadUserName: item.unloadUserName,
            unloadTime: item.unloadTime
          });
        });
        Sheet1.addRows(Sheet2_data);
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        // const url = blob;
        const date = new Date().getTime();
        const saveName = that.$t('collectionCenter.dailyReportsDetailedList') + date + '.xlsx'; // '揽收日报表明细表.xlsx'
        saveAs(blob, saveName);
        // if (typeof url === 'object' && url instanceof Blob) {
        //   url = URL.createObjectURL(url);
        // }
        // const aLink = document.createElement('a');
        // aLink.href = url;
        // aLink.download = saveName || '';
        // let event;
        // if (window.MouseEvent) event = new MouseEvent('click');
        // else {
        //   event = document.createEvent('MouseEvents');
        //   event.initMouseEvent(
        //     'click',
        //     true,
        //     false,
        //     window,
        //     0,
        //     0,
        //     0,
        //     0,
        //     0,
        //     false,
        //     false,
        //     false,
        //     false,
        //     0,
        //     null
        //   );
        // }
        // aLink.dispatchEvent(event);
        return;
      }
      ddd();
      this.exportMXList = [];
      this.$refs.mxTable.clearSelection();
    },
    downloadAllHZ() { // 全部导出——汇总
      this.isClickAll1 = true;
      const queryForm = JSON.parse(localStorage.getItem('RDHZQueryForm')) || this.queryForm;
      queryReportGatherExportAll(queryForm.params).then(res => {
        if (res.code===200) {
          const h = this.$createElement;
          this.$msgbox({
            // '提示'
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
              h('span', this.$t('collectionCenter.exportChangeTip')),
              // '请勿重复导出！'
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // '立即前往'
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // '关闭'
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          }).catch(() => {});
        }
      }).catch({});
    },
    downloadAll() { // 全部导出——明细
      this.isClickAll2 = true;
      const queryForm = JSON.parse(localStorage.getItem('RDMXQueryForm')) || this.queryFormMX;
      queryReportDetaileExportAll(queryForm.params).then(res => {
        if (res.code===200) {
          const h = this.$createElement;
          this.$msgbox({
            // '提示'
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
              h('span', this.$t('collectionCenter.exportChangeTip')),
              // '请勿重复导出！'
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // '立即前往'
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // '关闭'
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          }).catch(() => {});
        }
      }).catch({});
    }
  }
};
</script>

<style lang="scss" scoped>

  .autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
    position: relative;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    visibility: hidden;
    white-space: nowrap;
  }
  .autoWidth ::v-deep input {
    position: absolute;
  }
  .listQueryTime ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .listQueryTime ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .listQueryTime ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .TimeLimitForSigningBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .dialogC ::v-deep .el-select{
    width: 100%;
  }
  .col2 {
    width: auto !important;
  }
  .NumClass ::v-deep textarea.el-textarea__inner {
    max-height: 150px;
  }
  .NumClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .TimeLimitForSigningBox .selectTimeClass ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
  }
  .listQueryTime ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
</style>
