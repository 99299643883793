<template>
  <!-- 应付主体 下拉列表 虚拟列表 -->
  <ElSelectV2
    v-model="value"
    :loading="loading"
    clearable
    :options="list"
    filterable
    :placeholder="$t('operationCenter.PleaseSelect')"
    @change="onChange"
  />
</template>

<script>
import ElSelectV2 from 'el-select-v2';
import { apiProviderPage } from '@/api/finance/basicData';

const param = {
  pageSize: 9999999,
  pageNumber: 1,
  params: {
    state: 1
  }
};

export default {
  name: '',
  components: {
    ElSelectV2
  },

  props: {
    // 应付主体id 用于 回显的内容
    val: {
      type: [Number, String],
      default: ''
    }
  },

  data() {
    return {
      loading: false,
      list: [],
      value: []
    };
  },

  watch: {
    'val': {
      handler() {
        this.value = this.val;
      }
      // immediate: true
    }

  },

  created() {
    setTimeout(() => {
      this.getProviderPage();
    }, 500);
  },

  methods: {
    // 获取应付主体选项
    getProviderPage() {
      this.loading = true;
      apiProviderPage(param, false).then(res => {
        // if (res.code===200) {
        this.list = (res.data.records || []).map(item => {
          const { id, providerBody, providerName, code } = item;
          const obj = {
            label: `${providerBody}(${providerName})(${code})`,
            value: id
          };
          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onChange(val) {
      this.$emit('change', val);
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
