var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { inline: false, "label-position": "top", size: "small" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.LoadCarTime") } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          clearable: false,
                          "default-time": ["00:00:00", "23:59:59"],
                          format:
                            _vm.$i18n.locale == "zh" ? _vm.zhDate : _vm.ptDate,
                          placeholder: _vm.$t("newOrder.SelectDatetime"),
                          "range-separator": "~",
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.timeValue,
                          callback: function ($$v) {
                            _vm.timeValue = $$v
                          },
                          expression: "timeValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "selectType" } },
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          { staticClass: "selectLabel" },
                          [
                            _c(
                              "el-select",
                              {
                                style: _vm.IsWidth,
                                on: { change: _vm.getVehicleTagNumber01 },
                                model: {
                                  value: _vm.queryForm.params.queryType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "queryType",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.queryType",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.licensePlateNumber"
                                    ),
                                    value: 1,
                                  },
                                }),
                                _c("el-option", {
                                  key: "2",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.NIV"),
                                    value: 2,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "remote-method": _vm.getVehicleTagNumber,
                            clearable: "",
                            filterable: "",
                            multiple: "",
                            remote: "",
                            "reserve-keyword": "",
                          },
                          on: {
                            change: _vm.changeVehicleTagNumber,
                            focus: _vm.changeVehicleTagNumber,
                          },
                          model: {
                            value: _vm.queryForm.params.numberList,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "numberList", $$v)
                            },
                            expression: "queryForm.params.numberList",
                          },
                        },
                        _vm._l(_vm.nivNumberList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.Carregador") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            "remote-method": _vm.getOperationUserInfo1,
                            clearable: "",
                            filterable: "",
                            multiple: "",
                            remote: "",
                            "reserve-keyword": "",
                          },
                          on: {
                            change: _vm.changeOperationUserInfo,
                            focus: _vm.changeOperationUserInfo,
                          },
                          model: {
                            value: _vm.queryForm.params.loadUserIdList,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "loadUserIdList",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "queryForm.params.loadUserIdList",
                          },
                        },
                        _vm._l(_vm.userList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("customerManagements.loadSiteIdList"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            "remote-method": _vm.getSiteList,
                            clearable: "",
                            filterable: "",
                            multiple: "",
                            remote: "",
                            "reserve-keyword": "",
                          },
                          on: {
                            change: _vm.changeGetSiteList1,
                            focus: _vm.changeGetSiteList1,
                          },
                          model: {
                            value: _vm.queryForm.params.loadSiteIdList,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "loadSiteIdList",
                                $$v
                              )
                            },
                            expression: "queryForm.params.loadSiteIdList",
                          },
                        },
                        _vm._l(_vm.siteList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.nextStation") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "remote-method": _vm.getSiteList,
                            clearable: "",
                            filterable: "",
                            multiple: "",
                            remote: "",
                            "reserve-keyword": "",
                          },
                          on: {
                            change: _vm.changeGetSiteList,
                            focus: _vm.changeGetSiteList,
                          },
                          model: {
                            value: _vm.queryForm.params.nextSiteIdList,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "nextSiteIdList",
                                $$v
                              )
                            },
                            expression: "queryForm.params.nextSiteIdList",
                          },
                        },
                        _vm._l(_vm.siteList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 10 } }, [
                _c(
                  "div",
                  { staticStyle: { "margin-top": "27px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-search",
                          size: "small",
                          type: "primary",
                        },
                        on: { click: _vm.enterSearch },
                      },
                      [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { icon: "el-icon-upload2", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.allExportOrder(1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("newOrder.export")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value: "btn:deliveryLoading:airExportOrder",
                            expression: "'btn:deliveryLoading:airExportOrder'",
                            arg: "remove",
                          },
                        ],
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { size: "small" },
                        on: { click: _vm.airExportOrder },
                      },
                      [_vm._v(_vm._s(_vm.$t("GlobalSetObj.exportDIR")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-refresh-left",
                          size: "small",
                          type: "info",
                        },
                        on: { click: _vm.resetForm },
                      },
                      [_vm._v(_vm._s(_vm.$t("newOrder.reset")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-upload2", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.allExportOrder(2)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("newOrder.ExportPackageDetails")) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "header-cell-style": { background: "#f2f2f2", color: "black" },
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.serial", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.serial", "pu") +
                "）",
              align: "center",
              type: "index",
              width: "80",
            },
          }),
          _vm._l(_vm.tableHeaderList, function (item) {
            return _c("el-table-column", {
              key: item.id,
              attrs: {
                label: item.label,
                "min-width": item.cloWidth,
                prop: item.prop,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        item.prop === "sumIsUnload"
                          ? _c("div", [
                              scope.row["sumIsUnload"] === 1
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("GlobalSetObj.yes", "zh") +
                                          "（" +
                                          _vm.$t("GlobalSetObj.yes", "pu") +
                                          "）"
                                      )
                                    ),
                                  ])
                                : _c(
                                    "div",
                                    { staticStyle: { color: "#f56c6c" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("GlobalSetObj.No", "zh") +
                                            "（" +
                                            _vm.$t("GlobalSetObj.No", "pu") +
                                            "）"
                                        )
                                      ),
                                    ]
                                  ),
                            ])
                          : _c(
                              "div",
                              {
                                class:
                                  _vm.strARR.includes(item.prop) &&
                                  scope.row[item.prop] !== "0"
                                    ? "WaybillNumber"
                                    : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.seeDetial01(
                                      scope.row,
                                      scope.row[item.prop],
                                      item.numberType
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row[item.prop]
                                      ? "" + scope.row[item.prop]
                                      : "--"
                                  ) + " "
                                ),
                              ]
                            ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._l(_vm.tableHeaderList1, function (item) {
            return _c("el-table-column", {
              key: item.id,
              attrs: {
                label: item.label,
                "min-width": item.cloWidth,
                prop: item.prop,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            class:
                              _vm.strARR.includes(item.prop) &&
                              scope.row[item.prop] !== "0"
                                ? "WaybillNumber"
                                : "",
                            on: {
                              click: function ($event) {
                                return _vm.seeDetial(
                                  scope.row,
                                  scope.row[item.prop],
                                  item.numberType
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row[item.prop]
                                  ? "" + scope.row[item.prop]
                                  : "--"
                              ) + " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.operate", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.operate", "pu") +
                "）",
              "min-width": "150",
              prop: "varianceNumber",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "WaybillNumber",
                        on: {
                          click: function ($event) {
                            return _vm.seeDetialImage(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("navbar.seeImage", "zh") +
                              "（" +
                              _vm.$t("navbar.seeImage", "pu") +
                              "）"
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.number,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("GlobalSetObj.BagNumbeDetail"),
            visible: _vm.dialogVisible01,
            center: "",
            direction: "ltr",
            size: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible01 = $event
            },
            close: _vm.closeDrawer,
          },
        },
        [
          _vm.dialogVisible01
            ? _c("detail01", {
                ref: "votesDetails",
                attrs: { dataobj: _vm.dataObj },
                on: { "call-parent-method": _vm.parentMethod },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("customerManagements.packageDetail"),
            visible: _vm.dialogVisible,
            center: "",
            direction: "ltr",
            size: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.closeDrawer,
          },
        },
        [
          _vm.dialogVisible
            ? _c("packageDetail", {
                ref: "votesDetails",
                attrs: { dataobj: _vm.dataObj },
                on: { "call-parent-method": _vm.parentMethod },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("navbar.loadImage"),
            visible: _vm.photoSeeRefVisible,
            center: "",
            direction: "ltr",
            size: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.photoSeeRefVisible = $event
            },
            close: _vm.closeDrawer,
          },
        },
        [
          _vm.photoSeeRefVisible === true
            ? _c("photoSee", {
                ref: "photoSeeRef",
                attrs: { dataobj: _vm.dataObj },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }