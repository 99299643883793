<template>
  <div class="app-container" style="height: calc(100vh - 170px);overflow-y: auto;">
    <el-divider>{{ $t('customerManagements.otherInformation') }}</el-divider>
    <el-form ref="formDataOther" :model="formData">
      <el-row :gutter="20">
        <el-col :span="12">
          <!--返回单号规则-->
          <el-form-item
            :label="$t('customerManagements.orderNumberType')"
            :rules="[
              { required: true, message: $t('customerManagements.pleaseInput'), trigger: ['blur', 'change'] }
            ]"
            prop="orderNumberType"
          >
            <el-select
              v-model="formData.orderNumberType"
              :placeholder="$t('customerManagements.default')"
            >
              <el-option :key="1" :value="1" label="AJ" />
              <el-option :key="2" :value="2" label="ANJBR" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div
      v-if="customer.type === 'edit' || customer.type === 'add'"
      slot="footer"
      class="dialog-footer"
      style="text-align: right"
    >
      <el-button @click="clearData()">{{ $t('customerManagements.cancel') }}</el-button>
      <el-button type="primary" @click="onSubmit()">{{ $t('customerManagements.confirm') }}</el-button>
    </div>
  </div>
</template>

<script>
import { apiCustomerEditOther } from '@/api/customer';

export default {
  name: 'OtherInformation',
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      }
    },
    customer: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      formData: {
        orderNumberType: 1
      }
    };
  },
  created() {
    const detailData = this.detailData || {};
    this.formData = {
      orderNumberType: detailData.orderNumberType,
      id: detailData.id
    };
  },
  methods: {
    // 取消
    clearData() {
      this.$emit('updataData', true);
    },
    // 提交
    onSubmit() {
      this.$refs['formDataOther'].validate((valid) => {
        if (valid) {
          this.formData.id = this.detailData.id;
          apiCustomerEditOther(this.formData).then(res => {
            if (res.code===200) {
              this.$message({
                message: this.$t('customerManagements.ModificationSuccessful'),
                type: 'success'
              });
              this.$emit('updataData');
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
