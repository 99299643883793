<template>
  <div>
    <div style="margin-bottom: 20px;" class="selectClass">
      <el-form>
        <el-row class="MonitoringReportTime">
          <el-col class="col2">
            <el-select
              v-model="selectTime" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass autoWidth"
              @change="changeTime"
            >
              <template slot="prefix">
                {{ (timeList.find(item => item.value === selectTime) || {}).time }}
              </template>
              <el-option
                v-for="(item, index) in timeList"
                :key="index"
                :label="item.time"
                :value="item.value"
              />
            </el-select>
          </el-col>
          <!-- 订单创建 'mr0' -->
          <!-- 实际揽收 'mr1' -->
          <el-col :span="10" style="width: auto;">
            <el-form-item class="delMl">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                type="daterange"
                value-format="yyyy-MM-dd"
                :default-time="['00:00:00', '23:59:59']"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                :end-placeholder="$t('collectionCenter.EndTime')"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 客户名称： -->
            <el-form-item :label="$t('collectionCenter.CustomerN')">
              <el-select
                v-model="queryFormTimeHZ.params.customerId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable @focus="getNewestCustomer" @change="changeCustomer"
              >
                <el-option
                  v-for="(item, index) in customerIdList"
                  :key="index"
                  :label="item.username"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 站点： -->
            <el-form-item :label="$t('collectionCenter.site')">
              <el-select
                v-model="queryFormTimeHZ.params.siteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable @focus="getNewestSite"
              >
                <el-option
                  v-for="(item, index) in siteIdList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 揽收司机： -->
            <el-form-item :label="$t('collectionCenter.LanShouDriver')">
              <el-select
                v-model="queryFormTimeHZ.params.scanUserId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable @focus="getNewestScanUser"
              >
                <el-option
                  v-for="(item, index) in allDriverList"
                  :key="index"
                  :label="item.realName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" class="col10" style="width: auto;">
            <!-- 商户号/商户名称： -->
            <el-form-item class="m">
              <el-select
                v-model="merchantChoose" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass autoWidth"
                @change="changeMerchant"
              >
                <template slot="prefix">
                  {{ (merchantCodeOrName.find(item => item.m === merchantChoose) || {}).name }}
                </template>
                <el-option
                  v-for="(item, index) in merchantCodeOrName"
                  :key="index"
                  :label="item.name"
                  :value="item.m"
                />
              </el-select>
              <el-input
                v-model="merchantCodeValue"
                type="textarea"
                @change="changeValue"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--揽收方式：-->
            <el-form-item :label="$t('collectionCenter.lanshouType')">
              <el-select v-model="queryFormTimeHZ.params.collectSource" clearable :placeholder="$t('collectionCenter.pleaseChoose')">
                <!--全部-->
                <el-option key="0" :label="$t('collectionCenter.tudo')" :value="null" />
                <!--上门交件-->
                <el-option key="1" :label="$t('collectionCenter.dropOff')" :value="1" />
                <!--上门揽收-->
                <el-option key="2" :label="$t('collectionCenter.doorPickUp')" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="12">
          <el-button
            v-permit:remove="'btn:collection-center:report:MonitoringReport:time-find'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button>
          <!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:report:MonitoringReport:time-reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button>
          <!-- 重置 -->
          <el-button
            v-if="activeName === 'hz' && timeExportHZ"
            icon="el-icon-upload2"
            size="mini"
            :class="loadUpList.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="exportClickAll"
          >{{ $t('collectionCenter.export') }}</el-button>
          <el-button
            v-if="activeName === 'mx' && timeExportMX"
            icon="el-icon-upload2"
            size="mini"
            :class="loadUpList.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="downloadLogistics"
          >{{ $t('collectionCenter.export') }}</el-button>
          <!-- 导出 -->
          <el-button
            v-if="activeName === 'hz' && timeAllExportHZ"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll1 ? 'onlyClickOnce10' : ''"
            @click="downloadAll"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
          <el-button
            v-if="activeName === 'mx' && timeAllExportMX"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll2 ? 'onlyClickOnce10' : ''"
            @click="downloadAllMX"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
        </el-col>
      </el-row>
    </div>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleTimeClick">
      <!-- 汇总 -->
      <el-tab-pane :label="$t('collectionCenter.summary')" name="hz">
        <div>
          <el-table
            ref="timeHZ"
            :max-height="600"
            border
            show-summary
            :summary-method="getSummaries"
            :data="summaryTableDate"
            @select="batchSelectHZ"
            @select-all="batchSelectAllHZ"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <el-table-column
              min-width="120"
              align="center"
              prop="customerId"
              :label="$t('collectionCenter.clientID')"
            /><!-- 客户编号 -->
            <el-table-column
              min-width="130"
              align="center"
              prop="customerName"
              :label="$t('collectionCenter.CustomerName')"
            /><!-- 客户名称 -->
            <el-table-column
              min-width="100"
              align="center"
              prop="merchantCode"
              :label="$t('collectionCenter.MerchantNum')"
            /><!-- 商户号 -->
            <el-table-column
              min-width="150"
              align="center"
              prop="merchantName"
              :label="$t('collectionCenter.MerchantName')"
            /><!-- 商户名称 -->
            <el-table-column
              min-width="150"
              align="center"
              prop="collectSource"
              :label="$t('collectionCenter.lanshouTypee')"
            ><!-- 揽收方式 -->
              <template slot-scope="scope">
                <span>
                  <!-- 1:drop-off(上门交件)，2:pick-up(上门揽件) -->
                  {{
                    scope.row.collectSource == 1 ? $t('collectionCenter.dropOff') :
                    scope.row.collectSource == 2 ? $t('collectionCenter.doorPickUp') : ''
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              prop="collectWay"
              :label="$t('collectionCenter.collectWay')"
            ><!-- 实际揽收方式 -->
              <template slot-scope="scope">
                <span>
                  <!--  0:APP 1:PC  -->
                  {{
                    scope.row.collectWay == 0 ? 'APP' :
                    scope.row.collectWay == 1 ? 'PC' : ''
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="cacheSTime == 'mr0'"
              min-width="150"
              align="center"
              prop="createTime"
              :label="$t('collectionCenter.orderCreateT')"
            /><!-- 订单创建时间 -->
            <el-table-column
              v-if="cacheSTime == 'mr1'"
              min-width="150"
              align="center"
              prop="scanTime"
              :label="$t('collectionCenter.ActualCollectionTime')"
            /><!-- 实际揽收时间 -->
            <el-table-column
              min-width="200"
              align="center"
              prop="orderCount"
              :label="$t('collectionCenter.TotalNumberOfOrdersReceived')"
            ><!-- 总揽收订单数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row)">{{ scope.row.orderCount }}</el-button>
                <span v-else>{{ scope.row.orderCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              prop="collectedCount"
              align="center"
              :label="$t('collectionCenter.ActualNumberOfOrdersReceived')"
            ><!-- 实际已揽收订单数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, 'collectTimeBool1')">{{ scope.row.collectedCount }}</el-button>
                <span v-else>{{ scope.row.collectedCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              prop="uncollectedCount"
              align="center"
              :label="$t('collectionCenter.NumberOfUnreceivedOrders')"
            ><!-- 未揽收订单数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, 'collectTimeBool2')">{{ scope.row.uncollectedCount }}</el-button>
                <span v-else>{{ scope.row.uncollectedCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="200"
              prop="collectErrorCount"
              align="center"
              :label="$t('collectionCenter.AbnormalNumberOfOrdersReceived')"
            ><!-- 揽收异常订单数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, [5, 7])">{{ scope.row.collectErrorCount }}</el-button>
                <span v-else>{{ scope.row.collectErrorCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              prop="signedInCount"
              align="center"
              :label="$t('collectionCenter.orderNumberHasBeenSigned')"
            ><!-- 已签收订单数 -->
              <template slot-scope="scope">
                <el-button
                  v-if="hzTomx"
                  size="mini"
                  :style="scope.row.signedInCount < scope.row.collectedCount ? 'color:red;font-weight: bold;border: 1px solid red;' : ''"
                  @click="cellClick(scope.row, 'signerTimeBool')"
                >{{ scope.row.signedInCount }}</el-button>
                <span v-else>{{ scope.row.signedInCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              prop="canceledCount"
              align="center"
              :label="$t('collectionCenter.orderNumberHasBeenCancelled')"
            ><!-- 已取消订单数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, [2])">{{ scope.row.canceledCount }}</el-button>
                <span v-else>{{ scope.row.canceledCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              prop="bagSealedCount"
              align="center"
              :label="$t('collectionCenter.SealedBagOrderNumber')"
            ><!-- 已封袋订单数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="cellClick(scope.row, 'bagSealingTimeBool')">{{ scope.row.bagSealedCount }}</el-button>
                <span v-else>{{ scope.row.bagSealedCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="100"
              prop="orderPerDay"
              align="center"
              :label="$t('collectionCenter.orderPerDay')"
            /><!-- 日均下单量 -->
            <el-table-column
              min-width="150"
              align="center"
              :label="$t('collectionCenter.RangeEarningRate')"
            ><!-- 揽收率 -->
              <template slot-scope="scope">
                <div>
                  {{ scope.row.collectSuccessRate }}%
                </div>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              :label="$t('collectionCenter.RateOfSigning')"
            ><!-- 签收率 -->
              <template slot-scope="scope">
                <div>
                  {{ scope.row.signInRate }}%
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryFormTimeHZ.pageNumber"
            :page-sizes="[10, 20, 30, 50, 100, 500, 1000]"
            :page-size.sync="queryFormTimeHZ.pageSize"
            :total="huizongTotal"
            background
            style="margin: 10px 0"
            @current-change="getSummaryDate"
            @size-change="getPageSize"
          />
        </div>
      </el-tab-pane>
      <!-- 明细 -->
      <el-tab-pane v-if="isShowMXTime" :label="$t('collectionCenter.particulars')" name="mx">
        <div>
          <el-table
            ref="timeMX"
            :max-height="600"
            border
            :data="ScheduleTableDate"
            @select="batchSelectMX"
            @select-all="batchSelectAllMX"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <!-- 序号 -->
            <el-table-column
              :label="$t('collectionCenter.ind')" type="index" width="60"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ (queryFormTimeMX.pageNumber - 1) * queryFormTimeMX.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="merchantCode"
              :label="$t('collectionCenter.MerchantNum')"
            /><!-- 商户号 -->
            <el-table-column
              prop="merchantName"
              :label="$t('collectionCenter.MerchantName')"
            /><!-- 商户名称 -->
            <el-table-column
              prop="orderNumber"
              :label="$t('collectionCenter.lanshouOrderNum')"
            /><!-- 揽收订单号 -->
            <el-table-column
              prop="collectListNumber"
              :label="$t('collectionCenter.receivingListNum')"
            /><!-- 揽收清单号 -->
            <el-table-column
              prop="packageNumber"
              :label="$t('collectionCenter.parcelNum')"
            /><!-- 包裹号 -->
            <el-table-column
              prop="scanNumber"
              :label="$t('collectionCenter.Barcode')"
            /><!-- 条形码 -->
            <el-table-column
              prop="bagNumber"
              :label="$t('collectionCenter.BagNumber')"
            /><!-- 袋号 -->
            <el-table-column
              prop="siteName"
              :label="$t('collectionCenter.collectionDot')"
            /><!-- 揽收网点 -->
            <el-table-column
              prop="driverName"
              :label="$t('collectionCenter.LanShouPeople')"
            /><!-- 揽收人 -->
            <el-table-column
              prop="scanTime"
              :label="$t('collectionCenter.lanshouT')"
            /><!-- 揽收时间 -->
          </el-table>
        </div>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryFormTimeMX.pageNumber"
            :page-sizes="[10, 20, 30, 50, 100, 500, 1000]"
            :page-size.sync="queryFormTimeMX.pageSize"
            :total="ScheduleTotal"
            background
            style="margin: 10px 0"
            @current-change="newQueryPageNum"
            @size-change="newQueryPageSize"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  searchSiteIdList,
  scanUserList,
  // driverAll,
  queryAllCustomer,
  collectOrderCountQueryPage,
  collectOrderCountQueryDetailPage,
  findListOrderDetailExportAll,
  exportAll
  // collectOrderCountExport,
  // findListOrderDetailExport
} from '@/api/lanshou';
import ExcelJS from 'exceljs';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';
import { saveAs } from 'file-saver';

export default {
  data() {
    return {
      isClickAll1: false,
      isClickAll2: false,
      isClick: false,
      selectTime: 'mr0', // 选择期望揽收时间 / 实际揽收时间
      cacheSTime: '', // 缓存切换的时间
      timeList: [
        { value: 'mr0', time: this.$t('collectionCenter.orderCreateT') }, // '订单创建时间'
        { value: 'mr1', time: this.$t('collectionCenter.ActualCollectionTime') } // '实际揽收时间'
      ],
      timeExportHZ: this.$store.getters.button_permissions.includes('btn:collection-center:report:MonitoringReport:time-export-hz'),
      timeExportMX: this.$store.getters.button_permissions.includes('btn:collection-center:report:MonitoringReport:time-export-mx'),
      timeAllExportHZ: this.$store.getters.button_permissions.includes('btn:collection-center:report:MonitoringReport:time-allExport-hz'),
      timeAllExportMX: this.$store.getters.button_permissions.includes('btn:collection-center:report:MonitoringReport:time-allExport-mx'),
      hzTomx: this.$store.getters.button_permissions.includes('btn:collection-center:report:MonitoringReport:time-hzTomx'),
      timeValue: [],
      findTimeClick: 0, // 按时间查询————明细表是否点击查询按钮 0未点击  1已点击
      loadUpList: [], // 导出数据
      merchantChoose: 'm2', // 选择输入商户号还是商户名称
      merchantCodeOrName: [
        { m: 'm1', name: this.$t('collectionCenter.MerchantNum') }, // '商户号'
        { m: 'm2', name: this.$t('collectionCenter.MerchantName') } // '商户名称'
      ],
      // 按时间范围----------------------------------------------------
      isShowMXTime: false, // 控制明细表显示
      activeName: 'hz', // hz 汇总   mx 明细
      siteIdList: [], // 站点编号列表
      allDriverList: [], // 揽收司机列表
      customerIdList: [], // 客户名称列表
      queryFormTimeHZ: { // 汇总查询条件
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: '', // 开始时间
          endTime: '', // 结束时间
          collectStartTime: '', // 揽收初始时间
          collectEndTime: '', // 揽收终止时间
          siteId: '', // 站点id
          scanUserId: '', // 司机id
          collectSource: '', // 揽收方式
          customerId: '', // 客户id
          merchantCodes: [], // 商户号
          merchantNames: [] // 商户名称
        }
      },
      merchantCodeValue: '',
      huizongTotal: 0, // 汇总——数量
      summaryTableDate: [], // 汇总表单
      tableCustomerIdList: [], // 汇总——导出——选中的数据id
      tableIdList: [], // 按时间范围——明细——导出——选中的数据id

      queryFormTimeMX: { // 明细查询条件
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: '', // 开始时间
          endTime: '', // 结束时间
          collectStartTime: '', // 揽收初始时间
          collectEndTime: '', // 揽收终止时间
          siteId: '', // 站点id
          scanUserId: '', // 司机id
          customerId: '', // 客户id
          merchantCodes: [], // 商户号
          merchantNames: [], // 商户名称
          orderNumber: '',
          listNumber: '',
          packageNumber: '',
          collectstatusList: [],
          sourceType: '',
          customerCollectType: ''
        }
      },
      ScheduleTableDate: [], // 按时间查询——明细表
      ScheduleTotal: 0
    };
  },
  created() {
    this.getNewestSite();
    this.getNewestScanUser();
    this.getNewestCustomer();
    this.default();
    this.searchClick();
    this.isClick = false;
    this.isClickAll1 = false;
    this.isClickAll2 = false;
  },
  methods: {
    changeTime() { // 切换所选时间——清空未选时间
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryFormTimeHZ.params.beginTime = '';
      this.queryFormTimeHZ.params.endTime = '';
      this.queryFormTimeHZ.params.collectStartTime = '';
      this.queryFormTimeHZ.params.collectEndTime = '';
      if (this.selectTime === 'mr0') {
        this.queryFormTimeHZ.params.beginTime = today + ' 00:00:00';
        this.queryFormTimeHZ.params.endTime = today + ' 23:59:59';
      } else {
        this.queryFormTimeHZ.params.collectStartTime = today + ' 00:00:00';
        this.queryFormTimeHZ.params.collectEndTime = today + ' 23:59:59';
      }
    },
    getNewestSite() {
      searchSiteIdList().then(res => {
        if (res.code===200) {
          this.siteIdList = [];
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    getNewestScanUser() {
      const form = {};
      scanUserList(form).then(res => {
        if (res.code===200) {
          this.allDriverList = [];
          res.data.forEach(data => {
            this.allDriverList.push({ id: data.id, realName: data.realName });
          });
        }
      });
    },
    getNewestCustomer() {
      queryAllCustomer().then(res => {
        if (res.code===200) {
          this.customerIdList = [];
          res.data.forEach((list) => {
            this.customerIdList.push({
              id: list.id,
              username: list.username
            });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryFormTimeHZ.params.beginTime = '';
        this.queryFormTimeHZ.params.endTime = '';
        this.queryFormTimeHZ.params.collectStartTime = '';
        this.queryFormTimeHZ.params.collectEndTime = '';
        return;
      }
      if (this.selectTime === 'mr0') {
        this.queryFormTimeHZ.params.beginTime = val[0] + ' 00:00:00';
        this.queryFormTimeHZ.params.endTime = val[1] + ' 23:59:59';
        if (this.queryFormTimeHZ.params.endTime) {
          const endTime = dayjs(this.queryFormTimeHZ.params.beginTime).subtract(-1, 'month').format('YYYY-MM-DD') + ' 23:59:59';
          if (this.queryFormTimeHZ.params.endTime > endTime) {
            this.$message({
              message: this.$t('collectionCenter.OnlyAllowedOneMonthsOfData'), // '只允许查1个月内的数据'
              type: 'warning'
            });
            const d = new Date();
            const today = dayjs(d).format('YYYY-MM-DD');
            this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
            this.queryFormTimeHZ.params.beginTime = today + ' 00:00:00';
            this.queryFormTimeHZ.params.endTime = today + ' 23:59:59';
            this.queryFormTimeHZ.params.collectStartTime = '';
            this.queryFormTimeHZ.params.collectEndTime = '';
          }
        }
      } else {
        this.queryFormTimeHZ.params.collectStartTime = val[0] + ' 00:00:00';
        this.queryFormTimeHZ.params.collectEndTime = val[1] + ' 23:59:59';
        if (this.queryFormTimeHZ.params.collectEndTime) {
          const endTime = dayjs(this.queryFormTimeHZ.params.beginTime).subtract(-1, 'month').format('YYYY-MM-DD') + ' 23:59:59';
          if (this.queryFormTimeHZ.params.collectEndTime > endTime) {
            this.$message({
              message: this.$t('collectionCenter.OnlyAllowedOneMonthsOfData'), // '只允许查1个月内的数据'
              type: 'warning'
            });
            const d = new Date();
            const today = dayjs(d).format('YYYY-MM-DD');
            this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
            this.queryFormTimeHZ.params.beginTime = '';
            this.queryFormTimeHZ.params.endTime = '';
            this.queryFormTimeHZ.params.collectStartTime = today + ' 00:00:00';
            this.queryFormTimeHZ.params.collectEndTime = today + ' 23:59:59';
          }
        }
      }
    },

    // --------------------------按时间范围查询--------------------------------
    changeCustomer() { // 切换客户名称自动清空商户号/名称
      this.merchantCodeValue = '';
      this.queryFormTimeHZ.params.merchantCodes = [];
      this.queryFormTimeHZ.params.merchantNames = [];
      this.queryFormTimeMX.params.customerId = '';
    },
    changeMerchant() { // 切换商户号/名称 清空输入框
      this.merchantCodeValue = '';
      this.queryFormTimeHZ.params.merchantCodes = [];
      this.queryFormTimeHZ.params.merchantNames = [];
      this.queryFormTimeMX.params.merchantCodes = [];
      this.queryFormTimeMX.params.merchantNames = [];
    },
    changeValue(value) { // 商户号/名称 赋值
      if (!this.queryFormTimeHZ.params.customerId) {
        this.merchantCodeValue = '';
        this.$message.warning(this.$t('collectionCenter.chooseCustomerName')); // '请选择客户名称'
        return;
      }
      if (!this.merchantCodeValue) {
        this.queryFormTimeHZ.params.merchantCodes = [];
        this.queryFormTimeHZ.params.merchantNames = [];
        this.queryFormTimeMX.params.merchantCodes = [];
        this.queryFormTimeMX.params.merchantNames = [];
        return;
      }
      if (!value) return;
      const val = value.split('\n');
      if (val.length <= 100) {
        if (this.merchantChoose === 'm1') {
          this.queryFormTimeMX.params.merchantCodes = val;
          this.queryFormTimeHZ.params.merchantCodes = val;
        } else {
          this.queryFormTimeMX.params.merchantNames = val;
          this.queryFormTimeHZ.params.merchantNames = val;
        }
      } else {
        const filterVal = val.filter(function(f) {
          return f && f.trim();
        });
        const merchantCodeValue = filterVal.slice(0, 100);
        this.merchantCodeValue = merchantCodeValue.join('\n');
        if (this.merchantChoose === 'm1') {
          this.queryFormTimeHZ.params.merchantCodes = filterVal.slice(0, 100);
          this.queryFormTimeMX.params.merchantCodes = filterVal.slice(0, 100);
        } else {
          this.queryFormTimeHZ.params.merchantNames = filterVal.slice(0, 100);
          this.queryFormTimeMX.params.merchantNames = filterVal.slice(0, 100);
        }
        this.$message.warning(this.$t('collectionCenter.choose100')); // '单次最多可选100个，已截取前100个商户'
      }
    },
    default() { // 汇总默认查询条件
      this.selectTime = 'mr0';
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryFormTimeHZ = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: today + ' 00:00:00', // 开始时间
          endTime: today + ' 23:59:59', // 结束时间
          collectStartTime: '', // 揽收初始时间
          collectEndTime: '', // 揽收终止时间
          siteId: '', // 站点id
          scanUserId: '', // 用户id
          collectSource: '', // 揽收方式
          customerId: '', // 客户id
          merchantCodes: [], // 商户号
          merchantNames: [] // 商户名称
        }
      };
      this.merchantCodeValue = '';
    },
    getPageSize() {
      const params = JSON.parse(localStorage.getItem('MRTimeHZQueryForm')) || this.queryFormTimeHZ.params;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryFormTimeHZ.pageSize,
        params
      };
      collectOrderCountQueryPage(queryForm).then(res => {
        if (res.code===200) {
          this.summaryTableDate = res.data.records;
          this.huizongTotal = res.data.total;
        }
      }).catch({});
    },
    getSummaryDate() { // 汇总表单
      this.loadUpList = [];
      this.tableCustomerIdList = [];
      const selectTime = JSON.parse(localStorage.getItem('selectTime')) || this.selectTime;
      if ((selectTime === 'mr0' && (!this.queryFormTimeHZ.params.beginTime || !this.queryFormTimeHZ.params.endTime)) ||
        (selectTime === 'mr1' && (!this.queryFormTimeHZ.params.collectStartTime || !this.queryFormTimeHZ.params.collectEndTime))) {
        // 请选择日期时间
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
      } else {
        const params = JSON.parse(localStorage.getItem('MRTimeHZQueryForm')) || this.queryFormTimeHZ.params;
        const queryForm = {
          pageNumber: this.queryFormTimeHZ.pageNumber,
          pageSize: this.queryFormTimeHZ.pageSize,
          params
        };
        collectOrderCountQueryPage(queryForm).then(res => {
          if (res.code===200) {
            this.summaryTableDate = res.data.records;
            this.huizongTotal = res.data.total;
          }
        }).catch({});
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.summary');
          return;
        }
        if (column.label === this.$t('collectionCenter.TotalNumberOfOrdersReceived') ||
            column.label === this.$t('collectionCenter.ActualNumberOfOrdersReceived') ||
            column.label === this.$t('collectionCenter.NumberOfUnreceivedOrders') ||
            column.label === this.$t('collectionCenter.AbnormalNumberOfOrdersReceived') ||
            column.label === this.$t('collectionCenter.orderNumberHasBeenSigned') ||
            column.label === this.$t('collectionCenter.orderNumberHasBeenCancelled') ||
            column.label === this.$t('collectionCenter.SealedBagOrderNumber')) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    },
    searchClick() { // 汇总、明细查询
      localStorage.setItem('selectTime', JSON.stringify(this.selectTime));
      this.cacheSTime = JSON.parse(localStorage.getItem('selectTime')) || this.selectTime;
      if (this.activeName === 'hz') {
        this.tableCustomerIdList = [];
        this.queryFormTimeHZ.pageNumber = 1;
        const HZMerchantCodesLength = this.queryFormTimeHZ.params.merchantCodes.length;
        const HZMerchantNamesLength = this.queryFormTimeHZ.params.merchantNames.length;
        this.queryFormTimeHZ.params.merchantCodes = HZMerchantCodesLength > 0 ? this.queryFormTimeHZ.params.merchantCodes : [];
        this.queryFormTimeHZ.params.merchantNames = HZMerchantNamesLength > 0 ? this.queryFormTimeHZ.params.merchantNames : [];
        localStorage.setItem('MRTimeHZQueryForm', JSON.stringify(this.queryFormTimeHZ.params));
        this.getSummaryDate();
      }
      if (this.activeName === 'mx') {
        this.queryFormTimeMX.params.scanUserId = '';
        this.tableIdList = [];
        this.findTimeClick = 1;
        if (this.queryFormTimeHZ.params.customerId) {
          this.queryFormTimeMX.pageNumber = 1;
          this.queryFormTimeMX.params.siteId = this.queryFormTimeHZ.params.siteId;
          this.queryFormTimeMX.params.customerId = this.queryFormTimeHZ.params.customerId;
          this.queryFormTimeMX.params.merchantCodes = this.queryFormTimeHZ.params.merchantCodes;
          this.queryFormTimeMX.params.merchantNames = this.queryFormTimeHZ.params.merchantNames;
          this.queryFormTimeMX.params.beginTime = this.queryFormTimeHZ.params.beginTime;
          this.queryFormTimeMX.params.endTime = this.queryFormTimeHZ.params.endTime;
          this.queryFormTimeMX.params.collectStartTime = this.queryFormTimeHZ.params.collectStartTime;
          this.queryFormTimeMX.params.collectEndTime = this.queryFormTimeHZ.params.collectEndTime;
          localStorage.setItem('MRTimeMXQueryForm', JSON.stringify(this.queryFormTimeMX.params));
          this.getSchedulePage();
        } else {
          this.$message({
            message: this.$t('collectionCenter.chooseCustomerName'),
            type: 'warning'
          });
        }
      }
    },
    resetClick() {
      this.default();
    },
    handleTimeClick() {
      if (this.activeName === 'hz') {
        localStorage.setItem('MRTimeMXQueryForm', '');
        this.isShowMXTime = false;
        this.findTimeClick = 0;
        this.queryFormTimeMX.pageNumber = 1;
      }
    },
    newQueryPageNum() {
      this.loadUpList = [];
      // let queryFormTimeMX = {};
      if (this.findTimeClick === 1) {
        this.queryFormTimeMX = {
          pageNumber: this.queryFormTimeMX.pageNumber,
          pageSize: this.queryFormTimeMX.pageSize,
          params: {
            beginTime: this.queryFormTimeHZ.params.beginTime, // 开始时间
            endTime: this.queryFormTimeHZ.params.endTime, // 结束时间
            collectStartTime: this.queryFormTimeHZ.params.collectStartTime,
            collectEndTime: this.queryFormTimeHZ.params.collectEndTime,
            siteId: this.queryFormTimeHZ.params.siteId, // 站点id
            scanUserId: this.queryFormTimeHZ.params.scanUserId, // 司机id
            customerId: this.queryFormTimeHZ.params.customerId || this.queryFormTimeMX.params.customerId, // 客户id
            merchantCodes: this.queryFormTimeHZ.params.merchantCodes, // 商户号
            merchantNames: this.queryFormTimeHZ.params.merchantNames, // 商户名称
            orderNumber: '',
            listNumber: '',
            packageNumber: '',
            collectstatusList: this.queryFormTimeMX.params.collectstatusList,
            sourceType: this.queryFormTimeMX.params.sourceType,
            customerCollectType: this.queryFormTimeMX.params.customerCollectType
          }
        };
      } else {
        this.queryFormTimeMX = {
          pageNumber: this.queryFormTimeMX.pageNumber,
          pageSize: this.queryFormTimeMX.pageSize,
          params: {
            beginTime: this.queryFormTimeMX.params.beginTime, // 开始时间
            endTime: this.queryFormTimeMX.params.endTime, // 结束时间
            collectStartTime: this.queryFormTimeMX.params.collectStartTime,
            collectEndTime: this.queryFormTimeMX.params.collectEndTime,
            siteId: this.queryFormTimeHZ.params.siteId, // 站点id
            scanUserId: this.queryFormTimeHZ.params.scanUserId, // 司机id
            customerId: this.queryFormTimeMX.params.customerId, // 客户id
            merchantCodes: this.queryFormTimeMX.params.merchantCodes, // 商户号
            merchantNames: this.queryFormTimeMX.params.merchantNames, // 商户名称
            orderNumber: '',
            listNumber: '',
            packageNumber: '',
            collectstatusList: this.queryFormTimeMX.params.collectstatusList,
            sourceType: this.queryFormTimeMX.params.sourceType,
            customerCollectType: this.queryFormTimeMX.params.customerCollectType
          }
        };
      }
      collectOrderCountQueryDetailPage(this.queryFormTimeMX).then(res => {
        if (res.code===200) {
          this.ScheduleTableDate = res.data.records;
          this.ScheduleTotal = res.data.total;
        }
      }).catch({});
    },
    newQueryPageSize() {
      this.queryFormTimeMX.pageNumber = 1;
      this.newQueryPageNum();
    },
    cellClick(row, ind) { // 汇总切换到 ——> 明细
      this.queryFormTimeMX.params.merchantCodes = [];
      this.queryFormTimeMX.params.merchantNames = [];
      const merchantCodeRow = row.merchantCode == null ? '' : row.merchantCode;
      const merchantNameRow = row.merchantName == null ? '' : row.merchantName;
      this.loadUpList = [];
      this.activeName = 'mx';
      this.isShowMXTime = true;
      this.queryFormTimeMX.params.customerId = row.customerId;
      this.queryFormTimeMX.params.merchantCodes.push(merchantCodeRow);
      this.queryFormTimeMX.params.merchantNames.push(merchantNameRow);
      if (ind === 'collectTimeBool1') {
        this.queryFormTimeMX.params.collectTimeBool = 'false';
        this.queryFormTimeMX.params.signerTimeBool = '';
        this.queryFormTimeMX.params.bagSealingTimeBool = '';
        this.queryFormTimeMX.params.collectstatusList = [];
      } else if (ind === 'collectTimeBool2') {
        this.queryFormTimeMX.params.collectTimeBool = 'true';
        this.queryFormTimeMX.params.signerTimeBool = '';
        this.queryFormTimeMX.params.bagSealingTimeBool = '';
        this.queryFormTimeMX.params.collectstatusList = [];
      } else if (ind === 'signerTimeBool') {
        this.queryFormTimeMX.params.signerTimeBool = 'false';
        this.queryFormTimeMX.params.collectTimeBool = '';
        this.queryFormTimeMX.params.bagSealingTimeBool = '';
        this.queryFormTimeMX.params.collectstatusList = [];
      } else if (ind === 'bagSealingTimeBool') {
        this.queryFormTimeMX.params.bagSealingTimeBool = 'false';
        this.queryFormTimeMX.params.collectTimeBool = '';
        this.queryFormTimeMX.params.signerTimeBool = '';
        this.queryFormTimeMX.params.collectstatusList = [];
      } else if (ind) {
        this.queryFormTimeMX.params.collectstatusList = ind;
        this.queryFormTimeMX.params.collectTimeBool = '';
        this.queryFormTimeMX.params.signerTimeBool = '';
        this.queryFormTimeMX.params.bagSealingTimeBool = '';
      } else {
        this.queryFormTimeMX.params.collectTimeBool = '';
        this.queryFormTimeMX.params.signerTimeBool = '';
        this.queryFormTimeMX.params.bagSealingTimeBool = '';
      }
      this.getSchedulePage(row);
    },
    SelectHZ(selection) {
      this.loadUpList = [];
      this.loadUpList = selection;
      const tCollectIdList = [];
      selection.forEach(list => {
        tCollectIdList.push(list.customerId);
      });
      this.tableCustomerIdList = tCollectIdList;
    },
    batchSelectHZ(selection, row) { // 汇总——多选
      this.SelectHZ(selection);
    },
    batchSelectAllHZ(selection) { // 汇总——全选
      this.SelectHZ(selection);
    },
    // 下载揽收监控报表替换模板
    exportClickAll() { // 汇总、单号导出
      this.isClick = true;
      if (this.loadUpList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        Sheet1.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '009944' }};
        Sheet1.getRow(1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        Sheet1.getRow(1).height = 30;
        Sheet1.getRow(1).font = { bold: true, color: { argb: '000000' }, sz: 30 };
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.views = [
          { state: 'frozen', xSplit: undefined, ySplit: 1 }
        ];
        let Sheet1_data = [];
        const selectTime = JSON.parse(localStorage.getItem('selectTime')) || this.selectTime;
        if (selectTime === 'mr0') {
          Sheet1.columns = [
            {
              header: that.$t('collectionCenter.clientID'), // '客户编号'
              key: 'customerId',
              width: 15
            },
            {
              header: that.$t('collectionCenter.MerchantNum'), // '商户号'
              key: 'merchantCode',
              width: 15
            },
            {
              header: that.$t('collectionCenter.lanshouTypee'), // '揽收方式'
              key: 'collectSource',
              width: 15
            },
            {
              header: that.$t('collectionCenter.collectWay'), // '实际揽收方式'
              key: 'collectWay',
              width: 15
            },
            {
              header: that.$t('collectionCenter.orderCreateT'), // '订单创建时间'
              key: 'createTime',
              width: 15
            },
            {
              header: that.$t('collectionCenter.TotalNumberOfOrdersReceived'), // '总揽收订单数'
              key: 'orderCount',
              width: 15
            },
            {
              header: that.$t('collectionCenter.ActualNumberOfOrdersReceived'), // '实际已揽收订单数'
              key: 'collectedCount',
              width: 15
            },
            {
              header: that.$t('collectionCenter.NumberOfUnreceivedOrders'), // '未揽收订单数'
              key: 'uncollectedCount',
              width: 15
            },
            {
              header: that.$t('collectionCenter.AbnormalNumberOfOrdersReceived'), // '揽收异常订单数'
              key: 'collectErrorCount',
              width: 15
            },
            {
              header: that.$t('collectionCenter.orderNumberHasBeenSigned'), // '已签收订单数'
              key: 'signedInCount',
              width: 15
            },
            {
              header: that.$t('collectionCenter.orderNumberHasBeenCancelled'), // '已取消订单数'
              key: 'canceledCount',
              width: 15
            },
            {
              header: that.$t('collectionCenter.SealedBagOrderNumber'), // '已封袋订单数'
              key: 'bagSealedCount',
              width: 15
            },
            {
              header: that.$t('collectionCenter.orderPerDay'), // '日均下单量'
              key: 'orderPerDay',
              width: 15
            },
            {
              header: that.$t('collectionCenter.RangeEarningRate'), // '揽收率'
              key: 'collectSuccessRate',
              width: 15
            },
            {
              header: that.$t('collectionCenter.RateOfSigning'), // '签收率'
              key: 'signInRate',
              width: 15
            }
          ];
        } else {
          Sheet1.columns = [
            {
              header: that.$t('collectionCenter.clientID'), // '客户编号'
              key: 'customerId',
              width: 15
            },
            {
              header: that.$t('collectionCenter.MerchantNum'), // '商户号'
              key: 'merchantCode',
              width: 15
            },
            {
              header: that.$t('collectionCenter.lanshouTypee'), // '揽收方式'
              key: 'collectSource',
              width: 15
            },
            {
              header: that.$t('collectionCenter.collectWay'), // '实际揽收方式'
              key: 'collectWay',
              width: 15
            },
            {
              header: that.$t('collectionCenter.ActualCollectionTime'), // '实际揽收时间'
              key: 'scanTime',
              width: 15
            },
            {
              header: that.$t('collectionCenter.TotalNumberOfOrdersReceived'), // '总揽收订单数'
              key: 'orderCount',
              width: 15
            },
            {
              header: that.$t('collectionCenter.ActualNumberOfOrdersReceived'), // '实际已揽收订单数'
              key: 'collectedCount',
              width: 15
            },
            {
              header: that.$t('collectionCenter.NumberOfUnreceivedOrders'), // '未揽收订单数'
              key: 'uncollectedCount',
              width: 15
            },
            {
              header: that.$t('collectionCenter.AbnormalNumberOfOrdersReceived'), // '揽收异常订单数'
              key: 'collectErrorCount',
              width: 15
            },
            {
              header: that.$t('collectionCenter.orderNumberHasBeenSigned'), // '已签收订单数'
              key: 'signedInCount',
              width: 15
            },
            {
              header: that.$t('collectionCenter.orderNumberHasBeenCancelled'), // '已取消订单数'
              key: 'canceledCount',
              width: 15
            },
            {
              header: that.$t('collectionCenter.SealedBagOrderNumber'), // '已封袋订单数'
              key: 'bagSealedCount',
              width: 15
            },
            {
              header: that.$t('collectionCenter.orderPerDay'), // '日均下单量'
              key: 'orderPerDay',
              width: 15
            },
            {
              header: that.$t('collectionCenter.RangeEarningRate'), // '揽收率'
              key: 'collectSuccessRate',
              width: 15
            },
            {
              header: that.$t('collectionCenter.RateOfSigning'), // '签收率'
              key: 'signInRate',
              width: 15
            }
          ];
        }
        Sheet1_data = [];
        that.loadUpList.map((item) => {
          if (selectTime === 'mr0') {
            Sheet1_data.push({
              customerId: item.customerId,
              merchantCode: item.merchantCode,
              collectSource: item.collectSource === 1 ? that.$t('collectionCenter.dropOff') : item.collectSource === 2 ? that.$t('collectionCenter.doorPickUp') : '',
              collectWay: item.collectWay === 0 ? 'APP' : item.collectWay === 1 ? 'PC' : '',
              createTime: item.createTime,
              orderCount: item.orderCount,
              collectedCount: item.collectedCount,
              uncollectedCount: item.uncollectedCount,
              collectErrorCount: item.collectErrorCount,
              signedInCount: item.signedInCount,
              canceledCount: item.canceledCount,
              bagSealedCount: item.bagSealedCount,
              orderPerDay: item.orderPerDay,
              collectSuccessRate: item.collectSuccessRate + '%',
              signInRate: item.signInRate + '%'
            });
          } else {
            Sheet1_data.push({
              customerId: item.customerId,
              merchantCode: item.merchantCode,
              collectSource: item.collectSource === 1 ? that.$t('collectionCenter.dropOff') : item.collectSource === 2 ? that.$t('collectionCenter.doorPickUp') : '',
              collectWay: item.collectWay === 0 ? 'APP' : item.collectWay === 1 ? 'PC' : '',
              scanTime: item.scanTime,
              orderCount: item.orderCount,
              collectedCount: item.collectedCount,
              uncollectedCount: item.uncollectedCount,
              collectErrorCount: item.collectErrorCount,
              signedInCount: item.signedInCount,
              canceledCount: item.canceledCount,
              bagSealedCount: item.bagSealedCount,
              orderPerDay: item.orderPerDay,
              collectSuccessRate: item.collectSuccessRate + '%',
              signInRate: item.signInRate + '%'
            });
          }
        });
        Sheet1.addRows(Sheet1_data);
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        // let url = blob;
        const date = new Date().getTime();
        const saveName = that.$t('collectionCenter.collectXlsx') + date + '.xlsx'; // '订单揽收监控报表汇总.xlsx'
        saveAs(blob, saveName);
        return;
      }
      ddd();
      this.loadUpList = [];
      this.$refs.timeHZ.clearSelection();
    },
    downloadLogistics() { // 明细导出
      this.isClick = true;
      if (this.loadUpList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        Sheet1.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '009944' }};
        Sheet1.getRow(1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        Sheet1.getRow(1).height = 30;
        Sheet1.getRow(1).font = { bold: true, color: { argb: '000000' }, sz: 30 };
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.views = [
          { state: 'frozen', xSplit: undefined, ySplit: 1 }
        ];
        let Sheet2_data = [];
        Sheet1.columns = [
          {
            header: that.$t('collectionCenter.MerchantNum'), // '商户号'
            key: 'merchantCode',
            width: 15
          },
          {
            header: that.$t('collectionCenter.lanshouOrderNum'), // '揽收订单号'
            key: 'orderNumber',
            width: 30
          },
          {
            header: that.$t('collectionCenter.receivingListNum'), // '揽收清单号'
            key: 'collectListNumber',
            width: 30
          },
          {
            header: that.$t('collectionCenter.parcelNum'), // '包裹号'
            key: 'packageNumber',
            width: 20
          },
          {
            header: that.$t('collectionCenter.Barcode'), // '条形码'
            key: 'scanNumber',
            width: 20
          },
          {
            header: that.$t('collectionCenter.BagNumber'), // '袋号'
            key: 'bagNumber',
            width: 15
          },
          {
            header: that.$t('collectionCenter.collectionDot'), // '揽收网点'
            key: 'siteName',
            width: 15
          },
          {
            header: that.$t('collectionCenter.LanShouPeople'), // '揽收人'
            key: 'driverName',
            width: 15
          },
          {
            header: that.$t('collectionCenter.lanshouT'), // '揽收时间'
            key: 'scanTime',
            width: 20
          }
        ];
        Sheet2_data = [];
        that.loadUpList.map((item) => {
          Sheet2_data.push({
            merchantCode: item.merchantCode,
            orderNumber: item.orderNumber,
            collectListNumber: item.collectListNumber,
            packageNumber: item.packageNumber,
            scanNumber: item.scanNumber,
            bagNumber: item.bagNumber,
            siteName: item.siteName,
            driverName: item.driverName,
            scanTime: item.scanTime
          });
        });
        Sheet1.addRows(Sheet2_data);
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        // let url = blob;
        const date = new Date().getTime();
        const saveName = that.$t('collectionCenter.detailXlsx') + date + '.xlsx'; // '订单揽收监控报表明细.xlsx'
        saveAs(blob, saveName);
        // if (typeof url === 'object' && url instanceof Blob) {
        //   url = URL.createObjectURL(url);
        // }
        // const aLink = document.createElement('a');
        // aLink.href = url;
        // aLink.download = saveName || '';
        // let event;
        // if (window.MouseEvent) event = new MouseEvent('click');
        // else {
        //   event = document.createEvent('MouseEvents');
        //   event.initMouseEvent(
        //     'click',
        //     true,
        //     false,
        //     window,
        //     0,
        //     0,
        //     0,
        //     0,
        //     0,
        //     false,
        //     false,
        //     false,
        //     false,
        //     0,
        //     null
        //   );
        // }
        // aLink.dispatchEvent(event);
        return;
      }
      ddd();
      this.loadUpList = [];
      this.$refs.timeMX.clearSelection();
    },

    getSchedulePage(row) {
      let merchantCodes = [];
      let merchantNames = [];
      let beginTime = '';
      let endTime = '';
      let collectStartTime = ''; // 揽收初始时间
      let collectEndTime = ''; // 揽收终止时间
      let customerId = '';
      let merchantCode = '';
      let merchantName = '';
      let sourceType = '';
      let customerCollectType = '';
      const selectTime = JSON.parse(localStorage.getItem('selectTime')) || this.selectTime;
      if (row) {
        sourceType = row.collectWay;
        customerCollectType = row.collectSource;
        this.queryFormTimeMX.params.sourceType = row.collectWay;
        this.queryFormTimeMX.params.customerCollectType = row.collectSource;
        merchantCode = row.merchantCode ? row.merchantCode : -99;
        merchantName = row.merchantName ? row.merchantName : -99;
        const merchantCodeRow = row.merchantCode == null ? '' : row.merchantCode;
        const merchantNameRow = row.merchantName == null ? '' : row.merchantName;
        merchantCodes.push(merchantCodeRow);
        merchantNames.push(merchantNameRow);
        customerId = this.queryFormTimeMX.params.customerId;
        beginTime = row.createTime ? row.createTime + ' 00:00:00' : '';
        endTime = row.createTime ? row.createTime + ' 23:59:59' : '';
        collectStartTime = row.scanTime ? row.scanTime + ' 00:00:00' : '';
        collectEndTime = row.scanTime ? row.scanTime + ' 23:59:59' : '';
        if (row.createTime && selectTime === 'mr0') {
          this.queryFormTimeMX.params.beginTime = beginTime;
          this.queryFormTimeMX.params.endTime = endTime;
          this.queryFormTimeMX.params.collectStartTime = ''; // 揽收初始时间
          this.queryFormTimeMX.params.collectEndTime = ''; // 揽收终止时间
        }
        if (row.scanTime && selectTime === 'mr1') {
          this.queryFormTimeMX.params.beginTime = '';
          this.queryFormTimeMX.params.endTime = '';
          this.queryFormTimeMX.params.collectStartTime = collectStartTime; // 揽收初始时间
          this.queryFormTimeMX.params.collectEndTime = collectEndTime; // 揽收终止时间
        }
      } else {
        customerId = this.queryFormTimeHZ.params.customerId;
        merchantCodes = this.queryFormTimeHZ.params.merchantCodes;
        merchantNames = this.queryFormTimeHZ.params.merchantNames;
        beginTime = this.queryFormTimeHZ.params.beginTime;
        endTime = this.queryFormTimeHZ.params.endTime;
        collectStartTime = this.queryFormTimeHZ.params.collectStartTime; // 揽收初始时间
        collectEndTime = this.queryFormTimeHZ.params.collectEndTime; // 揽收终止时间
        sourceType = this.queryFormTimeMX.params.sourceType;
        customerCollectType = this.queryFormTimeMX.params.customerCollectType;
      }
      const queryFormTimeMX = {
        pageNumber: this.queryFormTimeMX.pageNumber,
        pageSize: this.queryFormTimeMX.pageSize,
        params: {
          beginTime: selectTime === 'mr0' ? beginTime : '', // 开始时间
          endTime: selectTime === 'mr0' ? endTime : '', // 结束时间
          collectStartTime: selectTime === 'mr1' ? collectStartTime : '',
          collectEndTime: selectTime === 'mr1' ? collectEndTime : '',
          siteId: this.queryFormTimeHZ.params.siteId, // 站点id
          scanUserId: this.queryFormTimeHZ.params.scanUserId, // 司机id
          customerId, // 客户id
          merchantCodes, // 商户号
          merchantNames, // 商户名称
          orderNumber: '',
          listNumber: '',
          packageNumber: '',
          merchantCode, // 商户号
          merchantName, // 商户名称
          collectstatusList: this.queryFormTimeMX.params.collectstatusList,
          collectTimeBool: this.queryFormTimeMX.params.collectTimeBool,
          signerTimeBool: this.queryFormTimeMX.params.signerTimeBool,
          bagSealingTimeBool: this.queryFormTimeMX.params.bagSealingTimeBool,
          sourceType,
          customerCollectType
        }
      };
      const qp = this.queryFormTimeMX.params;
      if (qp.collectstatusList <= 0 || !qp.collectstatusList) {
        delete this.queryFormTimeMX.params.collectstatusList;
        delete queryFormTimeMX.params.collectstatusList;
      }
      if (!qp.collectTimeBool) {
        delete this.queryFormTimeMX.params.collectTimeBool;
        delete queryFormTimeMX.params.collectTimeBool;
      }
      if (!qp.signerTimeBool) {
        delete this.queryFormTimeMX.params.signerTimeBool;
        delete queryFormTimeMX.params.signerTimeBool;
      }
      if (!qp.bagSealingTimeBool) {
        delete this.queryFormTimeMX.params.bagSealingTimeBool;
        delete queryFormTimeMX.params.bagSealingTimeBool;
      }
      localStorage.setItem('MRTimeMXQueryForm', JSON.stringify(queryFormTimeMX.params));
      collectOrderCountQueryDetailPage(queryFormTimeMX).then(res => {
        if (res.code===200) {
          this.ScheduleTableDate = res.data.records;
          this.ScheduleTotal = res.data.total;
        }
      }).catch({});
    },
    SelectMX(selection) {
      this.loadUpList = [];
      this.loadUpList = selection;
      const tIdList = [];
      selection.forEach(list => {
        tIdList.push(list.id);
      });
      this.tableIdList = tIdList;
    },
    batchSelectMX(selection, row) { // 明细——多选
      this.SelectMX(selection);
    },
    batchSelectAllMX(selection) { // 明细——全选
      this.SelectMX(selection);
    },
    downloadAll() { // 汇总——全部导出
      this.isClickAll1 = true;
      const queryForm = JSON.parse(localStorage.getItem('MRTimeHZQueryForm')) || this.queryFormTimeHZ.params;
      exportAll(queryForm).then(res => {
        if (res.code===200) {
          this.isClickAll1 = false;
          goExportList(this);
        } else {
          this.$message.error(res.msg);
        }
      }).catch({});
    },
    downloadAllMX() { // 明细——全部导出
      this.isClickAll2 = true;
      if (this.ScheduleTableDate.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.noDataExport')); // 无可导出数据
        return;
      }
      const dataForm = {
        beginTime: this.queryFormTimeMX.params.beginTime || this.queryFormTimeHZ.params.beginTime,
        endTime: this.queryFormTimeMX.params.endTime || this.queryFormTimeHZ.params.endTime,
        collectStartTime: this.queryFormTimeMX.params.collectStartTime || this.queryFormTimeHZ.params.collectStartTime,
        collectEndTime: this.queryFormTimeMX.params.collectEndTime || this.queryFormTimeHZ.params.collectEndTime,
        siteId: this.queryFormTimeMX.params.siteId || this.queryFormTimeHZ.params.siteId,
        scanUserId: this.queryFormTimeMX.params.scanUserId || this.queryFormTimeHZ.params.scanUserId,
        customerId: this.queryFormTimeMX.params.customerId || this.queryFormTimeHZ.params.customerId,
        merchantCodes: this.queryFormTimeMX.params.merchantCodes || this.queryFormTimeHZ.params.merchantCodes,
        merchantNames: this.queryFormTimeMX.params.merchantNames || this.queryFormTimeHZ.params.merchantNames,
        orderNumber: '',
        listNumber: '',
        packageNumber: '',
        collectstatusList: this.queryFormTimeMX.params.collectstatusList,
        collectTimeBool: this.queryFormTimeMX.params.collectTimeBool,
        signerTimeBool: this.queryFormTimeMX.params.signerTimeBool,
        bagSealingTimeBool: this.queryFormTimeMX.params.bagSealingTimeBool
      };
      const qp = this.queryFormTimeMX.params;
      if (qp.collectstatusList <= 0 || !qp.collectstatusList) {
        delete this.queryFormTimeMX.params.collectstatusList;
        delete dataForm.collectstatusList;
      }
      if (!qp.collectTimeBool) {
        delete this.queryFormTimeMX.params.collectTimeBool;
        delete dataForm.collectTimeBool;
      }
      if (!qp.signerTimeBool) {
        delete this.queryFormTimeMX.params.signerTimeBool;
        delete dataForm.signerTimeBool;
      }
      if (!qp.bagSealingTimeBool) {
        delete this.queryFormTimeMX.params.bagSealingTimeBool;
        delete dataForm.bagSealingTimeBool;
      }
      const MXqueryForm = JSON.parse(localStorage.getItem('MRTimeMXQueryForm')) || dataForm;
      findListOrderDetailExportAll(MXqueryForm).then(res => {
        if (res.code===200) {
          this.isClickAll2 = false;
          goExportList(this);
        } else {
          this.$message.error(res.msg);
        }
      }).catch({});
    }
  }
};
</script>

<style lang="scss" scoped>

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce2{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce2:active{
    filter: opacity(40%);
    animation: none;
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }
.col2 {
  width: auto !important;
}
.autoWidth ::v-deep .el-input--prefix .el-input__inner{
  padding-left: 0 !important;
}
.autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
  position: relative;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  padding: 0 25px;
  padding-left: 0;
  margin-left: 20px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  visibility: hidden;
  white-space: nowrap;
}
.autoWidth ::v-deep input {
  position: absolute;
}
.selectClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selectClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.selectClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
}
  .selectClass ::v-deep .el-select{
    width: 100%;
  }
  .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .col10 .m ::v-deep textarea.el-textarea__inner {
    max-height: 100px;
  }
  .col10 ::v-deep .el-select{
    width: 100%;
  }
  .col10 .m ::v-deep .el-form-item__content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px !important;
  }
  .col10 .m ::v-deep .el-form-item__content .selectTimeClass{
    width: auto !important;
  }
  .selectTimeClass ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
</style>
