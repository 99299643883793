import request from '@/utils/request';
import newRequest from '@/utils/new-request';

/**
 * 索赔列表
 */

// 分页查询
export const apidDmageClaimformPage = data => {
  return newRequest.post('/demageClaimform/page', data);
};

// 列表导出
export const apidDmageClaimformExport = data => {
  return newRequest.post('/demageClaimform/formExport', data);
};

// 关联订单批量导出
export const apidDmageClaimbatchExport = data => {
  return newRequest.post('/demageClaimform/batchExport', data);
};

// 详情
export const apiDamageClaimFormId = id => {
  return newRequest.get(`/demageClaimform/${id}`);
};

// 新增/保存
export const apiDemageClaimFormAdd = data => {
  return request.post('/fa/demageClaimform/add', data);
};

// 修改
export const apiDemageClaimFormEdit = data => {
  return newRequest.put('/demageClaimform/edit', data);
};

// 关联订单导出
export const apiDemageClaimformExport = data => {
  return newRequest.post('/demageClaimform/export', data);
};

// 关联单号校验/导入
export const apiDemageClaimformOrderDetail = (data, isLoading = true) => {
  return newRequest({
    method: 'POST',
    url: '/demageClaimform/orderDetail',
    data,
    isLoading // 是否显示加载中参数
  });
};

// 移除关联订单
export const apiDemageClaimFormRemoveBill = data => {
  return newRequest.post('/demageClaimform/removeBill', data);
};

// 提交变更节点
export const apiDemageClaimform = id => {
  return request.post(`/fa/demageClaimform/commit/${id}`);
};

// 审核--变更节点
export const apiDemageClaimformRejectAndPass = data => {
  return newRequest.post('/demageClaimform/rejectAndPass', data);
};

// 审批流
export const apiDemageClaimformCommit = id => {
  return request.post(`/fa/demageClaimform/commit/${id}`);
};

// 关闭
export const apiDemageClaimformClose = id => {
  return newRequest.get(`/demageClaimform/close/${id}`);
};

// 付款
export const apiDemageClaimformCommitPay = data => {
  return newRequest.post('/demageClaimform/commitPay', data);
};
// 取消付款
export const apiDemageClaimformUnCommitPay = data => {
  return newRequest.post('/demageClaimform/unCommitPay', data);
};
// 索赔账单取消付款
export const apiDemageClaimBillUnCommitPay = data => {
  return newRequest.post('/demageClaimBill/unCommitPay', data);
};
// 关联订单分页查询
export const apiDemageClaimformUnClimFromDetail = data => {
  return newRequest.post('/demageClaimform/climFromDetail', data);
};
