var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticStyle: { float: "left" } },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-upload2", size: "mini" },
                  on: { click: _vm.exportData },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("collectionCenter.明细导出")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "right" }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-right": "20px",
                      display: "flex",
                      "justify-content": "space-between",
                      "align-items": "center",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.updateTime,
                            expression: "updateTime",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("GlobalSetObj.UpdateTime")) +
                            "：" +
                            _vm._s(_vm.updateTime)
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "dateBox" }, [
                      _c(
                        "span",
                        {
                          class: { d1: _vm.type == 0 },
                          on: {
                            click: function ($event) {
                              return _vm.get2Data(0)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.yesterday")))]
                      ),
                      _c(
                        "span",
                        {
                          class: { d1: _vm.type == 1 },
                          on: {
                            click: function ($event) {
                              return _vm.get2Data(1)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.sameDay")))]
                      ),
                      _c(
                        "span",
                        {
                          class: { d1: _vm.type == 2 },
                          on: {
                            click: function ($event) {
                              return _vm.get2Data(2)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.nearlyAWeek")))]
                      ),
                      _c(
                        "span",
                        {
                          class: { d1: _vm.type == 3 },
                          on: {
                            click: function ($event) {
                              return _vm.get2Data(3)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.sameMonth")))]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", plain: "", size: "small" },
                    on: { click: _vm.closeWindow },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("collectionCenter.close")) + " ")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.tabClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("collectionCenter.unloadedVehicleDetailsGLP"),
                    name: "hz",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.tableData1,
                            border: "",
                            "show-summary": "",
                            "summary-method": _vm.getSummaries,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("operationCenter.ind"),
                              width: "60px",
                              align: "center",
                              fixed: "left",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.$index + 1)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "vehicleNumber",
                              label: _vm.$t("GlobalSetObj.licensePlateNumber"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "operationModeName",
                              label: _vm.$t("collectionCenter.operationMode"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "loadBagCount",
                              label: _vm.$t(
                                "collectionCenter.totalNumberBagsLoaded"
                              ),
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#409eff",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toMX(scope.row, 1)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.loadBagCount) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "loadTicketCount",
                              label: _vm.$t("collectionCenter.carLoadingVotes"),
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#409eff",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toMX(scope.row, 2)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.loadTicketCount) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "arrivalGlpTime",
                              label: _vm.$t(
                                "collectionCenter.TimeOfArrivalAtGLP"
                              ),
                              align: "center",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-pagination", {
                        staticStyle: { margin: "10px 0" },
                        attrs: {
                          layout: "total, sizes, prev, pager, next, jumper",
                          "current-page": _vm.pageNumber,
                          "page-sizes": [10, 20, 50, 100, 500, 1000],
                          "page-size": _vm.pageSize,
                          total: _vm.total,
                          background: "",
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            _vm.pageNumber = $event
                          },
                          "update:current-page": function ($event) {
                            _vm.pageNumber = $event
                          },
                          "update:pageSize": function ($event) {
                            _vm.pageSize = $event
                          },
                          "update:page-size": function ($event) {
                            _vm.pageSize = $event
                          },
                          "current-change": _vm.getDatePageMX,
                          "size-change": _vm.getPageSizeMX,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm.activeName === "mx"
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: _vm.tabsName, name: "mx" } },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.tableDataMX,
                            border: "",
                            "max-height": _vm.mxHeight,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("operationCenter.ind"),
                              width: "60px",
                              align: "center",
                              fixed: "left",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            (_vm.formMX.pageNumber - 1) *
                                              _vm.formMX.pageSize +
                                              scope.$index +
                                              1
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3968133944
                            ),
                          }),
                          _vm.mxType === 1
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "bagNumber",
                                  label: this.$t("GlobalSetObj.bagNumber"),
                                  "min-width": "160",
                                  align: "center",
                                },
                              })
                            : _vm._e(),
                          _vm.mxType === 2
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "scanNumber",
                                  label: this.$t("collectionCenter.Barcode"),
                                  "min-width": "160",
                                  align: "center",
                                },
                              })
                            : _vm._e(),
                          _vm.mxType === 2
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "packageNumber",
                                  label: this.$t("collectionCenter.parcelNum"),
                                  "min-width": "160",
                                  align: "center",
                                },
                              })
                            : _vm._e(),
                          _vm._l(_vm.mxColumn, function (item, ind) {
                            return _c("el-table-column", {
                              key: ind,
                              attrs: {
                                prop: item.prop,
                                label: item.label,
                                "min-width": item.width,
                                align: item.align,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-pagination", {
                            staticStyle: { margin: "10px 0" },
                            attrs: {
                              layout: "total, sizes, prev, pager, next, jumper",
                              "current-page": _vm.formMX.pageNumber,
                              "page-sizes": [10, 20, 50, 100, 500, 1000],
                              "page-size": _vm.formMX.pageSize,
                              total: _vm.totalMX,
                              background: "",
                            },
                            on: {
                              "update:currentPage": function ($event) {
                                return _vm.$set(
                                  _vm.formMX,
                                  "pageNumber",
                                  $event
                                )
                              },
                              "update:current-page": function ($event) {
                                return _vm.$set(
                                  _vm.formMX,
                                  "pageNumber",
                                  $event
                                )
                              },
                              "update:pageSize": function ($event) {
                                return _vm.$set(_vm.formMX, "pageSize", $event)
                              },
                              "update:page-size": function ($event) {
                                return _vm.$set(_vm.formMX, "pageSize", $event)
                              },
                              "current-change": _vm.getDatePageMX,
                              "size-change": _vm.getPageSizeMX,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }