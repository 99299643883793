var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Box" }, [
    _vm.firstTab
      ? _c("div", { staticClass: "boxUrl containerData" }, [
          _c("div", { staticClass: "topBox" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.dataVisual")))]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.updateTime,
                  expression: "updateTime",
                },
              ],
              staticClass: "updateTimeClass",
            },
            [
              _vm._v(
                _vm._s(_vm.$t("GlobalSetObj.UpdateTime")) +
                  "：" +
                  _vm._s(_vm.updateTime)
              ),
            ]
          ),
          _c(
            "div",
            {
              ref: "parentRef",
              staticStyle: { padding: "20px", "box-sizing": "border-box" },
            },
            [
              _c(
                "el-row",
                { staticClass: "grid-row" },
                [
                  _c(
                    "el-col",
                    { staticClass: "left-column", attrs: { span: 8 } },
                    [
                      _c("div", { ref: "childRef1" }, [
                        _c(
                          "div",
                          { ref: "childRef11", staticClass: "dateBox" },
                          [
                            _c(
                              "span",
                              {
                                class: { d1: _vm.type == 0 },
                                on: {
                                  click: function ($event) {
                                    return _vm.getData(0)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.yesterday"))
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                class: { d1: _vm.type == 1 },
                                on: {
                                  click: function ($event) {
                                    return _vm.getData(1)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.sameDay"))
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                class: { d1: _vm.type == 2 },
                                on: {
                                  click: function ($event) {
                                    return _vm.getData(2)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.nearlyAWeek"))
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                class: { d1: _vm.type == 3 },
                                on: {
                                  click: function ($event) {
                                    return _vm.getData(3)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.sameMonth"))
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { ref: "childRef12", staticStyle: { width: "100%" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "leftBox",
                                staticStyle: { width: "95%" },
                              },
                              [
                                _c("span", { staticClass: "angle lt" }),
                                _c("span", { staticClass: "angle rt" }),
                                _c("span", { staticClass: "angle lb" }),
                                _c("span", { staticClass: "angle rb" }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#47E3FF",
                                      "font-size": "20px",
                                      "font-weight": "bold",
                                      "letter-spacing": "1px",
                                      "line-height": "20px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "collectionCenter.signConditionView"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "totalBox orange" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "s1 org",
                                              staticStyle: {
                                                "line-height": "18px",
                                                "margin-bottom": "5px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "collectionCenter.shouldSignedGrossAmount"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "s2",
                                              staticStyle: {
                                                "line-height": "18px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.leftAll.shouldSignCount
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "totalBox blue" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "s1 bl",
                                              staticStyle: {
                                                "line-height": "18px",
                                                "margin-bottom": "5px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "collectionCenter.actualSignGrossAmount"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "s2",
                                              staticStyle: {
                                                "line-height": "18px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.leftAll.actualSign)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "totalBox green" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "s1 gr",
                                              staticStyle: {
                                                "line-height": "18px",
                                                "margin-bottom": "5px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "collectionCenter.unsignedGrossAmount"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "s2",
                                              staticStyle: {
                                                "line-height": "18px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.leftAll.unSignCount)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 12 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "progressClass" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "progressBox" },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "60%",
                                                  height: "60%",
                                                },
                                                attrs: {
                                                  src: _vm.ring,
                                                  alt: "",
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.leftAll.signRate) +
                                                    "%"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "#01f2ff",
                                                "font-weight": "bold",
                                                "font-size": "17px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "collectionCenter.RateOfSigning"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "leftBox",
                                staticStyle: { margin: "10px 0", width: "95%" },
                              },
                              [
                                _c("span", { staticClass: "angle lt" }),
                                _c("span", { staticClass: "angle rt" }),
                                _c("span", { staticClass: "angle lb" }),
                                _c("span", { staticClass: "angle rb" }),
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#47E3FF",
                                        "font-size": "20px",
                                        "font-weight": "bold",
                                        "letter-spacing": "1px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "collectionCenter.SuspectedLostPackage"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          _vm.toSecondaryLose(
                                            _vm.$t(
                                              "collectionCenter.SuspectedLostPackage"
                                            )
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#f42048",
                                            "margin-left": "20px",
                                            "font-size": "18px",
                                            "font-weight": "bold",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.tableData5.suspectedLostCount
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                            color: "#f42048",
                                            "font-size": "16px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("operationCenter.pieces")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "leftBox",
                                staticStyle: { margin: "10px 0", width: "95%" },
                              },
                              [
                                _c("span", { staticClass: "angle lt" }),
                                _c("span", { staticClass: "angle rt" }),
                                _c("span", { staticClass: "angle lb" }),
                                _c("span", { staticClass: "angle rb" }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#47E3FF",
                                      "font-size": "20px",
                                      "font-weight": "bold",
                                      "letter-spacing": "1px",
                                      "line-height": "20px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "collectionCenter.CollectionPunctualityRate"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { width: "100%" } },
                                  [
                                    _c(
                                      "el-row",
                                      {
                                        staticStyle: { "flex-wrap": "wrap" },
                                        attrs: {
                                          type: "flex",
                                          justify: "start",
                                        },
                                      },
                                      [
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "progressClass" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "progressBox",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.toSecondarySummary(
                                                        0,
                                                        _vm.$t(
                                                          "collectionCenter.NationalPunctualityRate"
                                                        )
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "90%",
                                                      height: "90%",
                                                    },
                                                    attrs: {
                                                      src: _vm.national,
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "14px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.tableData5
                                                            .nationalCount
                                                        ) + "%"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#01f2ff",
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.NationalPunctualityRate"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "progressClass" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "progressBox",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.toSecondarySummary(
                                                        1,
                                                        _vm.$t(
                                                          "collectionCenter.SPPunctualityRate"
                                                        )
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "90%",
                                                      height: "90%",
                                                    },
                                                    attrs: {
                                                      src: _vm.sp,
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "14px",
                                                        "font-weight": "100",
                                                        color: "#fbcd50",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.tableData5.spCount
                                                        ) + "%"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#01f2ff",
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.SPPunctualityRate"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "progressClass" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "progressBox",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.toSecondarySummary(
                                                        2,
                                                        _vm.$t(
                                                          "collectionCenter.FrancaPunctualityRate"
                                                        )
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "90%",
                                                      height: "90%",
                                                    },
                                                    attrs: {
                                                      src: _vm.franca,
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "14px",
                                                        "font-weight": "100",
                                                        color: "#2ad42a",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.tableData5
                                                            .francaCount
                                                        ) + "%"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#01f2ff",
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.FrancaPunctualityRate"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "progressClass" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "progressBox",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.toSecondarySummary(
                                                        3,
                                                        _vm.$t(
                                                          "collectionCenter.JauPunctualityRate"
                                                        )
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "90%",
                                                      height: "90%",
                                                    },
                                                    attrs: {
                                                      src: _vm.jau,
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "14px",
                                                        "font-weight": "100",
                                                        color: "#dd32e8",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.tableData5
                                                            .jauCount
                                                        ) + "%"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#01f2ff",
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.JauPunctualityRate"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "progressClass" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "progressBox",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.toSecondarySummary(
                                                        5,
                                                        _vm.$t(
                                                          "collectionCenter.MGPunctualityRate"
                                                        )
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "90%",
                                                      height: "90%",
                                                    },
                                                    attrs: {
                                                      src: _vm.mg,
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "14px",
                                                        "font-weight": "100",
                                                        color: "#fc5831",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.tableData5.mgCount
                                                        ) + "%"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#01f2ff",
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.MGPunctualityRate"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "progressClass" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "progressBox",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.toSecondarySummary(
                                                        4,
                                                        _vm.$t(
                                                          "collectionCenter.RJPunctualityRate"
                                                        )
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "90%",
                                                      height: "90%",
                                                    },
                                                    attrs: {
                                                      src: _vm.rj,
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "14px",
                                                        "font-weight": "100",
                                                        color: "#9b39ff",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.tableData5.rjCount
                                                        ) + "%"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#01f2ff",
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.RJPunctualityRate"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c(
                      "div",
                      {
                        ref: "childRef2",
                        staticClass: "rightBox right-column",
                        staticStyle: { height: "calc(100% - 10px)" },
                      },
                      [
                        _c("span", { staticClass: "angle lt" }),
                        _c("span", { staticClass: "angle rt" }),
                        _c("span", { staticClass: "angle lb" }),
                        _c("span", { staticClass: "angle rb" }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#47E3FF",
                              "font-size": "20px",
                              "font-weight": "bold",
                              "letter-spacing": "1px",
                              "margin-bottom": "14px",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "collectionCenter.unsignEachLinkdetail"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#47E3FF",
                              "font-size": "16px",
                              "font-weight": "bold",
                              "letter-spacing": "1px",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("collectionCenter.mdLanshou")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "el-table",
                          {
                            staticClass: "tableBox",
                            staticStyle: { width: "500px" },
                            attrs: { border: "", data: _vm.tableData1 },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "SP-MD",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                return _vm.routeClick(1)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.spMdStayCount)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1115463612
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "Franca-MD",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                return _vm.routeClick(2)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.francaMdStayCount
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3009621765
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "RJ-MD",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                return _vm.routeClick(4)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.rjMdStayCount)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3254609730
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "MG-MD",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                return _vm.routeClick(5)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.mgMdStayCount)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4265446449
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "Jau-MD", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                return _vm.routeClick(3)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.jauMdStayCount)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2497826595
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#47E3FF",
                              "font-size": "16px",
                              "font-weight": "bold",
                              "letter-spacing": "1px",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("collectionCenter.mdToxxx")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "el-table",
                          {
                            staticClass: "tableBox",
                            staticStyle: { width: "600px" },
                            attrs: { border: "", data: _vm.tableData2 },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "SP-Z-H005",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                _vm.detailClick(
                                                  1,
                                                  1,
                                                  _vm.$t(
                                                    "collectionCenter.mdToSpzh005"
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.mdSpH5Count)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1021591353
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "SP-Z-H006",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                _vm.detailClick(
                                                  1,
                                                  2,
                                                  _vm.$t(
                                                    "collectionCenter.mdToSpzh006"
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.mdSpH6Count)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3127643546
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "SP-Z-H002",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                _vm.detailClick(
                                                  1,
                                                  3,
                                                  _vm.$t(
                                                    "collectionCenter.mdToSpzh002"
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.mdSpH2Count)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1172299643
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "SP-Z-H004",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                _vm.detailClick(
                                                  1,
                                                  4,
                                                  _vm.$t(
                                                    "collectionCenter.mdToSpzh004"
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.mdSpH4Count)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2979386588
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "MG-Z-H001",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                _vm.detailClick(
                                                  1,
                                                  5,
                                                  _vm.$t(
                                                    "collectionCenter.mdToMgzh001"
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.mdMgH1Count)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                685225629
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "RJ-W-H001", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                _vm.detailClick(
                                                  1,
                                                  6,
                                                  _vm.$t(
                                                    "collectionCenter.mdToRjwh001"
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.mdRjH1count)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                192903091
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#47E3FF",
                              "font-size": "16px",
                              "font-weight": "bold",
                              "letter-spacing": "1px",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("collectionCenter.arrivexxx")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "el-table",
                          {
                            staticClass: "tableBox",
                            staticStyle: { width: "600px" },
                            attrs: { border: "", data: _vm.tableData3 },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "SP-Z-H005",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                return _vm.detailClick(
                                                  2,
                                                  1,
                                                  "SP-Z-H005"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.arrivalSpH5Count)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1075062650
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "SP-Z-H006",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                return _vm.detailClick(
                                                  2,
                                                  2,
                                                  "SP-Z-H006"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.arrivalSpH6Count)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2169360057
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "SP-Z-H002",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                return _vm.detailClick(
                                                  2,
                                                  3,
                                                  "SP-Z-H002"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.arrivalSpH2Count)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2444374008
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "SP-Z-H004",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                return _vm.detailClick(
                                                  2,
                                                  4,
                                                  "SP-Z-H004"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.arrivalSpH4Count)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4260107967
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "MG-Z-H001",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                return _vm.detailClick(
                                                  2,
                                                  5,
                                                  "MG-Z-H001"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.arrivalMgH1Count)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1358039070
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "RJ-W-H001", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                return _vm.detailClick(
                                                  2,
                                                  6,
                                                  "RJ-W-H001"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.arrivalRjH1Count)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                576113136
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#47E3FF",
                              "font-size": "16px",
                              "font-weight": "bold",
                              "letter-spacing": "1px",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("collectionCenter.xxxToWrglp01")
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "el-table",
                          {
                            staticClass: "tableBox",
                            staticStyle: { width: "600px" },
                            attrs: { border: "", data: _vm.tableData4 },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "SP-Z-H005",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                _vm.detailClick(
                                                  3,
                                                  1,
                                                  _vm.$t(
                                                    "collectionCenter.spzh005ToWrglp01"
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.row.spH5ToGlp1))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3502677046
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "SP-Z-H006",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                _vm.detailClick(
                                                  3,
                                                  2,
                                                  _vm.$t(
                                                    "collectionCenter.spzh006ToWrglp01"
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.row.spH6ToGlp1))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                748712885
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "SP-Z-H002",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                _vm.detailClick(
                                                  3,
                                                  3,
                                                  _vm.$t(
                                                    "collectionCenter.spzh002ToWrglp01"
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.row.spH2ToGlp1))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4017498964
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "SP-Z-H004",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                _vm.detailClick(
                                                  3,
                                                  4,
                                                  _vm.$t(
                                                    "collectionCenter.spzh004ToWrglp01"
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.row.spH4ToGlp1))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3922401843
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "MG-Z-H001",
                                width: "100",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                _vm.detailClick(
                                                  3,
                                                  5,
                                                  _vm.$t(
                                                    "collectionCenter.mgzh001ToWrglp01"
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.row.mgH1ToGlp1))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3747448818
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "RJ-W-H001", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skipClass",
                                            on: {
                                              click: function ($event) {
                                                _vm.detailClick(
                                                  3,
                                                  6,
                                                  _vm.$t(
                                                    "collectionCenter.rjwh001ToWrglp01"
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.row.rjH1ToGlp1))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1452315388
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#47E3FF",
                              "font-size": "16px",
                              "font-weight": "bold",
                              "letter-spacing": "1px",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("collectionCenter.glpUnloadTotal")
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("br"),
                        _c(
                          "div",
                          {
                            staticClass: "table-cell skipClass",
                            on: {
                              click: function ($event) {
                                _vm.detailClick(
                                  4,
                                  1,
                                  _vm.$t("collectionCenter.glpUnloadTotal")
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.glpNoLoadCount))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _c("div", { staticClass: "tabsBox" }, [
          _vm.showPunctualityCountry
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                    "z-index": "1000",
                    background: "#fff",
                  },
                },
                [
                  _c("punctuality", {
                    key: _vm.showPunctualityCountry,
                    attrs: {
                      "time-form": _vm.timeForm,
                      dateType: _vm.type,
                      tabName: _vm.tabName,
                    },
                    on: { closeWindow: _vm.closeWindow },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showSuspectedLoss
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                    "z-index": "1000",
                    background: "#fff",
                  },
                },
                [
                  _c("suspected-loss", {
                    key: _vm.showSuspectedLoss,
                    attrs: {
                      "time-form": _vm.timeForm,
                      dateType: _vm.type,
                      tabName: _vm.tabName,
                    },
                    on: { closeWindow: _vm.closeWindow },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showDetailMsg
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "tabsClose" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "small" },
                          on: { click: _vm.closeTabsClick },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("collectionCenter.close")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("detailMsg", { attrs: { form: _vm.queryFormList } }),
                ],
                1
              )
            : _vm._e(),
          _vm.showGlpNotUnloaded
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                    "z-index": "1000",
                    background: "#fff",
                  },
                },
                [
                  _c("glp-not-unloaded", {
                    key: _vm.showGlpNotUnloaded,
                    attrs: {
                      "time-form": _vm.timeForm,
                      "date-type": _vm.type,
                      "tab-name": _vm.tabName,
                    },
                    on: { closeWindow: _vm.closeWindow },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }