var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    [6, 7, 8].includes(_vm.row.model) ||
    [9, 10, 11].includes(_vm.row.operateModule)
      ? _c(
          "div",
          [
            _vm.row.status === _vm.IMPORT_STATUS_OBJ["2"].value
              ? _c(
                  "el-link",
                  {
                    attrs: {
                      href: _vm.row.failureDescription,
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.clickDown")))]
                )
              : _vm._e(),
          ],
          1
        )
      : _c("span", [_vm._v(_vm._s(_vm.row.failureDescription))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }