<template>
  <!-- 该下来列表只为搜索服务，无法双向绑定 -->
  <el-select
    v-model="customerId"
    filterable
    clearable
    remote
    reserve-keyword
    :loading="loading"
    :remote-method="getCustomerPage"
    :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
    @change="onChange"
  >
    <el-option
      v-for="item in customerList"
      :key="item.id"
      :label="item.customerAlias + '(' + item.username + ')'"
      :value="item.id"
    />
  </el-select>
</template>

<script>
import { apiCustomerPage } from '@/api/customer';

const param = {
  pageSize: 10,
  pageNumber: 1,
  params: {
    quickSearch: ''
  }
};

export default {
  name: '',

  data() {
    return {
      customerList: [],
      customerId: '',
      loading: false
    };
  },

  created() {
    this.getCustomerPage();
  },

  methods: {
    // 获取客户
    getCustomerPage(name = '') {
      param.params.quickSearch = name;

      this.loading = true;

      apiCustomerPage(param, false).then((res) => {
        if (res.code===200) {
          this.customerList = (res.data.records || []).map(item => {
            const { id, customerAlias, username } = item;
            const obj = {
              id,
              customerAlias,
              username
            };

            return obj;
          });
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onChange() {
      this.$emit('change', this.customerId);
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
