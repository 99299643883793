<template>
  <div class="ProblemDeliveryBox">
    <!-- 问题件登记查询 -->
    <div style="margin-bottom: 20px;">
      <el-form class="selectClass">
        <el-row justify="start" style="flex-wrap: wrap;" type="flex">
          <el-col :span="10" style="width: auto;">
            <!-- 订单创建时间： -->
            <el-form-item>
              <div style="display: flex;justify-content: flex-start;align-items: center;">
                <el-select
                  v-model="selectTime"
                  :placeholder="$t('collectionCenter.pleaseChoose')"
                  class="selectTimeClass autoWidth wid"
                  @change="changeTime"
                >
                  <template slot="prefix">
                    {{ (timeList.find(item => item.value === selectTime) || {}).time }}
                  </template>
                  <el-option
                    v-for="(item, index) in timeList"
                    :key="index"
                    :label="item.time"
                    :value="item.value"
                  />
                </el-select>
                <el-date-picker
                  v-model="timeValue"
                  :clearable="false"
                  :default-time="['00:00:00', '23:59:59']"
                  :end-placeholder="$t('collectionCenter.EndTime')"
                  :range-separator="$t('collectionCenter.to')"
                  :start-placeholder="$t('collectionCenter.StartTime')"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  @change="dateChange"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <el-row>
              <el-col class="col2">
                <el-select
                  v-model="selectCode" :placeholder="$t('collectionCenter.pleaseChoose')"
                  class="selectTimeClass autoWidth" @change="changeCode"
                >
                  <template slot="prefix">
                    {{ (selectCodeList.find(item => item.value === selectCode) || {}).label }}
                  </template>
                  <el-option
                    v-for="(item, index) in selectCodeList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col v-if="selectCode == 'c3'" style="width: auto;"><!-- 物流订单号 -->
                <el-form-item class="siteClass">
                  <el-input
                    v-model="queryForm.params.scanNumberList"
                    :placeholder="$t('collectionCenter.BatchQuery')"
                    autosize
                    type="textarea"
                    @keydown.enter.native="BlockNewlineLogistics"
                  />
                </el-form-item>
              </el-col>
              <!-- 包裹号 -->
              <el-col v-if="selectCode == 'c1'" style="width: auto;">
                <el-form-item class="siteClass">
                  <div class="packageNumberClass">
                    <el-input
                      v-model="queryForm.params.packageNumber"
                      :autosize="{ maxRows: 5}"
                      :placeholder="$t('collectionCenter.BatchQuery')"
                      type="textarea"
                      @keydown.enter.native="BlockNewline"
                    />
                  </div>
                  <!-- 支持批量查询(回车换行分割) -->
                </el-form-item>
              </el-col>
              <el-col v-if="selectCode == 'c2'" style="width: auto;"><!-- 揽收订单号 -->
                <el-form-item class="siteClass">
                  <el-input
                    v-model="queryForm.params.orderNumberList"
                    :placeholder="$t('collectionCenter.BatchQuery')"
                    autosize
                    type="textarea"
                    @keydown.enter.native="BlockNewlineOrder"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 订单状态： -->
            <el-form-item :label="$t('collectionCenter.orderStatus')">
              <el-select
                v-model="queryForm.params.collectStatus" :placeholder="$t('collectionCenter.pleaseChoose')"
                :value="5" clearable filterable
              >
                <el-option
                  v-for="(item, index) in orderTypeList"
                  :key="index"
                  :label="item.collectStatusName"
                  :value="item.collectStatus"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 客户名称： -->
            <el-form-item :label="$t('collectionCenter.CustomerN')">
              <el-select
                v-model="queryForm.params.customerId" :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable filterable @change="changeMerchant"
                @focus="getAllCustomer"
              >
                <el-option
                  v-for="(item, index) in customerNameList"
                  :key="index"
                  :label="item.username"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="col10" style="width: auto;">
            <!-- 商户号/商户名称： -->
            <el-form-item class="m">
              <el-select
                v-model="merchantChoose" :placeholder="$t('collectionCenter.pleaseChoose')"
                class="selectTimeClass autoWidth" @change="changeMerchant"
              >
                <template slot="prefix">
                  {{ (merchantCodeOrName.find(item => item.m === merchantChoose) || {}).name }}
                </template>
                <el-option
                  v-for="(item, index) in merchantCodeOrName"
                  :key="index"
                  :label="item.name"
                  :value="item.m"
                />
              </el-select>
              <el-select
                v-model="merchantCodeValue"
                :loading="loading"
                :placeholder="$t('collectionCenter.pleaseInput')"
                :remote-method="remoteMethod"
                clearable
                filterable
                remote
                reserve-keyword
                @change="changeValue"
              >
                <el-option
                  v-for="(item, index) in merchantCodeList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 问题件来源： -->
            <el-form-item :label="$t('collectionCenter.SourceProblemPiece')">
              <el-select
                v-model="queryForm.params.problemSource" :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable filterable
              >
                <el-option
                  v-for="(item, index) in problemSourceList"
                  :key="index"
                  :label="item.name"
                  :value="item.come"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 问题件类型： -->
            <el-form-item :label="$t('collectionCenter.TypesProblem')">
              <el-select
                v-model="queryForm.params.problemItemType" :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
              >
                <!--全部-->
                <el-option key="0" :label="$t('collectionCenter.tudo')" :value="null" />
                <!--揽收-->
                <el-option key="1" :label="$t('collectionCenter.collect')" :value="0" />
                <!--派送-->
                <el-option key="2" :label="$t('collectionCenter.dispatch')" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 登记网点： -->
            <el-form-item :label="$t('collectionCenter.registrationNetwork') + '：'">
              <el-select
                v-model="problemSite"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable filterable @focus="getNewestSite"
                @change="setSite"
              >
                <el-option
                  v-for="(item, index) in siteIdList"
                  :key="index"
                  :label="item.name"
                  :value="item.id + '/' + item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;"><!--所属平台 -->
            <el-form-item :label="$t('collectionCenter.PlatformWhichBelongs')">
              <el-select
                v-model="queryForm.params.platform"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
              >
                <el-option :value="1" label="shein" /><!-- shein -->
                <el-option :value="2" label="kwai" /><!-- kwai -->
                <el-option :label="$t('collectionCenter.expressDelivery')" :value="3" /><!-- 快递 -->
                <el-option :label="$t('collectionCenter.other')" :value="0" /><!-- 其他 -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:problem:ProblemDelivery:search'"
            icon="el-icon-search"
            size="mini"
            type="primary"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}
          </el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:problem:ProblemDelivery:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}
          </el-button><!-- 重置 -->
          <el-button
            v-permit:remove="'btn:collection-center:problem:ProblemDelivery:register'"
            size="mini"
            @click="ProblemClick"
          >{{ $t('collectionCenter.ProblemFileRegistration') }}
          </el-button><!-- 问题件登记 -->
          <el-button
            v-permit:remove="'btn:collection-center:problem:ProblemDelivery:allExport'"
            icon="el-icon-upload2"
            size="mini"
            @click="download"
          >{{ $t('collectionCenter.allExport') }}
          </el-button><!-- 全部导出 -->
        </el-col>
        <!-- <el-col v-if="isShow" :span="4">
          <el-popover
            placement="bottom-end"
            title=""
            trigger="hover"
            content="x"
          >
            <el-button
              slot="reference"
              size="mini"
            >{{ $t('collectionCenter.ProblemPieceProcessing') }}</el-button>问题件处理
            <div class="ProblemBox">
              <el-button
                size="mini"
                @click="dataRecoveryClick"
              >{{ $t('collectionCenter.DataRecovery') }}</el-button>数据恢复
              <el-button
                size="mini"
                @click="ProblemClick"
              >{{ $t('collectionCenter.ProblemFileRegistration') }}</el-button>问题件登记
            </div>
          </el-popover>
        </el-col> -->
      </el-row>
      <el-divider />
    </div>
    <div>
      <el-table
        ref="multipleTable"
        :data="tableDate"
        :max-height="600"
        border
        @select="batchSelect"
        @select-all="batchSelectAll"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column
          :label="$t('collectionCenter.ind')" align="center" type="index"
          width="60"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('collectionCenter.CustomerName')"
          prop="customerName"
        /><!-- 客户名称 -->
        <el-table-column
          :label="$t('collectionCenter.MerchantName')"
          prop="merchantName"
        />
        <el-table-column
          :label="$t('collectionCenter.platform')"
          align="center"
          prop="platform"
        ><!--        所属平台-->
          <template slot-scope="scope">
            <div>
              {{
                platformObj[scope.row.platform]
              }}
            </div>
          </template>
        </el-table-column>
        <!-- 商户名称 -->
        <el-table-column
          :label="$t('collectionCenter.parcelNum')"
          prop="packageNumber"
        /><!-- 包裹号 -->
        <el-table-column
          :label="$t('collectionCenter.logisticsNum')"
          prop="scanNumber"
        /><!-- 物流号 -->
        <el-table-column
          :label="$t('collectionCenter.lanshouOrderNum')"
          prop="orderNumber"
        /><!-- 揽收订单号 -->
        <el-table-column
          :label="$t('collectionCenter.lanshouT')"
          prop="scanTime"
          width="100"
        /><!-- 揽收时间 -->
        <el-table-column
          :label="$t('newOrder.clientExitTime')"
          prop="customerSendTime"
          width="100"
        /><!-- 客户出仓时间 -->
        <el-table-column
          :label="$t('collectionCenter.SourcePPiece')"
          prop="problemSource"
        ><!-- 问题件来源 -->
          <template slot-scope="scope">
            <div>
              {{
                scope.row.problemSource === 0 ? 'pc' :
                scope.row.problemSource === 1 ? 'pda' : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('collectionCenter.CreationTime')"
          prop="orderCreateTime"
          width="100"
        /><!-- 创建时间 -->
        <el-table-column
          :label="$t('collectionCenter.OrderStatus')"
          prop="collectStatus"
        ><!-- 订单状态 -->
          <template slot-scope="scope">
            <div>
              <!--
                5, 揽收异常
                7, 问题件
                0, 未揽收
                1, 已揽收
                8, 已封袋
                6, 已签收
                scope.row.collectStatus == 5 ? $t('collectionCenter.AbnormalLanShou') :
                scope.row.collectStatus == 7 ? $t('collectionCenter.ProblemPiece') :
                scope.row.collectStatus == 0 ? $t('collectionCenter.notLanShou') :
                scope.row.collectStatus == 1 ? $t('collectionCenter.hasLanShou') :
                scope.row.collectStatus == 8 ? $t('collectionCenter.SealedBag') :
                scope.row.collectStatus == 6 ? $t('collectionCenter.haveBeenSigned') : ''
              -->
              {{
                scope.row.collectStatus == 0 ? $t('collectionCenter.notLanShou') :
                scope.row.collectStatus == 1 ? $t('collectionCenter.hasLanShou') :
                scope.row.collectStatus == 2 ? $t('collectionCenter.cancelled') :
                scope.row.collectStatus == 4 ? $t('collectionCenter.scheduled') :
                scope.row.collectStatus == 5 ? $t('collectionCenter.AbnormalLanShou') :
                scope.row.collectStatus == 6 ? $t('collectionCenter.haveBeenSigned') :
                scope.row.collectStatus == 7 ? $t('collectionCenter.ProblemPiece') :
                scope.row.collectStatus == 8 ? $t('collectionCenter.SealedBag') :
                scope.row.collectStatus == 9 ? $t('collectionCenter.truckLoading') :
                scope.row.collectStatus == 10 ? $t('collectionCenter.unloading') :
                scope.row.collectStatus == 11 ? $t('collectionCenter.Bagged') :
                scope.row.collectStatus == 12 ? $t('collectionCenter.closedLoop') :
                scope.row.collectStatus == 13 ? $t('collectionCenter.unclaimed') :
                scope.row.collectStatus == 14 ? $t('collectionCenter.collectionPointInventory') : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('collectionCenter.problemPieceType')"
          prop="problemItemTypeName"
        /><!-- 问题件类型 -->
        <el-table-column
          :label="$t('collectionCenter.CauseOfTheP')"
          prop="problemTypeName"
        /><!-- 问题件原因 -->
        <el-table-column
          :label="$t('collectionCenter.registrar')"
          prop="problemName"
        /><!-- 登记人 -->
        <el-table-column
          :label="$t('collectionCenter.registrantSiteName')"
          prop="registrantSiteName"
        /><!-- 登记人所属网点 -->
        <el-table-column
          :label="$t('collectionCenter.RegistrationT')"
          prop="createTime"
          width="100"
        /><!-- 登记时间 -->
        <el-table-column
          :label="$t('collectionCenter.enclosureFJ')"
          align="center"
          prop="problemImg"
        ><!-- 附件 -->
          <template slot-scope="scope">
            <el-popover
              :title="$t('collectionCenter.enclosureFJ')"
              content=""
              placement="left-start"
              trigger="hover"
              width="300"
            >
              <img
                v-show="scope.row.problemImg" slot="reference" :src="scope.row.problemImg"
                alt=""
                style="width: 26px;height: 36px;"
              >
              <div v-show="!scope.row.problemImg" slot="reference">{{ $t('collectionCenter.Null') }}</div><!-- 暂无 -->
              <img
                v-if="scope.row.problemImg" :src="scope.row.problemImg" alt=""
                style="width: 100%;height: 100%;"
              >
              <div v-else>{{ $t('collectionCenter.NoContentYet') }}</div><!-- 暂无内容 -->
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('collectionCenter.Note')"
          prop="problemReason"
        /><!-- 备注 -->
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="getProblemDeliveryPage"
        @size-change="getPageSize"
      />
    </div>
    <!-- 问题件登记 -->
    <el-dialog
      :title="$t('collectionCenter.ProblemFileRegistration')"
      :visible.sync="dialogVisible"
      width="40%"
      @close="closeDialog"
    >
      <div class="registerBox">
        <div class="registerContent">
          <el-form ref="registerQueryRef" :model="registerQueryForm" :rules="rules">
            <el-row>
              <!-- 问题件类型： -->
              <el-col :span="24">
                <el-form-item
                  :label="$t('collectionCenter.TypesProblem')" class="typesProblemClass"
                  prop="problemItemType"
                >
                  <el-radio-group v-model="registerQueryForm.problemItemType" @change="problemTypeChange">
                    <el-radio :label="0">{{ $t('collectionCenter.collect') }}</el-radio><!-- 揽收 -->
                    <el-radio :label="1">{{ $t('collectionCenter.dispatch') }}</el-radio><!-- 派送 -->
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <!-- 问题件原因： -->
                <el-form-item :label="$t('collectionCenter.CauseOfTheProblem')" prop="problemType">
                  <el-select
                    v-model="registerQueryForm.problemType" :placeholder="$t('collectionCenter.pleaseChoose')"
                    clearable filterable @change="changeProblem"
                    @focus="getProblemList(registerQueryForm.problemItemType)"
                  >
                    <el-option
                      v-for="(item, index) in CauseProblemList"
                      :key="index"
                      :label="item.dictDescribe"
                      :value="(item.dictValue + '~' + item.problemTypeSub)"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <!-- 备注： -->
                <el-form-item :label="$t('collectionCenter.note')">
                  <el-input
                    v-model="registerQueryForm.problemReason" :placeholder="$t('collectionCenter.pleaseInput')"
                    clearable
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <div class="enclosureClass">
                  <!-- 附件 -->
                  <el-form-item :label="$t('collectionCenter.enclosure')">
                    <div class="uploadClass">
                      <el-upload
                        v-show="!upLoadFile"
                        ref="demoUpload"
                        :before-upload="beforeUpload"
                        :http-request="upLoad"
                        :limit="1"
                        action
                        class="upload-demo"
                        drag
                      >
                        <!-- * 仅支持png、bmp、jpg格式的图片 -->
                        <div slot="tip" class="el-upload__tip">{{
                          $t('collectionCenter.OnlyImageFormatIsSupported')
                        }}
                        </div>
                        <i class="el-icon-plus" style="margin: 50px auto 20px;" />
                        <!-- 点击或拖拽文件到此处上传 -->
                        <div class="el-upload__text">{{ $t('collectionCenter.cldrUpload') }}</div>
                      </el-upload>
                    </div>
                    <div v-show="upLoadFile">
                      <img
                        :src="upLoadFile" alt="" style="width: auto;height: 200px;"
                        @load="loadImg(upLoadFile)"
                      >
                      <div class="el-icon-delete-solid imgDelClass" @click="delImg" />
                    </div>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          icon="el-icon-delete"
          type="danger"
          @click="drawerResetClick"
        >{{ $t('collectionCenter.empty') }}</el-button><!-- 置空 -->
        <el-button
          icon="el-icon-check"
          type="primary"
          @click="saveClick"
        >{{ $t('collectionCenter.Save') }}</el-button><!-- 保存 -->
      </span>
    </el-dialog>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
import {
  collectProblemExport,
  collectProblemPage,
  getAllProblemType,
  problemRecovery,
  problemRegistration,
  queryAllCustomer,
  queryAllMerchantCode,
  searchSiteIdList
} from '@/api/lanshou';
import { uploadOos } from '@/utils/ali_oos_util';
import { getTimes } from '@/utils/watermark/watermark_time';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';
export default {
  name: 'ProblemDelivery',
  data() {
    return {
      siteIdList: [],
      selectTime: 't0',
      timeList: [
        { value: 't0', time: this.$t('collectionCenter.orderCreateT') }, // '订单创建时间'
        { value: 't1', time: this.$t('collectionCenter.lanshouT') }, // '揽收时间'
        { value: 't2', time: this.$t('newOrder.clientExitTime') }, // '客户出仓时间'
        { value: 't3', time: this.$t('collectionCenter.RegistrationT') } // '登记时间'
      ],
      okMp3,
      errorMp3,
      selectCode: 'c3',
      selectCodeList: [
        { value: 'c3', label: this.$t('collectionCenter.logisticsNum') }, // '物流号'
        { value: 'c1', label: this.$t('collectionCenter.parcelNum') }, // '包裹号'
        { value: 'c2', label: this.$t('collectionCenter.lanshouOrderNum') } // '揽收订单号'
      ],
      isChooseImg: false,
      isSubmitImg: false,
      timeValue: [],
      notEnter: true, // 是否允许换行
      loading: false, // 商户号加载
      merchantChoose: 'm2', // 选择输入商户号还是商户名称
      merchantCodeOrName: [
        { m: 'm1', name: this.$t('collectionCenter.MerchantNum') }, // '商户号'
        { m: 'm2', name: this.$t('collectionCenter.MerchantName') } // '商户名称'
      ],
      merchantCodeValue: '',
      merchantCodeList: [], // 商户编号列表
      isShow: true,
      // 揽收状态 0:未揽收 1:已揽收 2:取消 3:揽收中 4:已调度 5:揽收异常 6:已签收 7:揽收异常推送 8:已封袋
      orderTypeList: [
        { collectStatus: 5, collectStatusName: this.$t('collectionCenter.AbnormalLanShou') }, // 揽收异常
        { collectStatus: 7, collectStatusName: this.$t('collectionCenter.ProblemPiece') }, // 问题件
        { collectStatus: 0, collectStatusName: this.$t('collectionCenter.notLanShou') }, // 未揽收
        { collectStatus: 1, collectStatusName: this.$t('collectionCenter.hasLanShou') }, // 已揽收
        { collectStatus: 8, collectStatusName: this.$t('collectionCenter.SealedBag') }, // 已封袋
        { collectStatus: 6, collectStatusName: this.$t('collectionCenter.haveBeenSigned') } // 已签收
      ],
      isEdit: '',
      dialogVisible: false, // 问题件登记弹窗显示
      idList: [], // 问题件勾选列id
      customerNameList: [], // 客户列表
      CauseProblemList: [], // 问题件原因
      rules: { // 问题件登记校验
        customerId: [{ required: true, message: this.$t('collectionCenter.pleaseInput'), trigger: ['blur', 'change'] }],
        packageNumber: [{
          required: true,
          message: this.$t('collectionCenter.pleaseInput'),
          trigger: ['blur', 'change']
        }],
        problemType: [{ required: true, message: this.$t('collectionCenter.pleaseChoose'), trigger: ['blur', 'change'] }],
        problemItemType: [{
          required: true,
          message: this.$t('collectionCenter.pleaseChoose'),
          trigger: ['blur', 'change']
        }]
      },
      registerQueryForm: {
        userId: '', // 用户id
        problemItemType: '', // 问题件类型
        problemType: '', // 问题件原因
        problemReason: '', // 备注
        orderIdList: [] // 订单id
      },
      upLoadFile: '', // 问题件登记——附件
      problemRecoveryForm: {
        userId: '', // 用户id
        orderIdList: [] // 订单id
      },
      // 问题件登记查询
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          problemStartTime: '', // 开始时间
          problemEndTime: '', // 结束时间
          closeStartTime: '', // 揽收开始时间
          closeEndTime: '', // 揽收结束时间
          customerSendStartTime: '', // 客户出仓开始时间
          customerSendEndTime: '', // 客户出仓结束时间
          packageNumber: '', // 包裹号
          orderNumberList: '', // 揽收订单号
          scanNumberList: '', // 物流订单号
          collectStatus: '',
          customerId: '', // 客户名称
          merchantCode: '', // 商户号
          problemSource: 0, // 问题件来源
          problemItemType: '',
          problemCreateStartTime: '',
          problemCreateEndTime: '',
          platform: '',
          problemSiteId: '',
          problemSiteName: ''
        }
      },
      problemSite: '', // 需要传两个值 id name
      problemSourceList: [ // 问题件来源
        { come: 0, name: 'pc' },
        { come: 1, name: 'pda' }
      ],
      tableDate: [],
      problemTypeSub: '',
      total: 0,
      problemTypeNameList: [], // 当前所有选项中是否有problemTypeName值
      platformObj: {
        0: this.$t('GlobalSetObj.other'),
        1: 'shein',
        2: 'kwai',
        3: this.$t('collectionCenter.expressDelivery')
      }, // 0：其它 1：shein 2：kwai 3:快递
      problemId: null
    };
  },
  watch: {
    'queryForm.params.packageNumber'(val) {
      const bgh = val.split('\n');
      let resetPackageNumber = [];
      if (bgh.length >= 1000) {
        resetPackageNumber = bgh.slice(0, 1000).join('\n');
        this.queryForm.params.packageNumber = resetPackageNumber;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bgh.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'queryForm.params.orderNumberList'(val) {
      const bgh = val.split('\n');
      let resetNumber = [];
      if (bgh.length >= 1000) {
        resetNumber = bgh.slice(0, 1000).join('\n');
        this.queryForm.params.orderNumberList = resetNumber;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bgh.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'queryForm.params.scanNumberList'(val) {
      const bgh = val.split('\n');
      let resetNumber = [];
      if (bgh.length >= 1000) {
        resetNumber = bgh.slice(0, 1000).join('\n');
        this.queryForm.params.scanNumberList = resetNumber;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bgh.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    }
  },
  created() {
    const userId = this.$store.state.user.id;
    this.registerQueryForm.userId = userId;
    this.problemRecoveryForm.userId = userId;
    this.defaultCondition();
    // 查询所有客户信息
    this.getAllCustomer();
    this.searchClick();
    this.getNewestSite();
  },
  methods: {
    setSite(val) {
      if (val) {
        const value = val.split('/');
        this.queryForm.params.problemSiteId = value[0];
        this.queryForm.params.problemSiteName = value[1];
      } else {
        this.queryForm.params.problemSiteId = '';
        this.queryForm.params.problemSiteName = '';
      }
    },
    changeTime() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params.problemCreateStartTime = '';
      this.queryForm.params.problemCreateEndTime = '';
      this.queryForm.params.problemStartTime = '';
      this.queryForm.params.problemEndTime = '';
      this.queryForm.params.closeStartTime = '';
      this.queryForm.params.closeEndTime = '';
      this.queryForm.params.customerSendStartTime = '';
      this.queryForm.params.customerSendEndTime = '';
      this.queryForm.params.problemCreateStartTime = '';
      this.queryForm.params.problemCreateEndTime = '';
      if (this.selectTime === 't0') {
        this.queryForm.params.problemStartTime = today + ' 00:00:00';
        this.queryForm.params.problemEndTime = today + ' 23:59:59';
      }
      if (this.selectTime === 't1') {
        this.queryForm.params.closeStartTime = today + ' 00:00:00';
        this.queryForm.params.closeEndTime = today + ' 23:59:59';
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.customerSendStartTime = today + ' 00:00:00';
        this.queryForm.params.customerSendEndTime = today + ' 23:59:59';
      }
      if (this.selectTime === 't3') {
        this.queryForm.params.problemCreateStartTime = today + ' 00:00:00';
        this.queryForm.params.problemCreateEndTime = today + ' 23:59:59';
      }
    },
    // 获取最新的网点list数据
    getNewestSite() {
      searchSiteIdList().then(res => {
        if (res.code===200) {
          this.siteIdList = [];
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    getAllCustomer() {
      // 查询所有客户信息
      queryAllCustomer().then(res => {
        if (res.code===200) {
          this.customerNameList = [];
          res.data.forEach((list) => {
            this.customerNameList.push({
              id: list.id,
              username: list.username
            });
          });
        }
      });
    },
    changeCode() {
      this.queryForm.params.packageNumber = '';
      this.queryForm.params.scanNumberList = '';
      this.queryForm.params.orderNumberList = '';
    },
    // 提示先选择问题件类型
    getProblemList(val) {
      if (!val && val !== 0) {
        this.$message.warning(this.$t('collectionCenter.selectProblemFirst')); // '请先选择问题件类型'
      }
    },
    changeProblem(val) {
      this.problemId = null;
      if (val) {
        const arr = val.split('~');
        this.problemTypeSub = arr[1];
        this.CauseProblemList.map(item => {
          if (arr[0] === item.dictValue) {
            this.problemId = item.problemId;
          }
        });
      } else {
        this.problemTypeSub = '';
      }
    },
    // 选择问题件类型获取问题件原因list
    problemTypeChange() {
      getAllProblemType(this.registerQueryForm.problemItemType).then(res => {
        if (res.code===200) {
          this.registerQueryForm.problemType = '';
          this.CauseProblemList = res.data;
        }
      }).catch({});
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        return;
      }
      this.queryForm.params.problemStartTime = '';
      this.queryForm.params.problemEndTime = '';
      this.queryForm.params.closeStartTime = '';
      this.queryForm.params.closeEndTime = '';
      this.queryForm.params.customerSendStartTime = '';
      this.queryForm.params.customerSendEndTime = '';
      this.queryForm.params.problemCreateStartTime = '';
      this.queryForm.params.problemCreateEndTime = '';
      this.timeValue = [val[0] + ' 00:00:00', val[1] + ' 23:59:59'];
      if (this.selectTime === 't0') {
        this.queryForm.params.problemStartTime = val[0] + ' 00:00:00';
        this.queryForm.params.problemEndTime = val[1] + ' 23:59:59';
      }
      if (this.selectTime === 't1') {
        this.queryForm.params.closeStartTime = val[0] + ' 00:00:00';
        this.queryForm.params.closeEndTime = val[1] + ' 23:59:59';
      }
      if (this.selectTime === 't2') {
        this.queryForm.params.customerSendStartTime = val[0] + ' 00:00:00';
        this.queryForm.params.customerSendEndTime = val[1] + ' 23:59:59';
      }
      if (this.selectTime === 't3') {
        this.queryForm.params.problemCreateStartTime = val[0] + ' 00:00:00';
        this.queryForm.params.problemCreateEndTime = val[1] + ' 23:59:59';
      }
    },
    BlockNewline() { // 文本域最大输入行数为1000
      const e = window.event || arguments[0];
      const packageNumberLen = this.queryForm.params.packageNumber.split('\n');
      if (!this.notEnter) {
        if (packageNumberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    BlockNewlineOrder() {
      const e = window.event || arguments[0];
      const NumberLen = this.queryForm.params.orderNumberList.split('\n');
      if (!this.notEnter) {
        if (NumberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    BlockNewlineLogistics() {
      const e = window.event || arguments[0];
      const NumberLen = this.queryForm.params.scanNumberList.split('\n');
      if (!this.notEnter) {
        if (NumberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    changeMerchant() { // 切换商户号/名称 清空输入框
      this.merchantCodeList = [];
      this.merchantCodeValue = '';
      this.queryForm.params.merchantCode = '';
    },
    remoteMethod(value) {
      const merchantCodeList = [];
      if (this.queryForm.params.customerId) {
        if (value !== '') {
          this.loading = true;
          let merchantForm = {};
          if (this.merchantChoose === 'm1') {
            merchantForm = {
              customerId: this.queryForm.params.customerId,
              merchantCode: value,
              merchantName: ''
            };
          } else {
            merchantForm = {
              customerId: this.queryForm.params.customerId,
              merchantCode: '',
              merchantName: value
            };
          }
          setTimeout(() => {
            queryAllMerchantCode(merchantForm).then(res => {
              if (res.code===200) {
                res.data.records.forEach(list => {
                  if (list.merchantName) {
                    merchantCodeList.push({
                      name: list.merchantCode + ' / ' + list.merchantName
                    });
                  } else {
                    merchantCodeList.push({
                      name: list.merchantCode
                    });
                  }
                });
              }
            });
            this.merchantCodeList = merchantCodeList;
            this.loading = false;
          }, 200);
        } else {
          this.merchantCodeList = [];
        }
      } else {
        this.merchantCodeValue = '';
        this.$message.warning(this.$t('collectionCenter.chooseCustomerName')); // '请选择客户名称'
      }
    },
    changeValue(value) {
      value = value.split(' / ');
      this.queryForm.params.merchantCode = value[0];
    },
    loadImg(img) {
      if (img) {
        this.isSubmitImg = true;
      } else {
        this.isSubmitImg = false;
      }
    },
    // 问题件登记
    saveClick() { // 保存
      this.$refs['registerQueryRef'].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          // 等待图片加载完成
          if (!this.isSubmitImg && this.isChooseImg) {
            this.$message.warning(this.$t('collectionCenter.loading')); // 正在努力加载中...
            return;
          }
          //                    '是否确认保存?'                        '提示'
          this.$confirm(this.$t('collectionCenter.AreYouSureToSave'), this.$t('collectionCenter.tips'), {
            confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
            cancelButtonText: this.$t('collectionCenter.cancel') // '取消'
          }).then(() => {
            const registerQueryForm = {
              userId: this.registerQueryForm.userId,
              problemType: this.registerQueryForm.problemType,
              problemReason: this.registerQueryForm.problemReason,
              problemItemType: this.registerQueryForm.problemItemType,
              orderIdList: this.idList,
              problemImg: this.upLoadFile,
              problemTypeSub: this.problemTypeSub,
              problemId: this.problemId
            };
            if (this.registerQueryForm.problemType) {
              registerQueryForm.problemType = this.registerQueryForm.problemType.split('~')[0];
            }
            problemRegistration(registerQueryForm).then(res => {
              if (res.code===200 || res.data.code===200) {
                this.registerQueryForm = {
                  userId: this.registerQueryForm.userId, // 用户id
                  problemItemType: '',
                  problemType: '', // 问题件原因
                  problemReason: '', // 备注
                  orderIdList: [] // 订单id
                };
                this.idList = [];
                this.upLoadFile = '';
                this.$refs.registerQueryRef.resetFields();
                this.defaultCondition();
                this.searchClick();
                this.dialogVisible = false;
                this.$refs.okRef.play();
              }
            }).catch((err) => {
              // this.$message.error(err.msg);
              this.$refs.errRef.play();
            });
          }).catch(() => {
            //
          });
        }
      });
    },
    drawerResetClick() { // 置空
      this.registerQueryForm = {
        userId: this.registerQueryForm.userId, // 用户id
        customerId: '', // 客户名称
        packageNumber: '', // 包裹号
        problemItemType: '',
        problemType: '', // 问题件原因
        problemReason: '' // 备注
      };
      this.upLoadFile = '';
      this.$nextTick(() => {
        this.$refs.registerQueryRef.resetFields();
        this.$refs.demoUpload.clearFiles();
      });
    },
    // 问题件登记查询
    defaultCondition() { // 默认查询条件
      this.selectTime = 't0';
      this.selectCode = 'c3';
      const d = new Date();
      const Sday = dayjs(d).format('YYYY-MM-DD') + ' 00:00:00';
      const Eday = dayjs(d).format('YYYY-MM-DD') + ' 23:59:59';
      this.timeValue = [Sday, Eday];
      this.queryForm.params = {
        problemStartTime: Sday,
        problemEndTime: Eday,
        closeStartTime: '', // 揽收开始时间
        closeEndTime: '', // 揽收结束时间
        customerSendStartTime: '', // 客户出仓开始时间
        customerSendEndTime: '', // 客户出仓结束时间
        packageNumber: '',
        orderNumberList: '', // 揽收订单号
        scanNumberList: '', // 物流订单号
        collectStatus: '',
        customerId: '',
        merchantCode: '',
        problemSource: '',
        problemItemType: '',
        problemCreateStartTime: '',
        problemCreateEndTime: '',
        platform: '',
        problemSiteId: '',
        problemSiteName: ''
      };
    },
    searchClick() { // 查询
      if (this.timeValue.length <= 0 && !this.queryForm.params.packageNumber && !this.queryForm.params.orderNumberList && !this.queryForm.params.scanNumberList) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      if (this.queryForm.params.collectStatus === 7) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
      this.queryForm.pageNumber = 1;
      let NumberList = [];
      if (this.selectCode === 'c1') {
        NumberList = this.queryForm.params.packageNumber.split('\n');
        NumberList = NumberList.filter((s) => {
          return s && s.trim();
        });
      }
      if (this.selectCode === 'c2') {
        NumberList = this.queryForm.params.orderNumberList.split('\n');
        NumberList = NumberList.filter((s) => {
          return s && s.trim();
        });
      }
      if (this.selectCode === 'c3') {
        NumberList = this.queryForm.params.scanNumberList.split('\n');
        NumberList = NumberList.filter((s) => {
          return s && s.trim();
        });
      }
      let queryForm = {
        pageNumber: this.queryForm.pageNumber,
        pageSize: this.queryForm.pageSize,
        params: {
          problemStartTime: this.queryForm.params.problemStartTime,
          problemEndTime: this.queryForm.params.problemEndTime,
          closeStartTime: this.queryForm.params.closeStartTime,
          closeEndTime: this.queryForm.params.closeEndTime,
          customerSendStartTime: this.queryForm.params.customerSendStartTime,
          customerSendEndTime: this.queryForm.params.customerSendEndTime,
          packageNumber: this.selectCode === 'c1' ? NumberList.join('\n') : '',
          orderNumberList: this.selectCode === 'c2' ? NumberList.join('\n') : '', // 揽收订单号
          scanNumberList: this.selectCode === 'c3' ? NumberList.join('\n') : '', // 物流订单号
          collectStatus: this.queryForm.params.collectStatus,
          customerId: this.queryForm.params.customerId,
          merchantCode: this.queryForm.params.merchantCode,
          problemSource: this.queryForm.params.problemSource,
          problemItemType: this.queryForm.params.problemItemType,
          problemCreateStartTime: this.queryForm.params.problemCreateStartTime,
          problemCreateEndTime: this.queryForm.params.problemCreateEndTime,
          platform: this.queryForm.params.platform,
          problemSiteId: this.queryForm.params.problemSiteId,
          problemSiteName: this.queryForm.params.problemSiteName
        }
      };
      if ((this.queryForm.params.packageNumber || this.queryForm.params.orderNumberList || this.queryForm.params.scanNumberList) && NumberList.length > 0) {
        queryForm = {
          pageNumber: this.queryForm.pageNumber,
          pageSize: this.queryForm.pageSize,
          params: {
            problemStartTime: '',
            problemEndTime: '',
            closeStartTime: '',
            closeEndTime: '',
            customerSendStartTime: '',
            customerSendEndTime: '',
            packageNumber: this.selectCode === 'c1' ? NumberList.join('\n') : '',
            orderNumberList: this.selectCode === 'c2' ? NumberList.join('\n') : '', // 揽收订单号
            scanNumberList: this.selectCode === 'c3' ? NumberList.join('\n') : '', // 物流订单号
            collectStatus: '',
            customerId: '',
            merchantCode: '',
            problemSource: '',
            problemItemType: '',
            problemCreateStartTime: '',
            problemCreateEndTime: '',
            platform: '',
            problemSiteId: '',
            problemSiteName: ''
          }
        };
        this.timeValue = [];
        this.queryForm.params.collectStatus = '';
        this.queryForm.params.customerId = '';
        this.queryForm.params.merchantCode = '';
        this.queryForm.params.problemSource = '';
        this.queryForm.params.problemItemType = '';
        this.queryForm.params.problemStartTime = '';
        this.queryForm.params.problemEndTime = '';
        this.queryForm.params.problemCreateStartTime = '';
        this.queryForm.params.problemCreateEndTime = '';
        this.queryForm.params.platform = '';
        this.queryForm.params.problemSiteId = '';
        this.queryForm.params.problemSiteName = '';
        this.queryForm.params.closeStartTime = '';
        this.queryForm.params.closeEndTime = '';
        this.queryForm.params.customerSendStartTime = '';
        this.queryForm.params.customerSendEndTime = '';
      }
      localStorage.setItem('pdQueryForm', JSON.stringify(queryForm));
      this.getProblemDeliveryPage();
    },
    resetClick() { // 重置
      this.defaultCondition();
      this.changeMerchant();
    },
    getPageSize() {
      this.idList = [];
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('pdQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      collectProblemPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    getProblemDeliveryPage() { // 表格
      this.idList = [];
      const queryForm = JSON.parse(localStorage.getItem('pdQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      collectProblemPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 问题件处理
    dataRecoveryClick() { // 数据恢复
      if (this.idList.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.checkMsg')); // 请勾选数据
        return;
      }
      this.problemRecoveryForm.orderIdList = this.idList;
      problemRecovery(this.problemRecoveryForm).then(res => {
        if (res.code===200 || res.data.code===200) {
          this.$message.success(this.$t('collectionCenter.restoreSuccess')); // '恢复成功!'
          this.searchClick();
        }
      });
    },
    closeDialog() {
      this.CauseProblemList = [];
    },
    ProblemClick() { // 问题件登记
      if (this.idList.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.checkMsg')); // 请勾选数据
        return;
      }
      if (this.idList.length > 0) {
        setTimeout(() => {
          this.dialogVisible = true;
          this.drawerResetClick();
        }, 300);
      }
    },
    batchSelect(selection, row) {
      const IdList = [];
      selection.forEach(list => {
        IdList.push(list.id);
      });
      this.idList = IdList;
    },
    batchSelectAll(selection) {
      const IdListAll = [];
      selection.forEach(list => {
        IdListAll.push(list.id);
      });
      this.idList = IdListAll;
    },
    beforeUpload(file) {
      this.isChooseImg = true;
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['png', 'bmp', 'jpg', 'jpeg'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        // 仅支持png、bmp、jpg格式的图片
        this.$message.error(this.$t('collectionCenter.OnlyImageFormatIsSupported'));
        return false;
      }
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file); // file转base64
        reader.onload = e => {
          const canvas = document.createElement('canvas');
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            // 图片加载后再添加水印，否则可能报错
            const width = img.width;
            const height = img.height;
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d'); // 绘制2d图形
            ctx.drawImage(img, 0, 0, width, height);
            var basePx = canvas.width;
            // 字体大小  照片添加水印
            var fontSize = basePx / 28; // 水印文字尺寸
            ctx.font = fontSize + 'px Open Sans';
            ctx.fillStyle = 'rgba(255,255,255, 0.5)';
            ctx.shadowOffsetY = 4;
            ctx.shadowBlur = 2;
            ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
            let time = getTimes();
            time = time.split(' ');
            const fontHeight = ctx.measureText(time[0]).fontBoundingBoxAscent + ctx.measureText(time[0]).fontBoundingBoxDescent;
            ctx.fillText(time[0], width - ctx.measureText(time[0]).width - 20, fontHeight);
            ctx.fillText(time[1], width - ctx.measureText(time[1]).width - 20, fontHeight * 2);
            var dataBase64 = canvas.toDataURL(file.type); // 输出压缩后的base64
            // base64转file
            const arr = dataBase64.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            const files = new File(
              [new Blob([u8arr], { type: mime })],
              file.name,
              { type: file.type }
            );
            files.uid = file.uid;
            resolve(files);
          };
        };
      });
    },
    async upLoad(option) {
      const files = option.file;
      const res = await uploadOos(files, 'collectionCenter');
      if (res.code === 200) {
        this.upLoadFile = res.url;
      } else {
        this.$message.error(this.$t('placeOrder.uploadInvoiceErr'), res.msg);
      }
    },
    delImg() { // 问题件登记————删除图片
      this.upLoadFile = '';
      // 清空上传图片
      this.$refs.demoUpload.clearFiles();
    },
    // 全部导出
    download() {
      const query = JSON.parse(localStorage.getItem('pdQueryForm')) || this.queryForm;
      collectProblemExport(query).then(res => {
        goExportList(this);
        // if (res.code===200) {
        //   const h = this.$createElement;
        //   this.$msgbox({
        //     // 提示
        //     title: this.$t('collectionCenter.tips'),
        //     message: h('p', null, [
        //       // 导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载,
        //       h('span', this.$t('collectionCenter.ExportSuccessful')),
        //       // 请勿重复导出!
        //       h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
        //     ]),
        //     showCancelButton: true,
        //     // 立即前往
        //     confirmButtonText: this.$t('collectionCenter.Immediately'),
        //     // 关闭
        //     cancelButtonText: this.$t('collectionCenter.close')
        //   }).then(action => {
        //     if (action === 'confirm') {
        //       this.$router.push({ path: '/views/resource/exportList' });
        //     }
        //   }).catch(() => {
        //   });
        // }
      }).catch(err => {
        console.log(err);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.autoWidth ::v-deep .el-input__prefix { // 下拉框自适应占位
  position: relative;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  padding: 0 25px;
  padding-left: 0;
  margin-left: 20px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  visibility: hidden;
  white-space: nowrap;
}

.autoWidth ::v-deep input {
  position: absolute;
}

.col2 {
  width: auto !important;
}

.siteClass ::v-deep .el-form-item__content {
  margin-left: 0 !important;
  width: 100%;
}

.registerContent ::v-deep .el-form-item__label {
  line-height: 20px;
}

.typesProblemClass {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.typesProblemClass ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.registerContent .uploadClass ::v-deep .el-upload, .registerContent .uploadClass ::v-deep .el-upload-dragger {
  width: 100%;
}

.autoWidth ::v-deep .el-input__prefix { // 下拉框自适应占位
  position: relative;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  padding: 0 25px;
  padding-left: 0;
  margin-left: 20px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  visibility: hidden;
  white-space: nowrap;
}

.autoWidth ::v-deep input {
  position: absolute;
}

.selectClass ::v-deep .el-form-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.selectClass ::v-deep .el-form-item__label {
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}

.selectClass ::v-deep .el-form-item__content {
  margin-left: 0 !important;
  width: 100%;
}

.packageNumberClass ::v-deep textarea.el-textarea__inner {
  max-height: 120px !important;
}

.ProblemDeliveryBox .col10 ::v-deep .el-select {
  width: 100%;
}

.ProblemDeliveryBox .m ::v-deep .el-form-item__content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0px !important;
}

.ProblemDeliveryBox .m ::v-deep .el-form-item__content .selectTimeClass {
  width: auto !important;
}

.ProblemDeliveryBox .selectTimeClass {
  margin-left: 2px;
}
.ProblemDeliveryBox .selectTimeClass.wid {
  width: auto !important;
}

.ProblemDeliveryBox .selectTimeClass ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-size: 14px;
  color: #606266;
  font-weight: bold;
  text-align: right;
  padding: 0 25px;
  padding-left: 0;
}

.imgDelClass {
  margin-left: 10px;
  padding-bottom: 10px;
  transform: scale(1.5);

  &:hover {
    color: red;
  }
}

.enclosureClass {
  margin-top: 15px;
  padding-bottom: 15px;
  box-sizing: border-box;
  overflow: hidden;
}

.enclosureClass ::v-deep .el-upload-list {
  position: absolute;
  left: 54px;
  bottom: -30px;
}

.enclosureClass ::v-deep .el-upload__tip {
  line-height: 15px;
  position: absolute;
  left: 54px;
  bottom: -3px;
}

.selectStyle {
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%;
  cursor: pointer;
  padding-right: 30px;
}

.ProblemDeliveryBox {
  padding: 20px;
  box-sizing: border-box;
}

.ProblemFileRegistration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ProblemDeliveryBox .delMl ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.ProblemDeliveryBox .registerBox ::v-deep .el-select, .ProblemDeliveryBox .selectClass ::v-deep .el-select {
  width: 100%;
}

.ProblemBox ::v-deep .el-button {
  display: block;
  margin: 10px auto;
}
</style>
