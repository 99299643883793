<template>
  <div>
    <el-card class="box-card" style="margin-bottom: 10px;">
      <el-form :model="queryForm" size="small" label-position="top">
        <el-row :gutter="10">
          <el-col :span="6">
            <!--模板名称-->
            <el-form-item
              :label="$t('Resource.TemplateName')"
              prop="name"
            >
              <el-select
                v-model="queryForm.params.name"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option key="all" :label="$t('GlobalSetObj.all')" :value="null" />
                <el-option
                  v-for="(item,index) in smsTemplateList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--状态-->
            <el-form-item :label="$t('GlobalSetObj.status')">
              <el-select
                v-model="queryForm.params.state"
                :placeholder="$t('GlobalSetObj.all')"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option key="all" :label="$t('GlobalSetObj.all')" :value="null" />
                <!--停用-->
                <el-option key="1" :label="$t('collectionCenter.Disable')" :value="0" />
                <!--启用-->
                <el-option key="2" :label="$t('collectionCenter.enable')" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <div style="margin-top: 27px;">
                <el-button type="primary" icon="el-icon-search" @click="searchData()">{{ $t('GlobalSetObj.search') }}</el-button>
                <el-button icon="el-icon-refresh-left" @click="resetForm()">{{ $t('GlobalSetObj.reset') }}</el-button>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="box-card">
      <div v-permit:remove="'but:system-manage:SMSTemplate:add'" style="margin-bottom: 10px">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="small"
          @click="addData()">{{ $t('GlobalSetObj.add') }}</el-button>
      </div>
      <el-table ref="smsTemplateTable" :data="tableData" border>
        <!--序号-->
        <el-table-column
          :label="$t('basicData.serialNumber')" align="center" type="index"
          width="60"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--创建时间-->
        <el-table-column
          v-for="(item, index) in columnData"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :align="item.align"
          :min-width="item.width"
        >
          <template slot-scope="scope">
            <div v-if="item.prop === 'state'">
              <el-tag
                v-if="scope.row[item.prop] === 1"
                size="mini"
              >
                {{ $t('GlobalSetObj.enable') }}
              </el-tag>
              <el-tag
                v-else-if="scope.row[item.prop] === 0"
                size="mini"
                type="danger"
              >
                {{ $t('GlobalSetObj.stop') }}
              </el-tag>
            </div>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column
          :label="$t('orderCenterCont.operation')"
          width="100"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <el-tooltip
                :content="$t('GlobalSetObj.edit')" class="item" effect="dark"
                placement="top"
              >
                <i class="el-icon-edit textOperator edit" @click="editData(scope.row)"/>
              </el-tooltip>
              <el-tooltip
                v-show="scope.row.state === 0"
                v-permit:remove="'but:system-manage:SMSTemplate:edit'"
                :content="$t('GlobalSetObj.enable')" class="item" effect="dark"
                placement="top"
              >
                <i class="el-icon-circle-check textOperator enable" @click="disableData(scope.row, 1)" />
              </el-tooltip>
              <el-tooltip
                v-show="scope.row.state === 1"
                v-permit:remove="'but:system-manage:SMSTemplate:edit'"
                :content="$t('GlobalSetObj.stop')" class="item" effect="dark"
                placement="top"
              >
                <i class="el-icon-remove-outline textOperator stop" @click="disableData(scope.row, 0)" />
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          @size-change="getSizeTableData"
          @current-change="getCurrentTableData"
        />
      </div>
    </el-card>
    <!--新增短信配置-->
    <el-dialog
      :title="$t('Resource.SMSTemplate')"
      :visible.sync="showSMSTemplate"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      width="500px"
      @close="cancelSMSConfiguration"
    >
      <div>
        <el-form
          ref="smsTemplateForm"
          :key="showSMSTemplate"
          :model="smsTemplateForm"
          size="small"
          label-position="top"
        >
          <!--模板名称-->
          <el-form-item
            :label="$t('Resource.TemplateName')"
            prop="name"
            :rules="[
              { required: true, message: $t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-input
              v-model="smsTemplateForm.name"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              maxlength="50"
              show-word-limit
              clearable
            />
          </el-form-item>
          <!--模板文案-->
          <el-form-item
            :label="$t('Resource.TemplateText')"
            prop="content"
            :rules="[
              { required: true, message: $t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-input
              v-model="smsTemplateForm.content"
              type="textarea"
              maxlength="500"
              show-word-limit
              clearable
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              :rows="4"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="showSMSTemplate = false"
        >{{ $t('operationCenter.Cancel') }}</el-button>
        <el-button
          v-permit:remove="'but:system-manage:SMSTemplate:edit'"
          size="small"
          type="primary"
          @click="determineSMSConfiguration"
        >{{ $t('operationCenter.determine') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { apiSmsTemplateAdd, apiSmsTemplateEdit, apiSmsTemplatePage } from '@/api/automaticSorting';

export default {
  name: 'SmsTemplate',
  data() {
    return {
      activeName: 'smsTemplate',
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          name: null, // 模板名称
          state: null
        }
      },
      tableData: [], // 列表数据
      total: 0,
      showSMSTemplate: false,
      smsTemplateForm: {
        id: null,
        name: '', // 模板名称
        content: '',
        state: 1
      },
      smsTemplateList: []
    };
  },
  computed: {
    columnData() {
      return [
        // 创建时间
        { label: this.$t('GlobalSetObj.CreationTime'), prop: 'createTime', width: '180', align: 'center' },
        // 模板名称
        { label: this.$t('Resource.TemplateName'), prop: 'name', width: '180', align: 'center' },
        // 模板文案
        { label: this.$t('Resource.TemplateText'), prop: 'content', width: '180', align: 'center' },
        // 状态
        { label: this.$t('GlobalSetObj.status'), prop: 'state', width: '180', align: 'center' },
        // 创建人
        { label: this.$t('GlobalSetObj.CreatePeople'), prop: 'createUserName', width: '180', align: 'center' },
        // 最新操作人
        { label: this.$t('customerManagements.newOptioner'), prop: 'updateUserName', width: '180', align: 'center' },
        // 最新操作时间
        { label: this.$t('customerManagements.newOptionTime'), prop: 'updateTime', width: '180', align: 'center' }
      ];
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyPress);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyPress);
  },
  created() {
    this.getTableData();
    this.getSmsTemplate();
  },
  methods: {
    updateTable() {
      this.$refs.smsTemplateTable.doLayout();
    },
    handleKeyPress(event) {
      if (event.key === 'Enter') {
        event.preventDefault(); // 阻止默认行为
      }
    },
    // 获取所有短信模板
    getSmsTemplate() {
      const data = {
        pageNumber: 1,
        pageSize: 9999999,
        params: {}
      };
      apiSmsTemplatePage(data).then((res) => {
        if (res.code===200) {
          this.smsTemplateList = res.data.records;
        }
      });
    },
    // 编辑
    editData(row) {
      this.smsTemplateForm = {
        id: row.id,
        name: row.name, // 模板名称
        content: row.content,
        state: row.state
      };
      this.showSMSTemplate = true;
    },
    // 停用\启用
    disableData(row, type) {
      const params = {
        id: row.id,
        name: row.name, // 模板名称
        content: row.content,
        state: type
      };
      const typeText = type === 1 ? this.$t('basicData.AreYouSureEnabled') : this.$t('basicData.AreYouSureDisabled');
      this.$confirm(typeText, this.$t('operationCenter.tips'), {
        confirmButtonText: this.$t('operationCenter.determine'), // '确定',
        cancelButtonText: this.$t('operationCenter.Cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        apiSmsTemplateEdit(params).then(res => {
          if (res.code===200) {
            this.$message({
              type: 'success',
              message: this.$t('operationCenter.TheOperationSucceeded')
            });
            this.getTableData();
          }
        });
      });
    },
    // 搜索
    searchData() {
      this.queryForm.pageNumber = 1;
      this.getTableData();
    },
    // 重置
    resetForm() {
      this.queryForm.params.name = '';
      this.queryForm.params.state = null;
    },
    // 获取表格数据
    getTableData() {
      apiSmsTemplatePage(this.queryForm).then(res => {
        if (res.code===200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 新增
    addData() {
      this.showSMSTemplate = true;
    },
    // 确定新增
    determineSMSConfiguration() {
      this.$refs.smsTemplateForm.validate((valid) => {
        if (valid) {
          if (this.smsTemplateForm.id) {
            apiSmsTemplateEdit(this.smsTemplateForm).then(res => {
              if (res.code===200) {
                this.$message({
                  type: 'success',
                  message: this.$t('operationCenter.TheOperationSucceeded')
                });
                this.getTableData();
                this.getSmsTemplate();
              }
            });
          } else {
            apiSmsTemplateAdd(this.smsTemplateForm).then(res => {
              if (res.code===200) {
                this.$message({
                  type: 'success',
                  message: this.$t('operationCenter.TheOperationSucceeded')
                });
                this.getTableData();
                this.getSmsTemplate();
              }
            });
          }
          this.showSMSTemplate = false;
        }
      });
    },
    // 关闭弹窗
    cancelSMSConfiguration() {
      this.smsTemplateForm = {
        id: null,
        name: '', // 模板名称
        content: '',
        state: 1
      };
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getTableData();
    },
    // 当前页码变化
    getCurrentTableData(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    }
  }
};
</script>

<style scoped>
.block{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.textOperator {
  font-size: 24px;
}
.edit {
  color: #409EFF;
}
.enable {
  color: #0ca040;
}
.stop {
  color: #f56c6c;
}
</style>
