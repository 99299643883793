<template>
  <div>
    <el-button size="small" style="margin-right: 10px;" @click="openColumns">{{ $t('orderCenterCont.CustomizeListColumn') }}</el-button>

    <el-drawer
      :append-to-body="true"
      :visible.sync="showColumn"
      direction="ltr"
      custom-class="drawerClass"
      :title="$t('orderCenterCont.SelectColumn')"
      width="500px"
      @close="closeDrawer()"
    >
      <div style="padding: 20px;height: calc(100vh - 140px);overflow: auto" class="columnTree">
        <el-tree
          :key="dragging"
          ref="elTree"
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
          :data="columnList"
          :default-checked-keys="newCheckedColumns"
          :props="defaultProps"
          check-on-click-node
          default-expand-all
          draggable
          node-key="prop"
          show-checkbox
          @check="handleCheckChange"
          @node-drop="handleDrop"
        />
      </div>
      <div style="padding: 10px 20px;text-align: right;border-top: 1px solid #ccc;">
        <el-button size='small' type="primary" @click="determineColumns()">{{ $t('operationCenter.determine') }}</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { P_TABLE_HEADER, L_TABLE_HEADER } from '@/views/finance/payableManage/feesPayable/enums.js';
import { apiCustomColumnTemplateUpdate, apiCustomColumnTemplateInsert } from '@/api/finance/receivables';

const templateMenu = {
  1: {
    id: '',
    userId: '',
    type: 0,
    menuEnum: 'hut',
    templateName: '应付安俊小屋',
    templateField: '' // 模板字段-column

  },
  2: {
    id: '',
    userId: '',
    type: 0,
    menuEnum: 'ThreeCollect',
    templateName: '应付第三方揽收',
    templateField: '' // 模板字段-column
  },
  3: {
    id: '',
    userId: '',
    type: 0,
    menuEnum: 'AJDDispatch',
    templateName: '应付AJD派送',
    templateField: '' // 模板字段-column
  },
  4: {
    id: '',
    userId: '',
    type: 0,
    menuEnum: 'DSPDispatch',
    templateName: '应付DSP派送',
    templateField: '' // 模板字段-column
  },
  5: {
    id: '',
    userId: '',
    type: 0,
    menuEnum: 'TransportationTrunkFeeder',
    templateName: '应付运输（干/支线）',
    templateField: '' // 模板字段-column
  }
};

export default {
  name: '',

  props: {
    // 费用类型
    payableType: {
      type: [String, Number],
      required: true
    }

    // 默认选中列
    // checkedColumns: {
    //   type: Array,
    //   default: () => [],
    //   required: true
    // },

    // 模板id
    // customColumnId: {
    //   type: [String, Number],
    //   required: true,
    //   default: ''
    // }
  },

  inject: ['checkedColumns', 'customColumnId', 'treeColumns', 'getCheckedColumns', 'getCustomColumnId', 'getTreeColumns', 'getCustomColumnTemplateList'],

  data() {
    return {
      showColumn: false, // 是否显示列
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      P_TABLE_HEADER,
      L_TABLE_HEADER,

      dragging: new Date().getTime()
    };
  },

  computed: {
    columnList() {
      // const children = [1,2].includes(this.payableType) ? L_TABLE_HEADER : P_TABLE_HEADER;
      return [
        {
          label: this.$i18n.t('basicData.all'), // '全部',
          prop: '',
          children: [
            ...this.getTreeColumns()
          ]
        }
      ];
    },

    newCheckedColumns() {
      return this.getCheckedColumns();
    },

    newCustomColumnId() {
      return this.getCustomColumnId();
    }
  },

  activated() {
  },
  created() {
  },

  methods: {
    // 开启弹窗
    openColumns() {
      this.showColumn = true;
    },

    // 选中状态改变
    handleCheckChange(data, checked) {
      console.log(data, checked.checkedKeys);
    },

    handleDrop(draggedNode, insertNode, dropLocation, event) {
      this.dragging = new Date().getTime();
    },

    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.level === 2 && type !== 'inner') {
        return true;
      } else {
        return false;
      }
    },

    allowDrag(node) {
      return node.level === 2;
    },

    // 更新列
    determineColumns() {
      const neKey = this.$refs.elTree.getCheckedKeys();
      // console.log(neKey, 'neKey');
      const columns = [];
      this.columnList[0].children.map(list => {
        if (neKey.includes(list.prop)) {
          columns.push(list);
        }
      });

      const params = templateMenu[this.payableType];
      params.userId = this.$store.state.user.id;
      params.id = this.newCustomColumnId;
      params.templateField = JSON.stringify(columns);
      // console.log(params, 'params');
      // return;
      const fn = this.newCustomColumnId ? apiCustomColumnTemplateUpdate : apiCustomColumnTemplateInsert;
      fn(params).then(res => {
        if (res.code === 200) {
          this.showColumn = false;
          this.$message.success(this.$t('basicData.TheOperationSucceeded'));
          this.getCustomColumnTemplateList();
        }
      });
    },

    closeDrawer() {
      this.showColumn = false;
    }
  }
};
</script>

<style scoped>
.columnTree {
  height: calc(100vh - 130px) !important;
}
</style>
