<template>
  <div class="container">
    <div v-show="isDropQuery" class="headeContainer">
      <div class="searchWrap">
        <el-form ref="queryForm" :model="queryForm" style="width: 50%">
          <el-form-item :label="$t('ChannelManage.ServiceProviderName')">
            <el-input
              v-model.trim="queryForm.params.name"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width: 60%"
              @keyup.enter.native="getSearch"
            />
          </el-form-item>
        </el-form>
        <div style="float:right">
          <el-button plain type="primary" @click="dropQuery">{{ $t('GlobalSetObj.CollapseQuery') }}</el-button>
        </div>
      </div>
      <div class="btnList">
        <!--搜索-->
        <el-button
          icon="el-icon-search" size="small" type="primary"
          @click="getSearch"
        >{{
          $t('GlobalSetObj.search')
        }}
        </el-button>
        <!--重置-->
        <el-button
          icon="el-icon-refresh-left" plain size="small"
          @click="resetForm"
        >{{
          $t('GlobalSetObj.reset')
        }}
        </el-button>
      </div>
      <el-divider />
    </div>
    <!-- 新增 -->
    <el-button
      icon="el-icon-plus" size="small" style="margin-bottom: 15px"
      type="primary" @click="addDrawer"
    >
      {{ $t('GlobalSetObj.add') }}
    </el-button>
    <el-button
      v-show="!isDropQuery" plain style="float: right"
      type="primary" @click="dropQuery"
    >
      {{ $t('GlobalSetObj.ExpandQuery') }}
    </el-button>
    <el-card>
      <!--  表格数据  -->
      <AnjunTableList
        ref="anjunTable"
        :format-time="isTormat"
        :has-operation="true"
        :header-list="tableHeaderList"
        :is-index="true"
        :is-tip="true"
        :table-data="tableData"
        @on-look="toLook"
        @to-SystemName="toSystemName"
      />
      <!--  分页  -->
      <el-pagination
        :current-page="queryForm.number"
        :page-size="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="queryForm.total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0;float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!--添加系统服务商-->
    <el-drawer
      ref="drawer"
      :append-to-body="true"
      :title="$t('ChannelManage.NewSystemServiceProvider')"
      :visible.sync="drawer"
      custom-class="demo-drawer"
      direction="ltr"
      size="50%"
    >
      <div class="demo-drawer__content" style="padding-right: 30px;padding-left: 10px">
        <el-form
          ref="addForm"
          :label-position="$i18n.locale!='zh'?'top':'right'"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          label-width="180px"
        >
          <el-form-item :label="$t('ChannelManage.providerCode')" prop="code">
            <el-input v-model="ruleForm.code" />
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.ServiceProviderName')" prop="name">
            <el-input v-model="ruleForm.name" />
          </el-form-item>
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item :label="$t('GlobalSetObj.DeclaredQuantity')" prop="declaredCountMin">
                <el-input v-model="ruleForm.declaredCountMin" type="number" @blur="checkMinData" />
              </el-form-item>
            </el-col>
            <el-col :span="1" :style="{marginTop:isTormat ? '27px' : ''}">
              <div class="QuestionMark">~</div>
            </el-col>
            <el-col :span="$i18n.locale!='zh' ? 8 : 4" :style="{marginTop:isTormat ? '27px' : ''}">
              <el-form-item label-width="0px" prop="declaredCountMax">
                <el-input v-model="ruleForm.declaredCountMax" type="number" @blur="checkMaxData" />
              </el-form-item>
            </el-col>
            <el-col :span="1" :style="{marginTop:isTormat ? '27px' : ''}">
              <div class="QuestionMark">
                <el-tooltip placement="right-start">
                  <div slot="content">{{ $t('GlobalSetObj.ValueSet') }}<br>{{ $t('GlobalSetObj.Actual') }}</div>
                  <svg-icon icon-class="QuestionMark" />
                </el-tooltip>
              </div>
            </el-col>
          </el-row>
          <el-form-item :label="$t('ChannelManage.RelatedConfig')" prop="variableList">
            <el-link
              :underline="false"
              href="http://www.form-create.com/designer/?fr=home"
              target="_blank"
              type="primary"
            >
              {{ $t('ChannelManage.goRelatedConfig') }}
            </el-link>
            <el-input v-model="ruleForm.variableList" :rows="5" type="textarea" />
          </el-form-item>
        </el-form>
      </div>

      <div class="demo-drawer__footer">
        <el-button style="margin-right: 20px" @click="cancelForm">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="saveSystem">{{ $t('GlobalSetObj.Salve') }}</el-button>
      </div>
    </el-drawer>
    <!--  查看详情  -->
    <el-drawer
      ref="drawer"
      :append-to-body="true"
      :title="drawerInfoName"
      :visible.sync="drawerInfo"
      custom-class="demo-drawer"
      direction="ltr"
      size="50%"
    >
      <el-tabs type="border-card">
        <el-tab-pane :label="$t('ChannelManage.SystemInfo')" class="elTabs">
          <div class="systemInfo">
            <el-form :label-position="$i18n.locale!='zh'?'top':'right'" label-width="180px">
              <el-form-item :label="$t('ChannelManage.providerCode')">
                <div>{{ ruleForm.code }}</div>
              </el-form-item>
              <el-form-item :label="$t('ChannelManage.ServiceProviderName')">
                <div>{{ ruleForm.name }}</div>
              </el-form-item>
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item :label="$t('GlobalSetObj.DeclaredQuantity')" prop="declaredCountMin">
                    <el-input v-model="ruleForm.declaredCountMin" disabled type="number" />
                  </el-form-item>
                </el-col>
                <el-col :span="1" :style="{marginTop:isTormat ? '27px' : ''}">
                  <div class="QuestionMark">~</div>
                </el-col>
                <el-col :span="$i18n.locale!='zh' ? 8 : 4" :style="{marginTop:isTormat ? '27px' : ''}">
                  <el-form-item label-width="0px" prop="declaredCountMax">
                    <el-input v-model="ruleForm.declaredCountMax" disabled type="number" />
                  </el-form-item>
                </el-col>
                <el-col :span="1" :style="{marginTop:isTormat ? '27px' : ''}">
                  <div class="QuestionMark">
                    <el-tooltip placement="right-start">
                      <div slot="content">{{ $t('GlobalSetObj.ValueSet') }}<br>{{ $t('GlobalSetObj.Actual') }}</div>
                      <svg-icon icon-class="QuestionMark" />
                    </el-tooltip>
                  </div>
                </el-col>
              </el-row>
              <el-form-item :label="$t('ChannelManage.RelatedConfig')">
                <div class="createdForm">
                  <form-create
                    :key="newKey"
                    v-model="fApi"
                    :option="option"
                    :rule="variableList"
                  />
                </div>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('ChannelManage.modifyProvider')" class="elTabs1">
          <el-form
            ref="editRuleForm"
            :label-position="$i18n.locale!='zh'?'top':'right'"
            :model="ruleForm"
            :rules="rules"
            class="demo-ruleForm"
            label-width="180px"
            style="padding-right: 10px"
          >
            <el-form-item :label="$t('ChannelManage.providerCode')" prop="code">
              <el-input v-model="ruleForm.code" disabled />
            </el-form-item>
            <el-form-item :label="$t('ChannelManage.ServiceProviderName')" prop="name">
              <el-input v-model="ruleForm.name" />
            </el-form-item>
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item :label="$t('GlobalSetObj.DeclaredQuantity')" prop="declaredCountMin">
                  <el-input v-model="ruleForm.declaredCountMin" type="number" @blur="checkMinData" />
                </el-form-item>
              </el-col>
              <el-col :span="1" :style="{marginTop:isTormat ? '27px' : ''}">
                <div class="QuestionMark">~</div>
              </el-col>
              <el-col :span="$i18n.locale!='zh' ? 8 : 4" :style="{marginTop:isTormat ? '27px' : ''}">
                <el-form-item label-width="0px" prop="declaredCountMax">
                  <el-input v-model="ruleForm.declaredCountMax" type="number" @blur="checkMaxData" />
                </el-form-item>
              </el-col>
              <el-col :span="1" :style="{marginTop:isTormat ? '27px' : ''}">
                <div class="QuestionMark">
                  <el-tooltip placement="right-start">
                    <div slot="content">{{ $t('GlobalSetObj.ValueSet') }}<br>{{ $t('GlobalSetObj.Actual') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </div>
              </el-col>
            </el-row>
            <el-form-item :label="$t('ChannelManage.RelatedConfig')" prop="variableList">
              <el-link
                :underline="false"
                href="http://www.form-create.com/designer/?fr=home"
                target="_blank"
                type="primary"
              >
                {{ $t('ChannelManage.goRelatedConfig') }}
              </el-link>
              <el-input v-model="ruleForm.variableList" :rows="20" type="textarea" />
            </el-form-item>
          </el-form>
          <div class="demo_footer">
            <el-button style="margin-right: 20px" @click="cancelForm">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
            <el-button type="primary" @click="updateSystem">{{ $t('GlobalSetObj.Salve') }}</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
  </div>
</template>

<script>
import AnjunTableList from '@/components/anjun-tableList';
import { addSystemProvider, providerSystemPage, updatSystemProvider } from '@/api/channelManage';
import { minData } from '@/utils/validate';

export default {
  name: 'ServiceProvider',
  components: {
    AnjunTableList
  },
  data() {
    return {
      newKey: new Date().getTime(),
      variableList: [],
      fApi: {},
      option: {
        // 显示重置表单按扭
        submitBtn: false,
        resetBtn: false
      },
      // 是否收起
      isDropQuery: true,
      // 是否格式化成巴西时间
      isTormat: false,
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0,
        params: {
          name: ''
        }
      },
      tableHeaderList: [
        { id: 1, label: this.$t('ChannelManage.providerCode'), prop: 'code', cloWidth: 320 },
        { id: 2, label: this.$t('ChannelManage.ServiceProviderName'), prop: 'name', cloWidth: 310 },
        { id: 3, label: this.$t('GlobalSetObj.CreatePeople'), prop: 'userName' },
        { id: 7, label: this.$t('GlobalSetObj.CreationTime'), prop: 'createTime', cloWidth: 160 }
      ],
      tableData: [],
      // 添加弹窗
      drawer: false,
      ruleForm: {
        code: '',
        name: '',
        variableList: '',
        declaredCountMin: '',
        declaredCountMax: ''
      },
      rules: {
        code: [
          { required: true, message: this.$t('ChannelManage.PleaseProvidercode'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('ChannelManage.PleaseProviderName'), trigger: 'blur' }
        ],
        variableList: [
          { required: true, message: this.$t('ChannelManage.PleaseRelatedConfig'), trigger: 'blur' }
        ],
        declaredCountMin: [
          { required: false, trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (minData(value) !== true) {
                if (minData(value) === 1) {
                  return callback(new Error(this.$t('GlobalSetObj.greaterThan0')));
                }
                if (minData(value) === 2) {
                  return callback(new Error(this.$t('GlobalSetObj.greaterThan99')));
                }
              } else {
                callback();
              }
            }
          }
        ],
        declaredCountMax: [
          { required: false, trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (minData(value) !== true) {
                if (minData(value) === 1) {
                  return callback(new Error(this.$t('GlobalSetObj.greaterThan0')));
                }
                if (minData(value) === 2) {
                  return callback(new Error(this.$t('GlobalSetObj.greaterThan99')));
                }
              } else {
                callback();
              }
            }
          }
        ]
      },
      drawerInfo: false,
      drawerInfoName: ''
    };
  },
  watch: {
    drawer: {
      handler: function(val) {
        this.$refs['addForm']?.resetFields();
      },
      immediate: true
    },
    drawerInfo: {
      handler: function(val) {
        this.$refs['editRuleForm']?.resetFields();
      },
      immediate: true
    }
  },
  mounted() {
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getSearch();
  },
  methods: {
    // 检验
    checkMinData() {
      if (this.ruleForm.declaredCountMin && this.ruleForm.declaredCountMax) {
        if (parseInt(this.ruleForm.declaredCountMin) > parseInt(this.ruleForm.declaredCountMax)) {
          this.$message.error(this.$t('GlobalSetObj.minValueMoreThanMaxValue'));
          return false;
        }
        if (parseInt(this.ruleForm.declaredCountMax) / 2 < parseInt(this.ruleForm.declaredCountMin)) {
          this.$message.error(this.$t('GlobalSetObj.half'));
          return false;
        }
        return true;
      } else {
        return true;
      }
    },
    checkMaxData() {
      if (this.ruleForm.declaredCountMax) {
        if (this.ruleForm.declaredCountMin === '') {
          this.$message.error(this.$t('GlobalSetObj.minDeclaredValue'));
          return false;
        } else {
          if (parseInt(this.ruleForm.declaredCountMin) > parseInt(this.ruleForm.declaredCountMax)) {
            this.$message.error(this.$t('GlobalSetObj.minValueMoreThanMaxValue'));
            return false;
          }
          if (parseInt(this.ruleForm.declaredCountMax) / 2 < parseInt(this.ruleForm.declaredCountMin)) {
            this.$message.error(this.$t('GlobalSetObj.half'));
            return false;
          }
          return true;
        }
      } else {
        return true;
      }
    },
    // 是否收起查询
    dropQuery() {
      this.isDropQuery = !this.isDropQuery;
    },
    // 重置
    resetForm() {
      this.queryForm.number = 1;
      this.queryForm.params.name = '';
      this.getSearch();
    },
    // 搜索
    getSearch() {
      const data = {};
      data.pageSize = this.queryForm.pageSize;
      if (this.queryForm.params.name) {
        this.queryForm.number = 1;
      }
      data.pageNumber = this.queryForm.number;
      data.params = this.queryForm.params;
      providerSystemPage(data).then(res => {
        if (res.code===200) {
          this.tableData = res.data.records;
          this.tableData.map(item => {
            item['look'] = true;
          });
          this.queryForm.total = res.data.total;
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.number = val;
      this.getSearch();
    },
    // 查看
    toLook(row) {
      console.log(row, 'row');
      this.drawerInfo = true;
      this.drawerInfoName = row.name;
      this.$nextTick(_ => {
        this.ruleForm.code = row.code;
        this.ruleForm.name = row.name;
        this.ruleForm.declaredCountMax = row.declaredCountMax;
        this.ruleForm.declaredCountMin = row.declaredCountMin;
        try {
          this.variableList = JSON.parse(row.variableList);
        } catch (e) {
          this.variableList = [];
        }
        this.ruleForm.variableList = row.variableList;
        this.ruleForm.id = row.id;
      });
    },
    // 点开详情
    toSystemName(row) {
      this.toLook(row);
    },
    // 保存修改
    updateSystem() {
      this.$refs['editRuleForm'].validate((valid) => {
        if (valid) {
          if (this.checkMinData() && this.checkMaxData()) {
            updatSystemProvider(this.ruleForm).then(res => {
              if (res.code===200) {
                this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
                this.drawerInfo = false;
                this.getSearch();
              } else {
                this.drawerInfo = false;
              }
            });
          }
        }
      });
    },
    // 点击新增, 弹出窗口
    addDrawer() {
      this.drawer = true;
    },
    cancelForm() {
      this.drawer = false;
      this.drawerInfo = false;
    },
    saveSystem() {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          if (this.ruleForm.declaredCountMin.length > 0) {
            if (this.ruleForm.declaredCountMax === '') {
              this.$message.error(this.$t('GlobalSetObj.maxDeclaredValue'));
              return;
            }
          }
          if (this.checkMinData() && this.checkMaxData()) {
            this.ruleForm.id = null;
            addSystemProvider(this.ruleForm).then(res => {
              if (res.code===200) {
                this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
                this.drawer = false;
                this.getSearch();
              } else {
                this.drawer = false;
              }
            });
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.QuestionMark {
  width: 30px;
  text-align: center;
  margin-top: 10px;
}

.container {
  padding: 15px;
}

.searchWrap {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.demo-drawer {
  position: relative;
}

.demo-drawer__footer {
  width: 100%;
  border-top: 1px solid #cccccc;
  position: absolute;
  display: flex;
  justify-content: right;
  padding: 10px;
  right: 0;
  bottom: 0;
}

.container /deep/ .el-drawer__header {
  margin-bottom: 15px;
}

.container /deep/ .el-tabs__content {
  height: 100%;
}

.container /deep/ .el-tabs__content {
  height: 80%;
}

.elTabs {
  height: calc(100vh - 100px);
  overflow: auto;
}

.elTabs1 {
  /*height: 90%;*/
  height: calc(100vh - 100px);
  overflow: auto;
}

.demo_footer {
  float: right;
  height: 50px;
  margin-bottom: 15px;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>
