<template>
  <div class="Box">
    <el-form style="margin-bottom: 20px;" class="selectClass">
      <el-row type="flex" justify="start" style="flex-wrap: wrap;">
        <el-col :span="10" style="width: auto;">
          <el-form-item :label="$t('collectionCenter.vehicleMsgConfirmTime') + '：'">
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :range-separator="$t('collectionCenter.to')"
              :start-placeholder="$t('collectionCenter.StartTime')"
              :end-placeholder="$t('collectionCenter.EndTime')"
              @change="dateChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7" style="width: auto;">
          <!-- 车牌号： -->
          <el-form-item :label="$t('collectionCenter.licensePlateNum')">
            <div class="BlockNewlineClass">
              <el-input
                v-model="queryForm.params.vehicleNumbers"
                type="textarea"
                :placeholder="$t('collectionCenter.BatchQuery')"
                @blur="ft"
                @keydown.enter.native="BlockNewline"
              />
            </div>
            <!-- 支持批量查询(回车换行分割) -->
          </el-form-item>
        </el-col>
        <el-col :span="7" style="width: auto;">
          <!-- 揽收网点： -->
          <el-form-item :label="$t('collectionCenter.LSSite')">
            <el-select
              v-model="queryForm.params.siteIdList"
              multiple
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              filterable
              clearable
              style="width: 100%"
              @focus="getNewestSite"
            >
              <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-col :span="12">
        <el-button
          v-permit:remove="'btn:collection-center:receive-info-verify-query:find'"
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="searchClick"
        >{{ $t('collectionCenter.search') }}</el-button>
        <!-- 查询 -->
        <el-button
          v-permit:remove="'btn:collection-center:receive-info-verify-query:reset'"
          icon="el-icon-refresh"
          size="mini"
          @click="resetClick"
        >{{ $t('collectionCenter.reset') }}</el-button>
        <!-- 重置 -->
        <el-button
          v-permit:remove="'btn:collection-center:receive-info-verify-query:exportAll'"
          icon="el-icon-upload2"
          size="mini"
          type="primary"
          :class="isClickAll ? 'onlyClickOnce10' : ''"
          @click="download"
        >{{ $t('collectionCenter.allExport') }}</el-button>
        <!-- 全部导出 -->
      </el-col>
    </el-row>

    <el-table
      ref="tableList"
      :max-height="600"
      border
      :data="tableDate"
      style="margin-top: 20px;"
    >
      <el-table-column
        type="selection"
        width="55"
      />
      <!-- 序号 -->
      <el-table-column :label="$t('collectionCenter.ind')" type="index" width="60" align="center">
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="vehicleNumber"
        :label="$t('collectionCenter.licensePlateNumber')"
      /><!-- 车牌号 -->
      <el-table-column
        align="center"
        prop="driverName"
        :label="$t('collectionCenter.driverName')"
      /><!-- 司机姓名 -->
      <el-table-column
        align="center"
        prop="siteName"
        :label="$t('collectionCenter.LanShouSite')"
      /><!-- 揽收网点 -->
      <el-table-column
        align="center"
        prop="vehicleConfirmName"
        :label="$t('collectionCenter.vehicleMsgConfirmPeople')"
      /><!-- 车辆信息确认人 -->
      <el-table-column
        align="center"
        prop="loadVehicleConfirmTime"
        :label="$t('collectionCenter.vehicleMsgConfirmTime')"
      /><!-- 车辆信息确认时间 -->
      <el-table-column
        align="center"
        prop="loadVehicleBagConfirmTime"
        :label="$t('collectionCenter.loadPkgConfirmTime')"
      /><!-- 装车包裹确认时间 -->
      <el-table-column
        align="center"
        prop="loadVehicleBagConfirmUserName"
        :label="$t('collectionCenter.loadPkgConfirmPeople')"
      /><!-- 装车包裹确认人 -->
      <el-table-column
        align="center"
        prop="loadVehicleBagCount"
        :label="$t('collectionCenter.loadPkgBagSum')"
      /><!-- 装车包裹袋数 -->
      <el-table-column
        align="center"
        prop="loadVehicleTicketNum"
        :label="$t('collectionCenter.loadPkgTicketSum')"
      /><!-- 装车包裹票数 -->
      <el-table-column
        v-if="isLoadSumConfirm || isDel"
        align="center"
        :label="$t('collectionCenter.operation')"
      >
        <template slot-scope="scope">
          <!-- 装车数量确认 -->
          <div
            v-if="isLoadSumConfirm"
            @click="loadSumVerify(scope.row)"
            :class="scope.row.confirmStatus == 1 ? 'disBtn' : 'lsvBtnClass'"
          >{{ $t('collectionCenter.loadSumConfirm') }}</div>
          <!-- 删除 -->
          <div
            v-if="isDel"
            style="margin-top: 10px;"
            :class="scope.row.confirmStatus == 1 ? 'disBtn' : 'lsvBtnClass'"
          >
            <el-popconfirm
              :title="$t('collectionCenter.deleteSure')"
              @confirm="delLoadSum(scope.row)"
            ><!-- 确定删除吗？ -->
              <div slot="reference" style="cursor: pointer;">{{ $t('collectionCenter.Delete') }}</div><!-- 删除 -->
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="loadVehicleDesc"
        :label="$t('collectionCenter.loadDataConfirmRemark')"
      /><!-- 装车数据确认备注 -->
    </el-table>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getTablePage"
        @size-change="getPageSize"
      />
    </div>
    <!--  车辆信息  -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="60%"
      @close="handleClose"
    >
      <div style="display: flex;justify-content: center;align-items: flex-start">
        <div class="loadSignImgClass">
          <img :src="carList.loadSignImg" alt="">
        </div>
        <div>
          <div class="dialogClass">
            <!--  车牌号：  -->
            <span>{{ $t('collectionCenter.licensePlateNum') }}{{ carList.vehicleNumber }}</span>
            <!--  装车总袋数：  -->
            <span>{{ $t('collectionCenter.totalNumberBagsLoaded') }}：{{ carList.allBagCount }}</span>
            <!--  装车总票数：  -->
            <span>{{ $t('collectionCenter.carLoadingVotes') }}：{{ carList.allTicketCount }}</span>
            <!--  装车时间：  -->
            <span>{{ $t('collectionCenter.loadTime') }}：{{ carList.loadVehicleTime }}</span>
          </div>
          <div class="scanClass">
            <span style="min-width: 55px;">{{ $t('collectionCenter.note') }}</span><!--  备注：  -->
            <el-input
              v-model="loadVehicleDesc"
              clearable
              type="textarea"
            />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--  取消  -->
        <el-button @click="handleClose">{{ $t('collectionCenter.cancel') }}</el-button>
        <!--  确认  -->
        <el-button type="primary" @click="loadSumConfirm">{{ $t('collectionCenter.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSiteByUser,
  scanConfirmPage,
  scanConfirmExport,
  confirmLoadVehicleRecord,
  scanConfirm,
  scanConfirmDel
} from '@/api/lanshou';
import dayjs from 'dayjs';

export default {
  name: 'ReceiveInfoVerifyQuery',
  data() {
    return {
      isDel: this.$store.getters.button_permissions.includes('btn:collection-center:receive-info-verify-query:del'),
      isLoadSumConfirm: this.$store.getters.button_permissions.includes('btn:collection-center:receive-info-verify-query:loadSumConfirm'),
      isClickAll: false,
      notEnter: true, // 是否允许换行
      timeValue: [],
      siteList: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          confirmStartTime: '',
          confirmEndTime: '',
          siteIdList: [],
          vehicleNumbers: ''
        }
      },
      total: 0,
      tableDate: [],
      dialogVisible: false,
      carList: {
        id: '',
        vehicleNumber: '',
        allBagCount: '',
        allTicketCount: '',
        loadSignImg: '',
        tagNumber: '',
        loadVehicleTime: ''
      },
      loadVehicleDesc: ''
    };
  },
  watch: {
    'queryForm.params.vehicleNumbers'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 1000) {
        number = bghTrim.slice(0, 1000).join('\n');
        this.queryForm.params.vehicleNumbers = number;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bghTrim.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    }
  },
  created() {
    this.isClickAll = false;
    this.resetClick();
    this.searchClick();
    this.getNewestSite();
  },
  methods: {
    getNewestSite() {
      getSiteByUser().then(res => {
        if (res.code===200) {
          this.siteList = [];
          res.data.forEach(data => {
            this.siteList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.confirmStartTime = '';
        this.queryForm.params.confirmEndTime = '';
        return;
      }
      this.queryForm.params.confirmStartTime = val[0];
      this.queryForm.params.confirmEndTime = val[1];
      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-1, 'year').format('YYYY-MM-DD') + ' 23:59:59';
        if (val[1] > endTime) {
          this.$message({
            // 最多可查询到一年的数据
            message: this.$t('collectionCenter.moreYear'),
            type: 'warning'
          });
          const d = new Date();
          const today = dayjs(d).format('YYYY-MM-DD');
          this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
          this.queryForm.params.confirmStartTime = today + ' 00:00:00';
          this.queryForm.params.confirmEndTime = today + ' 23:59:59';
        }
      }
    },
    ft() {
      const val = this.queryForm.params.vehicleNumbers.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.vehicleNumbers = bghTrim.join('\n');
    },
    BlockNewline() { // 文本域最大输入行数为1000
      const e = window.event || arguments[0];
      this.ft();
      const numberLen = this.queryForm.params.vehicleNumbers.split('\n');
      if (!this.notEnter) {
        if (numberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    resetClick() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params = {
        confirmStartTime: today + ' 00:00:00',
        confirmEndTime: today + ' 23:59:59',
        siteIdList: [],
        vehicleNumbers: ''
      };
    },
    searchClick() {
      if (!this.queryForm.params.confirmStartTime || !this.queryForm.params.confirmEndTime) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      this.queryForm.pageNumber = 1;
      localStorage.setItem('rivqForm', JSON.stringify(this.queryForm));
      this.getTablePage();
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('rivqForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      scanConfirmPage(queryForm).then(res => {
        if (res.code===200) {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      });
    },
    getTablePage() {
      const queryForm = JSON.parse(localStorage.getItem('rivqForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      scanConfirmPage(queryForm).then(res => {
        if (res.code===200) {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      });
    },
    download() {
      this.isClickAll = true;
      const query = JSON.parse(localStorage.getItem('rivqForm')) || this.queryForm;
      const queryForm = {
        params: query.params
      };
      scanConfirmExport(queryForm).then(res => {
        if (res.code===200) {
          const h = this.$createElement;
          this.$msgbox({
            // '提示'
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', this.$t('collectionCenter.ExportSuccessful')),
              // '请勿重复导出！'
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // '立即前往'
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // '关闭'
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          }).catch(() => {});
        }
      });
    },
    delLoadSum(row) {
      scanConfirmDel(row.id).then(res => {
        if (res.code===200) {
          this.searchClick();
        }
      });
    },
    loadSumVerify(row) {
      const msgForm = {
        id: row.id,
        vehicleNumber: row.vehicleNumber,
        vehicleConfirmTime: row.loadVehicleConfirmTime,
        driverName: row.driverName,
        collectSite: row.siteName,
        vehicleConfirm: row.vehicleConfirmName
      };
      confirmLoadVehicleRecord(msgForm).then(res => {
        if (res.code===200) {
          this.dialogVisible = true;
          this.carList = res.data;
          this.carList.id = row.id;
          this.loadVehicleDesc = row.loadVehicleDesc;
        }
      });
    },
    loadSumConfirm() {
      const submitForm = {
        id: this.carList.id,
        vehicleNumber: this.carList.vehicleNumber,
        allBagCount: this.carList.allBagCount,
        allTicketCount: this.carList.allTicketCount,
        loadSignImg: this.carList.loadSignImg,
        tagNumber: this.carList.tagNumber,
        loadVehicleDesc: this.loadVehicleDesc,
        loadVehicleTime: this.carList.loadVehicleTime
      };
      scanConfirm(submitForm).then(res => {
        if (res.code===200) {
          this.dialogVisible = false;
          this.searchClick();
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.loadSignImgClass{
  width: 400px;
  margin-right: 10px;
  img{
    width: 100%;
    height: auto;
  }
}
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.BlockNewlineClass ::v-deep textarea.el-textarea__inner {
  min-height: 40px !important;
  max-height: 120px !important;
}
// 防止按钮重复点击
@keyframes throttle{
  from{
    filter: opacity(40%);
    pointer-events: none;
  }
  to{
    pointer-events: all;
  }
}
.onlyClickOnce10{
  animation: throttle 10s step-end forwards;
}
.onlyClickOnce10:active{
  filter: opacity(40%);
  animation: none;
}

.selectClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selectClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.selectClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
  width: 100%;
}
.selectClass ::v-deep .el-select {
  width: 100%;
}
.disBtn{
  padding: 3px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: not-allowed;
  color: #dcdfe6;
  pointer-events: none;
}
.lsvBtnClass{
  padding: 3px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}
.lsvBtnClass::before{
  color: #409eff;
  border: 1px solid #409eff;
}

.dialogClass{
  padding: 20px;
  border: 1px solid #b1b3b7;
  border-radius: 10px;
  box-sizing: border-box;
  span{
    font-size: 19px;
    display: block;
    margin: 10px 0;
  }
}

.scanClass{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  font-size: 18px;
}
.scanClass ::v-deep .el-input{
  width: 30%;
}
.scanClass ::v-deep .el-input .el-input__inner{
  height: 45px;
}
</style>
