<template>
  <div>
    <el-form
      ref="ruleForm" v-loading="loading" :model="ruleForm"
      :rules="rules" label-width="10px" class="demo-ruleForm"
    >
      <el-form-item label="" prop="name">
        <el-input
          v-model="ruleForm.name"
          type="textarea"
          :autosize="{ minRows: 10, maxRows: 30}"
          :placeholder="tips"
        />
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="submitForm('ruleForm')">{{ $t('GlobalSetObj.determine') }}</el-button>
        <el-button size="small" @click="()=>onClose(undefined)">{{ $t('GlobalSetObj.cancel') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { basicsRow as basicsObj, addQuery, asyncApiClaimformOrderDetail } from './utils.js';

// const query = {
//   claimsNode: 0, // 理赔节点
//   orderType: 0, // 揽收类别
//   customerId: '', // 客户
//   orderNumbers: []
// };

export default {
  name: '',

  props: {
    basicsRow: {
      type: Object,
      default: () => {
        return {
          ...basicsObj
        };
      }
    }
  },

  data() {
    return {
      ruleForm: {
        name: ''
      },

      rules: {
        name: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'blur' }
        ]
      },

      loading: false
    };
  },

  computed: {
    tips() {
      // claimsNode 2 揽收 3 派送
      if (this.basicsRow.claimsNode === 3) {
        return this.$t('ClaimsManagement.multipleOrdersOrAnjun');
      } else if (this.basicsRow.claimsNode === 2 && this.basicsRow.orderType === 2) { // orderType 2 普通揽收 3 备货揽收
        return this.$t('ClaimsManagement.multipleOrdersOrLanshou');
      } else {
        return this.$t('trajectory.multipleOrders');
      }
    }
  },

  created() {
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }
        const { name } = this.ruleForm;
        const arr = name.split(/[(\r\n)]+/).map(item => {
          return item.trim();
        }).filter(item => !!item);

        if (arr.length === 0) {
          this.$message({
            showClose: true,
            message: this.$t('GlobalSetObj.pleaseInputContent'),
            type: 'error'
          });
          return false;
        }

        if (arr.length > 20000) {
          this.$message({
            showClose: true,
            message: this.$t('receivablePayable.Only20000PiecesImport'),
            type: 'error'
          });
          return false;
        }

        this.asyncGetList(arr);
      });
    },

    asyncGetList(arr) {
      const { claimsNode, orderType, customerId } = this.basicsRow;

      const obj = {
        ...addQuery,
        claimsNode,
        orderType,
        customerId,
        orderNumbers: arr
        // 'claimsNode': 2,
        // 'orderType': 2,
        // 'customerId': '24005',
        // orderNumbers: ['CL2023042009562978836',
        //   'CL2023042009562978837',
        //   'CL2023042009562978838']
      };

      this.loading = true;

      // const basicsRow = this.basicsRow;

      asyncApiClaimformOrderDetail(obj, false).then(res => {
        if (res.length === 0) { return; }
        console.log(res, 'add-order');
        this.onClose(res);
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    /**
     * 关闭
     * @param {[*] | undefined} row
     */
    onClose(row) {
      this.$emit('close', row);
      // this.$emit('click');
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
