var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-form",
                {
                  ref: "formQuery",
                  attrs: { "label-position": "top", size: "small" },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("collectionCenter.CustomerName"),
                                prop: "region",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "operationCenter.PleaseSelect"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  on: { change: _vm.selectCustomeId },
                                  model: {
                                    value: _vm.queryForm.customerName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm,
                                        "customerName",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.customerName",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.all"),
                                      value: null,
                                    },
                                  }),
                                  _vm._l(
                                    _vm.customerList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.username,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("collectionCenter.merchant"),
                                prop: "merchantCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: !_vm.queryForm.customerName,
                                    placeholder: _vm.$t(
                                      "placeOrder.pleaseChoose"
                                    ),
                                    "remote-method": _vm.eventEnter,
                                    clearable: "",
                                    filterable: "",
                                    remote: "",
                                    "reserve-keyword": "",
                                  },
                                  on: {
                                    clear: function ($event) {
                                      return _vm.eventEnter("")
                                    },
                                  },
                                  model: {
                                    value: _vm.queryForm.merchantCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm,
                                        "merchantCode",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.merchantCode",
                                  },
                                },
                                _vm._l(_vm.commercialList, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        label: item.merchantName,
                                        value: item.merchantCode,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { float: "left" } },
                                        [
                                          _vm._v(
                                            _vm._s(item.merchantName || "--")
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "20px",
                                            "margin-right": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.merchantCode || "--")
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("orderCenterCont.region"),
                                prop: "region",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "operationCenter.PleaseSelect"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.queryForm.region,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryForm, "region", $$v)
                                    },
                                    expression: "queryForm.region",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "0",
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.all"),
                                      value: null,
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "1",
                                    attrs: {
                                      label: "Midwest",
                                      value: "Midwest",
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "2",
                                    attrs: { label: "North", value: "North" },
                                  }),
                                  _c("el-option", {
                                    key: "3",
                                    attrs: {
                                      label: "Northeast",
                                      value: "Northeast",
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "4",
                                    attrs: { label: "South", value: "South" },
                                  }),
                                  _c("el-option", {
                                    key: "5",
                                    attrs: {
                                      label: "Southeast",
                                      value: "Southeast",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.Estado"),
                                prop: "state",
                              },
                            },
                            [
                              _c("el-select-virtual-list", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseChoose"
                                  ),
                                  "select-data": _vm.selectData,
                                  clearable: "",
                                },
                                on: { change: _vm.selectChange },
                                model: {
                                  value: _vm.queryForm.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "state", $$v)
                                  },
                                  expression: "queryForm.state",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("orderCenterCont.dueTime01"),
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: false,
                                  "default-time": ["00:00:00", "23:59:59"],
                                  "end-placeholder": _vm.$t(
                                    "operationCenter.endDate"
                                  ),
                                  "picker-options": _vm.pickerOptions,
                                  "range-separator":
                                    _vm.$t("operationCenter.to"),
                                  "start-placeholder": _vm.$t(
                                    "operationCenter.startDate"
                                  ),
                                  "popper-class": "dateTimeClass",
                                  type: "datetimerange",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                },
                                on: { blur: _vm.refreshDate },
                                model: {
                                  value: _vm.dateArr,
                                  callback: function ($$v) {
                                    _vm.dateArr = $$v
                                  },
                                  expression: "dateArr",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-search",
                                      size: "small",
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.searchTable(1)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("GlobalSetObj.search")) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-refresh-left",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.searchTable(2)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("operationCenter.reset")) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.exportsTable()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("operationCenter.exports"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      input: function () {
                                        return _vm.searchTable(1)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryForm.dataType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.queryForm, "dataType", $$v)
                                      },
                                      expression: "queryForm.dataType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: 1 } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("collectionCenter.ballot")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: 2 } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "orderCenterCont.proportions"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "sheind-table" },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    "cell-class-name": _vm.tableCellClassName,
                    data: _vm.tableData,
                    "row-class-name": _vm.tableRowClassName,
                    "span-method": _vm.arraySpanMethod,
                    border: "",
                  },
                },
                [
                  _vm._l(_vm.columnList, function (item, ind) {
                    return _c("el-table-column", {
                      key: ind,
                      attrs: {
                        align: item.align,
                        label: item.label,
                        "min-width": item.width,
                        prop: item.prop,
                        "show-overflow-tooltip": true,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row[item.prop] !== "" &&
                                          scope.row[item.prop] !== null &&
                                          scope.row[item.prop] !== undefined
                                          ? scope.row[item.prop]
                                          : "--"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _vm._l(_vm.currentColumnList, function (item, ind) {
                    return _c("el-table-column", {
                      key: ind + item.prop,
                      attrs: {
                        align: item.align,
                        label: item.label,
                        "min-width": item.width,
                        prop: item.prop,
                        "show-overflow-tooltip": true,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    class:
                                      _vm.queryForm.dataType === 1 &&
                                      ![
                                        "订单数Qtd. de Pedidos",
                                        "已签收数Qtd. de pacotes entregues",
                                        "签收率Taxa de Pacotes Entregues",
                                        "准时签收量Qtd. de Entregas Pontuais",
                                        "准时签收率Taxa de Entregas Pontuais",
                                      ].includes(scope.row.Region)
                                        ? "table-active"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getVoteDetails(
                                          scope.row,
                                          item.prop
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row[item.prop] !== "" &&
                                            scope.row[item.prop] !== null &&
                                            scope.row[item.prop] !== undefined
                                            ? scope.row[item.prop]
                                            : "--"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("orderCenterCont.votesDetails"),
            visible: _vm.dialogVisible,
            center: "",
            direction: "ltr",
            size: "990px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.closeDrawer,
          },
        },
        [
          _c("detail", {
            ref: "votesDetails",
            attrs: {
              "customer-list": _vm.customerList,
              "query-data": _vm.queryData,
              "select-data": _vm.selectData,
            },
            on: { "call-parent-method": _vm.parentMethod },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }