import { apiProviderPage } from '@/api/finance/basicData';
import { apiCommonSite } from '@/api/logisticsOrders';
import { getUserPage } from '@/api/user';
import { feePage } from '@/api/finance/partition';
import dayjs from 'dayjs';

// 获取数据
export const AJD_initGetData = (() => {
  const FEES_PAYABLE = 'FEES_PAYABLE';
  const providerList = 'providerList'; // 应付主体选项
  const outletsList = 'outletsList'; // 获取站点
  const operatorList = 'operatorList'; // 操作人
  const feeTypeOption = 'feeTypeOption'; // 费用类型

  const row = {
    [providerList]: [],
    [outletsList]: [],
    [operatorList]: [],
    [feeTypeOption]: []
  };

  // 从缓存中提前数据
  const getStorageData = (key) => {
    const data = JSON.parse(sessionStorage.getItem(FEES_PAYABLE)) || {};

    if (data && data[key] && data[key].length > 0) {
      return data[key];
    }
    return undefined;
  };

  /**
   * 获取主体数据
   */
  const Providerparam = {
    pageSize: 9999999,
    pageNumber: 1,
    params: {
      state: 1
    }
  };
  const getProviderPage = () => {
    const arr = getStorageData(providerList);

    if (arr) {
      return Promise.resolve(arr);
    }

    return apiProviderPage(Providerparam, false).then(res => {
      const { records } = res.data;
      row[providerList] = records;
      return records;
    }).catch(err => {
      console.error(err);
      return [];
    });
  };

  /**
   * 获取网点数据
   * @returns
   */
  const Siteparam = {
    pageNumber: 1,
    pageSize: 9999999,
    params: {
      siteStatus: undefined,
      name: ''
    }
  };
  const getSitePageList = () => {
    const arr = getStorageData(outletsList);

    if (arr) {
      return Promise.resolve(arr);
    }
    return apiCommonSite(Siteparam, false).then(res => {
      // this.outletsList = res.data.records;
      const { records } = res.data;
      row[outletsList] = records.map((item) => {
        return {
          id: item.id,
          name: item.name
        };
      });
      return records;
    }).catch(err => {
      console.error(err);
      return [];
    });
  };

  /**
   * 获取操作人
   */
  const OperatorqueryForm = {
    pageSize: 9999999,
    pageNumber: 1,
    params: {
      realName: '',
      accountState: 1
    }
  };
  const getOperatorList = () => {
    const arr = getStorageData(operatorList);

    if (arr) {
      return Promise.resolve(arr);
    }
    return getUserPage(OperatorqueryForm, false).then((response) => {
      // if (response.code===200) {
      const { records } = response.data;
      //   this.operatorList = records;
      // }
      row[operatorList] = records;
      return records;
    }).catch(err => {
      console.error(err);
      return [];
    });
  };

  const feePageparam = {
    orderDirection: 'DESC',
    pageNumber: 1,
    pageSize: 10000,
    params: {
      state: 1,
      attribute: 2, // 应付
      businessType: 3 // AJD费用
    }
  };

  // 获取费用类型
  const getExpenseType = () => {
    // const arr = getStorageData(feeTypeOption);

    // if (arr) {
    //   return Promise.resolve(arr);
    // }
    return feePage(feePageparam, false).then(res => {
      // @ts-ignore
      if (res.code === 200) {
        const { records } = res.data;
        row[feeTypeOption] = records;
        return records;
      }
    }).catch(err => {
      console.error(err);
      return [];
    });
  };

  const AJD_initGetData = () => {
    const arr = [getProviderPage(), getSitePageList(), getOperatorList(), getExpenseType()];
    return Promise.all(arr).then(res => {
      sessionStorage.setItem(FEES_PAYABLE, JSON.stringify(row));
      return res;
    });
  };

  return AJD_initGetData;
})();


/**
 * @description 派件员选中数据筛选字符串切割
 * @param  {any[]} data
 * @returns {any[]}
 */
export const filterAndFormatData = (data) => {
  return data.map((item) => {
    const fields = item.split(/:/);
    return {
      source: fields[0],
      dispatcherName: fields[1],
      dispatcherId: fields[2]
    };
  });
};

/**
 * 获取周期
 * 规则:
 *   1. 需要*个日期，不管当前日期
 *   2. 如果当月超过（含） option.generationCycle1Date （例如17号），当月只有第一个周期
 *   3. 如果当月超过（含） option.generationCycle1Date （例如2号），生成上个月周期2
 * @param {number} len 需要的周期数量
 * @param {import('./types').IGenerateOption} option 配置
 * @returns {TGReturn[]}
 */
export const generatePeriods = (len, option) => {
  const { oneTitle, twoTitle, generationCycle2Date, generationCycle1Date } = option;

  const dateArr = getDateList(len, oneTitle, twoTitle);
  const day = dayjs().date(); // 当前几号

  // 不超过则，不生成上个月周期2
  if (day < generationCycle2Date) {
    dateArr.pop(); // 剔除上个月的周期2
    return setDataArr(dateArr, len);
  }

  // 如果超过则生成本月周期1
  if (day >= generationCycle1Date) {
    const str = dayjs().format('YYYY-MM') + ` ${oneTitle}1`;

    /** @type {TGReturn} */
    const obj = {
      years: dayjs().format('YYYY-MM'),
      period: 1,
      label: str
    };
    dateArr.push(obj);
    return setDataArr(dateArr, len);
  }

  return setDataArr(dateArr, len);
};

/**
 * 获取周期，如果超过指定周期数量，则删除多余的周期
 * @param {TGReturn[]} arr 周期
 * @param {number} len 指定周期数量
 * @returns {TGReturn[]}
 */
const setDataArr = (arr, len) => {
  const newArr = [...arr];
  const l = arr.length;
  if (l > len) {
    newArr.splice(0, l - len);
  }
  return newArr;
};

/**
 * 获取周期 生成一个不含当月的周期，例如当前是8月，则会生成
 * @param {number} len 周期长度
 * @param {string} oneTitle 第一个周期的标题
 * @param {string} twoTitle 第而个周期的标题
 * @returns {TGReturn[]}
 */
const getDateList = (len, oneTitle, twoTitle) => {
  const minLen = Math.max(len, 2); // 最小生成2个周期
  const val = Math.ceil(minLen / 2) + 2;

  /** @type {TGReturn[]} */
  const dateArr = [];

  // 不生成本月的周期
  for (let i = 1; i < val; i++) {
    const le = dayjs().subtract(i, 'month').format('YYYY-MM');

    /** @type {TGReturn[]} */
    const obj = [{
      years: `${le}`,
      period: 1, // 周期
      label: `${le} ${oneTitle}1`
    }, {
      years: `${le}`,
      period: 2,
      label: `${le} ${twoTitle}2`
    }];
    dateArr.unshift(...obj);
  }

  return dateArr;
};

