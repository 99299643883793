var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "z-index": "999999 !important",
        "background-color": "#ffffff",
      },
    },
    [
      _c("div", { staticClass: "sysInfo" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", [
            _c("span", [_vm._v(_vm._s(_vm.$t("GlobalSetObj.sysMessage")))]),
            _vm._m(0),
          ]),
          _c("div", [
            _c("i", {
              staticClass: "iconfont icon-a-zu389",
              staticStyle: { "font-size": "14px" },
              on: { click: _vm.closeMessage },
            }),
          ]),
        ]),
        _c("div", { staticClass: "infoContant" }, [
          _vm.messageObj.msgType === 4
            ? _c(
                "div",
                [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.messageObj.msgData) },
                  }),
                  _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.toRecyclingManagement },
                    },
                    [_vm._v(_vm._s(_vm.$t("home.lijiReturn")))]
                  ),
                ],
                1
              )
            : _c("div", {
                domProps: { innerHTML: _vm._s(_vm.messageObj.msgData) },
              }),
        ]),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "flex-end",
              "margin-right": "10px",
            },
          },
          [
            _vm.messageObj.isRead === 0
              ? _c(
                  "el-button",
                  {
                    attrs: { plain: "", size: "mini" },
                    on: { click: _vm.notTip },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.notTip")) + " ")]
                )
              : _vm._e(),
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "primary" },
                on: { click: _vm.closeMessage },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("GlobalSetObj.close")) +
                    " " +
                    _vm._s(_vm.countdown) +
                    "s "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "margin-left": "10px" } }, [
      _c("i", {
        staticClass: "iconfont icon-a-lujing642",
        staticStyle: { "font-size": "18px" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }