import newRequest from '@/utils/new-request';

// 揽收每日应收统计 分页
export const apiStatisticsPage = (data) => {
  return newRequest({
    method: 'POST',
    url: '/statistics/page',
    data
  });
};

// 揽收每日应收统计 刷新数据
export const apiStatisticsAgainStatistics = (time) => {
  return newRequest({
    method: 'GET',
    url: `/statistics/againStatistics?time=${time}`
  });
};

// 揽收每日应收统计 导出文件
export const apiStatisticsExport = (data) => {
  return newRequest({
    method: 'POST',
    url: '/statistics/export',
    data
  });
};
