<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="7">
          <!--留言时间-->
          <el-form-item prop="">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.type"
                  :style="IsWidth"
                >
                  <el-option key="1" :label="$t('GlobalSetObj.messageTime')" :value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.newMessageTime')" :value="2" />
                  <el-option key="3" :label="$t('GlobalSetObj.newReplyTime')" :value="3" />
                </el-select>
              </div>
            </template>
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :placeholder="$t('newOrder.SelectDatetime')"
              range-separator="~"
              style="width:100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--    网点名称      -->
          <el-form-item :label="$t('collectionCenter.siteName')">
            <el-select
              v-model="queryForm.params.siteId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width:100%"
              @change="getUserList"
            >
              <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!-- 账号名称  -->
          <el-form-item :label="$t('customerManagements.AccountName')">
            <el-select
              v-model="queryForm.params.userId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              :remote-method="searchGetUserList"
              clearable
              filterable
              remote
              reserve-keyword
              style="width: 100%"
            >
              <el-option
                v-for="(item,index) in userList"
                :key="index"
                :label="item.username"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 需求类型 -->
        <el-col :span="5">
          <el-form-item :label="$t('GlobalSetObj.requirement')">
            <el-select
              v-model="queryForm.params.messageType"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              style="width: 100%"
            >
              <el-option :label="$t('GlobalSetObj.MaterialRequisition')" :value="1" />
              <el-option :label="$t('GlobalSetObj.ComplaintsSuggestions')" :value="2" />
              <el-option :label="$t('GlobalSetObj.developmentRequirements')" :value="3" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <!--  状态   -->
          <el-form-item :label="$t('GlobalSetObj.status')">
            <el-select
              v-model="queryForm.params.messageStatus"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 100%"
            >
              <el-option :label="$t('GlobalSetObj.all')" :value="null" />
              <el-option :label="$t('GlobalSetObj.Ongoing')" :value="1" />
              <el-option :label="$t('GlobalSetObj.Closed')" :value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="15">
          <div style="margin-top: 27px">
            <!--搜索-->
            <el-button
              icon="el-icon-search" size="small" type="primary"
              @click="enterSearch"
            >{{
              $t('newOrder.search')
            }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh-left" size="small" type="info"
              @click="resetForm()"
            >{{
              $t('newOrder.reset')
            }}
            </el-button>
            <el-button
              icon="el-icon-upload2" size="small" style="margin-bottom: 10px"
              @click="allExportOrder"
            >{{
              $t('newOrder.export')
            }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" type="index"
        width="70"
      />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div v-if="item.prop==='messageStatusName'">
            <el-tag
              v-if="scope.row.messageStatus === 2" effect="dark" size="small"
              type="warning"
            >{{
              scope.row.messageStatusName
            }}
            </el-tag>
            <el-tag
              v-else effect="dark" size="small"
              type=""
            >{{ scope.row.messageStatusName }}
            </el-tag>
          </div>
          <div v-else>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '- -' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('newOrder.operation')"
        align="center"
        fixed="right"
        width="120"
      >
        <template slot-scope="scope">
          <div style="display: flex;justify-content: space-between;align-items: center;">
            <el-tooltip
              :content="$t('GlobalSetObj.Reply')" class="item" effect="dark"
              placement="top"
            >
              <i class="iconfont icon-chakantiezihuifu textOperator2" @click="replyMessage(scope.row)" />
            </el-tooltip>
            <el-tooltip
              :content="$t('GlobalSetObj.remark')" class="item" effect="dark"
              placement="top"
            >
              <i class="iconfont icon-a-zu255 textOperator1" @click="remarkMessage(scope.row)" />
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.messageStatus != 2"
              :content="$t('GlobalSetObj.Close')"
              class="item"
              effect="dark"
              placement="top"
            >
              <i class="iconfont icon-guanbi textOperator" @click="closeMessage(scope.row.id)" />
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-drawer
      :close-on-click-modal="true"
      :destroy-on-close="true"
      :title="$t('GlobalSetObj.MessageContent')"
      :visible.sync="seeDialogVisible"
      class="drawerClass"
      direction="ltr"
      size="1000px"
      @close="closeDrawer"
    >
      <div style="padding: 10px 20px 0 20px;overflow-y: auto;height: calc(100% - 80px)">
        <el-form
          ref="dialogFormRef"
          :inline="false"
          :model="replyData"
          class="demo-ruleForm"
          label-position="top"
          size="default"
        >
          <el-row :gutter="20">
            <el-col :span="24">
              <div ref="messageListRef" :style="IsHeight" class="messgeList">
                <div v-for="item in detailData" :key="item.id">
                  <div v-if="item.self" class="selfMessage">
                    <div style="margin-bottom: 10px">
                      <span class="pepole">{{ item.createUserName || "--" }}</span>
                      <span style="margin-left: 10px">{{ item.createTime }}</span>
                    </div>
                    <div class="pepoleMessage">
                      {{ item.message }}
                    </div>
                  </div>
                  <div v-else class="otherMessage">
                    <div style="margin-bottom: 10px">
                      <span class="pepole">{{ item.createUserName || "--" }}</span>
                      <span style="margin-left: 10px">{{ item.createTime }}</span>
                    </div>
                    <div class="pepoleMessage">{{ item.message }}</div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col v-if="isColse" :span="24">
              <el-form-item
                :rules="[{ required: true, message: $t('GlobalSetObj.pleaseInput'), trigger: 'blur' }]"
                label=""
                prop="replyTextArea"
              >
                <el-input
                  v-model="replyData.replyTextArea"
                  :autosize="{ minRows: 10, maxRows: 11 }"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  maxlength="1000"
                  show-word-limit
                  style="width: 100%"
                  type="textarea"
                  @keydown.native.enter.prevent="replyMessageSubmit"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div v-if="isColse" style="text-align: right">
          <el-button class="custom-button" type="primary" @click="replyMessageSubmit">{{
            $t('GlobalSetObj.Reply')
          }}
          </el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 备注 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.remark')"
      :visible.sync="messageRemrkDialog"
      width="500px"
    >
      <div class="remarkBox">
        <el-input
          v-model="messageRemrk"
          :autosize="{ minRows: 8, maxRows: 15}"
          :placeholder="$t('collectionCenter.pleaseInput')"
          maxlength="200"
          show-word-limit
          type="textarea"
        />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="messageRemrkSubmit(1)">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="messageRemrkSubmit(2)">{{
          $t('GlobalSetObj.confirm')
        }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPmUserList,
  messageBoardClose,
  messageBoardContentPage,
  messageBoardExport,
  messageBoardMessage,
  messageBoardPage,
  messageBoardSaveRemark
} from '@/api/automaticSorting';
import dayjs from 'dayjs';
import { getAllsite } from '@/api/logisticsOrders';

export default {
  name: 'RequestMessageList',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          type: 1,
          siteId: null, // 网点ID
          userId: '', // 账号名id
          messageStatus: null, // 状态: 1-进行中, 2-已关闭
          messageType: '' // 留言类型: 1-物料申领, 2-投诉建议, 3-系统开发需求
        }
      },
      replyData: {
        replyTextArea: ''
      },
      seeDialogVisible: false,
      siteList: [], // 网点
      tableData: [],
      tableHeaderList: [
        // 留言时间
        { id: 2, label: this.$t('GlobalSetObj.MessageTime'), prop: 'createTime', cloWidth: '200' },
        // 留言时间
        { id: 3, label: this.$t('GlobalSetObj.newMessageTime'), prop: 'newMessageTime', cloWidth: '180' },
        // 是否有最新回复
        { id: 4, label: this.$t('GlobalSetObj.HasReply'), prop: 'hasReplyName', cloWidth: '150' },
        // 回复时间
        { id: 5, label: this.$t('GlobalSetObj.newReplyTime'), prop: 'newReplyTime', cloWidth: '180' },
        // 状态
        { id: 6, label: this.$t('GlobalSetObj.status'), prop: 'messageStatusName', cloWidth: '180' },
        // 需求类型
        { id: 7, label: this.$t('GlobalSetObj.requirement'), prop: 'messageTypeName', cloWidth: '180' },
        // 留言内容
        { id: 8, label: this.$t('GlobalSetObj.Message'), prop: 'message', cloWidth: '180' },
        // 账号名称
        { id: 9, label: this.$t('GlobalSetObj.userName'), prop: 'createUserName', cloWidth: '180' },
        // 网点
        { id: 10, label: this.$t('GlobalSetObj.Outlets'), prop: 'siteName', cloWidth: '180' },
        // 供应商
        { id: 11, label: this.$t('GlobalSetObj.supplier'), prop: 'providerName', cloWidth: '180' },
        // 备注
        { id: 12, label: this.$t('GlobalSetObj.remark'), prop: 'remark', cloWidth: '180' },
        // 处理人
        { id: 13, label: this.$t('GlobalSetObj.Operator'), prop: 'optionUserName', cloWidth: '180' }
      ],
      userList: [], // 用户列表
      messageRemrkDialog: false,
      messageRemrk: '',
      messageId: null,
      detailData: [],
      intervalTime: null,
      isColse: true
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '220px' : '140px'
      };
    },
    IsHeight() {
      return {
        height: this.isColse ? '' : '800px'
      };
    }
  },
  watch: {
    'detailData.length': function(newLength) {
      console.log('detailData 的长度变成了:', newLength);
      this.scrollToBottom();
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getUserList();
    this.getAllSite();
    this.getSearch();
  },
  destroyed() {
    clearInterval(this.intervalTime);
    this.intervalTime = null;
  },
  methods: {
    // 回复 发送留言
    replyMessageSubmit() {
      this.$refs.dialogFormRef.validate((valid) => {
        if (valid) {
          const data = {
            messageId: this.messageId, // 留言id
            message: this.replyData.replyTextArea // 回复内容
          };
          messageBoardMessage(data).then(res => {
            if (res.code===200) {
              this.replyData.replyTextArea = '';
              this.getMessageDetail(1);
            }
          });
          console.log('submit!', this.replyData);
        }
      });
    },
    // 备注
    messageRemrkSubmit(val) {
      if (val === 1) {
        this.messageRemrkDialog = false;
        this.messageRemrk = '';
      } else {
        if (this.messageRemrk) {
          const data = {
            messageId: this.messageId, // 留言id
            remark: this.messageRemrk // 备注
          };
          messageBoardSaveRemark(data).then(res => {
            if (res.code===200) {
              this.messageRemrkDialog = false;
              this.messageRemrk = '';
              this.getSearch();
            }
          });
        } else {
          this.messageRemrkDialog = false;
          this.messageRemrk = '';
        }
      }
    },
    // 点击回复弹框
    async replyMessage(row) {
      console.log(row, 'rowrow');
      this.messageId = row.id;
      const data = {
        pageSize: 500,
        pageNumber: 1,
        params: {
          messageId: row.id
        }
      };
      if (row.messageStatus === 2) {
        this.isColse = false;
        const res = await messageBoardContentPage(data);
        if (res.code===200) {
          this.seeDialogVisible = true;
          this.detailData = res.data.records;
          this.scrollToBottom();
          console.log(res, 'replyMessage');
        }
      } else {
        this.isColse = true;
        const res = await messageBoardContentPage(data);
        if (res.code===200) {
          this.seeDialogVisible = true;
          this.intervalTime = setInterval(() => {
            this.getMessageDetail();
          }, 3000);
          this.detailData = res.data.records;
          this.scrollToBottom();
          console.log(res, 'replyMessage');
        }
      }
    },
    async getMessageDetail(val) {
      const data = {
        pageSize: 500,
        pageNumber: 1,
        params: {
          messageId: this.messageId
        }
      };
      const res = await messageBoardContentPage(data);
      if (res.code===200) {
        this.detailData = res.data.records;
        if (val === 1) {
          this.scrollToBottom();
        }
      }
    },
    remarkMessage(row) {
      this.messageId = row.id;
      this.messageRemrkDialog = true;
      this.messageRemrk = row.remark;
    },
    closeMessage(id) {
      this.$confirm(this.$t('GlobalSetObj.confirmClose'), this.$t('GlobalSetObj.tips'), {
        confirmButtonText: this.$t('GlobalSetObj.Confirm'),
        cancelButtonText: this.$t('GlobalSetObj.cancel'),
        type: 'warning'
      }).then(async() => {
        const res = await messageBoardClose(id);
        if (res.code===200) {
          this.$message.success(this.$t('GlobalSetObj.closeSuccessfully'));
          this.getSearch();
        }
      }).catch(() => {
        console.log('取消关闭');
      });
    },
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      const oneWeekAgo = dayjs(brazilTime).subtract(1, 'week').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.getTimer();
      this.queryForm.params = {
        type: 1,
        siteId: null, // 网点ID
        userId: '', // 账号名id
        messageStatus: null, // 状态: 1-进行中, 2-已关闭
        messageType: '' // 留言类型: 1-物料申领, 2-投诉建议, 3-系统开发需求
      };
      this.getSearch();
    },
    // 查询
    getSearch() {
      const messageTypeObj = {
        1: this.$t('GlobalSetObj.MaterialRequisition'),
        2: this.$t('GlobalSetObj.ComplaintsSuggestions'),
        3: this.$t('GlobalSetObj.developmentRequirements')
      };
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params = this.queryForm.params;
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.startTime = this.timeValue[0];
        data.params.endTime = this.timeValue[1];
      } else {
        data.params.startTime = null;
        data.params.endTime = null;
      }
      messageBoardPage(data).then(res => {
        if (res.code===200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach(item => {
            item.messageTypeName = messageTypeObj[item.messageType];
            item.hasReplyName = item.hasReply === 1 ? this.$t('GlobalSetObj.yes') : this.$t('GlobalSetObj.No');
            if (item.messageStatus === 1) {
              item.messageStatusName = this.$t('GlobalSetObj.Ongoing');
            } else {
              item.messageStatusName = this.$t('GlobalSetObj.Closed');
            }
          });
        }
      });
    },
    allExportOrder() {
      const data = this.queryForm.params;
      if (this.timeValue && this.timeValue.length > 0) {
        data.startTime = this.timeValue[0];
        data.endTime = this.timeValue[1];
      } else {
        data.startTime = null;
        data.endTime = null;
      }
      messageBoardExport(data).then(res => {
        if (res.code===200) {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          });
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    // 滚动到底部
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.messageListRef.scrollTo({
          top: this.$refs.messageListRef.scrollHeight,
          behavior: 'smooth'
        });
      });
    },
    // 获取所有网点
    getAllSite() {
      getAllsite().then(res => {
        if (res.code===200) {
          console.log('获取所有网点', res.data);
          this.siteList = res.data;
        }
      });
    },
    closeDrawer() {
      clearInterval(this.intervalTime);
      this.intervalTime = null;
      this.replyData.replyTextArea = '';
      console.log(this.intervalTime, ' this.intervalTime');
    },
    getUserList(val) {
      this.queryForm.params.userId = '';
      const data = {
        pageNumber: 1, // 页数
        pageSize: 10, // 每页 行数
        params: {
          siteId: val
        }
      };
      getPmUserList(data).then(res => {
        if (res.code===200) {
          this.userList = res.data.records;
        }
      });
    },
    // 查用户
    searchGetUserList(val) {
      const data = {
        pageNumber: 1, // 页数
        pageSize: 10, // 每页 行数
        params: {
          siteId: this.queryForm.params.siteId,
          username: val
        }
      };
      getPmUserList(data).then(res => {
        if (res.code===200) {
          this.userList = res.data.records;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 15px;
  padding-bottom: 15px;
}

::v-deep {
  .drawerClass header {
    border-bottom: 1px solid #aaa !important;
    margin-bottom: 0 !important;
    padding: 20px 20px 20px !important;
  }
}

.el-form--label-top ::v-deep .el-form-item__label {
  width: 100% !important;
}

.selectLabel ::v-deep .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.selectLabel ::v-deep .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep .el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.conditionListitme {
  position: relative;
  padding: 10px;
  background-color: #d6d6d6;
  margin-bottom: 10px;
}

.demo-drawercontent {
  padding: 0 20px;
  height: calc(100vh - 130px);
  overflow: auto;
}

.delFillter {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.demo-drawer__footer {
  width: 100%;
  border-top: 1px solid #cccccc;
  position: absolute;
  display: flex;
  justify-content: right;
  padding: 10px;
  right: 10px;
  bottom: 0;
  background-color: white;
}

.textOperator {
  color: #409eff;
  font-size: 22px;
  cursor: pointer;
}

.textOperator1 {
  color: #409eff;
  cursor: pointer;
}

.textOperator2 {
  margin-top: -5px;
  color: #409eff;
  font-size: 22px;
  cursor: pointer;
}

.messgeList {
  height: 450px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;

  .selfMessage {
    text-align: right;
    margin-bottom: 15px;

    .pepole {
      color: #409eff;
    }

    .pepoleMessage {
      text-align: left;
      display: inline-block;
      padding: 10px;
      background-color: #e2ecf6;
      border: 1px solid #409eff;
      border-radius: 7px;
      max-width: calc(70% - 20px); /* 减去padding和可能的margin值 */
      word-wrap: break-word; /* 允许长单词换行 */
    }
  }

  .otherMessage {
    text-align: left;
    margin-bottom: 15px;

    .pepole {
      color: #409eff;
    }

    .pepoleMessage {
      text-align: left;
      display: inline-block;
      padding: 10px;
      background-color: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 7px;
      max-width: calc(70% - 20px); /* 减去padding和可能的margin值 */
      word-wrap: break-word; /* 允许长单词换行 */
    }
  }
}
</style>
