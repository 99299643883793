<!--银行付款导入模板组件-->
<template>
  <el-popover placement="bottom-start" trigger="hover">
    <div class="mb10">
      <!--下载模板-->
      <el-button
        size="small"
        icon="el-icon-download"
        type="primary"
        @click="downloadTemplate"
      >{{ $t('receivablePayable.DownloadTheTemplate') }}
      </el-button>
    </div>
    <div>
      <!--导入文件-->
      <uploadExcel
        :on-success="onImportFile"
        :but-name="$t('receivablePayable.ImportFile')"
      />
    </div>
    <el-button
      slot="reference"
      type="primary"
      size="mini"
    >{{ $t('basicData.BankAccountImport') }}
    </el-button>
  </el-popover>
</template>

<script>
import { saveAs } from 'file-saver';
import { apiDeliveryPayableAccountImport, apiCabinCollectionPayableAccountImport } from '@/api/newFinance';
import { goFileUploadManagement } from '@/utils/goExportList';

const ExcelJS = require('exceljs');

export default {
  name: 'BankPaymentImportTemplate',

  components: {
    uploadExcel: () => import('@/components/UploadExcel/index.vue')
  },

  props: {
    // 导入文件成功回调
    bankList: {
      type: Array,
      default: () => []
    },
    // 导入类型，用于区分导入模板的类型1.AJD 2.DSP 3.安骏小屋
    type: {
      type: [Number, String],
      required: true,
      default: ''
    }
  },

  data() {
    return {
      dictCodeTypeList: [
        {
          value: 'CPF',
          label: 'CPF'
        },
        {
          value: 'CNPJ',
          label: 'CNPJ'
        },
        {
          value: 'MOBILE PHONE',
          label: 'MOBILE PHONE'
        },
        {
          value: 'EMAIL',
          label: 'EMAIL'
        },
        {
          value: 'EVP',
          label: 'EVP'
        }
      ]
    };
  },

  methods: {
    // 下载模板
    downloadTemplate() {
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const Sheet2 = wb.addWorksheet('Sheet2');
        const Sheet3 = wb.addWorksheet('Sheet3');
        const Sheet4 = wb.addWorksheet('Sheet4');
        Sheet1.columns = [
          {
            header: '*' + that.$t('route.AccountsPayable'), // 应付账户,
            key: 'username',
            width: 40
          },
          {
            header: '*' + 'PIX' + that.$t('receivablePayable.accountType'), // '账户类型',
            key: 'dictCodeType',
            width: 40
          },
          {
            header: '*' + 'PIX' + that.$t('customerManagements.account'), // '账户',
            key: 'dictCode',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.ReceivingName'), // '收款名称',
            key: 'payeeName',
            width: 40
          },
          {
            header: '*' + that.$t('orderCenterCont.CertificateType'), // 证件类型',
            key: 'documentType',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.CertificateNumber'), // 证件号码',
            key: 'documentNumber',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.BankNumber'), // '银行编号,
            key: 'bankCode',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.BranchNumber'), // 分行号',
            key: 'branch',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.ReceivingAccount'), // 收款账户,
            key: 'bankNumber',
            width: 40
          }
        ];

        Sheet1.mergeCells('A2:H2');
        Sheet1.getCell('A2').value = `
        1、* 必填项 /* Este campo é obrigatório
        2、不可更改此模板 /Esse modelo não pode ser alterado '`;

        Sheet1.getRow(2).getCell(1).font = {
          color: { argb: 'ff0000' }
        };

        Sheet2.columns = [
          {
            header: 'PIX' + that.$t('receivablePayable.accountType'), // 账号类型
            key: 'dictCodeType',
            width: 40
          }
        ];
        const Sheet2_data = [];
        that.dictCodeTypeList.map(item => {
          Sheet2_data.push({
            dictCodeType: item.label,
            width: 20
          });
        });
        Sheet2.addRows(Sheet2_data);

        Sheet3.columns = [
          {
            header: that.$t('orderCenterCont.CertificateType'), // 证件类型',
            key: 'documentType',
            width: 40
          }
        ];
        const Sheet3_data = [
          { documentType: 'CPF' },
          { documentType: 'CNPJ' }
        ];
        Sheet3.addRows(Sheet3_data);

        Sheet4.columns = [
          {
            header: that.$t('basicData.BankNumber'), // 银行编号,
            key: 'bankCode',
            width: 40
          }
        ];
        const Sheet4_data = [];
        that.bankList.map(item => {
          Sheet4_data.push({
            bankCode: item.code + '-' + '（' + item.name + '）' + item.ispd,
            width: 20
          });
        });
        Sheet4.addRows(Sheet4_data);

        new Array(2000).fill(0).forEach((_, idx) => {
          const row = idx + 2;

          // 账户类型下拉框
          Sheet1.getCell(row, 2).dataValidation = {
            type: 'list',
            formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
          };

          // 证件类型下拉框
          Sheet1.getCell(row, 5).dataValidation = {
            type: 'list',
            formulae: [`=Sheet3!$A$2:$A${Sheet3_data.length + 1}`]
          };

          // 银行编号下拉框
          Sheet1.getCell(row, 7).dataValidation = {
            type: 'list',
            formulae: [`=Sheet4!$A$2:$A${Sheet4_data.length + 1}`]
          };
        });

        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });

        return saveAs(blob, that.$t('basicData.BankAccountImportTemplate') + '.xlsx');
      }

      try {
        ddd();
      } catch (error) {
        console.error(error);
      }
    },
    onImportFile(data) {
      const importList = data[0].results.splice(1); // 解析数据
      const headObj = ['username', 'dictCodeType', 'dictCode', 'payeeName', 'documentType', 'documentNumber', 'bankCode', 'branch', 'bankNumber'];
      const dataList = importList.map(item => {
        const data = {};
        Object.keys(item).forEach((key, i) => {
          console.log(key, i, item[key], headObj[i]);
          try {
            // 银行编号
            if (['bankCode'].includes(headObj[i])) {
              data[headObj[i]] = item[key]?.split('-').shift() || '';
            } else {
              data[headObj[i]] = item[key] || '';
            }
          } catch (error) {
            this.$message.error(this.$t('receivablePayable.ImportTemplateIncorrect'));
            throw new Error(error);
          }
        });
        return data;
      });
      // console.log(dataList);
      const fn = this.type === 3 ? apiCabinCollectionPayableAccountImport : apiDeliveryPayableAccountImport;
      fn({
        accountType: this.type,
        dataList
      }).then(res => {
        if (res.code === 200) {
          // this.$message.success(this.$t('basicData.TheOperationSucceeded'));
          goFileUploadManagement(this);

          this.bus.$emit('onClose', true);
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }
};
</script>

<style scoped>

</style>
