<template>
  <div v-if="[0,2].includes(status)">
    <el-form
      ref="basicForm"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      size="small"
      :label-position="$i18n.locale !== 'zh'?'top':'right'"
    >
      <el-row :gutter="20">
        <!--供应商-->
        <!-- <el-col :span="8">
          <el-form-item :label="$t('GlobalSetObj.supplier')" prop="customerId">
            <el-select
              v-model="ruleForm.customerId"
              filterable
              :disabled="[2].includes(status) && id !== ''"
              :placeholder="$t('operationCenter.PleaseSelect')"
              style="width: 100%;"
              @change="handelCustomer"
              id="customerId"
            >
              <el-option
                v-for="item in providerList"
                :key="item.id"
                :label="item.providerBody + '（' + item.providerName + '）'"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col> -->

        <el-col :span="8">
          <!--业务类型-->
          <el-form-item :label="$t('collectionCenter.businessT')" prop="claimsNode">
            <el-select
              id="claimsNode" v-model="ruleForm.claimsNode" :placeholder="$t('GlobalSetObj.pleaseChoose')"
              class="form-bottom"
              :disabled="[2].includes(status) && id !== ''" style="width: 100%"
              @change="handelClaimsNode"
            >
              <el-option
                v-for="item in claimNode"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 索赔类型 -->
          <el-form-item :label="$t('compensationManagement.ClaimType')" prop="claimsType">
            <el-select
              id="claimsType"
              v-model="ruleForm.claimsType"
              :placeholder="$t('operationCenter.PleaseSelect')"
              style="width: 100%;"
              @change="handelClaimType"
            >
              <el-option
                v-for="item in CLAIMS_TYPE"
                :key="item.value"
                :label="item.name"
                :value="item.value"
                :disabled="item.disabled"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 索赔金额 -->
          <el-form-item :label="$t('compensationManagement.AmountOfClaim')" prop="claimAmount">
            <el-input
              id="claimAmount"
              v-model="ruleForm.claimAmount"
              :disabled="isDisabled"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 申请人 -->
          <el-form-item :label="$t('Resource.applicant')">
            <el-input
              id="applicant"
              v-model.trim="ruleForm.applicant"
              maxlength="40"
              show-word-limit
              :placeholder="$t('GlobalSetObj.pleaseInputContent')"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 审批人 -->
          <el-form-item :label="$t('Resource.Reviewer')">
            <!-- <el-select
              v-model="ruleForm.reviewer"
              filterable
              :placeholder="$t('operationCenter.PleaseSelect')"
              clearable
              style="width: 100%;"
            >
              <el-option
                v-for="item in customList"
                :key="item.id"
                :label="item.customerAlias + '（' + item.username + '）'"
                :value="item.id"
              />
            </el-select> -->
            <!-- <UserSelectCmp v-model="ruleForm.reviewer" :is-user-name="true" class="form-bottom" /> -->
            <UserSelectCmp
              v-model="ruleForm.reviewer" :user-name="userName"
              :is-user-name="true" class="form-bottom"
            />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <!-- 索赔原因 -->
          <el-form-item :label="$t('compensationManagement.CauseOfClaim')">
            <el-input
              id="claimReason"
              v-model="ruleForm.claimReason"
              type="textarea"
              maxlength="2000"
              show-word-limit
              :autosize="{ minRows: 2, maxRows: 4}"
              :placeholder="$t('GlobalSetObj.pleaseInputContent')"
            />
          </el-form-item>
        </el-col>

        <el-col :span="16">
          <el-form-item :label="$t('customerManagements.enclosure')" prop="attachmentUrl" class="uploadFile">
            <UploadCmp
              :drag="true" :multiple="true" :file-arr="fileArr"
              :auto-upload="true" :limit="5" @change="onFileChange"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
  <div v-else>
    <el-form
      label-width="120px"
      class="edit-form"
      size="small"
      :label-position="$i18n.locale !== 'zh'?'top':'right'">
      <el-row :gutter="20">
        <!--供应商-->
        <!-- <el-col :span="8">
          <el-form-item :label="$t('GlobalSetObj.supplier')">
            <template><span>{{ ruleForm.customerName }}</span></template>
          </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <!--业务类型-->
          <el-form-item :label="$t('collectionCenter.businessT')+'：'">
            <template><span>{{ claimNode[ruleForm.claimsNode] && claimNode[ruleForm.claimsNode].name || '' }}</span></template>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--索赔类型-->
          <el-form-item :label="$t('compensationManagement.ClaimType')+'：'">
            <template><span>{{ CLAIMS_TYPE[ruleForm.claimsType] && CLAIMS_TYPE[ruleForm.claimsType].name || '' }}</span></template>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 索赔金额 -->
          <el-form-item :label="$t('compensationManagement.AmountOfClaim')+'：'">
            <template><span>{{ ruleForm.claimAmount }}</span></template>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="$t('Resource.applicant')+'：'">
            <template><span>{{ ruleForm.applicant }}</span></template>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <!-- 审批人 -->
          <el-form-item :label="$t('Resource.Reviewer')+'：'">
            <template><span>{{ ruleForm.reviewerUsername }}</span></template>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <!-- 索赔原因 -->
          <el-form-item :label="$t('compensationManagement.CauseOfClaim')+'：'">
            <span style="display: inline-block; max-height: 100px; overflow-y: scroll; width: 100%; word-break: break-all;">{{ ruleForm.claimReason }}</span>
          </el-form-item>
        </el-col>

        <el-col :span="16">
          <el-form-item :label="$t('customerManagements.enclosure')+'：'" class="uploadFile">
            <template>
              <li v-for="item of fileArr" :key="item.url">
                <el-link type="primary" target="_blank" :href="item.url">{{ item.name }}<i class="el-icon-view el-icon--right" /> </el-link>
              </li>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import UserSelectCmp from '@/views/claimsManagement/claimsReviewRules/cmp/UserSelectCmp.vue';
import UploadCmp from '@/views/claimsManagement/claimStatement/cmp/UploadCmp.vue';
import { claimantMixin } from '../../claimantMinxin.js';

export default {

  components: {
    UploadCmp,
    UserSelectCmp
  },

  mixins: [claimantMixin],
  props: {
    // id
    id: {
      type: [Number, String],
      default: ''
    },
    // 节点状态
    status: {
      type: [String, Number],
      default: ''
    }
  },

  data() {
    return {
      userName: '', // 审批人名称
      ruleForm: {},
      rules: {
        // customerId: [
        //   { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change'}
        // ],
        claimsNode: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        claimsType: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        claimAmount: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' },
          { pattern: /^\d+(\.\d{1,3})?$/, message: this.$t('compensationManagement.pleaseEnterANumberWithAMaximumOf3DecimalPlaces'), trigger: 'change' }
        ]
      },
      fileArr: [], // 附件回显
      isDisabled: false
    };
  },

  watch: {
    // 监听form变化回传数据
    'ruleForm': {
      deep: true,
      immediate: true,
      handler() {
        this.$emit('change', this.getFormData());
      }
    },

    // 监听索赔类型变化，设置金额是否必填
    'ruleForm.claimsType': {
      deep: true,
      immediate: true,
      handler(val) {
        this.isDisabled = val === 1 || val === 4;
        this.rules.claimAmount[0].required = ![1, 4].includes(val);
      }
    }
  },

  methods: {
    // 回传form数据
    getFormData() {
      const obj = cloneDeep(this.ruleForm);
      if (Array.isArray(obj.attachmentUrl)) {
        obj.attachmentUrl = obj.attachmentUrl.map(item => item.url).join(','); // 附件处理
      }
      return obj;
    },

    // 更新form数据
    setFormData(data) {
      this.ruleForm = cloneDeep(data);
      // 设置审批人名称
      this.userName = data?.reviewerUsername;
      if (data?.attachmentUrl) {
        this.fileArr = cloneDeep(data.attachmentUrl.split(',').map((item, index) => {
          return { name: `file${index + 1}`, url: item };
          // return { name: getFileNameFromURL(item), url: item };
        }));
      }
    },

    // 上传附件
    onFileChange(val) {
      console.log(val, '附件');
      this.ruleForm.attachmentUrl = val;
    },

    // 切换供应商or业务类型or索赔类型清除关联订单
    // handelCustomer() {
    //   this.$emit('removeChange');
    // },
    handelClaimsNode() {
      this.$emit('removeChange');
      // this.ruleForm.claimAmount = 0;
      // 业务类型为AJD、DSP启用超时未闭环退回
      if ([5, 6].includes(this.ruleForm.claimsNode)) {
        this.CLAIMS_TYPE[4].disabled = false;
      } else {
        this.CLAIMS_TYPE[4].disabled = true;
        this.ruleForm.claimsType = '';
      }
      // this.CLAIMS_TYPE[4].disabled = ![5, 6].includes(this.ruleForm.claimsNode);
    },
    handelClaimType() {
      this.$emit('removeChange');
      // this.ruleForm.claimAmount = 0;
    }
  }
};

// function getFileNameFromURL(url) {
//   const parsedURL = new URL(url);
//   return parsedURL.pathname.split('/').pop();
// }
</script>

<style scoped>
.edit-form /deep/.el-form-item {
  margin-bottom: 10px;
}
.el-input-number--small {
  line-height: 32px;
}

.uploadFile /deep/ .el-upload {
  width: 100%;
}

.uploadFile /deep/ .el-upload-dragger {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadFile /deep/.el-upload-dragger .el-icon-upload {
  margin: 0 10px 0 0;
  font-size: 24px;
}

.form-bottom {
  width: 100%;
}

ul, li {
  list-style: none;
  word-break: break-all;
  margin: 0;
  /* padding: 0; */
}
</style>
