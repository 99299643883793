<template>
  <div class="app-box">
    <el-form :model="queryForm" label-position="top" class="selectClass">
      <el-row :gutter="10">
        <el-col :span="24" style="height: 70px">
          <el-form-item>
            <div style="display: flex;justify-content: flex-start;align-items: center">
              <div class="selectLabel">
                <el-select
                  v-model="searchTimeType"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                >
                  <el-option :label="$t('trajectory.ArrivalTime')" :value="1" />
                  <el-option :label="$t('collectionCenter.departureTime')" :value="2" />
                </el-select>
              </div>
              <el-date-picker
                v-model="queryDate"
                :placeholder="$t('newOrder.SelectDatetime')"
                type="datetimerange"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                :end-placeholder="$t('collectionCenter.EndTime')"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                :clearable="false"
              />
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="8" style="height: 70px">
          <el-form-item :label="$t('collectionCenter.licensePlateNumber')">
            <div class="searchVehicleNumbers">
              <el-input
                v-model.trim="queryForm.params.vehicleNumbers"
                :placeholder="$t('orderDetails.pleaseInput')"
                clearable
                style="width: 70%"
                @keyup.enter.native="searchClick"
              />
              <div style="width: 40px;">
                <el-button class="chaxunBtn" @click="searchClick">
                  <i class="iconfont icon-chaxun" style="font-size: 24px" />
                </el-button>
              </div>
              <div style="width: 38px;">
                <el-popover
                  v-model="showNumList"
                  placement="bottom-end"
                  trigger="manual"
                >
                  <div>
                    <el-input
                      v-model="queryForm.params.vehicleNumbers"
                      :placeholder="$t('collectionCenter.maxQuery1000')"
                      :rows="10"
                      type="textarea"
                    />
                    <div style="margin-top: 10px;">
                      <!--清空-->
                      <el-button size="small" @click="queryForm.params.vehicleNumbers=''">{{ $t('newOrder.empty') }}</el-button>
                      <div style="float: right">
                        <!--关闭-->
                        <el-button size="small" @click="showNumList=false">{{ $t('orderExport.close') }}</el-button>
                        <!--搜索-->
                        <el-button
                          plain size="small" type="primary"
                          @click="searchClick"
                        >{{
                          $t('newOrder.search')
                        }}
                        </el-button>
                      </div>
                    </div>
                  </div>
                  <el-button slot="reference" class="btnIcon" @click="showNumList=true">
                    <i class="iconfont icon-liebiao1" style="font-size: 24px" />
                  </el-button>
                </el-popover>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="8" style="height: 70px">
          <el-form-item :label="$t('collectionCenter.vehicleLocation')">
            <el-select
              v-model="queryForm.params.siteId"
              filterable
              :placeholder="$t('collectionCenter.tudo')"
              clearable
            >
              <el-option
                v-for="item in siteIdList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" style="height: 70px">
          <el-form-item :label="$t('collectionCenter.operationMode')">
            <el-select
              v-model="queryForm.params.operationMode"
              :placeholder="$t('collectionCenter.tudo')"
              clearable
            >
              <el-option
                v-for="(item,key) in operationModeObj"
                :key="key"
                :label="item"
                :value="key"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" style="width: auto;">
          <el-row>
            <el-col class="col2">
              <el-select
                v-model="selectSite"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                class="autoWidth"
                @change="changeSite"
              >
                <template slot="prefix">
                  {{ (stList.find(item => item.value === selectSite) || {}).label }}
                </template>
                <el-option
                  v-for="(item, index) in stList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-col>
            <el-col v-if="selectSite == 's1'" style="width: auto;">
              <el-form-item class="siteClass">
                <el-select
                  v-model="queryForm.params.reachSite"
                  :placeholder="$t('collectionCenter.pleaseChoose')"
                  clearable
                  filterable
                  @focus="getNewestSite"
                >
                  <el-option
                    v-for="(item, index) in dotIdList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="selectSite == 's2'" style="width: auto;">
              <el-form-item class="siteClass">
                <el-select
                  v-model="queryForm.params.leaveSite"
                  :placeholder="$t('collectionCenter.pleaseChoose')"
                  clearable
                  filterable
                  @focus="getNewestSite"
                >
                  <el-option
                    v-for="(item, index) in dotIdList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="8" style="width: auto;">
          <el-row>
            <el-col class="col2">
              <el-select
                v-model="selectPeople"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                class="autoWidth"
                @change="changePeople"
              >
                <template slot="prefix">
                  {{ (selectPeopleList.find(item => item.value === selectPeople) || {}).label }}
                </template>
                <el-option
                  v-for="(item, index) in selectPeopleList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-col>
            <el-col v-if="selectPeople == 'n1'" style="width: auto;">
              <el-form-item class="siteClass">
                <el-select
                  v-model="queryForm.params.reachScanUserId"
                  :loading="loading"
                  :placeholder="$t('collectionCenter.pleaseInput')"
                  :remote-method="driverNameMethod"
                  clearable
                  filterable
                  remote
                  reserve-keyword
                >
                  <el-option
                    v-for="(item, index) in driverNameList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="selectPeople == 'n2'" style="width: auto;">
              <el-form-item class="siteClass">
                <el-select
                  v-model="queryForm.params.leaveScanUserId"
                  :loading="loading"
                  :placeholder="$t('collectionCenter.pleaseInput')"
                  :remote-method="driverNameMethod"
                  clearable
                  filterable
                  remote
                  reserve-keyword
                >
                  <el-option
                    v-for="(item, index) in driverNameList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-col :span="24" style="margin-top: 10px">
        <el-button
          v-permit:remove="'btn:collection-center:report:signAgeing:odd-find'"
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="searchClick"
        >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
        <el-button
          v-permit:remove="'btn:collection-center:report:signAgeing:odd-reset'"
          icon="el-icon-refresh"
          size="mini"
          @click="resetClick"
        >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
        <el-button
          icon="el-icon-upload2"
          size="mini"
          :class="isClickAll ? 'onlyClickOnce10' : ''"
          @click="download"
        >{{ $t('collectionCenter.allExport') }}</el-button><!-- 导出 -->
      </el-col>
    </el-row>
    <div>
      <el-table
        ref="tableRef"
        border
        :data="tableDate"
        style="margin-top: 20px"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column
          :label="$t('collectionCenter.ind')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="vehicleNumber"
          :label="$t('collectionCenter.licensePlateNumber')"
          align="center"
        />
        <el-table-column
          prop="vehicleType"
          :label="$t('collectionCenter.carType')"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ vehicleTypeObj[scope.row.vehicleType] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="operationMode"
          :label="$t('collectionCenter.operationMode')"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ operationModeObj[scope.row.operationMode] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="siteName"
          :label="$t('collectionCenter.vehicleLocation')"
          align="center"
        />
        <el-table-column
          prop="reachScanTime"
          :label="$t('trajectory.ArrivalTime')"
          align="center"
        />
        <el-table-column
          prop="reachAddress"
          :label="$t('collectionCenter.arrivalLocation')"
          align="center"
        />
        <el-table-column
          prop="reachScanUser"
          :label="$t('collectionCenter.arrivalScanningPersonnel')"
          align="center"
        />
        <el-table-column
          prop="reachSiteName"
          :label="$t('collectionCenter.arrivalPoint')"
          align="center"
        />
        <el-table-column
          prop="leaveScanTime"
          :label="$t('collectionCenter.departureTime')"
          align="center"
        />
        <el-table-column
          prop="leaveScanUser"
          :label="$t('collectionCenter.departureScanningPersonnel')"
          align="center"
        />
        <el-table-column
          prop="leaveSiteName"
          :label="$t('collectionCenter.exitNode')"
          align="center"
        />
        <el-table-column
          prop="leaveAddress"
          :label="$t('collectionCenter.leaveLocation')"
          align="center"
        />
        <el-table-column
          prop="collectTime"
          :label="$t('collectionCenter.operation')"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="getPictureDetails(scope.row)">{{ $t('collectionCenter.imageDetails') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="searchClick"
        @size-change="searchClick"
      />
    </div>
    <el-dialog
      :title="$t('collectionCenter.imageDetails')"
      :visible.sync="dialogVisible"
      width="700px"
      @close="imageDetailsType=1"
    >
      <div style="min-height: 300px;max-height: 70vh;overflow-y: auto">
        <el-tabs v-model="imageDetailsType" type="card">
          <el-tab-pane :label="$t('collectionCenter.arrival')" :name="1">
            <template v-if="pictureDetails.reachImgUrls">
              <el-row :gutter="20">
                <el-col v-for="(item,index) in pictureDetails.reachImgUrls" :key="index" :span="12">
                  <el-image
                    style="width: 100%; height: 100%"
                    :preview-src-list="pictureDetails.reachImgUrls"
                    :src="item"
                  />
                </el-col>
              </el-row>
            </template>
            <el-empty v-else :description="$t('collectionCenter.noImageAvailable')" />
          </el-tab-pane>
          <el-tab-pane :label="$t('collectionCenter.departure')" :name="2">
            <template v-if="pictureDetails.leaveImgUrls">
              <el-row :gutter="20">
                <el-col v-for="(item,index) in pictureDetails.leaveImgUrls" :key="index" :span="12">
                  <el-image
                    style="width: 100%; height: 100%"
                    :preview-src-list="pictureDetails.leaveImgUrls"
                    :src="item"
                  />
                </el-col>
              </el-row>
            </template>
            <el-empty v-else :description="$t('collectionCenter.noImageAvailable')" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  collectVehicleExportAll,
  searchQueryWholeSite,
  siteQueryOwnSite,
  vehicleUsageStatisticsPage,
  queryDriver
} from '@/api/lanshou';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

dayjs.locale('pt-br'); // 加载巴西语言配置

export default {
  name: 'VehicleUsageStatistics',
  data() {
    return {
      driverNameList: [],
      loading: false,
      selectPeople: 'n1', // 1 到达扫描人   2 离开扫描人
      selectPeopleList: [
        { value: 'n1', label: this.$t('collectionCenter.arrivalScanningPersonnel') }, // '到达扫描人'
        { value: 'n2', label: this.$t('collectionCenter.departureScanningPersonnel') } // '离开扫描人'
      ],
      dotIdList: [],
      selectSite: 's1', // 选择站点  1 到达网点  2 离开网点
      stList: [
        { value: 's1', label: this.$t('collectionCenter.arrivalPoint') }, // '到达网点'
        { value: 's2', label: this.$t('collectionCenter.exitNode') } // '离开网点'
      ],
      isClickAll: false,
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          operationMode: '', // 车辆运营模式
          siteId: '', // 网点id
          vehicleNumbers: '',
          startReachTime: '',
          endReachTime: '',
          reachSite: '',
          leaveSite: '',
          reachScanUserId: '',
          leaveScanUserId: ''
        }
      },
      queryDate: [], // 查询时间
      searchTimeType: 1, // 1：到达时间 2：离开时间
      siteIdList: [], // 网点列表
      tableDate: [], // 表格数据
      total: 0, // 总条数
      showNumList: false,
      vehicleTypeObj: {
        0: 'vuc',
        1: '3/4',
        3: 'ToCo',
        4: 'van',
        5: 'Fiorino',
        6: 'Truck',
        7: 'Carreta',
        100: this.$t('collectionCenter.other')
      },
      operationModeObj: {
        '0': this.$t('collectionCenter.directOperation'),
        '1': this.$t('collectionCenter.thirdPartyCooperation')
      },
      dialogVisible: false, // 图片详情弹窗
      pictureDetails: {}, // 图片详情，获取当前点击车辆数据
      imageDetailsType: 1 // 1：到达 2：离开
    };
  },
  created() {
    // 获取网点列表
    this.getBranchList();
    // Moment获取巴西当前时间
    // this.queryDate = [moment().locale('pt-br').format('YYYY-MM-DD') + ' 00:00:00', moment().locale('pt-br').format('YYYY-MM-DD') + ' 23:59:59'];
    this.queryDate = [dayjs().format('YYYY-MM-DD') + ' 00:00:00', dayjs().format('YYYY-MM-DD') + ' 23:59:59'];
    // 获取列表数据
    this.searchClick();

    this.getNewestSite();
    this.isClickAll = false;
  },
  methods: {
    driverNameMethod(value) {
      if (value !== '') {
        this.loading = true;
        setTimeout(() => {
          queryDriver(value).then(res => {
            if (res.code===200) {
              this.driverNameList = [];
              res.data.forEach(item => {
                this.driverNameList.push({
                  id: item.driverId,
                  name: item.driverName
                });
              });
            }
          });
          this.loading = false;
        }, 200);
      }
    },
    changePeople() { // 切换到达扫描人 / 离开扫描人
      this.queryForm.params.reachScanUserId = '';
      this.queryForm.params.leaveScanUserId = '';
    },
    changeSite() { // 查询条件，切换揽收 / 签收网点
      this.queryForm.params.reachSite = '';
      this.queryForm.params.leaveSite = '';
    },
    // 获取最新的网点list数据
    getNewestSite() {
      searchQueryWholeSite().then(res => {
        if (res.code===200) {
          this.dotIdList = [];
          res.data.forEach(data => {
            this.dotIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    /**
     * @description 获取网点列表
     */
    getBranchList() {
      siteQueryOwnSite().then(res => {
        if (res.code===200) {
          this.siteIdList = res.data;
        }
      });
    },
    /**
     * @description 查询
     */
    searchClick() {
      if (!this.queryDate && !this.queryForm.params.vehicleNumbers) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      let vehicleNumbers = [];
      if (this.queryForm.params.vehicleNumbers) {
        const NumberList = this.queryForm.params.vehicleNumbers.split(/[(\r\n)\r\n]+/);
        const Numbers = [];
        NumberList.map((item) => {
          if (item) {
            Numbers.push(item.trim());
          }
        });
        vehicleNumbers = Numbers;
      } else {
        vehicleNumbers = [];
      }
      if (vehicleNumbers.length > 1000) {
        this.$message.warning(this.$i18n.t('collectionCenter.maxQuery1000'));
        return;
      }
      let params = {
        ...this.queryForm,
        params: {
          ...this.queryForm.params,
          vehicleNumbers
        }
      };
      if (this.queryDate && this.queryDate.length) {
        if (this.searchTimeType === 1) {
          params.params.startReachTime = this.queryDate[0];
          params.params.endReachTime = this.queryDate[1];
        } else if (this.searchTimeType === 2) {
          params.params.startLeaveTime = this.queryDate[0];
          params.params.endLeaveTime = this.queryDate[1];
        }
      }
      // 车辆号不为空时，其它参数不传，只传车辆号
      if (vehicleNumbers.length > 0) {
        params = {
          ...this.queryForm,
          params: {
            vehicleNumbers
          }
        };
      }
      localStorage.setItem('vehicleUsageStatisticsQueryForm', JSON.stringify(params));
      vehicleUsageStatisticsPage(params)
        .then((res) => {
          if (res.code===200) {
            this.tableDate = res.data.records;
            this.total = res.data.total;
          }
        });
    },
    /**
     * @description 重置
     */
    resetClick() {
      // Moment获取巴西当前时间
      this.searchTimeType = 1;
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          operationMode: '', // 车辆运营模式
          siteId: '', // 网点id
          vehicleNumbers: '', // 车辆号
          startReachTime: '',
          endReachTime: '',
          reachSite: '',
          leaveSite: '',
          reachScanUserId: '',
          leaveScanUserId: ''
        }
      };
      // this.queryDate = [moment().locale('pt-br').format('YYYY-MM-DD') + ' 00:00:00', moment().locale('pt-br').format('YYYY-MM-DD') + ' 23:59:59'];
      this.queryDate = [dayjs().format('YYYY-MM-DD') + ' 00:00:00', dayjs().format('YYYY-MM-DD') + ' 23:59:59'];
      this.searchClick();
    },
    /**
     * @description 全部导出
     */
    download() {
      this.isClickAll = true;
      let vehicleNumbers = [];
      if (this.queryForm.params.vehicleNumbers) {
        const NumberList = this.queryForm.params.vehicleNumbers.split(/[(\r\n)\r\n]+/);
        const Numbers = [];
        NumberList.map((item) => {
          if (item) {
            Numbers.push(item.trim());
          }
        });
        vehicleNumbers = Numbers;
      } else {
        vehicleNumbers = [];
      }
      if (vehicleNumbers.length > 1000) {
        this.$message.warning(this.$i18n.t('collectionCenter.maxQuery1000'));
        return;
      }
      let params = {
        ...this.queryForm,
        params: {
          ...this.queryForm.params,
          vehicleNumbers
        }
      };
      if (this.queryDate && this.queryDate.length) {
        if (this.searchTimeType === 1) {
          params.params.startReachTime = this.queryDate[0];
          params.params.endReachTime = this.queryDate[1];
        } else if (this.searchTimeType === 2) {
          params.params.startLeaveTime = this.queryDate[0];
          params.params.endLeaveTime = this.queryDate[1];
        }
      }
      // 车辆号不为空时，其它参数不传，只传车辆号
      if (vehicleNumbers.length > 0) {
        params = {
          ...this.queryForm,
          params: {
            vehicleNumbers
          }
        };
      }
      // 本地存储中有查询条件就用本地存储中的查询条件
      params = JSON.parse(localStorage.getItem('vehicleUsageStatisticsQueryForm')) || params;
      collectVehicleExportAll(params)
        .then((res) => {
          if (res.code===200) {
            this.isClickAll = false;
            goExportList(this);
          }
        });
    },
    /**
     * @description 获取图片详情数据
     * @param row
     */
    getPictureDetails(row) {
      this.pictureDetails = row;
      this.dialogVisible = true;
    }
  }
};
</script>
<style lang="scss" scoped>

.selectClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selectClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.selectClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
  width: 100%;
}
.selectClass .searchVehicleNumbers {
  width: 100%;
}
.autoWidth{
  width: auto !important;
}
.autoWidth ::v-deep .el-input__prefix { // 下拉框自适应占位
  position: relative;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  padding: 0 25px;
  padding-left: 0;
  margin-left: 20px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  visibility: hidden;
  white-space: nowrap;
}
.autoWidth ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-size: 14px;
  color: #606266;
  font-weight: bold;
  text-align: right;
  padding-left: 0;
  box-sizing: border-box;
}
.autoWidth ::v-deep input {
  position: absolute;
}
.col2 {
  width: auto !important;
}
.siteClass ::v-deep .el-select, .selectClass ::v-deep .el-select {
  width: 100%;
}
.siteClass ::v-deep .el-form-item__content {
  margin-left: 0 !important;
  width: 100%;
}

/* 防止按钮重复点击*/
@keyframes throttle{
  from{
    filter: opacity(40%);
    pointer-events: none;
  }
  to{
    pointer-events: all;
  }
}
.onlyClickOnce10{
  animation: throttle 10s step-end forwards;
}
.onlyClickOnce10:active{
  filter: opacity(40%);
  animation: none;
}

.app-box{
  padding: 12px;
}
.searchVehicleNumbers {
  display: flex;
}
.btnIcon{
  padding: 6px;
}
.searchVehicleNumbers .el-popover__reference-wrapper .btnIcon {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
}

.searchVehicleNumbers .el-input__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.searchVehicleNumbers .el-input-group__prepend {
  background-color: transparent !important;
}
.chaxunBtn {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  width: 40px;
  height: 40px;
  padding: 7px;
}

.searchNum {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 15px;
  height: 28px;
}

.searchNumWarp {
  height: 28px;
  border: 1px solid #91B8FA;
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5px 5px 5px 5px;
  background: rgba(145, 184, 250, 0.2);
}

.searchNumWarp p {
  margin: 0;
}

.clearSearchNum {
  height: 28px;
  border: 1px solid #91B8FA;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: rgba(145, 184, 250, 0.2);
  cursor: pointer;
  padding-right: 5px;
  padding-top: 5px;
}

.wordBreak {
  word-break: break-all; /*word-break: normal|break-all|keep-all;在恰当的断字点进行换行,break-all 允许在单词内换行,keep-all 只能在半角空格或连字符处换行,normal 使用浏览器默认的换行规则。*/
  text-overflow: ellipsis; /*溢出文字显示省略号*/
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
}
.right{
  text-align: right;
}
.el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.selectLabel .el-input__icon {
  line-height: 28px !important;
}
</style>
