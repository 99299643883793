import request from '@/utils/request';

const baseUserURL = process.env.VUE_APP_USER;

/**
 * 揽收装车记录————所有车辆下拉，默认展示10条
 */
export const queryVehiclePage = (data) => {
  return request({
    url: `/collectVehicle/queryVehiclePage?pageSize=10&vehicleNumber=${data}`,
    method: 'get'
  });
};
/**
 * 揽收装车记录————查询所属网点州
 */
export const queryState = (data) => {
  return request({
    url: `/site/queryOptionSelectForState?name=${data}&pageSize=1000`,
    method: 'get'
  });
};
/**
 * 揽收装车记录————所有网点下拉，默认展示10条
 */
export const query10Site = (data) => {
  return request({
    url: `/site/queryPage?name=${data}`,
    method: 'get'
  });
};
/**
 * 揽收装车记录————所有员工下拉，默认展示10条
 */
export const query10User = (data) => {
  return request({
    url: `/queryPage?realName=${data}`,
    method: 'get',
    baseURL: baseUserURL
  });
};
/**
 * 揽收装车记录————所有客户下拉，默认展示10条
 */
export const queryCustomerName = (data) => {
  return request({
    url: `/customer/queryPage?customerName=${data}`,
    method: 'GET'
  });
};

/**
 * 揽收装车记录————分页
 */
export const collectBulkyLoadPage = (data) => {
  return request({
    url: `/collectBulkyLoad/page`,
    method: 'post',
    data
  });
};

/**
 * 揽收装车记录————导出
 */
export const collectBulkyLoadExportAll = (data) => {
  return request({
    url: `/collectBulkyLoad/exportAll`,
    method: 'post',
    data
  });
};
