<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="5">
          <!-- 规则名称 -->
          <el-form-item :label="$t('GlobalSetObj.RuleName')">
            <el-input
              v-model.trim="queryForm.params.ruleName"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width:100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--  分拣条件   -->
          <el-form-item :label="$t('GlobalSetObj.SortingCondition')">
            <el-select
              v-model="queryForm.params.ruleType"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 100%"
              @change="handleRuleType"
            >
              <el-option
                v-for="(item,index) in SortingconditionList"
                :key="index"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--    网点      -->
          <el-form-item :label="$t('GlobalSetObj.Outlets')">
            <el-select
              v-model="queryForm.params.siteId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width:100%"
            >
              <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.siteName"
                :value="item.siteId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <!--  设备名称 -->
        <el-col :span="5">
          <el-form-item :label="$t('GlobalSetObj.TheNameOfTheDevice')">
            <el-select
              v-model="queryForm.params.deviceId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="item in deviceList"
                :key="item.id"
                :label="item.deviceName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="5">
          <!--   格口号   -->
          <el-form-item :label="$t('GlobalSetObj.GridSlogan')">
            <el-input
              v-model="queryForm.params.gridNumber"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width:100%"
              @input="handleInput"
            />
          </el-form-item>
        </el-col>
        <el-col :span="15">
          <div style="margin-top: 27px">
            <!--搜索-->
            <el-button
              icon="el-icon-search" size="small" type="primary"
              @click="enterSearch"
            >{{
              $t('newOrder.search')
            }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh-left" size="small" type="info"
              @click="resetForm()"
            >{{
              $t('newOrder.reset')
            }}
            </el-button>
            <el-button
              icon="el-icon-upload2" size="small" style="margin-bottom: 10px"
              @click="allExportOrder"
            >{{
              $t('newOrder.export')
            }}
            </el-button>
            <!-- 新增 -->
            <el-button size="small" type="primary" @click="newAdd">{{
              $t('GlobalSetObj.add')
            }}
            </el-button>
          </div>
        </el-col>
        <el-col :span="4">
          <!-- 操作日志 -->
          <el-button size="small" style="float: right;margin-top: 27px;" type="primary" @click="clickOperationLog">{{
            $t('basicData.OperationalLogs')
          }}
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <div>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '- -' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('newOrder.operation')"
        align="center"
        fixed="right"
        width="120"
      >
        <template slot-scope="scope">
          <div style="text-align: center;">
            <el-button size="mini" type="text" @click="editRule(scope.row)"><i
              class="el-icon-edit-outline"
              style="font-size: 18px;"
            /></el-button>
            <el-tooltip
              :content="$t('GlobalSetObj.newChildRule')" class="item" effect="dark"
              placement="top"
            >
              <el-button
                size="mini" style="margin-right: 10px;" type="text"
                @click="newChildRule(scope.row)"
              ><i
                class="el-icon-plus"
                style="font-size: 18px;"
              /></el-button>
            </el-tooltip>
            <el-popconfirm
              :title="$t('GlobalSetObj.AreYouSureDelete')"
              @confirm="delSubRule(scope.row)"
            >
              <el-button slot="reference" size="mini" type="text"><i
                class="el-icon-delete"
                style="font-size: 18px;"
              /></el-button>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-drawer
      ref="drawer"
      :append-to-body="true"
      :title="titleName"
      :visible.sync="drawer"
      custom-class="demo-drawer"
      direction="ltr"
      size="800px"
      @closed="handleClosed"
    >
      <div class="demo-drawercontent">
        <el-form
          :key="drawer" ref="addForm" :model="ruleForm"
          label-position="top" label-width="auto"
        >
          <el-form-item :label="$t('GlobalSetObj.RuleName')" :rules="subRuleListRules.inputRequire" prop="ruleName">
            <el-input
              v-model="ruleForm.ruleName"
              :disabled="isEdit"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              maxlength="50"
              show-word-limit
              type="text"
            />
          </el-form-item>
          <el-form-item
            :label="$t('GlobalSetObj.TheNameOfTheDevice')"
            :rules="subRuleListRules.gridNumber"
            prop="deviceId"
          >
            <el-select
              v-model="ruleForm.deviceId"
              :disabled="isEdit"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 100%"
              @change="getGridNumList"
            >
              <el-option
                v-for="item in deviceList"
                :key="item.id"
                :label="item.deviceName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <div :key="listKey">
            <div v-for="(item,index) in ruleForm.subRuleList" :key="index" class="conditionListitme">
              <span
                v-if="ruleForm.subRuleList.length > 1"
                class="delFillter"
                @click="delRule(index)"
              ><i class="el-icon-circle-close" /></span>
              <el-form-item
                :label="$t('GlobalSetObj.gridNum')"
                :prop="`subRuleList.${index}.gridNumber`"
                :rules="subRuleListRules.gridNumber"
              >
                <el-select
                  v-model="item.gridNumber"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  filterable
                  @change="changeGridNumList(item.gridNumber,index)"
                >
                  <el-option
                    v-for="grid in gridNumsList"
                    :key="grid.id"
                    :label="grid.gridNum"
                    :value="grid.gridNum"
                  />
                </el-select>
              </el-form-item>
              <!--  分拣条件   -->
              <el-form-item
                :label="$t('GlobalSetObj.SortingCondition')"
                :prop="`subRuleList.${index}.ruleType`"
                :rules="subRuleListRules.gridNumber"
              >
                <el-select
                  v-model="item.ruleType"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  style="width: 100%"
                  @change="handleRuleType(item.ruleType, index)"
                >
                  <el-option
                    v-for="(itemRuleType,ruleTypeindex) in SortingconditionList"
                    :key="ruleTypeindex"
                    :label="itemRuleType.label"
                    :value="itemRuleType.code"
                  />
                </el-select>
              </el-form-item>
              <!--     网点       -->
              <el-form-item
                v-if="item.ruleType === 1"
                :key="item.ruleType"
                :label="$t('GlobalSetObj.Outlets')"
                :prop="`subRuleList.${index}.siteId`"
                :rules="subRuleListRules.gridNumber"
              >
                <el-select
                  v-model="item.siteId"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  filterable
                  style="width: 100%"
                  @change="changeSiteId(item.siteId,index)"
                >
                  <el-option
                    v-for="(site,sitendex) in siteList"
                    :key="sitendex"
                    :label="site.siteName"
                    :value="site.siteId"
                  />
                </el-select>
              </el-form-item>
              <!--   转运中心      -->
              <el-form-item
                v-if="item.ruleType === 8"
                :key="item.ruleType"
                :label="$t('collectionCenter.transferCenter')"
                :prop="`subRuleList.${index}.transferCenterId`"
                :rules="subRuleListRules.gridNumber"
              >
                <el-select
                  v-model="item.transferCenterId"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  filterable
                  style="width: 100%"
                  @change="changeTransferCenterId(item.transferCenterId,index)"
                >
                  <el-option
                    v-for="(transferCenterItem,transferCenterIndex) in transferCenterList"
                    :key="transferCenterIndex"
                    :label="transferCenterItem.transferCenterName"
                    :value="transferCenterItem.transferCenterId"
                  />
                </el-select>
              </el-form-item>
              <!--  服务商   -->
              <el-form-item
                v-if="item.ruleType === 3"
                :key="item.ruleType"
                :label="$t('GlobalSetObj.fuwushang')"
                :prop="`subRuleList.${index}.providerId`"
                :rules="subRuleListRules.gridNumber"
              >
                <el-select
                  v-model="item.providerId"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  filterable
                  style="width: 100%"
                  @change="changeProviderId(item.providerId,index)"
                >
                  <el-option
                    v-for="(item2,index2) in providerList"
                    :key="index2"
                    :label="item2.providerName"
                    :value="item2.providerId"
                  />
                </el-select>
              </el-form-item>
              <!-- 邮编  -->
              <el-form-item
                v-if="item.ruleType === 4"
                :key="item.ruleType"
                :label="$t('GlobalSetObj.zipCode')"
                :prop="`subRuleList.${index}.zipcodeRange`"
                :rules="subRuleListRules.zipcodeAreaCode"
              >
                <el-input
                  v-model.trim="item.zipcodeRange"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  maxlength="1000"
                  show-word-limit
                  type="textarea"
                  @blur="handleZipcodeRange(item.zipcodeRange,index)"
                />
              </el-form-item>
              <!-- 包裹状态  -->
              <el-form-item
                v-if="item.ruleType === 5"
                :key="item.ruleType"
                :label="$t('GlobalSetObj.packageStatus')"
                :prop="`subRuleList.${index}.statusStrName`"
                :rules="subRuleListRules.gridNumber"
              >
                <el-select
                  v-model="item.statusStrName"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  clearable
                  multiple
                  size="small"
                  style="width: 100%;"
                >
                  <el-option
                    v-for="(itemStatus) in waybillStatus"
                    :key="itemStatus.code"
                    :label="itemStatus.label"
                    :value="itemStatus.code"
                  />
                </el-select>
              </el-form-item>
            </div>
          </div>
          <el-button v-if="isEdit === false || isEdit01 === true" type="primary" @click="createRule">
            {{ $t('ChannelManage.CreateCondition') }}
          </el-button>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <el-button style="margin-right: 20px" @click="cancelForm">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="saveForm">{{ $t('GlobalSetObj.Salve') }}</el-button>
      </div>
    </el-drawer>
    <!-- 操作日志 -->
    <el-drawer
      :title="$t('basicData.OperationalLogs')"
      :visible.sync="operateDialogVisible"
      center
      direction="ltr"
      size="1200px"
    >
      <operationLog
        v-if="operateDialogVisible"
        ref="operationDetails"
      />
    </el-drawer>
  </div>
</template>

<script>
import {
  addSortedRule,
  deleteSubRule,
  deviceManagementList,
  editSubRule,
  getAllcustomer,
  newApiRulePage,
  newChilderRules,
  newsorterGridRuleSelectGrid,
  queryByRuleType,
  sortedRuleExport
} from '@/api/automaticSorting';

export default {
  name: 'NewSortingRules',
  components: {
    'operationLog': () => import('./component/operationLog')
  },
  data() {
    return {
      operateDialogVisible: false,
      isTormat: false,
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          ruleName: '', // 规则名称
          ruleType: null, // 规则类型,1:网点，2：客户，3：服务商，4:邮编，5：状态，6：三次分拣，7：其他
          siteId: null, // 网点id
          // customerId: null, // 客户id
          gridNumber: null, // 格口号
          deviceId: '' // 设备名称
        }
      },
      siteList: [], // 网点
      transferCenterList: [], // 转运中心
      tableData: [],
      tableHeaderList: [
        // 编号
        { id: 1, label: this.$t('GlobalSetObj.serialNumber'), prop: 'id', cloWidth: '100' },
        // 规则名称
        { id: 3, label: this.$t('GlobalSetObj.RuleName'), prop: 'ruleName', cloWidth: '200' },
        // 格口
        { id: 2, label: this.$t('GlobalSetObj.GridSlogan'), prop: 'gridNumber', cloWidth: '180' },
        // 分拣条件
        { id: 12, label: this.$t('GlobalSetObj.SortingCondition'), prop: 'ruleTypeName', cloWidth: '150' },
        // 设备名称
        { id: 14, label: this.$t('GlobalSetObj.deviceName'), prop: 'deviceName', cloWidth: '180' },
        // 网点
        { id: 7, label: this.$t('GlobalSetObj.Outlets'), prop: 'siteName', cloWidth: '180' },
        // // 客户
        // { id: 13, label: this.$t('GlobalSetObj.client'), prop: 'customerName', cloWidth: '160' },
        // 服务商
        { id: 99, label: this.$t('GlobalSetObj.fuwushang'), prop: 'providerName', cloWidth: '180' },
        // 创建人
        { id: 4, label: this.$t('GlobalSetObj.CreatePeople'), prop: 'createUserName', cloWidth: '180' },
        // 创建时间
        { id: 6, label: this.$t('GlobalSetObj.CreationTime'), prop: 'createTime', cloWidth: '180' },
        // 最新修改人
        { id: 8, label: this.$t('GlobalSetObj.editor'), prop: 'updateUserName', cloWidth: '180' },
        // 更新时间
        { id: 9, label: this.$t('GlobalSetObj.ModificationTime'), prop: 'updateTime', cloWidth: '170' }
      ],
      userList: [], // 用户列表
      SortingconditionList: [
        { label: this.$t('GlobalSetObj.Outlets'), code: 1 },
        { label: this.$t('collectionCenter.transferCenter'), code: 8 },
        { label: this.$t('GlobalSetObj.provider'), code: 3 },
        { label: this.$t('GlobalSetObj.zipCode'), code: 4 },
        { label: this.$t('GlobalSetObj.statusFenjian'), code: 5 },
        { label: this.$t('GlobalSetObj.ThreeSortingFailures'), code: 6 },
        { label: this.$t('orderCenterCont.other'), code: 7 }
      ], // 分拣类型
      deviceList: [],
      drawer: false, // 新建弹框
      ruleForm: {
        ruleName: '', // 规则名称
        deviceId: '', // 设备
        subRuleList: [
          {
            id: 0,
            ruleType: null,
            gridNumber: null,
            siteId: null,
            // customerId: '', // 客户,
            providerId: '', // 供应商id
            zipcodeRange: '',
            statusStrName: [],
            transferCenterId: null // 转运中心id
          }
        ]
      },
      listKey: new Date().getTime(),
      subRuleListRules: {
        gridNumber: [{ required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }],
        zipcodeAreaCode: [
          { required: true, message: this.$t('orderCenterCont.inputEmail'), trigger: ['blur', 'change'] }
        ],
        inputRequire: [{ required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] }]
      },
      gridNumsList: [],
      customerList: [],
      titleName: this.$t('GlobalSetObj.newRule'),
      providerList: [],
      isEdit: false,
      isEdit01: false,
      editId: null,
      waybillStatus: [
        { label: this.$i18n.t('newOrder.ToBeAssigned'), code: '1' }, // 待分配
        { label: this.$i18n.t('newOrder.ToBeCollected'), code: '2' }, // 待揽收
        { label: this.$i18n.t('newOrder.Collected'), code: '3' }, // 已揽收
        { label: this.$i18n.t('newOrder.CollectTheRevenuePool'), code: '4' }, // 揽收入库
        { label: this.$i18n.t('newOrder.CollectTakeOutWarehouse'), code: '5' }, // 揽收出库
        { label: this.$i18n.t('GlobalSetObj.moduan'), code: '19' }, // 末端已揽收
        { label: this.$i18n.t('newOrder.TransshipmentIntoStorage'), code: '6' }, // 转运入库
        { label: this.$i18n.t('newOrder.TransferOutWarehouse'), code: '7' }, // 转运出库
        { label: this.$i18n.t('newOrder.DeliveryOutletsIntoStorage'), code: '8' }, // 派送网点入库
        { label: this.$i18n.t('newOrder.Delivering'), code: '9' }, // 派送中
        { label: this.$i18n.t('newOrder.Signed'), code: '10' }, // 已签收
        { label: this.$i18n.t('newOrder.ProblemPiece'), code: '11' }, // 问题件
        { label: this.$i18n.t('GlobalSetObj.Canceled'), code: '13' }, // 已取消
        { label: this.$i18n.t('GlobalSetObj.deliveryFailure'), code: '14' }, // 投递失败
        { label: this.$i18n.t('GlobalSetObj.DotBack'), code: '17' }, // 网点退回
        { label: this.$i18n.t('GlobalSetObj.ReturnToStorage'), code: '18' }, // 退仓入库
        { label: this.$i18n.t('GlobalSetObj.Withdrawn'), code: '15' }, // 退件中
        { label: this.$i18n.t('GlobalSetObj.TheReturnComplete'), code: '16' }, // 退件完成
        { label: this.$i18n.t('GlobalSetObj.mutilSign'), code: '20' } // 多次签收
      ],
      isZipcode: true // 是否是邮编
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '220px' : '120px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.handleRuleType(1, -1);
    this.getdeviceList();
    this.getcustomerList();
    this.getSearch();
  },
  methods: {
    changeProviderId(providerId, index) {
      let flag = false;
      this.ruleForm.subRuleList.forEach((item, idx) => {
        if (index !== idx) {
          if (item.providerId === providerId) {
            flag = true;
          }
        }
      });
      if (flag) {
        this.ruleForm.subRuleList[index].providerId = null;
        this.$message.error(this.$t('orderCenterCont.serviceProviderConflict'));
      }
    },
    changeSiteId(siteId, index) {
      console.log('siteId', siteId, index);
      let flag = false;
      this.ruleForm.subRuleList.forEach((item, idx) => {
        if (index !== idx) {
          if (item.siteId === siteId) {
            flag = true;
          }
        }
      });
      if (flag) {
        this.ruleForm.subRuleList[index].siteId = null;
        this.$message.error(this.$t('orderCenterCont.siteConflict'));
      }
    },
    changeTransferCenterId(transferCenterId, index) {
      console.log('transferCenterId', transferCenterId, index);
      let flag = false;
      this.ruleForm.subRuleList.forEach((item, idx) => {
        if (index !== idx) {
          if (item.transferCenterId === transferCenterId) {
            flag = true;
          }
        }
      });
      if (flag) {
        this.ruleForm.subRuleList[index].transferCenterId = null;
        this.$message.error(this.$t('orderCenterCont.transferCenterConflict'));
      }
    },
    // 切换格口
    changeGridNumList(gridNumList, index) {
      let flag = false;
      this.ruleForm.subRuleList.forEach((item, idx) => {
        if (index !== idx) {
          if (item.gridNumber === gridNumList) {
            flag = true;
          }
        }
      });
      if (flag) {
        this.ruleForm.subRuleList[index].gridNumber = null;
        this.$message.error(this.$t('orderCenterCont.gridNumConflict'));
      }
    },
    handleZipcodeRange(zipcodeRange, index) {
      if (zipcodeRange) {
        const reg1 = /^[0-9]{8}$/;
        const reg2 = /^[0-9]{8}~[0-9]{8}$/;
        const splitArr = zipcodeRange.split(',');
        for (let i = 0; i < splitArr.length; i++) {
          if (reg1.test(splitArr[i]) || reg2.test(splitArr[i])) {
            if (reg2.test(splitArr[i])) {
              const [start, end] = splitArr[i].split('~').map(Number); // 分割并转换为数字
              if (start > end) {
                this.$message.error(this.$t('orderCenterCont.endThanstart'));
                this.isZipcode = false;
                return 0;
              } else {
                this.isZipcode = true;
              }
            }
            const uniqueArray = Array.from(new Set(splitArr));
            if (uniqueArray.length < splitArr.length) {
              this.$message.error(this.$t('GlobalSetObj.PostcodeDuplication'));
              this.isZipcode = false;
              return 0;
            } else {
              this.isZipcode = true;
            }
          } else {
            this.$message.error(this.$t('GlobalSetObj.Englishcomma'));
            this.isZipcode = false;
            return;
          }
        }
      } else {
        this.isZipcode = true;
      }
    },
    handleRuleType(ruleType, index) {
      console.log(ruleType, index, 'ruleType,index');
      this.isZipcode = true;
      const data = { ruleType: ruleType };
      if (index !== -1) {
        this.$nextTick(() => {
          this.ruleForm.subRuleList[index].siteId = null;
          // this.ruleForm.subRuleList[index].customerId = null;
          this.ruleForm.subRuleList[index].providerId = null;
          this.ruleForm.subRuleList[index].transferCenterId = null;
          this.ruleForm.subRuleList[index].zipcodeRange = '';
          this.ruleForm.subRuleList[index].statusStrName = [];
        });
      }
      // this.$refs['addForm']?.resetFields();
      queryByRuleType(data).then(res => {
        if (res.code===200) {
          console.log(res, 'handleRuleType');
          if (ruleType === 1) {
            this.siteList = res.data;
          } else if (ruleType === 2) {
            this.customerList = res.data;
          } else if (ruleType === 3) {
            this.providerList = res.data;
          } else if (ruleType === 8) {
            this.transferCenterList = res.data;
          }
        }
      });
    },
    handleInput(val) {
      console.log(val, 'LLKJLKJLJL');
      const pattern = /^[1-9]\d*$/;
      if (!pattern.test(val)) {
        this.queryForm.params.gridNumber = '';
      }
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.queryForm.params = {
        ruleName: '', // 规则名称
        ruleType: '', // 规则类型,1:网点，2：客户，3：服务商，4:邮编，5：状态，6：三次分拣，7：其他
        siteId: null, // 网点id
        // customerId: null, // 客户id
        gridNumber: null, // 格口号
        deviceId: '' // 设备名称
      };
      this.getSearch();
    },
    handleTest() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 新增
    newAdd() {
      this.drawer = true;
      this.titleName = this.$t('GlobalSetObj.newRule');
    },
    // 点击操作日志
    clickOperationLog() {
      this.operateDialogVisible = true;
    },
    // 查询
    getSearch() {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params.siteId = this.queryForm.params.siteId;
      data.params.ruleName = this.queryForm.params.ruleName;
      data.params.ruleType = this.queryForm.params.ruleType;
      // data.params.customerId = this.queryForm.params.customerId;
      data.params.gridNumber = this.queryForm.params.gridNumber;
      data.params.deviceId = this.queryForm.params.deviceId;
      newApiRulePage(data).then(res => {
        if (res.code===200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach(item => {
            if (item.ruleType === 1) {
              item.ruleTypeName = this.$t('GlobalSetObj.Outlets');
            } else if (item.ruleType === 2) {
              item.ruleTypeName = this.$t('GlobalSetObj.client');
            } else if (item.ruleType === 3) {
              item.ruleTypeName = this.$t('GlobalSetObj.provider');
            } else if (item.ruleType === 4) {
              item.ruleTypeName = this.$t('GlobalSetObj.zipCode');
            } else if (item.ruleType === 5) {
              item.ruleTypeName = this.$t('GlobalSetObj.statusFenjian');
            } else if (item.ruleType === 6) {
              item.ruleTypeName = this.$t('GlobalSetObj.ThreeSortingFailures');
            } else if (item.ruleType === 8) {
              item.ruleTypeName = this.$t('collectionCenter.transferCenter');
            } else {
              item.ruleTypeName = this.$t('orderCenterCont.other');
            }
          });
        }
      });
    },
    // 删除规则
    async delSubRule(row) {
      const data = {
        id: row.id,
        parentRuleId: row.parentRuleId
      };
      const res = await deleteSubRule(data);
      if (res.code===200) {
        this.$message.success(this.$t('GlobalSetObj.DeleteSuccessful'));
        this.getSearch();
      }
    },
    // 新建子规则
    async newChildRule(row) {
      console.log(row, '新建子规则');
      const data = {
        deviceName: row.deviceName,
        deviceId: row.deviceId,
        parentRuleId: row.parentRuleId
      };
      const res = await newsorterGridRuleSelectGrid(data);
      if (res.code===200) {
        this.gridNumsList = res.data;
      }
      this.ruleForm.ruleName = row.ruleName;
      this.ruleForm.deviceId = row.deviceId;
      this.ruleForm.parentRuleId = row.parentRuleId;
      this.titleName = this.$t('GlobalSetObj.newChildRule');
      this.isEdit01 = true;
      this.drawer = true;
      this.isEdit = true;
    },
    // 点击编辑
    async editRule(row) {
      this.isEdit = true;
      this.titleName = this.$t('GlobalSetObj.EditingRules');
      this.editId = row.id;
      this.ruleForm.ruleName = row.ruleName;
      this.ruleForm.deviceId = row.deviceId;
      this.ruleForm.parentRuleId = row.parentRuleId;
      // this.getGridNumList(row.deviceId);
      const data = {
        deviceName: row.deviceName,
        deviceId: row.deviceId,
        parentRuleId: row.parentRuleId,
        id: row.id
      };
      const res = await newsorterGridRuleSelectGrid(data);
      if (res.code===200) {
        this.gridNumsList = res.data;
      }
      const data1 = { ruleType: row.ruleType };
      // this.$refs['addForm']?.resetFields();
      queryByRuleType(data1).then(res => {
        if (res.code===200) {
          console.log(res, 'handleRuleType');
          if (row.ruleType === 1) {
            this.siteList = res.data;
          } else if (row.ruleType === 2) {
            this.customerList = res.data;
          } else if (row.ruleType === 3) {
            this.providerList = res.data;
          } else if (row.ruleType === 8) {
            this.transferCenterList = res.data;
          }
          this.ruleForm.subRuleList[0].ruleType = row.ruleType;
          this.ruleForm.subRuleList[0].gridNumber = row.gridNumber;
          this.ruleForm.subRuleList[0].siteId = row.siteId;
          this.ruleForm.subRuleList[0].zipcodeRange = row.zipcodeRange;
          this.ruleForm.subRuleList[0].providerId = row.providerId;
          this.ruleForm.subRuleList[0].transferCenterId = row.transferCenterId;
          if (row.statusStr) {
            this.ruleForm.subRuleList[0].statusStrName = row.statusStr.split(',');
          } else {
            this.ruleForm.subRuleList[0].statusStrName = [];
          }
        }
      });
      this.drawer = true;
      console.log(row, '点击编辑');
    },
    allExportOrder() {
      const data = {};
      data.siteId = this.queryForm.params.siteId;
      data.ruleName = this.queryForm.params.ruleName;
      data.ruleType = this.queryForm.params.ruleType;
      // data.customerId = this.queryForm.params.customerId;
      data.gridNumber = this.queryForm.params.gridNumber;
      data.deviceId = this.queryForm.params.deviceId;
      sortedRuleExport(data).then(res => {
        if (res.code===200) {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          }).catch(() => {
          });
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    // 点击创建规则
    createRule() {
      let i = 0;
      const obj = {
        id: i++,
        ruleType: null,
        gridNumber: null,
        siteId: null,
        // customerId: '', // 客户,
        providerId: '', // 供应商id
        zipcodeRange: '',
        statusStrName: [],
        transferCenterId: ''
      };
      this.ruleForm.subRuleList.push(obj);
    },
    delRule(index) {
      if (this.ruleForm.subRuleList.length > 1) {
        this.ruleForm.subRuleList.splice(index, 1);
      } else {
        this.ruleForm.subRuleList = [
          {
            id: 0,
            ruleType: null,
            gridNumber: null,
            siteId: null,
            // customerId: '', // 客户,
            providerId: '', // 供应商id
            zipcodeRange: '',
            statusStrName: [],
            transferCenterId: ''
          }
        ];
      }
    },
    // 提交保存
    saveForm() {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm, '===this.ruleForm');
          this.ruleForm.subRuleList.forEach(item => {
            if (item.statusStrName && item.statusStrName.length > 0) {
              item.statusStr = item.statusStrName.join(',');
            } else {
              item.statusStr = null;
            }
          });
          if (this.isZipcode === false) {
            this.$message.error(this.$t('GlobalSetObj.zipCodeError'));
            return;
          }
          const data = { ...this.ruleForm };
          // data.deviceId = this.ruleForm.deviceId;
          data.sorterCode = this.deviceList.find(item => item.id === this.ruleForm.deviceId).deviceNumber;
          this.ruleForm.subRuleList.forEach(item => {
            if (item.siteId) {
              item.siteName = this.siteList.find(item1 => item1.siteId === item.siteId).siteName;
            }
            if (item.providerId) {
              item.providerName = this.providerList.find(item1 => item1.providerId === item.providerId).providerName;
            }
            if (item.transferCenterId) {
              item.transferCenterName = this.transferCenterList.find(item1 => item1.transferCenterId === item.transferCenterId).transferCenterName;
            }
          });
          console.log(this.ruleForm, '===this.ruleForm');
          console.log(data, 'datadatadata');
          // return;
          if (this.titleName === this.$t('GlobalSetObj.newRule')) {
            addSortedRule(data).then(res => {
              if (res.code===200) {
                this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
                this.drawer = false;
                this.getSearch();
              }
            });
          } else if (this.titleName === this.$t('GlobalSetObj.EditingRules')) {
            const editData = {};
            editData.id = this.editId;
            editData.ruleType = this.ruleForm.subRuleList[0].ruleType;
            editData.gridNumber = this.ruleForm.subRuleList[0].gridNumber;
            editData.siteId = this.ruleForm.subRuleList[0].siteId;
            editData.zipcodeRange = this.ruleForm.subRuleList[0].zipcodeRange;
            editData.providerId = this.ruleForm.subRuleList[0].providerId;
            editData.transferCenterId = this.ruleForm.subRuleList[0].transferCenterId;
            editData.statusStr = this.ruleForm.subRuleList[0].statusStrName.join(',');
            editData.parentRuleId = this.ruleForm.parentRuleId;
            console.log(editData, 'editDataeditData');
            console.log(this.siteList, 'Three');
            if (editData.siteId) {
              editData.siteName = this.siteList.find(item => item.siteId === editData.siteId).siteName;
            }
            if (editData.providerId) {
              editData.providerName = this.providerList.find(item => item.providerId === editData.providerId).providerName;
            }
            if (editData.transferCenterId) {
              editData.transferCenterName = this.transferCenterList.find(item => item.transferCenterId === editData.transferCenterId).transferCenterName;
            }
            editSubRule(editData).then(res => {
              if (res.code===200) {
                this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
                this.drawer = false;
                this.getSearch();
              }
            }).catch(e => {
              this.ruleForm.subRuleList[0].statusStrName = [];
            });
          } else {
            console.log(this.ruleForm, '===this.ruleForm');
            newChilderRules(data).then(res => {
              if (res.code===200) {
                this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
                this.drawer = false;
                this.getSearch();
              }
            });
          }
        }
      });
    },
    // 取消
    cancelForm() {
      this.drawer = false;
    },
    // 获取设备
    getdeviceList() {
      deviceManagementList().then((res) => {
        if (res.code===200) {
          this.deviceList = res.data;
        }
        console.log(res, '设备列表');
      });
    },
    async getGridNumList(deviceId) {
      if (deviceId) {
        const deviceName = this.deviceList.find(item => item.id === deviceId).deviceName;
        const data = {
          deviceName,
          deviceId: deviceId
        };
        const res = await newsorterGridRuleSelectGrid(data);
        if (res.code===200) {
          this.gridNumsList = res.data;
          this.listKey = new Date().getTime();
        }
      } else {
        this.gridNumsList = [];
        this.listKey = new Date().getTime();
      }
      this.ruleForm.subRuleList.forEach(item => {
        item.gridNumber = null;
      });
      this.queryForm.params.gridNumber = null;
      console.log(deviceId, 'deviceId');
    },
    // 获取所有customerList
    getcustomerList() {
      const data = {
        pageNumber: 1,
        pageSize: 100000,
        params: {
          username: ''
        }
      };
      // 查询所有客户
      getAllcustomer(data).then(res => {
        if (res.code===200) {
          res.data.records.map(arr => {
            this.customerList.push({
              customerId: arr.id,
              customerName: arr.username
            });
          });
          console.log(res, '查询所有客户');
        }
      });
    },
    // 关闭弹框
    handleClosed() {
      this.drawer = false;
      this.isEdit = false;
      this.editId = null;
      this.isEdit01 = false;
      this.gridNumsList = [];
      this.ruleForm = {
        ruleName: '', // 规则名称
        deviceId: '', // 设备
        subRuleList: [
          {
            id: 0,
            ruleType: null,
            gridNumber: null,
            siteId: null,
            // customerId: '', // 客户,
            providerId: '', // 供应商id
            zipcodeRange: '',
            statusStrName: [],
            transferCenterId: ''
          }
        ]
      };
    }
  }
};
</script>

<style scoped>
.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.conditionListitme {
  position: relative;
  padding: 10px;
  background-color: #d6d6d6;
  margin-bottom: 10px;
}

.demo-drawercontent {
  padding: 0 20px;
  height: calc(100vh - 130px);
  overflow: auto;
}

.delFillter {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.demo-drawer__footer {
  width: 100%;
  border-top: 1px solid #cccccc;
  position: absolute;
  display: flex;
  justify-content: right;
  padding: 10px;
  right: 10px;
  bottom: 0;
  background-color: white;
}

>>> .el-drawer__header {
  margin-bottom: 10px;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  color: black;
}
</style>
