<template>
  <div class="app-container">
    <div class="headerOperation">
      <el-form :inline="true" @submit.native.prevent>
        <el-row :gutter="10" style="display: flex;align-items: center">
          <el-col :span="15">
            <el-form-item :label="$t('collectionCenter.parcelNum')">
              <el-input
                ref="inputRef"
                v-model="queryForm.bagNo"
                :placeholder="$t('GlobalSetObj.EnterparcelNumber')"
                clearable
                style="width: 300px"
                @keyup.enter.native="scannWaybillNumber"
              />
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item :label="$t('GlobalSetObj.GridSlogan')">
              <div class="gekou">{{ gridNums }}</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!--  表格数据  -->
    <el-table
      :cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      :header-cell-style="{ 'text-align': 'center',background:'#f2f2f2',color:'black' }"
      :show-overflow-tooltip="true"
      border
      style="width: 100%"
    >
      <el-table-column :label="$t('collectionCenter.parcelNum')" min-width="180" prop="waybillNumber">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row.isSelect"
            v-model="queryForm.waybillNumber"
            :placeholder="$t('GlobalSetObj.pleaseInput')"
            clearable
            @keyup.enter.native="getSearchEnter"
          />
          <div v-else>
            {{ scope.row.waybillNumber || '- -' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('GlobalSetObj.GridSlogan')" min-width="180" prop="gridNumber">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row.isSelect"
            v-model="queryForm.gridNumber"
            :placeholder="$t('GlobalSetObj.pleaseInput')"
            clearable
            maxlength="6"
            @keyup.enter.native="getSearchEnter"
          />
          <div v-else>
            {{ scope.row.gridNumber || '- -' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('collectionCenter.scanTime')" min-width="230" prop="createTime">
        <template slot-scope="scope">
          <el-date-picker
            v-if="scope.row.isSelect"
            v-model="queryForm.timeValue"
            :default-time="['00:00:00', '23:59:59']"
            :end-placeholder="$t('operationCenter.endDate')"
            :format="$i18n.locale==='zh'?zhDate:ptDate"
            :start-placeholder="$t('operationCenter.startDate')"
            range-separator="~"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="getSearchEnter"
          />
          <div v-else>
            {{ scope.row.createTime || '- -' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('collectionCenter.scanner')" min-width="180" prop="scanUserName">
        <template slot-scope="scope">
          <el-select
            v-if="scope.row.isSelect"
            v-model="queryForm.scanUserId"
            :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
            :remote-method="getCustomerPage"
            clearable
            filterable
            remote
            reserve-keyword
            style="width: 100%;"
            @change="getSearchEnter"
          >
            <el-option
              v-for="(item, ind) in customerList"
              :key="ind"
              :label="item.realName"
              :value="item.id"
            />
          </el-select>
          <div v-else>
            {{ scope.row.scanUserName || '- -' }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { anualSortingPage, getGridNum } from '@/api/automaticSorting';
import { queryAllUser } from '@/api/customer';
import dayjs from 'dayjs';

export default {
  name: 'SupplierKpiList',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      queryForm: {
        waybillNumber: '',
        bagNo: '',
        timeValue: [],
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        gridNumber: null, // 格口(传int)
        scanUserId: null // 扫描用户id,传int
      },
      tableData: [],
      customerList: [],
      gridNums: ''
    };
  },
  computed: {
    StatusName() {
      return {
        1: { label: '待处理', code: 1 }, // 带处理
        2: { label: '已处理', code: 2 } // 已处理
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getCustomerPage();
    this.initTime();
    this.getSearch();
  },
  methods: {
    // 初始化时间
    initTime() {
      const today = dayjs(); // 获取当前日期
      const firstDay = today.subtract(1, 'week').format('YYYY-MM-DD');
      const endDate = today.format('YYYY-MM-DD'); // 获取当前月份的结束日期
      this.queryForm.timeValue = [firstDay + ' 00:00:00', endDate + ' 23:59:59'];
    },
    getSearchEnter() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 扫描包裹号
    scannWaybillNumber() {
      const data = {
        height: 1,
        length: 1,
        weight: 1,
        width: 1
      };
      // debugger;
      data.waybillNumber = this.queryForm.bagNo;
      data.operator = this.$store.getters.userId;
      data.operatorName = this.$store.getters.name;
      data.dateTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
      console.log(data, 'datadatadatadata');
      getGridNum(data).then(res => {
        if (res.code===200) {
          console.log(res, 'resres');
          this.gridNums = res.data.gridNums;
          this.queryForm.bagNo = '';
          this.$refs.inputRef.$refs.input.focus();
          this.getSearchEnter();
        }
      }).catch(e => {
        this.$refs.inputRef.$refs.input.select();
      });
    },
    // 查询
    getSearch() {
      const data = {};
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      if (this.queryForm.gridNumber) {
        const regex = /^\d+$/;
        if (!regex.test(this.queryForm.gridNumber)) {
          this.$message.error(this.$t('dictionary.inputNum'));
          return;
        }
      }
      data.params = {
        waybillNumber: this.queryForm.waybillNumber, // 运单号（包裹号）
        gridNumber: this.queryForm.gridNumber, // 格口(传int)
        scanUserId: this.queryForm.scanUserId // 扫描用户id,传int
      };
      if (this.queryForm.timeValue) {
        data.params.scanStartTime = this.queryForm.timeValue[0];
        data.params.scanEndTime = this.queryForm.timeValue[1];
      }
      anualSortingPage(data).then(res => {
        if (res.code===200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.unshift({
            id: 0,
            isSelect: true
          });
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    /**
     * @description 分页获取客户列表
     * @param { string } name 客户名称
     */
    getCustomerPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          realName: name
        }
      };
      queryAllUser(param).then((res) => {
        if (res.code===200) {
          this.customerList = res.data.records;
        }
      });
    }
  }
};
</script>
<style scoped>
.headerOperation {
  border: 1px solid #dcdfe6;
  padding: 15px 15px 0 15px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.gekou {
  width: 100px;
  height: 100px;
  border: 1px solid #dcdfe6;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
}

.app-container /deep/ .el-dialog__body {
  padding: 10px 20px;
}

.app-container /deep/ .el-form--label-top .el-form-item__label {
  width: 100% !important;
}
</style>
