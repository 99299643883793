<template>
  <div class="content">
    <div style="height: calc(100% - 55px); overflow-y: scroll;">
      <!-- 付款 -->
      <el-card v-if="[3].includes(claimsStatus)">
        <el-button
          v-permit:remove="'but:compensationManagement:compensationList:obligation'"
          type="success" size="small"
          style="margin-bottom: 10px;" @click="SubmitPayment"
        >
          {{ this.$t('compensationManagement.SubmitPayment') }}
        </el-button>
        <!--取消付款-->
        <el-button
          v-permit:remove="'but:compensationManagement:compensationList:CancelPayment'"
          type="danger" size="small"
          style="margin-bottom: 10px;" @click="CancelPayment"
        >
          {{ this.$t('compensationManagement.CancelPayment') }}
        </el-button>
        <el-input
          v-model="mark"
          type="textarea"
          :rows="3"
          maxlength="2000"
          show-word-limit
          :placeholder="this.$t('GlobalSetObj.pleaseInputContent')"
        />
      </el-card>

      <!-- 审核 -->
      <el-card v-if="[1].includes(claimsStatus) && reviewer === userId">
        <el-button
          v-permit:remove="'but:compensationManagement:compensationList:process'"
          type="success" size="small"
          style="margin-bottom: 10px;" @click="toExamine(1)"
        >
          {{ this.$t('compensationManagement.Pass') }}
        </el-button>
        <el-button
          v-permit:remove="'but:compensationManagement:compensationList:process'"
          type="danger" size="small"
          style="margin-bottom: 10px;" @click="toExamine(2)"
        >
          {{ this.$t('compensationManagement.TurnDown') }}
        </el-button>
        <el-input
          v-model="rejectionReasons"
          type="textarea"
          :rows="3"
          maxlength="2000"
          show-word-limit
          :placeholder="this.$t('GlobalSetObj.pleaseInputContent')"
        />
      </el-card>

      <!-- 基础信息 -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>{{ $t('newOrder.BasicInformation') }}</span>
        </div>
        <BasicForm
          :id="id"
          ref="basicForm"
          :key="updateKey"
          :status="claimsStatus"
          @change="updateForm"
          @removeChange="remove"
        />
      </el-card>

      <!-- 关联订单 -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>{{ $t('ClaimsManagement.LinkedOrder') }}</span>
        </div>
        <BasicTable
          :id="id"
          ref="basicTable"
          :key="updateKey"
          :status="claimsStatus"
          :form-data="formList"
          :data-list="claimRelatedOrdersList"
          @close="onClose"
          @change="clickUpdate"
        />
      </el-card>

      <!-- 索赔流程 -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>{{ $t('compensationManagement.ClaimSettlementProcess') }}</span>
        </div>
        <NodeTable ref="nodeTable" :key="updateKey" :data-list="claimFlows" />
      </el-card>
    </div>

    <!-- 操作区 -->
    <div v-if="![4,5].includes(claimsStatus)" class="footer">
      <el-row :gutter="20">
        <template v-for="(item, index) in btnList">
          <el-button
            v-if="item.show"
            :key="index"
            size="small"
            :type="item.type"
            @click="btnClick(item.btnType)"
          >{{ item.name }}</el-button>
        </template>
      </el-row>
    </div>
  </div>
</template>

<script>
import i18n from '@/lang';
import BasicForm from './basicForm.vue';
import BasicTable from './basicTable.vue';
import NodeTable from './nodeTable.vue';
import {
  apiDamageClaimFormId,
  apiDemageClaimformClose,
  apiDemageClaimformCommitPay,
  apiDemageClaimformRejectAndPass, apiDemageClaimformUnCommitPay
} from '@/api/compensationManagement/list';

import { commonMinXin } from '../../commonMinXin.js';

export default {

  components: {
    BasicForm,
    BasicTable,
    NodeTable
  },

  mixins: [commonMinXin],
  props: {
    id: {
      type: [String, Number],
      default: () => ''
    },
    status: {
      type: [Number],
      default: () => 0
      // 0-待提交 1-待审核 2-已驳回 3-待付款 4-已完成 5-已关闭 7-付款中
    }
  },

  data() {
    return {
      auditStatus: 2, // 1-审核提交 2-保存草稿
      reviewer: '', // 审核人
      userId: '', // 当前登录用户id
      claimsStatus: 0, // 状态
      formList: {}, // 基础信息
      claimRelatedOrdersList: [], // 关联订单
      claimFlows: [], // 索赔流程
      rejectionReasons: '', // 审核备注
      mark: '', // 付款备注
      updateKey: new Date().getTime(),
      btnList: [
        {
          type: '',
          btnType: 'cancel',
          name: i18n.t('GlobalSetObj.cancel')
        },
        {
          type: 'danger',
          btnType: 'close',
          name: i18n.t('GlobalSetObj.close')
        },
        {
          type: 'success',
          btnType: 'save',
          name: i18n.t('customerManagements.Save')
        },
        {
          type: 'primary',
          btnType: 'submit',
          name: i18n.t('customerManagements.submit')
        }
      ]
    };
  },

  created() {
    if (this.id) {
      // 获取详情
      this.asyncApiDemageClaimFormIds();
    }

    this.userId = this.$store.getters.userId;
  },

  methods: {
    // 获取详情
    asyncApiDemageClaimFormIds() {
      apiDamageClaimFormId(this.id).then(res => {
        if (res.code === 200) {
          const { claimsStatus, claimRelatedOrdersList, claimFlows, reviewer } = res.data;
          this.claimsStatus = claimsStatus;
          this.reviewer = reviewer;
          // 基础信息
          setTimeout(() => {
            this.$refs.basicForm.setFormData(res.data);
          }, 300);
          this.formList = res.data;

          // 关联订单
          this.claimRelatedOrdersList = claimRelatedOrdersList || [];

          // 索赔流程
          this.claimFlows = claimFlows || [];
          this.updateKey = new Date().getTime();
          this.upDateBtn();
        }
      }).catch(err => {
        console.error(err);
      });
    },

    // 更新按钮状态
    upDateBtn() {
      this.btnList = this.btnList.map(item => {
        if ([0, 1, 2, 3].includes(this.claimsStatus)) {
          // 审核 付款隐藏保存 提交
          if (['save', 'submit'].includes(item.btnType) && [1, 3].includes(this.claimsStatus)) {
            item.show = false;
          } else item.show = !(['save'].includes(item.btnType) && this.claimsStatus === 2);
        }
        return item;
      });
    },

    // 按钮点击事件
    btnClick(type) {
      switch (type) {
        case 'cancel':
          this.$emit('close', false);
          break;
        case 'close':
          this.close();
          break;
        case 'save':
          this.save();
          break;
        case 'submit':
          this.submit();
          break;
      }
    },

    // 关闭事件
    close() {
      this.$confirm(`${this.$t('GlobalSetObj.confirmClosure')}?`, this.$t('GlobalSetObj.tips'), {
        confirmButtonText: this.$t('GlobalSetObj.determine'),
        cancelButtonText: this.$t('GlobalSetObj.cancel'),
        type: 'warning'
      }).then(() => {
        apiDemageClaimformClose(this.id).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
            this.$emit('close', true);
          }
        }).catch(err => {
          console.error(err);
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('GlobalSetObj.cancel')
        });
      });
    },

    // 提交付款
    SubmitPayment() {
      apiDemageClaimformCommitPay({
        id: this.id,
        mark: this.mark
      }).then(res => {
        if (res.code === 200) {
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.$emit('close', true);
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 取消付款
    CancelPayment() {
      this.$confirm(`${this.$t('compensationManagement.CancelPayment')}?`, this.$t('GlobalSetObj.tips'), {
        confirmButtonText: this.$t('GlobalSetObj.determine'),
        cancelButtonText: this.$t('GlobalSetObj.cancel'),
        type: 'warning'
      }).then(() => {
        apiDemageClaimformUnCommitPay({
          id: this.id,
          mark: this.mark
        }).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
            this.$emit('close', true);
          }
        });
      });
    },
    /**
     * 审核
     * @param type 1 通过 2 驳回
     */
    toExamine(type) {
      if (type === 2) {
        if (!this.rejectionReasons) {
          this.$message.warning(this.$t('compensationManagement.PleaseEnterTheReasonForRejection'));
          return;
        }
      }
      apiDemageClaimformRejectAndPass({
        id: this.id,
        passed: type,
        rejectionReasons: this.rejectionReasons
      }).then(res => {
        if (res.code === 200) {
          this.$message.success(this.$t('GlobalSetObj.succeed'));
          this.$emit('close', true);
        }
      }).catch(err => {
        console.error(err);
      });
    },

    onClose() {
      this.$emit('close', false);
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  height: calc(100vh - 75px);
  overflow-y: auto;
}

.el-card {
  margin-bottom: 10px;
}

.clearfix {
  padding-left: 10px;
}

.footer {
  padding: 10px 20px;
  display: flex;
  flex-direction: row-reverse;
  position: sticky;
  bottom: 0;
  border-top: 1px solid #ebeef5;
  background-color: #fff;
  z-index: 999;
}
</style>
