<template>
  <!-- 装车扫描 -->
  <div class="Box">
    <div>
      <el-steps :active="active" finish-status="success" align-center>
        <el-step :title="$t('collectionCenter.scanningVehicle')" /><!--  扫描车辆  -->
        <el-step :title="$t('collectionCenter.scanningBag')" /><!--  扫描袋号  -->
        <el-step :title="$t('collectionCenter.scanningCarTag')" /><!--  扫描车签号  -->
      </el-steps>
    </div>
    <!--  扫描  -->
    <div v-show="active == 0" class="scanClass">
      <span>{{ $t('collectionCenter.carScan') }}</span><!--  车辆扫描：  -->
      <el-input
        v-model.trim="carMsg"
        :placeholder="$t('collectionCenter.scanQRCodeOrNum')"
        clearable
        @keyup.enter.native="scanCar"
      /><!--  请扫描车辆二维码或输入车辆编号  -->
      <!--
      <el-tooltip v-else effect="dark" :content="$t('userManage.unauthorized')" placement="right">
        <el-input v-model.trim="carMsg" :placeholder="$t('collectionCenter.scanQRCodeOrNum')" clearable />
      </el-tooltip>
      -->
    </div>
    <!--  车辆信息  -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
      @close="handleClose"
    >
      <div class="dialogClass">
        <!--  车辆编号：  -->
        <span>{{ $t('collectionCenter.carNum') }}{{ carList.id }}</span>
        <!--  车牌号：  -->
        <span>{{ $t('collectionCenter.licensePlateNum') }}{{ carList.vehicleNumber }}</span>
        <!--  运营模式：  -->
        <span>{{ $t('collectionCenter.operationM') }}{{ carList.operationModeName }}</span>
        <!--  车辆类型：  -->
        <span>{{ $t('collectionCenter.carT') }}{{ carList.vehicleTypeName }}</span>
        <!--  驾驶人：  -->
        <span>{{ $t('collectionCenter.carDriver') }}{{ carList.vehicleDriver }}</span>
        <!--  所属网点：  -->
        <span>{{ $t('collectionCenter.belongToSite') }}{{ carList.siteIdName }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--  下一步  -->
        <el-button v-permit:remove="'btn:collection-center:loading-scan:nextAllowShow'" type="primary" @click="next">{{ $t('collectionCenter.next') }}</el-button>
      </span>
    </el-dialog>
    <!--  袋号扫描  -->
    <div v-show="active == 1">
      <div class="scanClass">
        <span>{{ $t('collectionCenter.bagNumScan') }}</span><!--  袋号扫描：  -->
        <el-input
          v-model.trim="bagMsg"
          :placeholder="$t('collectionCenter.pleaseScanBagNum')"
          clearable
          @keyup.enter.native="scanBag"
        /><!--  请扫描袋号  -->
      </div>

      <div class="scanClass">
        <span>{{ $t('GlobalSetObj.nextStation') }}：</span><!-- 下一站： -->
        <el-select
          v-model="nextSite" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
          filterable
          remote
          reserve-keyword
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="(item, index) in siteIdList"
            :key="index"
            :label="item.name"
            :value="item.id + '/' + item.name"
          />
        </el-select>
      </div>
      <el-button v-permit:remove="'btn:collection-center:loading-scan:lastAllowShow'" style="margin-top: 12px;" @click="last">{{ $t('userManage.Previous') }}</el-button><!--  返回上一步  -->
      <el-button
        v-permit:remove="'btn:collection-center:loading-scan:nextAllowShow'" type="primary" style="margin-top: 12px;"
        @click="next"
      >{{ $t('collectionCenter.next') }}</el-button><!--  下一步  -->
    </div>
    <div v-show="active == 2 && signatureFilling">
      <div class="scanClass">
        <!--  车签号扫描：  -->
        <span class="requiredClass">{{ $t('collectionCenter.carNumScan') }}</span>
        <el-input
          v-model.trim="carTagMsg"
          :placeholder="$t('collectionCenter.pleaseScanCarNum')"
          clearable
          @keyup.enter.native="scanCarTag"
        /><!--  请扫描车签号  -->
      </div>
      <el-button v-permit:remove="'btn:collection-center:loading-scan:lastAllowShow'" style="margin-top: 12px;" @click="last">{{ $t('userManage.Previous') }}</el-button><!--  返回上一步  -->
    </div>
    <!--  袋号表  -->
    <div v-if="active == 1" class="tableClass">
      <!--  已扫描  -->
      <div class="titleTop">{{ $t('collectionCenter.scanned') }}：<span class="totalClass">{{ tablePage.length }}</span></div>
      <el-table
        class="scanTable"
        :max-height="600"
        :data="tablePage"
        border
      >
        <el-table-column
          align="center"
          prop="bagNumber"
          :label="$t('collectionCenter.BagNumber')"
        /><!-- 袋号 -->
        <el-table-column
          align="center"
          prop="scanTimeStr"
          :label="$t('collectionCenter.scanTime')"
        /><!-- 扫描时间 -->
        <el-table-column
          v-if="delAllowShow"
          :label="$t('collectionCenter.operation')"
          fixed="right"
          width="100"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-popconfirm
              :title="$t('collectionCenter.deleteSure')"
              @confirm="scanDelClick(scope.row, scope.$index)"
            ><!-- 确定删除吗？ -->
              <el-button
                slot="reference" :disabled="active == 2" type="danger"
                size="mini"
              >{{ $t('collectionCenter.Delete') }}</el-button><!-- 删除 -->
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--  扫描车签号——结束装车  -->
    <el-dialog
      title=""
      :visible.sync="carTagDialogVisible"
      :close-on-click-modal="false"
      width="30%"
      @close="carTagHandleClose"
    >
      <div class="dialogClass">
        <!--  车签号：  -->
        <span>{{ $t('collectionCenter.bagNumber') }}{{ carTagMsg }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--  装车结束  -->
        <el-button type="primary" style="margin-top: 12px;" @click="finish">{{ $t('collectionCenter.loadingEnd') }}</el-button>
      </span>
    </el-dialog>

    <div v-if="signVisible" class="signMask">
      <div class="signVisibleClass">
        <div class="el-icon-close signClose" @click="closeSign" />
        <div class="descriptionsClass">
          <div ref="image">
            <el-descriptions
              title="" :column="1" border
              :content-style="rowClass" :label-style="labelRowClass"
            >
              <el-descriptions-item label="">
                <div class="top1">
                  <!-- 车牌号： -->
                  <span>{{ $t('collectionCenter.licensePlateNum') }}{{ scanSign.carNum }}</span>
                  <img :src="LOGO" alt="">
                </div>
              </el-descriptions-item>
              <el-descriptions-item label=""><div class="isolateClass" /></el-descriptions-item>
              <el-descriptions-item label="">
                <div class="two">
                  <!-- 装车地点： -->
                  <div class="dd">{{ $t('collectionCenter.loadingPoint') }}</div>
                  <div class="dd">{{ scanSign.loadedSite }}</div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="">
                <div class="two">
                  <!-- 装车人： -->
                  <div class="dd">{{ $t('collectionCenter.loader') }}：</div>
                  <div class="dd">{{ scanSign.loadedPeople }}</div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="">
                <div class="two">
                  <!-- 装车时间： -->
                  <div class="dd">{{ $t('collectionCenter.loadTime') }}：</div>
                  <div class="dd">{{ scanSign.signerTime }}</div>
                </div>
              </el-descriptions-item>
              <!-- 包裹信息 -->
              <el-descriptions-item label=""><div class="isolateClass">{{ $t('collectionCenter.infoPackage') }}</div></el-descriptions-item>
              <el-descriptions-item label="">
                <div class="two">
                  <!-- 装车总数： -->
                  <div class="dd">{{ $t('collectionCenter.totalLoad') }}</div>
                  <div class="dd">{{ scanSign.realityNumber }}{{ $t('collectionCenter.bag') }}（{{ scanSign.bagNumberCount }}）{{ $t('collectionCenter.ticket') }}</div>
                </div>
              </el-descriptions-item>
              <!-- 签名确认 -->
              <el-descriptions-item label=""><div class="isolateClass">{{ $t('collectionCenter.signatureConfirmation') }}</div></el-descriptions-item>
              <el-descriptions-item label="">
                <div class="two">
                  <!-- 司机是否符合预期： -->
                  <div class="dd isDriver">
                    <span class="req">{{ $t('collectionCenter.driverIsExpect') }}</span>
                    <el-radio-group v-model="scanSign.isDriver">
                      <el-radio :label="1">{{ $t('Resource.yes') }}</el-radio><!-- 是 -->
                      <el-radio :label="2">{{ $t('Resource.No') }}</el-radio><!-- 否 -->
                    </el-radio-group>
                    <div v-if="isNull && !scanSign.isDriver" class="redTip">- {{ $t('collectionCenter.pleaseChoose') }} -</div>
                  </div>
                  <div class="dd textarea">
                    <!-- 备注 -->
                    <span>{{ $t('collectionCenter.Note') }}：</span>
                    <div v-if="showTime" class="remarkShow">
                      {{ scanSign.remark }}
                    </div>
                    <el-input
                      v-else
                      v-model="scanSign.remark"
                      type="textarea"
                      autosize
                      :placeholder="$t('collectionCenter.driverRemark')"
                    />
                  </div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="">
                <div style="width: 550px;height: 260px;overflow: hidden;">
                  <vue-esign
                    ref="esign" :width="550" :height="260"
                    :line-width="5" line-color="#000" bg-color="#FFF"
                  />
                </div>
              </el-descriptions-item>
            </el-descriptions>
            <div class="statementClass">
              Declaro para todos os fins legais que li e verifiquei a exatidão e veracidade de todas as informações contidas neste formulário, e assumo total responsabilidade civil e criminal por quaisquer danos materiais e morais decorrentes de qualquer não conformidade das informações aqui contidas.
            </div>
          </div>
          <div class="signBtn">
            <el-button type="success" class="btn" @click="handleGenerate">{{ $t('collectionCenter.Confirm') }}</el-button><!-- 确认 -->
            <el-button class="btn" @click="resetSign">{{ $t('collectionCenter.empty') }}</el-button><!-- 置空 -->
          </div>
        </div>
      </div>
    </div>

    <!-- 拍照 -->
    <div v-if="active !== 0" class="cameraClass"><!--  上传xx图片：  -->
      <span>{{ $t('collectionCenter.Upload') + $t('collectionCenter.truckLoading') + $t('collectionCenter.picture') + '：' }}</span>
      <camera ref="changeImg" :file-list1="fileList1" @uploadImg="getImg" />
    </div>

    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';
import {
  checkVehicle,
  checkVehicleLoadedBagNumber,
  deletedVehicleLoadedBagNumber,
  loadedVehicle,
  BagScanRecord,
  queryBagCountAndSiteByBagNumber,
  siteFuzzyQuery
} from '@/api/lanshou';
import camera from './camera';
import html2canvas from 'html2canvas';
import { uploadOos } from '@/utils/ali_oos_util';
export default {
  name: 'LoadingScan',
  components: {
    camera
  },
  data() {
    return {
      delAllowShow: this.$store.getters.button_permissions.includes('btn:collection-center:loading-scan:delAllowShow'),
      nextSite: '', // 下一站
      siteIdList: [], // （启用）网点list
      showTime: false, // 签名截图 备注字段截图bug
      isNull: false,
      signVisible: false,
      signatureFilling: false,
      signImg: '',
      scanSign: {
        carNum: '',
        loadedSite: '', // 装车地点
        loadedPeople: '', // 装车人
        signerTime: '', // 装车时间
        realityNumber: '', // 袋号数量（左侧数量）
        bagNumberCount: '', // 装车的总票数（右侧数量）
        isDriver: '', // 司机是否符合预期
        remark: '',
        scanNumbers: ''
      },
      rowClass: {
        'border-width': '2px',
        'border-color': '#000'
      },
      labelRowClass: {
        'border-width': '2px',
        'border-color': '#000'
      },
      LOGO: '/static/anjunLogo.png',
      okMp3,
      errorMp3,
      // isScanCar: this.$store.getters.button_permissions.includes('btn:collection-center:operation:loadingScan:scan'),
      fileList1: [],
      carTagDialogVisible: false,
      active: 0,
      dialogVisible: false,
      carMsg: '', // 车辆扫描
      carList: {}, // 扫描车辆获取的返回值
      vehicleNumber: '', // 提交需要的车牌号缓存
      bagMsg: '',
      bagForm: { // 袋号扫描参数
        bagNumber: '',
        vehicleId: ''
      },
      tablePage: [],
      carTagMsg: '',
      loadingScanList: {}, // 装车扫描缓存
      carBagCache: {}, // 根据车辆保存对应的袋号————缓存
      // 后加的提交用到的参数
      operationMode: '',
      vehicleType: ''
    };
  },
  watch: {
    nextSite(val) {
      if (!val) {
        this.remoteMethod();
      }
    }
  },
  created() {
    this.remoteMethod();
    if (localStorage.getItem('lsList')) {
      // 切换账号后，先判断scanTableData有无值，没有值再获取缓存scanCourier值
      this.loadingScanList = JSON.parse(localStorage.getItem('lsList')) || {};
      const who = 'ls' + this.$store.state.user.id;
      if (this.loadingScanList[who]) {
        this.bagForm = this.loadingScanList[who].bagForm;
        this.tablePage = this.loadingScanList[who].tablePage;
        this.active = this.loadingScanList[who].active;
        this.fileList1 = this.loadingScanList[who].fileList1;
        this.operationMode = this.loadingScanList[who].operationMode;
        this.vehicleType = this.loadingScanList[who].vehicleType;
        this.vehicleNumber = this.loadingScanList[who].vehicleNumber;
        this.nextSite = this.loadingScanList[who].nextSite;
      } else {
        this.nextSite = '';
        this.bagForm = {};
        this.tablePage = [];
        this.active = 0;
      }
    } else {
      this.bagForm = {};
      this.tablePage = [];
      this.active = 0;
    }
    if (this.active === 2) {
      this.active = 1;
    }
    this.signVisible = false;
    this.signatureFilling = false;
  },
  methods: {
    remoteMethod(val) {
      const value = val || '';
      siteFuzzyQuery(value).then(res => {
        if (res.code === 200) {
          this.siteIdList = res.data;
        }
      });
    },
    handleGenerate() {
      if (this.scanSign.remark) {
        this.showTime = true;
        setTimeout(() => {
          this.showTime = false;
        }, 2000);
      }
      this.$refs['esign'].generate().then(res => {
        if (!this.scanSign.isDriver) {
          this.isNull = true;
          return;
        } else {
          this.canvasImg();
        }
      }).catch((err) => { // 没有签名，点击生成图片时调用
        console.log(err);
        this.$message({
          message: this.$t('collectionCenter.unsigned'), // '未签名!'
          type: 'warning'
        });
      });
    },
    canvasImg() {
      const loading = this.$loading({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
        background: 'rgba(250, 250, 250, 0.6)'
      });
      const canvas = document.createElement('canvas'); // 截图区
      const canvasBox = this.$refs.image; // 父级
      const width = parseInt(window.getComputedStyle(canvasBox).width);
      const height = parseInt(window.getComputedStyle(canvasBox).height);
      const size = window.devicePixelRatio;
      canvas.width = width * 2 * size;
      canvas.height = height * 2 * size;
      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';
      const context = canvas.getContext('2d');
      context.scale(2, 2);
      const options = {
        backgroundColor: null,
        canvas: canvas,
        useCORS: true
      };
      html2canvas(canvasBox, options).then((canvas) => {
        // toDataURL 图片格式转成 base64
        const dataURL = canvas.toDataURL('image/png');
        const rSignImg = this.base64ImgtoFile(dataURL, 'file'); // 得到文件格式
        uploadOos(rSignImg, 'sign').then(res => {
          if (res.code === 200) {
            if (res.url) {
              this.signImg = res.url;
              this.signVisible = false;
              this.signatureFilling = true;
              this.scanSign = {};
              this.isNull = false;
              loading.close();
            } else {
              this.signImg = '';
            }
          }
        }).catch({}).finally(() => {
          loading.close();
        }).finally(() => {
          loading.close();
        });
      });
    },
    base64ImgtoFile(dataurl, filename = 'file') {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const suffix = mime.split('/')[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      });
    },
    resetSign() {
      this.$refs['esign'].reset(); // 清空画布
    },
    closeSign() {
      this.signVisible = false;
      this.active--;
      this.scanSign = {};
    },
    // 装车图片
    getImg(val) {
      if (val) {
        this.fileList1 = val;
        this.loadingScanList['ls' + this.$store.state.user.id] = {
          nextSite: this.nextSite,
          bagForm: this.bagForm,
          tablePage: this.tablePage,
          active: this.active,
          fileList1: val,
          operationMode: this.operationMode,
          vehicleType: this.vehicleType,
          vehicleNumber: this.vehicleNumber
        };
        // 加密
        const a = JSON.stringify(this.loadingScanList);
        localStorage.setItem('lsList', a);
      } else {
        this.fileList1 = [];
        this.loadingScanList['ls' + this.$store.state.user.id] = {
          nextSite: this.nextSite,
          bagForm: this.bagForm,
          tablePage: this.tablePage,
          active: this.active,
          fileList1: [],
          operationMode: this.operationMode,
          vehicleType: this.vehicleType,
          vehicleNumber: this.vehicleNumber
        };
        // 加密
        const a = JSON.stringify(this.loadingScanList);
        localStorage.setItem('lsList', a);
        return;
      }
    },
    scanCarTag() {
      if (!this.carTagMsg) {
        this.$message.warning(this.$t('collectionCenter.carTagNull')); // 车签号不能为空
        this.$refs.errRef.play();
        return;
      }
      this.carTagDialogVisible = true;
    },
    carTagHandleClose() {
      this.carTagMsg = '';
      this.carTagDialogVisible = false;
    },
    last() {
      this.active--;
      this.carMsg = '';
      this.carTagMsg = '';
      this.bagMsg = '';
      this.loadingScanList['ls' + this.$store.state.user.id] = {
        nextSite: this.nextSite,
        bagForm: this.bagForm,
        tablePage: this.tablePage,
        active: this.active,
        fileList1: this.fileList1,
        operationMode: this.operationMode,
        vehicleType: this.vehicleType,
        vehicleNumber: this.vehicleNumber
      };
      // 加密
      const a = JSON.stringify(this.loadingScanList);
      localStorage.setItem('lsList', a);

      if (this.active === 0) {
        this.nextSite = '';
      }
    },
    next() {
      this.dialogVisible = false;
      this.carMsg = '';
      this.carTagMsg = '';
      this.bagMsg = '';
      if (this.active === 1 && this.tablePage.length <= 0) {
        // 无数据提交
        this.$message({
          message: this.$t('collectionCenter.nullMsg'), // '暂无数据'
          type: 'warning'
        });
        return;
      }
      // // 保留每台车辆扫入的缓存袋号信息
      // if (this.active === 0 && localStorage.getItem('carBag')) {
      //   // 拿到扫描袋号后的缓存，根据车辆id赋值对应的袋号
      //   this.carBagCache = JSON.parse(localStorage.getItem('carBag'));
      //   const carBagLst = this.carBagCache[this.bagForm.vehicleId];
      //   if (carBagLst && (this.bagForm.vehicleId === carBagLst.vehicleId)) {
      //     console.log(this.carBagCache[this.bagForm.vehicleId].tablePage, 'hiugyugyug');
      //     this.tablePage = this.carBagCache[this.bagForm.vehicleId].tablePage;
      //   } else {
      //     this.tablePage = [];
      //   }
      // }

      this.active++;

      if (this.active === 2) {
        this.getSignVal();
      }

      this.loadingScanList['ls' + this.$store.state.user.id] = {
        nextSite: this.nextSite,
        bagForm: this.bagForm,
        tablePage: this.tablePage,
        active: this.active,
        fileList1: this.fileList1,
        operationMode: this.operationMode,
        vehicleType: this.vehicleType,
        vehicleNumber: this.vehicleNumber
      };
      // 加密
      const a = JSON.stringify(this.loadingScanList);
      localStorage.setItem('lsList', a);
    },
    // 签名模板
    getSignVal() {
      const bb = this.tablePage.map(arr => {
        return {
          bagNumber: arr.bagNumber,
          type: arr.type
        };
      });
      const signForm = bb;
      queryBagCountAndSiteByBagNumber(signForm).then(res => {
        this.scanSign = {
          carNum: this.vehicleNumber,
          realityNumber: res.data.realityNumber, // 袋号数量（左侧数量）
          bagNumberCount: res.data.bagNumberCount, // 装车的总票数（右侧数量）
          signerTime: res.data.signerTime || '', // 签收时间
          scanNumbers: res.data.scanNumbers || '',
          loadedSite: res.data.loadedSite || '', // 装车地点
          loadedPeople: this.$store.state.user.realName, // 装车人
          isDriver: this.scanSign.isDriver, // 司机是否符合预期
          remark: this.scanSign.remark
        };
        this.signVisible = true;
      }).catch({});
    },
    // 获取展示车的信息——弹出弹框
    scanCar() {
      if (!this.carMsg) {
        this.$message({
          message: this.$t('collectionCenter.pleaseEnterOrScan'), // '请输入或扫描'
          type: 'warning'
        });
        this.$refs.errRef.play();
        return;
      }
      const checkVehicleParams = {
        vehicleId: this.carMsg
      };
      checkVehicle(checkVehicleParams).then(res => {
        if (res.code === 200) {
          this.operationMode = res.data.operationModeName;
          this.vehicleType = res.data.vehicleTypeName;
          this.carList = res.data;
          this.vehicleNumber = res.data.vehicleNumber;
          this.bagForm.vehicleId = res.data.id;

          // 传入 res.data.id 查询扫描的袋号数据
          BagScanRecord(res.data.id).then(ress => {
            if (ress.code === 200) {
              this.tablePage = ress.data;
              this.dialogVisible = true;
              this.$refs.okRef.play();
            } else {
              this.$refs.errRef.play();
            }
          }).catch(() => {
            this.$refs.errRef.play();
          });

          this.loadingScanList['ls' + this.$store.state.user.id] = {
            nextSite: this.nextSite,
            vehicleNumber: this.vehicleNumber,
            bagForm: this.bagForm,
            tablePage: this.tablePage,
            active: this.active,
            fileList1: this.fileList1,
            operationMode: res.data.operationModeName,
            vehicleType: res.data.vehicleTypeName
          };
          // 加密
          const a = JSON.stringify(this.loadingScanList);
          localStorage.setItem('lsList', a);
        }
      }).catch(() => {
        this.carMsg = '';
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    scanBag() {
      if (!this.nextSite) {
        this.$message({
          message: this.$t('collectionCenter.pleaseChooseNextSite'), // '请选择下一站'
          type: 'warning'
        });
        return;
      }
      if (!this.bagMsg) {
        this.$message({
          message: this.$t('collectionCenter.pleaseEnterOrScan'), // '请输入或扫描'
          type: 'warning'
        });
        this.$refs.errRef.play();
        return;
      }

      // 判断重复值，减少接口请求次数
      const alike = this.tablePage.some(el => {
        return el.bagNumber === this.bagMsg;
      });
      if (alike && this.tablePage.length > 0) {
        this.$message({
          message: this.$t('collectionCenter.bagExist'), // '当前袋号重复'
          type: 'warning'
        });
        this.$refs.errRef.play();
        return;
      }

      const bagMsg = this.bagMsg;
      this.bagForm.bagNumber = bagMsg;
      let preDeliverStationId = this.nextSite ? Number(this.nextSite.split('/')[0]) : '';
      preDeliverStationId = preDeliverStationId === null ? '' : preDeliverStationId;
      const bagForm = {
        bagNumber: bagMsg,
        vehicleId: this.bagForm.vehicleId,
        siteId: this.nextSite ? Number(this.nextSite.split('/')[0]) : '',
        siteType: '',
        preDeliverStationId
      };
      checkVehicleLoadedBagNumber(bagForm).then(res => {
        if (res.code === 200) {
          this.tablePage.unshift({
            vehicleId: this.bagForm.vehicleId,
            bagNumber: res.data.newBagNumber ? res.data.newBagNumber : bagMsg,
            scanTimeStr: res.data.scanTimeStr,
            scanTime: res.data.scanTime,
            type: res.data.type,
            preDeliverStationId: this.nextSite ? this.nextSite.split('/')[0] : res.data.preDeliverStationId,
            preDeliverStationName: this.nextSite ? this.nextSite.split('/')[1] : res.data.preDeliverStationName,
            newBagNumber: res.data.newBagNumber
          });
          const idName = res.data.preDeliverStationId && res.data.preDeliverStationName ? res.data.preDeliverStationId + '/' + res.data.preDeliverStationName : '';
          this.nextSite = !this.nextSite ? idName : this.nextSite;
          this.loadingScanList['ls' + this.$store.state.user.id] = {
            nextSite: this.nextSite,
            bagForm: this.bagForm,
            tablePage: this.tablePage,
            active: this.active,
            fileList1: this.fileList1,
            operationMode: this.operationMode,
            vehicleType: this.vehicleType,
            vehicleNumber: this.vehicleNumber
          };
          // 加密
          const a = JSON.stringify(this.loadingScanList);
          localStorage.setItem('lsList', a);

          // 车辆对应的袋号另外缓存
          this.carBagCache[this.bagForm.vehicleId] = {
            vehicleId: this.bagForm.vehicleId,
            tablePage: this.tablePage
          };
          const carBag = JSON.stringify(this.carBagCache);
          localStorage.setItem('carBag', carBag);
          this.bagMsg = '';
          this.$refs.okRef.play();
        } else {
          this.bagMsg = '';
          this.$refs.errRef.play();
        }
      }).catch(() => {
        this.bagMsg = '';
        this.$refs.errRef.play();
      });
    },
    scanDelClick(row, index) {
      const delForm = {
        bagNumber: row.bagNumber,
        vehicleId: row.vehicleId
      };
      deletedVehicleLoadedBagNumber(delForm).then(res => {
        this.tablePage.splice(index, 1);
        if (this.tablePage.length <= 0 || row.bagNumber == this.bagMsg) {
          this.bagMsg = '';
        }
        if (this.tablePage.length <= 0) {
          this.nextSite = '';
        }
        this.loadingScanList['ls' + this.$store.state.user.id] = {
          nextSite: this.nextSite,
          bagForm: this.bagForm,
          tablePage: this.tablePage,
          active: this.active,
          fileList1: this.fileList1,
          operationMode: this.operationMode,
          vehicleType: this.vehicleType,
          vehicleNumber: this.vehicleNumber
        };
        // 加密
        const a = JSON.stringify(this.loadingScanList);
        localStorage.setItem('lsList', a);

        // 车辆对应的袋号另外缓存
        this.carBagCache[this.bagForm.vehicleId] = {
          vehicleId: this.bagForm.vehicleId,
          tablePage: this.tablePage
        };
        const carBag = JSON.stringify(this.carBagCache);
        localStorage.setItem('carBag', carBag);
      }).catch({});
    },
    finish() {
      if (this.fileList1.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.pleaseUploadLI')); // '请上传装车照片'
        return;
      }
      const loadForm = this.tablePage.map(arr => {
        const bagNumber = arr.bagNumber;
        const scanTime = arr.scanTime;
        const scanUserId = this.$store.state.user.id;
        const vehicleId = this.bagForm.vehicleId;
        const vehicleTag = this.carTagMsg;
        const type = arr.type;
        return {
          bagNumber,
          scanTime,
          scanUserId,
          vehicleId,
          vehicleTag,
          type,
          nextSiteId: arr.preDeliverStationId,
          nextSiteName: arr.preDeliverStationName
        };
      });
      const imgList = [];
      this.fileList1.forEach(arr => {
        imgList.push(arr.url);
      });

      const submit = {
        vehicleNumber: this.vehicleNumber,
        imgList: imgList,
        operationMode: this.operationMode,
        vehicleType: this.vehicleType,
        vehicleLoadedParams: loadForm,
        loadSignImg: this.signImg
      };
      loadedVehicle(submit).then(res => {
        if (res.code === 200) {
          this.nextSite = '';
          this.signatureFilling = false;
          this.signVisible = false;
          this.$refs.changeImg.$data.fileList = [];
          this.fileList1 = [];
          this.$message.success(this.$t('collectionCenter.loadingSuc')); // 装车成功
          this.tablePage = [];
          this.active = 0;
          this.carMsg = '';
          this.bagMsg = '';
          this.carTagMsg = '';
          this.carTagDialogVisible = false;
          this.vehicleNumber = '';
          this.loadingScanList['ls' + this.$store.state.user.id] = {
            nextSite: '',
            bagForm: {},
            tablePage: [],
            active: this.active,
            fileList1: [],
            operationMode: '',
            vehicleType: '',
            vehicleNumber: ''
          };
          // 加密
          const a = JSON.stringify(this.loadingScanList);
          localStorage.setItem('lsList', a);

          // 车辆对应的袋号另外缓存
          this.carBagCache[this.bagForm.vehicleId] = {
            vehicleId: '',
            tablePage: []
          };
          const carBag = JSON.stringify(this.carBagCache);
          localStorage.setItem('carBag', carBag);
          // 清空
          this.bagForm = {};
        }
      }).catch({});
    }
  }
};
</script>

<style lang="scss" scoped>
.remarkShow{
  width: 80%;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  border: 1px solid #d4d7df;
  border-radius: 5px;
  padding: 3px;
  box-sizing: border-box;
}
.isolateClass{
  background-color: #d6d6d6;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: bold;
}
.two{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.two .dd ::v-deep .el-radio{
  margin-right: 10px;
}
.two .dd ::v-deep .el-radio .el-radio__label{
  padding-left: 2px;
}
.dd.textarea ::v-deep .el-textarea{
  width: 70%;
}
.two .dd .req{
  position: relative;
  &::before{
    content: "*";
    color: red;
    position: absolute;
    top: 0;
    left: -7px;
  }
}

.dd.isDriver{
  position: relative;
  .redTip{
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    color: red;
    font-size: 15px;
    font-weight: normal;
  }
}
.two .dd{
  width: 50%;
  padding: 10px 0;
  padding-left: 3px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.two div.dd:nth-child(1) {
  border-right: 2px solid #000;
  height: 100%;
  position: absolute;
}
.two div.dd:nth-child(2) {
  margin-left: 50%;
}
.descriptionsClass ::v-deep tbody td{
  padding: 0 !important;
}
.descriptionsClass ::v-deep td:nth-child(1) th{
  width: 0;
  background: #222;
}
.descriptionsClass ::v-deep th{
  padding: 0 !important;
  border: none !important;
  box-sizing: border-box;
}
.descriptionsClass ::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell{
  background: #fff;
  font-size: 17px;
  font-weight: 400;
  color: #000;
  text-align: center;
}
.descriptionsClass {
  position: relative;
  width: 554px;
  margin: 50px auto;
  .statementClass{
    position: absolute;
    bottom: 27px;
    left: 4px;
    color: red;
    font-size: 14px;
    //pointer-events: none; //  该元素会不触发事件,而且事件可以穿透该元素
  }
  .cameraClass{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
  }
  .requiredClass{
    position: relative;
    &::before{
      position: absolute;
      content: '*';
      color: #F56C6C;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .dialogClass{
    padding: 20px;
    border: 1px solid #b1b3b7;
    border-radius: 10px;
    box-sizing: border-box;
    span{
      font-size: 16px;
      display: block;
      margin: 10px 0;
    }
  }
  .tableClass{
    .titleTop{
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  .signBtn{
    position: absolute;
    right: 20px;
    bottom: 5px;
    .btn{
      color: #000;
      font-size: 17px;
    }
  }
}
.top1{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
  font-weight: bold;
  padding: 3px;
  box-sizing: border-box;
}
.signMask{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
  .signVisibleClass{
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    height:100%;
    background:#fff;
    padding:20px 0;
    // overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }
  .signClose{
    position: absolute;
    top: 25px;
    right: 40px;
    transform: scale(1.3);
  }
}
.cameraClass{
  margin-top: 20px;
  display: flex;
  justify-content: start;
}
.requiredClass{
  position: relative;
  &::before{
    position: absolute;
    content: '*';
    color: #F56C6C;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.dialogClass{
  padding: 20px;
  border: 1px solid #b1b3b7;
  border-radius: 10px;
  box-sizing: border-box;
  span{
    font-size: 16px;
    display: block;
    margin: 10px 0;
  }
}
.tableClass{
  .titleTop{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 17px;
    .totalClass{
      font-weight: bold;
      color: #da0014;
      font-size: 30px;
    }
  }
}
.scanClass{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}
.scanClass ::v-deep .el-input{
  width: 30%;
}
.scanClass ::v-deep .el-select .el-input{
  width: 100%;
}
.scanClass ::v-deep .el-input .el-input__inner{
  height: 45px;
}
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
