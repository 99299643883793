<template>
  <div class="Box">
    <el-form style="margin-bottom: 20px;" class="selectClass">
      <el-row>
        <el-col :span="10" style="width: auto;">
          <!-- 订单创建时间： -->
          <el-form-item :label="$t('collectionCenter.orderCreateTime')">
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :range-separator="$t('collectionCenter.to')"
              :start-placeholder="$t('collectionCenter.StartTime')"
              :end-placeholder="$t('collectionCenter.EndTime')"
              @change="dateChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7" style="width: auto;">
          <!-- 客户名称： -->
          <el-form-item :label="$t('collectionCenter.CustomerN')">
            <el-select
              v-model="queryForm.params.customerId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
              filterable @focus="getNewestCustomer" @change="changeCustomer"
            >
              <el-option
                v-for="(item, index) in customerIdList"
                :key="index"
                :label="item.username"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" class="col10" style="width: auto;">
          <!-- 商户号/商户名称： -->
          <el-form-item class="m">
            <el-select
              v-model="merchantChoose" :placeholder="$t('collectionCenter.pleaseChoose')" class="autoWidth"
              @change="changeMerchant"
            >
              <template slot="prefix">
                {{ (merchantCodeOrName.find(item => item.m === merchantChoose) || {}).name }}
              </template>
              <el-option
                v-for="(item, index) in merchantCodeOrName"
                :key="index"
                :label="item.name"
                :value="item.m"
              />
            </el-select>
            <el-input
              v-model="merchantCodeValue"
              type="textarea"
              @change="changeValue"
            />
            <!--            <el-select-->
            <!--              v-model="merchantCodeValue"-->
            <!--              filterable-->
            <!--              clearable-->
            <!--              remote-->
            <!--              reserve-keyword-->
            <!--              :placeholder="$t('collectionCenter.pleaseInput')"-->
            <!--              :remote-method="remoteMethod"-->
            <!--              :loading="loading"-->
            <!--              @change="changeValue"-->
            <!--            >-->
            <!--              <el-option-->
            <!--                v-for="(item, index) in merchantCodeList"-->
            <!--                :key="index"-->
            <!--                :label="item.name"-->
            <!--                :value="item.name"-->
            <!--              />-->
            <!--            </el-select>-->
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row>
      <el-col :span="24">
        <el-button
          v-permit:remove="'btn:collection-center:order:collector-inquire:find'"
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="searchClick"
        >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
        <el-button
          v-permit:remove="'btn:collection-center:order:collector-inquire:reset'"
          icon="el-icon-refresh"
          size="mini"
          @click="resetClick"
        >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
        <el-button
          v-permit:remove="'btn:collection-center:order:collector-inquire:allExport'"
          icon="el-icon-upload"
          size="mini"
          :class="isClickAll ? 'onlyClickOnce10' : ''"
          @click="downloadAll"
        >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
      </el-col>
    </el-row>
    <el-divider />

    <el-table
      ref="tableRef"
      :max-height="600"
      border
      :data="tableDate"
    >
      <el-table-column
        type="selection"
        width="55"
      />
      <!-- 序号 -->
      <el-table-column
        :label="$t('collectionCenter.ind')" type="index" width="60"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="customerId"
        :label="$t('collectionCenter.clientID')"
      /><!-- 客户编号 -->
      <el-table-column
        prop="customerName"
        :label="$t('collectionCenter.CustomerName')"
      /><!-- 客户名称 -->
      <el-table-column
        prop="merchantCode"
        :label="$t('collectionCenter.MerchantNum')"
      /><!-- 商户号 -->
      <el-table-column
        prop="merchantName"
        :label="$t('collectionCenter.MerchantName')"
      /><!-- 商户名称 -->
      <el-table-column
        prop="collectTypeName"
        :label="$t('collectionCenter.receivingMode')"
      /><!-- 揽收模式 -->
      <!--  1 上门交件    2 上门揽收 -->
      <el-table-column
        prop="orderCount"
        :label="$t('collectionCenter.toBeCollectedNumber')"
      /><!-- 待揽收包裹数 -->
      <el-table-column
        prop="senderName"
        :label="$t('collectionCenter.senderName')"
      /><!-- 发件人 -->
      <el-table-column
        width="120"
        prop="senderPhone"
        :label="$t('collectionCenter.senderMobile')"
      ><!-- 发件人联系电话 -->
        <template slot-scope="scope">
          <span v-if="senderPhoneIndex == ((queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1) && findSenderPhone">{{ scope.row.senderPhone }}</span>
          <span v-else>{{ scope.row.senderEncryptPhone }}</span>
          <div class="el-icon-view isShowSenderPhone" @click="findSPhone((queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1)" />
        </template>
      </el-table-column>
      <el-table-column
        prop="senderTotalAddress"
        :label="$t('collectionCenter.SenderAddress')"
        :show-overflow-tooltip="false"
      ><!-- 发件人地址 -->
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <pre slot="content" style="max-height: 200px;overflow: auto;width:500px;">{{ scope.row.senderTotalAddress }}</pre>
            <div class="paramsStyle">{{ scope.row.senderTotalAddress }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="senderZipcode"
        :label="$t('collectionCenter.senderZipCode')"
      /><!-- 发件人邮编 -->
      <el-table-column
        prop="merchantLevel"
        :label="$t('collectionCenter.MerchantClass')"
      /><!-- 商家等级 -->
      <el-table-column
        prop="receiveName"
        :label="$t('collectionCenter.recipientS')"
      /><!-- 收件人 -->
      <el-table-column
        prop="receiveTotalAddress"
        :label="$t('collectionCenter.receiptArs')"
        :show-overflow-tooltip="false"
      ><!-- 收件地址 -->
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <pre slot="content" style="max-height: 200px;overflow: auto;width:500px;">{{ scope.row.receiveTotalAddress }}</pre>
            <div class="paramsStyle">{{ scope.row.receiveTotalAddress }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        width="120"
        prop="receivePhone"
        :label="$t('collectionCenter.addresseeContactNumber')"
      ><!-- 收件人联系电话 -->
        <template slot-scope="scope">
          <span v-if="receivePhoneIndex == ((queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1) && findReceivePhone">{{ scope.row.receivePhone }}</span>
          <span v-else>{{ scope.row.receiveEncryptPhone }}</span>
          <div class="el-icon-view isShowSenderPhone" @click="findRPhone((queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1)" />
        </template>
      </el-table-column>
      <el-table-column
        prop="receiveZipcode"
        :label="$t('collectionCenter.Recipientzc')"
      /><!-- 收件人邮编 -->
    </el-table>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getPage"
        @size-change="getPageSize"
      />
    </div>
  </div>
</template>

<script>
import { queryAllCustomer, merchantPage, merchantClassExportAll } from '@/api/lanshou';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';
import { commonDesensitize } from '@/utils/desensitization';

export default {
  data() {
    return {
      isClickAll: false,
      senderPhoneIndex: -1,
      findSenderPhone: false,
      receivePhoneIndex: -1,
      findReceivePhone: false,
      timeValue: [],
      customerIdList: [],
      merchantChoose: 'm2', // 选择输入商户号还是商户名称
      merchantCodeOrName: [
        { m: 'm1', name: this.$t('collectionCenter.MerchantNum') }, // '商户号'
        { m: 'm2', name: this.$t('collectionCenter.MerchantName') } // '商户名称'
      ],
      merchantCodeValue: '',
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          customerId: '',
          merchantName: '',
          merchantCode: '',
          createStartTime: '',
          createEndTime: ''
        }
      },
      total: 0,
      tableDate: []
    };
  },
  created() {
    this.getNewestCustomer();
    this.default();
    this.searchClick();
    this.isClickAll = false;
  },
  methods: {
    findSPhone(index) {
      const hasPermission = this.$store.getters.button_permissions.includes('field:customer-sensitive-info');
      if (!hasPermission) {
        this.$message.error(this.$t('GlobalSetObj.sensitiveInfoPermission'));
        return;
      }
      if (this.senderPhoneIndex !== index) {
        this.findSenderPhone = true;
      } else {
        this.findSenderPhone = !this.findSenderPhone;
      }
      this.senderPhoneIndex = index;
    },
    findRPhone(index) {
      const hasPermission = this.$store.getters.button_permissions.includes('field:customer-sensitive-info');
      if (!hasPermission) {
        this.$message.error(this.$t('GlobalSetObj.sensitiveInfoPermission'));
        return;
      }
      if (this.receivePhoneIndex !== index) {
        this.findReceivePhone = true;
      } else {
        this.findReceivePhone = !this.findReceivePhone;
      }
      this.receivePhoneIndex = index;
    },
    getNewestCustomer() {
      queryAllCustomer().then(res => {
        if (res.code===200) {
          this.customerIdList = [];
          res.data.forEach((list) => {
            this.customerIdList.push({
              id: list.id,
              username: list.username
            });
          });
        }
      });
    },
    changeCustomer() { // 切换客户名称自动清空商户号/名称
      this.merchantCodeValue = '';
      this.queryForm.params.merchantCode = '';
      this.queryForm.params.merchantName = '';
    },
    changeMerchant() { // 切换商户号/名称 清空输入框
      this.merchantCodeValue = '';
      this.queryForm.params.merchantCode = '';
      this.queryForm.params.merchantName = '';
    },
    // remoteMethod(value) {
    //   const merchantCodeList = [];
    //   if (this.queryForm.params.customerId) {
    //     if (value !== '') {
    //       this.loading = true;
    //       let merchantForm = {};
    //       if (this.merchantChoose === 'm1') {
    //         merchantForm = {
    //           customerId: this.queryForm.params.customerId,
    //           merchantCode: value,
    //           merchantName: ''
    //         };
    //       } else {
    //         merchantForm = {
    //           customerId: this.queryForm.params.customerId,
    //           merchantCode: '',
    //           merchantName: value
    //         };
    //       }
    //       setTimeout(() => {
    //         queryAllMerchantCode(merchantForm).then(res => {
    //           if (res.code===200) {
    //             res.data.records.forEach(list => {
    //               if (list.merchantName) {
    //                 merchantCodeList.push({
    //                   name: list.merchantCode + ' / ' + list.merchantName
    //                 });
    //               } else {
    //                 merchantCodeList.push({
    //                   name: list.merchantCode
    //                 });
    //               }
    //             });
    //           }
    //         });
    //         this.merchantCodeList = merchantCodeList;
    //         this.loading = false;
    //       }, 200);
    //     } else {
    //       this.merchantCodeList = [];
    //     }
    //   } else {
    //     this.merchantCodeValue = '';
    //     this.$message.warning(this.$t('collectionCenter.chooseCustomerName')); // '请选择客户名称'
    //   }
    // },
    changeValue(value) {
      // value = value.split(' / ');
      // this.queryForm.params.merchantCode = value[0];
      // this.queryForm.params.merchantName = value[1];
      if (!this.queryForm.params.customerId) {
        this.merchantCodeValue = '';
        this.$message.warning(this.$t('collectionCenter.chooseCustomerName')); // '请选择客户名称'
        return;
      }
      const val = value.split('\n');
      const filterVal = val.filter(function(f) {
        return f && f.trim();
      });
      if (val.length <= 1000) {
        this.merchantCodeValue = filterVal.join('\n');
        if (this.merchantChoose === 'm1') {
          this.queryForm.params.merchantCode = filterVal.join('\n');
        } else {
          this.queryForm.params.merchantName = filterVal.join('\n');
        }
      } else {
        const merchantCodeValue = filterVal.slice(0, 1000);
        this.merchantCodeValue = merchantCodeValue.join('\n');
        if (this.merchantChoose === 'm1') {
          this.queryForm.params.merchantCode = filterVal.slice(0, 1000).join('\n');
        } else {
          this.queryForm.params.merchantName = filterVal.slice(0, 1000).join('\n');
        }
        this.$message.warning(this.$t('collectionCenter.choose1000')); // '单次最多可选1000个，已截取前1000个商户'
      }
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.createStartTime = '';
        this.queryForm.params.createEndTime = '';
        return;
      }
      this.queryForm.params.createStartTime = val[0];
      this.queryForm.params.createEndTime = val[1];
    },
    default() {
      const d = new Date();
      const end = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD 23:59:59');
      const start = dayjs(d).subtract(3, 'day').format('YYYY-MM-DD 00:00:00');
      this.timeValue = [start, end];
      this.queryForm.params = {
        customerId: '',
        merchantName: '',
        merchantCode: '',
        createStartTime: start,
        createEndTime: end
      };
    },
    getPage() {
      const queryForm = JSON.parse(localStorage.getItem('ciQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      merchantPage(queryForm).then(res => {
        if (res.code===200) {
          this.total = res.data.total;
          this.tableDate = res.data.records;
          // 数据脱敏处理
          this.desensitization();
        }
      }).catch({});
    },
    // 数据脱敏处理
    desensitization() {
      // 根据权限控制敏感信息脱敏处理
      const hasPermission = this.$store.getters.button_permissions.includes('field:customer-sensitive-info');
      if (!hasPermission) {
        this.tableDate.map((item) => {
          item.senderName = commonDesensitize(item.senderName);
          item.senderTotalAddress = commonDesensitize(item.senderTotalAddress);
          item.receiveName = commonDesensitize(item.receiveName);
          item.receiveTotalAddress = commonDesensitize(item.receiveTotalAddress);
        });
      }
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const queryForm = JSON.parse(localStorage.getItem('ciQueryForm')) || this.queryForm;
      queryForm.pageNumber = 1;
      queryForm.pageSize = this.queryForm.pageSize;
      merchantPage(queryForm).then(res => {
        if (res.code===200) {
          this.total = res.data.total;
          this.tableDate = res.data.records;
          // 数据脱敏处理
          this.desensitization();
        }
      }).catch({});
    },
    searchClick() {
      this.queryForm.pageNumber = 1;
      localStorage.setItem('ciQueryForm', JSON.stringify(this.queryForm));
      this.getPage();
    },
    resetClick() {
      this.merchantCodeValue = '';
      this.default();
    },
    downloadAll() {
      this.isClickAll = true;
      const queryForm = JSON.parse(localStorage.getItem('ciQueryForm')) || this.queryForm;
      merchantClassExportAll(queryForm).then(res => {
        if (res.code===200) {
          this.isClickAll = false;
          goRWExportList(this);
        }
      }).catch(() => {
        this.isClickAll = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce2{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce2:active{
    filter: opacity(40%);
    animation: none;
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }
  .isShowSenderPhone{
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  .paramsStyle{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
    word-wrap: break-word;
    word-break: break-all;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .selectClass ::v-deep .el-select{
    width: 100%;
  }
  .autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
    position: relative;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    visibility: hidden;
    white-space: nowrap;
  }
  .autoWidth ::v-deep input {
    position: absolute;
  }

  .m ::v-deep .el-form-item__content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px !important;
  }
  .m ::v-deep .el-form-item__content .autoWidth{
    width: auto !important;
  }
  .m ::v-deep .el-textarea__inner{
    min-height: 40px !important;
    max-height: 200px !important;
  }
  .autoWidth ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
    padding-left: 0;
    box-sizing: border-box;
  }

  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
</style>
