var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary", icon: "el-icon-plus" },
          on: { click: _vm.addRow },
        },
        [_vm._v(_vm._s(_vm.$t("GlobalSetObj.add")))]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary", icon: "el-icon-plus" },
          on: { click: _vm.submitForm },
        },
        [_vm._v(_vm._s(_vm.$t("operationCenter.verify")))]
      ),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.formData, "label-width": "0", size: "small" },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("GlobalSetObj.client") + "：",
                    "label-width": "85px",
                    prop: "customerIdList",
                    rules: {
                      required: true,
                      message: _vm.$t("GlobalSetObj.pleaseChoose"),
                      trigger: ["blur", "change"],
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select",
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                        filterable: "",
                        multiple: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.formData.customerIdList,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "customerIdList", $$v)
                        },
                        expression: "formData.customerIdList",
                      },
                    },
                    _vm._l(_vm.customerList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label:
                            item.customerAlias + "（" + item.username + "）",
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.formData.arr, border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "collectTime",
                  label: _vm.$t("routes.对应揽收时间") + "*",
                  "min-width": "300",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { display: "inline-block" },
                            attrs: {
                              prop: "arr." + scope.$index + ".startCollect",
                              rules: {
                                required: true,
                                validator: function (rule, value, callback) {
                                  return _vm.collectTimeCheck(
                                    rule,
                                    value,
                                    callback,
                                    scope.row,
                                    _vm.formData.arr,
                                    1
                                  )
                                },
                              },
                            },
                          },
                          [
                            _c("el-time-picker", {
                              staticStyle: { width: "130px" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                format: "HH:mm",
                                "value-format": "HH:mm",
                              },
                              model: {
                                value: scope.row.startCollect,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "startCollect", $$v)
                                },
                                expression: "scope.row.startCollect",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticStyle: { margin: "0 5px" } }, [
                          _vm._v("~"),
                        ]),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { display: "inline-block" },
                            attrs: {
                              prop: "arr." + scope.$index + ".endCollect",
                              rules: {
                                required: true,
                                validator: function (rule, value, callback) {
                                  return _vm.collectTimeCheck(
                                    rule,
                                    value,
                                    callback,
                                    scope.row,
                                    _vm.formData.arr,
                                    2
                                  )
                                },
                              },
                            },
                          },
                          [
                            _c("el-time-picker", {
                              staticStyle: { width: "130px" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                format: "HH:mm",
                                "value-format": "HH:mm",
                              },
                              model: {
                                value: scope.row.endCollect,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "endCollect", $$v)
                                },
                                expression: "scope.row.endCollect",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "collectLoadFinishTime",
                  label: _vm.$t("routes.对应揽收装车完成时间") + "*",
                  "min-width": "280",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "arr." +
                                scope.$index +
                                ".collectLoadFinishTime",
                              rules: {
                                required: true,
                                validator: function (rule, value, callback) {
                                  return _vm.collectLoadFinishTimeCheck(
                                    rule,
                                    value,
                                    callback,
                                    scope.row
                                  )
                                },
                              },
                            },
                          },
                          [
                            _c("el-time-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                format: "HH:mm",
                                "value-format": "HH:mm",
                              },
                              model: {
                                value: scope.row.collectLoadFinishTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "collectLoadFinishTime",
                                    $$v
                                  )
                                },
                                expression: "scope.row.collectLoadFinishTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "hub1ArrivalTime",
                  label: _vm.$t("routes.对应HUB1到件时间") + "*",
                  "min-width": "280",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "arr." + scope.$index + ".hub1ArrivalTime",
                              rules: {
                                required: true,
                                validator: function (rule, value, callback) {
                                  return _vm.hub1ArrivalTimeCheck(
                                    rule,
                                    value,
                                    callback,
                                    scope.row
                                  )
                                },
                              },
                            },
                          },
                          [
                            _c("el-time-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                format: "HH:mm",
                                "value-format": "HH:mm",
                              },
                              model: {
                                value: scope.row.hub1ArrivalTime,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "hub1ArrivalTime", $$v)
                                },
                                expression: "scope.row.hub1ArrivalTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.delRow(scope.$index)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Delete")))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }