<template>
  <div class="app-container">
    <el-card class="mb5">
      <el-form class="searchContainer" label-position="top">
        <!--客户-->
        <el-form-item class="numberClassSelect" :label="$t('GlobalSetObj.client')">
          <CustomerSelectCmp v-model="query.params.customerId" :is-all="true" style="width: 25%" />
        </el-form-item>
      </el-form>

      <el-button
        type="primary"
        size="small"
        icon="el-icon-search"
        @click="onQuery"
      >{{ $t('operationCenter.Procurar') }}</el-button>
      <el-button size="small" icon="el-icon-refresh-left" @click="onReset">
        {{ $t('operationCenter.reset') }}
      </el-button>

    </el-card>

    <el-card>
      <!-- 添加 -->
      <el-button
        class="mb10"
        size="small" type="primary" icon="el-icon-plus"
        @click="onAdd"
      >
        {{ $t('GlobalSetObj.append') }}
      </el-button>
      <el-table
        :data="tableData"
        border
        style="width: 100%; min-height: 300px;"
      >
        <!--序号-->
        <el-table-column :label="$t('operationCenter.ind')" width="60px" align="center">
          <template slot-scope="scope">
            <span>{{ (query.pageNumber - 1) * query.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <!-- 客户 -->
        <el-table-column :label="$t('GlobalSetObj.client')" prop="customerName" />
        <!-- 理赔节点 -->
        <el-table-column :label="$t('ClaimsManagement.ClaimsNode')" prop="claimsNodeStr" />
        <!-- 申报价值阈值(<=) -->
        <el-table-column :label="`${$t('ClaimsManagement.DeclarationValueThreshold')}(<=)`" prop="declaredValue" />
        <!-- 默认审核人 -->
        <el-table-column :label="$t('ClaimsManagement.DefaultAuditor')" prop="defaultReviewerName" />
        <!-- 最后更新人 -->
        <el-table-column :label="$t('ClaimsManagement.LastUpdater')" prop="updateUserName" />
        <!-- 最后更新时间 -->
        <el-table-column :label="$t('ClaimsManagement.LastUpdateTime')" prop="updateTime" />
        <!-- 操作 -->
        <el-table-column :label="$t('GlobalSetObj.operate')">
          <template slot-scope="scope">
            <!-- 编辑 -->
            <el-button size="small" type="primary" @click="onEdit(scope.row)">{{ $t('GlobalSetObj.edit') }}</el-button>
            <el-popconfirm
              v-permit:remove="'btn:finance:basicData:payable:feesType-editAdd'"
              :title="$t('basicData.AreYouSureYouWantToDelete')"
              @confirm="onDelete(scope.row.id)"
            >
              <!--删除-->
              <el-button
                slot="reference" class="ml" type="danger"
                size="mini" style="margin-left: 10px;"
              >{{ $t('basicData.Delete') }}</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="query.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="query.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getCurrentTableData"
          @size-change="getSizeTableData"
        />
      </div>
    </el-card>

    <!-- 添加编辑 -->
    <el-dialog
      :title="$t('GlobalSetObj.tips')"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <FormCmp
        v-if="dialogVisible" :ids="editId" :row="form"
        @close="onClose"
      />
    </el-dialog>
  </div>
</template>

<script>
import CustomerSelectCmp from '@/components/CustomerSelectCmp/index.vue';
import { apiOperationBigBagInfoPage, apiClaimsReviewConfigurationDelete } from '@/api/claimsManagement/rules';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { addTmp } from './utils';
import { CLAIMSNODE_TYPE_OBJ } from '@/views/claimsManagement/claimList/utils.js';

export default {
  name: '',

  components: {
    CustomerSelectCmp,
    FormCmp: () => import('./cmp/FormCmp.vue')
  },
  data() {
    return {
      query: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          customerId: ''
        }
      },
      total: 0,

      tableData: [],

      dialogVisible: false,

      editId: 0, // 编辑的时候传入的id
      form: {
        ...addTmp
        // customerId: '', // 客户id
        // claimsNode: '', // 理赔节点1:转运 2:揽收 3:派送
        // declaredValue: '', // 申报价值阈值（<=）
        // defaultReviewer: '', // 默认审核人id
        // defaultReviewerName: ''
      }
    };
  },

  created() {
    this.onQuery();
    this.userObj = getUserIdAndNameObj();
  },

  methods: {
    // 页码变化翻页
    getCurrentTableData(val) {
      this.query.pageNumber = val;
      this.getTableData();
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.query.pageNumber = 1;
      this.query.pageSize = val;
      this.getTableData();
    },

    // 搜索
    onQuery() {
      this.query.pageNumber = 1;
      this.query.pageSize = 10;
      this.getTableData();
    },

    // 重置
    onReset() {
      this.query.pageNumber = 1;
      this.query.pageSize = 10;
      this.query.params.customerId = '';
      this.getTableData();
    },

    // 获取列表数据
    getTableData() {
      apiOperationBigBagInfoPage(this.query).then(res => {
        const { records, total } = res.data;

        this.total = total;

        this.tableData = (records || []).map(item => {
          const { id, customerId, customerName, defaultReviewer, declaredValue, claimsNode, updateUser, updateTime } = item;

          let claimsNodeStr = '';
          if (claimsNode) {
            claimsNodeStr = CLAIMSNODE_TYPE_OBJ[claimsNode].name;
          }

          let defaultReviewerName = '';
          if (defaultReviewer) {
            defaultReviewerName = this.userObj[defaultReviewer];
          }

          const obj = {
            id,
            customerId, // 客户id
            customerName, // 客户名称
            claimsNode, // 理赔节点
            claimsNodeStr, // 理赔节点 中文字段 1:转运 2:揽收 3:派送
            declaredValue, // 申报价值阈值
            defaultReviewer, // 默认审核人id
            defaultReviewerName,
            updateUser,
            updateUserName: this.userObj[updateUser],
            updateTime
          };

          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      });
    },

    // 添加
    onAdd() {
      this.dialogVisible = true;
      // this.form
      this.editId = 0;
      // 数据重置
      Object.assign(this.form, { ...addTmp });
    },

    // 编辑
    onEdit(row) {
      const { id, customerId, claimsNode, declaredValue, defaultReviewer, defaultReviewerName } = row;

      this.editId = id;

      // let defaultReviewerNum = '';
      // if (defaultReviewer) {
      //   defaultReviewerNum = Number(defaultReviewer);
      // }

      Object.assign(this.form, {
        customerId, claimsNode, declaredValue, defaultReviewer, defaultReviewerName
      });
      this.dialogVisible = true;
    },

    // 删除
    onDelete(id) {
      apiClaimsReviewConfigurationDelete(id).then(res => {
        if (res.code === 200) {
          // 删除成功
          this.$message.success(this.$t('GlobalSetObj.DeleteSuccessful'));
          this.getTableData();
        }
      }).catch(err => {
        console.error(err);
      });
    },

    // 关闭
    onClose(isBool) {
      this.dialogVisible = false;
      isBool && this.onQuery();
    }
  }

};
</script>

<style lang="scss" scoped>

</style>
