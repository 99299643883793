<template>
  <div class="app-container">
    <div class="searchContainer">
      <div>
        <el-button
          v-permit:remove="'btn:receivableManagementSite:toExamine:export'" size="small" type="primary"
          @click="operationBtn(5)"
        >
          {{ $t('receivablePayable.Export') }} <!--导出-->
        </el-button>
        <!--自定义栏目-->
        <el-button
          size="small"
          style="margin-left: 10px;"
          @click="customColumn()"
        >{{ $t('orderCenterCont.CustomizeListColumn') }}
        </el-button>
        <div style="float: right">
          <!--查询-->
          <el-button
            icon="el-icon-search" size="small" type="primary"
            @click="searchResources()"
          >{{ $t('receivablePayable.Search') }}
          </el-button>
        </div>
        <div style="clear:both;" />
      </div>
      <el-divider />
    </div>
    <div>
      <!--<div class="alert">
        <i class="el-icon-info icon"></i>
        <div style="display: contents;flex: 1">
          <div style="padding: 0 10px;">
            <div>
              <span class="title">总费用（RMB）： </span>
              <span class="num">
                {{ billBatchData.receivablesRmb }}
                <span v-if="JSON.stringify(totalFee) !== '{}'">
                  （<span v-for="(item, key, i) of totalFee" :key="i">{{ item }}：{{ key }}；</span>）
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>-->
      <el-table
        ref="elTable"
        :data="tableData"
        :height="tableHeight"
        border
        @header-dragend="headerWidthChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
        />
        <el-table-column :label="$t('operationCenter.ind')" align="center" width="60px">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in currentColumns"
          :key="index"
          :align="item.fixed"
          :label="item.label"
          :prop="item.prop"
          :show-overflow-tooltip="true"
          :width="item.width"
        >
          <template slot-scope="scope">
            <div
              v-if="item.prop === 'businessType'"
            >{{
              scope.row[item.prop] === 0
                ? $t('basicData.CourierSystemBusiness')
                : scope.row[item.prop] === 1 ? $t('basicData.CollectionSystemBusiness') : ''
            }}
            </div>
            <div
              v-else-if="item.prop === 'collectType'"
            >{{
              scope.row[item.prop] === 1
                ? $t('collectionCenter.dropOff')
                : scope.row[item.prop] === 2 ? $t('collectionCenter.doorPickUp') : ''
            }}
            </div>
            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, ind) in getCurrentColumns"
          :key="ind + 100"
          :align="item.fixed"
          :label="item.label"
          :prop="item.prop"
          :show-overflow-tooltip="true"
          :width="item.width"
        >
          <template slot-scope="scope">
            <div>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="getExamineTableData"
          @size-change="getExamineTableData"
        />
      </div>
    </div>
    <!--选择栏目-->
    <el-drawer
      :append-to-body="true"
      :title="$t('orderCenterCont.SelectColumn')"
      :visible.sync="showColumn"
      custom-class="drawerClass"
      direction="ltr"
      width="500px"
      @close="closeDrawer()"
    >
      <div class="columnTree" style="padding: 20px;height: calc(100vh - 140px);overflow: auto">
        <el-tree
          :key="dragging"
          ref="elTree"
          :allow-drag="allowDrag"
          :allow-drop="allowDrop"
          :data="columnList"
          :default-checked-keys="checkedColumns"
          :props="props"
          check-on-click-node
          default-expand-all
          draggable
          node-key="prop"
          show-checkbox
          @check="handleCheckChange"
          @node-drop="handleDrop"
        />
      </div>
      <div style="padding: 10px 20px;text-align: right;border-top: 1px solid #ccc;">
        <el-button type="primary" @click="determineColumns()">{{ $t('operationCenter.determine') }}</el-button>
      </div>
    </el-drawer>
    <!--  查询  -->
    <el-drawer
      :append-to-body="true"
      :title="$t('receivablePayable.Search')"
      :visible.sync="showSearch"
      custom-class="drawerClass"
      direction="rtl"
      size="50%"
    >
      <div>
        <search-form
          :key="showSearch"
          :is-read-only="true"
          :query-form="queryForm"
          :search-select-arr="searchSelectArr"
          @search="searchFun"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
// import searchForm from '@/views/finance/receivableManagementSite/aRGenerationNew/cmp/searchForm';
import searchForm from '@/views/finance/receivableManagementSite/aRGenerationNew/cmp/searchForm/index.vue';
import {
  apiCustomColumnTemplateInsert,
  apiCustomColumnTemplateList,
  apiCustomColumnTemplateUpdate,
  apiExportCustomerBills,
  apiSiteExpenseGetPage
} from '@/api/finance/receivables';
import { goResourceTaskList } from '@/utils/goExportList.js';

export default {
  name: 'BatchNumberDetail',
  components: {
    searchForm
    // examineManage
  },
  props: {
    batchNumber: {
      type: String,
      default: ''
    },
    billBatchData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      // 物流订单
      waybillStatus: [
        { label: this.$i18n.t('newOrder.all'), code: 0 }, // 待分配
        { label: this.$i18n.t('newOrder.ToBeAssigned'), code: 1 }, // 待分配
        { label: this.$i18n.t('newOrder.ToBeCollected'), code: 2 }, // 待揽收
        { label: this.$i18n.t('newOrder.Collected'), code: 3 }, // 已揽收
        { label: this.$i18n.t('newOrder.CollectTheRevenuePool'), code: 4 }, // 揽收入库
        { label: this.$i18n.t('newOrder.CollectTakeOutWarehouse'), code: 5 }, // 揽收出库
        { label: this.$i18n.t('GlobalSetObj.moduan'), code: 19 }, // 末端已揽收
        { label: this.$i18n.t('newOrder.TransshipmentIntoStorage'), code: 6 }, // 转运入库
        { label: this.$i18n.t('newOrder.TransferOutWarehouse'), code: 7 }, // 转运出库
        { label: this.$i18n.t('newOrder.DeliveryOutletsIntoStorage'), code: 8 }, // 派送网点入库
        { label: this.$i18n.t('newOrder.Delivering'), code: 9 }, // 派送中
        { label: this.$i18n.t('newOrder.Signed'), code: 10 }, // 已签收
        { label: this.$i18n.t('newOrder.ProblemPiece'), code: 11 }, // 问题件
        { label: this.$i18n.t('GlobalSetObj.Canceled'), code: 13 }, // 已取消
        // { label: this.$i18n.t('GlobalSetObj.deliveryFailure'), code: 14 }, // 投递失败
        { label: this.$i18n.t('GlobalSetObj.DotBack'), code: 17 }, // 网点退回
        { label: this.$i18n.t('GlobalSetObj.ReturnToStorage'), code: 18 }, // 退仓入库
        { label: this.$i18n.t('GlobalSetObj.Withdrawn'), code: 15 }, // 退件中
        { label: this.$i18n.t('GlobalSetObj.TheReturnComplete'), code: 16 } // 退件完成
      ],
      // 揽收订单
      orderTypeList: [
        { collectStatus: 0, collectStatusName: this.$t('collectionCenter.notLanShou') }, // '未揽收'
        { collectStatus: 1, collectStatusName: this.$t('collectionCenter.hasLanShou') }, // '已揽收'
        { collectStatus: 2, collectStatusName: this.$t('collectionCenter.cancelled') }, // 已取消
        { collectStatus: 4, collectStatusName: this.$t('collectionCenter.scheduled') }, // 已调度
        { collectStatus: 5, collectStatusName: this.$t('collectionCenter.AbnormalLanShou') }, // 揽收异常
        { collectStatus: 6, collectStatusName: this.$t('collectionCenter.haveBeenSigned') }, // 已签收
        { collectStatus: 7, collectStatusName: this.$t('collectionCenter.ProblemPiece') }, // 问题件
        { collectStatus: 8, collectStatusName: this.$t('collectionCenter.SealedBag') }, // 已封袋
        { collectStatus: 9, collectStatusName: this.$t('collectionCenter.truckLoading') }, // 装车
        { collectStatus: 10, collectStatusName: this.$t('collectionCenter.unloading') }, // 卸车
        { collectStatus: 11, collectStatusName: this.$t('collectionCenter.Bagged') }, // 已装袋
        { collectStatus: 12, collectStatusName: this.$t('collectionCenter.closedLoop') }, // 已闭环
        { collectStatus: 13, collectStatusName: this.$t('collectionCenter.unclaimed') }, // 待取件
        { collectStatus: 14, collectStatusName: this.$t('collectionCenter.collectionPointInventory') }// 揽收网点入库
      ],
      // 备货揽收订单
      bhOrderTypeList: [ // 订单状态
        { collectStatus: 0, collectStatusName: this.$t('collectionCenter.notLanShou') }, // '未揽收'
        { collectStatus: 1, collectStatusName: this.$t('collectionCenter.hasLanShou') }, // '已揽收'
        { collectStatus: 2, collectStatusName: this.$t('collectionCenter.haveBeenSigned') }, // 已签收
        { collectStatus: 3, collectStatusName: this.$t('collectionCenter.cancelled') }, // 已取消
        { collectStatus: 4, collectStatusName: this.$t('collectionCenter.truckLoading') }, // 装车
        { collectStatus: 5, collectStatusName: this.$t('collectionCenter.unloading') }, // 卸车
        { collectStatus: 6, collectStatusName: this.$t('operatingPlatform.collectWarehousing') } // 网点揽收入库
      ],
      queryForm: {
        orderDirection: 'DESC',
        orderProperty: '',
        pageNumber: 1,
        pageSize: 10,
        params: {
          providerId: '', // 应收主体id
          siteId: '', // 战点id
          startTime: '', // 业务开始时间
          endTime: '', // 业务结束时间
          timeField: 'operate_time', // 查询时间类型
          numberList: [], // 单号
          numberField: 'waybill_number', // waybill_number: 业务单号 customer_number: 客户单号
          billBatchNumber: '', // // 批次号
          feeName: '', // 费用类型名称
          isGenBill: null, // 是否生成对账单
          isPaid: null, // // 客户是否已支付
          isOut: null, // 是否已出库
          status: ''
        }
      },
      total: 0,
      tableData: [],
      currentColumns: [],
      getCurrentColumns: [],
      selectData: [],
      showSearch: false,
      tableHeight: 0,
      totalFee: 0,
      searchSelectArr: {
        providerItem: null,
        outletsItem: null,
        customerItem: null
      },
      showColumn: false,
      dragging: new Date().getTime(),
      props: {
        label: 'label',
        children: 'children'
      },
      checkedColumns: [],
      checkedSelectColumns: [],
      customColumnId: ''
    };
  },
  computed: {
    columnList() {
      return [
        {
          label: this.$i18n.t('basicData.all'), // '全部',
          prop: '',
          children: [
            // 网点
            {
              prop: 'siteName',
              label: this.$i18n.t('basicData.Outlets'),
              width: 120,
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 业务单号
            {
              prop: 'waybillNumber',
              label: this.$i18n.t('receivablePayable.TicketNumber'),
              width: 160,
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 客户单号
            {
              prop: 'customerNumber',
              label: this.$i18n.t('receivablePayable.CustomerTrackingNumber'),
              width: 160,
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 订单状态
            {
              prop: 'status',
              label: this.$i18n.t('collectionCenter.OrderStatus'),
              width: '',
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 客户
            {
              prop: 'customerAlias',
              label: this.$i18n.t('receivablePayable.customer'),
              width: 160,
              fixed: 'left',
              highlight: false,
              disabled: false
            },

            // 所属分区
            {
              prop: 'partitionName',
              label: this.$i18n.t('basicData.PartitionName'),
              width: 160,
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 收件人城市
            {
              prop: 'receiveCity',
              label: this.$i18n.t('collectionCenter.receiveCity'),
              width: 160,
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 收件人邮编
            {
              prop: 'receiveZipcode',
              label: this.$i18n.t('collectionCenter.Recipientzc'),
              width: 160,
              fixed: 'left',
              highlight: false,
              disabled: false
            },

            // 收件人州
            {
              prop: 'receiveState',
              label: this.$i18n.t('newOrder.RecipientState'),
              width: '',
              fixed: 'left',
              highlight: false,
              disabled: false
            },

            // 发件人州
            {
              prop: 'senderState',
              label: this.$i18n.t('newOrder.SenderState'),
              width: '',
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 计费重量
            {
              prop: 'chargeWeight',
              label: this.$i18n.t('receivablePayable.BillableWeight'),
              width: 120,
              fixed: 'left',
              highlight: true,
              disabled: false
            },
            // 应收费用
            {
              prop: 'totalFee',
              label: this.$i18n.t('basicData.FeesApply'),
              width: 120,
              fixed: 'left',
              highlight: true,
              disabled: false
            },
            // 业务类型
            {
              prop: 'businessType',
              label: this.$i18n.t('collectionCenter.businessT'),
              width: 110,
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 揽收方式
            {
              prop: 'collectType',
              label: this.$i18n.t('collectionCenter.lanshouTypee'),
              width: 110,
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 备注
            {
              prop: 'remark',
              label: this.$i18n.t('basicData.remark'),
              width: 160,
              fixed: 'left',
              highlight: false,
              disabled: false
            }
          ]
        }
      ];
    }
  },
  created() {
    this.createUser = getUserIdAndNameObj();
    this.getHeaderColumn();
    this.tableHeight = document.body.clientHeight - 280;
    this.tableKey = new Date().getTime();
    this.queryForm.params.billBatchNumber = this.batchNumber;
    this.getExamineTableData();
  },
  mounted() {
    // 页面加载的时候设置table的高度
    this.tableHeight = document.body.clientHeight - 280;
    // 页面大小该变的时候（缩放页面）设置table的高度（可加可不加）
    window.onresize = () => {
      this.tableHeight = document.body.clientHeight - 280;
    };
  },
  methods: {
    // 找出两个数组中相同的元素
    findCommonElements(arr1, arr2) {
      return arr1.filter((element) => arr2.includes(element));
    },
    // 找出两个数组中不相同的元素
    findDifferentElements(arr1, arr2) {
      // 找出arr1中不存在于arr2的元素
      const uniqueToArr1 = arr1.filter(element => !arr2.includes(element));
      // 找出arr2中不存在于arr1的元素
      const uniqueToArr2 = arr2.filter(element => !arr1.includes(element));
      // 将两个数组中的不同元素合并
      return [...uniqueToArr1, ...uniqueToArr2];
    },
    // 获取当前用户自定义列表表头
    getHeaderColumn() {
      const param = {
        type: 0,
        menuEnum: 'companyToSiteReviewDetails'
      };
      apiCustomColumnTemplateList(param).then(res => {
        if (res.code === 200) {
          this.customColumnId = '';
          let selectedColumn = [];
          if (res.data.length > 0) {
            this.customColumnId = res.data[0].id;
            selectedColumn = JSON.parse(res.data[0].templateField);
          }
          this.currentColumns = [];
          let allColumn = [];
          const selectedProp = [];
          const allProp = [];
          selectedColumn.map(list => {
            if (selectedProp.includes(list.prop) === false) {
              selectedProp.push(list.prop);
            }
          });
          this.columnList[0].children.map(item => {
            if (allProp.includes(item.prop) === false) {
              allProp.push(item.prop);
            }
          });
          const commonElements = this.findCommonElements(selectedProp, allProp);
          if (commonElements.length > 0) {
            commonElements.map(list => {
              this.columnList[0].children.map(item => {
                if (list === item.prop) {
                  this.currentColumns.push(item);
                }
              });
            });
          } else {
            this.columnList[0].children.map(item => {
              if (selectedProp.includes(item.prop) === false) {
                selectedProp.push(item.prop);
              }
              this.currentColumns.push(item);
              // if (item.disabled) {
              //   if (selectedProp.includes(item.prop) === false) {
              //     selectedProp.push(item.prop);
              //   }
              //   this.currentColumns.push(item);
              // }
            });
          }
          allColumn = cloneDeep(this.currentColumns);
          const differentElements = this.findDifferentElements(allProp, selectedProp);
          this.columnList[0].children.map(item => {
            if (differentElements.includes(item.prop)) {
              allColumn.push(item);
            }
          });
          // 页面加载的时候设置table的高度
          this.$nextTick(() => {
            this.columnList[0].children = cloneDeep(allColumn);
            this.tableKey = new Date().getTime();
          });
        }
      });
    },
    // 打开自定义栏目弹窗
    customColumn() {
      const selectedColumn = cloneDeep(this.currentColumns);
      this.checkedColumns = [];
      this.checkedSelectColumns = cloneDeep(this.columnList[0].children);
      this.checkedSelectColumns.map(item => {
        if (selectedColumn.length > 0) {
          selectedColumn.map(list => {
            if (item.prop === list.prop) {
              this.checkedColumns.push(item.prop);
            }
          });
        } else {
          this.checkedColumns.push(item.prop);
          // if (item.disabled === true) {
          //   this.checkedColumns.push(item.prop);
          // }
        }
      });
      this.showColumn = true;
      this.dragging = new Date().getTime();
    },
    // 确定保存已选栏目
    determineColumns() {
      const columns = [];
      const neKey = this.$refs.elTree.getCheckedKeys();
      if (neKey.length > 0) {
        this.columnList[0].children.map(list => {
          if (neKey.includes(list.prop)) {
            columns.push(list);
          }
        });
      }
      const params = {
        templateField: JSON.stringify(columns),
        templateName: '公司对网点审核详情',
        type: 0,
        menuEnum: 'companyToSiteReviewDetails',
        userId: this.$store.state.user.id
      };
      if (this.customColumnId) {
        params.id = this.customColumnId;
        apiCustomColumnTemplateUpdate(params).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$t('basicData.TheOperationSucceeded'));
            this.getHeaderColumn();
          }
        });
      } else {
        apiCustomColumnTemplateInsert(params).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$t('basicData.TheOperationSucceeded'));
            this.getHeaderColumn();
          }
        });
      }
      this.showColumn = false;
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
        this.tableData.map(list => {
          const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
          this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
        });
      });
    },
    // 关闭自定义栏目弹窗事件将未保存的数据还原
    closeDrawer() {
      this.columnList[0].children = this.checkedSelectColumns;
    },
    // 判断节点是否能被拖拽
    allowDrag(node) {
      return node.level === 2;
    },
    // 判断节点是否可放置
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.level === 2 && type !== 'inner') {
        return true;
      } else {
        return false;
      }
    },
    // 拖拽成功后触发事件
    handleDrop(draggedNode, insertNode, dropLocation, event) {
      this.dragging = new Date().getTime();
    },
    // 栏目勾选
    handleCheckChange(code, node) {
      this.checkedColumns = node.checkedKeys;
    },
    // 表头宽度变化
    headerWidthChange() {
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    // 列表数据勾选
    handleSelectionChange(arr) {
      this.selectData = arr;
    },
    // 操作
    operationBtn(type) {
      const ids = [];
      this.selectData.map(list => {
        ids.push(list.id);
      });
      if (type === 5) {
        const prop = [];
        const label = [];
        this.currentColumns.map(item => {
          prop.push(item.prop);
          label.push(item.label);
        });
        const param = {
          ...this.queryForm.params,
          fieldList: prop,
          headList: label,
          ids: ids
        };
        apiExportCustomerBills(param).then(res => {
          if (res.code === 200) {
            return goResourceTaskList();
          }
        }).then(res => {
          if (res) {
            this.$emit('closeDetail');
          }
        }).catch(err => {
          console.error(err);
        });
      }
    },
    // 打开查询弹窗
    searchResources() {
      this.queryForm.params.billBatchNumber = this.batchNumber;
      this.showSearch = true;
    },
    // 获取列表
    getExamineTableData() {
      const param = cloneDeep(this.queryForm);
      this.getCurrentColumns = [];
      // 获取列表数据
      apiSiteExpenseGetPage(param).then(resp => {
        if (resp.code === 200) {
          this.tableData = resp.data.map(arr => {
            // 物流订单 waybillStatus    揽收订单 orderTypeList  备货揽收订单 bhOrderTypeList
            if ((arr.businessType === 0 || arr.businessType === 7) && arr.status) {
              /**
               * 1、业务类型 businessType 为 0，订单状态展示【物流订单】的运单状态
               */
              const s1 = this.waybillStatus.find(item => item.code === arr.status) || '';
              arr.status = s1.label;
            } else if (arr.businessType === 1 && arr.collectionCategory === 0 && arr.status) {
              /**
               * 2、业务类型 businessType 为 1，且揽收类别 collectionCategory 为 0，订单状态展示【揽收订单】的订单状态
               */
              const s2 = this.orderTypeList.find(item => item.collectStatus === arr.status) || '';
              arr.status = s2.collectStatusName;
            } else if (arr.businessType === 1 && arr.collectionCategory === 1 && arr.status) {
              /**
               * 3、业务类型 businessType 为 1，且揽收类别 collectionCategory 为 1，订单状态展示【备货揽收订单】的订单状态
               */
              const s3 = this.bhOrderTypeList.find(item => item.collectStatus === arr.status) || '';
              arr.status = s3.collectStatusName;
            } else {
              arr.status = '';
            }
            return arr;
          });
          this.total = resp.total;
          if (resp.data.length > 0) {
            this.getCurrentColumns = [];
            resp.columns.map(item => {
              const obj = {
                prop: item.prop,
                label: item.label.split('_')[0],
                width: 120,
                fixed: 'left',
                highlight: false
              };
              this.getCurrentColumns.push(obj);
              this.$nextTick(() => {
                this.$refs.elTable.doLayout();
                this.rowSelectFlag = true;
                this.tableData.map(list => {
                  const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
                  this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
                });
                this.rowSelectFlag = false;
              });
            });
          }
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 查询
    searchFun(data, select, type) {
      this.searchSelectArr = select;
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.getExamineTableData();
        this.showSearch = false;
      } else if (type === 3) {
        this.queryForm.params = {
          providerId: '', // 应收主体id
          siteId: '', // 战点id
          startTime: '', // 业务开始时间
          endTime: '', // 业务结束时间
          timeField: 'operate_time', // 查询时间类型
          numberList: [], // 单号
          numberField: 'waybill_number', // waybill_number: 业务单号 customer_number: 客户单号
          billBatchNumber: '', // // 批次号
          feeName: '', // 费用类型名称
          isGenBill: null, // 是否生成对账单
          isPaid: null, // // 客户是否已支付
          isOut: null, // 是否已出库
          status: ''
        };
      } else {
        this.showSearch = false;
      }
    }
  }
};
</script>

<style scoped>
.alert {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 6px 16px;
  margin-bottom: 5px;
}

.alert p {
  margin: 0;
  padding: 0;
}

.alert .icon {
  color: #409eff;
  font-size: 24px;
}

.alert .title {
  /*margin-left: 8px;*/
  font-size: 14px;
}

.alert .num {
  font-weight: 600;
  font-size: 14px;
}

.alert .signal {
  color: #409eff;
}
</style>
