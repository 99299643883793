<template>
  <div class="warpp">
    <el-form
      ref="ruleForm"
      :model="queryForm"
      :rules="rules"
      class="queryFormClass"
      label-width="110px"
    >

      <el-form-item
        :label="$t('CenterForSorting.LatticePortNum')"
        prop="gridNum"
      ><!-- 格口编号： -->
        <el-input
          v-model.number="queryForm.gridNum"
          :disabled="isDisable"
          :placeholder="$t('CenterForSorting.pleaseInput')"
          maxlength="9"
          show-word-limit
        />
      </el-form-item>
      <!--      设备名称-->
      <el-form-item :label="$t('GlobalSetObj.deviceName')" prop="deviceId">
        <el-select
          v-model="queryForm.deviceId"
          :placeholder="$t('GlobalSetObj.pleaseChoose')"
          collapse-tags
          filterable
          style="width: 40%"
        >
          <el-option
            v-for="item in deviceList"
            :key="item.id"
            :label="item.deviceName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('CenterForSorting.note')"
      ><!-- 备注： -->
        <el-input
          v-model="queryForm.remark"
          :placeholder="$t('CenterForSorting.pleaseInput')"
          :rows="5"
          type="textarea"
        />
      </el-form-item>

      <el-form-item>
        <el-button v-if="ids === 0" type="primary" @click="handleAddSubmit('ruleForm')">
          {{ $t('CenterForSorting.ImmediatelyCreate') }}
        </el-button><!-- 立即创建 -->
        <el-button v-else type="primary" @click="handleUpdateSubmit('ruleForm')">{{
          $t('CenterForSorting.modify')
        }}
        </el-button><!-- 修改 -->
        <el-button @click="handleCancel">{{ $t('CenterForSorting.cancel') }}</el-button><!-- 取消 -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { apiAdd, apiEdit, deviceManagementList } from '@/api/automaticSorting';
import { checkNumber } from '@/utils/validate';

export default {
  props: {
    ids: {
      type: Number,
      default: 0
    },

    gridNum: {
      type: [Number, undefined],
      default: 0
    },

    remark: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    deviceId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isDisable: false,
      queryForm: {
        gridNum: '', // 编号
        remark: '', // 备注
        deviceId: '' // 设备Id
      },

      rules: {
        gridNum: [
          { required: true, message: this.$t('CenterForSorting.pleaseInput'), trigger: ['blur', 'change'] },
          { validator: checkNumber }
        ],
        deviceId: [
          { required: true, message: this.$t('CenterForSorting.pleaseChoose'), trigger: ['blur', 'change'] }
        ]
      },
      deviceList: [] // 设备列表
    };
  },

  watch: {
    ids: {
      handler(val) {
        if (val === 0) {
          this.isDisable = false;
          this.queryForm.gridNum = '';
          this.queryForm.remark = '';
          this.queryForm.deviceId = '';
        } else {
          this.isDisable = true;
        }
        if (val) {
          // 编辑
          this.queryForm.gridNum = this.gridNum;
          this.queryForm.remark = this.remark;
          this.queryForm.deviceId = this.deviceId;
        } else {
          this.$nextTick(() => {
            // DOM 更新了
            this.$refs['ruleForm'].clearValidate();
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (this.title === this.$t('CenterForSorting.gekouEdit')) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    // 获取设备列表
    deviceManagementList()
      .then((res) => {
        if (res.code===200) {
          this.deviceList = res.data;
        }
        console.log(res, '设备列表');
      });
  },

  methods: {

    // 取消
    handleCancel() {
      this.$emit('updataData', false);
      this.queryForm.gridNum = '';
      this.queryForm.remark = '';
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate();
      });
      setTimeout(() => {
        // eslint-disable-next-line vue/no-mutating-props
        this.ids = 0; // 会报错，但是ids无变化不会触发编辑赋值......
      }, 200);
    },

    // 创建提交
    handleAddSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }
        this.asyncAdd();
      });
    },

    // 修改提交
    handleUpdateSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }
        this.asycnEdit();
      });
    },

    // 新增
    asyncAdd() {
      const deviceName = this.deviceList.find(item => item.id === this.queryForm.deviceId).deviceName;
      const deviceNumber = this.deviceList.find(item => item.id === this.queryForm.deviceId).deviceNumber;
      apiAdd({ ...this.queryForm, deviceName, deviceNumber }).then(res => {
        const { code } = res;
        if (code !== 200) {
          return;
        }

        this.$message({
          message: this.$t('CenterForSorting.newAddSuc'), // '新增成功'
          type: 'success'
        });

        setTimeout(() => {
          this.queryForm.gridNum = '';
          this.queryForm.remark = '';
          this.queryForm.deviceId = '';
        }, 100);
        setTimeout(() => {
          this.$refs['ruleForm'].clearValidate();
          this.$emit('updataData', true);
        }, 200);
      }).catch(err => {
        console.error(err);
      });
    },

    // 编辑
    asycnEdit() {
      const deviceName = this.deviceList.find(item => item.id === this.queryForm.deviceId).deviceName;
      const deviceNumber = this.deviceList.find(item => item.id === this.queryForm.deviceId).deviceNumber;
      const obj = {
        ...this.queryForm,
        id: this.ids,
        deviceName,
        deviceNumber
      };
      apiEdit(obj).then(res => {
        const { code } = res;
        if (code !== 200) {
          return;
        }

        this.$message({
          message: this.$t('CenterForSorting.EditSuccessful'), // '修改成功'
          type: 'success'
        });

        setTimeout(() => {
          this.$emit('updataData', true);
        }, 200);
      }).catch(err => {
        console.error(err);
      });
    }
  }
};
</script>

<style>
.warpp {
  padding-right: 20px;
  box-sizing: border-box;
}

</style>
