<template>
  <div class="searchContainer">
    <el-form label-position="top">
      <el-row :gutter="10">
        <el-col :span="6">
          <!--标题-->
          <el-form-item :label="$t('customerManagements.title')">
            <el-input v-model.trim="params.title" :placeholder="$t('GlobalSetObj.pleaseInputContent')" clearable />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <!--理赔单号-->
          <el-form-item :label="$t('ClaimsManagement.ClaimNumber')">
            <el-input v-model.trim="params.claimsNumber" clearable :placeholder="$t('GlobalSetObj.pleaseInputContent')" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <!--客户-->
          <el-form-item :label="$t('GlobalSetObj.client')">
            <CustomerSelectCmp v-model="params.customerId" style="width: 100%" :is-all="true" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <!--理赔状态-->
          <el-form-item :label="$t('ClaimsManagement.ClaimStatus')">
            <el-select v-model="params.claimsStatus" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%">
              <el-option :label="$t('GlobalSetObj.all')" value="" />
              <el-option
                v-for="item in CLAIMS_STATUS_SELECT_OBJ"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <!--创建人-->
          <el-form-item :label="$t('GlobalSetObj.CreatePeople')">
            <UserSelectCmp
              v-model="params.createUser" :is-user-name="true" class="form-bottom"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <!--开始时间/结束时间-->
          <el-form-item label="">
            <template slot="label">
              <el-select v-model="type2" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 180px;">
                <!-- 申请时间 -->
                <el-option :label="$t('ClaimsManagement.ApplicationTime')" value="startedTime" />
                <el-option :label="$t('collectionCenter.EndTime')" value="endedTime" />
              </el-select>
            </template>
            <el-date-picker
              v-model="params.val2"
              type="datetimerange"
              :start-placeholder="$t('GlobalSetObj.startDate')"
              :end-placeholder="$t('GlobalSetObj.endDate')"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['09:00:00', '18:00:00']"
            />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <!--理赔节点-->
          <el-form-item :label="$t('ClaimsManagement.ClaimsNode')">
            <el-select
              v-model="params.claimsNode" :placeholder="$t('GlobalSetObj.pleaseChoose')" class="form-bottom"
              style="width: 100%"
            >
              <el-option :label="$t('GlobalSetObj.all')" value="" />
              <el-option
                v-for="item in CLAIMSNODE_TYPE_OBJ"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <!--订单号/客户单号-->
          <el-form-item label="" class="selectInner">
            <template slot="label">
              <el-select v-model="type1" :placeholder="$t('GlobalSetObj.pleaseChoose')" size="mini" style="width: 180px;height: 28px;">
                <!-- 客户单号 -->
                <el-option :label="$t('collectionCenter.customerNum')" value="customerNumber" />
                <!-- 订单号 -->
                <el-option :label="$t('basicData.OrderNumber')" value="orderNumber" />
              </el-select>
            </template>
            <el-input
              v-model="params.val1" :placeholder="$t('GlobalSetObj.pleaseInputContent')" clearable
              class="input-with-select"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-button
      type="primary"
      size="small"
      icon="el-icon-search"
      @click="onQuery"
    >{{ $t('operationCenter.Procurar') }}</el-button>
    <el-button size="small" icon="el-icon-refresh-left" @click="onReset">
      {{ $t('operationCenter.reset') }}
    </el-button>
  </div>
</template>

<script>
import UserSelectCmp from '@/views/claimsManagement/claimsReviewRules/cmp/UserSelectCmp.vue';
import CustomerSelectCmp from '@/components/CustomerSelectCmp/index.vue';
import { CLAIMS_STATUS_SELECT_OBJ, QUERY_OBJ, CLAIMSNODE_TYPE_OBJ } from '@/views/claimsManagement/claimList/utils.js';

export default {
  name: '',

  components: {
    CustomerSelectCmp,
    UserSelectCmp
  },

  data() {
    return {
      params: {
        // title: '', // 标题
        // claimsNumber: '', // 理赔单号
        // customerId: '', // 客户
        // claimsStatus: '', // 理赔状态
        // createUser: '', // 创建人
        // claimsNode: '', // 理赔节点
        // // startedTime: '', // 时间
        // // startedTime: '', // 时间
        // time: '', // 时间（开始时间/结束时间）
        // val1: ''
        // val2: ''
        ...QUERY_OBJ
      },

      type1: 'orderNumber', // 订单号/客户单号
      type2: 'startedTime' // 申请时间、结束时间
    };
  },

  watch: {
    params: {
      handler() {
        const row = this.getData();
        this.$emit('change', row);
      },
      deep: true,
      immediate: true
    }
  },

  created() {
    // this.CLAIMS_STATUS_OBJ = CLAIMS_STATUS_OBJ;
    this.CLAIMS_STATUS_SELECT_OBJ = CLAIMS_STATUS_SELECT_OBJ;
    this.CLAIMSNODE_TYPE_OBJ = CLAIMSNODE_TYPE_OBJ;
  },

  methods: {
    getData() {
      const { title, claimsNumber, customerId, claimsStatus, createUser, claimsNode, val1, val2 } = this.params;

      const obj = {
        title,
        claimsNumber,
        customerId,
        claimsStatus,
        createUser,
        claimsNode,
        startedTime: '',
        endedTime: '',
        customerNumber: '',
        orderNumber: ''
      };

      obj[this.type1] = val1;
      obj[this.type2] = val2;

      for (const k in obj) {
        if (obj[k] === '' || obj[k] === null) {
          // delete obj[k];
          obj[k] = undefined;
        }
      }
      return obj;
    },

    onQuery() {
      // const obj = this.getData();
      this.$emit('query');
    },

    onReset() {
      Object.assign(this.params, { ...QUERY_OBJ });
      setTimeout(() => {
        this.$emit('reset');
      }, 50);
    }

  }

};
</script>

<style scoped lang="scss">
.selectInner {
  // height: 28px;
  line-height: 28px;

  .el-form-item__label {
    line-height: 28px !important;
  }
}

.el-date-editor {
  line-height: 30px;
  padding: 0 15px;
}

</style>
