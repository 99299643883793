var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "avatar-container" },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "imgs" },
                  [
                    _c("el-image", {
                      staticStyle: { width: "40px", height: "40px" },
                      attrs: {
                        src: _vm.avatar + "?imageView2/1/w/80/h/80",
                        fit: "cover",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "imgs_box" }, [
                  _c("p", [_vm._v(_vm._s(_vm.orgName))]),
                  _c("p", [_vm._v(_vm._s(_vm.realName))]),
                ]),
                _c("i", {
                  staticClass: "el-icon-arrow-down",
                  staticStyle: { "margin-left": "5px" },
                }),
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.goChangePwd($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v(_vm._s(_vm.$t("login.changePwd"))),
                      ]),
                    ]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v(_vm._s(_vm.$t("login.lginOut"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "todo" }, [_c("lang-select")], 1),
      _c(
        "div",
        { staticClass: "messageTip", on: { click: _vm.handleMessage } },
        [
          _c(
            "el-popover",
            {
              ref: "popoverRef123",
              attrs: { width: 350, placement: "bottom", trigger: "click" },
              scopedSlots: _vm._u([
                {
                  key: "reference",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticStyle: { color: "#ffffff" } },
                        [
                          _vm.getmessageNumber01 > 0
                            ? _c(
                                "el-badge",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    max: 99,
                                    value: _vm.getmessageNumber01,
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-bell",
                                    staticStyle: { "font-size": "25px" },
                                  }),
                                ]
                              )
                            : _c("i", {
                                staticClass: "el-icon-bell",
                                staticStyle: { "font-size": "25px" },
                              }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label:
                          _vm.$t("GlobalSetObj.info") +
                          "(" +
                          _vm.messageList.length +
                          ")",
                        name: "second",
                      },
                    },
                    [
                      _vm.messageList.length
                        ? _c(
                            "div",
                            {
                              staticClass: "message-list",
                              staticStyle: { height: "300px" },
                            },
                            _vm._l(_vm.messageList, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "message-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleMessageInfo(item)
                                    },
                                  },
                                },
                                [
                                  item.isShow
                                    ? _c(
                                        "el-badge",
                                        {
                                          staticClass: "item",
                                          attrs: { value: item.isShow },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "message-icon",
                                            attrs: {
                                              alt: "",
                                              src: require("@/assets/images/msg02.png"),
                                            },
                                          }),
                                        ]
                                      )
                                    : _c("img", {
                                        staticClass: "message-icon",
                                        attrs: {
                                          alt: "",
                                          src: require("@/assets/images/msg02.png"),
                                        },
                                      }),
                                  _c(
                                    "div",
                                    { staticClass: "message-content" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            content: item.title,
                                            effect: "dark",
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "message-title sle",
                                            },
                                            [_vm._v(_vm._s(item.title))]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "message-date" },
                                        [_vm._v(_vm._s(item.titleTime))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _c("div", { staticClass: "message-empty" }, [
                            _c("img", {
                              attrs: {
                                alt: "notData",
                                src: require("@/assets/images/notData.png"),
                              },
                            }),
                            _c("div", [_vm._v("暂无消息")]),
                          ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-popover",
        { attrs: { placement: "bottom", trigger: "click", width: "600" } },
        [
          _c("msg-item", {
            key: _vm.newKey,
            attrs: { "all-total": _vm.allTotal, "grid-data": _vm.gridData },
            on: { getMsg: _vm.getMsg },
          }),
          _c(
            "div",
            {
              staticClass: "numberMsg",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _vm.msgNum
                ? _c(
                    "el-badge",
                    {
                      staticClass: "item",
                      attrs: { max: 99, value: _vm.msgNum },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-sort",
                        staticStyle: { "font-size": "25px" },
                      }),
                    ]
                  )
                : _c("i", {
                    staticClass: "el-icon-sort",
                    staticStyle: { "font-size": "25px" },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "messageTip", on: { click: _vm.handleSearchMenm } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                content: _vm.$t("customerManagements.searchMenu"),
                effect: "dark",
                placement: "top",
              },
            },
            [
              _c("i", {
                staticClass: "el-icon-search",
                staticStyle: { "font-size": "25px" },
              }),
            ]
          ),
        ],
        1
      ),
      _c("searchMenu", { ref: "searchMenuRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }