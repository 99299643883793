<!-- 导入添加费用 -->
<template>
  <div class="flex-c">
    <el-button
      icon="el-icon-download"
      size="small"
      type="primary"
      style="margin: 0 10px 10px 0"
      @click="Download"
    >{{ $t('receivablePayable.DownloadTheTemplate') }}</el-button>
    <upload-excel
      :on-success="importLogistics"
      :but-name="$t('receivablePayable.ImportAddCharges')"
    />
  </div>
</template>

<script>
const ExcelJS = require('exceljs');
import uploadExcel from '@/components/UploadExcel';
import { apiPayableSiteExpenseImportAddFee } from '@/api/finance/receivables';
import { goFileUploadManagement } from '@/utils/goExportList.js';
import { saveAs } from 'file-saver';
import i18n from '@/lang';
const MAX_LENGTH = 100000;
const str1 = i18n.t('receivablePayable.ThirdPartyCollection'); // 第三方收款
const str2 = i18n.t('receivablePayable.AnjunCottage'); // 安俊
const str3 = i18n.t('receivablePayable.AJDDispatch'); // AJD
const str4 = i18n.t('receivablePayable.DSPDispatch'); // DSP

export default {
  name: 'ImportAddFees',

  components: {
    uploadExcel
  },
  props: {
    // 账单类型
    payableType: {
      type: Number,
      required: true
    },

    // 费用类型
    feeTypeList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
    };
  },
  created() {
    // 每次最多导入xxxx条数据
    this.lenTips = this.$t('receivablePayable.Only100000PiecesImport');
  },
  methods: {
    Download() {
      if ([1, 2].includes(this.payableType)) {
        this.downloadTemplate_one();
      } else {
        this.downloadTemplate_two();
      }
    },
    // 下载模板(揽收和小屋)
    async downloadTemplate_one() {
      const that = this;
      async function ddd(outletsList) {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const Sheet2 = wb.addWorksheet('Sheet2');
        const Sheet3 = wb.addWorksheet('Sheet3');
        const Sheet4 = wb.addWorksheet('Sheet4');
        const Sheet5 = wb.addWorksheet('Sheet5');
        Sheet1.columns = [
          {
            header: '*' + that.$t('basicData.是否存在数据'), // 是否存在数据',
            key: 'exitData',
            width: 40
          },
          {
            header: '*' + that.$t('receivablePayable.TicketNumber'), // 业务单号',
            key: 'waybillNumber',
            width: 40
          },
          {
            header: '*' + that.$t('newOrder.CollectionType'), // '揽收类型',
            key: 'collectType',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.ExpenseType'), // 费用类型',
            key: 'feeCode',
            width: 40
          },
          {
            header: '*' + that.$t('receivablePayable.AmountDue'), // 应付金额',
            key: 'fee',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.BusinessTime') + '(' + that.$t('customerManagements.format') + '：2022-01-01 00:00:00)', // '业务时间(格式：2022-01-01 00:00:00)',
            key: 'operateTime',
            width: 40
          },
          {
            header: '*' + that.$t('receivablePayable.FlatSharingMethod'), // '分摊方式',
            key: 'flatMethod',
            width: 40
          },
          {
            header: that.$t('receivablePayable.remark'), // '备注',
            key: 'remark',
            width: 40
          }
        ];
        Sheet1.getCell('A2').value = 'Existência de dados ou não';
        Sheet1.getCell('B2').value = 'O negócio pertence';
        Sheet1.getCell('C2').value = 'número do negócio';
        Sheet1.getCell('D2').value = 'Tipo de negócio';
        Sheet1.getCell('E2').value = 'Nome da taxa';
        Sheet1.getCell('F2').value = 'Taxas a pagar';
        Sheet1.getCell('G2').value = 'Hora da ocorrência (Formato: 2022-01-01 00:00:00)';
        Sheet1.getCell('H2').value = 'Observações';
        Sheet1.mergeCells('A3:E3');
        Sheet1.getCell('A3').value = `1、${that.$t('receivablePayable.Only100000PiecesImport')} /Importar apenas 100000 itens de cada vez
          2、除了备注其他必填 /Tudo, exceto as notas, é obrigatório
          3、不可更改此模板 /Esse modelo não pode ser alterado '`;
        Sheet1.getRow(3).getCell(1).font = {
          color: { argb: 'ff0000' }
        };

        Sheet2.columns = [
          {
            header: that.$t('basicData.ExpenseType'), // 费用类型
            key: 'feeCode',
            width: 40
          }
        ];
        const Sheet2_data = [];
        that.feeTypeList.map(item => {
          Sheet2_data.push({
            feeCode: item.displayName + '-' + item.code,
            width: 20
          });
        });
        Sheet2.addRows(Sheet2_data);

        Sheet3.columns = [
          {
            header: that.$t('newOrder.CollectionType'), // 揽收类型',
            key: 'collectType',
            width: 40
          }
        ];
        const Sheet3_data = [
          { collectType: that.$t('collectionCenter.dropOff') + '-' + 1 },
          { collectType: that.$t('collectionCenter.doorPickUp') + '-' + 2 }
        ];
        Sheet3.addRows(Sheet3_data);

        // Sheet4 是否存在数据
        Sheet4.columns = [
          {
            header: '是否存在数据',
            key: 'exitData',
            width: 40
          }
        ];
        const sheet4Types = [
          { name: that.$t('GlobalSetObj.yes'), code: 1 },
          { name: that.$t('GlobalSetObj.No'), code: 0 }
        ];
        const Sheet4_data = sheet4Types.map(item => {
          return {
            exitData: item.name + '-' + item.code,
            width: 20
          };
        });
        Sheet4.addRows(Sheet4_data);

        Sheet5.columns = [
          {
            header: that.$t('receivablePayable.FlatSharingMethod'), // 平摊方式,
            key: 'flatMethod',
            width: 40
          }
        ];
        const Sheet5_data = [
          { flatMethod: that.$t('receivablePayable.SpreadEquallyByWeight') + '-' + 0 },
          { flatMethod: that.$t('receivablePayable.SplitEquallyByTheNumberOfVotes') + '-' + 1 },
          { flatMethod: that.$t('receivablePayable.SpecifyTheAmount') + '-' + 2 }
        ];
        Sheet5.addRows(Sheet5_data);

        new Array(MAX_LENGTH).fill(0).forEach((_, idx) => {
          const row = idx + 4;

          // 是否存在数据
          Sheet1.getCell(row, 1).dataValidation = {
            type: 'list',
            formulae: [`=Sheet4!$A$2:$A${Sheet4_data.length + 1}`]
          };
          // 揽收类型
          Sheet1.getCell(row, 3).dataValidation = {
            type: 'list',
            formulae: [`=Sheet3!$A$2:$A${Sheet3_data.length + 1}`]
          };

          // 平摊方式
          Sheet1.getCell(row, 7).dataValidation = {
            type: 'list',
            formulae: [`=Sheet5!$A$2:$A${Sheet5_data.length + 1}`]
          };

          // 费用类型下拉框
          Sheet1.getCell(row, 4).dataValidation = {
            type: 'list',
            formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
          };

          Sheet1.getCell(row, 6).numFmt = '@';
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = (() => {
          switch (that.payableType) {
            case 1:
              return str2;
            case 2:
              return str1;
            case 3:
              return str3;
            default:
              return str4;
          }
        })() + that.$t('receivablePayable.ImportAddCharges') + '.xlsx';

        saveAs(blob, saveName);
        return;
      }

      try {
        await ddd();
      } catch (error) {
        console.error(error);
      }
    },

    // 下载模板（AJD/DSP）
    async downloadTemplate_two() {
      const that = this;
      async function ddd(outletsList) {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const Sheet2 = wb.addWorksheet('Sheet2');
        const Sheet4 = wb.addWorksheet('Sheet4');
        const Sheet5 = wb.addWorksheet('Sheet5');
        Sheet1.columns = [
          {
            header: '*' + that.$t('basicData.是否存在数据'), // 是否存在数据',
            key: 'exitData',
            width: 40
          },
          {
            header: '*' + that.$t('receivablePayable.TicketNumber'), // 业务单号',
            key: 'waybillNumber',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.ExpenseType'), // 费用类型',
            key: 'feeCode',
            width: 40
          },
          {
            header: '*' + that.$t('receivablePayable.AmountDue'), // 应付金额',
            key: 'fee',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.BusinessTime') + '(' + that.$t('customerManagements.format') + '：2022-01-01 00:00:00)', // '业务时间(格式：2022-01-01 00:00:00)',
            key: 'operateTime',
            width: 40
          },
          {
            header: '*' + that.$t('receivablePayable.FlatSharingMethod'), // '分摊方式',
            key: 'flatMethod',
            width: 40
          },
          {
            header: that.$t('receivablePayable.remark'), // '备注',
            key: 'remark',
            width: 40
          }
        ];
        Sheet1.getCell('A2').value = 'Existência de dados ou não';
        Sheet1.getCell('B2').value = 'O negócio pertence';
        Sheet1.getCell('C2').value = 'Tipo de negócio';
        Sheet1.getCell('D2').value = 'Nome da taxa';
        Sheet1.getCell('E2').value = 'Taxas a pagar';
        Sheet1.getCell('F2').value = 'Hora da ocorrência (Formato: 2022-01-01 00:00:00)';
        Sheet1.getCell('G2').value = 'Observações';
        Sheet1.mergeCells('A3:E3');
        Sheet1.getCell('A3').value = `1、${that.$t('receivablePayable.Only100000PiecesImport')} /Importar apenas 100000 itens de cada vez
          2、除了备注其他必填 /Tudo, exceto as notas, é obrigatório
          3、不可更改此模板 /Esse modelo não pode ser alterado '`;
        Sheet1.getRow(3).getCell(1).font = {
          color: { argb: 'ff0000' }
        };

        Sheet2.columns = [
          {
            header: that.$t('basicData.ExpenseType'), // 费用类型
            key: 'feeCode',
            width: 40
          }
        ];
        const Sheet2_data = [];
        that.feeTypeList.map(item => {
          Sheet2_data.push({
            feeCode: item.displayName + '-' + item.code,
            width: 20
          });
        });
        Sheet2.addRows(Sheet2_data);

        // Sheet4 是否存在数据
        Sheet4.columns = [
          {
            header: '是否存在数据',
            key: 'exitData',
            width: 40
          }
        ];
        const sheet4Types = [
          { name: that.$t('GlobalSetObj.yes'), code: 1 },
          { name: that.$t('GlobalSetObj.No'), code: 0 }
        ];
        const Sheet4_data = sheet4Types.map(item => {
          return {
            exitData: item.name + '-' + item.code,
            width: 20
          };
        });
        Sheet4.addRows(Sheet4_data);

        Sheet5.columns = [
          {
            header: that.$t('receivablePayable.FlatSharingMethod'), // 平摊方式,
            key: 'flatMethod',
            width: 40
          }
        ];
        const Sheet5_data = [
          { flatMethod: that.$t('receivablePayable.SpreadEquallyByWeight') + '-' + 0 },
          { flatMethod: that.$t('receivablePayable.SplitEquallyByTheNumberOfVotes') + '-' + 1 },
          { flatMethod: that.$t('receivablePayable.SpecifyTheAmount') + '-' + 2 }
        ];
        Sheet5.addRows(Sheet5_data);

        new Array(MAX_LENGTH).fill(0).forEach((_, idx) => {
          const row = idx + 4;

          // 是否存在数据
          Sheet1.getCell(row, 1).dataValidation = {
            type: 'list',
            formulae: [`=Sheet4!$A$2:$A${Sheet4_data.length + 1}`]
          };

          // 平摊方式
          Sheet1.getCell(row, 6).dataValidation = {
            type: 'list',
            formulae: [`=Sheet5!$A$2:$A${Sheet5_data.length + 1}`]
          };

          // 费用类型下拉框
          Sheet1.getCell(row, 3).dataValidation = {
            type: 'list',
            formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
          };

          Sheet1.getCell(row, 5).numFmt = '@';
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = (() => {
          switch (that.payableType) {
            case 1:
              return str1;
            case 2:
              return str2;
            case 3:
              return str3;
            default:
              return str4;
          }
        })() + that.$t('receivablePayable.ImportAddCharges') + '.xlsx';

        saveAs(blob, saveName);
        return;
      }

      try {
        await ddd();
      } catch (error) {
        console.error(error);
      }
    },

    // 导入费用
    async importLogistics(importData) {
      const headObj = [1, 2].includes(this.payableType)
        ? ['exitData', 'waybillNumber', 'collectType', 'feeCode', 'fee', 'operateTime', 'flatMethod', 'remark']
        : ['exitData', 'waybillNumber', 'feeCode', 'fee', 'operateTime', 'flatMethod', 'remark'];
      const excelData = importData[0].results.splice(2);
      // console.log(excelData.length, headObj);
      const dataList = [];
      excelData.map(item => {
        const data = {};
        // console.log(item, 'item');
        Object.keys(item).forEach((key, i) => {
          // console.log(key, i);
          try {
            // 是否存在数据、揽收类型、费用类型、平摊方式
            if (['exitData', 'feeCode', 'collectType', 'flatMethod'].includes(headObj[i])) {
              // console.log(item[key]);
              data[headObj[i]] = item[key]?.split('-').pop() || '';
            } else if (headObj[i] === 'operateTime') {
              // 此处可以添加时间格式校验逻辑
              data[headObj[i]] = item[key];
            } else {
              data[headObj[i]] = item[key] || '';
            }
          } catch (error) {
            this.$message.error(this.$t('receivablePayable.ImportTemplateIncorrect'));
            throw new Error(error);
          }
        });
        dataList.push(data);
      });
      // console.log(dataList, 'dataList');
      if (!dataList.length) {
        this.$message({
          type: 'warning',
          message: this.$t('userManage.ImportDataIsEmpty')
        });
        return;
      }

      if (dataList.length > MAX_LENGTH) {
        this.$message({
          type: 'warning',
          message: this.$t('receivablePayable.Only100000PiecesImport')
        });
        return;
      }

      const list = dataList.map(item => {
        item.businessType = [1, 2].includes(this.payableType) ? 2 : 3;
        return item;
      });

      try {
        const res = await apiPayableSiteExpenseImportAddFee({
          list,
          payableType: this.payableType
        });
        if (res.code === 200) {
          await goFileUploadManagement(this);
          this.$emit('colse');
        } else {
          // 处理API调用失败的场景
          console.error('API调用失败:', res);
        }
      } catch (err) {
        console.error('导入费用时发生错误:', err);
      }
    }
  }
};
</script>

<style scoped>
.flex-c {
  display: flex;
  flex-wrap: wrap;
}
</style>
