<template>
  <div class="ScanCourierBox">
    <el-tabs v-model="changeTab" type="border-card">
      <!-- 客户交件查询列表 -->
      <el-tab-pane :label="$t('collectionCenter.customerHandFindTable')" name="second">
        <div style="margin-bottom: 20px;">
          <el-form class="selectClass">
            <el-row type="flex" justify="start" style="flex-wrap: wrap;">
              <el-col :span="10" style="width: auto;">
                <el-row>
                  <!-- 揽收时间： -->
                  <el-form-item :label="$t('collectionCenter.lanshouTime')">
                    <el-date-picker
                      v-model="timeValue"
                      :clearable="false"
                      type="daterange"
                      value-format="yyyy-MM-dd"
                      :default-time="['00:00:00', '23:59:59']"
                      :range-separator="$t('collectionCenter.to')"
                      :start-placeholder="$t('collectionCenter.StartTime')"
                      :end-placeholder="$t('collectionCenter.EndTime')"
                      @change="dateChange"
                    />
                  </el-form-item>
                </el-row>
              </el-col>
              <!-- 网点名称 -->
              <el-col :span="7" style="width: auto;">
                <el-form-item :label="$t('collectionCenter.siteTitle')">
                  <el-select v-model="queryForm.params.siteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable @focus="getNewestSite">
                    <el-option
                      v-for="(item, index) in siteIdList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 揽收清单号： -->
              <el-col :span="7" style="width: auto;">
                <el-form-item :label="$t('collectionCenter.receivingListNumber')">
                  <el-input v-model.trim="queryForm.params.listNumber" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
                </el-form-item>
              </el-col>
              <el-col :span="7" style="width: auto;">
                <!-- 客户名称： -->
                <el-form-item :label="$t('collectionCenter.CustomerN')">
                  <!-- <el-input v-model.trim="queryForm.params.customerName" clearable :placeholder="$t('collectionCenter.pleaseInput')" /> -->
                  <el-select v-model.trim="queryForm.params.customerId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable @focus="getNewestCustomer" @change="changeCustomerName">
                    <el-option
                      v-for="(item, index) in customerIdList"
                      :key="index"
                      :label="item.username"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 商户号/名称 -->
              <el-col :span="7" style="width: auto;" class="col10">
                <!-- 商户号/商户名称： -->
                <el-form-item class="m">
                  <el-select v-model="merchantChoose" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass" @change="changeMerchant">
                    <el-option
                      v-for="(item, index) in merchantCodeOrName"
                      :key="index"
                      :label="item.name"
                      :value="item.m"
                    />
                  </el-select>
                  <el-select
                    v-model="merchantCodeValue"
                    filterable
                    clearable
                    remote
                    reserve-keyword
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    :remote-method="remoteMethod"
                    :loading="loading"
                    @change="changeValue"
                  >
                    <el-option
                      v-for="(item, index) in merchantCodeList"
                      :key="index"
                      :label="item.name"
                      :value="item.name"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7" style="width: auto;">
                <!-- 揽收人： -->
                <el-form-item :label="$t('collectionCenter.LanShouPerson')">
                  <el-select
                    v-model="queryForm.params.collectId"
                    remote
                    reserve-keyword
                    :remote-method="getNewestUser"
                    :placeholder="$t('collectionCenter.pleaseChoose')"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in userNameList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7" style="width: auto;">
                <!-- 袋号： -->
                <el-form-item :label="$t('collectionCenter.bagsNumber')">
                  <div class="BlockNewlineClass">
                    <el-input
                      v-model="queryForm.params.bagNumber"
                      type="textarea"
                      :placeholder="$t('collectionCenter.BatchQuery')"
                      @blur="ft"
                      @keydown.enter.native="BlockNewline"
                    />
                  </div>
                  <!-- 支持批量查询(回车换行分割) -->
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-row>
            <el-col :span="12">
              <div>
                <el-button
                  v-permit:remove="'btn:collection-center:operation:ScanCourier:find'"
                  v-loading.fullscreen.lock="fullscreenLoading"
                  type="primary"
                  size="mini"
                  icon="el-icon-search"
                  @click="searchClick"
                >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
                <el-button
                  v-permit:remove="'btn:collection-center:operation:ScanCourier:reset'"
                  icon="el-icon-refresh"
                  size="mini"
                  @click="resetClick"
                >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
                <el-button
                  v-permit:remove="'btn:collection-center:operation:ScanCourier:print'"
                  icon="el-icon-printer"
                  size="mini"
                  :class="collectListIdList.length <=0 && isClick ? 'onlyClickOnce10' : ''"
                  @click="uploadClick"
                >{{ $t('collectionCenter.Print') }}</el-button><!-- 打印 -->
                <el-button
                  v-permit:remove="'btn:collection-center:operation:ScanCourier:export'"
                  icon="el-icon-upload2"
                  size="mini"
                  :class="selectionList.length <=0 && isClickAll ? 'onlyClickOnce10' : ''"
                  @click="downloadLogistics"
                >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 -->
              </div>
            </el-col>
          </el-row>
          <el-divider />
        </div>
        <div>
          <el-table
            ref="queryTableRef"
            border
            show-summary
            :summary-method="getSummaries"
            :data="tableList"
            :max-height="600"
            @select="batchSelect"
            @select-all="batchSelectAll"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <!-- 序号 -->
            <el-table-column :label="$t('collectionCenter.ind')" type="index" width="60" align="center">
              <template slot-scope="scope">
                <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="listNumber"
              :label="$t('collectionCenter.receivingListNum')"
              width="200"
            /><!-- 揽收清单号 -->
            <el-table-column
              prop="customerId"
              :label="$t('collectionCenter.clientID')"
            /><!-- 客户编号 -->
            <el-table-column
              prop="customerName"
              :label="$t('collectionCenter.CustomerName')"
            /><!-- 客户名称 -->
            <el-table-column
              prop="merchantCode"
              :label="$t('collectionCenter.MerchantNum')"
            /><!-- 商户号 -->
            <el-table-column
              prop="merchantName"
              :label="$t('collectionCenter.MerchantName')"
            />
            <!-- 商户名称 -->
            <el-table-column
              prop="bagNumber"
              :label="$t('collectionCenter.BagNumber')"
            /><!-- 袋号 -->
            <el-table-column
              prop="realNumber"
              :label="$t('collectionCenter.ballot')"
              width="100"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="!isBallot">{{ scope.row.realNumber }}</span>
                <el-button v-else size="mini" @click="cellClick(scope.row)">{{ scope.row.realNumber }}</el-button>
              </template>
            </el-table-column><!-- 票数 -->
            <el-table-column
              prop="code"
              :label="$t('collectionCenter.siteNum')"
            /><!-- 站点编号 -->
            <el-table-column
              prop="name"
              :label="$t('collectionCenter.siteName')"
            /><!-- 站点名称 -->
            <el-table-column
              prop="userName"
              :label="$t('collectionCenter.LanShouPeople')"
            /><!-- 揽收人 -->
            <el-table-column
              prop="scanTime"
              :label="$t('collectionCenter.lanshouT')"
            /><!-- 揽收时间 -->
            <el-table-column
              prop="printCount"
              :label="$t('collectionCenter.printNumber')"
            /><!-- 打印次数 -->
            <el-table-column
              prop="printTime"
              :label="$t('collectionCenter.printTime')"
            /><!-- 打印时间 -->
            <!-- 商户名称 -->
            <el-table-column
              v-if="isBallot"
              prop="moreActionsOrder"
              :label="$t('collectionCenter.MoreActions')"
              width="160"
              align="center"
            >
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="cellClick(scope.row)"> {{ $t('collectionCenter.orderDetails') }} </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :page-size.sync="queryForm.pageSize"
            :total="tableTotal"
            background
            style="margin: 10px 0"
            @current-change="getDatePage"
            @size-change="getPageSize"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 扫描预览 -->
    <div class="dialogUpload">
      <el-dialog
        title=""
        :visible.sync="dialogUploadVisible"
        :fullscreen="true"
        :show-close="false"
        @opened="openDialog"
      >
        <div ref="dialogUpload" style="padding:0;" class="upLoadContentBox">
          <div v-for="(item, index) in printBox.printList" :key="index" class="upLoadContent">
            <div class="blackBoxLine">
              <div class="rowTopClass">
                <div class="l" style="font-size: 26px;">{{ $t('collectionCenter.LSSite') }}{{ item.siteName }}</div><!-- 揽收站点： -->
                <div class="r">
                  <img :src="LOGO" alt="" style="width: 80%;height: auto;">
                </div>
              </div>
              <div class="titleTop" style="height:30px;">
                <p />
              </div>
              <div class="rowClass">
                <div class="l" style="font-size: 26px;min-height: 60px;">{{ $t('collectionCenter.LanShouP') }}</div><!-- 揽收人员： -->
                <div class="r" style="font-size: 26px;min-height: 60px;">{{ item.lanshouName }}</div>
              </div>
              <div class="rowClass">
                <div class="l" style="font-size: 26px;min-height: 60px;">{{ $t('collectionCenter.lanshouTime') }}</div><!-- 揽收时间： -->
                <div class="r" style="font-size: 26px;min-height: 60px;">{{ item.lanshouTime }}</div>
              </div>
              <div class="titleTop" style="height: 62px;">
                <p class="" style="font-size: 26px;">{{ $t('collectionCenter.lanshouMsg') }}</p><!-- 揽收信息 -->
              </div>
              <div class="rowClass">
                <div class="l" style="font-size: 26px;min-height: 60px;">{{ $t('collectionCenter.LSSum') }}</div><!-- 揽收总票数： -->
                <div class="r bigSize" style="font-size: 26px;min-height: 60px;">{{ item.lanshouSum }}</div>
              </div>
              <div class="titleTop" style="height: 62px;">
                <p class="" style="font-size: 26px;">{{ $t('collectionCenter.customerSignature') }}</p><!-- 客户签名 -->
              </div>
              <div class="">
                <div class="signatureBox">
                  <img
                    style="width: auto; height: 198px"
                    :src="item.uploadSignImg"
                    fit="contain"
                  >
                </div>
              </div>
              <!-- 扫描保存揽收图片不显示包裹号
              <div>
                <div class="packageNumberListClass" style="padding: 20px;box-sizing: border-box;">
                  <span style="margin-right:10px;">{{ item.packageNumberList }}</span>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="printBtnClass">
          <el-button type="success" class="onlyClickOnce" @click="saveCollectioniOrder">{{ $t('collectionCenter.Save') }}</el-button><!-- 保存 -->
        </div>
      </el-dialog>
    </div>
    <!-- 打印预览 -->
    <div v-if="dayinVisible">
      <el-dialog
        title=""
        :visible.sync="dayinVisible"
        :fullscreen="true"
        :show-close="false"
      >
        <div class="dayinContentBox">
          <div v-for="(item, index) in printBox.printList" :key="index" class="dayinImg">
            <div class="dyImg">
              <img
                style="width: 900px;height: auto;max-width: 100%;"
                :src="item.uploadSignImg || ''"
                fit="contain"
                @load="loadImg(index)"
              >
            </div>
            <div class="dayinBox">
              <div class="dayinCenter">
                <div class="rowTopClass" style="border-top:1px solid #000;border-bottom: none;">
                  <div class="l">{{ $t('collectionCenter.LSSite') }}{{ item.siteName }}</div><!-- 揽收站点： -->
                  <div class="r">
                    <img :src="LOGO" alt="">
                  </div>
                </div>
                <div class="titleTop packageMsg" style="height: 40px;font-size: 20px;">
                  <p>{{ $t('collectionCenter.pgMsg') }}</p>
                </div>
                <div class="">
                  <div class="pgTableRowTitle">
                    <div class="pgTableRow">
                      <span class="one">{{ $t('collectionCenter.ind') }}</span>
                      <span class="two">{{ $t('collectionCenter.pgBarCode') }}</span>
                      <span class="three">{{ $t('collectionCenter.scanTime') }}</span>
                    </div>
                    <div class="pgTableRow">
                      <span class="one">{{ $t('collectionCenter.ind') }}</span>
                      <span class="two">{{ $t('collectionCenter.pgBarCode') }}</span>
                      <span class="three">{{ $t('collectionCenter.scanTime') }}</span>
                    </div>
                  </div>
                  <div v-for="(itemm, indexx) in item.packageNumberList" :key="indexx" class="tableClass">
                    <div class="pgTableRow">
                      <span class="one">{{ indexx + 1 }}</span>
                      <span class="two">{{ itemm.scanNumber }}</span>
                      <span class="three">{{ itemm.scanTime }}</span>
                    </div>
                  </div>
                  <div v-if="item.packageNumberList && item.packageNumberList.length%2!==0" class="tableClass" style="float: right;">
                    <div class="pgTableRow">
                      <span class="one" />
                      <span class="two" />
                      <span class="three" />
                    </div>
                  </div>
                  <div style="text-align: center;height: 43px;line-height: 43px;font-size: 24px;">{{ $t('collectionCenter.pgTotal') }}{{ item.lanshouSum }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="printBtnClass">
          <el-button type="success" @click="printBtnClick">{{ $t('collectionCenter.Print') }}</el-button><!-- 打印 -->
          <el-button @click="cancelBtnClick">{{ $t('collectionCenter.cancel') }}</el-button><!-- 取消 -->
        </div>
      </el-dialog>
    </div>
    <!-- 票数信息弹窗 -->
    <div v-if="pollVisible" class="pollVisible">
      <!-- 票数信息 -->
      <el-dialog :title="$t('collectionCenter.pollList')" :visible.sync="pollVisible">
        <div class="pollTop">{{ $t('collectionCenter.NumName') }}{{ pollListName }}</div><!-- 商户号/商户名称： -->
        <div class="pollBox">
          <div v-for="(item, index) in pollListNumber" :key="index" class="pollContent">
            <span class="pollIndex">{{ index + 1 }}.</span>
            <!-- 条形码： -->
            <span>{{ $t('collectionCenter.BarcodeL') }}{{ item }}</span>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 签名 -->
    <div v-if="signVisible" class="signMask">
      <div class="signVisibleClass">
        <div class="el-icon-close signClose" @click="signVisible=false" />
        <div class="page-content">
          <div class="content">
            <vue-esign ref="esign" :width="1024" :height="728" :line-width="5" line-color="#000" bg-color="#FFF" />
          </div>
          <div class="flex">
            <el-button class="btn" type="danger" @click="handleReset">{{ $t('collectionCenter.empty') }}</el-button><!-- 清空 -->
            <el-button class="btn" type="success" @click="handleGenerate">{{ $t('collectionCenter.Confirm') }}</el-button><!-- 确认 -->
          </div>
        </div>
      </div>
    </div>
    <div class="audioClass">
      <!-- 扫描成功音效 -->
      <audio ref="audioSuc" :src="audioSucUrl" />
      <!-- 扫描失败音效 -->
      <audio ref="audioFail" :src="audioFailUrl" />
    </div>

  </div>
</template>

<script>
import ExcelJS from 'exceljs';
import {
  query10User,
  getSiteByUser,
  createConfirmedList,
  doorToDoorDeliveryqueryPage,
  queryCustomerPackage,
  updatePrintTimes,
  onSiteDeliveryDetailedPage,
  queryPrintDetailed,
  userFindByUser,
  reidsDeleteCustomerPackage,
  queryAllCustomer,
  queryAllMerchantCode,
  getFormatServerTime
} from '@/api/lanshou';
import store from '@/store';
import { uploadOos } from '@/utils/ali_oos_util';
import html2Canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      isClick: false,
      isClickAll: false,
      userNameList: [],
      isBallot: this.$store.getters.button_permissions.includes('btn:collection-center:operation:ScanCourier:ballot'),
      timeValue: [],
      brazilTime: '', // 赋值巴西时间
      siteIdList: [],
      loadIndex: [], // 需打印图片加载完成存放
      isPrintOK: false, // 图片加载完成后打印
      dialogUploadVisible: false, // 扫描预览
      LOGO: '/static/anjunLogo.png',
      changeTab: 'second',
      // 音效
      audioSucUrl: '/static/scan_success.mp3',
      audioFailUrl: '/static/scan_fail.mp3',
      // 签名
      signVisible: false, // 签名窗口
      uploadSignImg: '', // 上传签名图片
      // 揽收订单图（含签名）
      uploadImg: '',
      isGetUploadImg: false,
      lanshouSiteName: '',
      dayinVisible: false, // 打印预览（图片展示）

      pollVisible: false, // 票数信息窗口
      pollListName: '', // 票数窗口存值
      pollListNumber: [], // 票数窗口存值
      packageNumberMsg: [],
      // dialogVisible: false, // 已扫描的物流单号列表
      isPrint: true, // 是否显示打印按钮
      packageNumber: '', // 包裹号
      getPackageNumber: '', // 当前被扫描包裹号为：
      scanTotal: 0,
      scanTableData: [],
      getSearchCustomer: {
        code: '', // 客户编号
        username: '', // 客户名称 fsd
        customerPhone: '' // 手机号
      },
      // 登记扫描
      registrationScanForm: {
        userId: '',
        customerId: '', // 按条件查询——id
        finishSignUrl: '',
        merchantCode: '',
        collectOrderList: [
          {
            orderId: '',
            scanTime: '',
            scanPackageNumber: '',
            orderNumber: '',
            packageNumber: ''
          }
        ] // 扫件信息：scanTime、packageNumber
      },
      collectOrderList: [], // 赋值给registrationScanForm
      queryCustomerPackageList: { // 查询客户下的包号是否存在
        customerId: '',
        packageNumber: '',
        merchantCode: ''
      },

      customerIdList: [], // 客户名称列表
      merchantChoose: 'm2', // 选择输入商户号还是商户名称
      merchantCodeOrName: [
        { m: 'm1', name: this.$t('collectionCenter.MerchantNum') }, // '商户号'
        { m: 'm2', name: this.$t('collectionCenter.MerchantName') } // '商户名称'
      ],
      loading: false, // 商户号加载
      merchantCodeValue: '',
      merchantCodeList: [], // 商户编号列表
      // 客户上门交件查询
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '', // 开始时间
          endTime: '', // 结束时间
          siteId: '', // 站点编号
          customerId: '', // 客户名称
          merchantCode: '',
          merchantName: '',
          listNumber: '', // 揽收清单号
          collectId: '', // 揽收人
          bagNumber: ''
        }
      },
      tableTotal: 0,
      tableList: [],
      printBox: { // 打印表单
        printList: [{
          listNumber: '',
          customerName: '',
          lanshouName: '',
          lanshouSum: '',
          lanshouTime: '',
          siteName: '',
          uploadSignImg: ''
        }]
      },
      collectListIdList: [],
      strId: '', // 打印后更新打印次数获取id
      selectionList: [], // 导出数据
      userId: '',
      isAdd: false,
      scanCourierList: {},
      fullscreenLoading: false
    };
  },
  watch: {
    scanTableData: {
      handler(newValue, oldValue) {
        this.scanTotal = this.scanTableData.length || newValue.length;
        if (localStorage.getItem('scanTableData')) {
          if (this.scanTableData.length > 0) {
            this.getPackageNumber = this.scanTableData[0].packageNumber || '';
          }
        } else {
          this.getPackageNumber = '';
          this.scanTotal = 0;
          localStorage.setItem('scanTableData', '');
          localStorage.setItem('collectOrderList', '');
          this.collectOrderList = [];
        }
      },
      deep: true,
      immediate: true
    },
    'queryForm.params.bagNumber'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 50) {
        number = bghTrim.slice(0, 50).join('\n');
        this.queryForm.params.bagNumber = number;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bghTrim.length > 50) {
        this.$message.warning(this.$t('collectionCenter.intercept50')); // '单次查询最多50条，已截取前50条数据'
      }
    },
    'queryForm.params.collectId'(val) {
      if (!val && this.userNameList.length > 0) {
        this.getNewestUser();
      }
    }
  },
  created() {
    this.isClick = false;
    this.isClickAll = false;
    if (localStorage.getItem('scanTableData') || localStorage.getItem('collectOrderList')) {
      this.scanTableData = JSON.parse(localStorage.getItem('scanTableData'));
      this.collectOrderList = JSON.parse(localStorage.getItem('collectOrderList'));
    } else {
      if (localStorage.getItem('scanCourier')) {
        // 切换账号后，先判断scanTableData有无值，没有值再获取缓存scanCourier值
        this.scanCourierList = JSON.parse(window.atob(localStorage.getItem('scanCourier'))) || {};
        const who = 'nui' + this.$store.state.user.id;
        if (this.scanCourierList[who]) {
          this.scanTableData = this.scanCourierList[who].scanTableData;
          this.collectOrderList = this.scanCourierList[who].collectOrderList;

          localStorage.setItem('scanTableData', JSON.stringify(this.scanTableData));
          localStorage.setItem('collectOrderList', JSON.stringify(this.collectOrderList));
        } else {
          this.scanTableData = [];
          this.collectOrderList = [];
        }
      } else {
        this.scanTableData = [];
        this.collectOrderList = [];
        this.scanTotal = this.scanTableData.length;
      }
    }
    this.userId = this.$store.state.user.id;
    // 站点：this.$store.state.user.siteId
    this.strId = '';
    this.defaultCondition();
    // 交件查询列表
    this.searchClick();
    userFindByUser(this.userId).then(res => {
      if (res.code===200) {
        this.lanshouSiteName = res.data[0].siteName;
      }
    });
    this.getNewestSite();
    this.getNewestUser();
    this.getNewestCustomer();
  },
  methods: {
    ft() {
      const val = this.queryForm.params.bagNumber.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.bagNumber = bghTrim.join('\n');
    },
    BlockNewline() { // 文本域最大输入行数为50
      const e = window.event || arguments[0];
      this.ft();
      const numberLen = this.queryForm.params.bagNumber.split('\n');
      if (!this.notEnter) {
        if (numberLen.length >= 50) {
          this.$message.warning(this.$t('collectionCenter.intercept50')); // '单次查询最多50条，已截取前50条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    // 获取揽收人数据
    getNewestUser(val) {
      const value = val || '';
      query10User(value).then(res => {
        if (res.code===200) {
          this.userNameList = [];
          res.data.forEach(data => {
            this.userNameList.push({ name: data.realName, id: data.id });
          });
        }
      });
    },
    // 获取最新的网点list数据
    getNewestSite() {
      getSiteByUser().then(res => {
        if (res.code===200) {
          this.siteIdList = [];
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    getNewestCustomer() {
      queryAllCustomer().then(res => {
        if (res.code===200) {
          this.customerIdList = [];
          res.data.forEach((list) => {
            this.customerIdList.push({
              id: list.id,
              username: list.username
            });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.startTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.startTime = val[0] + ' 00:00:00';
      this.queryForm.params.endTime = val[1] + ' 23:59:59';
      if (this.queryForm.params.endTime) {
        const endTime = dayjs(this.queryForm.params.startTime).subtract(-6, 'day').format('YYYY-MM-DD') + ' 23:59:59';
        if (this.queryForm.params.endTime > endTime) {
          this.$message({
            // 最多可查询到7天的数据
            message: this.$t('collectionCenter.max7DaysDate'),
            type: 'warning'
          });
          const d = new Date();
          const today = dayjs(d).format('YYYY-MM-DD');
          this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
          this.queryForm.params.startTime = today + ' 00:00:00';
          this.queryForm.params.endTime = today + ' 23:59:59';
        }
      }
    },
    changeCustomerName() {
      if (!this.queryForm.params.customerId) {
        this.changeMerchant();
      }
    },
    changeMerchant() { // 切换商户号/名称 清空输入框
      this.merchantCodeValue = '';
      this.merchantCodeList = [];
      this.queryForm.params.merchantCode = '';
      this.queryForm.params.merchantName = '';
    },
    remoteMethod(value) {
      const merchantCodeList = [];
      if (this.queryForm.params.customerId) {
        if (value !== '') {
          this.loading = true;
          let merchantForm = {};
          if (this.merchantChoose === 'm1') {
            merchantForm = {
              customerId: this.queryForm.params.customerId,
              merchantCode: value,
              merchantName: ''
            };
          } else {
            merchantForm = {
              customerId: this.queryForm.params.customerId,
              merchantCode: '',
              merchantName: value
            };
          }
          setTimeout(() => {
            queryAllMerchantCode(merchantForm).then(res => {
              if (res.code===200) {
                res.data.records.forEach(list => {
                  if (list.merchantName) {
                    merchantCodeList.push({
                      name: list.merchantCode + ' / ' + list.merchantName
                    });
                  } else {
                    merchantCodeList.push({
                      name: list.merchantCode
                    });
                  }
                });
              }
            });
            this.merchantCodeList = merchantCodeList;
            this.loading = false;
          }, 200);
        } else {
          this.merchantCodeList = [];
        }
      } else {
        this.merchantCodeValue = '';
        this.$message.warning(this.$t('collectionCenter.chooseCustomerName')); // '请选择客户名称'
      }
    },
    changeValue(value) {
      value = value.split(' / ');
      this.queryForm.params.merchantCode = value[0];
      this.queryForm.params.merchantName = value[1];
    },
    cellClick(row) { // 点击票数展示明细
      this.pollVisible = true;
      this.pollListNumber = [];
      const collectListIdForm = {
        bagNumber: row.bagNumber,
        collectListId: row.id
      };
      onSiteDeliveryDetailedPage(collectListIdForm).then(res => {
        if (res.code===200) {
          const merchantCode = res.data[0].merchantCode;
          const merchantName = res.data[0].merchantName;
          if (merchantCode === null) {
            this.pollListName = '';
          } else {
            this.pollListName = merchantCode + ' / ' + merchantName;
          }
          res.data.forEach(list => {
            this.pollListNumber.push(list.scanNumber);
          });
        }
      });
    },
    afterPrint() { // 监听打印
      if (this.strId) {
        // 确认打印后调用——> 更新打印次数接口--updatePrintTimes
        const strIdList = {
          strId: this.strId
        };
        updatePrintTimes(strIdList).then(res => {
          if (res.code===200) {
            this.$message.success(this.$t('collectionCenter.tableUpdatedPrintTimes')); // '查询表已更新打印次数'
            this.getDatePage();
            this.scanTableData = [];
            setTimeout(() => {
              this.getDatePage();
            }, 2000);
          }
        });
      }
    },
    getBrazilTime() {
      getFormatServerTime().then(res => {
        if (res.code===200 && res.data) {
          const bxTime = res.data.split(' ');
          const bxt1 = bxTime[0].split('-');
          this.brazilTime = bxTime[1] + ' ' + bxt1[0] + '-' + bxt1[1] + '-' + bxt1[2];
        }
      }).catch({});
    },
    setTable() {
      this.getBrazilTime(); // 调用巴西时间
      setTimeout(() => {
        this.waitAMoment();
      }, 100);
    },
    waitAMoment() { // 回车——包裹号验证后给表单赋值
      if (!this.brazilTime) {
        this.$message({
          message: this.$t('collectionCenter.UnacquiredTime'), // '未获取时间'
          type: 'warning'
        });
        return;
      }
      const that = this;
      if (that.packageNumber) {
        // 查询客户下的包号是否存在
        that.queryCustomerPackageList = {
          packageNumber: that.packageNumber
        };
        queryCustomerPackage(that.queryCustomerPackageList).then(res => {
          if (res.code===200 || res.data.code===200) {
            // 根据id判断有无重复值
            that.isAdd = that.scanTableData.some(el => {
              if (res.data.id) {
                return el.orderId === res.data.id;
              }
            });
            if (that.isAdd && that.scanTableData.length > 0) {
              this.$refs['audioFail'].play();
              this.$message({
                message: this.$t('collectionCenter.PackageExist'), // '当前包裹号重复'
                type: 'warning'
              });
              that.packageNumber = '';
              return;
            } else {
              // 无重复
              this.$nextTick(() => {
                this.$refs.numFocusFn.focus();
              });
              this.$refs['audioSuc'].play();
              that.scanTableData.unshift({
                orderId: res.data.id,
                scanTime: this.brazilTime,
                packageNumber: that.packageNumber,
                merchantCode: res.data.merchantCode,
                merchantName: res.data.merchantName
              });
              that.collectOrderList.unshift({
                orderId: res.data.id,
                scanTime: this.brazilTime,
                scanPackageNumber: that.packageNumber,
                orderNumber: res.data.orderNumber,
                packageNumber: res.data.packageNumber,
                scanNumber: res.data.scanNumber,
                customerNumber: res.data.customerNumber,
                receiveZipcode: res.data.receiveZipcode
              });
              localStorage.setItem('scanTableData', JSON.stringify(that.scanTableData));
              localStorage.setItem('collectOrderList', JSON.stringify(that.collectOrderList));
              if (res.data.customerName) {
                localStorage.setItem('customerId', JSON.stringify(res.data.customerId));
                localStorage.setItem('customerName', JSON.stringify(res.data.customerName));
              }
              if (res.data.merchantCode) {
                localStorage.setItem('merchantCode', JSON.stringify(res.data.merchantCode));
                localStorage.setItem('merchantName', JSON.stringify(res.data.merchantName));
              }
              this.scanCourierList['nui' + this.$store.state.user.id] = {
                scanTableData: that.scanTableData,
                collectOrderList: that.collectOrderList,
                customerId: res.data.customerId,
                customerName: res.data.customerName,
                merchantCode: res.data.merchantCode,
                merchantName: res.data.merchantName
              };
              // 加密
              const a = window.btoa(JSON.stringify(this.scanCourierList));
              localStorage.setItem('scanCourier', a);

              that.packageNumber = '';
            }
          } else {
            this.$refs['audioFail'].play();
            that.packageNumber = '';
            that.$message.error(res.data.msg);
          }
        }).catch(err => {
          that.packageNumber = '';
          console.log('err', err);
          this.$refs['audioFail'].play();
        });
      }
    },
    scanDelClick(row, index) { // 扫件——删除
      this.scanTableData.splice(index, 1);
      this.collectOrderList.splice(index, 1);
      if (this.scanTableData.length <= 0) {
        this.getPackageNumber = '';
      }
      const del = {
        orderId: row.orderId || '',
        packageNumber: row.packageNumber
      };
      reidsDeleteCustomerPackage(del).then(res => {
        if (res.code===200) {
          this.$message.success(res.data);
        } else {
          this.$message.error(res.data);
        }
      }).catch((err) => {
        this.$message.error(err.data);
      });
      localStorage.setItem('scanTableData', JSON.stringify(this.scanTableData));
      localStorage.setItem('collectOrderList', JSON.stringify(this.collectOrderList));
      const customerId = JSON.parse(localStorage.getItem('customerId'));
      const customerName = JSON.parse(localStorage.getItem('customerName'));
      const merchantCode = JSON.parse(localStorage.getItem('merchantCode'));
      const merchantName = JSON.parse(localStorage.getItem('merchantName'));
      this.scanCourierList['nui' + this.$store.state.user.id] = {
        scanTableData: this.scanTableData,
        collectOrderList: this.collectOrderList,
        customerId,
        customerName,
        merchantCode,
        merchantName
      };
      // 加密
      const a = window.btoa(JSON.stringify(this.scanCourierList));
      localStorage.setItem('scanCourier', a);
    },
    // 弹框开启回调,在弹框打开后获取元素并进行截图保存操作
    openDialog() {
      const _this = this;
      // setTimeout(() => {
      const canvasElement = document.getElementsByClassName('upLoadContentBox')[0];
      html2Canvas(canvasElement).then(function(canvas) {
        const pageData = canvas.toDataURL('image/png');
        const arr = pageData.split(',');
        const binary = atob(arr[1]); // base64解码
        const mine = arr[0].match(/:(.*?);/)[1]; // 文件类型
        const array = [];
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i)); //  Unicode 编码
        }
        const blob = new Blob([new Uint8Array(array)], { type: mine });
        // 转换成file对象
        const file = new File([blob], Date.now() + '.png', { type: mine });
        uploadOos(file, 'workOrder').then(res => {
          if (res.code === 200) {
            if (res.url) {
              _this.uploadImg = res.url; // 揽收订单（含签名）图片
              _this.isGetUploadImg = true;
            } else {
              _this.isGetUploadImg = false;
              _this.uploadImg = '';
            }
          }
        });
      });
      // }, 100);
    },
    generateConfirmation() { // 生成确认单按钮
      if (this.scanTableData.length > 0) {
        this.signVisible = true;
      }
      if (this.scanTableData.length <= 0) {
        if (!this.packageNumber) {
          this.$message({
            message: this.$t('collectionCenter.inputParcelNumber'), // '请输入包裹号'
            type: 'warning'
          });
        }
      }
    },
    handleReset() {
      this.$refs['esign'].reset(); // 清空画布
    },
    handleGenerate() {
      this.$refs['esign'].generate().then(res => {
        this.uploadSignImg = res;
        this.canvasImg();
        // this.base64ToFile(res);
      }).catch((err) => { // 没有签名，点击生成图片时调用
        console.log(err);
        this.$message({
          message: this.$t('collectionCenter.unsigned'), // '未签名!'
          type: 'warning'
        });
      });
    },
    canvasImg() {
      const customerName = JSON.parse(localStorage.getItem('customerName'));
      const merchantCode = JSON.parse(localStorage.getItem('merchantCode'));
      const merchantName = JSON.parse(localStorage.getItem('merchantName'));
      const merchantCodeName = merchantCode === '' && merchantName === '' ? '' : merchantCode + '/' + merchantName;
      const printBoxPrintList = [{
        customerName,
        lanshouName: store.getters.realName,
        merchantName: merchantCodeName,
        siteName: this.lanshouSiteName,
        lanshouSum: this.scanTotal,
        lanshouTime: this.scanTableData[0].scanTime,
        uploadSignImg: this.uploadSignImg
      }];
      this.printBox.printList = printBoxPrintList;
      this.dialogUploadVisible = true;
    },
    cancelBtnClick() {
      this.dayinVisible = false;
    },
    saveCollectioniOrder() { // 点击保存，弹出生成的揽收清单号
      if (this.changeTab === 'first') {
        if (this.uploadImg && this.isGetUploadImg) {
          // 提交已扫描的物流单号
          this.signVisible = false;
          const collectOrderList = this.collectOrderList.reverse();
          const registrationScanForm = {
            userId: this.userId,
            finishSignUrl: this.uploadImg,
            collectOrderList: collectOrderList
          };
          createConfirmedList(registrationScanForm).then(res => {
            // 提交已扫描的物流单号
            if (res.code===200) {
              this.dialogUploadVisible = false;
              this.strId = res.data;
              const customerName = JSON.parse(localStorage.getItem('customerName'));
              const merchantCode = JSON.parse(localStorage.getItem('merchantCode'));
              const merchantName = JSON.parse(localStorage.getItem('merchantName'));
              const merchantCodeName = merchantCode === '' && merchantName === '' ? '' : merchantCode + '/' + merchantName;
              this.printBox.printList = [{ // 打印表单
                customerName,
                lanshouName: store.getters.realName,
                merchantName: merchantCodeName,
                siteName: this.lanshouSiteName,
                lanshouSum: this.scanTotal,
                lanshouTime: this.scanTableData[0].scanTime,
                uploadSignImg: this.uploadSignImg
              }];
              localStorage.setItem('scanTableData', '');
              localStorage.setItem('collectOrderList', '');
              this.scanTableData = [];
              this.collectOrderList = [];
              if (res.data.indexOf('CLL') !== -1) {
                //          生成的揽收清单号为：                                                                '揽收清单号'
                this.$alert(`${this.$t('collectionCenter.createReceivingListNum')}${res.data}`, this.$t('collectionCenter.receivingListNum'), {
                  confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
                  callback: action => {
                    this.$message.success(this.$t('collectionCenter.tableUpdated')); // '已生成确认单'
                    this.getDatePage();
                  }
                });
                this.isGetUploadImg = false;
              } else {
                this.$message.error(this.$t('collectionCenter.packageCollected1')); // '当前包裹已被揽收'
              }
              // 扫描数据清空处理
              const who = 'nui' + this.$store.state.user.id;
              delete this.scanCourierList[who];
              const a = window.btoa(JSON.stringify(this.scanCourierList));
              localStorage.setItem('scanCourier', a);
            } else {
              this.$message.warning(res.data.msg);
            }
          }).catch((err) => {
            this.dialogUploadVisible = false;
            this.$message.warning(err.data.msg);
            localStorage.setItem('scanTableData', '');
            localStorage.setItem('collectOrderList', '');
            this.scanTableData = [];
            this.collectOrderList = [];
          });
        } else {
          this.$message.warning(this.$t('collectionCenter.hasNotBeenObtainedPleaseTryAgain')); // '还未获取到揽收图片，请稍后再保存'
        }
      } else {
        this.dayinVisible = false;
        this.getDatePage();
        this.collectListIdList = [];
      }
    },
    defaultCondition() { // 查询表单——默认查询条件
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD 23:59:59');
      const day = dayjs(d).subtract(3, 'day').format('YYYY-MM-DD 00:00:00');
      this.timeValue = [day, today];
      this.queryForm.params = {
        startTime: day, // 开始时间
        endTime: today, // 结束时间
        siteId: '', // 站点编号
        customerId: '', // 客户编号
        listNumber: '',
        merchantCode: '',
        merchantName: '',
        collectId: '', // 揽收人
        bagNumber: ''
      };
    },
    getDatePage() { // 交件表单查询
      this.fullscreenLoading = true;
      const queryForm = JSON.parse(localStorage.getItem('scQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      doorToDoorDeliveryqueryPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableList = res.data.records;
          this.tableTotal = res.data.total;
          this.fullscreenLoading = false;
        } else {
          this.fullscreenLoading = false;
        }
      }).catch((err) => {
        console.error(err);
        this.fullscreenLoading = false;
      });
      this.strId = '';
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.summary');
          return;
        }
        if (column.label === this.$t('collectionCenter.ballot')) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('scQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      doorToDoorDeliveryqueryPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableList = res.data.records;
          this.tableTotal = res.data.total;
        }
      });
    },
    searchClick() {
      if (this.queryForm.params.startTime && this.queryForm.params.endTime) {
        this.queryForm.pageNumber = 1;
        localStorage.setItem('scQueryForm', JSON.stringify(this.queryForm));
        this.getDatePage();
      } else {
        this.$message({
          message: this.$t('collectionCenter.PleaseSelectTime'),
          type: 'warning'
        });
      }
    },
    selectGetDate(selection) {
      const collectListIdList = [];
      const selectionList = [];
      selection.forEach(list => {
        collectListIdList.push(list.id);
        selectionList.push(list);
      });
      this.collectListIdList = collectListIdList;
      // 获取id查询打印包裹明细服务
      this.selectionList = selectionList;
      let strid = [];
      const printList = [];
      selection.forEach(list => {
        strid.push(list.id);
        printList.push({
          id: list.id,
          listNumber: list.listNumber,
          customerName: list.customerName, // customerName
          lanshouName: list.userName, // userName
          lanshouSum: list.realNumber, // realNumber,
          lanshouTime: list.scanTime, // scanTime
          siteName: list.name
        });
      });
      strid = new Array(strid);
      this.strId = strid.join(',');
      this.printBox.printList = printList;
    },
    batchSelect(selection, row) {
      this.selectGetDate(selection);
    },
    batchSelectAll(selection) {
      this.selectGetDate(selection);
    },
    uploadClick() { // 打印
      this.isClick = true;
      this.selectionList = [];
      if (this.collectListIdList.length > 0) {
        const collectListId = {
          collectListIdList: this.collectListIdList
        };
        queryPrintDetailed(collectListId).then(res => {
          if (res.code===200) {
            this.isClick = false;
            for (let i = 0; i < this.printBox.printList.length; i++) {
              var data = {};
              for (let j = 0; j < res.data.length; j++) {
                if (this.printBox.printList[i].id === res.data[j].collectListId) {
                  data = res.data[j];
                  break;
                }
              }
              this.printBox.printList[i].merchantName = data.merchantName;
              this.printBox.printList[i].uploadSignImg = data.finishSignUrl;
              this.printBox.printList[i].packageNumberList = data.collectOrderVOList;
            }

            this.$refs.queryTableRef.clearSelection();
            // 弹出预览打印
            this.dayinVisible = true;
            this.isPrint = true;
          }
        }).catch(() => {
          this.isClick = false;
        });
      } else {
        this.$message.warning(this.$t('collectionCenter.checkMsg')); // '请勾选数据'
      }
    },
    loadImg(index) { // 图片加载完成功后执行
      this.loadIndex.push(index);
      if (this.loadIndex.length === this.printBox.printList.length) {
        this.isPrintOK = true;
        this.loadIndex = [];
      } else {
        this.isPrintOK = false;
      }
    },
    printBtnClick() { // 表单多选——打印
      if (this.isPrintOK) {
        this.isPrint = false;
        this.dayinVisible = false;
        this.dialogUploadVisible = false;
        this.afterPrint();
        // 打印
        this.toPrint();
        setTimeout(() => {
          this.getDatePage();
        }, 2000);
      } else {
        this.$message.warning(this.$t('collectionCenter.loading')); // 正在努力加载中...
      }
    },
    toPrint() { // 新打开一个空窗口进行打印
      const newWin = window.open();
      // 客户上门交件
      const styleSheet = `<style>
        body{
          -webkit-print-color-adjust: exact;
        }
        .signatureBox{
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .dayinBox{
          display: flex;
          justify-content: center;
          color: #000;
          margin: 5px 0;
        }
        .dayinBox .dayinCenter{
          width: 100%;
          border: 2px solid #000;
          border-top: none;
          box-sizing: border-box;
        }
        .tableClass{
          display: inline-block;
          width: 50%;
        }
        .tableClass .pgTableRow{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid #000;
          box-sizing: border-box;
        }
        .tableClass .pgTableRow .one{
          width: 12%;
        }
        .tableClass .pgTableRow .two{
          width: 45%;
          min-height: 50px;
          border-left: 2px solid #000;
          border-right: 2px solid #000;
          box-sizing: border-box;
          display: inline-block !important;
          word-break: break-all;
        }
        .tableClass .pgTableRow .three{
          width: 43%;
          min-height: 50px;
          border-right: 2px solid #000;
          box-sizing: border-box;
        }
        .tableClass:nth-child(odd) .pgTableRow .three{
          border-right: 2px solid #000;
          box-sizing: border-box;
        }
        .tableClass:nth-child(even) .pgTableRow .three {
          border-right:none;
        }
        .tableClass .pgTableRow .one, .tableClass .pgTableRow .two, .tableClass .pgTableRow .three{
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          word-break: break-all;
        }
        .pgTableRowContent{
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .pgTableRowTitle{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-bottom: 2px solid #000;
          box-sizing: border-box;
        }
        .pgTableRowTitle .pgTableRow{
          width: 50%;
          height: 50px;
          display: flex;
          justify-content: space-between;
        }
        .pgTableRowTitle .pgTableRow .one{
          width: 12%;
        }
        .pgTableRowTitle .pgTableRow .two{
          width: 45%;
          border-left: 2px solid #000;
          border-right: 2px solid #000;
          box-sizing: border-box;
        }
        .pgTableRowTitle .pgTableRow .three{
          width: 43%;
          border-right: 2px solid #000;
          box-sizing: border-box;
        }
        .pgTableRowTitle .pgTableRow:nth-child(odd) .three{
          border-right: 2px solid #000;
          box-sizing: border-box;
        }
        .pgTableRowTitle .pgTableRow:nth-child(even) .three {
          border-right:none;
        }
        .pgTableRowTitle .pgTableRow .one, .pgTableRowTitle .pgTableRow .two, .pgTableRowTitle .pgTableRow .three{
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          word-wrap:break-word;
        }
        .rowTopClass{
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid #000;
          box-sizing: border-box;
          padding: 5px;
        }
        .rowTopClass .l, .rowTopClass .r{
          width: 50%;
          font-size: 21px;
        }
        .rowTopClass .l{
          padding-left: 10px;
        }
        .rowTopClass .r{
          text-align: right;
        }
        .rowClass{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-bottom: 2px solid #000;
          box-sizing: border-box;
        }
        .rowClass .l, .rowClass .r{
          width: 50%;
          font-size: 21px;
          min-height: 50px;
          display: flex;
          align-items: center;
        }
        .rowClass .l{
          padding-left: 10px;
          box-sizing: border-box;
        }
        .rowClass .r{
          padding: 5px;
          border-left: 2px solid #000;
          box-sizing: border-box;
          justify-content: center;
        }
        .bigSize.r{
          font-size: 26px;
        }
        .titleTop{
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #a8a9ad;
          font-size: 23px;
          border-bottom: 2px solid #000;
          box-sizing: border-box;
        }
        .packageMsg{
          border-top: 2px solid #000;
          box-sizing: border-box;
        }
      </style>`;
      const printTop = `<html><head><title> ${this.$t('collectionCenter.customerHandDoor')}</title> ${styleSheet} </head><body><div>`;
      const printBottom = `
                      </div></body>
                      </html>`;
      const printBox = [];
      let printContent = [];

      // 获取id查询打印包裹明细服务
      const collectListId = {
        collectListIdList: this.collectListIdList
      };
      queryPrintDetailed(collectListId).then(res => {
        if (res.code===200) {
          this.collectListIdList = [];
          for (let i = 0; i < this.printBox.printList.length; i++) {
            printBox[i] = this.printBox.printList[i];
            var data = {};
            for (let j = 0; j < res.data.length; j++) {
              if (printBox[i].id === res.data[j].collectListId) {
                data = res.data[j];
                break;
              }
            }
            let pgHTML = ''; // 包裹号列
            let pgSupplement = ''; // 补空缺row
            let forList = ''; // 包裹页
            const result = [];
            const PrintPgLen = printBox[i].packageNumberList;
            for (let y = 0; y < PrintPgLen.length; y += 30) {
              result.push(PrintPgLen.slice(y, y + 30));
            }
            // 得到每32个一组的数据
            let index = 0;
            for (let t = 0; t < result.length; t++) {
              for (let get = 0; get < result[t].length; get++) {
                index++;
                pgHTML += `
                  <div class="tableClass">
                    <div class="pgTableRow">
                      <span class="one">${index}</span>
                      <span class="two">${result[t][get].scanNumber}</span>
                      <span class="three">${result[t][get].scanTime}</span>
                    </div>
                  </div>
                `;
              }
              pgSupplement = `
                <div class="tableClass" style="float: right;">
                  <div class="pgTableRow">
                    <span class="one"></span>
                    <span class="two"></span>
                    <span class="three"></span>
                  </div>
                </div>
              `;
              if (result[t].length % 2 !== 0) {
                pgHTML = pgHTML + pgSupplement;
              }
              forList +=
                `
                  <div class="dayinBox" style="page-break-after:always">
                    <div class="dayinCenter">
                      <div>
                        <div class="rowTopClass" style="border-top:2px solid #000;border-bottom: none;box-sizing: border-box;">
                          <div class="l">${this.$t('collectionCenter.LSSite')}${printBox[i].siteName}</div>
                          <div class="r">
                            <img src="/anjunLogo.png" alt="">
                          </div>
                        </div>
                      </div>
                      <div class="titleTop packageMsg" style="border-left: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;">
                        <p>${this.$t('collectionCenter.pgMsg')}</p>
                      </div>
                      <div class="">
                        <div class="pgTableRowTitle">
                          <div class="pgTableRow">
                            <span class="one">${this.$t('collectionCenter.ind')}</span>
                            <span class="two">${this.$t('collectionCenter.pgBarCode')}</span>
                            <span class="three">${this.$t('collectionCenter.scanTime')}</span>
                          </div>
                          <div class="pgTableRow">
                            <span class="one">${this.$t('collectionCenter.ind')}</span>
                            <span class="two">${this.$t('collectionCenter.pgBarCode')}</span>
                            <span class="three">${this.$t('collectionCenter.scanTime')}</span>
                          </div>
                        </div>
                        <div style="display: flex;justify-content: flex-start;flex-wrap:wrap;">
                          ${pgHTML}
                        </div>
                        <div style="text-align: center;height: 37px;line-height: 43px;font-size: 24px;display: flex;justify-content: flex-start;align-items: center;">
                          <span style="display: inline-block;width: 50%;border-right: 2px solid #000;box-sizing: border-box;" style="margin-right: -1px;">
                            ${this.$t('collectionCenter.ParcelSubtotal')}${result[t].length}
                          </span>
                          <span style="display: inline-block;width: 50%;">${this.$t('collectionCenter.pgTotal')}${printBox[i].lanshouSum}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                `
              ;
              pgHTML = '';
            }
            printContent.push(
              `
              <div class="upLoadContent">

                <div>
                  <div class="signatureBox" style="page-break-after:always;">
                    <img
                      style="width: 100%;height: 100%;"
                      src="${data.finishSignUrl || ''}"
                      fit="contain"
                    >
                  </div>
                  ${forList}
                </div>
              </div>
            </div>
            `
            );
          }
          printContent = printContent.toString().replace(/,/g, '');
          const imageToPrint = printTop + printContent + printBottom; // 获取需要打印的内容
          newWin.document.write(imageToPrint); // 将需要打印的内容添加进新的窗口
          newWin.document.close(); // 在IE浏览器中使用必须添加这一句
          newWin.focus(); // 在IE浏览器中使用必须添加这一句
          setTimeout(function() {
            newWin.print(); // 打印
            newWin.close(); // 关闭窗口
          }, 1000);
        } else {
          this.collectListIdList = [];
        }
      });
    },
    resetClick() {
      this.defaultCondition();
      this.merchantChoose = 'm2';
      this.changeMerchant();
    },
    downloadLogistics() {
      this.isClickAll = true;
      this.collectListIdList = [];
      if (this.selectionList.length <= 0) {
        this.$message({
          message: this.$t('collectionCenter.checkExportList'), // '请选择需要导出的数据'
          type: 'warning'
        });
        return;
      }
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        Sheet1.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '009944' }};
        Sheet1.getRow(1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        Sheet1.getRow(1).height = 30;
        Sheet1.getRow(1).font = { bold: true, color: { argb: '000000' }, sz: 30 };
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.views = [
          { state: 'frozen', xSplit: undefined, ySplit: 1 }
        ];
        let Sheet2_data = [];
        Sheet1.columns = [
          {
            header: that.$t('collectionCenter.receivingListNum'), // '揽收清单号'
            key: 'listNumber',
            width: 23
          },
          {
            header: that.$t('collectionCenter.clientID'), // '客户编号'
            key: 'customerId',
            width: 10
          },
          {
            header: that.$t('collectionCenter.MerchantNum'), // '商户号'
            key: 'merchantCode',
            width: 10
          },
          {
            header: that.$t('collectionCenter.BagNumber'), // '袋号'
            key: 'bagNumber',
            width: 10
          },
          {
            header: that.$t('collectionCenter.ballot'), // '票数'
            key: 'realNumber',
            width: 10
          },
          {
            header: that.$t('collectionCenter.siteNum'), // '网点编号'
            key: 'code',
            width: 15
          },
          {
            header: that.$t('collectionCenter.siteName'), // '网点名称'
            key: 'name',
            width: 20
          },
          {
            header: that.$t('collectionCenter.LanShouPeople'), // '揽收人'
            key: 'userName',
            width: 10
          },
          {
            header: that.$t('collectionCenter.lanshouT'), // '揽收时间'
            key: 'scanTime',
            width: 20
          },
          {
            header: that.$t('collectionCenter.printNumber'), // '打印次数'
            key: 'printCount',
            width: 10
          },
          {
            header: that.$t('collectionCenter.printTime'), // '打印时间'
            key: 'printTime',
            width: 20
          }
        ];
        Sheet2_data = [];
        that.selectionList.map((item) => {
          Sheet2_data.push({
            listNumber: item.listNumber,
            customerId: item.customerId,
            merchantCode: item.merchantCode,
            bagNumber: item.bagNumber,
            realNumber: item.realNumber,
            code: item.code,
            name: item.name,
            userName: item.userName,
            scanTime: item.scanTime,
            printCount: item.printCount,
            printTime: item.printTime
          });
        });
        Sheet1.addRows(Sheet2_data);
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });

        const saveName = that.$t('collectionCenter.doorToDoorDeliveryInquiryForm') + '.xlsx'; // '上门交件查询表.xlsx'
        saveAs(blob, saveName);

        // eslint-disable-next-line require-atomic-updates
        that.isClickAll = false;
        return;
      }
      ddd();
      this.selectionList = [];
      this.$refs.queryTableRef.clearSelection();
    }
  }
};
</script>

<style lang="scss" scoped>

  .BlockNewlineClass ::v-deep textarea.el-textarea__inner {
    min-height: 40px !important;
    max-height: 120px !important;
  }
  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce2{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce2:active{
    filter: opacity(40%);
    animation: none;
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }
.autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
  position: relative;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  padding: 0 25px;
  padding-left: 0;
  margin-left: 20px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  visibility: hidden;
  white-space: nowrap;
}
.autoWidth ::v-deep input {
  position: absolute;
}
.selectClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selectClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.selectClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
}

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce:active{
    filter: opacity(40%);
    animation: none;
  }
  .block{
    width: 100%;
  }
  .ScanCourierBox .block ::v-deep .el-input{
    width: 100%;
  }
  .dayinBox{
    display: flex;
    justify-content: center;
    color: #000;
    margin: 5px 0;
    .dayinCenter{
      width: 900px;
      border: 1px solid #000;
      border-top: none;
      box-sizing: border-box;
    }
  }

  .dialogUpload ::v-deep .el-dialog__body{
    padding: 20px !important;
  }
  .dialogUpload ::v-deep .el-dialog__header{
    display: none;
  }
  .tableClass{
    display: inline-block;
    width: 50%;
    .pgTableRow{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #000;
      box-sizing: border-box;
      .one{
        width: 10%;
      }
      .two{
        width: 45%;
        min-height: 25px;
        border-left: 1px solid #000;
        border-right: 1px solid #000;
        box-sizing: border-box;
      }
      .three{
        width: 45%;
        min-height: 25px;
        border-right: 1px solid #000;
        box-sizing: border-box;
        margin-right: -1px;
      }
      .one, .two, .three{
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .pgTableRowContent{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .pgTableRowTitle{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
    .pgTableRow{
      width: 50%;
      height: 25px;
      display: flex;
      justify-content: space-between;
      .one{
        width: 10%;
      }
      .two{
        width: 45%;
        border-left: 1px solid #000;
        border-right: 1px solid #000;
        box-sizing: border-box;
      }
      .three{
        width: 45%;
        border-right: 1px solid #000;
        box-sizing: border-box;
        margin-right: -1px;
      }
      .one, .two, .three{
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .blackBoxLine{
    border: 1px solid #000;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 100px;
  }
  .rowTopClass{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
    padding: 5px;
    .l, .r{
      width: 50%;
      font-size: 21px;
    }
    .l{
      padding-left: 10px;
    }
    .r{
      text-align: right;
      float: right;
    }
  }
  .rowClass{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
    .l, .r{
      width: 50%;
      font-size: 21px;
      min-height: 40px;
      display: flex;
      align-items: center;
    }
    .l{
      padding-left: 10px;
      box-sizing: border-box;
    }
    .r{
      padding: 5px;
      border-left: 1px solid #000;
      box-sizing: border-box;
      justify-content: center;
    }
    .bigSize.r{
      font-size: 26px;
    }
  }
  .titleTop{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #a8a9ad;
    font-size: 23px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }
  .packageMsg{
    border-top: 1px solid #000;
    box-sizing: border-box;
  }
  .audioClass{
    opacity: 0;
  }
  .signMask{
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100% - 215px);
    height: 100%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);
    z-index: 99;
  }
  .signClose{
    position: absolute;
    top: 25px;
    right: 40px;
    transform: scale(1.3);
  }
  .signVisibleClass{
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    height:100%;
    background:#fff;
    padding:20px 0;
    // overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .signVisibleClass ::v-deep .el-drawer__header{
    margin-bottom: 0 !important;
  }
  .signVisibleClass ::v-deep .el-drawer__header>:first-child{
    // display: none;
    opacity: 0;
  }
  .signVisibleClass ::v-deep .el-drawer__open .el-drawer.btt{
    height: 50% !important;
  }
  .page-content{
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    max-height: 644px;
  }
  .content {
    border: 1px solid #dadada;
    box-sizing: border-box;
    overflow: hidden;
  }
  .flex {
    margin: 10px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
  .flex ::v-deep .el-button{
    height: 40px;
    margin: 20px;
    display: inline-block;
  }
.selectTimeClass{
  // width: 135px !important;
  max-width: 110px;
}
  .ScanCourierBox .selectTimeClass ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
  }
  .ScanCourierBox .selectTimeClass ::v-deep.el-input--suffix{
    text-align: right;
  }
  .pollTop{
    font-size: 16px;
    font-weight: bold;
    // width: 60%;
    text-align: center;
    margin: 0 auto 20px;
  }
  .pollBox{
    width: 60%;
    height: 400px;
    margin: 0 auto;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 13px;
    padding-left: 0;
  }
  //如果不想显示滚动条
  .pollBox::-webkit-scrollbar {
    // width: 0;
  }
  .pollContent{
    font-size: 16px;
    margin: 10px 0;
    padding: 5px;
    border: 1px dotted #cdcdcd;
    position: relative;
    margin-left: 30px;
    .pollIndex{
      position: absolute;
      left: -20px;
    }
  }
  .printBtnClass{
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 30px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    height: 100px;
  }
  .upLoadContentBox{
    // padding: 50px;
    width: 900px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  .dayinContentBox{
    margin-bottom: 100px;
    .dayinImg{
      // display: flex;
      // justify-content: center;
      // align-items: center;
      margin-bottom: 100px;
      .dyImg{
        text-align: center;
      }
    }
  }
  .upLoadContent{
    width: 100%;
    font-size: 17px;
    font-weight: 400;
    color: #000;
    margin-bottom: 100px;
  }
  .packageNumberListClass{
    min-height: 100px;
    font-size: 32px;
  }
  .signatureBox{
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ScanCourierBox .scanIcon ::v-deep .el-input__inner{
    text-indent: 40px;
  }
  .ScanCourierBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .searchInput{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: 30px;
  }
  .scan.searchInput{
    margin-top: 0px;
  }
  .searchInputP{
    width: 120px;
    text-align: right;
    font-size: 16px;
  }
  .iconBtn{
    position: absolute;
    left: 110px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 25px;
    color: #bbb;
  }
  .scanTable{
    margin: 30px auto;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .ScanSumber{
    text-align: center;
    span{
      color: #e13047;
      font-size: 40px;
      font-weight: bold;
    }
  }
  .ScanCourierBox .dialogBox  ::v-deep .el-table .cell{
    text-align: center;
  }
  .ScanCourierBox .dialogBox  ::v-deep .el-dialog__title{
    line-height: 16px;
    font-size: 15px;
  }
  .ScanCourierBox .dialogBox  ::v-deep .el-dialog__body{
    padding: 0 20px 30px 20px;
  }
  .ScanCourierBox .searchInput ::v-deep .el-select .el-input {
    width: 120px;
  }
  .ScanCourierBox .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .ScanCourierBox .searchInput .iconBtn::after{
    position: absolute;
    content: "-";
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%) scaleX(2.3);
  }
  .ScanCourierBox .printBtnClass ::v-deep .el-button--success, .ScanCourierBox .printBtnClass ::v-deep .el-button--default{
    width: 100px;
  }

  .ScanCourierBox .pollVisible ::v-deep .el-dialog{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0 !important;
  }
  .ScanCourierBox .col10{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ScanCourierBox .col10 ::v-deep .el-select{
    width: 100%;
  }
  .ScanCourierBox .m{
    width: 100%;
  }
  .ScanCourierBox .m ::v-deep .el-form-item__content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px !important;
  }
  .ScanCourierBox .m ::v-deep .el-form-item__content .selectTimeClass{
    width: 180px !important;
  }
</style>
