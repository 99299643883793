var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticStyle: { height: "calc(100% - 55px)", "overflow-y": "scroll" } },
      [
        [3].includes(_vm.claimsStatus)
          ? _c(
              "el-card",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "but:compensationManagement:compensationList:obligation",
                        expression:
                          "'but:compensationManagement:compensationList:obligation'",
                        arg: "remove",
                      },
                    ],
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: { type: "success", size: "small" },
                    on: { click: _vm.SubmitPayment },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          this.$t("compensationManagement.SubmitPayment")
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "but:compensationManagement:compensationList:CancelPayment",
                        expression:
                          "'but:compensationManagement:compensationList:CancelPayment'",
                        arg: "remove",
                      },
                    ],
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: { type: "danger", size: "small" },
                    on: { click: _vm.CancelPayment },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          this.$t("compensationManagement.CancelPayment")
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 3,
                    maxlength: "2000",
                    "show-word-limit": "",
                    placeholder: this.$t("GlobalSetObj.pleaseInputContent"),
                  },
                  model: {
                    value: _vm.mark,
                    callback: function ($$v) {
                      _vm.mark = $$v
                    },
                    expression: "mark",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        [1].includes(_vm.claimsStatus) && _vm.reviewer === _vm.userId
          ? _c(
              "el-card",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "but:compensationManagement:compensationList:process",
                        expression:
                          "'but:compensationManagement:compensationList:process'",
                        arg: "remove",
                      },
                    ],
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: { type: "success", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toExamine(1)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(this.$t("compensationManagement.Pass")) + " "
                    ),
                  ]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "but:compensationManagement:compensationList:process",
                        expression:
                          "'but:compensationManagement:compensationList:process'",
                        arg: "remove",
                      },
                    ],
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: { type: "danger", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toExamine(2)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(this.$t("compensationManagement.TurnDown")) +
                        " "
                    ),
                  ]
                ),
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 3,
                    maxlength: "2000",
                    "show-word-limit": "",
                    placeholder: this.$t("GlobalSetObj.pleaseInputContent"),
                  },
                  model: {
                    value: _vm.rejectionReasons,
                    callback: function ($$v) {
                      _vm.rejectionReasons = $$v
                    },
                    expression: "rejectionReasons",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "el-card",
          [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("newOrder.BasicInformation"))),
                ]),
              ]
            ),
            _c("BasicForm", {
              key: _vm.updateKey,
              ref: "basicForm",
              attrs: { id: _vm.id, status: _vm.claimsStatus },
              on: { change: _vm.updateForm, removeChange: _vm.remove },
            }),
          ],
          1
        ),
        _c(
          "el-card",
          [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("ClaimsManagement.LinkedOrder"))),
                ]),
              ]
            ),
            _c("BasicTable", {
              key: _vm.updateKey,
              ref: "basicTable",
              attrs: {
                id: _vm.id,
                status: _vm.claimsStatus,
                "form-data": _vm.formList,
                "data-list": _vm.claimRelatedOrdersList,
              },
              on: { close: _vm.onClose, change: _vm.clickUpdate },
            }),
          ],
          1
        ),
        _c(
          "el-card",
          [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("compensationManagement.ClaimSettlementProcess")
                    )
                  ),
                ]),
              ]
            ),
            _c("NodeTable", {
              key: _vm.updateKey,
              ref: "nodeTable",
              attrs: { "data-list": _vm.claimFlows },
            }),
          ],
          1
        ),
      ],
      1
    ),
    ![4, 5].includes(_vm.claimsStatus)
      ? _c(
          "div",
          { staticClass: "footer" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _vm._l(_vm.btnList, function (item, index) {
                  return [
                    item.show
                      ? _c(
                          "el-button",
                          {
                            key: index,
                            attrs: { size: "small", type: item.type },
                            on: {
                              click: function ($event) {
                                return _vm.btnClick(item.btnType)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }