import request from '@/utils/request';

const baseUserURL = process.env.VUE_APP_USER;

/**
 * 客户新增
 * @param data
 * @returns
 */
export const apiCustomerAdd = (data) => {
  return request.post('/customer/add', data);
};

/**
 * 客户 账户名校验
 * @param data
 * @returns
 */
export const apiCustomerCheck = (data) => {
  return request({
    url: '/customer/check',
    method: 'get',
    params: { ...data }
  });
};

/**
 * 客户修改
 * @param data
 * @returns
 */
export const apiCustomerEdit = (data) => {
  // eslint-disable-next-line no-undef
  return request.put(`/customer/${data.id}`, data);
};

// 客户修改FTP
export const apiCustomerEditFtp = (data) => {
  return request.put('customer/updateFtpInfo/' + data.id, data);
};

export const apiCustomerEditOther = (data) => {
  return request.put('customer/addOrUpdateOtherInfo', data);
};

/**
 * 客户列表
 */
export const apiCustomerPage = (data, isBool = true) => {
  // return request.post('/user/customer/page', data);
  return request({
    method: 'POST',
    data,
    url: `/customer/page`,
    isLoading: isBool // 是否显示加载中参数
  });
};
/**
 * 客户列表 ==> dxk
 */
export const CustomerPage = (data) => {
  return request.post('/customer/customerPage', data);
};
// 获取所有客户
export const apiCustomerList = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/user/customer/list`,
    isLoading: isBool // 是否显示加载中参数
  });
  // return request.post('/user/customer/list', data);
};

/**
 * 根据客户id查询客户详细信息
 */
export const apiCustomerFindById = (ids) => {
  // return request.post('/user/customer/findById', data);
  return request({
    url: `/customer/findById/${ids}`,
    method: 'get'
  });
};

/**
 * 状态控制
 */
export const apiCustomerStateHandler = (data) => {
  return request.post('/customer/stateHandler', data);
};

// 获取所有客户渠道
export const apiCustomerChannellist = (data, isBool = true) => {
  return request({
    method: 'post',
    data,
    url: `/logistics/customerChannel/list`,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 获取当前客户的所有客户渠道
export const apiListByCustomerId = (ids, isBool = true) => {
  return request({
    method: 'get',
    url: `/customerChannelConfig/listByCustomerId/${ids}`,
    isLoading: isBool // 是否显示加载中参数
  });
  // return request.get(`user/customerChannelConfig/listByCustomerId/${ids}`);
};

// 保存客户渠道配置
export const apiCustomerChannelConfigAdd = (data) => {
  return request.post('/user/customerChannelConfig/add', data);
};

// 更新客户渠道配置
export const apiCustomerChannelConfigEdit = (data) => {
  return request.post('/user/customerChannelConfig/edit', data);
};

// 获取客户跟进信息
export const apiCustomerFollow = (ids) => {
  return request.get(`customerFollow/listByCustomerId/${ids}`);
};

// 新增客户跟进说明
export const apiCustomerFollowAdd = (data) => {
  return request.post('/user/customerFollow/add', data);
};

// 新增客户跟进说明
export const apiCustomerFollowDel = (ids) => {
  return request.put(`/user/customerFollow/delete/${ids}`);
};

// 获取授权码
export const apiGetAuthorizationCode = (ids) => {
  return request.get(`/customer/getAuthorizationCode/${ids}`);
};

/**
 * 重置密码
 */
export const apiResetPassword = (data) => {
  return request.post('/customer/resetPassword', data);
};

// 获取寄件人列表
// export const apiGetSendList = (data, isBool = true) => {
//   return request({
//     method: 'post',
//     data,
//     url: `/logistics/addressBook/getList`,
//     isLoading: isBool // 是否显示加载中参数
//   });
// };
// 充值余额
export const billRecharge = (data) => {
  return request.post('/fa/bill/recharge?amount=' + data.amount + '&customerId=' + data.customerId);
};
// 修改信用额度
export const changeCreditLimit = (data) => {
  return request.post(
    '/fa/bill/changeCreditLimit?creditLimit=' + data.creditLimit + '&customerId=' + data.customerId
  );
};
export const queryAllUser = (data) => {
  //  return request.post(`/user/queryAllUserPage`, data);
  return request({
    method: 'post',
    data,
    url: '/queryAllUserPage',
    baseURL: baseUserURL
  });
};
// 客户时效
export const localAgingGetConfigPage = (data) => {
  return request.post(`/localAging/getConfigPage`, data);
};
// 客户时效
export const localAgingupdateStatus = (data) => {
  return request.post(`/localAging/updateStatus`, data);
};
// 客户时效
export const localAgingdownload = (id) => {
  return request.get(`/localAging/download/${id}`);
};
// 新增客户时效
export const localAgingAdd = (data) => {
  return request({
    method: 'POST',
    data,
    url: `/localAging/add`,
    headers: {
      'Content-Type': 'multipart/form-data' // 设置 Content-Type
    }
  });
};
// 客户时效
export const localAgingdownloadmodule = () => {
  return request({
    method: 'get',
    url: `/localAging/download/module`,
    responseType: 'blob'
  });
};
// 客户时效
export const timelinessdownloadmodule = () => {
  return request({
    method: 'get',
    url: `/timeliness/transport/download/module`,
    responseType: 'blob'
  });
};
// 客户时效
export const localproblemList = (data) => {
  return request.post(`/localCustomer/problem/pageList`, data);
};
// 查询问题件原因
export const getproblemList = () => {
  return request.get(`/localCustomer/problem/problemType/list?keyword=${''}`);
};
// 查询本地客户数据
export const getproblemcustomerList = () => {
  return request.get(`/localCustomer/problem/customer/list?keyword=${''}`);
};
// 查询本地客户数据  释放问题件拦截
export const liftInterceptType = (data) => {
  return request.post(`/packageWhetherIntercept/liftInterceptType`, data);
};
// 查询本地客户数据  释放10天限制
export const releaseTenDaysIntercept = (data) => {
  return request.post(`/packageWhetherIntercept/releaseTenDaysIntercept`, data);
};
// 修改客户时效
export const localAgingEdit = (data) => {
  return request({
    method: 'POST',
    data,
    url: `/localAging/modify`,
    headers: {
      'Content-Type': 'multipart/form-data' // 设置 Content-Type
    }
  });
};
// 商户管理
export const getMerchantByCustomerId = (customerId) => {
  return request.get(`/customerMerchant/getMerchantByCustomerId/${customerId}`);
};
export const apiCustomerPageList = (val, isBool = true) => {
  return request({
    method: 'get',
    url: `/customerMerchant/getMerchantList?merchantName=${val}`,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 配合远程搜索
export const getMerchantByCustomerIdSearch = (customerId, val) => {
  return request.get(`/customerMerchant/getMerchantByCustomerId/${customerId}?merchantName=${val}`);
};
export const apiCustomerPageList01 = (customerId, val, isBool = true) => {
  return request({
    method: 'get',
    url: `/customerMerchant/getMerchantAgingList?customerId=${customerId}&merchantName=${val}`,
    isLoading: isBool // 是否显示加载中参数
  });
};
export const getMerchantByCustomerId01 = (customerId, val) => {
  return request.get(
    `/customerMerchant/getMerchantAgingList?customerId=${customerId}&merchantName=${val}`
  );
};
// 获取商户名称
export const apimerchantName = (val, isBool = true) => {
  return request({
    method: 'get',
    url: `/customerMerchant/getMerchantNameList?merchantName=${val}`,
    isLoading: isBool // 是否显示加载中参数
  });
};
