<!-- 快录添加费用 -->
<template>
  <div style="padding: 0 10px;">
    <div class="addTable">
      <el-card class="box-card">
        <el-form
          ref="basicInformation" :model="addBatchFee.basicInformation" label-width="auto" size="small"
          label-position="top"
        >
          <el-row :gutter="20">
            <el-col :span="8">
              <!--业务单号-->
              <el-form-item
                :label="$t('receivablePayable.TicketNumber') + '：'"
                prop="waybillNumber"
                :rules="[
                  { required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] },
                  { max: 20, message: $t('basicData.LengthCantExceed20'), trigger: ['blur', 'change'] }
                ]"
              >
                <div style="display:flex;">
                  <el-input v-model="addBatchFee.basicInformation.waybillNumber" :disabled="!!queryFeeParam.id" >
                    <template slot="prepend">QR</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!--客户-->
              <el-form-item
                :label="$t('receivablePayable.customer') + '：'"
                prop="customerId"
                :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <el-select
                  v-model="addBatchFee.basicInformation.customerId"
                  filterable
                  clearable
                  :placeholder="$t('spareNumberPoo.pleaseChoose')"
                  style="width: 100%;"
                  :disabled="!!queryFeeParam.id"
                  @change="changeCustomer"
                >
                  <el-option
                    v-for="(item, ind) in customerList"
                    :key="ind"
                    :label="item.customerAlias + '(' + item.username + ')'"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!--应付主体-->
              <el-form-item :label="$t('basicData.PayablePrincipal') + '：'">
                <!-- <div>{{ providerName }}</div> -->
                <el-input v-model="addBatchFee.basicInformation.providerName" :disabled="true" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <!--网点-->
              <el-form-item
                :label="$t('userManage.site') + '：'"
                prop="siteId"
                :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <QuerySiteV2Cmp
                  style="width: 100%;"
                  provider-id=""
                  :disabled="!!queryFeeParam.id"
                  :val="addBatchFee.basicInformation.siteId"
                  @change="val => addBatchFee.basicInformation.siteId = val"
                  @row="changeOutlets"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!--计费重量-->
              <el-form-item
                :label="$t('receivablePayable.BillableWeight') + '：'"
                prop="chargeWeight"
                :rules="{ required: true, suffix: 3, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
              >
                <el-input v-model="addBatchFee.basicInformation.chargeWeight" :disabled="!!queryFeeParam.id" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!--揽收类型-->
              <el-form-item
                v-if="[1, 2].includes(payableType)"
                :label="$t('newOrder.CollectionType') + '：'"
                prop="collectType"
                :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <el-select
                  v-model="addBatchFee.basicInformation.collectType"
                  :placeholder="$t('basicData.PleaseSelect')"
                  style="width: 100%;"
                  :disabled="!!queryFeeParam.id"
                >
                  <!--上门交件-->
                  <el-option :key="1" :label="$t('collectionCenter.dropOff')" :value="1" />
                  <!--上门揽收-->
                  <el-option :key="2" :label="$t('collectionCenter.doorPickUp')" :value="2" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <!--备注-->
              <el-form-item
                :label="$t('receivablePayable.remark') + '：'"
                prop="remark"
                :rules="{ max: 200, message: $t('basicData.LengthCantExceed200'), trigger: ['blur', 'change'] }"
              >
                <el-input
                  v-model="addBatchFee.basicInformation.remark" :rows="4" type="textarea"
                  :disabled="!!queryFeeParam.id"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>

      <!--费用明细-->
      <el-card>
        <div slot="header" class="clearfix">
          <span class="headerStyle">{{ $t('receivablePayable.FeeBreakdown') }}</span>
        </div>
        <el-form ref="addBatchFee" :model="addBatchFee" label-width="0" size="small">
          <el-table :data="addBatchFee.expenseSubList" height="300" border class="CostDetails">
            <el-table-column width="55" align="center">
              <template slot="header">
                <span class="el-icon-plus addStyle" @click="addCost" />
              </template>
              <template slot-scope="scope">
                <!--确定删除吗？-->
                <el-popconfirm
                  :title="$t('basicData.AreYouSureDelete')"
                  @confirm="deleteCost(scope)"
                >
                  <span slot="reference" class="el-icon-delete deleteStyle" />
                </el-popconfirm>
              </template>
            </el-table-column>
            <!--费用类型-->
            <el-table-column
              prop="feeName"
              :label="$t('basicData.ExpenseType')"
              width="180"
            >
              <template slot="header">
                <div>
                  <span style="color: #f00;margin-right: 5px;">*</span>
                  <span>{{ $t('basicData.ExpenseType') }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div class="formStyle">
                  <div v-if="!scope.row.isEdit">{{ scope.row.feeName }}</div>
                  <el-form-item
                    v-else
                    :prop="'expenseSubList.' + scope.$index + '.feeCode'"
                    :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                  >
                    <el-select
                      v-model="scope.row.feeCode"
                      filterable
                      clearable
                      :placeholder="$t('operationCenter.PleaseSelect')"
                      style="width: 100%;"
                      @change="feeTypeChange(scope)"
                    >
                      <el-option
                        v-for="item in feeTypeList"
                        :key="item.id"
                        :label="item.displayName"
                        :value="item.code"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!--应付金额-->
            <el-table-column
              prop="fee"
              :label="$t('receivablePayable.AmountDue')"
              width="160"
            >
              <template slot="header">
                <div>
                  <span style="color: #f00;margin-right: 5px;">*</span>
                  <span>{{ $t('receivablePayable.AmountDue') }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div class="formStyle">
                  <div v-if="!scope.row.isEdit">{{ scope.row.fee }}</div>
                  <el-form-item
                    v-else
                    :prop="'expenseSubList.' + scope.$index + '.fee'"
                    :rules="{ required: true, suffix: 2, negative: '-?', validator: priceType, trigger: ['blur', 'change'] }"
                  >
                    <el-input v-model="scope.row.fee" />
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!--业务时间-->
            <el-table-column
              prop="operateTime"
              :label="$t('basicData.BusinessTime')"
              width="220"
            >
              <template slot="header">
                <div>
                  <span style="color: #f00;margin-right: 5px;">*</span>
                  <span>{{ $t('basicData.BusinessTime') }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div class="formStyle">
                  <div v-if="!scope.row.isEdit">{{ scope.row.operateTime }}</div>
                  <el-form-item
                    v-else
                    :prop="'expenseSubList.' + scope.$index + '.operateTime'"
                    :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                  >
                    <el-date-picker
                      v-model="scope.row.operateTime"
                      type="datetime"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      style="width: 100%;"
                      :placeholder="$t('basicData.SelectADatetime')"
                    />
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!--操作人-->
            <el-table-column :label="$t('basicData.Operator')" prop="feeName" width="180">
              <template slot="header">
                <div>
                  <span style="color: #f00;margin-right: 5px;">*</span>
                  <span>{{ $t('basicData.Operator') }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <div class="formStyle">
                  <div v-if="!scope.row.isEdit">{{ scope.row.operatorName }}</div>
                  <el-form-item
                    v-else
                    :prop="'expenseSubList.' + scope.$index + '.operator'"
                    :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                  >
                    <QueryUserV2Cmp :val="scope.row.operator" @change="val => scope.row.operator = val" />
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!--备注-->
            <el-table-column
              prop="remark"
              :label="$t('receivablePayable.remark')"
            >
              <template slot-scope="scope">
                <div v-if="!scope.row.isEdit">{{ scope.row.remark }}</div>
                <el-form-item
                  v-else
                  :prop="'expenseSubList.' + scope.$index + '.remark'"
                  :rules="{ max: 200, message: $t('basicData.LengthCantExceed200'), trigger: ['blur', 'change'] }"
                >
                  <el-input v-model="scope.row.remark" :placeholder="$t('GlobalSetObj.pleaseInputContent')" />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-card>
    </div>

    <div class="footerBtn">
      <!--保存-->
      <el-button v-permit:remove="permission" type="primary" size="small" @click="saveData(2)">{{ $t('basicData.Save') }}</el-button>
      <!--关闭-->
      <el-button size="small" @click="saveData(1)">{{ $t('orderCenterCont.ShutDown') }}</el-button>
    </div>
  </div>

</template>

<script>
import { cloneDeep } from 'lodash-es';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import {
  apiPayableSiteExpenseFastInputFee,
  apiPayableSiteExpenseAddFee,
  apiPayableSiteExpenseDelete
} from '@/api/finance/receivables';
import { feePage } from '@/api/finance/partition';
import { apiCustomerPage } from '@/api/customer';
import { apiProviderPage } from '@/api/finance/basicData';
import QuerySiteV2Cmp from '@/views/finance/cmp/Query/QuerySiteV2Cmp.vue';
import QueryUserV2Cmp from '@/views/finance/cmp/Query/QueryUserV2Cmp.vue';
import { QUERY_FEE_PARAM } from '@/views/finance/payableManage/feesPayable/enums.js';
import { getPermission } from './AddFeee/utils';

export default {
  name: 'AddSnapshotFee',

  components: {
    QuerySiteV2Cmp,
    QueryUserV2Cmp
  },

  props: {
    // 搜索数据
    queryForm: {
      type: Object,
      default: () => {
        return {};
      }
    },

    // 费用类型（后端请求）
    feeTypeOption: {
      type: Array,
      default: () => {
        return [];
      }
    },

    // 快录明细
    queryFeeParam: {
      type: Object,
      default: () => {
        return {
          ...QUERY_FEE_PARAM
        };
      }
    },

    // 费用明细（后端请求）
    expenseSubList: {
      type: Array,
      default: () => {
        return [];
      }
    },

    // 账单类型
    payableType: {
      type: Number,
      required: true
    },

    // 勾选
    selectData: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  data() {
    const priceType = (rule, value, callback) => {
      const reg = new RegExp('(^' + rule.negative + '[1-9]\\d*(\\.\\d{1,' + rule.suffix + '})?$)|(^' + rule.negative + '0(\\.\\d{1,' + rule.suffix + '})?$)');
      const { required } = rule;
      if (!required) {
        // 非必填
        if (value) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // 格式不正确
          } else {
            this.adopt = true;
            return callback();
          }
        } else {
          return callback();
        }
      } else {
        if (value) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // 格式不正确
          } else {
            this.adopt = true;
            return callback();
          }
        } else {
          this.adopt = false;
          return callback(this.$i18n.t('basicData.CannotBeEmpty')); // '不能为空'
        }
      }
    };
    return {
      addBatchFee: {
        basicInformation: {
          ...QUERY_FEE_PARAM
        },
        expenseSubList: []
      },

      showAddSnapshotFee: false, // 弹出框

      priceType: priceType,
      createUser: null,
      customerList: [],
      outletsList: [],
      feeTypeList: [],
      providerName: '', // 业务主体名称
      permission: '', // 权限
    };
  },

  activated() {
    this.permission = getPermission(this.payableType);
  },

  created() {
    // 费用类型
    this.feeTypeList = cloneDeep(this.feeTypeOption);
    this.createUser = getUserIdAndNameObj();

    this.addBatchFee.basicInformation = cloneDeep(this.queryFeeParam);
    if(this.queryFeeParam.id) {
      this.addBatchFee.expenseSubList = cloneDeep(this.expenseSubList);
    }

    this.permission = getPermission(this.payableType);

    this.$nextTick(() => {
      // 获取客户
      this.getCustomerPage();
    });
  },
  methods: {
    // 费用类型变化
    feeTypeChange(scope) {
      this.feeTypeList.map(item => {
        if (item.code === scope.row.feeCode) {
          this.addBatchFee.expenseSubList[scope.$index].feeName = item.name;
        }
      });
    },
    // 业务类型变化
    changeBusinessType() {
      this.addBatchFee.basicInformation.collectType = null;
      if (!this.addBatchFee.basicInformation.id) {
        this.addBatchFee.expenseSubList = [];
      }
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10000,
        params: {
          state: 1,
          attribute: 2,
          businessType: this.addBatchFee.basicInformation.businessType
        }
      };
      feePage(param, false).then(res => {
        if (res.code === 200) {
          this.feeTypeList = res.data.records;
        }
      });
    },

    // 网点选项变化
    changeOutlets(obj) {
      const { label, providerId } = obj;
      this.addBatchFee.basicInformation.siteName = label;
      // 网点反推主体
      this.asyncapiProviderPage(providerId);
    },
    asyncapiProviderPage(id) {
      const param = {
        pageNumber: 1,
        pageSize: 1,
        params: {
          id
        }
      };

      apiProviderPage(param, false).then(res => {
        const { records } = res.data;
        if (records.length > 0) {
          this.providerName = records[0].providerBody;
          this.addBatchFee.basicInformation.providerName = records[0].providerBody;
        }
      }).catch(err => {
        console.error(err);
      });
    },

    // 获取客户
    getCustomerPage(name = '') {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          quickSearch: name
        }
      };
      apiCustomerPage(param).then((res) => {
        if (res.code === 200) {
          this.customerList = res.data.records;
        }
      });
    },
    // 客户选项变化
    changeCustomer() {
      this.customerList.map(item => {
        if (this.addBatchFee.basicInformation.customerId === item.id) {
          this.addBatchFee.basicInformation.customerAlias = item.customerAlias;
        }
      });
    },
    // 关闭，保存
    saveData(type) {
      if (type === 2) {
        this.$refs.basicInformation.validate((validIs) => {
          if (validIs) {
            const basicInformation = {
              id: this.addBatchFee.basicInformation.id,
              waybillNumber: 'QR' + this.addBatchFee.basicInformation.waybillNumber, // 业务单号
              businessType: this.queryForm.params.businessType, // 业务类型
              customerId: this.addBatchFee.basicInformation.customerId, // 客户id
              customerAlias: this.addBatchFee.basicInformation.customerAlias, // 客户别称
              siteId: this.addBatchFee.basicInformation.siteId, // 网点id
              siteName: this.addBatchFee.basicInformation.siteName, // 网点名称
              collectType: this.addBatchFee.basicInformation.collectType, // 揽收类型
              providerId: null,
              remark: this.addBatchFee.basicInformation.remark, // 备注
              chargeWeight: this.addBatchFee.basicInformation.chargeWeight, // 计费重量
              operationMode: this.queryForm.params.operationMode, //派送方式
            };
            this.$refs.addBatchFee.validate((valid) => {
              if (valid) {
                const list = [];
                this.addBatchFee.expenseSubList.map(item => {
                  if (item.isEdit) {
                    const obj = {
                      feeCode: item.feeCode,
                      feeName: item.feeName,
                      operateTime: item.operateTime,
                      remark: item.remark,
                      fee: item.fee,
                      operator: item.operator
                    };
                    list.push(obj);
                  }
                });
                if (!basicInformation.id) { // 新增
                  const param = {
                    ...basicInformation,
                    expenseFastInputSubDTOList: list,
                    payableType: this.payableType
                  };
                  if (this.addBatchFee.expenseSubList.length === 0) {
                    this.$message.warning(this.$t('receivablePayable.AddAtLeastOneCharge'));
                    return;
                  }
                  apiPayableSiteExpenseFastInputFee(param).then(res => {
                    if (res.code === 200) {
                      this.$message.success(this.$i18n.t('GlobalSetObj.SaveSuccessfully')); // 保存成功
                      this.$emit('saveFormSnapshot', true);
                    }
                  });
                } else { // 修改
                  const param = {
                    waybillNumber: basicInformation.waybillNumber,
                    expenseSubList: list,
                    businessType: basicInformation.businessType,
                    payableType: this.payableType,
                    operationMode: basicInformation.operationMode // 派送方式
                  };
                  apiPayableSiteExpenseAddFee(param).then(res => {
                    if (res.code === 200) {
                      this.$message.success(this.$i18n.t('GlobalSetObj.SaveSuccessfully')); // 保存成功
                      this.$emit('saveFormSnapshot', true);
                    }
                  });
                }
              }
            });
          }
        });
      } else {
        this.$emit('saveFormSnapshot', false);
      }
    },
    // 删除新增
    deleteCost(scope) {
      if (scope.row.id) {
        apiPayableSiteExpenseDelete({ id: scope.row.id }).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
            this.addBatchFee.expenseSubList.splice(scope.$index, 1);
          }
        });
      } else {
        this.addBatchFee.expenseSubList.splice(scope.$index, 1);
      }
    },
    // 添加费用
    addCost() {
      const data = {
        isEdit: true,
        feeName: '', // 费用类型
        feeCode: '', // 费用类型代码
        fee: '', // 应收金额
        operateTime: '', // 生成业务时间
        operator: '', // 操作人
        remark: '', // 备注
      };

      this.addBatchFee.expenseSubList.push(data);
      this.$nextTick(() => {
        this.$refs.addBatchFee.clearValidate();
      })
    }
  }
};
</script>

<style scoped>
.box-card {
  margin-bottom: 10px;
}
::v-deep .el-card__header {
  padding: 0 10px;
}

.headerStyle {
  background:#eee;
  height: 50px;
  line-height: 50px;
}

.CostDetails /deep/ .el-form-item {
  margin-bottom: 0;
}

.CostDetails /deep/ .el-form-item__error {
  position: sticky;
  text-align: left;
}

.footerBtn {
  position: sticky;
  bottom: 0;
  border-top: 1px solid #EBEEF5;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
</style>
<style>
.addTable {
  height: calc(100vh - 118px);
  overflow-y: auto;
}
.addTable .el-table tbody .el-table__cell{
  padding: 5px;
}
.addStyle {
  color: #409EFF;
  cursor: pointer;
  font-size: 18px;
}
.deleteStyle {
  color: #F56C6C;
  cursor: pointer;
  font-size: 18px;
}
</style>
