<template>
  <div class="Box">
    <!--  扫描  -->
    <div class="scanClass">
      <span class="span">{{ $t('collectionCenter.inputVehicleNumber') }}：</span><!--  请输入车牌号码：  -->
      <el-input
        v-model.trim="carMsg"
        clearable
        :placeholder="$t('collectionCenter.pleaseInput')"
        @keyup.enter.native="scanCar"
        @input="onInput"
      /><!--    -->
    </div>
    <!--  车辆信息  -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%"
      @close="handleClose"
    >
      <div class="dialogClass">
        <!--  车辆编号：  -->
        <span>{{ $t('collectionCenter.carNum') }}{{ carList.id }}</span>
        <!--  车牌号：  -->
        <span>{{ $t('collectionCenter.licensePlateNum') }}{{ carList.vehicleNumber }}</span>
        <!--  运营模式：  -->
        <span>{{ $t('collectionCenter.operationM') }}{{ carList.operationModeName }}</span>
        <!--  车辆类型：  -->
        <span>{{ $t('collectionCenter.carT') }}{{ carList.vehicleTypeName }}</span>
        <!--  驾驶人：  -->
        <span>{{ $t('collectionCenter.carDriver') }}{{ carList.vehicleDriver }}</span>
        <!--  所属网点：  -->
        <span>{{ $t('collectionCenter.belongToSite') }}{{ carList.siteIdName }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--  取消  -->
        <el-button @click="loadScanConfirm('1')">{{ $t('collectionCenter.cancel') }}</el-button>
        <!--  确认  -->
        <el-button type="primary" @click="loadScanConfirm('0')">{{ $t('collectionCenter.confirm') }}</el-button>
      </span>
    </el-dialog>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';
import {
  loadVehicleScan,
  confirmAndCancelVehicle
} from '@/api/lanshou';
export default {
  name: 'LoadingConfirm',
  data() {
    return {
      okMp3,
      errorMp3,
      dialogVisible: false,
      carMsg: '',
      carList: {}, // 扫描车辆获取的返回值
      vehicleScanOperateLog: {}, // 确认取消入参
      record: {
        inputValue: '', // 存储输入框的值
        lastInputTime: null, // 记录上一次输入的时间
        inputInterval: null // 存储两次输入的时间间隔
      }
    };
  },
  methods: {
    onInput(event) {
      const currentTime = new Date().getTime(); // 获取当前时间（毫秒数）
      if (this.lastInputTime !== null) {
        this.inputInterval = currentTime - this.lastInputTime; // 计算本次输入与上一次输入的时间间隔
      }
      this.lastInputTime = currentTime; // 更新上一次输入时间为当前时间
      // 假设扫描枪输入速度非常快，这里设置一个阈值，比如 50 毫秒
      if (this.inputInterval < 50) {
        this.carMsg = event.slice(0, -1); // 如果时间间隔小于阈值，阻止输入(常见阻止输入方式无效)
        return;
      }
      this.carMsg = event;
    },
    scanCar() {
      if (!this.carMsg) {
        this.$message({
          message: this.$t('collectionCenter.pleaseInput'), // '请输入'
          type: 'warning'
        });
        this.$refs.errRef.play();
        return;
      }
      if (this.inputInterval < 50) return;
      loadVehicleScan(this.carMsg).then(res => {
        if (res.code===200) {
          this.dialogVisible = true;
          this.$refs.okRef.play();
          this.carList = res.data;
          this.vehicleScanOperateLog = res.data.vehicleScanOperateLog;
        }
      }).catch(() => {
        this.carMsg = '';
        this.$refs.errRef.play();
      });
    },
    // 车辆扫描确认取消接口
    loadScanConfirm(val) {
      const carForm = {
        confirmStatus: val === '0' ? 0 : val === '1' ? 1 : '',
        vehicleScanOperateLog: this.vehicleScanOperateLog
      };
      confirmAndCancelVehicle(carForm).then(res => {
        if (res.code===200) {
          this.handleClose();
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.carMsg = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.scanClass{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  .span{
    font-size: 20px;
    font-weight: bold;
  }
}
.scanClass ::v-deep .el-input{
  width: 50%;
}
.scanClass ::v-deep .el-input .el-input__inner{
  border-width: 2px;
  height: 45px;
  font-size: 20px;
}
.scanClass ::v-deep .el-input .el-input__clear{
  transform: scale(1.5);
}
.dialogClass{
  padding: 20px;
  border: 1px solid #b1b3b7;
  border-radius: 10px;
  box-sizing: border-box;
  span{
    font-size: 18px;
    font-weight: bold;
    display: block;
    margin: 10px 0;
  }
}
</style>
