var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "mb5 searchContainer" },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "top" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseChoose"
                                        ),
                                      },
                                      model: {
                                        value: _vm.type1,
                                        callback: function ($$v) {
                                          _vm.type1 = $$v
                                        },
                                        expression: "type1",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "ClaimsManagement.BillNumber"
                                          ),
                                          value: "claimsBillNumber",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "ClaimsManagement.ClaimNumber"
                                          ),
                                          value: "claimsNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "GlobalSetObj.pleaseInputContent"
                              ),
                              clearable: "",
                            },
                            model: {
                              value: _vm.query.params.val1,
                              callback: function ($$v) {
                                _vm.$set(_vm.query.params, "val1", $$v)
                              },
                              expression: "query.params.val1",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("ClaimsManagement.TimeOfPayment"),
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "start-placeholder": _vm.$t(
                                "GlobalSetObj.startDate"
                              ),
                              "end-placeholder": _vm.$t("GlobalSetObj.endDate"),
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "default-time": ["09:00:00", "18:00:00"],
                            },
                            model: {
                              value: _vm.query.params.patyedTimeList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.query.params,
                                  "patyedTimeList",
                                  $$v
                                )
                              },
                              expression: "query.params.patyedTimeList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("GlobalSetObj.client") } },
                        [
                          _c("CustomerSelectCmp", {
                            staticStyle: { width: "100%" },
                            attrs: { "is-all": true },
                            model: {
                              value: _vm.query.params.customerId,
                              callback: function ($$v) {
                                _vm.$set(_vm.query.params, "customerId", $$v)
                              },
                              expression: "query.params.customerId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("GlobalSetObj.status") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                              },
                              model: {
                                value: _vm.query.params.payedStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.query.params, "payedStatus", $$v)
                                },
                                expression: "query.params.payedStatus",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.all"),
                                  value: "",
                                },
                              }),
                              _vm._l(_vm.PAYSTATUS_STATUS, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.name,
                                    value: item.value,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small", icon: "el-icon-search" },
              on: { click: _vm.getTableData },
            },
            [_vm._v(_vm._s(_vm.$t("operationCenter.Procurar")))]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", icon: "el-icon-refresh-left" },
              on: { click: _vm.onReset },
            },
            [_vm._v(" " + _vm._s(_vm.$t("operationCenter.reset")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, height: 580, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operationCenter.ind"),
                  width: "60px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.query.pageNumber - 1) * _vm.query.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.BillNumber"),
                  prop: "claimsBillNumber",
                  width: "130",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.ClaimNumber"),
                  prop: "claimsNumber",
                  width: "130",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.ClaimsClient"),
                  prop: "customerName",
                  width: "130",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.ClaimAmount"),
                  prop: "claimAmount",
                  width: "110",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.AmountActuallyPaid"),
                  prop: "actualClaimAmount",
                  width: "110",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.PaymentStatus"),
                  prop: "name",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.payedStatus === _vm.PAYSTATUS_STATUS[1].value
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v(_vm._s(_vm.PAYSTATUS_STATUS[1].name)),
                            ])
                          : scope.row.payedStatus ===
                            _vm.PAYSTATUS_STATUS[2].value
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v(_vm._s(_vm.PAYSTATUS_STATUS[2].name)),
                            ])
                          : scope.row.payedStatus ===
                            _vm.PAYSTATUS_STATUS[3].value
                          ? _c("el-tag", [
                              _vm._v(_vm._s(_vm.PAYSTATUS_STATUS[3].name)),
                            ])
                          : _c("span"),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.TimeOfPayment"),
                  prop: "payedTime",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.PaymentMethod"),
                  prop: "payedTypeStr",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.PaymentAccountNumber"),
                  prop: "payAccount",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("customerManagements.enclosure"),
                  prop: "attachmentUrl",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.attachmentUrl, function (item) {
                        return _c(
                          "el-link",
                          {
                            key: item.url,
                            attrs: {
                              type: "primary",
                              href: item.url,
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      })
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.remark"),
                  prop: "remarks",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: _vm.$t("GlobalSetObj.operate"),
                  width: "170",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.payedStatus === _vm.PAYSTATUS_STATUS[2].value
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onOpenPayment(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("ClaimsManagement.Payment"))
                                ),
                              ]
                            )
                          : _vm._e(),
                        scope.row.payedStatus === _vm.PAYSTATUS_STATUS[2].value
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.asyncApiClaimBillDeduction(
                                      scope.row.id
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("ClaimsManagement.BillCredit"))
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  "current-page": _vm.query.pageNumber,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.query.pageSize,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.query, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.query, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.query, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.query, "pageSize", $event)
                  },
                  "current-change": _vm.getCurrentTableData,
                  "size-change": _vm.getSizeTableData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("GlobalSetObj.tips"),
            visible: _vm.paymentRow.isBool,
            width: "950px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.paymentRow, "isBool", $event)
            },
          },
        },
        [
          _vm.paymentRow.isBool
            ? _c("PagamentosCmp", {
                attrs: { row: _vm.paymentRow.row },
                on: { close: _vm.onClose },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }