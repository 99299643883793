<template>
  <div class="app-container">
    <div class="searchContainer">
      <el-form
        ref="queryFormRef" class="queryFormClass" :model="queryForm"
        label-width="100px" size="mini"
      >
        <el-row>
          <el-col :span="7">
            <el-form-item :label="$t('collectionCenter.name')">
              <el-input v-model="queryForm.params.username" clearable :placeholder="$t('GlobalSetObj.pleaseInput')" />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item :label="$t('GlobalSetObj.status')+'：'" prop="state">
              <el-select v-model="queryForm.params.state" clearable>
                <el-option :value="1" :label="$t('userManage.onJob')" />
                <el-option :value="2" :label="$t('userManage.leaveJob')" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item :label="$t('userManage.roleName')+'：'">
              <el-select v-model="queryForm.params.role" clearable>
                <el-option :label="$t('userManage.temporaryWorker')" :value="1" />
                <el-option :label="$t('userManage.deliveryRider')" :value="2" />
                <el-option :label="$t('userManage.courier')" :value="3" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item :label="$t('userManage.workLocation')+'：'">
              <el-select v-model="queryForm.params.location" clearable>
                <el-option :label="$t('userManage.warehouse1')" :value="1" />
                <el-option :label="$t('userManage.warehouse2')" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item :label="$t('GlobalSetObj.serialNumber')+'：'">
              <el-input
                v-model="serialNumber" type="textarea" :rows="4"
                clearable :placeholder="$t('GlobalSetObj.pleaseInput')"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="start">
        <el-col :span="8">
          <div>
            <el-button
              v-permit:remove="'btn:user-center:personnel-registration:find'"
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="getData"
            >{{ $t('GlobalSetObj.search') }}</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="queryFormReset">{{ $t('userManage.reset') }}</el-button>
          </div>
        </el-col>
      </el-row>
      <el-divider />
      <div style="display: flex; justify-content: space-between; margin: 10px 0;">
        <span>
          <el-button
            v-permit:remove="'btn:user-center:personnel-registration:add'"
            size="small"
            type="primary"
            @click="addUser"
          >{{ $t('GlobalSetObj.append') }}</el-button>
          <el-popover
            placement="bottom"
            trigger="hover"
            popper-class="popperBox"
          >
            <el-button
              slot="reference"
              v-permit:remove="'btn:user-center:personnel-registration:import'"
              size="small"
              type="primary"
              style="margin: 0 10px;"
            >{{ $t('collectionCenter.Import') }}</el-button>
            <div>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-download"
                style="display: block;margin-bottom: 10px"
                @click="downloadTemplate"
              >{{ $t('basicData.DownloadTheTemplate') }}</el-button>
              <upload-excel
                :on-success="importUserData"
                :but-name="$t('spareNumberPoo.ImportData')"
                style="display: inline-block;"
              />
            </div>
          </el-popover>
          <el-button
            v-permit:remove="'btn:user-center:personnel-registration:entry'"
            size="small"
            type="success"
            @click="updateState(1)"
          >{{ $t('userManage.onboarding') }}</el-button>
          <el-button
            v-permit:remove="'btn:user-center:personnel-registration:retire'"
            size="small"
            type="danger"
            @click="updateState(2)"
          >{{ $t('userManage.leaveJob') }}</el-button>
          <el-button
            v-permit:remove="'btn:user-center:personnel-registration:estimation'"
            size="small"
            @click="dialogVisible=true"
          >{{ $t('userManage.evaluation') }}</el-button>
        </span>
        <span>
          <el-button
            v-permit:remove="'btn:user-center:personnel-registration:export'"
            size="small"
            @click="exportUser"
          >{{ $t('collectionCenter.export') }}</el-button>
        </span>
      </div>
      <el-card>
        <div>
          <el-table
            :data="tableData" border row-key="id"
            @selection-change="selectionChange"
          >
            <el-table-column
              type="selection" width="45" align="center"
              :reserve-selection="true"
            />
            <el-table-column
              :label="$t('GlobalSetObj.serial')" type="index" width="55"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('GlobalSetObj.serialNumber')" prop="serialNumber">
              <template slot-scope="scope">
                <span style="color: #409EFF;cursor: pointer" @click="getUserInfo(scope.row)">{{ scope.row.serialNumber }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('collectionCenter.compellation')" prop="username" />
            <el-table-column :label="$t('basicData.status')" prop="state" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.state === 1">{{ $t('userManage.onJob') }}</span>
                <span v-else>{{ $t('userManage.leaveJob') }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('userManage.workLocation')" prop="location" width="130px"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.location==1?$t('userManage.warehouse1'):$t('userManage.warehouse2') }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('userManage.roleName')" prop="role" width="130px"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.role === 1">{{ $t('userManage.temporaryWorker') }}</span>
                <span v-else-if="scope.row.role === 2">{{ $t('userManage.deliveryRider') }}</span>
                <span v-else>{{ $t('userManage.courier') }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('userManage.age')" prop="age" width="110px"
              align="center"
            />
            <el-table-column
              :label="$t('userManage.gender1')" prop="sex" width="90px"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.sex===1">{{ $t('userManage.male') }}</span>
                <span v-if="scope.row.sex===2">{{ $t('userManage.female') }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="CPF" prop="cpf" width="120px"
              align="center"
            />
            <el-table-column
              :label="$t('userManage.userPhone')" width="120px" align="center"
              prop="phone"
            />
            <el-table-column
              :label="$t('login.email')" prop="email" width="160px"
              align="center"
            />
            <el-table-column
              :label="$t('userManage.latestHiringDate')" prop="entryTime" width="120px"
              align="center"
            />
            <el-table-column
              :label="$t('userManage.latestDepartureDate')" width="155" align="center"
              prop="resignationTime"
            />
            <el-table-column
              :label="$t('userManage.latestIntegrityLevel')" prop="creditGrade" width="120px"
              align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.creditGrade">
                  <span :class="[`level${scope.row.creditGrade}`]" style="opacity: 1">{{ scope.row.creditGrade }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('GlobalSetObj.CreationTime')" prop="createTime" width="120px"
              align="left"
            />
            <el-table-column
              :label="$t('GlobalSetObj.CreatePeople')" prop="createName" width="120px"
              align="left"
            />
            <el-table-column
              :label="$t('GlobalSetObj.operate')" prop="createUserNameStr" width="120px"
              align="left"
            >
              <template slot-scope="scope">
                <el-button size="mini" @click="getUserInfo(scope.row)">{{ $t('userManage.viewEdit') }}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页工具 -->
        <div class="pagination">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            style="margin: 10px 0"
            background
            @current-change="getData"
            @size-change="getData"
          />
        </div>
      </el-card>
    </div>
    <el-drawer
      :title="$t('userManage.viewEdit')"
      :visible.sync="drawer"
      direction="ltr"
      size="1000px"
      @close="closeDrawer"
    >
      <div style="padding: 20px;height: calc(100vh - 80px);overflow-x: auto">
        <p>{{ $t('basicData.UnderlyingData') }}</p>
        <el-divider />
        <el-form
          ref="ruleForm" :model="ruleForm" :rules="rules"
          label-width="100px" size="mini"
        >
          <el-row>
            <el-col :span="7">
              <el-form-item :label="$t('collectionCenter.name')" prop="username">
                <el-input v-model="ruleForm.username" clearable :placeholder="$t('GlobalSetObj.pleaseInput')" />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="CPF：" prop="cpf">
                <el-input v-model="ruleForm.cpf" clearable :placeholder="$t('GlobalSetObj.pleaseInput')" />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item :label="$t('userManage.workLocation')+'：'" prop="location">
                <el-select v-model="ruleForm.location">
                  <el-option :label="$t('userManage.warehouse1')" :value="1" />
                  <el-option :label="$t('userManage.warehouse2')" :value="2" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item :label="$t('userManage.roleName')+'：'" prop="role">
                <el-select v-model="ruleForm.role">
                  <el-option :label="$t('userManage.temporaryWorker')" :value="1" />
                  <el-option :label="$t('userManage.deliveryRider')" :value="2" />
                  <el-option :label="$t('userManage.courier')" :value="3" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item :label="$t('userManage.age')+'：'" prop="age">
                <el-input v-model="ruleForm.age" :placeholder="$t('GlobalSetObj.pleaseInput')" />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item :label="$t('userManage.birthday')" prop="birthday">
                <el-date-picker
                  v-model="ruleForm.birthday"
                  type="date"
                  :placeholder="$t('basicData.SelectADate')"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item :label="$t('userManage.userPhone')+'：'" prop="phone">
                <el-input v-model="ruleForm.phone" clearable :placeholder="$t('GlobalSetObj.pleaseInput')" />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item :label="$t('login.email')+'：'" prop="email">
                <el-input v-model="ruleForm.email" clearable :placeholder="$t('GlobalSetObj.pleaseInput')" />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item :label="$t('userManage.gender')" prop="sex">
                <el-select v-model="ruleForm.sex">
                  <el-option :label="$t('userManage.male')" :value="1" />
                  <el-option :label="$t('userManage.female')" :value="2" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('userManage.entryTime')" prop="entryTime">
                <el-date-picker
                  v-model="ruleForm.entryTime	"
                  type="date"
                  :placeholder="$t('basicData.SelectADate')"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item :label="$t('userManage.detailedAddress')+'：'" prop="address">
                <el-input
                  v-model="ruleForm.address" type="textarea" :rows="4"
                  clearable :placeholder="$t('GlobalSetObj.pleaseInput')"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
        <p>{{ $t('userManage.workRecord') }}</p>
        <el-table :data="wordList" border>
          <el-table-column :label="$t('trajectory.Time')" prop="createTime" />
          <el-table-column :label="$t('userManage.node')" prop="node">
            <template slot-scope="scope">
              <span>{{ scope.row.node===1?$t('userManage.onJob'):$t('userManage.leaveJob') }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('basicData.Operator')" prop="createName" />
        </el-table>
        <el-divider />
        <p>{{ $t('userManage.evaluationRecord') }}</p>
        <el-table :data="evaluateList" border>
          <el-table-column :label="$t('userManage.time')" prop="createTime" />
          <el-table-column :label="$t('userManage.evaluationLevel')">
            <template slot-scope="scope">
              <div v-if="scope.row.level">
                <span :class="[`level${scope.row.level}`]" style="opacity: 1">{{ scope.row.level }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('WorkCentreTable.ticketDescribe')" prop="content" />
          <el-table-column :label="$t('basicData.Operator')" prop="createName" />
        </el-table>
        <div style="text-align: right;margin-top: 20px">
          <template v-if="detailType===1">
            <el-button v-if="$store.getters.button_permissions.includes('btn:user-center:personnel-registration:update')" type="primary" @click="addAndUpdateUser">{{ $t('userManage.confirm') }}</el-button>
          </template>
          <el-button v-else type="primary" @click="addAndUpdateUser">{{ $t('userManage.confirm') }}</el-button>
        </div>
      </div>
    </el-drawer>
    <!--    评价-->
    <el-dialog
      :title="$t('userManage.employeeEvaluation')"
      :visible.sync="dialogVisible"
      width="500px"
      @close="handleClose"
    >
      <el-form>
        <el-form-item :label="$t('customerManagements.grade')+'：'">
          <div
            v-for="(item,index) in [1,2,3,4]" :key="index" :class="[`level${item}`,level===item?'level-active':'']"
            @click="level=item"
          >
            {{ item }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('WorkCentreTable.ticketDescribe')+'：'">
          <el-input
            v-model="content"
            type="textarea"
            :rows="8"
            :placeholder="$t('userManage.pleaseInput')"
            style="width: 80%"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="evaluateUser">{{ $t('GlobalSetObj.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  userUserRegisterAdd,
  userUserRegisterAddEvaluateRecord, userUserRegisterBatchAdd, userUserRegisterEdit, userUserRegisterExportExcel,
  userUserRegisterGetEvaluateRecord,
  userUserRegisterGetInfo,
  userUserRegisterGetWorkRecord,
  userUserRegisterPage, userUserRegisterUpdateStatus
} from '@/api/user';
import ExcelJS from 'exceljs';
import uploadExcel from '@/components/UploadExcel/index.vue';
import { formatDateExcel } from '@/utils/date_util';
// import { orderCenterCont } from '@/lang/type/orderCenter';
import { saveAs } from 'file-saver';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'PersonnelRegistration',
  components: { uploadExcel },
  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          username: '',
          state: '',
          role: '',
          location: ''
        }
      },
      serialNumber: '',
      tableData: [],
      total: 0,
      selectData: [], // 选中数据
      drawer: false,
      ruleForm: {
        username: '',
        cpf: '',
        location: '',
        role: '',
        age: null,
        birthday: '',
        phone: '',
        email: '',
        address: '',
        entryTime: '',
        sex: ''
      },
      rules: {
        username: [
          { required: true, message: this.$t('userManage.pleaseInput'), trigger: ['blur', 'change'] },
          { max: 100, message: this.$t('userManage.exceedsLengthLimit'), trigger: ['blur', 'change'] }
        ],
        cpf: [{ required: true, message: this.$t('userManage.pleaseInput'), trigger: ['blur', 'change'] },
          { max: 60, message: this.$t('userManage.exceedsLengthLimit'), trigger: ['blur', 'change'] }],
        location: [{ required: true, message: this.$t('userManage.pleaseChoose'), trigger: ['blur', 'change'] }],
        role: [{ required: true, message: this.$t('userManage.pleaseChoose'), trigger: ['blur', 'change'] }],
        age: [{ required: true, message: this.$t('userManage.pleaseInput'), trigger: ['blur', 'change'] },
          { min: 1, max: 3, message: this.$t('userManage.exceedsLengthLimit'), trigger: ['blur', 'change'] }],
        birthday: [{ required: true, message: this.$t('userManage.pleaseChoose'), trigger: ['blur', 'change'] }],
        phone: [{ required: true, message: this.$t('userManage.pleaseInput'), trigger: ['blur', 'change'] },
          { max: 40, message: this.$t('userManage.exceedsLengthLimit'), trigger: ['blur', 'change'] }],
        email: [{ required: true, message: this.$t('userManage.pleaseInput'), trigger: ['blur', 'change'] },
          { max: 60, message: this.$t('userManage.exceedsLengthLimit'), trigger: ['blur', 'change'] }],
        address: [{ required: true, message: this.$t('userManage.pleaseInput'), trigger: ['blur', 'change'] },
          { max: 2000, message: this.$t('userManage.exceedsLengthLimit'), trigger: ['blur', 'change'] }],
        entryTime: [{ required: true, message: this.$t('userManage.pleaseChoose'), trigger: ['blur', 'change'] }],
        sex: [{ required: true, message: this.$t('userManage.pleaseChoose'), trigger: ['blur', 'change'] }]
      },
      wordList: [], // 工作记录
      evaluateList: [], // 评价记录
      detailType: 0, // 0:添加 1:编辑
      dialogVisible: false, // 评价弹窗
      level: 0, // 评价等级
      content: '' // 评价内容
    };
  },
  created() {
    this.getData();
  },
  methods: {
    /**
     * @description 获取数据
     */
    getData() {
      const params = {
        ...this.queryForm,
        params: {
          ...this.queryForm.params
        }
      };
      if (this.serialNumber) {
        const order = this.serialNumber.split(/[(\r\n)\r\n]+/);
        const orderNoList = [];
        order.map((item) => {
          if (item) {
            orderNoList.push(item.trim());
          }
        });
        params.params.serialNumberList = orderNoList;
      } else {
        params.params.serialNumberList = [];
      }
      if (params.params.serialNumberList.length === 1) {
        params.params.serialNumber = params.params.serialNumberList[0];
        delete params.params.serialNumberList;
      }
      if (params.params.serialNumberList && params.params.serialNumberList.length > 500) {
        this.$message.warning(this.$t('userManage.upTo500ID'));
        return;
      }
      userUserRegisterPage(params)
        .then((res) => {
          if (res.code===200) {
            this.tableData = res.data.records;
            this.total = res.data.total;
          }
        });
    },
    // 重置
    queryFormReset() {
      this.queryForm.params = {
        username: '',
        state: '',
        role: '',
        location: ''
      };
      this.serialNumber = '';
    },
    /**
     * @description 添加
     */
    addUser() {
      this.drawer = true;
      this.detailType = 0;
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
      });
      this.evaluateList = [];
      this.wordList = [];
    },
    /**
     * @description 导出
     */
    exportUser() {
      let params = {
        ...this.queryForm.params
      };
      if (this.selectData && this.selectData.length) {
        params = {};
        params.serialNumberList = this.selectData.map((item) => item.serialNumber);
      } else {
        if (this.serialNumber) {
          const order = this.serialNumber.split(/[(\r\n)\r\n]+/);
          const orderNoList = [];
          order.map((item) => {
            if (item) {
              orderNoList.push(item.trim());
            }
          });
          params.serialNumberList = orderNoList;
        } else {
          params.serialNumberList = [];
        }
      }
      userUserRegisterExportExcel(params)
        .then((res) => {
          if (res.code===200) {
            goExportList(this);
          }
        });
    },
    // 账号启用/停用
    isDisable(flag) {
      console.log('账号启用/停用', flag);
    },
    selectionChange(val) {
      this.selectData = val;
    },
    /**
     * @description 用户信息关闭
     */
    closeDrawer() {
      this.drawer = false;
      this.$refs.ruleForm.resetFields();
      this.ruleForm = {
        username: '',
        cpf: '',
        location: '',
        role: '',
        age: '',
        birthday: '',
        phone: '',
        email: '',
        address: '',
        entryTime: '',
        sex: ''
      };
    },
    /**
     * @description 获取用户信息详情
     * @param row
     */
    getUserInfo(row) {
      this.drawer = true;
      this.detailType = 1;
      userUserRegisterGetInfo(row.id)
        .then((res) => {
          this.ruleForm = res.data;
          if (this.ruleForm.age) {
            this.ruleForm.age = this.ruleForm.age.toString();
          }
        });
      userUserRegisterGetWorkRecord(row.id)
        .then((res) => {
          this.wordList = res.data;
        });
      userUserRegisterGetEvaluateRecord(row.id)
        .then((res) => {
          this.evaluateList = res.data;
        });
    },
    /**
     * @description 更新状态
     * @param type
     */
    updateState(type) {
      if (this.selectData.length === 0) {
        this.$message.warning(this.$t('collectionCenter.pleaseSelectData'));
        return;
      }
      this.$confirm(this.$t('userManage.thisOperation'), this.$t('GlobalSetObj.tips'), {
        confirmButtonText: this.$t('GlobalSetObj.confirm'),
        cancelButtonText: this.$t('GlobalSetObj.cancel'),
        type: 'warning'
      }).then(() => {
        const ids = this.selectData.map((item) => item.id);
        userUserRegisterUpdateStatus({ ids, state: type })
          .then(() => {
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
            this.getData();
          });
      }).catch(() => {
      });
    },
    /**
     * @description 评价
     */
    evaluateUser() {
      if (this.selectData.length !== 1) {
        this.$message.warning(this.$t('userManage.employeeToEvaluate'));
        return;
      }
      if (!this.level) {
        this.$message.warning(this.$t('userManage.pleaseSelectevaluationLevel'));
        return;
      }
      if (!this.content) {
        this.$message.warning(this.$t('userManage.pleaseEnterEvaluationContent'));
        return;
      }
      userUserRegisterAddEvaluateRecord({
        content: this.content,
        level: this.level,
        userId: this.selectData[0].id
      })
        .then((res) => {
          if (res.code===200) {
            this.$message.success(this.$t('userManage.evaluationSuccessful'));
            this.dialogVisible = false;
            this.getData();
          }
        });
    },
    /**
     * @description 评价弹窗关闭
     */
    handleClose() {
      this.dialogVisible = false;
      this.level = 0;
      this.content = '';
    },
    /**
     * @description 下载模板
     */
    // 下载模板
    downloadTemplate() {
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        Sheet1.columns = [
          {
            header: that.$t('collectionCenter.compellation'),
            key: '1',
            width: 10
          },
          {
            header: 'CPF',
            key: '2',
            width: 10
          },
          {
            header: that.$t('userManage.workLocation'),
            key: '3',
            width: 20
          },
          {
            header: that.$t('userManage.roleName'),
            key: '4',
            width: 10
          },
          {
            header: that.$t('userManage.age'),
            key: '5',
            width: 10
          },
          {
            header: that.$t('userManage.birthday1'),
            key: '6',
            width: 10
          },
          {
            header: that.$t('userManage.userPhone'),
            key: '7',
            width: 10
          },
          {
            header: that.$t('userManage.email1'),
            key: '8',
            width: 10
          },
          {
            header: that.$t('userManage.gender1'),
            key: '9',
            width: 10
          },
          {
            header: that.$t('userManage.entryTime1'),
            key: '10',
            width: 20
          },
          {
            header: that.$t('userManage.detailedAddress'),
            key: '11',
            width: 20
          }
        ];
        const Sheet1_data = [];
        Sheet1.addRows(Sheet1_data);
        Sheet1.ValueType = 'string';
        new Array(1000).fill(0).forEach((_, idx) => {
          const row = idx + 1;
          // 渲染状态下拉框
          Sheet1.getCell(row, 3).dataValidation = {
            type: 'list',
            formulae: [`"${that.$t('userManage.warehouse1')},${that.$t('userManage.warehouse2')}"`]
          };
          Sheet1.getCell(row, 4).dataValidation = {
            type: 'list',
            formulae: [`"${that.$t('userManage.temporaryWorker')},${that.$t('userManage.deliveryRider')},${that.$t('userManage.courier')}"`]
          };
          Sheet1.getCell(row, 9).dataValidation = {
            type: 'list',
            formulae: [`"${that.$t('userManage.male')},${that.$t('userManage.female')}"`]
          };
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = `${that.$t('userManage.importTemplate')}.xls`;
        saveAs(blob, saveName);

        return;
      }
      ddd();
    },
    /**
     * @description 导入用户数据
     */
    importUserData(array) {
      const dataArr = [];
      const keyList = {
        [`${this.$t('collectionCenter.compellation')}`]: 'username',
        'CPF': 'cpf',
        [`${this.$t('userManage.workLocation')}`]: 'location',
        [`${this.$t('userManage.roleName')}`]: 'role',
        [`${this.$t('userManage.age')}`]: 'age',
        [`${this.$t('userManage.birthday1')}`]: 'birthday',
        [`${this.$t('userManage.userPhone')}`]: 'phone',
        [`${this.$t('userManage.email1')}`]: 'email',
        [`${this.$t('userManage.gender1')}`]: 'sex',
        [`${this.$t('userManage.entryTime1')}`]: 'entryTime',
        [`${this.$t('userManage.detailedAddress')}`]: 'address'
      };
      console.log(array, 'array');
      const results = array[0].results;
      results.map((item) => {
        const dataObj = {};
        for (const k in item) {
          dataObj[keyList[k]] = item[k];
          if (k === this.$t('userManage.birthday1') || k === this.$t('userManage.entryTime1')) {
            const date = formatDateExcel(item[k], '-', ':');
            dataObj[keyList[k]] = date || '';
          }
          if (k === this.$t('userManage.roleName')) {
            if (item[k] === this.$t('userManage.temporaryWorker')) {
              dataObj[keyList[k]] = 1;
            } else if (item[k] === this.$t('userManage.deliveryRider')) {
              dataObj[keyList[k]] = 2;
            } else {
              dataObj[keyList[k]] = 3;
            }
          }
          if (k === this.$t('userManage.gender1')) {
            if (item[k] === this.$t('userManage.male')) {
              dataObj[keyList[k]] = 1;
            } else {
              dataObj[keyList[k]] = 2;
            }
          }
          if (k === this.$t('userManage.workLocation')) {
            if (item[k] === this.$t('userManage.warehouse1')) {
              dataObj[keyList[k]] = 1;
            } else {
              dataObj[keyList[k]] = 2;
            }
          }
        }
        dataArr.push(dataObj);
      });
      if (dataArr.length === 0) {
        this.$message.warning(this.$t('userManage.ImportDataIsEmpty'));
        return;
      }
      userUserRegisterBatchAdd(dataArr)
        .then((res) => {
          if (res.code===200 && Object.keys(res.data.error).length === 0) {
            this.$message.success(this.$t('operationCenter.TheImportWasSuccessful'));
            this.getData();
          } else {
            let errorStr = '';
            const entries = Object.entries(res.data.error).sort((a, b) => a[0].localeCompare(b[0]));
            errorStr = entries.map(([index, str]) => `${this.$t('basicData.Lines')}${index}${this.$t('orderCenterCont.pieceOfData')}： ${str}`).join('\n');
            this.$alert(errorStr, this.$t('userManage.failedToImport'), {
              confirmButtonText: this.$t('userManage.confirm')
            });
          }
        });
    },
    /**
     * @description 新增and修改用户
     */
    addAndUpdateUser() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.detailType === 0) {
            userUserRegisterAdd({ ...this.ruleForm, age: Number(this.ruleForm.age) })
              .then((res) => {
                if (res.code===200) {
                  this.$message.success(this.$t('customerManagements.AddedSuccessfully'));
                  this.drawer = false;
                  this.getData();
                }
              });
          } else {
            const {
              birthday, entryTime, role, address, phone, cpf, location, age, sex, email, id, username
            } = this.ruleForm;
            userUserRegisterEdit({ birthday, entryTime, sex, role, address, phone, cpf, location, age: Number(age), email, id, username }).then((res) => {
              if (res.code===200) {
                this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
                this.drawer = false;
                this.getData();
              }
            });
          }
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.level1{
  color: #ffffff;
  border-radius: 5px;
  width: 40px;
  height: 20px;
  background-color: #67C23A;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  line-height: 20px;
  opacity: 0.5;
  cursor: pointer;
}
.level2{
@extend .level1;
  background-color: #409EFF;
}
.level3{
  @extend .level1;
  background-color: #E6A23C;
}
.level4{
  @extend .level1;
  background-color: #F56C6C;
}
.level-active{
  opacity: 1;
}
</style>
