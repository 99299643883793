<template>
  <div class="box">
    <div class="title">
      <!-- 班次编号： -->
      <p>{{ $t('routes.班次编号') }}: {{ row.scheduleNumber }}</p>
      <el-button
        size="mini" type="primary" plain
        @click="$emit('edit', row)">{{ $t('GlobalSetObj.edit') }}</el-button>
    </div>
    <ul>
      <li v-for="(item, index) in row.collectTimeList" :key="index">
        <!--客户-->
        <p>{{ $t('GlobalSetObj.client') }}: {{ row.customerNameList }}</p>
        <p>{{ $t('routes.对应揽收时间') }}: {{ item.collectTime[0] }}-{{ item.collectTime[1] }}</p>
        <p>{{ $t('routes.对应揽收装车完成时间') }}: {{ item.collectLoadFinishTime }}</p>
        <p>{{ $t('routes.对应HUB1到件时间') }} {{ item.hub1ArrivalTime }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { SCHEDULE_ROW } from '../utils';

export default {
  name: '',

  props: {

    // 当前行的数据
    row: {
      type: Object,
      default: () => {
        return cloneDeep(SCHEDULE_ROW);
      }
    }

  },

  data() {
    return {

    };
  },

  created() {

  },

  methods: {

  }

};
</script>

<style scoped lang="scss">
.box {
  padding: 5px 10px;
  border: 1px solid #dadada;
  border-radius: 5px;
  margin-bottom: 5px;
}

p {
  margin: 0;
}

.title {
  display: flex;
  align-items: center;

  p {
    width: 20%;
    color:#049dd9;
    font-size: 14px;

  }
}

ul {
  // border: 1px solid #000;
  list-style: none;
  padding: 0;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border-radius: 5px;
    background-color: #f2f2f2;
    margin-bottom: 5px;

    p {
      width: 33%;
      font-size: 13px;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  p {
    width: 50%;
  }
}

</style>
