// 用于信息脱敏处理

// 手机号脱敏
export function phoneHide(phone) {
  const reg = /^(1[3-9][0-9])d{4}(d{4}$)/; // 定义手机号正则表达式
  phone = phone.replace(reg, '$1****$2');
  return phone;
}

// 姓名脱敏
export function desensitizeName(name, options) {
  // 默认脱敏选项
  const defaultOptions = {
    showFirstLetter: true, // 是否显示第一个字母
    starLength: 2 // 脱敏字符的长度
  };

  // 合并用户选项和默认选项
  const settings = { ...defaultOptions, ...options };

  // 检查是否是中文姓名
  const isChineseName = /[一-龥]/.test(name);

  // 脱敏函数
  const desensitize = (str) => {
    const firstLetter = str[0];
    const restLength = str.length - 1;
    const stars = settings.showFirstLetter ? '*'.repeat(restLength) : '*'.repeat(str.length);
    return firstLetter + stars;
  };

  if (isChineseName) {
    // 假设中文姓名由两个或三个字组成
    return desensitize(name);
  } else {
    // 英文姓名可能由多个单词组成
    const parts = name.split(' ');
    const desensitizedParts = parts.map(part => desensitize(part));
    return desensitizedParts.join(' ');
  }
}

// 身份证号脱敏
export function desensitizeIDCard(idCard) {
  if (!idCard || idCard.length < 6) {
    console.error('Please enter a valid ID number');
    return '';
  }

  // 中国大陆的身份证号码长度为18位
  const idLength = 18;
  // 保留前6位和后4位，中间替换为星号
  const prefix = idCard.substr(0, 6);
  const suffix = idCard.substr(idLength - 4);
  const middle = '*'.repeat(idLength - 10);

  return prefix + middle + suffix;
}

// 邮箱脱敏
export function desensitizeEmail(email) {
  if (!email || !email.includes('@')) {
    return email;
  }

  // 分离邮箱的用户名部分和域名部分
  const [username, domain] = email.split('@');
  // 确定用户名部分脱敏后的长度，这里保留前后各2个字符
  const visibleLength = 2;
  // 如果用户名长度小于或等于保留长度，则不进行脱敏
  if (username.length <= visibleLength * 2) {
    return desensitizeFullText(username) + '@' + domain;
  }

  // 脱敏用户名部分，中间用星号替换
  const visibleUsername = username.substring(0, visibleLength) + '*'.repeat(username.length - visibleLength * 2) + username.substring(username.length - visibleLength);
  // 重新组合脱敏后的邮箱
  return visibleUsername + '@' + domain;
}

// 巴西邮编脱敏
export function desensitizeBrazilianZipCode(zipCode) {
  // 检查邮编格式是否正确
  const zipCodePattern = /^\d{5}-\d{3}$|^\d{8}$/;
  if (!zipCodePattern.test(zipCode)) {
    console.error('Invalid Brazilian zip code format');
  }

  // 去除所有非数字字符
  const cleanZipCode = zipCode.replace(/\D/g, '');

  // 提取前三位和最后一位
  const prefix = cleanZipCode.substring(0, 3);
  const suffix = cleanZipCode.substring(7, 8);

  // 构建脱敏后的邮编
  const desensitizedCleanZipCode = `${prefix}***${suffix}`;

  // 保留原始格式
  if (zipCode.includes('-')) {
    return `${desensitizedCleanZipCode.substring(0, 3)}***-${desensitizedCleanZipCode.substring(7, 8)}`;
  } else {
    return desensitizedCleanZipCode;
  }
}

// 巴西手机号码脱敏
export function desensitizeBrazilianPhoneNumber(phoneNumber) {
  // 检查手机号码格式是否正确
  const internationalPattern = /^55\d{10,11}$/;
  const nationalPattern = /^\(\d{2}\) \d{4}-\d{4}$/;

  if (internationalPattern.test(phoneNumber)) {
    // 国际格式：5551995099199
    const prefix = phoneNumber.substring(0, 4); // 5551
    const middle = phoneNumber.substring(4, 6); // 99
    const suffix = phoneNumber.substring(10, 14); // 9199

    // 构建脱敏后的手机号码
    const desensitizedPhoneNumber = `${prefix}**${middle}****${suffix}`;

    return desensitizedPhoneNumber;
  } else if (nationalPattern.test(phoneNumber)) {
    // 国内格式：(XX) XXXX-XXXX
    const prefix = phoneNumber.substring(0, 6); // (XX)
    const suffix = phoneNumber.substring(11, 15); // -XXXX

    // 构建脱敏后的手机号码
    const desensitizedPhoneNumber = `${prefix} ****${suffix}`;

    return desensitizedPhoneNumber;
  } else {
    console.error('Invalid Brazilian phone number format');
  }
}

// 巴西税号脱敏
export function desensitizeBrazilianTaxNumber(taxNumber) {
  if (!taxNumber.includes('.') && !taxNumber.includes('/') && !taxNumber.includes('-')) {
    return commonDesensitize(taxNumber);
  }

  // 去除所有非数字字符
  const cleanTaxNumber = taxNumber.replace(/\D/g, '');

  // 检查税号格式是否正确
  const cnpjPattern = /^\d{14}$/; // CNPJ without non-digit characters
  const cpfPattern = /^\d{11}$/; // CPF without non-digit characters

  if (!cnpjPattern.test(cleanTaxNumber) && !cpfPattern.test(cleanTaxNumber)) {
    return commonDesensitize(taxNumber);
  }

  let prefix, suffix, middle, desensitizedTaxNumber;

  if (cleanTaxNumber.length === 11) { // CPF
    prefix = cleanTaxNumber.substring(0, 3);
    suffix = cleanTaxNumber.substring(9);
    middle = cleanTaxNumber.substring(3, 9);
    desensitizedTaxNumber = `${prefix}.${'*'.repeat(middle.length)}-${suffix}`;
  } else if (cleanTaxNumber.length === 14) { // CNPJ
    prefix = cleanTaxNumber.substring(0, 8);
    suffix = cleanTaxNumber.substring(12);
    middle = cleanTaxNumber.substring(8, 12);
    desensitizedTaxNumber = `${prefix}.${'*'.repeat(middle.length)}/${suffix}`;
  }

  return desensitizedTaxNumber;
}

// 字符串脱敏，保留前三个字符和后三个字符
export function desensitizeString(input) {
  if (!input || typeof input !== 'string') {
    return '';
  }

  // 如果字符串长度小于等于6个字符，直接返回原字符串
  if (input.length <= 6) {
    return input;
  }

  // 提取前三个字符和后三个字符
  const prefix = input.substring(0, 3);
  const suffix = input.substring(input.length - 3);

  // 计算中间部分的长度
  const middleLength = input.length - 6;

  // 构建脱敏后的字符串
  const desensitizedMiddle = '*'.repeat(middleLength);
  const desensitizedString = `${prefix}${desensitizedMiddle}${suffix}`;

  return desensitizedString;
}

// 葡萄牙语字符串通用脱敏，保留字符串的第一个单词和最后一个单词
export function desensitizePortugueseString(input) {
  if (!input || typeof input !== 'string') {
    return input;
  }

  // 将字符串拆分为单词数组
  const words = input.split(' ');

  // 如果只有一个单词或没有单词，直接返回原字符串
  if (words.length <= 1) {
    return input;
  }

  // 提取第一个和最后一个单词
  const firstWord = words[0];
  const lastWord = words[words.length - 1];

  // 中间单词数量
  const middleWordsCount = words.length - 2;

  // 构建脱敏后的字符串
  const desensitizedMiddleWords = '*'.repeat(middleWordsCount);
  const desensitizedString = `${firstWord} ${desensitizedMiddleWords} ${lastWord}`;

  return desensitizedString;
}

// 全文脱敏
export function desensitizeFullText(input) {
  if (!input || typeof input !== 'string') {
    return input;
  }
  let desensitizedString = '';
  const length = input.length;
  for (let i = 0; i < length; i++) {
    desensitizedString += '*';
  }
  return desensitizedString;
}

// 通用脱敏方法
export function commonDesensitize(value) {
  if (!value || typeof value !== 'string') {
    return value;
  }
  const length = value.length;
  // 如果城市名称长度小于等于2个字符，直接返回原字符串
  if (length <= 2) {
    return desensitizeFullText(value);
  }

  // 提取第一个字符和最后一个字符
  const firstChar = value[0];
  const lastChar = value[length - 1];

  // 计算中间部分的长度
  const middleLength = length - 2;

  // 脱敏处理
  const desensitizedMiddle = '*'.repeat(middleLength);
  const desensitizedCityName = `${firstChar}${desensitizedMiddle}${lastChar}`;

  return desensitizedCityName;
}
