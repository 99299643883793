<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="5">
          <!--  运单号   -->
          <el-form-item>
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.selectType"
                  :style="IsWidth"
                >
                  <el-option key="1" :label="$t('newOrder.waybillNumber')" :value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.ScanNumber')" :value="2" />
                </el-select>
              </div>
            </template>
            <el-input
              v-model="queryForm.params.inputValue"
              :placeholder="$t('newOrder.PreciseSearch1')"
              :rows="1"
              style="width:100%"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <!--        封袋人-->
        <el-col :span="5">
          <el-form-item :label="$t('GlobalSetObj.operator')">
            <el-select
              v-model="queryForm.params.operateUserId"
              :placeholder="$t('operationCenter.Only10AreDisplayed')"
              :remote-method="getUserList"
              clearable
              filterable
              remote
              style="width: 100%"
              @focus="getUserList('')"
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.createUserName+'('+item.realName+')'"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <!--装车时间-->
          <el-form-item :label="$t('GlobalSetObj.operationTime')">
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :placeholder="$t('newOrder.SelectDatetime')"
              range-separator="~"
              style="width:100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!--搜索-->
          <el-button
            icon="el-icon-search" size="small" type="primary"
            @click="enterSearch"
          >{{
            $t('newOrder.search')
          }}
          </el-button>
          <!--重置-->
          <el-button
            icon="el-icon-refresh-left" size="small" type="info"
            @click="resetForm()"
          >{{
            $t('newOrder.reset')
          }}
          </el-button>
          <el-button
            icon="el-icon-upload2" size="small" style="margin-bottom: 10px"
            @click="allExportOrder"
          >{{
            $t('newOrder.export')
          }}
          </el-button>
          <el-radio-group
            v-model="orderWaybillType"
            v-permit:remove="'btn:orderCenter:multipleSignRecordsTest'"
            size="small"
            style="margin-left: 10px"
            @change="handleTest"
          >
            <el-radio-button :label="0">{{ $t("newOrder.Formal") }}</el-radio-button>
            <el-radio-button :label="1">{{ $t("newOrder.test1") }}</el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column :label="$t('GlobalSetObj.serial')" type="index" width="70" />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <!--  签收照片  -->
          <div v-if="item.prop === 'signPhotographList'" class="demo-image__preview" style="z-index: 99999">
            <el-image
              v-for="(item1, index1) in scope.row.signPhotographList"
              :key="index1"
              :initial-index="4"
              :max-scale="7"
              :min-scale="0.2"
              :preview-teleported="true"
              :src="item1"
              :zoom-rate="1.2"
              fit="contain"
              style="width: 50px; margin-right: 5px"
            >
              <template #error>
                <div class="image-slot" />
              </template>
            </el-image>
          </div>
          <!--  签收签名  -->
          <div v-else-if="item.prop === 'signerImg'" class="demo-image__preview" style="z-index: 99999">
            <el-image
              :initial-index="4"
              :max-scale="7"
              :min-scale="0.2"
              :preview-teleported="true"
              :src="scope.row.signerImg"
              :zoom-rate="1.2"
              fit="contain"
              style="width: 50px"
            >
              <template #error>
                <div class="image-slot" />
              </template>
            </el-image>
          </div>
          <div v-else>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { manyTimesExportQuery, manyTimesSignpage } from '@/api/orderApi';
import { getUserPage } from '@/api/user';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';
import { commonDesensitize } from '@/utils/desensitization';

export default {
  name: 'MultipleSignRecords',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          inputValue: '',
          selectType: 1,
          operateUserId: ''
        }
      },
      tableData: [],
      tableHeaderList: [
        // 操作时间
        { id: 1, label: this.$t('GlobalSetObj.operationTime'), prop: 'operateTime', cloWidth: '170' },
        // 运单号
        { id: 2, label: this.$t('newOrder.waybillNumber'), prop: 'waybillNumber', cloWidth: '180' },
        // 扫描单号
        { id: 3, label: this.$t('GlobalSetObj.ScanNumber'), prop: 'scanOrderNumber', cloWidth: '180' },
        // 预派送网点
        { id: 5, label: this.$t('GlobalSetObj.preEntrega'), prop: 'preSiteName', cloWidth: '170' },
        // 实际派送网点
        { id: 6, label: this.$t('GlobalSetObj.entrega'), prop: 'deliverStationName', cloWidth: '170' },
        // 操作人
        { id: 7, label: this.$t('GlobalSetObj.operator'), prop: 'operateUserName', cloWidth: '180' },
        // 签收类型
        { id: 8, label: this.$t('GlobalSetObj.signType'), prop: 'signTypeName', cloWidth: '180' },
        // 收件人
        { id: 9, label: this.$t('trajectory.recipient'), prop: 'signUserName', cloWidth: '180' },
        // 签收照片
        { id: 10, label: this.$t('GlobalSetObj.signedNamePhoto'), prop: 'signPhotographList', cloWidth: '180' },
        // 签收签名
        { id: 11, label: this.$t('GlobalSetObj.signedPhoto'), prop: 'signerImg', cloWidth: '180' }
      ],
      orderWaybillType: 0,
      userList: []
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '250px' : '120px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getUserList();
    this.getSearch();
  },
  methods: {
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.getTimer();
      this.queryForm.params = {
        inputValue: '',
        selectType: 1
      };
      this.getSearch();
    },
    handleTest() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 查询
    getSearch() {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.operateStartTime = this.timeValue[0];
        data.params.operateEndTime = this.timeValue[1];
      } else {
        data.params.operateStartTime = null;
        data.params.operateEndTime = null;
      }
      if (this.queryForm.params.selectType === 1) {
        data.params.waybillNumberList = inputValList;
        data.params.scanOrderNumberList = [];
      } else {
        data.params.scanOrderNumberList = inputValList;
        data.params.waybillNumberList = [];
      }
      data.params.operateUserId = this.queryForm.params.operateUserId || null;
      data.params.orderWaybillType = this.orderWaybillType;
      manyTimesSignpage(data).then(res => {
        if (res.code===200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.map((item) => {
            const hasPermission = this.$store.getters.button_permissions.includes('field:customer-sensitive-info');
            if (!hasPermission) {
              item.signUserName = commonDesensitize(item.signUserName);
            }
          });
        }
      });
    },
    allExportOrder() {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      const data = {};
      if (this.timeValue && this.timeValue.length > 0) {
        data.operateStartTime = this.timeValue[0];
        data.operateEndTime = this.timeValue[1];
      } else {
        data.operateStartTime = null;
        data.operateEndTime = null;
      }
      if (this.queryForm.params.selectType === 1) {
        data.waybillNumberList = inputValList;
        data.scanOrderNumberList = [];
      } else {
        data.scanOrderNumberList = inputValList;
        data.waybillNumberList = [];
      }
      data.operateUserId = this.queryForm.params.operateUserId || null;
      data.orderWaybillType = this.orderWaybillType;
      manyTimesExportQuery(data).then(res => {
        goExportList(this);
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      const oneWeekAgo = dayjs(brazilTime).subtract(1, 'week').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    getUserList(name) {
      console.log('被调用了', name);
      // 获取操作员
      const param = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          accountState: '1',
          usernameLike: ''
        }
      };
      if (name) {
        param.params.usernameLike = name;
      }
      getUserPage(param)
        .then((res) => {
          if (res.code===200) {
            this.userList = res.data.records;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
};
</script>

<style scoped>
.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.WaybillNumber {
  color: #409eff;
  cursor: pointer;
  padding: 0;
}

>>> .el-table__cell {
  position: static !important;
}
</style>
