<template>
  <div class="Box">
    <div v-if="changeVal == 'hz'" style="margin-bottom: 20px;">
      <el-form>
        <el-row
          type="flex" justify="start" style="flex-wrap: wrap;"
          class="selectClass"
        >
          <el-col class="col2">
            <el-select
              v-model="selectTime" :placeholder="$t('collectionCenter.pleaseChoose')" class="autoWidth"
              @change="changeTime"
            >
              <template slot="prefix">
                {{ (timeList.find(item => item.value === selectTime) || {}).time }}
              </template>
              <el-option
                v-for="(item, index) in timeList"
                :key="index"
                :label="item.time"
                :value="item.value"
              />
            </el-select>
          </el-col>
          <el-col :span="8">
            <el-form-item class="delMl">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                type="daterange"
                value-format="yyyy-MM-dd"
                :default-time="['00:00:00', '23:59:59']"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                :end-placeholder="$t('collectionCenter.EndTime')"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 客户名称： -->
            <el-form-item :label="$t('collectionCenter.CustomerN')">
              <el-select
                v-model="queryForm.params.customerId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable @focus="getNewestCustomer"
              >
                <el-option
                  v-for="(item, index) in customerIdList"
                  :key="index"
                  :label="item.username"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;"><!--所属平台 -->
            <el-form-item :label="$t('collectionCenter.PlatformWhichBelongs')">
              <el-select
                v-model="queryForm.params.platform" clearable filterable
                :placeholder="$t('collectionCenter.pleaseChoose')"
              >
                <el-option label="shein" :value="1" /><!-- shein -->
                <el-option label="kwai" :value="2" /><!-- kwai -->
                <el-option :label="$t('collectionCenter.expressDelivery')" :value="3" /><!-- 快递 -->
                <el-option :label="$t('collectionCenter.other')" :value="0" /><!-- 其他 -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!--揽收模式： -->
            <el-form-item :label="$t('collectionCenter.receivingMode') + '：'">
              <el-select
                v-model="queryForm.params.collectType" clearable filterable
                :placeholder="$t('collectionCenter.pleaseChoose')"
              >
                <el-option :label="$t('collectionCenter.dropOff')" :value="1" /><!-- 上门交件 -->
                <el-option :label="$t('collectionCenter.toCollect')" :value="2" /><!-- 上门揽收 -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="selectTime == 'cq1'" :span="6" style="width: auto;">
            <!--实际揽收模式： -->
            <el-form-item :label="$t('collectionCenter.actualCollectionMode') + '：'">
              <el-select
                v-model="queryForm.params.collectModel" clearable filterable
                :placeholder="$t('collectionCenter.pleaseChoose')"
              >
                <el-option label="APP" :value="0" />
                <el-option label="PC" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto">
            <!-- 发件州： -->
            <el-form-item :label="$t('collectionCenter.stateOri')">
              <el-input
                v-model="queryForm.params.state"
                type="textarea"
                autosize
                :placeholder="$t('collectionCenter.BatchQuery')"
                @blur="ftS"
                @keydown.enter.native="BlockNewlineState"
              /><!-- 支持批量查询(回车换行分割) -->
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto">
            <!-- 发件城市： -->
            <el-form-item :label="$t('collectionCenter.sendingCt')">
              <el-input
                v-model="queryForm.params.city"
                type="textarea"
                autosize
                :placeholder="$t('collectionCenter.BatchQuery')"
                @blur="ftC"
                @keydown.enter.native="BlockNewlineCity"
              /><!-- 支持批量查询(回车换行分割) -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="space-between">
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:report:collect-order:find'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:report:collect-order:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-if="changeVal == 'hz'"
            v-permit:remove="'btn:collection-center:report:collect-order:allExport'"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll1 ? 'onlyClickOnce10' : ''"
            @click="downloadAll"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
        </el-col>
      </el-row>
    </div>
    <div v-if="changeVal == 'mx'">
      <el-button
        v-permit:remove="'btn:collection-center:report:collect-order:allExportMX'"
        icon="el-icon-upload"
        size="mini"
        :class="isClickAll2 ? 'onlyClickOnce10' : ''"
        @click="downloadAllMX"
      >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
    </div>

    <el-divider />
    <el-tabs v-model="changeVal" type="border-card">
      <!-- 汇总 -->
      <el-tab-pane :label="$t('collectionCenter.summary')" name="hz">
        <el-table
          ref="tableList"
          :max-height="600"
          border
          show-summary
          :summary-method="getSummaries"
          :data="tableDate"
        >
          <el-table-column
            type="selection"
            width="55"
          />
          <!-- 序号 -->
          <el-table-column
            :label="$t('collectionCenter.ind')" type="index" width="60"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="platformName"
            :label="$t('collectionCenter.platform')"
          /><!-- 所属平台 -->
          <el-table-column
            align="center"
            prop="customerId"
            :label="$t('collectionCenter.clientID')"
          /><!-- 客户编号 -->
          <el-table-column
            align="center"
            prop="customerName"
            :label="$t('collectionCenter.CustomerName')"
          /><!-- 客户名称 -->
          <el-table-column
            v-if="cacheSTime == 'cq0'"
            align="center"
            prop="createDate"
            :label="$t('collectionCenter.orderCreateT')"
          /><!-- 订单创建时间 -->
          <el-table-column
            v-if="cacheSTime == 'cq1'"
            align="center"
            prop="collectDate"
            :label="$t('collectionCenter.ActualCollectionTime')"
          /><!-- 实际揽收时间 -->
          <el-table-column
            align="center"
            prop="collectTypeName"
            :label="$t('collectionCenter.receivingMode')"
          /><!-- 揽收模式 -->
          <el-table-column
            v-if="cacheSTime == 'cq1'"
            align="center"
            prop="collectModel"
            :label="$t('collectionCenter.actualCollectionMode')"
          /><!-- 实际揽收模式 -->
          <el-table-column
            align="center"
            prop="state"
            :label="$t('collectionCenter.state')"
          /><!-- 州 -->
          <el-table-column
            align="center"
            prop="city"
            :label="$t('collectionCenter.city')"
          /><!-- 城市 -->
          <el-table-column
            align="center"
            prop="orderNum"
            :label="$t('collectionCenter.orderNum')"
          /><!-- 应揽收量 -->
          <el-table-column
            align="center"
            prop="collectNum"
            :label="$t('collectionCenter.collectNum')"
          /><!-- 实际揽收量 -->
          <el-table-column
            align="center"
            prop="unreceivedNum"
            :label="$t('collectionCenter.unreceivedNum')"
          ><!-- 未揽收量 -->
            <template slot-scope="scope">
              <!-- 编辑 -->
              <el-button v-if="cacheSTime == 'cq0' && hzTomx" size="mini" @click.stop="detailClick(scope.row)">{{ scope.row.unreceivedNum }}</el-button>
              <div v-else>{{ scope.row.unreceivedNum }}</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getTable"
            @size-change="getPageSize"
          />
        </div>
      </el-tab-pane>
      <!-- 明细 -->
      <el-tab-pane v-if="changeVal == 'mx'" :label="$t('collectionCenter.particulars')" name="mx">
        <el-table
          ref="tableList"
          :max-height="600"
          border
          show-summary
          :summary-method="getSummariesMX"
          :data="tableDateMX"
        >
          <el-table-column
            type="selection"
            width="55"
          />
          <!-- 序号 -->
          <el-table-column
            :label="$t('collectionCenter.ind')" type="index" width="60"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ (queryFormMX.pageNumber - 1) * queryFormMX.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="merchantCode"
            :label="$t('collectionCenter.MerchantNum')"
          /><!-- 商户号 -->
          <el-table-column
            prop="merchantName"
            :label="$t('collectionCenter.MerchantName')"
          /><!-- 商户名称 -->
          <el-table-column
            prop="unreceivedNum"
            :label="$t('collectionCenter.unreceivedOrder')"
          /><!-- 未揽收订单 -->
        </el-table>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryFormMX.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :page-size.sync="queryFormMX.pageSize"
            :total="totalMX"
            background
            style="margin: 10px 0"
            @current-change="getTableMX"
            @size-change="getPageSizeMX"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  queryAllCustomer,
  collectCreateReportPage,
  collectCreateReportExport,
  collectCreateReportDetailPage,
  collectCreateReportDetailExport
} from '@/api/lanshou';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'CollectOrder',
  data() {
    return {
      isClickAll1: false,
      isClickAll2: false,
      notEnterC: true, // 是否允许换行——城市
      notEnterS: true, // 是否允许换行——州
      hzTomx: this.$store.getters.button_permissions.includes('btn:collection-center:report:collect-order:hzTomx'),
      changeVal: 'hz',
      selectTime: 'cq0', // 选择订单创建时间 / 实际揽收时间
      cacheSTime: '', // 缓存切换的时间
      timeList: [
        { value: 'cq0', time: this.$t('collectionCenter.orderCreateT') }, // '订单创建时间'
        { value: 'cq1', time: this.$t('collectionCenter.ActualCollectionTime') } // '实际揽收时间'
      ],
      timeValue: '',
      customerIdList: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          createStartDate: '',
          createEndDate: '',
          collectStartDate: '',
          collectEndDate: '',
          customerId: '',
          platform: '',
          collectType: '',
          collectModel: '',
          state: '',
          city: ''
        }
      },
      total: 0,
      tableDate: [],
      queryFormMX: {},
      totalMX: 0,
      tableDateMX: []
    };
  },
  watch: {
    timeValue() {
      if (this.timeValue && this.timeValue.length <= 0) {
        // 创建时间
        this.queryForm.params.createStartDate = '';
        this.queryForm.params.createEndDate = '';
        // 揽收时间
        this.queryForm.params.collectStartDate = '';
        this.queryForm.params.collectEndDate = '';
      }
    },
    'queryForm.params.state'(val1) {
      const bgh = val1.split('\n');
      let stateVal = [];
      if (bgh.length >= 100) {
        stateVal = bgh.slice(0, 100).join('\n');
        this.queryForm.params.state = stateVal;
        this.notEnterS = false;
      } else {
        this.notEnterS = true;
      }
      if (bgh.length > 100) {
        this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
      }
    },
    'queryForm.params.city'(val) {
      const bgh = val.split('\n');
      let cityVal = [];
      if (bgh.length >= 100) {
        cityVal = bgh.slice(0, 100).join('\n');
        this.queryForm.params.city = cityVal;
        this.notEnterC = false;
      } else {
        this.notEnterC = true;
      }
      if (bgh.length > 100) {
        this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
      }
    }
  },
  created() {
    this.getNewestCustomer();
    this.default();
    this.selectTime = 'cq0';
    this.cacheSTime = 'cq0';
    this.searchClick();
    this.isClickAll1 = false;
    this.isClickAll2 = false;
  },
  methods: {
    // 获取最新的客户list
    getNewestCustomer() {
      queryAllCustomer().then(res => {
        if (res.code===200) {
          this.customerIdList = [];
          res.data.forEach((list) => {
            this.customerIdList.push({
              id: list.id,
              username: list.username
            });
          });
        }
      });
    },
    changeTime() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      // 创建时间
      this.queryForm.params.createStartDate = '';
      this.queryForm.params.createEndDate = '';
      // 揽收时间
      this.queryForm.params.collectStartDate = '';
      this.queryForm.params.collectEndDate = '';
      if (this.selectTime === 'cq0') {
        this.queryForm.params.createStartDate = today + ' 00:00:00';
        this.queryForm.params.createEndDate = today + ' 23:59:59';
      }
      if (this.selectTime === 'cq1') {
        this.queryForm.params.collectStartDate = today + ' 00:00:00';
        this.queryForm.params.collectEndDate = today + ' 23:59:59';
      }
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        return;
      }
      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-1, 'month').format('YYYY-MM-DD') + ' 23:59:59';
        if (val[1] > endTime) {
          this.$message({
            message: this.$t('collectionCenter.OnlyAllowedOneMonthsOfData'), // '只允许查1个月内的数据'
            type: 'warning'
          });
          this.changeTime();
          return;
        }
      }
      // 创建时间
      this.queryForm.params.createStartDate = '';
      this.queryForm.params.createEndDate = '';
      // 揽收时间
      this.queryForm.params.collectStartDate = '';
      this.queryForm.params.collectEndDate = '';
      if (this.selectTime === 'cq0') {
        this.queryForm.params.createStartDate = val[0] + ' 00:00:00';
        this.queryForm.params.createEndDate = val[1] + ' 23:59:59';
      }
      if (this.selectTime === 'cq1') {
        this.queryForm.params.collectStartDate = val[0] + ' 00:00:00';
        this.queryForm.params.collectEndDate = val[1] + ' 23:59:59';
      }
    },

    ftS() {
      const val11 = this.queryForm.params.state.split('\n');
      // 过滤空项
      const numLen = val11.filter(function(f) {
        return f && f.trim();
      });
      // // 去除空格
      // const bghTrim = numLen.map(item => {
      //   return item.replace(/\s/g, '').trim();
      // });
      this.queryForm.params.state = numLen.join('\n');
    },
    ftC() {
      const val22 = this.queryForm.params.city.split('\n');
      // 过滤空项
      const numLen2 = val22.filter(function(f) {
        return f && f.trim();
      });
      // // 去除空格
      // const bghTrim2 = numLen2.map(item => {
      //   return item.replace(/\s/g, '').trim();
      // });
      this.queryForm.params.city = numLen2.join('\n');
    },
    BlockNewlineState() {
      const e = window.event || arguments[0];
      const stateLen = this.queryForm.params.state.split('\n');
      if (!this.notEnterS) {
        if (stateLen.length >= 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    BlockNewlineCity() {
      const e = window.event || arguments[0];
      const cityLen = this.queryForm.params.city.split('\n');
      if (!this.notEnterC) {
        if (cityLen.length >= 100) {
          this.$message.warning(this.$t('collectionCenter.intercept100')); // '单次查询最多100条，已截取前100条数据'
        }
        e.returnValue = false;
        return true;
      }
    },

    default() {
      this.selectTime = 'cq0';
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          createStartDate: today + ' 00:00:00',
          createEndDate: today + ' 23:59:59',
          collectStartDate: '',
          collectEndDate: '',
          customerId: '',
          platform: '',
          collectType: '',
          collectModel: '',
          state: '',
          city: ''
        }
      };
    },
    searchClick() {
      if (this.timeValue.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime')); // 请选择日期时间
        return;
      }
      if (this.selectTime === 'cq0') {
        this.queryForm.params.collectModel = '';
      }
      localStorage.setItem('cOselectTime', JSON.stringify(this.selectTime));
      this.cacheSTime = JSON.parse(localStorage.getItem('cOselectTime')) || this.selectTime;
      localStorage.setItem('cOQueryForm', JSON.stringify(this.queryForm));
      this.getTable();
    },
    getTable() {
      const queryForm = JSON.parse(localStorage.getItem('cOQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      collectCreateReportPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableDate = [];
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      }).catch({});
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.all');
          return;
        }
        if (
          column.label === this.$t('collectionCenter.orderNum') ||
          column.label === this.$t('collectionCenter.collectNum') ||
          column.label === this.$t('collectionCenter.unreceivedNum')
        ) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    },

    getPageSize() {
      this.queryForm.pageNumber = 1;
      const queryForm = JSON.parse(localStorage.getItem('cOQueryForm')) || this.queryForm;
      queryForm.pageNumber = 1;
      queryForm.pageSize = this.queryForm.pageSize;
      collectCreateReportPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableDate = [];
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      }).catch({});
    },
    resetClick() {
      this.default();
    },
    downloadAll() {
      this.isClickAll1 = true;
      const queryForm = JSON.parse(localStorage.getItem('cOQueryForm')) || this.queryForm;
      collectCreateReportExport(queryForm).then(res => {
        if (res.code===200) {
          this.isClickAll1 = false;
          goExportList(this);
        }
      }).catch(() => {
        this.isClickAll1 = false;
      });
    },
    // 跳转明细
    detailClick(row) {
      this.changeVal = 'mx';
      this.queryFormMX = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          reportId: row.id || '',
          platform: row.platform,
          customerId: row.customerId,
          customerName: row.customerName,
          collectType: row.collectType,
          state: row.state,
          city: row.city,
          createStartDate: row.createDate + ' 00:00:00',
          createEndDate: row.createDate + ' 23:59:59'
        }
      };
      collectCreateReportDetailPage(this.queryFormMX).then(res => {
        if (res.code===200) {
          this.totalMX = res.data.total;
          this.tableDateMX = res.data.records;
        }
      }).catch({});
    },
    getTableMX() {
      // this.queryFormMX = {
      //   pageNumber: this.queryFormMX.pageNumber,
      //   pageSize: this.queryFormMX.pageSize,
      //   params: {
      //     reportId: this.queryFormMX.params.reportId,
      //     platform: this.queryFormMX.params.platform,
      //     customerId: this.queryFormMX.params.customerId,
      //     customerName: this.queryFormMX.params.customerName,
      //     collectType: this.queryFormMX.params.collectType,
      //     state: this.queryFormMX.params.state,
      //     city: this.queryFormMX.params.city,
      //     createStartDate: this.queryFormMX.params.createStartDate,
      //     createEndDate: this.queryFormMX.params.createEndDate
      //   }
      // };
      collectCreateReportDetailPage(this.queryFormMX).then(res => {
        if (res.code===200) {
          this.totalMX = res.data.total;
          this.tableDateMX = res.data.records;
        }
      }).catch({});
    },
    getPageSizeMX() {
      this.queryFormMX.pageNumber = 1;
      collectCreateReportDetailPage(this.queryFormMX).then(res => {
        if (res.code===200) {
          this.totalMX = res.data.total;
          this.tableDateMX = res.data.records;
        }
      }).catch({});
    },
    // 明细导出
    downloadAllMX() {
      this.isClickAll2 = true;
      collectCreateReportDetailExport(this.queryFormMX).then(res => {
        if (res.code===200) {
          this.isClickAll2 = false;
          goExportList(this);
        }
      }).catch(() => {
        this.isClickAll2 = false;
      });
    },
    getSummariesMX(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.all');
          return;
        }
        if (
          column.label === this.$t('collectionCenter.unreceivedOrder')
        ) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    }
  }
};
</script>

<style lang="scss" scoped>

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce2{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce2:active{
    filter: opacity(40%);
    animation: none;
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }

  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .selectClass ::v-deep .el-select{
    width: 100%;
  }
  .selectClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep textarea.el-textarea__inner {
    max-height: 150px !important;
    min-height: 40px !important;
  }
  .col2 {
    width: auto !important;
  }
  .autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
    position: relative;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    visibility: hidden;
    white-space: nowrap;
  }
  .autoWidth ::v-deep input {
    position: absolute;
  }
  .col2 ::v-deep.el-input--suffix .el-input__inner {
    border: none !important;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    padding-left: 0;
    box-sizing: border-box;
  }
  .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
    width: 100%;
  }
  .delMl ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 100%;
  }
</style>
