import $i18n from '../lang/index';

export const waybillStatusList = [
  // { label: $i18n.t('newOrder.all'), code: 0 }, // 待分配
  // { label: $i18n.t('newOrder.ToBeAssigned'), code: 1 }, // 待分配
  { label: $i18n.t('newOrder.ToBeCollected'), code: 2 }, // 待揽收
  { label: $i18n.t('newOrder.Collected'), code: 3 }, // 已揽收
  // { label: $i18n.t('newOrder.CollectTheRevenuePool'), code: 4 }, // 揽收入库
  // { label: $i18n.t('newOrder.CollectTakeOutWarehouse'), code: 5 }, // 揽收出库
  // { label: $i18n.t('newOrder.Collected'), code: 19 }, // 末端已揽收   ===>   已揽收
  { label: $i18n.t('newOrder.TransshipmentIntoStorage'), code: 6 }, // 转运入库
  { label: $i18n.t('newOrder.TransferOutWarehouse'), code: 7 }, // 转运出库
  { label: $i18n.t('newOrder.DeliveryOutletsIntoStorage'), code: 8 }, // 派送网点入库
  { label: $i18n.t('newOrder.Delivering'), code: 9 }, // 派送中
  { label: $i18n.t('newOrder.Signed'), code: 10 }, // 已签收
  { label: $i18n.t('newOrder.ProblemPiece'), code: 11 }, // 问题件
  { label: $i18n.t('GlobalSetObj.Canceled'), code: 13 }, // 已取消
  // // { label: $i18n.t('GlobalSetObj.deliveryFailure'), code: 14 }, // 投递失败
  { label: $i18n.t('GlobalSetObj.DotBack'), code: 17 }, // 网点退回
  { label: $i18n.t('GlobalSetObj.ReturnToStorage'), code: 18 }, // 退仓入库
  { label: $i18n.t('GlobalSetObj.Withdrawn'), code: 15 }, // 退件中
  { label: $i18n.t('GlobalSetObj.TheReturnComplete'), code: 16 }, // 退件完成
  { label: $i18n.t('GlobalSetObj.mutilSign'), code: 20 } // 多次签收
  // { label: $i18n.t('GlobalSetObj.financialProcessing'), code: 21 }, // 财务处理中
  // { label: $i18n.t('GlobalSetObj.financialClosedLoop'), code: 22 } // 财务闭环
];

export const waybillStatusObj = {
  // 1: { label: $i18n.t('newOrder.ToBeAssigned'), code: 1 }, // 待分配
  2: { label: $i18n.t('newOrder.ToBeCollected'), code: 2 }, // 待揽收
  3: { label: $i18n.t('newOrder.Collected'), code: 3 }, // 已揽收
  // 4: { label: $i18n.t('newOrder.CollectTheRevenuePool'), code: 4 }, // 揽收入库
  // 5: { label: $i18n.t('newOrder.CollectTakeOutWarehouse'), code: 5 }, // 揽收出库
  19: { label: $i18n.t('newOrder.Collected'), code: 19 }, // 末端已揽收   ===>  已揽收
  6: { label: $i18n.t('newOrder.TransshipmentIntoStorage'), code: 6 }, // 转运入库
  7: { label: $i18n.t('newOrder.TransferOutWarehouse'), code: 7 }, // 转运出库
  8: { label: $i18n.t('newOrder.DeliveryOutletsIntoStorage'), code: 8 }, // 派送网点入库
  9: { label: $i18n.t('newOrder.Delivering'), code: 9 }, // 派送中
  10: { label: $i18n.t('newOrder.Signed'), code: 10 }, // 已签收
  11: { label: $i18n.t('newOrder.ProblemPiece'), code: 11 }, // 问题件
  12: { label: $i18n.t('collectionCenter.unclaimed'), code: 12 }, // 待取件
  13: { label: $i18n.t('GlobalSetObj.Canceled'), code: 13 }, // 已取消
  17: { label: $i18n.t('GlobalSetObj.DotBack'), code: 17 }, // 网点退回
  18: { label: $i18n.t('GlobalSetObj.ReturnToStorage'), code: 18 }, // 退仓入库
  15: { label: $i18n.t('GlobalSetObj.Withdrawn'), code: 15 }, // 退件中
  16: { label: $i18n.t('GlobalSetObj.TheReturnComplete'), code: 16 }, // 退件完成
  20: { label: $i18n.t('GlobalSetObj.mutilSign'), code: 20 }, // 多次签收
  21: { label: $i18n.t('GlobalSetObj.financialProcessing'), code: 21 }, // 财务处理中
  22: { label: $i18n.t('GlobalSetObj.financialClosedLoop'), code: 22 }, // 财务闭环
  200: { label: '', code: 200 } // 问题件
};
export const headerList = [
  { id: 2, label: $i18n.t('newOrder.orderCreateTime'), prop: 'createTime', cloWidth: 180 },
  // 客户名称
  {
    id: 21,
    label: $i18n.t('newOrder.CustomerName'),
    prop: 'customerName',
    clickClass: false,
    cloWidth: 100
  },
  { id: 3, label: $i18n.t('newOrder.waybillStatus'), prop: 'waybillStatusName' },
  {
    id: 30,
    label: $i18n.t('orderCenterCont.latestStatusTime'),
    prop: 'lastTrackTime',
    cloWidth: 180
  },
  {
    id: 50,
    label: $i18n.t('orderCenterCont.zuixinguijineir'),
    prop: 'lastTrackDescribe',
    cloWidth: 180
  },
  { id: 51, label: $i18n.t('orderCenterCont.wentijianyuanyin'), prop: 'deliveryFailReason' },
  { id: 52, label: $i18n.t('placeOrder.cargoType'), prop: 'cargoTypeName' },
  {
    id: 5,
    label: $i18n.t('newOrder.orderNumber'),
    prop: 'customerNumber',
    clickClass: false,
    cloWidth: 180
  },
  {
    id: 1,
    label: $i18n.t('orderCenterCont.anjunWaybillNumber'),
    prop: 'waybillNumber',
    cloWidth: 180
  },
  {
    id: 999,
    label: $i18n.t('GlobalSetObj.ScanNumber'),
    prop: 'scanOrderNumber',
    cloWidth: 180
  },
  {
    id: 4,
    label: $i18n.t('GlobalSetObj.serviceLogistics'),
    prop: 'logisticsNumber',
    clickClass: false,
    cloWidth: 180
  },
  {
    id: 31,
    label: $i18n.t('newOrder.ActualCollection'),
    prop: 'collectStationName',
    cloWidth: 100
  },
  // 揽收袋号
  // { id: 8, label: $i18n.t('orderCenterCont.PickUpBagNumber'), prop: 'bagNumber', cloWidth: 120 },
  // 派送距离
  {
    id: 993,
    label: $i18n.t('newOrder.deliveryDistance'),
    prop: 'deliveryDistance',
    clickClass: false,
    cloWidth: 120
  },
  {
    id: 39,
    label: $i18n.t('GlobalSetObj.preEntrega'),
    prop: 'preDeliverStationName',
    cloWidth: 100
  },
  { id: 32, label: $i18n.t('GlobalSetObj.entrega'), prop: 'deliverStationName' },
  { id: 994, label: $i18n.t('orderCenterCont.deliveryPerson'), prop: 'deliveryManName' },
  { id: 997, label: $i18n.t('GlobalSetObj.CustomerWeight'), prop: 'customerWeight' },
  {
    id: 33,
    label: $i18n.t('orderCenterCont.incomingWeightOfTransitWarehouse'),
    prop: 'transportWeight'
  },
  // 开票金额 BRL
  {
    id: 1009,
    label: $i18n.t('placeOrder.InvoiceAmount') + '(BRL)',
    prop: 'invoiceMoney',
    clickClass: false,
    cloWidth: 120
  },
  // 中转仓袋号
  {
    id: 9,
    label: $i18n.t('orderCenterCont.TransitBinBagNumber'),
    prop: 'transitBagNumber',
    clickClass: false,
    cloWidth: 120
  },
  { id: 34, label: $i18n.t('newOrder.RecipientName'), prop: 'receiveName' },
  { id: 35, label: $i18n.t('newOrder.RecipientZipCode'), prop: 'receiveZipcode' },
  { id: 36, label: $i18n.t('orderCenterCont.recipientProvince'), prop: 'receiveState' },
  { id: 37, label: $i18n.t('newOrder.RecipientCity'), prop: 'receiveCity' },
  { id: 40, label: $i18n.t('orderCenterCont.SenderzipCode'), prop: 'senderZipcode' },
  { id: 41, label: $i18n.t('orderCenterCont.SenderProvince'), prop: 'senderState' },
  { id: 42, label: $i18n.t('orderCenterCont.SenderCity'), prop: 'senderCity' },
  {
    id: 43,
    label: $i18n.t('orderCenterCont.customerDeliveryTime'),
    prop: 'customerSendTime'
  },
  {
    id: 44,
    label: $i18n.t('orderCenterCont.customerDeliveryStatusDescription'),
    prop: 'customerSendStatus'
  },
  { id: 488, label: $i18n.t('newOrder.orderNote'), prop: 'waybillRemark' },
  { id: 489, label: $i18n.t('collectionCenter.MerchantNum'), prop: 'merchantCode' },
  { id: 490, label: $i18n.t('collectionCenter.MerchantName'), prop: 'merchantName' }
  // { id: 491, label: $i18n.t('newOrder.InvoiceKey'), prop: 'invoiceKey' }
];

export const tableHeaderForm = [
  $i18n.t('newOrder.orderCreateTime'),
  $i18n.t('newOrder.CustomerName'),
  $i18n.t('newOrder.waybillStatus'),
  $i18n.t('orderCenterCont.latestStatusTime'),
  $i18n.t('orderCenterCont.zuixinguijineir'),
  $i18n.t('orderCenterCont.wentijianyuanyin'),
  $i18n.t('placeOrder.cargoType'),
  $i18n.t('newOrder.orderNumber'),
  $i18n.t('orderCenterCont.anjunWaybillNumber'),
  $i18n.t('GlobalSetObj.ScanNumber'),
  $i18n.t('GlobalSetObj.serviceLogistics'),
  $i18n.t('newOrder.ActualCollection'),
  $i18n.t('GlobalSetObj.preEntrega'),
  $i18n.t('orderCenterCont.customerDeliveryTime'),
  $i18n.t('orderCenterCont.customerDeliveryStatusDescription'),
  $i18n.t('GlobalSetObj.entrega'),
  $i18n.t('orderCenterCont.deliveryPerson'),
  $i18n.t('GlobalSetObj.CustomerWeight'),
  $i18n.t('placeOrder.InvoiceAmount') + '(BRL)',
  $i18n.t('orderCenterCont.incomingWeightOfTransitWarehouse'),
  $i18n.t('orderCenterCont.TransitBinBagNumber'),
  $i18n.t('newOrder.RecipientName'),
  $i18n.t('newOrder.RecipientZipCode'),
  $i18n.t('orderCenterCont.recipientProvince'),
  $i18n.t('newOrder.RecipientCity'),
  $i18n.t('orderCenterCont.SenderzipCode'),
  $i18n.t('orderCenterCont.SenderProvince'),
  $i18n.t('orderCenterCont.SenderCity'),
  $i18n.t('newOrder.deliveryDistance'),
  $i18n.t('newOrder.orderNote'),
  $i18n.t('collectionCenter.MerchantNumBer'),
  $i18n.t('collectionCenter.MerchantN')
];
