var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "mb5" },
        [
          _c(
            "el-form",
            {
              staticClass: "searchContainer",
              attrs: { "label-position": "top" },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "numberClassSelect",
                  attrs: { label: _vm.$t("GlobalSetObj.client") },
                },
                [
                  _c("CustomerSelectCmp", {
                    staticStyle: { width: "25%" },
                    attrs: { "is-all": true },
                    model: {
                      value: _vm.query.params.customerId,
                      callback: function ($$v) {
                        _vm.$set(_vm.query.params, "customerId", $$v)
                      },
                      expression: "query.params.customerId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small", icon: "el-icon-search" },
              on: { click: _vm.onQuery },
            },
            [_vm._v(_vm._s(_vm.$t("operationCenter.Procurar")))]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", icon: "el-icon-refresh-left" },
              on: { click: _vm.onReset },
            },
            [_vm._v(" " + _vm._s(_vm.$t("operationCenter.reset")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "el-button",
            {
              staticClass: "mb10",
              attrs: { size: "small", type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.onAdd },
            },
            [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.append")) + " ")]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "min-height": "300px" },
              attrs: { data: _vm.tableData, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operationCenter.ind"),
                  width: "60px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.query.pageNumber - 1) * _vm.query.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.client"),
                  prop: "customerName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.ClaimsNode"),
                  prop: "claimsNodeStr",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label:
                    _vm.$t("ClaimsManagement.DeclarationValueThreshold") +
                    "(<=)",
                  prop: "declaredValue",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.DefaultAuditor"),
                  prop: "defaultReviewerName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.LastUpdater"),
                  prop: "updateUserName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.LastUpdateTime"),
                  prop: "updateTime",
                },
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("GlobalSetObj.operate") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("GlobalSetObj.edit")))]
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit:remove",
                                value:
                                  "btn:finance:basicData:payable:feesType-editAdd",
                                expression:
                                  "'btn:finance:basicData:payable:feesType-editAdd'",
                                arg: "remove",
                              },
                            ],
                            attrs: {
                              title: _vm.$t(
                                "basicData.AreYouSureYouWantToDelete"
                              ),
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.onDelete(scope.row.id)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "ml",
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  slot: "reference",
                                  type: "danger",
                                  size: "mini",
                                },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(_vm.$t("basicData.Delete")))]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  "current-page": _vm.query.pageNumber,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.query.pageSize,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.query, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.query, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.query, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.query, "pageSize", $event)
                  },
                  "current-change": _vm.getCurrentTableData,
                  "size-change": _vm.getSizeTableData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("GlobalSetObj.tips"),
            visible: _vm.dialogVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("FormCmp", {
                attrs: { ids: _vm.editId, row: _vm.form },
                on: { close: _vm.onClose },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }