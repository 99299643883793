<template>
  <div class="Box">

    <div style="margin-bottom: 20px;">
      <el-form class="findClass">
        <el-row>
          <el-col :span="7" style="width: auto;">
            <!-- 所属网点： -->
            <el-form-item :label="$t('collectionCenter.belongDot')">
              <el-select
                v-model="queryForm.params.siteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in siteIdList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 是否启用： -->
            <el-form-item :label="$t('collectionCenter.ifEnabled')">
              <el-select v-model="queryForm.params.isActive" :placeholder="$t('collectionCenter.pleaseChoose')" clearable>
                <el-option :label="$t('collectionCenter.enable')" :value="0" />
                <el-option :label="$t('collectionCenter.disable')" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24" style="margin-left: 20px">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="addClick"
          >{{ $t('collectionCenter.add') }}</el-button><!-- 新增 -->
        </el-col>
      </el-row>
    </div>
    <div>
      <el-table
        ref="tableRef"
        border
        :max-height="600"
        :data="tableData"
      >
        <el-table-column
          prop="lockerNumber"
          align="center"
          :label="$t('collectionCenter.cabinetNumber')"
        /><!-- 柜子编号 -->
        <el-table-column
          prop="lockerType"
          align="center"
          :label="$t('collectionCenter.cabinetType')"
        ><!-- 柜子类型 -->
          <template slot-scope="scope">
            <div>
              <!-- 0 一拖一  -->
              {{ scope.row.lockerType == 0 ? $t('collectionCenter.towOne') : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="siteName"
          align="center"
          :label="$t('collectionCenter.belongD')"
        /><!-- 所属网点 -->
        <el-table-column
          prop="address"
          align="center"
          :label="$t('collectionCenter.cabinet')"
        /><!-- 柜子位置 -->
        <el-table-column
          prop="isActive"
          align="center"
          :label="$t('collectionCenter.ifUse')"
        ><!-- 是否启用 -->
          <template slot-scope="scope">
            <div>
              <!-- 0 启用    1 禁用 -->
              {{ scope.row.isActive == 0 ? $t('collectionCenter.enable') :
                scope.row.isActive == 1 ? $t('collectionCenter.disable') : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="latLongitude"
          align="center"
          :label="$t('collectionCenter.LAL')"
        /><!-- 经纬度 -->
        <el-table-column
          prop="remark"
          align="center"
          :label="$t('collectionCenter.Note')"
        /><!-- 备注 -->
        <el-table-column
          align="center"
          prop="createUserStr"
          :label="$t('collectionCenter.creator')"
        /><!-- 创建人 -->
        <el-table-column
          align="center"
          prop="createTime"
          :label="$t('collectionCenter.CreationTime')"
        /><!-- 创建时间 -->
        <el-table-column
          align="center"
          prop="updateUserStr"
          :label="$t('collectionCenter.ModifiedBy')"
        /><!-- 修改人 -->
        <el-table-column
          align="center"
          prop="updateTime"
          :label="$t('collectionCenter.ModificationTime')"
        /><!-- 修改时间 -->
        <el-table-column
          :label="$t('operatingPlatform.operation')"
          fixed="right"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-button
              style="margin-right: 5px;"
              size="mini"
              @click="editClick(scope.row)"
            >{{ $t('collectionCenter.modify') }}</el-button><!-- 修改 -->
            <el-popconfirm
              :title="$t('collectionCenter.deleteSure')"
              @confirm="delClick(scope.row)"
            ><!-- 确定删除吗？ -->
              <el-button slot="reference" type="danger" size="mini">{{ $t('collectionCenter.Delete') }}</el-button><!-- 删除 -->
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getPage"
        @size-change="searchClick"
      />
    </div>

    <div v-if="drawerIsOpen">
      <el-drawer
        :title="drawerTitle"
        :visible.sync="drawerIsOpen"
        :direction="direction"
        size="40%"
        @close="closeDrawer"
      >
        <div class="drawerClass">
          <el-form
            ref="drawerFormRef" :model="drawerForm" :rules="rules"
            label-position="top"
          >
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 柜子编号： -->
                <el-form-item prop="lockerNumber" :label="$t('collectionCenter.cabinetNum')">
                  <el-input
                    v-model="drawerForm.lockerNumber" :disabled="true" placeholder=""
                    clearable
                  />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 所属网点： -->
                <el-form-item prop="siteId" :label="$t('collectionCenter.belongDot')">
                  <el-select
                    v-model="drawerForm.siteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                    filterable @focus="getSiteList"
                  >
                    <el-option
                      v-for="(item, index) in siteIdList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 是否启用： -->
                <el-form-item prop="isActive" :label="$t('collectionCenter.ifEnabled')">
                  <el-select v-model="drawerForm.isActive" :placeholder="$t('collectionCenter.pleaseChoose')" clearable>
                    <el-option :label="$t('collectionCenter.enable')" :value="0" />
                    <el-option :label="$t('collectionCenter.disable')" :value="1" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 柜子位置： -->
                <el-form-item prop="address" :label="$t('collectionCenter.cabinetAddress')">
                  <el-input v-model="drawerForm.address" :placeholder="$t('collectionCenter.pleaseInput')" clearable />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 经纬度： -->
                <el-form-item prop="latLongitude" :label="$t('collectionCenter.longitudeAndLatitude')">
                  <el-input v-model="drawerForm.latLongitude" :placeholder="$t('collectionCenter.pleaseInput')" clearable />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 柜子类型： -->
                <el-form-item prop="lockerType" :label="$t('collectionCenter.cabinetT')">
                  <el-select v-model="drawerForm.lockerType" :placeholder="$t('collectionCenter.pleaseChoose')" clearable>
                    <el-option :label="$t('collectionCenter.towOne')" :value="0" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <!-- 备注： -->
                <el-form-item :label="$t('collectionCenter.note')">
                  <div class="textarea">
                    <el-input
                      v-model="drawerForm.remark"
                      type="textarea"
                      autosize
                      maxlength="250"
                      show-word-limit
                      placeholder=""
                    />
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="submitClickBtn">
            <el-button type="success" @click="save">{{ $t('collectionCenter.Save') }}</el-button><!-- 保存 -->
            <el-button @click="cancel">{{ $t('collectionCenter.cancel') }}</el-button><!-- 取消 -->
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import {
  searchSiteIdList
} from '@/api/lanshou';
import {
  getLockerPage,
  generateNumber,
  delGenerateNumber,
  addLocker,
  editLocker,
  delLocker
} from '@/api/smartCabinet';
export default {
  data() {
    return {
      drawerForm: {
        id: '',
        lockerNumber: '',
        lockerType: '',
        address: '',
        latLongitude: '',
        siteId: '',
        isActive: 0,
        remark: ''
      },
      rules: {
        lockerNumber: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        siteId: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        isActive: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        address: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        lockerType: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }]
      },
      drawerIsOpen: false,
      drawerTitle: '',
      direction: 'ltr',
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          siteId: '',
          isActive: ''
        }
      },
      siteIdList: [],
      total: 0,
      tableData: [{}],
      userIdAndNameObj: null
    };
  },
  created() {
    this.getSiteList();
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.searchClick();
  },
  methods: {
    getSiteList() {
      searchSiteIdList().then(res => {
        if (res.code===200) {
          this.siteIdList = [];
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    searchClick() {
      this.queryForm.pageNumber = 1;
      this.getPage();
    },
    getPage() {
      getLockerPage(this.queryForm).then(res => {
        if (res.code===200) {
          this.total = res.data.total;
          this.tableData = res.data.records.map(item => {
            const updateUserStr = getUserName(item.updateUser, this.userIdAndNameObj);
            const createUserStr = getUserName(item.createUser, this.userIdAndNameObj);
            return {
              ...item,
              updateUserStr,
              createUserStr
            };
          });
        } else {
          this.total = 0;
          this.tableData = [];
        }
      }).catch({});
    },
    resetClick() {
      this.queryForm.params = {
        siteId: '',
        isActive: ''
      };
    },
    addClick() {
      this.drawerTitle = this.$t('collectionCenter.add'); // '新增'
      this.drawerIsOpen = true;
      generateNumber({}).then(res => {
        if (res.code===200) {
          this.drawerForm.id = res.data.id;
          this.drawerForm.lockerNumber = res.data.lockerNumber;
        }
      }).catch({});
    },
    editClick(row) {
      this.drawerForm = {
        id: row.id,
        lockerNumber: row.lockerNumber,
        lockerType: row.lockerType,
        address: row.address,
        latLongitude: row.latLongitude,
        siteId: row.siteId,
        isActive: row.isActive,
        remark: row.remark
      };
      this.drawerIsOpen = true;
      this.drawerTitle = this.$t('collectionCenter.modify'); // '修改'
    },
    delClick(row) {
      // 启用状态的数据不允许删除
      if (row.isActive === 0) {
        this.$message({
          message: this.$t('collectionCenter.enableNotAllowDel'), // '启用状态的数据不允许删除'
          type: 'warning'
        });
        return;
      }
      delLocker(row.id).then(res => {
        if (res.code===200) {
          this.searchClick();
          this.$message({
            message: this.$t('collectionCenter.DeleteSuccessful'), // '删除成功'
            type: 'success'
          });
        }
      }).catch({});
    },

    save() {
      this.$refs['drawerFormRef'].validate(valid => {
        if (!valid) {
          return;
        } else {
          if (this.drawerTitle === this.$t('collectionCenter.add')) {
            addLocker(this.drawerForm).then(res => {
              if (res.code===200) {
                this.cancel();
                this.searchClick();
                this.$message({
                  message: this.$t('collectionCenter.addSuccessful'), // '新增成功'
                  type: 'success'
                });
              }
            }).catch({});
          }
          if (this.drawerTitle === this.$t('collectionCenter.modify')) {
            editLocker(this.drawerForm.id, this.drawerForm).then(res => {
              if (res.code===200) {
                this.cancel();
                this.searchClick();
                this.$message({
                  message: this.$t('collectionCenter.ModificationSuccessful'), // '修改成功'
                  type: 'success'
                });
              }
            }).catch({});
          }
        }
      });
    },
    closeDrawer() {
      if (this.drawerTitle === this.$t('collectionCenter.add')) {
        delGenerateNumber(this.drawerForm.id).then().catch({});
      }
      this.cancel();
    },
    cancel() {
      this.drawerForm = {
        id: '',
        lockerNumber: '',
        lockerType: '',
        address: '',
        latLongitude: '',
        siteId: '',
        isActive: 0,
        remark: ''
      };
      this.$refs['drawerFormRef'].clearValidate();
      this.drawerIsOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>

.submitClickBtn{
  text-align: end;
  margin-top: 30px;
}
.textarea ::v-deep textarea.el-textarea__inner {
  min-height: 40px !important;
  max-height: 200px !important;
}
.drawerClass ::v-deep .el-select{
  width: 100%;
}
.drawerClass ::v-deep .el-textarea .el-input__count{
  background: transparent;
  bottom: -9px;
  right: 18px;
}
.drawerClass{
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
::v-deep .el-table .cell{
  text-overflow: unset;
}
.findClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.findClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.findClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
  width: 100%;
}
</style>
