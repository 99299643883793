<template>
  <div class="app-container">
    <div style="margin-bottom: 20px">
      <el-form
        ref="formSearch"
        :model="queryForm"
        label-position="top"
        size="small"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item :label="$t('GlobalSetObj.ReturnTime')">
              <!--网点入库时间-->
              <el-date-picker
                v-model="queryDateTime"
                :end-placeholder="$t('operationCenter.endDate')"
                :range-separator="$t('operationCenter.to')"
                :start-placeholder="$t('operationCenter.startDate')"
                style="width: 100%"
                type="daterange"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
          <!-- 网点所属州 -->
          <el-col :span="4">
            <el-form-item :label="$t('collectionCenter.stateSite1')">
              <el-select
                v-model="queryForm.params.stateList"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                multiple
                style="width: 100%"
              >
                <el-option
                  v-for="item in StateList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 网点 -->
          <el-col :span="5">
            <el-form-item :label="$t('GlobalSetObj.Outlets')">
              <el-select
                v-model="queryForm.params.siteIdList"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                multiple
                style="width: 100%"
              >
                <el-option
                  v-for="item in siteList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--转运中心-->
            <el-form-item :label="$t('collectionCenter.owningTransitCenter1')">
              <el-select
                v-model="queryForm.params.transitCenterIdList"
                :placeholder="$t('GlobalSetObj.all')"
                clearable
                collapse-tags
                filterable
                multiple
                style="width: 100%;"
              >
                <el-option
                  v-for="item in TransferList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <div>
              <el-button
                icon="el-icon-search"
                size="small"
                type="primary"
                @click="searchResources(1)"
              >{{ $t('operationCenter.Procurar') }}
              </el-button>
              <el-button
                icon="el-icon-upload2"
                size="small" style="margin-bottom: 10px" type="primary"
                @click="allExportOrder"
              >{{ $t('newOrder.export') }}
              </el-button>
              <el-button icon="el-icon-refresh-left" size="small" @click="searchResources(2)">
                {{ $t('operationCenter.reset') }}
              </el-button>
              <el-radio-group
                v-model="queryForm.params.isTest"
                size="small"
                style="margin-left: 10px"
                @change="searchResources(1)"
              >
                <el-radio-button :label="1">{{ $t('newOrder.Formal') }}</el-radio-button>
                <el-radio-button :label="2">{{ $t('newOrder.test1') }}</el-radio-button>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#f2f2f2',color:'black'}" border>
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" type="index"
        width="80"
      />
      <el-table-column
        v-for="(item, ind) in columnList"
        :key="ind"
        :align="item.align"
        :min-width="item.width"
        :prop="item.prop"
        :show-overflow-tooltip="true"
      >
        <template slot="header">
          <div>{{ item.label }}</div>
        </template>
        <template slot-scope="scope">
          <div
            :class="strARR.includes(item.prop) ? 'table-active' :''"
            @click="seeDetial(scope.row,item.prop,scope.row[item.prop], item.typeNum)"
          >
            {{ scope.row[item.prop] || 0 }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="getCurrentTableData"
        @size-change="getSizeTableData"
      />
    </div>
    <!-- 包裹详情-->
    <el-drawer
      :title="$t('customerManagements.detailTitle')"
      :visible.sync="dialogVisible"
      center
      direction="ltr"
      size="1200px"
      @close="closeDrawer"
    >
      <packageDetail
        v-if="dialogVisible"
        ref="votesDetails"
        :dataobj="dataObj"
        @call-parent-method="parentMethod"
      />
    </el-drawer>
  </div>
</template>

<script>
import {
  getLgAreaListData,
  getSiteTransfer,
  returnDataMonitoringexport,
  returnDataMonitoringPage,
  selectInfoByStateTwo
} from '@/api/orderApi';
import dayjs from 'dayjs';

export default {
  name: 'ReturnDataMonitoring',
  components: {
    'packageDetail': () => import('./component/packageDetail')
  },
  data() {
    return {
      dialogVisible: false, // 详情弹窗
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          transitCenterIdList: [],
          stateList: [],
          siteIdList: [],
          startTime: '',
          endTime: '',
          isTest: 1 // 数据类型（1正式 2测试）
        }
      },
      tableData: [],
      total: 0,
      StateList: [], // 州
      columnList: [
        // 网点所属州
        { label: this.$t('collectionCenter.stateSite1'), prop: 'state', width: 160 },
        // 所属转运中心
        { label: this.$t('collectionCenter.owningTransitCenter1'), prop: 'transitCenter', width: 120 },
        // 网点
        { label: this.$t('collectionCenter.dot'), prop: 'site', width: 180 },
        // 退件日期
        { label: this.$t('GlobalSetObj.ReturnTime'), prop: 'returnDate', width: 180 },
        // 退件票数
        { label: this.$t('customerManagements.returnCount'), prop: 'returnCount', width: 180, typeNum: 1 },
        // 二次入库票数
        {
          label: this.$t('customerManagements.secondaryStorageCount'),
          prop: 'secondaryStorageCount',
          width: 180,
          typeNum: 2
        },
        // 退仓到件票数
        {
          label: this.$t('customerManagements.returnedShipmentsCount'),
          prop: 'returnedShipmentsCount',
          width: 180,
          typeNum: 3
        },
        // 差异
        { label: this.$t('customerManagements.discrepancyCount'), prop: 'discrepancyCount', width: 180, typeNum: 4 },
        // 疑似丢失
        { label: this.$t('operationTips.yisiLost'), prop: 'suspectedMissCount', width: 180, typeNum: 5 }
      ],
      queryDateTime: [this.getAWeek().startDate, this.getAWeek().endDate],
      siteList: [],
      TransferList: [],
      dataObj: {},
      strARR: ['returnCount', 'secondaryStorageCount', 'returnedShipmentsCount', 'discrepancyCount', 'suspectedMissCount']
    };
  },
  created() {
    // 获取加盟商选项
    this.getAddress();
    this.getTableData(1);
    this.getSiteTransfer();
    this.getSiteList();
  },
  methods: {
    // 搜索、重置
    searchResources(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.getTableData();
      } else {
        this.queryForm = {
          pageSize: 10,
          pageNumber: 1,
          params: {
            transitCenterIdList: [],
            stateList: [],
            siteIdList: [],
            startTime: '',
            endTime: '',
            isTest: 1 // 数据类型（1正式 2测试）
          }
        };
        this.queryDateTime = [this.getAWeek().startDate, this.getAWeek().endDate];
        this.getTableData();
      }
    },
    // 获取最近30天时间
    getAWeek() {
      return {
        endDate: dayjs().format('YYYY-MM-DD'),
        startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD')
      };
    },
    // 获取加盟商时效统计列表
    getTableData(val) {
      if (this.queryDateTime && this.queryDateTime.length > 0) {
        this.queryForm.params.startTime = this.queryDateTime[0];
        this.queryForm.params.endTime = this.queryDateTime[1];
      } else {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      let loading = null;
      if (val === 1) {
        loading = this.$loading({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: 'loading...',
          background: 'rgba(250, 250, 250, 0.6)'
        });
      }
      returnDataMonitoringPage(this.queryForm).then(res => {
        if (res.code===200) {
          this.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach(item => {
            item.date = item.date || '--';
          });
          if (val === 1) {
            loading.close();
          }
        }
      }).catch(() => {
        if (val === 1) {
          loading.close();
        }
      });
    },
    allExportOrder() {
      if (this.queryDateTime && this.queryDateTime.length > 0) {
        this.queryForm.params.startTime = this.queryDateTime[0];
        this.queryForm.params.endTime = this.queryDateTime[1];
      } else {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      const data = this.queryForm.params;
      returnDataMonitoringexport(data).then(res => {
        if (res.code===200) {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          }).catch(() => {
          });
        }
      });
    },
    // 页码变化翻页
    getCurrentTableData(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableData();
    },
    closeDrawer() {
      this.dialogVisible = false;
    },
    parentMethod(arg) {
      this.dialogVisible = arg;
    },
    // 州
    getAddress() {
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.code===200) {
          this.StateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    },
    seeDetial(row, porp, number, type) {
      console.log(row, porp, number);
      if (this.strARR.includes(porp)) {
        if (number) {
          this.dataObj = {
            id: row.id,
            siteId: row.siteId,
            startTime: row.returnDate,
            endTime: row.returnDate,
            isTest: this.queryForm.params.isTest,
            type: type
          };
          this.dialogVisible = true;
        }
      }
    },
    // 获取网点列表
    getSiteList() {
      const data = {};
      selectInfoByStateTwo(data).then((res) => {
        if (res.code===200) {
          this.siteList = res.data.siteList;
        }
      });
    },
    getSiteTransfer() {
      getSiteTransfer().then(res => {
        if (res.code===200) {
          this.TransferList = res.data;
        }
      });
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-drawer__header {
  margin-bottom: 10px;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  color: black;
}

.table-active {
  color: #409EFF;
  cursor: pointer;
}
</style>
