<template>
  <div class="app-container">
    <el-card class="box-card">
      <el-form label-position="top" size="small">
        <el-row :gutter="10">
          <el-col :span="5">
            <el-row>
              <el-col :span="24">
                <!--运单编号-->
                <el-form-item>
                  <template slot="label">
                    <div class="selectLabel">
                      <el-select v-model="queryForm.params.numberType">
                        <el-option
                          key="1"
                          :label="$t('GlobalSetObj.waybillNumber')"
                          :value="0"
                        /><!--运单号-->
                        <el-option
                          key="2"
                          :label="$t('GlobalSetObj.ScanNumber')"
                          :value="1"
                        /><!--扫描单号-->
                      </el-select>
                    </div>
                  </template>
                  <el-input
                    v-model="returnWaybillNumber"
                    :placeholder="$t('GlobalSetObj.CanBeEnteredMultiple2000')"
                    :rows="5"
                    type="textarea"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="19">
            <el-row :gutter="10">
              <el-col :span="9">
                <!--留言时间-->
                <el-form-item>
                  <template slot="label">
                    <div class="selectLabel">
                      <el-select v-model="queryForm.params.timeType">
                        <el-option
                          key="1"
                          :label="$t('collectionCenter.RegistrationT')"
                          :value="1"
                        /><!--登记时间-->
                        <el-option
                          key="2"
                          :label="$t('GlobalSetObj.newReplyTime')"
                          :value="2"
                        /><!--最新回复时间-->
                        <!--                        <el-option key="3" :label="$t('GlobalSetObj.messageTime')" :value="3" />&lt;!&ndash;留言时间&ndash;&gt;-->
                        <!--                        <el-option key="4" :label="$t('GlobalSetObj.newMessageTime')" :value="4" />&lt;!&ndash;最新留言时间&ndash;&gt;-->
                      </el-select>
                    </div>
                  </template>
                  <el-date-picker
                    v-model="timeValue"
                    :clearable="false"
                    :default-time="['00:00:00', '23:59:59']"
                    :format="$i18n.locale === 'zh' ? zhDate : ptDate"
                    :placeholder="$t('newOrder.SelectDatetime')"
                    range-separator="~"
                    style="width: 100%"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <!--  处理状态   -->
                <el-form-item :label="$t('GlobalSetObj.ProcessingStatus')">
                  <el-select
                    v-model="queryForm.params.processingState"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    style="width: 100%"
                  >
                    <el-option key="1" :label="$t('GlobalSetObj.all')" :value="null" />
                    <el-option
                      key="2"
                      :label="$t('GlobalSetObj.Pending')"
                      :value="0"
                    /><!--待处理-->
                    <el-option key="3" :label="$t('newOrder.processing')" :value="1" /><!--处理中-->
                    <el-option
                      key="4"
                      :label="$t('collectionCenter.closedLoop')"
                      :value="2"
                    /><!--已闭环-->
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <!--  处理结果   -->
                <el-form-item :label="$t('collectionCenter.processingResult')">
                  <el-select
                    v-model="queryForm.params.processingResult"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    style="width: 100%"
                  >
                    <el-option key="1" :label="$t('GlobalSetObj.all')" :value="null" />
                    <el-option
                      key="2"
                      :label="$t('GlobalSetObj.blameless')"
                      :value="1"
                    /><!--无责-->
                    <el-option
                      key="3"
                      :label="$t('compensationManagement.FalseSigningForDelivery')"
                      :value="0"
                    />
                    <!--虚假签收-->
                    <el-option
                      key="4"
                      :label="$t('compensationManagement.lossOfAll')"
                      :value="2"
                    /><!--全部丢失-->
                    <el-option
                      key="5"
                      :label="$t('compensationManagement.PartiallyLost')"
                      :value="3"
                    /><!--部分丢失-->
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <!-- 是否超时 -->
                <el-form-item :label="$t('orderCenterCont.TimeoutOrNot')">
                  <el-select
                    v-model="queryForm.params.istimeOut"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    clearable
                    style="width: 100%"
                  >
                    <el-option :label="$t('GlobalSetObj.all')" :value="null" /><!--全部-->
                    <el-option :label="$t('GlobalSetObj.yes')" :value="1" /><!--是-->
                    <el-option :label="$t('GlobalSetObj.No')" :value="0" /><!--否-->
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="4">
                <!--  客户  -->
                <el-form-item :label="$t('GlobalSetObj.client')">
                  <el-select
                    v-model="queryForm.params.customerId"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    :remote-method="getCustomerPage"
                    clearable
                    filterable
                    multiple
                    remote
                    reserve-keyword
                    style="width: 100%"
                    @clear="getCustomerPage"
                  >
                    <!--@change="getShanghu()"-->
                    <el-option
                      v-for="(item, index) in customerList"
                      :key="index"
                      :label="item.customerAlias + '（' + item.username + '）'"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <!--商户-->
                <el-form-item :label="$t('placeOrder.CommercialOwner')">
                  <el-select
                    v-model="queryForm.params.merchantName"
                    :placeholder="$t('placeOrder.pleaseChoose')"
                    :remote-method="getShanghu"
                    clearable
                    filterable
                    multiple
                    remote
                    reserve-keyword
                    style="width: 100%"
                    @clear="getShanghu"
                  >
                    <el-option
                      v-for="item in commercialList"
                      :key="item.id"
                      :label="item.merchantName"
                      :value="item.merchantName"
                    >
                      <div>
                        <span>{{ item.merchantName }}</span>
                        <span style="margin-left: 30px">{{ item.merchantCode }}</span>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <!-- 网点所属州 -->
                <el-form-item :label="$t('collectionCenter.stateSite1')">
                  <el-select
                    v-model="queryForm.params.state"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    clearable
                    filterable
                    multiple
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in stateList"
                      :key="item.id"
                      :label="item.nameEn"
                      :value="item.twoCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <!-- 实际派送网点 -->
                <el-form-item :label="$t('GlobalSetObj.entrega')">
                  <el-select
                    v-model="queryForm.params.siteName"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    :remote-method="getAllSiteList"
                    clearable
                    filterable
                    multiple
                    remote
                    reserve-keyword
                    style="width: 100%"
                    @clear="getAllSiteList"
                  >
                    <el-option
                      v-for="item in siteList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <!-- 登记人 -->
                <el-form-item :label="$t('collectionCenter.registrar')">
                  <el-select
                    v-model="queryForm.params.registerUserId"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    :remote-method="getRegisterUserList"
                    clearable
                    filterable
                    multiple
                    remote
                    reserve-keyword
                    style="width: 100%"
                    @clear="getRegisterUserList()"
                  >
                    <el-option
                      v-for="item in registerUserList"
                      :key="item.id"
                      :label="item.username"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <!--搜索-->
        <el-button
          icon="el-icon-search" size="small" type="primary"
          @click="enterSearch"
        >{{ $t('newOrder.search') }}
        </el-button>
        <!--重置-->
        <el-button
          icon="el-icon-refresh-left" size="small" type="info"
          @click="resetForm()"
        >{{ $t('newOrder.reset') }}
        </el-button>
        <!--批量导入-->
        <el-button
          v-permit:remove="'btn:complaintWorkOrder:bulkImport'"
          icon="el-icon-upload2" size="small" @click="allImportOrder"
        >{{ $t('newOrder.bulkImport') }}
        </el-button>
        <!--关闭-->
        <el-button
          v-permit:remove="'btn:complaintWorkOrder:close'"
          icon="el-icon-circle-close"
          size="small"
          @click="closeOrder()"
        >
          {{ $t('GlobalSetObj.Close') }}
        </el-button>
        <el-button
          v-permit:remove="'btn:complaintWorkOrder:export'"
          icon="el-icon-upload2"
          size="small"
          @click="exportOrder()"
        >
          {{ $t('navbar.export') }}
        </el-button>
        <el-radio-group
          v-model="queryForm.params.testData"
          size="small"
          style="margin-left: 10px"
          @change="enterSearch"
        >
          <el-radio-button :label="1">{{ $t('newOrder.Formal') }}</el-radio-button><!--正式-->
          <el-radio-button :label="2">{{ $t('newOrder.test1') }}</el-radio-button><!--测试-->
        </el-radio-group>
      </div>
    </el-card>
    <el-card class="box-card" style="margin-top: 10px">
      <!--  表格数据  -->
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <!--@selection-change="handleSelectionChange"-->
        <!-- 序列号/复选框 -->
        <el-table-column
          align="center" fixed="left" type="selection"
          width="50"
        />
        <el-table-column
          :label="$t('GlobalSetObj.serial')"
          align="center"
          type="index"
          width="70"
        />
        <el-table-column
          v-for="item in tableHeaderList"
          :key="item.id"
          :align="item.align"
          :label="item.label"
          :min-width="item.cloWidth"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!--处理状态-->
            <div v-if="item.prop === 'processingState'">
              <el-tag
                v-if="scope.row[item.prop] === 0" size="small" type="success"
              >{{ $t('GlobalSetObj.Pending') }}
                <!--待处理-->
              </el-tag>
              <el-tag
                v-else-if="scope.row[item.prop] === 1" size="small"
              >{{ $t('newOrder.processing') }}
                <!--处理中-->
              </el-tag>
              <el-tag
                v-else-if="scope.row[item.prop] === 2" size="small" type="warning"
              >{{ $t('collectionCenter.closedLoop') }}
                <!--已闭环-->
              </el-tag>
            </div>
            <!--处理结果-->
            <div v-else-if="item.prop === 'processingResult'">
              <div v-if="scope.row[item.prop] === 1">
                {{ $t('GlobalSetObj.blameless') }}
                <!--无责-->
              </div>
              <div v-else-if="scope.row[item.prop] === 0">
                {{ $t('compensationManagement.FalseSigningForDelivery') }}
                <!--虚假签收-->
              </div>
              <div v-else-if="scope.row[item.prop] === 2">
                {{ $t('compensationManagement.lossOfAll') }}
                <!--全部丢失-->
              </div>
              <div v-else-if="scope.row[item.prop] === 3">
                {{ $t('compensationManagement.PartiallyLost') }}
                <!--部分丢失-->
              </div>
              <div v-else>-</div>
            </div>
            <!--是否有回复-->
            <div v-else-if="item.prop === 'hasReply'">
              <div v-if="scope.row.processingState === 2">-</div>
              <div v-else>
                <el-tag
                  v-if="scope.row[item.prop] === 0" size="small" type="info"
                >{{ $t('GlobalSetObj.No') }}
                  <!--否-->
                </el-tag>
                <el-tag
                  v-else-if="scope.row[item.prop] === 1" size="small" type="success"
                >{{ $t('GlobalSetObj.yes') }}
                  <!--是-->
                </el-tag>
                <div v-else-if="!scope.row[item.prop]">-</div>
              </div>
            </div>
            <!--是否超时-->
            <div v-else-if="item.prop === 'isTimeOut'">
              <el-tag
                v-if="scope.row[item.prop] === 0" size="small" type="info"
              >{{ $t('GlobalSetObj.No') }}
                <!--否-->
              </el-tag>
              <el-tag
                v-else-if="scope.row[item.prop] === 1" size="small" type="success"
              >{{ $t('GlobalSetObj.yes') }}
                <!--是-->
              </el-tag>
            </div>
            <div v-else-if="item.prop === 'newReply'">
              <div
                v-if="
                  scope.row[item.prop] !== null &&
                    scope.row[item.prop] !== '' &&
                    scope.row[item.prop] !== undefined
                "
              >
                <div class="tableImg" v-html="scope.row[item.prop]" />
              </div>
              <div v-else>-</div>
            </div>
            <div v-else>
              <div
                v-if="
                  scope.row[item.prop] !== null &&
                    scope.row[item.prop] !== '' &&
                    scope.row[item.prop] !== undefined
                "
              >
                {{ scope.row[item.prop] }}
              </div>
              <div v-else>-</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('newOrder.operation')" align="center" fixed="right"
          width="120"
        >
          <template slot-scope="scope">
            <div style="display: flex; justify-content: space-between; align-items: center">
              <!--回复-->
              <el-tooltip
                v-permit:remove="'btn:complaintWorkOrder:Reply'"
                :content="$t('GlobalSetObj.Reply')"
                class="item"
                effect="dark"
                placement="top"
              >
                <i
                  class="iconfont icon-chakantiezihuifu textOperator2"
                  @click="replyMessage(scope.row)"
                />
              </el-tooltip>
              <!--关闭-->
              <el-tooltip
                v-if="scope.row.processingState !== 2"
                v-permit:remove="'btn:complaintWorkOrder:close'"
                :content="$t('GlobalSetObj.Close')"
                class="item"
                effect="dark"
                placement="top"
              >
                <i class="el-icon-circle-close textOperator" @click="closeOrder(scope.row)" />
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--  分页  -->
      <el-pagination
        :current-page="queryForm.pageNumber"
        :page-size="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="queryForm.total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0; float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!--回复弹窗-->
    <el-drawer
      :close-on-click-modal="true"
      :destroy-on-close="true"
      :title="$t('GlobalSetObj.CommunicationContent')"
      :visible.sync="seeDialogVisible"
      class="drawerClass"
      direction="ltr"
      size="1000px"
      @close="closeDrawer"
    >
      <messageDetail ref="messageDetail" :reply-message-data="replyMessageData" />
    </el-drawer>
    <!-- 批量导入 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('basicData.BulkImport')"
      :visible.sync="importForm.messageRemrkDialog"
      width="500px"
    >
      <div class="remarkBox">
        <el-form :key="importForm.messageRemrkDialog" ref="importForm" :model="importForm">
          <el-form-item prop="importNumberType">
            <el-select
              v-model="importForm.importNumberType"
              :rules="{
                required: true,
                message: $t('GlobalSetObj.pleaseChoose'),
                trigger: ['blur', 'change']
              }"
              style="width: 100%"
            >
              <el-option
                key="1"
                :label="$t('GlobalSetObj.waybillNumber')"
                :value="0"
              /><!--运单号-->
              <el-option key="2" :label="$t('GlobalSetObj.ScanNumber')" :value="1" /><!--扫描单号-->
            </el-select>
          </el-form-item>
          <el-form-item
            :rules="{
              required: true,
              message: $t('GlobalSetObj.pleaseInput'),
              trigger: ['blur', 'change']
            }"
            prop="importNumber"
          >
            <el-input
              v-model="importForm.importNumber"
              :placeholder="$t('receivablePayable.MultipleNumberWrapEntry')"
              :rows="6"
              type="textarea"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="messageRemarkSubmit(1)">{{
          $t('GlobalSetObj.cancel')
        }}</el-button>
        <el-button size="small" type="primary" @click="messageRemarkSubmit(2)">
          {{ $t('GlobalSetObj.confirm') }}
        </el-button>
      </div>
    </el-dialog>
    <!--导入结果-->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.ImportResults')"
      :visible.sync="resultData.showImportResults"
      width="710px"
    >
      <div class="remarkBox">
        <div style="display: flex">
          <div style="width: 220px">
            <!--总共导入-->
            {{ $t('receivablePayable.TotalImport') }}：<span style="color: #409eff">{{
              resultData.all
            }}</span>
          </div>
          <div style="flex: 1">
            <!--导入成功-->
            {{ $t('orderCenterCont.ImportWasSuccessful') }}：{{ resultData.success }}
          </div>
          <div style="width: 220px">
            <!--导入失败-->
            {{ $t('orderCenterCont.ImportFailed') }}：<span style="color: red">{{
              resultData.err
            }}</span>
          </div>
        </div>
        <div v-if="resultData.err > 0">
          <!--导入失败原因-->
          <div style="margin: 20px 0 0 0">{{ $t('GlobalSetObj.ReasonForFailure') }}</div>
          <div style="max-height: 310px; overflow-y: auto">
            <div v-for="(item, index) in resultData.errData" :key="index" style="margin-top: 10px">
              <span>{{ index }}：{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="resultData.showImportResults = false">
          {{ $t('GlobalSetObj.confirm') }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 关闭投诉 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('collectionCenter.processingResult')"
      :visible.sync="closeReturn.showCloseComplaints"
      width="500px"
    >
      <div class="remarkBox">
        <div style="margin: 20px 0">
          {{ $t('GlobalSetObj.totalOptions') }}：{{ closeReturn.selectedNum }}
        </div>
        <el-form :key="closeReturn.showCloseComplaints" ref="closeReturn" :model="closeReturn">
          <el-form-item
            :label="$t('collectionCenter.processingResult') + '：'"
            :rules="{
              required: true,
              message: $t('GlobalSetObj.pleaseChoose'),
              trigger: ['change', 'blur']
            }"
            prop="processingResult"
          >
            <el-select
              v-model="closeReturn.processingResult"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 100%"
            >
              <el-option key="2" :label="$t('GlobalSetObj.blameless')" :value="1" /><!--无责-->
              <el-option
                key="3"
                :label="$t('compensationManagement.FalseSigningForDelivery')"
                :value="0"
              /><!--虚假签收-->
              <el-option
                key="4"
                :label="$t('compensationManagement.lossOfAll')"
                :value="2"
              /><!--全部丢失-->
              <el-option
                key="5"
                :label="$t('compensationManagement.PartiallyLost')"
                :value="3"
              /><!--部分丢失-->
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeReturnSubmit(1)">{{
          $t('GlobalSetObj.cancel')
        }}</el-button>
        <el-button size="small" type="primary" @click="closeReturnSubmit(2)">
          {{ $t('GlobalSetObj.confirm') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { apiCustomerPage } from '@/api/customer';
import { getLgAreaListData } from '@/api/orderApi';
import {
  apiGetComplaintGetMerchantName,
  apiGetComplaintWorkCloseWork,
  apiGetComplaintWorkOrderImport,
  apiGetComplaintWorkOrderPage, complaintWorkOrderExport
} from '@/api/newExpressAPI';
import { siteFuzzyQuery } from '@/api/lanshou';
import { goExportList } from '@/utils/goExportList';
import { getUserPage } from '@/api/user';

export default {
  name: 'ComplaintWorkOrder',
  components: {
    messageDetail: () => import('./messageDetail.vue')
  },
  data() {
    return {
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      returnWaybillNumber: '', // 运单号
      customerList: [], // 客户选项
      commercialList: [], // 商户
      stateList: [], // 所属州
      siteList: [], // 网点
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          waybillNumberList: [], // 运单号
          scanNumberList: [], // 扫描单号
          processingState: null, // 状态: 1-待处理 2-处理中 3-已闭环
          processingResult: null, // 处理结果: 0.虚假签收，1.无责 2.全部丢失 3.部分丢失
          registerTime: null, // 登记时间开始
          registerTimeEnd: null, // 登记时间结束
          customerId: [], // 客户名称
          merchantName: [], // 商户名称
          istimeOut: null, // 是否超时: 1-是, 2-否
          state: [], // 网点所属州
          siteName: [], // 网点名称
          newReplyTime: null, // 最新回复时间开始
          newReplyTimeEnd: null, // 最新回复时间结束
          numberType: 0, // 单号类型: 0-运单号, 1-扫描单号
          timeType: 1, // 时间类型: 1-登记时间, 2-最新回复时间
          testData: 1, // 1:正式 2:测试
          registerUserId: [] // 登记人id
        }
      },
      // 导入结果提示
      resultData: {
        showImportResults: false,
        all: 0,
        success: 0,
        err: 0,
        errData: []
      },
      // 批量导入
      importForm: {
        messageRemrkDialog: false,
        importNumberType: 0,
        importNumber: ''
      },
      // 处理结果
      closeReturn: {
        showCloseComplaints: false,
        selectedNum: 0, // 选择的数据数量
        processingResult: null // 处理结果
      },
      seeDialogVisible: false,
      tableData: [],
      tableHeaderList: [
        // 运单号
        {
          label: this.$t('GlobalSetObj.waybillNumber'),
          prop: 'waybillNumber',
          cloWidth: '200',
          align: 'center'
        },
        // 扫描单号
        {
          label: this.$t('GlobalSetObj.ScanNumber'),
          prop: 'scanNumber',
          cloWidth: '200',
          align: 'center'
        },
        // 处理状态
        {
          label: this.$t('GlobalSetObj.ProcessingStatus'),
          prop: 'processingState',
          cloWidth: '200',
          align: 'center'
        },
        // 处理结果
        {
          label: this.$t('collectionCenter.processingResult'),
          prop: 'processingResult',
          cloWidth: '200',
          align: 'center'
        },
        // 登记时间
        {
          label: this.$t('collectionCenter.RegistrationT'),
          prop: 'registerTime',
          cloWidth: '200',
          align: 'center'
        },
        // 是否有最新回复
        {
          label: this.$t('GlobalSetObj.HasReply'),
          prop: 'hasReply',
          cloWidth: '150',
          align: 'center'
        },
        // 最新回复时间
        {
          label: this.$t('GlobalSetObj.newReplyTime'),
          prop: 'newReplyTime',
          cloWidth: '200',
          align: 'center'
        },
        // 最新回复内容
        {
          label: this.$t('GlobalSetObj.LatestReplyContent'),
          prop: 'newReply',
          cloWidth: '225',
          align: 'center'
        },
        // 客户
        {
          label: this.$t('GlobalSetObj.client'),
          prop: 'customerName',
          cloWidth: '200',
          align: 'center'
        },
        // 商户
        {
          label: this.$t('collectionCenter.CommercialOwner'),
          prop: 'merchantName',
          cloWidth: '200',
          align: 'center'
        },
        // 截止时间
        // {
        //   label: this.$t('GlobalSetObj.Deadline'),
        //   prop: 'abortTime',
        //   cloWidth: '200',
        //   align: 'center'
        // },
        // 是否超时
        {
          label: this.$t('orderCenterCont.TimeoutOrNot'),
          prop: 'isTimeOut',
          cloWidth: '200',
          align: 'center'
        },
        // 网点所属州
        {
          label: this.$t('collectionCenter.stateSite1'),
          prop: 'state',
          cloWidth: '200',
          align: 'center'
        },
        // 网点名称
        {
          label: this.$t('collectionCenter.siteName'),
          prop: 'siteName',
          cloWidth: '180',
          align: 'center'
        },
        // 登记人
        {
          label: this.$t('collectionCenter.registrar'),
          prop: 'registerUserName',
          cloWidth: '200',
          align: 'center'
        },
        // 最新处理人
        {
          label: this.$t('GlobalSetObj.LatestProcessor'),
          prop: 'newOptionUserName',
          cloWidth: '180',
          align: 'center'
        },
        // 最新处时间
        {
          label: this.$t('GlobalSetObj.LatestProcessingTime'),
          prop: 'newOptionTime',
          cloWidth: '180',
          align: 'center'
        }
      ],
      selectData: [],
      closeData: [],
      replyMessageData: null,
      registerUserList: [] // 登记人选项
    };
  },
  created() {
    this.getTimer(); // 默认时间
    this.getAddress(); // 获取所有州
    this.getAllSiteList(); // 获取所有网点
    this.getCustomerPage(''); // 获取所有客户
    this.getShanghu(); // 获取所有商户
    this.getSearch(); // 查询投诉列表
    this.getRegisterUserList(); // 获取登记人选项
  },
  methods: {
    // 获取登记人选项
    getRegisterUserList(name = '') {
      const data = {
        pageNumber: 1,
        pageSize: 20,
        params: {
          accountState: '1',
          username: name
        }
      };
      getUserPage(data).then(res => {
        if (res.code === 200) {
          this.registerUserList = res.data.records;
        }
      });
    },
    // 导出
    exportOrder() {
      const data = this.paramSearch();
      if (!data) {
        return;
      }
      complaintWorkOrderExport(data).then(res => {
        goExportList(this, '/views/resource/exportTaskList');
      });
    },
    // 数据勾选
    handleSelectionChange(arr) {
      this.selectData = arr;
    },
    // 打开关闭弹窗
    closeOrder(row) {
      if (row) {
        this.closeData = [row.waybillNumber];
      } else {
        this.closeData = [];
        this.selectData.map((item) => {
          this.closeData.push(item.waybillNumber);
        });
      }
      this.closeReturn.selectedNum = this.closeData.length;
      this.closeReturn.showCloseComplaints = true;
    },
    // 处理结果确认、取消
    closeReturnSubmit(type) {
      if (type === 1) {
        this.closeReturn.processingResult = null;
        this.closeReturn.showCloseComplaints = false;
      } else {
        this.$refs.closeReturn.validate((valid) => {
          if (valid) {
            console.log(this.closeData, '===this.tableData');
            const paramData = {
              pageNumber: this.queryForm.pageNumber,
              pageSize: this.queryForm.pageSize,
              params: {
                waybillNumberList: this.closeData,
                processingState: this.closeReturn.processingResult
              }
            };
            console.log(paramData, '===paramData');
            this.closeReturn.processingResult = null;
            this.closeReturn.showCloseComplaints = false;
            apiGetComplaintWorkCloseWork(paramData).then((res) => {
              if (res.code === 200) {
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded')); // 操作成功
                this.enterSearch();
                this.closeReturn.processingResult = null;
                this.closeReturn.showCloseComplaints = false;
              }
            });
          }
        });
      }
    },
    // 州
    getAddress() {
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.code === 200) {
          this.stateList = res.data;
        }
      });
    },
    // 获取商户
    getShanghu(name = '') {
      const data = {
        // orderType: 0,
        pageSize: 20,
        pageNumber: 1,
        params: {
          merchantNames: name,
          customerName: [] // this.queryForm.params.customerId
        }
      };
      apiGetComplaintGetMerchantName(data, false).then((res) => {
        if (res.code === 200) {
          this.commercialList = res.data.records;
        }
      });
    },
    // 获取客户
    getCustomerPage(name = '') {
      const param = {
        pageSize: 20,
        pageNumber: 1,
        params: {
          quickSearch: name
        }
      };
      apiCustomerPage(param).then((res) => {
        if (res.code === 200) {
          this.customerList = res.data.records;
        }
      });
    },
    // 打开批量导入弹窗
    allImportOrder() {
      this.importForm.messageRemrkDialog = true;
    },
    // 批量导入、关闭弹窗
    messageRemarkSubmit(val) {
      if (val === 1) {
        this.importForm = {
          messageRemrkDialog: false,
          importNumberType: 0,
          importNumber: ''
        };
      } else {
        this.$refs.importForm.validate((valid) => {
          if (valid) {
            const orderNum = this.importForm.importNumber.split(/[,\n\r，]/); // /[(\r\n)\r\n]+/
            const orderNumArr = [];
            for (let j = 0; j < orderNum.length; j++) {
              const orderNumber = orderNum[j].replace(/\s+/g, '');
              if (orderNumber !== '') {
                if (orderNumArr.indexOf(orderNumber) === -1) {
                  orderNumArr.push(orderNumber.trim());
                }
              }
            }
            const params = {
              orderType: this.importForm.importNumberType,
              waybillNumberList: orderNumArr
            };
            apiGetComplaintWorkOrderImport(params).then((res) => {
              if (res.code === 200) {
                if (res.data && res.data.failNumber > 0) {
                  this.resultData = {
                    showImportResults: true,
                    all: res.data.totalNumber,
                    success: res.data.succeedNumber,
                    err: res.data.failNumber,
                    errData: res.data.failMap
                  };
                } else {
                  this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                  this.enterSearch();
                }
                this.importForm = {
                  messageRemrkDialog: false,
                  importNumberType: 0,
                  importNumber: ''
                };
              }
            });
          }
        });
      }
    },
    // 点击回复弹框
    replyMessage(row) {
      this.seeDialogVisible = true;
      this.replyMessageData = row;
    },
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      // const oneWeekAgo = dayjs(brazilTime).subtract(1, 'week').format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.getTimer();
      this.getShanghu();
      this.getAllSiteList();
      this.getCustomerPage();
      this.getRegisterUserList();
      this.queryForm.params = {
        waybillNumberList: [], // 运单号
        scanNumberList: [], // 扫描单号
        processingState: null, // 状态: 1-待处理 2-处理中 3-已闭环
        processingResult: null, // 处理结果: 0.虚假签收，1.无责 2.全部丢失 3.部分丢失
        registerTime: null, // 登记时间开始
        registerTimeEnd: null, // 登记时间结束
        customerId: [], // 客户名称
        merchantName: [], // 商户名称
        istimeOut: null, // 是否超时: 1-是, 2-否
        state: [], // 网点所属州
        siteName: [], // 网点名称
        newReplyTime: null, // 最新回复时间开始
        newReplyTimeEnd: null, // 最新回复时间结束
        numberType: 0, // 单号类型: 0-运单号, 1-扫描单号
        timeType: 1, // 时间类型: 1-登记时间, 2-最新回复时间
        testData: 1, // 1:正式 2:测试,
        registerUserId: [] // 登记人id
      };
      this.returnWaybillNumber = '';
      this.getSearch();
    },
    // 查询
    paramSearch() {
      let isParam = true;
      const data = {
        pageNumber: this.queryForm.pageNumber,
        pageSize: this.queryForm.pageSize,
        params: {
          waybillNumberList: [], // 运单号
          scanNumberList: [], // 扫描单号
          processingState: this.queryForm.params.processingState, // 状态: 1-待处理 2-处理中 3-已闭环
          processingResult: this.queryForm.params.processingResult, // 处理结果: 0.虚假签收，1.无责 2.全部丢失 3.部分丢失
          registerTime: null, // 登记时间开始
          registerTimeEnd: null, // 登记时间结束
          customerId: this.queryForm.params.customerId, // 客户名称
          merchantName: this.queryForm.params.merchantName, // 商户名称
          istimeOut: this.queryForm.params.istimeOut, // 是否超时: 1-是, 2-否
          state: this.queryForm.params.state, // 网点所属州
          siteName: this.queryForm.params.siteName, // 网点名称
          newReplyTime: null, // 最新回复时间开始
          newReplyTimeEnd: null, // 最新回复时间结束
          testData: this.queryForm.params.testData, // 1:正式 2:测试
          registerUserId: this.queryForm.params.registerUserId // 登记人id
          // numberType: this.queryForm.params.numberType, // 单号类型: 0-运单号, 1-扫描单号
          // timeType: this.queryForm.params.timeType // 时间类型: 1-登记时间, 2-最新回复时间
        }
      };
      const orderNum = this.returnWaybillNumber.split(/[,\n\r，]/); // /[(\r\n)\r\n]+/
      const orderNumArr = [];
      for (let j = 0; j < orderNum.length; j++) {
        const orderNumber = orderNum[j].replace(/\s+/g, '');
        if (orderNumber !== '') {
          if (orderNumArr.indexOf(orderNumber) === -1) {
            orderNumArr.push(orderNumber.trim());
          }
        }
      }
      if (orderNumArr.length > 2000) {
        isParam = false;
        this.$message.warning(this.$t('orderCenterCont.queried2000pieces'));
        return;
      }
      if (this.queryForm.params.numberType === 0) {
        data.params.waybillNumberList = orderNumArr;
        data.params.scanNumberList = [];
      } else {
        data.params.waybillNumberList = [];
        data.params.scanNumberList = orderNumArr;
      }
      if (orderNumArr.length > 0) {
        this.timeValue = null;
        data.params.registerTime = null;
        data.params.registerTimeEnd = null;
        data.params.newReplyTime = null;
        data.params.newReplyTimeEnd = null;
      } else {
        if (this.timeValue && this.timeValue.length > 0) {
          if (this.queryForm.params.timeType === 1) {
            data.params.registerTime = this.timeValue[0];
            data.params.registerTimeEnd = this.timeValue[1];
            data.params.newReplyTime = null;
            data.params.newReplyTimeEnd = null;
          } else {
            data.params.registerTime = null;
            data.params.registerTimeEnd = null;
            data.params.newReplyTime = this.timeValue[0];
            data.params.newReplyTimeEnd = this.timeValue[1];
          }
        } else {
          data.params.registerTime = null;
          data.params.registerTimeEnd = null;
          data.params.newReplyTime = null;
          data.params.newReplyTimeEnd = null;
          this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
          isParam = false;
          return;
        }
      }
      if (isParam === true) {
        return data;
      } else {
        return isParam;
      }
    },
    getSearch() {
      const data = this.paramSearch();
      if (!data) {
        return;
      }
      apiGetComplaintWorkOrderPage(data).then((res) => {
        if (res.code === 200) {
          this.queryForm.total = res.data.total;
          const removeHtmlTags = (html) => {
            return html.replace(/<[^>]*>/g, '');
          };
          res.data.records.map((item) => {
            if (item.newReply) {
              item.newReply = removeHtmlTags(item.newReply);
            }
          });
          this.tableData = res.data.records;
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    // 获取所有网点
    getAllSiteList(name = '') {
      siteFuzzyQuery(name, false).then((res) => {
        if (res.code === 200) {
          this.siteList = res.data;
        }
      });
    },
    closeDrawer() {
      console.log(this.intervalTime, ' this.intervalTime');
      clearInterval(this.$refs.messageDetail.getIntervalMsg);
      this.getSearch();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 15px;
  padding-bottom: 15px;
}

::v-deep {
  .drawerClass header {
    border-bottom: 1px solid #aaa !important;
    margin-bottom: 0 !important;
    padding: 20px 20px 20px !important;
  }
}

.el-form--label-top ::v-deep .el-form-item__label {
  width: 100% !important;
}

.selectLabel ::v-deep .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.selectLabel ::v-deep .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep .el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.conditionListitme {
  position: relative;
  padding: 10px;
  background-color: #d6d6d6;
  margin-bottom: 10px;
}

.demo-drawercontent {
  padding: 0 20px;
  height: calc(100vh - 130px);
  overflow: auto;
}

.delFillter {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.demo-drawer__footer {
  width: 100%;
  border-top: 1px solid #cccccc;
  position: absolute;
  display: flex;
  justify-content: right;
  padding: 10px;
  right: 10px;
  bottom: 0;
  background-color: white;
}

.textOperator {
  color: #409eff;
  font-size: 22px;
  cursor: pointer;
}

.textOperator1 {
  color: #409eff;
  cursor: pointer;
}

.textOperator2 {
  margin-top: -5px;
  color: #409eff;
  font-size: 22px;
  cursor: pointer;
}

.messgeList {
  height: 450px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;

  .selfMessage {
    text-align: right;
    margin-bottom: 15px;

    .pepole {
      color: #409eff;
    }

    .pepoleMessage {
      text-align: left;
      display: inline-block;
      padding: 10px;
      background-color: #e2ecf6;
      border: 1px solid #409eff;
      border-radius: 7px;
      max-width: calc(70% - 20px); /* 减去padding和可能的margin值 */
      word-wrap: break-word; /* 允许长单词换行 */
    }
  }

  .otherMessage {
    text-align: left;
    margin-bottom: 15px;

    .pepole {
      color: #409eff;
    }

    .pepoleMessage {
      text-align: left;
      display: inline-block;
      padding: 10px;
      background-color: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 7px;
      max-width: calc(70% - 20px); /* 减去padding和可能的margin值 */
      word-wrap: break-word; /* 允许长单词换行 */
    }
  }
}
</style>
<style>
.tableImg p {
  margin: 0;
  max-height: 180px;
  overflow: hidden;
}

.tableImg img {
  display: none;
}
</style>
