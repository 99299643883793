<!--我返回的-->
<template>
  <div>
    <tableCmp
      ref="tableCmp"
      :column-list="columnList"
      :is-select-btn="isSelectBtn"
      :table-data="tableData"
      :table-type="1"
      @selectionChange="selectionChange"
    />
    <el-pagination
      :current-page.sync="pageNumber"
      :page-size.sync="pageSize"
      :page-sizes="[10, 50, 100, 200, 400]"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 20px 0; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
import { setDateTime2 } from '@/utils/date_util';
import { batchPdfDownload } from '@/utils/PDF_util';
import {
  apiGetBagRecycleDetailDelete,
  apiGetBagRecycleDetailExportMyList,
  apiGetBagRecycleDetailMyList,
  apiGetBagRecycleDetailPrintWaybill
} from '@/api/newExpressAPI';
import { goExportList } from '@/utils/goExportList';

export default {
  name: 'MyReturn',
  components: {
    tableCmp: () => import('./tableCmp.vue')
  },
  props: {
    queryForm: {
      type: Object,
      default() {
        return {
          returnWaybillNumberList: [], // 运单号
          startTime: '', // 起始日期
          endTime: '', // 结束日期
          siteCode: this.$store.state.user.siteCode, // 网点编号
          siteName: this.$store.state.user.dotName, // 网点名称
          recycleStatus: null, // 是否确认
          waybillPrintStatus: null // 是否打印
        };
      }
    }
  },
  data() {
    return {
      total: 0,
      pageNumber: 1,
      pageSize: 10,
      isSelectBtn: true,
      tableData: [],
      selectArr: [],
      selectData: null,
      columnList: [
        {
          label: this.$t('GlobalSetObj.DocumentNumber'),
          prop: 'returnWaybillNumber',
          minWidth: '180px',
          align: 'center'
        }, // 单据号
        {
          label: this.$t('GlobalSetObj.ReturningBranch'),
          prop: 'returnSiteName',
          minWidth: '180px',
          align: 'center'
        }, // 返回网点
        {
          label: this.$t('GlobalSetObj.ReceivingBranch'),
          prop: 'receiveSiteName',
          minWidth: '180px',
          align: 'center'
        }, // 接收网点
        {
          label: this.$t('GlobalSetObj.ExpectedReturnQuantity'),
          prop: 'shouldReturnCount',
          minWidth: '180px',
          align: 'center'
        }, // 应返回数量
        {
          label: this.$t('GlobalSetObj.ConfirmReturnQuantity'),
          prop: 'confirmReturnCount',
          minWidth: '180px',
          align: 'center'
        }, // 确认返回数量
        {
          label: this.$t('customerManagements.varianceNumber'),
          prop: 'differenceCount',
          minWidth: '180px',
          align: 'center'
        }, // 差异数量
        {
          label: this.$t('GlobalSetObj.RecyclingStatus'),
          prop: 'recycleStatus',
          minWidth: '180px',
          align: 'center'
        }, // 回收状态
        {
          label: this.$t('GlobalSetObj.Returner'),
          prop: 'returnMan',
          minWidth: '180px',
          align: 'center'
        }, // 返回人
        {
          label: this.$t('GlobalSetObj.ReturnDate'),
          prop: 'returnTime',
          minWidth: '180px',
          align: 'center'
        }, // 返回时间
        {
          label: this.$t('collectionCenter.verifyPerson'),
          prop: 'confirmMan',
          minWidth: '180px',
          align: 'center'
        }, // 确认人
        {
          label: this.$t('collectionCenter.verifyTime'),
          prop: 'confirmTime',
          minWidth: '180px',
          align: 'center'
        } // 确认时间
        // {
        //   label: this.$t('GlobalSetObj.WaybillPrintingStatus'),
        //   prop: 'waybillPrintStatus',
        //   minWidth: '180px',
        //   align: 'center'
        // } // 运单打印状态
      ]
    };
  },
  created() {
    this.getTableData(this.queryForm);
  },
  methods: {
    // 列表操作
    selectionChange(data, type) {
      // type = delete: 删除 selected: 列表数据勾选 confirmRecycling: 回收确认
      if (type === 'delete') {
        // 删除
        this.selectData = data;
        this.$confirm(
          this.$t('GlobalSetObj.PleaseConfirmWhetherToDelete'),
          this.$t('basicData.tips'),
          {
            // '删除后将不能恢复，请确认是否删除？'
            confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
            cancelButtonText: this.$t('collectionCenter.cancel'), // '取消'
            type: 'warning'
          }
        ).then(() => {
          apiGetBagRecycleDetailDelete({ id: data.id }).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: this.$t('spareNumberPoo.DeleteSucceeded') // '删除成功'
              });
              this.getTableData(this.queryForm);
            }
          });
        });
      } else if (type === 'selected') {
        this.selectArr = data;
      }
    },
    // 获取列表数据
    getTableData(param) {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        params: {
          type: 'return',
          ...param
        }
      };
      console.log(params, '===params');
      apiGetBagRecycleDetailMyList(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 表格布局更新
    doLayout() {
      this.$refs.tableCmp.$refs.wovenBagManagementTable.doLayout();
    },
    // 每页条数
    handleSizeChange(val) {
      this.pageNumber = 1;
      this.pageSize = val;
      this.getTableData(this.queryForm);
    },
    // 当前页
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getTableData(this.queryForm);
    },
    // 导出
    exportData(param) {
      console.log(param, '===param');
      const params = {
        type: 'return',
        ...param
      };
      apiGetBagRecycleDetailExportMyList(params).then((res) => {
        if (res.code === 200) {
          goExportList(this, '/views/resource/exportTaskList');
        }
      });
    },
    // 打印运单
    async printWaybill() {
      if (this.selectArr.length === 0) {
        this.$message({
          type: 'warning',
          message: this.$t('collectionCenter.checkMsg') // '请选择数据'
        });
        return;
      }
      if (this.selectArr.length > 200) {
        this.$message.warning(
          this.$i18n.t('orderCenterCont.TheNumberOfSelectedWaybillsExceedsTheMaximumLimit200')
        ); // 选中运单数超出最大限制：200
        return;
      }
      // 手动显示加载中动画
      const loading = this.$loading({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
        background: 'rgba(250, 250, 250, 0.6)'
      });
      const downloaUrldArr = [];
      const downloadArr = {
        returnWaybillNumberList: []
      };
      this.selectArr.map((item) => {
        downloadArr.returnWaybillNumberList.push(item.returnWaybillNumber);
        downloaUrldArr.push(item.labelUrl);
      });
      apiGetBagRecycleDetailPrintWaybill(downloadArr);
      batchPdfDownload(downloaUrldArr)
        .then((res) => {
          this.getTableData(this.queryForm);
          this.$refs.tableCmp.clearSelection();
          const pdfBase64 = res.split(',')[1]; // 提取 Base64 部分
          const byteCharacters = atob(pdfBase64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          // 在新窗口中打开 PDF
          // const byteArray = new Uint8Array(byteNumbers);
          // const blob = new Blob([byteArray], { type: 'application/pdf' });
          // const blobUrl = URL.createObjectURL(blob);
          // window.open(blobUrl, '_blank');
          // 先下载
          const dateType = setDateTime2();
          const a = document.createElement('a');
          a.download = `${dateType}`;
          a.href = res;
          a.download = `${dateType}` + this.$i18n.t('orderCenterCont.FaceSheet');
          a.click();
          a.remove();
        })
        .finally(() => {
          loading.close();
        });
    }
  }
};
</script>

<style scoped></style>
