<template>
  <div style="padding: 0 10px;">
    <div class="addTable">
      <div v-if="addType === 2">
        <!--基本信息-->
        <div class="headerStyle">{{ $t('collectionCenter.basicInformation') }}</div>
        <el-card class="box-card">
          <el-form label-width="auto">
            <el-row>
              <el-col :span="12">
                <!--业务单号-->
                <el-form-item :label="$t('receivablePayable.TicketNumber') + '：'">
                  {{ addBatchFee.waybillNumber }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!--客户单号-->
                <el-form-item :label="$t('receivablePayable.CustomerTrackingNumber') + '：'">
                  {{ addBatchFee.customerNumber }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!--站点-->
                <el-form-item :label="$t('basicData.Outlets') + '：'">
                  {{ addBatchFee.siteName }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!--客户-->
                <el-form-item :label="$t('receivablePayable.customer') + '：'">
                  {{ addBatchFee.customerAlias }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!--包裹类型-->
                <el-form-item :label="$t('placeOrder.packageType') + '：'">
                  <span v-if="addBatchFee.orderPackageType === 1">{{ $t('placeOrder.zhifa') }}</span> <!--直发-->
                  <span v-if="addBatchFee.orderPackageType === 2">{{ $t('placeOrder.CoPacking') }}</span> <!--合包-->
                  <span v-if="addBatchFee.orderPackageType === 3">{{ $t('basicData.other') }}</span> <!--其他-->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!--计费重量-->
                <el-form-item :label="$t('receivablePayable.BillableWeight') + '：'">
                  {{ addBatchFee.chargeWeight }}
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <!--备注-->
                <el-form-item :label="$t('receivablePayable.remark') + '：'">
                  {{ queryFeeParam.remark }}
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </div>
      <!--费用明细-->
      <div class="headerStyle">{{ $t('receivablePayable.FeeBreakdown') }}</div>
      <el-form
        ref="addBatchFee"
        :model="addBatchFee"
        label-width="0px"
        size="small"
        class="error-form"
      >
        <el-table
          :data="addBatchFee.expenseSubList"
          height="300"
          border
        >
          <el-table-column width="55" align="center">
            <template slot="header">
              <span class="el-icon-plus addStyle" @click="addCost()" />
            </template>
            <template slot-scope="scope">
              <!--确定删除吗-->
              <el-popconfirm
                v-if="scope.row.feeSource === 1 && (scope.row.isAccept === false)"
                :title="$t('basicData.AreYouSureDelete')"
                @confirm="deleteCost(scope)"
              >
                <span slot="reference" class="el-icon-delete deleteStyle" />
              </el-popconfirm>
            </template>
          </el-table-column>
          <!--业务类型-->
          <el-table-column
            prop="businessType"
            :label="$t('collectionCenter.businessT')"
            align="center"
            width="180"
          >
            <template slot-scope="scope">
              <div class="formStyle">
                <div v-if="scope.row.isEdit === false">
                  <!-- 快递 -->
                  <span v-if="scope.row.businessType === 0">{{ $t('collectionCenter.expressDelivery') }}</span>
                  <!-- 揽收 -->
                  <span v-if="scope.row.businessType === 1">{{ $t('collectionCenter.lanshou') }}</span>
                  <!--  派送 -->
                  <span v-if="scope.row.businessType === 2">{{ $t('collectionCenter.dispatch') }}</span>
                </div>
                <el-form-item
                  v-else
                  :prop="'expenseSubList.' + scope.$index + '.businessType'"
                  :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                >
                  <el-select
                    v-model="scope.row.businessType"
                    filterable
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    clearable
                    style="width: 100%;"
                    @change="businessTypeChange(scope)"
                  >

                    <el-option
                      v-for="item of businessTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <!--费用类型-->
          <el-table-column
            prop="feeName"
            :label="$t('basicData.ExpenseType')"
            align="center"
            width="180"
          >
            <template slot-scope="scope">
              <div class="formStyle">
                <div v-if="scope.row.isEdit === false">
                  {{ scope.row.feeName }}
                </div>
                <el-form-item
                  v-else
                  :prop="'expenseSubList.' + scope.$index + '.feeCode'"
                  :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                >
                  <el-select
                    v-model="scope.row.feeCode"
                    filterable
                    clearable
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="item in scope.row.feeTypeOptionList"
                      :key="item.id"
                      :label="item.displayName"
                      :value="item.code"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <!--是否生成对账单-->
          <el-table-column
            prop="isGenBill"
            :label="$t('receivablePayable.WhetherToGenerateAStatement')"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.isGenBill ? $t('receivablePayable.Generated') : $t('receivablePayable.NotGenerated') }}</div>
            </template>
          </el-table-column>
          <!--是否核收-->
          <el-table-column
            prop="isAccept"
            :label="$t('receivablePayable.WhetherToCheckOrNot')"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.isAccept ? $t('basicData.Received') : $t('basicData.NotReconciled') }}</div>
            </template>
          </el-table-column>
          <!--计费重量-->
          <el-table-column
            prop="chargeWeight"
            :label="$t('receivablePayable.BillableWeight')"
            width="100"
            align="center"
          >
            <div>{{ addBatchFee.chargeWeight }}</div>
          </el-table-column>
          <!--应收金额-->
          <el-table-column
            prop="fee"
            :label="$t('receivablePayable.AmountReceivable')"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <div class="formStyle">
                <div v-if="scope.row.isEdit === false">
                  {{ scope.row.fee }}
                </div>
                <el-form-item
                  v-else
                  :prop="'expenseSubList.' + scope.$index + '.fee'"
                  :rules="{ required: true, suffix: 2, negative: '-?', validator: priceType, trigger: ['blur', 'change'] }"
                >
                  <el-input v-model="scope.row.fee" />
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <!--业务时间-->
          <el-table-column
            prop="operateTime"
            :label="$t('basicData.BusinessTime')"
            align="center"
            width="240"
          >
            <template slot-scope="scope">
              <div class="formStyle">
                <div v-if="scope.row.isEdit === false">
                  {{ scope.row.operateTime }}
                </div>
                <el-form-item
                  v-else
                  :prop="'expenseSubList.' + scope.$index + '.operateTime'"
                  :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                >
                  <el-date-picker
                    v-model="scope.row.operateTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 100%;"
                    :placeholder="$t('basicData.SelectADatetime')"
                  />
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <!--录入人员-->
          <el-table-column
            prop="createUser"
            :label="$t('receivablePayable.EnterThePerson')"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              <div class="formStyle">
                <div v-if="scope.row.isEdit === false">
                  {{ createUser[scope.row.createUser] }}
                </div>
                <el-form-item
                  v-else
                  :prop="'expenseSubList.' + scope.$index + '.createUser'"
                >
                  <div>{{ createUser[scope.row.createUser] }}</div>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <!--平摊方式-->
          <el-table-column
            v-if="addType === 1"
            prop="flatMethod"
            :label="$t('receivablePayable.FlatSharingMethod')"
            align="center"
            width="180"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="'expenseSubList.' + scope.$index + '.flatMethod'"
                :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <el-select
                  v-model="scope.row.flatMethod"
                  filterable
                  clearable
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  style="width: 100%;"
                >
                  <!--按重量平摊-->
                  <el-option key="0" :label="$t('receivablePayable.SpreadEquallyByWeight')" value="1" />
                  <!--按票数平摊-->
                  <el-option key="1" :label="$t('receivablePayable.SplitEquallyByTheNumberOfVotes')" value="2" />
                  <!--指定金额-->
                  <el-option key="2" :label="$t('receivablePayable.SpecifyTheAmount')" value="3" />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <!--费用来源-->
          <el-table-column
            prop="feeSource"
            :label="$t('receivablePayable.SourceOfFees')"
            align="center"
            width="180"
          >
            <template slot-scope="scope">
              <!--'系统生成' : '手动添加'-->
              <div>
                <span v-if="scope.row.feeSource === 0">{{ $t('receivablePayable.SystemGenerated') }}</span>
                <span v-else-if="scope.row.feeSource === 1">{{ $t('receivablePayable.AddManually') }}</span>
                <span v-else>{{ $t('receivablePayable.CollectionSystemPush') }}</span>
              </div>
            </template>
          </el-table-column>
          <!-- 收件人邮编 -->
          <el-table-column
            prop="receiveZipcode"
            :label="$t('collectionCenter.Recipientzc')"
            align="center"
            min-width="120"
          />
          <!-- 发件人邮编 -->
          <el-table-column
            prop="senderZipcode"
            :label="$t('collectionCenter.senderZipCode')"
            align="center"
            min-width="120"
          />
          <!--备注-->
          <el-table-column
            prop="remark"
            :label="$t('receivablePayable.remark')"
            align="center"
            min-width="160"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.isEdit === false">
                {{ scope.row.remark }}
              </div>
              <el-form-item
                v-else
                :prop="'expenseSubList.' + scope.$index + '.remark'"
                :rules="{ required: false, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <el-input v-model="scope.row.remark" :placeholder="$t('GlobalSetObj.pleaseInputContent')" />
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
    <div style="padding: 20px;text-align: center;">
      <!--保存-->
      <el-button type="primary" size="small" @click="saveData(2)">{{ $t('basicData.Save') }}</el-button>
      <!--关闭-->
      <el-button size="small" @click="saveData(1)">{{ $t('collectionCenter.close') }}</el-button>
    </div>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import {
  apiGetSubsByWaybillNumber, apiReCalculateDeleteFeeDTO,
  apiSiteExpenseAddFeeSub,
  apiSiteExpenseAddMultiFeeSub
} from '@/api/finance/receivables';
import { feePage } from '@/api/finance/partition';
import { getOneBusinessTypeList, getBatchBusinessTypeList } from './utils';

export default {
  name: 'BatchAddFee',
  props: {

    // 当前选中的值
    queryFeeParam: {
      type: Object,
      default: () => {
        return {};
      }
    },
    feeTypeOption: {
      type: Array,
      default: () => {
        return [];
      }
    },
    queryForm: {
      type: Object,
      default: () => {
        return {};
      }
    },
    selectData: {
      type: Array,
      default: () => {
        return [];
      }
    },

    // 1: 单票添加费用 2：费用详情-单票添加费用
    addType: {
      type: Number,
      default: 1
    }
  },
  data() {
    const priceType = (rule, value, callback) => {
      const reg = new RegExp('(^' + rule.negative + '[1-9]\\d*(\\.\\d{1,' + rule.suffix + '})?$)|(^' + rule.negative + '0(\\.\\d{1,' + rule.suffix + '})?$)');
      const { required } = rule;
      if (!required) {
        // 非必填
        if (value) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // 格式不正确
          } else {
            this.adopt = true;
            return callback();
          }
        } else {
          return callback();
        }
      } else {
        if (value) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            this.adopt = true;
            return callback();
          }
        } else {
          this.adopt = false;
          return callback(this.$i18n.t('basicData.CannotBeEmpty')); // '不能为空'
        }
      }
    };
    return {
      total: 0,
      addBatchFee: {
        expenseSubList: []
      },
      currencyOption: [],
      showSearch: false,
      priceType: priceType,
      createUser: null
    };
  },

  computed: {
    // 获取费用明细业务类型下拉菜单可选项
    businessTypeList() {
      // 添加单个
      if (this.addType === 2) {
        const { businessType, billingMethod } = this.queryFeeParam;

        return getOneBusinessTypeList(businessType, billingMethod);
      }
      // 批量添加
      if (this.addType === 1) {
        const { businessType } = this.queryForm.params;
        return getBatchBusinessTypeList(businessType);
      }

      return [];
    }
  },

  created() {
    this.createUser = getUserIdAndNameObj();
    if (this.queryFeeParam.waybillNumber) {
      apiGetSubsByWaybillNumber(this.queryFeeParam).then(res => {
        if (res.code === 200) {
          res.data.expenseSubList.map(item => {
            item['isEdit'] = false;
          });
          this.addBatchFee = res.data;
        }
      });
    }
  },
  methods: {
    // 业务类型变化
    businessTypeChange(scope) {
      this.addBatchFee.expenseSubList[scope.$index].feeName = '';
      this.addBatchFee.expenseSubList[scope.$index].feeCode = '';
      // 获取费用类型
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10000,
        params: {
          state: 1,
          businessType: scope.row.businessType
        }
      };
      feePage(param, false).then(res => {
        if (res.code === 200) {
          this.addBatchFee.expenseSubList[scope.$index].feeTypeOptionList = res.data.records;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 关闭，保存
    saveData(type) {
      if (type === 2) {
        if (this.addType === 1) {
          if (this.addBatchFee.expenseSubList.length > 1) {
            this.$message.warning(this.$i18n.t('receivablePayable.OnlyOneChargeCanBeAdded')); // 只能添加一条费用
            return false;
          }
          if (this.addBatchFee.expenseSubList.length === 0) {
            this.$message.warning(this.$i18n.t('receivablePayable.AddAtLeastOneCharge')); // '最少添加一条费用'
            return false;
          }
          const ids = [];
          if (this.selectData.length > 0) {
            this.selectData.map(item => {
              ids.push(item.id);
            });
          }
          const param = {
            condition: this.queryForm.params,
            expenseSubList: this.addBatchFee.expenseSubList
          };
          param.condition.ids = ids;
          this.$refs.addBatchFee.validate((valid) => {
            if (valid) {
              apiSiteExpenseAddFeeSub(param).then(res => {
                if (res.code === 200) {
                  this.$message.success(this.$i18n.t('collectionCenter.SaveSuccessfully')); // '保存成功'
                  this.$emit('saveForm', type);
                }
              }).catch(err => {
                console.error(err);
              });
            }
          });
        } else {
          const list = [];
          this.addBatchFee.expenseSubList.map(item => {
            if (item.isEdit) {
              list.push(item);
            }
          });
          this.$refs.addBatchFee.validate((valid) => {
            if (valid) {
              const param = {
                waybillNumber: this.queryFeeParam.waybillNumber,
                orderNumber: this.queryFeeParam.orderNumber,
                expenseSubList: list
              };
              apiSiteExpenseAddMultiFeeSub(param).then(res => {
                if (res.code === 200) {
                  this.$message.success(this.$i18n.t('collectionCenter.SaveSuccessfully')); // '保存成功'
                  this.$emit('saveForm', type);
                }
              });
            }
          });
        }
      } else {
        this.$emit('saveForm', type);
      }
    },
    // 删除新增
    deleteCost(scope) {
      if (scope.row.id) {
        apiReCalculateDeleteFeeDTO({ id: scope.row.id }).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // '操作成功'
            this.addBatchFee.expenseSubList.splice(scope.$index, 1);
          }
        });
      } else {
        this.addBatchFee.expenseSubList.splice(scope.$index, 1);
      }
    },
    // 添加费用
    addCost() {
      const data = {
        isEdit: true,
        businessType: '', // 业务类型
        feeName: '', // 费用类型
        feeCode: '',
        feeTypeOptionList: this.feeTypeOption,
        feeSource: 1, // 费用来源
        fee: '', // 应收金额
        isGenBill: 0, // 是否生成对账单
        isAccept: false, // 是否核收
        isOut: 0, // 是否已出库
        billBatchNumber: '', // 对账批次号
        remark: '', // 备注
        createUser: this.$store.state.user.id, // 录入人员
        operateTime: '', // 生成业务时间
        flatMethod: '', // 平摊方式
        chargeWeight: this.queryFeeParam.chargeWeight // 计费重量
      };
      if (this.addType === 1) {
        if (this.addBatchFee.expenseSubList.length === 0) {
          this.addBatchFee.expenseSubList.push(data);
        } else {
          this.$message.warning(this.$i18n.t('receivablePayable.OnlyOneChargeCanBeAdded')); // 只能添加一条费用
        }
      } else {
        this.addBatchFee.expenseSubList.push(data);
      }
    }
  }
};
</script>

<style scoped>
.headerStyle {
  background:#eee;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
}
.el-form-item {
  margin-bottom: 0;
}
</style>
<style>
.addTable .el-table tbody .el-table__cell{
  padding: 5px;
}
.addStyle {
  color: #409EFF;
  cursor: pointer;
  font-size: 18px;
}
.deleteStyle {
  color: #F56C6C;
  cursor: pointer;
  font-size: 18px;
}
</style>
