var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { inline: false, "label-position": "top", size: "small" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.operationTime") } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          clearable: false,
                          "default-time": ["00:00:00", "23:59:59"],
                          format:
                            _vm.$i18n.locale == "zh" ? _vm.zhDate : _vm.ptDate,
                          placeholder: _vm.$t("newOrder.SelectDatetime"),
                          "range-separator": "~",
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.timeValue,
                          callback: function ($$v) {
                            _vm.timeValue = $$v
                          },
                          expression: "timeValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.OperationState") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getOperationState(
                                1,
                                _vm.queryForm.params.operationalUserState
                              )
                            },
                          },
                          model: {
                            value: _vm.queryForm.params.operationalUserState,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "operationalUserState",
                                $$v
                              )
                            },
                            expression: "queryForm.params.operationalUserState",
                          },
                        },
                        _vm._l(_vm.StateList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.label, value: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.OperationSite") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getOperationState(
                                2,
                                _vm.queryForm.params.operationalUserSiteId
                              )
                            },
                          },
                          model: {
                            value: _vm.queryForm.params.operationalUserSiteId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "operationalUserSiteId",
                                $$v
                              )
                            },
                            expression:
                              "queryForm.params.operationalUserSiteId",
                          },
                        },
                        _vm._l(_vm.opearateSitelist, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.operator") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            "remote-method": _vm.getOperationName,
                            clearable: "",
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                          },
                          model: {
                            value: _vm.queryForm.params.operateUserName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "operateUserName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "queryForm.params.operateUserName",
                          },
                        },
                        _vm._l(_vm.userList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.operateUserName,
                              value: item.keyId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.operationType"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.operationType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "operationType",
                                $$v
                              )
                            },
                            expression: "queryForm.params.operationType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.all"),
                              value: "",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.arrive"),
                              value: 2,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.sender"),
                              value: 1,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.truckLoading"),
                              value: 3,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.unloading"),
                              value: 4,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          { staticClass: "selectLabel" },
                          [
                            _c(
                              "el-select",
                              {
                                style: _vm.IsWidth,
                                model: {
                                  value: _vm.queryForm.params.selectType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "selectType",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.selectType",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t("newOrder.waybillNumber"),
                                    value: 1,
                                  },
                                }),
                                _c("el-option", {
                                  key: "3",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.ScanNumber"),
                                    value: 3,
                                  },
                                }),
                                _c("el-option", {
                                  key: "2",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.bagNumber"),
                                    value: 2,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("newOrder.PreciseSearch1"),
                          rows: 1,
                          type: "textarea",
                        },
                        model: {
                          value: _vm.queryForm.params.inputValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm.params, "inputValue", $$v)
                          },
                          expression: "queryForm.params.inputValue",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("newOrder.RecipientState") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                          },
                          on: { change: _vm.handlepreDeliverySiteState },
                          model: {
                            value: _vm.queryForm.params.preDeliverySiteState,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "preDeliverySiteState",
                                $$v
                              )
                            },
                            expression: "queryForm.params.preDeliverySiteState",
                          },
                        },
                        _vm._l(_vm.StateList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.label, value: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.preEntrega") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.preDeliverySiteId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "preDeliverySiteId",
                                $$v
                              )
                            },
                            expression: "queryForm.params.preDeliverySiteId",
                          },
                        },
                        _vm._l(_vm.preDeliverySiteList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.nextStation") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                            multiple: "",
                          },
                          model: {
                            value: _vm.queryForm.params.nextSiteIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "nextSiteIds", $$v)
                            },
                            expression: "queryForm.params.nextSiteIds",
                          },
                        },
                        _vm._l(_vm.siteList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "selectType" } },
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          { staticClass: "selectLabel" },
                          [
                            _c(
                              "el-select",
                              {
                                style: _vm.IsWidth,
                                on: { change: _vm.handleQueryType },
                                model: {
                                  value: _vm.queryForm.params.queryType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "queryType",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.queryType",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.licensePlateNumber"
                                    ),
                                    value: 1,
                                  },
                                }),
                                _c("el-option", {
                                  key: "2",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.NIV"),
                                    value: 2,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "remote-method": _vm.getVehicleTagNumber,
                            clearable: "",
                            filterable: "",
                            multiple: "",
                            remote: "",
                            "reserve-keyword": "",
                          },
                          model: {
                            value: _vm.queryForm.params.numberList,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "numberList", $$v)
                            },
                            expression: "queryForm.params.numberList",
                          },
                        },
                        _vm._l(_vm.nivNumberList, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("newOrder.waybillStatus") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.all"),
                            clearable: "",
                            "collapse-tags": "",
                            filterable: "",
                            multiple: "",
                          },
                          on: { change: _vm.selectStatus },
                          model: {
                            value: _vm.queryForm.params.waybillStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "waybillStatus",
                                $$v
                              )
                            },
                            expression: "queryForm.params.waybillStatus",
                          },
                        },
                        _vm._l(_vm.waybillStatusList, function (item, ind) {
                          return _c("el-option", {
                            key: ind,
                            attrs: { label: item.label, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.client") } },
                    [
                      _c("QueryCustomerV2Cmp", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "multiple-limit": 10,
                          val: _vm.queryForm.params.customerIdList,
                          multiple: "",
                        },
                        on: {
                          change: function ($event) {
                            _vm.queryForm.params.customerIdList = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        size: "small",
                        type: "primary",
                      },
                      on: { click: _vm.enterSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh-left",
                        size: "small",
                        type: "info",
                      },
                      on: { click: _vm.resetForm },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.reset")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { icon: "el-icon-upload2", size: "small" },
                      on: { click: _vm.allExportOrder },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.export")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.clickBtchExport },
                    },
                    [_vm._v(_vm._s(_vm.$t("placeOrder.BatchExport")) + " ")]
                  ),
                  _c(
                    "el-radio-group",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:SortingCenter:outGoRecordData",
                          expression: "'btn:SortingCenter:outGoRecordData'",
                          arg: "remove",
                        },
                      ],
                      staticStyle: { "margin-left": "10px" },
                      attrs: { size: "small" },
                      on: { change: _vm.handleTest },
                      model: {
                        value: _vm.isTest,
                        callback: function ($$v) {
                          _vm.isTest = $$v
                        },
                        expression: "isTest",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v(_vm._s(_vm.$t("newOrder.Formal"))),
                      ]),
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v(_vm._s(_vm.$t("newOrder.test1"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "header-cell-style": { background: "#f2f2f2", color: "black" },
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.serial", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.serial", "pu") +
                "）",
              align: "center",
              type: "index",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.bagNumber", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.bagNumber", "pu") +
                "）",
              "min-width": "130",
              prop: "bagNumber",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.bagNumber || "--"))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("newOrder.waybillNumber", "zh") +
                "（" +
                _vm.$t("newOrder.waybillNumber", "pu") +
                "）",
              "min-width": "160",
              prop: "waybillNumber",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top",
                              "popper-class": "copy",
                              trigger: "hover",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "black",
                                  "font-size": "12px",
                                  padding: "2px",
                                },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(scope.row.waybillNumber)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("orderDetails.copy", "zh") +
                                      "（" +
                                      _vm.$t("orderDetails.copy", "pu") +
                                      "）"
                                  ) + " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "WaybillNumber",
                                attrs: { slot: "reference" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toOrderDetail(scope.row)
                                  },
                                },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.waybillNumber || "--") +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.ScanNumber", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.ScanNumber", "pu") +
                "）",
              "min-width": "160",
              prop: "scanOrderNumber",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(scope.row.scanOrderNumber || "--")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.client", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.client", "pu") +
                "）",
              "min-width": "120",
              prop: "customerName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("newOrder.waybillStatus", "zh") +
                "（" +
                _vm.$t("newOrder.waybillStatus", "pu") +
                "）",
              "min-width": "160",
              prop: "waybillStatusName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("collectionCenter.operationType", "zh") +
                "（" +
                _vm.$t("collectionCenter.operationType", "pu") +
                "）",
              align: "center",
              "min-width": "150",
              prop: "operationTypeName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.operationTime", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.operationTime", "pu") +
                "）",
              "min-width": "170",
              prop: "operateTime",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.nextStation", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.nextStation", "pu") +
                "）",
              "min-width": "170",
              prop: "nextSiteName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.licensePlateNumber", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.licensePlateNumber", "pu") +
                "）",
              "min-width": "160",
              prop: "vehicleNumber",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.vehicleNumber || "-"))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.NIV", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.NIV", "pu") +
                "）",
              "min-width": "140",
              prop: "vehicleTagNumber",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(scope.row.vehicleTagNumber || "-")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("newOrder.RecipientState", "zh") +
                "（" +
                _vm.$t("newOrder.RecipientState", "pu") +
                "）",
              "min-width": "120",
              prop: "preState",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.preEntrega", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.preEntrega", "pu") +
                "）",
              "min-width": "160",
              prop: "preDeliverSiteName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.OperationState", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.OperationState", "pu") +
                "）",
              "min-width": "100",
              prop: "operateUserState",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.OperationSite", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.OperationSite", "pu") +
                "）",
              "min-width": "120",
              prop: "operateSiteName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.operator", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.operator", "pu") +
                "）",
              "min-width": "150",
              prop: "operateUserName",
            },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.number,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("orderDetails.waybillMsg"),
            visible: _vm.waybillDescription,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.waybillDescription = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "calc(100vh - 67px)", overflow: "auto" } },
            [
              _c("detailPage", {
                key: _vm.detailKey,
                attrs: {
                  "query-track-param": _vm.queryTrackParam,
                  "query-type": "1",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "show-close": true,
            title: _vm.$t("placeOrder.BatchExport"),
            visible: _vm.dialogVisibleExport,
            top: "10vh",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleExport = $event
            },
            close: _vm.clearForm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "exportRuleForm",
              staticClass: "demo-exportRuleForm",
              attrs: { model: _vm.exportRuleForm, "label-position": "top" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    rules: {
                      required: true,
                      message: _vm.$t("placeOrder.pleaseInput"),
                      trigger: ["blur", "change"],
                    },
                    prop: "NumberType",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      model: {
                        value: _vm.exportRuleForm.NumberType,
                        callback: function ($$v) {
                          _vm.$set(_vm.exportRuleForm, "NumberType", $$v)
                        },
                        expression: "exportRuleForm.NumberType",
                      },
                    },
                    [
                      _c("el-option", {
                        key: "1",
                        attrs: {
                          label: _vm.$t("orderCenterCont.anjunWaybillNumber"),
                          value: "1",
                        },
                      }),
                      _c("el-option", {
                        key: "5",
                        attrs: {
                          label: _vm.$t("GlobalSetObj.ScanNumber"),
                          value: "5",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    rules: {
                      required: true,
                      message: _vm.$t("placeOrder.pleaseInput"),
                      trigger: ["blur"],
                    },
                    prop: "inputValeu",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("orderCenterCont.Multiple"),
                      clearable: "",
                      rows: "6",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.exportRuleForm.inputValeu,
                      callback: function ($$v) {
                        _vm.$set(_vm.exportRuleForm, "inputValeu", $$v)
                      },
                      expression: "exportRuleForm.inputValeu",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right", margin: "15px 0" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisibleExport = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.comfireBtchExport },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.CheckItOutNow")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }