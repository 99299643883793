import newRequest from '@/utils/new-request';

// 理赔列表

// 分页
export const apiClaimformPage = data => {
  return newRequest.post('/claimform/page', data);
};

// 详情
export const apiClaimformIds = id => {
  return newRequest.get(`/claimform/${id}`);
};

// 保存
export const apiClaimformAdd = data => {
  return newRequest.post('/claimform/add', data);
};

// 关闭
export const apiClaimformClose = id => {
  return newRequest.get(`/claimform/close/${id}`);
};

// 修改
export const apiClaimformEdit = data => {
  return newRequest.put('/claimform/edit', data);
};

// 提交审核
export const apiClaimformCommit = id => {
  return newRequest.post(`/claimform/commit/${id}`);
};

// 关联订单详情-普通订单/备货揽收/快递单
export const apiClaimformOrderDetail = (data, isLoading = true) => {
  // return request.post('/fa/claimform/orderDetail', data);
  return newRequest({
    method: 'POST',
    url: '/claimform/orderDetail',
    data,
    isLoading // 是否显示加载中参数
  });
};

// 提交付款
export const apiClaimformCommitPay = data => {
  return newRequest.post('/claimform/commitPay', data);
};

// 审核
export const apiClaimformRejectAndPass = data => {
  return newRequest.post('/claimform/rejectAndPass', data);
};

// 关联订单-导出
export const apiClaimformExport = data => {
  return newRequest.post('/claimform/export', data);
};

// 理赔列表-导出
export const apiClaimformFormExport = data => {
  return newRequest.post('/claimform/formExport', data);
};

