<template>
  <div class="app-container pwd">
    <div class="box">
      <el-card>
        <!-- 请设置新的密码 -->
        <h3>{{ $t('changepwd.setNewPwd') }}</h3>

        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px">
          <!-- 旧密码 -->
          <el-form-item
            :label="$t('changepwd.oldPwd')"
            prop="oldPassword"
          >
            <!-- 请输入旧密码 -->
            <el-input v-model="ruleForm.oldPassword" show-password :placeholder="$t('changepwd.inputOldPwd')" />
          </el-form-item>
          <!-- 新密码 -->
          <el-form-item
            :label="$t('changepwd.newPwd')"
            prop="newPassword"
          >
            <!-- 请输入新密码 -->
            <el-input v-model.trim="ruleForm.newPassword" :placeholder="$t('changepwd.inputNewPwd')" show-password minlength="8" maxlength="12" />

            <!-- 必须由 4-16 位小写或大写字母、数字组成 -->
            <span class="tips">{{ $t('changepwd.inputHint') }}</span>
          </el-form-item>
          <!-- 确认密码 -->
          <el-form-item
            :label="$t('changepwd.checkPwd')"
            prop="newPasswordConfirm"
          >
            <!-- 请再次输入新密码 -->
            <el-input v-model.trim="ruleForm.newPasswordConfirm" :placeholder="$t('changepwd.inputConfirmCode')" show-password minlength="8" maxlength="12" />
          </el-form-item>
          <el-form-item>
            <!-- 确认 -->
            <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('changepwd.confirm') }}</el-button>
            <!-- 重置 -->
            <el-button @click="resetForm('ruleForm')">{{ $t('changepwd.reset') }}</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>

  </div>
</template>

<script>
// import { weakPwd } from '@/utils/reg_util';
import { weakPwd } from '@/utils/validate';
import { apiResetPassword } from '@/api/user';
import md5 from 'js-md5';

export default {
  data() {
    return {

      ruleForm: {
        newPassword: '', // 新密码
        newPasswordConfirm: '', // 确认新密码
        oldPassword: '', // 旧密码
        userId: ''
      },

      userId: '', // 用户id

      rules: {
        oldPassword: [{ required: true, message: this.$t('changepwd.inputOldPwd'), trigger: ['blur', 'change'] }],
        newPassword: [
          { required: true, message: this.$t('changepwd.inputNewPwd'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const { oldPassword } = this.ruleForm;
              if (!weakPwd(value)) {
                callback(new Error(this.$t('changepwd.requestedInputCode')));
              } else if (value === oldPassword) {
                callback(new Error(this.$t('changepwd.newCodeNotOldCode')));
                return;
              } else {
                callback();
              }
            }
          }
        ],
        newPasswordConfirm: [
          // { required: true, validator: newPasswordConfirmCheck, trigger: 'blur' }
          { required: true, message: this.$t('changepwd.inputConfirmCode'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const { newPassword } = this.ruleForm;
              if (value === '') {
                callback(new Error(this.$t('changepwd.inputConfirmCode')));
                return;
              }
              if (newPassword !== value) {
                callback(new Error('确认密码与新密码不相同'));
                return;
              }
              callback();
            }
          }
        ]
      }
    };
  },

  created() {
    this.userId = this.$store.state.user.id;
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.asyncResetPassword();
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    /**
     * 设置新密码
     */
    asyncResetPassword() {
      // const { newPassword, oldPassword } = this.ruleForm;
      const newPassword = md5(md5(this.ruleForm.newPassword));
      const oldPassword = md5(md5(this.ruleForm.oldPassword));
      const obj = {
        isVerification: 1, // 是否需要验证旧密码
        newPassword,
        oldPassword,
        id: this.userId
      };
      apiResetPassword(obj).then(data => {
        if (data.code===200) {
          return this.$store.dispatch('user/logout');
        }
      }).then(data => {
        this.$notify({
          title: this.$t('changepwd.succeed'),
          message: this.$t('changepwd.loginAgain'),
          type: 'success'
        });
        setTimeout(() => {
          this.$router.push(`/login?redirect=${this.$route.fullPath}`);
        }, 1000);
      }).catch(err => {
        console.log(err);
      });
    }
  }
};
</script>

<style scoped>
.app-container.pwd ::v-deep .el-form-item__error{
  position: relative;
}
.app-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  margin-top: 200px;
  width: 30%;
}

.tips {
  color: #b4b2b2;
  font-size: 12px;
  line-height: 13px;
  display: inline-block;
}
</style>
