<template>
  <div class="Box">
    <el-form class="selectClass">
      <el-row>
        <el-col :span="12" style="width: auto;">
          <!-- 揽收时间： -->
          <el-form-item :label="$t('collectionCenter.lanshouT') + '：'">
            <el-date-picker
              ref="dateRef"
              v-model="timeValue"
              :clearable="false"
              type="datetimerange"
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
              :range-separator="$t('collectionCenter.to')"
              :start-placeholder="$t('collectionCenter.StartTime')"
              :end-placeholder="$t('collectionCenter.EndTime')"
              @change="dateChange"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7" style="width: auto;">
          <el-form-item :label="$t('collectionCenter.LanShouSite') + '：'">
            <el-select
              v-model="queryForm.params.collectSiteId"
              filterable
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              style="width: 100%"
              @focus="getNewestSite"
            >
              <el-option
                v-for="item in siteIdList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7" style="width: auto;"><!--所属平台 -->
          <el-form-item :label="$t('collectionCenter.platform') + '：'">
            <el-select
              v-model="queryForm.params.platform"
              :placeholder="$t('collectionCenter.pleaseChoose')"
              clearable
            >
              <el-option :label="$t('collectionCenter.tudo')" :value="null" /><!-- 全部 -->
              <el-option :value="1" label="shein" /><!-- shein -->
              <el-option :label="$t('collectionCenter.expressDelivery')" :value="3" /><!-- 快递 -->
              <el-option :value="2" label="kwai" /><!-- kwai -->
              <el-option :label="$t('collectionCenter.other')" :value="0" /><!-- 其他 -->
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <!-- 条形码： -->
          <el-form-item :label="$t('collectionCenter.Barcode') + '：'">
            <div class="BlockNewlineClass">
              <el-input
                v-model="queryForm.params.scanNumberList"
                type="textarea"
                :autosize="{ maxRows: 5}"
                :placeholder="$t('collectionCenter.BatchQuery')"
                @blur="ft"
                @keydown.enter.native="BlockNewline"
              />
            </div>
            <!-- 支持批量查询(回车换行分割) -->
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-col :span="24">
        <el-button
          v-permit:remove="'btn:collection-center:collectNode-errData-report:find'"
          icon="el-icon-search"
          size="mini"
          type="primary"
          @click="searchClick"
        >{{ $t('collectionCenter.search') }}
        </el-button><!-- 查询 -->
        <el-button
          v-permit:remove="'btn:collection-center:collectNode-errData-report:reset'"
          icon="el-icon-refresh"
          size="mini"
          @click="resetClick"
        >{{ $t('collectionCenter.reset') }}
        </el-button><!-- 重置 -->
        <el-button
          v-permit:remove="'btn:collection-center:collectNode-errData-report:allExport'"
          icon="el-icon-upload"
          size="mini"
          :class="isClickAll ? 'onlyClickOnce10' : ''"
          @click="downloadAll"
        >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
      </el-col>
    </el-row>

    <el-divider />

    <el-table
      ref="tableRef"
      border
      :max-height="600"
      :data="tableData"
    >
      <el-table-column
        prop="scanNumber"
        align="center"
        :label="$t('collectionCenter.Barcode')"
      /><!-- 条形码 -->
      <el-table-column
        prop="orderNumber"
        align="center"
        :label="$t('collectionCenter.lanshouOrderNumber')"
      /><!-- 条形码 -->
      <el-table-column
        prop="orderState"
        align="center"
        :label="$t('collectionCenter.OrderStatus')"
      ><!-- 订单状态 -->
        <template slot-scope="scope">
          <div>
            {{
              scope.row.orderState == 0 ? $t('collectionCenter.notLanShou') :
              scope.row.orderState == 1 ? $t('collectionCenter.hasLanShou') :
              scope.row.orderState == 2 ? $t('collectionCenter.cancelled') :
              scope.row.orderState == 4 ? $t('collectionCenter.scheduled') :
              scope.row.orderState == 5 ? $t('collectionCenter.AbnormalLanShou') :
              scope.row.orderState == 6 ? $t('collectionCenter.haveBeenSigned') :
              scope.row.orderState == 7 ? $t('collectionCenter.ProblemPiece') :
              scope.row.orderState == 8 ? $t('collectionCenter.SealedBag') :
              scope.row.orderState == 9 ? $t('collectionCenter.truckLoading') :
              scope.row.orderState == 10 ? $t('collectionCenter.unloading') :
              scope.row.orderState == 11 ? $t('collectionCenter.Bagged') :
              scope.row.orderState == 12 ? $t('collectionCenter.closedLoop') :
              scope.row.orderState == 13 ? $t('collectionCenter.unclaimed') :
              scope.row.orderState == 14 ? $t('collectionCenter.collectionPointInventory') : ''
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="customerId"
        align="center"
        :label="$t('collectionCenter.clientID')"
      /><!-- 客户编号 -->
      <el-table-column
        prop="customerName"
        align="center"
        :label="$t('collectionCenter.CustomerName')"
      /><!-- 客户名称 -->
      <el-table-column
        prop="platform"
        align="center"
        :label="$t('collectionCenter.platform')"
      ><!-- 所属平台 -->
        <!-- 0:其他 1:shein 2:kwai 3:快递 -->
        <template slot-scope="scope">
          <div>
            {{
              scope.row.platform == 0 ? $t('collectionCenter.other') :
              scope.row.platform == 1 ? 'shein' :
              scope.row.platform == 2 ? 'kwai' :
              scope.row.platform == 3 ? $t('collectionCenter.expressDelivery') : ''
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="merchantCode"
        align="center"
        :label="$t('collectionCenter.MerchantNum')"
      /><!-- 商户号 -->
      <el-table-column
        prop="merchantName"
        align="center"
        :label="$t('collectionCenter.MerchantName')"
      /><!-- 商户名称 -->
      <el-table-column
        prop="collectTime"
        align="center"
        :label="$t('collectionCenter.lanshouT')"
      /><!-- 揽收时间 -->
      <el-table-column
        prop="collectUserName"
        align="center"
        :label="$t('collectionCenter.LanShouPeople')"
      /><!-- 揽收人 -->
      <el-table-column
        prop="collectStationName"
        align="center"
        :label="$t('collectionCenter.LanShouSite')"
      /><!-- 揽收网点 -->
      <el-table-column
        prop="collectTimeLength"
        align="center"
        :label="$t('collectionCenter.cDuration')"
      /><!-- 揽收时长 -->
      <el-table-column
        prop="transportTimeLength"
        align="center"
        :label="$t('collectionCenter.tDuration')"
      /><!-- 运输时长 -->
      <el-table-column
        prop="transitTimeLength"
        align="center"
        :label="$t('collectionCenter.tfDuration')"
      /><!-- 中转时长 -->
      <el-table-column
        prop="arrivalTimeLength"
        align="center"
        :label="$t('collectionCenter.prescriptionArrival')"
      /><!-- 运抵时效 -->
      <el-table-column
        prop="senderState"
        align="center"
        :label="$t('collectionCenter.sendState')"
      /><!-- 寄件州 -->
      <el-table-column
        prop="senderCity"
        align="center"
        :label="$t('collectionCenter.sendCity')"
      /><!-- 寄件城市 -->
      <el-table-column
        prop="senderZipcode"
        align="center"
        :label="$t('collectionCenter.SendZipcode')"
      /><!-- 寄件邮编 -->
      <el-table-column
        prop="senderBigArea"
        align="center"
        :label="$t('collectionCenter.senderSubregion')"
      /><!-- 寄件人所属大区 -->
      <el-table-column
        prop="senderZone"
        align="center"
        :label="$t('collectionCenter.senderOwningRegion')"
      /><!-- 寄件人所属区域 -->
      <el-table-column
        prop="firstVehicleNumber"
        align="center"
        :label="$t('collectionCenter.firstLoadingLicensePlate')"
      /><!-- 第一次装车车牌号 -->
      <el-table-column
        prop="firstLoadedUser"
        align="center"
        :label="$t('collectionCenter.firstLoadedUser')"
      /><!-- 第一次装车操作人 -->
      <el-table-column
        prop="firstLoadSite"
        align="center"
        :label="$t('collectionCenter.firstLoadSite')"
      /><!-- 第一次装车网点 -->
      <el-table-column
        prop="firstUnloadUser"
        align="center"
        :label="$t('collectionCenter.firstUnloadUser')"
      /><!-- 第一次卸车操作人 -->
      <el-table-column
        prop="firstUnloadSite"
        align="center"
        :label="$t('collectionCenter.firstUnloadSite')"
      /><!-- 第一次卸车网点 -->
      <el-table-column
        prop="secondVehicleNumber"
        align="center"
        :label="$t('collectionCenter.secondLoadingLicensePlate')"
      /><!-- 第二次装车车牌号 -->
      <el-table-column
        prop="secondLoadedUser"
        align="center"
        :label="$t('collectionCenter.secondLoadedUser')"
      /><!-- 第二次装车操作人 -->
      <el-table-column
        prop="secondLoadSite"
        align="center"
        :label="$t('collectionCenter.secondLoadSite')"
      /><!-- 第二次装车网点 -->
    </el-table>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getPage"
        @size-change="getPageSize"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  searchQueryWholeSite,
  collectAbnormalPage,
  collectAbnormalExport
} from '@/api/lanshou';
export default {
  name: 'CollectNodeErrDataReport',
  data() {
    return {
      isClickAll: false,
      notEnter: true, // 是否允许换行
      timeValue: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '',
          endTime: '',
          platform: '',
          collectSiteId: '',
          scanNumberList: ''
        }
      },
      siteIdList: [],
      total: 0,
      tableData: []
    };
  },
  watch: {
    'queryForm.params.scanNumberList'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 10000) {
        number = bghTrim.slice(0, 10000).join('\n');
        this.queryForm.params.scanNumberList = number;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bghTrim.length > 10000) {
        this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多1000条，已截取前1000条数据'
      }
    }
  },
  created() {
    this.getNewestSite();
    this.isClickAll = false;
    this.resetClick();
    this.searchClick();
  },
  methods: {
    ft() {
      const val = this.queryForm.params.scanNumberList.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.scanNumberList = bghTrim.join('\n');
    },
    BlockNewline() { // 文本域最大输入行数为10000
      const e = window.event || arguments[0];
      this.ft();
      const numberLen = this.queryForm.params.scanNumberList.split('\n');
      if (!this.notEnter) {
        if (numberLen.length >= 10000) {
          this.$message.warning(this.$t('collectionCenter.intercept1')); // '单次查询最多10000条，已截取前10000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    // 获取最新的网点list数据（全部）
    getNewestSite() {
      searchQueryWholeSite().then(res => {
        if (res.code===200) {
          this.siteIdList = [];
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.startTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.startTime = val[0];
      this.queryForm.params.endTime = val[1];
      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-1, 'month').format('YYYY-MM-DD 23:59:59');
        if (val[1] > endTime) {
          this.$message({
            // 只允许查1个月内的数据
            message: this.$t('collectionCenter.OnlyAllowedOneMonthsOfData'),
            type: 'warning'
          });
          const d = new Date();
          const day = dayjs(d).format('YYYY-MM-DD');
          this.timeValue = [day + ' 00:00:00', day + ' 23:59:59'];
          this.queryForm.params.startTime = day + ' 00:00:00';
          this.queryForm.params.endTime = day + ' 23:59:59';
        }
      }
    },
    resetClick() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: today + ' 00:00:00',
          endTime: today + ' 23:59:59',
          platform: '',
          collectSiteId: '',
          scanNumberList: ''
        }
      };
    },
    searchClick() {
      if (!this.timeValue[0] || !this.timeValue[1]) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      this.queryForm.pageNumber = 1;
      localStorage.setItem('cnedrQueryForm', JSON.stringify(this.queryForm));
      this.getPage();
    },
    getPage() {
      const queryForm = JSON.parse(localStorage.getItem('cnedrQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      queryForm.params.scanNumberList = queryForm.params.scanNumberList ? queryForm.params.scanNumberList.split('\n') : [];
      collectAbnormalPage(queryForm).then(res => {
        if (res.code===200) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      }).catch({});
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('cnedrQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      queryForm.params.scanNumberList = queryForm.params.scanNumberList ? queryForm.params.scanNumberList.split('\n') : [];
      collectAbnormalPage(queryForm).then(res => {
        if (res.code===200) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      }).catch({});
    },

    downloadAll() { // 全部导出
      this.isClickAll = true;
      const queryForm = JSON.parse(localStorage.getItem('cnedrQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      queryForm.params.scanNumberList = queryForm.params.scanNumberList ? queryForm.params.scanNumberList.split('\n') : [];
      collectAbnormalExport(queryForm).then(res => {
        if (res.code===200) {
          this.isClickAll = false;
          const h = this.$createElement;
          this.$msgbox({
            // 提示
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
              h('span', this.$t('collectionCenter.exportChangeTip')),
              // 请勿重复导出!
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // 立即前往
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // 关闭
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          }).catch(() => {});
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.BlockNewlineClass ::v-deep textarea.el-textarea__inner {
  min-height: 40px !important;
  max-height: 120px !important;
}

.selectClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.selectClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.selectClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
  width: 100%;
}
.selectClass ::v-deep .el-select{
  width: 100%;
}
.selectClass ::v-deep .el-date-editor .el-range-separator{
  width: 8% !important;
}
</style>
