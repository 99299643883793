<template>
  <div v-loading="loading" class="app-container">
    <el-card class="mb5">
      <el-form label-position="top" size="small" class="searchContainer">
        <el-row :gutter="10">
          <el-col :span="6">
            <!-- 开票时间 -->
            <el-form-item :label="$t('GlobalSetObj.InvoicingTime')">
              <el-date-picker
                v-model="queryForm.params.invoiceIssueTime"
                type="datetimerange"
                range-separator="-"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                :start-placeholder="$t('GlobalSetObj.startDate')"
                :end-placeholder="$t('GlobalSetObj.endDate')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 开票主体 -->
            <el-form-item :label="$t('basicData.BillingBody')">
              <el-input v-model.trim="queryForm.params.issueEntity" :placeholder="$t('GlobalSetObj.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 业务类型 -->
            <el-form-item :label="$t('collectionCenter.businessT')">
              <el-select v-model="queryForm.params.businessType" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%;">
                <el-option :label="$t('collectionCenter.tudo')" :value="null" />
                <el-option
                  v-for="item in BUSINESS_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />

              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 发票状态 -->
            <el-form-item :label="$t('basicData.InvoiceStatus')">
              <el-select v-model="queryForm.params.invoiceStatus" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%;">
                <el-option :label="$t('collectionCenter.tudo')" :value="null" />
                <el-option
                  v-for="item in INVOICE_STATUS"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />

              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="">
              <template slot="label">
                <el-select v-model="type1" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 110px">
                  <!--  发票号码 -->
                  <el-option :label="$t('basicData.InvoiceNumber')" value="invoiceNumberList" />
                  <!-- 业务单号 -->
                  <el-option :label="$t('receivablePayable.TicketNumber')" value="businessNumberList" />
                </el-select>
              </template>
              <InputTextareaCmp ref="inputTextareaRef" :val="listVal" @input="(_, val) => listVal = val" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!--查询-->
      <el-button
        type="primary"
        icon="el-icon-search"
        size="mini"
        @click="getTable"
      >{{ $t('basicData.Search') }}
      </el-button>
      <!--重置-->
      <el-button
        icon="el-icon-refresh"
        size="mini"
        @click="onReset"
      >{{ $t('basicData.reset') }}
      </el-button>
    </el-card>

    <el-card>
      <div class="mb10">
        <!-- 手动开票 -->
        <el-button
          v-permit:remove="'but:finance:receivableManagementSite:invoiceManagementCN:manualInvoicing'"
          size="small" @click="onManualInvoicing"
        >{{ $t('receivablePayable.手动开票') }}</el-button>

        <!-- 重开 -->
        <el-button
          v-permit:remove="'but:finance:receivableManagementSite:invoiceManagementCN:restart'"
          size="small" type="warning" @click="onRestart"
        >{{ $t('basicData.reopen') }}</el-button>

        <!-- 取消 -->
        <el-button
          v-permit:remove="'but:finance:receivableManagementSite:invoiceManagementCN:cancel'"
          size="small" type="danger"
          @click="onCancel"
        >{{ $t('GlobalSetObj.cancel') }}</el-button>

        <!-- 批量下载 -->
        <el-button
          v-permit:remove="'but:finance:receivableManagementSite:invoiceManagementCN:download'"
          size="small" type="primary"
          @click="onDownload"
        >{{ $t('orderCenterCont.BulkDownload') }}</el-button>

        <!-- 导出 -->
        <el-button
          v-permit:remove="'but:finance:receivableManagementSite:invoiceManagementCN:export'"
          size="small" type="primary" @click="onExport"
        >{{ $t('CenterForSorting.export') }}</el-button>
      </div>

      <el-table
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection" width="55"
        />

        <!-- 发票类型 -->
        <el-table-column prop="invoiceTypeStr" :label="$t('collectionCenter.invoiceT')" width="100" />

        <!-- 开票时间 -->
        <el-table-column prop="invoiceIssueTime" :label="$t('GlobalSetObj.InvoicingTime')" width="160" />

        <!-- 业务类型 -->
        <el-table-column prop="businessTypeStr" :label="$t('collectionCenter.businessT')" width="100" />

        <!-- 发票号码 -->
        <el-table-column prop="invoiceNumber" :label="$t('basicData.InvoiceNumber')">
          <template slot-scope="scope">
            <!-- 成功状态 -->
            <el-link
              v-if="scope.row.invoiceStatus === INVOICE_STATUS['1'].value" type="primary"
              @click="onCheckWaybill(scope.row)"
            >{{ scope.row.invoiceNumber }}</el-link>
            <span v-else>{{ scope.row.invoiceNumber }}</span>
          </template>
        </el-table-column>

        <!-- 发票密钥 -->
        <el-table-column prop="invoiceSecretKey" :label="$t('newOrder.InvoiceKey')" />

        <!-- 业务单号 -->
        <el-table-column prop="businessNumber" :label="$t('receivablePayable.TicketNumber')" />

        <!-- 开票主体 -->
        <el-table-column prop="issueEntity" :label="$t('basicData.BillingBody')" />

        <!-- 状态 -->
        <el-table-column prop="invoiceTypeStr" :label="$t('GlobalSetObj.status')" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.invoiceStatus === 1">{{ INVOICE_STATUS['1'].label }}</el-tag>
            <el-tag v-else-if="scope.row.invoiceStatus === 2" type="danger">{{ INVOICE_STATUS['2'].label }}</el-tag>
            <el-tag v-else-if="scope.row.invoiceStatus === 3" type="info">{{ INVOICE_STATUS['3'].label }}</el-tag>
            <span v-else>&nbsp;</span>
            <el-tooltip
              class="item" effect="dark" :content="scope.row.failureReason"
              placement="top-start"
            >
              <i v-if="scope.row.failureReason" class="el-icon-warning-outline" style="margin-left: 5px" />
            </el-tooltip>
          </template>
        </el-table-column>

        <!-- 最后修改人 -->
        <el-table-column prop="updateUserStr" :label="$t('basicData.LastModifier')" />

        <!-- 最后修改时间 -->
        <el-table-column prop="updateTime" :label="$t('basicData.LastModified')" width="160" />

        <!--操作-->
        <el-table-column
          :label="$t('basicData.operate')"
          align="center" width="140"
        >
          <template slot-scope="scope">

            <!-- 成功状态才能取消 -->
            <el-button
              v-if="scope.row.isCloseBut"
              v-permit:remove="'but:finance:receivableManagementSite:invoiceManagementCN:cancel'"
              type="danger"
              size="mini"
              @click="() => asyncApiLogisticsInvoicecCancel([scope.row.id])"
            >{{ $t('GlobalSetObj.cancel') }}</el-button>

            <!-- 取消 失败状态才显示重开 -->
            <el-button
              v-if="scope.row.isRestart"
              v-permit:remove="'but:finance:receivableManagementSite:invoiceManagementCN:restart'"
              type="primary"
              size="mini"
              @click="asyncapiLogisticsInvoiceReGenerateCtl([scope.row.id])"
            >{{ $t('basicData.reopen') }}</el-button>

          </template>
        </el-table-column>
      </el-table>

      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getTable"
          @size-change="getTable"
        />
      </div>
    </el-card>

    <!-- 手动开票 -->
    <ManualInvoicingCmp ref="manualInvoicingRef" />
  </div>
</template>

<script>
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import InputTextareaCmp from '@/views/finance/cmp/Query/InputTextareaCmp.vue';
import { apiLogisticsInvoicePage, apiLogisticsInvoicecCancel, apiLogisticsInvoicecExport, apiLogisticsInvoiceReGenerateCte, apiLogisticsInvoicecLabel } from '@/api/finance/basicData';
import { batchPdfDownload } from '@/utils/PDF_util';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';
import { INVOICE_STATUS, INVOICE_TYPE, BUSINESS_TYPE, isCancelCheck, restartCheck, downloadCheck } from './utils';

const startTime = dayjs().format('YYYY-MM-DD') + ' 00:00:00';
const endTime = dayjs().format('YYYY-MM-DD') + ' 23:59:59'; // 设置此刻的时间

const PARAMS = {
  invoiceIssueTime: [startTime, endTime], // 开票时间
  // invoiceIssueTime: '', // 开票时间
  issueEntity: '', // 开票主体
  businessType: null, // 业务类型
  invoiceStatus: null // 发票状态
};

export default {
  name: '',

  components: {
    InputTextareaCmp,
    ManualInvoicingCmp: () => import('./cmp/ManualInvoicingCmp.vue')
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      BUSINESS_TYPE, // 业务类型
      INVOICE_STATUS, // 发票状态
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          ...PARAMS
        }
      },
      type1: 'invoiceNumberList',
      listVal: '',
      total: 0,
      tableData: [],
      multipleSelection: [], // 勾选

      loading: false,
      pickerMinDate: '',
      pickerRange: 14 * 24 * 60 * 60 * 1000, // 可选择日期范围 为15天
      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({ minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (time.getTime() > (this.pickerMinDate + this.pickerRange)) || (time.getTime() < (this.pickerMinDate - this.pickerRange));
          }
          return false;
        }
      }
    };
  },

  created() {
    // 用户列表
    this.userIdAndNameObj = getUserIdAndNameObj();

    setTimeout(() => {
      this.getTable();
    }, 100);
  },

  methods: {

    getQurey() {
      // 多选框
      const inputVal = this.$refs.inputTextareaRef.getValue();
      const { type1 } = this;
      const queryForm = this.queryForm;
      const { invoiceIssueTime, issueEntity, businessType, invoiceStatus } = queryForm.params;

      const obj = {
        ...queryForm,
        params: {
          invoiceIssueTimeStart: undefined, // 开票时间开始
          invoiceIssueTimeEnd: undefined, // 开票时间结束
          issueEntity, // 开票主体
          businessType, // 业务类型
          invoiceStatus, // 发票状态
          [type1]: inputVal //
        }
      };

      if (Array.isArray(invoiceIssueTime) && invoiceIssueTime.length === 2) {
        obj.params.invoiceIssueTimeStart = invoiceIssueTime[0];
        obj.params.invoiceIssueTimeEnd = invoiceIssueTime[1];
      }

      return obj;
    },

    getTable() {
      const obj = this.getQurey();
      apiLogisticsInvoicePage(obj).then(res => {
        const { records, total } = res.data;
        this.total = total;
        this.tableData = (records || []).map(item => {
          const { id, invoiceType, invoiceIssueTime, businessType, invoiceNumber, invoiceUrl, invoiceSecretKey, businessNumber, issueEntity, invoiceStatus, updateUser, updateTime, failureReason } = item;

          const invoiceTypeStr = INVOICE_TYPE[invoiceType]?.label || '';
          const businessTypeStr = BUSINESS_TYPE[businessType]?.label || '';
          const updateUserStr = this.userIdAndNameObj[updateUser] || '';

          let isCloseBut = false;
          // 是否显示取消按钮
          {
            // 规则1：成功状态才显示取消按钮
            // 规则2：开票时间为24小时内才能进行取消按钮
            const isBool1 = invoiceStatus === INVOICE_STATUS['1'].value;
            const val = dayjs(invoiceIssueTime || new Date());
            const isBool2 = dayjs().diff(val, 'hour') < 24;

            isCloseBut = isBool1 && isBool2;
          }

          // 是否显示重开按钮（当前是取消或者失败的状态下才显示）
          const isRestart = invoiceStatus !== INVOICE_STATUS['1'].value;

          const obj = {
            id,
            invoiceType, // 发票类型
            invoiceTypeStr,
            invoiceIssueTime, // 开票时间
            businessType, // 业务类型
            businessTypeStr,
            invoiceNumber, // 发票号码
            invoiceUrl, // 发票地址
            invoiceSecretKey, // 发票密钥
            businessNumber, // 业务单号
            issueEntity, // 开票主体
            invoiceStatus, // 状态
            // invoiceStatusStr,
            updateUser, // 最后修改人
            updateUserStr, // 最后修改人
            updateTime, // 最后修改时间
            isCloseBut, // 是否显示取消按钮
            isRestart, // 是否显示重开按钮
            failureReason // 失败原因
          };

          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      });
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 重置
    onReset() {
      this.type1 = 'invoiceNumberList';
      this.listVal = '';

      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = 10;
      this.queryForm.params = {
        ...PARAMS
      };

      this.getTable();
    },

    // 手动开票
    onManualInvoicing() {
      this.$refs.manualInvoicingRef.onOpen();
    },

    // 重开
    onRestart() {
      // 校验
      if (!restartCheck(this.multipleSelection)) {
        return;
      }

      const arr = this.multipleSelection.map(item => item.id);

      this.asyncapiLogisticsInvoiceReGenerateCtl(arr);
    },

    // 重开请求
    asyncapiLogisticsInvoiceReGenerateCtl(arr) {
      const data = new FormData();
      data.append('ids', arr.join(','));
      apiLogisticsInvoiceReGenerateCte(data).then(() => {
        this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
        this.getTable();
      }).catch(err => {
        console.error(err);
      });
    },

    // 取消
    onCancel() {
      // 校验
      if (!isCancelCheck(this.multipleSelection)) {
        return;
      }

      const arr = this.multipleSelection.map(item => item.id);

      this.asyncApiLogisticsInvoicecCancel(arr);
    },

    // 取消开票
    asyncApiLogisticsInvoicecCancel(arr) {
      apiLogisticsInvoicecCancel(arr).then(() => {
        this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
        this.getTable();
      }).catch(err => {
        console.error(err);
      });
    },

    // 导出
    onExport() {
      const { params } = this.getQurey();
      apiLogisticsInvoicecExport(params).then(() => {
        goExportList(this);
      }).catch(err => {
        console.error(err);
      });
    },

    // 批量下载
    onDownload() {
      // 校验
      if (!downloadCheck(this.multipleSelection)) {
        return;
      }

      const arr = this.multipleSelection.map(item => item.id);

      this.asyncApiLogisticsInvoicecLabel(arr).then(res => {
        this.loading = true;
        // 合并面单
        return batchPdfDownload(res);
      }).then(res => {
        const str = dayjs().format('YYYY-MM-DD HH-mm-ss');
        const name = this.$t('route.invoice') + str + '.pdf';
        saveAs(res, name);
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    // 查看面单
    onCheckWaybill(row) {
      const { id, invoiceUrl } = row;
      // 当前面单存在
      if (invoiceUrl && invoiceUrl.length) {
        window.open(invoiceUrl, '_blank');
        return;
      }
      // 如果是第一次点击则没有地址需要请求后台地址
      this.asyncApiLogisticsInvoicecLabel([id]).then(res => {
        const str = dayjs().format('YYYY-MM-DD HH-mm-ss');
        const name = this.$t('route.invoice') + str + '.pdf';
        saveAs(res[0], name);
      }).catch(err => {
        console.error(err);
      });
    },

    /**
     * 异步调用物流发票标签接口
     * @param ids 发票ID数组
     * @returns 返回Promise对象
     */
    asyncApiLogisticsInvoicecLabel(ids) {
      return apiLogisticsInvoicecLabel(ids).then(res => {
        return res.data;
      }).catch(err => {
        console.error(err);
      });
    }
  }
};
</script>

<style scoped>

</style>
