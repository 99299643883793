import { apiGetPositionList, apiGetUserAdditionalInformation } from '@/api/user';
import { getOrganizationTree } from '@/api/organization';
import { ORGANIZATION_TREE, ORGID_AND_NAME, POSITION_LIST, USERID_AND_NAME } from '@/enums/storageEnum';

/**
 * 获取 所有人员名单和部门
 * isRefresh 0 从后台缓存中获取，当没有拿到指定的内容时候传入 1
 *           1  从后台数据库中获取，
 */
const asyncGetUserAdditionalInformation = function asyncGetUserAdditionalInformation(isRefresh = 0) {
  return apiGetUserAdditionalInformation({ isRefresh }).then(res => {
    const { code, data } = res;
    if (code !== 200) {
      return;
    }
    const { orgIdAndName, userIdAndName } = JSON.parse(data);
    localStorage.setItem(ORGID_AND_NAME, JSON.stringify(orgIdAndName));
    localStorage.setItem(USERID_AND_NAME, JSON.stringify(userIdAndName));
  }).catch(err => {
    console.error(err);
  });
};

/**
 * 获取部门 树状结构
 */
const asyncGetOrganizationTree = function asyncGetOrganizationTree() {
  return getOrganizationTree().then(data => {
    if (data.code === 200) {
      localStorage.setItem(ORGANIZATION_TREE, JSON.stringify(data.data));
      return data.data;
    }
  }).catch(err => {
    console.error(err);
    return [];
  });
};

/**
 * 获取全部职位
 */
const asyncGetPositionList = function asyncGetPositionList() {
  return apiGetPositionList({}).then(data => {
    if (data.code === 200) {
      const obj = {};
      data.data.forEach(item => {
        obj[item.id] = item;
      });
      localStorage.setItem(POSITION_LIST, JSON.stringify(obj));
    }
  });
};

/**
 * 获取发起人/所属部门
 * @param str {string}
 * @param obj {{}}
 * @returns string
 */
const getUserName = function(str, obj) {
  if (!str || (str === 'null')) {
    return '';
  }
  return obj[str] || '';
};

/**
 * 获取协作部门
 * @param str {string}
 * @param obj {{}}
 * @param smy {string}
 * @returns string
 */
const getOrgNames = function(str, obj, smy = ',') {
  if (!str || (str === 'null')) {
    return '/';
  }
  const arr = str.split(',') || [];
  return arr.map(item => {
    return obj[item] || '';
  }).join(smy);
};
export {
  asyncGetUserAdditionalInformation,
  asyncGetOrganizationTree,
  asyncGetPositionList,
  getUserName,
  getOrgNames
};

