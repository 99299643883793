<template>
  <div class="boxInfo">
    <el-tabs type="border-card" style="height: 100%;">
      <!--'新增分区'\'分区详情'-->
      <el-tab-pane :label="typeIndex === 1 ? $t('basicData.AddPartition') : $t('basicData.PartitionDetails')">
        <div class="addFormBox">
          <el-form
            ref="queryForm"
            class="queryFormClass"
            :model="formData"
            :rules="rules"
            size="small"
            :label-width="$i18n.locale=='zh'?'120px':''"
            :label-position="$i18n.locale!='zh'?'top':'right'"
          >
            <el-row>
              <el-col :span="20">
                <!--分区方案名称-->
                <el-form-item :label="$t('basicData.PartitionSchemeName')" prop="name">
                  <el-input
                    v-model.trim="formData.name"
                    :placeholder="$t('orderDetails.pleaseInput')"
                    clearable
                    maxlength="40"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <!--备注内容-->
                <el-form-item :label="$t('basicData.RemarkContent')">
                  <el-input
                    v-model="formData.remark"
                    :placeholder="$t('orderDetails.pleaseInput')"
                    type="textarea"
                    :rows="3"
                    maxlength="200"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <!--业务类型-->
                <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
                  <el-select v-model="formData.businessType" :placeholder="$t('GlobalSetObj.pleaseChoose')">

                    <el-option
                      v-for="item in BUSINESS_TYPE_TWO"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div>
            <!--新增分区-->
            <el-button
              v-permit:remove="'btn:finance:basicData:payable:partition-addEdit'"
              size="small"
              type="primary"
              @click="handleAdd"
            >{{ $t('basicData.AddPartition') }}
            </el-button>
            <el-popover
              placement="bottom-end"
              trigger="hover"
            >
              <div>
                <import-download
                  :key="downloadKey" :table-data="formData" :all-state="allState"
                  :all-city="allCity" @importPartition="importPartition"
                />
              </div>
              <!--导入/下载-->
              <el-button
                slot="reference"
                v-permit:remove="'btn:finance:basicData:payable:partition-addEdit'"
                size="small"
                plain
                style="margin-left: 10px"
              >
                {{ $t('basicData.ImportDownload') }}
              </el-button>
            </el-popover>
          </div>
          <div style="margin-top: 10px;">
            <el-form
              :key="tableKey"
              ref="tableForm"
              :model="formData"
              label-width="0"
              size="small"
              class="demo-ruleForm"
            >
              <el-table
                :data="formData.partitionAffiliateList"
                border
              >
                <el-table-column :label="$t('basicData.PartitionName')" width="160">
                  <template slot="header">
                    <div>
                      <span style="color: red;">*</span>
                      <!--分区名称-->
                      <span>{{ $t('basicData.PartitionName') }}</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <el-form-item
                        :prop="'partitionAffiliateList.' + scope.$index + '.partitionIdentifier'"
                        :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                      >
                        <el-input
                          v-model="scope.row.partitionIdentifier"
                          maxlength="20"
                          show-word-limit
                          :placeholder="$t('basicData.PartitionName')"
                        />
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('GlobalSetObj.Estado')" width="240">
                  <template slot="header">
                    <div>
                      <span style="color: red;">*</span>
                      <!--州-->
                      <span>{{ $t('GlobalSetObj.Estado') }}</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <el-form-item
                        :prop="'partitionAffiliateList.' + scope.$index + '.state'"
                        :rules="{ required: (scope.row.postalCode === '' && scope.row.city.length === 0) ? true : false, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                      >
                        <el-select
                          v-model="scope.row.state"
                          multiple
                          clearable
                          filterable
                          :placeholder="$t('GlobalSetObj.pleaseChoose')"
                          @change="changeState(scope)"
                        >
                          <el-option
                            v-for="(item, ind) in scope.row.allState"
                            :key="ind"
                            :label="item.twoCode + '（' + item.name + '）'"
                            :value="item.id"
                          />
                        </el-select>
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('orderCenterCont.city')" width="240">
                  <template slot="header">
                    <div>
                      <span style="color: red;">*</span>
                      <!--城市-->
                      <span>{{ $t('orderCenterCont.city') }}</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div :key="cityKey">
                      <el-form-item
                        :prop="'partitionAffiliateList.' + scope.$index + '.city'"
                        :rules="{ required: (scope.row.postalCode === '' && scope.row.state.length === 0) ? true : false, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                      >
                        <el-select
                          v-model="scope.row.city"
                          multiple
                          clearable
                          filterable
                          :placeholder="$t('GlobalSetObj.pleaseChoose')"
                        >
                          <el-option
                            v-for="(item, ind) in scope.row.allCity"
                            :key="ind"
                            :label="item.name"
                            :value="item.id"
                          />
                        </el-select>
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <!--邮编-->
                <el-table-column :label="$t('basicData.zipCode')">
                  <template slot="header">
                    <div>
                      <span style="color: red;">*</span>
                      <span>{{ $t('basicData.zipCode') }}</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <el-form-item
                        :prop="'partitionAffiliateList.' + scope.$index + '.postalCode'"
                        :rules="{
                          required: (scope.row.city.length === 0 && scope.row.state.length === 0) ? true : false, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                      >
                        <el-input
                          v-model="scope.row.postalCode"
                          type="textarea"
                          :rows="2"
                          maxlength="2000"
                          :placeholder="$t('basicData.ZipCodeExample')"
                          @change="zipcodeCollation(scope.$index, scope.row.postalCode)"
                        />
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <!--操作-->
                <el-table-column :label="$t('basicData.operate')" width="120">
                  <template slot-scope="scope">
                    <!--确定删除吗？-->
                    <el-popconfirm
                      :title="$t('basicData.AreYouSureYouWantToDelete')"
                      @confirm="handleDel(scope)"
                    >
                      <!--删除-->
                      <el-button slot="reference" type="danger" size="mini">{{ $t('basicData.Delete') }}</el-button>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
          </div>
        </div>
        <div
          v-permit:remove="'but:finance:basicData:partition:edit'"
          class="addFooter"
        >
          <!--取消-->
          <el-button size="small" @click="() => handleUpdatePage(false)">{{ $t('basicData.Cancel') }}</el-button>
          <!--保存-->
          <el-button size="small" type="primary" @click="submitForm">{{ $t('basicData.Save') }}</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!--保存提示-->
    <el-dialog
      :title="$t('basicData.tips')"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="30%"
    >
      <div>
        <div v-for="(item, ind) in errData" :key="ind" style="color: red;margin-bottom: 10px;">{{ item }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--确 定-->
        <el-button type="primary" @click="dialogVisible = false">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiAffiliateAdd, apiAffiliateDelete, apiAffiliateEdit, apiGetAffiliateById } from '@/api/finance/partition';
import { compressList } from '@/utils/zipcode';
import { Loading } from 'element-ui';
import { apiAreaPage } from '@/api/finance/basicData';
import { cloneDeep } from 'lodash-es';
import { BUSINESS_TYPE_TWO } from '@/views/finance/utils.js';

const options = {
  lock: true,
  spinner: 'el-icon-loading',
  text: 'loading...',
  background: 'rgba(250, 250, 250, 0.6)' // 加载动画的背景
};
export default {
  components: {
    'import-download': () => import('@/views/finance/basicData/cope/partition/cmp/ImportDownload')
  },
  props: {
    // 类型标识 1新增 2查看 3 编辑
    typeIndex: {
      type: Number,
      default: 1
    },
    editData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    allState: {
      type: Array,
      default: () => {
        return [];
      }
    },
    allCity: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      rules: {
        name: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] },
          // '长度在 2 到 20 个字符'
          {
            min: 2,
            max: 40,
            message: this.$i18n.t('basicData.TheLengthBetween2And40Characters'),
            trigger: ['blur', 'change']
          }
        ],
        businessType: { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
      },
      errData: [],
      dialogVisible: false,
      formData: {
        name: '',
        remark: '',
        businessType: '',
        partitionAffiliateList: []
      },
      tableKey: new Date().getTime(),
      downloadKey: new Date().getTime(),
      cityKey: new Date().getTime()
    };
  },
  created() {
    this.BUSINESS_TYPE_TWO = BUSINESS_TYPE_TWO;
    this.$nextTick(() => {
      if (this.editData.partitionId) {
        this.asyncGetAffiliateById(this.editData.partitionId);
      }
    });
  },
  methods: {
    // 州省选项切换
    changeState(scope) {
      this.formData.partitionAffiliateList[scope.$index].allCity = [];
      this.formData.partitionAffiliateList[scope.$index].city = '';
      if (scope.row.state.length > 0) {
        const param = {
          pageNumber: 1,
          pageSize: 99999,
          params: {
            level: 2,
            parentIdList: scope.row.state
          }
        };
        apiAreaPage(param, false).then(res => {
          if (res.code === 200) {
            this.formData.partitionAffiliateList[scope.$index].allCity = res.data.records;
            this.cityKey = new Date().getTime();
          }
        });
      } else {
        this.formData.partitionAffiliateList[scope.$index].allCity = this.allCity;
        this.cityKey = new Date().getTime();
      }
    },
    // 邮编整理
    zipcodeCollation(index, zipcode) {
      this.formData.partitionAffiliateList[index].postalCode = compressList(zipcode).join(',');
      this.tableKey = new Date().getTime();
    },
    // 增加分区
    handleAdd() {
      this.formData.partitionAffiliateList = this.formData.partitionAffiliateList || [];
      const obj = {
        id: '',
        partitionIdentifier: '',
        postalCode: '',
        allState: this.allState,
        allCity: this.allCity,
        state: '',
        city: ''
      };
      this.formData.partitionAffiliateList.push(obj);
      this.tableKey = new Date().getTime();
    },
    // 导入分区
    importPartition(arr) {
      const arrData = arr.map(item => {
        const obj = {
          postalCode: item.zipcode ? compressList(item.zipcode).join(',') : '',
          state: (item.state && item.state.length > 0) ? item.state : [],
          allState: this.allState,
          allCity: item.allCity,
          city: (item.city && item.city.length > 0) ? item.city : [],
          partitionIdentifier: item.name,
          id: ''
        };
        return obj;
      });
      this.formData.partitionAffiliateList = [...this.formData.partitionAffiliateList, ...arrData];
      this.tableKey = new Date().getTime();
    },
    // 删除分区
    handleDel(scope) {
      if (scope.row.id) {
        apiAffiliateDelete(scope.row.id).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
          }
        });
      }
      this.formData.partitionAffiliateList.splice(scope.$index, 1);
    },
    // 取消
    handleUpdatePage(type) {
      this.$emit('updatePage', type);
    },
    // 邮编校验
    verifyZipCode(arr) {
      // arr=分区列表
      const errObj = {
        // 格式错误
        errArr: [],
        // 交集错误
        repeat: []
      };
      // const reg = /^[A-Z0-9 \-]+$/g;
      const reg = /[A-Z \-]*[0-9]+/g; // 至少存在一个数字
      // 校验格式
      arr.forEach((item, ind) => {
        if (!item.state && !item.city && !item.postalCode) {
          // 州、城市、邮编，至少一项必填
          errObj.errArr.push((ind + 1) + '：' + this.$t('basicData.StateCityZipCodeLeastOneRequired'));
        }

        // item = 每行对象, ind下标
        let zipcodeStr = '';
        if (item.postalCode.length > 0) {
          // 清除字符串两端空白
          zipcodeStr = item.postalCode.trim();
        }
        // 替换 所有中文逗号为英文逗号
        zipcodeStr = zipcodeStr.replace(/，/ig, ',');
        // 没有填写,格式错误
        // if (zipcodeStr.length === 0) {
        //   if (!errObj.errArr.includes(ind + 1)) {
        //     // 这一整行邮编错误
        //     errObj.errArr.push(ind + 1);
        //   }
        // }
        // 获取每个分区的邮编,逗号分隔成数组
        const zipcodeArr = [];
        zipcodeStr.split(',').map(item => {
          if (item.trim()) {
            zipcodeArr.push(item.trim());
          }
        });
        // 不能出现 "41900,,,"
        if (zipcodeArr.includes('')) {
          if (zipcodeStr) {
            errObj.errArr.push(ind + 1 + '：' + this.$t('basicData.areMalformed'));
          }
          // if (!errObj.errArr.includes(ind + 1)) {
          //   errObj.errArr.push(ind + 1 + '：' + this.$t('basicData.areMalformed'));
          // }
        }

        const isBool = zipcodeArr.every(item => {
          let dataList = [];
          if (item) {
            dataList = item.split('~');
          }
          const list = dataList.map(item => {
            return item.trim();
          });

          if (list.length === 1) {
            reg.lastIndex = 0;
            return reg.test(list[0]);
          }

          // 不能出现 100~1000~10000
          if (list.length >= 3) {
            return false;
          }

          // 不能出现 "~100" "~" "100~"
          if (list.includes('')) {
            return false;
          }

          // 不能出现 10~9 10~10，前提是都为纯数字
          const num1 = Number(list[0]);
          const num2 = Number(list[1]);
          if ((num1 >= 0) && (num2 >= 0) && (num1 >= num2)) {
            return false;
          }

          return list.every(key => {
            reg.lastIndex = 0;
            return reg.test(key);
          });
        });

        if (!isBool) {
          // 邮箱不合格
          if (!errObj.errArr.includes(ind + 1)) {
            errObj.errArr.push(ind + 1 + '：' + this.$t('basicData.areMalformed'));
          }
        }
      });
      if (errObj.errArr.length > 0) {
        return errObj;
      }
      // 校验邮编
      for (let i = 0; i < arr.length; i++) {
        const itemA = arr[i];
        let zipcodeStrA = '';
        if (itemA.postalCode.length > 0) {
          // 清除字符串两端空白
          zipcodeStrA = itemA.postalCode.trim();
        }
        // 替换 所有中文逗号为英文逗号
        zipcodeStrA = zipcodeStrA.replace(/，/ig, ',');
        // 获取每个分区的邮编,逗号分隔成数组,并删除空白
        const zipcodeArrA = zipcodeStrA.split(',').map(item => {
          return item.trim();
        });
        // 需要脚本本身是否存在交集
        for (let j = 0; j < zipcodeArrA.length; j++) {
          const postalCodeA = zipcodeArrA[j];
          for (let k = j + 1; k < zipcodeArrA.length; k++) {
            const postalCodeB = zipcodeArrA[k];
            const bool = this.contrastPostalCode(postalCodeA, postalCodeB);
            if (!bool) {
              errObj.repeat.push((i + 1) + ':' + postalCodeA + ' and ' + (i + 1) + ':' + postalCodeB);
              return errObj;
            }
          }
        }
        // #
        for (let j = i + 1; j < arr.length; j++) {
          const itemB = arr[j];
          let zipcodeStrB = '';
          // 邮编长度
          if (itemB.postalCode.length > 0) {
            zipcodeStrB = itemB.postalCode.trim();
          }
          // 替换 所有中文逗号为英文逗号
          zipcodeStrB = zipcodeStrB.replace(/，/ig, ',');
          const zipcodeArrB = zipcodeStrB.split(',').map(item => {
            return item.trim();
          });
          // 第一行的所有邮编和第二行所有邮编来一次
          for (let k = 0; k < zipcodeArrA.length; k++) {
            const postalCodeA = zipcodeArrA[k];
            for (let l = 0; l < zipcodeArrB.length; l++) {
              const postalCodeB = zipcodeArrB[l];
              const bool = this.contrastPostalCode(postalCodeA, postalCodeB);
              if (!bool) {
                errObj.repeat.push((i + 1) + ':' + postalCodeA + ' and ' + (j + 1) + ':' + postalCodeB + this.$t('basicData.intersect'));
                return errObj;
              }
            }
          }
        }
      }
      return errObj;
    },
    // 对比邮编
    contrastPostalCode(intervalA, intervalB) {
      if (intervalA === null || intervalA === '' || intervalA === undefined) {
        return true;
      }
      if (intervalB === null || intervalB === '' || intervalB === undefined) {
        return true;
      }
      const addZipcodeList = intervalA.split('~');
      const existZipCodeList = intervalB.split('~');
      // 如果两个都是 ~ 的邮编
      if (addZipcodeList.length === 2 && existZipCodeList.length === 2) {
        const min1 = parseInt(addZipcodeList[0]);
        const max1 = parseInt(addZipcodeList[1]);
        const min2 = parseInt(existZipCodeList[0]);
        const max2 = parseInt(existZipCodeList[1]);
        if (!((max1 < min2) || (max2 < min1))) {
          return false;
        }
      }
      // 前面的没有~  后面有~
      if (addZipcodeList.length === 1 && existZipCodeList.length === 2) {
        const min1 = parseInt(addZipcodeList[0]);
        const min2 = parseInt(existZipCodeList[0]);
        const max2 = parseInt(existZipCodeList[1]);
        if (!(((min1 < min2) && (max2 > min1)) || (max2 < min1))) {
          return false;
        }
      }
      // 都没有~
      if (addZipcodeList.length === 1 && existZipCodeList.length === 1) {
        const min1 = parseInt(addZipcodeList[0]);
        const min2 = parseInt(existZipCodeList[0]);
        if (min1 === min2) {
          return false;
        }
      }

      if (addZipcodeList.length === 2 && existZipCodeList.length === 1) {
        const min1 = parseInt(addZipcodeList[0]);
        const max1 = parseInt(addZipcodeList[1]);
        const min2 = parseInt(existZipCodeList[0]);
        if (!(((min2 < min1) && (max1 > min2)) || (max1 < min2))) {
          return false;
        }
      }
      return true;
    },
    // 处理提交的数据格式
    setSubmitFormDataType() {
      const tableData = this.formData.partitionAffiliateList;

      return tableData.map(item => {
        const { postalCode, partitionIdentifier, id, state, city } = item;
        const stateStr = state ? state.join(',') : '';
        const cityStr = city ? city.join(',') : '';
        const obj = {
          partitionIdentifier,
          postalCode, // 邮编
          id,
          state: stateStr,
          city: cityStr,
          partitionId: this.editData.id
        };
        return obj;
      });
    },

    // 新增
    asyncAffiliateAdd(obj) {
      apiAffiliateAdd(obj).then(res => {
        const { code } = res;
        if (code !== 200) {
          return;
        }
        this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
        this.handleUpdatePage(true);
      }).catch(err => {
        console.error(err);
      });
    },
    // 查看订单详情
    asyncGetAffiliateById(ids) {
      apiGetAffiliateById(ids).then(res => {
        const { code, data } = res;
        if (code !== 200) {
          return;
        }
        const {
          businessType,
          name,
          remark,
          partitionAffiliateList
        } = data;
        this.formData = {
          businessType,
          name,
          remark,
          partitionAffiliateList
        };
        this.formData.partitionAffiliateList.map((item, index) => {
          if (item.city === '' || item.city === null || item.city === undefined) {
            item.city = [];
          } else {
            const cityList = cloneDeep(item.city.split(','));
            const cityArr = [];
            cityList.map(item => {
              cityArr.push(Number(item));
            });
            item.city = cityArr;
          }
          item.allState = this.allState;
          if (item.state !== '' && item.state !== null && item.state !== undefined) {
            const stateList = cloneDeep(item.state.split(','));
            const stateArr = [];
            stateList.map(item => {
              stateArr.push(Number(item));
            });
            item.state = stateArr;
            if (stateList.length > 0) {
              const param = {
                pageNumber: 1,
                pageSize: 99999,
                params: {
                  level: 2,
                  parentIdList: stateList
                }
              };
              apiAreaPage(param).then(res => {
                if (res.code === 200) {
                  item.allCity = res.data.records;
                  this.tableKey = new Date().getTime();
                }
              });
            }
          } else {
            item.state = [];
            item.allCity = this.allCity;
          }
        });
      });
    },
    // 编辑
    asyncAffiliateEdit(obj) {
      apiAffiliateEdit(obj).then(res => {
        const { code } = res;
        if (code !== 200) {
          return;
        }
        this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded'));
        this.handleUpdatePage(true);
      }).catch(err => {
        console.error(err);
      });
    },
    // 保存
    submitForm() {
      this.$refs.queryForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        if (this.formData.partitionAffiliateList.length === 0) {
          this.$message.warning(this.$i18n.t('basicData.PleaseAddPartitions')); // `请添加分区`,);
          return false;
        }
        this.$refs.tableForm.validate((tabRules) => {
          if (!tabRules) {
            return false;
          }
          Loading.service(options);
          const loadingInstance = Loading.service(options);
          // this.errData = [];
          const errCode = this.verifyZipCode(this.formData.partitionAffiliateList);
          if (errCode.errArr.length > 0) {
            // const tip = this.$i18n.t('basicData.Lines') + `${errCode.errArr.join(',')}` + this.$i18n.t('basicData.areMalformed'); // 第*和*邮编格式错误
            errCode.errArr.map(item => {
              this.errData.push(item);
            });
          }
          if (errCode.repeat.length > 0) {
            // const tip = this.$i18n.t('basicData.Lines') + `${errCode.repeat.join(',')}` + this.$i18n.t('basicData.intersect'); // 第*和*邮编存在交集
            errCode.repeat.map(item => {
              this.errData.push(item);
            });
          }
          const findByItems = (eq) => (arr) => arr.filter(
            (x, i) => arr.find((y, j) => i !== j && eq(x, y))
          );
          const duplicatedItems = findByItems((a, b) => a.partitionIdentifier.toString() === b.partitionIdentifier.toString());
          const duplicatedZipCode = findByItems((a, b) => a.postalCode.toString() === b.postalCode.toString());
          // const duplicatedCity = findByItems((a, b) => a.city.toString() === b.city.toString());
          // const duplicatedState = findByItems((a, b) => a.state.toString() === b.state.toString());
          const haveIntersection = (arr1, arr2) => {
            return arr1.some(item => arr2.includes(item));
          };
          const arrDuplicated = duplicatedItems(this.formData.partitionAffiliateList);
          const arrZipCode = duplicatedZipCode(this.formData.partitionAffiliateList);
          const arrCityState = cloneDeep(this.formData.partitionAffiliateList);
          // const arrState = duplicatedState(this.formData.partitionAffiliateList);
          const nameArr = [];
          const zipCode = [];
          const city = [];
          const state = [];
          this.formData.partitionAffiliateList.map((item, ind) => {
            // if (item.city && item.city.length > 0) {
            //   if (item.allCity.length > 0) {
            //     let isCity = false;
            //     item.allCity.map(cityList => {
            //       item.city.map(cityItem => {
            //         if (cityList.id === cityItem) {
            //           isCity = true;
            //         }
            //       });
            //     });
            //     if (isCity === false) {
            //       const tip = this.$i18n.t('basicData.Lines') + (ind + 1) + this.$i18n.t('basicData.repeat'); // 第*和*行重复
            //       this.errData.push(tip);
            //     }
            //   }
            // }
            arrDuplicated.map(list => {
              if (list.partitionIdentifier === item.partitionIdentifier) {
                if (!nameArr.includes(ind + 1)) {
                  nameArr.push(ind + 1);
                }
              }
            });
            arrZipCode.map((list, index) => {
              if (index !== ind) {
                if (
                  (list.postalCode !== null && list.postalCode !== '' && list.postalCode !== undefined) &&
                  (item.postalCode !== null && item.postalCode !== '' && item.postalCode !== undefined)
                ) {
                  if (list.postalCode === item.postalCode) {
                    if (!zipCode.includes(ind + 1)) {
                      zipCode.push(ind + 1);
                    }
                  }
                }
              }
            });
            arrCityState.map((list, index) => {
              if (index !== ind) {
                if (
                  (
                    (list.postalCode === null || list.postalCode === undefined || list.postalCode === '') &&
                    (list.postalCode === null || list.postalCode === undefined || list.postalCode === '')
                  )
                ) {
                  if (
                    (list.city !== null && list.city !== undefined && list.city.length !== 0) &&
                    (item.city !== null && item.city !== undefined && item.city.length !== 0)
                  ) {
                    if (haveIntersection(list.city, item.city)) {
                      if (!city.includes(ind + 1)) {
                        city.push(ind + 1);
                      }
                    }
                  }
                }
                if (
                  (
                    (list.postalCode === null || list.postalCode === undefined || list.postalCode === '') &&
                    (list.city === null || list.city === undefined || list.city.length === 0)
                  ) &&
                  (
                    (item.postalCode === null || item.postalCode === '' || item.postalCode === undefined) &&
                    (item.city === null || item.city === undefined || item.city.length === 0)
                  )
                ) {
                  if (
                    (list.state !== null && list.state !== undefined && list.state.length !== 0) &&
                    (item.state !== null && item.state !== undefined && item.state.length !== 0)
                  ) {
                    if (haveIntersection(list.state, item.state)) {
                      if (!state.includes(ind + 1)) {
                        state.push(ind + 1);
                      }
                    }
                  }
                }
              }
            });
          });
          if (nameArr.length > 1) {
            const tip = this.$i18n.t('basicData.Lines') + `${nameArr.join(',')}` + this.$i18n.t('basicData.repeat'); // 第*和*行重复
            this.errData.push(tip);
          }
          if (zipCode.length > 1) {
            const tip = this.$i18n.t('basicData.Lines') + `${zipCode.join(',')}` + this.$i18n.t('basicData.areDuplicated'); // 第*和*行重复
            this.errData.push(tip);
          }
          if (city.length > 1) {
            const tip = this.$i18n.t('basicData.Lines') + `${city.join(',')}` + this.$i18n.t('basicData.theCityIsDuplicated'); // 第*和*行重复
            this.errData.push(tip);
          }
          if (state.length > 1) {
            const tip = this.$i18n.t('basicData.Lines') + `${state.join(',')}` + this.$i18n.t('basicData.StateDuplication'); // 第*和*行重复
            this.errData.push(tip);
          }
          if (this.errData.length > 0) {
            this.dialogVisible = true;
            loadingInstance.close();
            return;
          }
          const arr = this.setSubmitFormDataType();
          const { name, remark, businessType } = this.formData;
          const obj = {
            id: '',
            name,
            businessType,
            remark,
            attribute: 2,
            partitionAffiliateList: arr
          };
          loadingInstance.close();
          if (this.typeIndex === 1) {
            // 新增
            this.asyncAffiliateAdd(obj);
            return;
          }

          if (this.typeIndex === 2) {
            // 3.编辑
            obj.id = this.editData.id;
            this.asyncAffiliateEdit(obj);
          }
          // this.handleUpdatePage(true);
        });
      });
      // this.$emit('updatePage', true);
    }
  }
};
</script>

<style scoped>

</style>
<style>
.boxInfo {
  height: 100%;
}

.boxInfo .el-tabs__content {
  height: calc(100vh - 40px);
  padding: 0;
}

.boxInfo .el-tabs__content .el-tab-pane {
  height: 100%;
}

.boxInfo .el-tabs__content .el-tab-pane .addFormBox {
  height: calc(100% - 65px);
  padding: 15px;
  overflow-y: auto;
}

.addFooter {
  border-top: 1px solid #ccc;
  padding: 10px;
  text-align: right;
}
</style>
