<!-- 操作中心 -->
<template>
  <div class="operational">
    <el-popover placement="bottom-start" trigger="hover">
      <div class="feet">
        <!--批量添加费用-->
        <el-button
          size="small" type="primary" class="feet-btn"
          @click="onBatchAddingcosts"
        >{{ $t('receivablePayable.AddChargesInBulk') }}
        </el-button>

        <!--导入添加费用-->
        <el-button
          v-if="payableType !== 5"
          size="small" type="primary" class="feet-btn"
          @click="isImportAdd = true"
        >{{ $t('receivablePayable.ImportAddCharges') }}
        </el-button>

        <!--导入修改费用-->
        <el-button
          v-if="payableType !== 5"
          size="small" type="primary" class="feet-btn"
          @click="isImportEdit = true"
        >{{ $t('receivablePayable.ImportModifyExpenses') }}
        </el-button>

        <!--快录新增-->
        <el-button
          v-if="payableType !== 5"
          size="small" type="primary" class="feet-btn"
          @click="onQR"
        >{{ $t('receivablePayable.QuickRecord') }}
        </el-button>

        <!--批量导入快录-->
        <el-button
          v-if="payableType !== 5"
          size="small" type="primary" class="feet-btn"
          @click="onQRImport"
        >{{ $t('receivablePayable.BatchImportClips') }}
        </el-button>
      </div>

      <el-button
        slot="reference"
        :loading="isAddAndEdit" size="small" style="margin-right: 10px;">
        {{ $t('receivablePayable.AddModifyExpenses') }}
      </el-button>
    </el-popover>

    <!-- 生成应付 -->
    <el-button
      v-permit:remove="permissionList[payableType].meet"
      style="margin-right: 10px;"
      type="primary" size="small" @click="onGenerateCope">{{ $t('basicData.GeneratePayables') }}</el-button>

    <!-- 备注（含弹出窗） -->
    <RemarkButCmp
      :ids="selectIdArr"
      :payable-type="payableType"
      :async-sumbit="asyncRemark"
    />

    <!-- 导出 -->
    <el-button
      v-permit:remove="permissionList[payableType].export"
      style="margin-right: 10px;"
      size="small" @click="onExport">{{ $t('receivablePayable.Export') }}</el-button>

    <div v-permit:remove="permissionList[payableType].rerun" style="margin-right: 10px;">
      <!-- 重新计算（第三方/安俊） -->
      <RecalculateButCmp
        v-if="[1, 2].includes(payableType)"
        v-permit:remove="'btn:finance:payableManagement:feesPayable:addEdit'"
        @submit="onRecalculate" :payableType="payableType"/>
      <!-- AJD重算 -->
      <el-button
        v-else-if="payableType === 3" v-permit:remove="'btn:finance:payableManagement:feesPayable:addEdit'"
        size="small" @click="onRerun">{{ $t('receivablePayable.RecalculateThePrice')}}</el-button>
      <!-- DSP重算 -->
      <el-popover
        v-else-if="payableType === 4"
        v-permit:remove="'btn:finance:payableManagement:feesPayable:addEdit'"
        placement="bottom-start"
        trigger="hover">
        <el-button slot="reference" size="small">{{ $t('receivablePayable.RecalculateThePrice') }}</el-button>
        <div class="feet">
          <!-- 重新计算价格（DSP） -->
          <el-button
            size="small" type="primary" class="feet-btn"
            @click="onRerunDSP(1)"
          >{{ $t('receivablePayable.RecalculateThePrice') + '(' + $t('receivablePayable.Maximum100000') + ')' }}
          </el-button>
          <!-- 导入dsp计算价格 -->
          <el-button
            size="small" type="primary" class="feet-btn"
            @click="onRerunDSP(2)"
          >{{ $t('receivablePayable.ImportCalculatePrices') }}
          </el-button>
          <!-- 批量导入DSP价格 -->
          <el-button
            size="small" type="primary" class="feet-btn"
            @click="onRerunDSP(3)"
          >{{ $t('receivablePayable.ImportWeightsCalculatePrices') }}
          </el-button>
        </div>
      </el-popover>
      <!--运输干支线重算费用-->
      <el-button
        v-else-if="payableType === 5"
        v-permit:remove="'btn:finance:payableManagement:transportation:RecalculateThePrice'"
        size="small" @click="onRecalculateThePrice">{{ $t('receivablePayable.RecalculateThePrice')}}</el-button>
    </div>

    <!-- AJD-dialog -->
    <RerunDialog ref="rerunRef" @submit="onRecalculate" />

    <!-- DSP导入重算 -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('receivablePayable.RecalculateThePrice')"
      :visible.sync="showImportTemplate"
      width="400px"
    >
      <importDsp
        :key="showImportTemplate"
        :calculation-type="calculationType"
        @importPrice="importPrice"
      />
    </el-dialog>
    <!--运输干支线重算-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('receivablePayable.RecalculateThePrice')"
      :visible.sync="showRecalculateThePrice"
      width="500px"
    >
      <recalculate :key="showRecalculateThePrice" @saveReCalculate="saveReCalculate" />
    </el-dialog>

    <!-- 删除（含弹出窗） -->
    <DelButCmp
      :async-sumbit="asyncDel"
      :payable-type="payableType"
    />

    <!-- 自定义列栏目 :checkedColumns="checkedColumns" :customColumnId="customColumnId"-->
    <CustomStatusBar :payableType="payableType"  />

    <!-- 生成应付弹窗 -->
    <AccountsPayable
      ref="generateCopeTimeRef"
      :cb="generateReceivablesSuccess"
      :query-form="queryForm"
      :select-data="selectArr"
      :payable-type="payableType"
    />

    <!-- 显示生成应付结果弹窗 -->
    <AccountsPayableDialog ref="generateCopeListRef"/>

    <!-- 批量添加费用 -->
    <AddFee
      ref="notQRAddRef"
      :fee-type-option="feeTypeOption"
      :query-form="queryForm"
      :select-data="selectArr"
      :payable-type="payableType"
      @close="onQRClose"
    />

    <!-- 非快录详情 -->
    <DetailFee
      ref="notQRDetailsRef"
      :query-fee-param="queryFeeParam"
      :fee-type-option="feeTypeOption"
      :query-form="queryForm"
      :select-data="selectArr"
      :payable-type="payableType"
      @close="onQRClose"
    />

    <!-- 导入添加费用 -->
    <el-dialog
      :title="$t('receivablePayable.ImportAddCharges')"
      :visible.sync="isImportAdd"
      width="400px"
    >
      <importAddFees :payable-type="payableType" :fee-type-list="feeTypeOption" @colse="onImportAddClose" />
    </el-dialog>

    <!-- 导入修改费用 -->
    <el-dialog
      :title="$t('receivablePayable.ImportModifyExpenses')"
      :visible.sync="isImportEdit"
      width="400px"
    >
      <importEditFees :payable-type="payableType" :fee-type-list="feeTypeOption" @changeFeeList="changeFeeList"/>
    </el-dialog>

    <!-- 添加快录 -->
    <el-drawer
      :append-to-body="true"
      :title="$t('receivablePayable.SnapshotFee')"
      :visible.sync="addQRRow.isVisible"
      custom-class="drawerClass"
      direction="ltr"
      size="80%"
    >
      <AddQRCmp
        ref="addQRRef"
        :key="addQRRow.key"
        :payable-type="payableType"
        :query-fee-param="queryFeeParam"
        :query-form="queryForm"
        :expense-sub-list="addQRRow.expenseSubList"
        :fee-type-option="feeTypeOption"
        :select-data="selectArr"
        @saveFormSnapshot="onQRClose"
      />
    </el-drawer>

    <!-- 导入快录 -->
    <importQuickRecording
      ref="importQRRef"
      :operator-list="operatorList"
      :outlets-list="outletsList"
      :fee-type-list="feeTypeOption"
      :payable-type="payableType"
    />
  </div>
</template>

<script>
import {
  apiPayableSiteExpenseBatchDelete,
  apiPayableSiteExpenseExport,
  apiPayableSiteExpenseGetInfo,
  apiPayableSiteExpenseSetRemark,
  apiPayableTransportSiteExpenseBatchDelete,
  apiPayableTransportSiteExpenseExport,
  apiPayableTransportSiteExpenseSetRemark
} from '@/api/finance/receivables';
import { initGetData } from '@/views/finance/payableManage/feesPayable/component/utils.js';
import { cloneDeep } from 'lodash-es';
import { goResourceTaskList } from '@/utils/goExportList.js';
import {
  L_TABLE_HEADER,
  P_TABLE_HEADER,
  QUERY_FEE_PARAM,
  searchEmpty,
  TF_TABLE_HEADER
} from '@/views/finance/payableManage/feesPayable/enums.js';
import { setGenerateCheck } from '@/views/finance/payableManage/feesPayable/component/AccountsPayable/utils.js';
import AddFee from '@/views/finance/payableManage/feesPayable/component/AddFeee/index.vue';
import DetailFee from '@/views/finance/payableManage/feesPayable/component/AddFeee/details.vue';
import AddQRCmp from '@/views/finance/payableManage/feesPayable/component/AddQRcmp.vue';
import AccountsPayable from '@/views/finance/payableManage/feesPayable/component/AccountsPayable/index.vue';
import AccountsPayableDialog from '@/views/finance/payableManage/feesPayable/component/AccountsPayable/dialog.vue';
import importAddFees from '@/views/finance/payableManage/feesPayable/ImportCmp/importAddFees.vue';
import importEditFees from '@/views/finance/payableManage/feesPayable/ImportCmp/importEditFees.vue';
import importQuickRecording from '@/views/finance/payableManage/feesPayable/ImportCmp/importQuickRecording.vue';
import RerunDialog from '@/views/finance/payableManage/feesPayable/AJDDispatch/rerunDialog.vue';
import importDsp from '@/views/finance/payableManage/feesPayable/ImportCmp/importDsp.vue';

const permissionList = {
  1: {
    export: 'btn:finance:payableManagement:hut:export',
    meet: 'btn:finance:payableManagement:hut:GeneratePayables',
    rerun: 'btn:finance:payableManagement:hut:RecalculateThePrice'
  },
  2: {
    export: 'btn:finance:payableManagement:threeCollect:export',
    meet: 'btn:finance:payableManagement:threeCollect:GeneratePayables',
    rerun: 'btn:finance:payableManagement:threeCollect:RecalculateThePrice'
  },
  3: {
    export: 'btn:finance:payableManagement:ajd:export',
    meet: 'btn:finance:payableManagement:ajd:GeneratePayables',
    rerun: 'btn:finance:payableManagement:ajd:RecalculateThePrice'
  },
  4: {
    export: 'btn:finance:payableManagement:dsp:export',
    meet: 'btn:finance:payableManagement:dsp:GeneratePayables',
    rerun: 'btn:finance:payableManagement:dsp:RecalculateThePrice'
  },
  5: {
    export: 'btn:finance:payableManagement:transportation:export',
    meet: 'btn:finance:payableManagement:transportation:GeneratePayables',
    rerun: 'btn:finance:payableManagement:transportation:RecalculateThePrice'
  }
};
export default {
  name: 'ActionCenter',

  components: {
    AddFee,
    DetailFee,
    AddQRCmp,
    AccountsPayable,
    AccountsPayableDialog,
    importAddFees,
    importEditFees,
    importQuickRecording,
    RerunDialog,
    importDsp,
    DelButCmp: () => import('./DelButCmp.vue'),
    RemarkButCmp: () => import('./RemarkButcmp.vue'),
    RecalculateButCmp: () => import('./RecalculateButcmp.vue'),
    recalculate: () => import('@/views/finance/payableManage/feesPayable/component/AddFeee/recalculate.vue'),
    CustomStatusBar: () => import('@/views/finance/cmp/CustomStatusBar.vue')
  },

  props: {
    // 搜索条件
    queryForm: {
      type: Object,
      required: true
    },

    // 多选
    selectData: {
      type: Array,
      required: true
    },

    // 业务类型
    businessType: {
      type: [Number, String],
      default: null, // 2 揽收 3 派送
      required: true
    },

    // 账单日期
    payableType: {
      type: [Number, String],
      default: '', // 1 安俊 2 第三方 3 AJD 4 DSP 5 运输（干/支线）
      required: true
    }

    // 自定义列选中列
    // checkedColumns: {
    //   type: Array,
    //   default: () => []
    // },

    // customColumnId: {
    //   type: [Number, String],
    //   default: null, // 自定义列id
    // },
  },

  data() {
    return {
      isAddAndEdit: false,
      providerList: [], // 获取应付主体选项
      outletsList: [], // 获取站点
      operatorList: [], // 获取操作人
      feeTypeOption: [], // 获取费用类型
      queryFeeParam: {}, // 快录详情参数
      isImportAdd: false, // 导入添加费用
      isImportEdit: false, // 导入修改费用
      showImportTemplate: false, // DSP导入重算dialog
      calculationType: null, // 价格重算类型 0 查询结果 1 勾选结果 2 DSP导入 3 DSP导入重量

      // 非快录添加
      addNotQRRow: {
        key: 1,
        addType: 2
      },

      // 非快录详情
      editNotQRRow: {
        key: 1
      },

      // 添加快录
      addQRRow: {
        expenseSubList: [], // 费用详情

        isVisible: false, // 弹窗
        key: 2
      },
      showRecalculateThePrice: false, // 运输干支线价格重算弹窗
      permissionList
    };
  },

  computed: {
    // 获取多选选中的值(只含id)
    selectIdArr() {
      const arr = this.selectData;
      if (arr.length === 0) {
        return [];
      }

      return arr.map(item => {
        return item.id;
      });
    },

    // 获取多选选中的值
    selectArr() {
      return cloneDeep(this.selectData);
    }
  },

  activated() {
    this.initData();
  },

  created() {
    console.log(this.payableType, '==payableType');
    this.initData();
  },

  methods: {
    // 打开运输干支线重新计算价格弹窗
    onRecalculateThePrice() {
      this.showRecalculateThePrice = true;
    },
    saveReCalculate(data) {
      if (data) {
        const query = {
          pageNumber: 1,
          pageSize: 10,
          orderDirection: 'desc',
          orderProperty: '',
          params: {
            loadUserIdList: data.dispatcherList,
            startDate: data.queryDate[0],
            endDate: data.queryDate[1]
          }
        };
        const obj = {};
        obj.postData = query;
        obj.isBool = true;
        this.$emit('recalculatePrice', obj);
      }
      this.showRecalculateThePrice = false;
    },
    // 初始化数据
    initData() {
      this.isAddAndEdit = true;
      initGetData({ payableType: this.payableType }).then(res => {
        this.providerList = res[0];// 应付主体选项
        this.outletsList = res[1];// 获取站点
        this.operatorList = res[2];// 操作人
        this.feeTypeOption = res[3];// 费用类型
      }).catch(err => console.error(err)).finally(() => {
        this.isAddAndEdit = false;
      });
    },

    // 刷新列表
    refresh() {
      this.$emit('refresh');
    },

    // 批量添加费用
    onBatchAddingcosts() {
      this.$refs.notQRAddRef.open();
    },

    // 非快录详情
    openNotQRDetail(row) {
      this.queryFeeParam = cloneDeep(row);
      this.$refs.notQRDetailsRef.open();
    },

    // 快录添加
    onQR() {
      let obj = { ...QUERY_FEE_PARAM };
      if (this.selectArr.length > 0) {
        obj = cloneDeep(this.selectArr[0]);
        obj.waybillNumber = obj.waybillNumber.replace('QR', '');
        obj.id = null;
      }
      this.queryFeeParam = obj;
      this.addQRRow.isVisible = true;
      this.addQRRow.key += 1;
    },

    // 查看快录详情
    onQREditFee(row) {
      apiPayableSiteExpenseGetInfo(row).then(res => {
        if (res.code === 200) {
          const obj = cloneDeep(res.data);
          obj.waybillNumber = obj.waybillNumber.replace('QR', '');
          this.queryFeeParam = obj;
          this.addQRRow.expenseSubList = res.data.expenseSubList;
          this.addQRRow.key += 1;
          this.addQRRow.isVisible = true;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 导入添加费用关闭
    onImportAddClose() {
      this.isImportAdd = false;
    },
    // 导入修改费用关闭
    changeFeeList() {
      this.isImportEdit = false;
    },
    // 快录添加关闭
    onQRClose(isBool) {
      this.addQRRow.isVisible = false;

      isBool && this.refresh();
    },

    // 批量添加快录
    onQRImport() {
      this.$refs.importQRRef.open();
    },

    // 生成应付
    onGenerateCope() {
      // 判断搜索条件是否为空
      {
        const isBool = searchEmpty(this.queryForm.params);

        if (isBool) {
          return;
        }
      }
      // 判断业务类型及操作人
      const [isBool, val] = setGenerateCheck(this.selectArr, this.queryForm);
      if (!isBool) {
        return;
      }
      this.$refs.generateCopeTimeRef.open(val);
    },

    // 生成应付 后续处理函数
    generateReceivablesSuccess(data) {
      this.$refs.generateCopeListRef.open(data);
    },

    // 备注提交
    asyncRemark(str) {
      const param = {
        condition: {
          ...this.queryForm.params,
          ids: this.selectIdArr
        },
        remark: str,
        payableType: this.payableType
      };
      if (this.payableType === 5) {
        return apiPayableTransportSiteExpenseSetRemark(param).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // '保存成功'
            this.refresh();
          }
        });
      } else {
        return apiPayableSiteExpenseSetRemark(param).then(res => {
          this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // '保存成功'
          this.refresh();
        }).catch(err => {
          console.error(err);
        });
      }
    },

    /**
     * 默认导出所有
     */
    onExport() {
      const isBool = searchEmpty(this.queryForm.params);
      if (isBool) return;

      const prop = [];
      const label = [];
      let TABLE_HEADER = null;
      if (this.payableType === 5) {
        TABLE_HEADER = TF_TABLE_HEADER;
      } else {
        TABLE_HEADER = this.businessType === 2 ? L_TABLE_HEADER : P_TABLE_HEADER;
      }
      TABLE_HEADER.forEach(item => {
        prop.push(item.prop);
        label.push(item.label);
      });
      const param = {
        ...this.queryForm.params,
        fieldList: prop,
        headList: label,
        ids: this.selectIdArr
      };
      const fn = this.payableType === 5 ? apiPayableTransportSiteExpenseExport : apiPayableSiteExpenseExport;
      fn(param).then(res => {
        if (res.code === 200) {
          goResourceTaskList();
        }
      }).catch(err => {
        console.error(err);
      });
    },

    /**
     * 根据费用类型删除费用
     * @param code 费用编号
     */
    asyncDel(code) {
      const param = {
        ...this.queryForm.params,
        feeCode: code, // 费用编号
        ids: this.selectIdArr // 多选订单数据
      };
      if (this.payableType === 5) {
        return apiPayableTransportSiteExpenseBatchDelete(param).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('GlobalSetObj.TheOperationSucceeded')); // '操作成功'
            this.refresh();
          } else {
            this.$message.warning(res.msg);
          }
        });
      } else {
        return apiPayableSiteExpenseBatchDelete(param).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('GlobalSetObj.TheOperationSucceeded')); // '操作成功'
            this.refresh();
          } else {
            this.$message.warning(res.msg);
          }
        });
      }
    },

    // 重算价格（AJD/anjun/第三方）
    onRerun() {
      this.$refs.rerunRef.open();
    },
    onRecalculate(data) {
      const obj = {};
      obj.postData = data;
      obj.isBool = true;
      this.$emit('recalculatePrice', obj);
    },
    // DSP重新计算
    onRerunDSP(type) {
      const formData = cloneDeep(this.queryForm.params);
      if (type === 1) {
        if (!formData.startTime) {
          this.$message.warning(this.$t('receivablePayable.TheBusinessTimeCannotBeEmpty'));
          return;
        }
        try {
          const getDaysBetweenDates = (dateStr1, dateStr2) => {
            const date1 = new Date(dateStr1);
            const date2 = new Date(dateStr2);

            // 可选：验证输入日期的有效性
            if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
              throw new Error('Invalid date input');
            }

            // 计算天数差
            const millisecondsPerDay = 24 * 60 * 60 * 1000;
            const diffInMilliseconds = Math.abs(date2 - date1);
            const diffInDays = Math.ceil(diffInMilliseconds / millisecondsPerDay);

            return diffInDays;
          };

          const day = getDaysBetweenDates(formData.startTime, formData.endTime);
          const count = this.$emit('getTotal');
          if (day > 7 || count > 100000) {
            // 一次仅能重算同一业务类型，业务时间为7天且数据量小于10w的数据
            this.$message.warning(this.$t('receivablePayable.OnlyDataSameServiceType'));
            return;
          }
          let msg;
          if (this.selectData.length > 0) {
            msg = this.$i18n.t('receivablePayable.PressRecalculateWithChecked');
          } else {
            msg = this.$i18n.t('receivablePayable.Recalculate');
          }
          this.$confirm(
            msg, // 点击”确认“按钮，将按照系统最新价格表对当前查询的所有“费用来源”为“系统生成”的费用进行重新核算，是否确认？
            this.$i18n.t('basicData.tips'), {
              confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
              cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
              type: 'warning'
            }).then(() => {
            const obj = {
              key: new Date().getTime(),
              isBool: true
            };
            this.$emit('recalculatePrice', obj);
          }).catch(() => {
            console.info('用户点击取消');
          });
        } catch (error) {
          this.$message.warning(error);
        }
      } else {
        this.calculationType = type;
        this.showImportTemplate = true;
      }
    },
    // dsp导入重算
    importPrice(data) {
      this.showImportTemplate = false;
      const obj = {
        importData: data,
        key: new Date().getTime(),
        isBool: true,
        calculationType: this.calculationType // 重算类型
      };
      this.$emit('recalculatePrice', obj);
    }
  }
};
</script>

<style lang="scss" scoped>
.operational {
  display: flex;
  align-items: center;
}

.feet {
  display: flex;
  flex-direction: column;
  align-items: start;
  .feet-btn {
    margin-left: 0;
    margin-bottom: 10px;
  }
}
</style>
