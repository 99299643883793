var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { margin: "-15px" } }, [
    _c(
      "div",
      {
        staticStyle: {
          height: "calc(100vh - 100px)",
          "overflow-y": "auto",
          padding: "15px",
        },
      },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            attrs: {
              model: _vm.formData,
              size: "small",
              "label-width": "160px",
              "label-position": _vm.$i18n.locale !== "zh" ? "top" : "right",
            },
          },
          [
            _vm.type === 2
              ? _c(
                  "el-form-item",
                  {
                    staticStyle: {
                      "padding-inline": "20px",
                      "margin-bottom": "5px",
                    },
                    attrs: {
                      label: _vm.$t("GlobalSetObj.settlementType") + "：",
                      prop: "paymentType",
                      rules: [
                        {
                          required: true,
                          message: _vm.$t("GlobalSetObj.pleaseChoose"),
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "400px" },
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          filterable: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.formData.paymentType,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "paymentType", $$v)
                          },
                          expression: "formData.paymentType",
                        },
                      },
                      [
                        _c("el-option", {
                          key: "0",
                          attrs: { label: "RPA", value: "RPA" },
                        }),
                        _c("el-option", {
                          key: "1",
                          attrs: { label: "CNPJ", value: "CNPJ" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-card",
              [
                _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                  _c("span", [
                    _vm._v(_vm._s("PIX" + _vm.$t("basicData.transferable"))),
                  ]),
                ]),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label:
                        "PIX" + _vm.$t("receivablePayable.accountType") + "：",
                      prop: "dictCodeType",
                      rules: [
                        {
                          required: true,
                          message: _vm.$t("GlobalSetObj.pleaseChoose"),
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "400px" },
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          filterable: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.formData.dictCodeType,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "dictCodeType", $$v)
                          },
                          expression: "formData.dictCodeType",
                        },
                      },
                      [
                        _c("el-option", {
                          key: "0",
                          attrs: { label: "CPF", value: "CPF" },
                        }),
                        _c("el-option", {
                          key: "1",
                          attrs: { label: "CNPJ", value: "CNPJ" },
                        }),
                        _c("el-option", {
                          key: "2",
                          attrs: {
                            label: "MOBILE PHONE",
                            value: "MOBILE PHONE",
                          },
                        }),
                        _c("el-option", {
                          key: "3",
                          attrs: { label: "EMAIL", value: "EMAIL" },
                        }),
                        _c("el-option", {
                          key: "4",
                          attrs: { label: "EVP", value: "EVP" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label:
                        "PIX" + _vm.$t("customerManagements.account") + "：",
                      prop: "dictCode",
                      rules: [
                        {
                          required: true,
                          message: _vm.$t("GlobalSetObj.pleaseInput"),
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                      },
                      model: {
                        value: _vm.formData.dictCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "dictCode", $$v)
                        },
                        expression: "formData.dictCode",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-card",
              { staticStyle: { "margin-top": "5px" } },
              [
                _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("basicData.bankTransfer"))),
                  ]),
                ]),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("basicData.ReceivingName") + "：",
                      prop: "payeeName",
                      rules: [
                        {
                          required: true,
                          message: _vm.$t("GlobalSetObj.pleaseInput"),
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                      },
                      model: {
                        value: _vm.formData.payeeName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "payeeName", $$v)
                        },
                        expression: "formData.payeeName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("orderCenterCont.CertificateType") + "：",
                      prop: "documentType",
                      rules: [
                        {
                          required: true,
                          message: _vm.$t("GlobalSetObj.pleaseChoose"),
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "400px" },
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          filterable: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.formData.documentType,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "documentType", $$v)
                          },
                          expression: "formData.documentType",
                        },
                      },
                      [
                        _c("el-option", {
                          key: "0",
                          attrs: { label: "CPF", value: "CPF" },
                        }),
                        _c("el-option", {
                          key: "1",
                          attrs: { label: "CNPJ", value: "CNPJ" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("basicData.CertificateNumber") + "：",
                      prop: "documentNumber",
                      rules: [
                        {
                          required: true,
                          validator: _vm.validateNumber,
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                      },
                      model: {
                        value: _vm.formData.documentNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "documentNumber", $$v)
                        },
                        expression: "formData.documentNumber",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("basicData.BankNumber") + "：",
                      prop: "bankCode",
                      rules: [
                        {
                          required: true,
                          message: _vm.$t("GlobalSetObj.pleaseChoose"),
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "400px" },
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          filterable: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.formData.bankCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "bankCode", $$v)
                          },
                          expression: "formData.bankCode",
                        },
                      },
                      _vm._l(_vm.bankList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: {
                            label:
                              item.code + "（" + item.name + "）-" + item.ispd,
                            value: item.code,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("basicData.BranchNumber") + "：",
                      prop: "branch",
                      rules: [
                        {
                          required: true,
                          validator: _vm.validateNumber,
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                      },
                      model: {
                        value: _vm.formData.branch,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "branch", $$v)
                        },
                        expression: "formData.branch",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("basicData.ReceivingAccount") + "：",
                      prop: "bankNumber",
                      rules: [
                        {
                          required: true,
                          validator: _vm.validateNumber,
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                      },
                      model: {
                        value: _vm.formData.bankNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "bankNumber", $$v)
                        },
                        expression: "formData.bankNumber",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          padding: "10px",
          "text-align": "right",
          "border-top": "1px solid #ccc",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small" },
            on: {
              click: function ($event) {
                return _vm.onClose()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
        ),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "permit",
                rawName: "v-permit:remove",
                value: _vm.permission,
                expression: "permission",
                arg: "remove",
              },
            ],
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.submitForm },
          },
          [_vm._v(" " + _vm._s(_vm.$t("basicData.Save")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }