<template>
  <div class="sharedResourceBox">
    <div style="margin-bottom: 20px;">
      <el-form label-width="100px">
        <el-row>
          <el-col :span="7">
            <!-- 资源名称： -->
            <el-form-item :label="$t('Resource.ResourceN')">
              <el-input v-model.trim="queryForm.params.name" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!-- 资源类型： -->
            <el-form-item :label="$t('Resource.ResourceT')" class="sRClass">
              <el-select
                v-model="queryForm.params.type" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in resourcesTypeList"
                  :key="index"
                  :label="item.remark"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="12">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button>
          <!-- 查询 -->
          <el-button
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button>
          <!-- 重置 -->
          <!-- <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="addClick"
          >{{ $t('collectionCenter.add') }}</el-button>新增 -->
        </el-col>
      </el-row>
    </div>
    <div>
      <el-table
        :max-height="600"
        :data="tableDate"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column
          :label="$t('collectionCenter.ind')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="typeName"
          :label="$t('Resource.ResourceType')"
        /><!-- 资源类型 -->
        <el-table-column
          prop="compel"
          :label="$t('Resource.WhetherForceUpdate')"
        ><!-- 是否强制更新 -->
          <template slot-scope="scope">
            <div>
              <!--                                    '否' : '是' -->
              {{ scope.row.compel == 0 ? $t('Resource.No') : $t('Resource.yes') }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('Resource.ResourceName')"
        /><!-- 资源名称 -->
        <el-table-column
          prop="updateUserName"
          :label="$t('Resource.uploader')"
        /><!-- 上传者 -->
        <el-table-column
          prop="versionInfo"
          :label="$t('Resource.VersionNum')"
        /><!-- 版本号 -->
        <el-table-column
          prop="updateTime"
          :label="$t('Resource.CreationTime')"
        /><!-- 创建时间 -->
        <el-table-column
          :label="$t('collectionCenter.operation')"
          fixed="right"
          width="120"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-button type="info" size="mini" @click="editClick(scope.row)">{{ $t('collectionCenter.edit') }}</el-button><!-- 编辑 -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getTablePage"
        @size-change="searchClick"
      />
    </div>
    <div v-if="drawerIsOpen" class="drawerClass"><!-- 新增、编辑 -->
      <el-drawer
        :title="drawerTitle"
        :visible.sync="drawerIsOpen"
        :direction="direction"
      >
        <div class="drawerBox">
          <el-form
            ref="resourceRef" :model="resourcesForm" :rules="resourceRules"
            label-width="120px"
          >
            <!-- 资源名称： -->
            <el-form-item :label="$t('Resource.ResourceN')" prop="name">
              <el-input v-model="resourcesForm.name" :placeholder="$t('collectionCenter.pleaseInput')" />
            </el-form-item>
            <!-- 资源链接： -->
            <el-form-item :label="$t('Resource.ResourceLinks')" prop="url">
              <el-input v-model="resourcesForm.url" :placeholder="$t('collectionCenter.pleaseInput')" />
            </el-form-item>
            <!-- 资源类型： -->
            <el-form-item :label="$t('Resource.ResourceT')" prop="type">
              <el-select
                v-model="resourcesForm.type" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in resourcesTypeList"
                  :key="index"
                  :label="item.remark"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
            <!-- 是否更新版本： -->
            <el-form-item v-if="drawerTitle == $t('Resource.edit')" :label="$t('Resource.WhetherToUpdateTheVersion')" prop="updatedVersion">
              <el-radio-group v-model="resourcesForm.updatedVersion">
                <el-radio-button label="true">{{ $t('Resource.yes') }}</el-radio-button><!-- 是 -->
                <el-radio-button label="false">{{ $t('Resource.No') }}</el-radio-button><!-- 否 -->
              </el-radio-group>
            </el-form-item>
            <!-- 是否强制更新： -->
            <el-form-item v-if="drawerTitle == $t('collectionCenter.edit')" :label="$t('Resource.WhetherToForceUpdate')" prop="compel">
              <el-radio-group v-model="resourcesForm.compel">
                <el-radio-button label="1">{{ $t('Resource.yes') }}</el-radio-button><!-- 是 -->
                <el-radio-button label="0">{{ $t('Resource.No') }}</el-radio-button><!-- 否 -->
              </el-radio-group>
            </el-form-item>
            <!-- 版本号： -->
            <el-form-item :label="$t('Resource.VersionNumber')" prop="versionInfo">
              <el-input v-model="resourcesForm.versionInfo" :placeholder="$t('collectionCenter.pleaseInput')" />
            </el-form-item>
            <!-- 实际版本号： -->
            <el-form-item v-if="drawerTitle == $t('collectionCenter.edit')" :label="$t('Resource.ActualVersionNumber')" prop="versionCode">
              <el-input v-model="resourcesForm.versionCode" :placeholder="$t('collectionCenter.pleaseInput')" />
            </el-form-item>
            <!-- 更新提示： -->
            <el-form-item :label="$t('Resource.UpdateTips')" prop="remark" class="noteClass">
              <el-input v-model="resourcesForm.remark" type="textarea" :placeholder="$t('collectionCenter.pleaseInput')" />
            </el-form-item>
            <!-- 备注： -->
            <el-form-item :label="$t('Resource.note')" prop="extra" class="noteClass">
              <el-input v-model="resourcesForm.extra" type="textarea" :placeholder="$t('collectionCenter.pleaseInput')" />
            </el-form-item>
          </el-form>
          <div class="dialog-footer">
            <!-- 取 消 -->
            <el-button @click="Close">{{ $t('collectionCenter.cancel') }}</el-button>
            <!-- 确 定 -->
            <el-button type="primary" @click="Confirm">{{ $t('collectionCenter.confirm') }}</el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { appManagePage, appManageUpdate, appManageAdd } from '@/api/resources';
export default {
  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          name: '',
          type: ''
        }
      },
      resourcesTypeList: [ // 资源类型
        { code: 0, remark: this.$t('Resource.file') }, // 文件
        { code: 1, remark: this.$t('Resource.video') }, // 视频
        { code: 2, remark: this.$t('Resource.picture') }, // 图片
        { code: 3, remark: this.$t('Resource.tape') } // 录音
      ],
      tableDate: [],
      total: 0,
      drawerIsOpen: false,
      drawerTitle: this.$t('Resource.add'), // 新增
      direction: 'ltr',
      resourcesForm: {
        id: '',
        name: '', // 资源名称
        type: '', // 资源类型
        versionInfo: '', // 版本信息
        versionCode: '', // 实际版本号（编辑时显示，不可编辑）
        compel: '0', // 是否强制更新
        updatedVersion: false, // 是否更新版本
        url: '', // 链接
        remark: '', // 更新提示
        extra: '' // 备注
      },
      resourceRules: {
        name: [{ required: true, message: this.$t('collectionCenter.pleaseInput'), trigger: ['blur', 'change'] }],
        url: [{ required: true, message: this.$t('collectionCenter.pleaseInput'), trigger: ['blur', 'change'] }],
        type: [{ required: true, message: this.$t('collectionCenter.pleaseChoose'), trigger: ['blur', 'change'] }],
        versionInfo: [{ required: true, message: this.$t('collectionCenter.pleaseInput'), trigger: ['blur', 'change'] }],
        versionCode: [
          { required: true, message: this.$t('collectionCenter.pleaseInput'), trigger: ['blur', 'change'] },
          {
            validator: async(rule, value, callback) => {
              const reg = /^[0-9]*$/; // 校验不是0-9的数字
              if (!reg.test(value)) {
                return callback(new Error(this.$t('orderCenterCont.inputNum'))); // '请输入数字'
              } else {
                return callback();
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        remark: [{ required: true, message: this.$t('collectionCenter.pleaseInput'), trigger: ['blur', 'change'] }]
      }
    };
  },
  created() {
    this.default();
    this.getTablePage();
  },
  methods: {
    searchClick() { // 查询
      this.queryForm.pageNumber = 1;
      this.getTablePage();
    },
    resetClick() { // 重置
      this.default();
    },
    default() { // 默认查询条件
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          name: '',
          type: ''
        }
      };
    },
    getTablePage() {
      appManagePage(this.queryForm).then(res => {
        if (res.code===200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch({});
    },
    addClick() { // 新增
      this.drawerIsOpen = true;
      this.drawerTitle = this.$t('Resource.add');
      this.resourcesForm = {
        id: '',
        name: '', // 资源名称
        type: '', // 资源类型
        versionInfo: '', // 版本信息
        versionCode: '',
        compel: '0', // 是否强制更新
        updatedVersion: '', // 是否更新版本
        url: '', // 链接
        remark: '', // 更新提示
        extra: '' // 备注
      };
    },
    editClick(row) { // 编辑
      this.drawerIsOpen = true;
      this.drawerTitle = this.$t('Resource.edit');
      this.resourcesForm = {
        id: row.id,
        name: row.name, // 资源名称
        type: row.type, // 资源类型
        versionInfo: row.versionInfo, // 版本信息
        versionCode: row.versionCode, // 实际版本号
        compel: row.compel, // 是否强制更新
        updatedVersion: row.updatedVersion, // 是否更新版本
        url: row.url, // 链接
        remark: row.remark, // 更新提示
        extra: row.extra // 备注
      };
    },
    Close() { // 新增、编辑弹窗——取消
      this.drawerIsOpen = false;
    },
    Confirm() { // 新增、编辑弹窗——确定
      this.$refs['resourceRef'].validate((valid) => {
        if (valid) {
          if (this.drawerTitle === this.$t('Resource.edit')) {
            appManageUpdate(this.resourcesForm).then(res => {
              if (res.code===200) {
                this.drawerIsOpen = false;
                this.searchClick();
                this.resourcesForm = {
                  id: '',
                  name: '', // 资源名称
                  type: '', // 资源类型
                  versionInfo: '', // 版本信息
                  versionCode: '',
                  compel: '0', // 是否强制更新
                  updatedVersion: false, // 是否更新版本
                  url: '', // 链接
                  remark: '', // 更新提示
                  extra: '' // 备注
                };
              }
            }).catch({});
          }
          if (this.drawerTitle === this.$t('Resource.add')) {
            const addForm = {
              name: this.resourcesForm.name,
              type: this.resourcesForm.type,
              versionInfo: this.resourcesForm.versionInfo,
              compel: this.resourcesForm.compel,
              url: this.resourcesForm.url,
              remark: this.resourcesForm.remark,
              extra: this.resourcesForm.extra
            };
            appManageAdd(addForm).then(res => {
              if (res.code===200) {
                this.drawerIsOpen = false;
                this.searchClick();
                this.resourcesForm = {
                  id: '',
                  name: '', // 资源名称
                  type: '', // 资源类型
                  versionInfo: '', // 版本信息
                  compel: '0', // 是否强制更新
                  updatedVersion: false, // 是否更新版本
                  url: '', // 链接
                  remark: '', // 更新提示
                  extra: '' // 备注
                };
              }
            }).catch({});
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .sharedResourceBox, .drawerBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .dialog-footer{
    text-align: right;
    margin-top: 50px;
  }
  .sharedResourceBox .sRClass ::v-deep .el-select, .drawerBox ::v-deep .el-select{
    width: 100%;
  }
  .noteClass ::v-deep .el-textarea__inner{
    max-height: 200px;
  }
  .sharedResourceBox .drawerClass ::v-deep .el-textarea .el-textarea__inner{
    max-width: 100%;
  }
  .sharedResourceBox .drawerClass ::v-deep section.el-drawer__body{
    overflow: visible;
    overflow-y: auto;
  }
</style>
