<template>
  <div class="warpp">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <!-- 授权码 -->
        <span>{{ $t('customerManagements.authorizationCode') }}</span>
        <el-button
          v-permit:remove="'but:customer-managements:list:AuthorizationCode'"
          style="float: right;"
          size="mini"
          type="primary"
          :disabled="Number(customerId) === 0"
          @click="handleMessage"
        >{{ $t('customerManagements.generateAuthorizationCode') }}</el-button>
        <!-- 生成授权码 -->
      </div>
      <div class="text item">
        <!-- 当前客户不存在 -->
        <el-link v-if="Number(customerId) === 0" type="info" :underline="false">{{ $t('customerManagements.CustomerNoExist') }}</el-link>
        <!-- 暂无授权码（请点击右上角按钮生成或者联系管理员) -->
        <el-link v-else-if="authorizationCode.length === 0" type="info" :underline="false">{{ $t('customerManagements.noAuthorizationCode') }}</el-link>
        <template v-else>
          <p class="code">{{ authorizationCode }}</p>
          <!-- 复制授权码 -->
          <el-button type="info" size="mini" icon="el-icon-document-copy" @click="handleCopy">{{ $t('customerManagements.copyAuthorizationCode') }}</el-button>
        </template>
      </div>
    </el-card>
  </div>
</template>

<script>
import { apiGetAuthorizationCode } from '@/api/customer';
export default {
  props: {
    // 客户ID
    customerId: {
      type: [Number, String],
      default: ''
    },

    // 授权码
    code: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      authorizationCode: '' // 授权码
    };
  },

  watch: {
    code(value) {
      this.authorizationCode = value;
    }
  },

  methods: {
    // 生成授权码确认
    handleMessage() {
      if (this.authorizationCode.length === 0) {
        this.asyncGetAuthorizationCode();
        return;
      }
      // '之前生成的授权码将会失效, 是否继续?'
      this.$confirm(this.$t('customerManagements.WhetherToContinue'), this.$t('customerManagements.tips'), {
        confirmButtonText: this.$t('customerManagements.confirm'), // '确定'
        cancelButtonText: this.$t('customerManagements.cancel'), // '取消'
        type: 'warning'
      }).then(() => {
        this.asyncGetAuthorizationCode();
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
        console.log('已取消');
      });
    },

    asyncGetAuthorizationCode() {
      apiGetAuthorizationCode(this.customerId).then(res => {
        if (res.code !== 200) { return; }
        this.authorizationCode = res.data;
        this.$message({
          message: this.$t('customerManagements.AuthorizationCodeSuccessfully'), // '授权码生成成功'
          type: 'success'
        });
      }).catch(err => {
        console.error(err);
      });
    },

    // 实现复制功能
    handleCopy() {
      if (!navigator.clipboard) {
        this.$message({
          message: this.$t('customerManagements.PleaseUpgradeOrManualCopy'), // '当前浏览器版本过低，请升级浏览器或手动复制'
          type: 'warning'
        });
        return;
      }
      navigator.clipboard.writeText(this.authorizationCode);
      this.$message({
        message: this.$t('customerManagements.ReplicationSucceeded'), // '复制成功'
        type: 'success'
      });
    }

  }
};
</script>

<style scoped>
.code {
  margin: 0;
  color: #409eff;
  font-size: 14px;
  word-wrap:break-word;
  word-break:normal;
}
</style>
