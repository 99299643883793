<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="5">
          <!-- 限制的州  -->
          <el-form-item :label="$t('navbar.restrictionState')">
            <el-select
              v-model="queryForm.params.restrictionStateList"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              style="width: 100%"
              @change="handleState(queryForm.params.restrictionStateList, 2)"
              @clear="clear1"
            >
              <el-option :label="$t('collectionCenter.tudo')" value="all" />
              <el-option
                v-for="item in StateList"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--限制的网点-->
          <el-form-item :label="$t('navbar.restrictionSiteId')">
            <el-select
              v-model="queryForm.params.restrictionSiteIdList"
              :placeholder="$t('GlobalSetObj.all')"
              clearable
              filterable
              multiple
              style="width: 100%"
            >
              <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--  状态   -->
          <el-form-item :label="$t('GlobalSetObj.status')">
            <el-select
              v-model="queryForm.params.status"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 100%"
            >
              <el-option :label="$t('GlobalSetObj.all')" :value="null" />
              <el-option :label="$t('GlobalSetObj.enable')" :value="1" />
              <el-option :label="$t('GlobalSetObj.stop')" :value="0" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--  类型   -->
          <el-form-item :label="$t('GlobalSetObj.Type')">
            <el-select
              v-model="queryForm.params.restrictionType"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 100%"
            >
              <el-option :label="$t('GlobalSetObj.all')" :value="null" />
              <el-option :label="$t('navbar.dainziweilan')" :value="1" />
              <el-option :label="$t('navbar.qiangzhiyanzhengma')" :value="2" />
              <el-option :label="$t('navbar.qiangzhiqinashou')" :value="3" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item :label="$t('navbar.RestrictedAccounts')" prop="userIdList">
            <el-select
              v-model="queryForm.params.userIdList"
              :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
              :remote-method="getAllUserList"
              clearable
              filterable
              multiple
              remote
              reserve-keyword
              style="width: 100%"
              @visible-change="getAllUserList()"
            >
              <el-option
                v-for="item in accountList"
                :key="item.id"
                :label="item.username"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div style="margin-bottom: 15px">
            <!--搜索-->
            <el-button
              icon="el-icon-search" size="small" type="primary"
              @click="enterSearch"
            >{{ $t('newOrder.search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh-left" size="small" type="info"
              @click="resetForm()"
            >{{ $t('newOrder.reset') }}
            </el-button>
            <el-button
              size="small" type="primary" @click="newRestrictions"
            >{{ $t('GlobalSetObj.add') }}
            </el-button>
            <el-button
              plain size="small" type="primary"
              @click="statusBtn(1)"
            >{{ $t('GlobalSetObj.enable') }}
            </el-button>
            <el-button
              plain size="small" type="danger"
              @click="statusBtn(0)"
            >{{ $t('GlobalSetObj.stop') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      ref="multipleTable"
      :data="tableData"
      :header-cell-style="{ background: '#f2f2f2', color: 'black' }"
      border
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" type="index"
        width="70"
      />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :align="item.cloAlign"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">
          <div v-if="item.prop === 'isElectronicFenceName'">
            <div :style="{ color: scope.row.isElectronicFence ? '#409eff' : 'red' }">
              {{ scope.row.isElectronicFenceName }}
            </div>
          </div>
          <div v-else-if="item.prop === 'isCaptchaName'">
            <div :style="{ color: scope.row.isCaptcha ? '#409eff' : 'red' }">
              {{ scope.row.isCaptchaName }}
            </div>
          </div>
          <div v-else-if="item.prop === 'isDeliverImgName'">
            <div :style="{ color: scope.row.isDeliverImg ? '#409eff' : 'red' }">
              {{ scope.row.isDeliverImgName }}
            </div>
          </div>
          <div v-else-if="item.prop === 'statusName'">
            <el-tag v-if="scope.row.status === 1">{{ scope.row.statusName }}</el-tag>
            <el-tag v-else type="danger">{{ scope.row.statusName }}</el-tag>
          </div>
          <div v-else>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '- -' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('newOrder.operation')" align="center" fixed="right"
        width="100"
      >
        <template slot-scope="scope">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <el-tooltip
              :content="$t('GlobalSetObj.edit')"
              class="item"
              effect="dark"
              placement="top"
            >
              <i class="el-icon-edit textOperator" @click="editData(scope.row)" />
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.status === 0"
              :content="$t('GlobalSetObj.enable')"
              class="item"
              effect="dark"
              placement="top"
            >
              <i class="el-icon-circle-check textOperator" @click="updateStatus(scope.row)" />
            </el-tooltip>
            <el-tooltip
              v-else
              :content="$t('GlobalSetObj.stop')"
              class="item"
              effect="dark"
              placement="top"
            >
              <i class="el-icon-remove-outline textOperator1" @click="updateStatus(scope.row)" />
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0; float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 新增限制 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="dialogTitle"
      :visible.sync="addDialog"
      top="5vh"
      width="520px"
      @close="handleAddCusTimeDialog"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
        label-position="top"
        label-width="auto"
      >
        <el-form-item :label="$t('navbar.restrictionState')" prop="restrictionState">
          <el-select
            v-model="ruleForm.restrictionState"
            :placeholder="$t('GlobalSetObj.pleaseChoose')"
            clearable
            filterable
            multiple
            style="width: 100%"
            @change="handleState(ruleForm.restrictionState, 1)"
            @clear="clear2"
          >
            <el-option
              :label="$t('collectionCenter.tudo')"
              value="all"
              @click.native.stop="toggleSelectAll2"
            />
            <el-option
              v-for="item in StateList"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
        <!--限制的网点-->
        <el-form-item :label="$t('navbar.restrictionSiteId')" prop="siteIds">
          <el-select
            v-model="ruleForm.siteIds"
            :placeholder="$t('GlobalSetObj.pleaseChoose')"
            clearable
            filterable
            multiple
            style="width: 100%"
            remote
            reserve-keyword
            :remote-method="getSiteOptionList"
            @change="handleSiteIds"
            @visible-change="getSiteOptionList()"
          >
            <el-option
              v-for="item in restrictionSiteIds"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <!--限制的账号-->
        <el-form-item :label="$t('navbar.RestrictedAccounts')" prop="userIds">
          <el-select
            v-model="ruleForm.userIds"
            :placeholder="$t('GlobalSetObj.pleaseChoose')"
            clearable
            filterable
            multiple
            remote
            reserve-keyword
            :remote-method="getSiteAccounet"
            style="width: 100%"
            @visible-change="getSiteAccounet()"
          >
            <el-option
              v-for="item in limitAccountList"
              :key="item.id"
              :label="item.username"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('GlobalSetObj.Type')" prop="TypeList">
          <el-checkbox-group v-model="ruleForm.TypeList" @change="handleTypeList">
            <el-checkbox :label="1">{{ $t('navbar.dainziweilan') }}</el-checkbox>
            <el-checkbox :label="2">{{ $t('navbar.qiangzhiyanzhengma') }}</el-checkbox>
            <el-checkbox :label="3">{{ $t('navbar.qiangzhiqinashou') }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <!-- 派送距离  -->
        <el-form-item
          v-if="ruleForm.TypeList.includes(1)"
          :label="$t('navbar.deliveryDistance')"
          prop="deliveryDistance"
        >
          <el-input
            v-model.number="ruleForm.deliveryDistance"
            :placeholder="$t('GlobalSetObj.pleaseInput')"
            maxlength="9"
          >
            <template #suffix>
              <span>M</span>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.TypeList.includes(2)"
          :label="$t('navbar.smsTemplateId')"
          prop="smsTemplateId"
        >
          <el-select
            v-model="ruleForm.smsTemplateId"
            :placeholder="$t('GlobalSetObj.pleaseChoose')"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in smsTemplateList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.TypeList.includes(2)"
          :label="$t('navbar.smsPushNode')"
          prop="smsPushNode"
        >
          <el-select
            v-model="ruleForm.smsPushNode"
            :placeholder="$t('GlobalSetObj.pleaseChoose')"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in pushNodeList"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <!--推送方式-->
        <el-form-item
          v-if="ruleForm.TypeList.includes(2)"
          :label="$t('GlobalSetObj.pushMode')"
          :rules="[
            {
              required: true,
              message: $t('GlobalSetObj.pleaseChoose'),
              trigger: ['blur', 'change']
            }
          ]"
          prop="pushMethod"
        >
          <el-select
            v-model="ruleForm.pushMethod"
            :placeholder="$t('GlobalSetObj.pleaseChoose')"
            clearable
            style="width: 100%"
          >
            <el-option :key="1" :label="$t('GlobalSetObj.sms')" :value="1" /><!--SMS短信-->
            <el-option :key="2" :value="2" label="Whatsapp" /><!--Whatsapp-->
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="clickSubmit(1)">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button
          size="small" type="primary" @click="clickSubmit(2)"
        >{{ $t('GlobalSetObj.confirm') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getLgAreaListData } from '@/api/orderApi';
import {
  batchIsOpened,
  getOpenTemplate,
  getSiteByState,
  getUserList,
  restrictionadd,
  restrictionisOpened,
  restrictionPage,
  restrictionupdate,
  signRescissiongetPmUserList
} from '@/api/resources';
import { getAllsite } from '@/api/logisticsOrders';

export default {
  name: 'SignCofig',
  data() {
    return {
      isAllSelected1: false,
      isAllSelected2: false,
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          restrictionStateList: [],
          restrictionSiteIdList: [],
          status: null,
          restrictionType: null,
          userIdList: []
        }
      },
      tableData: [],
      tableHeaderList: [
        // 创建时间
        {
          id: 1,
          label: this.$t('GlobalSetObj.CreationTime'),
          prop: 'createTime',
          cloWidth: '160',
          cloAlign: 'center'
        },
        // 状态
        {
          id: 9,
          label: this.$t('GlobalSetObj.status'),
          prop: 'statusName',
          cloWidth: '150',
          cloAlign: 'center'
        },
        // 限制的网点
        {
          id: 2,
          label: this.$t('navbar.restrictionSiteId'),
          prop: 'restrictionSiteIdName',
          cloWidth: '160',
          cloAlign: 'center'
        },
        // 限制的州
        {
          id: 3,
          label: this.$t('navbar.restrictionState'),
          prop: 'restrictionState',
          cloWidth: '160',
          cloAlign: 'center'
        },
        // 限制的账号
        {
          id: 10,
          label: this.$t('navbar.RestrictedAccounts'),
          prop: 'accountName',
          cloWidth: '160',
          cloAlign: 'center'
        },
        // 强制电子围栏
        {
          id: 4,
          label: this.$t('navbar.dainziweilan'),
          prop: 'isElectronicFenceName',
          cloWidth: '160',
          cloAlign: 'center'
        },
        // 强制验证码
        {
          id: 5,
          label: this.$t('navbar.qiangzhiyanzhengma'),
          prop: 'isCaptchaName',
          cloWidth: '190',
          cloAlign: 'center'
        },
        // 是否强制照片
        {
          id: 115,
          label: this.$t('navbar.isDeliverImgName'),
          prop: 'isDeliverImgName',
          cloWidth: '190',
          cloAlign: 'center'
        },
        // 派送距离(KM)
        {
          id: 6,
          label: this.$t('navbar.deliveryDistance'),
          prop: 'dispatchDistanceName',
          cloWidth: '160',
          cloAlign: 'center'
        },
        // 短信模板
        {
          id: 7,
          label: this.$t('navbar.smsTemplateId'),
          prop: 'smsTemplateIdName',
          cloWidth: '150',
          cloAlign: 'center'
        },
        // 短信推送节点
        {
          id: 8,
          label: this.$t('navbar.smsPushNode'),
          prop: 'smsPushNodeName',
          cloWidth: '160',
          cloAlign: 'center'
        }
      ],
      userList: [], // 用户列表
      addDialog: false,
      ruleForm: {
        siteIds: [],
        smsTemplateId: '',
        smsPushNode: '',
        pushMethod: '',
        deliveryDistance: '',
        restrictionState: '',
        TypeList: [],
        userIds: []
      },
      rules: {
        restrictionState: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        siteIds: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        TypeList: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        smsTemplateId: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        smsPushNode: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        pushMethod: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        deliveryDistance: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const regex = /^[1-9]\d*$/; // 正则表达式，匹配正整数
              if (!regex.test(value)) {
                callback(new Error(this.$t('collectionCenter.pleaseInputInteger')));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
        userIds: [
          { required: false, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ]
      },
      detailData: [],
      defaultTime: '',
      smsTemplateList: [],
      StateList: [],
      siteList: [],
      pushNodeList: [
        { value: 1, label: this.$t('navbar.orderCreate') },
        { value: 2, label: this.$t('navbar.ruksaomiao') },
        { value: 3, label: this.$t('navbar.renwufenpei') }
      ],
      dialogTitle: this.$t('navbar.dialogTitlecreate'),
      editId: null,
      restrictionSiteIds: [],
      editSiteObject: {},
      multipleSelection: [],
      limitAccountList: [],
      accountList: [],
      allSiteList: []
    };
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getSearch();
    this.getAddress();
    this.getAllSite();
    this.getAllUserList();
    this.getOpenTemplateList();
  },
  methods: {
    // 网点搜索
    getSiteOptionList(query) {
      setTimeout(() => {
        if (query) {
          const siteOption = this.allSiteList.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
          this.restrictionSiteIds = siteOption;
        } else {
          this.restrictionSiteIds = this.allSiteList;
        }
      }, 200);
      console.log(this.restrictionSiteIds, 'this.restrictionSiteIds');
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    statusBtn(val) {
      if (this.multipleSelection.length === 0) {
        this.$message.warning(this.$t('collectionCenter.pleaseSelectData'));
      } else {
        const signRestrictionDTOS = [];
        this.multipleSelection.forEach((item) => {
          signRestrictionDTOS.push({
            id: item.id,
            smsTemplateId: item.smsTemplateId
          });
        });
        const data = {
          status: val,
          signRestrictionDTOS
        };
        this.$confirm(this.$t('customerManagements.tip103'), this.$t('collectionCenter.tips'), {
          confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
          cancelButtonText: this.$t('collectionCenter.cancel'), // '取消'
          type: 'warning'
        })
          .then(async() => {
            batchIsOpened(data).then((res) => {
              if (res.code === 200) {
                this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
                this.$refs.multipleTable.clearSelection();
                this.getSearch();
              }
            });
          })
          .catch(() => {
            console.log('取消关闭');
          });
      }
    },
    newRestrictions() {
      this.dialogTitle = this.$t('navbar.dialogTitlecreate');
      this.restrictionSiteIds = [];
      this.allSiteList = [];
      this.ruleForm.TypeList = [1];
      this.editId = null;
      this.addDialog = true;
      this.limitAccountList = [];
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate();
      });
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.queryForm.params = {
        restrictionStateList: [],
        restrictionSiteIdList: [],
        status: null,
        restrictionType: null
      };
      this.isAllSelected1 = false;
      this.getSearch();
      this.handleState('', 2);
    },
    handleTypeList(val) {
      if (val.length > 0) {
        if (!val.includes(1)) {
          this.ruleForm.deliveryDistance = '';
        }
        if (!val.includes(2)) {
          this.ruleForm.smsTemplateId = '';
          this.ruleForm.smsPushNode = '';
          this.ruleForm.pushMethod = '';
        }
      }
    },
    // 查询
    getSearch() {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params = this.queryForm.params;
      restrictionPage(data).then(res => {
        if (res.code===200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach((item) => {
            if (item.dispatchDistance) {
              item.dispatchDistanceName = item.dispatchDistance + 'M';
            } else {
              item.dispatchDistanceName = '--';
            }
          });
        }
      });
    },
    clear1() {
      this.queryForm.params.restrictionStateList = [];
      this.isAllSelected1 = false;
    },
    clear2() {
      this.ruleForm.restrictionState = [];
      this.isAllSelected2 = false;
    },
    toggleSelectAll2() {
      if (this.ruleForm.restrictionState && this.ruleForm.restrictionState.indexOf('all') > -1) {
        if (this.isAllSelected2) {
          this.ruleForm.restrictionState = [];
          this.isAllSelected2 = false;
        } else {
          const allVal = this.StateList.map((arr) => {
            return arr.label;
          });
          this.ruleForm.restrictionState = allVal;
          this.isAllSelected2 = true;
        }
      }
    },
    handleState(val, isFilter) {
      this.ruleForm.siteIds = [];
      let stateList = [];
      if (isFilter === 1) {
        if (this.ruleForm.restrictionState && this.ruleForm.restrictionState.indexOf('all') > -1) {
          if (this.isAllSelected2) {
            this.ruleForm.restrictionState = [];
            this.isAllSelected2 = false;
          } else {
            const allVal = this.StateList.map((arr) => {
              return arr.label;
            });
            this.ruleForm.restrictionState = allVal;
            this.isAllSelected2 = true;
          }
        }
        stateList = this.ruleForm.restrictionState;
      }
      if (isFilter === 2) {
        if (
          this.queryForm.params.restrictionStateList &&
          this.queryForm.params.restrictionStateList.indexOf('all') > -1
        ) {
          if (this.isAllSelected1) {
            this.queryForm.params.restrictionStateList = [];
            this.isAllSelected1 = false;
          } else {
            const allVal = this.StateList.map((arr) => {
              return arr.label;
            });
            this.queryForm.params.restrictionStateList = allVal;
            this.isAllSelected1 = true;
          }
        } else {
          stateList = this.queryForm.params.restrictionStateList;
        }
      }
      if (val.length > 0) {
        console.log(val, '==val');
        const data = {
          stateList,
          isFilter: isFilter
        };
        if (isFilter === 2) {
          data.stateList = this.queryForm.params.restrictionStateList;
        }
        getSiteByState(data).then(res => {
          if (res.code===200) {
            if (isFilter === 1) {
              this.restrictionSiteIds = res.data;
              this.allSiteList = res.data;
            } else {
              this.siteList = res.data;
            }
          }
        });
      } else {
        console.log(2222);
        this.restrictionSiteIds = [];
        this.allSiteList = [];
        this.getAllSite();
      }
      this.ruleForm.userIds = [];
      this.limitAccountList = [];
      if (this.ruleForm.siteIds.length > 0) {
        this.getSiteAccounet();
      }
    },
    editData(row) {
      this.editSiteObject = {
        id: row.restrictionSiteId,
        name: row.restrictionSiteIdName,
        state: row.restrictionState
      };
      const data = {
        stateList: [row.restrictionState],
        isFilter: 1
      };
      getSiteByState(data).then(res => {
        if (res.code===200) {
          this.restrictionSiteIds = res.data;
          this.allSiteList = res.data;
          this.restrictionSiteIds.unshift(this.editSiteObject);
          this.dialogTitle = this.$t('navbar.dialogTitleedit');
          this.editId = row.id;
          this.ruleForm.restrictionState = [row.restrictionState];
          this.ruleForm.siteIds = [row.restrictionSiteId];
          this.ruleForm.deliveryDistance = row.dispatchDistance;
          this.ruleForm.smsTemplateId = row.smsTemplateId;
          this.ruleForm.smsPushNode = row.smsPushNode;
          this.ruleForm.pushMethod = row.pushMethod;
          this.ruleForm.userIds = JSON.parse(row.account);
          if (row.isElectronicFence) {
            this.ruleForm.TypeList.push(1);
          }
          if (row.isCaptcha) {
            this.ruleForm.TypeList.push(2);
          }
          if (row.isDeliverImg) {
            this.ruleForm.TypeList.push(3);
          }
          this.limitAccountList = [];
          if (this.ruleForm.siteIds.length > 0) {
            this.getSiteAccounet();
          }
          this.addDialog = true;
        }
      });
    },
    // 点击确定
    clickSubmit(val) {
      if (val === 1) {
        this.addDialog = false;
        this.isAllSelected2 = false;
      } else {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            const data = {
              stateSites: [],
              status: 1, // 状态
              deliveryDistance: Number(this.ruleForm.deliveryDistance), // 派送距离
              smsTemplateId: this.ruleForm.smsTemplateId, // 短信模板id
              smsPushNode: this.ruleForm.smsPushNode, // 推送节点(1创建订单,2网点入库扫描,3任务分配4签收扫描5问题件)
              pushMethod: this.ruleForm.pushMethod,
              isElectronicFence: this.ruleForm.TypeList.includes(1) ? 1 : 0, // 强制电子围栏0：否 1：是
              isCaptcha: this.ruleForm.TypeList.includes(2) ? 1 : 0, // 强制验证码0：否 1：是
              isDeliverImg: this.ruleForm.TypeList.includes(3) ? 1 : 0,
              userIds: this.ruleForm.userIds
            };
            const stateSites = this.ruleForm.siteIds
              .map((id) => this.allSiteList.find((item) => item.id === id))
              .filter((item) => item);
            data.stateSites = stateSites.map((arr) => {
              return {
                id: arr.id,
                state: arr.state
              };
            });
            // console.log('过滤', data);
            // return;
            if (this.editId) {
              data.id = this.editId;
              restrictionupdate(data).then(res => {
                if (res.code===200) {
                  this.addDialog = false;
                  this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
                  this.getSearch();
                }
              });
            } else {
              restrictionadd(data).then(res => {
                if (res.code===200) {
                  this.addDialog = false;
                  this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
                  this.getSearch();
                }
              });
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    // 启用停用
    updateStatus(row) {
      const data = {
        id: row.id,
        status: row.status === 1 ? 0 : 1,
        smsTemplateId: row.smsTemplateId
      };
      this.$confirm(this.$t('customerManagements.tip103'), this.$t('collectionCenter.tips'), {
        confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
        cancelButtonText: this.$t('collectionCenter.cancel'), // '取消'
        type: 'warning'
      })
        .then(async() => {
          restrictionisOpened(data).then((res) => {
            if (res.code === 200) {
              this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
              this.getSearch();
            }
          });
        })
        .catch(() => {
          console.log('取消关闭');
        });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    handleAddCusTimeDialog() {
      this.ruleForm = {
        siteIds: [],
        smsTemplateId: '',
        smsPushNode: '',
        pushMethod: '',
        deliveryDistance: '',
        restrictionState: '',
        TypeList: [],
        userIds: []
      };
      this.$refs.ruleForm.resetFields();
    },
    // 州
    getAddress() {
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.code===200) {
          this.StateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    },
    // 获取所有网点
    getAllSite() {
      getAllsite().then(res => {
        if (res.code===200) {
          this.siteList = res.data;
        }
      });
    },
    handleSiteIds(val) {
      console.log(val);
      // this.$set(this.ruleForm, 'siteIds', [val]);
      this.ruleForm.siteIds = val;
      this.ruleForm.userIds = [];
      this.limitAccountList = [];
      if (this.ruleForm.siteIds.length > 0) {
        this.getSiteAccounet();
      }
    },
    // 网点下的账号
    getSiteAccounet(val = '') {
      const data = {
        siteIds: this.ruleForm.siteIds,
        fuzzyFind: val
      };
      if (this.ruleForm.siteIds?.length > 0) {
        getUserList(data).then((res) => {
          if (res.status === 'OK') {
            this.limitAccountList = res.data;
          }
        });
      }
    },
    // 获取全部账号
    getAllUserList(val = '') {
      const data = {
        operateUserName: val
      };
      signRescissiongetPmUserList(data).then(res => {
        if (res.code === 200) {
          console.log(res.data, '===res');
          this.accountList = res.data;
        }
      });
    },
    // 获取启用短信模板
    getOpenTemplateList() {
      getOpenTemplate().then(res => {
        if (res.code===200) {
          this.smsTemplateList = res.data;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 15px;
  padding-bottom: 15px;
}

::v-deep {
  .drawerClass header {
    border-bottom: 1px solid #aaa !important;
    margin-bottom: 0 !important;
    padding: 20px 20px 20px !important;
  }
}

.selectLabel ::v-deep .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.selectLabel ::v-deep .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep .el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.textOperator {
  color: #409eff;
  cursor: pointer;
  font-size: 22px;
}

.textOperator1 {
  color: #f56c6c;
  cursor: pointer;
  font-size: 22px;
}

.messgeList {
  height: 450px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.downLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7fa;
  cursor: pointer;
  padding: 5px;
}

.downModule {
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 15px;
}

::v-deep .el-table__fixed-right {
  height: 100% !important;
}
</style>
