<template>
  <div>
    <!-- 提示弹框   -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('collectionCenter.cancelOrder')"
      :visible.sync="tipDialog"
      width="500px"
    >
      <div>
        <div style="font-size: 16px; line-height: 35px;color: black;">
          <div>{{ $t('orderCenterCont.selected') }}: {{ selectionList.length }}</div>
          <div>{{ $t('orderCenterCont.upLoad3') }}</div>
        </div>
        <div>
          <el-upload
            ref="uploadRef"
            :before-upload="beforeAvatarUpload"
            :file-list="imageList"
            :http-request="upLoad"
            :limit="3"
            :on-change="handleChangeFile"
            :on-remove="handleDeleteImgRemove"
            action
            class="upload-demo"
            multiple
          >
            <el-button icon="el-icon-upload" style="font-weight: bold;" type="text">{{
              $t('GlobalSetObj.UploadPictures')
            }}
            </el-button>
          </el-upload>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="clickConfirm">
          {{ $t('GlobalSetObj.determine') }}
        </el-button>
        <el-button size="small" @click="clearOrderSelect">{{ $t('GlobalSetObj.close') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('collectionCenter.cancelOrder')"
      :visible.sync="clearOrder.showCancelOrder"
      width="500px"
      @close="clearOrderOver"
    >
      <template slot="title">
        <div style="font-weight: bold;font-size: 20px;">{{ $t('GlobalSetObj.TheOrderBeingCancelled') }}</div>
      </template>
      <div style="padding: 20px;">
        <div>
          <div style="width: 100%;background:#DADADA;border-radius: 20px;height: 10px;">
            <div
              :style="{ width: widthProgress + '%' }"
              style="background:#42B983;height: 100%;border-radius: 20px;"
            />
          </div>
          <div style="text-align: right;color: #13ADF0;font-size: 13px;margin-top: 10px;">
            {{ widthProgress < 100 ? $t('newOrder.processing') : $t('newOrder.ProcessingComplete') }}
          </div>
        </div>
        <div v-if="widthProgress === 100">
          <div style="margin-bottom: 20px;">
            <span>
              {{ $t('operationCenter.succeed') }}：<span
                style="color:#42B983;margin-right: 30px;"
              >{{ clearOrder.clearSuccess }}</span>
              {{ $t('operationCenter.fail') }}：<span style="color:red;">{{ clearOrder.falharOrder }}</span>
            </span>
          </div>
          <div
            v-if="clearOrder.falharOrder > 0"
            style="border: 1px solid #ccc;padding: 10px;max-height: 170px;overflow-y: auto;"
          >
            <div v-for="(item, key, ind) in clearOrder.errorList" :key="ind" style="margin-bottom: 10px;">{{
              key
            }}：{{ item }}
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="clearOrder.showCancelOrder = false">{{ $t('GlobalSetObj.close') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiBatchAbolish } from '@/api/logisticsOrders';
import { uploadOos } from '@/utils/ali_oos_util';

export default {
  name: 'ClearOrder',
  props: {
    customerList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    selectionList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      widthProgress: 0,
      clearOrder: {
        selectCustomers: false,
        showCancelOrder: false,
        clearSuccess: 0,
        falharOrder: 0,
        customId: '',
        code: '',
        customName: '',
        errorList: []
      },
      tipDialog: false,
      imageList: [],
      noneUploadImg: true,
      cancelOrderPictureList: []
    };
  },
  watch: {
    'clearOrder.customId'(val) {
      this.customerList.map(item => {
        if (item.id === val) {
          this.clearOrder.customName = item.username;
          this.clearOrder.code = item.authorizationCode;
        }
      });
    }
  },
  methods: {
    // 上传图片之前
    beforeAvatarUpload(file) {
      console.log(file, 'filefile');
      // 获取文件扩展名
      const extension = file.name.split('.').pop().toLowerCase();
      // 支持的图片格式
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
      // 检查文件格式是否为图片格式
      if (!allowedExtensions.includes(extension)) {
        this.$message.error(this.$t('GlobalSetObj.formatFile'));
      } else {
        // 图片格式正确，可以继续上传的逻辑
        console.log(file, 'filefile');
        // 这里可以添加上传文件的代码
      }
      return allowedExtensions.includes(extension);
    },
    // 上传图片
    async upLoad(option) {
      const files = option.file;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      try {
        const res = await uploadOos(files, 'cancelOrderImage');
        console.log(res, 'resresres');
        // if (res.code === 200) {
        this.cancelOrderPictureList.push(res.url);
        // } else {
        //   // 上传图片失败
        //   this.$message.error(this.$t('supplierManagement.failUpload'));
        // }
      } catch (e) {
        // 上传图片失败
        this.$message.error(this.$t('GlobalSetObj.failUpload'));
      } finally {
        loading.close();
      }
    },
    // 改变图片选择
    handleChangeFile(file, fileList) {
      this.noneUploadImg = fileList.length <= 3;
    },
    // 移除后的回调
    handleDeleteImgRemove(file, fileList) {
      if (file.name) {
        const fileName = file.name.slice(0, -6);
        const findIndex = this.cancelOrderPictureList.findIndex(url => url.includes(fileName));
        this.cancelOrderPictureList.splice(findIndex, 1);
      } else {
        const findIndex = this.cancelOrderPictureList.findIndex(url => url.includes(file.url));
        this.cancelOrderPictureList.splice(findIndex, 1);
      }
      this.noneUploadImg = fileList.length <= 3;
      console.log(this.cancelOrderPictureList, 'pictureListpictureList');
    },
    // 取消完成关闭弹窗
    clearOrderOver() {
      this.clearOrder = {
        selectCustomers: false,
        showCancelOrder: false,
        clearSuccess: 0,
        falharOrder: 0,
        customId: '',
        code: '',
        customName: '',
        errorList: []
      };
    },
    // 取消订单选择客户
    cancelOrder() {
      // this.clearOrder.showCancelOrder = true;
      this.confirmClear();
    },
    clearOrderSelect() {
      this.tipDialog = false;
      this.imageList = [];
      this.cancelOrderPictureList = [];
    },
    // 点击确定
    clickConfirm() {
      const order = this.selectionList.map(item => {
        return item.waybillNumber;
      });
      const param = {
        orderWaybillList: order,
        cancelOrderPictureList: this.cancelOrderPictureList
      };
      apiBatchAbolish(param).then(res => {
        if (res.code===200) {
          this.clearOrderSelect();
          this.clearOrder.showCancelOrder = true;
          this.widthProgress = 0;
          const time = setInterval(() => {
            this.widthProgress++;
            if (this.widthProgress < 100) {
              this.widthProgress++;
              if (this.widthProgress === 100) {
                clearInterval(time);
              }
            }
          }, 50);
          this.clearOrder.clearSuccess = res.data.successCount;
          this.clearOrder.falharOrder = res.data.failuresCount;
          this.clearOrder.errorList = res.data.failuresMap;
          this.$emit('getSearchFun');
        }
      });
    },
    // 确定取消订单
    confirmClear() {
      this.tipDialog = true;
    }
  }
};
</script>

<style scoped>
>>> .el-dialog__body {
  padding: 0 20px;
}
</style>
