<template>
  <div class="app-container">
    <el-form label-position="top">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item :label="$t('routes.班次编号')">
            <ScheduleNumberSelect v-model="queryForm.params.scheduleNumber" :list="scheduleNumberList" is-all />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('routes.发车时间')">
            <el-select
              v-model="queryForm.params.departureTime" filterable clearable
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 100%;"
            >

              <el-option
                v-for="item in departureTimeList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('routes.下一站流向')">
            <el-select
              v-model="queryForm.params.nextSiteId" filterable clearable
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 100%;"
            >
              <el-option
                v-for="(item, ind) in nextSiteIdList"
                :key="ind"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

<!--          <el-form-item :label="$t('routes.下一站流向')">-->
<!--            <NextSiteIdListSelect v-model="queryForm.params.nextSiteId" :list="nextSiteIdList" is-all />-->
<!--          </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>

    <div>
      <el-button type="primary" size="small" @click="asyncGetList">{{ $t('operationCenter.Procurar') }}</el-button>
      <el-button size="small" @click="reset">{{ $t('GlobalSetObj.reset') }}</el-button>
    </div>

    <el-divider />

    <el-button
      type="primary" size="small" style="margin-bottom: 10px"
      @click="open"
    >{{ $t('routes.新增流向') }}</el-button>

    <el-table
      :data="tableData"
      border
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" type="index"
        width="70"
      />
      <el-table-column
        prop="scheduleNumber"
        align="center"
        :label="$t('routes.班次编号')"
        width="200"
      />
      <el-table-column
        prop="departureTime"
        align="center"
        :label="$t('routes.发车时间')"
        width="120"
      />
      <el-table-column
        prop="nextSiteNameStr"
        align="center"
        :label="$t('routes.下一站流向')"
      />
      <el-table-column
        :label="$t('GlobalSetObj.status')"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <!-- 1:启用 0:停用 -->
          <el-tag v-if="scope.row.status === 1" type="primary">{{ $t('GlobalSetObj.enable') }}</el-tag>
          <el-tag v-else-if="scope.row.status === 0" type="danger">{{ $t('GlobalSetObj.stop') }}</el-tag>
          <span v-else />
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('GlobalSetObj.operate')"
        align="center"
        fixed="right"
        width="200"
      >
        <template slot-scope="scope">

          <el-button size="mini" @click="edit(scope.row)">{{ $t('GlobalSetObj.edit') }}</el-button>

          <!-- 启用 -->
          <el-button
            v-if="scope.row.status === 0" type="primary" size="mini"
            @click="onEnable(scope.row.id, 1)"
          >{{ $t('GlobalSetObj.enable') }}</el-button>

          <!-- 停用 -->
          <el-button
            v-if="scope.row.status === 1" type="danger" size="mini"
            @click="onEnable(scope.row.id, 0)"
          >{{ $t('GlobalSetObj.stop') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

    <!-- 弹窗 -->
    <el-dialog
      :title="$t('routes.新增干支线流向')"
      :visible.sync="addRow.isBool"
      width="500px"
    >
      <AddCmp
        :schedule-number-list="scheduleNumberList"
        :next-site-id-list="nextSiteIdList"
        :row="addRow.row"
        :index="addRow.index"
        @close="onClose"
      />
    </el-dialog>
  </div>
</template>

<script>
import { apiDepartureScheduleGetScheduleNumber, apiDepartureScheduleGetSiteAndTransfer, apiDepartureScheduleGetDepartureTime, apiBranchLineDirectList, apiBranchLineDirectModifyStatus } from '@/api/routerPlan.js';
import ScheduleNumberSelect from './cmp/ScheduleNumberSelect.vue';
// import NextSiteIdListSelect from './cmp/NextSiteIdListSelect.vue';
import { DIALOG_TEXT } from '@/views/route/shiftManagement/utils.js';
import { ADD_ROW } from './utils.js';
import { cloneDeep } from 'lodash-es';

const PARAMS = {
  scheduleNumber: '', // 班次
  departureTime: '', // 发车时间
  nextSiteId: '' // 下一站流向
};

export default {
  name: '',

  components: {
    AddCmp: () => import('./cmp/AddCmp.vue'),
    ScheduleNumberSelect
    // NextSiteIdListSelect
  },

  data() {
    return {
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: cloneDeep(PARAMS)
      },
      total: 0,
      tableData: [],

      scheduleNumberList: [], // 班次编号
      nextSiteIdList: [], // 所有启用的网点和转运中心
      departureTimeList: [], // 发车时间

      addRow: {
        index: 0,
        isBool: false,
        title: DIALOG_TEXT.add,
        row: cloneDeep(ADD_ROW)
      }

    };
  },

  created() {
    this.asyncGetList();

    setTimeout(() => {
      this.asyncApiDepartureScheduleGetScheduleNumber();
      this.asyncApiDepartureScheduleGetSiteAndTransfer();
      this.asyncApiDepartureScheduleGetDepartureTime();
    }, 500);
  },

  methods: {
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.asyncGetList();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.asyncGetList();
    },

    reset() {
      this.queryForm.pageNumber = 1;
      this.queryForm.params = cloneDeep(PARAMS);
      this.asyncGetList();
    },

    getQuery() {
      const { pageSize, pageNumber, params } = this.queryForm;
      const { scheduleNumber, departureTime, nextSiteId } = params;
      const obj = {
        pageSize,
        pageNumber,
        params: {
          scheduleNumber,
          departureTime,
          nextSiteId
        }
      };

      return obj;
    },

    asyncGetList() {
      const row = this.getQuery();
      apiBranchLineDirectList(row).then(res => {
        // console.log(res);

        const { total, records } = res.data;

        const arr = (records || []).map(item => {
          const { id, scheduleNumber, departureTime, nextSite, status, statusName } = item;
          const nextSiteIdList = nextSite.map(item => {
            return item.siteId;
          });

          const nextSiteNameStr = nextSite.map(item => {
            return item.siteName;
          }).join(' / ');

          const obj = {
            id, scheduleNumber, departureTime, nextSite, nextSiteIdList, nextSiteNameStr, status, statusName
          };

          return obj;
        });
        this.tableData = Object.freeze(arr);
        this.total = total;
      }).catch(err => {
        console.error(err);
      });
    },

    open() {
      this.addRow.index += 1;
      this.addRow.isBool = true;
      this.addRow.title = DIALOG_TEXT.add;
      this.addRow.row = cloneDeep(ADD_ROW);
    },

    edit(row) {
      this.addRow.index += 1;
      this.addRow.isBool = true;
      this.addRow.title = DIALOG_TEXT.edit;
      // this.addRow.row = cloneDeep(row);
      console.log(row);

      const { id, scheduleNumber, departureTime, nextSiteIdList } = row;

      this.addRow.row.id = id;
      this.addRow.row.scheduleNumber = scheduleNumber;
      this.addRow.row.departureTime = departureTime;
      this.addRow.row.nextSiteIdList = nextSiteIdList;
    },

    onClose(isBool) {
      this.addRow.isBool = false;
      if (isBool) {
        this.queryForm.pageNumber = 1;
        this.asyncGetList();
        this.asyncApiDepartureScheduleGetDepartureTime();
      }
    },

    // 获取所有班次
    asyncApiDepartureScheduleGetScheduleNumber() {
      apiDepartureScheduleGetScheduleNumber().then(res => {
        console.log(res);
        this.scheduleNumberList = Object.freeze(res.data.map(item => {
          return {
            label: item,
            value: item
          };
        }));
      }).catch(err => {
        console.error(err);
      });
    },

    // 获取所有网点和转运中心
    asyncApiDepartureScheduleGetSiteAndTransfer() {
      apiDepartureScheduleGetSiteAndTransfer().then(res => {
        console.log(res);
        this.nextSiteIdList = Object.freeze(res.data.map(item => {
          return {
            label: item.siteName,
            value: item.siteId

          };
        }));
      }).catch(err => {
        console.error(err);
      });
    },

    // 下拉时间
    asyncApiDepartureScheduleGetDepartureTime() {
      apiDepartureScheduleGetDepartureTime().then(res => {
        console.log(res);
        this.departureTimeList = res.data;
      }).catch(err => {
        console.error(err);
      });
    },

    // 启用停用
    onEnable(id, status) {
      apiBranchLineDirectModifyStatus({ id, status }).then(rss => {
        this.$message.success(this.$t('GlobalSetObj.submittedSuccessfully'));
        this.asyncGetList();
      }).catch(err => {
        console.error(err);
      });
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
