var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dailyBox" }, [
    _c("div", { staticClass: "leftTopClass" }, [
      _vm._v(_vm._s(_vm.data.createTime)),
    ]),
    _c("div", { staticClass: "top-title" }, [
      _c("img", { attrs: { src: "/static/daily/titleBg.png", alt: "" } }),
      _c("span", [
        _vm._v(_vm._s(_vm.$t("collectionCenter.lanshouLargeScreen"))),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "searchConst",
        staticStyle: {
          padding: "0 20px",
          "z-index": "100",
          top: "-10px",
          position: "relative",
        },
      },
      [
        _c(
          "el-form",
          {
            attrs: {
              size: "small",
              "label-position": "left",
              "label-width": "auto",
            },
          },
          [
            _c(
              "el-form-item",
              {
                staticStyle: {
                  display: "inline-block",
                  "margin-right": "20px",
                },
                attrs: { label: _vm.$t("collectionCenter.lanshouT") },
              },
              [
                _c("el-date-picker", {
                  staticStyle: { width: "160px" },
                  attrs: {
                    clearable: false,
                    type: "date",
                    "value-format": "yyyy-MM-dd",
                    placeholder: _vm.$t("collectionCenter.pleaseChoose"),
                  },
                  model: {
                    value: _vm.timeValue,
                    callback: function ($$v) {
                      _vm.timeValue = $$v
                    },
                    expression: "timeValue",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: {
                  display: "inline-block",
                  "margin-right": "20px",
                },
                attrs: { label: _vm.$t("navbar.businessType") },
              },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "160px" },
                    model: {
                      value: _vm.queryForm.businessType,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "businessType", $$v)
                      },
                      expression: "queryForm.businessType",
                    },
                  },
                  [
                    _c("el-option", {
                      key: "0",
                      attrs: {
                        label: _vm.$t("customerManagements.all"),
                        value: 0,
                      },
                    }),
                    _c("el-option", {
                      key: "1",
                      attrs: {
                        label: _vm.$t("collectionCenter.lanshou"),
                        value: 1,
                      },
                    }),
                    _c("el-option", {
                      key: "2",
                      attrs: {
                        label: _vm.$t("collectionCenter.expressDelivery"),
                        value: 2,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { display: "inline-block" },
                attrs: {
                  label: _vm.$t("collectionCenter.CustomerName"),
                  placeholder: _vm.$t("collectionCenter.pleaseChoose"),
                },
              },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "180px" },
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.queryForm.customerId,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "customerId", $$v)
                      },
                      expression: "queryForm.customerId",
                    },
                  },
                  _vm._l(_vm.customerArr, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.username, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "20px" },
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.searchClick },
              },
              [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary", plain: "" },
                on: { click: _vm.resetClick },
              },
              [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "screen" }, [
      _c("div", { staticClass: "screenL" }, [
        _c("span", { staticClass: "angle lt" }),
        _c("span", { staticClass: "angle rt" }),
        _c("span", { staticClass: "angle lb" }),
        _c("span", { staticClass: "angle rb" }),
        _c("div", { staticClass: "screenLC" }, [
          _c("div", [
            _c("span", { staticClass: "screen-title" }, [
              _vm._v(_vm._s(_vm.$t("collectionCenter.merchantData"))),
            ]),
            _c("div", { staticClass: "screenLC-font" }, [
              _vm._v(_vm._s(_vm.$t("collectionCenter.merchantsTotalNumber"))),
              _c("span", { staticClass: "screenLC-font-span red" }, [
                _vm._v(_vm._s(_vm.data.totalMerchantNum || 0)),
              ]),
            ]),
            _c("div", { staticClass: "screenLC-font" }, [
              _vm._v(_vm._s(_vm.$t("collectionCenter.completeMerchant"))),
              _c("span", { staticClass: "screenLC-font-span yellow" }, [
                _vm._v(_vm._s(_vm.data.finishedMerchantNum || 0)),
              ]),
            ]),
            _c("div", { staticClass: "screenLC-font" }, [
              _vm._v(_vm._s(_vm.$t("collectionCenter.residualMerchant"))),
              _c("span", { staticClass: "screenLC-font-span green" }, [
                _vm._v(_vm._s(_vm.data.remainingMerchantNum || 0)),
              ]),
              _vm.merchantExportBtn
                ? _c(
                    "span",
                    {
                      staticClass: "export-green",
                      on: { click: _vm.residualMerchantExport },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                  )
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "round" }, [
            _c("span", { staticClass: "round-percent" }, [
              _vm._v(_vm._s(_vm.data.completion || 0)),
            ]),
            _c("span", { staticClass: "round-completion" }, [
              _vm._v(_vm._s(_vm.$t("collectionCenter.degreeCompletion"))),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "screenR" }, [
        _c("span", { staticClass: "angle lt" }),
        _c("span", { staticClass: "angle rt" }),
        _c("span", { staticClass: "angle lb" }),
        _c("span", { staticClass: "angle rb" }),
        _c("div", { staticClass: "screenRC" }, [
          _c("span", { staticClass: "screen-title" }, [
            _vm._v(_vm._s(_vm.$t("collectionCenter.singleVolumeData"))),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "20px",
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center",
                "flex-wrap": "wrap",
              },
            },
            [
              _c("div", { staticClass: "screenRC-content" }, [
                _c("span", { staticClass: "screenRC-font sum red" }, [
                  _vm._v(_vm._s(_vm.data.totalOrderNum || 0)),
                ]),
                _c("span", { staticClass: "screenRC-font description" }, [
                  _vm._v(_vm._s(_vm.$t("collectionCenter.totalUnit"))),
                ]),
                _c("span", {
                  staticStyle: {
                    height: "80px",
                    width: "100%",
                    background:
                      'url("/static/daily/redRing.png") no-repeat 100% 50%',
                    "background-size": "cover",
                  },
                }),
              ]),
              _c("div", { staticClass: "screenRC-content" }, [
                _c("span", { staticClass: "screenRC-font sum blue" }, [
                  _vm._v(_vm._s(_vm.data.finishedOrderNum || 0)),
                ]),
                _c("span", { staticClass: "screenRC-font description" }, [
                  _vm._v(_vm._s(_vm.$t("collectionCenter.completionUnit"))),
                ]),
                _c("span", {
                  staticStyle: {
                    height: "80px",
                    width: "100%",
                    background:
                      'url("/static/daily/blueRing.png") no-repeat 100% 50%',
                    "background-size": "cover",
                  },
                }),
              ]),
              _c("div", { staticClass: "screenRC-content" }, [
                _c("span", { staticClass: "screenRC-font sum yellow" }, [
                  _vm._v(_vm._s(_vm.data.remainingOrderNum || 0)),
                ]),
                _c("span", { staticClass: "screenRC-font description" }, [
                  _vm._v(_vm._s(_vm.$t("collectionCenter.residualUnit"))),
                ]),
                _c("span", {
                  staticStyle: {
                    height: "80px",
                    width: "100%",
                    background:
                      'url("/static/daily/ylwRing.png") no-repeat 100% 50%',
                    "background-size": "cover",
                  },
                }),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "screenB" }, [
      _c("span", { staticClass: "angle lt" }),
      _c("span", { staticClass: "angle rt" }),
      _c("span", { staticClass: "angle lb" }),
      _c("span", { staticClass: "angle rb" }),
      _c("div", { staticClass: "screenB-box" }, [
        _c("div", { staticClass: "screenB-content" }, [
          _c("span", { staticClass: "screenB-img green" }),
          _c("span", { staticClass: "screenB-font sum green" }, [
            _vm._v(_vm._s(_vm.data.trackPushNum || 0)),
          ]),
          _c("span", { staticClass: "screenB-font description" }, [
            _vm._v(_vm._s(_vm.$t("collectionCenter.PushTrack"))),
          ]),
        ]),
        _c("div", { staticClass: "screenB-content" }, [
          _c("span", { staticClass: "screenB-img orange" }),
          _c("span", { staticClass: "screenB-font sum orange" }, [
            _vm._v(_vm._s(_vm.data.closedOrderNum || 0)),
          ]),
          _c("span", { staticClass: "screenB-font description" }, [
            _vm._v(_vm._s(_vm.$t("collectionCenter.closedLoopUnit"))),
          ]),
        ]),
        _c("div", { staticClass: "screenB-content" }, [
          _c("span", { staticClass: "screenB-img purple" }),
          _c("span", { staticClass: "screenB-font sum purple" }, [
            _vm._v(_vm._s(_vm.data.abnormalOrderNum || 0)),
          ]),
          _c("span", { staticClass: "screenB-font description" }, [
            _vm._v(_vm._s(_vm.$t("collectionCenter.AbnormalUnivariate")) + " "),
            _vm.orderExportBtn
              ? _c(
                  "span",
                  {
                    staticClass: "export-white",
                    on: { click: _vm.exceptionNumExport },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }