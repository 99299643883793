<template>
  <div class="app-container">
    <div ref="searchFormCon" v-resize="handleResize" class="searchContainer">
      <div>
        <div style="float: left">
          <!--批量添加费用-->
          <el-popover
            placement="bottom"
            trigger="hover"
          >
            <div>
              <div style="margin-bottom: 10px;">
                <!--批量添加费用-->
                <el-button
                  size="small"
                  type="primary"
                  @click="operationBtn(2)"
                >{{ $t('receivablePayable.AddChargesInBulk') }}
                </el-button>
              </div>
              <!--导入添加费用-->
              <div style="margin-bottom: 10px;">
                <el-button
                  size="small"
                  type="primary"
                  @click="importFees(1)"
                >{{ $t('receivablePayable.ImportAddCharges') }}
                </el-button>
              </div>
              <!--导入修改费用-->
              <div style="margin-bottom: 10px;">
                <el-button
                  size="small"
                  type="primary"
                  @click="importFees(2)"
                >{{ $t('receivablePayable.ImportModifyExpenses') }}
                </el-button>
              </div>
              <!--快录-->
              <div style="margin-bottom: 10px;">
                <el-button
                  size="small"
                  type="primary"
                  @click="quickRecord(1)"
                >{{ $t('receivablePayable.QuickRecord') }}
                </el-button>
              </div>
              <!--批量导入快录-->
              <div>
                <el-button
                  size="small"
                  type="primary"
                  @click="quickRecord(2)"
                >{{ $t('receivablePayable.BatchImportClips') }}
                </el-button>
              </div>
            </div>
            <el-button
              slot="reference"
              v-permit:remove="'btn:receivableManagementCompany:addCharge'"
              size="small"
              style="margin-right: 10px;"
            >
              {{ $t('receivablePayable.AddModifyExpenses') }}
            </el-button>
          </el-popover>
          <!--生成应收-->
          <el-button
            v-permit:remove="'btn:receivableManagementCompany:generate'"
            size="small"
            type="primary"
            @click="operationBtn(3)"
          >{{ $t('receivablePayable.GenerateReceivables') }}
          </el-button>
          <!--备注-->
          <el-button
            v-permit:remove="'btn:receivableManagementCompany:remarks'"
            size="small"
            type="primary"
            @click="operationBtn(5)"
          >{{ $t('receivablePayable.remark') }}
          </el-button>
          <!--导出-->
          <el-button
            v-permit:remove="'btn:receivableManagementCompany:export'"
            size="small"
            type="primary"
            @click="operationBtn(6)"
          >{{ $t('receivablePayable.Export') }}
          </el-button>
          <!--重新计算价格-->
          <el-popover
            placement="bottom"
            trigger="hover"
          >
            <el-button
              slot="reference"
              v-permit:remove="'btn:receivableManagementCompany:RecalculateThePrice'"
              size="small"
              style="margin-left: 10px;"
            >{{ $t('receivablePayable.RecalculateThePrice') }}
            </el-button>
            <div>
              <div>
                <!--重新计算价格-->
                <el-button
                  size="small"
                  type="primary"
                  @click="operationBtn(7, 0)"
                >{{ $t('receivablePayable.RecalculateThePrice') + '(' + $t('receivablePayable.Maximum100000') + ')' }}
                </el-button>
              </div>
              <div style="margin-top: 10px;">
                <!--导入计算价格-->
                <el-button
                  size="small"
                  type="primary"
                  @click="getImportTemplate(2)"
                >{{ $t('receivablePayable.ImportCalculatePrices') }}
                </el-button>
              </div>
              <div style="margin-top: 10px;">
                <!--导入重量计算价格-->
                <el-button
                  size="small"
                  type="primary"
                  @click="getImportTemplate(3)"
                >{{ $t('receivablePayable.ImportWeightsCalculatePrices') }}
                </el-button>
              </div>
              <div style="margin-top: 10px;">
                <!--导入邮编计算-->
                <el-button
                  size="small"
                  type="primary"
                  @click="postcodeCalculation(5)"
                >{{ $t('receivablePayable.ImportPostcodeCalculation') }}
                </el-button>
              </div>
            </div>
          </el-popover>
          <!--删除-->
          <el-button
            v-permit:remove="'btn:receivableManagementCompany:deleteRow'"
            size="small"
            style="margin-left: 10px;"
            type="danger"
            @click="deleteRow()"
          >{{ $t('receivablePayable.Delete') }}
          </el-button>
          <!--自定义栏目-->
          <el-button
            size="small"
            style="margin-left: 10px;"
            type="primary"
            @click="customColumn()"
          >{{ $t('orderCenterCont.CustomizeListColumn') }}
          </el-button>
        </div>
        <div style="float: right">
          <!--查询-->
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="searchResources()"
          >{{ $t('receivablePayable.Search') }}
          </el-button>
        </div>
        <div style="clear:both;" />
      </div>
      <el-divider />
    </div>
    <div>
      <el-table
        :key="tableKey"
        ref="elTable"
        :data="tableData"
        :height="tableHeightRow.height"
        :row-key="getRowKey"
        border
        class="table-height"
        @header-dragend="headerWidthChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          :reserve-selection="true"
          align="center"
          type="selection"
          width="55"
        />
        <el-table-column :label="$t('operationCenter.ind')" align="center" width="60px">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in currentColumns"
          :key="index"
          :align="item.fixed"
          :label="item.label"
          :prop="item.prop"
          :show-overflow-tooltip="true"
          :width="item.width"
        >
          <template slot-scope="scope">
            <div
              v-if="item.prop === 'waybillNumber'"
              style="color: #409eff;cursor: pointer;"
              @click="editFee(scope.row)"
            >{{ scope.row[item.prop] }}
            </div>
            <div
              v-else-if="item.prop === 'businessType'"
            >
              <span v-if="scope.row[item.prop] === 0">{{ `${$t('basicData.CourierSystemBusiness')}` }}</span><!--快递订单-->
              <span v-if="scope.row[item.prop] === 1">{{ $t('basicData.CollectionSystemBusiness') }}</span> <!--揽收订单-->
              <span v-if="scope.row[item.prop] === 2">{{ $t('basicData.DeliverOrder') }}</span> <!--派件订单-->
              <span v-if="scope.row[item.prop] === 7">{{
                `${$t('basicData.CourierSystemBusiness')}(${$t('basicData.lastMilePickup')})`
              }}</span> <!--快递订单(尾程揽收)-->
            </div>
            <div v-else-if="item.prop === 'orderPackageType'">
              <span v-if="scope.row[item.prop] === 1">{{ $t('placeOrder.zhifa') }}</span> <!--直发-->
              <span v-if="scope.row[item.prop] === 2">{{ $t('placeOrder.CoPacking') }}</span> <!--合包-->
              <span v-if="scope.row[item.prop] === 3">{{ $t('basicData.other') }}</span> <!--其他-->
            </div>
            <div v-else-if="item.prop === 'collectionCategory'">
              <span v-if="scope.row[item.prop] === 0">{{ $t('basicData.OrdinaryPickUp') }}</span>
              <span v-if="scope.row[item.prop] === 1">{{ $t('basicData.StockingAndPickup') }}</span>
            </div>
            <div v-else-if="item.prop === 'feeSource'">
              <!--系统生成-->
              <span v-if="scope.row[item.prop] === 0">{{ $t('receivablePayable.SystemGenerated') }}</span>
              <!--快录生成-->
              <span v-else-if="scope.row[item.prop] === 1">{{ $t('receivablePayable.QuickRecordGeneration') }}</span>
              <!--其他-->
              <span v-else>{{ $t('basicData.other') }}</span>
            </div>
            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, ind) in getCurrentColumns"
          :key="ind + 100"
          :align="item.fixed"
          :label="item.label"
          :prop="item.prop"
          :show-overflow-tooltip="true"
          :width="item.width"
        >
          <template slot-scope="scope">
            <div>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
        <!--备注-->
        <el-table-column
          :label="$t('basicData.remark')"
          :show-overflow-tooltip="true"
          align="left"
          prop="remark"
          width="200"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.remark }}</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="getARGenerationTableData"
          @size-change="getARGenerationTableData"
        />
      </div>
    </div>
    <!--    搜索-->
    <el-drawer
      :append-to-body="true"
      :title="$t('receivablePayable.Search')"
      :visible.sync="showSearch"
      custom-class="drawerClass"
      direction="rtl"
      size="50%"
    >
      <div>
        <search-form
          :key="showSearchKey"
          :is-read-only="false"
          :query-form="queryForm"
          :search-select-arr="searchSelectArr"
          @search="searchFun"
        />
      </div>
    </el-drawer>
    <!--  添加备注  -->
    <el-dialog
      :append-to-body="true"
      :title="$t('receivablePayable.AddANote')"
      :visible.sync="showAddNotes"
      width="50%"
      @close="remarks = ''"
    >
      <div>
        <el-input v-model="remarks" :rows="5" type="textarea" />
      </div>
      <span slot="footer" class="dialog-footer">
        <!--取 消-->
        <el-button @click="saveRemarks(1)">{{ $t('basicData.Cancel') }}</el-button>
        <!--确 定-->
        <el-button type="primary" @click="saveRemarks(2)">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
    <!--操作日志-->
    <el-dialog
      :append-to-body="true"
      :title="$t('receivablePayable.OperationalLogs')"
      :visible.sync="showJournal"
      width="80%"
    >
      <div>
        <operation-log :key="keyJournal" :check-data="checkData" />
      </div>
    </el-dialog>
    <!--批量添加费用-->
    <el-drawer
      :append-to-body="true"
      :title="addType === 1 ? $t('receivablePayable.AddChargesInBulk') : $t('receivablePayable.FeeDetails')"
      :visible.sync="showBatchAdd"
      custom-class="drawerClass"
      direction="ltr"
      size="80%"
    >
      <div>
        <batch-add-fee
          :key="batchAddKey"
          :add-type="addType"
          :fee-type-option="feeTypeOption"
          :query-fee-param="queryFeeParam"
          :query-form="queryForm"
          :select-data="selectData"
          @saveForm="saveForm"
        />
      </div>
    </el-drawer>
    <!--快录添加费用-->
    <el-drawer
      :append-to-body="true"
      :title="$t('receivablePayable.SnapshotFee')"
      :visible.sync="showAddSnapshotFee"
      custom-class="drawerClass"
      direction="ltr"
      size="80%"
    >
      <div>
        <add-snapshot-fee
          :key="snapshotFeeKey"
          :expense-sub-list="expenseSubList"
          :fee-type-option="feeTypeOption"
          :provider-list="providerList"
          :query-fee-param="queryFeeParam"
          :query-form="queryForm"
          :select-data="selectData"
          @saveFormSnapshot="saveFormSnapshot"
        />
      </div>
    </el-drawer>
    <!--应收生成提示-->
    <el-dialog
      :append-to-body="true"
      :title="$t('receivablePayable.tips')"
      :visible.sync="showGenerate"
      width="50%"
    >
      <div style="height: 40vh;overflow: hidden;">
        <div style="height: 100%;overflow: auto;">
          <!--对账批次生成成功！-->
          <div style="margin-bottom: 20px;">{{ $t('receivablePayable.ReconciliationBatchGenerationSuccessful') }}</div>
          <!--对账批次号：-->
          <div>{{ $t('receivablePayable.ReconciliationBatchNumber') }}
            <div v-for="(item, index) in batchNumber" :key="index" style="margin: 0 10px 5px 0;">
              <span>{{ item.replace('~', '\n') }}</span>
            </div>
          </div>
        </div>
        <textarea id="message5" />
      </div>
      <span slot="footer" class="dialog-footer">
        <!--取 消-->
        <el-button @click="copyBatchNumber(1)">{{ $t('basicData.Cancel') }}</el-button>
        <!--复制批次号-->
        <el-button type="primary" @click="copyBatchNumber(2)">{{
          $t('receivablePayable.CopyTheBatchNumber')
        }}</el-button>
      </span>
    </el-dialog>
    <!--重新计算价格-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('receivablePayable.RecalculateThePrice')"
      :visible.sync="recalculate"
      width="80%"
      @close="changePage(false)"
    >
      <div>
        <recalculatePrice
          :key="showManage1"
          :calculation-type="calculationType"
          :get-param="queryForm.params"
          :import-data="importData"
          :import-list="importList"
          :select-data="selectData"
          @changePage="changePage"
        />
      </div>
    </el-dialog>
    <!--删除费用-->
    <el-dialog
      :title="$t('receivablePayable.DeleteTheFee')"
      :visible.sync="showDeleteRow"
      width="500px"
    >
      <div>
        <!--注意：会删除所选费用类型的所有数据-->
        <div style="color: red;margin-bottom: 20px;">{{
          $t('receivablePayable.NoteAllDataSelectedChargeDeleted')
        }}
        </div>
        <div>
          <!--费用类型-->
          <span style="color: red">*</span>{{ $t('basicData.ExpenseType') }}：
          <el-select
            v-model="feeTypeName"
            :placeholder="$t('operationCenter.PleaseSelect')"
            clearable
            filterable
            style="width: 100%;"
          >
            <el-option
              v-for="item in feeTypeOption"
              :key="item.id"
              :label="item.displayName"
              :value="item.code"
            />
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--取 消-->
        <el-button @click="saveDeleteRow(1)">{{ $t('basicData.Cancel') }}</el-button>
        <!--确 定-->
        <el-button type="primary" @click="saveDeleteRow(2)">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
    <!--生成应收时间-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('receivablePayable.SelectGenerateAccrualTime')"
      :visible.sync="generateReceivables"
      width="500px"
      @close="TimeCollection = []"
    >
      <div>
        <div style="margin-bottom: 5px;">
          {{ $t('receivablePayable.SelectGenerateAccrualTime') }}：
        </div>
        <el-date-picker
          v-model="TimeCollection"
          :end-placeholder="$t('operationCenter.endDate')"
          :start-placeholder="$t('operationCenter.startDate')"
          range-separator="-"
          style="width: 100%"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </div>
      <div slot="footer" class="dialog-footer">
        <!--取 消-->
        <el-button @click="determineReceivables(1)">{{ $t('basicData.Cancel') }}</el-button>
        <!--确 定-->
        <el-button type="primary" @click="determineReceivables(2)">{{ $t('basicData.determine') }}</el-button>
      </div>
    </el-dialog>
    <!--打开导入计算价格模板弹窗-->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('receivablePayable.RecalculateThePrice')"
      :visible.sync="showImportTemplate"
      width="500px"
    >
      <div>
        <import-calculated-price
          :key="showImportTemplate"
          :calculation-type="calculationType"
          @importPrice="importPrice"
        />
      </div>
    </el-dialog>
    <!--导入添加、 修改费用-->
    <el-dialog
      :title="expensesType === 1 ? $t('receivablePayable.ImportAddCharges') : $t('receivablePayable.ImportModifyExpenses')"
      :visible.sync="showModification"
      width="400px"
    >
      <div v-if="expensesType === 1">
        <import-add-fee @changeFeeList="changeFeeList" />
      </div>
      <div v-else-if="expensesType === 2">
        <batch-change @changeFeeList="changeFeeList" />
      </div>
    </el-dialog>
    <!--导入快录-->
    <el-dialog
      :title="$t('receivablePayable.BatchImportClips')"
      :visible.sync="showBatchImportClips"
      width="400px"
    >
      <div>
        <batch-import-clips
          :key="snapshotImportClips"
          :outlets-list="outletsList"
          :provider-list="providerList"
          @importClips="importClips"
        />
      </div>
    </el-dialog>
    <!--选择栏目-->
    <el-drawer
      :append-to-body="true"
      :title="$t('orderCenterCont.SelectColumn')"
      :visible.sync="showColumn"
      custom-class="drawerClass"
      direction="ltr"
      width="500px"
      @close="closeDrawer()"
    >
      <div class="columnTree" style="padding: 20px;height: calc(100vh - 140px);overflow: auto">
        <el-tree
          :key="dragging"
          ref="elTree"
          :allow-drag="allowDrag"
          :allow-drop="allowDrop"
          :data="columnList"
          :default-checked-keys="checkedColumns"
          :props="props"
          check-on-click-node
          default-expand-all
          draggable
          node-key="prop"
          show-checkbox
          @check="handleCheckChange"
          @node-drop="handleDrop"
        />
      </div>
      <div style="padding: 10px 20px;text-align: right;border-top: 1px solid #ccc;">
        <el-button type="primary" @click="determineColumns()">{{ $t('operationCenter.determine') }}</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import searchForm from './cmp/searchForm/index.vue';
import { feePage } from '@/api/finance/partition';
import {
  apiCustomColumnTemplateInsert,
  apiCustomColumnTemplateList,
  apiCustomColumnTemplateUpdate,
  apiCustomerExpenseBatchFastInputFee,
  apiCustomerExpenseDelete,
  apiCustomerExpenseExport,
  apiCustomerExpenseGetInfo,
  apiCustomerExpenseGetPage,
  apiCustomerExpenseRemark,
  apiCustomerGenerateBillNumber
} from '@/api/finance/receivables';
import operationLog from './cmp/operationLog';
import batchAddFee from './cmp/batchAddFee';
import recalculatePrice from './cmp/recalculatePrice';
import importCalculatedPrice from './cmp/ImportCalculatedPrice';
import batchChange from './cmp/batchChange';
import importAddFee from './cmp/importAddFee';
import addSnapshotFee from './cmp/addSnapshotFee';
import batchImportClips from './cmp/batchImportClips';
import { apiCommonSite } from '@/api/logisticsOrders';
import { apiProviderPage } from '@/api/finance/basicData';
import { getIsAuth } from '@/views/finance/utils.js';
import { goExportList, goFileUploadManagement } from '@/utils/goExportList.js';
import { cloneDeep } from 'lodash-es';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';

export default {
  name: 'FeesApply',
  components: {
    searchForm,
    operationLog,
    batchAddFee,
    recalculatePrice,
    importCalculatedPrice,
    batchChange,
    importAddFee,
    addSnapshotFee,
    batchImportClips
  },

  mixins: [estimateTableHeight],

  data() {
    const priceType = (rule, value, callback) => {
      // const reg = /(^[1-9]\d*(\.\d{1,5})?$)|(^0(\.\d{1,5})?$)/;
      const reg = new RegExp('(^[1-9]\\d*(\\.\\d{1,' + rule.suffix + '})?$)|(^0(\\.\\d{1,' + rule.suffix + '})?$)');
      const { required } = rule;
      if (!required) {
        if (!value) {
          return callback();
        } else {
          if (!reg.test(value)) {
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            const valNum = Number(value);
            if (rule.max && (valNum >= rule.max)) {
              return callback(new Error(this.$i18n.t('basicData.MustBeLessThan') + `${rule.max}`)); // 必须小于
            } else {
              return callback();
            }
          }
        }
      } else {
        if (!value || (value.length === 0)) {
          return callback(new Error(this.$i18n.t('basicData.CannotBeEmpty'))); // '不能为空'
        }
        if (!reg.test(value)) {
          return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
        } else {
          const valNum = Number(value);
          if (rule.max && (valNum >= rule.max)) {
            return callback(new Error(this.$i18n.t('basicData.MustBeLessThan') + `${rule.max}`)); // 必须小于
          } else {
            return callback();
          }
        }
      }
    };
    return {
      // 物流订单
      waybillStatus: [
        { label: this.$i18n.t('newOrder.all'), code: 0 }, // 待分配
        { label: this.$i18n.t('newOrder.ToBeAssigned'), code: 1 }, // 待分配
        { label: this.$i18n.t('newOrder.ToBeCollected'), code: 2 }, // 待揽收
        { label: this.$i18n.t('newOrder.Collected'), code: 3 }, // 已揽收
        { label: this.$i18n.t('newOrder.CollectTheRevenuePool'), code: 4 }, // 揽收入库
        { label: this.$i18n.t('newOrder.CollectTakeOutWarehouse'), code: 5 }, // 揽收出库
        { label: this.$i18n.t('GlobalSetObj.moduan'), code: 19 }, // 末端已揽收
        { label: this.$i18n.t('newOrder.TransshipmentIntoStorage'), code: 6 }, // 转运入库
        { label: this.$i18n.t('newOrder.TransferOutWarehouse'), code: 7 }, // 转运出库
        { label: this.$i18n.t('newOrder.DeliveryOutletsIntoStorage'), code: 8 }, // 派送网点入库
        { label: this.$i18n.t('newOrder.Delivering'), code: 9 }, // 派送中
        { label: this.$i18n.t('newOrder.Signed'), code: 10 }, // 已签收
        { label: this.$i18n.t('newOrder.ProblemPiece'), code: 11 }, // 问题件
        { label: this.$i18n.t('GlobalSetObj.Canceled'), code: 13 }, // 已取消
        // { label: this.$i18n.t('GlobalSetObj.deliveryFailure'), code: 14 }, // 投递失败
        { label: this.$i18n.t('GlobalSetObj.DotBack'), code: 17 }, // 网点退回
        { label: this.$i18n.t('GlobalSetObj.ReturnToStorage'), code: 18 }, // 退仓入库
        { label: this.$i18n.t('GlobalSetObj.Withdrawn'), code: 15 }, // 退件中
        { label: this.$i18n.t('GlobalSetObj.TheReturnComplete'), code: 16 }, // 退件完成
        { label: this.$i18n.t('GlobalSetObj.mutilSign'), code: 20 }, // 多次签收
        { label: this.$i18n.t('GlobalSetObj.processSettlement'), code: 21 }, // 理赔中
        { label: this.$i18n.t('GlobalSetObj.ClaimsCompleted'), code: 22 } // 理赔完成
      ],
      // 揽收订单
      orderTypeList: [
        { collectStatus: 0, collectStatusName: this.$t('collectionCenter.notLanShou') }, // '未揽收'
        { collectStatus: 1, collectStatusName: this.$t('collectionCenter.hasLanShou') }, // '已揽收'
        { collectStatus: 2, collectStatusName: this.$t('collectionCenter.cancelled') }, // 已取消
        { collectStatus: 4, collectStatusName: this.$t('collectionCenter.scheduled') }, // 已调度
        { collectStatus: 5, collectStatusName: this.$t('collectionCenter.AbnormalLanShou') }, // 揽收异常
        { collectStatus: 6, collectStatusName: this.$t('collectionCenter.haveBeenSigned') }, // 已签收
        { collectStatus: 7, collectStatusName: this.$t('collectionCenter.ProblemPiece') }, // 问题件
        { collectStatus: 8, collectStatusName: this.$t('collectionCenter.SealedBag') }, // 已封袋
        { collectStatus: 9, collectStatusName: this.$t('collectionCenter.truckLoading') }, // 装车
        { collectStatus: 10, collectStatusName: this.$t('collectionCenter.unloading') }, // 卸车
        { collectStatus: 11, collectStatusName: this.$t('collectionCenter.Bagged') }, // 已装袋
        { collectStatus: 12, collectStatusName: this.$t('collectionCenter.closedLoop') }, // 已闭环
        { collectStatus: 13, collectStatusName: this.$t('collectionCenter.unclaimed') }, // 待取件
        { collectStatus: 14, collectStatusName: this.$t('collectionCenter.collectionPointInventory') }// 揽收网点入库
      ],
      // 备货揽收订单
      bhOrderTypeList: [ // 订单状态
        { collectStatus: 0, collectStatusName: this.$t('collectionCenter.notLanShou') }, // '未揽收'
        { collectStatus: 1, collectStatusName: this.$t('collectionCenter.hasLanShou') }, // '已揽收'
        { collectStatus: 2, collectStatusName: this.$t('collectionCenter.haveBeenSigned') }, // 已签收
        { collectStatus: 3, collectStatusName: this.$t('collectionCenter.cancelled') }, // 已取消
        { collectStatus: 4, collectStatusName: this.$t('collectionCenter.truckLoading') }, // 装车
        { collectStatus: 5, collectStatusName: this.$t('collectionCenter.unloading') }, // 卸车
        { collectStatus: 6, collectStatusName: this.$t('operatingPlatform.collectWarehousing') } // 网点揽收入库
      ],
      queryForm: {
        orderDirection: 'DESC',
        orderProperty: '',
        pageNumber: 1,
        pageSize: 10,
        params: {
          providerId: '', // 应收主体id
          siteId: '', // 战点id
          customerId: '',
          startTime: '', // 业务开始时间
          endTime: '', // 业务结束时间
          timeField: 'operate_time', // 查询时间类型 入库时间:input_time 出库时间:out_time 业务时间:operate_time
          numberList: [], // 单号
          numberField: 'waybill_number', // waybill_number: 业务单号 customer_number: 客户单号
          billBatchNumber: '', // // 批次号
          feeName: '', // 费用类型名称
          feeCode: '',
          isGenBill: null, // 是否生成对账单
          isPaid: null, // 是否已支付
          businessType: null,
          collectionCategory: null,
          status: '',
          handlerId: '', // 经办人
          // isOut: null // 是否已出库
          receiveState: '', // 收件人州
          senderState: '' // 发件人州
        }
      },
      total: 0,
      tableData: [],
      height: 100,
      selectData: [],
      showSearch: false,
      showAddNotes: false,
      remarks: '',
      currentColumns: [],
      getCurrentColumns: [],
      showJournal: false,
      checkData: {},
      keyJournal: new Date().getTime(),
      showSearchKey: new Date().getTime(),
      tableKey: new Date().getTime(),
      selectedAmount: 0, // 已选金额
      head: [],
      showBatchAdd: false,
      batchAddKey: new Date().getTime(),
      showGenerate: false,
      batchNumber: [],
      priceType: priceType,
      recalculate: false,
      showManage1: new Date().getTime(),
      importList: [],
      calculationType: 0,
      showDeleteRow: false,
      feeTypeOption: [],
      feeTypeName: '',
      rowSelectFlag: false,
      queryFeeParam: {},
      addType: 1,
      generateReceivables: false,
      TimeCollection: [],
      showImportTemplate: false,
      importData: [],
      searchSelectArr: {
        providerItem: null,
        outletsItem: null,
        customerItem: null
      },
      showModification: false,
      expensesType: 1,
      showColumn: false,
      dragging: new Date().getTime(),
      props: {
        label: 'label',
        children: 'children'
      },
      checkedColumns: [],
      checkedSelectColumns: [],
      customColumnId: '',
      showAddSnapshotFee: false,
      snapshotFeeKey: new Date().getTime(),
      snapshotImportClips: new Date().getTime(),
      showBatchImportClips: false,
      expenseSubList: [],
      providerList: [],
      outletsList: []
    };
  },
  computed: {
    columnList() {
      return [
        {
          label: this.$i18n.t('basicData.all'), // '全部',
          prop: '',
          children: [
            // 订单号
            {
              prop: 'orderNumber',
              label: this.$i18n.t('basicData.OrderNumber'),
              width: '',
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 业务单号
            {
              prop: 'waybillNumber',
              label: this.$i18n.t('receivablePayable.TicketNumber'),
              width: '',
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 客户单号
            {
              prop: 'customerNumber',
              label: this.$i18n.t('receivablePayable.CustomerTrackingNumber'),
              width: '',
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 订单状态
            {
              prop: 'status',
              label: this.$i18n.t('collectionCenter.OrderStatus'),
              width: '',
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 收件人州
            {
              prop: 'receiveState',
              label: this.$i18n.t('newOrder.RecipientState'),
              width: '',
              fixed: 'left',
              highlight: false,
              disabled: false
            },

            // 发件人州
            {
              prop: 'senderState',
              label: this.$i18n.t('newOrder.SenderState'),
              width: '',
              fixed: 'left',
              highlight: false,
              disabled: false
            },

            // 网点
            {
              prop: 'siteName',
              label: this.$i18n.t('basicData.Outlets'),
              width: '',
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 客户
            {
              prop: 'customerAlias',
              label: this.$i18n.t('receivablePayable.customer'),
              width: 110,
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 商户
            {
              prop: 'merchantName',
              label: this.$i18n.t('collectionCenter.CommercialOwner'),
              width: 110,
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 支付方式
            {
              prop: 'payType',
              label: this.$i18n.t('ClaimsManagement.PaymentMethod'),
              width: 110,
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 计费重量
            {
              prop: 'chargeWeight',
              label: this.$i18n.t('receivablePayable.BillableWeight'),
              width: 110,
              fixed: 'left',
              highlight: true,
              disabled: false
            },
            // 应收总费用
            {
              prop: 'totalFee',
              label: this.$i18n.t('basicData.TotalFeesReceivable'),
              width: 110,
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 业务类型
            {
              prop: 'businessType',
              label: this.$i18n.t('collectionCenter.businessT'),
              width: 110,
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 揽收类别
            {
              prop: 'collectionCategory',
              label: this.$i18n.t('basicData.PickupCategory'),
              width: 110,
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 包裹类型
            {
              prop: 'orderPackageType',
              label: this.$i18n.t('placeOrder.packageType'),
              width: 110,
              fixed: 'left',
              highlight: false,
              disabled: false
            },
            // 来源
            {
              prop: 'feeSource',
              label: this.$i18n.t('receivablePayable.source'),
              width: 110,
              fixed: 'left',
              highlight: false,
              disabled: false
            }
          ]
        }
      ];
    }
  },
  activated() {
    if (this.$route.params.id) {
      this.selectData = [];
      this.selectedAmount = 0;
      this.tableKey = new Date().getTime();
      this.getARGenerationTableData();
    }
  },
  created() {
    this.getHeaderColumn();
    this.getExpenseType();
    // this.getExpenseType();
    // 获取网点
    this.getSitePageList();
    // 主体
    this.getProviderPage();

    // 经办人是否存在禁用，如果禁用则只能查看当前当前登陆人
    {
      const isBool = getIsAuth('btn:receivableManagementCompany:onlyAgent');
      if (isBool) {
        this.queryForm.params.handlerId = this.$store.getters.userId;
      }
    }
  },
  mounted() {
    // 页面加载的时候设置table的高度
    // this.height = this.tableHeight('searchFormCon');
    // // 页面大小该变的时候（缩放页面）设置table的高度（可加可不加）
    // window.onresize = () => {
    //   this.height = this.tableHeight('searchFormCon');
    // };
  },
  methods: {
    // 找出两个数组中相同的元素
    findCommonElements(arr1, arr2) {
      return arr1.filter((element) => arr2.includes(element));
    },
    // 找出两个数组中不相同的元素
    findDifferentElements(arr1, arr2) {
      // 找出arr1中不存在于arr2的元素
      const uniqueToArr1 = arr1.filter(element => !arr2.includes(element));
      // 找出arr2中不存在于arr1的元素
      const uniqueToArr2 = arr2.filter(element => !arr1.includes(element));
      // 将两个数组中的不同元素合并
      return [...uniqueToArr1, ...uniqueToArr2];
    },
    // 获取当前用户自定义列表表头
    getHeaderColumn() {
      const param = {
        type: 0,
        menuEnum: 'siteToCustomer'
      };
      apiCustomColumnTemplateList(param).then(res => {
        if (res.code === 200) {
          this.customColumnId = '';
          let selectedColumn = [];
          if (res.data.length > 0) {
            this.customColumnId = res.data[0].id;
            selectedColumn = JSON.parse(res.data[0].templateField);
          }
          this.currentColumns = [];
          let allColumn = [];
          const selectedProp = [];
          const allProp = [];
          selectedColumn.map(list => {
            if (selectedProp.includes(list.prop) === false) {
              selectedProp.push(list.prop);
            }
          });
          this.columnList[0].children.map(item => {
            if (allProp.includes(item.prop) === false) {
              allProp.push(item.prop);
            }
          });
          const commonElements = this.findCommonElements(selectedProp, allProp);
          if (commonElements.length > 0) {
            commonElements.map(list => {
              this.columnList[0].children.map(item => {
                if (list === item.prop) {
                  this.currentColumns.push(item);
                }
              });
            });
          } else {
            this.columnList[0].children.map(item => {
              if (selectedProp.includes(item.prop) === false) {
                selectedProp.push(item.prop);
              }
              this.currentColumns.push(item);
              // if (item.disabled) {
              //   if (selectedProp.includes(item.prop) === false) {
              //     selectedProp.push(item.prop);
              //   }
              //   this.currentColumns.push(item);
              // }
            });
          }
          // 将当前已选栏目赋值给全部栏目
          allColumn = cloneDeep(this.currentColumns);
          // 找出不同栏目
          const differentElements = this.findDifferentElements(allProp, selectedProp);
          this.columnList[0].children.map(item => {
            if (differentElements.includes(item.prop)) {
              allColumn.push(item);
            }
          });
          // 页面加载的时候设置table的高度
          this.$nextTick(() => {
            this.columnList[0].children = cloneDeep(allColumn);
            this.tableKey = new Date().getTime();
          });
        }
      });
    },
    // 打开自定义栏目弹窗
    customColumn() {
      const selectedColumn = cloneDeep(this.currentColumns);
      this.checkedColumns = [];
      this.checkedSelectColumns = cloneDeep(this.columnList[0].children);
      this.checkedSelectColumns.map(item => {
        if (selectedColumn.length > 0) {
          selectedColumn.map(list => {
            if (item.prop === list.prop) {
              this.checkedColumns.push(item.prop);
            }
          });
        } else {
          this.checkedColumns.push(item.prop);
          // if (item.disabled === true) {
          //   this.checkedColumns.push(item.prop);
          // }
        }
      });
      this.showColumn = true;
      this.dragging = new Date().getTime();
    },
    // 确定保存已选栏目
    determineColumns() {
      const columns = [];
      const neKey = this.$refs.elTree.getCheckedKeys();
      if (neKey.length > 0) {
        this.columnList[0].children.map(list => {
          if (neKey.includes(list.prop)) {
            columns.push(list);
          }
        });
      }
      const params = {
        templateField: JSON.stringify(columns),
        templateName: '网点对客户',
        type: 0,
        menuEnum: 'siteToCustomer',
        userId: this.$store.state.user.id
      };
      if (this.customColumnId) {
        params.id = this.customColumnId;
        apiCustomColumnTemplateUpdate(params).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$t('basicData.TheOperationSucceeded'));
            this.getHeaderColumn();
          }
        });
      } else {
        apiCustomColumnTemplateInsert(params).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$t('basicData.TheOperationSucceeded'));
            this.getHeaderColumn();
          }
        });
      }
      this.showColumn = false;
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
        this.tableData.map(list => {
          const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
          this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
        });
      });
    },
    // 关闭自定义栏目弹窗事件将未保存的数据还原
    closeDrawer() {
      this.columnList[0].children = this.checkedSelectColumns;
    },
    // 判断节点是否能被拖拽
    allowDrag(node) {
      return node.level === 2;
    },
    // 判断节点是否可放置
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.level === 2 && type !== 'inner') {
        return true;
      } else {
        return false;
      }
    },
    // 拖拽成功后触发事件
    handleDrop(draggedNode, insertNode, dropLocation, event) {
      this.dragging = new Date().getTime();
    },
    // 栏目勾选
    handleCheckChange(code, node) {
      this.checkedColumns = node.checkedKeys;
    },
    // 分页获取站点
    getSitePageList(name = '') {
      const param = {
        pageNumber: 1,
        pageSize: 9999999,
        params: {
          siteStatus: undefined,
          name
        }
      };
      apiCommonSite(param).then(res => {
        if (res.code === 200) {
          this.outletsList = res.data.records;
        }
      });
    },
    // 获取应收主体选项
    getProviderPage(name = '') {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          providerName: name,
          state: 1
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.providerList = res.data.records;
        }
      });
    },
    importClips(data) {
      apiCustomerExpenseBatchFastInputFee(data).then(res => {
        if (res.status === 'OK') {
          // this.$message.success(this.$t('basicData.TheOperationSucceeded'));
          goFileUploadManagement(this);
          this.getARGenerationTableData();
          this.tableKey = new Date().getTime();
          this.showBatchImportClips = false;
        }
      });
    },
    // 添加快录费用
    quickRecord(type) {
      if (type === 1) {
        if (this.selectData.length > 0) {
          this.queryFeeParam = cloneDeep(this.selectData[0]);
          this.queryFeeParam.waybillNumber = this.queryFeeParam.waybillNumber.replace('QR', '');
          this.queryFeeParam.id = null;
        } else {
          this.queryFeeParam = {
            id: null,
            waybillNumber: '', // 业务单号
            customerId: '', // 客户id
            customerAlias: '', // 客户别称
            providerId: '', // 业务主体id
            siteId: '', // 网点id
            siteName: '', // 网点名称
            chargeWeight: '', // 计费重量
            businessType: '', // 业务类型
            collectionCategory: '', // 揽收类型
            remark: '', // 备注
            isSynchronous: false
          };
        }
        this.showAddSnapshotFee = true;
        this.snapshotFeeKey = new Date().getTime();
      } else {
        this.showBatchImportClips = true;
        this.snapshotImportClips = new Date().getTime();
      }
    },
    // 导入添加、修改费用
    changeFeeList(type) {
      if (type === true) {
        this.selectData = [];
        this.selectedAmount = 0;
        this.tableKey = new Date().getTime();
        this.getARGenerationTableData();
      }
      this.showModification = false;
    },
    // 打开批量添加修改费用弹窗
    importFees(type) {
      this.expensesType = type;
      this.showModification = true;
    },

    postcodeCalculation(type) {
      this.calculationType = type;
      this.showImportTemplate = true;
      this.importData = [];
    },
    // 打开导入计算价格模板弹窗
    getImportTemplate(type) {
      this.importData = [];
      this.calculationType = type;
      this.showImportTemplate = true;
    },
    // 导入计算价格
    importPrice(data) {
      this.importData = data;
      this.showManage1 = new Date().getTime();
      this.recalculate = true;
    },
    // 费用详情、编辑
    editFee(row) {
      if (row) {
        this.queryFeeParam = cloneDeep(row);
        if (this.queryFeeParam.feeSource === 0) {
          this.addType = 2;
          this.batchAddKey = new Date().getTime();
          this.showBatchAdd = true;
        } else {
          const param = cloneDeep(this.queryFeeParam);
          apiCustomerExpenseGetInfo(param).then(res => {
            if (res.status === 'OK') {
              this.expenseSubList = res.data.expenseSubList;
              this.snapshotFeeKey = new Date().getTime();
            }
          });
          this.queryFeeParam.waybillNumber = this.queryFeeParam.waybillNumber.replace('QR', '');
          this.showAddSnapshotFee = true;
        }
      }
    },
    // 表头宽度变化
    headerWidthChange() {
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
        this.rowSelectFlag = true;
        this.tableData.map(list => {
          const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
          this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
        });
        this.rowSelectFlag = false;
      });
    },
    // 监听元素宽高变化
    handleResize() {
      // this.height = this.tableHeight('searchFormCon');
    },
    // 列表高度计算
    tableHeight(name) {
      const searchFormHeight = this.$refs[name].clientHeight; // 可变的查询高度
      const pageHeight = document.documentElement.clientHeight; // 可用窗口的高度
      const tableHeight = Number(pageHeight - searchFormHeight - 210); // 计算完之后剩余table可用的高度
      this.$refs.elTable.doLayout();
      this.$nextTick(() => {
        this.rowSelectFlag = true;
        this.tableData.map(list => {
          const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
          this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
        });
        this.rowSelectFlag = false;
      });
      return tableHeight;
    },
    // 确定删除费用
    saveDeleteRow(type) {
      if (type === 2) {
        if (!this.feeTypeName) {
          this.$message.warning(this.$i18n.t('receivablePayable.TheChargeTypeCannotBeEmpty')); // '费用类型不能为空'
          return;
        }
        const ids = [];
        if (this.selectData.length > 0) {
          this.selectData.map(item => {
            ids.push(item.id);
          });
        }
        const param = {
          condition: {
            ...this.queryForm.params,
            ids
          }
        };
        param.condition.feeCode = this.feeTypeName;
        this.$confirm(this.$i18n.t('receivablePayable.ConfirmDeletionAllDataForSelectedExpenseType'), this.$i18n.t('basicData.tips'), { // '确认删除所选费用类型的所有数据？' 提示
          confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
          cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
          type: 'warning'
        }).then(() => {
          apiCustomerExpenseDelete(param).then(res => {
            if (res.status === 'OK') {
              this.$message.success(this.$i18n.t('GlobalSetObj.TheOperationSucceeded')); // '操作成功'
              this.showDeleteRow = false;
              this.selectData = [];
              this.selectedAmount = 0;
              this.feeTypeName = '';
              this.tableKey = new Date().getTime();
              this.getARGenerationTableData();
            }
          });
        }).catch(() => {
        });
      } else {
        this.showDeleteRow = false;
      }
    },
    // 获取费用类型
    getExpenseType() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10000,
        params: {
          state: 1
        }
      };
      feePage(param, false).then(res => {
        if (res.code === 200) {
          this.feeTypeOption = res.data.records;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 打开删除费用弹窗
    deleteRow() {
      // this.feeTypeName = this.queryForm.params.feeName;
      this.showDeleteRow = true;
    },
    // 关闭重新计算价格弹窗
    changePage(type) {
      this.importData = [];
      this.recalculate = false;
      this.showImportTemplate = false;
      if (type === true) {
        this.selectData = [];
        this.selectedAmount = 0;
        this.tableKey = new Date().getTime();
        this.getARGenerationTableData();
      }
    },
    // 复制批次号
    copyBatchNumber(type) {
      if (type === 1) {
        this.showGenerate = false;
        this.batchNumber = [];
        return false;
      }
      const input = document.getElementById('message5');
      const num = [];
      this.batchNumber.map(item => {
        num.push(item.split('~')[0]);
      });
      input.value = num.join(','); // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand('copy'); // 执行浏览器复制命令
      this.$message.success(this.$i18n.t('orderCenterCont.ReplicationSucceeded')); // '复制成功'
    },
    // 批量添加费用保存
    saveForm(type) {
      if (type === 2) {
        this.selectData = [];
        this.selectedAmount = 0;
        this.tableKey = new Date().getTime();
        this.getARGenerationTableData();
      }
      this.showBatchAdd = false;
    },
    // 保存、关闭添加快录弹窗
    saveFormSnapshot(type) {
      if (type === 2) {
        this.selectData = [];
        this.tableKey = new Date().getTime();
        this.getARGenerationTableData();
      }
      this.showAddSnapshotFee = false;
    },
    // rowKey
    getRowKey(row) {
      return row.id;
    },
    // 保存备注
    saveRemarks(type) {
      if (type === 1) {
        this.showAddNotes = false;
        this.remarks = '';
        return;
      }
      if (!this.remarks) {
        this.$message.warning(this.$i18n.t('receivablePayable.TheCommentCannotBeEmpty')); // '备注不能为空'
        return;
      }
      const ids = [];
      if (this.selectData.length > 0) {
        this.selectData.map(item => {
          ids.push(item.id);
        });
      }
      const param = {
        condition: {
          ...this.queryForm.params,
          ids: ids
        },
        remark: this.remarks
      };
      apiCustomerExpenseRemark(param).then(res => {
        if (res.status === 'OK') {
          this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // '保存成功'
          this.remarks = '';
          this.selectData = [];
          this.selectedAmount = 0;
          this.tableKey = new Date().getTime();
          this.getARGenerationTableData();
          this.showAddNotes = false;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 查看操作日志
    operationBtnLog(data) {
      this.checkData = data;
      this.keyJournal = new Date().getTime();
      this.showJournal = true;
    },
    // 确定生成应收
    determineReceivables(type) {
      if (type === 1) {
        this.generateReceivables = false;
        return;
      }
      const ids = [];
      if (this.selectData.length > 0) {
        this.selectData.map(item => {
          ids.push(item.id);
        });
      }
      const tips = ids.length > 0 ? this.$i18n.t('receivablePayable.AreYouSureGenerateReceivablesSelectedData') // 确定给选中数据生成应收？
        : this.$i18n.t('receivablePayable.UncheckedDataWillGenerateReceivablesAllDataQueried'); // '未勾选数据将会给查询到的所有数据都生成应收，确定生成应收？';
      this.$confirm(tips,
        this.$i18n.t('basicData.tips'), // '提示'
        {
          confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
          cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
          type: 'warning'
        }
      ).then(() => {
        const paramForm = cloneDeep(this.queryForm.params);
        if (this.TimeCollection && this.TimeCollection.length > 1) {
          paramForm.generateTimeStart = this.TimeCollection[0];
          paramForm.generateTimeEnd = this.TimeCollection[1];
        } else {
          this.$message.warning(this.$i18n.t('receivablePayable.TheTimeCannotBeEmpty'));
          return;
        }
        const param = {
          ...paramForm,
          ids: ids
        };
        apiCustomerGenerateBillNumber(param).then(res => {
          if (res.status === 'OK') {
            this.batchNumber = res.data;
            this.selectData = [];
            this.selectedAmount = 0;
            this.tableKey = new Date().getTime();
            this.getARGenerationTableData();
            this.bus.$emit('pendingData');
            this.generateReceivables = false;
            this.showGenerate = true;
          }
        }).catch(err => {
          console.error(err);
        });
      }).catch(() => {
      });
    },
    // 操作
    operationBtn(type, calculationType) {
      const ids = [];
      if (this.selectData.length > 0) {
        this.selectData.map(item => {
          ids.push(item.id);
        });
      }
      // 批量添加费用
      if (type === 2) {
        if (this.tableData.length === 0) {
          this.$message.warning(this.$t('receivablePayable.PleaseQueryCheckTheDataFirst')); // 请先查询/勾选数据
          return;
        }
        if (this.queryForm.params.businessType === null || this.queryForm.params.businessType === '' || this.queryForm.params.businessType === undefined ||
          !this.queryForm.params.customerId) {
          this.$message.warning(this.$t('receivablePayable.PleaseFilterBusinessCustomer')); // 请筛选相同业务类型同一客户的数据进行批量添加费用
          return;
        }
        this.addType = 1;
        this.queryFeeParam = {
          billingMethod: this.tableData[0].billingMethod
        };
        this.showBatchAdd = true;
        this.batchAddKey = new Date().getTime();
        return;
      }
      // 应收生成
      if (type === 3) {
        this.generateReceivables = true;
        return;
      }
      // 添加备注
      if (type === 5) {
        this.remarks = '';
        this.showAddNotes = true;
        return;
      }
      // 导出
      if (type === 6) {
        const prop = [];
        const label = [];
        this.currentColumns.map(item => {
          prop.push(item.prop);
          label.push(item.label);
        });
        const param = {
          ...this.queryForm.params,
          fieldList: prop,
          headList: label,
          ids: ids
        };
        apiCustomerExpenseExport(param).then(res => {
          goExportList(this);
        }).catch(err => {
          console.error(err);
        });
      }
      // 重新计算价格
      if (type === 7) {
        if (this.total > 100000) {
          this.$message.warning(this.$i18n.t('receivablePayable.RecalculationPriceExceed100000Pieces')); // 重新计算价格上限不能超过10w
          return;
        }
        // 按搜索结果计算
        if (this.tableData.length === 0) {
          this.$message.warning(this.$i18n.t('receivablePayable.PleaseSearchDataBeforeRecalculatingPrice')); // 请先搜索出数据后再做重算价格
          return;
        }
        let msg = '';
        if (this.selectData.length > 0) {
          this.calculationType = 1;
          msg = this.$i18n.t('receivablePayable.PressRecalculateWithChecked');
        } else {
          this.calculationType = 0;
          msg = this.$i18n.t('receivablePayable.Recalculate');
        }

        if (this.queryForm.params.businessType === 1) {
          if (this.queryForm.params.collectionCategory === null) {
            this.$message.warning(this.$t('receivablePayable.BusinessTypePickupPickupCategoryCannotEmpty'));
            return;
          }
        }
        // if (this.queryForm.params.collectionCategory === 1) {
        //   const time1 = this.queryForm.params.startTime.split(' ')[0];
        //   const time2 = this.queryForm.params.endTime.split(' ')[0];
        //   if (time1 !== time2) {
        //     this.$message.warning(this.$t('receivablePayable.WhenPickupCategoryStockingPickup'));
        //     return;
        //   }
        // }

        this.$confirm(
          msg, // 点击”确认“按钮，将按照系统最新价格表对当前查询的所有“费用来源”为“系统生成”的费用进行重新核算，是否确认？
          this.$i18n.t('basicData.tips'),
          {
            confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
            cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
            type: 'warning'
          }
        ).then(() => {
          this.showManage1 = new Date().getTime();
          this.recalculate = true;
        }).catch(() => {
        });
      }
    },
    // 搜索
    searchResources() {
      this.showSearch = true;
      this.showSearchKey = new Date().getTime();
    },
    // 获取列表
    getARGenerationTableData() {
      const param = cloneDeep(this.queryForm);
      param.params.ids = [];
      this.getCurrentColumns = [];
      // 获取列表数据
      apiCustomerExpenseGetPage(param).then(resp => {
        if (resp.status === 'OK') {
          resp.data.map(item => {
            for (const key in item) {
              if (key === 'inputTime') {
                item[key] = item[key] ? item[key].replace('T', ' ') : '';
              }
            }
          });
          const filArr = resp.data.map(arr => {
            if (arr.payType === 1) {
              arr.payType = 'OffLine';
            } else if (arr.payType === 0) {
              arr.payType = 'OnLine';
            } else {
              arr.payType = '';
            }
            // 物流订单 waybillStatus    揽收订单 orderTypeList  备货揽收订单 bhOrderTypeList
            if ((arr.businessType == 0 || arr.businessType == 7) && arr.status) {
              /**
               * 1、业务类型 businessType 为 0，订单状态展示【物流订单】的运单状态
               */
              const s1 = this.waybillStatus.find(item => item.code == arr.status) || '';
              arr.status = s1.label;
            } else if (arr.businessType == 1 && arr.collectionCategory == 0 && arr.status) {
              /**
               * 2、业务类型 businessType 为 1，且揽收类别 collectionCategory 为 0，订单状态展示【揽收订单】的订单状态
               */
              const s2 = this.orderTypeList.find(item => item.collectStatus == arr.status) || '';
              arr.status = s2.collectStatusName;
            } else if (arr.businessType == 1 && arr.collectionCategory == 1 && arr.status) {
              /**
               * 3、业务类型 businessType 为 1，且揽收类别 collectionCategory 为 1，订单状态展示【备货揽收订单】的订单状态
               */
              const s3 = this.bhOrderTypeList.find(item => item.collectStatus == arr.status) || '';
              arr.status = s3.collectStatusName;
            } else {
              arr.status = '';
            }
            return arr;
          });
          this.tableData = filArr;
          this.total = resp.total;
          if (resp.data.length > 0) {
            this.getCurrentColumns = [];
            resp.columns.map(item => {
              // const test = item.label.indexOf('_');
              // item.label = item.label.substring(0, test);
              const obj = {
                prop: item.prop,
                label: item.label.split('_')[0],
                width: 120,
                fixed: 'left',
                highlight: false
              };
              this.getCurrentColumns.push(obj);
              this.$nextTick(() => {
                this.$refs.elTable.doLayout();
                this.rowSelectFlag = true;
                this.tableData.map(list => {
                  const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
                  this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
                });
                this.rowSelectFlag = false;
              });
            });
          }
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 列表数据勾选
    handleSelectionChange(arr) {
      if (this.rowSelectFlag) return;
      this.selectData = [];
      this.selectedAmount = 0;
      this.selectData = arr; // deWeight(arr);
      if (this.selectData.length === 0) {
        this.selectedAmount = 0;
      } else {
        this.selectData.map(item => {
          this.selectedAmount = item.receivablesRmb ? Number(item.receivablesRmb) + this.selectedAmount : this.selectedAmount;
        });
      }
    },
    // 查询
    searchFun(data, select, type) {
      this.searchSelectArr = select;
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.selectData = [];
        this.selectedAmount = 0;
        this.tableKey = new Date().getTime();
        this.getARGenerationTableData();
        this.showSearch = false;
      } else if (type === 3) {
        this.queryForm.params = {
          providerId: '', // 应收主体id
          siteId: '', // 战点id
          customerId: '',
          startTime: '', // 业务开始时间
          endTime: '', // 业务结束时间
          timeField: 'operate_time', // 查询时间类型 入库时间:input_time 出库时间:out_time 业务时间:operate_time
          numberList: [], // 单号
          numberField: 'waybill_number', // waybill_number: 业务单号 customer_number: 客户单号
          billBatchNumber: '', // // 批次号
          feeName: '', // 费用类型名称
          feeCode: '',
          isGenBill: null, // 是否生成对账单
          isPaid: null, // 是否已支付
          businessType: null,
          collectionCategory: null,
          status: '',
          merchantName: '', // 商户
          payType: null
          // isOut: null // 是否已出库
        };
      } else {
        this.showSearch = false;
      }
    }
  }
};
</script>
<style>
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  border-color: #aaa;
}

.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #aaa;
}
</style>
<style scoped>
.alert {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 6px 16px;
  margin-bottom: 5px;
}

.alert p {
  margin: 0;
  padding: 0;
}

.alert .icon {
  color: #409eff;
  font-size: 24px;
}

.alert .title {
  /*margin-left: 8px;*/
  font-size: 14px;
}

.alert .num {
  font-weight: 600;
  font-size: 14px;
}

.alert .signal {
  color: #409eff;
}
</style>
