import $i18n from '../../../../lang/index';

export const headerList = [
  { id: 1, label: $i18n.t('navbar.providerOrderStatus'), prop: 'providerOrderStatusName', cloWidth: 140 },
  { id: 3, label: $i18n.t('navbar.providerInfo'), prop: 'responseInfo', cloWidth: 200 },
  { id: 2, label: $i18n.t('newOrder.orderCreateTime'), prop: 'createTime', cloWidth: 180 },
  // 客户名称
  {
    id: 4,
    label: $i18n.t('newOrder.CustomerName'),
    prop: 'customerName',
    clickClass: false,
    cloWidth: 100
  },
  { id: 5, label: $i18n.t('GlobalSetObj.fuwushang'), prop: 'providerName', cloWidth: 160 },
  { id: 18, label: $i18n.t('collectionCenter.MerchantName'), prop: 'merchantName', cloWidth: 160 },
  { id: 19, label: $i18n.t('collectionCenter.MerchantNum'), prop: 'merchantCode', cloWidth: 100 },
  {
    id: 6,
    label: $i18n.t('newOrder.orderNumber'),
    prop: 'customerNumber',
    clickClass: false,
    cloWidth: 180
  },
  {
    id: 7,
    label: $i18n.t('orderCenterCont.anjunWaybillNumber'),
    prop: 'waybillNumber',
    cloWidth: 180
  },
  { id: 81, label: $i18n.t('GlobalSetObj.ScanNumber'), prop: 'scanOrderNumber', cloWidth: 140 },
  {
    id: 8,
    label: $i18n.t('GlobalSetObj.serviceLogistics'),
    prop: 'providerNumber',
    clickClass: false,
    cloWidth: 180
  },
  {
    id: 9,
    label: $i18n.t('orderCenterCont.incomingWeightOfTransitWarehouse'),
    prop: 'transportWeight', cloWidth: 160
  },
  // 中转仓袋号
  {
    id: 10,
    label: $i18n.t('orderCenterCont.TransitBinBagNumber'),
    prop: 'transitBagNumber',
    clickClass: false,
    cloWidth: 160
  },
  { id: 11, label: $i18n.t('newOrder.RecipientName'), prop: 'receiveName', cloWidth: 140 },
  { id: 12, label: $i18n.t('newOrder.RecipientZipCode'), prop: 'receiveZipcode', cloWidth: 140 },
  { id: 13, label: $i18n.t('orderCenterCont.recipientProvince'), prop: 'receiveState', cloWidth: 140 },
  { id: 14, label: $i18n.t('newOrder.RecipientCity'), prop: 'receiveCity', cloWidth: 140 },
  { id: 15, label: $i18n.t('orderCenterCont.SenderzipCode'), prop: 'senderZipcode', cloWidth: 140 },
  { id: 16, label: $i18n.t('orderCenterCont.SenderProvince'), prop: 'senderState', cloWidth: 140 },
  { id: 17, label: $i18n.t('orderCenterCont.SenderCity'), prop: 'senderCity', cloWidth: 140 }
];

export const tableHeaderForm = [
  $i18n.t('navbar.providerOrderStatus'),
  $i18n.t('GlobalSetObj.ScanNumber'),
  $i18n.t('navbar.providerInfo'),
  $i18n.t('newOrder.orderCreateTime'),
  $i18n.t('newOrder.CustomerName'),
  $i18n.t('GlobalSetObj.fuwushang'),
  $i18n.t('collectionCenter.MerchantName'),
  $i18n.t('collectionCenter.MerchantNum'),
  $i18n.t('newOrder.orderNumber'),
  $i18n.t('orderCenterCont.anjunWaybillNumber'),
  $i18n.t('GlobalSetObj.serviceLogistics'),
  $i18n.t('orderCenterCont.incomingWeightOfTransitWarehouse'),
  $i18n.t('orderCenterCont.TransitBinBagNumber'),
  $i18n.t('newOrder.RecipientName'),
  $i18n.t('newOrder.RecipientZipCode'),
  $i18n.t('orderCenterCont.recipientProvince'),
  $i18n.t('newOrder.RecipientCity'),
  $i18n.t('orderCenterCont.SenderzipCode'),
  $i18n.t('orderCenterCont.SenderProvince'),
  $i18n.t('orderCenterCont.SenderCity')
];
