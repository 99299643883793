<template>
  <div class="app-container" style="padding-top: 5px">
    <!--       default-expand-all -->
    <el-tree
      ref="treeRef"
      :data="permissionList"
      :default-checked-keys="defSelectedPermissionList"
      :props="props"
      icon-class="el-icon-thumb"
      node-key="id"
      show-checkbox
    />
  </div>
</template>

<script>
import { getPermissionStructureByField } from '@/api/role';

export default {
  name: 'PermissionTree',
  props: {
    permissionTreePop: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      props: {
        label: 'name',
        children: 'children'
      },
      // 权限树
      permissionList: [],
      // 选中了的节点
      selectedPermissionList: [],
      // 默认选中节点
      defSelectedPermissionList: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      getPermissionStructureByField().then((res) => {
        if (res.code===200) {
          const { permissionStructure, lastChildrenIds } = res.data;
          if (permissionStructure) {
            this.permissionList = permissionStructure;
            if (this.permissionTreePop.selectedPermissionList.char) {
              this.defSelectedPermissionList = this.permissionTreePop.selectedPermissionList.char.filter(
                (item) => {
                  return lastChildrenIds.indexOf(item) !== -1;
                }
              );
            }
          }
        }
      }).catch(err => {
        console.error(err);
      });
      // getPermissionStructureAll().then((response) => {
      //   console.log(response, 'responseresponse');
      //   const { permissionStructure, lastChildrenIds } = response.data;
      //   if (permissionStructure) {
      //     this.permissionList = permissionStructure;
      //     if (this.permissionTreePop.seeStrPermission) {
      //       this.defSelectedPermissionList = this.permissionTreePop.seeStrPermission.filter(
      //         (item) => {
      //           return lastChildrenIds.indexOf(item) !== -1;
      //         }
      //       );
      //     }
      //   }
      // });
    },
    getSelectedPermissionList() {
      const checkedKeys = this.$refs.treeRef.getCheckedKeys();
      const halfCheckedKeys = this.$refs.treeRef.getHalfCheckedKeys();
      const checkedNodes = this.$refs.treeRef.getCheckedNodes();
      const halfCheckedNodes = this.$refs.treeRef.getHalfCheckedNodes();
      const menuPermissions = [];
      const buttonPermissions = [];
      checkedNodes.map(item => {
        const code = item.code.split(':')[0];
        if (code === 'menu') {
          menuPermissions.push(item.code);
        } else {
          buttonPermissions.push(item.code);
        }
      });
      halfCheckedNodes.map(item => {
        const code = item.code.split(':')[0];
        if (code === 'menu') {
          menuPermissions.push(item.code);
        } else {
          buttonPermissions.push(item.code);
        }
      });
      const permissionIds = halfCheckedKeys.concat(checkedKeys);

      return {
        permissionIds,
        menuPermissions,
        buttonPermissions
      };
    }
  }
};
</script>

<style scoped>
.el-tree {
  min-width: 100%;
  display: inline-block;
}

.tree {
  overflow: auto;
  max-height: 400px;
}
</style>
