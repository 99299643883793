<template>
  <div style="z-index: 999999 !important; background-color: #ffffff">
    <!--  系统信息弹框  -->
    <div class="sysInfo">
      <!--  头部   -->
      <div class="header">
        <div>
          <span>{{ $t('GlobalSetObj.sysMessage') }}</span>
          <span style="margin-left: 10px">
            <i class="iconfont icon-a-lujing642" style="font-size: 18px" />
          </span>
        </div>
        <div>
          <i class="iconfont icon-a-zu389" style="font-size: 14px" @click="closeMessage" />
        </div>
      </div>
      <!--  信息   -->
      <div class="infoContant">
        <div v-if="messageObj.msgType === 4">
          <div v-html="messageObj.msgData" />
          <el-link type="primary" @click="toRecyclingManagement">{{
            $t('home.lijiReturn')
          }}</el-link>
        </div>
        <div v-else v-html="messageObj.msgData" />
      </div>
      <!--  关闭  -->
      <div style="display: flex; justify-content: flex-end; margin-right: 10px">
        <el-button
          v-if="messageObj.isRead === 0" plain size="mini"
          @click="notTip"
        >{{ $t('GlobalSetObj.notTip') }}
        </el-button>
        <el-button
          size="mini" type="primary" @click="closeMessage"
        >{{ $t('GlobalSetObj.close') }} {{ countdown }}s
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { readMessageUser, selectMessageUser } from '@/api/messageCenter';

export default {
  props: {
    messageType: {
      type: Boolean,
      required: false
    },
    messageObj: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      countdown: 0,
      intervalId: null,
      messageShow: true,
      getmessageContent: '',
      getmessageNumber: 0
    };
  },
  mounted() {
    this.countdown = this.messageObj.messageInfoTime;
    this.startCountdown();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    this.intervalId = null;
  },
  methods: {
    toRecyclingManagement() {
      this.$router.push('/views/wovenBagManagement/recyclingManagement');
      this.closeMessage();
    },
    // 倒计时
    startCountdown() {
      this.intervalId = window.setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.intervalId); // 倒计时结束时清除定时器
          this.closeMessage();
        }
      }, 1000);
    },
    // 点击关闭
    closeMessage() {
      const data = {
        messageInfo: false,
        messageInfoTime: 60
      };
      this.$store.commit('user/set_getmessageShow', data);
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
    // 不再提示
    async notTip() {
      const data = {
        receiveId: store.getters.userId,
        clientType: 1,
        messageId: this.messageObj.messageId
      };
      this.closeMessage();
      const res = await readMessageUser(data);
      if (res.code===200) {
        // this.$refs.popoverRef123.hide();
        this.bus.$emit('getMessage');
      }
    },
    // 查询系统消息
    async getMessage() {
      const data = {
        receiveId: store.getters.userId,
        clientType: 1
      };
      const res = await selectMessageUser(data);
      if (res.code===200) {
        this.getmessageNumber = 0;
        if (res.data && res.data.length > 0) {
          this.messageList.forEach((item) => {
            if (item.isRead === 0) {
              item.isShow = 1; // 未读
              // 未读
              this.getmessageNumber = this.getmessageNumber + 1;
            } else {
              item.isShow = 0; // 已读
            }
          });
          this.$store.commit('user/set_getmessageNumber', this.getmessageNumber);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.sysInfo {
  position: absolute;
  bottom: 0;
  width: 595px !important;
  right: 0;
  color: #142957;
  background: #ffffff;
  box-shadow: 0 0 16px rgba(6, 53, 157, 0.14);
  opacity: 1;
  border-radius: 4px;
  padding-bottom: 10px;
  z-index: 99999;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px 15px 20px;
    font-size: 16px;
    font-weight: bold;
    opacity: 1;
    cursor: pointer;
    background-color: #f2f2f2;
    color: black;
  }

  .infoContant {
    padding: 10px 10px 10px 20px;
    line-height: 25px;
    height: 250px;
    overflow-x: hidden; /* 隐藏x轴滚动条，但这不是必须的，因为默认就是visible */
    word-wrap: break-word;
    overflow-y: auto;
  }
}
</style>
