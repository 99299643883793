<template>
  <div class="Box">
    <el-form style="margin-bottom: 20px;" class="selectClass">
      <el-row>
        <el-col :span="10" style="width: auto;">
          <!-- 创建时间： -->
          <el-form-item :label="$t('collectionCenter.createTime')">
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :range-separator="$t('collectionCenter.to')"
              :start-placeholder="$t('collectionCenter.StartTime')"
              :end-placeholder="$t('collectionCenter.EndTime')"
              @change="dateChange"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7" style="width: auto;">
          <!-- 模块： -->
          <el-form-item :label="$t('collectionCenter.module') + '：'">
            <el-select
              v-model="queryForm.params.moduleCode" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
              filterable
            >
              <el-option
                v-for="(item, index) in moduleList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7" style="width: auto;">
          <!-- 标题名称： -->
          <el-form-item :label="$t('collectionCenter.titleName') + '：'">
            <el-input
              v-model="queryForm.params.titleName"
              :placeholder="$t('collectionCenter.pleaseInput')"
              clearable
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-col :span="24">
        <el-button
          v-permit:remove="'btn:system-manage:operation-guide:find'"
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="searchClick"
        >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
        <el-button
          v-permit:remove="'btn:system-manage:operation-guide:reset'"
          icon="el-icon-refresh"
          size="mini"
          @click="resetClick"
        >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->

        <el-button
          v-permit:remove="'btn:system-manage:operation-guide:add'"
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="addClick"
        >{{ $t('collectionCenter.add') }}</el-button><!-- 新增 -->
      </el-col>
    </el-row>
    <el-divider />
    <el-table
      ref="tableRef"
      :max-height="600"
      border
      :data="tableDate"
    >
      <el-table-column
        type="selection"
        width="55"
      />
      <!-- 序号 -->
      <el-table-column
        :label="$t('collectionCenter.ind')" type="index" width="60"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="titleName"
        :label="$t('collectionCenter.titleName')"
      /><!-- 标题名称 -->
      <el-table-column
        align="center"
        prop="moduleCode"
        :label="$t('collectionCenter.module')"
      ><!-- 模块 -->
        <template slot-scope="scope">
          {{
            scope.row.moduleCode == 1 ? $t('collectionCenter.collect') :
            scope.row.moduleCode == 2 ? $t('collectionCenter.transfer') :
            scope.row.moduleCode == 3 ? $t('collectionCenter.dispatch') :
            scope.row.moduleCode == 4 ? $t('collectionCenter.finance') :
            scope.row.moduleCode == 5 ? $t('collectionCenter.trackingPackage') :
            scope.row.moduleCode == 6 ? $t('collectionCenter.BasicInformation') :
            scope.row.moduleCode == 7 ? $t('collectionCenter.serviceQuality') :
            scope.row.moduleCode == 0 ? $t('collectionCenter.other') : ''
          }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="versionInfo"
        :label="$t('editingRegistration.editions')"
      /><!-- 版本 -->
      <el-table-column
        align="center"
        prop="createRealName"
        :label="$t('collectionCenter.creator')"
      /><!-- 创建人 -->
      <el-table-column
        align="center"
        prop="createTime"
        :label="$t('collectionCenter.CreationTime')"
      /><!-- 创建时间 -->
      <el-table-column
        align="center"
        prop="updateRealName"
        :label="$t('collectionCenter.ModifiedBy')"
      /><!-- 修改人 -->
      <el-table-column
        align="center"
        prop="updateTime"
        :label="$t('collectionCenter.ModificationTime')"
      /><!-- 修改时间 -->
      <el-table-column
        v-if="isAllowDownload"
        fixed="right"
        align="center"
        :label="$t('collectionCenter.enclosureFJ')"
      ><!-- 附件 -->
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.attachment" :key="index">
            {{ item.file_name }}.{{ item.file_type }}
            <span style="color: #83d4f8;cursor: pointer;" @click="uploadUrl(item.file_url)">{{ $t('GlobalSetObj.Download') }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isEdit"
        fixed="right"
        align="center"
        :label="$t('collectionCenter.operation')"
      ><!-- 修改 -->
        <template slot-scope="scope">
          <div style="color: #83d4f8;cursor: pointer;" @click="editRow(scope.row)">{{ $t('collectionCenter.modify') }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getPage"
        @size-change="getPageSize"
      />
    </div>
    <div v-if="drawerIsOpen">
      <el-drawer
        :direction="direction"
        :title="drawerTitle"
        :visible.sync="drawerIsOpen"
        size="50%"
        :before-close="handleClose"
      >
        <div>
          <el-form
            ref="drawerFormRef" :model="submitForm" :rules="rules"
            style="margin-bottom: 20px;" class="selectClass drawer"
          >
            <el-row>
              <el-col :span="20">
                <el-form-item prop="titleName" :label="$t('collectionCenter.titleName') + '：'">
                  <el-input
                    v-model="submitForm.titleName"
                    maxlength="300"
                    show-word-limit
                    type="textarea"
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    clearable
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item prop="moduleCode" :label="$t('collectionCenter.module') + '：'">
                  <el-select
                    v-model="submitForm.moduleCode" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in moduleList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item :label="$t('editingRegistration.editions') + '：'">
                  <el-input
                    v-model="submitForm.versionInfo"
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    clearable
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" style="width: auto;" class="fileClass">
                <el-form-item :label="$t('collectionCenter.enclosureFJ') + '：'">
                  <el-upload
                    class="uploadFileClass"
                    action
                    :limit="5"
                    :show-file-list="true"
                    :file-list="fileList"
                    :before-upload="beforeUpload"
                    :http-request="uploadClick"
                    :on-remove="handleRemove"
                  >
                    <el-button>{{ $t('spareNumberPoo.SelectTheFile') }}</el-button>
                    <br>
                    <!-- *限制最多上传5个文件 -->
                    <span>*{{ $t('collectionCenter.max5Files') }}</span>
                  </el-upload><!-- 选取文件 -->
                  <span v-if="isSave && fileList.length <= 0" style="color:#F56C6C;">{{ $t('collectionCenter.notNull') }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- 保存、取消 -->
          <div style="text-align: center;">
            <el-button @click="handleClose">{{ $t('collectionCenter.cancel') }}</el-button>
            <el-button type="success" @click="saveClick">{{ $t('collectionCenter.Save') }}</el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {
  operationGuidePage,
  operationGuideAdd,
  operationGuideEdit
} from '@/api/resources';
import { uploadOos } from '@/utils/ali_oos_util';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      isUploadOk: false,
      isAllowDownload: this.$store.getters.button_permissions.includes('btn:system-manage:operation-guide:allowDownload'),
      isEdit: this.$store.getters.button_permissions.includes('btn:system-manage:operation-guide:edit'),
      timeValue: [],
      moduleList: [
        { label: this.$t('collectionCenter.collect'), value: 1 }, // 揽收
        { label: this.$t('collectionCenter.transfer'), value: 2 }, // 中转
        { label: this.$t('collectionCenter.dispatch'), value: 3 }, // 派送
        { label: this.$t('collectionCenter.finance'), value: 4 }, // 财务
        { label: this.$t('collectionCenter.trackingPackage'), value: 5 }, // 轨迹
        { label: this.$t('collectionCenter.BasicInformation'), value: 6 }, // 基础资料
        { label: this.$t('collectionCenter.serviceQuality'), value: 7 }, // 服务质量
        { label: this.$t('collectionCenter.other'), value: 0 } // 其他
      ],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          createStartTime: '',
          createEndTime: '',
          moduleCode: '',
          titleName: ''
        }
      },
      total: 0,
      tableDate: [],
      drawerIsOpen: false,
      direction: 'ltr',
      drawerTitle: '',
      fileList: [],
      editFormId: '',
      submitForm: {
        titleName: '',
        moduleCode: '',
        versionInfo: '',
        attachment: ''
      },
      rules: {
        titleName: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        moduleCode: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }]
      },
      isSave: false
    };
  },
  created() {
    this.default();
    this.searchClick();
  },
  methods: {
    default() {
      const d = new Date();
      const end = dayjs(d).format('YYYY-MM-DD 23:59:59');
      const start = dayjs(d).subtract(1, 'year').format('YYYY-MM-DD 00:00:00');
      this.timeValue = [start, end];
      this.queryForm.params = {
        createStartTime: start,
        createEndTime: end,
        moduleCode: '',
        titleName: ''
      };
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        return;
      }
      this.queryForm.params.createStartTime = val[0];
      this.queryForm.params.createEndTime = val[1];
    },
    uploadUrl(url) {
      window.open(url);
    },
    getPage() {
      const queryForm = JSON.parse(localStorage.getItem('operationGuideForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      operationGuidePage(queryForm).then(res => {
        if (res.code===200) {
          this.total = res.data.total;
          this.tableDate = res.data.records.map(arr => {
            arr.attachment = JSON.parse(arr.attachment);
            return {
              ...arr
            };
          });
        }
      });
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const queryForm = JSON.parse(localStorage.getItem('operationGuideForm')) || this.queryForm;
      queryForm.pageNumber = 1;
      queryForm.pageSize = this.queryForm.pageSize;
      operationGuidePage(queryForm).then(res => {
        if (res.code===200) {
          this.total = res.data.total;
          this.tableDate = res.data.records.map(arr => {
            arr.attachment = JSON.parse(arr.attachment);
            return {
              ...arr
            };
          });
        }
      });
    },
    searchClick() {
      this.queryForm.pageNumber = 1;
      localStorage.setItem('operationGuideForm', JSON.stringify(this.queryForm));
      this.getPage();
    },
    resetClick() {
      this.default();
    },
    addClick() {
      this.drawerIsOpen = true;
      this.drawerTitle = this.$t('GlobalSetObj.add'); // 新增
    },
    handleClose() {
      // 清空 + 关闭
      this.submitForm = {};
      this.$refs['drawerFormRef'].clearValidate();
      this.drawerIsOpen = false;
      this.isSave = false;
      this.fileList = [];
    },
    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['PDF', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'png', 'jpg', 'bmg', 'jpeg', 'txt', 'MP4', 'mp4', 'GIF', 'gif'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        // 上传文件支持PDF、doc、docx、xls、xlsx、csv、png、jpg、bmg、jpeg、txt、MP4、GIF等格式
        this.$message.error(this.$t('collectionCenter.uploadFileFormat'));
        return false;
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        // 上传文件大小不能超过 10MB
        this.$message.error(this.$t('collectionCenter.uploadNot10MB'));
        return false;
      }
    },
    async uploadClick(option) {
      this.isUploadOk = false;
      const file = option.file;
      const res = await uploadOos(file, 'resource');
      if (res.code === 200) {
        this.isUploadOk = true;
        const obj = {
          name: file.name,
          url: res.url
        };
        this.fileList = [...this.fileList, obj];
      } else {
        this.$message.error(res.msg);
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.isUploadOk = true;
    },
    saveClick() {
      this.isSave = true;
      if (!this.isUploadOk && this.fileList.length <= 5) {
        this.$message.warning(this.$t('collectionCenter.pleaseWait'));
        return;
      }
      this.$refs['drawerFormRef'].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          if (this.fileList.length <= 0) return;
          this.submitForm.attachment = this.fileList.map(arr => {
            return {
              file_name: arr.name.split('.')[0],
              file_type: arr.name.split('.')[1],
              file_url: arr.url,
              oss_path: 'resource/' + arr.name
            };
          });
          const form = this.submitForm;
          form.attachment = JSON.stringify(form.attachment);
          if (this.drawerTitle === this.$t('GlobalSetObj.add')) {
            operationGuideAdd(form).then(res => {
              if (res.code===200) {
                this.drawerIsOpen = false;
                this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully')); // '保存成功'
                this.default();
                this.searchClick();
                this.handleClose();
              }
            }).catch({});
          }
          if (this.drawerTitle === this.$t('GlobalSetObj.edit')) {
            form.id = this.editFormId;
            operationGuideEdit(form).then(res => {
              if (res.code===200) {
                this.drawerIsOpen = false;
                this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully')); // '保存成功'
                this.searchClick();
                this.handleClose();
              }
            }).catch({});
          }
        }
      });
    },
    editRow(row) {
      this.isUploadOk = true;
      this.drawerIsOpen = true;
      this.drawerTitle = this.$t('GlobalSetObj.edit');
      this.fileList = row.attachment.map(list => {
        return {
          name: list.file_name + '.' + list.file_type,
          url: list.file_url
        };
      });
      this.editFormId = row.id;
      this.submitForm = {
        titleName: row.titleName,
        moduleCode: row.moduleCode,
        versionInfo: row.versionInfo,
        attachment: ''
      };
    }
  }
};
</script>

<style lang="scss" scoped>
  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .drawer.selectClass ::v-deep .el-form-item, .drawer.selectClass ::v-deep .el-form-item__content {
    width: 100%;
  }
  .drawer.selectClass ::v-deep textarea.el-textarea__inner {
    min-height: 40px !important;
    max-height: 200px !important;
  }
  .drawer.selectClass ::v-deep .el-textarea .el-input__count {
    background: none !important;
  }
  .fileClass ::v-deep .el-form-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start !important;
  }
  .fileClass ::v-deep .el-form-item__label {
    margin-top: 10px !important;
  }
  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .selectClass ::v-deep .el-select{
    width: 100%;
  }
  ::v-deep .el-table .el-table__header{
    width: 100%;
    border-collapse: inherit;
  }
</style>
