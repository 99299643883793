<template>
  <div class="warp" style="padding:0 20px;height: calc(100vh - 80px); overflow: auto;">
    <el-form
      :inline="true" :model="searchForm.params" class="search-form"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item :label="$t('GlobalSetObj.waybillNumber')" style="width: 100%">
            <el-input
              v-model="searchForm.params.waybillNumber"
              :placeholder="$t('orderCenterCont.MultipleLineBreaks')"
              :rows="2"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 网点所属州 -->
          <el-form-item :label="$t('collectionCenter.stateSite1')" style="width: 100%">
            <el-select-virtual-list
              v-model="searchForm.params.state"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              :select-data="selectData"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('GlobalSetObj.entrega')" style="width: 100%">
            <el-select
              v-model="searchForm.params.deliverStationId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="item in siteIdList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="margin-bottom: 20px">
      <el-button size="small" type="primary" @click="search">{{ $t('newOrder.search') }}</el-button>
      <el-button size="small" @click="resetForm">{{ $t('newOrder.reset') }}</el-button>
      <!-- 导出 -->
      <el-button
        :disabled="!tableData.length" icon="el-icon-download" size="small"
        @click="onExport"
      >{{ $t('navbar.export') }}
      </el-button>
    </div>
    <el-table :data="tableData" border style="width: 100%;">
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" prop="index"
        type="index" width="60px"
      />
      <el-table-column :label="$t('GlobalSetObj.waybillNumber')" align="center" prop="waybillNumber" />

      <!-- 网点所属州 -->
      <el-table-column :label="$t('collectionCenter.stateSite1')" align="center" prop="state" />
      <el-table-column :label="$t('GlobalSetObj.entrega')" align="center" prop="deliverStationName" />
      <el-table-column :label="$t('orderCenterCont.dueTime01')" align="center" prop="expectedTime" />
      <el-table-column :label="$t('GlobalSetObj.SigningTime')" align="center" prop="deliveredTime" />
    </el-table>
    <div style="text-align: right">
      <el-pagination
        :current-page.sync="searchForm.pageNumber"
        :page-size.sync="searchForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 15px 0;"
        @current-change="search"
        @size-change="search"
      />
    </div>
  </div>
</template>

<script>
import { searchSiteIdList } from '@/api/lanshou';
import { reportSheinDeliveredDailyByDate, reportSheinDeliveredDailyByDateExport } from '@/api/orderApi';
import { cloneDeep } from 'lodash-es';

export default {
  components: {
    'el-select-virtual-list': () => import('@/components/elSelectVirtualList')
  },
  props: {
    // 收件人州列表
    selectData: {
      type: Object,
      default: () => {
      }
    },
    queryData: {
      type: Object,
      default: () => {
      }
    },
    customerList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      customerList1: this.customerList,
      searchForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          waybillNumber: '',
          state: null,
          deliverStationId: null
        }
      },
      siteIdList: [], // 网点列表
      tableData: [],
      total: 0
    };
  },
  created() {
    // 获取网点列表
    this.getBranchList();
  },
  methods: {
    setQuery() {
      let waybillNumberList = [];
      if (this.searchForm.params.waybillNumber) {
        const order = this.searchForm.params.waybillNumber.split(/[(\r\n)\r\n]+/);
        const orderNoList = [];
        order.map((item) => {
          if (item) {
            orderNoList.push(item.trim());
          }
        });
        waybillNumberList = orderNoList;
      } else {
        waybillNumberList = [];
      }
      const queryForm = cloneDeep(this.searchForm);
      queryForm.params.moduleType = this.queryData.moduleType;
      queryForm.params.expectedTime = this.queryData.expectedTime;
      queryForm.params.region = this.queryData.region;
      queryForm.params.cluster = this.queryData.cluster;
      queryForm.params.customerName = this.queryData.customerName;
      queryForm.params.merchantCode = this.queryData.merchantCode;
      if (Object.keys(this.queryData).length) {
        if (this.queryData.state && this.queryData.state !== '~' && !this.searchForm.params.state) {
          queryForm.params.state = this.queryData.state;
          this.searchForm.params.state = this.queryData.state;
          this.queryData.state = null;
        }
      }
      if (queryForm.params.cluster === 'Total') {
        delete queryForm.params.cluster;
      }
      queryForm.params.waybillNumberList = waybillNumberList;

      return queryForm;
    },

    search() {
      const queryForm = this.setQuery();
      let queryFormCustomerName = null;
      if (queryForm.params.customerName) {
        queryFormCustomerName = this.customerList1.find(item => item.id === queryForm.params.customerName);
        queryForm.params.customerName = queryFormCustomerName.username;
      }
      reportSheinDeliveredDailyByDate(queryForm)
        .then((res) => {
          console.log(res);
          if (res.code===200) {
            this.tableData = res.data.records;
            this.total = res.data.total;
          }
        });
    },
    resetForm() {
      this.searchForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          waybillNumber: '',
          state: null,
          deliverStationId: null
        }
      };
    },
    /**
     * @description 获取网点列表
     */
    getBranchList() {
      searchSiteIdList().then(res => {
        if (res.code===200) {
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },

    // 导出
    onExport() {
      // this.$emit('onExport');
      const { params } = this.setQuery();
      let queryFormCustomerName = null;
      if (params.customerName) {
        queryFormCustomerName = this.customerList1.find(item => item.id === params.customerName);
        params.customerName = queryFormCustomerName.username;
      }
      reportSheinDeliveredDailyByDateExport(params).then(res => {
        if (res.code===200) {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              this.$emit('call-parent-method', false);
              this.$router.push({ path: '/views/resource/exportTaskList' });
            })
            .catch(() => {
            });
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }
};
</script>

<style scoped>
.search-form {
  margin-bottom: 20px;
}

.warp >>> .el-form-item {
  margin-bottom: 0 !important;
}
</style>
