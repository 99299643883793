import request from '@/utils/request';
import newRequest from '@/utils/new-request';

// 理赔审核规则

// 分页
export const apiOperationBigBagInfoPage = (data, isLoading = true) => {
  return newRequest({
    method: 'POST',
    url: '/claimsReviewConfiguration/page',
    data,
    isLoading // 是否显示加载中参数
  });
};

// 添加
export const apiClaimsReviewConfigurationAdd = data => {
  return newRequest({
    method: 'POST',
    url: '/claimsReviewConfiguration/add',
    data // 是否显示加载中参数
  });
};

// 修改
export const apiClaimsReviewConfigurationEdit = data => {
  return newRequest({
    method: 'PUT',
    url: '/claimsReviewConfiguration/edit',
    data // 是否显示加载中参数
  });
};

// 删除
export const apiClaimsReviewConfigurationDelete = ids => {
  return newRequest({
    method: 'delete',
    url: `/claimsReviewConfiguration/delete/${ids}`
  });
};
