// @ts-check
/**
 * 检测字符串是否含有 <img >标签
 * @param {string} str 字符串
 * @returns {boolean}
 */
const isImg = function(str) {
  const reg = /<img[*]*>/g;
  return reg.test(str);
};

/**
 * 转义HTML标签的方法
 * @param  {String} str 需要转义的HTML字符串
 * @return {String}     转义后的字符串
 */
const funEncodeHTML = function funEncodeHTML(str) {
  if (typeof str === 'string') {
    return str.replace(/<[^>]+>|[&nbsp;]*/g, function($) {
      // 空格
      if ($ === '') {
        return '';
      }
      if (isImg($)) {
        return ` [图片] `;
      }
      return '';
    });
  }
  return '';
};

/**
 * 防抖 需要删除（建议使用 lodash-es ）
 */
const debounce = function debounce(func, wait = 1000) {
  let that = null;
  let tiems = 0;
  let rus;
  return function(...rags) {
    that = this;

    clearTimeout(tiems);
    tiems = window.setTimeout(function() {
      rus = func.apply(that, rags);
    }, wait);

    return rus;
  };
};
/**
 * 节流 需要删除 （建议使用 lodash-es ）
 */
const throttling = function throttling(func, wait) {
  let tiems = 0;
  const now = new Date().getTime();
  if (now - tiems > wait) {
    tiems = window.setTimeout(function() {
      clearTimeout(tiems);
      return;
    }, wait);
  }
};

/**
 * 获取最近一个月时间
 */
// function dateMonth() {
//   const end = new Date();
//   const year = end.getFullYear();
//   const month = end.getMonth() + 1;// 0-11表示1-12月
//   const day = end.getDate();
//   const dateObj = {};
//   const endMonthDay = new Date(year, month, 0).getDate(); // 当前月的总天数
//   // 因为要包含当天的一整天时间所以结束时间往后延了一天
//   const currentDay = day + 1;
//   if (currentDay > endMonthDay) {
//     if (month === 12) {
//       dateObj.end = year + 1 + '-' + '01' + '-' + '01';
//     } else {
//       dateObj.end = year + '-' + ((month + 1) < 10 ? '0' + (month + 1) : (month + 1)) + '-' + '01';
//     }
//   } else {
//     dateObj.end = year + '-' + (month < 10 ? '0' + month : month) + '-' + (currentDay < 10 ? '0' + currentDay : currentDay);
//   }
//   if (month - 1 <= 0) { // 如果是1月，年数往前推一年<br>
//     dateObj.start = (year - 1) + '-' + 12 + '-' + (day < 10 ? '0' + day : day);
//   } else {
//     const startMonthDay = new Date(year, (parseInt(month + '') - 1), 0).getDate();
//     if (startMonthDay < day) { // 1个月前所在月的总天数小于现在的天日期
//       if (day < endMonthDay) { // 当前天日期小于当前月总天数
//         dateObj.start = year + '-' + ((month - 1) < 10 ? '0' + (month - 1) : (month - 1)) + '-' + ((startMonthDay - (endMonthDay - day)) < 10 ? '0' + (startMonthDay - (endMonthDay - day)) : (startMonthDay - (endMonthDay - day)));
//       } else {
//         dateObj.start = year + '-' + ((month - 1) < 10 ? '0' + (month - 1) : (month - 1)) + '-' + (startMonthDay < 10 ? '0' + startMonthDay : startMonthDay);
//       }
//     } else {
//       dateObj.start = year + '-' + ((month - 1) < 10 ? '0' + (month - 1) : (month - 1)) + '-' + (day < 10 ? '0' + day : day);
//     }
//   }
//   return [dateObj.start + ' 00:00:00', dateObj.end + ' 00:00:00'];
// }

// const monthTime = dateMonth();

/**
 * http 转 https
 * @param {string} str
 * @returns {string} 返回带 https的网址
 */
function setHttpTurnHttps(str) {
  if (!str) return;
  const isBool = str.startsWith('https://');
  if (isBool) {
    // 已经是 https
    return str;
  }
  const re = /^(http):\/\//;
  return str.replace(re, function() {
    return 'https://';
  });
}

// 用数组中对象的值递减排序
// function compare(property) {
//   return function(a, b) {
//     const value1 = a[property];
//     const value2 = b[property];
//     return value2 - value1;
//   };
// }
// 递增排序
// function Increasing(property) {
//   return function(a, b) {
//     const value1 = a[property];
//     const value2 = b[property];
//     return value1 - value2;
//   };
// }

/**
 * 哈希生成器
 */
class HashGenerator {
  /**
   * 生成复杂哈希值
   * @param {string} str - 要哈希的字符串
   * @param {number} length - 期望的哈希长度（默认64位）
   * @returns {string} - 返回哈希值
   */
  static complexHash(str, length = 64) {
    // 初始化哈希数组
    const hashArray = new Array(length).fill(0);

    // 添加时间戳
    const input = str + Date.now();

    // 第一轮：基础字符处理
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      for (let j = 0; j < length; j++) {
        hashArray[j] = (hashArray[j] * 31 + char * (j + 1)) >>> 0;
      }
    }

    // 第二轮：混淆
    for (let i = 0; i < length; i++) {
      hashArray[i] = (hashArray[i] ^ (hashArray[(i + 1) % length] << 5)) >>> 0;
      hashArray[i] = (hashArray[i] * 1597 + 51749) >>> 0;
    }

    // 转换为十六进制
    return hashArray
      .map(num => num.toString(16).padStart(8, '0'))
      .join('')
      .substring(0, length);
  }

  /**
   * 生成简单哈希值
   * @param {string} str - 要哈希的字符串
   * @returns {string} - 返回哈希值
   */
  static simpleHash(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash;
    }
    return Math.abs(hash).toString(16);
  }

  /**
   * 生成 UUID v4
   * @returns {string} - 返回 UUID
   */
  static uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  /**
   * 生成带时间戳的哈希值
   * @param {string} str - 要哈希的字符串
   * @returns {string} - 返回哈希值
   */
  static timeBasedHash(str) {
    const timestamp = new Date().getTime();
    return this.complexHash(str + timestamp);
  }
}

export {
  funEncodeHTML,
  debounce,
  throttling,
  // monthTime,
  setHttpTurnHttps,
  // Increasing
  HashGenerator
};

