<template>
  <div class="app-container">
    <el-form
      ref="ruleFormRef"
      :inline="true"
      :model="queryForm"
      label-position="top"
      size="large"
      @submit.native.prevent
    >
      <el-form-item :label="$t('GlobalSetObj.scannerBig')" prop="waybillBagNumber">
        <el-input
          ref="inputRef"
          v-model.trim="queryForm.waybillBagNumber"
          clearable
          style="width:320px"
          @keyup.enter.native="arrivedOut"
        />
      </el-form-item>
      <el-form-item :label="$t('GlobalSetObj.transportCenter')" prop="waybillBagNumber">
        <div class="huojia">{{ transferCenterName || "--" }}</div>
      </el-form-item>
    </el-form>
    <div class="btnRow">
      <!--  上传    -->
      <el-button size="medium" type="primary" @click="UploadData">{{ $t("GlobalSetObj.Upload") }}</el-button>
      <!--    已扫  -->
      <div class="header-button-ri">
        <span
          class="hasScan"
        >{{ $t("GlobalSetObj.swept") }}:<i>{{ queryForm.total }}</i></span>
      </div>
    </div>
    <!--  表格数据  -->
    <el-table
      :data="tableDataShow"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')"
        align="center"
        prop="serial"
        width="80"
      >
        <template #default="scope">
          <i v-if="scope.row.first" class="firstOrder centerBtn">{{ "New" }}</i>
          <div v-else class="centerBtn">{{ scope.row.serial }}</div>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        :label="$t('newOrder.waybillNumber')"-->
      <!--        min-width="160"-->
      <!--        prop="waybillNumber"-->
      <!--      >-->
      <!--        <template #default="scope">-->
      <!--          <div :class="{ firstOrder: scope.row.first }">{{ scope.row.waybillNumber || '&#45;&#45;' }}</div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
        :label="$t('GlobalSetObj.ScanNumber')"
        min-width="160"
        prop="scanNumber"
      >
        <template #default="scope">
          <div :class="{ firstOrder: scope.row.first }">{{ scope.row.scanNumber || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('collectionCenter.scanTime')"
        min-width="180"
        prop="scanDateTime"
      />
      <el-table-column
        :label="$t('collectionCenter.scanner')"
        min-width="150"
        prop="scanUser"
      />
      <el-table-column
        :label="$t('GlobalSetObj.operate')"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <div class="centerBtn">
            <i
              class="el-icon-delete"
              style="color:red;cursor:pointer;font-size: 20px"
              @click="handleDelete(scope.row)"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[50, 100, 200, 400]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
import { warehouseDelete, warehousereceive, warehouseReceivePage, warehousereceiveUpload } from '@/api/automaticSorting';

const okMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/su.wav';
const errorMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/print_error.wav';
export default {
  name: 'ArrivedBox',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      okMp3,
      errorMp3,
      queryForm: {
        pageSize: 50,
        pageNumber: 1,
        total: 0,
        waybillBagNumber: ''
      },
      tableData: [],
      tableDataShow: [],
      transferCenterName: ''
    };
  },
  activated() {
    this.$nextTick(_ => {
      this.$refs.inputRef.$refs.input.focus();
    });
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.$nextTick(_ => {
      this.$refs.inputRef.$refs.input.focus();
    });
    this.getSearch();
  },
  methods: {
    // 前端分页
    getscannedData() {
      const startIndex = (this.queryForm.pageNumber - 1) * this.queryForm.pageSize;
      const endIndex = startIndex + this.queryForm.pageSize;
      this.tableDataShow = this.tableData.slice(startIndex, endIndex);
    },
    // 查询
    getSearch() {
      warehouseReceivePage().then(res => {
        if (res.code===200) {
          this.tableData = res.data;
          this.queryForm.total = this.tableData.length;
          if (this.queryForm.total === 0) {
            this.transferCenterName = '--';
          }
          this.tableData.forEach((item, index) => {
            item.serial = index + 1;
            if (item.serial === 1) {
              item.first = true;
            }
          });
          this.getscannedData();
          console.log(this.tableData, 'tableData');
        }
      });
    },
    // 扫描
    arrivedOut() {
      this.$refs['ruleFormRef'].validate((valid) => {
        if (valid) {
          if (this.queryForm.waybillBagNumber) {
            // const flag = this.tableData.some(item => {
            //   return item.waybillNumber === this.queryForm.waybillBagNumber;
            // });
            const flag1 = this.tableData.some(item => {
              return item.scanNumber === this.queryForm.waybillBagNumber;
            });
            if (flag1) {
              this.$message.error(this.$t('GlobalSetObj.notrepeatOperation'));
              this.$refs.errRef.play();
              this.$nextTick(_ => {
                this.$refs.inputRef.$refs.input.select();
              });
              return;
            }
            warehousereceive(this.queryForm.waybillBagNumber).then(res => {
              if (res.code===200) {
                this.queryForm.waybillBagNumber = '';
                this.$refs.okRef.play();
                this.$nextTick(_ => {
                  this.$refs.inputRef.$refs.input.focus();
                });
                if (res.data.tipsMsg) {
                  this.$message.warning(res.data.tipsMsg);
                }
                this.transferCenterName = res.data.transferCenterName;
                this.getSearch();
              }
            }).catch(() => {
              this.$refs.errRef.play();
              this.$nextTick(_ => {
                this.$refs.inputRef.$refs.input.select();
              });
            });
          } else {
            this.getSearch();
            // return false;
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 点击删除
    handleDelete(row) {
      warehouseDelete(row.scanRecordId).then(res => {
        if (res.code===200) {
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.getSearch();
        }
      }).catch(() => {
        this.$refs.errRef.play();
      });
    },
    // 上传
    UploadData() {
      if (this.tableData.length === 0) {
        this.$message.warning(this.$t('GlobalSetObj.notDataUpload'));
        return;
      }
      // 运单号
      const scanNumbers = [];
      // 扫描的id
      const scanRecordIds = [];
      this.tableData.forEach(item => {
        scanNumbers.push(item.scanNumber);
        scanRecordIds.push(item.scanRecordId);
      });
      const data = {
        scanNumbers, // 运单号
        scanRecordIds
      };
      warehousereceiveUpload(data).then(res => {
        if (res.code===200) {
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.getSearch();
        }
      }).catch(() => {
        this.$refs.errRef.play();
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-dialog__body {
  padding: 10px 20px;
}

.app-container >>> .el-form-item {
  margin-bottom: 0;
}

.btnRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.hasScan {
  padding: 3px 10px;
  font-weight: 600;
  margin-left: 10px;
  font-size: 16px;
}

.hasScan i {
  font-style: normal;
  font-size: 22px;
  margin-left: 10px;
  color: #409eff;
}

.centerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.firstOrder {
  font-size: 16px;
  color: #409eff;
  font-weight: bold;
}

.huojia {
  min-width: 210px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  font-size: 30px;
  font-weight: bold;
  padding: 0 10px;
}
</style>
