<template>
  <div class="app-container">
    <div style="margin-bottom: 10px">
      <el-card class="box-card" shadow="never">
        <div style="display: flex">
          <div style="display: flex; align-items: center; flex: 6">
            <div style="margin-right: 10px">{{ $t('ChannelManage.oddNumbers') }}:</div>
            <el-input
              ref="inputRef"
              v-model.trim="bagNo"
              :autofocus="autofocus"
              :placeholder="$t('ChannelManage.inputoddNumbers')"
              clearable
              style="width: 40%"
              @keyup.enter.native="eventEnter()"
            />
            <el-button
              :disabled="tableDataLeft.length === 0"
              style="margin-left: 20px"
              type="warning"
              @click="determineClosure()"
            >
              {{ $t('GlobalSetObj.envelope') }}
            </el-button>
          </div>
          <!--    显示信息    -->
          <div style="flex: 4; line-height: 35px; font-size: 18px">
            <div>
              <span>{{ $t('ChannelManage.serviceProvider') }}</span>:<span style="margin-left: 10px">{{ systemName || '- -' }}</span>
            </div>
            <div>
              <span>{{ $t('GlobalSetObj.DeliveryPoint') }}</span>:<span style="margin-left: 10px">{{ dispthSite || '- -' }}</span>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <el-row :gutter="10">
      <el-col :span="13">
        <el-card class="box-card" shadow="never">
          <div class="currentBagHead" style="margin-bottom: 10px">
            <div style="display: flex; align-items: center">
              <!--      运单数        -->
              <div>
                <span class="titleStyle">{{ $t('GlobalSetObj.CurrentBagging') }}</span>
                <span style="margin-left: 20px">{{ $t('ChannelManage.waybillNumber') }}: </span>
                <span style="color: #dd1b33">{{ tableDataLeft.length }}</span>
              </div>
              <!--      总重量        -->
              <div>
                <span style="margin-left: 30px">{{ $t('ChannelManage.totalWeight') }}(KG): </span>
                <span style="color: #dd1b33">{{ TotalWeight.toFixed(3) }}</span>
              </div>
            </div>
            <!--重置-->
            <el-button
              icon="el-icon-refresh-left" size="small" @click="resetClick"
            >{{ $t('newOrder.reset') }}
            </el-button>
          </div>
          <el-table
            :data="tableDataLeftShow" border height="590px"
            style="width: 100%"
          >
            <el-table-column :label="$t('GlobalSetObj.waybillNumber')" min-width="150">
              <template slot-scope="scope">
                <div>{{ scope.row.waybill_number || '- -' }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('placeOrder.customerNumber')" min-width="150">
              <template slot-scope="scope">
                <div>{{ scope.row.customerNumber || '- -' }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('GlobalSetObj.serviceLogistics')" min-width="150">
              <template slot-scope="scope">
                <div>{{ scope.row.logistics_number || '- -' }}</div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('GlobalSetObj.weight')"
              align="center"
              min-width="130"
              prop="weight"
            />
            <el-table-column
              :label="$t('GlobalSetObj.operate')"
              align="center"
              fixed="right"
              prop="date"
              width="100"
            >
              <template slot-scope="scope">
                <div style="color: #f56c6c; cursor: pointer" @click="removeItem(scope)">
                  {{ $t('GlobalSetObj.MoveOut') }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--  分页  -->
          <el-pagination
            :current-page="packageList.pageNumber"
            :page-size="packageList.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :total="packageList.total"
            background
            layout="total, sizes, prev, pager, next"
            small
            style="margin-top: 10px; float: right; margin-bottom: 20px"
            @size-change="packageSizeChange"
            @current-change="packageCurrentChange"
          />
        </el-card>
      </el-col>
      <el-col :span="11">
        <el-card class="box-card" shadow="never">
          <div style="margin-bottom: 10px">
            <span class="titleStyle">{{ $t('GlobalSetObj.BaggingRecord') }}</span>
          </div>
          <el-form inline label-position="top">
            <el-form-item :label="$t('GlobalSetObj.bagNumber')">
              <el-input
                v-model.trim="queryForm.params.bagNumber"
                :placeholder="$t('GlobalSetObj.bagNumber')"
                clearable
                size="small"
              />
            </el-form-item>
            <!--封袋时间-->
            <el-form-item :label="$t('ChannelManage.BagSealingTime')" prop="searchTimeType">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                :default-time="['00:00:00', '23:59:59']"
                :format="$i18n.locale == 'zh' ? zhDate : ptDate"
                :picker-options="pickerOptions"
                :placeholder="$t('newOrder.SelectDatetime')"
                range-separator="~"
                size="small"
                style="width: 350px"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="handleTime"
              />
            </el-form-item>
            <el-form-item label="" style="margin-top: 27px">
              <!--搜索-->
              <el-button
                size="small" type="primary" @click="getBagSealingRecords"
              >{{ $t('newOrder.search') }}
              </el-button>
            </el-form-item>
          </el-form>
          <div class="elTable">
            <el-table
              :data="tableDataRight" border height="500px"
              style="width: 100%"
            >
              <el-table-column
                :label="$t('GlobalSetObj.bagNumber')"
                min-width="160"
                prop="transitBagNumber"
              />
              <el-table-column
                :label="$t('ChannelManage.totalWeightkg')"
                align="center"
                min-width="110"
                prop="totalWeight"
              />
              <el-table-column
                :label="$t('ChannelManage.BagSealingTime')"
                align="center"
                min-width="150"
                prop="packageTime"
              />
              <el-table-column
                :label="$t('GlobalSetObj.operate')"
                align="center"
                fixed="right"
                min-width="120"
                prop="date"
              >
                <template slot-scope="scope">
                  <div class="operator-wrap">
                    <!--更新-->
                    <el-button
                      size="small" type="text" @click="updateFun(scope.row)"
                    >{{ $t('GlobalSetObj.update') }}
                    </el-button>
                    <!--打印袋号-->
                    <el-button
                      size="small" type="text" @click="printBagNo(scope.row)"
                    >{{ $t('GlobalSetObj.Print') }}
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--  分页  -->
          <el-pagination
            :current-page="queryForm.pageNumber"
            :page-size="queryForm.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :total="queryForm.total"
            background
            layout="total, sizes, prev, pager, next"
            small
            style="margin-top: 10px; float: right; margin-bottom: 20px"
            @size-change="SizeChange"
            @current-change="CurrentChange"
          />
        </el-card>
      </el-col>
    </el-row>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
    <!--  扫描录入袋号  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.EntryBagNumber')"
      :visible.sync="scannerDialog"
      class="scannerBag"
      width="600px"
    >
      <el-form
        ref="ruleForm"
        :model="bagForm"
        :rules="rules"
        class="demo-ruleForm"
        inline
        label-width="auto"
        @submit.native.prevent
      >
        <el-form-item :label="$t('GlobalSetObj.bagNumber')" prop="scannerBagNo">
          <el-input
            ref="inputBagNo"
            v-model="bagForm.scannerBagNo"
            :placeholder="$t('GlobalSetObj.scannerOrinput')"
            clearable
            style="width: 250px"
          />
        </el-form-item>
        <el-form-item label="">
          <el-button type="text" @click="generateBagNo">{{
            $t('ChannelManage.GenerateBag')
          }}</el-button>
        </el-form-item>
      </el-form>
      <div style="text-align: right">
        <el-button size="small" type="info" @click="cancelClick">{{
          $t('GlobalSetObj.cancel')
        }}</el-button>
        <el-button size="small" type="primary" @click="confirmBagger">{{
          $t('GlobalSetObj.determine')
        }}</el-button>
      </div>
    </el-dialog>
    <!--打印大包号-->
    <div class="printStyle">
      <printBagNumberHtml
        v-if="showPrintBagNumberHtml"
        :param-data="printBagObj"
        @hiddenBagNumberHtml="hiddenBagNumberHtml"
      />
    </div>
  </div>
</template>

<script>
import {
  apiQueryTransitBag,
  apiSaveTransitBag,
  apiUpdateTransitBag,
  generateTransitBagNumber,
  pageTransitBag,
  queryTransitBagNumber
} from '@/api/storeAdmin';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import printBagNumberHtml from './printBagNumber';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';

const okMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/su.wav';
const errorMp3 =
  'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/print_error.wav';
export default {
  name: 'ScanBagging',
  components: {
    printBagNumberHtml
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          // 获取当前时间的时间戳
          const currentTimeStamp = Date.now();
          // 将时间戳转换为 Date 对象
          const currentDate = new Date(currentTimeStamp);
          // 将日期加一天
          currentDate.setDate(currentDate.getDate() + 1);
          // 获取加一天后的时间戳
          const nextDayTimeStamp = currentDate.getTime();
          return (
            time.getTime() > nextDayTimeStamp ||
            time.getTime() < Date.now() - 7 * 24 * 60 * 60 * 1000
          );
        }
      },
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      printBagObj: {},
      // 扫描录入袋号
      errorTip: '',
      first: false, // 是否为首次封袋
      bagForm: {
        scannerBagNo: ''
      },
      rules: {
        scannerBagNo: [
          {
            required: true,
            message: this.$t('GlobalSetObj.pleaseInput'),
            trigger: ['blur', 'change']
          },
          {
            validator: (rule, value, callback) => {
              const regex = /^(BG)?\d{6,8}$/;
              if (!regex.test(value)) {
                return callback(this.$t('ChannelManage.inputNumberStr610'));
              } else {
                callback();
              }
            }
          }
        ]
      },
      errorShow: false, // 错误提示
      scannerDialog: false, // 扫描录入袋号
      bagNo: '',
      updateBagNum: '',
      tableDataLeft: [],
      tableDataRight: [],
      height: 400,
      currentBagNumber: '',
      showUpdateNum: false,
      okMp3,
      errorMp3,
      userIdAndNameObj: null,
      showErr1: false,
      errMessage1: '',
      total: 0,
      isFirst: true,
      printObj: {},
      MoveIn: false,
      Remove: false,
      TotalWeight: 0,
      packageList: {
        pageNumber: 1,
        pageSize: 10,
        total: 0
      },
      tableDataLeftShow: [],
      timer: 0,
      autofocus: true,
      showPrintBagNumberHtml: false,
      Unloadingpoint: null,
      systemName: '', // 服务商
      dispthSite: '', // 派送网点
      queryForm: {
        // 查询袋号
        total: 0,
        pageSize: 10,
        pageNumber: 1,
        params: {
          currentUserId: '',
          bagNumber: '',
          beginTime: '',
          endTime: ''
        }
      }
    };
  },
  watch: {
    tableDataLeft: {
      handler: function(val) {
        this.$store.dispatch('tagsView/scannerBagg', val.length);
        this.packageList.total = val.length;
      }
    },
    scannerDialog: {
      handler: function() {
        this.$refs['ruleForm']?.resetFields();
        this.$nextTick(() => {
          this.$refs.inputBagNo.$refs.input.focus();
        });
      }
    }
  },
  mounted() {
    const str = sessionStorage.getItem('currentPackageList');
    let arr = null;
    if (str) {
      arr = JSON.parse(str);
      this.tableDataLeft = [...arr];
    }
    if (this.tableDataLeft.length) {
      let totalWeight = 0;
      this.tableDataLeft.map((item) => {
        totalWeight = item.weight + totalWeight;
      });
      this.TotalWeight = totalWeight;
      this.Unloadingpoint = this.tableDataLeft[0].preDeliverStationId;
      this.systemName = this.tableDataLeft[0].systemName;
      this.dispthSite = this.tableDataLeft[0].siteName;
      const result = this.tableDataLeft.filter((obj) => obj.transit_bag_number !== '');
      if (result.length) {
        this.first = false;
        this.currentBagNumber = result[0].transit_bag_number;
        this.updateBagNum = this.currentBagNumber; // 更新袋号(旧袋号)
      } else {
        this.currentBagNumber = '';
        this.updateBagNum = '';
        this.first = true;
      }
    }
    this.$nextTick(() => {
      if (arr) {
        this.packageList.total = arr.length;
        // 计算起始索引和结束索引
        arr.map((item, index) => {
          item.indexNo = this.packageList.total - index;
        });
        const startIndex = (this.packageList.pageNumber - 1) * this.packageList.pageSize;
        const endIndex = startIndex + this.packageList.pageSize;
        // 使用 slice 方法获取当前页的数据
        this.tableDataLeftShow = arr.slice(startIndex, endIndex);
      }
    });
  },
  activated() {
    this.$nextTick((_) => {
      this.$refs.inputRef.$refs.input.focus();
    });
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.height = window.document.body.offsetHeight - 360;
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getTimer();
    this.getBagSealingRecords();
    this.$nextTick((_) => {
      this.$refs.inputRef.$refs.input.focus();
    });
  },
  methods: {
    // 获取最近一星期时间
    getTimer() {
      let today = '';
      let oneWeekAgo = '';
      if (this.isTormat) {
        const day = new Date();
        const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
        today = dayjs(brazilTime).format('YYYY-MM-DD');
        oneWeekAgo = dayjs().format('YYYY-MM-DD');
      } else {
        today = dayjs().format('YYYY-MM-DD');
        oneWeekAgo = dayjs().format('YYYY-MM-DD');
      }
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    // 重置
    resetClick() {
      // this.getTimer();
      this.tableDataLeft = [];
      this.Unloadingpoint = null;
      this.tableDataLeftShow = [];
      this.cachArray(this.tableDataLeft);
      this.currentBagNumber = '';
      this.updateBagNum = '';
      this.systemName = '';
      this.dispthSite = '';
      this.TotalWeight = 0;
      this.queryForm.pageNumber = 1;
      this.scannerDialog = false;
      this.first = false;
    },
    // 缓存数组
    cachArray(arr) {
      const str = JSON.stringify(arr);
      sessionStorage.setItem('currentPackageList', str);
    },
    cachArraySave(arr) {
      const str = JSON.stringify(arr);
      sessionStorage.setItem('currentPackageList', str);
    },
    // 清空打印袋号数据
    hiddenBagNumberHtml() {
      this.showPrintBagNumberHtml = false;
      this.printBagObj = {};
    },
    showUpdateNumDialog() {
      this.Remove = false;
      this.MoveIn = true;
      if (this.currentBagNumber) {
        if (this.tableDataLeft.length !== 0) {
          this.showUpdateNum = false;
          this.$message.warning(this.$t('GlobalSetObj.bagIsSealed'));
        } else {
          this.showUpdateNum = true;
        }
      } else {
        this.showUpdateNum = true;
      }
    },
    // 确定更新袋号
    determineUpdateBagNumber() {
      if (this.tableDataLeft.length > 0) {
        this.closureBag(1);
      } else {
        const param = {
          transitBagNumber: this.updateBagNum
        };
        apiUpdateTransitBag(param).then((res) => {
          if (res.code === 200) {
            this.first = false;
            if (res.data.list) {
              this.tableDataLeft = res.data.list;
              this.tableDataLeft.map((item) => {
                item.isRemove = true;
              });
              let totalWeight = 0;
              this.tableDataLeft.map((item) => {
                totalWeight = item.weight + totalWeight;
              });
              this.TotalWeight = totalWeight;
              this.cachArraySave(this.tableDataLeft);
              this.getCurreentList();
            }
            this.currentBagNumber = res.data.bagNumber;
            this.showUpdateNum = false;
          }
        });
      }
    },
    // 时间
    handleTime(val) {
      console.log(val, '时间');
    },
    // 查询封袋记录
    getBagSealingRecords() {
      return new Promise((resolve, reject) => {
        this.queryForm.params.currentUserId = this.$store.getters.userId;
        this.queryForm.params.bigBagStatus = 1;
        if (this.timeValue) {
          this.queryForm.params.beginTime = this.timeValue[0];
          this.queryForm.params.endTime = this.timeValue[1];
        } else {
          this.queryForm.params.beginTime = '';
          this.queryForm.params.endTime = '';
        }
        // todo xiaibai
        pageTransitBag(this.queryForm).then((resp) => {
          if (resp.code === 200) {
            this.total = resp.data.total;
            this.queryForm.total = resp.data.total;
            this.tableDataRight = resp.data.records.map((item) => {
              if (!item.updateTime) {
                item.updateTime = item.createTime;
              }
              return { ...item };
            });
          }
          resolve();
        });
      });
    },
    // 封袋
    closureBag(type) {
      let serverChannelName = '';
      let serverChannelId = '';
      let serverChannelCode = '';
      let pathNumber = '';
      let providerId = '';
      let providerName = '';
      let legacyTransitBagNumber = '';
      let systemCode = '';
      let preDeliverStationId = '';
      let siteState = '';
      let getSealType = '';
      const dtoList = this.tableDataLeft.map((item) => {
        return {
          logisticsNumber: item.logistics_number,
          orderWaybill: item.waybill_number,
          weight: item.weight,
          quantity: item.package_count,
          id: item.id,
          customerName: item.customer_name
        };
      });
      if (this.tableDataLeft.length) {
        serverChannelName = this.tableDataLeft[0].server_channel_name;
        serverChannelId = this.tableDataLeft[0].server_channel_id;
        serverChannelCode = this.tableDataLeft[0].provider_channel_code;
        pathNumber = this.tableDataLeft[0].pathNumber;
        providerId = this.tableDataLeft[0].providerId;
        providerName = this.tableDataLeft[0].providerName;
        systemCode = this.tableDataLeft[0].systemCode;
        preDeliverStationId = this.tableDataLeft[0].preDeliverStationId;
        siteState = this.tableDataLeft[0].siteState;
        getSealType = this.tableDataLeft[0].getSealType;
      }
      if (this.updateBagNum.toLowerCase() === this.bagForm.scannerBagNo.toLowerCase()) {
        legacyTransitBagNumber = '';
      } else {
        legacyTransitBagNumber = this.updateBagNum;
      }
      const param = {
        legacyTransitBagNumber, // 旧袋号
        dtoList,
        transitBagNumber: this.currentBagNumber,
        serverChannelName,
        serverChannelId,
        serverChannelCode,
        totalWeight: this.TotalWeight,
        pathNumber,
        providerId,
        providerName,
        systemCode,
        preDeliverStationId,
        siteState,
        getSealType
      };
      apiSaveTransitBag(param).then((res) => {
        if (res.code === 200) {
          sessionStorage.removeItem('currentPackageList');
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.tableDataLeft = [];
          this.Unloadingpoint = null;
          this.tableDataLeftShow = [];
          this.cachArray(this.tableDataLeft);
          this.currentBagNumber = '';
          this.systemName = '';
          this.dispthSite = '';
          this.updateBagNum = '';
          this.TotalWeight = 0;
          this.queryForm.pageNumber = 1;
          this.scannerDialog = false;
          this.first = false;
          this.getBagSealingRecords().then(() => {
            if (this.tableDataRight.length > 0) {
              console.log(this.tableDataRight[0]);
              this.printBagNo(this.tableDataRight[0]);
            }
          });
          if (type === 1) {
            const param = {
              transitBagNumber: this.updateBagNum
            };
            apiUpdateTransitBag(param).then((res) => {
              if (res.code === 200) {
                if (res.data.list) {
                  this.tableDataLeft = res.data.list;
                  this.cachArray(this.tableDataLeft);
                }
                this.currentBagNumber = res.data.bagNumber;
                this.showUpdateNum = false;
              }
            });
          }
        }
      });
    },
    // 生成袋号
    async generateBagNo() {
      let res = await generateTransitBagNumber();
      // 重试十次，生成不了就提示，生成成功就赋值并且停止请求
      let num = 1;
      if (!res.data) {
        while (num < 10) {
          res = await generateTransitBagNumber();
          if (res.data) {
            break;
          }
          num++;
        }
      }
      this.bagForm.scannerBagNo = res.data;
      console.log(res, 'res生成袋号');
      console.log(this.bagForm.scannerBagNo, 'randomNumber');
    },
    // 取消封袋
    cancelClick() {
      this.scannerDialog = false;
      this.bagForm.scannerBagNo = '';
    },
    // 扫描录入袋号
    confirmBagger() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.updateBagNum.toLowerCase() === this.bagForm.scannerBagNo.toLowerCase()) {
            this.currentBagNumber = this.bagForm.scannerBagNo;
            this.closureBag();
          } else {
            const data = {};
            data.transitBagNumber = this.bagForm.scannerBagNo;
            queryTransitBagNumber(data)
              .then((res) => {
                if (res.code === 200) {
                  this.errorShow = false;
                  this.$message.success(this.$t('GlobalSetObj.BagAvailable'));
                  this.currentBagNumber = this.bagForm.scannerBagNo;
                  this.closureBag();
                }
              })
              .catch((e) => {
                this.$nextTick(() => {
                  this.$refs.inputBagNo.$refs.input.select();
                });
              });
          }
        } else {
          this.$nextTick(() => {
            this.$refs.inputBagNo.$refs.input.select();
          });
        }
      });
    },
    // 确定封袋确认
    determineClosure() {
      if (this.tableDataLeft.length === 0) {
        this.$message.error('请先扫描单号');
      } else {
        this.scannerDialog = true;
        console.log(this.updateBagNum, 'this.updateBagNum');
        if (this.updateBagNum) {
          this.$nextTick(() => {
            this.bagForm.scannerBagNo = this.updateBagNum;
          });
        } else {
          this.$nextTick(() => {
            this.bagForm.scannerBagNo = '';
          });
        }
      }
    },
    // 点击更新
    updateFun(row) {
      console.log(row, '点击更新');
      this.Unloadingpoint = row.preDeliverStationId;
      if (this.tableDataLeft.length > 0) {
        this.$message.error(this.$t('GlobalSetObj.Pleasesealthebag'));
      } else {
        const param = {
          transitBagNumber: row.transitBagNumber
        };
        apiUpdateTransitBag(param).then((res) => {
          if (res.code === 200) {
            this.updateBagNum = row.transitBagNumber;
            this.bagForm.scannerBagNo = row.transitBagNumber;
            this.first = false;
            if (this.tableDataLeftShow && this.tableDataLeftShow.length) {
              if (res.data.list[0].systemCode !== this.transitBagNumberList[0].systemCode) {
                this.$message({
                  message: this.$t('operatingPlatform.serviceProviderInconsistency'),
                  type: 'warning'
                });
                return;
              }
              if (res.data.list[0].siteId !== this.transitBagNumberList[0].siteId) {
                this.$message({
                  message: this.$t('operatingPlatform.deliveryPointInconsistency'),
                  type: 'warning'
                });
                return;
              }
            }
            if (res.data.list && res.data.list.length) {
              // this.systemName = res.data.systemProviderName;
              // this.dispthSite = res.data.list[0].siteName;
              res.data.list[0].systemName = res.data.systemProviderName;
              res.data.list[0].systemCode = res.data.systemCode;
              this.tableDataLeft = res.data.list;
              console.log(this.tableDataLeft, 'this.tableDataLeft');
              this.tableDataLeft.map((item) => {
                item.isRemove = true;
              });
              let totalWeight = 0;
              this.tableDataLeft.map((item) => {
                totalWeight = item.weight + totalWeight;
              });
              this.TotalWeight = totalWeight;
              this.cachArraySave(this.tableDataLeft);
              this.getCurreentList();
            }
            this.currentBagNumber = res.data.bagNumber;
            this.showUpdateNum = false;
          }
        });
      }
    },
    // 打印袋号
    printBagNo(row) {
      // const param = {
      //   orderWaybill: '',
      //   bagNumber: row.transitBagNumber,
      //   serverChannelName: row.serverChannelName
      // };
      console.log(row, '打印袋号');
      if (
        row.baseCode && // 确保 baseCode 存在
        row.baseCode.length === 3 && // 确保 baseCode 的长度是 3
        /^[a-zA-Z]{3}$/.test(row.baseCode) // 使用正则表达式测试 baseCode 是否仅包含3个字母
      ) {
        row.baseCodeName = row.baseCode;
      }
      this.printBagObj = {
        threeCode: row.siteName,
        departmentCode: row.siteDepartmentCode,
        providerName: row.providerName,
        deliverSiteName: row.siteCode,
        siteAddress: row.siteAddress,
        baseCodeName: row.baseCodeName,
        time: dayjs().format('DD-MM-YYYY'),
        timeSs: dayjs().format('HH:mm:ss')
      };
      this.showPrintBagNumberHtml = true;
      // return;
      // this.printBagObj = row;
      // printBagNumber(param).then(res => {
      //   if (res.code===200) {
      //     this.printBagObj = {
      //       threeCode: row.siteName,
      //       departmentCode: row.siteDepartmentCode,
      //       providerName: row.providerName,
      //       deliverSiteName: row.siteCode,
      //       siteAddress: row.siteAddress,
      //       baseCodeName: row.baseCodeName,
      //       time: dayjs().format('DD-MM-YYYY'),
      //       timeSs: dayjs().format('HH:mm:ss')
      //     };
      //     this.showPrintBagNumberHtml = true;
      //   }
      // });
    },
    // 移除
    removeItem(scope) {
      this.Remove = true;
      this.MoveIn = false;
      if (scope) {
        this.tableDataLeft.splice(scope.$index, 1);
        let totalWeight = 0;
        this.tableDataLeft.map((item) => {
          totalWeight = item.weight + totalWeight;
        });
        this.TotalWeight = totalWeight;
        this.cachArray(this.tableDataLeft);
        this.getCurreentList();
        if (this.tableDataLeft.length === 0) {
          this.currentBagNumber = '';
          this.Unloadingpoint = null;
        }
        if (!this.currentBagNumber) {
          this.currentBagNumber = cloneDeep(this.tableDataLeft[0].transit_bag_number);
        }
      } else {
        this.$router.push({ path: '/views/expressDataCenter/operatingPlatform/removeTheBag' });
      }
    },
    // 节流
    throttle(func, wait) {
      const _this = this;
      const now = new Date().getTime();
      if (now - _this.timer > wait) {
        _this.timer = now + wait;
        const tiem = setTimeout(function() {
          clearTimeout(tiem);
          func();
        }, wait);
      }
    },
    // 触发回车事件使用节流
    eventEnter() {
      this.throttle(this.inboundOperations, 300);
    },
    // 查询运单号装袋
    inboundOperations() {
      if (!this.bagNo) {
        this.$refs.errRef.play();
        return;
      }
      if (this.tableDataLeft.length > 999) {
        this.$refs.errRef.play();
        this.$message.error(this.$t('GlobalSetObj.ParcelLimit'));
        return;
      }
      if (this.tableDataLeft.length) {
        const result = this.tableDataLeft.some((obj) => Object.values(obj).includes(this.bagNo));
        this.cachArray(this.tableDataLeft);
        if (result) {
          this.$message.error(this.$t('GlobalSetObj.bagged'));
          this.$refs.errRef.play();
          this.$nextTick((_) => {
            this.$refs.inputRef.$refs.input.select();
          });
          return;
        }
      }
      let isRepeat = false;
      this.tableDataLeft.map((item) => {
        if (item.waybill_number.toString() === this.bagNo.toString()) {
          isRepeat = true;
        }
        if (item.logistics_number === null) {
          isRepeat = false;
        }
        if (item.logistics_number === this.bagNo) {
          isRepeat = true;
        }
      });
      if (isRepeat === true) {
        this.$refs.errRef.play();
        this.$nextTick((_) => {
          this.$refs.inputRef.$refs.input.select();
        });
        return;
      }
      const param = {
        transitBagNumber: this.currentBagNumber,
        orderWaybill: this.bagNo
      };
      apiQueryTransitBag(param)
        .then((res) => {
          if (res.code === 200) {
            // if (!res.data.weight) {
            //   this.$message.warning(this.$t('GlobalSetObj.sorterWeighsBagging'));
            //   this.$nextTick(_ => {
            //     this.$refs.inputRef.$refs.input.select();
            //   });
            //   return;
            // }
            this.Unloadingpoint = res.data.preDeliverStationId;
            if (this.tableDataLeft.length > 0) {
              if (this.tableDataLeft[0].systemCode === res.data.systemCode) {
                if (this.tableDataLeft[0].siteId !== res.data.siteId) {
                  // 网点不一致
                  this.$message.error(this.$t('operatingPlatform.deliveryPointInconsistency'));
                  return;
                }
                if (res.data.provider_channel_code === 'BRAZIL_LOCAL') {
                  this.first = false;
                  let totalWeight = 0;
                  this.tableDataLeft.map((item) => {
                    totalWeight = item.weight + totalWeight;
                  });
                  totalWeight = totalWeight + (res.data.weight || 0);
                  this.tableDataLeft.unshift(res.data);
                  this.TotalWeight = totalWeight;
                  this.cachArray(this.tableDataLeft);
                  if (!this.currentBagNumber) {
                    this.currentBagNumber = cloneDeep(this.tableDataLeft[0].transit_bag_number);
                  }
                } else {
                  let totalWeight = 0;
                  this.tableDataLeft.map((item) => {
                    totalWeight = item.weight + totalWeight;
                  });
                  totalWeight = totalWeight + (res.data.weight || 0);
                  if (totalWeight > 500) {
                    this.$message.warning(this.$t('GlobalSetObj.minimo500kg'));
                    this.$refs.errRef.play();
                    this.$nextTick((_) => {
                      this.$refs.inputRef.$refs.input.select();
                    });
                    return;
                  }
                  this.tableDataLeft.unshift(res.data);
                  this.TotalWeight = totalWeight;
                  this.cachArray(this.tableDataLeft);
                  if (!this.currentBagNumber) {
                    this.first = true;
                    this.currentBagNumber = cloneDeep(this.tableDataLeft[0].transit_bag_number);
                  }
                }
                this.$refs.okRef.play();
              } else {
                // 服务商不一致
                this.$message.error(this.$t('operatingPlatform.serviceProviderInconsistency'));
                this.$refs.errRef.play();
                this.$nextTick((_) => {
                  this.$refs.inputRef.$refs.input.select();
                });
                return;
              }
            } else {
              if (res.data.provider_channel_code === 'BRAZIL_LOCAL') {
                this.first = false;
                let totalWeight = 0;
                this.tableDataLeft.map((item) => {
                  totalWeight = item.weight + totalWeight;
                });
                totalWeight = totalWeight + (res.data.weight || 0);
                this.tableDataLeft.unshift(res.data);
                this.TotalWeight = totalWeight;
                this.cachArray(this.tableDataLeft);
                if (!this.currentBagNumber) {
                  this.currentBagNumber = cloneDeep(this.tableDataLeft[0].transit_bag_number);
                }
              } else {
                let totalWeight = 0;
                this.tableDataLeft.map((item) => {
                  totalWeight = item.weight + totalWeight;
                });
                totalWeight = totalWeight + (res.data.weight || 0);
                if (totalWeight > 500) {
                  this.$message.warning(this.$t('GlobalSetObj.minimo500kg'));
                  this.$refs.errRef.play();
                  this.$nextTick((_) => {
                    this.$refs.inputRef.$refs.input.select();
                  });
                  return;
                }
                this.tableDataLeft.unshift(res.data);
                this.TotalWeight = totalWeight;
                this.cachArray(this.tableDataLeft);
                if (!this.currentBagNumber) {
                  this.first = true;
                  this.currentBagNumber = cloneDeep(this.tableDataLeft[0].transit_bag_number);
                }
              }
              this.$refs.okRef.play();
            }
            if (res.msg) {
              this.$message.info(res.msg);
            }
            this.bagNo = '';
            this.getCurreentList();
          }
        })
        .catch(() => {
          this.$refs.errRef.play();
          this.$nextTick((_) => {
            this.$refs.inputRef.$refs.input.select();
          });
        });
    },
    // 点击分页
    SizeChange(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getBagSealingRecords();
    },
    CurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getBagSealingRecords();
    },
    getCurreentList() {
      console.log(this.tableDataLeft, 'this.tableDataLeft');
      if (this.tableDataLeft.length > 0) {
        this.systemName = this.tableDataLeft[0].systemName;
        this.dispthSite = this.tableDataLeft[0].siteName;
      } else {
        this.systemName = '';
        this.dispthSite = '';
      }
      for (let i = 0; i < this.tableDataLeft.length - 1; i++) {
        if (this.tableDataLeft[i].unloadingId) {
          if (this.tableDataLeft[i].unloadingId !== this.tableDataLeft[i + 1].unloadingId) {
            this.$message.error(this.$t('GlobalSetObj.Differentpoints'));
            this.$refs.errRef.play();
            this.$nextTick((_) => {
              this.$refs.inputRef.$refs.input.select();
            });
            this.tableDataLeft.shift();
            this.cachArray(this.tableDataLeft);
            return;
          }
        } else {
          if (this.tableDataLeft[i].siteId !== this.tableDataLeft[i + 1].siteId) {
            this.$message.error(this.$t('GlobalSetObj.Differentoutlets'));
            this.$refs.errRef.play();
            this.$nextTick((_) => {
              this.$refs.inputRef.$refs.input.select();
            });
            this.tableDataLeft.shift();
            this.cachArray(this.tableDataLeft);
            return;
          }
        }
      }
      this.packageList.total = this.tableDataLeft.length;
      // 计算起始索引和结束索引
      this.tableDataLeft.map((item, index) => {
        item.indexNo = this.packageList.total - index;
      });
      const startIndex = (this.packageList.pageNumber - 1) * this.packageList.pageSize;
      const endIndex = startIndex + this.packageList.pageSize;
      // 使用 slice 方法获取当前页的数据
      const currentPageData = this.tableDataLeft.slice(startIndex, endIndex);
      this.tableDataLeftShow = currentPageData;
    },
    // 点击分页
    packageSizeChange(val) {
      this.packageList.pageNumber = 1;
      this.packageList.pageSize = val;
      this.getCurreentList();
    },
    packageCurrentChange(val) {
      this.packageList.pageNumber = val;
      this.getCurreentList();
    }
  }
};
</script>

<style scoped>
.operator-wrap {
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.titleStyle {
  font-size: 18px;
}

.printHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editStyle {
  font-size: 18px;
  margin-left: 10px;
  color: #409eff;
  cursor: pointer;
}

.printClass {
  font-size: 18px;
  margin-bottom: 15px;
  /*margin-left: 100px;*/
}

.printStyle {
  display: none;
}

.elTable /deep/ .el-table__fixed-right {
  height: 100% !important;
}

.app-container >>> .el-table__row td:not(.is-hidden):last-child {
  border-left: 1px solid #ebeef5;
}

.app-container >>> .el-table__header th:not(.is-hidden):last-child {
  border-left: 1px solid #ebeef5;
}

.app-container >>> .el-table__header-wrapper tr th:nth-last-child(3) {
  border-right: none;
}

.app-container >>> .el-table__body-wrapper tr td:nth-last-child(2) {
  border-right: none;
}

.scannerBag >>> .el-dialog__body {
  padding-top: 0 !important;
}

.currentBagHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-container >>> .el-pagination__total {
  margin-top: 4px;
}

.bgImgUrlPrintBag {
  background: url('/static/PrintBagNumberHui.svg') no-repeat 100% 100%;
  display: block;
  width: 20px;
  height: 20px;
}

.bgImgUrlPrintBag:hover {
  background: url('/static/PrintBagNumber.svg') no-repeat 100% 100%;
  display: block;
  width: 20px;
  height: 20px;
}

@media print {
  .printClass {
    font-size: 12px;
    margin-bottom: 15px;
    /*margin-left: 100px;*/
  }
}
</style>
