<template> <!-- 费用类型 -->
  <el-select
    v-model="value"
    filterable
    clearable
    remote
    :remote-method="getExpenseType"
    :placeholder="$t('GlobalSetObj.pleaseChoose')"
    style="width: 100%;"
    :loading="loading"
    @change="$emit('change', value)"
  >
    <el-option
      v-for="item in list"
      :key="item.id"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
import { feePage } from '@/api/finance/partition';

const PARAM = {
  orderDirection: 'DESC',
  pageNumber: 1,
  pageSize: 9999999,
  params: {
    name: '',
    state: 1,
    attribute: 2,
    businessType: '' //  1 安俊 // 2 第三方揽收 3 AJD派件 4 DSP派件 5 运输（干/支线）
  }
};

export default {
  name: '',

  props: {
    val: {
      type: String,
      default: ''
    },

    // 业务类型
    businessType: {
      type: [Number, String],
      default: ''
    }
  },

  data() {
    return {
      list: [],
      loading: false,
      value: ''
    };
  },

  watch: {
    val: {
      handler(val) {
        this.value = val;
      }
    }
  },

  created() {
    setTimeout(() => {
      this.getExpenseType('');
    }, 500);
  },

  methods: {
    // 获取费用类型
    getExpenseType(name = '') {
      const param = {
        ...PARAM,
        params: {
          ...PARAM.params,
          name,
          businessType: this.businessType // 1 安俊 // 2 第三方揽收 3 AJD派件 4 DSP派件 5 运输（干/支线）
        }
      };

      this.loading = true;
      feePage(param, false).then(res => {
        if (res.code === 200) {
          // this.feeTypeOption = res.data.records;
          this.list = (res.data.records || []).map(item => {
            const { id, displayName, code } = item;
            const obj = {
              label: displayName,
              value: code,
              id
            };
            return Object.freeze(obj);
          });
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
