<template>
  <div class="TimeLimitForSigningBox">
    <div style="margin-bottom: 20px;">
      <el-form class="selectClass">
        <el-row>
          <el-col :span="10" style="width: auto;">
            <!-- 揽收时间： -->
            <el-form-item :label="$t('collectionCenter.lanshouTime')">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                type="daterange"
                value-format="yyyy-MM-dd"
                :default-time="['00:00:00', '23:59:59']"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                :end-placeholder="$t('collectionCenter.EndTime')"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!-- 站点： -->
            <el-form-item :label="$t('collectionCenter.site')">
              <div class="dialogC">
                <el-select
                  v-model="queryForm.params.siteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                  filterable @focus="getNewestSite"
                >
                  <el-option
                    v-for="(item, index) in siteIdList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!-- 揽收人： -->
            <el-form-item :label="$t('collectionCenter.LanShouPerson')">
              <div class="dialogC">
                <el-select
                  v-model="queryForm.params.scanUserId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                  filterable @focus="chooseSite"
                >
                  <el-option
                    v-for="(item, index) in driverList"
                    :key="index"
                    :label="item.realName"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:report:signAgeing:time-find'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:report:signAgeing:time-reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-if="findActiveTime=='hzFind' && timeExportHZ"
            icon="el-icon-upload2"
            size="mini"
            :class="exportList.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="download"
          >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 汇总 -->
          <el-button
            v-if="findActiveTime=='mxFind' && timeExportMX"
            icon="el-icon-upload2"
            size="mini"
            :class="exportMXList.idList.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="downloadLogistics"
          >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 明细 -->
          <el-button
            v-if="findActiveTime=='hzFind' && timeAllExportHZ"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll1 ? 'onlyClickOnce10' : ''"
            @click="downloadAll"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 时间—汇总—全部导出 -->
          <el-button
            v-if="findActiveTime=='mxFind' && timeAllExportMX"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll2 ? 'onlyClickOnce10' : ''"
            @click="downloadAllMX"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 时间—明细—全部导出 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <el-tabs v-model="findActiveTime" type="border-card">
      <!-- 汇总 -->
      <el-tab-pane :label="$t('collectionCenter.summary')" name="hzFind">
        <div>
          <el-table
            ref="hzTable"
            :max-height="600"
            border
            show-summary
            :summary-method="getSummaries"
            :data="tableDate"
            @select="batchSelect"
            @select-all="batchSelectAll"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <!-- 序号 -->
            <el-table-column
              :label="$t('collectionCenter.ind')" type="index" width="60"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              prop="driverName"
              :label="$t('collectionCenter.LanShouPeople')"
            /><!-- 揽收人 -->
            <el-table-column
              min-width="150"
              align="center"
              prop="siteName"
              :label="$t('collectionCenter.collectionDot')"
            /><!-- 揽收网点 -->
            <el-table-column
              min-width="150"
              align="center"
              prop="scanDate"
              :label="$t('collectionCenter.lanshouT')"
            /><!-- 揽收时间 -->
            <el-table-column
              min-width="150"
              align="center"
              prop="sourceType"
              :label="$t('collectionCenter.DataSource')"
            ><!-- 数据来源 -->
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.sourceType === 0 ? 'pda' :
                    scope.row.sourceType === 1 ? 'pc' : ''
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              prop="collectedCount"
              :label="$t('collectionCenter.receivedPackagesTotalNumber')"
            ><!-- 已揽收包裹总数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="details(scope.row, 'collectBool')">{{ scope.row.collectedCount }}</el-button>
                <span v-else>{{ scope.row.collectedCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              prop="signedInCount"
              :label="$t('collectionCenter.TotalNumberOfParcelsSignedIn24Hours')"
            ><!-- 24小时内签收包裹总数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="details(scope.row, 'twentyFoursignBool')">{{ scope.row.signedInCount }}</el-button>
                <span v-else>{{ scope.row.signedInCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              prop="unsignedQty"
              :label="$t('collectionCenter.in24UnsignedPackagesTotalNumber')"
            ><!-- 24小时内未签收包裹总数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="details(scope.row, 'unsignedBool')">{{ scope.row.unsignedQty }}</el-button>
                <span v-else>{{ scope.row.unsignedQty }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              prop="unsignedCount"
              :label="$t('collectionCenter.over24HoursUnsignedPackagesTotalNumber')"
            ><!-- 超24小时未签收包裹总数 -->
              <template slot-scope="scope">
                <el-button v-if="hzTomx" size="mini" @click="details(scope.row, 'overtwentyFoursignBool')">{{ scope.row.unsignedCount }}</el-button>
                <span v-else>{{ scope.row.unsignedCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              prop="collectSuccessRate"
              :label="$t('collectionCenter.SigningRateWithin24Hours')"
            ><!-- 24小时内签收率 -->
              <template slot-scope="scope">
                <div>{{ scope.row.collectSuccessRate }}%</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 50, 100, 500, 1000]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getTablePage"
            @size-change="getPageSize"
          />
        </div>
      </el-tab-pane>
      <!-- 明细 -->
      <el-tab-pane v-if="findActiveTime=='mxFind'" :label="$t('collectionCenter.particulars')" name="mxFind">
        <div>
          <el-table
            ref="mxTable"
            :max-height="600"
            border
            :data="mxtableDate"
            @select="batchSelectMX"
            @select-all="batchSelectAllMX"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <!-- 序号 -->
            <el-table-column
              :label="$t('collectionCenter.ind')" type="index" width="60"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ (queryFormMX.pageNumber - 1) * queryFormMX.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderNumber"
              :label="$t('collectionCenter.lanshouOrderNum')"
            /><!-- 揽收订单号 -->
            <el-table-column
              prop="scanNumber"
              :label="$t('collectionCenter.Barcode')"
            /><!-- 条形码 -->
            <el-table-column
              prop="driverName"
              :label="$t('collectionCenter.LanShouPeople')"
            /><!-- 揽收人 -->
            <el-table-column
              prop="scanTime"
              :label="$t('collectionCenter.lanshouT')"
            /><!-- 揽收时间 -->
            <el-table-column
              prop="siteName"
              :label="$t('collectionCenter.collectionDot')"
            /><!-- 揽收网点 -->
            <el-table-column
              prop="signerSiteName"
              :label="$t('collectionCenter.signerBelongsSite')"
            /><!-- 签收人所属站点 -->
            <el-table-column
              prop="signerUsername"
              :label="$t('collectionCenter.signer')"
            /><!-- 签收人 -->
            <el-table-column
              prop="signerTime"
              :label="$t('collectionCenter.signT')"
            /><!-- 签收时间 -->
          </el-table>
        </div>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryFormMX.pageNumber"
            :page-sizes="[5,10, 50, 100, 500, 1000]"
            :page-size.sync="queryFormMX.pageSize"
            :total="totalMX"
            background
            style="margin: 10px 0"
            @current-change="getTablePageMX"
            @size-change="searchClickMX"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  queryPageByUser,
  collectCountReportePage,
  signingTimeFindListOrderNumberPage,
  timePageExport,
  TimeMXExportAll,
  collectCountReporteExportAll, getSiteByUser
} from '@/api/lanshou';
import ExcelJS from 'exceljs';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';
import { saveAs } from 'file-saver';

export default {
  data() {
    return {
      isClickAll1: true,
      isClickAll2: true,
      isClick: false,
      timeExportHZ: this.$store.getters.button_permissions.includes('btn:collection-center:report:signAgeing:time-export-hz'),
      timeExportMX: this.$store.getters.button_permissions.includes('btn:collection-center:report:signAgeing:time-export-mx'),
      timeAllExportHZ: this.$store.getters.button_permissions.includes('btn:collection-center:report:signAgeing:time-allExport-hz'),
      timeAllExportMX: this.$store.getters.button_permissions.includes('btn:collection-center:report:signAgeing:time-allExport-mx'),
      hzTomx: this.$store.getters.button_permissions.includes('btn:collection-center:report:signAgeing:time-hzTomx'),
      timeValue: [],
      findActiveTime: 'hzFind', // 汇总 hzFind   明细 mxFind
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: '',
          endTime: '',
          siteId: '',
          scanUserId: ''
        }
      },
      siteIdList: [], // 站点list
      driverList: [], // 司机list
      tableDate: [],
      total: 0,
      // 导出列
      exportList: [], // 汇总
      exportMXList: {
        idList: []
      }, // 明细
      // 明细
      mxtableDate: [],
      totalMX: 0,
      queryFormMX: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: '',
          endTime: '',
          siteId: '',
          scanUserId: '',
          sourceType: '', // 数据来源
          customerId: '', // 客户id
          orderNumber: '',
          scanNumber: ''
        }
      }
    };
  },
  watch: {
    findActiveTime() {
      if (this.findActiveTime === 'hzFind') {
        this.queryFormMX.pageNumber = 1;
      }
    }
  },
  created() {
    this.default();
    this.getNewestSite();
    this.searchClick();
    this.isClick = false;
    this.isClickAll1 = false;
    this.isClickAll2 = false;
  },
  destroyed() {
    this.isClick = false;
  },
  methods: {
    getNewestSite() {
      getSiteByUser().then(res => {
        if (res.code===200) {
          this.siteIdList = [];
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.beginTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.beginTime = val[0] + ' 00:00:00';
      this.queryForm.params.endTime = val[1] + ' 23:59:59';
      if (this.queryForm.params.endTime) {
        const endTime = dayjs(this.queryForm.params.beginTime).subtract(-1, 'month').format('YYYY-MM-DD') + ' 23:59:59';
        if (this.queryForm.params.endTime > endTime) {
          this.$message({
            // 只允许查1个月内的数据
            message: this.$t('collectionCenter.OnlyAllowedOneMonthsOfData'),
            type: 'warning'
          });
          const d = new Date();
          const day = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD 00:00:00');
          const today = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD 23:59:59');
          this.timeValue = [day, today];
          this.queryForm.params.beginTime = day;
          this.queryForm.params.endTime = today;
        }
      }
    },
    // 汇总---------------------------------------
    default() {
      const d = new Date();
      // const today = dayjs(d).format('YYYY-MM-DD 23:59:59');
      const day = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD 00:00:00');
      const today = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD 23:59:59');
      this.timeValue = [day, today];
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: day,
          endTime: today,
          siteId: '',
          scanUserId: ''
        }
      };
    },
    chooseSite() {
      // 选择站点
      queryPageByUser().then(res => {
        if (res.code===200) {
          this.queryForm.params.scanUserId = '';
          this.driverList = res.data;
        } else {
          this.queryForm.params.scanUserId = '';
          this.driverList = [];
        }
      });
    },
    getTablePage() {
      const params = JSON.parse(localStorage.getItem('TFTimeHZQueryForm')) || this.queryForm.params;
      const queryForm = {
        pageNumber: this.queryForm.pageNumber,
        pageSize: this.queryForm.pageSize,
        params
      };
      collectCountReportePage(queryForm).then(res => {
        if (res.code===200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableDate = [];
          this.total = 0;
        }
      }).catch((err) => {
        console.log(err);
        this.tableDate = [];
        this.total = 0;
      });
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const params = JSON.parse(localStorage.getItem('TFTimeHZQueryForm')) || this.queryForm.params;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params
      };
      collectCountReportePage(queryForm).then(res => {
        if (res.code===200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableDate = [];
          this.total = 0;
        }
      }).catch((err) => {
        console.log(err);
        this.tableDate = [];
        this.total = 0;
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.all');
          return;
        }
        if (column.label === this.$t('collectionCenter.receivedPackagesTotalNumber') ||
            column.label === this.$t('collectionCenter.TotalNumberOfParcelsSignedIn24Hours') ||
            column.label === this.$t('collectionCenter.over24HoursUnsignedPackagesTotalNumber') ||
            column.label === this.$t('collectionCenter.in24UnsignedPackagesTotalNumber')) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    },
    batchSelect(selection, row) {
      this.exportList = selection;
    },
    batchSelectAll(selection) {
      this.exportList = selection;
    },
    // 下载签收时效报表模板
    download() { // 汇总导出
      this.isClick = true;
      if (this.exportList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        Sheet1.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '009944' }};
        Sheet1.getRow(1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        Sheet1.getRow(1).height = 30;
        Sheet1.getRow(1).font = { bold: true, color: { argb: '000000' }, sz: 30 };
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.views = [
          { state: 'frozen', xSplit: undefined, ySplit: 1 }
        ];
        let Sheet1_data = [];
        Sheet1.columns = [
          {
            header: that.$t('collectionCenter.LanShouPeople'), // '揽收人'
            key: 'driverName',
            width: 15
          },
          {
            header: that.$t('collectionCenter.collectionDot'), // '揽收网点'
            key: 'siteName',
            width: 15
          },
          {
            header: that.$t('collectionCenter.lanshouT'), // '揽收时间'
            key: 'scanDate',
            width: 15
          },
          {
            header: that.$t('collectionCenter.DataSource'), // '数据来源'
            key: 'sourceType',
            width: 15
          },
          {
            header: that.$t('collectionCenter.receivedPackagesTotalNumber'), // '已揽收包裹总数'
            key: 'collectedCount',
            width: 15
          },
          {
            header: that.$t('collectionCenter.TotalNumberOfParcelsSignedIn24Hours'), // '24小时内签收包裹总数'
            key: 'signedInCount',
            width: 18
          },
          {
            header: that.$t('collectionCenter.in24UnsignedPackagesTotalNumber'), // '24小时内未签收包裹总数'
            key: 'unsignedQty',
            width: 18
          },
          {
            header: that.$t('collectionCenter.over24HoursUnsignedPackagesTotalNumber'), // '超24小时未签收包裹总数'
            key: 'unsignedCount',
            width: 18
          },
          {
            header: that.$t('collectionCenter.SigningRateWithin24Hours'), // '24小时内签收率'
            key: 'collectSuccessRate',
            width: 15
          }
        ];
        Sheet1_data = [];
        that.exportList.map((item) => {
          Sheet1_data.push({
            driverName: item.driverName,
            siteName: item.siteName,
            scanDate: item.scanDate,
            sourceType: item.sourceType === 0 ? 'pda' : item.sourceType === 1 ? 'pc' : '',
            collectedCount: item.collectedCount,
            signedInCount: item.signedInCount,
            unsignedQty: item.unsignedQty,
            unsignedCount: item.unsignedCount,
            collectSuccessRate: item.collectSuccessRate + '%'
          });
        });
        Sheet1.addRows(Sheet1_data);
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        // let url = blob;
        const saveName = that.$t('collectionCenter.SignPrescriptionStatement') + '.xlsx'; // '签收时效报表汇总.xlsx'
        saveAs(blob, saveName);
        // if (typeof url === 'object' && url instanceof Blob) {
        //   url = URL.createObjectURL(url);
        // }
        // const aLink = document.createElement('a');
        // aLink.href = url;
        // aLink.download = saveName || '';
        // let event;
        // if (window.MouseEvent) event = new MouseEvent('click');
        // else {
        //   event = document.createEvent('MouseEvents');
        //   event.initMouseEvent(
        //     'click',
        //     true,
        //     false,
        //     window,
        //     0,
        //     0,
        //     0,
        //     0,
        //     0,
        //     false,
        //     false,
        //     false,
        //     false,
        //     0,
        //     null
        //   );
        // }
        // aLink.dispatchEvent(event);
        return;
      }
      ddd();
    },
    details(row, boolCode) { // 跳转到明细
      this.findActiveTime = 'mxFind';
      this.queryFormMX = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: row.scanDate + ' 00:00:00',
          endTime: row.scanDate + ' 23:59:59',
          siteId: row.siteId ? row.siteId : -99,
          // driverId: this.queryForm.params.driverId || row.driverId,
          sourceType: row.sourceType, // 数据来源
          customerId: row.customerId, // 客户id
          scanUserId: row.scanUserId ? row.scanUserId : -99,
          orderNumber: '',
          scanNumber: '',
          collectBool: false,
          twentyFoursignBool: false,
          unsignedBool: false,
          overtwentyFoursignBool: false
        }
      };
      if (boolCode === 'collectBool') {
        this.queryFormMX.params.collectBool = true;
      } else if (boolCode === 'twentyFoursignBool') {
        this.queryFormMX.params.twentyFoursignBool = true;
      } else if (boolCode === 'unsignedBool') {
        this.queryFormMX.params.unsignedBool = true;
      } else if (boolCode === 'overtwentyFoursignBool') {
        this.queryFormMX.params.overtwentyFoursignBool = true;
      } else {
        this.queryFormMX.params.collectBool = false;
        this.queryFormMX.params.twentyFoursignBool = false;
        this.queryFormMX.params.unsignedBool = false;
        this.queryFormMX.params.overtwentyFoursignBool = false;
      }
      localStorage.setItem('TFTimeMXQueryForm', JSON.stringify(this.queryFormMX));
      this.getTablePageMX();
    },
    searchClick() {
      this.queryFormMX.params.beginTime = '';
      this.queryFormMX.params.endTime = '';
      this.queryFormMX.params.siteId = '';
      // this.queryFormMX.params.driverId = '';
      if (this.queryForm.params.beginTime && this.queryForm.params.endTime) {
        if (this.findActiveTime === 'hzFind') {
          this.exportList = [];
          this.queryForm.pageNumber = 1;
          localStorage.setItem('TFTimeHZQueryForm', JSON.stringify(this.queryForm.params));
          this.getTablePage();
        }
        if (this.findActiveTime === 'mxFind') {
          this.exportMXList.idList = [];
          this.queryFormMX = {
            pageNumber: 1,
            pageSize: this.queryFormMX.pageSize,
            params: {
              beginTime: this.queryForm.params.beginTime,
              endTime: this.queryForm.params.endTime,
              siteId: this.queryForm.params.siteId,
              // driverId: this.queryForm.params.driverId,
              sourceType: this.queryFormMX.params.sourceType, // 数据来源
              customerId: this.queryFormMX.params.customerId, // 客户id
              scanUserId: this.queryForm.params.scanUserId,
              orderNumber: '',
              scanNumber: '',
              collectBool: this.queryFormMX.params.collectBool,
              twentyFoursignBool: this.queryFormMX.params.twentyFoursignBool,
              unsignedBool: this.queryFormMX.params.unsignedBool,
              overtwentyFoursignBool: this.queryFormMX.params.overtwentyFoursignBool
            }
          };
          localStorage.setItem('TFTimeMXQueryForm', JSON.stringify(this.queryFormMX));
          this.getTablePageMX();
        }
      } else {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
      }
    },
    resetClick() {
      this.default();
    },
    // 明细---------------------------------------
    searchClickMX() {
      this.queryFormMX.pageNumber = 1;
      this.getTablePageMX();
    },
    getTablePageMX() {
      this.exportMXList.idList = [];
      const queryFormMX = JSON.parse(localStorage.getItem('TFTimeMXQueryForm')) || this.queryFormMX;
      queryFormMX.pageNumber = this.queryFormMX.pageNumber;
      queryFormMX.pageSize = this.queryFormMX.pageSize;
      signingTimeFindListOrderNumberPage(queryFormMX).then(res => {
        if (res.code===200) {
          this.mxtableDate = res.data.records;
          this.totalMX = res.data.total;
        }
      }).catch({});
    },
    choose(selection) {
      const exportMXList = [];
      selection.forEach(list => {
        exportMXList.push(list.id);
      });
      this.exportMXList.idList = exportMXList;
    },
    batchSelectMX(selection, row) {
      this.choose(selection);
    },
    batchSelectAllMX(selection) {
      this.choose(selection);
    },
    downloadLogistics() { // 明细导出
      this.isClick = true;
      if (this.exportMXList.idList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      timePageExport(this.exportMXList).then(res => {
        if (res.code===200) {
          goExportList(this);
        }
      }).catch({});
    },
    downloadAll() { // 汇总——全部导出
      this.isClickAll1 = true;
      const dataForm = {
        beginTime: this.queryForm.params.beginTime,
        endTime: this.queryForm.params.endTime,
        siteId: this.queryForm.params.siteId,
        scanUserId: this.queryForm.params.scanUserId
      };
      const queryForm = JSON.parse(localStorage.getItem('TFTimeHZQueryForm')) || dataForm;
      collectCountReporteExportAll(queryForm).then(res => {
        if (res.code===200) {
          goExportList(this);
        }
      }).catch({});
    },
    downloadAllMX() { // 明细——全部导出
      this.isClickAll2 = true;
      const dataForm = {
        beginTime: this.queryFormMX.params.beginTime || this.queryForm.params.beginTime,
        endTime: this.queryFormMX.params.endTime || this.queryForm.params.endTime,
        siteId: this.queryFormMX.params.siteId || this.queryForm.params.siteId,
        // driverId: this.queryFormMX.params.driverId || this.queryForm.params.driverId,
        sourceType: this.queryFormMX.params.sourceType,
        scanUserId: this.queryFormMX.params.scanUserId,
        orderNumber: '',
        scanNumber: '',
        collectBool: this.queryFormMX.params.collectBool,
        twentyFoursignBool: this.queryFormMX.params.twentyFoursignBool,
        unsignedBool: this.queryFormMX.params.unsignedBool,
        overtwentyFoursignBool: this.queryFormMX.params.overtwentyFoursignBool
      };
      const query = JSON.parse(localStorage.getItem('TFTimeMXQueryForm'));
      const queryForm = query.params || dataForm;
      TimeMXExportAll(queryForm).then(res => {
        goExportList(this);
      }).catch({});
    }
  }
};
</script>

<style lang="scss" scoped>

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce2{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce2:active{
    filter: opacity(40%);
    animation: none;
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }

  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-select, .selectClass ::v-deep .el-form-item__content{
    width: 100%;
  }
  .TimeLimitForSigningBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .TimeLimitForSigningBox .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .dialogC ::v-deep .el-select{
    width: 100%;
  }
  .col2 {
    width: 135px !important;
  }
  .NumClass ::v-deep textarea.el-textarea__inner {
    max-height: 150px;
  }
  .NumClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .TimeLimitForSigningBox .selectTimeClass ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
  }
</style>
