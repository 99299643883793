<template>
  <div class="optionBut">
    <el-popover
      placement="bottom"
      trigger="hover"
    >
      <div class="but">
        <div class="but-row">
          <el-button
            v-permit:remove="'btn:payableManagement:payableCollection:InitiationPayment'" type="warning" size="small"
            :disabled="isPayBut"
            @click="openPayDialog"
          >{{ $i18n.t('basicData.发起付款') }}</el-button>
        </div>
        <div class="but-row">
          <el-button
            v-permit:remove="'btn:payableManagement:payableCollection:EnquiryResults'" type="primary" size="small"
            :disabled="isResults"
            @click="asyncApiSitePayableQuirePay"
          >{{ $i18n.t('basicData.查询结果') }}</el-button>
        </div>
      </div>
      <!-- 付款 -->
      <el-button
        v-if="isShowBut" slot="reference" type="primary"
        size="small"
      >{{ $i18n.t('ClaimsManagement.Payment') }}（Santander）</el-button>
    </el-popover>

    <!-- 发起付款 弹窗 -->
    <el-dialog
      :title="`${$i18n.t('ClaimsManagement.Payment')}-Santander`"
      :visible.sync="isPayDialog"
      :loading="isLoading"
      width="600px"
    >
      <div style="height: 300px;overflow-y: auto;margin: -10px -15px;padding: 10px;">
        <el-form label-width="150px">
          <el-card
            v-for="(item, index) in selectData" :key="index"
            class="box-card descriptionsCon"
            style="margin: 10px 0;"
          >
            <el-descriptions :colon="false" direction="horizontal" :column="2">
              <!--实际收款人-->
              <el-descriptions-item :label="$t('basicData.实际收款人') + '：'">
                <span>{{ item.actualRecipients }}</span>
              </el-descriptions-item>
              <!--付款总金额-->
              <el-descriptions-item :label="$t('basicData.付款总金额') + '：'">
                <span>{{ item.billFee }}</span>
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="isPayDialog = false">{{ $i18n.t('GlobalSetObj.cancel') }}</el-button>
        <!-- 确定 -->
        <el-button type="primary" size="small" @click="paySumbit">{{ $i18n.t('GlobalSetObj.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { PAYABLE_STATUS } from '@/views/finance/payableManage/payableCollection/enums.js';
import { apiSitePayableSantanderPay, apiSitePayableSantanderQuery } from '@/api/newFinance/index.js';

// 付款单状态为“待核付0、付款失败4、部分付款2”的单
const PAY_STATUS = [
  PAYABLE_STATUS['0'].value, // 待核付
  PAYABLE_STATUS['4'].value, // 付款失败
  // PAYABLE_STATUS['2'].value // 部分核付
];

export default {
  name: '',

  props: {
    // 勾选选中的值
    selectData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      // 发起付款
      payForm: {
        ids: []
      },
      isPayDialog: false, // 弹窗显示/隐藏
      isLoading: false, // Loading
      isShowBut: true // 是否显示付款按钮
    };
  },

  computed: {

    /**
     * 发起付款按钮能否点击的条件
     *    条件1 每次仅可选择一条付款单数据
     *    条件2 付款单状态为“待核付0、付款失败4、部分付款2”的单
     *    条件3 账单类型 AJD、DSP、安骏
     */
    isPayBut() {
      const { selectData } = this;
      let isPayBut = false;
      // 禁用
      if (selectData.length === 0) {
        isPayBut = true;
      }
      for (let i = 0; i < selectData.length; i++) {
        if (PAY_STATUS.includes(selectData[i].payableStatus) === false || ![1,3,4].includes(selectData[i].payableType) ) {
          isPayBut = true;
        }
      }
      // 启用
      return isPayBut;
    },

    /**
     * 查询结果 按钮 启用条件
     *  条件1 仅可选择一条付款单数据
     *  条件2 付款单状态为“付款中3”的单
     */
    isResults() {
      const { selectData } = this;
      // 禁用
      if (selectData.length !== 1) {
        return true;
      }

      const { payableStatus, payableType } = selectData[0];
      // 启用
      if (payableStatus === PAYABLE_STATUS['3'].value && payableType === 3) {
        return false;
      }
      // 禁用
      return true;
    }
  },

  created() {
    // 是否存在付款按钮权限，只有存在一个权限就会显示
    const buttonPermissions = this.$store.getters.button_permissions;
    this.isShowBut = buttonPermissions.includes('btn:payableManagement:payableCollection:InitiationPayment') || buttonPermissions.includes('btn:payableManagement:payableCollection:EnquiryResults');
  },

  methods: {
    // 打开付款
    openPayDialog() {
      console.log(this.selectData, '==selectData');
      this.payForm.ids = [];
      this.selectData.map(item => {
        this.payForm.ids.push(item.id);
      });
      this.isPayDialog = true;
    },

    // 确认付款
    paySumbit() {
      const str1 = this.$i18n.t('GlobalSetObj.determine'); // 确定
      const str2 = this.$i18n.t('ClaimsManagement.Payment'); // 付款
      const str3 = this.$i18n.t('customerManagements.tips'); // 提示
      const str4 = this.$i18n.t('GlobalSetObj.TheOperationSucceeded'); // 操作成功
      this.$confirm(`${str1} ${str2}？`, str3, {
        confirmButtonText: str1,
        cancelButtonText: this.$i18n.t('GlobalSetObj.cancel'),
        type: 'warning'
      }).then(() => {
        this.isLoading = true;
        return apiSitePayableSantanderPay(this.payForm, false);
      }).then(res => {
        this.$message.success(str4);
        this.isPayDialog = false;
        this.$emit('updatePage', true);
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    // 查询结果
    asyncApiSitePayableQuirePay() {
      const { id } = this.selectData[0];
      apiSitePayableSantanderQuery({ id }).then(res => {
        if (res.code === 200) {
          this.$message(this.$i18n.t('GlobalSetObj.bemSucedido'));
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }

};
</script>

<style scoped>
.optionBut {
  margin-left: 10px;
}

.but {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid #000; */
}
.but-row {
  margin-bottom: 10px;
}
.descriptionsCon .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  margin-bottom: 0;
}
.descriptionsCon .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 0;
}
</style>
