<template>
  <div class="app-container">
    <div style="margin-bottom: 10px;">
      <!--新增同级-->
      <el-button
        v-permit:remove="'btn:supplierManagement:VendorPermissions:add'"
        size="small"
        type="primary"
        :disabled="selectData === null"
        @click="addPermissions(1)"
      >{{ $t('organizationManage.addSameLevel') }}</el-button>
      <!--新增下级-->
      <el-button
        v-permit:remove="'btn:supplierManagement:VendorPermissions:add'"
        size="small"
        type="primary"
        :disabled="selectData === null"
        @click="addPermissions(2)"
      >{{ $t('organizationManage.addNextLevel') }}</el-button>
      <!--编辑-->
      <el-button
        v-permit:remove="'btn:supplierManagement:VendorPermissions:edit'"
        size="small"
        type="primary"
        :disabled="selectData === null"
        @click="editPermissions()"
      >{{ $t('GlobalSetObj.edit') }}</el-button>
      <!--删除-->
      <el-button
        v-permit:remove="'btn:supplierManagement:VendorPermissions:delete'"
        size="small"
        type="danger"
        :disabled="selectData === null"
        @click="deletePermissions()"
      >{{ $t('GlobalSetObj.Delete') }}</el-button>
    </div>
    <div class="treeNode">
      <el-tabs v-model="activeName" type="border-card" @tab-click="handleClickPermissions">
        <el-tab-pane label="PC" name="pc">
          <div style="height: calc(100vh - 220px);overflow: auto;">
            <el-tree
              :key="treeKey"
              node-key="id"
              default-expand-all
              highlight-current
              :data="permissionPcList"
              :expand-on-click-node="false"
              :props="defaultRole"
              @current-change="getPcClickNode"
            >
              <span slot-scope="{ node,data }" class="custom-tree-node mb">
                <span class="titlts">
                  <i v-if="data.parentId === 0" class="iconfont icon-folder-open" />
                  <i v-else class="iconfont icon-wenjian" />
                  {{ data.name }}
                </span>
              </span>
            </el-tree>
          </div>
        </el-tab-pane>
        <el-tab-pane label="PDA" name="pda">
          <div style="height: calc(100vh - 220px);overflow: auto;">
            <el-tree
              :key="treeKey"
              node-key="id"
              default-expand-all
              highlight-current
              :data="permissionPadList"
              :expand-on-click-node="false"
              :props="defaultRole"
              @current-change="getPadClickNode"
            >
              <span slot-scope="{ node,data }" class="custom-tree-node mb">
                <span class="titlts">
                  <i v-if="data.parentId === 0" class="iconfont icon-folder-open" />
                  <i v-else class="iconfont icon-wenjian" />
                  {{ data.name }}
                </span>
              </span>
            </el-tree>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!--添加权限-->
    <el-dialog
      :title="permissonForm.title"
      :visible.sync="permissonForm.visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="600px"
      @close="closePermissonForm"
    >
      <div>
        <el-form
          :key="permissonForm.visible"
          ref="permissionRef"
          :model="permissonForm"
          :rules="permissonRules"
          label-position="top"
        >
          <el-row v-if="permissonForm.type!=='add'">
            <el-col :span="24">
              <!-- 权限ID -->
              <el-form-item :label="$t('roleManage.permissionsID')">
                <el-input v-model="permissonForm.id" disabled />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-show="permissonForm.parentId!==0">
            <el-col :span="24">
              <!-- 父权限ID -->
              <el-form-item :label="$t('roleManage.parentPermissionID')" prop="parentId">
                <el-input v-show="false" v-model="permissonForm.parentId" disabled />
                <el-input v-model="permissonForm.parentName" disabled />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <!-- 权限名称 -->
              <el-form-item :label="$t('roleManage.permissionsName')" prop="name">
                <el-input v-model.trim="permissonForm.name" autocomplete="off" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <!-- 权限标识 -->
              <el-form-item :label="$t('roleManage.permissionsTag')" prop="code">
                <el-input v-model.trim="permissonForm.code" autocomplete="off" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <!-- 排序 -->
              <el-form-item
                :label="$t('roleManage.sort')"
                prop="sort"
                :rules="[ { required: true, message: $t('roleManage.sortNotEmpty')},{ type: 'number', message: $t('roleManage.sortIsNumber')}]"
              ><!--                                   排序不能为空                               排序必须为数字值 -->
                <el-input
                  v-model.number="permissonForm.sort"
                  type="sort"
                  :placeholder="$t('roleManage.littleValueFront')"
                  autocomplete="off"
                /><!-- 值越小越靠前 -->
              </el-form-item>
            </el-col>
          </el-row>

          <!--          <el-row>-->
          <!--            <el-col :span="24">-->
          <!--              &lt;!&ndash; 备注 &ndash;&gt;-->
          <!--              <el-form-item :label="$t('roleManage.note')">-->
          <!--                <el-input v-model="permissonForm.remark" type="textarea" autocomplete="off" />-->
          <!--              </el-form-item>-->
          <!--            </el-col>-->
          <!--          </el-row>-->
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="onSubmit">{{ $t('roleManage.confirm') }}</el-button><!-- 确认 -->
        <el-button size="small" @click="permissonForm.visible = false">{{ $t('roleManage.cancel') }}</el-button><!-- 取消 -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addPmPermissionApi, deletePmPermissionApi, editPmPermissionApi, getPermissionStructure } from '@/api/role';

export default {
  name: 'VendorPermissions',
  data() {
    return {
      treeKey: new Date().getTime(),
      permissionPcList: [],
      permissionPadList: [],
      pcRoleAll: [],
      padRoleAll: [],
      defaultRole: {
        children: 'children',
        label: 'name'
      },
      permissonForm: {
        title: '',
        type: '',
        visible: false,
        id: null,
        parentId: null,
        name: '',
        code: '',
        sort: 1,
        remark: ''
      },
      permissonRules: {
        // '父权限ID不能为空'
        parentId: [{ required: true, message: this.$t('organizationManage.PpermissionIDNotEmpty'), trigger: ['blur', 'change'] }],
        // '权限名称不能为空'
        name: [{ required: true, message: this.$t('organizationManage.permissionNameNotEmpty'), trigger: ['blur', 'change'] }],
        // '权限标识不能为空'
        code: [{ required: true, message: this.$t('organizationManage.permissionLogoNotEmpty'), trigger: ['blur', 'change'] }],
        // 排序
        sort: [
          { required: true, message: this.$t('roleManage.sortNotEmpty'), trigger: ['blur', 'change'] },
          { type: 'number', message: this.$t('roleManage.sortIsNumber'), trigger: ['blur', 'change'] }
        ]
      },
      selectData: null,
      selectNode: null,
      activeName: 'pc',
      permissionType: 1
    };
  },
  created() {
    this.getPermissionTree();
  },
  methods: {
    // 关闭弹窗
    closePermissonForm() {
      this.selectData = null;
      this.selectNode = null;
      this.treeKey = new Date().getTime();
    },
    // 权限树切换
    handleClickPermissions() {
      this.treeKey = new Date().getTime();
      this.selectData = null;
      this.selectNode = null;
    },
    // pc权限被选中
    getPcClickNode(data, node) {
      this.permissionType = 1;
      this.selectData = data;
      this.selectNode = node;
    },
    // pda权限被选中
    getPadClickNode(data, node) {
      this.permissionType = 2;
      this.selectData = data;
      this.selectNode = node;
    },
    // 添加统计、下级
    addPermissions(type) {
      this.permissonForm.title = this.$t('roleManage.addPermissions');
      this.permissonForm.type = 'add';
      if (type === 1) {
        const parent = this.selectNode.parent;
        this.permissonForm.parentId = parent.data.id || 0;
        this.permissonForm.parentName = parent.data.name || '';
      } else {
        this.permissonForm.parentId = this.selectData.id || 0;
        this.permissonForm.parentName = this.selectData.name || '';
      }
      this.permissonForm.visible = true;
      this.permissonForm.id = null;
      this.permissonForm.name = '';
      this.permissonForm.code = '';
      this.permissonForm.sort = 1;
      this.permissonForm.remark = '';
    },
    // 获取权限树
    getPermissionTree() {
      getPermissionStructure().then(res => {
        if (res.code===200) {
          this.permissionPcList = res.data.permissionStructurePc;
          this.permissionPadList = res.data.permissionStructurePda;
          this.pcRoleAll = res.data.lastChildrenIdsPc;
          this.padRoleAll = res.data.lastChildrenIdsPda;
          this.treeKey = new Date().getTime();
        }
      });
    },
    // 编辑
    editPermissions() {
      this.permissonForm.title = this.$t('roleManage.editPermissions');
      this.permissonForm.type = 'edit';
      this.permissonForm.visible = true;
      this.permissonForm.id = this.selectData.id;
      this.permissonForm.parentId = this.selectData.parentId;
      const parent = this.selectNode.parent;
      this.permissonForm.parentName = parent.data.name || '';
      this.permissonForm.name = this.selectData.name;
      this.permissonForm.code = this.selectData.code;
      this.permissonForm.sort = this.selectData.sort || 1;
      this.permissonForm.remark = this.selectData.remark;
    },
    // 提交
    onSubmit() {
      const param = {
        id: this.permissonForm.id,
        parentId: this.permissonForm.parentId,
        name: this.permissonForm.name,
        code: this.permissonForm.code,
        sort: this.permissonForm.sort,
        remark: this.permissonForm.remark,
        permissionType: this.permissionType
      };
      this.$refs.permissionRef.validate((valid) => {
        if (!valid) {
          return;
        }
        // 添加权限
        if (this.permissonForm.type === 'add') {
          addPmPermissionApi(param).then(res => {
            if (res.code===200) {
              this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
              this.getPermissionTree();
              this.permissonForm.visible = false;
              this.bus.$emit('updateRoleTree');
            }
          });
        } else {
          editPmPermissionApi(param).then(res => {
            if (res.code===200) {
              this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
              this.getPermissionTree();
              this.permissonForm.visible = false;
              this.bus.$emit('updateRoleTree');
            }
          });
        }
      });
    },
    // 删除
    deletePermissions() {
      this.$confirm(this.$t('GlobalSetObj.AreYouSureDelete'), this.$t('GlobalSetObj.tips'), {
        confirmButtonText: this.$t('GlobalSetObj.confirm'),
        cancelButtonText: this.$t('GlobalSetObj.Cancelar'),
        type: 'warning'
      }).then(() => {
        const param = {
          id: this.selectData.id
        };
        deletePmPermissionApi(param).then(res => {
          if (res.code===200) {
            this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
            this.getPermissionTree();
            this.selectData = null;
            this.selectNode = null;
            this.bus.$emit('updateRoleTree');
          }
        });
      });
    }
  }
};
</script>

<style scoped>
>>> .treeNode .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content .custom-tree-node.mb {
  background-color: #007efd!important;
  color: #fff;
  padding: 3px;
  border-radius: 3px;
}
</style>
