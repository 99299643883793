<template>

  <el-form
    ref="ruleForm" v-loading="loading" :model="form"
    label-position="right" :rules="rules" label-width="170px"
  >

    <!-- 客户 -->
    <el-form-item class="numberClassSelect" :label="$t('GlobalSetObj.client')" prop="customerId">
      <CustomerSelectCmp v-model="form.customerId" />
    </el-form-item>

    <!-- 理赔节点 -->
    <el-form-item class="numberClassSelect" :label="$t('ClaimsManagement.ClaimsNode')">
      <el-select v-model="form.claimsNode" :placeholder="$t('operationCenter.PleaseSelect')" clearable>
        <el-option
          v-for="item in CLAIMSNODE_TYPE_OBJ" :key="item.value" :label="item.name"
          :value="item.value"
        />

        <!--揽收-->
        <!-- <el-option :label="$t('collectionCenter.lanshou')" :value="2" /> -->
        <!--派送-->
        <!-- <el-option :label="$t('collectionCenter.dispatch')" :value="3" /> -->
        <!--转运-->
        <!-- <el-option :label="$t('collectionCenter.turnTransport')" :value="1" /> -->
      </el-select>
    </el-form-item>

    <!-- 申报价值阈值（<=） -->
    <el-form-item class="numberClassSelect" :label="`${$t('ClaimsManagement.DeclarationValueThreshold')}(<=)`">
      <el-input v-model="form.declaredValue" :placeholder="$t('GlobalSetObj.pleaseInputContent')" style="width: 200px" />
    </el-form-item>

    <!-- 默认审核人 -->
    <el-form-item class="numberClassSelect" :label="$t('ClaimsManagement.DefaultAuditor')">
      <UserSelectCmp v-model="form.defaultReviewer" :user-name="row.defaultReviewerName" :is-organization-name="true" />
    </el-form-item>

    <el-form-item>
      <!-- 确定 -->
      <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('GlobalSetObj.determine') }}</el-button>
      <!-- 取消 -->
      <el-button @click="onClose(false)">{{ $t('GlobalSetObj.cancel') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { apiClaimsReviewConfigurationAdd, apiClaimsReviewConfigurationEdit } from '@/api/claimsManagement/rules';
import CustomerSelectCmp from '@/components/CustomerSelectCmp/index.vue';
import UserSelectCmp from './UserSelectCmp.vue';
import { addTmp } from './../utils';
import { CLAIMSNODE_TYPE_OBJ } from '@/views/claimsManagement/claimList/utils.js';

export default {
  name: 'FormCmp',

  components: {
    CustomerSelectCmp,
    UserSelectCmp
  },

  props: {

    // 编辑的时候传入的id
    ids: {
      type: Number,
      default: 0
    },

    // 回填的内容
    row: {
      type: Object,
      default: () => {
        return {
          ...addTmp
        };
      }
    }
  },

  data() {
    var numberCheck = (rule, value, callback) => {
      if (value === '') {
        return callback();
      }
      // 只能输入纯数字
      const reg = /(^[1-9]\d*(\.\d{1,3})?$)|(^0(\.\d{1,3})?$)/;
      if (!reg.test(value)) {
        return callback(new Error('只能输入数字'));
      }
      // 不能超过999999999
      if (value > 999999999) {
        return callback(new Error('不能超过999999999'));
      }

      callback();
    };

    return {
      loading: false,
      form: {
        ...addTmp
        // customerId: '', // 客户id
        // claimsNode: '', // 理赔节点1:转运 2:揽收 3:派送
        // declaredValue: '', // 申报价值阈值（<=）
        // defaultReviewer: '' // 默认审核人id
      },

      rules: {
        customerId: [{
          required: true,
          message: this.$t('GlobalSetObj.pleaseChoose'), // 请选择客户
          trigger: 'change'
        }],
        declaredValue: [{ validator: numberCheck, trigger: 'blur' }]
      }
    };
  },

  watch: {
    row: {
      handler(val) {
        Object.assign(this.form, val);
      },
      immediate: true,
      deep: true
    }
  },

  created() {
    this.CLAIMSNODE_TYPE_OBJ = CLAIMSNODE_TYPE_OBJ;
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }

        const data = this.setData();

        if (this.ids) {
          // 编辑
          data.id = this.ids;
          this.asyncApiClaimsReviewConfigurationEdit(data);
        } else {
          // 添加
          this.asyncApiClaimsReviewConfigurationAdd(data);
        }
      });
    },

    setData() {
      const { customerId, claimsNode, declaredValue, defaultReviewer } = this.form;

      const data = {
        customerId,
        claimsNode: claimsNode || '',
        declaredValue: declaredValue || '',
        defaultReviewer: defaultReviewer || ''
      };

      return data;
    },

    // 添加
    asyncApiClaimsReviewConfigurationAdd(data) {
      this.loading = true;
      apiClaimsReviewConfigurationAdd(data).then(res => {
        if (res.code === 200) {
          // 添加成功
          this.$message.success(this.$t('customerManagements.AddedSuccessfully')); // 添加成功
          this.onClose(true);
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    // 修改
    asyncApiClaimsReviewConfigurationEdit(data) {
      this.loading = true;
      apiClaimsReviewConfigurationEdit(data).then(res => {
        if (res.code === 200) {
          this.$message.success(this.$t('customerManagements.AddedSuccessfully'));// 添加成功
          this.onClose(true);
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onClose(isBool = false) {
      this.$emit('close', isBool);
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
