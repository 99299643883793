<template>
  <div class="upload-container">
    <!-- 暂时不开启本地图片上传 -->
    <!-- <el-button :style="{background:color,borderColor:color}" icon="el-icon-upload" size="mini" type="primary" @click="dialogVisible=true"> -->
<!--    <el-button type="success" size="mini" @click="dialogVisible=true">{{ $t('WorkCentreTable.UploadPictures') }}<i class="el-icon-upload el-icon&#45;&#45;right" />-->
<!--    </el-button>-->

    <el-button size="mini" type="primary" @click="triggerFileInput">
      {{ $t('customerManagements.clickUpload') }}
    </el-button>
    <input
      ref="referenceUpload"
      accept="image/*"
      style="display: none"
      type="file"
      @change="uploadFile"
    >

    <!-- 上传图片 -->
    <!-- </el-button> -->
    <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
      <!-- <el-upload
        :multiple="true"
        :file-list="fileList"
        :show-file-list="true"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :before-upload="beforeUpload"
        class="editor-slide-upload upload"
        action="https://httpbin.org/post"
        list-type="picture-card"
      > -->
      <el-upload
        :key="dialogVisible"
        :accept="filtTypeArr.join(',')"
        :multiple="true"
        :show-file-list="true"
        :on-remove="handleRemove"
        :before-upload="beforeUpload"
        :http-request="enclosureUpload"
        action
        list-type="picture-card"
      >
        <i class="el-icon-plus" />
        <!-- <el-button size="small" type="primary">上传图片</el-button> -->
        <!-- <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div> -->
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <!--        取消-->
        <el-button @click="dialogVisible = false">{{ $t('WorkCentreTable.cancel') }}</el-button>
        <!--        确定-->
        <el-button type="primary" @click="handleSubmit">{{ $t('WorkCentreTable.confirm') }}</el-button>
      </span>

    </el-dialog>
  </div>
</template>

<script>
import { imgTypeArr } from '@/enums/evaluateEnums';
import { uploadOos } from '@/utils/ali_oos_util';

export default {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      filtTypeArr: imgTypeArr, // 上传文件格式
      sizes: 10 // 上传文件大小 单位M
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.referenceUpload.click();
    },
    // 上传文件
    async uploadFile(file) {
      if (file.target.files.length === 0) {
        return;
      }
      const loading = this.$loading({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: 'loading...',
        background: 'rgba(250, 250, 250, 0.6)'
      });
      await uploadOos(file.target.files[0], 'workOrder').then(res => {
        console.log(res, '==res');
        this.$emit('successCBK', [res]);
        setTimeout(() => {
          loading.close();
        }, 600);
      });
    },
    handleSubmit() {
      console.log(this.fileList, '==this.fileList');
      const fileList = this.fileList;
      if (fileList.length === 0) {
        this.$message.warning(this.$t('WorkCentreTable.pleaseUploadPictures')); // 请上传图片
        return;
      }
      const arr = fileList.map(item => {
        return {
          url: item.url
        };
      });
      this.$emit('successCBK', arr);
      this.fileList = [];
      this.dialogVisible = false;
    },
    handleRemove(file) {
      const uid = file.uid;
      this.fileList = this.fileList.filter(item => {
        return item.uid !== uid;
      });
    },
    beforeUpload(file) {
      const { type, size } = file;
      const isLt2M = (size / 1024 / 1024) < this.sizes;
      const isBool = imgTypeArr.includes(type);
      if (!isBool) {
        this.$message.error(`上传格式不符合要求!`);
      }
      if (!isLt2M) {
        this.$message.error(`上传图片大小不能超过 ${this.sizes}MB!`);
      }
      return isBool && isLt2M;

      // const _self = this;
      // const _URL = window.URL || window.webkitURL;
      // const fileName = file.uid;
      // this.listObj[fileName] = {};

      // return new Promise((resolve, reject) => {
      //   const img = new Image();
      //   img.src = _URL.createObjectURL(file);
      //   img.onload = function() {
      //     _self.listObj[fileName] = { hasSuccess: false, uid: file.uid, width: this.width, height: this.height };
      //   };
      //   resolve(true);
      // });
    },

    // 上传oos
    async enclosureUpload(option) {
      const file = option.file;
      const res = await uploadOos(file, 'workOrder');
      if (res.code === 200) {
        const obj = {
          name: file.name,
          url: res.url
        };
        this.fileList = [...this.fileList, obj];
      } else {
        this.$message.error(res.msg);
      }
    }
  }
};
</script>

<style scoped>
.editor-slide-upload {
  margin-bottom: 20px;
}

.editor-slide-upload >>> .el-upload--picture-card {
  width: 100%;
}
</style>
