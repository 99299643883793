<template>
  <div>
    <el-row :gutter="20">
      <!-- 添加 -->
      <el-button
        type="primary"
        size="small"
        :disabled="!isAddDisabled"
        @click="isAdd = true"
      >{{ $t('GlobalSetObj.append') }}</el-button>

      <!-- 导入 -->
      <el-button
        type="info"
        size="small"
        :disabled="!isAddDisabled"
        @click="isImport.isBool = true"
      >{{ $t('CenterForSorting.Import') }}</el-button>

      <!-- 移除 -->
      <el-button
        type="danger"
        size="small"
        :disabled="!multipleSelection.length"
        @click="onRemove"
      >{{ $t('ClaimsManagement.Remove') }}</el-button>

      <el-col :span="8">
        <TipsCmp :len="len" :total-value="totalValue" />
      </el-col>
    </el-row>

    <virtual-scroll
      ref="virtualScroll"
      :data="tableData"
      :item-size="62"
      key-prop="keyCode"
      @change="(virtualList) => list = virtualList">
      <el-table
        :data="list"
        height="350"
        row-key="keyCode"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <virtual-column width="50" type="selection" />
        <virtual-column :label="$t('GlobalSetObj.serial')" width="100" type="index" />

        <!-- 操作网点 -->
        <el-table-column :label="$t('collectionCenter.OperationSite')" prop="siteName" width="120" />

        <!-- 订单号 -->
        <el-table-column :label="$t('basicData.OrderNumber')" prop="barCode" width="170" />

        <!-- 客户单号 -->
        <el-table-column :label="$t('collectionCenter.customerNum')" prop="customerNumber" width="170" />

        <!-- 揽收类型 -->
        <el-table-column :label="$t('newOrder.CollectionType')" prop="collectTypeStr" />

        <!-- 包裹重量 -->
        <el-table-column :label="$t('collectionCenter.bagWeight')" prop="totalDeclaredWeight" />

        <!-- 申报价值 -->
        <el-table-column :label="$t('orderCenterCont.DeclaredValue')" prop="totalDeclaredValue" />

        <!-- 订单状态 -->
        <el-table-column :label="$t('orderCenterCont.OrderStatus')" prop="statusStr" />

        <!-- 当前轨迹 -->
        <el-table-column :label="$t('ClaimsManagement.CurrentTrajectory')" prop="trajectory" width="170" />

        <!-- 轨迹时间 -->
        <el-table-column :label="$t('ClaimsManagement.TrackTime')" prop="trajectoryTime" width="170" />

        <!-- 备注 -->
        <el-table-column :label="$t('GlobalSetObj.remark')" fixed="right" width="200">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.remarks"
              type="textarea"
              :rows="2"
              :placeholder="$t('GlobalSetObj.pleaseInputContent')"
              maxlength="50"
              show-word-limit
            />
          </template>
        </el-table-column>
      </el-table>
    </virtual-scroll>

    <!-- 添加订单 -->
    <el-dialog
      :title="$t('orderCenterCont.AddOrder')"
      :visible.sync="isAdd"
      width="500px"
      append-to-body
      :close-on-click-modal="false"
    >
      <AddCmp v-if="isAdd" :basics-row="basicsRow" @close="onAddClick" />
    </el-dialog>

    <!-- 导入 -->
    <el-dialog
      :title="$t('CenterForSorting.Import')"
      :visible.sync="isImport.isBool"
      width="500px"
      append-to-body
      :close-on-click-modal="false"
    >
      <ImportExcelCmp
        import-tmp-url="https://express-1313947203.cos.sa-saopaulo.myqcloud.com/upload-file/workOrder/%E7%90%86%E8%B5%94%E6%A8%A1%E6%9D%BF-20241225T172539.xlsx" import-tmp-name="理赔申请导入模板" :key-and-val-obj="excelKeyAndValue"
        @change="onImportClick"
      />
    </el-dialog>
  </div>
</template>

<script>

import VirtualScroll, { VirtualColumn } from 'el-table-virtual-scroll';
import { cloneDeep } from 'lodash-es';
import TipsCmp from './../OrderTipsCmp.vue';
import { basicsRow as basicsObj, addQuery, asyncApiClaimformOrderDetail, setAddData } from './utils.js';
import Big from 'big.js';
import { COLLECT_TYPE_OBJ } from '@/views/claimsManagement/claimList/utils.js';

const excelKeyAndValue = {
  'barCode': {
    key: 'barCode',
    header: '订单号/安骏运单号/揽收单号*'
  },
  'remarks': {
    key: 'remarks',
    header: '备注'
  }
};

export default {
  name: '',

  components: {
    VirtualScroll,
    VirtualColumn,
    TipsCmp,
    AddCmp: () => import('./OrderAddCmp.vue'),
    ImportExcelCmp: () => import('@/views/claimsManagement/cmp/ImportExcelCmp/index.vue')
  },

  props: {
    basicsRow: {
      type: Object,
      default: () => {
        return {
          ...basicsObj
        };
      }
    },
  },

  data() {
    return {
      tableData: [],
      list: [],

      multipleSelection: [], // 选中的数据

      isAdd: false,

      // 导入
      isImport: {
        isBool: false
      }
    };
  },

  computed: {
    // 总票数
    len() {
      return this.tableData.length;
    },

    // 总申报价值
    totalValue() {
      let val = 0;

      if (this.tableData.length !== 0) {
        val = this.tableData.reduce((acc, item) => {
          return new Big(acc).plus(item.totalDeclaredValue || 0);
        }, 0).toNumber();
      }

      return val;
    },

    // 能否添加
    isAddDisabled() {
      const { customerId, claimsNode, orderType } = this.basicsRow;
      // 客户 与 理赔节点 为必填
      if (!customerId || !claimsNode) {
        return false;
      }

      // 如果理赔节点选择了 非揽收
      if (claimsNode !== COLLECT_TYPE_OBJ[2].value) {
        return true;
      }

      // 如果是非揽收，则揽收类别 为必填
      if (!orderType) {
        return false;
      }

      return true;
    }
  },

  watch: {
    'tableData': {
      deep: true,
      immediate: true,
      handler(val) {
        this.$emit('getData', val);
      }
    }
  },

  created() {
    this.excelKeyAndValue = excelKeyAndValue;
  },

  methods: {
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取表单数据（仅用于父级调用），数据已经处理，可以直接提交
    getDataList() {
      const obj = cloneDeep(this.tableData);
      return obj;
    },

    // 回填数据
    setDataList(arr) {
      this.tableData = setAddData(arr);
    },

    // 添加数据
    onAddClick(arr) {
      this.isAdd = false;

      console.log(arr);

      if (arr === undefined) return;

      const addArr = this.tableData.map((item) => {
        return item.barCode + '' + item.orderNumber;
      });

      // 去重
      const arr2 = arr.filter(item => {
        return !addArr.includes(item.barCode + '' + item.orderNumber);
      });

      if (arr2.length === 0) return;

      this.$message.success(this.$t('customerManagements.AddedSuccessfully')); // 添加成功

      // 添加新的数据
      this.tableData.push(...arr2);
    },

    // 移除数据
    onRemove() {
      // this.$emit('onRemove', this.multipleSelection);
      const addArr = this.multipleSelection.map((item) => {
        return item.orderNumber;
      });

      this.tableData = this.tableData.filter(item => {
        return !addArr.includes(item.orderNumber);
      });
    },

    // 清空数据
    onClear() {
      this.tableData = [];
    },

    // 校验
    submitForm() {
      if (this.tableData.length === 0) {
        this.$message.warning(this.$t('orderCenterCont.AddOrder')); // 请添加订单
        return false;
      }

      return true;
    },

    onImportClick(val) {
      this.isImport.isBool = false;
      const codeArr = val.map(item => {
        return item.barCode;
      });

      if (codeArr.length > 20000) {
        return this.$message.warning(this.$t('receivablePayable.Only20000PiecesImport')); // 请导入20000条数据以下的数据
      }

      const { claimsNode, orderType, customerId } = this.basicsRow;
      const obj = {
        ...addQuery,
        claimsNode,
        orderType,
        customerId,
        orderNumbers: codeArr
        // 'claimsNode': 2,
        // 'orderType': 2,
        // 'customerId': '24005',
        // orderNumbers: ['CL2023042009562978836',
        //   'CL2023042009562978837',
        //   'CL2023042009562978838']
      };

      asyncApiClaimformOrderDetail(obj, true).then(arr => {
        if (arr.length === 0) { return; }
        // 回填备注
        const list = arr.map(item => {
          const row = val.find(key => {
            return key.barCode === item.barCode;
          });
          if (!row) return item;
          item.remarks = row.remarks;
          return item;
        });

        // this.onClose(res);
        this.onAddClick(list);
      }).catch(err => {
        console.error(err);
      });
    }

  }

};
</script>

<style lang="scss" scoped>
.el-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 10px;
}
</style>
<style>
.el-notification {
  overflow-y: scroll;
  height: calc(100% - 30px);
}
.el-notification__group {
  width: 100% !important;
}
</style>
