<!-- 查询组件 -->
<template>
  <div class="query-container">
    <div class="query-form">
      <el-form
        ref="form"
        :model="params"
        label-width="160px"
        size="small"
        :label-position="$i18n.locale !== 'zh' ? 'top' : 'right'"
      >
        <!--应付主体-->
        <el-form-item :label="$t('basicData.PayablePrincipal')">
          <QueryProviderCmp :val="params.providerId" @change="val => params.providerId = val" />
        </el-form-item>

        <!-- 网点 -->
        <el-form-item :label="$t('basicData.Outlets')">
          <QuerySiteCmp
            :val="params.siteId"
            :provider-id="params.providerId"
            @change="val => params.siteId = val"
          />
        </el-form-item>

        <!-- 客户 -->
        <el-form-item :label="$t('receivablePayable.customer')">
          <QueryCustomerCmp :val="params.customerId" @change="val => params.customerId = val" />
        </el-form-item>

        <!-- 业务时间 -->
        <el-form-item>
          <template slot="label">
            <el-select
              v-model="params.timeField"
              :placeholder="$t('operationCenter.PleaseSelect')"
            >
              <!--业务时间-->
              <el-option v-for="item in TIME_TYPE" :key="item.value" :label="item.label" :value="item.value"/>
            </el-select>
          </template>
          <el-date-picker
            v-model="queryDate"
            style="width: 100%"
            type="datetimerange"
            :picker-options="dateLimit"
            :default-time="['00:00:00', '23:59:59']"
            :range-separator="$t('operationCenter.to')"
            :start-placeholder="$t('operationCenter.startDate')"
            :end-placeholder="$t('operationCenter.endDate')"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>

        <!-- 业务单号 / 订单号 -->
        <el-form-item>
          <template slot="label">
            <el-select
              v-model="params.numberField"
              :placeholder="$t('operationCenter.PleaseSelect')"
            >
              <!--业务单号-->
              <el-option
                key="1"
                :label="$t('receivablePayable.TicketNumber')"
                value="waybill_number"
              />
              <!--条形码-->
              <el-option
                key="2"
                :label="$t('collectionCenter.orderNumber')"
                value="order_number"
              />
            </el-select>
          </template>
          <InputTextareaCmp ref="InputTextareaRef" :val="numberList" :max-length="20000" :autosize="{ minRows: 2, maxRows: 4}"/>
        </el-form-item>

        <!-- 操作人 -->
        <el-form-item>
          <template slot="label">
            <el-select
              v-model="selectPeople"
              :placeholder="$t('collectionCenter.pleaseChoose')"
              class="selectTimeClass autoWidth"
              @change="changePeople"
            >
              <el-option
                v-for="(item, index) in selectPeopleList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
          <!-- 揽收操作人 -->
          <CollectorsCmp
            v-if="selectPeople === 'n1'" 
            v-model="params.collectorIdList"
            @change="val => params.collectorIdList = val"
          />
          <!-- 派件操作人 -->
          <SendCmp
            v-else
            v-model="params.dispatcherList"
            @change="val => params.dispatcherList = val"
          />
        </el-form-item>

        <!-- 是否已支付 -->
        <el-form-item :label="$t('receivablePayable.WhetherItHasBeenPaid')">
          <el-select
            v-model="params.isPaid"
            filterable
            clearable
            :placeholder="$t('operationCenter.PleaseSelect')"
            style="width: 100%;"
          >
            <el-option :label="$t('basicData.all')" :value="null"/>
            <el-option :label="$t('GlobalSetObj.yes')" :value="1"/>
            <el-option :label="$t('GlobalSetObj.No')" :value="0"/>
          </el-select>
        </el-form-item>

        <!-- 是否生成对账单 -->
        <el-form-item :label="$t('receivablePayable.WhetherToGenerateAStatement')">
          <el-select
            v-model="params.isGenBill"
            filterable
            clearable
            :placeholder="$t('operationCenter.PleaseSelect')"
            style="width: 100%;"
          >
            <el-option :label="$t('basicData.all')" :value="null"/>
            <el-option :label="$t('GlobalSetObj.yes')" :value="1"/>
            <el-option :label="$t('GlobalSetObj.No')" :value="0"/>
          </el-select>
        </el-form-item>

        <!--业务类型-->
        <el-form-item :label="$t('collectionCenter.businessT')">
          <el-select
            v-model="params.businessType"
            filterable
            :placeholder="$t('operationCenter.PleaseSelect')"
            clearable
            style="width: 100%;"
            @change="changeBusinessType"
          >
            <!--全部-->
            <el-option
              key="0"
              :label="$t('basicData.all')"
              :value="null"
            />
            <!--揽收-->
            <el-option
              key="1"
              :label="$t('collectionCenter.lanshou')"
              :value="2"
            />
            <!--派送-->
            <el-option
              key="2"
              :label="$t('collectionCenter.dispatch')"
              :value="3"
            />
          </el-select>
        </el-form-item>

        <!-- 网点类型： -->
        <el-form-item :label="$t('basicData.TheTypeOutlet')">
          <el-select
            v-model="params.siteType"
            placeholder=""
            clearable
            filterable
            style="width: 100%;"
          >
            <el-option
              :label="$t('collectionCenter.hut')"
              :value="1"
            /><!-- 小屋 -->
            <el-option
              :label="$t('collectionCenter.dot')"
              :value="2"
            /><!-- 网点 -->
            <el-option
              :label="$t('collectionCenter.transferCenter')"
              :value="3"
            /><!-- 转运中心 -->
            <el-option
              :label="$t('collectionCenter.headOffice')"
              :value="4"
            /><!-- 总部 -->
            <el-option
              :label="$t('collectionCenter.merchant')"
              :value="5"
            /><!-- 商家 -->
          </el-select>
        </el-form-item>

        <!-- 派送模式： -->
        <el-form-item :label="$t('newOrder.OperationMode')" v-if="params.businessType === 3">
          <el-select
            v-model="params.operationMode"
            placeholder=""
            clearable
            filterable
            style="width: 100%;"
          >
            <el-option
              label="AJD(自营)"
              :value="0"
            /><!-- AJD(自营) -->
            <el-option
              label="DSP(第三方)"
              :value="1"
            /><!-- DSP(第三方) -->
          </el-select>
        </el-form-item>

        <!-- 如果“网点类型”选中“安骏小屋”并且“业务类型”为揽收的数据则“揽收方式”输入框隐藏，否则都显示并且必填 -->
        <!--揽收方式-->
        <el-form-item 
          :label="$t('collectionCenter.lanshouTypee')"
          v-if="params.businessType === 2 && params.siteType !== 1"
        >
          <el-select
            v-model="params.collectType"
            :placeholder="$t('GlobalSetObj.pleaseChoose')"
            style="width: 100%;"
          >
            <!--上门揽收-->
            <el-option
              :label="$t('collectionCenter.doorPickUp')"
              :value="2"
            />
            <!--上门交件-->
            <el-option
              :label="$t('collectionCenter.dropOff')"
              :value="1"
            />
          </el-select>
        </el-form-item>

        <!--批次号-->
        <el-form-item :label="$t('basicData.BatchNo')" v-if="selectKey === 1">
          <el-input
            v-model="params.billBatchNumber"
            :readonly="isReadOnly"
          />
        </el-form-item>
        
        <!--收款单号-->
        <el-form-item :label="$t('receivablePayable.PaymentSlipNumber')" v-if="selectKey === 2">
          <el-input
            v-model="params.payableNumber"
            :readonly="isReadOnly"
          />
        </el-form-item>

        <!-- 费用类型 -->
        <el-form-item :label="$t('basicData.ExpenseType')" prop="feeName" >
          <FeeCodeCmp :val="params.feeCode" @change="val => params.feeCode = val" />
        </el-form-item>

        <!--订单状态-->
        <el-form-item :label="$t('collectionCenter.OrderStatus')">
          <el-select
            v-model="params.status"
            filterable
            clearable
            :placeholder="$t('operationCenter.PleaseSelect')"
            style="width: 100%;"
          >
            <el-option
              v-for="(item, index) in orderList"
              :key="index"
              :label="item.label"
              :value="item.code"
            />
          </el-select>
        </el-form-item>

        <!-- 发件人州 -->
        <el-form-item :label="$t('newOrder.SenderState')">
          <el-input
            v-model="params.senderState"
            :placeholder="$t('GlobalSetObj.pleaseInput')"
          />
        </el-form-item>

        <!-- 收件人州 -->
        <el-form-item :label="$t('newOrder.RecipientState')">
          <el-input
            v-model="params.receiveState"
            :placeholder="$t('GlobalSetObj.pleaseInput')"
          />
        </el-form-item>
      </el-form>
    </div>

    <div class="query-footer">
      <!--查 询-->
      <el-button
        type="primary"
        size="small"
        @click="onSearch"
      >{{ $t('basicData.Search') }}</el-button>
      <!--重 置-->
      <el-button
        type="success"
        size="small"
        @click="onReset"
      >{{ $t('basicData.reset') }}</el-button>
      <!--取 消-->
      <el-button
        size="small"
        @click="onClose"
      >{{ $t('basicData.Cancel') }}</el-button>
    </div>
  </div>
</template>

<script>
import QueryProviderCmp from '@/views/finance/cmp/Query/QueryProviderCmp.vue';
import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp.vue';
import QueryCustomerCmp from '@/views/finance/cmp/Query/QueryCustomerCmp.vue';
import InputTextareaCmp from '@/views/finance/cmp/Query/InputTextareaCmp.vue';
import CollectorsCmp from '@/views/finance/payableManage/feesPayable/component/CollectorsCmp.vue';
import SendCmp from '@/views/finance/payableManage/feesPayable/component/SendCmp.vue';
import FeeCodeCmp from '@/views/finance/payableManage/feesPayable/component/FeeCodeCmp.vue';
import { cloneDeep } from 'lodash-es';
import { WAYBILL_STATUS, ORDER_TYPE_LIST, SEARCH_PARAMS, TIME_TYPE } from '@/views/finance/enums.js';
import { getTextareaVal } from '@/views/finance/basicData/PriceInquiryLog/utils.js';

export default {
  name: 'SearchForm',

  components: {
    QueryProviderCmp,
    QueryCustomerCmp,
    QuerySiteCmp,
    InputTextareaCmp,
    CollectorsCmp,
    SendCmp,
    FeeCodeCmp
  },

  props: {
    // 查询类型
    selectKey: {
      type: Number,
      default: 1
    },
    // 是否只读
    isReadOnly: {
      type: Boolean,
      default: false
    },
    // 批次号
    billBatchNumber: {
      type: String,
      default: ''
    },
    // 收款单号
    payableNumber: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      WAYBILL_STATUS,  // 物流订单状态
      ORDER_TYPE_LIST,  // 揽收订单状态
      orderList : [], // 订单状态
      TIME_TYPE, // 时间类型
      params: {
        ...SEARCH_PARAMS,
        billBatchNumber: this.billBatchNumber,
        payableNumber: this.payableNumber,
      },
      queryDate: [], // 业务时间
      numberList: '', // 输入框内容
      selectPeople: 'n1',
      selectPeopleList: [
        { value: 'n1', label: this.$t('basicData.揽收操作人') }, // '揽收人'
        { value: 'n2', label: this.$t('basicData.派送操作人') } // '派送人'
      ],
      // 日期限制
      pickerMinDate: '',
      pickerRange: 15 * 24 * 60 * 60 * 1000, // 可选择日期范围 为15天
      loadUserList: []
    };
  },

  computed: {
    /**
     * 日期限制处理
     *    默认情况下限制15天
     *    如果输入了 业务单号 或 客户单号 或 批次号，则不限制时间
     */
    dateLimit() {
      const { numberList, pickerMinDate, pickerRange } = this;
      const { billBatchNumber } = this.params;

      // 默认限制15天
      return {
        // 设置不能选择的日期
        onPick: ({ minDate }) => {
          // 输入了内容
          if (numberList || billBatchNumber) {
            // 取消限制
            this.pickerMinDate = null;
            return;
          }

          if (minDate && pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          // 输入了内容
          if (numberList || billBatchNumber) {
            // 取消时间限制
            return false;
          }

          if (this.pickerMinDate) {
            return (time.getTime() > (pickerMinDate + pickerRange)) || (time.getTime() < (pickerMinDate - pickerRange));
          }
          return false;
        }
      };
    }
  },

  methods: {
    // 业务类型变化
    changeBusinessType(value) {
      if (this.params.businessType !== 2) {
        this.params.collectType = null;
      }
      if (this.params.businessType !== 3) {
        this.params.operationMode = '';
      }

      this.orderList = value === 2 ? (ORDER_TYPE_LIST.map(item => ({
        label: item.collectStatusName,
        code: item.collectStatus
      }))) : value === 3 ? WAYBILL_STATUS : [];
    },

    changePeople() { // 切换揽收人 / 派送人
      this.params.collectorIdList = [];
      this.params.dispatcherList = [];
    },

    // 重置
    onReset() {
      this.params = { ...SEARCH_PARAMS };
      this.queryDate = [];
      this.numberList = '';
      this.$refs.InputTextareaRef.clear();
      this.params.billBatchNumber = this.billBatchNumber;
      this.params.payableNumber = this.payableNumber;
    },

    // 搜索
    onSearch() {
      const obj = cloneDeep(this.params);
      // 验证时间范围
      if (this.queryDate && this.queryDate.length) {
        const start = new Date(this.queryDate[0]).getTime();
        const end = new Date(this.queryDate[1]).getTime();
        if (start > end) {
          this.$message.warning(this.$i18n.t('receivablePayable.TheStartTimeCannotBeGreaterThanTheEndTime'));
          return;
        }
        obj.startTime = this.queryDate[0];
        obj.endTime = this.queryDate[1];
      } else {
        obj.startTime = '';
        obj.endTime = '';
      }

      obj.numberList = getTextareaVal(this.numberList);

      this.$emit('search', obj);
    },

    // 取消
    onClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped lang="scss">
.query-container {
	height: 100vh;
	padding: 0 20px;

	.query-form {
		height: calc(100% - 80px);
		overflow-y: scroll;
		padding-bottom: 55px;
	}

	.query-footer {
		text-align: center;
    border-top: 1px solid #ccc;
    padding: 10px;
    position: sticky;
    bottom: 0px;
    z-index: 10000;
    background-color: #fff;
	}
}
</style>
