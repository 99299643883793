<template>
  <div ref="content" class="containerClass contentData">
    <!--    <el-row>-->
    <!--      <el-card class="box-card tipWord">-->
    <!--        <el-col :span="24" style="margin-bottom: 10px;">{{ $t('GlobalSetObj.tow2') }}</el-col>-->
    <!--        <el-col :span="24" style="margin-bottom: 10px;line-height: 30px">{{ $t('GlobalSetObj.open') }}-->
    <!--          <a-->
    <!--            href="https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/software/安骏桌面插件/安骏桌面插件.exe"-->
    <!--            style="font-size: 14px;color: #409eff"-->
    <!--            type="primary"-->
    <!--          >{{ $t('GlobalSetObj.plugin') }}-->
    <!--          </a>-->
    <!--          {{ $t('GlobalSetObj.WeightInformation') }}-->
    <!--        </el-col>-->
    <!--        <OperationGuidance />-->
    <!--      </el-card>-->
    <!--    </el-row>-->
    <el-row :gutter="5" style="height: 100%;">
      <el-col :span="7" style="height: 100%">
        <el-card style="height: 100%">
          <div class="scan_Waybill">
            {{ $t('GlobalSetObj.ScanningWaybill') }}
            <span style="float: right;">{{ equipmentNo }}</span>
          </div>
          <div class="scannerWeight" style="margin-top: 10px">
            <el-input
              v-show="!isConnectWs&&!connectStatus||connectStatus"
              ref="inputRef"
              v-model.trim="packageListForm.params.waybillNumber"
              :disabled="isDisable"
              @blur="blurFocus"
              @keyup.enter.native="saveStorageRecordNoenter(true,false)"
            />
            <!--            ws连接-->
            <h3 v-if="isConnectWs">{{ packageListForm.params.waybillNumber }}</h3>
          </div>
          <!--称重设备品牌-->
          <div style="margin-top: 10px">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              class="demo-ruleForm"
              label-position="top"
              label-width="auto"
            >
              <el-form-item :label="$t('GlobalSetObj.equipamento')" prop="equipmentBrand">
                <el-select
                  id="dropdown"
                  v-model="ruleForm.equipmentBrand"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  :popper-append-to-body="false"
                  style="width: 100%"
                  @change="handleDeviceBrand"
                >
                  <el-option
                    v-for="item in DeviceBrandList"
                    :key="item.id"
                    :label="item.brandName"
                    :value="item.brandName"
                  >
                    <div style="width: 300px;">
                      <el-tooltip placement="left">
                        <div slot="content">
                          <img
                            :src="item.pictureUrl"
                            alt=""
                            class="image"
                            style="width: 300px;height: 300px"
                          >
                        </div>
                        <img
                          :src="item.pictureUrl"
                          alt=""
                          class="image"
                          style="width: 50px;height: 50px;float:left;"
                        >
                      </el-tooltip>
                      <span style="margin-left: 20px;width: 50px;height: 50px;">{{ item.brandName }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <!--长宽高-->
          <div>
            <div style="margin-bottom: 3px;">{{ $t('operationCenter.Size') }}（cm）</div>
            <div>
              <el-input v-model="ruleForm.length" style="width: 33%;display: inline-block" />
              <el-input v-model="ruleForm.width" style="width: 33%;display: inline-block" />
              <el-input v-model="ruleForm.height" style="width: 33%;display: inline-block" />
            </div>
          </div>
          <div style="margin-top: 20px;">
            {{ $t('orderCenterCont.Repetition') }}：
            {{ $t('GlobalSetObj.No') }}
            <el-switch
              v-model="repetition"
              :disabled="isKuaiShow"
              active-color="#13ce66"
              inactive-color="#ff4949"
              style="margin: 0 5px;"
            />
            {{ $t('GlobalSetObj.yes') }}
          </div>
          <el-row v-show="!connectStatus&&!isConnectWs" style="margin-top: 100px">
            <el-col :span="24" style="text-align: center">
              <div class="waringTip"> {{ $t('GlobalSetObj.deviceNotConnected') }}</div>
            </el-col>
            <el-col :span="24" class="tip_Content">
              <div>
                1. {{ $t('GlobalSetObj.PleaseConnectTheWeighingDeviceUSBPortToTheComputer') }}
              </div>
              <div>
                2. {{ $t('GlobalSetObj.PleaseEnsure') }}
                <a
                  href="https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/software/安骏桌面插件/安骏桌面插件.exe"
                  style="font-size: 14px;color: #409eff"
                  type="primary"
                >{{ $t('GlobalSetObj.plugin') }}
                </a>
                {{ $t('GlobalSetObj.OnState') }}
              </div>
              <div>3. {{ $t('GlobalSetObj.EquipmentMatching') }}</div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="17" style="height: 100%;">
        <el-card style="height: 100%;overflow-y: auto;width: 100%;">
          <div class="scan_Waybill" style="margin-bottom: 10px;">
            <span>{{ $t('GlobalSetObj.ScannerRecords') }}</span>
            <span style="margin-left: 25px">{{ $t('GlobalSetObj.InStorage') }} {{ packageListForm.total }}</span>
            <el-button
              size="mini" style="float: right" type="primary"
              @click="exportData()"
            >
              {{ $t('operationCenter.exports') }}
            </el-button>
          </div>
          <div class="tableClass" style="width: 100%;">
            <el-table
              ref="tableRef"
              :data="tableData"
              :header-cell-style="{background:'#f2f2f2'}"
              :height="tableHeight"
              :highlight-current-row="true"
              :row-class-name="tableRowClassName"
              border
              style="width: 100%;height:100%"
            >
              <!--运单号-->
              <el-table-column :label="$t('GlobalSetObj.waybillNumber')" prop="waybillNumber" width="210" />
              <!--客户单号-->
              <el-table-column :label="$t('placeOrder.customerNumber')" prop="customerNumber" width="160" />
              <!--服务商单号-->
              <el-table-column :label="$t('GlobalSetObj.serviceLogistics')" prop="logisticsNumber" width="160" />
              <!--称重（KG）-->
              <el-table-column :label="$t('GlobalSetObj.weigh') + '(kg)'" prop="packageWeight" width="160" />
              <!--长*宽*高(cm)-->
              <el-table-column :label="$t('GlobalSetObj.Comprimento')" prop="lengthWidthHeight" width="160">
                <template slot-scope="scope">
                  <span>{{ scope.row.length ? scope.row.length : 0 }}</span>*
                  <span>{{ scope.row.width ? scope.row.width : 0 }}</span>*
                  <span>{{ scope.row.height ? scope.row.height : 0 }}</span>
                </template>
              </el-table-column>
              <!--体积-->
              <el-table-column :label="$t('operationCenter.volume')" prop="volume" width="160" />
              <!--操作时间-->
              <el-table-column :label="$t('GlobalSetObj.operationTime')" prop="createTime" width="160" />
            </el-table>
            <!-- 新增的特效 -->
            <i v-show="tipsObj.isPrompt" :class="promptClass" :style="{ height: tipsObj.heightVal + 'px' }" />
          </div>
          <!--  分页  -->
          <el-pagination
            :current-page="packageListForm.pageNumber"
            :page-size="packageListForm.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :total="packageListForm.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            style="margin-top: 10px; float: right;margin-bottom: 20px"
            @size-change="packageListSizeChange"
            @current-change="packageListCurrentChange"
          />
        </el-card>
      </el-col>
    </el-row>
    <!--  设置设备  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.SetDevice')"
      :visible.sync="SetDeviceDialog"
      width="25%"
    >
      <div>
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          label-position="top"
          label-width="auto"
        >
          <el-row :gutter="10" style="margin-bottom: 10px">
            <el-col :span="16">
              <el-form-item :label="$t('GlobalSetObj.equipamento')" prop="equipmentBrand">
                <el-select
                  id="dropdown"
                  v-model="ruleForm.equipmentBrand"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  :popper-append-to-body="false"
                  style="width: 100%"
                  @change="handleDeviceBrand"
                >
                  <el-option
                    v-for="item in DeviceBrandList"
                    :key="item.id"
                    :label="item.brandName"
                    :value="item.brandName"
                  >
                    <span style="float: left">
                      <el-tooltip placement="left">
                        <div slot="content">
                          <img
                            :src="item.pictureUrl"
                            alt=""
                            class="image"
                            style="width: 300px;height: 300px"
                          >
                        </div>
                        <img
                          :src="item.pictureUrl"
                          alt=""
                          class="image"
                          style="width: 50px;height: 50px"
                        >
                      </el-tooltip>
                    </span>
                    <span style="margin-left: 20px;width: 50px;height: 50px;">{{
                      item.brandName
                    }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="SetDeviceDialog = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="setupElectronicScales('ruleForm')">{{
          $t('GlobalSetObj.Salve')
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>

  </div>
</template>

<script>
import {
  apiEditPackageVolume,
  checkGetWaybillInfo,
  checkWaybillStorage,
  getAllweightBrand,
  saveStorageRecordNo,
  saveStorageRecordNoGetLabelUrl,
  updateReviseWeight,
  waybillOperationLogPage
} from '@/api/storeAdmin';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
// import OperationGuidance from '@/views/automaticSortings/components/OperationGuidance';
import { apiGetWeight, apiOPenComPort, apiStartPortsList, apiStartUp, autoPrint } from '@/api/serialPort';
import { Loading } from 'element-ui';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const okMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/su.wav';
const errorMp3 =
  'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/print_error.wav';

export default {
  name: 'WarehousingWeight',
  components: {
    // OperationGuidance
  },
  beforeRouteLeave(to, from, next) {
    from.meta.keepAlive = false;
    next();
  },
  data() {
    return {
      tipsObj: {
        heightVal: 48,
        isPrompt: false,
        isOverweight: false // 是否正在处理超重
      },
      ruleForm: {
        equipmentBrand: '',
        computerChannel: '',
        length: null,
        width: null,
        height: null
      },
      rules: {
        equipmentBrand: [
          { required: true, message: this.$t('GlobalSetObj.SelectEquipment'), trigger: 'blur' }
        ]
      },
      weight: '',
      tableData: [],
      packageListForm: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        params: {
          waybillNumber: ''
        }
      },
      SetDeviceDialog: false,
      userIdAndNameObj: null,
      SetDevice: [], // 串口列表
      connectStatus: 0, // 0：未连接 1：已连接
      timer: null, // 循环获取电子秤重量定时器
      electronicScaleWeight: 0, // 电子秤获取的重量
      okMp3,
      errorMp3,
      isFocus: false,
      isDisable: true, // 666
      OperationResult: '',
      SuccessMsg: false,
      errorMsg: false,
      errMsg: false,
      isTormat: false, // 是否翻译成葡语
      DeviceBrandList: [],
      firstLink: true, // 第一次进入页面,不加载actived函数
      // isLink: true// 用来判断是否需要请求连接
      promptClass: 'prompt',
      isConnectWs: false, // 是否连接ws
      // ws
      wsObj: {
        ws: null, // WebSocket
        timeVal: 30 * 1000, // 心跳检测间隔事件
        index: 0, // 重试次数
        time: 0
      },

      // 用户信息
      webSocketObj: {
        userId: '', // 当前用户id
        operatorName: '', // 当前用户姓名
        workConsole: '' // 操作台
      },
      onlineTime: null, // ws心跳定时器
      tab: true,
      previousValue: null,
      stabilityCount: 0,
      isrequest: false,
      equipmentNo: '', // 设备号
      repetition: false, // 复重
      rightTableWaybillNumbers: [],
      rightTableDataAll: [],
      tableHeight: 200,
      isKuaiShow: false
    };
  },
  computed: {
    IsHeight() {
      return {
        height: this.isTormat ? '120px' : '100px'
      };
    },
    ElectronicBrand() {
      return this.$store.getters.ElectronicBrand;
    },
    isLink() {
      return this.$store.getters.isLink1;
    }
  },
  watch: {
    $route(to, form) {
      if (to.path === '/automaticSorting/WarehousingWeight') {
        this.firstLink = false;
      }
    }
  },
  created() {
    // this.$router.replace(this.$route.fullPath);
    this.colorText = 'warehousing';
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
      this.promptClass = 'prompt1';
    }
    this.userIdAndNameObj = getUserIdAndNameObj();
    // this.getSearch();
    this.getAllBrand();
    // 进入页面连接电子秤
    if (this.firstLink) {
      this.initDevicePlugin();
    }
    this.$nextTick(() => {
      this.tableHeight = window.document.body.offsetHeight - 210;
    });
    // 进行ws连接
    // this.creatWs()
  },
  activated() {
    // 进入连接电子秤
    if (!this.firstLink) {
      if (this.isLink) {
        this.initDevicePlugin();
      }
    }
    this.$nextTick(() => {
      this.$refs.inputRef.$refs.input.focus();
    });
    this.colorText = 'warehousing';
    this.tipsObj.isPrompt = false;
  },
  beforeDestroy() {
    // 页面销毁前清除定时器
    clearInterval(this.timer);
    // 页面销毁前清除ws定时器
    clearInterval(this.onlineTime);
  },
  methods: {
    // 导出
    exportData() {
      const that = this;

      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        let Sheet1_data = [];
        Sheet1.columns = [
          {
            header: that.$t('GlobalSetObj.waybillNumber'), // '运单号'
            key: 'waybillNumber',
            width: 20
          },
          {
            header: that.$t('GlobalSetObj.CustomerWeight'), // '客户重量（KG）'
            key: 'customerNumber',
            width: 20
          },
          {
            header: that.$t('GlobalSetObj.serviceLogistics'), // '服务商单号'
            key: 'logisticsNumber',
            width: 20
          },
          {
            header: that.$t('GlobalSetObj.weigh') + '(KG)', // '称重（KG）'
            key: 'packageWeight',
            width: 15
          },
          {
            header: that.$t('GlobalSetObj.Comprimento'), // '长*宽*高（cm）'
            key: 'lengthWidthHeight',
            width: 15
          },
          {
            header: that.$t('operationCenter.volume'), // '体积'
            key: 'volume',
            width: 15
          },
          {
            header: that.$t('GlobalSetObj.operationTime'), // '操作时间'
            key: 'createTime',
            width: 15
          }
        ];
        Sheet1_data = [];
        that.rightTableDataAll.map((item) => {
          Sheet1_data.push({
            waybillNumber: item.waybillNumber,
            customerNumber: item.customerNumber,
            logisticsNumber: item.logisticsNumber,
            packageWeight: item.packageWeight,
            lengthWidthHeight: item.lengthWidthHeight,
            volume: item.volume,
            createTime: item.createTime
          });
        });
        Sheet1.addRows(Sheet1_data);
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = that.$t('GlobalSetObj.ScannerRecords') + '.xlsx'; // '扫描入库记录.xlsx'
        saveAs(blob, saveName);
      }

      ddd();
    },
    // 获取所有设备
    getAllBrand() {
      getAllweightBrand().then(res => {
        if (res.code===200) {
          this.DeviceBrandList = res.data;
          if (this.ElectronicBrand) {
            this.ruleForm.equipmentBrand = this.ElectronicBrand;
          } else {
            this.ruleForm.equipmentBrand = this.DeviceBrandList[1].brandName;
          }
          //   添加快手选项
          this.DeviceBrandList.push({
            brandName: 'KUAI_SHOW',
            id: 3,
            pictureUrl: 'https://express-1313947203.cos.sa-saopaulo.myqcloud.com/upload/images/LOGO/16866399268334.png'
          });
          if (this.ruleForm.equipmentBrand === 'KUAI_SHOW') {
            this.isKuaiShow = true;
            this.repetition = false;
          } else {
            this.isKuaiShow = false;
          }
        }
      });
    },
    saveStorageRecordNoenter(tab, isrequest) {
      // 手动切换是否请求
      isrequest !== undefined ? this.isrequest = isrequest : this.isrequest;
      if (this.isrequest) {
        return;
      }
      // 这里需要
      isrequest !== undefined ? this.isrequest = !isrequest : this.isrequest;
      this.stabilityCount = 0;
      if (tab) {
        this.tab = tab;
      }
      if (!this.tab || !this.packageListForm.params.waybillNumber || this.packageListForm.params.waybillNumber.length < 6) {
        return;
      }
      if (Number(this.electronicScaleWeight) < 0) {
        this.SuccessMsg = false;
        this.errMsg = true;
        this.errorMsg = true;
        this.OperationResult = this.$t('GlobalSetObj.cannotNegative');
        return;
      }
      const regex = /^\d+(\.\d{0,3})?$/;
      const a = [];
      if (this.ruleForm.length) {
        if (!regex.test(this.ruleForm.length)) {
          this.ruleForm.length = '';
        } else {
          a.push(this.ruleForm.length);
        }
      }
      if (this.ruleForm.width) {
        if (!regex.test(this.ruleForm.width)) {
          this.ruleForm.width = '';
        } else {
          a.push(this.ruleForm.width);
        }
      }
      if (this.ruleForm.height) {
        if (!regex.test(this.ruleForm.height)) {
          this.ruleForm.height = '';
        } else {
          a.push(this.ruleForm.height);
        }
      }
      if (a.length > 0 && a.length < 3) {
        this.$message.warning(this.$t('operationCenter.lengthWidthAndHeightAll'));
        return;
      }
      const data = {
        waybillNumber: this.packageListForm.params.waybillNumber,
        packageWeight: this.electronicScaleWeight,
        length: this.ruleForm.length,
        width: this.ruleForm.width,
        height: this.ruleForm.height
      };
      if (Number(this.electronicScaleWeight) > 0) {
        if (this.repetition === false) {
          // 非复重情况下调用接口
          saveStorageRecordNo(data).then((res) => {
            this.isrequest = false;
            // this.packageListForm.params.waybillNumber = '';
            if (res.code===200) {
              this.$message.success(this.$t('operationCenter.IngestionSucceeded'));
              this.ruleForm.length = null;
              this.ruleForm.width = null;
              this.ruleForm.height = null;
              this.packageListForm.params.waybillNumber = '';
              if (res.data) {
                this.errMsg = true;
                this.errorMsg = false;
                this.OperationResult = res.data.errorMsg;
                if (res.data.labelUrl) {
                  autoPrint(res.data.labelUrl).then(async(resPrint) => {
                    if (resPrint.code === 200) {
                      this.$message.success(this.$t('collectionCenter.printSuc'));
                    }
                  }).catch(async(e) => {
                    this.$message.error(this.$t('GlobalSetObj.PrintFailure'));
                  });
                }
              } else {
                this.errMsg = false;
              }
              this.SuccessMsg = true;
              // this.OperationResult = this.$t('GlobalSetObj.IngestionSucceeded');
              this.$refs.okRef.play();
              this.tab = true;
              this.$nextTick(() => {
                this.packageListForm.pageNumber = 1;
                // this.packageListForm.params.waybillNumber = '';
                this.$refs.inputRef.$refs.input.focus();
                this.getTableHight(); // 行高设置
                this.getSearch();
                // 新增物品增加动画效果
                this.tipsObj.isPrompt = false;
                setTimeout(() => {
                  this.tipsObj.isPrompt = true;
                }, 500);
              });
            }
          }).catch(e => {
            this.SuccessMsg = false;
            this.errMsg = true;
            this.errorMsg = true;
            this.OperationResult = e.msg;
            this.$refs.errRef.play();
            this.stabilityCount = 0;
            // this.getSearch();
            this.tab = false;
            this.isrequest = false;
            this.$nextTick(() => {
              this.tab = false;
              this.$refs.inputRef.$refs.input.select();
            });
          });
        } else {
          // 复重情况下调用接口
          updateReviseWeight(data).then((res) => {
            this.isrequest = false;
            // this.packageListForm.params.waybillNumber = '';
            if (res.code===200) {
              this.$message.success(this.$t('operationCenter.ReloadSuccessfully'));
              this.ruleForm.length = null;
              this.ruleForm.width = null;
              this.ruleForm.height = null;
              if (res.data) {
                this.errMsg = true;
                this.errorMsg = false;
                this.OperationResult = res.data;
              } else {
                this.errMsg = false;
              }
              this.$refs.okRef.play();
              // this.OperationResult = this.$t('GlobalSetObj.CorrectSuccessfully');
              // 设置添加效果
              this.packageListForm.pageNumber = 1;
              this.tab = true;
              this.$nextTick(() => {
                this.packageListForm.params.waybillNumberCopy = this.packageListForm.params.waybillNumber;
                // this.packageListForm.params.waybillNumber = '';
                this.$refs.inputRef.$refs.input.focus();
                this.getTableHight(); // 行高设置
                this.getSearch();
                // 新增物品增加动画效果
                this.tipsObj.isPrompt = false;
                setTimeout(() => {
                  this.tipsObj.isPrompt = true;
                }, 1000);
              });
            }
          }).catch(e => {
            this.stabilityCount = 0;
            this.$refs.errRef.play();
            this.isrequest = false;
            this.$nextTick(() => {
              // this.packageListForm.params.waybillNumber = '';
              this.tab = false;
              this.$refs.inputRef.$refs.input.select();
            });
            this.errMsg = true;
            this.errorMsg = true;
            this.OperationResult = e.msg;
          });
        }
      }
      if (Number(this.electronicScaleWeight) === 0) {
        const param = {
          waybillNumber: data.waybillNumber,
          length: this.ruleForm.length,
          width: this.ruleForm.width,
          height: this.ruleForm.height
        };
        if (this.repetition === false) {
          // 非复重情况下调用接口
          checkWaybillStorage(param).then((res) => {
            if (res.code===200) {
              console.log(res, 'SSS');
            }
          }).catch(() => {
            this.$nextTick(() => {
              this.$refs.inputRef.$refs.input.select();
            });
          });
        } else {
          // 复重情况下调用接口
          checkGetWaybillInfo(param).then((res) => {
            if (res.code===200) {
              console.log(res, 'SSS');
            }
          }).catch(() => {
            this.$nextTick(() => {
              this.$refs.inputRef.$refs.input.select();
            });
          });
        }
      }
    },
    // 根据当前页码和每页显示的条目数量来切片数组并返回指定范围内的数据
    getPageData(pageNumber, data) {
      const startIndex = (pageNumber - 1) * this.packageListForm.pageSize;
      const endIndex = startIndex + this.packageListForm.pageSize;
      if (startIndex >= data.length) {
        throw new Error('Invalid page number');
      } else {
        return data.slice(startIndex, endIndex);
      }
    },
    // 获取右侧列表数据
    getSearch() {
      // let type = 1;
      // if (this.ruleForm.equipmentBrand === 'KUAI_SHOW') {
      //   type = 3;
      // } else {
      //   if (this.repetition === true) {
      //     type = 2;
      //   } else {
      //     type = 1;
      //   }
      // }
      const data = {
        pageNumber: 1,
        pageSize: this.packageListForm.pageSize,
        params: {
          // operateType: type,
          queryType: 1,
          waybillNumber: this.packageListForm.params.waybillNumber,
          userId: this.$store.state.user.id
        }
      };
      waybillOperationLogPage(data).then(res => {
        if (res.code===200) {
          // this.packageListForm.params.waybillNumber = '';
          if (res.data.records.length > 0) {
            const itemData = res.data.records;

            // if (!itemData.height || !itemData.width || !itemData.length) {
            //   itemData.volume = 0;
            // } else {
            //   itemData.volume = itemData.height * itemData.width * itemData.length;
            // }
            console.log(itemData, '==66666');
            itemData.map(list => {
              if (!list.height || !list.width || !list.length) {
                list.volume = 0;
              } else {
                list.volume = (list.height * list.width * list.length).toFixed(3);
              }
            });
            // const list = this.rightTableDataAll.filter((val) => val.waybillNumber !== this.packageListForm.params.waybillNumber);
            // console.log(list, '====list');

            // this.rightTableDataAll.map((item, ind) => {
            //   itemData.map(list => {
            //     if (!list.height || !list.width || !list.length) {
            //       list.volume = 0;
            //     } else {
            //       list.volume = list.height * list.width * list.length;
            //     }
            //     if (list.id === item.id) {
            //       this.rightTableDataAll.splice(ind, 1);
            //     }
            //     if (this.rightTableWaybillNumbers.includes(list.id) === false) {
            //       this.rightTableWaybillNumbers.push(list.id);
            //     }
            //   });
            // });
            // if (this.rightTableWaybillNumbers.includes(itemData.id) === false) {
            //   this.rightTableWaybillNumbers.push(itemData.id);
            //   // this.rightTableDataAll.unshift(itemData);
            // }
            this.rightTableDataAll.unshift(...res.data.records);
            // console.log(this.rightTableDataAll, '=====this.rightTableDataAll');
            this.packageListForm.total = this.rightTableDataAll.length;
            this.tableData = this.getPageData(this.packageListForm.pageNumber, this.rightTableDataAll);
            // console.log(this.tableData, '======this.tableData');
          }
        }
      }).catch(e => {
        console.log(e);
      });
    },
    initDevicePlugin() {
      apiStartUp().then((res) => {
        if (res.code !== 200) {
          this.$message.error(this.$t('GlobalSetObj.openPlugin'));
          return;
        }
        // 获取串口列表
        this.getSerialPortList().then((res) => {
          // this.ruleForm.equipmentBrand = this.DeviceBrandList[1].brandName;
          this.ruleForm.equipmentBrand = this.ElectronicBrand;
          this.connectDevice();
        });
      });
    },
    // 设置设备
    DeviceSetup() {
      apiStartUp().then((res) => {
        if (res.code !== 200) {
          this.$message.error(this.$t('GlobalSetObj.openPlugin'));
        }
      });
      // 打开设置弹窗
      this.SetDeviceDialog = true;
      // 获取串口列表
      this.getSerialPortList();
    },
    // 设置品牌名称,通过vuex进行管理
    handleDeviceBrand(item) {
      // if(item==='KUAI_SHOW'){
      //   return
      // }
      this.$store.commit('settings/CHANGE_ElectronicBrand', { item });
      this.setupElectronicScales('ruleForm');
      if (this.ruleForm.equipmentBrand === 'KUAI_SHOW') {
        this.isKuaiShow = true;
        this.repetition = false;
      } else {
        this.isKuaiShow = false;
      }
    },
    // 第一个增加颜色标记
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 0) {
        return 'success-row';
      }
      return '';
    },
    // 获取 表格第一行行高
    getTableHight() {
      const tab = this.$refs.tableRef.$el;
      const trDom = tab.querySelector('.el-table__body tbody tr');
      // const trDom = tab.querySelector('.el-table__body-wrapper tbody tr');
      if (trDom === null) {
        return;
      }
      const height = parseInt(window.getComputedStyle(trDom).height);
      const { heightVal } = this.tipsObj;
      if (height !== heightVal) {
        this.tipsObj.heightVal = height;
      }
    },
    // 点击分页
    packageListSizeChange(val) {
      this.packageListForm.pageSize = val;
      this.packageListForm.pageNumber = 1;
      this.tableData = this.getPageData(this.packageListForm.pageNumber, this.rightTableDataAll);
      // this.getSearch();
    },
    packageListCurrentChange(val) {
      this.packageListForm.pageNumber = val;
      this.tableData = this.getPageData(val, this.rightTableDataAll);
      // this.getSearch();
    },
    /**
     * @description: 设置电子秤
     * @return {*}
     */
    async setupElectronicScales(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.equipmentBrand = this.ElectronicBrand;
          // 是否初始化成功电子秤
          apiStartUp().then((res) => {
            if (res.code !== 200) {
              this.$message.error(this.$t('GlobalSetObj.openPlugin'));
              return;
            }
            // 选择快手进行ws连接
            if (this.ruleForm.equipmentBrand === 'KUAI_SHOW') {
              this.creatWs();
              // 关闭电子秤连接
              clearInterval(this.timer);
              // 修改电子秤连接状态
              this.connectStatus = 0;
              // 清空电子秤重量
              this.electronicScaleWeight = 0;
            } else {
              setTimeout(() => {
                if (!this.connectStatus) {
                  this.connectDevice();
                }
              }, 1000);
              // 选择电子秤进行连接
              this.connectDevice();
              // 关闭快手设备连接
              clearInterval(this.onlineTime);
              // 修改快手设备连接状态
              this.isConnectWs = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    /**
     * @description: 连接设备
     * @return {*}
     */
    async connectDevice() {
      const load = Loading.service({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$t('GlobalSetObj.inicializando'),
        background: 'rgba(250, 250, 250, 0.6)'
      });
      // 是否连接
      let isConnected = false;
      let count = 0; // 记录失败次数
      for (let i = 0; i < this.SetDevice.length; i++) {
        // 已经连接成功不再继续连接
        if (isConnected) {
          return;
        }
        await this.initElectronicScales(this.SetDevice[i])
          .then((res) => {
            // 该串口连接成功
            if (res) {
              isConnected = res;
              this.$message({
                message: this.$t('GlobalSetObj.sucesso'),
                type: 'success'
              });
              this.connectStatus = 1;
              this.isDisable = false;
              this.SetDeviceDialog = false;
              this.$store.commit('settings/CHANGE_isLink1', { item: false });
              // this.$store.commit('settings/CHANGE_isLink2', { item: false });
              // this.isLink = false;
              this.electronicScaleWeight = 0;
              this.$nextTick(() => {
                this.$refs.inputRef.$refs.input.focus();
              });
              // 循环获取电子秤重量
              this.getWeight();
              load.close();
            } else {
              count = count + 1;
              isConnected = false;
              load.close();
              this.isDisable = true; // 666
              // this.$message.error(this.$t('GlobalSetObj.InitiationFailure'));
              this.connectStatus = 0;
              this.packageListForm.params.waybillNumber = '';
              this.SuccessMsg = false;
              this.errMsg = false;
              this.electronicScaleWeight = 0;
              // this.isLink = true;
            }
          }).catch(() => {
            load.close();
          });
      }
      if (!isConnected) {
        this.$store.commit('settings/CHANGE_isLink1', { item: true });
        this.$store.commit('settings/CHANGE_isLink2', { item: true });
        this.$message.error(this.$t('GlobalSetObj.InitiationFailure'));
      }
    },
    /**
     * @description: 获取串口列表
     * @return {*}
     */
    async getSerialPortList() {
      return new Promise((resolve, reject) => {
        apiStartPortsList().then((res) => {
          if (res.code === 200) {
            this.SetDevice = res.data;
          }
          resolve();
        });
      });
    },
    /**
     * @description: 初始化电子秤
     * @param { String } device 串口（电脑通道）
     * @return {*}
     */
    initElectronicScales(device) {
      return new Promise((resolve, reject) => {
        // 请求接口选中指定品牌和串口进行读数
        apiOPenComPort({
          comPort: device,
          scaleType: this.ruleForm.equipmentBrand
        })
          .then((res) => {
            // 连接失败直接跳出
            if (res.code === 500) {
              reject();
            } else {
              // 连接成功并且获取电子秤读书三次成功后跳出
              const getArr = [apiGetWeight(), apiGetWeight(), apiGetWeight()];
              Promise.all(getArr).then((res) => {
                // 0：连接失败 1：连接成功
                let connected = 0;
                // 循环判断是否获取到电子秤读数进行处理
                res.forEach((item) => {
                  if (item.code === 200) {
                    connected = 1;
                  } else {
                    connected = 0;
                  }
                });
                // 对连接成功与连接失败进行处理
                resolve(connected);
              });
            }
          })
          .catch(() => {
            // 直接跳出
            reject();
          });
      });
    },
    /**
     * @description: 循环获取重量
     * @return {*}
     */
    getWeight() {
      const _this = this;
      this.timer = setInterval(() => {
        apiGetWeight()
          .then((res) => {
            if (res.code === 200) {
              this.electronicScaleWeight = res.data;
              // if (Number(this.electronicScaleWeight) > 0) {
              //   const value = this.electronicScaleWeight; // 获取需要检测的值
              //   // this.checkStability(value);
              // }
              // }
            } else {
              // _this.$message.error('读取电子秤错误,请检查电子秤是否正确链接!');
              _this.isDisable = true; // 666
              _this.connectStatus = 0;
              _this.packageListForm.params.waybillNumber = '';
              _this.SuccessMsg = false;
              _this.errMsg = false;
              _this.electronicScaleWeight = 0;
              _this.$store.commit('settings/CHANGE_isLink1', { item: true });
              _this.$store.commit('settings/CHANGE_isLink2', { item: true });
              // clearInterval(this.timer);
              _this.closeGetWeight();
            }
          }).catch(() => {
            _this.closeGetWeight();
          });
      }, 300);
    },
    checkStability(value) {
      if (value === this.previousValue) {
        this.stabilityCount++;
        if (this.stabilityCount === 5) { // 连续5次获取到相同的值
          this.stabilityCount = 0;
          this.saveStorageRecordNoenter();
          // 判断运单号是否存在
          if (this.packageListForm.params.waybillNumber) {
            // 不在请求入库
            this.isrequest = true;
          }
        }
      } else {
        this.stabilityCount = 0; // 重置计数器
      }
      this.previousValue = value;
    },
    /**
     * @description: 停止获取电子秤重量
     * @return {*}
     */
    closeGetWeight() {
      clearInterval(this.timer);
      this.getSearch();
    },
    // 鼠标离开失去焦点
    blurFocus() {
      this.isFocus = false;
    },
    // WebSocket 连接
    creatWs() {
      // ws连接
      this.wsObj.ws = new WebSocket('ws://127.0.0.1:3000/v1/wms/express/storage/register');

      if (this.wsObj.ws instanceof WebSocket) {
        this.wsObj.ws.addEventListener('open', this.handleWsOpend);
        this.wsObj.ws.addEventListener('message', this.handleWsMessage);
        this.wsObj.ws.addEventListener('close', this.hanldeWsClose);
        this.wsObj.ws.addEventListener('error', this.handleWsError);
      }
      // 关闭设置设备弹窗
      this.SetDeviceDialog = false;
    },
    // 首次连接ws
    handleWsOpend() {
      // 注册用户
      this.setWsRegisteredUser();
    },
    // 注册用户
    setWsRegisteredUser() {
      const { ws } = this.wsObj;
      const data = {
        token: this.$store.state.user.token,
        language: this.$store.state.language.language
      };
      // 生成一个每三秒定时的定时器，失败的
      // setInterval(() => {
      //   const res={
      //     "status": "ERROR",
      //     "code": 0,
      //     "msg": "系统异常,请联系管理员",
      //     "data": {
      //     "height": 3,
      //       "length": 1,
      //       "packageWeight": 0.123,
      //       "waybillNumber": "ANJZX2115134440YQ",
      //       "width": 2
      //   },
      //     "extra": ""
      //   }
      //   const {code, extra, data, msg, status}= res;
      //   this.wsMsgErr(msg, data);
      // }, 3000);
      // // 生成一个每三秒定时的定时器，失败的
      // setInterval(() => {
      //   const res={
      //     "status": "OK",
      //     "code": 0,
      //     "msg": "入库成功",
      //     "data": {
      //       "height": 3,
      //       "length": 1,
      //       "packageWeight": 0.123,
      //       "waybillNumber": "ANJZX2115134440YQ",
      //       "width": 2
      //     },
      //     "extra": ""
      //   }
      //   const {code, extra, data, msg, status}= res;
      //   this.wsMsgSuccess(status, data);
      // }, 5000);
      ws.send(JSON.stringify(data));
    },
    // 收到消息
    handleWsMessage(e) {
      const { data, msg, code } = JSON.parse(e.data);
      // 失败处理
      if (code !== 200) {
        this.wsMsgErr(msg, data);
        return;
      }

      // 成功处理
      this.wsMsgSuccess(status, data, msg);
    },
    // 失败处理
    wsMsgErr(msg, data) {
      this.SuccessMsg = false;
      this.errMsg = true;
      this.errorMsg = true;
      this.OperationResult = msg;
      this.packageListForm.params.waybillNumber = data.waybillNumber;
      this.$refs.errRef.play();
      this.electronicScaleWeight = data?.weight || 0;
      this.$message.error(msg);
    },
    // 成功处理
    wsMsgSuccess(status, data, msg) {
      if (code===200) {
        // 是否首次连接
        if (!this.isConnectWs) {
          this.isConnectWs = true;
          this.$message.success(this.$t('GlobalSetObj.successfuConnection'));
          //   进行心跳
          this.setWsHeartbeat();
        }
        // 获取到数据
        if (data != null) {
          // debugger
          this.errMsg = false;
          this.errorMsg = false;
          // this.OperationResult = '入库成功';
          this.SuccessMsg = true;
          this.electronicScaleWeight = data.packageWeight;
          // 增加入库单号显示
          this.packageListForm.params.waybillNumber = data.waybillNumber;
          // 如果data里面有msg数据
          if (msg) {
            this.errMsg = true;
            this.errorMsg = false;
            this.OperationResult = msg.errorMsg;
          }
          // this.OperationResult = this.$t('GlobalSetObj.IngestionSucceeded');
          this.$refs.okRef.play();
          const data011 = {
            waybillNumber: data.waybillNumber
          };
          saveStorageRecordNoGetLabelUrl(data011).then(res => {
            if (res.data.labelUrl) {
              autoPrint(res.data.labelUrl).then(async(resPrint) => {
                if (resPrint.code === 200) {
                  this.$message.success(this.$t('collectionCenter.printSuc'));
                }
              }).catch(async(e) => {
                this.$message.error(this.$t('GlobalSetObj.PrintFailure'));
              });
            }
          });
          this.$nextTick(() => {
            setTimeout(() => {
              this.packageListForm.pageNumber = 1;
              // this.packageListForm.params.waybillNumber = '';
              this.$refs.inputRef.$refs.input.focus();
              if (this.ruleForm.length || this.ruleForm.width || this.ruleForm.height) {
                const regex = /^\d+(\.\d{0,3})?$/;
                const a = [];
                if (this.ruleForm.length) {
                  if (!regex.test(this.ruleForm.length)) {
                    this.ruleForm.length = '';
                  } else {
                    a.push(this.ruleForm.length);
                  }
                }
                if (this.ruleForm.width) {
                  if (!regex.test(this.ruleForm.width)) {
                    this.ruleForm.width = '';
                  } else {
                    a.push(this.ruleForm.width);
                  }
                }
                if (this.ruleForm.height) {
                  if (!regex.test(this.ruleForm.height)) {
                    this.ruleForm.height = '';
                  } else {
                    a.push(this.ruleForm.height);
                  }
                }
                if (a.length > 0 && a.length < 3) {
                  // 长宽高必须全部填写或者全部不填
                  this.$message.warning(this.$t('operationCenter.lengthWidthAndHeightAll'));
                  return;
                }
                const param = {
                  waybillNumber: data.waybillNumber,
                  length: this.ruleForm.length,
                  width: this.ruleForm.width,
                  height: this.ruleForm.height
                };
                apiEditPackageVolume(param).then(res => {
                  if (res.code===200) {
                    this.getTableHight(); // 行高设置
                    this.getSearch();
                  }
                });
              } else {
                this.getTableHight(); // 行高设置
                this.getSearch();
              }
              // 新增物品增加动画效果
              this.tipsObj.isPrompt = false;
              setTimeout(() => {
                this.tipsObj.isPrompt = true;
              }, 500);
            }, 500);
          });
        } else {
          this.errMsg = false;
        }
      }
      // 客户端设备连接成功
    },
    // ws连接出错
    handleWsError() {
      this.wsObj.index = 0;
      clearTimeout(this.wsObj.time);
      const str = this.$i18n.t('operationTips.connectionError');
      this.$message.error(str);
      // this.playErr();
      // this.speechObj.text = str;
      // window.speechSynthesis.speak(this.speechObj);
      // this.wsObj.time = setTimeout(() => {
      //   this.creatWs();
      // }, 3000);
    },
    // 进行心跳连接
    setWsHeartbeat() {
      // 删除电子秤获取重量电子秤
      clearInterval(this.timer);
      // 删除快手设备心跳
      clearInterval(this.onlineTime);
      const { ws } = this.wsObj;
      const data = {
        path: '/v1/wms/express/storage/register',
        params: {}
      };
      ws.send(JSON.stringify(data));
      this.onlineTime = setInterval(() => {
        ws.send(JSON.stringify(data));
        // this.handleOnline();
      }, 3000);
    }
  }
};
</script>

<style lang="scss" scoped>
//::v-deep .el-select-dropdown {
//  width: 300px !important;
//}

::v-deep .el-select-dropdown__item {
  height: 50px;
  line-height: 50px;
  margin-bottom: 10px;
}

.custom-option {
  display: flex;
  align-items: center;
}

.scannerWeight ::v-deep .el-input__inner {
  height: 50px;
}

@import "./index.scss";
</style>
