var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticStyle: { position: "relative", "min-height": "40px" } },
        [
          _vm.queryBoxShow
            ? _c(
                "el-button",
                {
                  staticClass: "putAway",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.queryBoxShow = !_vm.queryBoxShow
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("userManage.CollapseQuery")) + " ")]
              )
            : _c(
                "el-button",
                {
                  staticClass: "putAway",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.queryBoxShow = !_vm.queryBoxShow
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("userManage.ExpandQuery")) + " ")]
              ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.queryBoxShow,
                  expression: "queryBoxShow",
                },
              ],
              staticClass: "searchContainer",
            },
            [
              _c(
                "el-form",
                {
                  ref: "queryFormRef",
                  attrs: { model: _vm.queryForm, "label-position": "top" },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("userManage.realName") } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("userManage.pleaseInput"),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryForm.params.realName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "realName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "queryForm.params.realName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("userManage.username") } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("userManage.pleaseInput"),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryForm.params.username,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "username",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "queryForm.params.username",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("userManage.phone") } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t("userManage.pleaseInput"),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryForm.params.phone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "phone",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "queryForm.params.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("userManage.roleId") } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "userManage.pleaseChoose"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.queryForm.params.roleId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "roleId",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.roleId",
                                  },
                                },
                                _vm._l(_vm.jsRolesList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.roleName,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("collectionCenter.owningSite"),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "collectionCenter.pleaseChoose"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  on: { focus: _vm.getSiteList },
                                  model: {
                                    value: _vm.queryForm.params.siteId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "siteId",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.siteId",
                                  },
                                },
                                _vm._l(_vm.siteIdList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.$t("userManage.organizationName") + "：",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c("el-cascader", {
                                    ref: "cascader",
                                    attrs: {
                                      options: _vm.organizationTree,
                                      props: _vm.organizationTreeProps,
                                      "show-all-levels": false,
                                      clearable: "",
                                      filterable: "",
                                    },
                                    on: { change: _vm.organizationTreeChange },
                                    model: {
                                      value: _vm.orgId,
                                      callback: function ($$v) {
                                        _vm.orgId = $$v
                                      },
                                      expression: "orgId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.accountState"),
                                prop: "accountState",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "mini" },
                                  on: { change: _vm.search },
                                  model: {
                                    value: _vm.queryForm.params.accountState,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "accountState",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.accountState",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "" } },
                                    [_vm._v(_vm._s(_vm.$t("userManage.all")))]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "1" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("userManage.enable"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "0" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("userManage.Disable"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { justify: "start", type: "flex" } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit:remove",
                                value: "btn:user-center:user:find",
                                expression: "'btn:user-center:user:find'",
                                arg: "remove",
                              },
                            ],
                            attrs: {
                              icon: "el-icon-search",
                              size: "mini",
                              type: "primary",
                            },
                            on: { click: _vm.search },
                          },
                          [_vm._v(_vm._s(_vm.$t("userManage.search")) + " ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { icon: "el-icon-refresh", size: "mini" },
                            on: { click: _vm.queryFormReset },
                          },
                          [_vm._v(_vm._s(_vm.$t("userManage.reset")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c("el-divider"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            margin: "10px 0",
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:user-center:user:add",
                      expression: "'btn:user-center:user:add'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    icon: "el-icon-plus",
                    size: "small",
                    type: "primary",
                  },
                  on: { click: _vm.addUser },
                },
                [_vm._v(_vm._s(_vm.$t("userManage.add")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:user-center:user:download",
                      expression: "'btn:user-center:user:download'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    icon: "el-icon-download",
                    size: "small",
                    type: "info",
                  },
                  on: { click: _vm.exportUser },
                },
                [_vm._v(_vm._s(_vm.$t("userManage.download")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-upload", size: "small" },
                  on: { click: _vm.downloadAll },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
              ),
            ],
            1
          ),
          _c(
            "span",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:user-center:user:stop",
                      expression: "'btn:user-center:user:stop'",
                      arg: "remove",
                    },
                  ],
                  attrs: { size: "small", type: "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.isDisable(false)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("userManage.userStop")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:user-center:user:start",
                      expression: "'btn:user-center:user:start'",
                      arg: "remove",
                    },
                  ],
                  attrs: { size: "small", type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.isDisable(true)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("userManage.userStart")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c("el-card", [
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                attrs: {
                  data: _vm.TableData,
                  "max-height": 600,
                  "tree-props": {
                    children: "children",
                    hasChildren: "hasChildren",
                  },
                  border: "",
                  "row-key": "tableId",
                },
                on: { "selection-change": _vm.selectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    "reserve-selection": false,
                    align: "center",
                    type: "selection",
                    width: "45",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("userManage.ind"),
                    align: "center",
                    type: "index",
                    width: "55",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                (_vm.queryForm.pageNumber - 1) *
                                  _vm.queryForm.pageSize +
                                  scope.$index +
                                  1
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: _vm.$t("userManage.name"), prop: "realName" },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("userManage.englishName"),
                    prop: "englishName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("userManage.userNameL"),
                    align: "left",
                    prop: "username",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("userManage.userPhone"),
                    align: "left",
                    prop: "phone",
                    width: "130px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("userManage.roleName"),
                    align: "center",
                    prop: "roleName",
                    width: "130px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tag",
                            {
                              attrs: { type: scope.row.roleName ? "" : "info" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  scope.row.roleName || _vm.$t("userManage.No")
                                ) + " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("userManage.organizationName"),
                    align: "center",
                    prop: "organizationName",
                    width: "110px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("collectionCenter.belongD"),
                    prop: "siteName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("userManage.AccountStatus"),
                    align: "center",
                    prop: "accountState",
                    width: "90px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.accountState === 1
                            ? _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("userManage.enable")) + " "
                                ),
                              ])
                            : _c("el-tag", { attrs: { type: "info" } }, [
                                _vm._v(_vm._s(_vm.$t("userManage.Disable"))),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("userManage.permissionType"),
                    align: "center",
                    prop: "permissionType",
                    width: "120px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.permissionType === 1
                            ? _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("userManage.normal")) + " "
                                ),
                              ])
                            : scope.row.permissionType === 2
                            ? _c("el-tag", { attrs: { type: "warning" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("userManage.personality")) + " "
                                ),
                              ])
                            : _c("el-tag", { attrs: { type: "info" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("userManage.unauthorized"))
                                ),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("userManage.createTime"),
                    align: "center",
                    prop: "createTime",
                    width: "160px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("userManage.creator"),
                    align: "left",
                    prop: "createUserNameStr",
                    width: "120px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("userManage.ModifyThePerson"),
                    prop: "updateUserName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("userManage.ModificationTime"),
                    prop: "updateTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("userManage.updateUserBelongs"),
                    prop: "updateUserSite",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "document number",
                    prop: "documentNumber",
                    width: "90px",
                  },
                }),
                _c("el-table-column", { attrs: { label: "CPF", prop: "cpf" } }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("collectionCenter.QYcnpj"),
                    prop: "signCnpj",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("collectionCenter.modalDriverLicence"),
                    prop: "driverLicense",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("collectionCenter.enclosureFJ"),
                    align: "center",
                    prop: "attachmentUrl",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-popover",
                            { attrs: { placement: "top", trigger: "hover" } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.attachmentUrl)),
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass: "clearImageClass",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [_vm._v(_vm._s(scope.row.attachmentUrl))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("userManage.operation"),
                    fixed: "right",
                    width: "155",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom-start",
                                trigger: "click",
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permit",
                                          rawName: "v-permit:remove",
                                          value: "btn:user-center:user:update",
                                          expression:
                                            "'btn:user-center:user:update'",
                                          arg: "remove",
                                        },
                                      ],
                                      attrs: { size: "mini", type: "info" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editUser(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("userManage.edit")) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permit",
                                          rawName: "v-permit:remove",
                                          value: "btn:user-center:user:set",
                                          expression:
                                            "'btn:user-center:user:set'",
                                          arg: "remove",
                                        },
                                      ],
                                      attrs: { size: "mini", type: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.authorizationOpen(
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("userManage.authorization")
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permit",
                                          rawName: "v-permit:remove",
                                          value:
                                            "btn:user-center:user:updatePwd",
                                          expression:
                                            "'btn:user-center:user:updatePwd'",
                                          arg: "remove",
                                        },
                                      ],
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openUpdatePwd(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("userManage.resetPassword")
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", size: "mini" },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("operationCenter.MoreActions")
                                    ) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              staticStyle: { margin: "10px 0" },
              attrs: {
                "current-page": _vm.queryForm.pageNumber,
                "page-size": _vm.queryForm.pageSize,
                "page-sizes": [10, 20, 50, 100],
                total: _vm.total,
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageNumber", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageNumber", $event)
                },
                "update:pageSize": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageSize", $event)
                },
                "update:page-size": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageSize", $event)
                },
                "current-change": _vm.getData,
                "size-change": _vm.getData,
              },
            }),
          ],
          1
        ),
      ]),
      _c("user-operation", {
        ref: "userOperation",
        attrs: {
          "organization-tree": _vm.organizationTree,
          "user-operation-pop": _vm.userOperationPop,
        },
        on: {
          UserTableDataRefresh: _vm.getData,
          closeUserOperationDialog: _vm.closeUserOperationDialog,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("userManage.userSetPermission"),
            visible: _vm.authorizationVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.authorizationVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "set_authorization" },
            [
              _c("permission-tree", {
                key: _vm.newKey,
                ref: "permissionTreeRef",
                attrs: { "permission-tree-pop": _vm.permissionTreePop },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { spam: 12 } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "18px",
                        "margin-top": "15px",
                        color: "#303133",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("GlobalSetObj.characterPermission")) +
                          " "
                      ),
                    ]
                  ),
                  _c("StrPermissionTree", {
                    key: _vm.newKey,
                    ref: "permissionTreeRefStr",
                    attrs: { "permission-tree-pop": _vm.permissionTreePop },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.authorizationVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("userManage.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.editUserPermission },
                },
                [_vm._v(_vm._s(_vm.$t("userManage.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": true,
            title: _vm.updataPwdObj.title,
            visible: _vm.updataPwdObj.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.updataPwdObj, "dialogVisible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.updataPwdObj.ruleForm,
                rules: _vm.updataPwdObj.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("userManage.newPassword"),
                    prop: "newPassword",
                  },
                },
                [
                  _vm.updataPwdObj.dialogVisible
                    ? _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("userManage.pleaseInput"),
                          maxlength: "12",
                          minlength: "8",
                          "show-password": "",
                        },
                        model: {
                          value: _vm.updataPwdObj.ruleForm.newPassword,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.updataPwdObj.ruleForm,
                              "newPassword",
                              $$v
                            )
                          },
                          expression: "updataPwdObj.ruleForm.newPassword",
                        },
                      })
                    : _vm._e(),
                  _c("span", { staticClass: "tips" }, [
                    _vm._v(_vm._s(_vm.$t("userManage.passwordPrompt"))),
                  ]),
                  _c("br"),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("userManage.checkPwd"),
                    prop: "newPasswordConfirm",
                  },
                },
                [
                  _vm.updataPwdObj.dialogVisible
                    ? _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("userManage.inputPwdAgian"),
                          maxlength: "12",
                          minlength: "8",
                          "show-password": "",
                        },
                        model: {
                          value: _vm.updataPwdObj.ruleForm.newPasswordConfirm,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.updataPwdObj.ruleForm,
                              "newPasswordConfirm",
                              $$v
                            )
                          },
                          expression:
                            "updataPwdObj.ruleForm.newPasswordConfirm",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.resetPasswordClose } }, [
                _vm._v(_vm._s(_vm.$t("userManage.cancel"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.asyncSetPwd("ruleForm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("userManage.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }