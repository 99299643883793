var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticStyle: { float: "left" } },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-upload2", size: "mini" },
                  on: { click: _vm.exportData },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.activeName === "hz"
                          ? _vm.$t("collectionCenter.SummaryExport")
                          : _vm.$t("collectionCenter.明细导出")
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "right" }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-right": "20px",
                      display: "flex",
                      "justify-content": "space-between",
                      "align-items": "center",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.updateTime,
                            expression: "updateTime",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("GlobalSetObj.UpdateTime")) +
                            "：" +
                            _vm._s(_vm.updateTime)
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "dateBox" }, [
                      _c(
                        "span",
                        {
                          class: { d1: _vm.type == 0 },
                          on: {
                            click: function ($event) {
                              return _vm.get2Data(0)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.yesterday")))]
                      ),
                      _c(
                        "span",
                        {
                          class: { d1: _vm.type == 1 },
                          on: {
                            click: function ($event) {
                              return _vm.get2Data(1)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.sameDay")))]
                      ),
                      _c(
                        "span",
                        {
                          class: { d1: _vm.type == 2 },
                          on: {
                            click: function ($event) {
                              return _vm.get2Data(2)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.nearlyAWeek")))]
                      ),
                      _c(
                        "span",
                        {
                          class: { d1: _vm.type == 3 },
                          on: {
                            click: function ($event) {
                              return _vm.get2Data(3)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.sameMonth")))]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", plain: "", size: "small" },
                    on: { click: _vm.closeWindow },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("collectionCenter.close")) + " ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", {
            staticStyle: {
              border: "1px solid #ebeef5",
              width: "100%",
              margin: "20px 0 10px 0",
            },
          }),
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.tabClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t(
                      "collectionCenter.SummarySuspectedLostPackageLinks"
                    ),
                    name: "hz",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.tableData1,
                            border: "",
                            "show-summary": "",
                            "summary-method": _vm.getSummaries,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("operationCenter.ind"),
                              width: "60px",
                              align: "center",
                              fixed: "left",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.$index + 1)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectCount",
                              label: _vm.$t("collectionCenter.TotalCollected"),
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toMX(scope.row, 1)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.collectCount) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "bagSealingCount",
                              label: _vm.$t("collectionCenter.TotalSealedBags"),
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toMX(scope.row, 2)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.bagSealingCount) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "siteLoadCount",
                              label: _vm.$t(
                                "collectionCenter.TotalLoadedBranch"
                              ),
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toMX(scope.row, 3)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.siteLoadCount) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "hubUnloadCount",
                              label: _vm.$t(
                                "collectionCenter.TotalUnloadedTransferCenter"
                              ),
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toMX(scope.row, 4)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.hubUnloadCount) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "hubLoadCount",
                              label: _vm.$t(
                                "collectionCenter.TotalLoadedTransferCenter"
                              ),
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toMX(scope.row, 5)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.hubLoadCount) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "signCount",
                              label: _vm.$t("collectionCenter.TotalSigned"),
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toMX(scope.row, 6)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.signCount) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm.activeName === "mx"
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t(
                          "collectionCenter.SuspectedLostPackageDetails"
                        ),
                        name: "mx",
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.tableDataMX,
                            border: "",
                            "max-height": _vm.mxHeight,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("operationCenter.ind"),
                              width: "60px",
                              align: "center",
                              fixed: "left",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            (_vm.formMX.pageNumber - 1) *
                                              _vm.formMX.pageSize +
                                              scope.$index +
                                              1
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3968133944
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "scanNumber",
                              label: _vm.$t("collectionCenter.Barcode"),
                              "min-width": "200",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "packageNumber",
                              label: _vm.$t("collectionCenter.parcelNum"),
                              "min-width": "200",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectTime",
                              label: _vm.$t("collectionCenter.lanshouT"),
                              "min-width": "180",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectSiteName",
                              label: _vm.$t("collectionCenter.LanShouSite"),
                              "min-width": "180",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectUserName",
                              label: _vm.$t("collectionCenter.LanShouPeople"),
                              "min-width": "180",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "siteLoadTime",
                              label: _vm.$t(
                                "collectionCenter.networkLoadingTime"
                              ),
                              "min-width": "180",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "siteLoadUserName",
                              label: _vm.$t("collectionCenter.networkLoader"),
                              "min-width": "180",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "siteLoadSiteName",
                              label: _vm.$t(
                                "customerManagements.loadSiteIdList"
                              ),
                              "min-width": "180",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "hubUnloadTime",
                              label: _vm.$t(
                                "collectionCenter.unloadingTransferCenterTime"
                              ),
                              "min-width": "180",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "hubUnloadUserName",
                              label: _vm.$t(
                                "collectionCenter.unloadingTransferCenterP"
                              ),
                              "min-width": "180",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "hubUnloadSiteName",
                              label: _vm.$t(
                                "collectionCenter.UnloadingTransferCenter"
                              ),
                              "min-width": "180",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "hubLoadTime",
                              label: _vm.$t(
                                "collectionCenter.transferCenterLoadingTime"
                              ),
                              "min-width": "180",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "hubLoadUserName",
                              label: _vm.$t(
                                "collectionCenter.transferCenterLoader"
                              ),
                              "min-width": "180",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "hubLoadSiteName",
                              label: _vm.$t(
                                "collectionCenter.LoadingTransferCenter"
                              ),
                              "min-width": "180",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "signTime",
                              label: _vm.$t("collectionCenter.signT"),
                              "min-width": "180",
                              align: "center",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-pagination", {
                            staticStyle: { margin: "10px 0" },
                            attrs: {
                              layout: "total, sizes, prev, pager, next, jumper",
                              "current-page": _vm.formMX.pageNumber,
                              "page-sizes": [10, 20, 50, 100, 500, 1000],
                              "page-size": _vm.formMX.pageSize,
                              total: _vm.totalMX,
                              background: "",
                            },
                            on: {
                              "update:currentPage": function ($event) {
                                return _vm.$set(
                                  _vm.formMX,
                                  "pageNumber",
                                  $event
                                )
                              },
                              "update:current-page": function ($event) {
                                return _vm.$set(
                                  _vm.formMX,
                                  "pageNumber",
                                  $event
                                )
                              },
                              "update:pageSize": function ($event) {
                                return _vm.$set(_vm.formMX, "pageSize", $event)
                              },
                              "update:page-size": function ($event) {
                                return _vm.$set(_vm.formMX, "pageSize", $event)
                              },
                              "current-change": _vm.getDatePageMX,
                              "size-change": _vm.getPageSizeMX,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }