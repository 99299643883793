<template>
  <div class="Box">
    <div class="scanClass">
      <!--  扫描：  -->
      <span>{{ $t('collectionCenter.scanBtn') }}</span>
      <el-input
        v-model.trim="number"
        :placeholder="$t('collectionCenter.pleaseEnterOrScan')"
        clearable
        prefix-icon="el-icon-full-screen"
        class="iconBtn"
        @keyup.enter.native="addTable"
      />
    </div>
    <div class="uploadBtn">
      <el-button
        v-if="isSignBtn"
        type="success"
        @click="signature"
      >{{ $t('collectionCenter.signature') }}</el-button><!-- 签名 -->
    </div>

    <div class="uploadBtn">
      <span>{{ $t('collectionCenter.scannedNumber') }}</span><!-- 已扫描的数量 -->
      <span class="red">{{ scanPage.length }}</span>
    </div>

    <el-table
      :max-height="600"
      :data="scanPage"
      border
    >
      <el-table-column
        prop="subpackageNo"
        align="center"
        :label="$t('collectionCenter.Number')"
      /><!-- 单号 -->
      <el-table-column
        prop="scanTime"
        align="center"
        :label="$t('collectionCenter.scanTime')"
      /><!-- 扫描时间 -->
      <el-table-column
        :label="$t('collectionCenter.operation')"
        fixed="right"
        width="100"
        align="center"
      ><!-- 操作 -->
        <template slot-scope="scope">
          <el-popconfirm
            :title="$t('collectionCenter.deleteSure')"
            @confirm="scanDelClick(scope.row,scope.$index)"
          ><!-- 确定删除吗？ -->
            <el-button slot="reference" type="danger" size="mini">{{ $t('collectionCenter.Delete') }}</el-button><!-- 删除 -->
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 签名 -->
    <div v-if="signVisible" class="signMask">
      <div class="signVisibleClass">
        <div class="el-icon-close signClose" @click="closeSign" />
        <div class="descriptionsClass">
          <!-- 截图区域 -->
          <div ref="image">
            <el-descriptions title="" :column="1" border :content-style="rowClass" :label-style="labelRowClass">

              <el-descriptions-item label="">
                <div class="top1">
                  <!-- 揽收网点： -->
                  <span>{{ $t('collectionCenter.LSSite') }}{{ scanSign.siteName }}</span>
                  <img :src="LOGO" alt="">
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="">
                <div class="two">
                  <!-- 发件人： -->
                  <div>{{ $t('collectionCenter.adrser') }}{{ scanSign.senderName }}</div>
                  <!-- 扫描人： -->
                  <div>{{ $t('collectionCenter.scanner') }}：{{ scanSign.scanUserName }}</div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="">
                <div class="two">
                  <!-- 预计揽收件数： -->
                  <div>{{ $t('collectionCenter.estimatedCollectedQuantity') }}：{{ scanSign.estimatedCount }}</div>
                  <!-- 实际揽收件数： -->
                  <div>{{ $t('collectionCenter.actualCollectedQuantity') }}：{{ scanSign.actualCollectionNum }}</div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="">
                <!-- 揽收时间： -->
                <div class="padClass pl">
                  <span>{{ $t('collectionCenter.lanshouTime') }}</span><span>{{ scanSign.collectionTime }}</span>
                </div>
              </el-descriptions-item>

              <el-descriptions-item label="">
                <div class="topTitle">{{ $t('collectionCenter.customerSignature') }}</div><!-- 客户签名 -->
              </el-descriptions-item>
              <el-descriptions-item label="">
                <div style="width: 500px;height: 205px;overflow: hidden;">
                  <vue-esign ref="esign" :width="500" :height="205" :line-width="5" line-color="#000" bg-color="#FFF" />
                </div>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="signBtn">
            <el-button type="success" class="btn" @click="handleGenerate">{{ $t('collectionCenter.Confirm') }}</el-button><!-- 确认 -->
            <el-button type="info" plain @click="resetSign">{{ $t('collectionCenter.empty') }}</el-button><!-- 置空 -->
            <el-button @click.stop="closeSign">{{ $t('collectionCenter.cancel') }}</el-button><!-- 取消 -->
          </div>
        </div>
      </div>
    </div>

    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
import {
  getCurrentUserStockScanData,
  stockCollectScanData,
  deleteStockScanData,
  getSignTemplateInfo,
  stockCollectScanSubmit
} from '@/api/lanshou';
import html2canvas from 'html2canvas';
import { uploadOos } from '@/utils/ali_oos_util';
import dayjs from 'dayjs';

const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';

export default {
  name: 'ReadyReceiveScanning',
  data() {
    return {
      okMp3,
      errorMp3,
      isSignBtn: true,
      number: '',
      scanPage: [],
      signVisible: false,
      rowClass: {
        'border-color': '#000'
      },
      labelRowClass: {
        'border-color': '#000'
      },
      scanSign: {
        siteName: '',
        senderName: '',
        scanUserName: '',
        estimatedCount: '',
        actualCollectionNum: '',
        collectionTime: ''
      },
      LOGO: '/static/anjunLogo.png',
      collectSignImg: ''
    };
  },
  created() {
    this.getScanRecords();
  },
  methods: {
    // 获取扫描记录
    getScanRecords() {
      getCurrentUserStockScanData().then(res => {
        if (res.code===200) {
          this.scanPage = res.data;
        }
      }).catch();
    },
    addTable() {
      if (!this.number) { return; }
      // 重复过滤
      const isAdd = this.scanPage.some(el => {
        return el.subpackageNo === this.number;
      });
      if (isAdd && this.scanPage.length > 0) {
        this.$message({
          message: this.$t('collectionCenter.currentOddNumberRepeat'), // '当前单号重复'
          type: 'warning'
        });
        this.$refs.errRef.play();
      } else {
        const scanForm = {
          subpackageNo: this.number,
          senderName: this.scanPage.length >= 1 ? this.scanPage[this.scanPage.length - 1].senderName : '',
          sourceType: 1
        };
        stockCollectScanData(scanForm).then(res => {
          if (res.code===200) {
            this.$refs.okRef.play();
            this.scanPage.unshift({
              scanId: res.data.scanId,
              stockOrderId: res.data.stockOrderId,
              stockBoxId: res.data.stockBoxId,
              packageNo: res.data.packageNo,
              senderName: res.data.senderName,
              subpackageNo: res.data.subpackageNo,
              scanTime: res.data.scanTime
            });
            this.number = '';
          } else {
            this.number = '';
            this.$refs.errRef.play();
          }
        }).catch(() => {
          this.number = '';
          this.$refs.errRef.play();
        });
      }
    },
    scanDelClick(row, index) {
      const delForm = {
        scanId: row.scanId,
        sourceType: 1
      };
      deleteStockScanData(delForm).then(res => {
        if (res.code===200) {
          this.scanPage.splice(index, 1);
          this.$message.success(this.$t('GlobalSetObj.DeleteSuccessful'));
          if (this.scanPage.length <= 0 || row.subpackageNo == this.number) {
            this.number = '';
          }
        }
      }).catch({});
    },
    signature() {
      if (this.scanPage.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.nullMsg')); // '暂无数据'
        return;
      }
      const idList = this.scanPage.map(arr => {
        return arr.stockOrderId;
      });
      const signVal = {
        stockOrderIdList: idList
      };
      getSignTemplateInfo(signVal).then(res => {
        if (res.code===200) {
          this.signVisible = true;
          const d = new Date();
          const now = dayjs(d).format('HH:mm:ss DD-MM-YYYY');
          this.scanSign = {
            siteName: res.data.siteName,
            senderName: res.data.senderName,
            scanUserName: res.data.scanUserName,
            estimatedCount: res.data.estimatedCount,
            actualCollectionNum: this.scanPage.length,
            collectionTime: now
          };
        }
      }).catch({});
    },
    closeSign() {
      this.signVisible = false;
      this.scanSign = {};
    },
    resetSign() {
      this.$refs['esign'].reset(); // 清空画布
    },
    handleGenerate() {
      this.$refs['esign'].generate().then(res => {
        this.canvasImg();
      }).catch((err) => { // 没有签名，点击生成图片时调用
        console.log(err);
        this.$message({
          message: this.$t('collectionCenter.unsigned'), // '未签名!'
          type: 'warning'
        });
      });
    },
    canvasImg() {
      const loading = this.$loading({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
        background: 'rgba(250, 250, 250, 0.6)'
      });
      const canvas = document.createElement('canvas'); // 截图区
      const canvasBox = this.$refs.image; // 父级
      const width = parseInt(window.getComputedStyle(canvasBox).width);
      const height = parseInt(window.getComputedStyle(canvasBox).height);
      const size = window.devicePixelRatio;
      canvas.width = width * 2 * size;
      canvas.height = height * 2 * size;
      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';
      const context = canvas.getContext('2d');
      context.scale(2, 2);
      const options = {
        backgroundColor: null,
        canvas: canvas,
        useCORS: true
      };
      html2canvas(canvasBox, options).then((canvas) => {
        // toDataURL 图片格式转成 base64
        const dataURL = canvas.toDataURL('image/png');
        const rSignImg = this.base64ImgtoFile(dataURL, 'file'); // 得到文件格式
        uploadOos(rSignImg, 'sign').then(res => {
          if (res.code === 200) {
            if (res.url) {
              this.collectSignImg = res.url;
              this.submitSign();
              loading.close();
            } else {
              this.collectSignImg = '';
            }
          }
        }).catch({}).finally(() => {
          loading.close();
        }).finally(() => {
          loading.close();
        });
      });
    },
    base64ImgtoFile(dataurl, filename = 'file') {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const suffix = mime.split('/')[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      });
    },
    submitSign() {
      if (!this.collectSignImg) {
        this.$message.warning(this.$t('collectionCenter.notGetSignImg')); // '还未获取到签名截图，请稍后...'
        return;
      }
      const signForm = {
        collectSignImg: this.collectSignImg,
        sourceType: 1,
        stockOrderScanParamsList: this.scanPage
      };
      console.log('signForm', signForm);
      stockCollectScanSubmit(signForm).then(res => {
        if (res.code===200) {
          this.$message.success(this.$t('collectionCenter.submitSuccessfully')); // 提交成功
          this.scanPage = [];
          this.number = '';
          this.scanSign = {};
          this.signVisible = false;
        }
      }).catch({});
    }
  }
};
</script>

<style lang="scss" scoped>
.signBtn{
  text-align: right;
  margin-top: 10px;
}
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.scanClass{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}
.scanClass ::v-deep .el-input{
  width: 30%;
}
.scanClass ::v-deep .el-input .el-input__inner{
  height: 45px;
}

.uploadBtn{
  margin-top: 40px;
  text-align: center;
  font-size: 18px;
  .red{
    font-size: 30px;
    font-weight: bold;
    color: #e0223c;
    margin-left: 6px;
  }
}
.uploadBtn ::v-deep .el-button{
  font-size: 18px;
  padding: 7px 30px;
  line-height: 17px;
  border: 1px solid #000;
  color: #000;
}

.iconBtn::after{
  position: absolute;
  content: "-";
  top: 9px;
  left: 15px;
  transform: scaleX(2.3);
  color: #bbb;
}

.signMask{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
  .signVisibleClass{
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    height:100%;
    background:#fff;
    padding:20px 0;
    // overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .signClose{
    position: absolute;
    top: 25px;
    right: 40px;
    transform: scale(1.3);
  }
}

.descriptionsClass {
  position: relative;
  width: 500px;
  margin: 50px auto;
  .topTitle{
    background: #abacac;
    text-align: center;
    font-size: 18px;
    color: #000;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
  }
}
.descriptionsClass ::v-deep tbody td{
  padding: 0 !important;
}
.descriptionsClass ::v-deep th{
  padding: 0 !important;
  border: none !important;
  box-sizing: border-box;
  width: 0;
}
.descriptionsClass ::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell{
  background: #fff;
  font-size: 17px;
  font-weight: 400;
  color: #000;
}
.top1{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 17px;
  font-weight: bold;
  padding-left: 3px;
  box-sizing: border-box;
}
.padClass{
  padding: 10px 0;
  font-size: 17px;
  font-weight: bold;
  box-sizing: border-box;
}
.padClass div, .pl{
  padding-left: 3px;
  box-sizing: border-box;
}
.two{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.two div{
  width: 50%;
  padding: 10px 0;
  padding-left: 3px;
  font-weight: bold;
  box-sizing: border-box;
}
.two div:nth-child(1) {
  border-right: 1px solid #000;
}
</style>
