import request from '@/utils/request';
import newRequest from '@/utils/new-request';

const baseFinanceURL = process.env.VUE_APP_FINANCE; // 财务系统接口地址

// 分页+查询
export const apiPostalCodeCorrectionPage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/postalCodeCorrection/page',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 新增邮编/更新操作/状态变更
export const apiPostalCodeCorrectionUpdate = data => {
  return newRequest({
    method: 'POST',
    url: '/postalCodeCorrection/saveOrEdit',
    data,
    baseURL: baseFinanceURL
  })
};

