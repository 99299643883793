<template>
  <div style="padding:0 20px;height: calc(100vh - 80px); overflow: auto;">
    <el-form
      :inline="true" :model="searchForm.params" class="search-form"
      label-position="top" size="small"
    >
      <el-row :gutter="20">
        <el-col :span="9">
          <el-form-item :label="$t('GlobalSetObj.operationTime')" style="width: 100%">
            <!--网点入库时间-->
            <el-date-picker
              v-model="timeValue"
              :default-time="['00:00:00', '23:59:59']"
              :end-placeholder="$t('operationCenter.endDate')"
              :start-placeholder="$t('operationCenter.startDate')"
              range-separator="~"
              style="width: 100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('collectionCenter.operatedObject')" style="width: 100%">
            <el-select
              v-model="searchForm.params.ruleName"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="(item,index) in ruleNameList"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <div style="margin-top: 28px">
            <el-button size="small" type="primary" @click="getSearch">{{ $t('newOrder.search') }}</el-button>
            <el-button size="small" @click="resetForm">{{ $t('newOrder.reset') }}</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="tableData" :header-cell-style="{background:'#f2f2f2',color:'black'}" border
      style="width: 100%;"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" prop="index"
        type="index" width="60px"
      />
      <el-table-column
        :label="$t('GlobalSetObj.operationTime')" align="center" min-width="160px"
        prop="createTime"
      />
      <el-table-column
        :label="$t('GlobalSetObj.operator')" align="center" min-width="130px"
        prop="operarorUser"
      />
      <el-table-column
        :label="$t('collectionCenter.operatedObject')"
        align="center"
        min-width="130px"
        prop="operateObject"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.operateObject || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('collectionCenter.operationType')"
        align="center"
        min-width="130px"
        prop="operatType"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.operatType || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('GlobalSetObj.beforeOperation')"
        align="center"
        min-width="130px"
        prop="oldData"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.oldData || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('GlobalSetObj.afterOperation')" align="center" min-width="140px"
        prop="newData"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.newData || '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: right">
      <el-pagination
        :current-page.sync="searchForm.pageNumber"
        :page-size.sync="searchForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 15px 0;"
        @current-change="getSearch"
        @size-change="getSearch"
      />
    </div>
  </div>
</template>

<script>
import { sortedRuleQueryLog, sortedRuleQueryLogObj } from '@/api/finance/basicData';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      timeValue: [],
      userIdAndNameObj: '',
      searchForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          ruleName: ''
        }
      },
      tableData: [],
      total: 0,
      ruleNameList: []
    };
  },
  created() {
    // return;
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getTimer();
    this.getRuleName();
    this.getSearch();
  },
  methods: {
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      const oneWeekAgo = dayjs(brazilTime).subtract(1, 'week').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    getSearch() {
      const data = {
        pageNumber: this.searchForm.pageNumber,
        pageSize: this.searchForm.pageSize,
        params: {
          ruleName: this.searchForm.params.ruleName
        }
      };
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.operateStartTime = this.timeValue[0];
        data.params.operateEndTime = this.timeValue[1];
      }
      sortedRuleQueryLog(data).then((res) => {
        console.log(res);
        if (res.code===200) {
          this.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.map(item => {
            item.operarorUser = getUserName(item.createUser, this.userIdAndNameObj);
          });
        }
      });
    },
    getRuleName(ruleName = '') {
      const data = {
        ruleName: ruleName
      };
      sortedRuleQueryLogObj(data).then((res) => {
        if (res.code===200) {
          this.ruleNameList = res.data;
        }
      });
    },
    resetForm() {
      this.searchForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          ruleName: ''
        }
      };
      this.getTimer();
      this.getSearch();
    }
  }
};
</script>

<style scoped>
.search-form {
  margin-bottom: 20px;
}

>>> .el-form-item {
  margin-bottom: 0 !important;
}
</style>
