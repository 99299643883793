<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20" type="flex" justify="flex-start" style="flex-wrap: wrap;">
        <el-col :span="5">
          <!--  运单号   -->
          <el-form-item prop="selectType">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.selectType"
                  :style="IsWidth1"
                >
                  <el-option key="1" :label="$t('newOrder.waybillNumber')" :value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.ScanNumber')" :value="2" />
                </el-select>
              </div>
            </template>
            <el-input
              v-model="queryForm.params.inputValue"
              :placeholder="$t('newOrder.PreciseSearch1')"
              :rows="1"
              style="width:100%"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <!--时间-->
          <el-form-item :label="$t('GlobalSetObj.SigningTime')" prop="timeType">
            <el-date-picker
              v-model="timeValue"
              :clearable="true"
              :default-time="['00:00:00', '23:59:59']"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :placeholder="$t('newOrder.SelectDatetime')"
              range-separator="~"
              style="width:100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <!-- 州 -->
        <el-col :span="4">
          <el-form-item :label="$t('collectionCenter.stateSite1')">
            <el-select
              v-model="queryForm.params.stateList"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              style="width: 100%"
            >
              <el-option
                v-for="item in StateList"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--   实际派送网点   -->
          <el-form-item :label="$t('GlobalSetObj.entrega')">
            <el-select
              v-model="queryForm.params.siteList"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              :remote-method="remoteGetSiteList"
              clearable
              filterable
              multiple
              remote
              reserve-keyword
              style="width:100%"
              @focus="remoteGetSiteList('')"
            >
              <el-option
                v-for="item in preDeliverySiteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--   与收件人距离   -->
          <el-form-item>
            <el-select v-model="signDistanceCompare" class="distanceClass" style="width: 100%">
              <el-option :label="$t('collectionCenter.greaterThanAddresseeDistance')" value=">" />
              <el-option :label="$t('collectionCenter.lessThanAddresseeDistance')" value="<=" />
            </el-select>
            <el-select v-model="queryForm.params.signDistance" style="width: 100%" :placeholder="$t('collectionCenter.pleaseChoose')" clearable>
              <el-option label="10" :value="10" />
              <el-option label="50" :value="50" />
              <el-option label="100" :value="100" />
              <el-option label="200" :value="200" />
              <el-option label="500" :value="500" />
              <el-option label="1000" :value="1000" />
              <el-option label="2000" :value="2000" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" style="display: flex; justify-content: flex-start; align-items: center;">
          <div>
            <!--搜索-->
            <el-button size="small" type="primary" @click="enterSearch">{{ $t('newOrder.search') }}</el-button>
            <el-button size="small" @click="allExportOrder">{{ $t('newOrder.export') }}</el-button>
            <!--重置-->
            <el-button size="small" type="info" @click="resetForm">{{ $t('newOrder.reset') }}</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="`${$t('GlobalSetObj.serial', 'zh')}（${$t('GlobalSetObj.serial', 'pu')}）`" align="center" type="index"
        width="80"
      />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :align="item.center"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">
          <div>{{ scope.row[item.prop] || scope.row[item.prop] == 0 ? `${scope.row[item.prop]}` : '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { getLgAreaListData, signLocationexportSign, signLocationPage } from '@/api/orderApi';
import dayjs from 'dayjs';
import { siteFuzzyQuery } from '@/api/lanshou';

export default {
  name: 'SignLatLonMonitoring',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          timeType: 1,
          selectType: 1,
          siteList: [],
          stateList: [],
          inputValue: '',
          signDistanceCompare: '>',
          signDistance: ''
        }
      },
      signDistanceCompare: '>',
      tableData: [],
      preDeliverySiteList: [],
      tableHeaderList: [
        // 签收时间
        {
          id: 1,
          label: `${this.$t('GlobalSetObj.SigningTime', 'zh')}（${this.$t('GlobalSetObj.SigningTime', 'pu')}）`,
          prop: 'signTime',
          cloWidth: '170',
          center: 'center'
        },
        // 运单号
        { id: 2, label: `${this.$t('newOrder.waybillNumber', 'zh')}（${this.$t('newOrder.waybillNumber', 'pu')}）`, prop: 'waybillNumber', cloWidth: '160', center: 'center' },
        // 扫描单号
        { id: 3, label: `${this.$t('GlobalSetObj.ScanNumber', 'zh')}（${this.$t('GlobalSetObj.ScanNumber', 'pu')}）`, prop: 'scanOrderNumber', cloWidth: '160', center: 'center' },
        // 客户
        { id: 4, label: `${this.$t('GlobalSetObj.client', 'zh')}（${this.$t('GlobalSetObj.client', 'pu')}）`, prop: 'customerName', cloWidth: '150', center: 'center' },
        // 州
        { id: 5, label: `${this.$t('GlobalSetObj.Estado', 'zh')}（${this.$t('GlobalSetObj.Estado', 'pu')}）`, prop: 'stateName', cloWidth: '140', center: 'center' },
        // 实际派送网点
        {
          id: 6,
          label: `${this.$t('GlobalSetObj.entrega', 'zh')}（${this.$t('GlobalSetObj.entrega', 'pu')}）`,
          prop: 'siteName',
          cloWidth: '150',
          center: 'center'
        },
        // 派送员
        {
          id: 7,
          label: `${this.$t('orderCenterCont.deliveryPerson', 'zh')}（${this.$t('orderCenterCont.deliveryPerson', 'pu')}）`,
          prop: 'delivererName',
          cloWidth: '150',
          center: 'center'
        },
        // 实际签收经纬度
        {
          id: 8,
          label: `${this.$t('navbar.actuallySignLoc', 'zh')}（${this.$t('navbar.actuallySignLoc', 'pu')}）`,
          prop: 'actuallySignLoc',
          cloWidth: '170',
          center: 'center'
        },
        // 收件人签收经纬度
        {
          id: 9,
          label: `${this.$t('navbar.receiveSignLoc', 'zh')}（${this.$t('navbar.receiveSignLoc', 'pu')}）`,
          prop: 'receiveSignLoc',
          cloWidth: '170', center: 'center'
        },
        // 与收件地址直线距离(米)
        {
          id: 10,
          label: `${this.$t('navbar.signDistance', 'zh')}（${this.$t('navbar.signDistance', 'pu')}）`,
          prop: 'signDistance',
          cloWidth: '180',
          center: 'center'
        },
        // 网点经纬度
        {
          id: 11,
          label: `${this.$t('navbar.siteSignLoc', 'zh')}（${this.$t('navbar.siteSignLoc', 'pu')}）`,
          prop: 'siteSignLoc',
          cloWidth: '170',
          center: 'center'
        },
        // 与网点直线距离(米)
        {
          id: 12,
          label: `${this.$t('navbar.siteDistance', 'zh')}（${this.$t('navbar.siteDistance', 'pu')}）`,
          prop: 'siteDistance',
          cloWidth: '170',
          center: 'center'
        }
      ],
      StateList: [] // 用户列表
    };
  },
  computed: {
    IsWidth1() {
      return {
        width: this.isTormat ? '180px' : '100px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getSearch();
    this.remoteGetSiteList('');
    this.getAddress();
  },
  methods: {
    // 查询
    getSearch() {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        this.timeValue = [];
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      const data = {
        params: {}
      };
      this.queryForm.params.signDistanceCompare = this.signDistanceCompare;
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params = this.queryForm.params;
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.beginTime = this.timeValue[0];
        data.params.endTime = this.timeValue[1];
      } else {
        data.params.beginTime = null;
        data.params.endTime = null;
      }
      if (this.queryForm.params.selectType === 1) {
        data.params.waybillNumberList = inputValList;
        data.params.scanOrderNumberList = [];
      } else {
        data.params.scanOrderNumberList = inputValList;
        data.params.waybillNumberList = [];
      }
      signLocationPage(data).then(res => {
        if (res.code===200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    allExportOrder() {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      const data = this.queryForm.params;
      if (this.timeValue && this.timeValue.length > 0) {
        data.beginTime = this.timeValue[0];
        data.endTime = this.timeValue[1];
      } else {
        data.beginTime = null;
        data.endTime = null;
      }
      if (this.queryForm.params.selectType === 1) {
        data.waybillNumberList = inputValList;
        data.scanOrderNumberList = [];
      } else {
        data.scanOrderNumberList = inputValList;
        data.waybillNumberList = [];
      }
      signLocationexportSign(data).then(res => {
        if (res.code===200) {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          });
        }
      });
    },
    // 获取最近一周时间
    getTimer() {
      // 获取当前时间
      const today = dayjs().format('YYYY-MM-DD');
      const oneWeekAgo = dayjs().subtract(1, 'months').format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.getTimer();
      this.queryForm.params = {
        timeType: 1,
        selectType: 1,
        siteList: [],
        stateList: [],
        inputValue: '',
        signDistanceCompare: '>',
        signDistance: ''
      };
      this.signDistanceCompare = '>';
      this.getSearch();
    },
    getAddress() {
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.code===200) {
          this.StateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    },
    // 获取所有网点
    remoteGetSiteList(val) {
      const value = val || '';
      siteFuzzyQuery(value).then(res => {
        if (res.code===200) {
          this.preDeliverySiteList = res.data;
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    }
  }
};
</script>

<style lang="scss" scoped>
.distanceClass ::v-deep .el-input__inner{
  border: none;
  height: 28px !important;
  line-height: 28px !important;
  font-weight: bold;
  text-align: left;
}
.selectLabel ::v-deep .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.selectLabel ::v-deep .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep .el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}
</style>
