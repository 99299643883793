<template>
  <div class="qMBox">
    <div style="margin-bottom: 20px;">
      <el-form class="findClass">
        <el-row>
          <el-col :span="10" style="width: auto;">
            <el-row type="flex" justify="flex-start">
              <!-- 有效起始时间： -->
              <el-form-item :label="$t('collectionCenter.effectiveStartTime')">
                <div class="block">
                  <el-date-picker
                    v-model="queryForm.params.startTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :placeholder="$t('collectionCenter.StartTime')"
                    :clearable="false"
                  />
                </div>
              </el-form-item>
            </el-row>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 客户名称： -->
            <el-form-item :label="$t('collectionCenter.CustomerN')">
              <el-select v-model="queryForm.params.customerId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable @focus="getAllCustomer">
                <el-option
                  v-for="(item, index) in customerIdList"
                  :key="index"
                  :label="item.username"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 计费模式： -->
            <el-form-item :label="$t('collectionCenter.billingMode')">
              <el-select v-model="queryForm.params.chargePattern" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable>
                <el-option :label="$t('collectionCenter.distance')" :value="0" /><!-- 距离 -->
                <el-option :label="$t('collectionCenter.ballot')" :value="1" /><!-- 票数 -->
                <el-option :label="$t('collectionCenter.Weight')" :value="2" /><!-- 重量 -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 业务类型： -->
            <el-form-item :label="$t('collectionCenter.businessT') + '：'">
              <el-select v-model="queryForm.params.businessType" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable>
                <el-option :label="$t('collectionCenter.dropOff')" :value="1" /><!-- 上门交件 -->
                <el-option :label="$t('collectionCenter.toCollect')" :value="2" /><!-- 上门揽收 -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 所属网点： -->
            <el-form-item :label="$t('collectionCenter.belongDot')">
              <el-select v-model="queryForm.params.belongToSiteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable @focus="getNewestSite">
                <el-option
                  v-for="(item, index) in siteIdList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:finance:quotationMaintenance:search'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            :disabled="btnDisablad.searBtn"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:finance:quotationMaintenance:reset'"
            icon="el-icon-refresh"
            size="mini"
            :disabled="btnDisablad.resBtn"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-permit:remove="'btn:collection-center:finance:quotationMaintenance:trialCalculation'"
            size="mini"
            :disabled="btnDisablad.tryBtn"
            @click="tryCalculationClick"
          >{{ $t('collectionCenter.trialCalculation') }}</el-button><!-- 试算 -->
          <el-button
            v-permit:remove="'btn:collection-center:finance:quotationMaintenance:Delete'"
            type="danger"
            size="mini"
            :disabled="btnDisablad.delBtn"
            @click="delClick"
          >{{ $t('collectionCenter.Delete') }}</el-button><!-- 删除 -->
          <el-button
            v-permit:remove="'btn:collection-center:finance:quotationMaintenance:add'"
            type="primary"
            size="mini"
            icon="el-icon-plus"
            :disabled="btnDisablad.addBtn"
            @click="addClick"
          >{{ $t('collectionCenter.add') }}</el-button><!-- 新增 -->
          <el-button
            v-permit:remove="'btn:collection-center:finance:quotationMaintenance:modify'"
            size="mini"
            icon="el-icon-edit-outline"
            :disabled="btnDisablad.editBtn"
            @click="editClick"
          >{{ $t('collectionCenter.modify') }}</el-button><!-- 修改 -->
          <el-button
            v-permit:remove="'btn:collection-center:finance:quotationMaintenance:Save'"
            type="success"
            size="mini"
            :disabled="btnDisablad.saveBtn"
            @click="saveClick"
          >{{ $t('collectionCenter.Save') }}</el-button><!-- 保存 -->
          <el-button
            v-permit:remove="'btn:collection-center:finance:quotationMaintenance:cancel'"
            type="info"
            size="mini"
            :disabled="btnDisablad.cancelBtn"
            @click="cancelClick"
          >{{ $t('collectionCenter.cancel') }}</el-button><!-- 取消 -->
        </el-col>
      </el-row>
      <el-divider />
      <div v-show="isShowSubmitForm" :class="findDisable ? 'disabledClass' : ''">
        <el-row>
          <el-col :span="14" class="bRClass">
            <el-form ref="submitFormRef" :model="submitForm" :rules="submitRules" class="submitClass">
              <el-row>
                <el-col :span="8" class="lineH">
                  <el-row type="flex" justify="space-between">
                    <el-col :span="11">
                      <!-- 寄件区域： -->
                      <el-form-item>
                        <span class="redClass">{{ $t('collectionCenter.sendArea') }}</span>
                        <div
                          class="areaClass"
                          :style="zipcodeSelectList.length <= 0 && isShowSubmitForm && clickSavaBtn ? 'border:1px solid #F56C6C;' : ''"
                          @click="chooseListV('send')"
                        >
                          <span v-if="submitForm.areaName.length > 0">
                            <span v-for="(item, index) in zipcodeSelectList" :key="index">
                              <span>
                                {{
                                  item.areaName
                                }}{{ index + 1 != zipcodeSelectList.length ? ', ' : '' }}
                              </span>
                            </span>
                          </span>
                        </div>
                        <span :class="zipcodeSelectList.length <= 0 && isShowSubmitForm && clickSavaBtn ? 'areaRedClass' : 'hideClass'">
                          {{ $t('collectionCenter.notNull') }}
                        </span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <!-- 揽收网点： -->
                      <el-form-item>
                        <span class="redClass">{{ $t('collectionCenter.collectionSite') }}</span>
                        <div class="areaClass" :style="selectedSiteList.length <= 0 && isShowSubmitForm && clickSavaBtn ? 'border:1px solid #F56C6C;' : ''" @click="chooseListV('site')">
                          <span v-for="(itemS, index) in selectedSiteList" :key="index">
                            <span>{{ itemS.name }}{{ index + 1 != selectedSiteList.length ? ', ' : '' }}</span>
                          </span>
                        </div>
                        <span :class="selectedSiteList.length <= 0 && isShowSubmitForm && clickSavaBtn ? 'areaRedClass' : 'hideClass'">
                          {{ $t('collectionCenter.notNull') }}
                        </span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="16">
                  <el-row class="flexStart">
                    <el-col :span="12">
                      <!-- 客户名称： -->
                      <el-form-item :label="$t('collectionCenter.CustomerN')" prop="customerId">
                        <el-select v-model="submitForm.customerId" :disabled="findDisable" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable @focus="getAllCustomer">
                          <el-option
                            v-for="(item, index) in customerIdList"
                            :key="index"
                            :label="item.username"
                            :value="item.id"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <!-- 业务类型： -->
                      <el-form-item :label="$t('collectionCenter.businessT') + '：'" prop="businessType">
                        <el-select v-model="submitForm.businessType" :disabled="findDisable" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable>
                          <el-option :label="$t('collectionCenter.dropOff')" :value="1" /><!-- 上门交件 -->
                          <el-option :label="$t('collectionCenter.toCollect')" :value="2" /><!-- 上门揽收 -->
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <!-- 计费模式： -->
                      <el-form-item :label="$t('collectionCenter.billingMode')" prop="chargePattern">
                        <el-select v-model="submitForm.chargePattern" :disabled="findDisable" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable @change="jfChange">
                          <el-option :label="$t('collectionCenter.distance')" :value="0" /><!-- 距离 -->
                          <el-option :label="$t('collectionCenter.ballot')" :value="1" /><!-- 票数 -->
                          <el-option :label="$t('collectionCenter.Weight')" :value="2" /><!-- 重量 -->
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <!-- 所属网点： -->
                      <el-form-item :label="$t('collectionCenter.belongDot')" prop="belongToSiteId">
                        <el-select v-model="submitForm.belongToSiteId" :disabled="findDisable" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable @focus="getNewestSite">
                          <el-option
                            v-for="(item, index) in siteIdList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <!-- 最低费用： -->
                      <el-form-item :label="$t('collectionCenter.minimumCharge')">
                        <el-input v-model="submitForm.minimumCharge" :disabled="findDisable" :placeholder="$t('collectionCenter.pleaseInput')" clearable />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <!-- 是否启用： -->
                      <el-form-item prop="enableFlag" :label="$t('collectionCenter.ifEnabled')">
                        <el-select v-model="submitForm.enableFlag" :disabled="findDisable" :placeholder="$t('collectionCenter.pleaseChoose')" clearable>
                          <el-option :label="$t('collectionCenter.enable')" :value="0" />
                          <el-option :label="$t('collectionCenter.notEnabled')" :value="1" />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" class="dateChooseClass">
                      <!-- 有效起始时间： -->
                      <el-form-item :label="$t('collectionCenter.effectiveStartTime')" prop="startTime">
                        <el-date-picker
                          v-model="submitForm.startTime"
                          :disabled="findDisable"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          :placeholder="$t('collectionCenter.StartTime')"
                          :clearable="false"
                          @change="submitChangeDate($event)"
                        />
                        ~
                        <el-date-picker
                          v-model="submitForm.endTime"
                          :disabled="findDisable"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          :placeholder="$t('collectionCenter.EndTime')"
                          :clearable="false"
                          @change="submitChangeDate($event)"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <!-- 备注： -->
                      <el-form-item :label="$t('collectionCenter.note')">
                        <el-input v-model="submitForm.remark" :disabled="findDisable" :placeholder="$t('collectionCenter.pleaseInput')" clearable />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
          <el-col :span="10" class="modelTableClass">
            <div>
              <div class="captionClass">
                <!-- '提示：d(distance)距离；w(weight)重量；p件数' -->
                <div class="captionRed" v-text="$t('collectionCenter.tableTips')" />

                <!-- '符号说明：>大于；<小于；>=大于或等于；<=小于或等于' -->
                <!--                <div v-text="$t('collectionCenter.tableExplain')" />-->
                <!--                <div v-text="$t('collectionCenter.formulaExample')" />-->
              </div>
              <!-- 计费模式为——距离——start -->
              <el-form
                ref="submitRef"
                :model="longTableForm"
                :rules="longTableForm.longTableRules"
              >
                <el-table
                  border
                  :max-height="200"
                  :data="longTableForm.longTable"
                >
                  <el-table-column
                    prop="startValue"
                    align="center"
                    width="100"
                    :label="submitForm.chargePattern == 0 ?
                      $t('collectionCenter.distanceMin') : submitForm.chargePattern == 1 ?
                        $t('collectionCenter.pollMin') : submitForm.chargePattern == 2 ?
                          $t('collectionCenter.weightMin') : ''"
                  >
                    <!-- '距离(最小值)' 0       '票数(最小值)' 1      '重量(最小值)' 2 -->
                    <!-- 距离段 -->
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="'longTable.' + scope.$index + '.startValue'"
                        :rules="longTableForm.longTableRules.startValue"
                      >
                        <el-input
                          v-model="scope.row.startValue"
                          :disabled="findDisable"
                          clearable
                          :placeholder="$t('collectionCenter.pleaseInput')"
                          @blur="toNumber(scope.row,scope.$index)"
                        />
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="endValue"
                    align="center"
                    width="100"
                    :label="submitForm.chargePattern == 0 ?
                      $t('collectionCenter.distanceMax') : submitForm.chargePattern == 1 ?
                        $t('collectionCenter.pollMax') : submitForm.chargePattern == 2 ?
                          $t('collectionCenter.weightMax') : ''"
                  >
                    <!-- '距离(最大值)' 0       '票数(最大值)' 1      '重量(最大值)' 2 -->
                    <!-- 距离段 -->
                    <template slot-scope="scope">
                      <el-form-item :prop="'longTable.' + scope.$index + '.endValue'" :rules="longTableForm.longTableRules.endValue">
                        <el-input
                          v-model="scope.row.endValue"
                          :disabled="findDisable"
                          clearable
                          :placeholder="$t('collectionCenter.pleaseInput')"
                          @blur="toNumber(scope.row,scope.$index)"
                        />
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="formula"
                    align="center"
                    :label="$t('collectionCenter.formula')"
                  ><!-- 公式 -->
                    <template slot-scope="scope">
                      <el-form-item :prop="'longTable.' + scope.$index + '.formula'" :rules="longTableForm.longTableRules.formula">
                        <el-input
                          v-model="scope.row.formula"
                          :disabled="findDisable"
                          type="text"
                          clearable
                          :placeholder="$t('collectionCenter.pleaseInput')"
                          @blur="toNumber(scope.row,scope.$index)"
                        />
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="remark"
                    align="center"
                    :label="$t('collectionCenter.Note')"
                    width="80"
                  ><!-- 备注 -->
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.remark"
                        :disabled="findDisable"
                        clearable
                        placeholder=""
                      />
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    label=""
                    width="29"
                  ><!-- 删除 -->
                    <template slot-scope="scope">
                      <div v-show="!findDisable">
                        <div v-show="scope.$index != 0" class="delRowBtnClass">
                          <el-button icon="el-icon-delete" type="danger" size="mini" circle @click="delRow(scope.row,scope.$index)" />
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form>
              <div v-show="!findDisable" style="text-align: center;">
                <el-button type="primary" icon="el-icon-plus" size="mini" circle @click="addRow" />
              </div>
              <!-- 计费模式为——距离——end -->
            </div>
          </el-col>
        </el-row>
        <el-divider />
      </div>
    </div>
    <div class="currentRowClass">
      <el-table
        ref="tableRef"
        border
        :max-height="600"
        :data="tableDate"
        highlight-current-row
        @select="batchSelect"
        @select-all="batchSelectAll"
        @row-click="rowClick"
        @current-change="handleCurrentChange"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column :label="$t('collectionCenter.ind')" type="index" width="60" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="customerName"
          align="center"
          :label="$t('collectionCenter.CustomerName')"
        /><!-- 客户名称 -->
        <el-table-column
          prop="areaName"
          align="center"
          :label="$t('collectionCenter.sendRegional')"
          :show-overflow-tooltip="false"
        ><!-- 寄件区域 -->
          <template slot-scope="scope">
            <el-tooltip placement="top">
              <div slot="content" style="max-height: 200px;overflow: auto;width:500px;">{{ scope.row.areaNameStr }}</div>
              <div class="paramsStyle">{{ scope.row.areaNameStr }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="collectSiteName"
          align="center"
          :label="$t('collectionCenter.collectionDot')"
          :show-overflow-tooltip="false"
        ><!-- 揽收网点 -->
          <template slot-scope="scope">
            <el-tooltip placement="top">
              <div slot="content" style="max-height: 200px;overflow: auto;width:500px;">{{ scope.row.collectSiteName }}</div>
              <div class="paramsStyle">{{ scope.row.collectSiteName == '[]' ? '' : scope.row.collectSiteName }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="belongToSiteName"
          align="center"
          :label="$t('collectionCenter.belongD')"
        /><!-- 所属网点 -->
        <el-table-column
          prop="startTime"
          align="center"
          :label="$t('collectionCenter.eStartTime')"
        /><!-- 有效起始时间 -->
        <el-table-column
          prop="endTime"
          align="center"
          :label="$t('collectionCenter.effectiveEndTime')"
        /><!-- 有效结束时间 -->
        <el-table-column
          align="center"
          prop="businessType"
          :label="$t('collectionCenter.businessT')"
        ><!-- 业务类型 -->
          <template slot-scope="scope">
            <div>
              <!-- 2 上门揽收    1 客户上门交件 -->
              {{
                scope.row.businessType == 2 ? $t('collectionCenter.toCollect') :
                scope.row.businessType == 1 ? $t('collectionCenter.dropOff') : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="enableFlag"
          align="center"
          :label="$t('collectionCenter.ifUse')"
        ><!-- 是否启用 -->
          <template slot-scope="scope">
            <div>
              <!-- 0 启用    1 停用 -->
              {{ scope.row.enableFlag == 0 ? $t('collectionCenter.enable') :
                scope.row.enableFlag == 1 ? $t('collectionCenter.Disable') : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          align="center"
          :label="$t('collectionCenter.Note')"
        /><!-- 备注 -->
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getPage"
        @size-change="getPageSize"
      />
    </div>

    <!-- 试算————start -->
    <div v-if="drawerIsOpen">
      <el-drawer
        :title="drawerTitle"
        :visible.sync="drawerIsOpen"
        :direction="direction"
        size="40%"
      >
        <div class="drawerClass">
          <el-form ref="tryCDrawerFormRef" :model="tryCDrawerForm" :rules="tryCRules" label-position="top">
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 客户名称： -->
                <el-form-item prop="customerId" :label="$t('collectionCenter.CustomerN')">
                  <el-select v-model="tryCDrawerForm.customerId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable @focus="getAllCustomer">
                    <el-option
                      v-for="(item, index) in customerIdList"
                      :key="index"
                      :label="item.username"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 寄件区域： -->
                <el-form-item prop="divisionId" :label="$t('collectionCenter.sendR')">
                  <el-select v-model="tryCDrawerForm.divisionId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable>
                    <el-option
                      v-for="(item, index) in tryZipcodeTableDate"
                      :key="index"
                      :label="item.areaName"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 业务类型： -->
                <el-form-item prop="businessType" :label="$t('collectionCenter.businessT') + '：'">
                  <el-select v-model="tryCDrawerForm.businessType" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable>
                    <el-option :label="$t('collectionCenter.dropOff')" :value="1" /><!-- 上门交件 -->
                    <el-option :label="$t('collectionCenter.toCollect')" :value="2" /><!-- 上门揽收 -->
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 所属网点： -->
                <el-form-item prop="siteId" :label="$t('collectionCenter.belongDot')">
                  <el-select v-model="tryCDrawerForm.siteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable filterable @focus="getNewestSite">
                    <el-option
                      v-for="(item, index) in siteIdList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 计费模式： -->
                <el-form-item prop="value" :label="$t('collectionCenter.billingMode')" class="chargePatternClass">
                  <el-select v-model="tryCDrawerForm.chargePattern" :placeholder="$t('collectionCenter.pleaseChoose')">
                    <el-option :label="$t('collectionCenter.distance')" :value="0" /><!-- 距离 -->
                    <el-option :label="$t('collectionCenter.ballot')" :value="1" /><!-- 票数 -->
                    <el-option :label="$t('collectionCenter.Weight')" :value="2" /><!-- 重量 -->
                  </el-select>
                  <el-input v-model.trim="tryCDrawerForm.value" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 揽收网点： -->
                <el-form-item prop="collectSiteId" :label="$t('collectionCenter.collectionSite')">
                  <el-input
                    v-model.trim="tryCDrawerForm.collectSiteId"
                    clearable
                    :placeholder="$t('collectionCenter.pleaseChoose')"
                    @focus="openSiteDialog"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="submitClickBtn tryC">
            <el-button type="success" @click="trySave">{{ $t('collectionCenter.trialCalculation') }}</el-button><!-- 试算 -->
            <el-button @click="tryCancel">{{ $t('collectionCenter.cancel') }}</el-button><!-- 取消 -->
          </div>
          <div class="tryResultBoxClass">
            <!-- 试算结果： -->
            <span>
              {{ $t('collectionCenter.trialResult') }}
            </span>
            <span class="tryResult">
              {{ tryCDrawerData }}
            </span>
          </div>
        </div>
      </el-drawer>
    </div>
    <!-- 试算————end -->
    <!-- 试算——揽收网点选择——start -->
    <el-dialog
      :title="$t('collectionCenter.selectReceivingOutlets')"
      :visible.sync="trySiteVisible"
      width="50%"
      @close="tryCloseDialog"
    ><!-- 揽收网点选择 -->
      <div>
        <div class="searchClass">
          <span>{{ $t('collectionCenter.query') }}</span><!-- 查询： -->
          <el-input v-model="siteDialogForm.name" clearable :placeholder="$t('collectionCenter.querySiteCode')">
            <el-button slot="append" icon="el-icon-search" @click="searchSiteTry" />
          </el-input>
        </div>
        <!-- 选中节点 -->
        <span style="display: block;text-align: center;">{{ chooseSite.name }}</span>
        <div>
          <el-card class="scoll trySiteClass">
            <el-tree
              ref="trySiteRef"
              :data="treeData"
              :default-expand-all="true"
              :props="defaultProps"
              :highlight-current="true"
              node-key="id"
              :current-node-key="currentNodekey"
              @node-click="treeCheck"
            ><span slot-scope="{ data }" class="custom-tree-node">
              <span> {{ data.code }} - {{ data.name }}</span>
            </span>
            </el-tree>
          </el-card>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="trySiteSaveClick">{{ $t('collectionCenter.Save') }}</el-button>
          <el-button @click="trySiteVisible = false">{{ $t('collectionCenter.cancel') }}</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 试算——揽收网点选择——end -->

    <div v-if="chooseListVisible">
      <el-dialog
        :title="chooseTitle"
        :visible.sync="chooseListVisible"
        width="50%"
        @close="closeDialog"
      >
        <!-- 寄件区域选择————start -->
        <div v-if="openTheDialog == 'send'">
          <div class="searchClass">
            <span>{{ $t('collectionCenter.ZipCodeAreaName') }}</span><!-- 分区名称： -->
            <el-input v-model="zipcodeDrawerForm.name" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
            <el-button type="primary" @click="chooseSearchClick">
              {{ $t('collectionCenter.search') }}
            </el-button><!-- 查询 -->
          </div>
          <div class="zipcodeTableClass">
            <el-table
              ref="zipcodeRef"
              border
              :max-height="400"
              :data="zipcodeTableDate"
              @select="zipcodeBatchSelect"
              @select-all="zipcodeBatchSelectAll"
            >
              <el-table-column
                type="selection"
                width="55"
              />
              <el-table-column
                prop="id"
                align="center"
                :label="$t('collectionCenter.SerialNumber')"
              /><!-- 编号 -->
              <el-table-column
                prop="areaName"
                align="center"
                :label="$t('collectionCenter.partitionName')"
              /><!-- 分区名称 -->
            </el-table>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveZipCodeClick">{{ $t('collectionCenter.Save') }}</el-button>
            <el-button @click="searchZipCodeClose">{{ $t('collectionCenter.cancel') }}</el-button>
          </span>
        </div>
        <!-- 寄件区域选择————end -->
        <!-- 揽收网点选择————start -->
        <div v-if="openTheDialog == 'site'">
          <div class="searchClass">
            <span>{{ $t('collectionCenter.query') }}</span><!-- 查询： -->
            <el-input v-model="siteDrawerForm.name" clearable :placeholder="$t('collectionCenter.querySiteCode')">
              <el-button slot="append" icon="el-icon-search" @click="searchSite" />
            </el-input>
          </div>
          <div>
            <el-card class="scoll highlightClass">
              <el-tree
                ref="siteRef"
                :data="treeData"
                show-checkbox
                :default-expand-all="true"
                :props="defaultProps"
                :check-strictly="true"
                node-key="id"
                :default-checked-keys="defaultChecked"
                :highlight-current="highlightCurrent"
                :current-node-key="currentNodekey"
                @check-change="changeEvent"
              ><span slot-scope="{ data }" class="custom-tree-node">
                <span> {{ data.code }} - {{ data.name }}</span>
              </span>
              </el-tree>
            </el-card>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="searchSiteClick">{{ $t('collectionCenter.Save') }}</el-button>
            <el-button @click="searchSiteClose">{{ $t('collectionCenter.cancel') }}</el-button>
          </span>
        </div>
        <!-- 揽收网点选择————end -->
      </el-dialog>
    </div>

  </div>
</template>

<script>
import {
  queryAllCustomer,
  collectCostCalculation,
  // zipCodeTreeStructure,
  zipCodeQueryData,
  getSiteTree,
  searchSiteIdList,
  collectCostCalculationPage,
  collectCostCalculationEdit,
  trial,
  delCollectCostCalculation
} from '@/api/lanshou';
import { checkSumber } from '@/utils/validate';
import dayjs from 'dayjs';

export default {
  name: 'QuotationMaintenance',
  data() {
    return {
      chooseRowList: [], // 选中行用于修改(选中多行提示)
      siteDialogForm: {}, // 试算-揽收网点选择
      highlightCurrent: true, // 高亮
      currentNodekey: '', // 高亮显示查询的网点
      findDisable: false, // 点击查看禁止操作
      defaultChecked: [], // 表单——揽收网点默认选中
      btnDisablad: {
        searBtn: false,
        resBtn: false,
        tryBtn: false,
        delBtn: false,
        addBtn: false,
        saveBtn: true,
        editBtn: false,
        cancelBtn: true
      },
      chooseSite: {
        code: '',
        name: '',
        id: ''
      }, // 展示选中网点
      trySiteVisible: false,
      tryCDrawerData: '', // 试算结果
      submitName: 'add', // 提交表单时判断是新增还是修改
      isShowSubmitForm: false, // 点击新增打开/关闭
      // 寄件区域选择、揽收网点选择
      openTheDialog: '', // 打开的窗口，控制显示内容
      chooseTitle: this.$t('collectionCenter.mailingAreaSelection'), // '寄件区域选择'
      chooseListVisible: false,
      zipcodeDrawerForm: {}, // 查询
      zipcodeTableDate: [],
      tryZipcodeTableDate: [],
      zipcodeSelectList: [], // 寄件区域选择——多选、全选
      siteDrawerForm: {
        name: ''
      },
      treeData: [],
      defaultProps: {
        children: 'children', // 指定子树为节点对象的某个属性值
        label: 'code' // 指定节点标签为节点对象的某个属性值
      },
      selectedSiteList: [], // 打开揽收网点弹窗——选中网点
      siteIdList: [], // 网点编号列表

      longTableForm: {
        longTable: [ // 距离段表格
          // { startValue: '0', endValue: '500', formula: '0.1', remark: '' },
          // { startValue: '500', endValue: '750', formula: '', remark: '' },
          { startValue: '', endValue: '', formula: '', remark: '' }
        ],
        longTableRules: {
          startValue: [
            { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
            {
              validator: async(rule, value, callback) => {
                if (!checkSumber(value)) {
                  this.$message.warning(this.$t('collectionCenter.enterNumber3significantDecimals')); // '请输入数字，可保留3位有效小数'
                } else {
                  callback();
                }
              }
            }
          ],
          endValue: [
            { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
            {
              validator: async(rule, value, callback) => {
                if (!checkSumber(value)) {
                  this.$message.warning(this.$t('collectionCenter.enterNumber3significantDecimals')); // '请输入数字，可保留3位有效小数'
                } else {
                  callback();
                }
              }
            }
          ],
          formula: [
            { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
            {
              validator: async(rule, value, callback) => {
                // 配置校验(2.5654 + 3) * 4.7568 - 1.223 / d
                // 以下情况报错
                const code2 = /[pwdPWD]{2,}/; // 字母不能连续
                const rightCode = /[pwdPWD]/; // 正确的字母
                const firstCode = /^[pwdPWD]/; // 第一个不能是字母
                const EnglishCode = /[a-zA-Z]/; // 校验字母时判断是否全英文
                // 校验括号()是否是完整的————start
                const stack1 = [];
                for (let i = 0, item1; i < value.length; i++) {
                  item1 = value.charAt(i);
                  if (item1 === '(') {
                    stack1.push('(');
                  } else if (item1 === ')') {
                    if (stack1.length > 0) {
                      stack1.pop();
                    }
                  }
                }
                // 校验括号()是否是完整的————end
                const reg1 = /^[0-9]+\.?[0-9]{0,3}$/;
                let sumberOK = true;
                // 公式内只有数字时不进入split和forEach
                if (sumberOK && !reg1.test(value)) {
                  const matchVal = value.split(/[\+\-\*\/\(\)\s~!@#$%^&a-zA-Z]/g);
                  matchVal.forEach(val => {
                    if (val !== '' && (val.toLowerCase().charCodeAt() < 97)) {
                      // 校验数字
                      if (!checkSumber(val)) {
                        sumberOK = false;
                      }
                    }
                  });
                }
                const v0 = /[~!@#$%^&]/; // 不允许匹配的运算符号
                const v1 = /^[\*\/\+\-]/; // 不能以运算符开始
                const v2 = /[\+\-\*\/]$/; // 不能以运算符结尾
                const v3 = /\(\)/; // 存在空括号
                const v4 = /[\+\-\*\/]{2,}/; // 运算符不能连续
                const v5 = /\([\+\-\*\/\s]/; // （ 的后面不能是运算符
                const v6 = /[\+\-\*\/\s]\)/; // ） 的前面不能是运算符
                const v7 = /[^\+\-\*\/\s]\(/; // （ 的前面只能是运算符
                const v8 = /\)[^\+\-\*\/\s]/; // ） 的后面只能是运算符

                if (!sumberOK) {
                  // 数字校验
                  this.$message.warning(this.$t('collectionCenter.enterNumber3significantDecimals')); // '请输入数字，可保留3位有效小数'
                  return callback(new Error(this.$t('collectionCenter.enterNumber3significantDecimals')));
                } else if (v0.test(value) || v1.test(value) || v2.test(value) || v3.test(value) || v4.test(value) || v5.test(value) ||
                  v6.test(value) || v7.test(value) || v8.test(value)) {
                  // 运算符号校验
                  return callback(new Error('符号错误'));
                } else if (code2.test(value) || firstCode.test(value) || !rightCode.test(value) && EnglishCode.test(value) ||
                  stack1.length !== 0) {
                  // 匹配字母
                  // this.$message.warning(this.$t('collectionCenter.formulaError')); // '公式错误，请检查'
                  return callback(new Error(this.$t('collectionCenter.formulaError'))); // '公式错误，请检查'
                } else {
                  callback();
                }
              }
            }
          ]
        }
      },
      customerIdList: [], // 客户名称列表
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '', // 开始时间
          // endTime: '', // 结束时间
          customerId: '', // 客户id
          chargePattern: '', // 计费模式:0:距离,1:票数,2:重量
          businessType: '', // 业务类型:2:pick-up(上门揽收),1:drop-off(客户上门交件)
          belongToSiteId: '' // 所属网点
        }
      },
      total: 0,
      tableDate: [],
      delIdList: [],
      drawerIsOpen: false,
      drawerTitle: this.$t('collectionCenter.trialCalculation'), // '试算'
      direction: 'ltr',
      tryCDrawerForm: {
      },
      tryCRules: {
        divisionId: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        businessType: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        siteId: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        value: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        collectSiteId: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }]
      },
      submitForm: { // 首页可编辑提交表
        collectSite: '', // 揽收网点
        customerId: '', // 客户id
        areaName: '', // 区域名称
        belongToSiteId: '', // 所属网点
        businessType: '', // 业务类型:2:pick-up(上门揽收), 1:drop-off(客户上门交件)
        chargePattern: '', // 计费模式:0:距离,1:票数,2:重量
        minimumCharge: '', // 最低费用
        enableFlag: 0, // 是否启用:0:启用,1:停用
        startTime: '', // 有效起始时间
        endTime: '', // 结束起始时间
        calculationFormula: '', // 计算公式
        remark: '' // 备注
      },
      submitFormAreaName: '', // 寄件区域选择选中项预存
      clickSavaBtn: false, // 寄件区域、揽收网点校验用
      submitRules: {
        customerId: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        businessType: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        chargePattern: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        belongToSiteId: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        enableFlag: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        startTime: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (!value || !this.submitForm.endTime) {
                callback(new Error(this.$t('collectionCenter.notNull')));
              } else {
                callback();
              }
            }
          }
        ]
      },
      currentRow: null // 高亮的行
    };
  },
  watch: {
    isShowSubmitForm(val) {
      if (!val) {
        this.btnDisablad = {
          searBtn: false,
          resBtn: false,
          tryBtn: false,
          delBtn: false,
          addBtn: false,
          saveBtn: true,
          editBtn: true,
          cancelBtn: true
        };
        // 关闭表单清空所有校验
        this.clickSavaBtn = false; // 寄件区域、揽收网点校验
        this.$refs['submitRef'].clearValidate();
        this.$refs['submitFormRef'].clearValidate();
      } else {
        this.$nextTick(() => {
          this.$refs['submitRef'].clearValidate();
          this.$refs['submitFormRef'].clearValidate();
        });
      }
    },
    'tryCDrawerForm.chargePattern'() {
      this.$nextTick(() => {
        this.$refs['tryCDrawerFormRef'].clearValidate('value');
      });
    }
  },
  created() {
    this.getAllCustomer();
    this.getNewestSite();
    this.defaultCondition();
    this.searchClick();
  },
  methods: {
    getNewestSite() {
      searchSiteIdList().then(res => {
        if (res.code===200) {
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    getAllCustomer() {
      // 查询条件——客户列表
      queryAllCustomer().then(res => {
        if (res.code===200) {
          res.data.forEach((list) => {
            this.customerIdList.push({
              id: list.id,
              username: list.username
            });
          });
        }
      });
    },
    jfChange(val) {
      this.longTableForm.longTable.forEach(arr => {
        // 还需加入计费模式选择校验并替换为对应的字母
        if (arr.formula) {
          if (val === 0) {
            arr.formula = arr.formula.replace(/[a-z]/ig, 'd');
          }
          if (val === 1) {
            arr.formula = arr.formula.replace(/[a-z]/ig, 'p');
          }
          if (val === 2) {
            arr.formula = arr.formula.replace(/[a-z]/ig, 'w');
          }
        }
      });
    },
    // 父节点关联子节点，子节点不关联父节点
    changeEvent(data, sel) {
      if (sel) {
        traverseTree(data).forEach((item) => {
          this.$refs.siteRef.setChecked(item, true);
        });
      } else {
        traverseTree(data).forEach((item) => {
          this.$refs.siteRef.setChecked(item, false);
        });
      }
      function traverseTree(node) {
        const child = node.children;
        let arr = [];
        arr.push(node.id);
        if (child) {
          child.forEach(function(node) {
            arr = arr.concat(traverseTree(node));
          });
        }
        return arr;
      }
    },
    // 高亮当前行
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    // 点击查看当前行
    rowClick(row) {
      this.chooseRowList[0] = row;
      this.btnDisablad = {
        searBtn: false,
        resBtn: false,
        tryBtn: false,
        delBtn: false,
        addBtn: false,
        editBtn: false,
        saveBtn: true,
        cancelBtn: true
      };
      this.findDisable = true;
      this.isShowSubmitForm = true;
      this.submitForm = {
        id: row.id,
        collectSite: row.collectSite ? JSON.parse(row.collectSite) : '', // 揽收网点
        customerId: row.customerId, // 客户id
        areaName: row.areaName ? JSON.parse(row.areaName) : '', // 区域名称
        belongToSiteId: row.belongToSiteId, // 所属网点
        businessType: row.businessType, // 业务类型:2:pick-up(上门揽收), 1:drop-off(客户上门交件)
        chargePattern: row.chargePattern, // 计费模式:0:距离,1:票数,2:重量
        minimumCharge: row.minimumCharge, // 最低费用
        enableFlag: row.enableFlag, // 是否启用:0:启用,1:停用
        startTime: row.startTime, // 有效起始时间
        endTime: row.endTime, // 结束起始时间
        calculationFormula: row.calculationFormula ? JSON.parse(row.calculationFormula) : '', // 计算公式
        remark: row.remark // 备注
      };
      // 寄件区域
      this.zipcodeSelectList = [];
      this.zipcodeSelectList.push({
        areaName: row.areaNameStr
      });
      // 揽收网点
      this.selectedSiteList = this.submitForm.collectSite;
      // 计费模式表
      this.longTableForm.longTable = this.submitForm.calculationFormula;
    },
    toNumber(row, index) { // 新增/编辑填写表单转数字类型——光标离开时判断
      // 以下情况报错
      const v0 = /[~!@#$%^&]/; // 不允许匹配的运算符号
      const v1 = /^[\*\/\+\-]/; // 不能以运算符开始
      const v2 = /[\+\-\*\/]$/; // 不能以运算符结尾
      const v3 = /\(\)/; // 存在空括号
      const v4 = /[\+\-\*\/]{2,}/; // 运算符不能连续
      const v5 = /\([\+\-\*\/\s]/; // （ 的后面不能是运算符
      const v6 = /[\+\-\*\/\s]\)/; // ） 的前面不能是运算符
      const v7 = /[^\+\-\*\/\s]\(/; // （ 的前面只能是运算符
      const v8 = /\)[^\+\-\*\/\s]/; // ） 的后面只能是运算符
      if (v0.test(row.formula) || v1.test(row.formula) || v2.test(row.formula) || v3.test(row.formula) || v4.test(row.formula) || v5.test(row.formula) ||
        v6.test(row.formula) || v7.test(row.formula) || v8.test(row.formula)) {
        this.$message.warning(this.$t('collectionCenter.formulaError')); // '公式错误，请检查'
      }

      if (row.startValue && checkSumber(row.startValue)) {
        this.longTableForm.longTable[index].startValue = Number(row.startValue);
        if (this.longTableForm.longTable[index].endValue && this.longTableForm.longTable[index].startValue > this.longTableForm.longTable[index].endValue) {
          this.$message({
            message: this.$t('collectionCenter.minimumNotThanMaximum'), // 最小值不能大于最大值
            type: 'error',
            offset: 350
          });
          this.longTableForm.longTable[index].startValue = '';
          this.longTableForm.longTable[index].endValue = '';
        }
      }
      if (row.endValue && checkSumber(row.endValue)) {
        this.longTableForm.longTable[index].endValue = Number(row.endValue);
      }
      if (row.formula && checkSumber(row.formula)) {
        this.longTableForm.longTable[index].formula = Number(row.formula);
      }
      // 优化范围选择
      const valTableList = this.longTableForm.longTable.filter(vL => {
        if (vL.startValue >= 0 && vL.endValue >= 0) {
          return { vL };
        }
      });
      if (valTableList.length <= 1) return;
      this.longTableForm.longTable.forEach((val, idx) => {
        // if (index < idx) return; // 需要全局查 🈲
        const sv = this.longTableForm.longTable[index].startValue;
        const ev = this.longTableForm.longTable[index].endValue;
        const isNumber = typeof sv === 'number' && typeof ev === 'number';
        if (sv >= 0 && ev >= 0 && val.startValue >= 0 && val.endValue >= 0 && isNumber) {
          if (
            (
              ((sv < val.startValue) && (ev > val.endValue)) ||
              ((sv < val.startValue) && (ev > val.startValue) && (ev < val.endValue)) ||
              ((sv > val.startValue) && (ev < val.endValue)) ||
              ((sv >= val.startValue) && (sv < val.endValue) && (ev > val.endValue)) ||
              ((sv >= val.startValue) && (sv < val.endValue) && (ev < val.endValue)) ||
              (ev === val.endValue)
            ) && (index !== idx)
          ) {
            this.longTableForm.longTable[index].startValue = '';
            this.longTableForm.longTable[index].endValue = '';
            this.$message({
              message: this.$t('collectionCenter.intervalDataOverlaps'), // 区间段数据维护重叠，请检查后重新维护
              type: 'error',
              offset: 350
            });
          }
        }
      });
      const az = /[a-z]/ig;
      if (!az.test(row.formula)) {
        return;
      }
      const chargePattern = this.submitForm.chargePattern ? this.submitForm.chargePattern : 0;
      // 还需加入计费模式选择校验并替换为对应的字母
      if (chargePattern === 0) {
        row.formula = row.formula.replace(/[a-z]/ig, 'd');
      }
      if (chargePattern === 1) {
        row.formula = row.formula.replace(/[a-z]/ig, 'p');
      }
      if (chargePattern === 2) {
        row.formula = row.formula.replace(/[a-z]/ig, 'w');
      }
    },
    addRow() {
      // 新增加一行
      const addLongTable = {
        startValue: '', endValue: '', formula: '', remark: ''
      };
      this.longTableForm.longTable.push(addLongTable);
    },
    delRow(row, index) {
      this.longTableForm.longTable.splice(index, 1);
    },
    defaultCondition() { // 默认查询条件
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.queryForm.params = {
        startTime: today + ' 00:00:00', // 开始时间
        // endTime: today + ' 23:59:59', // 结束时间
        customerId: '',
        chargePattern: '',
        businessType: '',
        belongToSiteId: ''
      };
    },
    resetClick() { // 重置
      this.defaultCondition();
    },
    getPage() { // 表格
      const queryForm = JSON.parse(localStorage.getItem('qmQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      collectCostCalculationPage(queryForm).then(res => {
        if (res.code===200 && res.data.records.length > 0) {
          this.total = res.data.total;
          this.tableDate = res.data.records;
          this.rowClick(this.tableDate[0]);
          // 默认高亮第一行
          this.$refs.tableRef.setCurrentRow(this.tableDate[0]);
          this.chooseRowList[0] = this.tableDate[0];
        } else {
          this.total = 0;
          this.tableDate = [];
          this.isShowSubmitForm = false;
          this.btnDisablad = {
            searBtn: false,
            resBtn: false,
            tryBtn: false,
            delBtn: false,
            addBtn: false,
            editBtn: true,
            saveBtn: true,
            cancelBtn: true
          };
        }
      });
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('qmQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      collectCostCalculationPage(queryForm).then(res => {
        if (res.code===200 && res.data.records.length > 0) {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        } else {
          this.total = 0;
          this.tableDate = [];
        }
      });
    },
    searchClick() {
      if (!this.queryForm.params.startTime) { // 请选择日期时间
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      } else {
        this.queryForm.pageNumber = 1;
        localStorage.setItem('qmQueryForm', JSON.stringify(this.queryForm));
        // 查询
        this.getPage();
      }
    },
    delClick(row) { // 删除
      if (this.delIdList.length > 0) {
        //                    '确定删除吗？'                           '提示'
        this.$confirm(this.$t('collectionCenter.deleteSure'), this.$t('collectionCenter.tips'), {
          confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
          cancelButtonText: this.$t('collectionCenter.cancel') // '取消'
        }).then(() => {
          const idForm = {
            idList: this.delIdList
          };
          delCollectCostCalculation(idForm).then(res => {
            if (res.code===200) {
              this.searchClick();
            }
          });
        }).catch(() => {
          //
        });
      } else {
        this.$message.warning(this.$t('collectionCenter.selectData'));
      }
    },
    batchSelect(selection, row) {
      this.btnDisablad = {
        searBtn: false,
        resBtn: false,
        tryBtn: false,
        delBtn: false,
        addBtn: false,
        saveBtn: true,
        editBtn: false,
        cancelBtn: true
      };
      this.chooseRowList = selection;
      this.delIdList = [];
      selection.forEach(list => {
        this.delIdList.push(list.id);
      });
    },
    batchSelectAll(selection) {
      this.btnDisablad = {
        searBtn: false,
        resBtn: false,
        tryBtn: false,
        delBtn: false,
        addBtn: false,
        saveBtn: true,
        editBtn: false,
        cancelBtn: true
      };
      this.chooseRowList = selection;
      this.delIdList = [];
      selection.forEach(list => {
        this.delIdList.push(list.id);
      });
    },

    // 试算 ----------------------------start
    tryCalculationClick() {
      this.drawerIsOpen = true;
      this.drawerTitle = this.$t('collectionCenter.trialCalculation'); // '试算'
      this.tryCDrawerForm = {
        customerId: '',
        divisionId: '',
        businessType: '',
        siteId: '',
        chargePattern: 0,
        value: '',
        collectSiteId: ''
      };
      this.chooseSite = {};
      this.sendArea();
      this.$nextTick(() => {
        this.$refs['tryCDrawerFormRef'].clearValidate();
      });
    },
    openSiteDialog() {
      this.trySiteVisible = true;
      this.searchSiteTry();
    },
    searchSiteTry() {
      const stForm = {
        nameCode: this.siteDialogForm.name || ''
      };
      getSiteTree(stForm).then(res => {
        if (res.code===200 && res.data) {
          let currentNodekey = '';
          if (!this.siteDialogForm.name) {
            this.treeData = res.data;
            // 默认选中
            currentNodekey = this.chooseSite.id || '';
          } else {
            this.chooseSite = {
              code: res.data[0].code,
              name: res.data[0].name,
              id: res.data[0].id
            };
            currentNodekey = res.data[0].id;
          }
          // 不做单独查询，高亮并定位到需查询网点位置
          this.currentNodekey = currentNodekey;
          this.$refs.trySiteRef.setCurrentKey(this.currentNodekey);
          this.$nextTick(() => {
            this.$refs.trySiteRef.setCurrentKey(this.currentNodekey);
          });
          const selected = this.$refs.trySiteRef.getCurrentNode();
          // 有父节点就展开
          if (this.$refs.trySiteRef.getNode(selected) && this.$refs.trySiteRef.getNode(selected).parent) {
            this.expandParents(this.$refs.trySiteRef.getNode(selected).parent);
          }
          setTimeout(() => {
            // 滑轮位置让高亮节点显示在框内（具体框内哪个位置只需更改 siteDom.offsetTop - 500 的数字）
            const siteDom = document.querySelector('.el-tree-node.is-current');
            const domScroll = document.querySelector('.trySiteClass');
            const movePX = siteDom.offsetTop > 360 ? siteDom.offsetTop - 150 : 0;
            domScroll.scrollTop = movePX;
          }, 300);
        }
      }).catch({});
    },
    treeCheck(node, list) { // 网点选中值
      this.chooseSite = {
        code: node.code,
        name: node.name,
        id: node.id
      };
    },
    trySiteSaveClick() {
      this.tryCDrawerForm.collectSiteId = this.chooseSite.name;
      this.trySiteVisible = false;
    },
    tryCloseDialog() {
      this.tryCDrawerData = '';
    },
    trySave() {
      this.$refs['tryCDrawerFormRef'].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          const tryCDrawerForm = {
            customerId: this.tryCDrawerForm.customerId,
            divisionId: this.tryCDrawerForm.divisionId,
            businessType: this.tryCDrawerForm.businessType,
            siteId: this.tryCDrawerForm.siteId,
            chargePattern: this.tryCDrawerForm.chargePattern,
            value: this.tryCDrawerForm.value,
            collectSiteId: this.chooseSite.id
          };
          trial(tryCDrawerForm).then(res => {
            if (res.code===200) {
              this.tryCDrawerData = res.data;
            }
          });
        }
      });
    },
    tryCancel() {
      this.drawerIsOpen = false;
      this.$refs['tryCDrawerFormRef'].clearValidate();
    },
    // 试算 -----------------------------end
    cancelClick() { // 取消操作
      this.btnDisablad = {
        searBtn: false,
        resBtn: false,
        tryBtn: false,
        delBtn: false,
        addBtn: false,
        saveBtn: true,
        editBtn: true,
        cancelBtn: true
      };
      this.isShowSubmitForm = false;
      this.findDisable = false;
      this.$refs.tableRef.setCurrentRow(-1);
      this.$refs.tableRef.clearSelection();
    },
    editClick() {
      if (this.chooseRowList.length > 1) {
        this.$message.warning(this.$t('collectionCenter.supportModifyData')); // '仅支持修改一条数据'
        return;
      }
      if (this.chooseRowList.length === 1) {
        this.submitForm = {
          id: this.chooseRowList[0].id,
          collectSite: this.chooseRowList[0].collectSite ? JSON.parse(this.chooseRowList[0].collectSite) : '', // 揽收网点
          customerId: this.chooseRowList[0].customerId, // 客户id
          areaName: this.chooseRowList[0].areaName ? JSON.parse(this.chooseRowList[0].areaName) : '', // 区域名称
          belongToSiteId: this.chooseRowList[0].belongToSiteId, // 所属网点
          businessType: this.chooseRowList[0].businessType, // 业务类型:2:pick-up(上门揽收), 1:drop-off(客户上门交件)
          chargePattern: this.chooseRowList[0].chargePattern, // 计费模式:0:距离,1:票数,2:重量
          minimumCharge: this.chooseRowList[0].minimumCharge, // 最低费用
          enableFlag: this.chooseRowList[0].enableFlag, // 是否启用:0:启用,1:停用
          startTime: this.chooseRowList[0].startTime, // 有效起始时间
          endTime: this.chooseRowList[0].endTime, // 结束起始时间
          calculationFormula: this.chooseRowList[0].calculationFormula ? JSON.parse(this.chooseRowList[0].calculationFormula) : '', // 计算公式
          remark: this.chooseRowList[0].remark // 备注
        };
        // 寄件区域
        this.zipcodeSelectList = [];
        this.zipcodeSelectList.push({
          areaName: this.chooseRowList[0].areaNameStr
        });
        // 揽收网点
        this.selectedSiteList = this.submitForm.collectSite;
        // 计费模式表
        this.longTableForm.longTable = this.submitForm.calculationFormula;
      }
      this.isShowSubmitForm = true;
      this.findDisable = false;
      this.btnDisablad = {
        searBtn: true,
        resBtn: true,
        tryBtn: true,
        delBtn: true,
        addBtn: true,
        saveBtn: false,
        editBtn: true,
        cancelBtn: false
      };
      this.submitName = 'edit';
    },
    addClick() { // 新增
      this.findDisable = false;
      this.isShowSubmitForm = true;
      this.reset();
    },
    reset() {
      this.btnDisablad = {
        searBtn: true,
        resBtn: true,
        tryBtn: true,
        delBtn: true,
        addBtn: true,
        saveBtn: false,
        editBtn: true,
        cancelBtn: false
      };
      this.submitName = 'add';
      this.submitForm = { // 首页可编辑提交表
        collectSite: '', // 揽收网点
        customerId: '', // 客户id
        areaName: '', // 区域名称
        belongToSiteId: '', // 所属网点
        businessType: '', // 业务类型:2:pick-up(上门揽收), 1:drop-off(客户上门交件)
        chargePattern: '', // 计费模式:0:距离,1:票数,2:重量
        minimumCharge: '', // 最低费用
        enableFlag: 0, // 是否启用:0:启用,1:停用
        startTime: '', // 有效起始时间
        endTime: '', // 结束起始时间
        calculationFormula: '', // 计算公式
        remark: '' // 备注
      };
      this.zipcodeSelectList = [];
      this.selectedSiteList = [];
      this.longTableForm.longTable = [
        { startValue: '', endValue: '', formula: '', remark: '' }
      ];
      this.$nextTick(() => {
        this.$refs['submitRef'].clearValidate();
        this.$refs['submitFormRef'].clearValidate();
      });
    },
    submitChangeDate(val) {
      if ((this.submitForm.startTime && this.submitForm.endTime) && (this.submitForm.startTime > this.submitForm.endTime)) {
        this.$message({
          // 开始时间不能大于结束时间
          message: this.$t('collectionCenter.startThanEnd'),
          type: 'warning'
        });
        const d = new Date();
        const today = dayjs(d).format('YYYY-MM-DD');
        this.submitForm.startTime = today + ' 00:00:00';
        this.submitForm.endTime = today + ' 23:59:59';
      }
    },
    saveClick() { // 保存
      if (!this.isShowSubmitForm) {
        this.btnDisablad = {
          searBtn: false,
          resBtn: false,
          tryBtn: false,
          delBtn: false,
          addBtn: false,
          saveBtn: false,
          editBtn: false,
          cancelBtn: false
        };
      }
      this.clickSavaBtn = true;
      this.submitForm.calculationFormula = JSON.stringify(this.longTableForm.longTable);
      this.submitForm.collectSite = JSON.stringify(this.selectedSiteList);
      this.submitForm.areaName = this.submitForm.areaName instanceof Array ? JSON.stringify(this.submitForm.areaName) : this.submitForm.areaName;
      // 校验表格填写
      let tableOK = false;
      this.$refs['submitRef'].validate((tableValid) => {
        if (!tableValid) {
          tableOK = false;
          return false;
        } else {
          tableOK = true;
        }
      });
      this.$refs['submitFormRef'].validate((valid) => {
        if (!valid) {
          return false;
        } if (this.zipcodeSelectList.length <= 0 && this.selectedSiteList.length <= 0) {
          return false;
        } else if (!tableOK) {
          return false;
        } else {
          if (this.submitName === 'add') {
            collectCostCalculation(this.submitForm).then(res => {
              if (res.code===200) {
                this.searchClick();
                this.isShowSubmitForm = false;
                this.reset();
                this.clickSavaBtn = false;
              }
            });
          }
          if (this.submitName === 'edit') {
            collectCostCalculationEdit(this.submitForm).then(res => {
              if (res.code===200) {
                this.searchClick();
                this.isShowSubmitForm = false;
                this.reset();
                this.clickSavaBtn = false;
              }
            });
          }
        }
      });
    },
    chooseListV(value) {
      if (this.findDisable) return;
      this.openTheDialog = value;
      this.chooseListVisible = true;
      this.chooseTitle = value === 'send' ? this.$t('collectionCenter.mailingAreaSelection') : value === 'site' ? this.$t('collectionCenter.selectReceivingOutlets') : ''; // send 寄件区域选择  site 揽收网点选择
      if (value === 'send') {
        this.sendArea();
      }
      if (value === 'site') {
        this.siteTree();
      }
    },
    // 寄件区域数据 ----------------------------start
    sendArea() { // 点击获取寄件区域
      const zipCodeForm = {
        areaName: this.zipcodeDrawerForm.name
      };
      zipCodeQueryData(zipCodeForm).then(res => {
        if (res.code===200) {
          this.zipcodeTableDate = res.data;
          this.tryZipcodeTableDate = res.data.map((li) => {
            return {
              areaName: li.areaName,
              id: li.id
            };
          });
          // 默认选中
          const selectArea = [];
          let zipcodeToArray = [];
          this.zipcodeSelectList.forEach(li1 => {
            if (li1.areaName.indexOf(',') === -1) {
              zipcodeToArray.push(li1.areaName);
            } else {
              zipcodeToArray = li1.areaName.split(',');
            }
          });
          const zipcodeSelectList1 = zipcodeToArray.map(value => {
            return {
              areaName: value
            };
          });
          res.data.forEach(item => {
            zipcodeSelectList1.forEach(val => {
              if (val.areaName === item.areaName) {
                selectArea.push(item);
              }
            });
          });
          if (selectArea) {
            selectArea.filter(row => {
              setTimeout(() => {
                this.$refs.zipcodeRef.toggleRowSelection(row, true);
              }, 0);
            });
          }
        }
      });
    },
    chooseSearchClick() { // 打开弹窗后——查询寄件区域数据
      const zipCodeForm = {
        areaName: this.zipcodeDrawerForm.name
      };
      zipCodeQueryData(zipCodeForm).then(res => {
        this.zipcodeTableDate = res.data;
      });
    },
    // 寄件区域——全选、多选
    allSelect(list) {
      const areaName = [];
      list.forEach(li => {
        areaName.push({
          id: li.id,
          areaName: li.areaName
        });
      });
      this.submitFormAreaName = JSON.stringify(areaName);
    },
    zipcodeBatchSelect(selection, row) {
      this.allSelect(selection);
    },
    zipcodeBatchSelectAll(selection) {
      this.allSelect(selection);
    },
    saveZipCodeClick() {
      this.zipcodeSelectList = this.$refs.zipcodeRef.selection;
      if (this.zipcodeSelectList.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.checkMsg')); // 请勾选数据
        return;
      } else {
        this.chooseListVisible = false;
        this.submitForm.areaName = this.submitFormAreaName;
      }
    },
    // 寄件区域数据 -----------------------------end

    // 揽收网点选择 ----------------------------start
    siteTree() { // 获取揽收网点结构树
      this.highlightCurrent = false;
      const stForm = {
        nameCode: this.siteDrawerForm.name
      };
      getSiteTree(stForm).then(res => {
        if (res.code===200 && res.data) {
          this.treeData = res.data;
          // 默认选中
          const defaultChecked = [];
          this.selectedSiteList.forEach(z => {
            defaultChecked.push(z.id);
          });
          this.defaultChecked = defaultChecked;
        }
      }).catch({});
    },
    searchSite() {
      const stForm = {
        nameCode: this.siteDrawerForm.name
      };
      getSiteTree(stForm).then(res => {
        if (res.code===200 && res.data) {
          // this.treeData = res.data;
          // 不做单独查询，高亮并定位到需查询网点位置
          this.highlightCurrent = true;
          this.currentNodekey = res.data[0].id;
          this.$refs.siteRef.setCurrentKey(this.currentNodekey);
          const selected = this.$refs.siteRef.getCurrentNode();
          // 有父节点就展开
          if (this.$refs.siteRef.getNode(selected) && this.$refs.siteRef.getNode(selected).parent) {
            this.expandParents(this.$refs.siteRef.getNode(selected).parent);
          }
          setTimeout(() => {
            // 滑轮位置让高亮节点显示在框内（具体框内哪个位置只需更改 siteDom.offsetTop - 500 的数字）
            const siteDom = document.querySelector('.el-tree-node.is-current');
            const domScroll = document.querySelector('.highlightClass');
            const movePX = siteDom.offsetTop > 360 ? siteDom.offsetTop - 150 : 0;
            domScroll.scrollTop = movePX;
          }, 300);
        }
      }).catch(err => {
        this.$message.warning(this.$t('collectionCenter.NoDataWasQueried')); // 未查询到数据
        console.error(err);
        this.highlightCurrent = false;
        this.currentNodekey = '';
      });
    },
    // 展开所有节点
    expandParents(node) {
      node.expanded = true;
      if (node.parent) {
        this.expandParents(node.parent);
      }
    },
    searchSiteClick() { // 打开弹窗后——选中节点
      const selectedSite = this.$refs.siteRef.getCheckedNodes();
      if (selectedSite.length <= 0) {
        this.selectedSiteList = [];
        this.submitForm.collectSite = '[]';
        this.$message.warning(this.$t('collectionCenter.checkMsg')); // 请勾选数据
      } else {
        this.selectedSiteList = selectedSite.map(item => {
          return {
            id: item.id,
            name: item.name
          };
        }); // 展示给用户
        this.submitForm.collectSite = JSON.stringify(selectedSite); // 提交
        this.chooseListVisible = false;
        this.siteDrawerForm.name = '';
      }
    },
    searchSiteClose() {
      this.closeDialog();
      this.chooseListVisible = false;
    },
    searchZipCodeClose() {
      this.closeDialog();
      this.chooseListVisible = false;
    },
    closeDialog() {
      if (this.openTheDialog === 'send') {
        this.zipcodeDrawerForm.name = '';
        this.$refs.zipcodeRef.clearSelection();
      } else {
        this.siteDrawerForm.name = '';
      }
      this.zipcodeSelectList = this.submitForm.areaName.length > 0 ? this.zipcodeSelectList : [];
      this.submitFormAreaName = '';
      this.zipcodeDrawerForm.name = '';
      this.siteDrawerForm.name = '';
    }
    // 揽收网点选择 -----------------------------end
  }
};
</script>

<style lang="scss" scoped>
  ::v-deep .el-tree-node{
    width: fit-content;
    min-width: 100%;
  }
  .zipcodeTableClass ::v-deep .el-table__body tr.current-row>td {
    color: #fff;
    background: #409EFF!important;
  }
  .findClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .findClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .findClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
    width: 100%;
  }

  .highlightClass ::v-deep .el-tree--highlight-current .is-current.el-tree-node > .el-tree-node__content {
    background-color: #6b849f !important;
    color: white;
  }
  .currentRowClass ::v-deep .el-table__body tr.current-row>td{
    background-color: #9fc5ed;
  }
  .disabledClass ::v-deep .el-input.is-disabled .el-input__inner{
    background-color: #fff;
  }
  .paramsStyle{
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .delRowBtnClass{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
.zipcodeTableClass ::v-deep .el-table__body tr.current-row>td {
  color: #fff;
  background: #409EFF!important;
}
.findClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.findClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.findClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
  width: 100%;
}
.highlightClass ::v-deep .el-tree--highlight-current .is-current.el-tree-node > .el-tree-node__content {
  background-color: #6b849f !important;
  color: white;
}
.currentRowClass ::v-deep .el-table__body tr.current-row>td{
  background-color: #9fc5ed;
}
.disabledClass ::v-deep .el-input.is-disabled .el-input__inner{
  background-color: #fff;
}
.paramsStyle{
  width: 100%;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.delRowBtnClass{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

  .trySiteClass ::v-deep .el-tree--highlight-current .is-current.el-tree-node > .el-tree-node__content {
    background-color: #409eff !important;
    color: white;
  }
  .tryResultBoxClass{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
    span{
      display: inline-block;
      color: #606266;
      font-weight: bold;
      font-size: 15px;
    }
    .tryResult{
      font-weight: normal;
      width: 80%;
      height: 50px;
      border: 1px solid #DCDFE6;
      border-radius: 4px;
      color: #606266;
      text-align: center;
      box-sizing: border-box;
      line-height: 50px;
    }
  }
  .chargePatternClass ::v-deep .el-form-item__content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .chargePatternClass ::v-deep .el-form-item__content .el-select{
    width: 120px;
  }
  .chargePatternClass ::v-deep .el-form-item__content .el-select .el-input__inner{
    border: none;
  }
  .redClass{
    display: inline-block;
    position: relative;
    padding-left: 10px;
  }
  .redClass::before {
    position: absolute;
    margin-left: -10px;
    content: '*';
    color: #F56C6C;
  }
  .areaRedClass{
    display: inline;
    color: #F56C6C;
  }
  .hideClass{
    display: none;
  }
  .areaClass{
    width: 100%;
    height: 210px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    padding: 0 5px;
    box-sizing: border-box;
    color: #606266;
    line-height: 25px;
    overflow: auto;
  }
.trySiteClass ::v-deep .el-tree--highlight-current .is-current.el-tree-node > .el-tree-node__content {
  background-color: #409eff !important;
  color: white;
}
.tryResultBoxClass{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  span{
    display: inline-block;
    color: #606266;
    font-weight: bold;
    font-size: 15px;
  }
  .tryResult{
    font-weight: normal;
    width: 80%;
    height: 50px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    color: #606266;
    text-align: center;
    box-sizing: border-box;
    line-height: 50px;
  }
}
.chargePatternClass ::v-deep .el-form-item__content{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.chargePatternClass ::v-deep .el-form-item__content .el-select{
  width: 120px;
}
.chargePatternClass ::v-deep .el-form-item__content .el-select .el-input__inner{
  border: none;
}
.redClass{
  display: inline-block;
  position: relative;
  padding-left: 10px;
}
.redClass::before {
  position: absolute;
  margin-left: -10px;
  content: '*';
  color: #F56C6C;
}
.areaRedClass{
  display: inline;
  color: #F56C6C;
}
.hideClass{
  display: none;
}
.areaClass{
  width: 100%;
  height: 210px;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  padding: 0 5px;
  box-sizing: border-box;
  color: #606266;
  line-height: 25px;
  overflow: auto;
}

  // ::v-deep .el-divider{
  //   background-color: #808080;
  // }
  .scoll {
    overflow: auto;
    min-width: 100px;
    max-width: 500px;
    margin: 0 auto 20px;
    max-height: 400px;
  }
  .zipcodeTableClass{
    margin: 30px 50px;
  }
  .dialog-footer{
    text-align: center;
    display: block;
  }
  .searchClass{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 17px;
  }
  // .searchClass ::v-deep .el-textarea{
  //   width: 60%;
  //   margin-right: 10px;
  // }
  // .searchClass ::v-deep .el-textarea__inner{
  //   max-height: 200px;
  // }
  .searchClass ::v-deep .el-input{
    width: 60%;
  }
  .dateChooseClass ::v-deep .el-date-editor.el-input, .dateChooseClass ::v-deep .el-date-editor.el-input__inner{
    width: 45%;
  }

  .bRClass{
    padding-right: 5px;
    box-sizing: border-box;
  }
  .modelTableClass ::v-deep .el-table td, .modelTableClass ::v-deep .el-table th{
    padding: 2px 0 !important;
  }
  .modelTableClass ::v-deep .el-input--suffix .el-input__inner{
    text-align: center;
    padding-right: 12px !important;
    padding-left: 2px !important;
  }
  .modelTableClass ::v-deep .el-input__suffix{
    right: 0;
    background: rgba(0,0,0,0.08);
    border-radius: 0 5px 5px 0;
  }
  .modelTableClass ::v-deep .el-table .cell{
    padding: 0 5px !important;
  }
  .modelTableClass ::v-deep .el-form-item{
    margin-bottom: 0;
  }
  .modelTableClass{
    padding-left: 5px;
    box-sizing: border-box;
    .captionClass{
      border: 1px solid #dcdfe6;
      border-bottom: none;
      padding: 10px 5px;
      box-sizing: border-box;
      div{
        font-size: 15px;
        line-height: 20px;
        color: #909399;
      }
      .captionRed{
        color: #db0d27;
      }
    }
  }

  .flexStart ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // overflow: hidden;
  }
  .flexStart ::v-deep .el-form-item__label{
    min-width: 120px;
    max-width: 130px;
    padding: 0;
    line-height: 16px;
  }
  .lineH ::v-deep .el-form-item__label{
    line-height: 16px;
  }
  .qMBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .qMBox ::v-deep .el-select{
    width: 100%;
  }

  .findClass ::v-deep .el-form-item__label{
    padding: 0;
  }
  .qMBox .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }

  .submitClickBtn{
    text-align: end;
    margin-top: 30px;
  }
  .tryC.submitClickBtn{
    text-align: center;
  }
  .drawerClass{
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 50px;
  }

</style>
