var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { padding: "10px 20px", "font-weight": "bold" } },
      [
        _vm._v(
          " " + _vm._s(_vm.$t("GlobalSetObj.SortingMachineSignboard")) + " "
        ),
      ]
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
          "margin-top": "50px",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "font-size": "32px", "font-weight": "bold" } },
          [
            _vm._v(
              _vm._s(_vm.$t("GlobalSetObj.Total")) +
                "：" +
                _vm._s(_vm.total.toLocaleString("en-US"))
            ),
          ]
        ),
        _c(
          "span",
          { staticStyle: { "font-size": "18px", "margin-left": "30px" } },
          [
            _vm._v(
              _vm._s(_vm.$t("GlobalSetObj.DataStatisticsTime")) +
                "：" +
                _vm._s(_vm.updateTime)
            ),
          ]
        ),
      ]
    ),
    _c("div", {
      ref: "echartsCont",
      staticStyle: {
        width: "100%",
        height: "calc(100vh - 360px)",
        "margin-top": "50px",
      },
      attrs: { id: "echartsCont" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }