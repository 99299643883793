<template>
  <div class="app-container">
    <div style="position:relative;">
      <el-form
        ref="form" :model="queryForm" label-width="120px"
        size="mini"
      >

        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="所属平台：">
              <el-select
                v-model="queryForm.params.platformCode"
                placeholder="请选择"
                style="width: 100%;"
              >
                <el-option
                  label="LHEV"
                  value="lhev"
                />
                <el-option
                  label="K3"
                  value="K3"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="消息状态：">
              <el-select
                v-model="queryForm.params.status"
                placeholder="请选择"
                style="width: 100%;"
              >
                <el-option
                  label="Success"
                  :value="1"
                />
                <el-option
                  label="Failure"
                  :value="0"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item>
              <template slot="label">
                <el-select v-model="orderType" placeholder="请选择">
                  <!--运单号-->
                  <el-option key="1" label="运单号" :value="1" />
                  <!--客户订单号-->
                  <el-option key="2" label="客户订单号" :value="2" />
                </el-select>
              </template>
              <el-input
                v-model="orderNumber"
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建时间：">
              <el-date-picker
                v-model="queryDate"
                type="datetimerange"
                range-separator="至"
                value-format="yyyy-MM-dd HH:mm:ss"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="margin-top:10px;">
        <el-button
          type="primary" size="small" icon="el-icon-search"
          @click="getSearch(1)"
        >查询</el-button>
        <el-divider />
      </div>
    </div>
    <el-card class="box-card">
      <!--  表格数据  -->
      <el-table
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="waybillNumber"
          label="运单号"
        />
        <el-table-column
          prop="customerNumber"
          label="客户订单号"
          width="180"
        />
        <!--        <el-table-column-->
        <!--            prop="requestAddress"-->
        <!--            label="发送方URL">-->
        <!--        </el-table-column>-->
        <el-table-column
          prop="requestParams"
          label="接收报文"
        >
          <template slot-scope="scope">
            <el-popover
              placement="top"
              popper-class="copy"
              trigger="hover"
            >
              <el-button
                style="color: black;font-size: 12px;padding: 2px"
                type="text"
                @click="copy(scope.row.requestParams)"
              >
                {{ $t('orderDetails.copy') }}
              </el-button>
              <span slot="reference" class="omit">{{ scope.row.requestParams }}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="responseParams"
          label="响应报文"
        >
          <template slot-scope="scope">
            <el-popover
              placement="top"
              popper-class="copy"
              trigger="hover"
            >
              <el-button
                style="color: black;font-size: 12px;padding: 2px"
                type="text"
                @click="copy(scope.row.responseParams)"
              >
                {{ $t('orderDetails.copy') }}
              </el-button>
              <span slot="reference" class="omit">{{ scope.row.responseParams }}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
        />
        <el-table-column
          prop="pushStatus"
          label="消息状态"
        >
          <template slot-scope="scope">
            {{ scope.row.status==1 ? 'Success' : 'Failure' }}
          </template>
        </el-table-column>
      </el-table>
      <!--  分页  -->
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0;float: right"
        @size-change="getSearch"
        @current-change="getSearch"
      />
    </el-card>
  </div>
</template>
<script>
import { trackAcceptRecordPage } from '@/api/trackQuery';
import { copyData } from '@/utils/copyData';
// import moment from 'moment/moment';
// import 'moment-timezone';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  data() {
    return {
      isTormat: false, // 是否格式化成巴西时间
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          platformCode: 'lhev',
          status: 1
        }
      },
      queryDate: null, // 创建时间
      // 订单类型
      orderType: 1, // 1：运单 2：客户订单号
      orderNumber: '', // 运单号 or 客户订单号输入值
      tableData: [], // 表格数据
      total: 0
    };
  },
  created() {
    // 默认查询时间改为巴西时间
    const brDate = dayjs().tz('America/Sao_Paulo').format('YYYY-MM-DD');
    this.queryDate = [brDate + ' 00:00:00', brDate + ' 23:59:59'];
    // 进入查询数据
    this.getSearch();
  },
  methods: {
    /**
     * @description 查询
     */
    getSearch(find) {
      if (find === 1) {
        this.queryForm.pageNumber = 1;
      }
      const param = {
        ...this.queryForm,
        params: {
          ...this.queryForm.params
        }
      };
      if (this.queryDate && this.queryDate.length) {
        param.params.beginTime = this.queryDate[0];
        param.params.endTime = this.queryDate[1];
      }
      // this.orderNumber去除回车和空格，回车替换成,号
      param.params[this.orderType === 1 ? 'waybillNumbers' : 'customerNumbers'] = this.orderNumber.replace(/[\r\n]/g, ',').replace(/\s+/g, '');
      trackAcceptRecordPage(param)
        .then((res) => {
          if (res.code===200) {
            this.tableData = res.data.records;
            this.total = res.data.total;
          }
        });
    },
    // 点击复制
    copy(data) {
      copyData(data);
    }
  }
};
</script>

<style scoped lang="scss">
.omit{
  // 限制显示四行，多出显示省略号
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.copy{
  display: flex;
  padding: 0;
  font-size: 12px;
}
</style>
