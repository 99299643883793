import i18n from '@/lang';

export const TABLE_HEADER = [
  // 账单批次号
  { prop: 'billBatchNumber', label: i18n.t('receivablePayable.BillingBatchNumber'), width: 170, align: 'left', highlight: false, disabled: false },
  // 账单时间
  { prop: 'billingTime', label: i18n.t('receivablePayable.BillingDate'), width: 290, highlight: false, disabled: false },
  // 账单类型 i18n.t('collectionCenter.TypeBill')
  { prop: 'payableType', label: i18n.t('collectionCenter.TypeBill'), width: 200, align: 'center', highlight: false, disabled: false },
  // 实际收款人
  { prop: 'actualRecipients', label: i18n.t('basicData.实际收款人'), width: 200, align: 'center', highlight: false, disabled: false },
  // 账单状态
  { prop: 'billStatusValue', label: i18n.t('receivablePayable.BillingStatus'), width: 160, align: 'left', highlight: false, disabled: false },
  // 是否请款
  { prop: 'requestPaymentStatus', label: i18n.t('receivablePayable.areYouRequestingPayment'), width: 160, align: 'left', highlight: false, disabled: false },
  // 付款主体名称
  { prop: 'providerName', label: i18n.t('GlobalSetObj.SubjectPayment'), width: 160, align: 'left', highlight: false, disabled: false },
  // 网点名称
  { prop: 'siteName', label: i18n.t('basicData.siteName'), width: 160, align: 'left', highlight: false, disabled: false },
  // 业务类型
  { prop: 'businessType', label: i18n.t('collectionCenter.businessT'), width: 160, align: 'left', highlight: true, disabled: false },
  // 订单数量
  { prop: 'orderQty', label: i18n.t('receivablePayable.TheNumberOfOrders'), width: 160, align: 'left', highlight: true, disabled: false },
  // 费用条数
  { prop: 'feeQty', label: i18n.t('receivablePayable.TheNumberOfFees'), width: 160, align: 'left', highlight: false, disabled: false },
  // 计费总重量（kg）
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.TotalBillableWeight'), width: 160, align: 'left', highlight: false, disabled: false },
  // 应付总金额
  { prop: 'totalReceivable', label: i18n.t('receivablePayable.TheTotalAmountDue'), width: 160, align: 'left', highlight: false, disabled: false },
  // 平均每票金额
  { prop: 'agvReceivable', label: i18n.t('receivablePayable.AverageAmountPerVote'), width: 160, align: 'left', highlight: false, disabled: false },
  // 生成人员
  { prop: 'createUserName', label: i18n.t('receivablePayable.BuildThePerson'), width: 160, align: 'left', highlight: false, disabled: false },
  // 生成时间
  { prop: 'createTime', label: i18n.t('receivablePayable.BuildTime'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核人员
  { prop: 'auditUserName', label: i18n.t('receivablePayable.Auditors'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核时间
  { prop: 'auditTime', label: i18n.t('receivablePayable.ReviewTime'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核原因
  { prop: 'auditRemark', label: i18n.t('receivablePayable.AuditReason'), width: 160, align: 'left', highlight: false, disabled: false }
];
