<template>
  <div>
    <div style="padding: 10px 20px;font-weight: bold;">
      <!--分拣机看板-->
      {{ $t('GlobalSetObj.SortingMachineSignboard') }}
    </div>
    <div style="display: flex;align-items: center;justify-content: center;margin-top: 50px;">
      <span style="font-size: 32px;font-weight: bold">{{ $t('GlobalSetObj.Total') }}：{{ total.toLocaleString('en-US') }}</span>
      <!--数据统计时间-->
      <span style="font-size: 18px;margin-left: 30px;">{{ $t('GlobalSetObj.DataStatisticsTime') }}：{{ updateTime }}</span>
    </div>
    <div id="echartsCont" ref="echartsCont" style="width: 100%; height: calc(100vh - 360px);margin-top: 50px;"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { getSortedRecordRecallBoard } from '@/api/logisticsOrders';
export default {
  name: 'SortingMachineSignboard',
  data() {
    return {
      myChart: null,
      chartsData: [],
      lineData: [],
      total: 0,
      updateTime: ''
    };
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById('echartsCont'));
    this.getChartsData();
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    // 获取数据
    getChartsData() {
      getSortedRecordRecallBoard().then(res => {
        if (res.code === 200) {
          this.updateTime = res.data.updateTime;
          this.total = Number(res.data.totalCount);
          if (res.data.tableNumberVOList && res.data.tableNumberVOList.length > 0) {
            this.chartsData = [];
            res.data.tableNumberVOList.map(item => {
              const data = {
                name: this.$t('GlobalSetObj.OperationDesk') +
                  (this.$i18n.locale !== 'zh' ? (' ' + this.$t('GlobalSetObj.No1') + item.tableNumber)
                    : (item.tableNumber + this.$t('GlobalSetObj.No1'))),
                value: item.count
              };
              this.chartsData.push(data);
            });
            this.lineData = [];
            const average = (this.total / this.chartsData.length).toFixed(2);
            this.chartsData.map(() => {
              const data = {
                name: this.$t('GlobalSetObj.Average'),
                value: average
              };
              this.lineData.push(data);
            });
            // 初始化图表
            this.initCharts();
          }
        }
      });
    },
    // 初始化图表
    initCharts() {
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none' // 折线图隐藏虚线
          },
          formatter: (params) => {
            // params 是一个数组，包含当前鼠标悬停的所有系列数据
            let tooltipHtml = '';
            params.forEach(param => {
              if (param.name.indexOf('-') !== -1) {
                const nameArr = param.name.split('-');
                const name = nameArr[0] + ' ' + nameArr[1];
                tooltipHtml += param.seriesIndex === 0 ? `<div>${name}：${param.value}</div>`
                  : `<div>${param.name}：${param.value}</div>`;
              } else {
                tooltipHtml += param.seriesIndex === 0 ? `<div>${param.name}：${param.value}</div>`
                  : `<div>${param.name}：${param.value}</div>`;
              }
            });
            return tooltipHtml;
          }
        },
        grid: {
          left: '3%',
          right: '5%',
          bottom: '3%',
          containLabel: true,
          borderWidth: 1
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true, // 柱状图的 x 轴
            data: this.chartsData.map(item => item.name),
            axisLine: {
              show: true, // 隐藏轴线
              lineStyle: {
                type: 'dashed', // 设置为虚线
                color: '#bbbbbb' // 设置颜色为黑色
              }
            },
            axisTick: {
              show: false // 隐藏分隔线
            },
            axisLabel: {
              // show: false, // 隐藏轴线
              interval: 0, // 强制显示所有刻度
              formatter: (value) => {
                // 使用 \n 进行换行
                return value.split('-').join('\n');
              },
              // rotate: -20, // 设置标签旋转角度为45度
              align: 'center',
              margin: 10,
              color: '#000000',
              fontSize: 14
            },
            emphasis: {
              axisTick: {
                show: false
              }
            }
          },
          {
            type: 'category',
            boundaryGap: false, // 折线图的 x 轴
            data: this.chartsData.map(item => item.name),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            emphasis: {
              axisTick: {
                show: false
              }
            }
          }
        ],
        yAxis: {
          type: 'value',
          splitLine: { // 设置Y轴的线条的颜色;
            lineStyle: {
              type: 'dashed',
              color: ['#bbbbbb']
            }
          },
          axisLabel: {
            margin: 20
          }
        },
        series: [
          {
            data: this.chartsData,
            type: 'bar',
            showBackground: false,
            barWidth: 50, // 设置柱子的宽度
            markLine: {
              data: [
                {
                  type: 'average', name: this.$t('GlobalSetObj.Average') // '平均值'
                }
              ],
              label: {
                color: 'rgba(255, 176, 25, 1)',
                fontSize: 14
              },
              lineStyle: {
                color: 'rgba(255, 176, 25, 1)',
                width: 2
              }
            },
            label: { // 添加 label 配置
              show: true,
              position: 'top',
              formatter: '{c}',
              color: 'rgba(53, 193, 103, 1)',
              fontSize: 16
            },
            itemStyle: {
              // color: 'rgba(53, 193, 103, 0.3)' // 设置柱子颜色为蓝色
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  { offset: 0, color: 'rgba(53, 193, 103, 0.8)' },
                  { offset: 1, color: 'rgba(53, 193, 103, 0.1)' }
                ]
              )
            },
            xAxisIndex: 0 // 关联到第一个 x 轴
          },
          {
            type: 'line',
            smooth: 0.6,
            symbol: 'none',
            symbolSize: 0, // 隐藏折线图的符号
            lineStyle: {
              color: 'rgba(255, 176, 25, 1)',
              width: 1,
              type: 'dashed'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  { offset: 0, color: 'rgba(255, 176, 25, 0.6)' }, // 渐变开始颜色
                  { offset: 1, color: 'rgba(255, 176, 25, 0)' } // 渐变结束颜色
                ]
              )
            },
            data: this.lineData,
            xAxisIndex: 1 // 关联到第二个 x 轴
          }
        ]
      };
      option && this.myChart.setOption(option, true);
    },
    // 重新绘制
    handleResize() {
      this.myChart.resize();
    }
  }
};
</script>

<style scoped>

</style>
