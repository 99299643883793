<template>
  <div>
    <div class="complaint-work-order">
      <div ref="messageListRef" class="message-content-bg">
        <div v-for="(item, index) in messageDetail" :key="index">
          <div v-if="item.createUser !== currentId" style="margin-bottom: 15px">
            <div style="text-align: left">
              <span style="margin-right: 10px; color: #409eff">{{ item.createUserName }}</span>
              <span>{{ item.createTime }}</span>
            </div>
            <div class="complaint-work-order-message-content" style="float: left">
              <div style="text-align: left" @click.stop="getImgSrc" v-html="item.message" />
              <div v-if="item.mediaUrl" style="margin-top: 10px">
                <div
                  v-for="(list, ind) in item.urlArr"
                  :key="ind"
                  class="complaint-work-file"
                  @click="getImgSrcFile(list.url)"
                >
                  {{ list.name }}
                </div>
              </div>
            </div>
            <div style="clear: both" />
          </div>
          <div v-else style="margin-bottom: 15px">
            <div style="text-align: right">
              <span style="margin-right: 10px; color: #409eff">{{ item.createUserName }}</span>
              <span>{{ item.createTime }}</span>
            </div>
            <div class="complaint-work-order-message-content" style="float: right">
              <div style="text-align: right" @click.stop="getImgSrc" v-html="item.message" />
              <div v-if="item.mediaUrl" style="margin-top: 10px">
                <div
                  v-for="(list, ind) in item.urlArr"
                  :key="ind"
                  class="complaint-work-file"
                  @click="getImgSrcFile(list.url)"
                >
                  {{ list.name }}
                </div>
              </div>
            </div>
            <div style="clear: both" />
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 10px">
      <!--      <div class="files-update-complaint-work" style="margin-bottom: 10px;">-->
      <!--        <el-upload-->
      <!--          :accept="acceptStr"-->
      <!--          :before-upload="resumeBeforeAvatarUpload"-->
      <!--          :file-list="fileList"-->
      <!--          :http-request="resumeUpload"-->
      <!--          :multiple="false"-->
      <!--          :limit="1"-->
      <!--          :on-remove="resumeRemove"-->
      <!--          action-->
      <!--          class="avatar-uploader"-->
      <!--        >-->
      <!--          <el-button size="small" type="primary">{{ $t('basicData.UploadTheFile') }}</el-button>-->
      <!--        </el-upload>-->
      <!--      </div>-->
      <tinymce
        v-model="textarea"
        :height="200"
        :msg-plugin="msgPlugin"
        :placeholder="$t('GlobalSetObj.pleaseInputContent') + '...'"
        :toolbar="toolbar"
      />
    </div>
    <div style="padding: 10px; text-align: right">
      <el-button
        size="small" type="primary" @click="submitMsg"
      >{{ $t('GlobalSetObj.Reply') }} </el-button><!--回复-->
    </div>
    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="imgArr" />
  </div>
</template>

<script>
import Tinymce from '@/components/Tinymce/index';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { uploadOos } from '@/utils/ali_oos_util';
import { apiGetComplaintWorkGetMessage, apiGetComplaintWorkSendMessage } from '@/api/newExpressAPI';

export default {
  components: { Tinymce, ElImageViewer },
  props: {
    replyMessageData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      messageDetail: [],
      textarea: '',
      getIntervalMsg: null,
      showViewer: false,
      imgArr: [],
      currentId: this.$store.state.user.id,
      acceptStr: [
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/webp',
        'application/pdf',
        'text/plain',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ].join(','), // 上传附件类型
      fileList: [],
      msgPlugin: [''],
      toolbar: [''],
      getItem: 0
    };
  },
  mounted() {
    this.getMessageData();
    this.getIntervalMsg = setInterval(() => {
      this.getMessageData();
    }, 5000);
  },
  methods: {
    // 查看图片
    getImgSrcFile(url) {
      function getFileExtension(url) {
        // 使用正则表达式匹配最后一个点之后的部分作为文件扩展名
        const match = url.match(/\.([a-zA-Z0-9]+)(?:[\?#]|$)/);
        if (match) {
          return match[1].toLowerCase();
        }
        return '';
      }
      function isImage(extension) {
        // 定义常见的图片文件扩展名
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
        return imageExtensions.includes(extension);
      }
      const extension = getFileExtension(url);
      if (isImage(extension)) {
        this.imgArr = [url];
        this.showViewer = true;
      } else {
        window.open(url);
      }
    },
    // 查看图片
    getImgSrc(e) {
      if (e.target.nodeName !== 'IMG') {
        return;
      }
      this.imgArr = [e.target.src];
      this.showViewer = true;
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    // 上传前的控制
    resumeBeforeAvatarUpload(file) {
      const typeArr = [
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/webp',
        'application/pdf',
        'text/plain',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];
      const { type, size } = file;
      // const isPDF = type === 'application/pdf';
      // console.log(1);
      const isType = typeArr.includes(type);

      const isLt5M = size / 1024 / 1024 < 10;
      if (!isType) {
        //                 上传只能是                                                        格式!
        this.$message.error(
          ` ${this.$t('customerManagements.UploadOnlyBe')}${typeArr.join('/')}${this.$t(
            'customerManagements.format'
          )} `
        );
      }
      if (!isLt5M) {
        this.$message.error(this.$t('collectionCenter.uploadNot10MB')); // '上传大小不能超过 10MB!'
      }
      return isType && isLt5M;
    },
    // 上传
    resumeUpload(option) {
      const { file } = option;
      uploadOos(file, 'customer')
        .then((res) => {
          if (res.code === 200) {
            const obj = {
              name: file.name,
              url: res.url
            };
            this.fileList = [...this.fileList, obj];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 删除
    resumeRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList = fileList;
    },
    // 获取回复消息
    getMessageData() {
      const param = {
        pageNumber: 1,
        pageSize: 1000,
        params: {
          messageId: this.replyMessageData.id
        }
      };
      apiGetComplaintWorkGetMessage(param, false).then((res) => {
        if (res.code === 200) {
          this.messageDetail = res.data.records;
          if (this.getItem === 0) {
            this.scrollToBottom();
            this.getItem++;
          }
        }
      });
    },
    // 提交回复
    submitMsg() {
      if (this.textarea === '' && this.fileList.length === 0) {
        this.$message.warning(this.$t('GlobalSetObj.MessageContentAndFileCannotBeEmpty')); // 消息内容和上传文件不能同时为空
        return;
      }
      console.log(this.fileList, '===this.fileList');
      const fileArr = this.fileList.map((item) => {
        return item.url;
      });
      const param = {
        messageId: this.replyMessageData.id,
        message: this.textarea,
        mediaUrl: fileArr.join(';')
      };
      apiGetComplaintWorkSendMessage(param, false).then((res) => {
        if (res.code === 200) {
          this.getMessageData();
          this.textarea = '';
          this.fileList = [];
        }
      });
    },
    // 滚动到底部
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.messageListRef.scrollTo(0, this.$refs.messageListRef.scrollHeight);
      });
    }
  }
};
</script>

<style>
.message-content-bg {
  overflow-y: auto;
  background-color: #f5f5f5;
  padding: 15px;
  height: 100%;
  border-radius: 5px;
}

.message-content-bg img {
  width: 150px;
  height: 150px;
}

.complaint-work-order {
  height: calc(100vh - 406px);
  border-radius: 5px;
  padding: 10px;
}

.complaint-work-order-message-content {
  display: inline-block;
  padding: 15px;
  background: #fff;
  border-radius: 5px;
  max-width: 80%;
}

.complaint-work-order-message-content * {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.complaint-work-order-message-content p {
  margin: 0;
}

.complaint-work-order-message-content a:hover {
  color: #409eff;
  text-decoration: underline;
}

.files-update-complaint-work .el-upload-list {
  height: 35px;
  overflow-y: auto;
}

.complaint-work-file {
  line-height: 16px;
  cursor: pointer;
}

.complaint-work-file:hover {
  color: #409eff;
  text-decoration: underline;
}
</style>
