<template>
  <div>
    <el-tabs
      v-model="activeName"
      :style="{
        height: `${tableHeightRow.height}px`
      }" class="table-height" type="border-card"
    >

      <!-- 收件人信息 -->
      <el-tab-pane :label="$t('collectionCenter.recipientInformation')" name="1">
        <ReceiveCmp
          ref="receiveCmpRef"
          :disabled="!isEdit"
        />
      </el-tab-pane>

      <!-- 发件人信息 -->
      <el-tab-pane :label="$t('collectionCenter.SenderInformation')" name="2">
        <SenderCmp
          ref="senderRef"
          :disabled="!isEdit"
        />
      </el-tab-pane>

      <!-- 商品信息 -->
      <el-tab-pane :label="$t('orderCenterCont.ProductInformation')" name="3">
        <ProductCmp
          ref="productRef"
          :height="tableHeightRow.height"
          :disabled="!isEdit"
        />
      </el-tab-pane>

      <!-- 发票信息 -->
      <el-tab-pane :label="$t('newOrder.InvoiceInformation')" name="4">
        <InvoiceCmp
          ref="invoiceRef"
          :height="tableHeightRow.height"
        />
      </el-tab-pane>

    </el-tabs>

    <div class="footer">
      <span />
      <div>
        <el-button v-if="isEdit" type="primary" @click="submit">{{ $t('customerManagements.EditSave') }}</el-button>
        <el-button @click="cancel">{{ $t('customerManagements.cancel') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import ReceiveCmp from './ReceiveCmp.vue';
import SenderCmp from './SenderCmp.vue';
import ProductCmp from './ProductCmp.vue';
import InvoiceCmp from './InvoiceCmp.vue';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { getOrderInfo } from '@/api/logisticsOrders/index.js';
import { apiProviderOrderUpdateWaybillInfo } from '@/api/orderApi.js';

export default {
  name: '',

  components: {
    ReceiveCmp,
    SenderCmp,
    ProductCmp,
    InvoiceCmp
  },

  mixins: [estimateTableHeight],

  props: {
    // 订单id
    ids: {
      type: Number,
      required: true
    },

    // 能否编辑不能编辑
    isEdit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      activeName: '1',
      waybillId: 0, // 提交时需要
      orderId: 0, // 提交时需要
      totalDeclaredValue: 0,
      totalDeclaredWeight: 0
    };
  },

  watch: {
    ids: {
      handler(val) {
        this.activeName = '1';
        if (val) {
          this.asyncGetDetail(val);
        }
      },
      immediate: true
    }
  },

  created() {

  },

  methods: {

    // 获取详情
    asyncGetDetail(ids) {
      getOrderInfo(ids).then(res => {
        console.log(res);
        const {
          orderId,
          waybillId,
          totalDeclaredValue,
          totalDeclaredWeight,
          receiverInfo,
          senderInfo,
          waybillInvoiceVOList,
          orderItemList
        } = res.data;

        this.orderId = orderId;
        this.waybillId = waybillId;
        this.totalDeclaredValue = totalDeclaredValue;
        this.totalDeclaredWeight = totalDeclaredWeight;

        // 发件人
        this.$refs.senderRef.setData(senderInfo);

        // 收件人
        this.$refs.receiveCmpRef.setData(receiverInfo);

        // 商品信息
        this.$refs.productRef.setData(orderItemList);

        // 发票信息
        this.$refs.invoiceRef.setData(waybillInvoiceVOList);
      }).catch(err => {
        console.error(err);
      });
    },

    // 校验
    submitCheck() {
      const PleaseFillRequiredFieldsCorrectly = this.$t('customerManagements.PleaseFillRequiredFieldsCorrectly');
      const addresseeTips = this.$t('collectionCenter.recipientInformation') + '  ' + PleaseFillRequiredFieldsCorrectly;
      const addresserTips = this.$t('collectionCenter.SenderInformation') + '  ' + PleaseFillRequiredFieldsCorrectly;
      const productTips = this.$t('orderCenterCont.ProductInformation') + '  ' + PleaseFillRequiredFieldsCorrectly;

      // 收件人信息校验
      {
        console.log(this.$refs.addresseeRef);

        const isBool = this.$refs.receiveCmpRef.submit();
        if (!isBool) {
          this.$message.warning(addresseeTips);
          return false;
        }
      }

      // 发件人信息校验
      {
        const isBool = this.$refs.senderRef.submit();
        if (!isBool) {
          this.$message.warning(addresserTips);
          return false;
        }
      }

      // 商品信息校验
      {
        const isBool = this.$refs.productRef.submit();
        if (!isBool) {
          this.$message.warning(productTips);
          return false;
        }
      }

      // 发票信息 不用校验

      return true;
    },

    // 获取数据
    getData() {
      const { waybillId, orderId, totalDeclaredValue, totalDeclaredWeight } = this;
      // 获取数据
      const obj = {
        id: waybillId,
        waybillId,
        orderId,
        totalDeclaredValue,
        totalDeclaredWeight,
        senderInfo: {},
        receiverInfo: {},
        orderItemList: [],
        waybillInvoiceVOList: []
      };

      // 发件人
      obj.senderInfo = this.$refs.senderRef.getData();

      // 收件人
      obj.receiverInfo = this.$refs.receiveCmpRef.getData();

      // 商品信息
      obj.orderItemList = this.$refs.productRef.getData();

      // 发票信息
      obj.waybillInvoiceVOList = this.$refs.invoiceRef.getData();
      return obj;
    },

    // 保存
    submit() {
      const isBool = this.submitCheck();
      if (!isBool) {
        return;
      }

      const obj = this.getData();

      this.asyncApiProviderOrderUpdateWaybillInfo(obj);
    },

    // 更新
    asyncApiProviderOrderUpdateWaybillInfo(obj) {
      apiProviderOrderUpdateWaybillInfo(obj).then(res => {
        // console.log(res);

        this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));

        setTimeout(() => {
          this.cancel(true);
        }, 500);
      }).catch(err => {
        console.error(err);
      });
    },

    // 取消
    cancel(isBool = false) {
      this.$emit('cancel', isBool);
    }
  }

};
</script>

<style scoped>
.tabs {
  height: calc(100vh - 140px);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 20px; */
  padding: 10px 20px;
}
</style>
