<template>
  <div>
    <div style="height:50px">
      <el-button
        icon="el-icon-plus"
        plain
        style="float: right"
        @click="clickSenderDialog($t('customerManagements.createConsignee'),null)"
      >{{ $t('GlobalSetObj.create') }}
      </el-button>
    </div>
    <div class="addressClass">
      <div v-if="customerAddressList.length>0">
        <div
          v-for="(item,index) in customerAddressList"
          :key="item.id"
          class="addressItem"
          @mouseleave="hideDeleteButton(index)"
          @mouseover="showDeleteButton(index)"
        >
          <div>
            <span>{{ item.receiveName || '--' }} / {{ item.receiveMobile || '--' }} / {{
              '--'
            }} / {{ item.receiveZipcode || '--' }}</span>
          </div>
          <div>
            <span>{{ item.receiveStreet || '--' }} / {{ item.receiveHouseNumber || '--' }} / {{
              item.receiveCity || '--'
            }} / {{ item.receiveState || '--' }} / {{ item.receiveComplement || '--' }}</span>
          </div>
          <div>
            <span>{{ item.receiveAddress || '--' }}</span>
          </div>
          <div
            :class="`btnOperate1${index}`"
            class="btnOperate1"
          >
            <template>
              <el-popconfirm
                :title="$t('GlobalSetObj.AreYouSureDelete')"
                @confirm="delAddress(item)"
              >
                <i slot="reference" class="el-icon-delete" style="color: #f56c6c" /></el-popconfirm>
            </template>
            <i
              class="el-icon-edit-outline"
              style="color: #409eff"
              @click="clickSenderDialog($t('customerManagements.editConsignee'),item)"
            />
          </div>
        </div>
      </div>
      <div v-else class="nodataImage">
        <svg-icon icon-class="noData" />
      </div>
    </div>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[2, 10, 20]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--  新建收件人  -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="receiveTitleDialog"
      :visible.sync="receiveDialog"
      top="10vh"
      width="900px"
    >
      <div>
        <el-form
          ref="receviceRuleForm"
          :model="receviceRuleForm"
          class="demo-receviceRuleForm"
          inline
          label-position="top"
        >
          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.name')"
                :rules="checkStr50"
                prop="receiveName"
                style="width: 100%"
              >
                <el-input
                  v-model="receviceRuleForm.receiveName"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="52"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('GlobalSetObj.MobilePhone')"
                :rules="isPhone"
                prop="receiveMobile"
                style="width: 100%"
              >
                <el-input
                  v-model.trim="receviceRuleForm.receiveMobile"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('placeOrder.Tax')" :rules="isTax12" prop="receiveTax" style="width: 100%">
                <el-input
                  v-model.trim="receviceRuleForm.receiveTax"
                  :placeholder="$t('newOrder.PleaseEnter12Or14DigitTaxNumber')"
                  clearable
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.zipcode')"
                :rules="isZipCode"
                prop="receiveZipcode"
                style="width: 100%"
              >
                <el-input
                  v-model.trim="receviceRuleForm.receiveZipcode"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.State')"
                :rules="{ required: true, message: $t('placeOrder.pleaseInput'), trigger: ['blur','change']}"
                prop="receiveState"
                style="width: 100%"
              >
                <el-select
                  v-model="receviceRuleForm.receiveState"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in receiveStateList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.city')"
                :rules="cityComplement"
                prop="receiveCity"
                style="width: 100%"
              >
                <el-input
                  v-model="receviceRuleForm.receiveCity"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="32"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.Area')"
                :rules="addressComplement"
                prop="receiveArea"
                style="width: 100%"
              >
                <el-input
                  v-model="receviceRuleForm.receiveArea"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="32"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('newOrder.Street')"
                prop="receiveStreet"
                style="width: 100%"
              >
                <el-input
                  v-model="receviceRuleForm.receiveStreet"
                  :placeholder="$t('newOrder.ConfirmStreetNumber')"
                  clearable
                  maxlength="50"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.HouseNumber')"
                :rules="checkStr5"
                prop="receiveHouseNumber"
                style="width: 100%"
              >
                <el-input
                  v-model="receviceRuleForm.receiveHouseNumber"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="6"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('GlobalSetObj.NationalRegistrationNumber')"
                :rules="IeNumber"
                prop="receiveIeNumber"
                style="width: 100%"
              >
                <el-input
                  v-model.trim="receviceRuleForm.receiveIeNumber"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.addressComplement')"
                :rules="addressComplement"
                prop="receiveComplement"
                style="width: 100%"
              >
                <el-input
                  v-model="receviceRuleForm.receiveComplement"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="32"
                />
              </el-form-item>
            </el-col>
            <!--    邮箱        -->
            <el-col :span="8">
              <el-form-item
                :label="$t('placeOrder.email')"
                :rules="checkMail"
                prop="receiveMail"
                style="width: 100%"
              >
                <el-input
                  v-model="receviceRuleForm.receiveMail"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="24">
              <el-form-item
                :label="$t('placeOrder.detailedAddress')"
                :rules="[{ required: true, message: $t('placeOrder.pleaseInput'), trigger: ['blur','change'] },
                         { min: 2, max: 200, message: $t('newOrder.TdetailedAddressCharacter'), trigger: 'blur' }]"
                prop="receiveAddress"
                style="width: 100%"
              >
                <el-input
                  v-model="receviceRuleForm.receiveAddress"
                  :placeholder="$t('newOrder.StreetHouseNumber')"
                  clearable
                  maxlength="200"
                  show-word-limit
                  type="text"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="receiveDialog = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="receiveConfirm">{{ $t('GlobalSetObj.determine') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  checkedzipcode,
  createCustomerReceive,
  deleteCustomerReceive,
  getLgAreaListData,
  pageCustomerReceive,
  updateCustomerReceive
} from '@/api/orderApi';
import {
  checkAddressComplement,
  checkCityComplement,
  E_Mail,
  IENumber,
  isMobile,
  isTax,
  isZipCodeAsync
} from '@/utils/validate';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'ReceviceAddress',
  props: {
    // 客户ID
    customerId: {
      type: [Number, String],
      default: ''
    }
  },

  data() {
    return {
      customerAddressList: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0
      },
      receiveDialog: false,
      receiveTitleDialog: this.$t('customerManagements.createConsignee'),
      receviceRuleForm: {
        receiveName: '', // （收/发）名字 receive
        receiveMobile: '', // 电话
        receiveTax: '', // 税号
        receiveZipcode: '', // 邮编
        receiveStreet: '', // 街道（精确到街道号）
        // receiveNeighborhood: '', // 邻里
        receiveAddress: '', // 详细地址
        receiveHouseNumber: '', // 门牌号
        receiveState: '', // 州
        receiveCity: '', // 城市
        receiveArea: '', // 区
        receiveIeNumber: '', // 国家注册号
        receiveMail: '' // 邮箱
      },
      isZipcode1: true, // 光标离开校验邮编-发件人
      isBlur: 0, // 光标移出 0 未移出不调用事件  1 已移出
      receiveStateList: [], // 州列表
      // 校验50个字符
      checkStr50: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        {
          validator: (rule, value, callback) => {
            if (value.trim().length === 0) {
              return callback(new Error(this.$t('placeOrder.pleaseInput')));
            } else if (value.length > 50) {
              return callback(new Error(this.$t('placeOrder.input50Str')));
            } else {
              callback();
            }
          }, trigger: ['blur', 'change']
        }
      ],
      // 邮箱 E_Mail
      checkMail: [
        { required: false },
        { validator: E_Mail, trigger: ['blur', 'change'] }
      ],
      // 城市
      cityComplement: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: checkCityComplement, trigger: ['blur', 'change'] }
      ],
      // 地址补充
      addressComplement: [
        { required: false },
        { validator: checkAddressComplement, trigger: ['blur', 'change'] }
      ],
      // 校验5个字符
      checkStr5: [
        { required: false, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { min: 1, max: 5, message: this.$t('placeOrder.input5'), trigger: ['blur', 'change'] }
      ],
      IeNumber: [
        { required: false },
        { validator: IENumber, trigger: ['blur', 'change'] }
      ],
      isPhone: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        {
          validator: async(rule, value, callback) => {
            if (!isMobile(value)) {
              return callback(new Error(this.$t('placeOrder.input11PhoneNumber')));
            } else {
              callback();
            }
          }
        }
      ],
      isZipCode: [
        { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: isZipCodeAsync, trigger: ['blur'] }
      ],
      isTax12: [ // 112125456431
        { required: true, message: this.$t('newOrder.TheTaxIDCannotBeEmpty'), trigger: ['blur', 'change'] },
        { validator: isTax, trigger: ['blur', 'change'] }
      ]
    };
  },
  watch: {
    receiveDialog: {
      handler: function(val, oldVal) {
        if (this.receiveTitleDialog === this.$t('customerManagements.createConsignee')) {
          this.receviceRuleForm = {
            receiveName: '', // （收/发）名字 receive
            receiveMobile: '', // 电话
            receiveTax: '', // 税号
            receiveZipcode: '', // 邮编
            receiveStreet: '', // 街道（精确到街道号）
            // receiveNeighborhood: '', // 邻里
            receiveAddress: '', // 详细地址
            receiveHouseNumber: '', // 门牌号
            receiveState: '', // 州
            receiveCity: '', // 城市
            receiveArea: '', // 区
            receiveIeNumber: ''// 国家注册号
          };
          this.$refs['receviceRuleForm']?.resetFields();
        }
      }
    }
  },
  created() {
    this.getAddress();
    this.getState();
  },
  methods: {
    // 确认
    receiveConfirm() {
      this.$refs['receviceRuleForm'].validate((valid) => {
        if (valid) {
          let data = {};
          data = this.receviceRuleForm;
          const str1 = data.receiveTax;
          const pattern = /\d+/g;
          const numbers = str1.match(pattern);
          data.receiveTax = numbers.join('');
          data.customerId = this.customerId;
          if (this.receiveTitleDialog === this.$t('customerManagements.createConsignee')) {
            if (this.receviceRuleForm.id) {
              delete data.id;
            }
            createCustomerReceive(data).then(res => {
              if (res.code===200) {
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                this.receiveDialog = false;
                this.getAddress();
              }
            });
          } else {
            updateCustomerReceive(data).then(res => {
              if (res.code===200) {
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                this.receiveDialog = false;
                this.getAddress();
              }
            });
          }
        }
      });
    },
    // 删除
    delAddress(item) {
      deleteCustomerReceive(item.id).then(res => {
        if (res.code===200) {
          this.$message.success(this.$t('GlobalSetObj.DeleteSuccessful'));
          this.getAddress();
        }
      });
    },
    // 编辑
    clickSenderDialog(title, item) {
      this.receiveDialog = true;
      this.receiveTitleDialog = title;
      if (item) {
        this.receviceRuleForm = cloneDeep(item);
      }
    },
    getAddress() {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params.customerId = this.customerId;
      data.params.receiveZipcode = '';
      data.params.receiveName = '';
      pageCustomerReceive(data).then(res => {
        if (res.code===200) {
          this.customerAddressList = res.data.records;
          this.queryForm.total = res.data.total;
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.getAddress();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getAddress();
    },
    // 发件人——级联地区
    getState() { // level 0 州    1 城市   2 地区
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.code===200) {
          this.receiveStateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}${item.nameCn ? '(' + item.nameCn + ')' : ''}${item.twoCode ? '(' + item.twoCode + ')' : ''}`
            };
          });
        }
      });
    },
    // 鼠标移入
    showDeleteButton(index) {
      const ele = '.btnOperate1' + index;
      const btnOperateElement = document.querySelector(ele);
      btnOperateElement.style.opacity = 1;
    },
    hideDeleteButton(index) {
      const ele = '.btnOperate1' + index;
      const btnOperateElement = document.querySelector(ele);
      btnOperateElement.style.opacity = 0;
    },
    // 邮编校验
    checkPostcode(postcode) {
      // pType === 1 发件人   pType === 2 收件人
      if (this.isBlur === 1) {
        const data = {};
        data.zipcode = postcode;
        checkedzipcode(data).then(res => {
          // console.log('验证邮编', res);
          if (res.code===200) {
            if (!res.data) {
              this.$message.error(this.$t('placeOrder.inputCodeErr'));
              this.receviceRuleForm.receiveZipcode = '';
            }
          }
        }).catch(() => {
          this.receviceRuleForm.receiveZipcode = '';
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: bold;
}

.addressClass {
  height: 680px;
  margin-top: 10px;
  overflow: auto;

  .nodataImage {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      width: 120px !important;
      height: 120px !important;
    }
  }

  .addressItem {
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    margin-bottom: 15px;
    cursor: pointer;
    padding: 15px 10px 15px 10px;
    position: relative;
    border: 1px solid #e4e7ed;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: #ddf0f8;
    }

    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .btnOperate1 {
      position: absolute;
      right: 20px;
      top: 10px;
      font-size: 16px;
      width: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 0;
    }
  }
}
</style>
