<template>
  <div class="Box LoadBox">
    <div class="scanClass">
      <!-- 扫描： -->
      <span>{{ $t('collectionCenter.scanBtn') }}</span>
      <el-input
        v-if="isReceiveSignBtn"
        v-model.trim="signBagNumber"
        :placeholder="$t('collectionCenter.pleaseEnterOrScan')"
        prefix-icon="el-icon-full-screen"
        clearable
        class="iconBtn"
        @keyup.enter.native="pushTable"
      />
      <el-tooltip v-else effect="dark" :content="$t('userManage.unauthorized')" placement="right">
        <el-input v-model.trim="signBagNumber" :placeholder="$t('collectionCenter.pleaseEnterOrScan')" prefix-icon="el-icon-full-screen" clearable class="iconBtn" />
      </el-tooltip>
    </div>
    <div class="uploadBtn">
      <el-button
        v-if="isReceiveSignBtn"
        type="success"
        @click="signature"
      >{{ $t('collectionCenter.signature') }}</el-button><!-- 签名 -->
    </div>
    <div class="recordClass">
      <span>{{ $t('collectionCenter.scannedNumber') }}</span><!-- 已扫描的数量 -->
      <span class="red">{{ scanPageTotal }}</span>
    </div>
    <div class="tableClass">
      <el-table
        :max-height="600"
        :data="scanPage"
        border
      >
        <el-table-column
          prop="signBagNum"
          align="center"
          :label="$t('collectionCenter.BagNumber')"
        /><!-- 袋号 -->
        <el-table-column
          prop="scanTimeStr"
          align="center"
          :label="$t('collectionCenter.scanTime')"
        /><!-- 扫描时间 -->
        <el-table-column
          :label="$t('collectionCenter.operation')"
          fixed="right"
          width="100"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-popconfirm
              :title="$t('collectionCenter.deleteSure')"
              @confirm="scanDelClick(scope.row,scope.$index)"
            ><!-- 确定删除吗？ -->
              <el-button slot="reference" type="danger" size="mini">{{ $t('collectionCenter.Delete') }}</el-button><!-- 删除 -->
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 签名 -->
    <div v-if="signVisible" class="signMask">
      <div class="signVisibleClass">
        <div class="el-icon-close signClose" @click="signCloseClick" />
        <div class="descriptionsClass">
          <div ref="image" style="border: 1px solid #a8a9ad;">
            <div class="desTitle">{{ $t('collectionCenter.lanshouMsg') }}</div><!-- 揽收信息 -->
            <el-descriptions class="margin-top" :column="1" border :content-style="rowClass" :label-style="labelRowClass">
              <el-descriptions-item>
                <template slot="label">
                  <!-- 操作人 -->
                  <span style="text-align: center;display: block">{{ $t('collectionCenter.operator') }}</span>
                </template>
                {{ scanList.name }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <!--                  操作网点-->
                  <span style="text-align: center;display: block">{{ $t('collectionCenter.OperationSite') }}</span>
                </template>
                {{ scanList.dotName }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <!--                  包裹总数-->
                  <span style="text-align: center;display: block">{{ $t('collectionCenter.totalNumberOfPackages') }}</span>
                </template>
                {{ scanList.bagSum }}
              </el-descriptions-item>
            </el-descriptions>

            <!--            <div class="desTitle">{{ $t('collectionCenter.deliverPackageInfo') }}</div>&lt;!&ndash; 派送包裹信息 &ndash;&gt;-->
            <!--            <el-descriptions class="margin-top" :column="1" border :content-style="rowClass" :label-style="labelRowClass">-->
            <!--              <el-descriptions-item>-->
            <!--                <template slot="label">-->
            <!--                  <div class="labelT">{{ $t('collectionCenter.signTotal') }}</div>&lt;!&ndash; 签收总数 &ndash;&gt;-->
            <!--                </template>-->
            <!--                &lt;!&ndash; 个包裹 &ndash;&gt;-->
            <!--                {{ scanList.parcelsSum.length }}-->
            <!--                <span v-if="scanList.parcelsSum.length <= 1">{{ $t('collectionCenter.howManyParcel') }}</span>-->
            <!--                <span v-else>{{ $t('collectionCenter.howManyParcels') }}</span>-->
            <!--                （{{ scanList.bagSum }}-->
            <!--                &lt;!&ndash; 袋 &ndash;&gt;-->
            <!--                <span v-if="scanList.bagSum <= 1">{{ $t('collectionCenter.bag') }}</span>-->
            <!--                <span v-else>{{ $t('collectionCenter.bags') }}</span>-->
            <!--                ）-->
            <!--              </el-descriptions-item>-->
            <!--              <el-descriptions-item>-->
            <!--                <template slot="label">-->
            <!--                  <div class="labelT">{{ $t('collectionCenter.signT') }}</div>&lt;!&ndash; 签收时间 &ndash;&gt;-->
            <!--                </template>-->
            <!--                {{ scanList.time }}-->
            <!--              </el-descriptions-item>-->
            <!--            </el-descriptions>-->

            <div class="desTitle">{{ $t('collectionCenter.customerSignature') }}</div><!-- 客户签名 -->
            <div class="page-content">
              <div class="content">
                <vue-esign ref="esignImg" :width="500" :height="300" :line-width="5" line-color="#000" bg-color="#FFF" />
              </div>
            </div>
          </div>
          <div class="flex">
            <el-button class="btn" type="danger" @click="handleReset">{{ $t('collectionCenter.empty') }}</el-button><!-- 清空 -->
            <el-button class="btn" type="success" @click="handleGenerate">{{ $t('collectionCenter.submitSignature') }}</el-button><!-- 提交签名 -->
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="loadingClass" />

    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';
import {
  getFormatServerTime, collectUnForecastOrderScan, deletedUnForecastOrderScan, unForecastOrderScan, collectSignCommit
} from '@/api/lanshou';
import { uploadOos } from '@/utils/ali_oos_util';
import html2canvas from 'html2canvas';
export default {
  data() {
    return {
      okMp3,
      errorMp3,
      isReceiveSignBtn: this.$store.getters.button_permissions.includes('btn:collection-center:operation:receiveScan:submit'),
      signBagNumber: '',
      scanPage: [],
      scanPageTotal: 0,
      receiveSignList: {}, // 缓存的数据
      signVisible: false,
      isLoading: false, // 加载动画
      scanList: {},
      receiveSignImg: '', // 提交的签名图片
      brazilTime: '',
      parcelsSum: '',
      rowClass: {
        'border-color': '#a8a9ad'
      },
      labelRowClass: {
        'border-color': '#a8a9ad'
      }
    };
  },
  watch: {
    scanPage() {
      this.scanPageTotal = this.scanPage.length;
    }
  },
  created() {
    if (localStorage.getItem('receiveScan')) {
      this.receiveSignList = JSON.parse(window.atob(localStorage.getItem('receiveScan'))) || {};
      const who = 'rs' + this.$store.state.user.id;
      if (this.receiveSignList[who]) {
        this.scanPage = this.receiveSignList[who].scanPage;
      } else {
        this.scanPage = [];
      }
    }
  },
  methods: {
    pushTable() {
      if (!this.signBagNumber) { return; }
      const regex = /^.{6,30}$/;
      if (!regex.test(this.signBagNumber)) {
        this.$message({
          message: this.$t('collectionCenter.pleaseRescan'), // '单号不符合要求请重新扫描'
          type: 'warning'
        });
        this.$refs.errRef.play();
        return;
      }
      // 重复过滤
      const isAdd = this.scanPage.some(el => {
        return el.signBagNum === this.signBagNumber;
      });
      if (isAdd && this.scanPage.length > 0) {
        this.$message({
          message: this.$t('collectionCenter.bagExist'), // '当前袋号重复'
          type: 'warning'
        });
        this.$refs.errRef.play();
      } else {
        // 无重复
        const form = {
          scanNumber: this.signBagNumber, // 条形码或者手输的袋号
          resource: 1, // 来源： 0 PDA 1 PC
          scanUserId: this.$store.getters.userId, // 用户ID
          scanUserName: this.$store.getters.realName, // 用户名
          scanUserSiteId: this.$store.getters.siteId // 用户网点ID
        };
        collectUnForecastOrderScan(form).then(res => {
          if (res.data.code===200 || res.code===200) {
            this.$refs.okRef.play();
            this.scanPage.unshift({
              scanTimeStr: res.data.scanTimeStr,
              id: res.data.scanId,
              signBagNum: this.signBagNumber
            });
            this.receiveSignList['rs' + this.$store.state.user.id] = {
              scanPage: this.scanPage
            };
            // 加密
            const a = window.btoa(JSON.stringify(this.receiveSignList));
            localStorage.setItem('receiveScan', a);
            this.signBagNumber = '';
          } else {
            this.signBagNumber = '';
            this.$refs.errRef.play();
            const msg = res.data.msg || res.msg;
            this.$message.error(msg);
          }
        }).catch((err) => {
          this.signBagNumber = '';
          this.$refs.errRef.play();
          console.log('err', err);
        });
      }
    },
    scanDelClick(row, index) { // 扫件——删除
      // const delForm = {
      //   bagNumber: row.signBagNum,
      //   id: row.id
      // };
      deletedUnForecastOrderScan(row.id).then(res => {
        this.scanPage.splice(index, 1);
        if (this.scanPage.length <= 0 || row.signBagNum == this.signBagNumber) {
          this.signBagNumber = '';
        }
        this.receiveSignList['rs' + this.$store.state.user.id] = {
          scanPage: this.scanPage
        };
        // 加密
        const a = window.btoa(JSON.stringify(this.receiveSignList));
        localStorage.setItem('receiveScan', a);
      }).catch({});
    },
    // 调用巴西时间
    getBrazilTime() {
      getFormatServerTime().then(res => {
        if (res.code===200 && res.data) {
          const bxTime = res.data.split(' ');
          const bxt1 = bxTime[0].split('-');
          this.brazilTime = bxTime[1] + ' ' + bxt1[0] + '-' + bxt1[1] + '-' + bxt1[2];
        }
      }).catch({});
    },
    // 获取包裹数
    getParcelsSum() {
      return new Promise((resolve, reject) => {
        unForecastOrderScan(this.$store.getters.userId).then(res => {
          console.log(res);
          if (res.code===200) {
            this.parcelsSum = res.data;
          } else {
            this.parcelsSum = '';
          }
          resolve();
          // this.parcelsSum = res.data;
        }).catch(() => {
          reject();
        });
      });
    },
    async signature() {
      if (this.scanPage.length <= 0) {
        this.$message({
          message: this.$t('collectionCenter.scanLargePackage'), // '请先扫描袋号'
          type: 'warning'
        });
        return;
      }
      this.getBrazilTime();
      await this.getParcelsSum();
      if (!this.parcelsSum && this.parcelsSum != 0) {
        this.$message({
          message: this.$t('collectionCenter.dataBeingGenerated'), // '正在生成数据'
          type: 'warning'
        });
      } else {
        this.scanList = {
          name: this.$store.state.user.realName,
          code: this.$store.state.user.username,
          parcelsSum: this.parcelsSum,
          bagSum: this.scanPageTotal,
          time: this.brazilTime,
          dotName: this.$store.state.user.dotName
        };
        this.signVisible = true;
      }
    },
    signCloseClick() {
      this.signVisible = false;
      this.parcelsSum = '';
    },
    // 签名——清空
    handleReset() {
      this.$refs['esignImg'].reset(); // 清空画布
    },
    // 签名并提交
    upload() {
      if (this.scanPage.length <= 0) {
        this.$message({
          message: this.$t('collectionCenter.nullSubmit'), // '无数据提交'
          type: 'warning'
        });
      } else {
        const recordIdList = this.parcelsSum.map(arr => {
          return arr.id;
        });
        const scanSubmit = {
          collectSignImg: this.receiveSignImg,
          recordIdList
        };
        collectSignCommit(scanSubmit).then(res => {
          if (res.data.code===200 || res.code===200) {
            this.isLoading = false;
            this.$message.success(this.$t('collectionCenter.submitSuccessfully')); // 提交成功
            this.signVisible = false;
            this.scanPage = [];
            this.signBagNumber = '';
            this.receiveSignList['rs' + this.$store.state.user.id] = {
              scanPage: this.scanPage
            };
            // 加密
            const a = window.btoa(JSON.stringify(this.receiveSignList));
            localStorage.setItem('receiveScan', a);
          }
        }).catch((err) => {
          this.isLoading = false;
          console.error(err);
        });
      }
    },
    handleGenerate() {
      // // 查看截图样式
      // html2canvas(this.$refs.image).then(res => {
      //   const imgUrl = res.toDataURL('image/png');
      //   // console.log('图片临时地址',imgUrl)
      //   const aLink = document.createElement('a');
      //   aLink.href = imgUrl;
      //   aLink.download = new Date().toLocaleString() + '.png';	// 导出文件名，这里以时间命名
      //   document.body.appendChild(aLink);
      //   // 模拟a标签点击事件
      //   aLink.click();
      // });
      // return;

      this.$refs['esignImg'].generate().then(res => {
        this.isLoading = true;
        this.canvasImg();
      }).catch((err) => {
        console.log(err);
        this.$message({
          message: this.$t('collectionCenter.unsigned'), // '未签名!'
          type: 'warning'
        });
      });
    },
    canvasImg() {
      const canvas = document.createElement('canvas'); // 截图区
      const canvasBox = this.$refs.image; // 父级
      const windowSize = window.devicePixelRatio; // 浏览器缩放大小会影响截屏最终展示
      const width = parseInt(window.getComputedStyle(canvasBox).width) * windowSize;
      const height = parseInt(window.getComputedStyle(canvasBox).height) * windowSize;
      canvas.width = width * 2;
      canvas.height = height * 2;
      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';
      const context = canvas.getContext('2d');
      context.scale(2, 2);
      const options = {
        backgroundColor: null,
        canvas: canvas,
        useCORS: true
      };
      html2canvas(canvasBox, options).then((canvas) => {
        // toDataURL 图片格式转成 base64
        const dataURL = canvas.toDataURL('image/png');
        const rSignImg = this.base64ImgtoFile(dataURL, 'file'); // 得到文件格式
        uploadOos(rSignImg, 'Order').then(res => {
          if (res.code === 200) {
            if (res.url) {
              this.receiveSignImg = res.url;
              this.upload();
            } else {
              this.receiveSignImg = '';
            }
          }
        });
      });
    },
    base64ImgtoFile(dataurl, filename = 'file') {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const suffix = mime.split('/')[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.descriptionsClass{
  position: relative;
  width: 500px;
  margin: 50px auto;
  max-height: 700px;
  .desTitle{
    color: #000;
    background: #a8a9ad;
    text-align: center;
    padding: 12px 10px;
    font-size: 20px;
    font-weight: 400;
    border: none;
    box-sizing: border-box;
  }
  .labelT{
    word-wrap: break-word;
  }
  .page-content{
    border-top: none;
    box-sizing: border-box;
    background: #fff;
  }
}
.descriptionsClass ::v-deep .el-descriptions-row{
  width: 100%;
  font-size: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.descriptionsClass ::v-deep tbody{
  font-size: 18px !important;
}
.descriptionsClass ::v-deep .el-descriptions-item__cell{
  border-top: none;
  background: none;
  color: #000;
  font-size: 19px;
  font-weight: 400;
  padding: 7px 10px;
}
.descriptionsClass ::v-deep .el-descriptions-item__content{
  text-align: center;
  border-right: none !important;
}
.descriptionsClass ::v-deep .el-descriptions-item__cell.is-bordered-label{
  width: 150px;
  max-width: 150px;
  border-left: none !important;
  border-right: none !important;
}
.LoadBox{
  position: relative;
  width: 100%;
  height: calc(100vh - 85px);
}
.loadingClass{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  z-index: 999;
}
.loadingClass:before{
  content: "";
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: spin 1s linear infinite;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #62b0ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.signMask{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
  .signVisibleClass{
    overflow: auto;
    width:100%;
    height:100%;
    background:#fff;
    padding:20px 0;
    // overflow: hidden;
  }
  .signClose{
    position: absolute;
    top: 25px;
    right: 40px;
    transform: scale(1.3);
  }
  .page-content{
    margin: 0 auto;
    width: 100%;
    height: auto;
    overflow-y: auto;
    box-sizing: border-box;
    //max-height: 644px;
  }
  .content {
    border: 1px solid #EBEEF5;
    box-sizing: border-box;
    overflow: hidden;
  }
  .flex {
    margin: 10px;
    text-align: center;
    position: absolute;
    right: -200px;
    bottom: 212px;
  }
}
.flex ::v-deep .el-button{
  height: 40px;
  margin: 20px 0;
  display: block;
  padding: 0 20px;
  width: auto;
}
.flex ::v-deep .el-button--danger{
  color: #FFF !important;
  background-color: #F56C6C !important;
  border-color: #F56C6C !important;
}
.signVisibleClass ::v-deep .el-drawer__header{
  margin-bottom: 0 !important;
}
.signVisibleClass ::v-deep .el-drawer__header>:first-child{
  // display: none;
  opacity: 0;
}
.signVisibleClass ::v-deep .el-drawer__open .el-drawer.btt{
  height: 50% !important;
}

.recordClass{
  text-align: center;
  margin: 20px auto;
  font-size: 18px;
  .red{
    font-size: 30px;
    font-weight: bold;
    color: #e0223c;
    margin-left: 6px;
  }
}
.uploadBtn ::v-deep .el-button{
  font-size: 18px;
  padding: 7px 20px;
  line-height: 17px;
}
.uploadBtn{
  margin-top: 40px;
  text-align: center;
}
.scanClass ::v-deep .el-input{
  width: 350px;
}
.scanClass{
  margin: 20px auto;
  .iconBtn::after{
    position: absolute;
    content: "-";
    top: 8px;
    left: 15px;
    transform: scaleX(2.3);
    color: #bbb;
  }
}
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
