import { operationCenter, operationCenterRoute, operationTips } from './type/operationCenter';
import { orderCenterCont } from './type/orderCenter';
import { trajectory } from './type/trajectory';
import { WorkCentreTable } from './type/workCentre';
import { RouterObj } from './type/route';
import { ChannelManage } from './type/channelManage';
import { customerManagements } from './type/customerManagements';
import { Resource } from './type/resource';
import { GlobalSetObj } from './type/global';
import { login } from './type/newLogin';
import { home } from './type/newHome';
import { newOrder, orderDetails, orderExport, orderImport, placeOrder } from './type/newOrder';
import { changepwd } from './type/newPwd';
import { userManage } from './type/newUserManage';
import { roleManage } from './type/newRoleManage';
import { organizationManage } from './type/newOrganizationManage';
import { jobManagement } from './type/newJobManagement';
import { spareNumberPoo } from './type/newSpareNumberPoo';
import { collectionCenter } from './type/newCollectionCenter';
import { dictionary } from './type/newDictionary';
import { CenterForSorting } from './type/newCenterForSorting';
import { newOperationCenter } from './type/newOperationCenter';
import { navbar } from './type/navbar';
import { operatingPlatform } from './type/operatingPlatform';
import { basicData } from './type/finance/basicData/index';
import { receivablePayable } from './type/finance/receivableAndPayable';
import { singleScan } from './type/newSingleScan';
import { scripting } from './type/scripting';
import { tablePrice } from './type/tablePrice';
import { driver } from './type/newDriver';
import { vehicle } from './type/newVehicle';
import { routes } from './type/newRoutes';
import { order } from './type/newOrderTransport';
import { seller } from './type/newSeller';
import { trailer } from './type/newTrailer';
import { bank } from './type/newBank';
import { base } from './type/newBase';
import { company } from './type/newCompany';
import { transferRoute } from './type/newTransferRoutes';
import { editingRegistration } from './type/newEditingRegistration';
import { uploadFile } from './type/newUploadFile';
import { dashboard } from './type/newControlDashboard';
import { errorMessage } from './type/newError';
import { ClaimsManagement } from './type/claimsManagement';
import { compensationManagement } from './type/compensationManagement';

const ALL_OBJ = {
  // 路由
  route: {
    ...RouterObj,
    // 操作中心 路由
    ...operationCenterRoute
  },
  RouterObj,
  // 全局使用的翻译
  GlobalSetObj,

  // 新操作中心
  operationCenter,
  // 新操作中心提示
  operationTips,
  // 订单中心
  orderCenterCont,
  // 轨迹管理
  trajectory,

  // 工单
  WorkCentreTable,

  // 渠道管理
  ChannelManage,

  // 客户列表
  customerManagements,

  // 系统管理
  Resource,

  // 登录
  login,

  // 首页
  home,

  // 订单
  newOrder,

  // 字典
  dictionary,

  // 订单详情
  orderDetails,

  // 批量导入
  orderImport,

  // 订单导出
  orderExport,

  // 手动下单
  placeOrder,

  // 修改密码
  changepwd,

  // 用户管理
  userManage,

  // 角色管理
  roleManage,

  // 组织架构
  organizationManage,

  // 职务管理
  jobManagement,

  // 备用号码池 / 号码池 / 平台管理
  spareNumberPoo,

  // 揽收中心
  collectionCenter,

  // 分拣中心
  CenterForSorting,

  // 新操作中心
  newOperationCenter,
  // Navbar
  navbar,

  // 操作平台
  operatingPlatform,
  // 财务基础资料
  basicData,
  // 财务应收应付
  receivablePayable,
  singleScan,
  scripting,
  tablePrice,

  // 司机
  driver,
  vehicle,
  routes,
  order,
  seller,
  trailer,

  // 转让
  bank,
  base,
  company,
  transferRoute,

  // 反向
  editingRegistration,
  uploadFile,

  // 塔台控制
  dashboard,

  // 理赔管理
  ClaimsManagement,

  // 索赔管理
  compensationManagement,

  errorMessage
};

export const setLang = function setLang(k) {
  const obj = {};
  for (const key in ALL_OBJ) {
    obj[key] = {};
    const childObj = ALL_OBJ[key];
    for (const item in childObj) {
      obj[key][item] = childObj[item][k];
      // console.log('打印obj[key][item]——>', obj[key][item]);// setLang(k)里的 k 就是要传入的语言
    }
  }
  return obj;
};

export default ALL_OBJ;
