<template>
  <div class="boxInfo">
    <el-tabs type="border-card" style="height: 100%;">
      <!--'新增分区'\'分区详情'-->
      <el-tab-pane :label="typeIndex === 1 ? $t('basicData.AddPartition') : $t('basicData.PartitionDetails')">
        <div class="addFormBox">
          <el-form
            ref="queryForm"
            class="queryFormClass"
            :model="formData"
            :rules="rules"
            :label-width="$i18n.locale=='zh'?'120px':''"
            :label-position="$i18n.locale!='zh'?'top':'right'"
          >
            <el-row>
              <el-col :span="20">
                <!--分区方案名称-->
                <el-form-item :label="$t('basicData.PartitionSchemeName')" prop="name">
                  <el-input
                    v-model.trim="formData.name"
                    :placeholder="$t('orderDetails.pleaseInput')"
                    clearable
                    maxlength="20"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <!--备注内容-->
                <el-form-item :label="$t('basicData.RemarkContent')">
                  <el-input
                    v-model="formData.remark"
                    :placeholder="$t('orderDetails.pleaseInput')"
                    type="textarea"
                    :rows="6"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <!--业务类型-->
                <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
                  <el-select v-model="formData.businessType" :placeholder="$t('GlobalSetObj.pleaseChoose')">
                    <!--快递-->
                    <el-option key="0" :label="$t('collectionCenter.expressDelivery')" :value="0" />
                    <!--揽收-->
                    <el-option key="1" :label="$t('collectionCenter.lanshou')" :value="1" />
                    <!--派件-->
                    <el-option key="2" :label="$t('collectionCenter.delivery')" :value="2" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <!--有始发州-->
                <el-form-item :label="$t('orderDetails.desRem') + $t('GlobalSetObj.Estado')">
                  <el-checkbox v-model="formData.stateOfOrigin" :true-label="1" :false-label="0" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div>
            <!--新增分区 v-permit:remove="'but:finance:basicData:partition:edit'"-->
            <el-button
              size="small"
              type="primary"
              @click="handleAdd"
            >{{ $t('basicData.AddPartition') }}
            </el-button>
            <el-popover
              placement="bottom-start"
              trigger="hover"
            >
              <div>
                <import-download :table-data="formData" :state-list="stateList" @importPartition="importPartition" />
              </div>
              <!--导入/下载-->
              <el-button
                slot="reference" size="small" plain
                style="margin-left: 10px"
              >
                {{ $t('basicData.ImportDownload') }}
              </el-button>
            </el-popover>
          </div>
          <div style="margin-top: 10px;">
            <el-form
              ref="tableForm"
              :model="formData"
              label-width="1px"
              class="demo-ruleForm"
              :rules="rules"
            >
              <el-table
                :data="formData.partitionAffiliateList"
                border
              >
                <el-table-column :label="$t('basicData.PartitionName')" width="160">
                  <template slot="header">
                    <div>
                      <span style="color: red;">*</span>
                      <!--分区名称-->
                      <span>{{ $t('basicData.PartitionName') }}</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <el-form-item
                        :prop="`partitionAffiliateList.${scope.$index}.partitionIdentifier`"
                        :rules="rules.partitionIdentifier"
                      >
                        <el-input
                          v-model="scope.row.partitionIdentifier"
                          maxlength="50"
                          show-word-limit
                          :placeholder="$t('basicData.PartitionName')"
                        />
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>

                <!-- 始发州 receivablePayable.OriginatingState -->
                <el-table-column :label="$t('orderDetails.desRem') + $t('GlobalSetObj.Estado')" width="220">
                  <template slot-scope="scope">
                    <div>
                      <el-form-item
                        :prop="`partitionAffiliateList.${scope.$index}.state`"
                        :rules="rules.state"
                      >
                        <StateSelectCmp
                          :disabled="formData.stateOfOrigin === 0" :list="stateList" :value="scope.row.state"
                          @input="val => scope.row.state = val"
                        />
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>

                <!--邮编-->
                <el-table-column>
                  <template slot="header">
                    <div>
                      <span style="color: red;">*</span>
                      <!-- 目的地邮编 -->
                      <span>{{ $t('orderDetails.desRem') + $t('basicData.zipCode') }}</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <el-form-item
                        :prop="'partitionAffiliateList.' + scope.$index + '.postalCode'"
                        :rules="rules.postalCode"
                      >
                        <el-input
                          v-model.trim="scope.row.postalCode"
                          :placeholder="$t('basicData.ZipCodeExample')"
                        />
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <!--操作-->
                <el-table-column :label="$t('basicData.operate')" width="120">
                  <template slot-scope="scope">
                    <!--确定删除吗？-->
                    <el-popconfirm
                      :title="$t('basicData.AreYouSureYouWantToDelete')"
                      @confirm="handleDel(scope)"
                    >
                      <!--删除-->
                      <el-button slot="reference" type="danger" size="mini">{{ $t('basicData.Delete') }}</el-button>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
          </div>
        </div>
        <div class="addFooter">
          <!--取消-->
          <el-button @click="() => handleUpdatePage(false)">{{ $t('basicData.Cancel') }}</el-button>
          <!--保存-->
          <el-button v-permit:remove="'but:finance:basicData:partition:edit'" type="primary" @click="submitForm">{{ $t('basicData.Save') }}</el-button>
        </div>
      </el-tab-pane>
      <!--操作日志-->
      <el-tab-pane v-if="typeIndex !== 1" :label="$t('basicData.OperationalLogs')">
        <operation-log :edit-data="editData" />
      </el-tab-pane>
    </el-tabs>
    <!--保存提示-->
    <el-dialog
      :title="$t('basicData.tips')"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="30%"
    >
      <div>
        <div v-for="(item, ind) in errData" :key="ind" style="color: red;margin-bottom: 10px;">{{ item }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--确 定-->
        <el-button type="primary" @click="dialogVisible = false">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiAffiliateAdd, apiAffiliateDelete, apiAffiliateEdit, apiGetAffiliateById } from '@/api/finance/partition';
import { compressList } from '@/utils/zipcode';
import { Loading } from 'element-ui';
import StateSelectCmp from './StateSelectCmp.vue';
import i18n from '@/lang';
import { cloneDeep } from 'lodash-es';
import { checkZipCode, rowComparison, postalCodeFormat, checkOverlap } from './utils';

const options = {
  lock: true,
  spinner: 'el-icon-loading',
  text: 'loading...',
  background: 'rgba(250, 250, 250, 0.6)' // 加载动画的背景
};

// 添加模板
const addTmp = {
  partitionIdentifier: '',
  postalCode: '',
  id: undefined,
  state: ''
};

export default {
  components: {
    'import-download': () => import('./ImportDownload'),
    'operation-log': () => import('./operationLog'),
    StateSelectCmp
  },
  props: {
    // 类型标识 1新增 2查看 3 编辑
    typeIndex: {
      type: Number,
      default: 1
    },
    editData: {
      type: Object,
      default: () => {
        return {};
      }
    },

    // 所有一级州
    stateList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    // 州校验
    const stateCheck = (rule, value, callback) => {
      const { stateOfOrigin } = this.formData;
      // const list = this.formData.partitionAffiliateList;
      // 没有勾选
      if (stateOfOrigin !== 1) {
        return callback();
      }

      if (!value) {
        return callback(new Error(i18n.t('GlobalSetObj.pleaseChoose'))); // '请选择州'
      }

      // 是否存在相同州
      // if (list.length >= 2) {
      //   console.log(value, list);
      //   let n = 0;
      //   list.forEach(item => {
      //     if (item.state === value) {
      //       n++;
      //     }
      //   });
      //   if (n >= 2) {
      //     const str1 = this.$t('basicData.StateDuplication');
      //     callback(new Error(`${str1}`));
      //     return;
      //   }
      // }

      return callback();
    };

    // 邮编校验
    const postalCodeCheck = (rule, value, callback) => {
      // 格式校验
      {
        const [isBool, text] = postalCodeFormat(value.trim());
        if (!isBool) {
          return callback(new Error(text));
        }
      }

      // 同行比较重叠的情况
      {
        const [isBool, text] = rowComparison(value);
        if (!isBool) {
          return callback(new Error(text));
        }
      }

      return callback();
    };

    return {
      rules: {
        name: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] },
          // '长度在 2 到 20 个字符'
          {
            min: 2,
            max: 20,
            message: this.$i18n.t('basicData.TheLengthBetween2And20Characters'),
            trigger: ['blur', 'change']
          }
        ],
        businessType: { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] },

        // 分区名称
        partitionIdentifier: [
          {
            required: true, message: this.$t('basicData.CannotBeEmpty'), trigger: 'blur'
          }
        ],

        state: [
          {
            validator: stateCheck,
            trigger: 'change'
          }
        ],

        // 邮编
        postalCode: [
          {
            // required: true, message: this.$t('basicData.CannotBeEmpty'), trigger: 'blur'
            required: true, validator: postalCodeCheck, trigger: 'blur'
          }
        ]
      },
      errData: [],
      dialogVisible: false,
      formData: {
        name: '',
        remark: '',
        businessType: '',
        stateOfOrigin: 0,
        partitionAffiliateList: []
      }

    };
  },
  created() {
    if (this.editData.id) {
      this.asyncGetAffiliateById(this.editData.id);
    }
  },

  methods: {
    // 邮编整理
    zipcodeCollation(index, zipcode) {
      this.formData.partitionAffiliateList[index].postalCode = compressList(zipcode).join(',');
    },
    // 增加分区
    handleAdd() {
      this.formData.partitionAffiliateList = this.formData.partitionAffiliateList || [];
      const obj = {
        ...addTmp

      };
      this.formData.partitionAffiliateList.push(obj);
    },
    // 导入分区
    importPartition(arr) {
      // 校验是否需要必填始发州
      {
        const { stateOfOrigin } = this.formData;
        if (stateOfOrigin === 1) {
          const isBool = arr.every(item => {
            return item.state > 0;
          });
          if (!isBool) {
            const str = this.$t('GlobalSetObj.pleaseChoose');// 请选择
            const str2 = this.$t('receivablePayable.OriginatingState'); // 始发州
            const str3 = this.$t('CenterForSorting.Import'); // 导入
            this.$message.warning(str3 + str + str2); // 请选择始发州
            return;
          }
        }
      }

      const partitionAffiliateList = cloneDeep(this.formData.partitionAffiliateList);

      arr.map(item => {
        // if (item.id) {
        //   isId = true;
        // }
        return {
          postalCode: compressList(item.zipcode).join(','),
          state: item.state,
          partitionIdentifier: item.name,
          id: item.id ? item.id : ''
        };
      }).forEach(item => {
        // id存在就是修改
        if (item.id) {
          const index = partitionAffiliateList.findIndex(i => i.id === item.id);
          partitionAffiliateList[index] = item;
        } else {
          // 添加
          partitionAffiliateList.push(item);
        }
      });
      this.formData.partitionAffiliateList = partitionAffiliateList;

      // if (isId) {
      //   this.formData.partitionAffiliateList = [...arrData];
      // } else {
      //   this.formData.partitionAffiliateList = [...this.formData.partitionAffiliateList, ...arrData];
      // }
    },
    // 删除分区
    handleDel(scope) {
      if (scope.row.id) {
        apiAffiliateDelete(scope.row.id).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
          }
        });
      }
      this.formData.partitionAffiliateList.splice(scope.$index, 1);
    },
    // 取消
    handleUpdatePage(type) {
      this.$emit('updatePage', type);
    },
    // 邮编校验
    // verifyZipCode(arr) {
    //   // arr=分区列表
    //   const errObj = {
    //     // 格式错误
    //     errArr: [],
    //     // 交集错误
    //     repeat: []
    //   };
    //   // const reg = /^[A-Z0-9 \-]+$/g;
    //   const reg = /[A-Z \-]*[0-9]+/g; // 至少存在一个数字
    //   // 校验格式
    //   arr.forEach((item, ind) => {
    //     // item = 每行对象, ind下标
    //     let zipcodeStr = '';
    //     if (item.postalCode.length > 0) {
    //       // 清除字符串两端空白
    //       zipcodeStr = item.postalCode.trim();
    //     }
    //     // 替换 所有中文逗号为英文逗号
    //     zipcodeStr = zipcodeStr.replace(/，/ig, ',');
    //     // 没有填写,格式错误
    //     if (zipcodeStr.length === 0) {
    //       if (!errObj.errArr.includes(ind + 1)) {
    //         // 这一整行邮编错误
    //         errObj.errArr.push(ind + 1);
    //       }
    //     }
    //     // 获取每个分区的邮编,逗号分隔成数组
    //     const zipcodeArr = zipcodeStr.split(',').map(item => {
    //       return item.trim();
    //     });
    //     // 不能出现 "41900,,,"
    //     if (zipcodeArr.includes('')) {
    //       if (!errObj.errArr.includes(ind + 1)) {
    //         errObj.errArr.push(ind + 1);
    //       }
    //     }

    //     const isBool = zipcodeArr.every(item => {
    //       const list = item.split('~').map(item => {
    //         return item.trim();
    //       });

    //       if (list.length === 1) {
    //         reg.lastIndex = 0;
    //         return reg.test(list[0]);
    //       }

    //       // 不能出现 100~1000~10000
    //       if (list.length >= 3) {
    //         return false;
    //       }

    //       // 不能出现 "~100" "~" "100~"
    //       if (list.includes('')) {
    //         return false;
    //       }

    //       // 不能出现 10~9 10~10，前提是都为纯数字
    //       const num1 = Number(list[0]);
    //       const num2 = Number(list[1]);
    //       if ((num1 >= 0) && (num2 >= 0) && (num1 >= num2)) {
    //         return false;
    //       }

    //       return list.every(key => {
    //         reg.lastIndex = 0;
    //         return reg.test(key);
    //       });
    //     });

    //     if (!isBool) {
    //       // 邮箱不合格
    //       if (!errObj.errArr.includes(ind + 1)) {
    //         errObj.errArr.push(ind + 1);
    //       }
    //     }
    //   });
    //   if (errObj.errArr.length > 0) {
    //     return errObj;
    //   }
    //   // 校验邮编
    //   for (let i = 0; i < arr.length; i++) {
    //     const itemA = arr[i];
    //     let zipcodeStrA = '';
    //     if (itemA.postalCode.length > 0) {
    //       // 清除字符串两端空白
    //       zipcodeStrA = itemA.postalCode.trim();
    //     }
    //     // 替换 所有中文逗号为英文逗号
    //     zipcodeStrA = zipcodeStrA.replace(/，/ig, ',');
    //     // 获取每个分区的邮编,逗号分隔成数组,并删除空白
    //     const zipcodeArrA = zipcodeStrA.split(',').map(item => {
    //       return item.trim();
    //     });
    //     // 需要脚本本身是否存在交集(自己和自己比较)
    //     // this.validatePostalCode(i, arr, zipcodeArrA, errObj);
    //     for (let j = 0; j < zipcodeArrA.length; j++) {
    //       const postalCodeA = zipcodeArrA[j];
    //       for (let k = j + 1; k < zipcodeArrA.length; k++) {
    //         const postalCodeB = zipcodeArrA[k];
    //         const bool = this.contrastPostalCode(postalCodeA, postalCodeB);
    //         // console.log('交集错误', bool);
    //         if (!bool) {
    //           errObj.repeat.push((i + 1) + ':' + postalCodeA + ' and ' + (i + 1) + ':' + postalCodeB);
    //           return errObj;
    //         }
    //       }
    //     }
    //     // 同一个始发州下的邮编区间校验
    //     // this.validatePostalCode(i, arr, zipcodeArrA, errObj);
    //     // for (let j = i + 1; j < arr.length; j++) {
    //     //   const itemB = arr[j];
    //     //   let zipcodeStrB = '';
    //     //   // 邮编长度
    //     //   if (itemB.postalCode.length > 0) {
    //     //     zipcodeStrB = itemB.postalCode.trim();
    //     //   }
    //     //   // 替换 所有中文逗号为英文逗号
    //     //   zipcodeStrB = zipcodeStrB.replace(/，/ig, ',');
    //     //   const zipcodeArrB = zipcodeStrB.split(',').map(item => {
    //     //     return item.trim();
    //     //   });
    //     // 第一行的所有邮编和第二行所有邮编来一次
    //     // for (let k = 0; k < zipcodeArrA.length; k++) {
    //     //   const postalCodeA = zipcodeArrA[k];
    //     //   for (let l = 0; l < zipcodeArrB.length; l++) {
    //     //     const postalCodeB = zipcodeArrB[l];
    //     //     const bool = this.contrastPostalCode(postalCodeA, postalCodeB);
    //     //     if (!bool) {
    //     //       errObj.repeat.push((i + 1) + ':' + postalCodeA + ' and ' + (j + 1) + ':' + postalCodeB);
    //     //       return errObj;
    //     //     }
    //     //   }
    //     // }
    //     // }
    //   }
    //   return errObj;
    // },

    // 同一个始发州下的邮编区间校验
    validatePostalCode() {
      const { stateOfOrigin, partitionAffiliateList } = this.formData;
      const list = cloneDeep(partitionAffiliateList);
      // const errArr = [];

      if (stateOfOrigin === 0) {
        // 所有的一起校验
        const errArr = [];
        checkOverlap(list, errArr);

        return errArr;
      }

      const errArr = checkZipCode(list);
      return errArr;

      // // 不存在始发州，则全局校验
      // if (stateOfOrigin === 0) {
      //   for (let j = 0; j < zipcodeArrA.length; j++) {
      //     const postalCodeA = zipcodeArrA[j];
      //     for (let k = j + 1; k < zipcodeArrA.length; k++) {
      //       const postalCodeB = zipcodeArrA[k];
      //       const bool = this.contrastPostalCode(postalCodeA, postalCodeB);
      //       if (!bool) {
      //         errObj.repeat.push((i + 1) + ':' + postalCodeA + ' and ' + (i + 1) + ':' + postalCodeB);
      //         return errObj;
      //       }
      //     }
      //   }
      // }

      // // 将相同始发州归类到一起，然后相同始发州校验

      // map.forEach(item => {
      //   // 第一行的所有邮编和第二行所有邮编来一次
      //   for (let j = i + 1; j < item.length; j++) {
      //     const itemB = item[j];
      //     let zipcodeStrB = '';
      //     // 邮编长度
      //     if (itemB.postalCode.length > 0) {
      //       zipcodeStrB = itemB.postalCode.trim();
      //     }
      //     // 替换 所有中文逗号为英文逗号
      //     zipcodeStrB = zipcodeStrB.replace(/，/ig, ',');
      //     const zipcodeArrB = zipcodeStrB.split(',').map(item => {
      //       return item.trim();
      //     });
      //     // 第一行的所有邮编和第二行所有邮编来一次;
      //     for (let k = 0; k < zipcodeArrA.length; k++) {
      //       const postalCodeA = zipcodeArrA[k];
      //       for (let l = 0; l < zipcodeArrB.length; l++) {
      //         const postalCodeB = zipcodeArrB[l];
      //         const bool = this.contrastPostalCode(postalCodeA, postalCodeB);
      //         if (!bool) {
      //           errObj.repeat.push((i + 1) + ':' + postalCodeA + ' and ' + (j + 1) + ':' + postalCodeB);
      //           return errObj;
      //         }
      //       }
      //     }
      //   }
      // });
    },

    // 对比邮编
    // contrastPostalCode(intervalA, intervalB) {
    //   const addZipcodeList = intervalA.split('~');
    //   const existZipCodeList = intervalB.split('~');
    //   // 如果两个都是 ~ 的邮编
    //   if (addZipcodeList.length === 2 && existZipCodeList.length === 2) {
    //     const min1 = parseInt(addZipcodeList[0]);
    //     const max1 = parseInt(addZipcodeList[1]);
    //     const min2 = parseInt(existZipCodeList[0]);
    //     const max2 = parseInt(existZipCodeList[1]);
    //     if (!((max1 < min2) || (max2 < min1))) {
    //       return false;
    //     }
    //   }
    //   // 前面的没有~  后面有~
    //   if (addZipcodeList.length === 1 && existZipCodeList.length === 2) {
    //     const min1 = parseInt(addZipcodeList[0]);
    //     const min2 = parseInt(existZipCodeList[0]);
    //     const max2 = parseInt(existZipCodeList[1]);
    //     if (!(((min1 < min2) && (max2 > min1)) || (max2 < min1))) {
    //       return false;
    //     }
    //   }
    //   // 都没有~
    //   if (addZipcodeList.length === 1 && existZipCodeList.length === 1) {
    //     const min1 = parseInt(addZipcodeList[0]);
    //     const min2 = parseInt(existZipCodeList[0]);
    //     if (min1 === min2) {
    //       return false;
    //     }
    //   }

    //   if (addZipcodeList.length === 2 && existZipCodeList.length === 1) {
    //     const min1 = parseInt(addZipcodeList[0]);
    //     const max1 = parseInt(addZipcodeList[1]);
    //     const min2 = parseInt(existZipCodeList[0]);
    //     if (!(((min2 < min1) && (max1 > min2)) || (max1 < min2))) {
    //       return false;
    //     }
    //   }
    //   return true;
    // },
    // 处理提交的数据格式
    setSubmitFormDataType() {
      const { name, remark, businessType, stateOfOrigin, partitionAffiliateList } = this.formData;
      // const tableData = this.formData.partitionAffiliateList;

      const row = {
        id: undefined,
        name,
        businessType,
        remark,
        attribute: 1,
        stateOfOrigin,
        partitionAffiliateList: []
      };

      row.partitionAffiliateList = partitionAffiliateList.map(item => {
        const { postalCode, partitionIdentifier, id, state } = item;

        const obj = {
          partitionIdentifier,
          postalCode, // 邮编
          id,
          state, // 州
          partitionId: this.editData.id
        };

        // 没有勾选
        if (!stateOfOrigin) {
          obj.state = undefined;
        }

        return obj;
      });

      return row;
    },

    // 新增
    asyncAffiliateAdd(obj) {
      apiAffiliateAdd(obj).then(res => {
        const { code } = res;
        if (code !== 200) {
          return;
        }
        this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
        this.handleUpdatePage(true);
      }).catch(err => {
        console.error(err);
      });
    },
    // 查看订单详情
    asyncGetAffiliateById(ids) {
      apiGetAffiliateById(ids).then(res => {
        const { code, data } = res;
        if (code !== 200) {
          return;
        }
        const {
          businessType,
          name,
          remark,
          stateOfOrigin,
          partitionAffiliateList
        } = data;

        const arr = (partitionAffiliateList || []).map(item => {
          const { partitionIdentifier, postalCode, id, state } = item;
          return {
            ...addTmp,
            id,
            partitionIdentifier,
            postalCode,
            state: Number(state) || ''
          };
        });

        this.formData = {
          businessType,
          name,
          remark,
          stateOfOrigin: stateOfOrigin || 0,
          partitionAffiliateList: arr
        };
      }).catch(err => {
        console.error(err);
      });
    },
    // 编辑
    asyncAffiliateEdit(obj) {
      apiAffiliateEdit(obj).then(res => {
        const { code } = res;
        if (code !== 200) {
          return;
        }
        this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded'));
        this.handleUpdatePage(true);
      }).catch(err => {
        console.error(err);
      });
    },
    // 保存
    submitForm() {
      this.$refs.queryForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        if (this.formData.partitionAffiliateList.length === 0) {
          this.$message.warning(this.$i18n.t('basicData.PleaseAddPartitions')); // `请添加分区`,);
          return false;
        }
        this.$refs.tableForm.validate((tabRules) => {
          if (!tabRules) {
            return false;
          }
          Loading.service(options);
          const loadingInstance = Loading.service(options);
          this.errData = [];
          // 邮编校验-同行邮编校验
          // const errCode = this.verifyZipCode(this.formData.partitionAffiliateList);
          // 同分区邮编校验
          {
            const errArr = this.validatePostalCode();
            if (errArr.length > 0) {
              // const tip = this.$i18n.t('basicData.Lines') + `${errCode.repeat.join(',')}` + this.$i18n.t('basicData.intersect'); // 第*和*邮编存在交集
              this.errData.push(...errArr);
              // return;
            }
          }

          // if (errCode.errArr.length > 0) {
          //   const tip = this.$i18n.t('basicData.Lines') + `${errCode.errArr.join(',')}` + this.$i18n.t('basicData.areMalformed'); // 第*和*邮编格式错误
          //   this.errData.push(tip);
          // }

          const findByItems = (eq) => (arr) => arr.filter(
            (x, i) => arr.find((y, j) => i !== j && eq(x, y))
          );
          const duplicatedItems = findByItems((a, b) => a.partitionIdentifier.toString() === b.partitionIdentifier.toString());
          const duplicatedZipCode = findByItems((a, b) => a.postalCode.toString() === b.postalCode.toString());
          const arrDuplicated = duplicatedItems(this.formData.partitionAffiliateList);
          const arrZipCode = duplicatedZipCode(this.formData.partitionAffiliateList);
          const nameArr = [];
          const zipCode = [];
          this.formData.partitionAffiliateList.map((item, ind) => {
            arrDuplicated.map(list => {
              if (list.partitionIdentifier === item.partitionIdentifier) {
                if (!nameArr.includes(ind + 1)) {
                  nameArr.push(ind + 1);
                }
              }
            });
            arrZipCode.map(list => {
              if (list.postalCode === item.postalCode) {
                if (!zipCode.includes(ind + 1)) {
                  zipCode.push(ind + 1);
                }
              }
            });
          });
          if (nameArr.length > 1) {
            const tip = this.$i18n.t('basicData.Lines') + `${nameArr.join(',')}` + this.$i18n.t('basicData.repeat'); // 第*和*行重复
            this.errData.push(tip);
          }
          // if (zipCode.length > 1) {
          //   const tip = this.$i18n.t('basicData.Lines') + `${zipCode.join(',')}` + this.$i18n.t('basicData.areDuplicated'); // 第*和*行重复
          //   this.errData.push(tip);
          // }
          if (this.errData.length > 0) {
            this.dialogVisible = true;
            loadingInstance.close();
            return;
          }
          // 提交数据处理
          const row = this.setSubmitFormDataType();
          loadingInstance.close();
          if (this.typeIndex === 1) {
            // 新增
            this.asyncAffiliateAdd(row);
            return;
          }

          if (this.typeIndex === 2) {
            // 3.编辑
            row.id = this.editData.id;
            this.asyncAffiliateEdit(row);
          }
          this.handleUpdatePage(true);
        });
      });
      // this.$emit('updatePage', true);
    }

  }
};
</script>

<style scoped>

</style>
<style>
.boxInfo {
  height: 100%;
}

.boxInfo .el-tabs__content {
  height: calc(100vh - 40px);
  padding: 0;
}

.boxInfo .el-tabs__content .el-tab-pane {
  height: 100%;
}

.boxInfo .el-tabs__content .el-tab-pane .addFormBox {
  height: calc(100% - 65px);
  padding: 15px;
  overflow-y: auto;
}

.addFooter {
  border-top: 1px solid #ccc;
  padding: 10px;
  text-align: right;
}
</style>
