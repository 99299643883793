<template>
  <div class="Box">
    <div style="margin-bottom: 20px;" class="selectClass">
      <el-form>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('collectionCenter.scheduleCollectionTime') + '：'">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                :default-time="['00:00:00', '23:59:59']"
                :end-placeholder="$t('collectionCenter.EndTime')"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('collectionCenter.pickupCode') + '：'">
              <el-input
                v-model="queryForm.params.pickUpCode"
                :placeholder="$t('collectionCenter.BatchQuery')"
                clearable
                type="textarea"
                @blur="ft"
                @keydown.enter.native="BlockNewline"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row justify="space-between" type="flex">
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:lanshou-order:receiving-code-query:find'"
            icon="el-icon-search"
            size="mini"
            type="primary"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}
          </el-button><!-- 查询 -->
          <el-button
            icon="el-icon-refresh"
            size="mini"
            @click="refreshClick"
          >{{ $t('collectionCenter.reset') }}
          </el-button><!-- 重置 -->
          <el-button
            v-permit:remove="'btn:collection-center:lanshou-order:receiving-code-query:export'"
            icon="el-icon-upload2"
            size="mini"
            :class="isClick ? 'onlyClickOnce10' : ''"
            @click="exportTable"
          >{{ $t('collectionCenter.export') }}
          </el-button><!-- 导出 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <div class="tableClass">
      <el-table
        ref="tableList"
        :data="tableDate"
        :max-height="600"
        border
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column
          :label="$t('collectionCenter.ind')" align="center" type="index"
          width="60"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('collectionCenter.scheduleCollectionTime')"
          prop="ppdTime"
          align="center"
        />
        <el-table-column
          :label="$t('collectionCenter.pickupCode')"
          prop="pickUpCode"
          align="center"
        />
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="searchClick"
        @size-change="searchClick"
      />
    </div>
  </div>
</template>

<script>
import { cutOffCodePage, collectOrderCutOffCodeExport } from '@/api/lanshou';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

export default {
  data() {
    return {
      isClick: false,
      notEnter: true, // 是否允许换行
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '',
          endTime: '',
          pickUpCode: ''
        }
      },
      timeValue: [],
      tableDate: [],
      total: 0
    };
  },
  watch: {
    timeValue() {
      if (this.timeValue && this.timeValue.length <= 0) {
        this.queryForm.params.startTime = '';
        this.queryForm.params.endTime = '';
      }
    },
    'queryForm.params.pickUpCode'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 1000) {
        number = bghTrim.slice(0, 1000).join('\n');
        this.queryForm.params.pickUpCode = number;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bghTrim.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    }
  },
  created() {
    this.default();
    this.searchClick();
    this.isClick = false;
  },
  methods: {
    ft() {
      const val = this.queryForm.params.pickUpCode.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.pickUpCode = bghTrim.join('\n');
    },
    BlockNewline() { // 文本域最大输入行数为1000
      const e = window.event || arguments[0];
      this.ft();
      const numberLen = this.queryForm.params.pickUpCode.split('\n');
      if (!this.notEnter) {
        if (numberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        return;
      }
      this.queryForm.params.startTime = val[0];
      this.queryForm.params.endTime = val[1];

      if (this.queryForm.params.endTime) {
        const endTime = dayjs(this.queryForm.params.startTime).subtract(-1, 'year').format('YYYY-MM-DD') + ' 23:59:59';
        if (this.queryForm.params.endTime > endTime) {
          this.$message({
            // 最多可查询到一年的数据
            message: this.$t('collectionCenter.moreYear'),
            type: 'warning'
          });
          const d = new Date();
          const time = dayjs(d).format('YYYY-MM-DD');
          this.timeValue = [time + ' 00:00:00', time + ' 23:59:59'];
          this.queryForm.params.startTime = time + ' 00:00:00';
          this.queryForm.params.endTime = time + ' 23:59:59';
        }
      }
    },
    default() { // 默认
      const d = new Date();
      const time = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [time + ' 00:00:00', time + ' 23:59:59'];
      this.queryForm.params = {
        startTime: time + ' 00:00:00',
        endTime: time + ' 23:59:59',
        pickUpCode: ''
      };
    },
    searchClick() {
      const queryForm = cloneDeep(this.queryForm);
      cutOffCodePage(queryForm).then(res => {
        if (res.code===200) {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      });
    },
    /**
     * @description 重置
     */
    refreshClick() {
      this.default();
    },
    /**
     * @description 导出
     */
    exportTable() {
      this.isClick = true;
      collectOrderCutOffCodeExport(this.queryForm).then(res => {
        if (res.code===200) {
          this.isClick = false;
          goExportList(this);
        }
      }).catch(err => {
        console.log(err);
        this.isClick = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// 防止按钮重复点击
@keyframes throttle{
  from{
    filter: opacity(40%);
    pointer-events: none;
  }
  to{
    pointer-events: all;
  }
}
.onlyClickOnce10{
  animation: throttle 10s step-end forwards;
}
.onlyClickOnce10:active{
  filter: opacity(40%);
  animation: none;
}
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.selectClass ::v-deep .el-form-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.selectClass ::v-deep .el-form-item__label {
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
  margin-top: 7px;
}
.selectClass ::v-deep .el-form-item__content {
  margin-left: 0 !important;
  width: 100%;
}
.selectClass ::v-deep textarea.el-textarea__inner {
  max-height: 150px;
  min-height: 40px !important;
}
</style>
