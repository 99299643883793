var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-popover",
        { attrs: { placement: "bottom-start", trigger: "hover" } },
        [
          _c(
            "div",
            { staticClass: "but" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: _vm.buttonPermissionsArr[0],
                      expression: "buttonPermissionsArr[0]",
                      arg: "remove",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onExport(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("receivablePayable.Export")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: _vm.buttonPermissionsArr[1],
                      expression: "buttonPermissionsArr[1]",
                      arg: "remove",
                    },
                  ],
                  staticStyle: { "margin-top": "10px", "margin-left": "0" },
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("receivablePayable.按单号导出")) + " ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "10px", "margin-left": "0" },
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onExport(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("receivablePayable.SheinExport")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: _vm.butPermit,
                  expression: "butPermit",
                  arg: "remove",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: { slot: "reference", size: "small" },
              slot: "reference",
            },
            [_vm._v(_vm._s(_vm.$t("receivablePayable.Export")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogVisible, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-download",
                size: "small",
                type: "primary",
              },
              on: { click: _vm.onDownloadTemplate },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("basicData.DownloadTheTemplate")) + " "
              ),
            ]
          ),
          _c("upload-excel", {
            staticStyle: { display: "inline-block", "margin-left": "20px" },
            attrs: {
              "on-success": _vm.importLogistics,
              "but-name": _vm.$t("spareNumberPoo.ImportData"),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }