import request from '@/utils/request';
import newRequest from '@/utils/new-request';

// 银行付款
export const apiSitePayablePay = (data, isLoading = true) => {
  return newRequest({
    method: 'POST',
    url: '/sitePayable/pay',
    data,
    isLoading // 是否显示加载中参数
  });
};

// 查询结果
export const apiSitePayableQuirePay = (data, isLoading = true) => {
  return newRequest({
    method: 'POST',
    url: '/sitePayable/quirePay',
    data,
    isLoading // 是否显示加载中参数
  });
};
