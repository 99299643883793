<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="5">
          <!--  运单号   -->
          <el-form-item>
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.selectType"
                  :style="IsWidth1"
                >
                  <el-option key="1" :label="$t('newOrder.waybillNumber')" :value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.ScanNumber')" :value="2" />
                </el-select>
              </div>
            </template>
            <el-input
              v-model="queryForm.params.inputValue"
              :placeholder="$t('newOrder.PreciseSearch1')"
              :rows="1"
              style="width:100%"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!-- 客户名称  -->
          <el-form-item :label="$t('orderCenterCont.CustomerName')">
            <el-select
              v-model="queryForm.params.customerIdList"
              :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
              :remote-method="getCustomerPage"
              clearable
              filterable
              multiple
              remote
              reserve-keyword
              style="width: 100%"
              @focus="getCustomerPage1"
            >
              <el-option
                v-for="(item,index) in customerList"
                :key="index"
                :label="item.username"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--   预派送网点   -->
          <el-form-item :label="$t('GlobalSetObj.preEntrega')">
            <el-select
              v-model="queryForm.params.preDeliverIdList"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              :remote-method="remoteGetSiteList"
              clearable
              filterable
              multiple
              remote
              reserve-keyword
              style="width:100%"
              @focus="remoteGetSiteList('')"
            >
              <el-option
                v-for="item in preDeliverySiteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <!--时间-->
          <el-form-item prop="timeType">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.timeType"
                  :style="IsWidth"
                >
                  <el-option
                    v-for="item in timeLable" :key="item.id" :label="item.label"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </template>
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :placeholder="$t('newOrder.SelectDatetime')"
              range-separator="~"
              style="width:100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <div>
            <!--搜索-->
            <el-button
              icon="el-icon-search" size="small" type="primary"
              @click="enterSearch"
            >{{ $t('newOrder.search') }}
            </el-button>
            <el-button
              icon="el-icon-upload2" size="small" style="margin-bottom: 10px"
              @click="allExportOrder"
            >{{ $t('newOrder.export') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh-left" size="small" type="info"
              @click="resetForm()"
            >{{
              $t('newOrder.reset')
            }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column :label="$t('GlobalSetObj.serial')" type="index" width="80" />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :align="item.center"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">
          <div>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { fullLinkMonitorexport, fullLinkMonitorgetPage } from '@/api/orderApi';
import dayjs from 'dayjs';
import { apiCustomerPage } from '@/api/customer';
import { siteFuzzyQuery } from '@/api/lanshou';

export default {
  name: 'StaticAgingMonitoring',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          timeType: 3,
          selectType: 1,
          customerIdList: [],
          preDeliverIdList: [],
          inputValue: ''
        }
      },
      tableData: [],
      preDeliverySiteList: [],
      tableHeaderList: [
        // 运单号
        { id: 1, label: this.$t('newOrder.waybillNumber'), prop: 'waybillNumber', cloWidth: '160', center: 'center' },
        // 扫描单号
        { id: 2, label: this.$t('GlobalSetObj.ScanNumber'), prop: 'scanNumber', cloWidth: '160', center: 'center' },
        // 客户
        { id: 3, label: this.$t('GlobalSetObj.client'), prop: 'customerName', cloWidth: '150', center: 'center' },
        // 预派送网点
        {
          id: 5,
          label: this.$t('GlobalSetObj.preEntrega'),
          prop: 'preDeliverStationName',
          cloWidth: '150',
          center: 'center'
        },
        // 订单创建时间
        {
          id: 6,
          label: this.$t('collectionCenter.orderCreateT'),
          prop: 'orderCreateTime',
          cloWidth: '150',
          center: 'center'
        },
        // 客户出仓时间
        {
          id: 8,
          label: this.$t('orderCenterCont.customerDeliveryTime'),
          prop: 'customerSendTime',
          cloWidth: '150',
          center: 'center'
        },
        // 揽收时间
        {
          id: 9,
          label: this.$t('collectionCenter.lanshouT'),
          prop: 'collectScanTime',
          cloWidth: '170',
          center: 'center'
        },
        // 预计揽收装车时长(H)
        // {
        //   id: 10,
        //   label: this.$t('navbar.estimatedCollectLoadingDuration'),
        //   prop: 'estimatedCollectLoadingDuration',
        //   cloWidth: '170', center: 'center'
        // },
        // // 预计揽收装车时间
        {
          id: 11,
          label: this.$t('navbar.estimatedCollectLoadingTime'),
          prop: 'estimatedCollectLoadingTime',
          cloWidth: '170', center: 'center'
        },
        // 实际揽收装车时间
        {
          id: 12,
          label: this.$t('navbar.actualCollectLoadingTime'),
          prop: 'actualCollectLoadingTime',
          cloWidth: '170',
          center: 'center'
        },
        // 超时时长(H)
        {
          id: 13,
          label: this.$t('navbar.moreThenTime'),
          prop: 'loadingOvertimeHours',
          cloWidth: '170',
          center: 'center'
        },
        // 揽收运输时时长(H)
        // {
        //   id: 14,
        //   label: this.$t('navbar.estimatedDeliveryTransportHours'),
        //   prop: 'estimatedDeliveryTransportHours',
        //   cloWidth: '170',
        //   center: 'center'
        // },
        // 预计一级分拨到件时间
        {
          id: 15,
          label: this.$t('navbar.estimatedFirstOutTime'),
          prop: 'estimatedFirstInTime',
          cloWidth: '170',
          center: 'center'
        },
        // 实际一级分拨到件时间
        {
          id: 16,
          label: this.$t('navbar.actualFirstInTime'),
          prop: 'actualFirstInTime',
          cloWidth: '170',
          center: 'center'
        },
        // 超时时长(H)
        {
          id: 17,
          label: this.$t('navbar.moreThenTime'),
          prop: 'firstInOvertimeHours',
          cloWidth: '170',
          center: 'center'
        },
        // 预计HUB1中转时长
        {
          id: 18,
          label: this.$t('navbar.estimatedHub1TransitHours'),
          prop: 'estimatedHub1TransitHours',
          cloWidth: '170', center: 'center'
        },
        // 预计一级分拨发件时间
        {
          id: 19,
          label: this.$t('navbar.estimatedFirstOutTime2'),
          prop: 'estimatedFirstOutTime',
          cloWidth: '170',
          center: 'center'
        },
        // 实际一级分拨发件时间
        {
          id: 20,
          label: this.$t('navbar.actualFirstOutTime1'),
          prop: 'actualFirstOutTime',
          cloWidth: '170',
          center: 'center'
        },
        // 超时时长(H)
        {
          id: 21,
          label: this.$t('navbar.moreThenTime'),
          prop: 'firstOutOvertimeHours',
          cloWidth: '170',
          center: 'center'
        },
        // 干线运输时长
        {
          id: 22,
          label: this.$t('navbar.trunkTransportDuration'),
          prop: 'trunkTransportDuration',
          cloWidth: '170',
          center: 'center'
        },
        // 预计二级分拨到件时间
        {
          id: 23,
          label: this.$t('navbar.estimatedSecondInTime'),
          prop: 'estimatedSecondInTime',
          cloWidth: '160',
          center: 'center'
        },
        // 实际二级分拨到件时间
        {
          id: 24,
          label: this.$t('navbar.actualSecondInTime'),
          prop: 'actualSecondInTime',
          cloWidth: '160',
          center: 'center'
        },
        // 超时时长(H)
        {
          id: 25,
          label: this.$t('navbar.moreThenTime'),
          prop: 'secondInOvertimeHours',
          cloWidth: '170',
          center: 'center'
        },
        // 预计HUB2中转时长
        {
          id: 26,
          label: this.$t('navbar.estimatedHub2TransitHours1'),
          prop: 'estimatedHub2TransitHours',
          cloWidth: '170',
          center: 'center'
        },
        // 预计二级分拨发件时间
        {
          id: 27,
          label: this.$t('navbar.estimatedSecondOutTime'),
          prop: 'estimatedSecondOutTime',
          cloWidth: '200',
          center: 'center'
        },
        // 实际二级分拨发件时间
        {
          id: 28,
          label: this.$t('navbar.actualSecondOutTime'),
          prop: 'actualSecondOutTime',
          cloWidth: '170',
          center: 'center'
        },
        // 超时时长(H)
        {
          id: 29,
          label: this.$t('navbar.moreThenTime'),
          prop: 'secondOutOvertimeHours',
          cloWidth: '170',
          center: 'center'
        },
        // 支线运输时长(H)
        {
          id: 30,
          label: this.$t('navbar.feederTransportDuration'),
          prop: 'feederTransportDuration',
          cloWidth: '160',
          center: 'center'
        },
        // 预计网点入库时间
        {
          id: 31,
          label: this.$t('navbar.estimatedSiteInTime'),
          prop: 'estimatedSiteInTime',
          cloWidth: '160',
          center: 'center'
        },
        // 实际网点入库时间
        {
          id: 32,
          label: this.$t('navbar.actualSiteInTime'),
          prop: 'actualSiteInTime',
          cloWidth: '160',
          center: 'center'
        },
        // 预计派送时长(H)
        {
          id: 33,
          label: this.$t('navbar.estimatedDispatchDuration'),
          prop: 'estimatedDispatchDuration',
          cloWidth: '160', center: 'center'
        },
        // 预计送达时间
        {
          id: 34,
          label: this.$t('navbar.estimatedDeliveredTime'),
          prop: 'estimatedDeliveredTime',
          cloWidth: '160',
          center: 'center'
        },
        // 实际签收时间
        {
          id: 35,
          label: this.$t('navbar.actualDeliveredTime'),
          prop: 'actualDeliveredTime',
          cloWidth: '160',
          center: 'center'
        },
        // 超时时间(H)
        { id: 36, label: this.$t('navbar.outTime'), prop: 'deliveredOvertimeHours', cloWidth: '160', center: 'center' },
        // 理应送达时间
        {
          id: 37,
          label: this.$t('orderCenterCont.dueTime01'),
          prop: 'expectedDeliveredTime',
          cloWidth: '160',
          center: 'center'
        }
      ],
      customerList: [], // 用户列表
      timeLable: [
        { id: 1, label: this.$t('collectionCenter.orderCreateT') },
        { id: 2, label: this.$t('orderCenterCont.customerDeliveryTime') },
        { id: 3, label: this.$t('collectionCenter.lanshouT') },
        { id: 4, label: this.$t('navbar.estimatedCollectLoadingTime') },
        { id: 5, label: this.$t('navbar.estimatedFirstOutTime') },
        { id: 6, label: this.$t('navbar.estimatedFirstOutTime2') },
        { id: 7, label: this.$t('navbar.yujierjidaojian') },
        { id: 8, label: this.$t('navbar.yujierjifajian') },
        { id: 9, label: this.$t('navbar.estimatedSiteInTime') },
        { id: 10, label: this.$t('navbar.estimatedDeliveredTime') },
        { id: 11, label: this.$t('navbar.actualCollectLoadingTime') },
        { id: 12, label: this.$t('navbar.actualFirstOutTime') },
        { id: 13, label: this.$t('navbar.actualFirstOutTime1') },
        { id: 14, label: this.$t('navbar.shijierjidaojian') },
        { id: 15, label: this.$t('navbar.shijierjifajian') },
        { id: 16, label: this.$t('navbar.shijisiteruku') },
        { id: 17, label: this.$t('navbar.shijiarrived') }
      ]
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '250px' : '160px'
      };
    },
    IsWidth1() {
      return {
        width: this.isTormat ? '180px' : '160px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getSearch();
    this.remoteGetSiteList('');
    this.getCustomerPage();
  },
  methods: {
    // 查询
    getSearch() {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params = this.queryForm.params;
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.beginTime = this.timeValue[0];
        data.params.endTime = this.timeValue[1];
      } else {
        data.params.endTime = null;
        data.params.endTime = null;
      }
      if (this.queryForm.params.selectType === 1) {
        data.params.waybillNumberList = inputValList;
        data.params.scanNumberList = [];
      } else {
        data.params.scanNumberList = inputValList;
        data.params.waybillNumberList = [];
      }
      fullLinkMonitorgetPage(data).then(res => {
        if (res.code===200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    allExportOrder() {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      const data = this.queryForm.params;
      if (this.timeValue && this.timeValue.length > 0) {
        data.beginTime = this.timeValue[0];
        data.endTime = this.timeValue[1];
      } else {
        data.endTime = null;
        data.endTime = null;
      }
      if (this.queryForm.params.selectType === 1) {
        data.waybillNumberList = inputValList;
        data.scanNumberList = [];
      } else {
        data.scanNumberList = inputValList;
        data.waybillNumberList = [];
      }
      fullLinkMonitorexport(data).then(res => {
        if (res.code===200) {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          });
        }
      });
    },
    // 获取最近一周时间
    getTimer() {
      // 获取当前时间
      const today = dayjs().format('YYYY-MM-DD');
      const oneWeekAgo = dayjs().subtract(1, 'months').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.getTimer();
      this.queryForm.params = {
        timeType: 3,
        selectType: 1,
        customerIdList: [],
        preDeliverIdList: [],
        inputValue: ''
      };
      this.getSearch();
    },
    getCustomerPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          quickSearch: name
        }
      };
      apiCustomerPage(param).then((res) => {
        if (res.code===200) {
          this.customerList = res.data.records;
        }
      });
    },
    getCustomerPage1(val) {
      console.log(val, 'getVehicleTagNumber01');
      if (val.length === 0) {
        this.queryForm.params.customerIdList = [];
        this.getCustomerPage('');
      }
      if (this.queryForm.params.customerIdList.length === 0) {
        this.getCustomerPage('');
      }
    },
    // 获取所有网点
    remoteGetSiteList(val) {
      const value = val || '';
      siteFuzzyQuery(value).then(res => {
        if (res.code===200) {
          this.preDeliverySiteList = res.data;
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 15px;
  padding-bottom: 15px;
}

::v-deep {
  .drawerClass header {
    border-bottom: 1px solid #aaa !important;
    margin-bottom: 0 !important;
    padding: 20px 20px 20px !important;
  }
}

.selectLabel ::v-deep .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.selectLabel ::v-deep .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep .el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.textOperator {
  color: #409eff;
  cursor: pointer;
  font-size: 22px;
}

.textOperator1 {
  color: #f56c6c;
  cursor: pointer;
  font-size: 22px;
}

.messgeList {
  height: 450px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.downLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7fa;
  cursor: pointer;
  padding: 5px;
}

.downModule {
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 15px;
}
</style>
