<template>
  <div>
    <el-popover placement="bottom-start" trigger="hover">
      <div class="but">
        <el-button
          v-permit:remove="buttonPermissionsArr[0]"
          size="small"
          type="primary"
          @click="onExport(1)"
        >{{ $t('receivablePayable.Export') }}</el-button>
        <el-button
          v-permit:remove="buttonPermissionsArr[1]"
          style="margin-top: 10px;margin-left: 0;"
          size="small"
          type="primary"
          @click="dialogVisible = true"
        >{{ $t('receivablePayable.按单号导出') }}
        </el-button>
        <el-button
          style="margin-top: 10px;margin-left: 0;"
          size="small"
          type="primary"
          @click="onExport(2)"
        >{{ $t('receivablePayable.SheinExport') }}
        </el-button>
      </div>

      <el-button
        slot="reference"
        v-permit:remove="butPermit"
        style="margin-left: 10px;"
        size="small"
      >{{ $t('receivablePayable.Export') }}
      </el-button>
    </el-popover>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <!--下载模板-->
      <el-button
        icon="el-icon-download"
        size="small"
        type="primary"
        @click="onDownloadTemplate"
      >
        {{ $t('basicData.DownloadTheTemplate') }}
      </el-button>
      <!--导入修改费用-->
      <upload-excel
        :on-success="importLogistics"
        :but-name="$t('spareNumberPoo.ImportData')"
        style="display: inline-block;margin-left: 20px;"
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  apiExportCustomerBills,
  apiShineExportCustomerBills
} from '@/api/finance/receivables';
import { goResourceTaskList } from '@/utils/goExportList.js';
import uploadExcel from '@/components/UploadExcel';
import { downloadTemplate, parseTemplate } from './utils';
import { apiByServiceCode } from '@/api/finance/receivableManagementSite';

// const buttonPermissionsArr = ['btn:aRGenerationNew:export', 'btn:aRGenerationNew:NumberExport'];

export default {
  name: '',

  components: {
    uploadExcel
  },

  props: {
    // 搜索
    queryForm: {
      type: Object,
      required: true
    },
    // 表格显示的栏目
    currentColumns: {
      type: Array,
      required: true
    },

    // 勾选的
    selectData: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      dialogVisible: false,
      buttonPermissionsArr: ['btn:aRGenerationNew:export', 'btn:aRGenerationNew:NumberExport']
    };
  },

  computed: {
    // 按钮是否有权限
    butPermit() {
      // console.log(this.buttonPermissions, this.buttonPermissionsArr);

      // 有导出权限
      if (this.buttonPermissions.includes(this.buttonPermissionsArr[0])) {
        return this.buttonPermissionsArr[0];
      }

      // 有按单号导出权限
      if (this.buttonPermissions.includes(this.buttonPermissionsArr[1])) {
        return this.buttonPermissionsArr[1];
      }

      return '';
    }
  },

  created() {
    // this.buttonPermissionsArr = buttonPermissionsArr;
    this.buttonPermissions = this.$store.getters.button_permissions;
  },

  methods: {
    // 获取表头 及多选id
    getHeadId() {
      const label = [];
      const prop = [];
      const ids = this.selectData.map(item => item.id);
      this.currentColumns.forEach(item => {
        prop.push(item.prop);
        label.push(item.label);
      });
      return { prop, label, ids };
    },

    /**
     * type 1:导出  2:希音揽收对账单
     */
    onExport(type) {
      const { prop, label, ids } = this.getHeadId();

      const param = {
        ...this.queryForm.params,
        ids
      };
      if (type === 1) {
        param.fieldList = prop;
        param.headList = label;
      } else {
        if (!param.businessType) {
          return this.$message.error(this.$t('basicData.PleaseSelectTheBusinessTypeFirst'));
        }
      }
      const fn = type === 1 ? apiExportCustomerBills : apiShineExportCustomerBills;
      fn(param).then(() => {
        goResourceTaskList();
      }).catch(err => {
        console.error(err);
      });
    },

    // 下载模板
    onDownloadTemplate() {
      downloadTemplate();
    },

    // 导入修改费用
    importLogistics(arr) {
      const list = parseTemplate(arr[0].results.splice(2));
      // console.log(list);

      const { prop, label } = this.getHeadId();

      const param = {
        fieldList: prop,
        headList: label,
        conditions: list
      };
      apiByServiceCode(param).then(() => {
        this.$message.success(this.$t('basicData.TheOperationSucceeded'));
        return goResourceTaskList();
      }).then(code => {
        if (!code) return;
        this.dialogVisible = false;
      }).catch(err => {
        console.error(err);
      });
    }
  }
};
</script>

<style scoped>
.but {
  display: flex;
  align-items:flex-start;
  justify-content: center;
  flex-direction: column;
}
</style>
