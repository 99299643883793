var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    { attrs: { placement: "bottom-start", trigger: "hover" } },
    [
      _c(
        "div",
        { staticClass: "mb10" },
        [
          _c(
            "el-button",
            {
              attrs: {
                size: "small",
                icon: "el-icon-download",
                type: "primary",
              },
              on: { click: _vm.downloadTemplate },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("receivablePayable.DownloadTheTemplate")) + " "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("uploadExcel", {
            attrs: {
              "on-success": _vm.onImportFile,
              "but-name": _vm.$t("receivablePayable.ImportFile"),
            },
          }),
        ],
        1
      ),
      _c(
        "el-button",
        {
          attrs: { slot: "reference", type: "primary", size: "mini" },
          slot: "reference",
        },
        [_vm._v(_vm._s(_vm.$t("basicData.BankAccountImport")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }