<template>
  <div class="Box LoadBox">
    <div class="scanClass">
      <!-- 签收： -->
      <span>{{ $t('collectionCenter.scanBtn') }}</span>
      <el-input
        v-model.trim="signBagNumber"
        :placeholder="$t('collectionCenter.pleaseEnterOrScan')"
        prefix-icon="el-icon-full-screen"
        clearable
        class="iconBtn"
        @keyup.enter.native="pushTable"
      />
      <!--      <el-tooltip effect="dark" :content="$t('userManage.unauthorized')" placement="right">-->
      <!--        <el-input v-model.trim="signBagNumber" :placeholder="$t('collectionCenter.pleaseEnterOrScan')" prefix-icon="el-icon-full-screen" clearable class="iconBtn" />-->
      <!--      </el-tooltip>-->
    </div>
    <div class="recordClass">
      <span>{{ $t('collectionCenter.scannedNumber') }}</span><!-- 已扫描的数量 -->
      <span class="red">{{ scanPage.length }}</span>
    </div>
    <div style="text-align: center;margin-bottom: 20px">
      <el-button type="success" :disabled="!scanPage.length" @click="openBagDialog">{{ $t('collectionCenter.SealingBags') }}</el-button>
    </div>
    <div class="tableClass">
      <el-table
        :max-height="600"
        :data="scanPage"
        border
      >
        <el-table-column
          prop="scanNumber"
          align="center"
          :label="$t('collectionCenter.Number')"
        /><!-- 袋号 -->
        <el-table-column
          prop="scanTimeStr"
          align="center"
          :label="$t('collectionCenter.scanTime')"
        /><!-- 扫描时间 -->
        <el-table-column
          :label="$t('collectionCenter.operation')"
          fixed="right"
          width="100"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-popconfirm
              :title="$t('collectionCenter.deleteSure')"
              @confirm="scanDelClick(scope.row,scope.$index)"
            ><!-- 确定删除吗？ -->
              <el-button slot="reference" type="danger" size="mini">{{ $t('collectionCenter.Delete') }}</el-button><!-- 删除 -->
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="$t('collectionCenter.SealingBags')"
      :visible.sync="dialogVisible"
      width="30%"
      @close="handleClose"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="65px"
        class="demo-ruleForm"
        @submit.native.prevent="handleSubmit"
      >
        <el-form-item :label="$t('operationCenter.largePackageNumber')" prop="bagNumber">
          <div class="bagBox">
            <el-input v-model="ruleForm.bagNumber" @keyup.enter.native="()=>{}" />
            <el-checkbox v-model="checked" style="margin-left: 10px;" @change="choose">{{ $t('receivablePayable.SystemGenerated') }}</el-checkbox>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sealedBag">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';
import {
  bagPcScanSealABagNumber,
  collectOrderScanScanInfoDeletedScan,
  collectOrderScanScanInfoPcSealABag,
  collectOrderScanScanInfoQueryScanLog, collectPcSealABagSubmit
} from '@/api/lanshou';

import {
  generatingBagNum,
  printLabel
} from '@/api/operatingPlatform';
export default {
  data() {
    // 大包号只允许输入6-8位数字
    const checkBagNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('GlobalSetObj.PleaseBagNumber')));
      }
      if (!/^\d{6,8}$/.test(value)) {
        return callback(new Error(this.$t('GlobalSetObj.bagNumberLimit')));
      }
      callback();
    };
    return {
      okMp3,
      errorMp3,
      checked: false,
      signBagNumber: '', // 扫描单号
      scanPage: [], // 已扫描列表
      dialogVisible: false, // 封袋弹窗
      ruleForm: {
        bagNumber: ''
      },
      rules: {
        bagNumber: [
          { required: true, trigger: 'blur', validator: checkBagNumber }
        ]
      }
    };
  },
  created() {
    // 获取扫描列表
    this.getData();
  },
  methods: {
    openBagDialog() {
      this.checked = false;
      this.dialogVisible = true;
    },
    choose(val) {
      if (!val) return;
      const data = {};
      generatingBagNum(data).then((res) => {
        if (res.code===200) {
          this.ruleForm.bagNumber = res.data.number;
          setTimeout(() => {
            const orderIdList = this.scanPage.map((item) => {
              return item.orderId;
            });
            const data = {
              bagNumber: this.ruleForm.bagNumber,
              siteId: this.$store.getters.siteId,
              scanUserId: this.$store.getters.userId,
              orderIdList
            };
            collectPcSealABagSubmit(data)
              .then((res) => {
                if (res.code===200) {
                  this.getData();
                  this.dialogVisible = false;
                  this.$message({
                    message: this.$t('operatingPlatform.bagSealedSuccessfully'),
                    type: 'success'
                  });
                }
              });
            /**
             * 跳转到打印页面
             */
            this.print(res.data.number);
          }, 1000);
        }
      });
    },
    print(data) {
      const printForm = {
        number: data.split(',')
      };
      printLabel(printForm).then(res => {
        if (res.code===200) {
          window.open(res.data);
        }
      });
    },
    /**
     * @description: 扫描
     */
    pushTable() {
      if (this.signBagNumber) {
        const queryData = {
          scanNumber: this.signBagNumber
        };
        if (this.scanPage && this.scanPage.length) {
          queryData.customerId = this.scanPage[this.scanPage.length - 1].customerId;
        }
        collectOrderScanScanInfoPcSealABag(queryData)
          .then((res) => {
            if (res.code===200) {
              this.getData();
              this.$refs.okRef.play();
              this.signBagNumber = '';
            } else {
              this.$refs.errRef.play();
              this.signBagNumber = '';
            }
          }).catch(() => {
            this.signBagNumber = '';
            this.$refs.errRef.play();
          });
      }
    },
    /**
     * @description 获取扫描列表
     */
    getData() {
      collectOrderScanScanInfoQueryScanLog(21, 1)
        .then((res) => {
          if (res.code===200) {
            this.scanPage = res.data;
            if (this.scanPage.length <= 0) {
              this.signBagNumber = '';
            }
          }
        });
    },
    /**
     * @description 删除扫描记录
     * @param row
     */
    scanDelClick(row) {
      collectOrderScanScanInfoDeletedScan(row.id)
        .then((res) => {
          if (res.code===200) {
            this.getData();
          }
        });
    },
    handleClose() {
      this.dialogVisible = false;
      this.ruleForm.bagNumber = '';
      this.$refs.ruleForm.clearValidate();
    },
    /**
     * @description 封袋
     */
    sealedBag() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          bagPcScanSealABagNumber(this.ruleForm.bagNumber)
            .then((res) => {
              if (res.code===200) {
                const orderIdList = this.scanPage.map((item) => {
                  return item.orderId;
                });
                const data = {
                  bagNumber: this.ruleForm.bagNumber,
                  siteId: this.$store.getters.siteId,
                  scanUserId: this.$store.getters.userId,
                  orderIdList
                };
                collectPcSealABagSubmit(data)
                  .then((res) => {
                    if (res.code===200) {
                      this.getData();
                      this.dialogVisible = false;
                      this.$message({
                        message: this.$t('operatingPlatform.bagSealedSuccessfully'),
                        type: 'success'
                      });
                    }
                  });
              }
            });
        }
      });
    },
    // 表单输入框回车阻止冒泡
    handleSubmit() {
      // 在这里处理表单提交逻辑，例如调用 sealedBag 函数
      this.sealedBag();
    }
  }
};
</script>

<style lang="scss" scoped>

.bagBox{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bagBox ::v-deep .el-checkbox__label{
  padding-left: 0 !important;
}
.descriptionsClass{
  position: relative;
  width: 500px;
  margin: 50px auto;
  max-height: 700px;
  .desTitle{
    color: #000;
    background: #a8a9ad;
    text-align: center;
    padding: 12px 10px;
    font-size: 20px;
    font-weight: 400;
    border: none;
    box-sizing: border-box;
  }
  .labelT{
    word-wrap: break-word;
  }
  .page-content{
    border-top: none;
    box-sizing: border-box;
    background: #fff;
  }
}
.descriptionsClass ::v-deep .el-descriptions-row{
  width: 100%;
  font-size: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.descriptionsClass ::v-deep tbody{
  font-size: 18px !important;
}
.descriptionsClass ::v-deep .el-descriptions-item__cell{
  border-top: none;
  background: none;
  color: #000;
  font-size: 19px;
  font-weight: 400;
  padding: 7px 10px;
}
.descriptionsClass ::v-deep .el-descriptions-item__content{
  text-align: center;
  border-right: none !important;
}
.descriptionsClass ::v-deep .el-descriptions-item__cell.is-bordered-label{
  width: 150px;
  max-width: 150px;
  border-left: none !important;
  border-right: none !important;
}
.LoadBox{
  position: relative;
  width: 100%;
  height: calc(100vh - 85px);
}
.loadingClass{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  z-index: 999;
}
.loadingClass:before{
  content: "";
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: spin 1s linear infinite;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #62b0ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.signMask{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #ffffff;
  z-index: 99;
  .signVisibleClass{
    overflow: auto;
    width:100%;
    height:100%;
    background:#fff;
    padding:20px 0;
    // overflow: hidden;
  }
  .signClose{
    position: absolute;
    top: 25px;
    right: 40px;
    transform: scale(1.3);
  }
  .page-content{
    margin: 0 auto;
    width: 100%;
    height: auto;
    overflow-y: auto;
    box-sizing: border-box;
    //max-height: 644px;
  }
  .content {
    border: 1px solid #EBEEF5;
    box-sizing: border-box;
    overflow: hidden;
  }
  .flex {
    margin: 10px;
    text-align: center;
    position: absolute;
    //right: -200px;
    right: 10px;
    bottom: 212px;
  }
}
.flex ::v-deep .el-button{
  height: 40px;
  margin: 20px 0;
  display: block;
  padding: 0 20px;
  width: auto;
}
.flex ::v-deep .el-button--danger{
  color: #FFF !important;
  background-color: #F56C6C !important;
  border-color: #F56C6C !important;
}
.signVisibleClass ::v-deep .el-drawer__header{
  margin-bottom: 0 !important;
}
.signVisibleClass ::v-deep .el-drawer__header>:first-child{
  // display: none;
  opacity: 0;
}
.signVisibleClass ::v-deep .el-drawer__open .el-drawer.btt{
  height: 50% !important;
}

.recordClass{
  text-align: center;
  margin: 20px auto;
  font-size: 18px;
  .red{
    font-size: 30px;
    font-weight: bold;
    color: #e0223c;
    margin-left: 6px;
  }
}
.uploadBtn ::v-deep .el-button{
  font-size: 18px;
  padding: 7px 20px;
  line-height: 17px;
}
.uploadBtn{
  margin-top: 40px;
  text-align: center;
}
.scanClass ::v-deep .el-input{
  width: 350px;
}
.scanClass{
  margin: 20px auto;
  .iconBtn{position: relative;}
  .iconBtn::after{
    position: absolute;
    content: "-";
    top: 8px;
    left: 15px;
    transform: scaleX(2.3);
    color: #bbb;
  }
}
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sc-wrapper-label {
  max-width: 900px;
  margin: 0px auto;
  box-sizing: border-box;
  display: grid;
  gap: 4rem;
}

.sc-border-full {
  border: 1px solid #000;
  background-color: #ffffff;
}

.m-b-200 {
  margin-bottom: 200px;
}

.sc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
}

.sc-pointCollect {
  font-size: 20px;
  font-weight: bold;
}

.sc-contentSeparator {
  padding: 1rem;
  text-align: center;
  background: #a8a9ad;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.sc-contentSeparator > h2 {
  font-size: 20px;
  font-weight: bold;
}

.sc-gridInformations {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.sc-gridItem {
  padding: 16px;
  font-size: 20px;
}

.sc-gridItem:nth-child(even) {
  border-left: 1px solid #000;
  text-align: center;
}

.sc-gridItem:nth-child(odd) {
  font-weight: bold;
}

.sc-gridItem:nth-child(n+3) {
  border-top: 1px solid #000;
}

.sc-wrapper-table {
  width: 100%;
}

.sc-section-table{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

tbody.sc-section-table > .sc-table-row {
  border-top: 1px solid #000;
}

.sc-table-row {
  display: grid;
  grid-template-columns: 20px 1fr 1fr;
}

.sc-table-cell{
  padding: 4px;
  font-size: 14px;
  text-align: center;
}

.sc-table-cell ~ .sc-table-cell {
  border-left: 1px solid #000;
}

.border-left {
  border-left: 1px solid #000;
}

.m-b {
  margin-bottom: 120px;
  background-color: #ffffff;
}
</style>
