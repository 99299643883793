import newRequest from '@/utils/new-request';

// 理赔账单

// 分页
export const apiClaimBillPage = data => {
  return newRequest.post('/claimBill/page', data);
};

// 付款
export const apiClaimBillPayTodo = data => {
  return newRequest.post('/claimBill/payTodo', data);
};

// 账单抵扣
export const apiClaimBillDeduction = data => {
  return newRequest.post('/claimBill/deduction', data);
};

