<!--调整明细-->
<template>
  <div>
    <tableCmp
      ref="tableCmp"
      :column-list="columnList"
      :is-select-btn="isSelectBtn"
      :table-data="tableData"
      :table-type="4"
      @selectionChange="selectionChange"
    />
    <el-pagination
      :current-page.sync="pageNumber"
      :page-size.sync="pageSize"
      :page-sizes="[10, 50, 100, 200, 400]"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 20px 0; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
import {
  apiGetBagRecycleDetailAdjustList,
  apiGetBagRecycleDetailExportAdjustList
} from '@/api/newExpressAPI';
import { goExportList } from '@/utils/goExportList';

export default {
  name: 'AdjustDetails',
  components: {
    tableCmp: () => import('./tableCmp.vue')
  },
  props: {
    queryForm: {
      type: Object,
      default() {
        return {
          returnWaybillNumberList: [], // 运单号
          startTime: '', // 起始日期
          endTime: '', // 结束日期
          siteCode: this.$store.state.user.siteCode, // 网点编号
          siteName: this.$store.state.user.dotName, // 网点名称
          recycleStatus: null, // 是否确认
          waybillPrintStatus: null // 是否打印
        };
      }
    }
  },
  data() {
    return {
      total: 0,
      pageNumber: 1,
      pageSize: 10,
      isSelectBtn: false,
      tableData: [],
      selectArr: [],
      selectData: null,
      columnList: [
        {
          label: this.$t('GlobalSetObj.DocumentNumber'),
          prop: 'docNo',
          minWidth: '180px',
          align: 'center'
        }, // 单据号
        {
          label: this.$t('GlobalSetObj.AdjustedBranch'),
          prop: 'adjustSiteName',
          minWidth: '180px',
          align: 'center'
        }, // 被调整网点
        {
          label: this.$t('GlobalSetObj.AdjustedQuantity'),
          prop: 'adjustCount',
          minWidth: '180px',
          align: 'center'
        }, // 调整数量
        {
          label: this.$t('GlobalSetObj.AdjustmentReasons'),
          prop: 'adjustReason',
          minWidth: '180px',
          align: 'center'
        }, // 调整原因
        {
          label: this.$t('GlobalSetObj.AdjustmentPerson'),
          prop: 'adjustMan',
          minWidth: '180px',
          align: 'center'
        }, // 调整人
        {
          label: this.$t('GlobalSetObj.AdjustmentDate'),
          prop: 'adjustTime',
          minWidth: '180px',
          align: 'center'
        } // 调整日期
      ]
    };
  },
  created() {
    this.getTableData(this.queryForm);
  },
  methods: {
    // 数据勾选
    selectionChange(arr) {
      this.selectArr = arr;
      console.log(this.selectArr, '=this.selectArr');
    },
    // 获取列表数据
    getTableData(param) {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        params: {
          // ...param
          startTime: param.startTime,
          endTime: param.endTime,
          siteCode: param.siteCode,
          siteName: param.siteName
        }
      };
      console.log(params, '===params');
      apiGetBagRecycleDetailAdjustList(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 表格布局更新
    doLayout() {
      this.$refs.tableCmp.$refs.wovenBagManagementTable.doLayout();
    },
    // 每页条数
    handleSizeChange(val) {
      this.pageNumber = 1;
      this.pageSize = val;
      this.getTableData(this.queryForm);
    },
    // 当前页
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getTableData(this.queryForm);
    },
    // 导出
    exportData(param) {
      console.log(param, '===param');
      const params = {
        startTime: param.startTime,
        endTime: param.endTime,
        siteCode: param.siteCode,
        siteName: param.siteName
      };
      apiGetBagRecycleDetailExportAdjustList(params).then((res) => {
        if (res.code === 200) {
          goExportList(this, '/views/resource/exportTaskList');
        }
      });
    }
  }
};
</script>

<style scoped></style>
