<template>
  <!-- 客户搜索下来框，支持模糊搜索，支持传入客户id搜索，暂时只支持单选 -->
  <el-select
    v-model="val1"
    :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
    :remote-method="asyncGetList"
    clearable
    :disabled="disabled"
    filterable
    remote
    :loading="isLoading"
    reserve-keyword
    @change="changeCustomer"
  >

    <el-option
      v-if="isAll"
      :label="$t('customerManagements.all')"
      value=""
    />
    <el-option
      v-for="(item) in customerList"
      :key="item.id"
      :label="item.customerAlias + '(' + item.username + ')'"
      :value="item.id"
    />
  </el-select>

</template>

<script>
import { throttle } from 'lodash-es';

const param = {
  pageSize: 10,
  pageNumber: 1,
  params: {
    quickSearch: '',
    id: 0 // 客户id 根据客户id获取客户
  }
};

import { apiCustomerPage } from '@/api/customer';

export default {
  name: '',

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    'value': {
      type: [String, Number], // Number: 0, String: ''
      default: ''
    },

    // 下拉列表是否显示全部
    isAll: {
      type: Boolean,
      default: false
    },

    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      // val1: '',
      isLoading: false,
      customerList: [] // 客户列表
    };
  },

  computed: {
    val1: {
      // 动态计算值
      get: function() {
        return this.value;
      },
      set: function(val) {
        // this.$emit('change', val);

      }
    }
  },

  watch: {
    value: {
      handler(val) {
        // this.val1 = val;
        if (val) {
          // 传入了客户id
          this.getCustomerPage('', val);
        } else {
          this.getCustomerPage('');
          // console.log('没有传入了客户id');
        }
      },
      immediate: true
    }
  },

  created() {
    // this.getCustomerPage();

    this.asyncGetList = throttle((val) => {
      // console.log(val);
      this.getCustomerPage(val);
    }, 1000);
  },

  methods: {
    // 客户选中
    changeCustomer(ids) {
      this.val1 = ids;
      // console.log(123);
      this.$emit('change', ids);

      const row = this.customerList.find(item => {
        return item.id === ids;
      });

      if (row && row.id) {
        this.$emit('row', row);
        return;
      }

      this.$emit('row', null);
    },

    /**
     * @description 分页获取客户列表
     * @param { string } name 客户名称
     */
    getCustomerPage(name = '', customerList) {
      param.params.quickSearch = name;
      param.params.id = customerList;
      this.isLoading = true;

      apiCustomerPage(param, false).then((res) => {
        const { code, data } = res;
        if (code !== 200) {
          return;
        }

        this.customerList = (data.records || []).map(item => {
          const { id, customerAlias, username } = item;
          const obj = {
            id,
            customerAlias, // 客户别名
            username // 客户名称
          };

          return Object.freeze(obj);
        });
      }).catch(() => {
        this.customerList = [];
      }).finally(() => {
        this.isLoading = false;
      });
    }

  }

};
</script>

<style scoped>

</style>

