import newRequest from '@/utils/new-request';

// 揽收应付账户分页（新）:
export const apiDeliveryPayableAccountPageNew = (data) => {
  return newRequest({
    method: 'POST',
    url: '/deliveryPayableAccount/page',
    data
  });
};
// 揽收应付账户编辑
export const apiDeliveryPayableAccountEdit = (data) => {
  return newRequest({
    method: 'POST',
    url: '/deliveryPayableAccount/edit',
    data
  });
};
// santander批量发起支付
export const apiSitePayableSantanderPay = (data) => {
  return newRequest({
    method: 'POST',
    url: '/sitePayable/santander/pay',
    data
  });
};
// Santander结果查询
export const apiSitePayableSantanderQuery = (data) => {
  return newRequest({
    method: 'POST',
    url: '/sitePayable/santander/query',
    data
  });
};
// 获取银行下拉选项
export const apiGetBankList = (data) => {
  return newRequest({
    method: 'get',
    url: '/deliveryPayableAccount/bank/list',
    data
  });
};

// 安骏小屋应付账户分页（新）:
export const apiHutPayableAccountPage = (data, isBool = false) => {
  return newRequest({
    method: 'POST',
    url: '/cabinCollectionPayableAccount/page',
    isLoading: isBool,
    data
  });
};

// 安骏小屋应付账户详情（新）:
export const apiHutPayableAccountEdit = (data, isBool = false) => {
  return newRequest({
    method: 'POST',
    url: '/cabinCollectionPayableAccount/edit',
    isLoading: isBool,
    data
  });
};

// 安骏小屋应付账户账户导出（新）:
export const apiHutPayableAccountExport = (data, isBool = false) => {
  return newRequest({
    method: 'POST',
    url: '/cabinCollectionPayableAccount/export',
    isLoading: isBool,
    data
  });
};

// 安骏小屋批量导入银行付款账户（新）:
export const apiCabinCollectionPayableAccountImport = (data, isBool = false) => {
  return newRequest({
    method: 'POST',
    url: '/cabinCollectionPayableAccount/importEdit',
    isLoading: isBool,
    data
  });
};

// AJD/DSP批量导入银行付款账户（新）:
export const apiDeliveryPayableAccountImport = (data, isBool = false) => {
  return newRequest({
    method: 'POST',
    url: '/deliveryPayableAccount/importEdit',
    isLoading: isBool,
    data
  });
};

