var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-card", { staticClass: "mb5" }, [
        _c(
          "div",
          { staticClass: "searchBox" },
          [
            _c(
              "div",
              [
                _c(
                  "el-popover",
                  { attrs: { placement: "bottom-start", trigger: "hover" } },
                  [
                    _c(
                      "div",
                      { staticStyle: { "margin-bottom": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.operationBtn(2)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("receivablePayable.AddChargesInBulk")
                              ) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-bottom": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.importFees(1)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("receivablePayable.ImportAddCharges")
                              ) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-bottom": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.importFees(2)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("receivablePayable.ImportModifyExpenses")
                              ) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-bottom": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.quickRecord(1)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("receivablePayable.QuickRecord")) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.quickRecord(2)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("receivablePayable.BatchImportClips")
                              ) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value: "btn:aRGeneration:addCharge",
                            expression: "'btn:aRGeneration:addCharge'",
                            arg: "remove",
                          },
                        ],
                        staticStyle: { "margin-right": "10px" },
                        attrs: { slot: "reference", size: "small" },
                        slot: "reference",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("receivablePayable.AddModifyExpenses")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-popover",
                  { attrs: { placement: "bottom-start", trigger: "hover" } },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value: "btn:aRGeneration:generate",
                            expression: "'btn:aRGeneration:generate'",
                            arg: "remove",
                          },
                        ],
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.operationBtn(3)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("receivablePayable.GenerateReceivables")
                          ) + " "
                        ),
                      ]
                    ),
                    _c("import-receivable-generation", {
                      attrs: { "fee-type-options": _vm.feeTypeOption },
                    }),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value: "btn:aRGeneration:generate",
                            expression: "'btn:aRGeneration:generate'",
                            arg: "remove",
                          },
                        ],
                        staticStyle: { "margin-right": "10px" },
                        attrs: {
                          slot: "reference",
                          size: "small",
                          type: "primary",
                        },
                        slot: "reference",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("receivablePayable.GenerateReceivables")
                          ) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value: "btn:aRGenerationNew:remarks",
                        expression: "'btn:aRGenerationNew:remarks'",
                        arg: "remove",
                      },
                    ],
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.operationBtn(5)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("receivablePayable.remark")) + " ")]
                ),
                _c("ExportButCmp", {
                  attrs: {
                    "query-form": _vm.queryForm,
                    "select-data": _vm.selectData,
                    "current-columns": _vm.currentColumns,
                  },
                }),
                _c(
                  "el-popover",
                  { attrs: { placement: "bottom-start", trigger: "hover" } },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.operationBtn(7, 0)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "receivablePayable.RecalculateThePrice"
                                ) +
                                  "(" +
                                  _vm.$t("receivablePayable.Maximum100000") +
                                  ")"
                              ) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.getImportTemplate(2)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "receivablePayable.ImportCalculatePrices"
                                )
                              ) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.getImportTemplate(3)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "receivablePayable.ImportWeightsCalculatePrices"
                                )
                              ) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.postcodeCalculation(5)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "receivablePayable.ImportPostcodeCalculation"
                                )
                              ) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permit",
                            rawName: "v-permit:remove",
                            value: "btn:aRGenerationNew:RecalculateThePrice",
                            expression:
                              "'btn:aRGenerationNew:RecalculateThePrice'",
                            arg: "remove",
                          },
                        ],
                        staticStyle: { "margin-left": "10px" },
                        attrs: { slot: "reference", size: "small" },
                        slot: "reference",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("receivablePayable.RecalculateThePrice")
                          ) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value: "btn:aRGenerationNew:deleteRow",
                        expression: "'btn:aRGenerationNew:deleteRow'",
                        arg: "remove",
                      },
                    ],
                    staticStyle: { "margin-left": "10px" },
                    attrs: { size: "small", type: "danger" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteRow()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("receivablePayable.Delete")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.customColumn()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("orderCenterCont.CustomizeListColumn")) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value: "btn:aRGeneration:PushFastHandCharge",
                        expression: "'btn:aRGeneration:PushFastHandCharge'",
                        arg: "remove",
                      },
                    ],
                    staticStyle: { "margin-left": "10px" },
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.onPushFastHandCharge },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("orderCenterCont.PushFastHandCharge")) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: {
                  icon: "el-icon-search",
                  size: "small",
                  type: "primary",
                },
                on: {
                  click: function ($event) {
                    return _vm.searchResources()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("receivablePayable.Search")) + " ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-card",
        [
          _c(
            "el-table",
            {
              key: _vm.tableKey,
              ref: "elTable",
              staticClass: "table-height",
              attrs: {
                data: _vm.tableData,
                height: _vm.tableHeightRow.height,
                "row-key": _vm.getRowKey,
                border: "",
              },
              on: {
                "header-dragend": _vm.headerWidthChange,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  "reserve-selection": true,
                  align: "center",
                  type: "selection",
                  width: "55",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operationCenter.ind"),
                  align: "center",
                  width: "60px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.queryForm.pageNumber - 1) *
                                _vm.queryForm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._l(_vm.currentColumns, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    align: item.fixed,
                    label: item.label,
                    prop: item.prop,
                    "show-overflow-tooltip": true,
                    width: item.width,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            item.prop === "waybillNumber"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#409eff",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editFee(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row[item.prop]) + " ")]
                                )
                              : item.prop === "businessType"
                              ? _c("div", [
                                  scope.row[item.prop] === 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "basicData.CollectionSystemBusiness"
                                            )
                                          )
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            "" +
                                              _vm.$t(
                                                "basicData.CourierSystemBusiness"
                                              )
                                          )
                                        ),
                                      ]),
                                ])
                              : item.prop === "orderPackageType"
                              ? _c("div", [
                                  scope.row[item.prop] === 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("placeOrder.zhifa"))
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row[item.prop] === 2
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("placeOrder.CoPacking"))
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row[item.prop] === 3
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("basicData.other"))
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : item.prop === "collectType"
                              ? _c("div", [
                                  scope.row[item.prop] === 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("collectionCenter.dropOff")
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  scope.row[item.prop] === 2
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "collectionCenter.doorPickUp"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : item.prop === "collectionCategory"
                              ? _c("div", [
                                  scope.row[item.prop] === 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("basicData.OrdinaryPickUp")
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  scope.row[item.prop] === 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "basicData.StockingAndPickup"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : item.prop === "feeSource"
                              ? _c("div", [
                                  scope.row[item.prop] === 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "receivablePayable.SystemGenerated"
                                            )
                                          )
                                        ),
                                      ])
                                    : scope.row[item.prop] === 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "receivablePayable.QuickRecordGeneration"
                                            )
                                          )
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("basicData.other"))
                                        ),
                                      ]),
                                ])
                              : _c("div", [
                                  _vm._v(_vm._s(scope.row[item.prop])),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm._l(_vm.getCurrentColumns, function (item, ind) {
                return _c("el-table-column", {
                  key: ind + 100,
                  attrs: {
                    align: item.fixed,
                    label: item.label,
                    prop: item.prop,
                    "show-overflow-tooltip": true,
                    width: item.width,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row[item.prop]))]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.remark"),
                  "show-overflow-tooltip": true,
                  align: "left",
                  prop: "remark",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.remark))])]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  "current-page": _vm.queryForm.pageNumber,
                  "page-size": _vm.queryForm.pageSize,
                  "page-sizes": [10, 20, 50, 100],
                  total: _vm.total,
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "current-change": _vm.getARGenerationTableData,
                  "size-change": _vm.getARGenerationTableData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("receivablePayable.Search"),
            visible: _vm.showSearch,
            "custom-class": "drawerClass",
            direction: "rtl",
            size: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSearch = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("search-form", {
                key: _vm.showSearchKey,
                attrs: {
                  "is-read-only": false,
                  "query-form": _vm.queryForm,
                  "search-select-arr": _vm.searchSelectArr,
                },
                on: { search: _vm.searchFun },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            title:
              _vm.addType === 1
                ? _vm.$t("receivablePayable.AddChargesInBulk")
                : _vm.$t("receivablePayable.FeeDetails"),
            visible: _vm.showBatchAdd,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showBatchAdd = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("batch-add-fee", {
                key: _vm.batchAddKey,
                attrs: {
                  "add-type": _vm.addType,
                  "fee-type-option": _vm.feeTypeOption,
                  "query-fee-param": _vm.queryFeeParam,
                  "query-form": _vm.queryForm,
                  "select-data": _vm.selectData,
                },
                on: { saveForm: _vm.saveForm },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("receivablePayable.SnapshotFee"),
            visible: _vm.showAddSnapshotFee,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddSnapshotFee = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("add-snapshot-fee", {
                key: _vm.snapshotFeeKey,
                attrs: {
                  "expense-sub-list": _vm.expenseSubList,
                  "fee-type-option": _vm.feeTypeOption,
                  "query-fee-param": _vm.queryFeeParam,
                  "query-form": _vm.queryForm,
                  "select-data": _vm.selectData,
                },
                on: { saveFormSnapshot: _vm.saveFormSnapshot },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("orderCenterCont.SelectColumn"),
            visible: _vm.showColumn,
            "custom-class": "drawerClass",
            direction: "ltr",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showColumn = $event
            },
            close: function ($event) {
              return _vm.closeDrawer()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "columnTree",
              staticStyle: {
                padding: "20px",
                height: "calc(100vh - 140px)",
                overflow: "auto",
              },
            },
            [
              _c("el-tree", {
                key: _vm.dragging,
                ref: "elTree",
                attrs: {
                  "allow-drag": _vm.allowDrag,
                  "allow-drop": _vm.allowDrop,
                  data: _vm.columnList,
                  "default-checked-keys": _vm.checkedColumns,
                  props: _vm.props,
                  "check-on-click-node": "",
                  "default-expand-all": "",
                  draggable: "",
                  "node-key": "prop",
                  "show-checkbox": "",
                },
                on: {
                  check: _vm.handleCheckChange,
                  "node-drop": _vm.handleDrop,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                padding: "10px 20px",
                "text-align": "right",
                "border-top": "1px solid #ccc",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.determineColumns()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("operationCenter.determine")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("receivablePayable.AddANote"),
            visible: _vm.showAddNotes,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddNotes = $event
            },
            close: function ($event) {
              _vm.remarks = ""
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                attrs: { rows: 5, type: "textarea" },
                model: {
                  value: _vm.remarks,
                  callback: function ($$v) {
                    _vm.remarks = $$v
                  },
                  expression: "remarks",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.saveRemarks(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveRemarks(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.determine")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("receivablePayable.OperationalLogs"),
            visible: _vm.showJournal,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showJournal = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("operation-log", {
                key: _vm.keyJournal,
                attrs: { "check-data": _vm.checkData },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.expensesType === 1
                ? _vm.$t("receivablePayable.ImportAddCharges")
                : _vm.$t("receivablePayable.ImportModifyExpenses"),
            visible: _vm.showModification,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showModification = $event
            },
          },
        },
        [
          _vm.expensesType === 1
            ? _c(
                "div",
                [
                  _c("import-add-fee", {
                    on: { changeFeeList: _vm.changeFeeList },
                  }),
                ],
                1
              )
            : _vm.expensesType === 2
            ? _c(
                "div",
                [
                  _c("batch-change", {
                    on: { changeFeeList: _vm.changeFeeList },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("receivablePayable.tips"),
            visible: _vm.showGenerate,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showGenerate = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { height: "40vh", overflow: "hidden" } }, [
            _c("div", { staticStyle: { height: "100%", overflow: "auto" } }, [
              _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "receivablePayable.ReconciliationBatchGenerationSuccessful"
                    )
                  )
                ),
              ]),
              _c(
                "div",
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("receivablePayable.ReconciliationBatchNumber")
                    ) + " "
                  ),
                  _vm._l(_vm.batchNumber, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticStyle: { margin: "0 10px 5px 0" } },
                      [_c("span", [_vm._v(_vm._s(item.replace("~", "\n")))])]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("textarea", { attrs: { id: "message5" } }),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.copyBatchNumber(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.copyBatchNumber(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("receivablePayable.CopyTheBatchNumber")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("receivablePayable.RecalculateThePrice"),
            visible: _vm.recalculate,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.recalculate = $event
            },
            close: function ($event) {
              return _vm.changePage(false)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("recalculatePrice", {
                key: _vm.showManage1,
                attrs: {
                  "calculation-type": _vm.calculationType,
                  "get-param": _vm.queryForm.params,
                  "import-data": _vm.importData,
                  "select-data": _vm.selectData,
                },
                on: { changePage: _vm.changePage },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("receivablePayable.DeleteTheFee"),
            visible: _vm.showDeleteRow,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDeleteRow = $event
            },
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticStyle: { color: "red", "margin-bottom": "20px" } },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("receivablePayable.NoteAllDataSelectedChargeDeleted")
                  ) + " "
                ),
              ]
            ),
            _c(
              "div",
              [
                _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                _vm._v(_vm._s(_vm.$t("basicData.ExpenseType")) + "： "),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: _vm.$t("operationCenter.PleaseSelect"),
                      clearable: "",
                      filterable: "",
                    },
                    model: {
                      value: _vm.feeTypeName,
                      callback: function ($$v) {
                        _vm.feeTypeName = $$v
                      },
                      expression: "feeTypeName",
                    },
                  },
                  _vm._l(_vm.feeTypeOption, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.displayName, value: item.code },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.saveDeleteRow(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveDeleteRow(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.determine")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("receivablePayable.SelectGenerateAccrualTime"),
            visible: _vm.generateReceivables,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.generateReceivables = $event
            },
            close: function ($event) {
              _vm.TimeCollection = []
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("receivablePayable.SelectGenerateAccrualDate")
                    ) +
                    "： "
                ),
              ]),
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "end-placeholder": _vm.$t("operationCenter.endDate"),
                  "start-placeholder": _vm.$t("operationCenter.startDate"),
                  "range-separator": "-",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.TimeCollection,
                  callback: function ($$v) {
                    _vm.TimeCollection = $$v
                  },
                  expression: "TimeCollection",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.determineReceivables(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.determineReceivables(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.determine")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("receivablePayable.RecalculateThePrice"),
            visible: _vm.showImportTemplate,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImportTemplate = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("import-calculated-price", {
                key: _vm.showImportTemplate,
                attrs: { "calculation-type": _vm.calculationType },
                on: { importPrice: _vm.importPrice },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("receivablePayable.BatchImportClips"),
            visible: _vm.showBatchImportClips,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showBatchImportClips = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("batch-import-clips", {
                key: _vm.snapshotImportClips,
                attrs: { "outlets-list": _vm.outletsList },
                on: { importClips: _vm.importClips },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }