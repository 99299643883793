<template>
  <div class="SupplierContainer">
    <div class="seacher">
      <div :style="{width: isTormat === false ? '150px' :'370px' }" class="selectType">
        <el-select v-model="queryForm.params.searchType" :placeholder="$t('GlobalSetObj.pleaseChoose')">
          <el-option :label="$t('GlobalSetObj.supplierAbbreviation')" value="1" />
          <el-option :label="$t('GlobalSetObj.supplierCode')" value="2" />
          <el-option :label="$t('GlobalSetObj.businessEntity')" value="3" />
        </el-select>
      </div>
      <el-input
        v-model.trim="queryForm.params.body"
        :placeholder="$t('GlobalSetObj.pleaseInput')"
        clearable
        @keyup.enter.native.prevent="getSearchEnter"
      >
        <el-button slot="append" icon="el-icon-search" @click="getSearchEnter" />
      </el-input>
    </div>
    <div style="display: flex;justify-content: space-between;width: 100%;margin-bottom: 15px">
      <!-- 新增 -->
      <div>
        <el-button size="small" type="primary" @click="addSupplier">{{ $t('GlobalSetObj.add') }}</el-button>
        <el-button
          :disabled="isstart" plain size="small"
          type="primary" @click="handleStart(1)"
        >{{
          $t('ChannelManage.enable')
        }}
        </el-button>
        <el-button
          :disabled="isstop" plain size="small"
          type="danger" @click="handleStart(0)"
        >{{
          $t('ChannelManage.Disable')
        }}
        </el-button>
      </div>
      <!--      操作日志-->
      <el-button size="small" type="primary" @click="showOperationLogList">
        {{ $t('spareNumberPoo.OperationalLogs') }}
      </el-button>
    </div>

    <!--  表格数据  -->
    <AnjunTableList
      ref="anjunTable"
      :format-time="isTormat"
      :has-operation="true"
      :header-list="tableHeaderList"
      :is-index="false"
      :is-select-btn="true"
      :is-tip="true"
      :op-width="100"
      :table-data="tableData"
      @selectTable="selectTable"
      @on-edit="edit"
      @to-SystemName="toSupplierCode"
      @on-resetPassword="onResetPassword"
    />
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.number"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--添加系统服务商-->
    <el-drawer
      ref="drawer"
      :append-to-body="true"
      :title="titleName"
      :visible.sync="drawer"
      direction="ltr"
      size="55%"
    >
      <div style="padding:0 20px;height: calc(100vh - 67px); overflow: auto">
        <el-form
          :key="drawer"
          ref="addForm"
          :label-position="$i18n.locale!='zh'?'top':'right'"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
        >
          <el-row :gutter="30">
            <el-col style="font-size: 18px;font-weight: 600">{{ $t('GlobalSetObj.CompanyInformation') }}</el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.supplierAbbreviation')" prop="providerName">
                <el-input
                  v-model.trim="ruleForm.providerName"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  maxlength="50"
                  show-word-limit
                  type="text"
                  @blur="selectName()"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.businessEntity')" prop="providerBody">
                <el-input
                  v-model.trim="ruleForm.providerBody"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  @blur="getSelectProviderBody()"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="CNPJ" prop="cnpj">
                <el-input
                  v-model.trim="ruleForm.cnpj"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  maxlength="14"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.NationalRegistrationNumber')" prop="ie">
                <el-input
                  v-model.trim="ruleForm.ie"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.nationalHighway')" prop="registerOfCarriers">
                <el-input
                  v-model.trim="ruleForm.registerOfCarriers"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  maxlength="50"
                  type="text"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="ChannelInfo">
                <div>
                  <span style="color: red;padding-right: 3px">*</span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.selectChannelTip') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                  <span style="color:#606266;font-weight: 700 ">{{ $t('GlobalSetObj.ServiceProviderChannels') }}</span>
                </div>
                <el-select
                  v-model.trim="ruleForm.ChannelInfo"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  clearable
                  collapse-tags
                  filterable
                  multiple
                  style="width: 100%;"
                  @change="handleChannel"
                >
                  <el-option
                    v-for="item in StartupChannelList"
                    :key="item.id"
                    :label="item.providerChannelName"
                    :value="item.providerChannelCode"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.Istransportationcompany')" prop="isTransportCompany">
                <el-select
                  v-model.trim="ruleForm.isTransportCompany"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  clearable
                  style="width: 100%;"
                >
                  <el-option :label="$t('GlobalSetObj.yes')" :value="1" />
                  <el-option :label="$t('GlobalSetObj.No')" :value="0" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col style="font-size: 18px;font-weight: 600">{{ $t('GlobalSetObj.CompanyAddress') }}</el-col>
            <el-col>
              <el-form-item
                :label="$t('placeOrder.detailedAddress')"
                :rules="[{ required: true, message: $t('placeOrder.pleaseInput'), trigger: ['blur','change'] },
                         { min: 2, max: 200, message: $t('newOrder.TdetailedAddressCharacter'), trigger: 'blur' }]"
                prop="address"
              >
                <el-input
                  v-model="ruleForm.address"
                  :disabled="isSee"
                  :placeholder="$t('newOrder.StreetHouseNumber')"
                  clearable
                  maxlength="200"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--门牌号 -->
              <el-form-item
                :label="$t('placeOrder.HouseNumber')"
                prop="providerHouseNumber"
              >
                <el-input
                  v-model.trim="ruleForm.providerHouseNumber"
                  :disabled="isSee"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="5"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--区 -->
              <el-form-item
                :label="$t('placeOrder.Area')"
                prop="providerArea"
              >
                <el-input
                  v-model="ruleForm.providerArea"
                  :disabled="isSee"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="30"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--街道 -->
              <el-form-item
                :label="$t('newOrder.Street')"
                prop="providerStreet"
              >
                <el-input
                  v-model="ruleForm.providerStreet"
                  :disabled="isSee"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="50"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--城市 -->
              <el-form-item
                :label="$t('placeOrder.city')"
                prop="providerCity"
              >
                <el-input
                  v-model="ruleForm.providerCity"
                  :disabled="isSee"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                  maxlength="30"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 州： -->
              <el-form-item
                :label="$t('placeOrder.State')"
                prop="providerState"
              >
                <el-select
                  v-model="ruleForm.providerState"
                  :disabled="isSee"
                  :placeholder="$t('placeOrder.pleaseChoose')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in stateList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 邮编 -->
              <el-form-item
                :label="$t('placeOrder.zipcode')"
                :rules="isZipCode"
                prop="zipCode"
              >
                <el-input
                  v-model="ruleForm.zipCode"
                  :disabled="isSee"
                  :placeholder="$t('placeOrder.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col style="font-size: 18px;font-weight: 600">{{ $t('GlobalSetObj.Contactinformation') }}</el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.ContactName1')" prop="principleName">
                <el-input
                  v-model.trim="ruleForm.principleName"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  maxlength="50"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.ContactPhone')" :rules="isContactphonenumber" prop="phoneNumber">
                <el-input
                  v-model.trim="ruleForm.phoneNumber"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.ContactTel')" :rules="isContactTel" prop="providerTel">
                <el-input
                  v-model.trim="ruleForm.providerTel"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  maxlength="25"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.contactEmail')" prop="email">
                <el-input
                  v-model.trim="ruleForm.email"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col style="font-size: 18px;font-weight: 600">{{ $t('GlobalSetObj.Bankinformation') }}</el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.bank')" prop="bank">
                <el-select
                  v-model="ruleForm.bank"
                  :disabled="isSee"
                  :placeholder="$t('placeOrder.pleaseChoose')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in bankList"
                    :key="item.id"
                    :label="item.dictName"
                    :value="item.dictName"
                  >
                    <div style="display: flex;align-items: center">
                      <span style="float: left">
                        <img
                          :src="item.dictValue"
                          alt=""
                          style="width: 20px;height: 20px;margin-top: 5px"
                        >
                      </span>
                      <span style="margin-left: 20px;height: 34px;margin-top: -5px">{{
                        item.dictName
                      }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.bankAccount')" :rules="isbankAccount" prop="bankAccount">
                <el-input
                  v-model.trim="ruleForm.bankAccount"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.BankManager')" prop="bankRelationshipManager">
                <el-input
                  v-model.trim="ruleForm.bankRelationshipManager"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  maxlength="50"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="PIX" prop="pixKey">
                <el-input
                  v-model.trim="ruleForm.pixKey"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  maxlength="50"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.ResponsiblePerson')" prop="bankPrincipleName">
                <el-input
                  v-model.trim="ruleForm.bankPrincipleName"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  maxlength="50"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.CPFCNPJ')" :rules="isTempVal" prop="tempVal">
                <el-input
                  v-model="ruleForm.tempVal"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  class="input-with-select"
                >
                  <el-select
                    slot="prepend"
                    v-model="ruleForm.cpfTitle"
                    :disabled="isSee"
                    style="width: 80px"
                    @change="handleCp"
                  >
                    <el-option label="CNPJ" value="CNPJ" />
                    <el-option label="CPF" value="CPF" />
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="15" style="float: right">
              <div style="float: right;height: 80px">
                <div v-if="titleName === `${$t('orderCenterCont.ViewDetails')}`">
                  <el-button style="margin-right: 20px" type="primary" @click="cancelForm">{{
                    $t('GlobalSetObj.close')
                  }}
                  </el-button>
                </div>
                <div v-else>
                  <el-button style="margin-right: 20px" @click="cancelForm">{{
                    $t('GlobalSetObj.Cancelar')
                  }}
                  </el-button>
                  <el-button type="primary" @click="saveForm">{{ $t('GlobalSetObj.Salve') }}</el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-drawer>
    <!--    操作日志弹窗-->
    <el-drawer
      :title="$t('spareNumberPoo.OperationalLogs')"
      :visible.sync="operationLogVisible"
      direction="rtl"
      size="80vw"
    >
      <div style="height: calc(100vh - 80px);overflow-y: auto">
        <el-card class="box-card">
          <el-form
            ref="searchForm"
            v-model="operationLogQuery"
            label-position="top"
            label-width="auto"
          >
            <el-row :gutter="20">
              <el-col :span="8" style="height: 70px">
                <!--操作时间-->
                <el-form-item :label="$t('GlobalSetObj.operationTime')+'：'">
                  <el-date-picker
                    v-model="operationLogDate"
                    :clearable="false"
                    :default-time="['00:00:00', '23:59:59']"
                    :end-placeholder="$t('collectionCenter.EndTime')"
                    :range-separator="$t('collectionCenter.to')"
                    :start-placeholder="$t('collectionCenter.StartTime')"
                    style="width: 100%"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6" style="height: 70px">
                <el-form-item :label="$t('collectionCenter.operator')+'：'">
                  <el-select
                    v-model="operationLogQuery.params.operateUser"
                    :placeholder="$t('collectionCenter.pleaseChoose')"
                    clearable
                    filterable
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in operationUserList"
                      :key="index"
                      :label="item.realName"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" style="height: 70px">
                <el-form-item :label="$t('collectionCenter.typeOperation')">
                  <el-select
                    v-model="operationLogQuery.params.operateType"
                    :placeholder="$t('collectionCenter.pleaseChoose')"
                    clearable
                    filterable
                    style="width: 100%"
                  >
                    <el-option
                      :label="$t('collectionCenter.add')"
                      value="add"
                    />
                    <el-option
                      :label="$t('collectionCenter.edit')"
                      value="update"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4" style="height: 70px">
                <el-button style="margin-top: 27px" type="primary" @click="getOperationLogList(1)">
                  {{ $t('newOrder.search') }}
                </el-button>
              </el-col>
            </el-row>
          </el-form>
          <div style="margin-top: 20px">
            <el-table :data="operationLogList" border>
              <el-table-column
                :label="$t('GlobalSetObj.operationTime')"
                align="center"
                min-width="150px"
                property="operateTime"
              />
              <el-table-column
                :label="$t('GlobalSetObj.operator')"
                align="center"
                min-width="120px"
                property="operateUserName"
              />
              <el-table-column
                :label="$t('collectionCenter.operatedObject')"
                align="center"
                min-width="120px"
                property="operateObject"
              />
              <el-table-column
                :label="$t('orderCenterCont.typeOperation')"
                align="center"
                min-width="120px"
                property="operateType"
              />
              <el-table-column
                :label="$t('receivablePayable.BeforeOperation')"
                align="center"
                min-width="250px"
                property="oldData"
              />
              <el-table-column
                :label="$t('receivablePayable.AfterTheOperation')"
                align="center"
                min-width="250px"
                property="newData"
              />
            </el-table>
          </div>
          <el-pagination
            :current-page.sync="operationLogQuery.pageNumber"
            :page-size.sync="operationLogQuery.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="operationLogTotal"
            background
            layout="total, sizes, prev, pager, next, jumper"
            style="margin: 10px 0;text-align: right"
            @size-change="getOperationLogList()"
            @current-change="getOperationLogList()"
          />
        </el-card>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import AnjunTableList from '@/components/anjun-tableList';
import {
  addSupplierMain,
  apiSelectProviderBody,
  apiSelectProviderName,
  enableDisableButton,
  getBankList,
  providerOperationLogPage,
  supplierMainPage,
  UpdateSupplierMain
} from '@/api/SupplierMain.js';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { getStartupProviderChannel } from '@/api/channelManage';
import { bankAccount, Contactphonenumber, ContactTel, isZipCodeAsyncSupplier, val11, val14 } from '@/utils/validate';
import { getLgAreaListData } from '@/api/orderApi';
import { receivablePayable } from '@/lang/type/finance/receivableAndPayable';
import { scanUserList } from '@/api/lanshou';
import dayjs from 'dayjs';
import { emailReg } from '@/utils/reg_util';

export default {
  name: 'SupplierMain',
  components: {
    AnjunTableList
  },
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0,
        params: {
          searchType: '1',
          body: ''
        }
      },
      tableHeaderList: [
        { id: 1, label: this.$t('GlobalSetObj.supplierCode'), prop: 'supplierCode', cloWidth: 160 },
        { id: 3, label: this.$t('GlobalSetObj.supplierAbbreviation'), prop: 'providerName', cloWidth: 200 },
        { id: 7, label: this.$t('GlobalSetObj.businessEntity'), prop: 'providerBody', cloWidth: 200 },
        { id: 2, label: this.$t('GlobalSetObj.ServiceProviderChannels'), prop: 'providerChannelInfo', cloWidth: 300 },
        { id: 19, label: this.$t('GlobalSetObj.status'), prop: 'isStop', cloWidth: 100 },
        { id: 4, label: this.$t('GlobalSetObj.Istransportationcompany'), prop: 'isTransportCompanyName', cloWidth: 140 },
        // { id: 6, label: this.$t('GlobalSetObj.contactNumber'), prop: 'phoneNumber', cloWidth: 160 },
        { id: 8, label: this.$t('GlobalSetObj.Updater'), prop: 'updateUser', cloWidth: 140 },
        { id: 9, label: this.$t('GlobalSetObj.UpdateTime'), prop: 'updateTime', cloWidth: 150 }
      ],
      tableData: [],
      drawer: false,
      titleName: this.$t('GlobalSetObj.newSupplier'),
      ruleForm: {
        id: '',
        ChannelInfo: [],
        providerName: '',
        providerBody: '',
        address: '',
        providerState: '',
        providerCity: '',
        providerArea: '',
        providerStreet: '',
        providerHouseNumber: '',
        principleName: '',
        phoneNumber: '',
        providerTel: '',
        zipCode: '',
        bank: '',
        bankAccount: '',
        bankRelationshipManager: '',
        pixKey: '',
        bankPrincipleName: '',
        cnpj: '',
        tempVal: '',
        bankCnpj: '',
        cpf: '',
        ie: '',
        cpfTitle: 'CNPJ',
        registerOfCarriers: '',
        isTransportCompany: '',
        code: '',
        state: '',
        email: '' // 联系人邮箱
      },
      isZipCode: [
        { required: false, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
        { validator: isZipCodeAsyncSupplier, trigger: ['blur'] }
      ],
      // 联系人手机号码
      isContactphonenumber: [
        { required: false },
        { validator: Contactphonenumber, trigger: ['blur', 'change'] }
      ],
      // 联系人电话
      isContactTel: [
        { required: false },
        { validator: ContactTel, trigger: ['blur', 'change'] }
      ],
      // 联系人电话
      isbankAccount: [
        { required: false },
        { validator: bankAccount, trigger: ['blur', 'change'] }
      ],
      // 联系人电话
      isVal14: [
        { required: false },
        { validator: val14, trigger: ['blur', 'change'] }
      ],
      isTempVal: [
        { required: false },
        { validator: val14, trigger: ['blur', 'change'] }
      ],
      // 联系人电话
      isVal11: [
        { required: false },
        { validator: val11, trigger: ['blur', 'change'] }
      ],
      rules: {
        providerName: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInputSuppler'), trigger: ['blur', 'change'] },
          { min: 2, max: 50, message: this.$t('ChannelManage.inputStr'), trigger: ['blur', 'change'] }
        ],
        providerBody: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] }
        ],
        ChannelInfo: [
          { required: true, message: this.$t('GlobalSetObj.pleaseSelectChannel'), trigger: ['blur', 'change'] }
        ],
        cnpj: [
          { required: true, message: this.$t('GlobalSetObj.digiteCNPJ'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const reg = /^[0-9]{14}$/;
              if (reg.test(value)) {
                callback();
              } else {
                callback(new Error(this.$t('GlobalSetObj.digiteCNPJ')));
              }
            }, trigger: ['blur', 'change']
          }
        ],
        ie: [
          { required: false },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback();
              }
              const reg = /^[0-9]+$/;
              if (reg.test(value)) {
                callback();
              } else {
                callback(new Error(this.$t('GlobalSetObj.valorIE')));
              }
            }, trigger: ['blur', 'change']
          }
        ],
        principleName: [
          { required: true, message: this.$t('GlobalSetObj.inputContactName'), trigger: ['blur', 'change'] }
        ],
        registerOfCarriers: [
          { required: false, message: this.$t('GlobalSetObj.RNTRC'), trigger: ['blur', 'change'] }
        ]
      },
      isSee: false,
      editId: null,
      userIdAndNameObj: '',
      StartupChannelList: [],
      selectChannelList: [],
      providerNameCopy: null,
      providerBodyCopy: null,
      stateList: [],
      bankList: [],
      operationLogVisible: false, // 操作日志弹窗
      operationLogList: [], // 操作日志列表
      operationLogQuery: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          operateUser: '',
          operateType: '' // update 修改  add 新增
        }
      },
      operationLogDate: [], // 操作日志时间查询
      operationLogTotal: 0, // 操作日志总条数
      operationUserList: [], // 操作人列表
      selectDataList: [], // 勾选表格数据
      isstart: true,
      isstop: true
    };
  },
  computed: {
    receivablePayable() {
      return receivablePayable;
    }
  },
  watch: {
    drawer: {
      handler: function(val) {
        this.$refs['addForm']?.resetFields();
      },
      immediate: true
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getAddress();
    this.getBank();
    this.getSearch();
    this.userIdAndNameObj = getUserIdAndNameObj();
  },
  methods: {
    // 勾选
    selectTable(data) {
      console.log(data, '勾选');
      this.selectDataList = data;
      if (data.length > 0) {
        const isfalg = this.allValuesAreSame(this.selectDataList);
        console.log(isfalg, 'aaa');
        if (isfalg) {
          if (data[0].state === 1) {
            this.isstop = false;
          }
          if (data[0].state === 0) {
            this.isstart = false;
          }
        } else {
          this.isstart = true;
          this.isstop = true;
        }
      } else {
        this.isstart = true;
        this.isstop = true;
      }
    },
    // 重置密码
    onResetPassword(row) {
    },
    // 查询所有启动的渠道
    getStartupChannel() {
      const _this = this;
      return new Promise((resolve, reject) => {
        getStartupProviderChannel().then(res => {
          if (res.code===200) {
            console.log(res.data, '查询所有启动的渠道');
            // this.StartupChannelList;
            _this.StartupChannelList = res.data;
          }
          resolve();
        });
      });
    },
    // 校验名称是否重复
    selectName() {
      const param = {
        providerName: this.ruleForm.providerName
      };
      if (this.editId) {
        param.providerId = this.editId;
      }
      if (this.providerNameCopy !== this.ruleForm.providerName) {
        apiSelectProviderName(param);
      }
    },
    // 校验业务主体是否重复
    getSelectProviderBody() {
      const param = {
        providerBody: this.ruleForm.providerBody
      };
      if (this.providerBodyCopy !== this.ruleForm.providerBody) {
        apiSelectProviderBody(param);
      }
    },
    // 点击搜索
    getSearchEnter() {
      this.queryForm.number = 1;
      this.getSearch();
    },
    // 查询
    async getSearch() {
      const data = {};
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.number;
      data.params = {};
      if (this.queryForm.params.searchType == 1) {
        data.params.providerName = this.queryForm.params.body;
      } else if (this.queryForm.params.searchType == 2) {
        data.params.code = this.queryForm.params.body;
      } else {
        data.params.providerBody = this.queryForm.params.body;
      }
      const result = await supplierMainPage(data);
      if (result.code===200) {
        this.queryForm.total = result.data.total;
        this.tableData = result.data.records;
        this.tableData.map(item => {
          item.updateUser = getUserName(item.updateUser, this.userIdAndNameObj);
          item.isStop = item.state;
          if (item.isTransportCompany !== null) {
            item.isTransportCompanyName = item.isTransportCompany === 1 ? this.$t('GlobalSetObj.yes') : this.$t('GlobalSetObj.No');
          }
          item['edit'] = true;
          // item['state'] = 2;
          item['supplierCode'] = item.code;
        });
      }
    },
    // 添加
    addSupplier() {
      this.getStartupChannel();
      this.isSee = false;
      this.drawer = true;
      this.editId = null;
      this.$nextTick(_ => {
        this.ruleForm.providerChannelInfo = [];
        this.ruleForm.cpfTitle = 'CNPJ';
      });
      this.titleName = this.$t('GlobalSetObj.newSupplier');
    },
    // 编辑
    edit(row) {
      console.log(row, 'rowrowrow');
      this.getStartupChannel().then(() => {
        if (row.code === 'S202305080000001') {
          console.log(123213);
          const BRAZILarr = this.StartupChannelList.filter(function(obj) {
            return obj.systemId === 1;
          });
          this.StartupChannelList = BRAZILarr;
        } else {
          const BRAZILarr1 = this.StartupChannelList.filter(function(obj) {
            return obj.systemId !== 1;
          });
          this.StartupChannelList = BRAZILarr1;
          // 找到providerChannelCode等于"ANJUN_COMPANY"的项，并将其移至数组的第一位
          for (let i = 0; i < this.StartupChannelList.length; i++) {
            if (this.StartupChannelList[i].providerChannelCode === 'ANJUN_COMPANY') {
              const temp = this.StartupChannelList[0];
              this.StartupChannelList[0] = this.StartupChannelList[i];
              this.StartupChannelList[i] = temp;
              break;
            }
          }
        }
        this.isSee = false;
        this.drawer = true;
        this.titleName = this.$t('GlobalSetObj.editSupplier');
        this.editId = row.id;
        this.providerNameCopy = row.providerName;
        this.providerBodyCopy = row.providerBody;
        this.$nextTick(_ => {
          if (row.providerChannelInfo) {
            const channerlInfo = row.providerChannelInfo.split(',');
            const arr = this.StartupChannelList.filter(function(obj) {
              return channerlInfo.includes(obj.providerChannelName);
            });
            console.log(arr, 'arrarrarrarrarrarr');
            arr.forEach(item => {
              this.ruleForm.ChannelInfo.push(item.providerChannelCode);
            });
            this.handleChannel(this.ruleForm.ChannelInfo);
          }
          this.ruleForm.providerName = row.providerName;
          this.ruleForm.providerBody = row.providerBody;
          this.ruleForm.cnpj = row.cnpj;
          this.ruleForm.ie = row.ie;
          this.ruleForm.address = row.address;
          this.ruleForm.providerState = row.providerState;
          this.ruleForm.providerCity = row.providerCity;
          this.ruleForm.providerArea = row.providerArea;
          this.ruleForm.providerStreet = row.providerStreet;
          this.ruleForm.providerHouseNumber = row.providerHouseNumber;
          this.ruleForm.principleName = row.principleName;
          this.ruleForm.phoneNumber = row.phoneNumber;
          this.ruleForm.providerTel = row.providerTel;
          this.ruleForm.zipCode = row.zipCode;
          this.ruleForm.bank = row.bank;
          this.ruleForm.bankAccount = row.bankAccount;
          this.ruleForm.bankRelationshipManager = row.bankRelationshipManager;
          this.ruleForm.pixKey = row.pixKey;
          this.ruleForm.bankPrincipleName = row.bankPrincipleName;
          this.ruleForm.bankCnpj = row.bankCnpj;
          this.ruleForm.registerOfCarriers = row.registerOfCarriers;
          this.ruleForm.isTransportCompany = row.isTransportCompany;
          this.ruleForm.code = row.code;
          this.ruleForm.state = row.state;
          this.ruleForm.email = row.email;
          if (row.bankCnpj) {
            this.ruleForm.tempVal = row.bankCnpj;
            this.ruleForm.cpfTitle = 'CNPJ';
            this.isTempVal = this.isVal14;
          }
          if (row.cpf) {
            this.ruleForm.tempVal = row.cpf;
            this.ruleForm.cpfTitle = 'CPF';
            this.isTempVal = this.isVal11;
          }
        });
      });
    },
    toSupplierCode(row) {
      // 查看
      this.drawer = true;
      this.isSee = true;
      this.titleName = this.$t('orderCenterCont.ViewDetails');
      this.$nextTick(_ => {
        if (row.providerChannelInfo) {
          const channerlInfo = row.providerChannelInfo.split(',');
          const arr = this.StartupChannelList.filter(function(obj) {
            return channerlInfo.includes(obj.providerChannelName);
          });
          arr.forEach(item => {
            this.ruleForm.ChannelInfo.push(item.providerChannelCode);
          });
          this.handleChannel(this.ruleForm.ChannelInfo);
        }
        this.ruleForm.providerName = row.providerName;
        this.ruleForm.providerBody = row.providerBody;
        this.ruleForm.cnpj = row.cnpj;
        this.ruleForm.ie = row.ie;
        this.ruleForm.address = row.address;
        this.ruleForm.providerState = row.providerState;
        this.ruleForm.providerCity = row.providerCity;
        this.ruleForm.providerArea = row.providerArea;
        this.ruleForm.providerStreet = row.providerStreet;
        this.ruleForm.providerHouseNumber = row.providerHouseNumber;
        this.ruleForm.principleName = row.principleName;
        this.ruleForm.phoneNumber = row.phoneNumber;
        this.ruleForm.providerTel = row.providerTel;
        this.ruleForm.zipCode = row.zipCode;
        this.ruleForm.bank = row.bank;
        this.ruleForm.bankAccount = row.bankAccount;
        this.ruleForm.bankRelationshipManager = row.bankRelationshipManager;
        this.ruleForm.pixKey = row.pixKey;
        this.ruleForm.bankPrincipleName = row.bankPrincipleName;
        this.ruleForm.bankCnpj = row.bankCnpj;
        this.ruleForm.registerOfCarriers = row.registerOfCarriers;
        this.ruleForm.isTransportCompany = row.isTransportCompany;
        this.ruleForm.code = row.code;
        this.ruleForm.state = row.state;
        if (row.bankCnpj) {
          this.ruleForm.tempVal = row.bankCnpj;
          this.ruleForm.cpfTitle = 'CNPJ';
          this.isTempVal = this.isVal14;
        }
        if (row.cpf) {
          this.ruleForm.tempVal = row.cpf;
          this.ruleForm.cpfTitle = 'CPF';
          this.isTempVal = this.isVal11;
        }
      });
    },
    // 取消
    cancelForm() {
      this.drawer = false;
    },
    // 选择服务商渠道
    handleChannel(val) {
      console.log(this.ruleForm.providerChannelInfo, 'providerChannelInfo', val);
      this.selectChannelList = this.StartupChannelList.filter(function(obj) {
        return val.includes(obj.providerChannelCode);
      });
      console.log(this.selectChannelList, ' this.selectChannelList');
    },
    // 提交保存
    async saveForm() {
      const valid = await this.$refs['addForm'].validate();
      if (valid) {
        if (this.ruleForm.email && !emailReg.test(this.ruleForm.email)) {
          this.$message.error(this.$t('collectionCenter.emailNotFormat'));
          return;
        }
        let data = {};
        if (this.ruleForm.cpfTitle === 'CNPJ') {
          this.ruleForm.bankCnpj = this.ruleForm.tempVal;
        } else {
          this.ruleForm.cpf = this.ruleForm.tempVal;
        }
        data = this.ruleForm;
        data.providerChannelInfo = JSON.stringify(this.selectChannelList);
        // return;
        if (this.titleName === this.$t('GlobalSetObj.newSupplier')) {
          delete data.id;
          addSupplierMain(data).then(res => {
            if (res.code===200) {
              this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
              this.drawer = false;
              this.getSearch();
            } else {
              this.drawer = false;
            }
          });
        } else {
          data.id = this.editId;
          UpdateSupplierMain(data).then(res => {
            if (res.code===200) {
              this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
              this.drawer = false;
              this.getSearch();
            } else {
              this.drawer = false;
            }
          });
        }
      }
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.number = val;
      this.getSearch();
    },
    // 获取银行清单
    getBank() {
      getBankList().then(res => {
        if (res.code===200) {
          console.log(res.data, '获取银行清单');
          this.bankList = res.data;
        }
      });
    },
    // 级联地区
    getAddress() { // level 0 州    1 城市   2 地区
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.code===200) {
          this.stateList = res.data.map(item => {
            return {
              id: item.id,
              // label: `${item.nameEn}${item.nameCn ? '(' + item.nameCn + ')' : ''}${item.twoCode ? '(' + item.twoCode + ')' : ''}`
              label: item.nameEn
            };
          });
        }
      });
    },
    handleCp(val) {
      console.log(val, 'handleCp');
      this.ruleForm.tempVal = '';
      this.ruleForm.bankCnpj = '';
      this.ruleForm.cpf = '';
      this.ruleForm.cpfTitle = val;
      if (val === 'CPF') {
        this.isTempVal = this.isVal11;
      } else {
        this.isTempVal = this.isVal14;
      }
    },
    // 邮编校验
    handleStart(isStop) {
      const listId = [];
      this.selectDataList.forEach(item => {
        listId.push(item.id);
      });
      const data = {
        providerIds: listId,
        providerStatus: isStop
      };
      enableDisableButton(data).then(res => {
        // console.log('验证邮编', res);
        if (res.code===200) {
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.getSearch();
          this.$refs['anjunTable'].toggleSelection();
        }
      });
    },
    /**
     * @description: 查看操作日志
     */
    showOperationLogList() {
      this.operationLogVisible = true;
      // moment获取最近一周的开始时间和结束时间
      const startTime = dayjs().subtract(7, 'day').format('YYYY-MM-DD 00:00:00');
      const endTime = dayjs().format('YYYY-MM-DD 23:59:59');
      this.operationLogDate = [startTime, endTime];
      this.operationLogQuery = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          operateUser: '',
          operateType: '' // update 修改  add 新增
        }
      };
      this.getOperationLogList();
    },
    /**
     * @description: 获取操作日志
     */
    getOperationLogList(pageNumber) {
      if (!this.operationUserList.length) {
        this.getOperationUserList();
      }
      if (pageNumber) {
        this.operationLogQuery.pageNumber = pageNumber;
      }
      const params = {
        ...this.operationLogQuery,
        params: {
          ...this.operationLogQuery.params
        }
      };
      if (this.operationLogDate && this.operationLogDate.length) {
        params.params.operateStartTime = this.operationLogDate[0];
        params.params.operateEndTime = this.operationLogDate[1];
      }
      providerOperationLogPage(params).then(res => {
        if (res.code===200) {
          this.operationLogList = res.data.records;
          this.operationLogTotal = res.data.total;
        }
      });
    },
    /**
     * @description: 获取操作人列表
     */
    getOperationUserList() {
      const form = {};
      scanUserList(form).then(res => {
        if (res.code===200) {
          this.operationUserList = [];
          res.data.forEach(data => {
            this.operationUserList.push({ id: data.id, realName: data.realName });
          });
        }
      });
    },
    // 判断是否可以统一点击启停用
    allValuesAreSame(arr) {
      if (arr.length > 0) {
        const firstValue = arr[0].state;
        for (let i = 1; i < arr.length; i++) {
          if (arr[i].state !== firstValue) {
            return false;
          }
        }
      }
      return true;
    }
  }
};
</script>

<style scoped>
.SupplierContainer {
  padding: 15px;
}

.seacher {
  /*display: flex;*/
  width: 350px;
  margin-bottom: 15px;
}

/*.selectType {*/
/*  width: 150px;*/
/*  !*display: inline-block;*!*/
/*}*/

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

.SupplierContainer /deep/ .el-drawer__header {
  margin-bottom: 20px !important;
  color: black;
  font-size: 20px;
}

.demo-drawer__footer {
  width: 100%;
  border-top: 1px solid #cccccc;
  display: flex;
  justify-content: right;
  padding: 10px;
  right: 10px;
}

.serviceType {
  width: 100%;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding-left: 10px;
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.QuestionMark {
  width: 30px;
  text-align: center;
  cursor: pointer;
}
</style>
