<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="grid-content bg-purple order-Type">
          <el-tabs type="border-card">
            <el-tab-pane>
              <template slot="label">
                <i class="iconfont icon-zichan-quanbushebei" style="margin-right: 5px;color:#0d47a1!important;" />
                <span style="color:#0d47a1!important;">
                  <!--全部-->
                  {{ $t('newOrder.all') }}（{{ orderNumber.orderAll.length }}）
                </span>
              </template>
              <el-collapse v-if="orderNumber.orderAll.length>0" v-model="activeName" accordion>
                <el-collapse-item
                  v-for="(orderItem, orderIndex) in orderNumber.orderAll"
                  :key="orderIndex"
                  :title="orderNumber.orderAll[0].orderNum"
                  name="1"
                >
                  <template slot="title">
                    <div style="float: left;">
                      <span
                        :class="classData"
                        class="iconStyle"
                      >
                        <!--揽收-->
                        <el-image
                          v-if="orderItem.orderStatus === 1"
                          :src="require('@/assets/lanshou.png')"
                          style="border-radius: 5px;"
                        />
                        <span v-else :class="iconData" class="iconfont" style="font-size: 18px;color:#fff;" />
                      </span>
                      <span style="margin-left: 10px;float: left;">{{ orderItem.orderNum }}</span>
                      <!--查询不到-->
                      <span
                        v-if="orderItem.orderStatus == 0"
                        style="margin-left: 10px;"
                      >{{ $t('orderCenterCont.NotFound') }}</span>
                      <span
                        v-else
                        style="margin-left: 10px;"
                      >
                        {{ orderItem.orderList[0].status }}
                      </span>
                    </div>
                    <div style="float: left;margin-left: 30px;">
                      <span v-if="orderItem.orderStatus != 0">{{ orderItem.orderList[0].dateTime }}</span>
                    </div>
                    <!--                    <div v-if="orderItem.orderList.length > 0" style="float: left;margin-left: 30px;">-->
                    <!--                      <span v-if="orderItem.orderStatus != 0">-->
                    <!--                        {{ orderItem.orderList[0].siteName }}</span>-->
                    <!--                    </div>-->
                    <div style="float: left;margin-left: 30px;">
                      <span v-if="orderItem.orderStatus != 0">
                        {{ orderItem.orderList[0].address }}</span>
                    </div>
                  </template>
                  <div v-if="orderItem.orderStatus != 0" style="border-top: 1px solid #ccc;padding-top: 20px;">
                    <el-timeline>
                      <el-timeline-item
                        v-for="(item, index) in orderItem.orderList"
                        :key="index"
                        :color="index===0?'#409EFF':''"
                        :timestamp="`${item.dateTime}；  ${item.status}；  ${item.address}`"
                      />
                      <!--                      :timestamp="item.dateTime + '; ' + item.status + ', ' + item.collectType?item.collectType+', ':'' + item.signTypeName?item.signTypeName+', ':''+ `${item.problemType ? item.problemType + ', ' : '',item.siteName}` + item.address"-->
                    </el-timeline>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getTrackQueryTable } from '@/api/trackQuery';

export default {
  name: 'TrackQuery',
  props: {
    queryTrackParam: {
      type: Object,
      default: () => {
        return {};
      }
    },
    queryType: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      orderNumber: {
        orderAll: []
      },
      orderList: [],
      orderContainer: '',
      activeName: '1',
      reverse: true,
      queryNumber: 0,
      orderNumArr: [],
      classData: '',
      iconData: ''
    };
  },
  created() {
    // this.orderContainer = this.queryTrackParam.referenceNumber;
    this.queryOrder();
  },
  methods: {
    // 获取查询订单数量
    getQueryNumber() {
      const orderNum = this.orderContainer.split(/[(\r\n)\r\n]+/);
      this.orderNumArr = [];
      for (let j = 0; j < orderNum.length; j++) {
        if (orderNum[j] !== '') {
          const orderNumber = orderNum[j].trim();
          if (this.orderNumArr.indexOf(orderNumber) === -1) {
            this.orderNumArr.push(orderNumber.trim());
          }
        }
      }
      this.queryNumber = this.orderNumArr.length;
    },
    // 获取订单
    queryOrder() {
      this.orderNumber.orderAll = [];
      // const param = this.queryType === '1' ? this.queryTrackParam.logisticsNumber : this.queryTrackParam.trackNo;
      const param = this.queryTrackParam.waybillNumber;
      // const param = 'CL2022112200000014855';
      getTrackQueryTable(param).then(res => {
        if (res.code===200) {
          const order = res.data;
          const orderDetail = order || []; // JSON.parse(order);
          orderDetail.orderNum = this.queryTrackParam.waybillNumber; // res.data.extra.trackNo;
          orderDetail.orderList = [];
          if (orderDetail.length > 0) {
            orderDetail.map(item => {
              orderDetail.orderList.push(item);
            });
            if (
              orderDetail[0].statusCode === 'collected' ||
              orderDetail[0].statusCode === 'collect_in_storage' ||
              orderDetail[0].statusCode === 'collect_out_storage' ||
              orderDetail[0].statusCode === 'pack_bag' ||
              orderDetail[0].statusCode === 'collect_uploading_truck'
            ) {
              // 揽收中
              this.classData = 'lanshou';
              orderDetail.orderStatus = 1;
              this.orderNumber.orderAll.push(orderDetail);
            } else if (
              orderDetail[0].statusCode === 'transfer_in_storage' ||
              orderDetail[0].statusCode === 'transfer_out_storage' ||
              orderDetail[0].statusCode === 'warehouse_temporary_storage' ||
              orderDetail[0].statusCode === 'collect_sign'
            ) {
              // 入库、出库
              orderDetail.orderStatus = 2;
              this.classData = 'cangku';
              this.iconData = 'icon-cangku';
              this.orderNumber.orderAll.push(orderDetail);
            } else if (
              orderDetail[0].statusCode === 'delivery_site_in_storage' ||
              orderDetail[0].statusCode === 'delivering' ||
              orderDetail[0].statusCode === 'delivery_uploading_truck' ||
              orderDetail[0].statusCode === 'customs_inspection' ||
              orderDetail[0].statusCode === 'returning'
            ) {
              // 运输中
              orderDetail.orderStatus = 3;
              this.classData = 'yunshu';
              this.iconData = 'icon-yunshuzhongwuliu-xianxing';
              this.orderNumber.orderAll.push(orderDetail);
            } else if (
              orderDetail[0].statusCode === 'signed' ||
              orderDetail[0].statusCode === 'return_completed'
            ) {
              // 已签收
              orderDetail.orderStatus = 4;
              this.classData = 'tuotou';
              this.iconData = 'icon-chenggong';
              this.orderNumber.orderAll.push(orderDetail);
            } else {
              // 异常
              this.classData = 'yichang';
              this.iconData = 'icon-yichangtishi';
              orderDetail.orderStatus = 5;
              this.orderNumber.orderAll.push(orderDetail);
            }
          } else {
            // 查不到
            orderDetail.orderStatus = 0;
            this.classData = 'chabudao';
            this.iconData = 'icon-fail';
            this.orderNumber.orderAll.push(orderDetail);
          }
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
      });
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-collapse-item__header {
  font-weight: bold;
}

.app-container >>> .order-Type .el-tabs__item {
  padding: 0 10px;
}

.iconStyle {
  margin-top: 10px;
  display: inline-block;
  width: 30px;
  height: 30px;
  float: left;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
  line-height: 30px;
}

.app-container >>> .el-timeline-item__timestamp.is-bottom {
  margin-top: 0;
}

.app-container >>> .el-timeline-item__timestamp {
  /*color: #000;*/
}

.lanshou {
  background: #0E843D;
}

.cangku {
  background: #2196f3
}

.yunshu {
  background: #5B62FF
}

.yichang {
  background: #FF6F00;
}

.chabudao {
  background: #797676;
}

.tuotou {
  background: #43A047
}
</style>
