var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "complaint-work-order" }, [
        _c(
          "div",
          { ref: "messageListRef", staticClass: "message-content-bg" },
          _vm._l(_vm.messageDetail, function (item, index) {
            return _c("div", { key: index }, [
              item.createUser !== _vm.currentId
                ? _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
                    _c("div", { staticStyle: { "text-align": "left" } }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "10px",
                            color: "#409eff",
                          },
                        },
                        [_vm._v(_vm._s(item.createUserName))]
                      ),
                      _c("span", [_vm._v(_vm._s(item.createTime))]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "complaint-work-order-message-content",
                        staticStyle: { float: "left" },
                      },
                      [
                        _c("div", {
                          staticStyle: { "text-align": "left" },
                          domProps: { innerHTML: _vm._s(item.message) },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.getImgSrc($event)
                            },
                          },
                        }),
                        item.mediaUrl
                          ? _c(
                              "div",
                              { staticStyle: { "margin-top": "10px" } },
                              _vm._l(item.urlArr, function (list, ind) {
                                return _c(
                                  "div",
                                  {
                                    key: ind,
                                    staticClass: "complaint-work-file",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getImgSrcFile(list.url)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(list.name) + " ")]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c("div", { staticStyle: { clear: "both" } }),
                  ])
                : _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
                    _c("div", { staticStyle: { "text-align": "right" } }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "10px",
                            color: "#409eff",
                          },
                        },
                        [_vm._v(_vm._s(item.createUserName))]
                      ),
                      _c("span", [_vm._v(_vm._s(item.createTime))]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "complaint-work-order-message-content",
                        staticStyle: { float: "right" },
                      },
                      [
                        _c("div", {
                          staticStyle: { "text-align": "right" },
                          domProps: { innerHTML: _vm._s(item.message) },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.getImgSrc($event)
                            },
                          },
                        }),
                        item.mediaUrl
                          ? _c(
                              "div",
                              { staticStyle: { "margin-top": "10px" } },
                              _vm._l(item.urlArr, function (list, ind) {
                                return _c(
                                  "div",
                                  {
                                    key: ind,
                                    staticClass: "complaint-work-file",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getImgSrcFile(list.url)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(list.name) + " ")]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c("div", { staticStyle: { clear: "both" } }),
                  ]),
            ])
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticStyle: { padding: "10px" } },
        [
          _c("tinymce", {
            attrs: {
              height: 200,
              "msg-plugin": _vm.msgPlugin,
              placeholder: _vm.$t("GlobalSetObj.pleaseInputContent") + "...",
              toolbar: _vm.toolbar,
            },
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { padding: "10px", "text-align": "right" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submitMsg },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Reply")) + " ")]
          ),
        ],
        1
      ),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: { "on-close": _vm.closeViewer, "url-list": _vm.imgArr },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }