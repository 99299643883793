<!-- 添加费用 -->
<template>
  <el-drawer
    :append-to-body="true"
    :title="$t('receivablePayable.FeeDetails')"
    :visible.sync="isVisible"
    custom-class="drawerClass"
    direction="ltr"
    size="80%"
  >
    <div class="details">
      <el-card :body-style="{padding: 0}">
        <OrderTable
          ref="addItemRef"
          :key="key"
          :query-form="queryForm"
          :select-data="selectData"
          :is-open="isVisible"
          :payable-type="payableType"
          @getSite="getSite"
        />
      </el-card>

      <el-card :body-style="{padding: 0}">
        <!-- 费用明细 -->
        <FeeTable
          ref="detailsTableRef"
          :add-type="1"
          :fee-type-option="feeTypeOption"
          :query-form="queryForm"
          :list="addBatchFeeList"
        />
      </el-card>
      <!-- 按钮 -->
      <div class="btn">
        <!--保存-->
        <el-button
          v-permit:remove="permission"
          type="primary"
          size="small"
          @click="submit"
        >{{ $t('basicData.Save') }}</el-button>
        <!--关闭-->
        <el-button size="small" @click="onClose">{{ $t('orderCenterCont.ShutDown') }}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import OrderTable from './orderTable.vue';
import FeeTable from './feeTable.vue';
import {
  apiBatchAddClAndLgFee,
  apiPayableSiteExpenseBatchAddFee
} from '@/api/finance/receivables';
import { isSearchAllEmpty } from '@/views/finance/payableManage/feesPayable/enums.js';
import { getPermission } from './utils.js';

export default {
  name: '',

  components: {
    OrderTable,
    FeeTable
  },

  props: {
    // 费用类型
    feeTypeOption: {
      type: Array,
      default: () => {
        return [];
      }
    },

    // 外部搜索条件
    queryForm: {
      type: Object,
      default: () => {
        return {};
      }
    },

    // 勾选
    selectData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 账单类型
    payableType: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      isVisible: false,
      key: 1,
      addBatchFeeList: [],
      permission: '', // 权限
      siteIdList: ''
    };
  },

  activated() {
    this.permission = getPermission(this.payableType);
  },
  created() {
    this.permission = getPermission(this.payableType);
  },

  methods: {
    open() {
      this.isVisible = true;
      this.key += 1;
      this.addBatchFeeList = [];
    },
    getSite(id) {
      this.siteIdList = id;
    },
    /**
     * 新增费用
     */
    submit() {
      const isEmptyCondition = isSearchAllEmpty(this.queryForm.params); // 搜索条件是否为空
      const waybillNumberList = this.$refs.addItemRef.getWaybillNumberList(); // 获取订单信息
      const list = this.$refs.detailsTableRef.submitEdit(); // 获取费用明细
      const str = this.$i18n.t('basicData.请添加订单信息'); // 添加订单信息
      const str1 = this.$i18n.t('receivablePayable.AddAtLeastOneCharge'); // '最少添加一条费用'
      const str2 = this.$i18n.t('receivablePayable.OnlyOneChargeCanBeAdded'); // '只能添加一条费用'
      // 1.校验订单信息是否为空
      {
        const len = waybillNumberList.length;
        if (len === 0) {
          this.$message.warning(str); // 添加订单信息
          // return;
        }
      }
      // // 2.1校验费用明细是否新增费用
      // // 1.校验搜索条件是否为空
      // {
      //   // 为空新增
      //   if (isEmptyCondition) {
      //     // 2.校验订单数据
      //     const len = waybillNumberList.length;
      //     if (len === 0) {
      //       this.$message.warning(str); // 添加订单信息
      //       return;
      //     }
      //   } else {
      //     // 未勾选数据
      //     if (this.selectData.length === 0) {
      //       // 2.校验订单数据
      //       const len = waybillNumberList.length;
      //       if (len === 0) {
      //         this.$message.warning(str); // 添加订单信息
      //         return;
      //       }
      //     }
      //   }
      // }

      // 2.校验费用
      {
        const len = list.length;
        if (len === 0) {
          this.$message.warning(str1); // '最少添加一条费用'
          return;
        }

        if (len !== 1) {
          this.$message.warning(str2); // '只能添加一条费用'
          return;
        }
      }

      const { params } = this.queryForm;
      const obj = {
        condition: params, // 外部搜索栏的搜索条件
        businessType: 0,
        isEmptyCondition: isEmptyCondition, // 搜索条件是否都为空
        orderNums: [],
        expenseSubList: list,
        payableType: this.payableType
      };

      // 勾选的数据--
      if (this.selectData.length > 0) {
        params.ids = this.selectData.map(item => {
          return item.id;
        });
        obj.isEmptyCondition = true; // 与后台约定如果勾选了，则给后台设置搜索标识条件为空
      }
      // 订单信息
      if (waybillNumberList.length) {
        obj.businessType = waybillNumberList[0].businessType; // 业务类型
        obj.orderNums = waybillNumberList.map(item => item.waybillNumber); // 业务单号
      }
      if (this.payableType === 5) {
        obj.siteId = this.siteIdList;
        apiBatchAddClAndLgFee(obj).then(res => {
          if (res.code !== 200) { return; }
          this.$message.success(this.$i18n.t('GlobalSetObj.SaveSuccessfully')); // 保存成功
          this.onClose(true);
        });
      } else {
        // return;
        apiPayableSiteExpenseBatchAddFee(obj).then(res => {
          if (res.code !== 200) { return; }
          this.$message.success(this.$i18n.t('GlobalSetObj.SaveSuccessfully')); // 保存成功
          this.onClose(true);
        }).catch(err => {
          console.error(err);
        });
      }
    },

    onClose(isBool = false) {
      this.isVisible = false;
      this.$emit('close', isBool);
    }
  }

};
</script>

<style scoped>
.details {
  width: 100%;
  height: calc(100vh - 80px);
  overflow-y: scroll;
}
.btn {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #eeefee;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000;
}
</style>
