<template>
  <el-card>
    <el-button type="primary" icon="el-icon-plus" @click="addRow">{{ $t('GlobalSetObj.add') }}</el-button>
    <el-button type="primary" icon="el-icon-plus" @click="submitForm">{{ $t('operationCenter.verify') }}</el-button>
    <el-form
      ref="ruleForm"
      :model="formData"
      label-width="0"
      class="demo-ruleForm"
      size="small"
    >
      <div>
        <el-form-item
          :label="$t('GlobalSetObj.client') + '：'"
          label-width="85px"
          prop="customerIdList"
          :rules="{
            required: true,
            message: $t('GlobalSetObj.pleaseChoose'),
            trigger: ['blur', 'change']
          }"
        >
          <el-select
            v-model="formData.customerIdList"
            :placeholder="$t('GlobalSetObj.pleaseChoose')"
            filterable
            multiple
            clearable
            class="select"
            style="width: 100%;"
          >
            <el-option
              v-for="item of customerList"
              :key="item.id"
              :label="item.customerAlias + '（' + item.username + '）'"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
      <el-table
        :data="formData.arr"
        border
      >
        <!-- 对应揽收时间 -->
        <el-table-column
          prop="collectTime"
          :label="`${$t('routes.对应揽收时间')}*`"
          min-width="300"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="`arr.${scope.$index}.startCollect`"
              :rules="{
                required: true,
                validator:(rule, value, callback)=>collectTimeCheck(rule, value, callback, scope.row, formData.arr, 1)
              }"
              style="display: inline-block"
            >
              <el-time-picker
                v-model="scope.row.startCollect"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                format="HH:mm"
                value-format="HH:mm"
                style="width: 130px;"
              />
            </el-form-item>
            <span style="margin: 0 5px;">~</span>
            <el-form-item
              :prop="`arr.${scope.$index}.endCollect`"
              :rules="{
                required: true,
                validator:(rule, value, callback)=>collectTimeCheck(rule, value, callback, scope.row, formData.arr, 2)
              }"
              style="display: inline-block"
            >
              <el-time-picker
                v-model="scope.row.endCollect"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                format="HH:mm"
                value-format="HH:mm"
                style="width: 130px;"
              />
            </el-form-item>
          </template>

        </el-table-column>

        <!-- 对应揽收装车完成时间 -->
        <el-table-column
          prop="collectLoadFinishTime"
          :label="`${$t('routes.对应揽收装车完成时间')}*`"
          min-width="280"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="`arr.${scope.$index}.collectLoadFinishTime`"
              :rules="{
                required: true,
                validator:(rule, value, callback)=>collectLoadFinishTimeCheck(rule, value, callback, scope.row)
              }"
            >
              <el-time-picker
                v-model="scope.row.collectLoadFinishTime"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 100%;"
                format="HH:mm"
                value-format="HH:mm"
              />
            </el-form-item>
          </template>

        </el-table-column>

        <!-- 对应HUB1到件时间 -->
        <el-table-column
          prop="hub1ArrivalTime"
          :label="`${$t('routes.对应HUB1到件时间')}*`"
          min-width="280"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="`arr.${scope.$index}.hub1ArrivalTime`"
              :rules="{
                required: true,
                validator:(rule, value, callback)=>hub1ArrivalTimeCheck(rule, value, callback, scope.row)
              }"
            >
              <el-time-picker
                v-model="scope.row.hub1ArrivalTime"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 100%;"
                format="HH:mm"
                value-format="HH:mm"
              />
            </el-form-item>
          </template>

        </el-table-column>

        <el-table-column
          width="100"
        >
          <template slot-scope="scope">
            <el-button type="danger" size="mini" @click="delRow(scope.$index)">{{ $t('GlobalSetObj.Delete') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </el-card>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import {
  COLLECT_TIME_ROW,
  collectTimeCheck,
  collectLoadFinishTimeCheck,
  hub1ArrivalTimeCheck
} from './../utils';

/** @typedef {import('../types').TCollectTime} TCollectTime */

export default {
  name: '',

  props: {
    arr: {
      type: Array,
      default: () => {
        return [];
      }
    },
    customerList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      formData: {
        customerIdList: [],
        arr: []
      },
      collectTimeCheck,
      collectLoadFinishTimeCheck,
      hub1ArrivalTimeCheck
    };
  },

  created() {},

  methods: {
    // 新增
    addRow() {
      this.formData.arr.push(cloneDeep(COLLECT_TIME_ROW));
    },

    delRow(index) {
      this.formData.arr.splice(index, 1);
    },

    // 校验
    submitForm() {
      // let isBool = false;
      const arr = [false, ''];
      if (this.formData.arr.length === 0) {
        // this.$message.error('请添加揽收时间');
        arr[1] = '请添加揽收时间';
        return arr;
      }

      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) {
          return;
        }
        arr[0] = true;
      });

      return arr;
    },

    // 设置数据
    setData(ruleForm) {
      this.formData.customerIdList = cloneDeep(ruleForm.customerIdList);
      this.formData.arr = cloneDeep(ruleForm.collectTimeList);
    },

    getData() {
      const param = cloneDeep(this.formData);
      let isBool = true;
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) {
          isBool = false;
        }
        const customerIdList = cloneDeep(param.customerIdList);
        if (customerIdList.length > 0) {
          param.customerIdList = customerIdList.join(',');
        } else {
          param.customerIdList = '';
        }
        param.arr = cloneDeep(this.formData.arr).map(item => {
          const { startCollect, endCollect, collectLoadFinishTime, hub1ArrivalTime } = item;
          return {
            startCollectTime: startCollect,
            endCollectTime: endCollect,
            collectLoadFinishTime,
            hub1ArrivalTime
          };
        });
      });
      if (isBool) {
        return param;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.demo-ruleForm {
  margin-top: 10px;
}
.demo-ruleForm .el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
</style>
