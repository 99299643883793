<template>
  <div class="trackDrawerContent">
    <el-row type="flex" justify="flex-start" style="flex-wrap: wrap;width: 100%;">
      <el-col :span="6" class="tdL">
        <ul>
          <li
            v-for="(item, index) in trackOrderList"
            :key="index" @click="clickThis(index)"
            :class="{'tdActive': selectedIndex === index}"
          >{{ item }}</li>
        </ul>
      </el-col>
      <el-col :span="18" class="tdR">
        <div v-if="activities.length > 0" style="margin-top: 20px;">
          <el-timeline :reverse="false">
            <el-timeline-item
              v-for="(item, index) in activities"
              :key="index"
              :color="index == 0 ? '#409eff': ''"
            >
              <span :style="index === 0 ? 'font-size: 16px;font-weight: bold;' : 'font-size: 15px'">
                {{ item.dateTime }}；{{ item.status }}；{{ item.address }}
              </span>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div v-else>
          <el-empty :image-size="200"></el-empty>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getTrackQueryTable } from '@/api/trackQuery';
export default {
  name: 'track',
  props: {
    waybillList: {
      type: Array
    }
  },
  data() {
    return {
      selectedIndex: 0,
      trackOrderList: [],
      activities: []
    };
  },
  mounted() {
    this.trackOrderList = this.waybillList;
    this.clickThis(0);
  },
  methods: {
    clickThis(index) {
      this.selectedIndex = index;
      this.getData(this.waybillList[index]);
    },
    getData(val) {
      const param = val;
      getTrackQueryTable(param).then(res => {
        if (res.code === 200) {
          this.activities = res.data;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.trackDrawerContent{
  border: 1px solid #e4e7ed;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 100%;
  .tdL{
    background: #f5f7fa;
    height: 100%;
    border-right: 1px solid #e4e7ed;
    box-sizing: border-box;
    flex: 1;
    overflow-y: auto;
    ul{ margin: 0 !important; padding: 0 !important; }
    li{ list-style: none;font-size: 15px; text-align: center;padding: 5px;border-bottom: 1px solid #e4e7ed;box-sizing: border-box;cursor: pointer; }
    li:hover{background: #fff;color: #409eff;}
    .tdActive{background: #fff;color: #409eff;}
  }
  .tdR{
    height: 100%;
    overflow-y: auto;
    padding-left: 20px;
    box-sizing: border-box;
  }
}
</style>
