<!-- 安骏小屋应付账户 -->
<template>
  <div class="app-container">
    <el-card class="mb5 searchContainer">
      <el-form label-position="top" size="small">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="">
              <template slot="label">
                <el-select
                  v-model="typeVal"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  class="status"
                >
                  <el-option :key="0" :label="$t('basicData.accountsPayableToSuppliers')" value="providerName" />
                  <el-option :key="1" :label="$t('orderCenterCont.name')" value="providerBody" />
                </el-select>
              </template>
              <el-input v-model.trim="inputVal" :placeholder="$t('GlobalSetObj.pleaseInputContent')" style="display: block;width: 100%;" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 所属网点 -->
            <el-form-item :label="$t('collectionCenter.belongSite')">
              <QuerySiteCmp
                :val="queryForm.params.siteId"
                :provider-id="queryForm.params.providerId"
                @change="val => queryForm.params.siteId = val"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="left">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="onSearch"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="onReset"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <div class="mb10" style="display: flex; justify-content: space-between;">
        <!--导入账户信息-->
        <ImportFile :bank-list="bankList" :type="3" />

        <div>
          <!-- 导出账户信息 -->
          <el-button
            v-permit:remove="'but:finance:basicData:payable:hutdispatchAccountsPayable:exportAccountInformation'"
            icon="el-icon-download"
            type="primary"
            size="mini"
            @click="onExportAccountInformation"
          >{{ $t('basicData.ExportAccountInformation') }}</el-button>

          <!-- 导出账户流水 -->
          <el-button
            v-permit:remove="'but:finance:basicData:payable:hutdispatchAccountsPayable:exportAccountStatement'"
            icon="el-icon-download" type="primary" size="mini"
            @click="onExportAccountStatement"
          >{{ $t('basicData.ExportAccountRollover') }}</el-button>
        </div>
      </div>

      <el-table
        ref="elTable"
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
      >
        <!--序号-->
        <el-table-column :label="$t('basicData.serialNumber')" width="80px" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <!-- 供应商账户 -->
        <el-table-column prop="providerName" :label="$t('basicData.accountsPayableToSuppliers')">
          <template slot-scope="scope">
            <el-link type="primary" @click="onDetails(scope.row)">{{ scope.row.providerName }}</el-link>
          </template>
        </el-table-column>

        <!-- 姓名 -->
        <el-table-column prop="providerBody" :label="$t('orderCenterCont.name')" />

        <!-- 付款总计 -->
        <el-table-column prop="totalPaymentAmount" :label="$t('basicData.totalPaymentAmount')" />

        <!-- 未付总计 -->
        <el-table-column prop="unpaidAmount" :label="$t('basicData.unpaidTotal')" />

        <!-- 已付总计 -->
        <el-table-column prop="paidAmount" :label="$t('basicData.totalPaid')" />
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="currentGetTableData"
          @size-change="sizeGetTableData"
        />
      </div>
    </el-card>

    <el-drawer
      :append-to-body="true"
      :label="$t('receivablePayable.ReconcileBatchDetails')"
      :visible.sync="batchNumberDetailRow.isBool"
      :with-header="false"
      direction="ltr"
      size="70%"
    >
      <detail-payment
        :key="batchNumberDetailKey"
        :detail-data="batchNumberDetailRow.row"
        :account-type="1"
      />
    </el-drawer>
  </div>
</template>

<script>
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { apiExportOfBillFlow } from '@/api/finance/basicData';
import { apiHutPayableAccountPage, apiHutPayableAccountExport, apiGetBankList } from '@/api/newFinance/index.js';
import { goExportList, goResourceTaskList } from '@/utils/goExportList.js';
import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp';

const PARAMS = {
  // providerName: '', // 账号
  // providerBody: '', // 姓名
  siteId: '' // 网点
};

export default {
  name: 'HutDispatchAccountsPayable',

  components: {
    QuerySiteCmp,
    ImportFile: () => import('../components/importFile.vue'),
    'detail-payment': () => import('@/views/finance/basicData/cope/dispatchAccountsPayable/cmp/detailPayment.vue')
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      typeVal: 'providerName',
      inputVal: '',
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          ...PARAMS
        }
      },

      total: 0,
      tableData: [],
      options: [],
      bankList: [],

      // 对账单详情
      batchNumberDetailRow: {
        isBool: false,
        row: {
          requestSource: 3, // 请求来源 1:应付-揽收账户 2:应付-派送账户 3 安骏
          cabinCollectionPayableAccountCondition: {
            id: 0
          }, // 应付-安骏
          providerBody: '', // 应付主体.
          payableUnsettledAmount: 0, // 应付合计
          payedAmount: 0, // 已付合计
          unpavedAmount: 0// 未付合计
        }
      },
      batchNumberDetailKey: new Date().getTime()
    };
  },

  created() {
    this.asyncGetTableData();
    this.getBankList();
  },

  mounted() {
    this.bus.$on('onClose', (data) => {
      if (data) {
        this.updateData();
      }
      this.batchNumberDetailRow.isBool = false;
    });
  },

  methods: {
    // 详情保存后更新列表数据
    updateData() {
      this.asyncGetTableData();
    },
    getQuery() {
      const { queryForm, inputVal, typeVal } = this;
      const obj = {
        ...queryForm,
        params: {
          ...queryForm.params,
          // providerName: undefined,
          // providerBody: undefined,
          [typeVal]: inputVal
        }
      };

      return obj;
    },
    asyncGetTableData() {
      const obj = this.getQuery();
      apiHutPayableAccountPage(obj, true).then(res => {
        const { records, total } = res.data;
        this.total = total;
        this.tableData = (records || []).map(item => {
          const obj = {
            ...item
          };

          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      });
    },

    onSearch() {
      this.queryForm.pageNumber = 1;
      this.asyncGetTableData();
    },

    onReset() {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = 10;
      Object.assign(this.queryForm.params, { ...PARAMS });
      this.inputVal = '';
      this.typeVal = 'providerName';
      this.asyncGetTableData();
    },

    // 详情
    onDetails(row) {
      const obj = {
        ...row,
        cabinCollectionPayableAccountCondition: {
          id: row.id
        },
        providerBody: row.providerBody, // 应付主体
        payableUnsettledAmount: row.totalPaymentAmount, // 应付合计
        payedAmount: row.paidAmount, // 已付合计
        unpavedAmount: row.unpaidAmount // 未付合计
      };
      Object.assign(this.batchNumberDetailRow.row, obj);
      this.batchNumberDetailRow.isBool = true;
      this.batchNumberDetailKey = new Date().getTime();
    },

    // 导出账户信息
    onExportAccountInformation() {
      const { params } = this.getQuery();
      apiHutPayableAccountExport(params).then(res => {
        // goExportList(this);
        goResourceTaskList();
      }).catch(err => {
        console.error(err);
      });
    },

    // 导出账户流水
    onExportAccountStatement() {
      const query = this.getQuery();
      const { requestSource } = this.batchNumberDetailRow.row;
      const obj = {
        startTime: '',
        endTime: '',
        requestSource,
        isSiteDetailsRequest: false,
        cabinCollectionPayableAccountCondition: {
          ...query.params
        }

      };
      apiExportOfBillFlow(obj).then(res => {
        if (res.code === 200) {
          goExportList(this);
        }
      });
    },
    // 银行列表
    getBankList() {
      apiGetBankList().then(res => {
        this.bankList = res.data;
      });
    },
    currentGetTableData(val) {
      this.queryForm.pageNumber = val;
      this.asyncGetTableData();
    },
    sizeGetTableData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.asyncGetTableData();
    }
  }
};

</script>

<style lang="scss" scoped>
@import '../accountsReceivablePayable/style.css';
</style>

