<template>
  <div class="app-container">
    <el-card class="mb5 searchContainer">
      <el-form
        ref="queryForm"
        label-position="top"
        label-width="auto"
        :model="queryForm"
        class="queryFormClass"
        size="mini"
      >
        <el-row :gutter="10">
          <el-col :span="6">
            <!--费用名称-->
            <el-form-item :label="$t('basicData.ExpenseName')" prop="name">
              <el-input v-model="queryForm.params.name" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--业务类型-->
            <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
              <el-select v-model="queryForm.params.businessType" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%">
                <!--全部-->
                <el-option key="0" :label="$t('collectionCenter.tudo')" :value="null" />
                <!--快递-->
                <el-option key="1" :label="$t('collectionCenter.expressDelivery')" :value="0" />
                <!--揽收-->
                <el-option key="2" :label="$t('collectionCenter.lanshou')" :value="1" />
                <!--派件-->
                <el-option key="3" :label="$t('collectionCenter.delivery')" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row justify="left" type="flex">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              icon="el-icon-search"
              size="mini"
              type="primary"
              @click="getDataSearch"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="queryFormReset"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <!--添加-->
      <el-button
        v-permit:remove="'btn:finance:basicData:feesType:add'"
        icon="el-icon-plus"
        size="mini"
        type="primary"
        class="mb10"
        @click="handlerOperation(1)"
      >{{ $t('basicData.Add') }}
      </el-button>
      <!--表格-->
      <el-table
        ref="elTable"
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
      >
        <el-table-column :label="$t('operationCenter.ind')" align="center" width="60px">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--费用名称-->
        <el-table-column
          :label="$t('basicData.ExpenseName')"
          :show-overflow-tooltip="true"
          align="left"
          prop="displayName"
          width="160"
        >
          <template slot-scope="scope">
            <div style="color: #409eff;cursor: pointer;" @click="handlerOperation(2, scope.row)">
              {{ scope.row.displayName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('basicData.FeeCode')"
          :show-overflow-tooltip="true"
          align="left"
          prop="code"
          width="160"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.code }}</div>
          </template>
        </el-table-column>
        <!--业务类型-->
        <el-table-column
          :label="$t('collectionCenter.businessT')"
          :show-overflow-tooltip="true"
          align="left"
          prop="code"
          width="160"
        >
          <template slot-scope="scope">
            <div>
              <!--快递-->
              <span v-if="scope.row.businessType === 0">{{ $t('collectionCenter.expressDelivery') }}</span>
              <!--揽收-->
              <span v-else-if="scope.row.businessType === 1">{{ $t('collectionCenter.lanshou') }}</span>
              <!--派件-->
              <span v-else-if="scope.row.businessType === 2">{{ $t('collectionCenter.delivery') }}</span>
              <!--快递\揽收-->
              <span v-else-if="scope.row.businessType === 3">{{
                $t('collectionCenter.expressDelivery')
              }}、{{ $t('collectionCenter.lanshou') }}</span>
              <!--快递\派件-->
              <span v-else-if="scope.row.businessType === 4">{{
                $t('collectionCenter.expressDelivery')
              }}、{{ $t('collectionCenter.delivery') }}</span>
              <!--揽收\派件-->
              <span v-else-if="scope.row.businessType === 5">{{
                $t('collectionCenter.lanshou')
              }}、{{ $t('collectionCenter.delivery') }}</span>
              <!--快递\揽收\派件-->
              <span v-else-if="scope.row.businessType === 6">{{
                $t('collectionCenter.expressDelivery')
              }}、{{ $t('collectionCenter.lanshou') }}、{{ $t('collectionCenter.delivery') }}</span>
            </div>
          </template>
        </el-table-column>
        <!--          费用类型-->
        <!--          <el-table-column-->
        <!--            prop="attribute"-->
        <!--            :label="$t('basicData.ExpenseType')"-->
        <!--            align="left"-->
        <!--            width="200"-->
        <!--            :show-overflow-tooltip="true"-->
        <!--          >-->
        <!--            <template slot-scope="scope">-->
        <!--              &lt;!&ndash;应收费用&ndash;&gt;-->
        <!--              <div>-->
        <!--                <span-->
        <!--                  v-for="(item, ind) in scope.row.attribute.split(',')"-->
        <!--                  :key="ind"-->
        <!--                  style="margin: 0 10px;"-->
        <!--                >{{ item === '1' ? $t('basicData.Receivables') : $t('basicData.payable') }}</span>-->
        <!--              </div>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--状态-->
        <el-table-column
          :label="$t('basicData.status')"
          :show-overflow-tooltip="true"
          align="center"
          prop="state"
          width="140"
        >
          <template slot-scope="scope">
            <!--启用-->
            <el-tag v-if="scope.row.state === true">{{ $t('basicData.enable') }}</el-tag>
            <!--禁用-->
            <el-tag v-else-if="scope.row.state === false" type="danger">{{ $t('basicData.disable') }}</el-tag>
            <!--未定义-->
            <el-tag v-else type="info">{{ $t('basicData.Undefined') }}</el-tag>
          </template>
        </el-table-column>
        <!--特殊规则-->
        <el-table-column
          :label="$t('basicData.SpecialRules')"
          :show-overflow-tooltip="true"
          align="center"
          prop="specialRules"
          width="200"
        >
          <template slot-scope="scope">
            <!--申报金额比例（可设置最低）-->
            <span v-if="scope.row.specialRules === 0">{{
              $t('basicData.PercentageOfDeclaredAmountMinimumCanBeSet')
            }}</span>
            <!--订单所有费用比例（GRIS）-->
            <span v-else-if="scope.row.specialRules === 1">{{
              $t('basicData.OrderAllCostRatioGRIS') + '（GRIS）'
            }}</span>
            <!--ISS税-->
            <span v-else-if="scope.row.specialRules === 2">{{ $t('basicData.Issn1') }}</span>
            <!--ICMS税-->
            <span v-else-if="scope.row.specialRules === 3">{{ 'ICMS' + $t('basicData.tax') }}</span>
            <!--超重额外费用-->
            <span v-else-if="scope.row.specialRules === 4">{{ $t('basicData.ExcessWeightSurcharges') }}</span>
            <span v-else-if="scope.row.specialRules === 5">*BRL/*KG（{{ $t('basicData.roundUp') }}）</span>
            <span v-else-if="scope.row.specialRules === 6">*BRL/{{ $t('basicData.ticket') }}</span>
            <span v-else-if="scope.row.specialRules === 7">{{ $t('basicData.Issn') }}</span>
          </template>
        </el-table-column>
        <!--创建人-->
        <el-table-column
          :label="$t('basicData.CreatePeople')"
          :show-overflow-tooltip="true"
          align="center"
          prop="createUserStr"
          width="120"
        />
        <!--创建时间-->
        <el-table-column
          :label="$t('basicData.CreationTime')"
          :show-overflow-tooltip="true"
          align="center"
          prop="createTime"
          width="160"
        />
        <!--最后修改人-->
        <el-table-column
          :label="$t('basicData.LastModifier')"
          :show-overflow-tooltip="true"
          align="center"
          prop="updateUserStr"
          width="120"
        />
        <!--最后修改时间-->
        <el-table-column
          :label="$t('basicData.LastModified')"
          :show-overflow-tooltip="true"
          align="center"
          prop="updateTime"
          width="160"
        />
        <!--备注-->
        <el-table-column
          :label="$t('basicData.remark')"
          :show-overflow-tooltip="true"
          align="left"
          prop="remark"
          width="200"
        />
        <el-table-column :label="$t('basicData.operate')" fixed="right" width="200">
          <template slot-scope="scope">
            <div>
              <!--确定禁用吗？-->
              <el-popconfirm
                v-if="scope.row.state === true"
                v-permit="'btn:finance:basicData:feesType:add'"
                :title="$t('basicData.AreYouSureDisabled')"
                @confirm="asyncPartitionEdit(scope.row, 0)"
              >
                <!--禁用-->
                <el-button
                  slot="reference" class="ml" size="mini"
                  type="warning"
                >{{
                  $t('basicData.disable')
                }}
                </el-button>
              </el-popconfirm>
              <!--确定启用吗？-->
              <el-popconfirm
                v-if="scope.row.state === false"
                v-permit="'btn:finance:basicData:feesType:add'"
                :title="$t('basicData.AreYouSureEnabled')"
                @confirm="asyncPartitionEdit(scope.row, 1)"
              >
                <!--启用-->
                <el-button
                  slot="reference" class="ml" size="mini"
                  type="primary"
                >{{
                  $t('basicData.enable')
                }}
                </el-button>
              </el-popconfirm>
              <!--删除-->
              <el-popconfirm
                v-permit="'btn:finance:basicData:feesType:delete'"
                :title="$t('basicData.AreYouSureYouWantToDelete')"
                @confirm="deleteAccount(scope.row)"
              >
                <!--启用-->
                <el-button
                  slot="reference" class="ml" size="mini"
                  type="danger"
                >{{
                  $t('basicData.Delete')
                }}
                </el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="getCurrencyTableData"
          @size-change="getCurrencyTableData"
        />
      </div>
    </el-card>

    <!-- 费用类型添加、修改 -->
    <el-drawer
      :title="drawerObj.typeIndex === 1 ? $t('basicData.AddAFee') : $t('basicData.FeeDetails')"
      :visible.sync="drawerObj.visible"
      direction="ltr"
      size="60%"
    >
      <check-detail
        :key="editKey"
        :detail-data="drawerObj.editData"
        :operation-type="drawerObj.typeIndex"
        @saveChange="saveChange"
      />
    </el-drawer>

  </div>
</template>

<script>
import { editStatusFee, feeDelete, feePage } from '@/api/finance/partition';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';

export default {
  name: 'FeeType',
  components: {
    'check-detail': () => import('./cmp/checkDetail')
  },
  mixins: [estimateTableHeight],
  data() {
    return {
      queryForm: {
        orderDirection: 'DESC',
        orderProperty: '',
        pageNumber: 1,
        pageSize: 10,
        params: {
          name: '', // 费用名称
          code: '', // 费用代码
          attribute: '', // 费用类型 1应收费用 2应付费用 3既是应收费用也是应付费用
          id: '', // ID
          remark: '', // 备注
          state: '', // 状态： true 启用 false 禁用 默认为 true
          businessType: null
        }
      },
      tableData: [],
      total: 0,
      userIdAndNameObj: null,
      editKey: new Date().getTime(),
      drawerObj: {
        visible: false,
        typeIndex: 1,
        editData: {}
      }
    };
  },

  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getCurrencyTableData();
  },
  methods: {
    // 搜索
    getDataSearch() {
      this.queryForm.pageNumber = 1;
      this.getCurrencyTableData();
    },
    // 重置
    queryFormReset() {
      for (const k in this.queryForm.params) {
        this.queryForm.params[k] = '';
        this.queryForm.params['businessType'] = null;
      }
    },
    // 添加、详情
    handlerOperation(type, row) {
      this.drawerObj.typeIndex = type;
      if (row) {
        const {
          name, // 费用名称
          namePu, // 费用名称（葡语）
          code, // 费用代码
          attribute, // 费用类型 1应收费用 2应付费用 3既是应收费用也是应付费用
          id, // ID
          remark, // 备注
          state, // 状态： 1 启用 0 禁用 默认为 1
          businessType,
          specialRules // 特殊规则
        } = row;
        this.drawerObj.editData = {
          name, // 费用名称
          namePu, // 费用名称（葡语）
          code, // 费用代码
          attribute, // 费用类型 1应收费用 2应付费用 3既是应收费用也是应付费用
          id, // ID
          remark, // 备注
          state, // 状态： 1 启用 0 禁用 默认为 1
          businessType,
          specialRules
        };
      } else {
        this.drawerObj.editData = {
          name: '', // 费用名称
          namePu: '', // 费用名称（葡语）
          code: '', // 费用代码
          attribute: '1', // 费用类型 1应收费用 2应付费用 3既是应收费用也是应付费用
          id: '', // ID
          remark: '', // 备注
          state: true, // 状态： true 启用 false 禁用 默认为 true
          businessType: [],
          specialRules: ''
        };
      }
      this.drawerObj.visible = true;
      this.editKey = new Date().getTime();
    },
    // 获取费用类型列表
    getCurrencyTableData() {
      feePage(this.queryForm).then(res => {
        if (res.code === 200) {
          const { data } = res;
          this.tableData = data.records.map(item => {
            const updateUserStr = getUserName(item.updateUser, this.userIdAndNameObj);
            const createUserStr = getUserName(item.createUser, this.userIdAndNameObj);
            for (const key in item) {
              if (key === 'createTime' || key === 'updateTime') {
                item[key] = item[key] ? item[key].replace('T', ' ') : '';
              }
            }
            // item.attribute = item.attribute === 3 || item.attribute === '3' ? '1,2' : item.attribute.toString();
            return {
              ...item,
              updateUserStr,
              createUserStr
            };
          });
          this.total = data.total;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 启用、禁用
    asyncPartitionEdit(row, type) {
      const param = {
        id: row.id,
        state: type
      };
      editStatusFee(param).then(res => {
        if (res.code === 200) {
          this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
          this.getCurrencyTableData();
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 删除
    deleteAccount(row) {
      feeDelete({ id: row.id }).then(res => {
        if (res.code === 200) {
          this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
          this.getCurrencyTableData();
        }
      });
    },
    // 更新列表数据
    saveChange(type) {
      this.drawerObj.visible = false;
      if (type === 1) {
        this.getCurrencyTableData();
      }
    }
  }
};
</script>

<style scoped>
.ml {
  margin-left: 10px;
}
</style>
