var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticStyle: { height: "calc(100% - 55px)", "overflow-y": "scroll" } },
      [
        _c(
          "el-card",
          [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("newOrder.BasicInformation"))),
                ]),
              ]
            ),
            _c("BasicForm", {
              ref: "basicForm",
              attrs: { status: _vm.claimsStatus },
              on: { change: _vm.updateForm, removeChange: _vm.remove },
            }),
          ],
          1
        ),
        _c(
          "el-card",
          [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("ClaimsManagement.LinkedOrder"))),
                ]),
              ]
            ),
            _c("BasicTable", {
              ref: "basicTable",
              attrs: { "form-data": _vm.formList, status: _vm.claimsStatus },
              on: { close: _vm.onClose, change: _vm.clickUpdate },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _vm._l(_vm.btnList, function (item, index) {
              return [
                _c(
                  "el-button",
                  {
                    key: index,
                    attrs: { size: "small", type: item.type },
                    on: {
                      click: function ($event) {
                        return _vm.btnClick(item.btnType)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }