var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("newOrder.order"), name: "1" } },
            [
              _c(
                "el-card",
                { staticStyle: { "margin-bottom": "5px" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("newOrder.BasicInformation"))),
                      ]),
                    ]
                  ),
                  _c("BasicsCmp", {
                    ref: "claimBasicsRef",
                    attrs: {
                      ids: _vm.ids,
                      "total-value": _vm.totalValue,
                      "total-len": _vm.totalLen,
                    },
                    on: {
                      change: _vm.onBasicsChange,
                      clearList: _vm.onClearList,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-card",
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("ClaimsManagement.LinkedOrder"))),
                      ]),
                    ]
                  ),
                  _c("OrderCmp", {
                    ref: "claimOrderRef",
                    attrs: { "basics-row": _vm.basicsRow },
                    on: { getData: _vm.getOrderData },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("ClaimsManagement.ClaimSettlementProcess"),
                name: "2",
                disabled: _vm.claimsStatus === 0,
              },
            },
            [_c("HistoryCmp", { ref: "claimHistoryRef" })],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "footer" }, [
        _c("div"),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { size: "small" },
                on: {
                  click: function () {
                    return _vm.onClose(false)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
            ),
            _vm.isCloseBut
              ? _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "danger" },
                    on: { click: _vm.onAddClose },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.close")))]
                )
              : _vm._e(),
            _vm.isAddBut
              ? _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.onAdd },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.add")))]
                )
              : _vm._e(),
            _vm.isEditBut
              ? _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.onEdit },
                  },
                  [_vm._v(_vm._s(_vm.$t("CenterForSorting.modify")))]
                )
              : _vm._e(),
            _vm.isSubmitBut
              ? _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.onSubmit },
                  },
                  [_vm._v(_vm._s(_vm.$t("ClaimsManagement.SubmitForReview")))]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }