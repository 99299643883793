<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="7">
          <!--时间-->
          <el-form-item prop="timeType">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.timeType"
                  :style="IsWidth"
                >
                  <el-option
                    v-for="item in timeLable" :key="item.id" :label="item.label"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </template>
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :placeholder="$t('newOrder.SelectDatetime')"
              range-separator="~"
              style="width:100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--  运单号   -->
          <el-form-item>
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.selectType"
                  :style="IsWidth1"
                >
                  <el-option key="1" :label="$t('newOrder.waybillNumber')" :value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.ScanNumber')" :value="2" />
                </el-select>
              </div>
            </template>
            <el-input
              v-model="queryForm.params.inputValue"
              :placeholder="$t('newOrder.PreciseSearch1')"
              :rows="1"
              style="width:100%"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!-- 客户名称  -->
          <el-form-item :label="$t('GlobalSetObj.client')">
            <el-select
              v-model="queryForm.params.customerList"
              :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
              clearable
              filterable
              multiple
              style="width: 100%"
            >
              <el-option
                v-for="(item,index) in customerList"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--是否拦截 -->
          <el-form-item :label="$t('navbar.interceptStatusName')">
            <el-select
              v-model="queryForm.params.interceptStatus"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width:100%"
            >
              <el-option :label="$t('GlobalSetObj.all')" value="" />
              <el-option :label="$t('GlobalSetObj.yes')" :value="0" />
              <el-option :label="$t('GlobalSetObj.No')" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="5">
          <!-- 问题件原因   -->
          <el-form-item :label="$t('GlobalSetObj.wentijian')">
            <el-select
              v-model="queryForm.params.problemTypeList"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              style="width:100%"
            >
              <el-option
                v-for="(item,index) in problemList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <div style="margin-top: 27px;">
            <!--搜索-->
            <el-button
              icon="el-icon-search" size="small" type="primary"
              @click="enterSearch"
            >{{ $t('newOrder.search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh-left" size="small" type="info"
              @click="resetForm"
            >{{
              $t('newOrder.reset')
            }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div style="margin-bottom: 20px;">
      <el-button
        v-permit:remove="'btn:collection-center:customerIssuemanagement:releaseProblemIntercept'"
        size="small" type="primary"
        @click="problemIntercept"
      >
        {{ $t('navbar.problemIntercept') }}
      </el-button>
      <el-button
        v-permit:remove="'btn:collection-center:customerIssuemanagement:releaseTenDays'"
        size="small"
        type="primary"
        @click="release10day"
      >
        {{ $t('navbar.release10day') }}
      </el-button>
    </div>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column
        v-for="(item,index) in tableHeaderList"
        :key="index"
        :align="item.center"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">
          <div>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 释放问题件拦截 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.tips')"
      :visible.sync="tipDialog1"
      width="620px"
    >
      <el-row :gutter="10" style="font-size: 16px !important">
        <el-col
          :span="4"
        >{{ $t('GlobalSetObj.all') }}:<span class="tipclass">{{
          problemObj.totalCount
        }}</span></el-col>
        <el-col
          :span="10"
        >{{ $t('customerManagements.ReleaseSuccess') }}:<span class="tipclass">{{
          problemObj.successCount
        }}</span></el-col>
        <el-col :span="10">{{ $t('customerManagements.ReleaseFail') }}:<span class="tipclass" style="color: red">{{
          problemObj.failCount
        }}</span></el-col>
      </el-row>
      <div
        style="
          margin-top: 20px;
          font-size: 16px;
          line-height: 30px;
          height: 200px;
          overflow-y: auto;
        "
      >
        <div v-if="problemObj.failCount">
          <div>{{ $t('GlobalSetObj.ReasonForFailure') }}:</div>
          <div v-for="(item, index) in problemObj.failReasonList" :key="index">
            <span>{{ index + 1 }}.</span>
            <span>{{ item.waybillNumber }}</span>
            <span style="margin-left: 10px">{{ item.reason }}</span>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin: 15px 0">
        <el-button size="small" type="primary" @click="tipDialog1 = false">{{
          $t('GlobalSetObj.determine')
        }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  getproblemcustomerList,
  getproblemList,
  liftInterceptType,
  localproblemList,
  releaseTenDaysIntercept
} from '@/api/customer';

export default {
  name: 'CustomerIssuemanagement',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          timeType: 1,
          selectType: 2,
          customerList: [],
          problemTypeList: [],
          inputValue: '',
          interceptStatus: ''
        }
      },
      tableData: [],
      problemList: [],
      tableHeaderList: [
        // 最新登记时间
        { id: 1, label: this.$t('navbar.lastTime'), prop: 'latestRegisterTime', cloWidth: '160', center: 'center' },
        // 首次登记时间
        { id: 2, label: this.$t('navbar.firstTime'), prop: 'firstRegisterTime', cloWidth: '160', center: 'center' },
        // 客户
        { id: 3, label: this.$t('GlobalSetObj.client'), prop: 'customerName', cloWidth: '150', center: 'center' },
        // 运单号
        { id: 4, label: this.$t('newOrder.waybillNumber'), prop: 'waybillNumber', cloWidth: '160', center: 'center' },
        // 扫描单号
        { id: 5, label: this.$t('GlobalSetObj.ScanNumber'), prop: 'scanOrderNumber', cloWidth: '160', center: 'center' },
        // 是否拦截中
        {
          id: 6,
          label: this.$t('navbar.interceptStatusName'),
          prop: 'interceptStatusName',
          cloWidth: '150',
          center: 'center'
        },
        // 运单状态
        {
          id: 7,
          label: this.$t('newOrder.waybillStatus'),
          prop: 'waybillStatusName',
          cloWidth: '150',
          center: 'center'
        },
        // 剩余投递时间
        {
          id: 8,
          label: this.$t('navbar.residueTimes'),
          prop: 'residueTimes',
          cloWidth: '150',
          center: 'center'
        },
        // 剩余投递次数
        {
          id: 9,
          label: this.$t('navbar.residueCount'),
          prop: 'residueCount',
          cloWidth: '150',
          center: 'center'
        },
        // 问题件原因1
        {
          id: 10,
          label: this.$t('navbar.wentijian01'),
          prop: 'firstProblemType',
          cloWidth: '150',
          center: 'center'
        },
        // 问题件原因1
        {
          id: 11,
          label: this.$t('navbar.wentijian02'),
          prop: 'secondProblemType',
          cloWidth: '170',
          center: 'center'
        },
        // 问题件原因3
        {
          id: 12,
          label: this.$t('navbar.wentijian03'),
          prop: 'thirdProblemType',
          cloWidth: '170', center: 'center'
        },
        // 最新登记网点
        {
          id: 13,
          label: this.$t('navbar.latestRegisterSite'),
          prop: 'latestRegisterSite',
          cloWidth: '170', center: 'center'
        },
        // 最新登记人
        {
          id: 14,
          label: this.$t('navbar.latestRegistrant'),
          prop: 'latestRegistrant',
          cloWidth: '170',
          center: 'center'
        },
        // 拦截释放时间
        {
          id: 15,
          label: this.$t('navbar.latestReleaseTime'),
          prop: 'latestReleaseTime',
          cloWidth: '170',
          center: 'center'
        },
        // 最新操作人
        {
          id: 16,
          label: this.$t('customerManagements.newOptioner'),
          prop: 'latestReleaseMan',
          cloWidth: '170',
          center: 'center'
        }
      ],
      customerList: [], // 用户列表
      timeLable: [
        { id: 1, label: this.$t('navbar.lastTime') },
        { id: 2, label: this.$t('navbar.firstTime') }
      ],
      selectionList: [],
      tipDialog1: false,
      problemObj: {}
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '250px' : '160px'
      };
    },
    IsWidth1() {
      return {
        width: this.isTormat ? '180px' : '160px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getSearch();
    this.getAllSite();
    this.getCustomerPage();
  },
  methods: {
    // 勾选回调
    handleSelectionChange(val) {
      console.log(val, '勾选回调');
      this.selectionList = val;
    },
    // 释放10天
    release10day() {
      if (this.selectionList.length !== 0) {
        console.log(this.selectionList, 'this.selectionList');
        const h = this.$createElement;
        this.$msgbox({
          title: this.$i18n.t('operationCenter.tips'),
          message: h('p', null, [
            h('span', this.$i18n.t('navbar.tip21')) // 确定要对选中单号释放10天限制吗
          ]),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('GlobalSetObj.determine'),
          cancelButtonText: this.$i18n.t('GlobalSetObj.cancel')
        })
          .then((action) => {
            if (action === 'confirm') {
              const data = {
                waybillNumbers: []
              };
              this.selectionList.forEach(item => {
                data.waybillNumbers.push(item.waybillNumber);
              });
              releaseTenDaysIntercept(data).then(res => {
                if (res.code===200) {
                  this.getSearch();
                  // this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                  this.problemObj = res.data;
                  this.tipDialog1 = true;
                }
              });
              console.log('确定');
            }
          })
          .catch(() => {
            console.log('取消');
          });
      } else {
        this.$message.warning(this.$t('GlobalSetObj.CheckForm'));
      }
    },
    problemIntercept() {
      if (this.selectionList.length !== 0) {
        console.log(this.selectionList, 'this.selectionList');
        const h = this.$createElement;
        this.$msgbox({
          title: this.$i18n.t('operationCenter.tips'),
          message: h('p', null, [
            h('span', this.$i18n.t('navbar.tip20')) // 确定要选中单号进行申请服务商单号吗
          ]),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('GlobalSetObj.determine'),
          cancelButtonText: this.$i18n.t('GlobalSetObj.cancel')
        })
          .then((action) => {
            if (action === 'confirm') {
              const data = {
                waybillNumbers: [],
                type: 2
              };
              this.selectionList.forEach(item => {
                data.waybillNumbers.push(item.waybillNumber);
              });
              liftInterceptType(data).then(res => {
                if (res.code===200) {
                  this.getSearch();
                  // this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                  this.problemObj = res.data;
                  this.tipDialog1 = true;
                }
              });
              console.log('确定');
            }
          })
          .catch(() => {
            console.log('取消');
          });
      } else {
        this.$message.warning(this.$t('GlobalSetObj.CheckForm'));
      }
    },
    // 查询
    getSearch() {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params = this.queryForm.params;
      if (this.timeValue && this.timeValue.length > 0) {
        if (this.queryForm.params.timeType === 1) {
          data.params.latestStartTime = this.timeValue[0];
          data.params.latestEndTime = this.timeValue[1];
          data.params.firstStartTime = '';
          data.params.firstEndTime = '';
        } else {
          data.params.firstStartTime = this.timeValue[0];
          data.params.firstEndTime = this.timeValue[1];
          data.params.latestStartTime = '';
          data.params.latestEndTime = '';
        }
      } else {
        data.params.latestStartTime = '';
        data.params.latestEndTime = '';
        data.params.firstStartTime = '';
        data.params.firstEndTime = '';
      }
      if (this.queryForm.params.selectType === 1) {
        data.params.waybillNumberList = inputValList;
        data.params.scanOrderNumberList = [];
      } else {
        data.params.scanOrderNumberList = inputValList;
        data.params.waybillNumberList = [];
      }
      localproblemList(data).then(res => {
        if (res.code===200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach(item => {
            item.residueCount = item.residueCount || '0';
          });
        }
      });
    },
    // 获取最近一周时间
    getTimer() {
      // 获取当前时间
      const today = dayjs().format('YYYY-MM-DD');
      const oneWeekAgo = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.getTimer();
      this.queryForm.params = {
        timeType: 1,
        selectType: 2,
        customerList: [],
        problemTypeList: [],
        inputValue: '',
        interceptStatus: ''
      };
      this.getSearch();
    },
    getCustomerPage() {
      getproblemcustomerList().then((res) => {
        if (res.code===200) {
          this.customerList = res.data;
        }
      });
    },
    // 获取所有网点
    getAllSite() {
      getproblemList().then(res => {
        if (res.code===200) {
          this.problemList = res.data;
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 15px;
  padding-bottom: 15px;
}

::v-deep {
  .drawerClass header {
    border-bottom: 1px solid #aaa !important;
    margin-bottom: 0 !important;
    padding: 20px 20px 20px !important;
  }
}

.selectLabel ::v-deep .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.selectLabel ::v-deep .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep .el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.textOperator {
  color: #409eff;
  cursor: pointer;
  font-size: 22px;
}

.textOperator1 {
  color: #f56c6c;
  cursor: pointer;
  font-size: 22px;
}

.messgeList {
  height: 450px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.downLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7fa;
  cursor: pointer;
  padding: 5px;
}

.downModule {
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 15px;
}
</style>
