<template>
  <div class="box">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane :label="$t('newOrder.order')" name="1">
        <!-- 基础信息 -->
        <el-card style="margin-bottom: 5px;">
          <div slot="header" class="clearfix">
            <span>{{ $t('newOrder.BasicInformation') }}</span>
          </div>
          <BasicsCmp
            ref="claimBasicsRef" :ids="ids" :total-value="totalValue"
            :total-len="totalLen" @change="onBasicsChange" @clearList="onClearList"
          />
        </el-card>

        <!-- 关联订单 -->
        <el-card>
          <div slot="header" class="clearfix">
            <span>{{ $t('ClaimsManagement.LinkedOrder') }}</span>
          </div>
          <OrderCmp ref="claimOrderRef" :basics-row="basicsRow" @getData="getOrderData"/>
        </el-card>
      </el-tab-pane>
      <!-- 理赔流程 -->
      <el-tab-pane :label="$t('ClaimsManagement.ClaimSettlementProcess')" name="2" :disabled="claimsStatus === 0">
        <HistoryCmp ref="claimHistoryRef" />
      </el-tab-pane>
    </el-tabs>

    <div class="footer">
      <div />
      <div>
        <!-- 取消 -->
        <el-button size="small" @click="() => onClose(false)">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <!-- 关闭 -->
        <el-button v-if="isCloseBut" size="small" type="danger" @click="onAddClose">{{ $t('GlobalSetObj.close') }}</el-button>
        <!-- 新增 -->
        <el-button v-if="isAddBut" size="small" type="primary" @click="onAdd">{{ $t('GlobalSetObj.add') }}</el-button>
        <!-- 修改 -->
        <el-button v-if="isEditBut" size="small" type="primary" @click="onEdit">{{ $t('CenterForSorting.modify') }}</el-button>
        <!-- 驳回提交 提交审核 -->
        <el-button v-if="isSubmitBut" size="small" type="primary" @click="onSubmit">{{ $t('ClaimsManagement.SubmitForReview') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import BasicsCmp from './BasicsCmp.vue';
import OrderCmp from './OrderCmp.vue';
import { basicsRow } from './utils.js';
import Big from 'big.js';
import { apiClaimformAdd, apiClaimformIds, apiClaimformEdit, apiClaimformCommit, apiClaimformClose } from '@/api/claimsManagement/list';
import { CLAIMS_STATUS_SELECT_OBJ, CLAIMSNODE_TYPE_OBJ, COLLECT_TYPE_OBJ } from '@/views/claimsManagement/claimList/utils.js';

export default {
  name: '',

  components: {
    BasicsCmp,
    OrderCmp,
    HistoryCmp: () => import('./../Edit/HistoryCmp.vue')
  },

  props: {
    ids: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      activeName: '1',

      claimsStatus: 0, // 当前订单理赔状态

      // 基础信息
      basicsRow: {
        ...basicsRow
      },

      orderList: [] // 关联订单

      // claimFlowsLen: 0 // 理赔流程长度

      // totalValue: 0 // 理赔金额
    };
  },

  computed: {

    // 总申报价值 === 理赔金额
    totalValue() {
      // const val = 0;
      const { claimsNode, orderType } = this.basicsRow;
      const orderList = this.orderList;
      const len = orderList.length;

      if (len === 0) {
        return 0;
      }

      // 如果当前是备货揽收则，金额最大判断最大不能超出75*n
      if (claimsNode === CLAIMSNODE_TYPE_OBJ['2'].value && orderType === COLLECT_TYPE_OBJ['3'].value) {
        return 75 * len;
      }

      return orderList.reduce((acc, item) => {
        // return add(item.totalDeclaredValue, acc);
        return new Big(acc).plus(item.totalDeclaredValue || 0);
      }, 0).toNumber();
    },

    // 总票数
    totalLen() {
      return this.orderList.length;
    },

    // 是否显示新增按钮
    isAddBut() {
      // 新增
      if (!this.ids) {
        return true;
      }

      // 编辑
      return false;
    },

    // 是否显示修改按钮
    isEditBut() {
      if (!this.ids) {
        return false;
      }

      // 当前处于 待提交 状态
      return this.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['0'].value;
    },

    // 是否显示提交按钮
    isSubmitBut() {
      if (!this.ids) {
        return false;
      }

      // 当前处于 待提交、已驳回 状态
      return this.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['0'].value || this.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['2'].value;
    },

    // 关闭
    isCloseBut() {
      if (!this.ids) {
        return false;
      }

      // 当前处于 待提交、已驳回 状态
      return this.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['0'].value || this.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['2'].value;
    }
  },

  created() {
    if (this.ids) {
      // 获取详情
      this.asyncApiClaimformIds();
    }
  },

  methods: {
    // 获取详情
    asyncApiClaimformIds() {
      apiClaimformIds(this.ids).then(res => {
        const { claimsStatus, claimRelatedOrdersList, claimFlows } = res.data;
        this.claimsStatus = claimsStatus;

        // 基础信息
        this.$refs.claimBasicsRef.setFormData(res.data);

        // 关联订单-因为在回显的时候回填 客户、理赔节点、揽收类别 会触发关联订单的清空操作，故使用定时器，延迟渲染
        setTimeout(() => {
          this.$refs.claimOrderRef.setDataList(claimRelatedOrdersList || []);
        }, 300);

        // 历史记录
        if (res.data.claimFlows.length !== 0) {
          // this.claimFlowsLen = claimFlows.length;
          this.$refs.claimHistoryRef.setDataList(claimFlows || []);
        }
      }).catch(err => {
        console.error(err);
      });
    },

    // 基础信息更新
    onBasicsChange(row) {
      Object.assign(this.basicsRow, row);
    },

    // 获取订单数据
    getOrderData(val) {
      this.orderList = val;
    },

    // 清空关联订单
    onClearList() {
      this.$refs.claimOrderRef.onClear();
    },

    // 校验
    async submitForm() {
      // 基础信息校验
      {
        const isBool = await this.$refs.claimBasicsRef.submitForm();
        if (!isBool) {
          this.$message.warning('请填写基础信息');
          return false;
        }
      }

      // 关联订单校验
      {
        const isBool = this.$refs.claimOrderRef.submitForm();
        if (!isBool) {
          return false;
        }
      }

      return true;
    },

    // 表单数据处理
    setFormData() {
      // 获取基础信息表单数据
      const obj = this.$refs.claimBasicsRef.getFormData();

      // 获取关联订单表单数据
      const arr = this.$refs.claimOrderRef.getDataList();

      return {
        id: this.ids || undefined,
        ...obj,
        relatedOrders: arr
      };
    },

    // 校验 + 表单数据处理
    async asyncSubmitAndGetData() {
      // 校验
      {
        const isBool = await this.submitForm();
        if (!isBool) {
          return [false, {}];
        }
      }

      // 表单数据处理
      const obj = this.setFormData();
      return [true, obj];
    },

    // 提交
    async onSubmit() {
      if (this.claimsStatus === CLAIMS_STATUS_SELECT_OBJ['0'].value) {
      // 当前订单状态为 待提交 状态
        apiClaimformCommit(this.ids).then(res => {
          this.onClose(true);
        }).catch(err => {
          console.error(err);
        });
        return;
      }

      // 当前订单状态为 驳回 状态
      const [isBool, obj] = await this.asyncSubmitAndGetData();
      if (!isBool) {
        return;
      }

      obj.auditStatus = 1; // 只有在驳回的时候才需要此字段

      this.asyncEidt(obj);
    },
    // 新增
    async onAdd() {
      const [isBool, obj] = await this.asyncSubmitAndGetData();

      if (!isBool) {
        return;
      }

      // 新增
      this.asyncAdd(obj);
    },

    // 修改
    async onEdit() {
      const [isBool, obj] = await this.asyncSubmitAndGetData();

      if (!isBool) {
        return;
      }

      this.asyncEidt(obj);
    },

    // 添加
    asyncAdd(data) {
      apiClaimformAdd(data).then(res => {
        this.onClose(true);
      }).catch(err => {
        console.error(err);
      });
    },

    // 修改
    asyncEidt(data) {
      apiClaimformEdit(data).then(res => {
        this.onClose(true);
      }).catch(err => {
        console.error(err);
      });
    },

    onClose(isBool) {
      this.$emit('close', isBool);
    },

    // 关闭
    onAddClose() {
      this.$confirm('确认关闭?', this.$t('GlobalSetObj.tips'), {
        confirmButtonText: this.$t('GlobalSetObj.determine'),
        cancelButtonText: this.$t('GlobalSetObj.cancel'),
        type: 'warning'
      }).then(() => {
        // this.$message({
        //   type: 'success',
        //   message: '删除成功!'
        // });
        return apiClaimformClose(this.ids);
      }).then(() => {
        // 操作成功
        this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
        this.onClose(true);
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
        console.error('取消操作');
      });
    }
  }

};
</script>

<style lang="scss" scoped>

.box {
  position: relative;
  top: 0;
  left: 0;
  padding: 0 20px;
  // height: 100vh;
  height: calc(100vh - 70px);
  overflow-y: auto;
}

.footer {
  // border: 1px solid #000;
  z-index: 10;
  position: sticky;
  bottom: 5px;
  right: 20px;
  display: flex;
  width: auto;
  justify-content: space-between;
  margin: 20px 0 0 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  transition: .3s;
  padding: 5px;
}
</style>
