<template>
  <div class="container">
    <div style="margin-bottom: 20px;">
      <div style="position: relative">
        <div class="headerTitle">{{ $t('customerManagements.tip100') }}</div>
        <div class="chajian">({{ $t('placeOrder.automaticPrinting1') }}
          <el-link
            href="https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/software/安骏桌面插件/安骏桌面插件.exe"
            style="font-size: 16px"
            type="primary"
          >{{ $t('placeOrder.automaticPrinting2') }}
          </el-link>
          )
        </div>
      </div>
      <el-row :gutter="20">
        <el-col :span="15">
          <el-card shadow="never">
            <div style="display: flex;align-items: center">
              <span style="font-size: 16px;margin-right: 15px;font-weight: bold">
                {{ $t('GlobalSetObj.ScanNumber') }}
              </span>
              <el-input
                ref="SingleNumberInput"
                v-model="ruleForm.SingleNumber"
                v-focus="isFocus"
                :placeholder="$t('GlobalSetObj.PleaseInputScanNumber')"
                clearable
                style="width: 280px"
                @keyup.enter.native="PrintSingleNumber"
              />
            </div>
          </el-card>
        </el-col>
        <el-col :span="9">
          <el-card shadow="never">
            <div style="font-size: 16px;font-weight: bold;margin-bottom: 15px">{{
              $t('customerManagements.scannerRecord')
            }}
            </div>
            <div style="height: 600px;overflow-y: auto">
              <div v-for="(item, index) in tableData" :key="index">
                <span> {{ tableData.length - index }}. </span>
                <span>{{ item }}</span>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>
<script>
import { printswapscanChangeOrder } from '@/api/logisticsOrders/index.js';
import { apiStartUp, autoPrint } from '@/api/serialPort';

const okMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/su.wav';
const errorMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/print_error.wav';
export default {
  name: 'ReplaceScanner',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      ruleForm: {
        SingleNumber: ''
      },
      isFocus: true,
      tableData: [],
      okMp3,
      errorMp3,
      userIdAndNameObj: null,
      queryForm: {
        total: 0,
        pageNumber: 1,
        pageSize: 50
      }

    };
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
  },
  methods: {
    PrintSingleNumber() {
      if (this.ruleForm.SingleNumber) {
        const _this = this;
        const data = {};
        data.waybillNumber = this.ruleForm.SingleNumber;
        const flag = this.tableData.includes(data.waybillNumber);
        if (flag) {
          this.$message.error(this.$t('GlobalSetObj.notrepeatOperation'));
          return;
        }
        console.log(flag, 'flagflag');
        // this.tableData.unshift(data.waybillNumber);
        // return;
        printswapscanChangeOrder(this.ruleForm.SingleNumber).then(async result => {
          if (result.code===200) {
            try {
              const res = await apiStartUp();
              console.log(res, 'apiStartUp');
              if (res.code === 200) {
                if (result.data.labelUrl) {
                  autoPrint(result.data.labelUrl).then(async(resPrint) => {
                    if (resPrint.code === 200) {
                      await this.$refs.okRef.play();
                      this.$message.success(this.$t('collectionCenter.printSuc'));
                      this.tableData.unshift(data.waybillNumber);
                      this.ruleForm.SingleNumber = '';
                      _this.$nextTick(_ => {
                        _this.$refs.SingleNumberInput.$refs.input.focus();
                      });
                    }
                  }).catch(async(e) => {
                    await this.$refs.errRef.play();
                    this.$message.error(this.$t('GlobalSetObj.PrintFailure'));
                    // this.ruleForm.SingleNumber = '';
                    _this.$nextTick(_ => {
                      _this.$refs.SingleNumberInput.$refs.input.select();
                    });
                  });
                }
              } else {
                await this.$refs.errRef.play();
                this.$message.error(this.$t('GlobalSetObj.setDefaultPrinter'));
                _this.$nextTick(_ => {
                  // this.ruleForm.SingleNumber = '';
                  _this.$refs.SingleNumberInput.$refs.input.select();
                });
              }
            } catch (e) {
              await this.$refs.errRef.play();
              this.$message.error(this.$t('GlobalSetObj.setDefaultPrinter'));
              _this.$nextTick(_ => {
                // this.ruleForm.SingleNumber = '';
                _this.$refs.SingleNumberInput.$refs.input.select();
              });
            }
          }
        }).catch(async e => {
          await this.$refs.errRef.play();
          _this.$nextTick(_ => {
            // this.ruleForm.SingleNumber = '';
            _this.$refs.SingleNumberInput.$refs.input.select();
          });
        });
      }
    }
  }
};
</script>

<style scoped>
@import "./index.css";
</style>
