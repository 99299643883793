<template> <!-- 发票信息 -->

  <el-table
    :data="tableData"
    :height="tableHeight"
    border
    style="width: 100%"
  >

    <el-table-column :label="$t('newOrder.SerialNumber')" type="index" width="50" />
    <!-- 子包号 -->
    <el-table-column :label="$t('orderCenterCont.SubpackageNumber')" prop="subPacketNumber" />

    <!-- 订单类型 -->
    <el-table-column :label="$t('collectionCenter.tipoTitle')" prop="orderType" />

    <!-- 包裹价值 -->
    <el-table-column :label="$t('GlobalSetObj.packageVlaue')" prop="packetValue" />

    <!-- 开票金额 -->
    <el-table-column :label="$t('GlobalSetObj.kaipiaojine')" prop="invoicedAmount" />

    <!-- 发票号 -->
    <el-table-column :label="$t('newOrder.InvoiceNumber')" prop="invoiceNumber" />

    <!-- 发票密钥 -->
    <el-table-column :label="$t('newOrder.InvoiceKey')" prop="invoiceKey" />

    <!-- 发票序列号 -->
    <el-table-column :label="$t('newOrder.TheInvoiceSerialNumber')" prop="invoiceSerial" />

    <!-- 税码 -->
    <el-table-column :label="$t('newOrder.TaxCode')" prop="taxCode" />

    <!-- 生成时间 -->
    <el-table-column :label="$t('newOrder.BuildTime')" prop="generationTime" />

    <!-- 州注册号 -->
    <el-table-column :label="$t('GlobalSetObj.NationalRegistrationNumber')" prop="ienumber" />
  </el-table>
</template>

<script>

import { commonDesensitize } from '@/utils/desensitization';

export default {
  name: '',

  props: {
    // 高度
    height: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      tableData: [
        { name: '' }
      ]
    };
  },

  computed: {
    tableHeight() {
      return this.height - 70;
    }
  },

  created() {

  },

  methods: {
    getData() {
      return this.tableData;
    },

    setData(arr) {
      this.tableData = arr.map(item => {
        const hasPermission = this.$store.getters.button_permissions.includes('field:customer-sensitive-info');
        if (!hasPermission) {
          // 发票信息脱敏
          if (item.invoiceNumber) {
            item.invoiceNumber = commonDesensitize(item.invoiceNumber);
          }
          if (item.invoiceKey) {
            item.invoiceKey = commonDesensitize(item.invoiceKey);
          }
        }
        return { ...item };
      });
    }
  }

};
</script>

<style scoped>
</style>
