<template>
  <div class="app-container">
    <el-card class="box-card" style="margin-bottom: 10px">
      <el-form size="small">
        <el-row :gutter="10">
          <el-col :span="6">
            <!--单据号-->
            <el-form-item :label="$t('GlobalSetObj.DocumentNumber')">
              <el-input
                v-model="returnWaybillNumber"
                :placeholder="$t('GlobalSetObj.PleaseEnterTheWaybillNumber')"
                :rows="4"
                type="textarea"
              />
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-row :gutter="10">
              <el-col :span="10">
                <!--日期-->
                <el-form-item :label="$t('GlobalSetObj.Date')">
                  <el-date-picker
                    v-model="timeValue"
                    :end-placeholder="$t('GlobalSetObj.endDate')"
                    :start-placeholder="$t('GlobalSetObj.startDate')"
                    clearable
                    range-separator="~"
                    style="width: 100%"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <!--网点编号-->
                <el-form-item :label="$t('collectionCenter.siteNum')">
                  <el-input
                    v-model="queryForm.siteCode"
                    :placeholder="$t('GlobalSetObj.pleaseInput')"
                    clearable
                    style="width: 100%"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <!--网点名称-->
                <el-form-item :label="$t('collectionCenter.siteName')">
                  <el-input
                    v-model="queryForm.siteName"
                    :placeholder="$t('GlobalSetObj.pleaseInput')"
                    clearable
                    style="width: 100%"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="activeName === '1' || activeName === '2'" :gutter="10">
              <el-col :span="8">
                <el-form-item>
                  <el-checkbox-group v-model="recycleStatus">
                    <!--已确认-->
                    <el-checkbox :label="1">{{ $t('GlobalSetObj.Confirmed') }}</el-checkbox>
                    <!--未确认-->
                    <el-checkbox :label="0">{{ $t('GlobalSetObj.Unconfirmed') }}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <el-button
          icon="el-icon-search" size="small" type="primary"
          @click="searchData"
        >
          {{ $t('collectionCenter.search') }} </el-button><!--查询-->
        <!--        <el-button-->
        <!--          v-if="activeName === '1'"-->
        <!--          icon="el-icon-printer"-->
        <!--          size="small"-->
        <!--          type="primary"-->
        <!--          @click="printWaybill"-->
        <!--        >-->
        <!--          {{ $t('GlobalSetObj.PrintWaybill') }} </el-button>&lt;!&ndash;打印运单&ndash;&gt;-->
        <el-button
          v-if="activeName === '2'" size="small" type="primary"
          @click="confirmRecycling"
        >
          {{ $t('GlobalSetObj.RecyclingConfirmation') }} </el-button><!--回收确认-->
        <el-button
          icon="el-icon-upload2" size="small" type="info"
          @click="exportData"
        >
          {{ $t('newOrder.export') }} </el-button><!--导出-->
        <el-button icon="el-icon-refresh" size="small" @click="reset">
          {{ $t('collectionCenter.reset') }} </el-button><!--重置-->
      </div>
    </el-card>
    <el-card class="box-card">
      <el-tabs v-model="activeName" type="border-card" @tab-click="changeTabs">
        <!--我返回的-->
        <el-tab-pane :label="$t('GlobalSetObj.IReturned')" name="1">
          <myReturn ref="myReturn" :query-form="queryForm" />
        </el-tab-pane>
        <!--我回收的-->
        <el-tab-pane :label="$t('GlobalSetObj.IRecycled')" name="2">
          <myRecycling ref="myRecycling" :query-form="queryForm" />
        </el-tab-pane>
        <!--领用明细-->
        <!--        <el-tab-pane :label="$t('GlobalSetObj.ReceivingDetails')" name="3">-->
        <!--          <collectionDetails ref="collectionDetails" :query-form="queryForm" />-->
        <!--        </el-tab-pane>-->
        <!--调整明细-->
        <el-tab-pane :label="$t('GlobalSetObj.AdjustmentDetails')" name="4">
          <adjustDetails ref="adjustDetails" :query-form="queryForm" />
        </el-tab-pane>
        <!--流出明细-->
        <el-tab-pane :label="$t('GlobalSetObj.OutflowDetails')" name="5">
          <flowOutDetails ref="flowOutDetails" :query-form="queryForm" />
        </el-tab-pane>
        <!--流入明细-->
        <el-tab-pane :label="$t('GlobalSetObj.InflowDetails')" name="6">
          <inflowDetails ref="inflowDetails" :query-form="queryForm" />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'DetailManagement',
  components: {
    myReturn: () => import('./cmp/myReturn.vue'), // 我返回的
    myRecycling: () => import('./cmp/myRecycling.vue'), // 我回收的
    // collectionDetails: () => import('./cmp/collectionDetails.vue'), // 领用明细
    adjustDetails: () => import('./cmp/adjustDetails.vue'), // 调整明细
    flowOutDetails: () => import('./cmp/flowOutDetails.vue'), // 流出明细
    inflowDetails: () => import('./cmp/inflowDetails.vue') // 流入明细
  },
  data() {
    return {
      queryForm: {
        returnWaybillNumberList: [], // 运单号
        startTime: '', // 起始日期
        endTime: '', // 结束日期
        siteCode: this.$store.state.user.siteCode, // 网点编号
        siteName: this.$store.state.user.dotName, // 网点名称
        recycleStatus: null, // 是否确认
        waybillPrintStatus: null // 是否打印
      },
      activeName: '1',
      recycleStatus: [0, 1],
      returnWaybillNumber: '',
      timeValue: []
    };
  },
  created() {
    this.getTimer();
    this.queryForm = this.searchForm();
    console.log(this.$store.state.user, '===this.$store.state.user');
  },
  methods: {
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      const oneWeekAgo = dayjs(brazilTime).subtract(1, 'week').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo, today];
    },
    // 卡片切换
    changeTabs(tab) {
      this.activeName = tab.name;
      this.$nextTick(() => {
        switch (this.activeName) {
          case '1':
            this.$refs.myReturn.doLayout();
            break;
          case '2':
            this.$refs.myRecycling.doLayout();
            break;
          case '3':
            // this.$refs.collectionDetails.doLayout();
            break;
          case '4':
            this.$refs.adjustDetails.doLayout();
            break;
          case '5':
            this.$refs.flowOutDetails.doLayout();
            break;
          case '6':
            this.$refs.inflowDetails.doLayout();
            break;
        }
      });
    },
    // 搜索数据处理
    searchForm() {
      const recycleStatus = this.recycleStatus.length === 1 ? this.recycleStatus[0] : null;
      const orderNum = this.returnWaybillNumber.split(/[,\n\r，]/); // /[(\r\n)\r\n]+/
      const orderNumArr = [];
      for (let j = 0; j < orderNum.length; j++) {
        const orderNumber = orderNum[j].replace(/\s+/g, '');
        if (orderNumber !== '') {
          if (orderNumArr.indexOf(orderNumber) === -1) {
            orderNumArr.push(orderNumber.trim());
          }
        }
      }
      let time = [];
      if (this.timeValue && this.timeValue.length > 0) {
        time.push(this.timeValue[0] + ' 00:00:00');
        time.push(this.timeValue[1] + ' 23:59:59');
      } else {
        time = ['', ''];
      }
      return {
        returnWaybillNumberList: orderNumArr, // 运单号
        startTime: time[0], // 起始日期
        endTime: time[1], // 结束日期
        siteCode: this.queryForm.siteCode, // 网点编号
        siteName: this.queryForm.siteName, // 网点名称
        recycleStatus: recycleStatus // 是否确认
      };
    },
    // 搜索
    searchData() {
      this.queryForm = this.searchForm();
      switch (this.activeName) {
        case '1':
          this.$refs.myReturn.getTableData(this.queryForm);
          break;
        case '2':
          this.$refs.myRecycling.getTableData(this.queryForm);
          break;
        case '3':
          // this.$refs.collectionDetails.getTableData(this.queryForm);
          break;
        case '4':
          this.$refs.adjustDetails.getTableData(this.queryForm);
          break;
        case '5':
          this.$refs.flowOutDetails.getTableData(this.queryForm);
          break;
        case '6':
          this.$refs.inflowDetails.getTableData(this.queryForm);
          break;
      }
    },
    // // 打印运单
    // printWaybill() {
    //   this.$refs.myReturn.printWaybill();
    //   this.$refs.myRecycling.getTableData(this.queryForm);
    // },
    // 批量确认回收
    confirmRecycling() {
      this.$refs.myRecycling.batchConfirmReturnQuantity();
    },
    // 导出
    exportData() {
      this.queryForm = this.searchForm();
      switch (this.activeName) {
        case '1':
          this.$refs.myReturn.exportData(this.queryForm);
          break;
        case '2':
          this.$refs.myRecycling.exportData(this.queryForm);
          break;
        case '3':
          // this.$refs.collectionDetails.exportData(this.queryForm);
          break;
        case '4':
          this.$refs.adjustDetails.exportData(this.queryForm);
          break;
        case '5':
          this.$refs.flowOutDetails.exportData(this.queryForm);
          break;
        case '6':
          this.$refs.inflowDetails.exportData(this.queryForm);
          break;
      }
    },
    // 重置
    reset() {
      this.queryForm = {
        returnWaybillNumberList: [], // 运单号
        startTime: '', // 起始日期
        endTime: '', // 结束日期
        siteCode: this.$store.state.user.siteCode, // 网点编号
        siteName: this.$store.state.user.dotName, // 网点名称
        recycleStatus: null, // 是否确认
        waybillPrintStatus: null // 是否打印
      };
      this.recycleStatus = [0, 1];
      this.returnWaybillNumber = '';
      this.getTimer();
      this.searchData();
    }
  }
};
</script>

<style scoped></style>
