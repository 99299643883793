<template>
  <div class="Box">
    <div class="scanClass">
      <!-- 扫描： -->
      <span>{{ $t('collectionCenter.scanBtn') }}</span>
      <el-input
        v-model.trim="number"
        :placeholder="$t('collectionCenter.pleaseEnterOrScan')"
        prefix-icon="el-icon-full-screen"
        clearable
        class="iconBtn"
        @keyup.enter.native="pushTable"
      />
    </div>
    <div :class="upLoadFile.length > 0 ? 'uploadBox addH' : 'uploadBox'">
      <div class="uploadClass">
        <el-upload
          ref="demoUpload"
          action
          :limit="1"
          :file-list="upLoadFile"
          list-type="picture"
          :before-upload="beforeUpload"
          :http-request="uploadDataClick"
          :on-remove="handleRemove"
          :on-exceed="handleExceed"
        >
          <el-button v-permit:remove="'btn:collection-center:packet-scan:upLoadImg'" size="mini">
            {{ $t('WorkCentreTable.UploadPictures') }}
          </el-button>
        </el-upload>
      </div>
      <el-button
        v-permit:remove="'btn:collection-center:packet-scan:upLoad'"
        type="success"
        @click="upload"
      >{{ $t('collectionCenter.Upload') }}</el-button><!-- 上传 -->
    </div>
    <div>
      <el-table
        :max-height="600"
        :data="scanTablePage"
        border
      >
        <el-table-column
          prop="scanNumber"
          align="center"
          :label="$t('collectionCenter.Number')"
        /><!-- 单号 -->
        <el-table-column
          prop="scanTimeStr"
          align="center"
          :label="$t('collectionCenter.scanTime')"
        /><!-- 扫描时间 -->
        <el-table-column
          v-if="isdelBtn"
          :label="$t('collectionCenter.operation')"
          fixed="right"
          width="100"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-popconfirm
              :title="$t('collectionCenter.deleteSure')"
              @confirm="scanDelClick(scope.row,scope.$index)"
            ><!-- 确定删除吗？ -->
              <el-button slot="reference" type="danger" size="mini">{{ $t('collectionCenter.Delete') }}</el-button><!-- 删除 -->
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="dialogVisible" class="resultClass">
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="40%"
        @close="closeDialog"
      >
        <div>
          <div class="vv">{{ $t('collectionCenter.auditBatchNumber') }}：{{ upLoadList.batchNumber }}</div>
          <div class="vv">{{ $t('collectionCenter.auditResult') }}：
            <span :class="upLoadList.auditStatus == 0 ? 'blackText' : upLoadList.auditStatus == 1 ? 'redText' : ''">
              {{ upLoadList.auditStatus == 0 ? $t('userManage.normal') : upLoadList.auditStatus == 1 ? $t('newOperationCenter.abnormal') : '' }}
            </span>
          </div>
          <div class="vv">{{ $t('collectionCenter.checkParcel') }}：{{ upLoadList.packageNumer }}</div>
          <div v-if="upLoadList.auditStatus == 1" class="vv processingResultClass">{{ $t('collectionCenter.processingResult') }}：
            <el-input
              v-model="processingResult" maxlength="300" show-word-limit
              :class="isRed ? 'redBor' : ''" type="textarea" :placeholder="$t('collectionCenter.pleaseInput')"
            />
          </div>
        </div>
        <div style="text-align: center; margin: 20px;"><el-button type="success" plain @click="SubmitResult">{{ $t('collectionCenter.confirm') }}</el-button></div>
        <div v-if="upLoadList.exceptOrderList && upLoadList.exceptOrderList.length >= 1">{{ $t('collectionCenter.abnormalData') }}：</div>
        <div v-if="upLoadList.exceptOrderList && upLoadList.exceptOrderList.length >= 1" class="errorClass">
          <div v-for="(item, index) in upLoadList.exceptOrderList" :key="index">
            {{ item.scanNumber }}<span class="space" />{{ item.collectStatus }}<span class="space" />{{ item.bagNumber }}
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';
import { uploadOos } from '@/utils/ali_oos_util';
import {
  inspectOrderScan,
  queryScanLog,
  deletedScan,
  pcSealABagSubmit,
  pcInspectSubmitResult
} from '@/api/lanshou';
import { getTimes } from '@/utils/watermark/watermark_time';
export default {
  data() {
    return {
      okMp3,
      errorMp3,
      isRed: false,
      dialogVisible: false,
      processingResult: '',
      isdelBtn: this.$store.getters.button_permissions.includes('btn:collection-center:packet-scan:delete'),
      number: '',
      scanTablePage: [],
      upLoadFile: [],
      upLoadList: {}
    };
  },
  watch: {
    processingResult(val) {
      if (val) {
        this.isRed = false;
      }
    }
  },
  created() {
    this.getTableLog();
  },
  methods: {
    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['png', 'bmp', 'jpg', 'jpeg'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        // 仅支持png、bmp、jpg格式的图片
        this.$message.error(this.$t('collectionCenter.OnlyImageFormatIsSupported'));
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        // 上传文件大小不能超过 2MB
        this.$message.warning(this.$t('collectionCenter.uploadNot2MB'));
        return false;
      }
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file); // file转base64
        reader.onload = e => {
          const canvas = document.createElement('canvas');
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            // 图片加载后再添加水印，否则可能报错
            const width = img.width;
            const height = img.height;
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d'); // 绘制2d图形
            ctx.drawImage(img, 0, 0, width, height);
            var basePx = canvas.width;
            // 字体大小  照片添加水印
            var fontSize = basePx / 28; // 水印文字尺寸
            ctx.font = fontSize + 'px Open Sans';
            ctx.fillStyle = 'rgba(255,255,255, 0.5)';
            ctx.shadowOffsetY = 4;
            ctx.shadowBlur = 2;
            ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
            let time = getTimes();
            time = time.split(' ');
            const fontHeight = ctx.measureText(time[0]).fontBoundingBoxAscent + ctx.measureText(time[0]).fontBoundingBoxDescent;
            ctx.fillText(time[0], width - ctx.measureText(time[0]).width - 20, fontHeight);
            ctx.fillText(time[1], width - ctx.measureText(time[1]).width - 20, fontHeight * 2);
            var dataBase64 = canvas.toDataURL(file.type); // 输出压缩后的base64
            // base64转file
            const arr = dataBase64.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            const files = new File(
              [new Blob([u8arr], { type: mime })],
              file.name,
              { type: file.type }
            );
            files.uid = file.uid;
            resolve(files);
          };
        };
      });
    },
    uploadDataClick(option) { // 导入xlsx模版 // 上传数据
      const files = option.file;
      if (files) {
        uploadOos(files, 'scan').then(res => {
          if (res.code === 200) {
            const obj = {
              name: files.name,
              url: res.url
            };
            this.upLoadFile = [...this.upLoadFile, obj];
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    handleRemove(file, upLoadFile) {
      this.upLoadFile = upLoadFile;
    },
    handleExceed() {
      // 最多上传1张照片
      this.$message.warning(this.$t('collectionCenter.upload1Photo'));
    },
    /**
     * 稽查查询扫描记录
     */
    getTableLog() {
      const form = {
        operationType: 22,
        sourceType: 1,
        timeDay: 2
      };
      queryScanLog(form).then(res => {
        if (res.code===200) {
          this.scanTablePage = res.data;
          if (this.scanTablePage.length <= 0) {
            this.number = '';
          }
        }
      });
    },
    pushTable() {
      if (!this.number) return;
      const isAdd = this.scanTablePage.some(el => {
        if (this.scanTablePage.length >= 1) {
          return el.scanNumber === this.number;
        }
      });
      if (isAdd && this.scanTablePage.length > 0) {
        this.$message({
          message: this.$t('collectionCenter.repeatDataTips'),
          type: 'warning'
        });
        this.$refs.errRef.play();
        return;
      }

      const addForm = {
        number: this.number,
        sourceType: 1 // 0:快递系统pda    1:快递系统pc
      };
      inspectOrderScan(addForm).then(res => {
        if (res.code===200) {
          this.number = '';
          this.$refs.okRef.play();
          this.getTableLog();
        } else {
          this.number = '';
          this.$refs.errRef.play();
        }
      }).catch(() => {
        this.number = '';
        this.$refs.errRef.play();
      });
    },
    scanDelClick(row) {
      deletedScan(row.id).then(res => {
        if (res.code===200) {
          if (row.scanNumber == this.number) {
            this.number = '';
          }
          this.getTableLog();
        }
      });
    },
    closeDialog() {
      this.dialogVisible = false;
      this.processingResult = '';
      this.isRed = false;
    },
    upload() {
      if (this.scanTablePage.length <= 0) {
        this.$message({
          message: this.$t('collectionCenter.nullSubmit'), // '无数据提交'
          type: 'warning'
        });
        return;
      }
      // if (this.upLoadFile.length <= 0) {
      //   this.$message({
      //     message: this.$t('WorkCentreTable.pleaseUploadPictures'),
      //     type: 'warning'
      //   });
      //   return;
      // }
      const sumbit = this.scanTablePage.map(arr => {
        return {
          scanId: arr.id,
          orderId: arr.orderId,
          number: arr.scanNumber,
          type: arr.type,
          scanTime: arr.scanTime
        };
      });
      const sumbitForm = {
        scanPackageList: sumbit
      };
      pcSealABagSubmit(sumbitForm).then(res => {
        if (res.code===200) {
          this.dialogVisible = true;
          this.upLoadList = res.data;
          this.getTableLog();
        }
      });
    },
    SubmitResult() {
      if (this.upLoadList.auditStatus === 1 && this.processingResult === '') {
        this.isRed = true;
        return;
      }
      const sumbit = this.scanTablePage.map(arr => {
        return {
          scanId: arr.id,
          orderId: arr.orderId,
          number: arr.scanNumber,
          type: arr.type,
          scanTime: arr.scanTime
        };
      });
      const resultForm = {
        sourceType: 1,
        img: this.upLoadFile.length > 0 ? this.upLoadFile[0].url : '',
        result: this.processingResult,
        batchNumber: this.upLoadList.batchNumber,
        scanPackageList: sumbit
      };
      console.log(resultForm);
      pcInspectSubmitResult(resultForm).then(res => {
        if (res.code===200) {
          this.upLoadFile = [];
          this.scanTablePage = [];
          this.number = '';
          this.dialogVisible = false;
          this.processingResult = '';
          this.$message.success(this.$t('collectionCenter.UploadSuccessful')); // 上传成功
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>

  .iconBtn::after{
    position: absolute;
    content: "-";
    top: 9px;
    left: 15px;
    transform: scaleX(2.3);
    color: #bbb;
  }
.resultClass{
  font-size: 16px;
  .vv{
    line-height: 30px;
  }
  .blackText{color: #000;font-size: 20px;}
  .redText{color: red;font-size: 20px;}
  .processingResultClass{
    margin-left: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    &::before{
      content: "*";
      color: red;
      position: absolute;
      top: 0;
      left: -7px;
    }
  }
  .space{
    margin-left: 10px;
  }
  .errorClass{
    max-height: 350px;
    overflow-y: auto;
  }
}
.resultClass ::v-deep .el-dialog{
  margin-top: 0 !important;
  margin-bottom: 100px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.processingResultClass ::v-deep .el-textarea{
  width: 50%;
  max-width: 60%;
}
.processingResultClass ::v-deep .el-textarea__inner{
  max-height: 200px !important;
}
.redBor ::v-deep .el-textarea__inner{
  border-color: #ff004f;
}

.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.scanClass ::v-deep .el-input{
  width: 350px;
}
.uploadBox ::v-deep .el-button{
  font-size: 16px;
  padding: 7px 20px;
  line-height: 17px;
}
.uploadBox{
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
}
.addH.uploadBox {
  margin-bottom: 120px;
}
.uploadClass{
  display: inline-block;
  margin-right: 20px;
}
.uploadClass ::v-deep .el-upload-list {
  position: absolute;
  left: 20px;
}

</style>
