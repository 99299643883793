<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="7">
          <!--装车时间-->
          <el-form-item :label="$t('GlobalSetObj.LoadCarTime')">
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :placeholder="$t('newOrder.SelectDatetime')"
              range-separator="~"
              style="width:100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--  运单号   -->
          <el-form-item>
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.selectType"
                  :style="IsWidth"
                >
                  <el-option key="1" :label="$t('newOrder.waybillNumber')" :value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.bagNumber')" :value="2" />
                </el-select>
              </div>
            </template>
            <el-input
              v-model="queryForm.params.inputValue"
              :placeholder="$t('newOrder.PreciseSearch1')"
              :rows="1"
              style="width:100%"
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <div style="margin-top: 28px;">
            <!--搜索-->
            <el-button
              icon="el-icon-search" size="small" type="primary"
              @click="enterSearch"
            >{{
              $t('newOrder.search')
            }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh-left" size="small" type="info"
              @click="resetForm()"
            >{{
              $t('newOrder.reset')
            }}
            </el-button>
            <el-button
              icon="el-icon-upload2" size="small" style="margin-bottom: 10px"
              @click="allExportOrder"
            >{{
              $t('newOrder.export')
            }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')"
        align="center"
        type="index"
        width="80"
      />
      <el-table-column
        :label="$t('GlobalSetObj.bagNumber')"
        min-width="130"
        prop="bagNumber"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.bagNumber || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('newOrder.waybillNumber')"
        min-width="160"
        prop="waybillNumber"
      >
        <template slot-scope="scope">
          <div>
            <el-popover placement="top" popper-class="copy" trigger="hover">
              <el-button
                style="color: black;font-size: 12px;padding: 2px"
                type="text"
                @click="copy(scope.row.waybillNumber)"
              >{{ $t('orderDetails.copy') }}
              </el-button>
              <div
                slot="reference"
                class="WaybillNumber"
                @click="toOrderDetail(scope.row)"
              >
                {{ scope.row.waybillNumber || '--' }}
              </div>
            </el-popover>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('GlobalSetObj.liyingsongdaCenter')"
        min-width="160"
        prop="expectedDeliveryStation"
      />
      <el-table-column
        :label="$t('GlobalSetObj.shijidaodaCenter')"
        min-width="170"
        prop="actualDeliveryStation"
      />
      <!-- 车签号  -->
      <el-table-column
        :label="$t('GlobalSetObj.NIV')"
        min-width="120"
        prop="vehicleTagNumber"
      />
      <!--   装车人   -->
      <el-table-column
        :label="$t('GlobalSetObj.Carregador')"
        min-width="160"
        prop="loadingUserName"
      />
      <!--   装车时间   -->
      <el-table-column
        :label="$t('GlobalSetObj.LoadCarTime')"
        min-width="180"
        prop="loadingTime"
      />
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--详情-->
    <el-drawer
      :append-to-body="true"
      :title="$t('orderDetails.waybillMsg')"
      :visible.sync="waybillDescription"
      custom-class="drawerClass"
      direction="ltr"
      size="80%"
    >
      <div style="height: calc(100vh - 67px); overflow: auto">
        <detailPage :key="detailKey" :query-track-param="queryTrackParam" query-type="1" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { allocationErrorRate, exportAllocationErrorRate } from '@/api/orderApi';
import detailPage from '@/components/detailComponent/detailPage.vue';
import { copyData } from '@/utils/copyData';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'MisallocationRate',
  components: {
    detailPage
  },
  data() {
    return {
      // 详情
      waybillDescription: false,
      detailKey: new Date().getTime(),
      queryTrackParam: {},
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          inputValue: '',
          selectType: 1
        }
      },
      tableData: []
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '220px' : '120px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getSearch(1);
  },
  methods: {
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.getTimer();
      this.queryForm.params = {
        inputValue: '',
        selectType: 1
      };
      this.getSearch();
    },
    handleTest() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 查询
    getSearch(val) {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.loadingStartTime = this.timeValue[0];
        data.params.loadingEndTime = this.timeValue[1];
      } else {
        this.$message.warning(this.$t('GlobalSetObj.selectTime'));
        return;
      }
      if (this.queryForm.params.selectType === 1) {
        data.params.waybillNumbers = inputValList;
      } else if (this.queryForm.params.selectType === 2) {
        data.params.bagNumbers = inputValList;
      } else {
        data.params.scanNumber = inputValList;
      }
      let loading = null;
      if (val === 1) {
        loading = this.$loading({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: 'loading...',
          background: 'rgba(250, 250, 250, 0.6)'
        });
      }
      allocationErrorRate(data).then(res => {
        if (res.code===200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          if (val === 1) {
            loading.close();
          }
          console.log(this.tableData, 'tableData');
        }
      }).catch(() => {
        if (val === 1) {
          loading.close();
        }
      });
    },
    allExportOrder() {
      let inputValList = [];
      if (this.queryForm.params.inputValue) {
        inputValList = this.queryForm.params.inputValue.split('\n');
        if (inputValList.length > 2000) {
          this.$message.warning(this.$t('newOrder.searchMax1000'));
          inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
        }
      }
      const data = {};
      if (this.timeValue && this.timeValue.length > 0) {
        data.loadingStartTime = this.timeValue[0];
        data.loadingEndTime = this.timeValue[1];
      } else {
        this.$message.warning(this.$t('GlobalSetObj.selectTime'));
        return;
      }
      if (this.queryForm.params.selectType === 1) {
        data.waybillNumbers = inputValList;
      } else if (this.queryForm.params.selectType === 2) {
        data.bagNumbers = inputValList;
      } else {
        data.scanNumber = inputValList;
      }
      exportAllocationErrorRate(data).then(res => {
        goExportList(this);
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      const oneWeekAgo = dayjs(brazilTime).subtract(1, 'week').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    // 进入运单详情
    toOrderDetail(row) {
      // row.waybillId = row.id;
      this.queryTrackParam = row;
      console.log(row, ':query-track-param="queryTrackParam"');
      console.log(row.waybillId, 'row.waybillIdrow.waybillIdrow.waybillId', this.queryTrackParam.waybillId);
      this.waybillDescription = true;
      this.detailKey = new Date().getTime();
      setTimeout(() => {
        this.bus.$emit('todetailBox', row.waybillId);
      }, 100);
    },
    // 点击复制
    copy(data) {
      copyData(data);
    }
  }
};
</script>

<style scoped>
.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.WaybillNumber {
  color: #409eff;
  cursor: pointer;
  padding: 0;
}
</style>
