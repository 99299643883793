<template>
  <div class="app-content">
    <el-card style="margin-bottom: 10px;">
      <el-form
        ref="form" :model="params" label-width="auto"
        :label-position="'top'"
      >
        <div class="searchContainer">
          <el-row :gutter="20">
            <el-col :span="6">
              <!--付款批次号/条码-->
              <el-form-item label="" class="selectInner">
                <template slot="label">
                  <el-select
                    id="orderType" v-model="orderType" :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    style="width:100%;"
                  >
                    <el-option
                      v-for="item in CODE_TYPE"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </template>
                <el-input
                  id="paymentBatchNumber" v-model="params.paymentBatchNumber" :placeholder="$t('GlobalSetObj.pleaseInputContent')"
                  clearable class="input-with-select"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--批次号状态-->
              <el-form-item :label="$t('receivablePayable.BatchNumberStatus')">
                <el-select
                  id="paymentStatus" v-model="params.paymentStatus" :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  class="form-bottom" style="width: 100%" clearable
                >
                  <el-option
                    v-for="item in BATCH_STATUS"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--创建付款时间-->
              <el-form-item :label="$t('receivablePayable.CreatePaymentTime')">
                <el-date-picker
                  id="val2"
                  v-model="params.val2"
                  style="width: 100%"
                  type="datetimerange"
                  :picker-options="dateLimit"
                  :default-time="['00:00:00', '23:59:59']"
                  :range-separator="$t('operationCenter.to')"
                  :start-placeholder="$t('operationCenter.startDate')"
                  :end-placeholder="$t('operationCenter.endDate')"
                  value-format="yyyy-MM-dd HH:mm:ss"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>

      <el-button
        type="primary"
        size="small"
        icon="el-icon-search"
        @click="onQuery"
      >{{ $t('operationCenter.Procurar') }}</el-button>
      <el-button size="small" icon="el-icon-refresh-left" @click="onReset">
        {{ $t('operationCenter.reset') }}
      </el-button>
    </el-card>

    <el-card>
      <div class="btnList">
        <div>
          <!-- 导入文件 -->
          <upload-excel
            v-permit:remove="'btn:finance:payableManagement:bankPaymentManagement:import'"
            :on-success="importLogistics"
            :but-name="$t('receivablePayable.ImportFile')"
            style="display: inline-block;"
          />
          <!-- 下载模板 -->
          <downloadTemplate />
          <!-- 发起付款 -->
          <el-button
            v-permit:remove="'btn:finance:payableManagement:bankPaymentManagement:pay'"
            type="warning"
            size="small"
            :disabled="isPay"
            @click="pay"
          >{{ $t('receivablePayable.InitiatePayment') }}</el-button>

          <!-- 查询结果 -->
          <el-button
            v-permit:remove="'btn:finance:payableManagement:bankPaymentManagement:result'"
            type="primary"
            size="small"
            :disabled="isSearch"
            @click="onQueryResult"
          >{{ $t('receivablePayable.QueryResults') }}</el-button>
        </div>

        <!-- 导出 -->
        <el-button
          v-permit:remove="'btn:finance:payableManagement:bankPaymentManagement:export'"
          type="info"
          size="small"
          @click="onExport"
        >{{ $t('CenterForSorting.export') }}</el-button>
      </div>

      <!-- @selection-change="handleSelectionChange" -->
      <virtual-scroll
        ref="virtualScroll"
        :data="tableData"
        :item-size="50"
        style="height: 100%"
        key-prop="id"
        @change="virtualList => list = virtualList"
      >
        <el-table
          ref="table"
          :data="list"
          border
          row-key="id"
          :indent="20"
          class="table-height"
          :height="tableHeightRow.height"
          :row-class-name="tableRowClassName"
        >
          <virtual-column width="50" type="selection">
            <template slot-scope="scope">
              <!-- 判断是否为子行，如果是子行则不显示多选框 -->
              <span v-if="scope.row.children">
                <el-checkbox
                  v-model="scope.row.isSelected"
                  :disabled="scope.row.paymentStatus === 2"
                  @change="handleSelectionChange(scope.row)"
                />
              </span>
            </template>
          </virtual-column>
          <!-- 付款批次号/条码 -->
          <virtual-column
            show-overflow-tooltip type="tree" :label="$t('receivablePayable.PaymentBatchNumber')+'/'+$t('receivablePayable.BarCode')"
            prop="paymentBatchNumber" min-width="200"
          >
            <template slot-scope="scope" :row="scope.row">
              <span v-if="scope.row.isParent === true" class="batchNumber">{{ scope.row.paymentBatchNumber }}</span>
              <span v-else>{{ scope.row.barCode }}</span>
            </template>
          </virtual-column>
          <!-- 付款金额 -->
          <el-table-column :label="$t('receivablePayable.TheAmountOfThePayment')" prop="feeTotal" width="140" />
          <!-- 付款状态 -->
          <el-table-column :label="$t('receivablePayable.PaymentStatus')" prop="paymentStatus" width="140">
            <template slot-scope="scope">
              <!-- 批次号状态 -->
              <span v-if="scope.row.children">
                <el-tag size="mini" :type="STATUS_TYPE[scope.row.paymentStatus] && STATUS_TYPE[scope.row.paymentStatus].type">
                  {{ STATUS_TYPE[scope.row.paymentStatus] && STATUS_TYPE[scope.row.paymentStatus].label || '' }}</el-tag>
              </span>
              <!-- 付款单状态 -->
              <span v-else>
                <el-tag v-if="scope.row.paymentStatus !== 3" size="mini" :type="STATUS_TYPE[scope.row.paymentStatus] && STATUS_TYPE[scope.row.paymentStatus].type">
                  {{ STATUS_TYPE[scope.row.paymentStatus] && STATUS_TYPE[scope.row.paymentStatus].label || '' }}</el-tag>
                <el-tag v-else size="mini" type="danger">
                  {{ $t('receivablePayable.付款失败') }}</el-tag>
              </span>
            </template>
          </el-table-column>
          <!-- 付款有效期 -->
          <el-table-column :label="$t('receivablePayable.PaymentValidityPeriod')" prop="paymentDeadline" width="140" />
          <!-- 创建付款时间 -->
          <el-table-column :label="$t('receivablePayable.CreatePaymentTime')" prop="createTime" min-width="140" />
          <!-- 付款成功时间 -->
          <el-table-column :label="$t('receivablePayable.PaymentSuccessTime')" prop="paymentTime" min-width="140" />
          <!-- 创建人 -->
          <el-table-column :label="$t('GlobalSetObj.CreatePeople')" prop="createUserName" width="140" />
        </el-table>
      </virtual-scroll>

      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="pageNumber"
          :page-sizes="[20, 50, 100, 200]"
          :page-size.sync="pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getCurrentTableData"
          @size-change="getSizeTableData"
        />
      </div>
    </el-card>

    <!-- 付款弹窗 -->
    <PayDialog
      :visible="isVisible" :row="row" @submint="submint"
      @close="isVisible = false"
    />
  </div>
</template>

<script>
import VirtualScroll, { VirtualColumn } from 'el-table-virtual-scroll';
import { cloneDeep } from 'lodash-es';
// import dayjs from 'dayjs';
import { apiBradescoPage, apiBradescoImportAdd, apiBradescoExport, apiPayment, apiQueryResult } from '@/api/finance/bankPaymentManagement';
import uploadExcel from '@/components/UploadExcel';
import downloadTemplate from './cmp/ImportTemplate.vue';
import PayDialog from './cmp/PaymentDialog.vue';
import { CODE_TYPE, STATUS_TYPE, BATCH_STATUS } from './utils.js';
import { goResourceTaskList } from '@/utils/goExportList.js';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';

const PARAM = {
  paymentStatus: '', // 状态
  paymentBatchNumber: '', // 批次号、条码
  val2: [] // 开始时间、结束时间
};

export default {
  name: 'BankPaymentManagement',

  components: {
    uploadExcel,
    downloadTemplate,
    VirtualScroll,
    VirtualColumn,
    PayDialog
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      params: {
        ...PARAM
      },
      orderType: 1, // 批次号/条码

      tableData: [], // 列表数据
      list: [], // 可视列表数据
      multipleSelection: [], // 多选
      pageNumber: 1, // 当前页
      pageSize: 20, // 每页条数
      total: 0, // 总条数

      isVisible: false, // 付款弹窗
      row: {}, // 当前行

      CODE_TYPE,
      STATUS_TYPE,
      BATCH_STATUS,
      pickerMinDate: '',
      pickerRange: 30 * 24 * 60 * 60 * 1000, // 可选择日期范围 为30天

      isFirst: true // 是否首次搜索
    };
  },

  computed: {
    dateLimit() {
      const { pickerMinDate, pickerRange } = this;

      // 默认限制30天
      return {
        onPick: ({ minDate }) => {
          if (minDate && pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (time.getTime() > (pickerMinDate + pickerRange)) || (time.getTime() < (pickerMinDate - pickerRange));
          }
          return false;
        }
      };
    },

    // 是否可发起付款
    isPay() {
      if (this.multipleSelection.length > 0) {
        return this.multipleSelection[0]?.paymentStatus === 1;
      }
      return true;
    },
    // 是否可以查询结果
    isSearch() {
      if (this.multipleSelection.length > 0) {
        return this.multipleSelection[0]?.paymentStatus !== 1;
      }
      return true;
    }
  },

  created() {
    this.getTableData();
    this.isFirst = false;
  },

  activated() {
    if (!this.isFirst) {
      this.getTableData();
    }
  },
  deactivated() {
    console.log('离开');
  },

  methods: {
    // 搜索
    onQuery() {
      this.searchList();
    },

    // 重置
    onReset() {
      this.params = {
        ...PARAM
      };

      this.searchList();
    },

    searchList() {
      this.pageNumber = 1;
      this.pageSize = 20;
      this.getTableData();
    },

    /**
     * 切换页码
     * @param val
     */
    getCurrentTableData(val) {
      this.pageNumber = val;
      this.getTableData();
    },

    /**
     * 切换每页条数
     * @param val
     */
    getSizeTableData(val) {
      this.pageSize = val;
      this.pageNumber = 1;
      this.getTableData();
    },

    // 获取列表数据
    getTableData() {
      apiBradescoPage({
        params: this.QureyParams(),
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code === 200) {
          const { total, records } = res.data;
          this.total = total;

          this.tableData = (records || []).map(item => {
            item.id = item.paymentBatchNumber; // 手动定义id作为table的rowKey
            // 判断是否勾选
            item.isSelected = this.multipleSelection.some(selectedRow => selectedRow.id === item.id);

            item.children = (item.detailsList || []).map(items => {
              items.id = items.barCode;
              return items;
            });

            return item;
          });

          // 更新完tableData后，统一处理选中状态
          this.$nextTick(() => {
            this.tableData.map(item => {
              if (item.isSelected) {
                this.multipleSelection = [item];
                this.$refs.table.toggleRowSelection(item, true); // 设置当前行勾选状态
              }
            });
          });
        }
      }).catch(err => {
        console.error(err);
      });
    },

    /**
     * 多选
     * @param row 勾选数据
     */
    handleSelectionChange(row) {
      const list = cloneDeep(this.tableData);
      this.$refs.table.clearSelection(); // 清空所有勾选状态，防止勾选错乱
      // console.log(this.multipleSelection, '**********************', row)
      // 选中row在multipleSelection中存在，则删除，不存在则添加
      const index = this.multipleSelection.findIndex(item => item.id === row.id);
      if (index !== -1) {
        this.multipleSelection = [];
        this.tableData = list.map(item => {
          item.isSelected = false;
          return item;
        });
      } else {
        this.multipleSelection = [row];
        this.$refs.table.toggleRowSelection(row, true); // 设置当前行勾选状态
        this.tableData = list.map(item => {
          item.isSelected = item.id === row.id;
          return item;
        });
      }
    },
    // 是否高亮
    tableRowClassName({ row }) {
      if (this.multipleSelection.some(selectedRow => selectedRow.id === row.id)) {
        return 'highlight-row';
      }
      return '';
    },

    /**
     * 导入账单
     */
    async importLogistics(importData) {
      const header = importData[0].header;
      const ImportList = importData[0].results;
      console.log(ImportList, header);
      const dataList = ImportList.map(obj => {
        const data = header.reduce((acc, key, index) => {
          const mappedKey = ['barCode', 'fee', 'paymentDeadline'][index] || key;
          acc[mappedKey] = obj[key] ?? '';
          return acc;
        }, {});
        return data;
      });
      console.log(dataList);
      // return;

      if (!dataList.length) {
        this.$message({
          type: 'warning',
          message: this.$t('userManage.ImportDataIsEmpty')
        });
        return;
      }

      if (dataList.length > 3000) {
        this.$message({
          type: 'warning',
          message: this.$t('receivablePayable.Only3000PiecesImport')
        });
        return;
      }

      try {
        const res = await apiBradescoImportAdd({ dataList });
        if (res.code === 200 && !res.data) {
          this.$message({
            type: 'success',
            message: this.$t('operationCenter.TheImportWasSuccessful')
          });
          this.getTableData(); // 刷新列表数据
        } else {
          const list = res.data?.existBarCodeList || res.data?.errorList || [];
          if (list.length > 0) {
            this.ErrroCode(list);
          }
        }
      } catch (err) {
        console.error('导入账单时发生错误:', err);
      }
    },

    /**
     * 发起付款
     */
    pay() {
      this.isVisible = true;
      this.row = this.multipleSelection[0];
    },
    submint() {
      this.$confirm(`${this.$i18n.t('GlobalSetObj.determine')}${this.$i18n.t('ClaimsManagement.Payment')}？`, this.$i18n.t('customerManagements.tips'), {
        confirmButtonText: this.$i18n.t('GlobalSetObj.determine'),
        cancelButtonText: this.$i18n.t('GlobalSetObj.cancel'),
        type: 'warning'
      }).then(() => {
        this.Payment();
      }).catch(() => {
        console.log('用户手动取消');
      });
    },
    Payment() {
      apiPayment({
        paymentBatchNumber: this.multipleSelection[0].paymentBatchNumber
      }).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: this.$t('GlobalSetObj.TheOperationSucceeded')
          });
          this.isVisible = false;
          // 刷新列表数据
          this.getTableData();
        } else {
          // 处理API调用失败的场景
          console.error('API调用失败:', res);
        }
      }).catch(err => {
        console.error(err);
      });
    },

    /**
     * 查询结果
     */
    onQueryResult() {
      apiQueryResult({
        paymentBatchNumber: this.multipleSelection[0].paymentBatchNumber
      }).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: this.$t('GlobalSetObj.bemSucedido')
          });
          // 刷新列表数据
          this.getTableData();
        } else {
          // 处理API调用失败的场景
          console.error('API调用失败:', res);
        }
      }).catch(err => {
        console.error(err);
      });
    },

    /**
     * 导出
     */
    onExport() {
      // 判断当前是否选中
      if (this.multipleSelection?.length > 0) {
        const params = this.QureyParams();
        apiBradescoExport({
          params: { ...params, paymentBatchNumber: this.multipleSelection[0]?.paymentBatchNumber },
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        }).then(res => {
          if (res.code === 200) {
            goResourceTaskList();
          }
        }).catch(err => {
          console.error(err);
        });
        return;
      }
      apiBradescoExport({
        params: this.QureyParams(),
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code === 200) {
          goResourceTaskList();
        }
      }).catch(err => {
        console.error(err);
      });
    },

    // 查询参数处理
    QureyParams() {
      const obj = cloneDeep(this.params);
      if (obj.val2) {
        obj.startTime = obj.val2[0];
        obj.endTime = obj.val2[1];
      }
      obj.numberQueryType = this.orderType;
      delete obj.val2;
      return removeEmptyProperties(obj);
    },

    // 导入付款单errro日志
    ErrroCode(arr) {
      if (arr.length > 10) {
        const list = arr.map(item => {
          return {
            code: item
          };
        });
        const h = this.$createElement;
        this.$notify.error({
          title: this.$t('GlobalSetObj.tips'),
          dangerouslyUseHTMLString: true,
          message: h('el-table', { props: { data: list, border: true }, style: "width: 100%; height: '100%'; overflow-y: auto;" },
            [
              h('el-table-column', { props: { type: 'index', label: this.$t('GlobalSetObj.serial'), width: '50' }}),
              h('el-table-column', { props: { prop: 'code', label: this.$t('collectionCenter.abnormalData') }})
            ]
          ),
          duration: 0
        });
      } else {
        const list = arr?.join('、');
        this.$message({
          message: `${list} ！${this.$t('collectionCenter.abnormalData')}？`,
          type: 'warning',
          duration: 5 * 1000,
          showClose: true
        });
      }
    }
  }
};

/**
 * 从对象中移除所有空属性（null, undefined, ''）
 * @param obj 需要处理的对象
 * @returns 返回去除空属性后的新对象
 */
function removeEmptyProperties(obj) {
  const newObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}
</script>

<style scoped lang="scss">
.app-content {
  padding: 10px;
}

.btnList {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.batchNumber {
  color: #409EFF;
  cursor: pointer;
}

::v-deep .el-table__header-wrapper:not(.custom-class) .el-checkbox {
  display: none !important;
}

::v-deep .highlight-row {
  background-color: #F5F7FA !important;
}
</style>
<style>
.el-notification {
  overflow-y: scroll;
  height: calc(100% - 30px);
}

.el-notification__group {
  width: 100% !important;
}
</style>
