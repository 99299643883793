<template>
  <el-form
    :label-width="'auto'" class="demo-ruleForm"
    :label-position="$i18n.locale !== 'zh'?'top':'right'">
    <el-row>
      <el-col :span="8">
        <!-- 客户 -->
        <el-form-item :label="$t('GlobalSetObj.client') + '：'">
          <span>{{ ruleForm.customerName }}</span>
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <!-- 申请人 -->
        <el-form-item :label="$t('Resource.applicant') + '：'">
          <span>{{ ruleForm.applicant }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 申请人联系方式 -->
        <el-form-item :label="$t('ClaimsManagement.ApplicantsContactInformation') + '：'">
          <span>{{ ruleForm.applicantContactInformation }}</span>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8">
        <!-- 原 理赔节点-->
        <!-- 现 业务类型 -->
        <el-form-item :label="$t('collectionCenter.businessT') + '：'">
          <span>{{ ruleForm.claimsNodeStr }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 理赔金额 -->
        <el-form-item :label="$t('ClaimsManagement.AmountOfClaim') + '：'">
          <span>{{ ruleForm.claimAmount }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 实际理赔金额 -->
        <el-form-item :label="$t('ClaimsManagement.ActualClaimAmount') + '：'">
          <span>{{ ruleForm.actualClaimAmount }}</span>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8">
        <!-- 理赔原因 -->
        <el-form-item :label="$t('ClaimsManagement.CauseOfClaim') + '：'">
          <span>{{ ruleForm.claimReason }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 商户 -->
        <el-form-item :label="$t('collectionCenter.CommercialOwner') + '：'">
          <span>{{ ruleForm.merchantsName }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 附件 -->
        <el-form-item :label="$t('customerManagements.enclosure') + '：'">
          <ul>
            <li v-for="item of ruleForm.attachmentUrl" :key="item.url">
              <el-link type="primary" target="_blank" :href="item.url">{{ item.name }}<i class="el-icon-view el-icon--right" /> </el-link>
            </li>
          </ul>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { CLAIMSNODE_TYPE_OBJ } from '@/views/claimsManagement/claimList/utils.js';

export default {
  name: '',
  data() {
    return {
      ruleForm: {
        customerId: 0,
        customerName: '', // 客户名称
        applicant: '', // 申请人
        applicantContactInformation: '', // 申请人联系方式
        claimsNode: '', // 理赔节点
        claimsNodeStr: '', // 理赔节点
        claimAmount: 0, // 申请理赔金额
        actualClaimAmount: 0, // 实际理赔金额
        claimReason: '', // 理赔原因
        merchantsId: '', // 商户id
        merchantsName: '', // 商户名称
        attachmentUrl: []// 附件
      }
    };
  },

  created() {

  },

  methods: {
    // 设置基础信息
    setFormData(val) {
      const { customerId, customerName, applicant, applicantContactInformation, claimsNode, claimAmount, claimReason, actualClaimAmount, attachmentUrl, merchantsId, merchantsName } = val;

      let attachmentUrlArr = [];
      if (attachmentUrl) {
        attachmentUrlArr = attachmentUrl.split(',').map((item, index) => {
          return { name: `file${index + 1}`, url: item };
        });
      }

      let claimsNodeStr = '';
      if (claimsNode) {
        claimsNodeStr = CLAIMSNODE_TYPE_OBJ[claimsNode].name;
      }

      Object.assign(this.ruleForm, {
        customerId,
        customerName,
        applicant, // 申请人
        applicantContactInformation, // 申请人联系方式
        claimsNode, // 理赔节点
        claimsNodeStr, // 理赔节点
        claimAmount, // 申请理赔金额
        actualClaimAmount, // 实际理赔金额
        claimReason, // 理赔原因
        merchantsId, // 商户id
        merchantsName, // 商户名称
        attachmentUrl: attachmentUrlArr
      });
    }
  }

};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0 !important;
}
</style>
