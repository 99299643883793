var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { inline: false, "label-position": "top", size: "small" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          { staticClass: "selectLabel" },
                          [
                            _c(
                              "el-select",
                              {
                                style: _vm.IsWidth,
                                model: {
                                  value: _vm.queryForm.params.selectType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "selectType",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.selectType",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t("newOrder.waybillNumber"),
                                    value: 1,
                                  },
                                }),
                                _c("el-option", {
                                  key: "3",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.ScanNumber"),
                                    value: 3,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("newOrder.PreciseSearch1"),
                          rows: 1,
                          type: "textarea",
                        },
                        model: {
                          value: _vm.queryForm.params.inputValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm.params, "inputValue", $$v)
                          },
                          expression: "queryForm.params.inputValue",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("customerManagements.daysDelayed"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.daysDelayed,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "daysDelayed", $$v)
                            },
                            expression: "queryForm.params.daysDelayed",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.all"),
                              value: "",
                            },
                          }),
                          _vm._l(_vm.dayList, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("newOrder.waybillStatus") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.all"),
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.waybillStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "waybillStatus",
                                $$v
                              )
                            },
                            expression: "queryForm.params.waybillStatus",
                          },
                        },
                        _vm._l(_vm.waybillStatusList, function (item, ind) {
                          return _c("el-option", {
                            key: ind,
                            attrs: { label: item.label, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "selectType1" } },
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          { staticClass: "selectLabel" },
                          [
                            _c(
                              "el-select",
                              {
                                style: _vm.IsWidth2,
                                model: {
                                  value: _vm.queryForm.params.selectType1,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "selectType1",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.selectType1",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t(
                                      "customerManagements.latestTraceSiteList"
                                    ),
                                    value: 1,
                                  },
                                }),
                                _c("el-option", {
                                  key: "2",
                                  attrs: {
                                    label: _vm.$t(
                                      "customerManagements.operatorNameSite"
                                    ),
                                    value: 2,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                            multiple: "",
                          },
                          model: {
                            value: _vm.queryForm.params.latestTraceSiteList1,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "latestTraceSiteList1",
                                $$v
                              )
                            },
                            expression: "queryForm.params.latestTraceSiteList1",
                          },
                        },
                        _vm._l(_vm.siteList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "selectType2" } },
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          { staticClass: "selectLabel" },
                          [
                            _c(
                              "el-select",
                              {
                                style: _vm.IsWidth2,
                                model: {
                                  value: _vm.queryForm.params.selectType2,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "selectType2",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.selectType2",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "1",
                                  attrs: {
                                    label: _vm.$t(
                                      "customerManagements.newupTransferName"
                                    ),
                                    value: 1,
                                  },
                                }),
                                _c("el-option", {
                                  key: "2",
                                  attrs: {
                                    label: _vm.$t(
                                      "customerManagements.nextNewTransferName"
                                    ),
                                    value: 2,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                            multiple: "",
                          },
                          model: {
                            value: _vm.queryForm.params.upTransferList1,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "upTransferList1",
                                $$v
                              )
                            },
                            expression: "queryForm.params.upTransferList1",
                          },
                        },
                        _vm._l(_vm.TransferList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.LatestTrajectoryState"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                            multiple: "",
                          },
                          model: {
                            value: _vm.queryForm.params.latestTraceStateList,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "latestTraceStateList",
                                $$v
                              )
                            },
                            expression: "queryForm.params.latestTraceStateList",
                          },
                        },
                        _vm._l(_vm.allTraceStateList, function (item, ind) {
                          return _c("el-option", {
                            key: ind,
                            attrs: { label: item.nameEn, value: item.twoCode },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        size: "small",
                        type: "primary",
                      },
                      on: { click: _vm.enterSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh-left", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.resetForm()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.reset")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: {
                        icon: "el-icon-upload2",
                        size: "small",
                        type: "primary",
                      },
                      on: { click: _vm.allExportOrder },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.export")) + " ")]
                  ),
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { size: "small" },
                      on: { change: _vm.enterSearch },
                      model: {
                        value: _vm.queryForm.params.isTest,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm.params, "isTest", $$v)
                        },
                        expression: "queryForm.params.isTest",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v(_vm._s(_vm.$t("newOrder.Formal"))),
                      ]),
                      _c("el-radio-button", { attrs: { label: 2 } }, [
                        _vm._v(_vm._s(_vm.$t("newOrder.test1"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "header-cell-style": { background: "#f2f2f2", color: "black" },
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$t("GlobalSetObj.serial", "zh") +
                "（" +
                _vm.$t("GlobalSetObj.serial", "pu") +
                "）",
              align: "center",
              type: "index",
              width: "80",
            },
          }),
          _vm._l(_vm.tableHeaderList, function (item) {
            return _c("el-table-column", {
              key: item.id,
              attrs: {
                label: item.label,
                "min-width": item.cloWidth,
                prop: item.prop,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        item.prop === "waybillNumber"
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#409eff",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toOrderDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.waybillNumber) + " ")]
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row[item.prop]
                                    ? "" + scope.row[item.prop]
                                    : "--"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.pageNumber,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            visible: _vm.waybillDescription,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "90%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.waybillDescription = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "calc(100vh - 67px)", overflow: "auto" } },
            [
              _c("detailPage", {
                key: _vm.detailKey,
                attrs: {
                  "query-track-param": _vm.queryTrackParam,
                  "query-type": "1",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }