<template>
  <div class="Box">
    <el-form style="margin-bottom: 20px;" class="selectClass">
      <el-row>
        <el-col :span="10" style="width: auto;">
          <el-form-item :label="$t('newOrder.BuildTime') + '：'">
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              :end-placeholder="$t('operationCenter.endDate')"
              :start-placeholder="$t('operationCenter.startDate')"
              range-separator="~"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="dateChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7" style="width: auto;">
          <!-- 所属网点： -->
          <el-form-item :label="$t('collectionCenter.belongDot')">
            <el-select
              v-model="queryForm.params.printUserSite" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
              filterable @focus="getNewestSite"
            >
              <el-option
                v-for="(item, index) in siteIdList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7" style="width: auto;">
          <el-form-item :label="$t('collectionCenter.bagsNumber')"><!-- 袋号 -->
            <el-input
              v-model="queryForm.params.numbers"
              :placeholder="$t('collectionCenter.pleaseInput')"
              clearable
              type="textarea"
              :autosize="{ maxRows: 5}"
              @blur="ft"
              @keydown.enter.native="BlockNewline"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row>
      <el-col :span="24">
        <el-button
          v-permit:remove="'btn:collection-center:bagNumQuery:find'"
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="searchClick"
        >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
        <el-button
          v-permit:remove="'btn:collection-center:bagNumQuery:reset'"
          icon="el-icon-refresh"
          size="mini"
          @click="resetClick"
        >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
        <el-button
          v-permit:remove="'btn:collection-center:bagNumQuery:print'"
          icon="el-icon-printer"
          size="mini"
          :class="chooseList.length <= 0 && priIsClick ? 'onlyClickOnce10' : ''"
          @click="print"
        >{{ $t('collectionCenter.Print') }}</el-button><!-- 打印 -->
        <el-button
          v-permit:remove="'btn:collection-center:bagNumQuery:export'"
          icon="el-icon-upload2"
          size="mini"
          :class="expIsClick ? 'onlyClickOnce10' : ''"
          @click="download"
        >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 -->
      </el-col>
    </el-row>

    <el-divider />

    <el-table
      ref="tableRef"
      :max-height="600"
      border
      :data="tableData"
      @select="batchSelect"
      @select-all="batchSelectAll"
    >
      <el-table-column
        type="selection"
        width="55"
      />
      <!-- 序号 -->
      <el-table-column
        :label="$t('collectionCenter.ind')" type="index" width="60"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="number"
        :label="$t('collectionCenter.BagNumber')"
      /><!-- 袋号 -->
      <el-table-column
        prop="createTimeStr"
        :label="$t('newOrder.BuildTime')"
      /><!-- 生成时间 -->
      <el-table-column
        prop="printFrequency"
        :label="$t('collectionCenter.printNumber')"
      /><!-- 打印次数 -->
      <el-table-column
        prop="printUserName"
        :label="$t('collectionCenter.printer')"
      /><!-- 打印人 -->
      <el-table-column
        prop="printSiteName"
        :label="$t('collectionCenter.printDot')"
      /><!-- 打印网点 -->
    </el-table>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getPage"
        @size-change="getPageSize"
      />
    </div>
  </div>
</template>

<script>
import {
  queryAllSite,
  collectNumberPage,
  collectNumberExport,
  collectNumberPrint
} from '@/api/lanshou';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      priIsClick: false,
      expIsClick: false,
      notEnter: true, // 是否允许换行
      timeValue: '',
      siteIdList: [], // （启用及未启用）网点list
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: '',
          endTime: '',
          printUserSite: '',
          numbers: ''
        }
      },
      total: 0,
      tableData: [],
      chooseList: []
    };
  },
  watch: {
    'queryForm.params.numbers'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 1000) {
        number = bghTrim.slice(0, 1000).join('\n');
        this.queryForm.params.numbers = number;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bghTrim.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    }
  },
  created() {
    this.default();
    this.searchClick();
    this.expIsClick = false;
    this.priIsClick = false;
  },
  methods: {
    ft() {
      const val = this.queryForm.params.numbers.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.numbers = bghTrim.join('\n');
    },
    BlockNewline() { // 文本域最大输入行数为1000
      const e = window.event || arguments[0];
      this.ft();
      const numberLen = this.queryForm.params.numbers.split('\n');
      if (!this.notEnter) {
        if (numberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    getNewestSite() {
      // 获取（启用及未启用）网点
      queryAllSite().then(res => {
        if (res.code===200) {
          this.siteIdList = [];
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.beginTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.beginTime = val[0];
      this.queryForm.params.endTime = val[1];
    },
    default() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params = {
        printUserSite: '',
        numbers: '',
        beginTime: today + ' 00:00:00',
        endTime: today + ' 23:59:59'
      };
    },
    resetClick() {
      this.default();
    },
    getPage() {
      const queryForm = JSON.parse(localStorage.getItem('bnqQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      queryForm.params.numbers = queryForm.params.numbers ? queryForm.params.numbers.split('\n') : [];
      collectNumberPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      }).catch({});
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const queryForm = JSON.parse(localStorage.getItem('bnqQueryForm')) || this.queryForm;
      queryForm.pageNumber = 1;
      queryForm.pageSize = this.queryForm.pageSize;
      queryForm.params.numbers = queryForm.params.numbers ? queryForm.params.numbers.split('\n') : [];
      collectNumberPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      }).catch({});
    },
    searchClick() {
      if (!this.queryForm.params.beginTime || !this.queryForm.params.endTime) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      this.queryForm.pageNumber = 1;
      localStorage.setItem('bnqQueryForm', JSON.stringify(this.queryForm));
      this.getPage();
    },
    /**
     * 勾选项
     */
    batchSelect(selection, row) {
      this.chooseList = selection;
    },
    batchSelectAll(selection) {
      this.chooseList = selection;
    },
    print() {
      this.priIsClick = true;
      if (this.chooseList.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      const number = this.chooseList.map(arr => {
        return arr.number;
      });
      const printForm = {
        number
      };
      collectNumberPrint(printForm).then(res => {
        if (res.code===200) {
          this.priIsClick = false;
          window.open(res.data);
          this.chooseList = [];
          this.$refs.tableRef.clearSelection();
          this.searchClick();
        }
      });
    },
    download() {
      this.expIsClick = true;
      const queryForm = JSON.parse(localStorage.getItem('bnqQueryForm')) || this.queryForm;
      queryForm.params.numbers = queryForm.params.numbers ? queryForm.params.numbers.split('\n') : [];
      collectNumberExport(queryForm.params).then(res => {
        if (res.code===200) {
          this.expIsClick = false;
          const h = this.$createElement;
          this.$msgbox({
            // '提示'
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
              h('span', this.$t('collectionCenter.exportChangeTip')),
              // '请勿重复导出！'
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // '立即前往'
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // '关闭'
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          }).catch(() => {
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .selectClass ::v-deep .el-select{
    width: 100%;
  }
  .selectClass ::v-deep textarea.el-textarea__inner {
    min-height: 40px !important;
    max-height: 150px !important;
  }

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }
</style>
