<template>
  <div class="container">
    <div class="headeContainer">
      <div class="searchWrap">
        <el-form
          ref="queryForm"
          :label-position="$i18n.locale!='zh'?'top':'right'"
          :model="queryForm"
          inline
          label-width="150px"
          style="width: 100%"
        >
          <el-form-item :label="$t('ChannelManage.ServiceProviderName')">
            <el-input
              v-model.trim="queryForm.params.serviceSystemName"
              :placeholder="$t('ChannelManage.PleaseProviderName')"
              clearable
              style="width: 380px"
              @keyup.enter.native="getSearch"
            />
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.providerCode')">
            <el-input
              v-model.trim="queryForm.params.serviceSystemCode"
              :placeholder="$t('ChannelManage.PleaseProvidercode')"
              clearable
              style="width: 380px"
              @keyup.enter.native="getSearch"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="btnList">
        <!--搜索-->
        <el-button
          icon="el-icon-search" size="small" type="primary"
          @click="getSearch"
        >{{
          $t('newOrder.search')
        }}
        </el-button>
        <!--重置-->
        <el-button
          icon="el-icon-refresh-left" plain size="small"
          @click="resetForm"
        >{{
          $t('newOrder.reset')
        }}
        </el-button>
      </div>
      <el-divider />
    </div>
    <!-- 新增 -->
    <el-button
      icon="el-icon-plus" size="small" style="margin-bottom: 15px"
      type="primary" @click="addDrawer"
    >
      {{ $t('GlobalSetObj.add') }}
    </el-button>
    <el-card>
      <!--  表格数据  -->
      <AnjunTableList
        ref="anjunTable"
        :format-time="isTormat"
        :has-operation="true"
        :header-list="tableHeaderList"
        :is-index="true"
        :is-tip="true"
        :op-width="210"
        :table-data="tableData"
        @on-look="toLook"
        @on-edit="onEdit"
        @on-del="onDel"
      />
      <!--  分页  -->
      <el-pagination
        :current-page="queryForm.number"
        :page-size="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="queryForm.total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0;float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!--添加系统服务商-->
    <el-drawer
      ref="drawer"
      :append-to-body="true"
      :title="$t('GlobalSetObj.add')"
      :visible.sync="drawer"
      custom-class="demo-drawer"
      direction="ltr"
      size="50%"
    >
      <div class="demo-drawer__content" style="padding-right: 30px;padding-left: 30px">
        <el-form
          ref="addForm"
          :label-position="$i18n.locale!='zh'?'top':'right'"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          label-width="150px"
        >
          <el-form-item :label="$t('ChannelManage.ServiceErrorMessage')" prop="serviceErrorCode">
            <el-input v-model="ruleForm.serviceErrorCode" />
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.SystemServiceProvider')" prop="serviceSystemName">
            <el-select
              v-model="ruleForm.serviceSystemName"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 250px"
            >
              <el-option
                v-for="item in systemSeviceList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.ChineseTranslation')" prop="chineseTranslateInfo">
            <el-input v-model="ruleForm.chineseTranslateInfo" />
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.EnglishTranslation')" prop="englishTranslateInfo">
            <el-input v-model="ruleForm.englishTranslateInfo" />
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.PortugueseTranslated')" prop="portugueseTranslateInfo">
            <el-input v-model="ruleForm.portugueseTranslateInfo" />
          </el-form-item>
          <el-form-item :label="$t('GlobalSetObj.remark')">
            <el-input
              v-model="ruleForm.remark"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              :rows="5"
              type="textarea"
            />
          </el-form-item>
        </el-form>
      </div>

      <div class="demo-drawer__footer">
        <el-button style="margin-right: 20px" @click="cancelForm">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="saveSystem">{{ $t('GlobalSetObj.Salve') }}</el-button>
      </div>
    </el-drawer>
    <!--  查看详情  -->
    <el-drawer
      ref="drawer"
      :append-to-body="true"
      :title="$t('GlobalSetObj.View')"
      :visible.sync="drawerInfo"
      custom-class="demo-drawer"
      direction="ltr"
      size="50%"
    >
      <div class="demo-drawer__content" style="padding-right: 30px;padding-left: 30px">
        <el-form
          ref="addForm"
          :label-position="$i18n.locale!='zh'?'top':'right'"
          :model="editRuleForm"
          :rules="rules"
          class="demo-ruleForm"
          label-width="150px"
        >
          <el-form-item :label="$t('ChannelManage.ServiceErrorMessage')" prop="serviceErrorCode">
            <el-input v-model="editRuleForm.serviceErrorCode" disabled />
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.SystemServiceProvider')" prop="serviceSystemName">
            <el-select
              v-model="editRuleForm.serviceSystemName"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              disabled
              filterable
              style="width: 250px"
            >
              <el-option
                v-for="item in systemSeviceList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.ChineseTranslation')" prop="chineseTranslateInfo">
            <el-input v-model="editRuleForm.chineseTranslateInfo" disabled />
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.EnglishTranslation')" prop="englishTranslateInfo">
            <el-input v-model="editRuleForm.englishTranslateInfo" disabled />
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.PortugueseTranslated')" prop="portugueseTranslateInfo">
            <el-input v-model="editRuleForm.portugueseTranslateInfo" disabled />
          </el-form-item>
          <el-form-item :label="$t('GlobalSetObj.remark')">
            <el-input
              v-model="editRuleForm.remark"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              :rows="5"
              type="textarea"
            />
          </el-form-item>
        </el-form>
      </div>

      <div class="demo-drawer__footer">
        <el-button style="margin-right: 20px" type="primary" @click="cancelForm">{{
          $t('GlobalSetObj.close')
        }}
        </el-button>
      </div>
    </el-drawer>
    <!--  编辑  -->
    <el-drawer
      ref="drawer"
      :append-to-body="true"
      :title="$t('GlobalSetObj.edit')"
      :visible.sync="drawerEdit"
      custom-class="demo-drawer"
      direction="ltr"
      size="50%"
    >
      <div class="demo-drawer__content" style="padding-right: 30px;padding-left: 30px">
        <el-form
          ref="editRuleForm"
          :label-position="$i18n.locale!='zh'?'top':'right'"
          :model="editRuleForm"
          :rules="rules"
          class="demo-ruleForm"
          label-width="150px"
        >
          <el-form-item :label="$t('ChannelManage.ServiceErrorMessage')" prop="serviceErrorCode">
            <el-input v-model="editRuleForm.serviceErrorCode" />
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.SystemServiceProvider')" prop="serviceSystemName">
            <el-select
              v-model="editRuleForm.serviceSystemName"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 250px"
            >
              <el-option
                v-for="item in systemSeviceList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.ChineseTranslation')" prop="chineseTranslateInfo">
            <el-input v-model="editRuleForm.chineseTranslateInfo" />
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.EnglishTranslation')" prop="englishTranslateInfo">
            <el-input v-model="editRuleForm.englishTranslateInfo" />
          </el-form-item>
          <el-form-item :label="$t('ChannelManage.PortugueseTranslated')" prop="portugueseTranslateInfo">
            <el-input v-model="editRuleForm.portugueseTranslateInfo" />
          </el-form-item>
          <el-form-item :label="$t('GlobalSetObj.remark')">
            <el-input
              v-model="editRuleForm.remark"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              :rows="5"
              type="textarea"
            />
          </el-form-item>
        </el-form>
      </div>

      <div class="demo-drawer__footer">
        <el-button style="margin-right: 20px" @click="cancelForm">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="updateData">{{ $t('GlobalSetObj.Salve') }}</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import AnjunTableList from '@/components/anjun-tableList';
// import ScrollSelect from '@/views/channelManage/ServiceErrorMessage/components/ScrollSelect';
import {
  addproviderSystemError,
  deleteProviderSystemError,
  getSystemServiceList,
  providerSystemErrorPage,
  updateProviderSystemError
} from '@/api/channelManage';

export default {
  name: 'ServiceErrorMessage',
  components: {
    AnjunTableList
    // ScrollSelect
  },
  data() {
    return {
      variableList: [],
      // 是否格式化成巴西时间
      isTormat: false,
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0,
        params: {
          serviceSystemName: '',
          serviceSystemCode: ''
        }
      },
      tableHeaderList: [
        { id: 1, label: this.$t('ChannelManage.ServiceErrorMessage'), prop: 'serviceErrorCode', cloWidth: 180 },
        { id: 2, label: this.$t('GlobalSetObj.remark'), prop: 'remark', cloWidth: 150 },
        { id: 3, label: this.$t('ChannelManage.providerCode'), prop: 'serviceSystemCode', cloWidth: 300 },
        { id: 7, label: this.$t('ChannelManage.ServiceProviderName'), prop: 'serviceSystemName', cloWidth: 300 },
        { id: 4, label: this.$t('ChannelManage.ChineseTranslation'), prop: 'chineseTranslateInfo', cloWidth: 300 },
        { id: 5, label: this.$t('GlobalSetObj.EditTime'), prop: 'updateTime', cloWidth: 180 }
      ],
      systemSeviceList: [],
      tableData: [],
      // 添加弹窗
      drawer: false,
      ruleForm: {
        serviceErrorCode: '',
        serviceSystemName: '',
        chineseTranslateInfo: '',
        englishTranslateInfo: '',
        portugueseTranslateInfo: '',
        remark: ''
      },
      editRuleForm: {
        serviceErrorCode: '',
        serviceSystemName: '',
        chineseTranslateInfo: '',
        englishTranslateInfo: '',
        portugueseTranslateInfo: '',
        remark: ''
      },
      rules: {
        serviceErrorCode: [
          { required: true, message: this.$t('ChannelManage.to200Characters'), trigger: 'blur' }
        ],
        serviceSystemName: [
          { required: true, message: this.$t('ChannelManage.SelectProvider'), trigger: 'blur' }
        ],
        chineseTranslateInfo: [
          { required: true, message: this.$t('ChannelManage.inputChineseTranslation'), trigger: 'blur' }
        ],
        englishTranslateInfo: [
          { required: true, message: this.$t('ChannelManage.inputEnglishTranslation'), trigger: 'blur' }
        ],
        portugueseTranslateInfo: [
          { required: true, message: this.$t('ChannelManage.inputPortugueseTranslated'), trigger: 'blur' }
        ]
        // remark: [
        //   { required: true, message: '请输入内容', trigger: 'blur' }
        // ]
      },
      drawerInfo: false,
      drawerEdit: false
    };
  },
  watch: {
    drawer: {
      handler: function(val) {
        this.$refs['addForm']?.resetFields();
      },
      immediate: true
    },
    drawerEdit: {
      handler: function(val) {
        this.$refs['editRuleForm']?.resetFields();
      },
      immediate: true
    }
  },
  mounted() {
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getSearch();
  },
  methods: {
    // 关联系统服务商
    remoteMethod() {
      getSystemServiceList().then(res => {
        if (res.code===200) {
          this.systemSeviceList = res.data;
        }
      });
    },
    // 重置
    resetForm() {
      this.queryForm.params.serviceSystemName = '';
      this.queryForm.params.serviceSystemCode = '';
      this.queryForm.number = 1;
      this.getSearch();
    },
    // 搜索
    getSearch() {
      const data = {};
      data.pageSize = this.queryForm.pageSize;
      if (this.queryForm.params.serviceSystemName || this.queryForm.params.serviceSystemCode) {
        this.queryForm.number = 1;
      }
      data.pageNumber = this.queryForm.number;
      data.params = this.queryForm.params;
      console.log(data);
      providerSystemErrorPage(data).then(res => {
        if (res.code===200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.map(item => {
            if (!item.updateTime) {
              item.updateTime = item.createTime;
            }
            // item.remark = item.remark ? `${item.remark}` : '- -';
            item['look'] = true;
            item['del'] = true;
            item['edit'] = true;
          });
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.number = val;
      this.getSearch();
    },
    // 查看
    toLook(row) {
      this.drawerInfo = true;
      this.$nextTick(_ => {
        this.editRuleForm = { ...row };
      });
      // getSystemErrorInfo(row.id).then((res) => {
      //   if (res.code===200) {
      // this.editRuleForm = { ...res.data };
      //   }
      // });
    },
    // 点击新增, 弹出窗口
    addDrawer() {
      this.drawer = true;
      this.remoteMethod();
    },
    cancelForm() {
      this.drawer = false;
      this.drawerInfo = false;
      this.drawerEdit = false;
    },
    saveSystem() {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          const obj = this.systemSeviceList.find((item) => {
            return item.id == this.ruleForm.serviceSystemName;
          });
          const data = {};
          data.serviceErrorCode = this.ruleForm.serviceErrorCode;
          data.chineseTranslateInfo = this.ruleForm.chineseTranslateInfo;
          data.englishTranslateInfo = this.ruleForm.englishTranslateInfo;
          data.portugueseTranslateInfo = this.ruleForm.portugueseTranslateInfo;
          data.remark = this.ruleForm.remark;
          data.serviceSystemName = obj.name;
          data.serviceSystemId = obj.id;
          data.serviceSystemCode = obj.code;
          addproviderSystemError(data).then(res => {
            if (res.code===200) {
              this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
              this.drawer = false;
              this.getSearch();
            } else {
              this.drawer = false;
            }
          });
        }
      });
    },
    // 编辑
    async onEdit(row) {
      this.drawerEdit = true;
      // const result = await getSystemErrorInfo(row.id);
      // if (result.code===200) {
      //   this.editRuleForm = { ...result.data };
      // }
      this.$nextTick(_ => {
        this.editRuleForm = { ...row };
      });
      this.remoteMethod();
    },
    // 修改更新数据
    updateData() {
      this.$refs['editRuleForm'].validate((valid) => {
        if (valid) {
          const data = {};
          data.serviceErrorCode = this.editRuleForm.serviceErrorCode;
          data.chineseTranslateInfo = this.editRuleForm.chineseTranslateInfo;
          data.englishTranslateInfo = this.editRuleForm.englishTranslateInfo;
          data.portugueseTranslateInfo = this.editRuleForm.portugueseTranslateInfo;
          data.remark = this.editRuleForm.remark;
          data.id = this.editRuleForm.id;
          const obj = this.systemSeviceList.find((item) => {
            return item.id == this.editRuleForm.serviceSystemName;
          });
          if (obj) {
            data.serviceSystemName = obj.name;
            data.serviceSystemId = obj.id;
            data.serviceSystemCode = obj.code;
          }
          updateProviderSystemError(data).then(res => {
            if (res.code===200) {
              this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
              this.drawerEdit = false;
              this.getSearch();
            } else {
              this.drawerEdit = false;
            }
          });
        }
      });
    },
    // 删除
    onDel(row) {
      deleteProviderSystemError(row.id).then((res) => {
        if (res.code===200) {
          this.$message.success(this.$t('GlobalSetObj.DeleteSuccessful'));
          this.getSearch();
        }
      });
    }
  }
};
</script>

<style scoped>
.container {
  padding: 15px;
}

.searchWrap {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.demo-drawer {
  position: relative;
}

.demo-drawer__footer {
  width: 100%;
  border-top: 1px solid #cccccc;
  position: absolute;
  display: flex;
  justify-content: right;
  padding: 10px;
  right: 0;
  bottom: 0;
}

.container /deep/ .el-drawer__header {
  margin-bottom: 15px;
}

.container /deep/ .el-tabs__content {
  height: 100%;
}

.container /deep/ .el-tabs__content {
  height: 80%;
}

.elTabs {
  height: calc(100vh - 100px);
  overflow: auto;
}

.elTabs1 {
  /*height: 90%;*/
  height: calc(100vh - 100px);
  overflow: auto;
}

.demo_footer {
  float: right;
  height: 50px;
  margin-bottom: 15px;
}

</style>
