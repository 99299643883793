import request from '@/utils/request';
import newRequest from '@/utils/new-request';
import qs from 'qs';

const baseFinanceURL = process.env.VUE_APP_FINANCE; // 财务系统接口地址

// --------------------------- 应收报价管理 start ---------------------------

// 报价分页查询
export const apiOfferPricePage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/offerPrice/page',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 报价管理启用禁用
export const apiOfferPriceSwitch = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/offerPrice/switch',
    data: qs.stringify(data),
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 报价操作日志
export const apiOfferPriceOperateLog = (data, isBool = true) => {
  return newRequest({
    method: 'GET',
    url: '/offerPriceOperateLog/listByOfferPriceId/' + data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 获取报价方案详情
export const apiOfferPriceDetail = (data, isBool = true) => {
  return newRequest({
    method: 'GET',
    url: '/offerPriceDetail/listByOfferPriceId/' + data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 新增报价
export const apiOfferPriceAdd = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/offerPriceDetail/add',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 编辑报价
export const apiOfferPriceDetailEdit = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/offerPriceDetail/edit',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// --------------------------- 应收报价管理 end ---------------------------

// 应收主体（服务商/供应商）分页
export const apiProviderPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/provider/page',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 获取报价方案其他规则
export const apiListByOfferPriceId = (data, isBool = true) => {
  return newRequest({
    method: 'GET',
    url: '/offerPriceOtherRules/listByOfferPriceId/' + data,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 通过业务主体id获取网点
export const apiSiteQuery = (data, isBool = true) => {
  return request({
    method: 'GET',
    url: '/site/query/' + data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 账单核收分页查询
export const apiSiteReceiptGetPage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/SiteReceipt/getPage',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 获取客户账户详情
export const apiGetCustomerFinanceInfo = (data, isBool = true) => {
  return request({
    method: 'GET',
    url: '/customer/getCustomerFinanceInfo/' + data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 获取客户账户流水
export const apiCustomerReceiptPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/customerReceipt/page',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 导出客户账户流水
export const apiCustomerExportAccountStatement = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/customer/exportAccountStatement',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 导出客户信息
export const apiCustomerExportAccountInfo = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/customer/exportAccountInfo',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

// ----------------- 财务基础资料-地区管理与税费 satrt -----------------

// 地区列表查询

export const apiAreaParentPage = (data, isBool = true) => {
  return newRequest({
    method: 'GET',
    url: '/area/getParentNodes',
    data,
    isLoading: isBool // 是否显示加载中参数
    // baseURL: baseFinanceURL
  });
};

export const apiAreaPage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/area/page',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 新增地区
export const apiAreaAdd = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/area/add',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 编辑地区
export const apiAreaEdit = (data, isBool = true) => {
  return newRequest({
    method: 'put',
    url: '/area/edit',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 删除地区
export const apiAreaDelete = (data, isBool = true) => {
  return newRequest({
    method: 'delete',
    url: '/area/delete/' + data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 导入添加地区
export const apiAreaImportAdd = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/area/importAdd',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 导出地区
export const apiAreaExport = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/area/export',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 获取icms税费匹配规则更改以及增加税费参照表
export const apiGetTaxationReferList = (data, isBool = true) => {
  return newRequest({
    method: 'post',
    url: '/taxationRefer/getTaxationReferList',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 编辑icms税费参照表
export const apiGetTaxationEdit = (data, isBool = true) => {
  return newRequest({
    method: 'put',
    url: '/taxationRefer/edit',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// ----------------- 财务基础资料-地区管理与税费 end -----------------

// 加盟商时效统计日期维度分页查询
export const apiProviderAgingPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/report/site/timeValidity/page',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 加盟商时效统计汇总查询
export const apiProviderAgingSummary = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/report/site/timeValidity/summary',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 加盟商时效统计日期维度导出
export const apiProviderAgingExportDateDimension = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/report/site/timeValidity/export',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 加盟商时效统计汇总导出
export const apiProviderAgingExportSummary = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/report/site/timeValidity/summary/export',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付账户导出账户信息
export const apiExportMeetSiteAccountInfo = (data, isBool = true) => {
  return request({
    method: 'post',
    url: '/siteAccount/exportMeetSiteAccountInfo',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 应付账户导出账户流水
export const apiExportOfBillFlow = (data, isBool = true) => {
  return request({
    method: 'post',
    url: '/fa/sitePayable/exportOfBillFlow',
    data,
    isLoading: isBool // 是否显示加载中参数
    // baseURL: baseFinanceURL
  });
};
// 应付账户详情
export const apiGetDetailPage = (data, isBool = true) => {
  return newRequest({
    method: 'post',
    url: '/sitePayable/getPage',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

// ---------------- 应付-报价管理 start --------------------

// 应付报价管理分页查询
export const apiPayableOfferPriceQueryList = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableOfferPrice/queryList',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 应付报价管理方案详情
export const apiListByPayableOfferPriceId = (data, isBool = true) => {
  return newRequest({
    method: 'GET',
    url: '/payableOfferPriceDetail/listByPayableOfferPriceId/' + data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 应付费用报价管理启用、禁用
export const apiPayableOfferPriceSwitch = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableOfferPrice/switch?id=' + data.id + '&state=' + data.sw,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 应付报价管理添加报价方案
export const apiPayableOfferPriceDetailInsert = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableOfferPriceDetail/insert',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 应付报价管理编辑报价方案
export const apiPayableOfferPriceDetailUpdate = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableOfferPriceDetail/update',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 应付报价管理报价操作日志
export const apiPayableOfferPriceOperateLog = (data, isBool = true) => {
  return newRequest({
    method: 'GET',
    url: '/payableOfferPriceOperateLog/listByPayableOfferPriceId/' + data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 应付报价管理导出
export const apiExportOfferPriceInfo = (data) => {
  return newRequest({
    method: 'POST',
    url: '/payableOfferPrice/exportOfferPriceInfo',
    data,
    baseURL: baseFinanceURL
  });
};

// ---------------- 应付-报价管理 end --------------------

// ---------------- 应付-距离参照表 start --------------------

// 获取派送参照分页列表
export const apiDispatchReferencePage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/dispatchReference/page',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 添加派送参照
export const apiDispatchReferenceInsert = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/dispatchReference/insert',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 导入派送参照
export const apiDispatchReferenceImportAdd = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/dispatchReference/importAdd',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 导出派送参照
export const apiDispatchReferenceExportAll = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/dispatchReference/exportAll',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 派送参照编辑
export const apiDispatchReferenceUpdate = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/dispatchReference/update',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 派送参照删除
export const apiDispatchReferenceDelete = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/dispatchReference/delete',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// ---------------- 应付-距离参照表 end --------------------

// 账单核付分页查询
export const apiSitePayableGetPage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/sitePayable/getPage',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 数据推送日志
export const apiExpressFeePushLog = (data) => {
  return newRequest({
    method: 'POST',
    data,
    url: '/expressFeePushLog/page',
    baseURL: baseFinanceURL
  });
};

// 网点时效统计-明细
export const siteWaybillDetail = (data) => {
  return request({
    method: 'POST',
    url: '/report/site/timeValidity/detail',
    data
  });
};
// 网点时效统计-明细导出
export const detailExport = (data) => {
  return request({
    method: 'POST',
    url: '/report/site/timeValidity/detailExport',
    data
  });
};

/**
 * @description 发票管理分页
 * @param data
 * @param isBool
 * @returns {*}
 */
export const logisticsInvoicePage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/brCte/page',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

/**
 * @description 发票管理导出
 * @param data
 * @param isBool
 * @returns {*}
 */
export const logisticsInvoiceExport = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/brCte/export',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

/**
 * @description 发票管理重开发票
 * @param data
 * @param isBool
 * @returns {*}
 */
export const logisticsInvoiceReGenerateCte = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/logisticsInvoice/reGenerateCte',
    data,
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    isLoading: isBool // 是否显示加载中参数
  });
};

/**
 * @description 发票管理取消发票
 * @param data
 * @param isBool
 * @returns {*}
 */
export const logisticsInvoiceCancel = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/logisticsInvoice/cancel',
    data,
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    isLoading: isBool // 是否显示加载中参数
  });
};

/**
 * @description 获取发票链接
 * @param data
 * @param isBool
 * @returns {*}
 */
export const faBrCteLabel = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/fa/brCte/label',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 批量添加费用 - 查询订单信息
export const apiGetClAndLgInfo = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableSiteExpense/getClAndLgInfo',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 运输干支线添加列表
export const apiSiteExpenseGetClAndLgInfo = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payableTransportSiteExpense/getClAndLgInfo',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 价格查询日志
export const apiCarlosRequestLogPage = (data) => {
  return newRequest({
    method: 'POST',
    url: '/carlosRequestLog/page',
    data
  });
};
// 遗失率监控  ==> 分页
export const lostMonitor = (data) => {
  return request.post('/report/site/lostMonitor', data);
};
// 遗失率监控  ==> 分页
export const getLostdictCodeType = () => {
  return request.get('/dictLang/dictCode/lostType');
};
export const lostMonitorDetail = (data) => {
  return request({
    method: 'POST',
    url: '/report/site/lostMonitor/detail',
    data
  });
};
export const lostMonitorDetailExport = (data) => {
  return request({
    method: 'POST',
    url: '/report/site/lostMonitor/detailExport',
    data
  });
};
export const lostMonitorExport = (data) => {
  return request({
    method: 'POST',
    url: '/report/site/lostMonitorExport',
    data
  });
};

// 新分拣规则操作日志
export const sortedRuleQueryLog = (data) => {
  return request.post('/wms/sortedRule/queryLog', data);
};
// 模糊查询操作对象
export const sortedRuleQueryLogObj = (data) => {
  return request.post('/wms/sortedRule/queryLogObj', data);
};
// 网点时效统计-明细
export const loadUnloadDetailed = (data) => {
  return request({
    method: 'POST',
    url: '/loadUnloadDetailed/pageQuery',
    data
  });
};
// 网点时效统计-明细
export const loadUnloadDetailedExportAll = (data) => {
  return request({
    method: 'POST',
    url: '/loadUnloadDetailed/exportAll',
    data
  });
};

// 导出账单文件
export const apiSitePayableGetFiles = (data) => {
  return request.post('/fa/sitePayable/getFiles', data);
};

// -------------------- 应付-揽收账户 start --------------------

// 揽收应付账户:
export const apiCollectionPayableAccountPage = (data) => {
  return request.post('/fa/collectionPayableAccount/page', data);
  // return newRequest({
  //   method: 'POST',
  //   url: '/collectionPayableAccount/page',
  //   data,
  //   baseURL: baseFinanceURL
  // })
};

// 派送应付账户:
export const apiDeliveryPayableAccountPage = (data) => {
  // return request.post('/fa/deliveryPayableAccount/page', data);
  return newRequest({
    method: 'POST',
    url: '/deliveryPayableAccount/page',
    data,
    baseURL: baseFinanceURL
  });
};

// 揽收应付账户信息导出
export const apiCollectionPayableAccountExport = (data) => {
  return request.post('/fa/collectionPayableAccount/export', data);
  // return newRequest({
  //   method: 'POST',
  //   url: '/collectionPayableAccount/export',
  //   data,
  //   baseURL: baseFinanceURL
  // })
};

// AJD派送应付账户信息导出
export const apiDeliveryPayableAccountExport = (data) => {
  return request.post('/fa/deliveryPayableAccount/export', data);
  // return newRequest({
  //   method: 'POST',
  //   url: '/deliveryPayableAccount/export',
  //   data,
  //   baseURL: baseFinanceURL
  // })
};
// DSP派送应付账户信息导出
export const apiDspDeliveryPayableAccountExport = (data) => {
  return request.post('/fa/deliveryPayableAccount/exportDsp', data);
  // return requestFinance({
  //   method: 'POST',
  //   url: '/deliveryPayableAccount/export',
  //   data,
  //   baseURL: baseFinanceURL
  // })
};
// 发票管理分页
export const apiLogisticsInvoicePage = (data) => {
  // return request.post('/fa/logisticsInvoice/page', data);
  return newRequest({
    method: 'post',
    url: '/logisticsInvoice/page',
    data
  });
};

// 手动生成cte(手动开票)
export const apiLogisticsInvoiceGenerateCte = (data) => {
  return request.post('/fa/logisticsInvoice/generateCte', data);
};

// 取消开票
export const apiLogisticsInvoicecCancel = (data) => {
  return request.post('/fa/logisticsInvoice/cancel', data);
};

// 重开cte
export const apiLogisticsInvoiceReGenerateCte = (data) => {
  return request.post('/fa/logisticsInvoice/reGenerateCte', data);
};

// 发票下载
export const apiLogisticsInvoicecLabel = (data) => {
  return request.post('/fa/logisticsInvoice/label', data);
};

// 发票导出
export const apiLogisticsInvoicecExport = (data) => {
  return request.post('/fa/logisticsInvoice/export', data);
};

// 账单审核导出
export const apiSiteBillExport = (data) => {
  return newRequest({
    method: 'post',
    url: '/siteBill/export',
    data
  });
};

// 账单审核导出明细
export const apiSiteBillExportDetailed = (data) => {
  return newRequest({
    method: 'post',
    url: '/siteBill/exportDetailed',
    data
  });
};

// 账单审核导出明细
export const apiSiteReceiptExport = (data) => {
  return newRequest({
    method: 'post',
    url: '/SiteReceipt/export',
    data
  });
};

// 账单审核导出明细
export const apiSiteReceiptExportDetailed = (data) => {
  return request.post('/fa/SiteReceipt/exportDetailed', data);
};
// 袋数明细-明细
export const loadUnloadDetailedbagNumber = (data) => {
  return request({
    method: 'POST',
    url: '/loadUnloadDetailed/bagNumber/pageQuery',
    data
  });
};
export const loadUnloadDetailedbagexportAll = (data) => {
  return request({
    method: 'POST',
    url: '/loadUnloadDetailed/bagNumber/exportAll',
    data
  });
};
