<template>
  <div class="app-container">
    <div style="margin-bottom: 20px">
      <el-form
        ref="formSearch"
        :model="queryForm"
        label-position="top"
        size="small"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item :label="$t('newOrder.StorageTimeOutlets')">
              <!--网点入库时间-->
              <el-date-picker
                v-model="queryDateTime"
                :end-placeholder="$t('operationCenter.endDate')"
                :range-separator="$t('operationCenter.to')"
                :start-placeholder="$t('operationCenter.startDate')"
                style="width: 100%"
                type="daterange"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
          <!-- 州 -->
          <el-col :span="4">
            <el-form-item :label="$t('GlobalSetObj.Estado')">
              <el-select
                v-model="queryForm.params.state"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%"
                @change="getOperationState"
              >
                <el-option
                  v-for="item in StateList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--网点名称-->
            <el-form-item :label="$t('basicData.siteName')">
              <el-select
                v-model="queryForm.params.siteIdList"
                :placeholder="$t('GlobalSetObj.all')"
                clearable
                collapse-tags
                filterable
                multiple
                style="width: 100%;"
              >
                <el-option
                  v-for="item in siteList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button
        icon="el-icon-search"
        size="small"
        type="primary"
        @click="searchResources(1)"
      >{{ $t('operationCenter.Procurar') }}
      </el-button>
      <el-button icon="el-icon-refresh-left" size="small" @click="searchResources(2)">
        {{ $t('operationCenter.reset') }}
      </el-button>
      <el-radio-group
        v-model="queryForm.params.customerType"
        size="small"
        style="margin-left: 10px"
        @change="searchResources(1)"
      >
        <el-radio-button :label="1">{{ $t('newOrder.Formal') }}</el-radio-button>
        <el-radio-button :label="2">{{ $t('newOrder.test1') }}</el-radio-button>
      </el-radio-group>
      <el-button
        size="small"
        style="margin-left: 10px"
        type="primary"
        @click="exportDateDimension"
      >{{ $t('operationCenter.exports') }}
      </el-button>
    </div>
    <el-tabs v-model="activeNameTab" @tab-click="handleClickTab">
      <!--汇总-->
      <el-tab-pane :label="$t('collectionCenter.summary')" name="summary">
        <el-table :data="tableData" border>
          <el-table-column
            v-for="(item, ind) in columnList"
            :key="ind"
            :align="item.align"
            :min-width="item.width"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div>{{ item.label[0] }}</div>
              <div>{{ item.label[1] }}</div>
            </template>
            <template slot-scope="scope">
              <div
                :class="strARR.includes(item.prop) ? 'table-active' :''"
                @click="seeDetial(scope.row,item.prop,true)"
              >
                {{ scope.row[item.prop] || 0 }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page.sync="queryForm.pageNumber"
            :page-size.sync="queryForm.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            style="margin: 10px 0"
            @current-change="getCurrentTableData"
            @size-change="getSizeTableData"
          />
        </div>
      </el-tab-pane>
      <!--日期维度-->
      <el-tab-pane :label="$t('GlobalSetObj.DateDimension')" name="dateDimension">
        <el-table :data="tableData" border>
          <!--运单号-->
          <el-table-column
            v-for="(item, ind) in columnList"
            :key="ind"
            :align="item.align"
            :min-width="item.width"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div>{{ item.label[0] }}</div>
              <div>{{ item.label[1] }}</div>
            </template>
            <template slot-scope="scope">
              <div
                :class="strARR.includes(item.prop) ? 'table-active' :''"
                @click="seeDetial(scope.row,item.prop,false)"
              >
                {{ scope.row[item.prop] || 0 }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page.sync="queryForm.pageNumber"
            :page-size.sync="queryForm.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            style="margin: 10px 0"
            @current-change="getCurrentTableData"
            @size-change="getSizeTableData"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 包裹详情-->
    <el-drawer
      :title="$t('orderCenterCont.packageMsg')"
      :visible.sync="dialogVisible"
      center
      direction="ltr"
      size="1200px"
      @close="closeDrawer"
    >
      <packageDetail
        v-if="dialogVisible"
        ref="votesDetails"
        :dataobj="dataObj"
        :query-date-time="queryDateTime"
        @call-parent-method="parentMethod"
      />
    </el-drawer>
  </div>
</template>

<script>
import {
  apiProviderAgingExportDateDimension,
  apiProviderAgingExportSummary,
  apiProviderAgingPage,
  apiProviderAgingSummary
} from '@/api/finance/basicData';
import { getLgAreaListData, selectInfoByStateTwo } from '@/api/orderApi';
import dayjs from 'dayjs';
import { goRWExportList } from '@/utils/goExportList.js';

export default {
  name: 'FranchiseeTimelinessStatistics',
  components: {
    'packageDetail': () => import('./component/packageDetail')
  },
  data() {
    return {
      dialogVisible: false, // 详情弹窗
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          siteIdList: [],
          state: '',
          beginDate: '',
          endDate: '',
          customerType: 1 // 1：正式2：测试
        }
      },
      tableData: [],
      total: 0,
      activeNameTab: 'summary',
      StateList: [], // 州
      columnList: [
        // 网点入库时间
        {
          label: [this.$t('newOrder.StorageTimeOutlets', 'zh'), this.$t('newOrder.StorageTimeOutlets', 'pu')],
          prop: 'date',
          width: 180,
          align: 'center'
        },
        // 网点所属州
        {
          label: [this.$t('GlobalSetObj.Estado', 'zh'), this.$t('GlobalSetObj.Estado', 'pu')],
          prop: 'state', width: 140, align: 'left'
        },
        // 网点名称
        {
          label: [this.$t('basicData.siteName', 'zh'), this.$t('basicData.siteName', 'pu')],
          prop: 'deliverStationName', width: 180, align: 'left'
        },
        // 网点入库总票数
        {
          label: [this.$t('basicData.zongpianshu', 'zh'), this.$t('basicData.zongpianshu', 'pu')],
          prop: 'total', width: 180, align: 'center'
        },
        // // 退件数
        // {
        //   label: [this.$t('basicData.returned', 'zh'), this.$t('basicData.returned', 'pu')],
        //   prop: 'returned', width: 160, align: 'center'
        // },
        // // 理应送达票数
        // {
        //   label: [this.$t('basicData.shouldDelivered', 'zh'), this.$t('basicData.shouldDelivered', 'pu')],
        //   prop: 'shouldDelivered', width: 160, align: 'center'
        // },
        // D+0签收量
        {
          label: [this.$t('basicData.h24', 'zh') + this.$t('GlobalSetObj.AmountOfReceipt', 'zh'),
            this.$t('basicData.h24', 'pu') + this.$t('GlobalSetObj.AmountOfReceipt', 'pu')],
          prop: 'zeroDay',
          width: 180,
          align: 'center'
        },
        // D+0签收率
        {
          label: [this.$t('basicData.h24', 'zh') + this.$t('GlobalSetObj.SignOffRate', 'zh'),
            this.$t('basicData.h24', 'pu') + this.$t('GlobalSetObj.SignOffRate', 'pu')],
          prop: 'zeroDayPer',
          width: 180,
          align: 'center'
        },
        // D+1签收量
        {
          label: [this.$t('basicData.h48', 'zh') + this.$t('GlobalSetObj.AmountOfReceipt', 'zh'),
            this.$t('basicData.h48', 'pu') + this.$t('GlobalSetObj.AmountOfReceipt', 'pu')],
          prop: 'firstDay',
          width: 180,
          align: 'center'
        },
        // D+1签收率
        {
          label: [this.$t('basicData.h48', 'zh') + this.$t('GlobalSetObj.SignOffRate', 'zh'),
            this.$t('basicData.h48', 'pu') + this.$t('GlobalSetObj.SignOffRate', 'pu')],
          prop: 'firstDayPer',
          width: 180,
          align: 'center'
        },
        // D+2签收量
        {
          label: [this.$t('basicData.h72', 'zh') + this.$t('GlobalSetObj.AmountOfReceipt', 'zh'),
            this.$t('basicData.h72', 'pu') + this.$t('GlobalSetObj.AmountOfReceipt', 'pu')],
          prop: 'secondDay',
          width: 180,
          align: 'center'
        },
        // D+2签收率
        {
          label: [this.$t('basicData.h72', 'zh') + this.$t('GlobalSetObj.SignOffRate', 'zh'),
            this.$t('basicData.h72', 'pu') + this.$t('GlobalSetObj.SignOffRate', 'pu')],
          prop: 'secondDayPer',
          width: 180,
          align: 'center'
        },
        // 妥投量
        {
          label: [this.$t('basicData.rightAmount', 'zh'), this.$t('basicData.rightAmount', 'pu')],
          prop: 'completed', width: 160, align: 'center'
        },
        // 妥投率
        {
          label: [this.$t('GlobalSetObj.ReasonableInvestmentRate', 'zh'), this.$t('GlobalSetObj.ReasonableInvestmentRate', 'pu')],
          prop: 'completedPer', width: 180, align: 'center'
        },
        // 未妥投票数
        {
          label: [this.$t('GlobalSetObj.NumberOfUnvalidatedVotes', 'zh'), this.$t('GlobalSetObj.NumberOfUnvalidatedVotes', 'pu')],
          prop: 'unCompleted', width: 180, align: 'center'
        }
      ],
      queryDateTime: [this.getAWeek().startDate, this.getAWeek().endDate],
      siteList: [],
      dataObj: {},
      strARR: ['total', 'returned', 'shouldDelivered', 'completed', 'unCompleted', 'zeroDay', 'firstDay', 'secondDay'],
      selectDate: '',
      // 日期时间范围在一个月以内
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = '';
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 30 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      }
    };
  },
  created() {
    // 获取加盟商选项
    this.getAddress();
    this.getOperationState();
    this.getTableData(1);
  },
  methods: {
    // 州
    getAddress() {
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.code===200) {
          this.StateList = res.data.map(item => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    },
    getOperationState(val) {
      console.log(val, 'getOperationState');
      this.queryForm.params.siteIdList = [];
      const data = {};
      if (val) {
        data.state = val;
      }
      selectInfoByStateTwo(data).then((res) => {
        if (res.code===200) {
          this.siteList = res.data.siteList;
        }
      });
    },
    // 日期维度导出
    exportDateDimension() {
      if (this.queryDateTime && this.queryDateTime.length > 0) {
        this.queryForm.params.beginDate = this.queryDateTime[0];
        this.queryForm.params.endDate = this.queryDateTime[1];
      } else {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      if (this.activeNameTab === 'dateDimension') {
        apiProviderAgingExportDateDimension(this.queryForm.params).then(res => {
          goRWExportList(this);
        });
      } else {
        apiProviderAgingExportSummary(this.queryForm.params).then(res => {
          goRWExportList(this);
        });
      }
    },
    // 页面切换
    handleClickTab() {
      this.queryForm.pageNumber = 1;
      this.getTableData();
    },
    // 搜索、重置
    searchResources(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.getTableData();
      } else {
        this.queryForm = {
          pageSize: 10,
          pageNumber: 1,
          params: {
            siteIdList: [],
            state: '',
            beginDate: '',
            endDate: '',
            customerType: 1 // 1：正式2：测试
          }
        };
        this.queryDateTime = [this.getAWeek().startDate, this.getAWeek().endDate];
      }
    },
    changeInput(val) {
      this.$emit('input', val);
    },
    // 获取最近30天时间
    getAWeek() {
      return {
        endDate: dayjs().format('YYYY-MM-DD'),
        startDate: dayjs().subtract(3, 'day').format('YYYY-MM-DD')
      };
    },
    // 获取加盟商时效统计列表
    getTableData(val) {
      if (this.queryDateTime && this.queryDateTime.length > 0) {
        this.queryForm.params.beginDate = this.queryDateTime[0];
        this.queryForm.params.endDate = this.queryDateTime[1];
      } else {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      let loading = null;
      if (val === 1) {
        loading = this.$loading({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: 'loading...',
          background: 'rgba(250, 250, 250, 0.6)'
        });
      }
      if (this.activeNameTab === 'dateDimension') {
        apiProviderAgingPage(this.queryForm).then(res => {
          if (res.code===200) {
            this.total = res.data.total;
            this.tableData = res.data.records;
            this.tableData.forEach(item => {
              item.date = item.date || '--';
            });
            if (val === 1) {
              loading.close();
            }
          }
        }).catch(() => {
          if (val === 1) {
            loading.close();
          }
        });
      } else {
        apiProviderAgingSummary(this.queryForm).then(res => {
          if (res.code===200) {
            this.tableData = res.data.records;
            this.total = res.data.total;
            this.tableData.forEach(item => {
              item.date = item.date || '--';
              item.state = item.state || '--';
            });
            if (val === 1) {
              loading.close();
            }
          }
        }).catch(() => {
          if (val === 1) {
            loading.close();
          }
        });
      }
    },
    // 页码变化翻页
    getCurrentTableData(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableData();
    },
    closeDrawer() {
      this.dialogVisible = false;
      this.$refs.votesDetails.resetForm();
    },
    parentMethod(arg) {
      this.dialogVisible = arg;
    },
    seeDetial(row, porp, flage) {
      if (this.strARR.includes(porp)) {
        this.dataObj = {
          dataType: porp,
          customerType: this.queryForm.params.customerType,
          state: this.queryForm.params.state,
          siteIdList: this.queryForm.params.siteIdList
        };
        if (flage) {
          this.dataObj.stationId = row.deliverStationId;
          this.dataObj.searchDate = null;
        } else {
          this.dataObj.stationId = row.deliverStationId;
          this.dataObj.searchDate = row.date;
        }
        this.dialogVisible = true;
      }
      console.log(row, porp);
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-drawer__header {
  margin-bottom: 10px;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  color: black;
}

.table-active {
  color: #409EFF;
  cursor: pointer;
}
</style>
