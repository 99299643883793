// 路由管理 route.

export const RouterObj = {
  dashboard: {
    zh: '首页',
    en: 'dashboard',
    pu: 'Casa'
  },
  UserCenter: {
    zh: '用户中心',
    en: 'User center',
    pu: 'Centro de Usuários'
  },
  UserManagement: {
    zh: '用户管理',
    en: 'User management',
    pu: 'Gerenciamento de usuários'
  },
  RoleManagement: {
    zh: '角色管理',
    en: 'Role management',
    pu: 'Gestão de papéis'
  },
  PermissionManagement: {
    zh: '权限管理',
    en: 'Permission management',
    pu: 'Gerenciamento de permissões'
  },
  OrganizationalStructure: {
    zh: '组织架构',
    en: 'Organizational structure',
    pu: 'Estrutura organizacional'
  },
  JobManagement: {
    zh: '职务管理',
    en: 'Job management',
    pu: 'Gestão de empregos'
  },
  TicketCenter: {
    zh: '工单中心',
    en: 'Ticket center',
    pu: 'Centro de Ingressos'
  },
  MyMission: {
    zh: '我的任务',
    en: 'My mission',
    pu: 'Minha missão'
  },
  MyTicket: {
    zh: '我的工单',
    en: 'My ticket',
    pu: 'Meu bilhete'
  },
  DepartmentTickets: {
    zh: '部门工单',
    en: 'Department tickets',
    pu: 'Bilhetes do departamento'
  },
  DepartmentTasks: {
    zh: '部门任务',
    en: 'Department tasks',
    pu: 'Tarefas do departamento'
  },
  TicketLabel: {
    zh: '工单标签',
    en: 'Ticket label',
    pu: 'Etiqueta de ingressos'
  },
  DailyNewspaperManagement: {
    zh: '日报管理',
    en: 'Daily newspaper management',
    pu: 'Gestão diária de jornais'
  },
  MicroWorkOrder: {
    zh: '微工单',
    en: 'Micro work order',
    pu: 'tarefas internas'
  },
  OrderCenter: {
    zh: '订单中心',
    en: 'Order center',
    pu: 'Centro de Pedidos'
  },
  dictionary: {
    zh: '字典',
    en: '',
    pu: 'dicionário'
  },
  RemoteAreaPostcodeManagement: {
    zh: '偏远地区邮编管理',
    en: '',
    pu: 'Gerenciamento de CEP de Área Remota'
  },
  orderList: {
    zh: '订单列表',
    en: 'orderList',
    pu: 'Lista dos Pedidos'
  },
  OrderLogs: {
    zh: '下单日志',
    en: 'Order Log',
    pu: 'Registro de pedidos'
  },
  ReplaceOrder: {
    zh: '换单订单',
    en: 'Replacement order',
    pu: 'Ordem de troca'
  },
  // ServiceProviderChannel: {
  //   zh: '服务商渠道',
  //   en: 'Service provider channel',
  //   pu: 'Canal de prestadores de serviços'
  // },
  // SystemServiceProvider: {
  //   zh: '服务商渠道',
  //   en: 'Service provider channel',
  //   pu: 'Canal de prestadores de serviços'
  // },
  logisticsOrders: {
    zh: '物流订单',
    en: 'Logistics orders',
    pu: 'Pedidos de logística'
  },
  placeOrder: {
    zh: '快递下单',
    en: 'Place the order',
    pu: 'fazer pedido do express'
  },
  PendingReturnOrders: {
    zh: '待返仓订单',
    en: 'Pending return orders',
    pu: 'Pedido a aguardar devolver ao armazém'
  },
  ReturnWaybill: {
    zh: '退件运单',
    en: 'Return waybill',
    pu: 'Carta de porte de devolução'
  },
  OrderLog: {
    zh: '下单日志',
    en: 'Order Log',
    pu: 'Registro de pedidos'
  },
  LogisticsOrders: {
    zh: '物流订单',
    en: 'Logistics orders',
    pu: 'Ordens logísticas'
  },
  PlatformOrders: {
    zh: '平台订单',
    en: 'Platform orders',
    pu: 'Pedidos de plataforma'
  },
  ExchangeOrders: {
    zh: '换单订单',
    en: 'Exchange orders',
    pu: 'Ordens de troca'
  },
  OrderLargeScreen: {
    zh: '订单大屏展示',
    en: 'Order large screen',
    pu: 'Encomendar tela grande'
  },
  CourierSource: {
    zh: '快递来源',
    en: 'Courier source',
    pu: 'Fonte do correio'
  },
  ProblemPiece: {
    zh: '异常件',
    en: '',
    pu: 'Exceções'
  },
  collectOrder: {
    zh: '揽收订单报表',
    en: '',
    pu: 'Declaração de ordens de cobrança'
  },
  deliveryData: {
    zh: '揽收送仓数据监控',
    en: '',
    pu: 'Monitoramento de dados de coleta e entrega ao armazém'
  },
  collectTimeReport: {
    zh: '揽收时效报表',
    en: '',
    pu: 'formulário de tempo de prazo de coleta'
  },
  collectNodeErrDataReport: {
    zh: '揽收节点异常数据监控报表',
    en: '',
    pu: 'Relatório de Monitoramento de Dados Anômalos de Coleta'
  },
  KPIMonitoringReports: {
    zh: '揽收KPI监控报表',
    en: '',
    pu: 'formulário de acompanhamento dos KPI de coleta'
  },
  MonitoringReport: {
    zh: '订单揽收监控报表',
    en: '',
    pu: 'Relatórios de monitoramento de coleta de pedidos'
  },
  PushTrack: {
    zh: '轨迹推送',
    en: '',
    pu: 'Empurrão de via '
  },
  SMSManagement: {
    zh: '短信管理',
    en: '',
    pu: 'Gerenciamento de SMS'
  },
  carMonitoring: {
    zh: '装车卸车数据监控',
    en: '',
    pu: 'Controlo dos dados de carga e descarga'
  },
  MerchantManagement: {
    zh: '商户管理',
    en: '',
    pu: 'Gestão do comerciante'
  },
  TimeLimitForSigning: {
    zh: '签收时效报表统计',
    en: '',
    pu: 'Estatísticas das declarações de limitação assinada'
  },
  DotReceiveDailyReport: {
    zh: '网点揽收日报表',
    en: '',
    pu: 'formulário de diário de coleta'
  },
  vehicleManagement: {
    zh: '车辆管理',
    en: '',
    pu: 'Gestão de veículos'
  },
  vehicleManagement1: {
    zh: '车辆维修',
    en: '',
    pu: 'Reparo de veículos'
  },
  carNumber: {
    zh: '车签号',
    en: '',
    pu: 'Número de escaneamento do veículo'
  },
  ListOfCountries: {
    zh: '国家列表',
    en: 'List of countries',
    pu: 'Lista de países'
  },
  ListOfProvinces: {
    zh: '省份列表',
    en: 'List of provinces',
    pu: 'Lista de províncias'
  },
  ListOfCities: {
    zh: '城市列表',
    en: 'List of cities',
    pu: 'Lista de cidades'
  },
  ListOfDowntownAreas: {
    zh: '市区列表',
    en: 'List of downtown areas',
    pu: 'Lista de áreas do centro'
  },
  DistrictManagement: {
    zh: '地区与税费',
    en: 'Regions & Taxes',
    pu: 'Regiões & Impostos'
  },
  IncomingSenderManagement: {
    zh: '收发件人管理',
    en: 'Incoming sender management',
    pu: 'Gerenciamento de remetente de entrada'
  },
  OrdersReport: {
    zh: '订单报表',
    en: 'Orders report',
    pu: 'Relatório de ordens'
  },
  OrderDataPush: {
    zh: '订单数据推送',
    en: 'Order data push',
    pu: 'Pressione os dados de ordem'
  },
  ChannelManagement: {
    zh: '渠道管理',
    en: 'Channel management',
    pu: 'Gestão de canais'
  },
  channel: {
    zh: '渠道',
    en: 'channel',
    pu: 'canal'
  },
  CustomerChannel: {
    zh: '客户渠道',
    en: 'customer channel',
    pu: 'canal do cliente'
  },
  SystemServiceProvider: {
    zh: '系统服务商',
    en: 'System service provider',
    pu: 'Provedor de serviços de sistema'
  },
  ServiceProviderChannels: {
    zh: '服务商渠道',
    en: 'Service provider channels',
    pu: 'Canais de provedor de serviços'
  },
  ServiceProviderChannelPublicField: {
    zh: '服务商渠道公共字段',
    en: 'Service provider channel publicField',
    pu: 'Provedor de serviços canaliza campo público'
  },
  ServiceProviderChannelRuleVerification: {
    zh: '服务商渠道规则校验',
    en: 'Service provider channel rule verification',
    pu: 'Verificação de regras de canal do provedor de serviços'
  },
  AlternateNumberPool: {
    zh: '备用号码池',
    en: 'Alternate number pool',
    pu: 'Pool de números alternativos'
  },
  NumberPool: {
    zh: '号码池',
    en: 'Number pool',
    pu: 'Pool de números'
  },
  FacetSingleTemplateManagement: {
    zh: '面单模板管理',
    en: 'Facet single template management',
    pu: 'Gerenciamento de modelo único faceta'
  },
  NextHomeList: {
    zh: '下家列表',
    en: 'Next home list',
    pu: 'Próxima lista de casas'
  },
  PlatformManagement: {
    zh: '平台管理',
    en: 'Platform management',
    pu: 'Gerenciamento de plataformas'
  },
  ServiceProviderErrorMessageComparison: {
    zh: '服务商错误信息对照',
    en: 'Service provider error message comparison',
    pu: 'Comparação de mensagens de erro do provedor de serviços'
  },
  OperationalLogs: {
    zh: '操作日志',
    en: 'Operational logs',
    pu: 'Diário de operações'
  },
  TrajectoryManagement: {
    zh: '轨迹管理',
    en: 'Tracking',
    pu: 'Rastreamento'
  },
  AListOfTracks: {
    zh: '轨迹列表',
    en: 'tracking list',
    pu: 'Lista de rastreamento'
  },
  TrackServiceProviderManagement: {
    zh: '轨迹服务商管理',
    en: 'Track service provider management',
    pu: 'Rastrear o gerenciamento do provedor de serviços'
  },
  TrajectoryStatus: {
    zh: '轨迹状态',
    en: 'Trajectory status',
    pu: 'Status da trajetória'
  },
  TrajectoryStateReference: {
    zh: '轨迹状态参照',
    en: 'Trajectory state reference',
    pu: 'Referência de estado de trajetória'
  },
  TrajectoryTemporaryMatching: {
    zh: '轨迹临时匹配',
    en: 'Trajectory temporary matching',
    pu: "Trajetória temporária pi'pei"
  },
  ChannelsAreInterconnectedWithOilCircuits: {
    zh: '渠道相关邮路',
    en: 'Channels are interconnected with oil circuits',
    pu: 'Os canais estão interligados com circuitos de petróleo'
  },
  TrajectoryQuery: {
    zh: '轨迹查询',
    en: 'Trajectory query',
    pu: 'Consulta de trajetória'
  },
  serviceTrajectoryMatching: {
    zh: '服务商轨迹匹配',
    en: '',
    pu: 'Correspondência de trilha de provedor de serviços'
  },
  trackStatus: {
    zh: '轨迹状态',
    em: 'Track status',
    pu: 'Status rastreamento'
  },
  TrackPushReport: {
    zh: '轨迹推送报表',
    en: 'Track push report',
    pu: 'Track push report'
  },
  TrajectoryStatisticsReport: {
    zh: '轨迹统计报表',
    en: 'Trajectory statistics report',
    pu: 'estatística de rastreamento'
  },
  AirlineBillLadingManagement: {
    zh: '航空提单管理',
    en: 'Airline bill of lading management',
    pu: 'Gestão de AWB'
  },
  TrajectoriesAreArtificiallyMade: {
    zh: '轨迹人工制作',
    en: 'Trajectories are artificially made',
    pu: 'rastreamento Manual'
  },
  RiskMonitoringCenter: {
    zh: '风控中心',
    en: 'Risk monitoring center',
    pu: 'Centro de Monitoramento de Riscos'
  },
  RiskMonitoringProducts: {
    zh: '风控产品',
    en: 'Risk monitoring products',
    pu: 'Produtos de monitoramento de riscos'
  },
  RiskMonitoringOrders: {
    zh: '风控订单',
    en: 'Risk monitoring orders',
    pu: 'Ordens de monitoramento de riscos'
  },
  largeJobManagement: {
    zh: '大件作业管理',
    en: '',
    pu: 'Gestão de Operações de Cargas Grandes'
  },
  collectingLoadingRecords: {
    zh: '揽收装车记录',
    en: '',
    pu: 'Registro de Carregamento de Coleta'
  },
  CustomerManagement: {
    zh: '客户管理',
    en: 'Customer management',
    pu: 'Customer management center'
  },
  CustomerList: {
    zh: '客户列表',
    en: 'Customer list',
    pu: 'Lista de clientes'
  },
  customerTimelinessManagement: {
    zh: '客户时效管理',
    en: '',
    pu: 'Gestão da prescrição de clientes'
  },
  CustomerKanban: {
    zh: '客户看板',
    en: 'Customer kanban',
    pu: 'Kanban cliente'
  },
  SortingCenter: {
    zh: '分拣中心',
    en: 'Sorting center',
    pu: 'Centro de triagem'
  },
  bulkyExpress: {
    zh: '大件快递',
    en: '',
    pu: 'Carga grande'
  },
  bulkyOrder: {
    zh: '大件订单',
    en: '',
    pu: 'Pedido de carga grande'
  },
  basicData: {
    zh: '基础数据',
    en: 'basic data',
    pu: 'Dados básicos'
  },
  SortingRules: {
    zh: '分拣规则',
    en: 'Sorting rules',
    pu: 'Regras de classificação'
  },
  NewSortingRules: {
    zh: '新分拣规则',
    en: '',
    pu: 'A nova regra de classifação'
  },
  GridManagement: {
    zh: '格口管理',
    en: 'Grid management',
    pu: 'Gerenciamento de grade'
  },
  ZipCodeArea: {
    zh: '邮编分区',
    en: '',
    pu: 'Zipod'
  },
  sorterOrder: {
    zh: '分拣订单',
    en: '',
    pu: 'Ordenação de pedidos'
  },
  Lattice: {
    zh: '格口',
    en: 'Lattice',
    pu: 'Reticulado'
  },
  SorterLargeScreen: {
    zh: '分拣机大屏展示',
    en: 'Sorter large screen',
    pu: 'Tela grande sorter'
  },
  CourierCollection: {
    zh: '快递揽收',
    en: 'Courier collection',
    pu: 'Coleção de correios'
  },
  InboundOperations: {
    zh: '入库操作',
    en: 'Inbound operations',
    pu: 'Operações de entrada'
  },
  ListOfSmallPackets: {
    zh: '小包列表',
    en: 'List of small packets',
    pu: 'Lista de pacotes pequenos'
  },
  MessageCenter: {
    zh: '消息中心',
    en: 'Message center',
    pu: 'Centro de Mensagens'
  },
  ISentAMessage: {
    zh: '我发送的消息',
    en: 'ISent a message',
    pu: 'Eu enviei uma mensagem.'
  },
  IReceivedAMessage: {
    zh: '我接收的消息',
    en: 'I received a message',
    pu: 'Recebi uma mensagem.'
  },
  MessageTemplates: {
    zh: '消息模板',
    en: 'Message templates',
    pu: 'Modelos de mensagens'
  },
  SystemAdministration: {
    zh: '系统管理',
    en: 'System administration',
    pu: 'Administração do Sistema'
  },
  resourceManagement: {
    zh: '资源管理',
    en: 'resource management',
    pu: 'gestão dos recursos'
  },
  regionalAdministration: {
    zh: '大区管理',
    en: 'Regional administration',
    pu: 'Administração regional'
  },
  LoginLogs: {
    zh: '登录日志',
    en: 'Login logs',
    pu: 'Logs de login'
  },
  ContractManagement: {
    zh: '合同管理',
    en: 'Contract management',
    pu: 'Gestão de contratos'
  },
  MessageSystemParameters: {
    zh: '消息系统参数',
    en: 'Message system parameters',
    pu: 'Parâmetros do sistema de mensagens'
  },
  UserParameters: {
    zh: '用户参数',
    en: 'User parameters',
    pu: 'Parâmetros do usuário'
  },
  TheResourceExportList: {
    zh: '资源导出列表',
    en: 'The resource export list',
    pu: 'A lista de exportação de recursos'
  },
  TheResourceTaskList: {
    zh: '资源任务列表',
    en: 'Resource Task List',
    pu: 'Lista de tarefas de recursos'
  },
  TemplateVariableSettings: {
    zh: '模板变量设置',
    en: 'Template variable settings',
    pu: 'Configurações de variáveis de modelo'
  },
  ServiceHealthTesting: {
    zh: '服务健康检测',
    en: 'Service health testing',
    pu: 'Teste de saúde do serviço'
  },
  OrderTargetSettings: {
    zh: '订单目标设置',
    en: 'Order target settings',
    pu: 'Configurações de destino de ordem'
  },
  LargeScreenDisplay: {
    zh: '大屏展示',
    en: 'Large screen display',
    pu: 'Tela grande'
  },
  ButtonDoorTemporaryDataCache: {
    zh: '钮门临时数据缓存',
    en: 'Button door temporary data cache',
    pu: 'Cache de dados temporários da porta do botão'
  },
  collectionCenter: {
    zh: '揽收中心',
    en: 'Collect center',
    pu: 'Centro de coleta'
  },
  customerHandDoor: {
    zh: '客户上门交件',
    en: '',
    pu: 'Entrega porta-a-porta do cliente'
  },
  listingQuery: {
    zh: '揽收清单',
    en: '',
    pu: 'Lista de coleta'
  },
  lanshouOrder: {
    zh: '揽收订单',
    en: '',
    pu: 'pedido de coleta'
  },
  receivingCodeQuery: {
    zh: '揽收码查询',
    en: '',
    pu: 'Consulta de código de faixa'
  },
  // siteManagement: {
  //   zh: '站点管理',
  //   en: '',
  //   pu: 'Gerenciamento de ponto'
  // },
  siteManagement: {
    zh: '网点管理',
    en: '',
    pu: 'Gestão de Pontos'
  },
  collectionTimeConfiguration: {
    zh: '揽收时效配置',
    en: '',
    pu: 'Configuração do Prazo de Coleta'
  },
  driverManagement: {
    zh: '员工管理',
    en: '',
    pu: 'Gestão de funcionários'
  },
  stockUpReceiveOrders: {
    zh: '备货揽收订单',
    en: '',
    pu: 'Armazenagem e coleta de ordens'
  },
  noScheduledPickUpOrders: {
    zh: '无预报揽收订单',
    en: 'No scheduled pick-up orders',
    pu: 'No scheduled pick-up orders'
  },
  collectorInquire: {
    zh: '揽收商家查询',
    en: 'Receive business inquiries',
    pu: 'Receber consultas comerciais'
  },
  orderLogQuery: {
    zh: '下单日志查询',
    en: '',
    pu: 'Consulta do diário de criar pedido'
  },
  receiveDataProcessing: {
    zh: '揽收数据处理',
    en: '',
    pu: 'Processamento de dados de coleta'
  },
  warehouseToWarehouse: {
    zh: '仓对仓揽收订单',
    en: '',
    pu: 'coleta de pedidos de armazém para armazém'
  },
  operatingPlatform: {
    zh: '操作平台',
    en: '',
    pu: 'Plataforma operacional'
  },
  dispatch: {
    zh: '派件',
    en: '',
    pu: 'Distribuição'
  },
  expressReturn: {
    zh: '快递退件',
    en: '',
    pu: 'Devoluções expressas'
  },
  signFor: {
    zh: '签收',
    en: '',
    pu: 'Assinatura'
  },
  collectWarehousing: {
    zh: '网点揽收入库',
    en: '',
    pu: 'Entrada do armazém na coleta no Ponto'
  },
  collectOutOfWarehouse: {
    zh: '网点揽收出库',
    en: '',
    pu: 'Fora do armazém na coleta no Ponto'
  },
  dispatchWarehousing: {
    zh: '网点派件入库',
    en: '',
    pu: 'Distribuição de entrada no Ponto'
  },
  signForQuery: {
    zh: '签收查询',
    en: '',
    pu: 'Consulta de entrega'
  },
  FinancialCenter: {
    zh: '财务中心',
    en: 'Financial Center',
    pu: 'Centro Financeiro'
  },
  BasicInformation: {
    zh: '基础资料',
    en: 'Basic information',
    pu: 'Informação básica'
  },
  PartitionManagement: {
    zh: '分区管理',
    en: 'Partition management',
    pu: 'Gerenciamento de partições'
  },
  AccountManagement: {
    zh: '账户管理',
    en: 'Account Management',
    pu: 'Gerenciamento de contas'
  },
  feesType: {
    zh: '费用类型',
    en: 'Fees type',
    pu: 'Tipo de custo'
  },
  quoteManagement: {
    zh: '报价管理',
    en: 'Quote management',
    pu: 'Gestão de cotações'
  },
  CustomerAccount: {
    zh: '客户账户',
    en: 'Customer Account',
    pu: 'Conta de Cliente'
  },
  BranchAccounts: {
    zh: '网点账户',
    en: 'Site account',
    pu: 'Conta do site'
  },
  DataPushLogs: {
    zh: '数据推送日志',
    en: 'Data push log',
    pu: 'Dados empurrar logs'
  },
  PriceInquiryLog: {
    zh: '价格查询日志',
    en: 'DPrice inquiry log',
    pu: 'Consulta de preços log'
  },
  ReceivableManagementSite: {
    zh: '应收管理（公司对网点）',
    en: 'receivable management（Company to site）',
    pu: 'gestão de recebíveis（Empresa para o site）'
  },
  ReceivableManagementCompany: {
    zh: '应收管理（网点对客户）',
    en: 'receivable management（Site to customer）',
    pu: 'gestão de recebíveis（Site para o cliente）'
  },
  FeesApply: {
    zh: '应收费用',
    en: 'Fees apply',
    pu: 'Aplicam-se taxas'
  },
  BillingReview: {
    zh: '账单审核',
    en: 'Billing review',
    pu: 'Revisão de faturamento'
  },
  BillReconciliation: {
    zh: '账单核收',
    en: 'Bill reconciliation',
    pu: 'Reconciliação de contas'
  },
  WarehouseInbound: {
    zh: '仓库入库',
    en: 'Warehouse inbound',
    pu: 'Entrada no armazém'
  },
  ReceivingManagement: {
    zh: '收货管理',
    en: '',
    pu: 'coleta e gerenciamento'
  },
  WarehousingWeight: {
    zh: '入库称重',
    en: '',
    pu: 'armazenar e pesar'
  },
  WeighTheQuery: {
    zh: '称重查询',
    en: 'Weigh the query',
    pu: 'Pesquisar o peso'
  },
  WeighingRecords: {
    zh: '称重记录',
    en: 'Weighing records',
    pu: 'Registros de pesagem'
  },
  WeightCorrection: {
    zh: '重量校正',
    en: '',
    pu: 'correção do peso'
  },
  ScanBagging: {
    zh: '扫描装袋',
    en: 'Scan bagging',
    pu: 'Ensacamento de digitalização'
  },
  RemoveTheBag: {
    zh: '移除出袋',
    en: 'Remove the bag',
    pu: 'Retire o saco'
  },
  BigPackList: {
    zh: '大包列表',
    en: 'Big Pack list',
    pu: 'Lista de Big Pack'
  },
  WarehouseOutbound: {
    zh: '仓库出库',
    en: 'Warehouse outbound',
    pu: 'Saída de armazém'
  },
  bagworkList: {
    zh: '装袋列表',
    en: 'bagging list',
    pu: 'Lista de ensacamento'
  },
  totalSingleList: {
    zh: '总单列表',
    en: '',
    pu: 'Lista Geral'
  },
  TotalSingleStowage: {
    zh: '总单配载',
    en: 'Total single stowage',
    pu: 'Saida do armazém'
  },
  operationCZ: {
    zh: '操作',
    en: 'operation',
    pu: 'Operar'
  },
  receiveSign: {
    zh: '揽收签收',
    en: '',
    pu: 'Recebimento e assinatura'
  },
  loadingConfirm: {
    zh: '装车确认',
    en: '',
    pu: 'Confirmação do Carregamento'
  },
  transferCenterVehicleConfirm: {
    zh: '转运中心车辆确认',
    en: '',
    pu: 'Confirmação do veículo no centro de distribuição.'
  },
  receiveInfoVerifyQuery: {
    zh: '揽收信息确认查询',
    en: '',
    pu: 'Consulta de Confirmação das Informações de Coleta'
  },
  transferCenterVehicleConfirmQuery: {
    zh: '转运中心车辆确认查询',
    en: '',
    pu: 'Consulta da confirmação do veículo no centro de distribuição.'
  },
  receiveScan: {
    zh: '非预报揽收',
    en: '',
    pu: 'Coleta não agendada'
  },
  loadingScan: {
    zh: '装车扫描',
    en: '',
    pu: 'Varredura de carga'
  },
  unloadingScan: {
    zh: '卸车扫描',
    en: '',
    pu: 'Descarregamento de digitalização'
  },
  packetScan: {
    zh: '稽包扫描',
    en: '',
    pu: 'Bipar pacote inspecionado'
  },
  bagNumQuery: {
    zh: '袋号查询',
    en: '',
    pu: 'Consulta sobre o número da bolsa'
  },
  problem: {
    zh: '问题件',
    en: 'Problem piece',
    pu: 'Peça problemática'
  },
  packetQuery: {
    zh: '稽包查询',
    en: '',
    pu: 'Consulta de pacote inspecionado'
  },
  monitorStatisticsStatement: {
    zh: '监控/统计报表',
    en: '',
    pu: 'Monitoração/Informações estatísticas'
  },
  basicDate: {
    zh: '基础资料',
    en: '',
    pu: 'Dados básicos'
  },
  finance: {
    zh: '财务',
    en: '',
    pu: 'Finanças'
  },
  bill: {
    zh: '账单推送',
    en: '',
    pu: 'Impulso de Faturamento'
  },
  quotationMaintenance: {
    zh: '报价维护',
    en: '',
    pu: 'Quotation Maintenance'
  },
  mailingArea: {
    zh: '寄件区域',
    en: '',
    pu: 'Área remetente'
  },
  zipZone: {
    zh: '邮编分区',
    en: '',
    pu: 'Divisão Zip Code'
  },
  zipZoneManagement: {
    zh: '邮编管理',
    en: '',
    pu: 'Gerenciamento de códigos postais'
  },
  trackQuery: {
    zh: '轨迹查询',
    en: '',
    pu: 'Busca de rastro'
  },
  CollectKanban: {
    zh: '揽收看板',
    en: '',
    pu: 'Tela de coleta'
  },
  daily: {
    zh: '揽收日报',
    en: '',
    pu: 'Relatório diário de coleta/Diário de coleta'
  },
  collectOperationalData: {
    zh: '揽收营运数据',
    en: '',
    pu: 'Dados Operacionais de Coleta'
  },
  mdHoldupTable: {
    zh: 'md滞留表',
    en: '',
    pu: 'Tabela de retenção MD'
  },
  supplier: {
    zh: '供应商列表',
    en: 'List of vendors',
    pu: 'Lista de fornecedores'
  },
  PrintedSheet: {
    zh: '打印面单',
    en: '',
    pu: 'Imprimir etiquetas'
  },
  singleScan: {
    zh: 'SingleScan',
    en: 'Single Scan',
    pu: 'Scan Unico'
  },
  singleScanZH: {
    zh: '单次扫描',
    en: 'Single Scan',
    pu: 'Scan Unico'
  },
  replaceBag: {
    zh: 'Replace Bag',
    en: 'Replace Bag',
    pu: 'Troca de Saca'
  },
  Transport: {
    zh: '运输',
    en: 'Transport',
    pu: 'Transporte'
  },
  delivery: {
    zh: 'Delivery',
    en: 'Delivery',
    pu: 'Entregador'
  },
  vehicle: {
    zh: '车辆',
    en: 'Vehicle',
    pu: 'Veículos'
  },
  route: {
    zh: '路线',
    en: 'Route',
    pu: 'Rota'
  },
  driver: {
    zh: '司机',
    en: 'Driver',
    pu: 'Motorista'
  },
  equipment: {
    zh: 'Equipment',
    en: 'Equipment',
    pu: 'Equipamento'
  },
  seller: {
    zh: '卖方',
    en: 'Seller',
    pu: 'Seller'
  },
  trailer: {
    zh: '拖车',
    en: 'trailer',
    pu: 'Carreta'
  },
  transfer: {
    zh: '转账',
    en: 'transfer',
    pu: 'Transferencia'
  },
  bank: {
    zh: '银行',
    en: 'bank',
    pu: 'Banco'
  },
  base: {
    zh: '基地',
    en: 'Base',
    pu: 'Base'
  },
  company: {
    zh: '公司',
    en: 'company',
    pu: 'Companhia'
  },
  transferRoutes: {
    zh: '转运路线',
    en: 'Transfer Routes',
    pu: 'Rotas de transferência'
  },
  edition: {
    zh: '版本',
    en: 'Edition',
    pu: 'Edição'
  },
  reverse: {
    zh: '反向 Panini',
    en: 'Reverse Panini',
    pu: 'Reverso Panini'
  },
  orderTransport: {
    zh: 'Order transport',
    en: 'Order transport',
    pu: 'Ordem de transporte'
  },
  editionUpload: {
    zh: '版本上传',
    en: 'Edition upload',
    pu: 'Upload de edição'
  },
  controlTower: {
    zh: '控制塔',
    en: 'Control Tower',
    pu: 'Torre de Controle'
  },
  FunctionalStatistics: {
    zh: '功能统计',
    en: '',
    pu: 'Estatísticas de funções'
  },
  ReportManagement: {
    zh: '数据报表',
    en: '',
    pu: 'Folha de dados'
  },
  orderPush: {
    zh: '订单推送',
    en: '',
    pu: 'push do pedido'
  },
  cancelOrder: {
    zh: '订单取消',
    en: 'Order Cancellation',
    pu: 'Cancelamento do Pedido'
  },
  tablePrice: {
    zh: 'Price table',
    en: 'Price table',
    pu: 'Tabela de preço'
  },
  TransporteStatus: {
    zh: '干线状态',
    en: '',
    pu: 'Transporte de linha principal'
  },
  smartCabinet: {
    zh: '智能柜',
    en: '',
    pu: 'gabinete inteligente'
  },
  cabinetInformation: {
    zh: '柜子信息',
    en: '',
    pu: 'Informações do gabinete'
  },
  latticeMaintenance: {
    zh: '格子维护',
    en: '',
    pu: 'Manutenção da treliça'
  },
  cabinetMaintenance: {
    zh: '柜子维护',
    en: '',
    pu: 'Manutenção do gabinete'
  },
  ShippingInvoices: {
    zh: '运输发票',
    en: 'Shipping invoices',
    pu: 'Faturas de envio'
  },
  invoice: {
    zh: '发票',
    en: 'invoice',
    pu: 'fatura'
  },
  UnloadingPoint: {
    zh: '卸货点',
    en: '',
    pu: 'Ponto de descarga'
  },
  entregaMDFE: {
    zh: '派送MDFE',
    en: '',
    pu: 'entrega MDFE'
  },
  ShiftManagement: {
    zh: '班次管理',
    en: '',
    pu: 'Gestão de turnos'
  },
  DryBranchFlowManagement: {
    zh: '干支线流向管理',
    en: '',
    pu: 'Gestão de Fluxo de Linhas de Transporte Primário e Secundário'
  },
  CurrencyManagement: {
    zh: '币种管理',
    en: 'Currency Management',
    pu: 'Gerenciamento de moedas'
  },
  InsurancePushTable: {
    zh: '保险推送表',
    en: 'Insurance Push Form',
    pu: 'Formulário de envio de seguro'
  },
  SupplierManagement: {
    zh: '服务商系统管理',
    en: 'SupplierManagement',
    pu: 'Gestão de fornecedores'
  },
  VendorRole: {
    zh: '供应商角色',
    en: 'Vendor role',
    pu: 'Função do fornecedor'
  },
  VendorPermissions: {
    zh: '供应商权限',
    en: 'Supplier permissions',
    pu: 'Permissões do fornecedor'
  },
  VendorAccountNumber: {
    zh: '供应商账号',
    en: 'Supplier account',
    pu: 'Lista de mensagens'
  },
  OperatorsManual: {
    zh: '操作手册',
    en: "Operator's manual",
    pu: 'Manual do operador'
  },
  MessageList: {
    zh: '留言列表',
    en: 'Message List',
    pu: 'Lista de mensagens'
  },
  AccountOpening: {
    zh: '账号开通',
    en: 'Account opening',
    pu: 'Abertura de conta'
  },
  ParcelReturned: {
    zh: '待退回包裹',
    en: '',
    pu: 'Pacotes a serem devolvidos'
  },
  TrackReceptionManagement: {
    zh: '轨迹接收管理',
    en: 'Track Reception Management',
    pu: 'Gerenciamento de Recebimento de Rastreamento'
  },
  TrajectoryStatusMonitoring: {
    zh: '轨迹与状态同步监控',
    en: '',
    pu: 'Monitoramento de sincronização de rastreamento e status'
  },
  SMSConfiguration: {
    zh: '短信配置',
    en: 'SMS configuration',
    pu: 'Mensagem de configuração'
  },
  operationGuide: {
    zh: '操作指南',
    en: '',
    pu: 'Guia de operação'
  },
  TrackPushManagement: {
    zh: '轨迹推送管理',
    en: 'Track Push Management',
    pu: 'Gerenciamento de Envio de Rastreamento'
  },
  ManualSortingRoute: {
    zh: '手动分拣',
    en: '',
    pu: 'Classificação manual'
  },
  ReverseOrders: {
    zh: '逆向订单',
    en: 'Reverse orders',
    pu: 'Pedido reverso'
  },
  MinutaList: {
    zh: 'Minuta列表',
    en: '',
    pu: 'Lista Minuta'
  },
  WaybillTimeliness: {
    zh: '运单时效监控',
    en: '',
    pu: 'Monitoramento da pontualidade de pedido'
  },
  SupplierData: {
    zh: '供应商数据',
    en: 'Supplier Data',
    pu: 'Dados de Fornecedor'
  },
  dotScanRate: {
    zh: '网点扫描率',
    en: '',
    pu: 'Taxa de bipe de ponto'
  },
  kanbanBoard: {
    zh: '运单看板',
    en: '',
    pu: 'Dashboard de pedido'
  },
  receivable: {
    zh: '应收',
    en: 'receivable',
    pu: 'receber'
  },
  payable: {
    zh: '应付',
    en: 'payable',
    pu: 'Pagável'
  },
  payableTransportation: {
    zh: '应付-运输',
    en: 'Payable - Transportation',
    pu: 'Pagável - Transporte'
  },
  AccountsPayable: {
    zh: '应付账户',
    en: 'Accounts payable',
    pu: 'Conta a Pagar'
  },
  allLinkTimeStatistics: {
    zh: '全链路时效统计',
    en: '',
    pu: 'Estatísticas de tempo de toda a cadeia'
  },
  PayableManagement: {
    zh: '应付管理',
    en: 'Payable management',
    pu: 'Gestão a Pagar'
  },
  FeesPayable: {
    zh: '应付费用',
    en: 'Fees payable',
    pu: 'Taxas a pagar'
  },
  DispatchReferenceTable: {
    zh: '派送参照表',
    en: 'Dispatch reference table',
    pu: 'Tabela de referência de expedição'
  },
  HutDispatchAccountsPayable: {
    zh: '安骏小屋应付账户',
    en: '',
    pu: 'Mini Drop Contas a pagar'
  },
  DispatchAccountsPayable: {
    zh: 'AJD派送应付账户',
    en: 'AJD Dispatch accounts payable',
    pu: 'AJD Despachar contas a pagar'
  },
  DspDispatchAccountsPayable: {
    zh: 'DSP派送应付账户',
    en: '',
    pu: 'DSP Despachar contas a pagar'
  },
  AccountsReceivablePayable: {
    zh: '揽收应付账户',
    en: 'Accounts receivable and payable',
    pu: 'Contas a receber e a pagar'
  },
  order: {
    zh: '订单',
    en: 'order',
    pu: 'Pedido'
  },
  returnCenter: {
    zh: '退件中心',
    en: '',
    pu: 'Centro de Devoluções'
  },
  returnQuery: {
    zh: '退件查询',
    en: '',
    pu: 'Consulta sobre devoluções'
  },
  BillVerification: {
    zh: '账单核付',
    en: 'Bill verification',
    pu: 'Verificação de Faturas'
  },
  RouteDistanceReference: {
    zh: '路线距离参照表',
    en: 'route distance reference table',
    pu: 'Mesa de referência da distância da rota'
  },
  FranchiseeTimelinessStatistics: {
    zh: '网点时效统计',
    en: '',
    pu: 'Estatísticas de Prazo dos Parceiros'
  },
  SHEINDailyDeliveryDataMonitoring: {
    zh: '每日派送数据监控',
    en: '',
    pu: 'Monitoramento diário dos dados de entrega'
  },
  DeviceManagement: {
    zh: '设备管理',
    en: 'Device management',
    pu: 'gestão de equipamento'
  },
  vehicleUsageStatistics: {
    zh: '车辆使用统计',
    en: 'Vehicle Usage Statistics',
    pu: 'Estatísticas de Uso de Veículos'
  },
  InvoiceManagement: {
    zh: '发票管理',
    en: 'Invoice management',
    pu: 'Gestão de faturas'
  },
  FileUploadManagement: {
    zh: '文件上传管理',
    en: 'File Upload Management',
    pu: 'Gerenciamento de upload de arquivos'
  },
  DataDheet: {
    zh: '数据报表',
    en: 'data sheet',
    pu: 'folha de dados'
  },
  CollectionDailyReceivableStatistics: {
    zh: '揽收每日应收统计报表',
    en: 'Collection of daily statistical statements of receivables',
    pu: 'Cobrança de extratos estatísticos diários de contas a receber'
  },
  sysInfo: {
    zh: '系统消息',
    en: '',
    pu: 'Informação de sistema'
  },
  largeParcelCollection: {
    zh: '大包裹揽收',
    en: '',
    pu: 'Coleta do pacotes grandes'
  },
  ReadyReceiveScanning: {
    zh: '备货揽收扫描',
    en: '',
    pu: 'Preparação de coleta a bipar'
  },
  bagScanning: {
    zh: '封袋',
    en: 'Close bag',
    pu: 'Fechar saca'
  },
  outGoRecord: {
    zh: '分拨扫描记录表',
    en: '',
    pu: 'Lista de registro de bipagem da triagem'
  },
  PackagePrinting1: {
    zh: '包牌打印',
    en: '',
    pu: 'Imprimir código de barra de Nº de saca'
  },
  arrive: {
    zh: '到件',
    en: '',
    pu: 'Entrada'
  },
  sender: {
    zh: '发件',
    en: '',
    pu: 'Saída'
  },
  arrivalRecord: {
    zh: '到件记录',
    en: '',
    pu: 'Registro de chegada de pacotes'
  },
  newReplaceBag: {
    zh: '袋子替换',
    en: '',
    pu: 'New Replace Bag'
  },
  personnelRegistration: {
    zh: '人员登记',
    en: '',
    pu: 'Registro de pessoal'
  },

  ClaimsManagement: {
    zh: '理赔管理',
    en: '',
    pu: 'Gestão de reclamações'
  },
  ClaimList: {
    zh: '理赔列表',
    en: '',
    pu: 'Lista de reclamações'
  },
  ClaimStatement: {
    zh: '理赔账单',
    en: '',
    pu: 'Cobrança de sinistros'
  },
  ClaimsReviewRules: {
    zh: '理赔审核规则',
    en: '',
    pu: 'Regras de revisão de sinistros'
  },
  signMultipleOrders: {
    zh: '多次签收订单',
    en: '',
    pu: 'Assinação o pedido várias vezes'
  },
  misallocationRateName: {
    zh: '分拨错发率',
    en: '',
    pu: 'Taxa de erro de distribuição'
  },
  quickOrderChange: {
    zh: '快速换单',
    en: '',
    pu: 'Troca rápida de pedidos'
  },
  LoadingDocument: {
    zh: '装车文件',
    en: '',
    pu: 'Documentos de carregamento'
  },
  requestMessageList: {
    zh: '需求留言列表',
    en: '',
    pu: 'Solicitar lista de mensagens'
  },
  lossRateMonitoring: {
    zh: '遗失率监控',
    en: '',
    pu: 'Monitorização da taxa de perdas'
  },
  TransferKanbanBoard: {
    zh: '转运看板',
    en: '',
    pu: 'Painel de Transferência'
  },
  DeliveryLoading: {
    zh: '派送装车监控',
    en: '',
    pu: 'Monitoramento de Carregamento de Entrega'
  },
  trackStopMonitoring: {
    zh: '轨迹停更数据监控',
    en: '',
    pu: 'Relatório de Monitoramento de Dados de Rastreio Parado'
  },
  returnDataMonitoring: {
    zh: '退件数据监控',
    en: '',
    pu: 'Monitoramento de Dados de Devolução'
  },
  endCollectionMonitoring: {
    zh: '末端揽收数据监控',
    en: '',
    pu: 'Monitoramento de Dados de Coleta Final'
  },
  allocateDetentionMonitoring: {
    zh: '分拨滞留监控',
    en: '',
    pu: 'Monitoramento de Retenção na Distribuição'
  },
  ReturnOperationRecord: {
    zh: '退件操作记录',
    en: '',
    pu: 'Registro de Operação de Devolução'
  },
  PostalCodeCorrection: {
    zh: '邮编纠正',
    en: '',
    pu: 'Correção de CEP'
  },
  CompensationManagement: {
    zh: '索赔管理',
    en: '',
    pu: 'Gestão de Pedidos de Indenização'
  },
  CompensationList: {
    zh: '索赔列表',
    en: '',
    pu: 'Lista de Pedidos de Indenização'
  },
  CompensationStatement: {
    zh: '索赔账单',
    en: '',
    pu: 'Fatura do Pedido de Indenização'
  },
  agingManagement: {
    zh: '时效管理',
    en: '',
    pu: 'Gestão de Eficácia'
  },
  staticAgingMonitoring: {
    zh: '静态时效监控',
    en: '',
    pu: 'Monitoramento de Eficácia Estática'
  },
  serviceOroviderOrder: {
    zh: '服务商订单',
    en: '',
    pu: 'Pedido do parceiro'
  },
  customerIssuemanagement: {
    zh: '本地客户问题件管理',
    en: '',
    pu: 'Gerenciamento de pacotes de problemas do cliente local'
  },
  ComprehensiveCost: {
    zh: '应付综合费用',
    en: '',
    pu: 'Custo global'
  },
  ThirdPartyCollection: {
    zh: '第三方揽收',
    en: '',
    pu: 'Coleta por treceiro'
  },
  AnjunCottage: {
    zh: '安骏小屋',
    en: '',
    pu: 'Mini Drop'
  },
  AJDDispatch: {
    zh: 'AJD派送',
    en: '',
    pu: 'Entrega por AJD'
  },
  DSPDispatch: {
    zh: 'DSP派送',
    en: '',
    pu: 'Entrega por DSP'
  },
  addressBaseManagement: {
    zh: '地址库管理',
    en: '',
    pu: 'Gerenciamento da biblioteca de endereços'
  },
  replaceScanner: {
    zh: '换单扫描',
    en: '',
    pu: 'Troca de verificação de ordem'
  },
  TransportationTrunkFeeder: {
    zh: '运输（干/支线）',
    en: 'Transportation (Trunk/Feeder)',
    pu: 'Transporte (seco/ramo)'
  },
  BankPaymentManagement: {
    zh: 'Bradesco银行付款管理',
    en: '',
    pu: 'Gestão de Pagamentos do Banco Bradesco'
  },
  signLatLonMonitoring: {
    zh: '签收经纬度监控',
    en: '',
    pu: 'Monitoramento da latitude e longitude da assinatura'
  },
  SignLimitConfiguration: {
    zh: '签收限制配置',
    en: '',
    pu: 'Configuração de Restrição de Assinatura'
  },
  largeOrderManagement: {
    zh: '大件订单管理',
    en: '',
    pu: 'Gestão de Pedidos de Cargas Grandes'
  },
  wovenBagManagement: {
    zh: '编织袋回收',
    en: '',
    pu: 'Reciclagem de sacos de tecido'
  },
  detailManagement: {
    zh: '回收明细',
    en: '',
    pu: 'Detalhes da reciclagem'
  },
  recyclingSummaryQuery: {
    zh: '回收汇总查询',
    en: '',
    pu: 'Consulta de resumo de reciclagem'
  },
  MonthlySummary: {
    zh: '按月汇总',
    en: '',
    pu: 'Resumo por mês'
  },
  ComplaintWorkOrder: {
    zh: '投诉工单',
    en: 'Complaint Work Order',
    pu: 'Ordem de serviço de reclamações'
  },
  usageConfiguration: {
    zh: '使用量配置',
    en: '',
    pu: 'Configuração de uso'
  },
  recyclingManagement: {
    zh: '回收管理',
    en: '',
    pu: 'Gerenciamento de reciclagem'
  },
  expressSignage: {
    zh: '快递看板',
    en: '',
    pu: 'Correio Kanban'
  },
  sortingManagement: {
    zh: '分拣管理',
    en: '',
    pu: 'Gerenciamento de classificação'
  },
  expressDataCenter: {
    zh: '快递中心',
    en: '',
    pu: 'Centro de correio'
  },
  serviceQuality: {
    zh: '服务质量',
    en: '',
    pu: 'Qualidade do serviço'
  },
  ITResourceManagement: {
    zh: 'IT资源管理',
    en: '',
    pu: 'Gestão de recursos de TI'
  },
  orderSearch: {
    zh: '订单查询',
    en: '',
    pu: 'Consulta de pedidos'
  },
  MenuSerch: {
    zh: '菜单搜索',
    en: '',
    pu: 'Pesquisa de Menu'
  },
  changePwd: {
    zh: '修改密码',
    en: 'changePwd',
    pu: 'alterar a senha'
  },
  MDFE: {
    zh: 'MDFE',
    en: 'MDFE',
    pu: 'MDFE'
  },
  MandatorySignatureVerification: {
    zh: '强制签收审核',
    en: '',
    pu: 'Auditoria de assinatura obrigatória'
  },
  qualityService: {
    zh: '服务质量',
    en: '',
    pu: 'QOS'
  },
  SortingMachineSignboard: {
    zh: '分拣机看板',
    en: 'Sorting machine signboard',
    pu: 'Taxa de ordenamento de máquinas'
  }
};
