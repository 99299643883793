<!-- AJD派送应付账户 -->
<template>
  <div class="app-container">
    <el-card class="mb5 searchContainer">
      <el-form label-position="top" size="small">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="">
              <template slot="label">
                <el-select
                  v-model="typeVal"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  class="status"
                >
                  <el-option
                    v-for="item in QUERY_NAME_ACCOUNT_PARAMS_OBJ" :key="item.value" :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
              <el-input v-model.trim="inputVal" :placeholder="$t('GlobalSetObj.pleaseInputContent')" style="display: block;width: 100%;" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 所属供应商 -->
            <el-form-item :label="$t('GlobalSetObj.SubordinateSupplier')">
              <QueryProviderV2Cmp style="width: 100%;" :val="queryForm.params.providerId" @change="val => queryForm.params.providerId = val" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 所属网点 -->
            <el-form-item :label="$t('collectionCenter.belongSite')">
              <QuerySiteCmp
                :val="queryForm.params.siteId"
                :provider-id="queryForm.params.providerId"
                @change="val => queryForm.params.siteId = val"
              />
              <!-- <QuerySiteV2Cmp
                class="form-bottom" :provider-id="queryForm.params.providerId" :val="queryForm.params.siteId"
                @change="val => queryForm.params.siteId = val"
              /> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="left">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="onSearch"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="onReset"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <div class="mb10" style="display: flex; justify-content: space-between;">
        <!--导入账户信息-->
        <ImportFile :bank-list="bankList" :type="queryForm.params.accountType" />

        <div>
          <!-- 导出账户信息 -->
          <el-button
            v-permit:remove="'but:finance:basicData:payable:dispatchAccountsPayable:exportAccountInformation'"
            icon="el-icon-download"
            type="primary"
            size="mini"
            @click="onExportAccountInformation"
          >{{ $t('basicData.ExportAccountInformation') }}</el-button>

          <!-- 导出账户流水 -->
          <el-button
            v-permit:remove="'but:finance:basicData:payable:dispatchAccountsPayable:exportAccountStatement'"
            icon="el-icon-download" type="primary" size="mini"
            @click="onExportAccountStatement"
          >{{ $t('basicData.ExportAccountRollover') }}</el-button>
        </div>
      </div>

      <el-table
        ref="elTable"
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
      >
        <!--序号-->
        <el-table-column :label="$t('basicData.serialNumber')" width="80px" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- 应付账户 -->
        <el-table-column prop="username" :label="$t('route.AccountsPayable')">
          <template slot-scope="scope">
            <el-link type="primary" @click="onDetails(scope.row)">{{ scope.row.username }}</el-link>
          </template>
        </el-table-column>

        <!-- 真实姓名 -->
        <el-table-column prop="userRealName" :label="$t('customerManagements.realName')" />

        <!-- 付款总计 -->
        <el-table-column prop="totalPaymentAmount" :label="$t('basicData.totalPaymentAmount')" />

        <!-- 未付总计 -->
        <el-table-column prop="unpaidAmount" :label="$t('basicData.unpaidTotal')" />

        <!-- 已付总计 -->
        <el-table-column prop="paidAmount" :label="$t('basicData.totalPaid')" />

        <!-- 账号类型 -->
        <el-table-column prop="accountTypeValue" :label="$t('GlobalSetObj.accountType')" />

        <!-- 所属网点 -->
        <el-table-column prop="siteName" :label="$t('collectionCenter.belongSite')" />

        <!-- 所属供应商 -->
        <el-table-column prop="providerName" :label="$t('GlobalSetObj.SubordinateSupplier')" />

        <!-- 派送车辆类型 -->
        <el-table-column prop="deliveryVehicleType" :label="$t('orderCenterCont.deliveryCar')" />

        <!-- 结算类型 -->
        <el-table-column prop="settlementType" :label="$t('GlobalSetObj.settlementType')" />
        <!-- 结算账号 -->
        <!--<el-table-column prop="settlementAccount" :label="$t('GlobalSetObj.settlementAccount')" />-->
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="currentGetTableData"
          @size-change="sizeGetTableData"
        />
      </div>
    </el-card>

    <el-drawer
      :append-to-body="true"
      :visible.sync="batchNumberDetailRow.isBool"
      direction="ltr"
      :with-header="false"
      :label="$t('receivablePayable.ReconcileBatchDetails')"
      size="70%"
    >
      <detail-payment
        :key="batchNumberDetailKey"
        :detail-data="batchNumberDetailRow.row"
        :account-type="2"
      />
    </el-drawer>
  </div>
</template>

<script>
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { QUERY_NAME_ACCOUNT_PARAMS_OBJ, ACCOUNT_TYPE_OBJ } from '@/views/finance/basicData/cope/accountsReceivablePayable/utils.js';
import QueryProviderV2Cmp from '@/views/finance/cmp/Query/QueryProviderV2Cmp.vue';
import { apiDeliveryPayableAccountExport, apiExportOfBillFlow } from '@/api/finance/basicData';
import { apiDeliveryPayableAccountPageNew, apiGetBankList } from '@/api/newFinance/index.js';
import { goExportList } from '@/utils/goExportList.js';
import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp';

const PARAMS = {
  username: '', // 账号
  realName: '', // 真实姓名
  // settlementType: null, // 结算类型： RPA, CNPJ
  providerId: '', // 服务商
  siteId: '', // 网点
  accountType: 1 // 账号类型 1 :AJD 2:DSP
};

export default {
  name: '',

  components: {
    QueryProviderV2Cmp,
    QuerySiteCmp,
    ImportFile: () => import('../components/importFile.vue'),
    'detail-payment': () => import('./cmp/detailPayment.vue')
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      QUERY_NAME_ACCOUNT_PARAMS_OBJ,
      typeVal: QUERY_NAME_ACCOUNT_PARAMS_OBJ['username'].value,
      inputVal: '',
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          ...PARAMS
        }
      },

      total: 0,
      tableData: [],
      options: [],
      bankList: [],

      // 对账单详情
      batchNumberDetailRow: {
        isBool: false,
        row: {
          requestSource: 2, // 请求来源 1:应付-揽收账户 2:应付-派送账户
          // collectionPayableAccountCondition: undefined, // 应付-揽收账户查询条件
          deliveryPayableAccountCondition: { // 应付-派送账户查询
            id: 0
          },
          name: '', // 网点名称
          providerBody: '', // 应付主体.
          payableUnsettledAmount: 0, // 应付合计
          payedAmount: 0, // 已付合计
          unpavedAmount: 0// 未付合计
        }
      },
      batchNumberDetailKey: new Date().getTime()
    };
  },

  created() {
    this.asyncGetTableData();
    this.getBankList();
  },

  mounted() {
    this.bus.$on('onClose', (data) => {
      if (data) {
        this.updateData();
      }
      this.batchNumberDetailRow.isBool = false;
    });
  },

  methods: {
    // 详情保存后更新列表数据
    updateData() {
      this.asyncGetTableData();
    },
    getQuery() {
      const { queryForm, inputVal, typeVal } = this;
      return {
        ...queryForm,
        params: {
          ...queryForm.params,
          username: undefined,
          realName: undefined,
          [typeVal]: inputVal
        }
      };

      // return obj;
    },
    asyncGetTableData() {
      const obj = this.getQuery();
      apiDeliveryPayableAccountPageNew(obj).then(res => {
        const { records, total } = res.data;
        this.total = total;
        this.tableData = (records || []).map(item => {
          const obj = {
            ...item
          };

          obj.accountTypeStr = ACCOUNT_TYPE_OBJ[item.accountType]?.label ?? '';

          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      });
    },

    onSearch() {
      this.queryForm.pageNumber = 1;
      this.asyncGetTableData();
    },

    onReset() {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = 10;
      Object.assign(this.queryForm.params, { ...PARAMS });
      this.inputVal = '';
      this.typeVal = QUERY_NAME_ACCOUNT_PARAMS_OBJ['username'].value;
      this.asyncGetTableData();
    },

    // 详情
    onDetails(row) {
      const obj = {
        ...row,
        deliveryPayableAccountCondition: {
          id: row.id
        },
        name: row.siteName, // 网点名称
        providerBody: row.providerName, // 应付主体
        payableUnsettledAmount: row.totalPaymentAmount, // 应付合计
        payedAmount: row.paidAmount, // 已付合计
        unpavedAmount: row.unpaidAmount // 未付合计
      };
      Object.assign(this.batchNumberDetailRow.row, obj);
      this.batchNumberDetailRow.isBool = true;
      this.batchNumberDetailKey = new Date().getTime();
    },

    // 导出账户信息
    onExportAccountInformation() {
      const { params } = this.getQuery();
      apiDeliveryPayableAccountExport(params).then(res => {
        goExportList(this);
      }).catch(err => {
        console.error(err);
      });
    },

    // 导出账户流水
    onExportAccountStatement() {
      const query = this.getQuery();
      const { requestSource } = this.batchNumberDetailRow.row;
      const obj = {
        startTime: '',
        endTime: '',
        requestSource,
        isSiteDetailsRequest: false,
        deliveryPayableAccountCondition: {
          ...query.params
        }

      };
      apiExportOfBillFlow(obj).then(res => {
        if (res.code === 200) {
          goExportList(this);
        }
      });
    },
    // 银行列表
    getBankList() {
      apiGetBankList().then(res => {
        this.bankList = res.data;
      });
    },
    currentGetTableData(val) {
      this.queryForm.pageNumber = val;
      this.asyncGetTableData();
    },
    sizeGetTableData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.asyncGetTableData();
    }
  }
};

</script>

<style lang="scss" scoped>
@import '../accountsReceivablePayable/style.css';
</style>

