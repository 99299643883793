<template>
  <el-select
    v-model="value"
    filterable
    :filter-method="filteMethod"
    :placeholder="$t('operationCenter.PleaseSelect')"
    :loading="loading"
    :disabled="disabled"
    clearable
    style="width: 100%;"
    @change="change"
  >
    <el-option
      v-for="item in list"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
      <span style="float: left">{{ item.label }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.username }}</span>
    </el-option>
  </el-select>
</template>

<script>
import { getUserPage } from '@/api/user.js';
import { cloneDeep } from 'lodash-es';

const query = {
  pageNumber: 1,
  pageSize: 99999999,
  params: {
    accountState: 1
  }
};

const ALL_USER = 'allUser';

export default {
  name: '',

  props: {
    val: {
      type: [String, Number],
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      list: [],
      copyList: [], // 副本
      value: '',
      loading: false
    };
  },

  watch: {
    'val': {
      handler(val) {
        this.value = val;
      },
      immediate: true
    }

  },

  created() {
    this.getAllUser();
  },

  methods: {
    // 获取所有用户
    getAllUser() {
      if (sessionStorage.getItem(ALL_USER)) {
        const arr = JSON.parse(sessionStorage.getItem(ALL_USER));
        const list = arr.map(item => Object.freeze(item));
        this.list = list;
        this.copyList = list;
        return;
      }

      this.loading = true;
      getUserPage(query, false).then((res) => {
        const { records } = res.data;

        const arr = (records || []).map(item => {
          const { id, realName, username } = item;
          const obj = {
            value: id,
            label: realName, // 用户名
            username // 账号名
          };

          return Object.freeze(obj);
        });

        // 存储
        sessionStorage.setItem(ALL_USER, JSON.stringify(arr));

        this.list = arr;
        this.copyList = arr;
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    change(val) {
      this.$emit('change', val);
    },

    // 自定义搜索 匹配用户名和账号
    filteMethod(val) {
      const list = cloneDeep(this.copyList);

      this.list = list.filter(item => {
        const { label, username } = item;
        return (label.indexOf(val) > -1) || (username.indexOf(val) > -1);
      }).map(item => {
        return Object.freeze(item);
      });
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
