<template>
  <div>
    <el-row :gutter="20" class="mt-20">
      <el-col :span="24">
        <el-card class="box-card">
          <span class="clearfix">{{$t('newOrder.SortingCenterOperationData')}}</span>
          <el-row v-if="segmentMetrics&&segmentMetrics.length" :gutter="20" style="margin-top: 20px">
            <el-col
              v-for="(item, index) in segmentMetrics" :key="index" :span="4"
              :md="6"
              :sm="8"
              :lg="4"
            >
              <div class="segment-item">
                <div class="segment-title">{{ item.operateSiteName }}</div>
                <div style="display: flex;justify-content: center">
                  <div class="segment-item-box">
                    <p class="segment-item-box-title"> {{$t('GlobalSetObj.arrive')}}</p>
                    <div class="segment-value">{{ item.leaveCount|formatNumber }}</div>
                  </div>
                  <div class="segment-item-box">
                    <p class="segment-item-box-title">{{$t('GlobalSetObj.sender')}}</p>
                    <div class="segment-subvalue">{{ item.arriveCount|formatNumber }}</div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-empty v-else :image-size="100" />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { waybillDashboardTransferOperateData } from '@/api/orderApi';

export default {
  filters: {
    // 数字格式化函数
    formatNumber(num, showPositiveSign = false) {
      // 如果不是数字，直接返回原值
      if (isNaN(num) || num === null) return num;

      // 将数字转换为字符串
      let numStr = Number(num).toString();

      // 处理负数
      const isNegative = numStr.startsWith('-');
      if (isNegative) {
        numStr = numStr.slice(1);
      }

      // 分离整数部分和小数部分
      const parts = numStr.split('.');
      let integerPart = parts[0];
      const decimalPart = parts[1];

      // 添加千分位逗号
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // 重组数字
      let result = integerPart;
      if (decimalPart) {
        result += '.' + decimalPart;
      }

      // 添加正负号
      if (isNegative) {
        result = '-' + result;
      } else if (showPositiveSign && num > 0) {
        result = '+' + result;
      }

      return result;
    }
  },
  props: {
    orderType: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      segmentMetrics: []
    };
  },
  watch: {
    orderType() {
      this.getData();
    }
  },
  created() {
    this.getData();
  },
  methods: {
    /**
     * @description 获取数据
     */
    getData() {
      waybillDashboardTransferOperateData(this.orderType)
        .then((res) => {
          if (res.code===200) {
            this.segmentMetrics = res.data;
          }
        });
    }
  }
};
</script>

<style scoped>
.clearfix{
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #1C1C1C;
  line-height: 22px;
  margin-bottom: 20px;
}
.segment-item{
  background: #F4FAF6;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 10px;
  .segment-title{
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 14px;
    color: #1C1C1C;
    line-height: 20px;
    text-align: center;
  }
  .segment-item-box{
    width: 50%;
    text-align: center;
    .segment-item-box-title{
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 14px;
      color: #1C1C1C;
      line-height: 20px;
    }
    .segment-value{
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 14px;
      color: #009C37;
      line-height: 20px;
    }
    .segment-subvalue{
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 14px;
      color: #FFA700;
      line-height: 20px;
    }
  }
}
</style>
