var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        { attrs: { type: "border-card" } },
        [
          _c(
            "el-tab-pane",
            {
              staticStyle: { margin: "0 -15px" },
              attrs: { label: _vm.$t("basicData.AccountDetails") },
            },
            [
              _c("check-detail", {
                attrs: { "detail-data": _vm.detailData, type: _vm.accountType },
                on: { clearDetail: _vm.clearDetail },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("basicData.PaymentData") } },
            [
              _c("payment-detail", {
                attrs: {
                  "bank-list": _vm.bankList,
                  "detail-data": _vm.detailData,
                  type: _vm.accountType,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }