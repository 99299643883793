<template>
  <!-- 页面名称 -->
  <div>
    <el-card class="box-card">
      <div style="float:left;">
        <el-button
          icon="el-icon-upload2"
          size="mini"
          @click="exportData">
          {{ $t('collectionCenter.明细导出') }}
        </el-button>
      </div>
      <div class="right">
        <div style="display: flex;justify-content: space-between;align-items: center">
          <div style="margin-right: 20px;display: flex;justify-content: space-between;align-items: center">
            <!-- 更新时间 -->
            <span v-show="updateTime">{{ $t('GlobalSetObj.UpdateTime') }}：{{ updateTime }}</span>
            <div class="dateBox">
              <span :class="{'d1': type == 0}" @click="get2Data(0)">{{ $t('collectionCenter.yesterday') }}</span><!-- 昨日 -->
              <span :class="{'d1': type == 1}" @click="get2Data(1)">{{ $t('collectionCenter.sameDay') }}</span><!-- 当天 -->
              <span :class="{'d1': type == 2}" @click="get2Data(2)">{{ $t('collectionCenter.nearlyAWeek') }}</span><!-- 近一周 -->
              <span :class="{'d1': type == 3}" @click="get2Data(3)">{{ $t('collectionCenter.sameMonth') }}</span><!-- 当月 -->
            </div>
          </div>
          <el-button
            type="primary" plain
            size="small"
            @click="closeWindow">
            {{ $t('collectionCenter.close') }}
          </el-button>
        </div>
      </div>
      <el-tabs v-model="activeName" @tab-click="tabClick">
        <el-tab-pane :label="$t('collectionCenter.unloadedVehicleDetailsGLP')" name="hz">
          <div>
            <el-table
              :data="tableData1"
              border
              show-summary
              :summary-method="getSummaries"
            >
              <el-table-column
                :label="$t('operationCenter.ind')"
                width="60px"
                align="center"
                fixed="left"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <!-- 车牌号 -->
              <el-table-column
                prop="vehicleNumber"
                :label="$t('GlobalSetObj.licensePlateNumber')"
                align="center"
              />
              <!-- 运营模式 -->
              <el-table-column
                prop="operationModeName"
                :label="$t('collectionCenter.operationMode')"
                align="center"
              />
              <!-- 装车总袋数 -->
              <el-table-column
                prop="loadBagCount"
                :label="$t('collectionCenter.totalNumberBagsLoaded')"
                align="center"
              >
                <template slot-scope="scope">
                  <div style="cursor: pointer;color: #409eff;" @click="toMX(scope.row, 1)">
                    {{ scope.row.loadBagCount }}
                  </div>
                </template>
              </el-table-column>
              <!-- 装车总票数 -->
              <el-table-column
                prop="loadTicketCount"
                :label="$t('collectionCenter.carLoadingVotes')"
                align="center"
              >
                <template slot-scope="scope">
                  <div style="cursor: pointer;color: #409eff;" @click="toMX(scope.row, 2)">
                    {{ scope.row.loadTicketCount }}
                  </div>
                </template>
              </el-table-column>
              <!-- 到达GLP时间 -->
              <el-table-column
                prop="arrivalGlpTime"
                :label="$t('collectionCenter.TimeOfArrivalAtGLP')"
                align="center"
              />
            </el-table>
          </div>
          <div class="right">
            <el-pagination
              layout="total, sizes, prev, pager, next, jumper"
              :current-page.sync="pageNumber"
              :page-sizes="[10, 20, 50, 100, 500, 1000]"
              :page-size.sync="pageSize"
              :total="total"
              background
              style="margin: 10px 0"
              @current-change="getDatePageMX"
              @size-change="getPageSizeMX"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="activeName === 'mx'" :label="tabsName" name="mx">
          <el-table
            :data="tableDataMX"
            border
            :max-height="mxHeight"
          >
            <el-table-column
              :label="$t('operationCenter.ind')"
              width="60px"
              align="center"
              fixed="left"
            >
              <template slot-scope="scope">
                <span>{{ (formMX.pageNumber - 1) * formMX.pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <!--袋号-->
            <el-table-column
              v-if="mxType === 1"
              prop="bagNumber"
              :label="this.$t('GlobalSetObj.bagNumber')"
              min-width="160"
              align="center"
            />
            <!--条形码-->
            <el-table-column
              v-if="mxType === 2"
              prop="scanNumber"
              :label="this.$t('collectionCenter.Barcode')"
              min-width="160"
              align="center"
            />
            <!--包裹号-->
            <el-table-column
              v-if="mxType === 2"
              prop="packageNumber"
              :label="this.$t('collectionCenter.parcelNum')"
              min-width="160"
              align="center"
            />
            <el-table-column
              v-for="(item, ind) in mxColumn"
              :key="ind"
              :prop="item.prop"
              :label="item.label"
              :min-width="item.width"
              :align="item.align"
            />
          </el-table>
          <div class="right">
            <el-pagination
              layout="total, sizes, prev, pager, next, jumper"
              :current-page.sync="formMX.pageNumber"
              :page-sizes="[10, 20, 50, 100, 500, 1000]"
              :page-size.sync="formMX.pageSize"
              :total="totalMX"
              background
              style="margin: 10px 0"
              @current-change="getDatePageMX"
              @size-change="getPageSizeMX"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';
<script>
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash-es';
import {
  getGlpNotUnloadVehicleDetailPage,
  getGlpNotUnloadBagDetailPage,
  getGlpNotUnloadOrderDetailPage,
  glpNotUnloadVehicleDetailExport,
  glpNotUnloadBagDetailExport,
  glpNotUnloadOrderDetailExport
} from '@/api/lanshou';
import { goExportList } from '@/utils/goExportList';
export default {
  name: 'GlpNotUnloaded',
  props: {
    timeForm: {
      type: Object,
      default: () => {
        return {
          startTime: '',
          endTime: '',
          mdStayType: '',
          timeType: '',
          updateTime: ''
        };
      }
    },
    tabName: {
      type: String,
      default: ''
    },
    dateType: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      activeName: 'hz',
      tableData1: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      clockRow: null,
      formMX: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '',
          endTime: '',
          collectPunctualType: '',
          punctualOrderType: '',
          latestBatchTime: ''
        }
      },
      totalMX: 0,
      tableDataMX: [],
      mxHeight: 600,
      tabsName: this.tabName,
      updateTime: this.timeForm.updateTime,
      type: this.dateType,
      currentTime: {},
      mxType: null,
      mxColumn: [
        // 装车扫描时间
        { label: this.$t('collectionCenter.loadingScanningTime'), prop: 'loadScanTime', align: 'center', width: 160 },
        // 装车网点
        { label: this.$t('collectionCenter.loadSiteIdList'), prop: 'loadSiteName', align: 'center', width: 160 },
        // 装车人
        { label: this.$t('collectionCenter.loader'), prop: 'loadUserName', align: 'center', width: 160 },
        // 卸车扫描时间
        { label: this.$t('collectionCenter.unloadingScanningTime'), prop: 'unloadScanTime', align: 'center', width: 160 },
        // 卸车网点
        { label: this.$t('collectionCenter.unloadDot'), prop: 'unloadSiteName', align: 'center', width: 160 },
        // 卸车人
        { label: this.$t('collectionCenter.unloader'), prop: 'unloadUserName', align: 'center', width: 160 }
      ]
    };
  },
  created() {
    this.mxHeight = document.body.clientHeight - 270;
    this.getData();
  },
  methods: {
    // 标签页切换
    tabClick(tab) {
      console.log(tab, 'tab');
      if (tab.name === 'hz') {
        this.pageNumber = 1;
        this.getData();
      } else if (tab.name === 'mx') {
        this.formMX.pageNumber = 1;
        if (this.mxType === 1) {
          this.getTableDatePageMX(); // 袋明细
        } else {
          this.getTableDatePageMXOrder(); // 票明细
        }
      }
    },
    // 导出
    exportData() {
      const data = cloneDeep(this.timeForm);
      if (this.activeName === 'hz') {
        const param = {
          startTime: this.currentTime.startTime || data.startTime,
          endTime: this.currentTime.endTime || data.endTime
        };
        glpNotUnloadVehicleDetailExport(param).then(res => {
          if (res.status === 'OK') {
            goExportList(this, '/views/resource/exportTaskList');
          }
        });
      } else {
        const param = {
          startTime: this.currentTime.startTime || data.startTime,
          endTime: this.currentTime.endTime || data.endTime,
          vehicleId: this.clockRow.vehicleId,
          latestBatchTime: data.updateTime
        };
        if (this.mxType === 1) {
          glpNotUnloadBagDetailExport(param).then(res => {
            if (res.status === 'OK') {
              goExportList(this, '/views/resource/exportTaskList');
            }
          });
        } else {
          glpNotUnloadOrderDetailExport(param).then(res => {
            if (res.status === 'OK') {
              goExportList(this, '/views/resource/exportTaskList');
            }
          });
        }
      }
    },
    // 日期切换
    get2Data(type) {
      this.type = type;
      const d = new Date();
      const day0 = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD');
      const day1 = dayjs(d).format('YYYY-MM-DD');
      const day2 = dayjs(d).subtract(6, 'day').format('YYYY-MM-DD');
      const day3 = dayjs(d).startOf('month').format('YYYY-MM-DD');
      let date = {};
      if (type === 0) {
        date = {
          startTime: day0,
          endTime: day0
        };
      }
      if (type === 1) {
        date = {
          startTime: day1,
          endTime: day1
        };
      }
      if (type === 2) {
        date = {
          startTime: day2,
          endTime: day1
        };
      }
      if (type === 3) {
        date = {
          startTime: day3,
          endTime: day1
        };
      }
      this.currentTime = {
        startTime: date.startTime,
        endTime: date.endTime
      };
      if (this.activeName === 'hz') {
        this.pageNumber = 1;
        this.getData();
      } else if (this.activeName === 'mx') {
        this.formMX.pageNumber = 1;
        if (this.mxType === 1) {
          this.getTableDatePageMX(); // 袋明细
        } else {
          this.getTableDatePageMXOrder(); // 票明细
        }
      }
    },
    // 计算总和
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.all');
          return;
        }
        if (
          column.label === this.$t('collectionCenter.totalNumberBagsLoaded') ||
          column.label === this.$t('collectionCenter.carLoadingVotes')
        ) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    },
    // 获取汇总数据
    getData() {
      const data = cloneDeep(this.timeForm);
      const param = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        params: {
          startTime: this.currentTime.startTime || data.startTime,
          endTime: this.currentTime.endTime || data.endTime
        }
      };
      getGlpNotUnloadVehicleDetailPage(param).then(res => {
        if (res.status === 'OK') {
          this.tableData1 = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 前往明细
    toMX(row, type) {
      this.mxType = type;
      this.clockRow = row;
      this.activeName = 'mx';
      this.formMX.pageNumber = 1;
      if (this.mxType === 1) {
        this.tabsName = this.$t('collectionCenter.unloadedBagDimensionGLP');
        this.getTableDatePageMX(); // 袋明细
      } else {
        this.tabsName = this.$t('collectionCenter.unloadedParcelDimensionGLP');
        this.getTableDatePageMXOrder(); // 包裹明细
      }
    },
    // 袋明细
    getTableDatePageMX() {
      const data = cloneDeep(this.timeForm);
      const dataParam = {
        pageNumber: this.formMX.pageNumber,
        pageSize: this.formMX.pageSize,
        params: {
          startTime: this.currentTime.startTime || data.startTime,
          endTime: this.currentTime.endTime || data.endTime,
          vehicleId: this.clockRow.vehicleId,
          latestBatchTime: data.updateTime
        }
      };
      getGlpNotUnloadBagDetailPage(dataParam).then(res => {
        if (res.status === 'OK') {
          this.totalMX = res.data.total;
          this.tableDataMX = res.data.records;
        }
      });
    },
    // 车票明细
    getTableDatePageMXOrder() {
      const data = cloneDeep(this.timeForm);
      const dataParam = {
        pageNumber: this.formMX.pageNumber,
        pageSize: this.formMX.pageSize,
        params: {
          startTime: this.currentTime.startTime || data.startTime,
          endTime: this.currentTime.endTime || data.endTime,
          vehicleId: this.clockRow.vehicleId,
          latestBatchTime: data.updateTime
        }
      };
      getGlpNotUnloadOrderDetailPage(dataParam).then(res => {
        if (res.status === 'OK') {
          this.totalMX = res.data.total;
          this.tableDataMX = res.data.records;
        }
      });
    },
    // 每页显示数量
    getPageSizeMX(val) {
      if (this.activeName === 'hz') {
        this.pageNumber = 1;
        this.pageSize = val;
        this.getData();
      } else {
        if (this.mxType === 1) {
          this.formMX.pageNumber = 1;
          this.formMX.pageSize = val;
          this.getTableDatePageMX();
        } else {
          this.formMX.pageNumber = 1;
          this.formMX.pageSize = val;
          this.getTableDatePageMXOrder();
        }
      }
    },
    // 当前页
    getDatePageMX(val) {
      if (this.activeName === 'hz') {
        this.pageNumber = val;
        this.getData();
      } else {
        if (this.mxType === 1) {
          this.formMX.pageNumber = val;
          this.getTableDatePageMX();
        } else {
          this.formMX.pageNumber = val;
          this.getTableDatePageMXOrder();
        }
      }
    },
    // 关闭
    closeWindow() {
      this.$emit('closeWindow');
    }
  }
};
</script>

<style lang="scss" scoped>
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dateBox{
  display: flex; justify-content: flex-start; align-items: center;
  margin-left: 5px;
  span{display: inline-block;border: 1px solid #5EBC7F;padding: 0 15px;box-sizing: border-box;cursor: pointer; text-align: center;color: #5EBC7F;}
  span:hover, span.d1:hover{background: #51d96b;color: #fff;}
  span.d1:hover{border: 1px solid #51D96B;box-sizing: border-box;}
  span.d1{background: linear-gradient(90deg, #51D96B, transparent);box-sizing: border-box;color: #009C37}
}
</style>
