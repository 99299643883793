import i18n from '@/lang/index.js';

// 索赔状态
export const STATUS = {
  0: {
    value: 0,
    type: 'primary',
    name: i18n.t('ClaimsManagement.ToBeSubmitted') // 待提交
  },
  1: {
    value: 1,
    type: 'warning',
    name: i18n.t('receivablePayable.ToBeReviewed') // 待审核
  },
  2: {
    value: 2,
    type: 'warning',
    name: i18n.t('ClaimsManagement.Rejected') // 已驳回
  },
  3: {
    value: 3,
    type: 'warning',
    name: i18n.t('ClaimsManagement.Due') // 待付款
  },
  4: {
    value: 4,
    type: 'success',
    name: i18n.t('collectionCenter.completed') // 已完成
  },
  5: {
    value: 5,
    type: 'danger',
    name: i18n.t('GlobalSetObj.Closed') // 已关闭
  },
  // 6: {
  //   value: 6,
  //   type: 'info',
  //   name: i18n.t('GlobalSetObj.edit') // 编辑
  // }
  7: {
    value: 7,
    type: 'primary',
    name: i18n.t('receivablePayable.付款中') // 付款中
  }
};

// 业务类型
export const claimNode = {
  // 第三方揽收
  4: {
    value: 4,
    name: i18n.t('compensationManagement.ThirdPartyCollection')
  },
  // AJD派送
  5: {
    value: 5,
    name: i18n.t('compensationManagement.AJDDispatch')
  },
  // DSP派送
  6: {
    value: 6,
    name: i18n.t('compensationManagement.DSPDispatch')
  },
  // 运输(干/支线)
  7: {
    value: 7,
    name: i18n.t('compensationManagement.Transportation_trunk_branch')
  }
};

// 支付状态
export const PAYSTATUS_STATUS = {
  1: {
    type: 'success',
    name: i18n.t('GlobalSetObj.paid'),
    value: 1
  },
  2: {
    type: 'warning',
    name: i18n.t('compensationManagement.Due'),
    value: 2
  },
  3: {
    type: 'danger',
    name: i18n.t('GlobalSetObj.Closed'),
    value: 3
  }
  // 4: {
  //   type: 'primary',
  //   name: i18n.t('compensationManagement.BillCredits'),
  //   value: 4
  // }
};

// 付款方式
export const PAYTYPE_LIST = {
  // 现金
  1: {
    name: i18n.t('basicData.cash'),
    value: 1
  },

  // 转账
  2: {
    name: i18n.t('basicData.transferable'),
    value: 2
  },

  // 网咯
  3: {
    name: i18n.t('basicData.online'),
    value: 3
  },
  // 冲销
  4: {
    name: i18n.t('basicData.Elimination'),
    value: 4
  },

  // 其他
  5: {
    name: i18n.t('basicData.other'),
    value: 5
  }
};

// 索赔类型
export const CLAIMS_TYPE = {
  1: {
    name: i18n.t('compensationManagement.TimeoutNotClosedLoop'), // 超时未关闭环
    value: 1
  },
  2: {
    name: i18n.t('compensationManagement.FalseSigningForDelivery'), // 虚假签收
    value: 2
  },
  3: {
    name: i18n.t('compensationManagement.TimeDelay'), // 时效延误
    value: 3
  },
  4: {
    name: i18n.t('compensationManagement.returnOfOvertimeWithoutClosingTheLoop'), // 超时未闭环退回
    value: 4
  }
};

// 订单状态 第三方揽收
export const ORDER_STATUS = {
  1: {
    name: i18n.t('collectionCenter.hasLanShou'),
    value: 1
  },
  2: {
    name: i18n.t('collectionCenter.cancelled'),
    value: 2
  },
  3: {
    name: i18n.t('collectionCenter.LanShouing'),
    value: 3
  },
  4: {
    name: i18n.t('collectionCenter.scheduled'),
    value: 4
  },
  5: {
    name: i18n.t('collectionCenter.notLanShou'),
    value: 5
  },
  6: {
    name: i18n.t('collectionCenter.haveBeenSigned'),
    value: 6
  },
  7: {
    name: i18n.t('collectionCenter.ProblemPiece'),
    value: 7
  },
  8: {
    name: i18n.t('collectionCenter.SealedBag'),
    value: 8
  },
  9: {
    name: i18n.t('collectionCenter.truckLoading'),
    value: 9
  },
  10: {
    name: i18n.t('collectionCenter.unloading'),
    value: 10
  },
  11: {
    name: i18n.t('collectionCenter.Bagged'),
    value: 11
  },
  12: {
    name: i18n.t('collectionCenter.closedLoop'),
    value: 12
  },
  13: {
    name: i18n.t('collectionCenter.unclaimed'),
    value: 13
  },
  14: {
    name: i18n.t('collectionCenter.collectWarehousing'),
    value: 14
  },
  100: {
    name: i18n.t('GlobalSetObj.Carregado'),
    value: 100
  },
  200: {
    name: i18n.t('GlobalSetObj.Descarregado'),
    value: 200
  }
};

// 订单状态 AJD、DSP、运输(干/支线)
export const P_ORDER_STATUS = {
  1: {
    name: i18n.t('newOrder.ToBeAssigned'),
    value: 1
  },
  2: {
    name: i18n.t('newOrder.ToBeCollected'),
    value: 2
  },
  3: {
    name: i18n.t('newOrder.Collected'),
    value: 3
  },
  4: {
    name: i18n.t('newOrder.CollectTheRevenuePool'),
    value: 4
  },
  5: {
    name: i18n.t('newOrder.CollectTakeOutWarehouse'),
    value: 5
  },
  6: {
    name: i18n.t('newOrder.TransshipmentIntoStorage'),
    value: 6
  },
  7: {
    name: i18n.t('newOrder.TransferOutWarehouse'),
    value: 7
  },
  8: {
    name: i18n.t('newOrder.DeliveryOutletsIntoStorage'),
    value: 8
  },
  9: {
    name: i18n.t('newOrder.Delivering'),
    value: 9
  },
  10: {
    name: i18n.t('newOrder.Signed'),
    value: 10
  },
  11: {
    name: i18n.t('newOrder.ProblemPiece'),
    value: 11
  },
  12: {
    name: i18n.t('compensationManagement.unclaimedItem'),
    value: 12
  },
  13: {
    name: i18n.t('collectionCenter.cancelled'),
    value: 13
  },
  14: {
    name: i18n.t('collectionCenter.FailureDeliver'),
    value: 14
  },
  15: {
    name: i18n.t('GlobalSetObj.Withdrawn'),
    value: 15
  },
  16: {
    name: i18n.t('GlobalSetObj.TheReturnComplete'),
    value: 16
  },
  17: {
    name: i18n.t('GlobalSetObj.DotBack'),
    value: 17
  },
  18: {
    name: i18n.t('GlobalSetObj.ReturnToStorage'),
    value: 18
  },
  19: {
    name: i18n.t('GlobalSetObj.moduan'),
    value: 19
  },
  20: {
    name: i18n.t('GlobalSetObj.mutilSign'),
    value: 20
  },
  21: {
    name: i18n.t('GlobalSetObj.processSettlement'),
    value: 21
  },
  22: {
    name: i18n.t('GlobalSetObj.ClaimsCompleted'),
    value: 22
  },
  100: {
    name: i18n.t('GlobalSetObj.financialProcessing'),
    value: 100
  },
  101: {
    name: i18n.t('GlobalSetObj.financialClosedLoop'),
    value: 101
  },
};
