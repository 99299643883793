var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("tableCmp", {
        ref: "tableCmp",
        attrs: {
          "column-list": _vm.columnList,
          "is-select-btn": _vm.isSelectBtn,
          "table-data": _vm.tableData,
          "table-type": 4,
        },
        on: { selectionChange: _vm.selectionChange },
      }),
      _c("el-pagination", {
        staticStyle: { margin: "20px 0", "text-align": "right" },
        attrs: {
          "current-page": _vm.pageNumber,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 50, 100, 200, 400],
          total: _vm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "update:currentPage": function ($event) {
            _vm.pageNumber = $event
          },
          "update:current-page": function ($event) {
            _vm.pageNumber = $event
          },
          "update:pageSize": function ($event) {
            _vm.pageSize = $event
          },
          "update:page-size": function ($event) {
            _vm.pageSize = $event
          },
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }