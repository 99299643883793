var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                disabled: !_vm.isAddDisabled,
              },
              on: {
                click: function ($event) {
                  _vm.isAdd = true
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.append")))]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "info",
                size: "small",
                disabled: !_vm.isAddDisabled,
              },
              on: {
                click: function ($event) {
                  _vm.isImport.isBool = true
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("CenterForSorting.Import")))]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "danger",
                size: "small",
                disabled: !_vm.multipleSelection.length,
              },
              on: { click: _vm.onRemove },
            },
            [_vm._v(_vm._s(_vm.$t("ClaimsManagement.Remove")))]
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("TipsCmp", {
                attrs: { len: _vm.len, "total-value": _vm.totalValue },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "virtual-scroll",
        {
          ref: "virtualScroll",
          attrs: {
            data: _vm.tableData,
            "item-size": 62,
            "key-prop": "keyCode",
          },
          on: {
            change: function (virtualList) {
              return (_vm.list = virtualList)
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                height: "350",
                "row-key": "keyCode",
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("virtual-column", {
                attrs: { width: "50", type: "selection" },
              }),
              _c("virtual-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.serial"),
                  width: "100",
                  type: "index",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.OperationSite"),
                  prop: "siteName",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.OrderNumber"),
                  prop: "barCode",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.customerNum"),
                  prop: "customerNumber",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("newOrder.CollectionType"),
                  prop: "collectTypeStr",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.bagWeight"),
                  prop: "totalDeclaredWeight",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("orderCenterCont.DeclaredValue"),
                  prop: "totalDeclaredValue",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("orderCenterCont.OrderStatus"),
                  prop: "statusStr",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.CurrentTrajectory"),
                  prop: "trajectory",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.TrackTime"),
                  prop: "trajectoryTime",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.remark"),
                  fixed: "right",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 2,
                            placeholder: _vm.$t(
                              "GlobalSetObj.pleaseInputContent"
                            ),
                            maxlength: "50",
                            "show-word-limit": "",
                          },
                          model: {
                            value: scope.row.remarks,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "remarks", $$v)
                            },
                            expression: "scope.row.remarks",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("orderCenterCont.AddOrder"),
            visible: _vm.isAdd,
            width: "500px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isAdd = $event
            },
          },
        },
        [
          _vm.isAdd
            ? _c("AddCmp", {
                attrs: { "basics-row": _vm.basicsRow },
                on: { close: _vm.onAddClick },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("CenterForSorting.Import"),
            visible: _vm.isImport.isBool,
            width: "500px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.isImport, "isBool", $event)
            },
          },
        },
        [
          _c("ImportExcelCmp", {
            attrs: {
              "import-tmp-url":
                "https://express-1313947203.cos.sa-saopaulo.myqcloud.com/upload-file/workOrder/%E7%90%86%E8%B5%94%E6%A8%A1%E6%9D%BF-20241225T172539.xlsx",
              "import-tmp-name": "理赔申请导入模板",
              "key-and-val-obj": _vm.excelKeyAndValue,
            },
            on: { change: _vm.onImportClick },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }