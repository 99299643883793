<template>
  <div class="app-container">
    <el-form inline label-position="top" size="small">
      <!--创建时间-->
      <el-form-item :label="$t('newOrder.createTime')">
        <el-date-picker
          v-model="timeValue"
          :clearable="true"
          :default-time="['00:00:00', '23:59:59']"
          :format="$i18n.locale=='zh'?zhDate:ptDate"
          :placeholder="$t('newOrder.SelectDatetime')"
          range-separator="~"
          style="width:450px"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>
      <!--   接收对象   -->
      <el-form-item :label="$t('GlobalSetObj.CreatePeople')">
        <el-select
          v-model="queryForm.params.createUser"
          :placeholder="$t('GlobalSetObj.pleaseChoose')"
          filterable
        >
          <el-option
            v-for="(item) in createrList"
            :key="item.id"
            :label="item.realName + '（' + item.username + '）'"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="" style="margin-top: 28px">
        <!--搜索-->
        <el-button icon="el-icon-search" type="primary" @click="enterSearch">{{ $t('newOrder.search') }}</el-button>
        <!--重置-->
        <el-button icon="el-icon-refresh-left" type="info" @click="resetForm()">{{ $t('newOrder.reset') }}</el-button>
        <el-button style="margin-bottom: 10px" type="primary" @click="newMessage">{{ $t('customerManagements.add') }}</el-button>
      </el-form-item>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :show-overflow-tooltip="true"
      border
      style="width: 100%"
    >
      <!--创建时间-->
      <el-table-column
        :label="$t('newOrder.createTime')"
        min-width="150"
        prop="createDate"
      />
      <!--系统-->
      <el-table-column
        :label="$t('GlobalSetObj.system')"
        min-width="110"
        prop="systemTypeData"
      />
      <!--用户端-->
      <el-table-column
        :label="$t('customerManagements.userSide')"
        min-width="90"
        prop="clientTypeData"
      />
      <!--是否弹框-->
      <el-table-column
        :label="$t('customerManagements.isTankuang')"
        min-width="100"
        prop="isPopStr"
      />
      <!--接收对象-->
      <el-table-column
        :label="$t('customerManagements.acceptObject')"
        min-width="150"
        prop="providerNames"
      />
      <!--发送次数-->
      <el-table-column
        :label="$t('customerManagements.SendCount')"
        min-width="150"
        prop="msgCount"
      />
      <!--标题-->
      <el-table-column
        :label="$t('customerManagements.headline')"
        min-width="180"
        prop="title"
      />
      <!--内容-->
      <el-table-column
        :label="$t('customerManagements.content')"
        min-width="250"
        prop="msgData"
      >
        <template slot-scope="scope">
          <!--          <el-tooltip :content="scope.row.msgData" class="item" effect="dark" placement="top-start">-->
          <!--            <div class="sle" v-html="scope.row.msgData" />-->
          <!--          </el-tooltip>-->
          <div class="sle" v-html="scope.row.msgData" />
        </template>
      </el-table-column>
      <!--创建人-->
      <el-table-column
        :label="$t('GlobalSetObj.CreatePeople')"
        min-width="160"
        prop="createName"
      />
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.number"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--  新增  -->
    <el-drawer
      ref="drawer"
      :before-close="handleClose"
      :title="$t('customerManagements.newcontent')"
      :visible.sync="isAddDialong"
      custom-class="demo-drawer"
      direction="ltr"
      size="900px"
    >
      <div class="demo-drawer__content">
        <el-form
          ref="sysInfoRuleForm"
          :model="sysInfoObj"
          :rules="sysInfoObjRules"
          label-position="top"
          label-width="auto"
          size="small"
          style="margin-bottom: 0"
        >
          <el-row :gutter="20">
            <el-col :span="24" style="margin-bottom: 30px">
              <!--系统-->
              <el-form-item :label="$t('GlobalSetObj.system')" prop="msgType" style="margin-bottom: 0">
                <el-radio-group v-model="sysInfoObj.systemType" @change="handleSystemType">
                  <el-radio :label="0">{{ $t('GlobalSetObj.all') }}</el-radio>
                  <el-radio :label="1">{{ $t('customerManagements.expressSystem') }}</el-radio>
                  <el-radio :label="2">{{ $t('customerManagements.providerSystem') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="margin-bottom: 30px">
              <!--用户端-->
              <el-form-item :label="$t('customerManagements.userSide')" prop="clientType" style="margin-bottom: 0">
                <el-radio-group v-model="sysInfoObj.clientType" @change="handleClientType">
                  <el-radio :label="0">{{ $t('GlobalSetObj.all') }}</el-radio>
                  <el-radio :label="1">{{ 'PC' }}</el-radio>
                  <el-radio :label="2">{{ 'APP' }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="margin-bottom: 30px">
              <!--是否弹框-->
              <el-form-item :label="$t('customerManagements.isTankuang')" prop="isPop" style="margin-bottom: 0">
                <el-radio-group v-model="sysInfoObj.isPop">
                  <el-radio :label="true">{{ $t('GlobalSetObj.yes') }}</el-radio>
                  <el-radio v-if="sysInfoObj.clientType === 1" :label="false">{{ $t('GlobalSetObj.No') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="margin-bottom: 30px">
              <!--虚假签收警告-->
              <el-form-item :label="$t('customerManagements.FalseSigningWarning')" prop="isWarning" style="margin-bottom: 0">
                <el-checkbox
                  v-model="sysInfoObj.isWarning"
                  :disabled="sysInfoObj.systemType != 2 || sysInfoObj.userList.length < 1"
                >{{ '' }}</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" style="margin-bottom: 30px">
              <!--服务商 / 网点-->
              <el-form-item :label="$t('customerManagements.providerSite')" style="margin-bottom: 0" class="providerSite">
                <el-cascader
                  v-if="sysInfoObj.systemType === 2"
                  v-model="sysInfoObj.providerId"
                  :disabled="sysInfoObj.systemType === 0"
                  :options="siteData"
                  :props="{ multiple: true, checkStrictly: true }"
                  clearable
                  filterable
                  size="small"
                  style="width: 100%;"
                  @change="handleObj"
                />
                <el-select
                  v-else
                  v-model="sysInfoObj.providerId"
                  :disabled="sysInfoObj.systemType === 0"
                  clearable
                  filterable
                  multiple
                  size="small"
                  style="width: 100%;"
                  @change="handleObj"
                >
                  <el-option
                    v-for="item in siteListData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" style="margin-bottom: 30px">
              <!--账号-->
              <el-form-item :label="$t('customerManagements.account')" style="margin-bottom: 0">
                <el-select
                  v-model.trim="sysInfoObj.userList"
                  :disabled="isDisable || sysInfoObj.systemType === 0"
                  :placeholder="$t('GlobalSetObj.Only10AreDisplayed')"
                  :remote-method="getAllUserList"
                  clearable
                  filterable
                  multiple
                  remote
                  reserve-keyword
                  style="width: 100%;"
                  @change="getAllUserList('')"
                >
                  <el-option
                    v-for="(item) in accountList"
                    :key="item.id"
                    :label="item.username"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" style="margin-bottom: 30px">
              <!--标题-->
              <el-form-item :label="$t('customerManagements.headline')" prop="title" style="margin-bottom: 10px;">
                <el-input
                  v-model="sysInfoObj.title"
                  :placeholder="$t('orderDetails.pleaseInput')"
                  clearable
                  maxlength="200"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <!--内容-->
              <el-form-item :label="$t('customerManagements.content')" prop="msgData" style="margin-bottom: 0">
                <quillEditor
                  ref="myQuilEdit"
                  v-model="sysInfoObj.msgData"
                  :options="editorOption"
                  style="height: 350px"
                  @input="onEditorInput($event)"
                />
                <div
                  style="float: right;right: 10px;"
                >
                  {{ wordCount }}/{{ maxWordCount }}
                </div>
              </el-form-item>
            </el-col>
<!--            <el-col :span="24">-->
<!--              <el-form-item>-->
<!--                <div style="margin-top: 50px;text-align: right;">-->
<!--                  <el-button size="small" @click="cancelForm">{{ $t('GlobalSetObj.cancel') }}</el-button>-->
<!--                  <el-button size="small" type="primary" @click="confirmDialog">{{-->
<!--                    $t('GlobalSetObj.determine')-->
<!--                  }}-->
<!--                  </el-button>-->
<!--                </div>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
        </el-form>
      </div>
      <div style="padding: 10px 20px;text-align: right;border-top: 1px solid #ccc;">
        <el-button size="small" @click="cancelForm">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="confirmDialog">{{ $t('GlobalSetObj.determine') }}</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getUserList, selectProviderMessage, sendMessage } from '@/api/orderApi';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css';
import { getUserPage } from '@/api/user';
import { apiSearchProviderSite } from '@/api/automaticSorting';
import { getAllsite } from '@/api/logisticsOrders'; // for bubble theme
import dayjs from 'dayjs';

export default {
  name: 'OrderPush1',
  components: {
    quillEditor
  },
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0,
        params: {
          createUser: null
        }
      },
      tableData: [],
      sysInfoObj: {
        msgType: 'TZ', // 更新提示
        providerId: [], // 投诉供应商
        isPop: true, // 是否弹框
        isWarning: false, // 虚假签收警告
        title: '', // 消息标题
        msgData: '', // 消息内容
        systemType: 0,
        clientType: 0,
        userList: []
      },
      sysInfoObjRules: {
        title: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' }
        ],
        msgData: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'blur' }
        ]
      },
      isAddDialong: false,
      editorOption: {
        placeholder: this.$t('GlobalSetObj.pleaseInput'),
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
            [{ 'list': 'ordered' }, { 'list': 'bullet' }], // 有序列表和无序列表
            [{ 'align': [] }], // 对齐方式：左对齐，居中，右对齐，两端对齐
            [{ 'size': [false, 'large', 'huge'] }], // 字体大小
            [{ 'color': [] }] // 字体颜色选择器
          ]
        }
      },
      createrList: [],
      siteData: [],
      providerList: [],
      siteList: [],
      siteListData: [],
      accountList: [],
      isDisable: false,
      wordCount: 0,
      maxWordCount: 3000
    };
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getTimer();
    this.getAllSite();
    this.getSupperSite();
    this.getUserManageOption();
    this.getSearch();
  },
  methods: {
    // 切换对象
    async handleObj(val) {
      this.providerList = [];
      this.siteList = [];
      if (this.sysInfoObj.systemType === 1) {
        this.siteList = val;
        if (this.siteList.includes(-1)) {
          this.sysInfoObj.providerId = [-1];
          this.isDisable = true;
          this.siteList = [-1];
        } else {
          this.isDisable = false;
        }
      } else {
        const providreId = [];
        const siteId = [];
        val.forEach(item => {
          if (item.length === 1) {
            providreId.push(item[0]);
          }
        });
        val.forEach(item => {
          if (item.length === 2) {
            if (providreId.includes(item[0])) {
              console.log('重复了供应商');
            } else {
              siteId.push(item[1]);
            }
          }
        });
        if (providreId.includes(-1)) {
          this.sysInfoObj.providerId = [-1];
          this.providerList = [-1];
          this.isDisable = true;
          this.siteList = [];
        } else {
          this.isDisable = false;
          this.providerList = providreId;
          this.siteList = siteId;
        }
      }
      const data = {
        'providerList': this.providerList,
        'siteList': this.siteList,
        'systemType': this.sysInfoObj.systemType
      };
      this.sysInfoObj.userList = [];
      const res = await getUserList(data);
      if (res.code === 200) {
        if (res.data.users) {
          this.accountList = res.data.users;
        }
        if (res.data.pmUsers) {
          this.accountList = res.data.pmUsers;
        }
      }
    },
    enterSearch() {
      this.queryForm.number = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.number = 1;
      this.getTimer();
      this.queryForm.params = {
        createUser: null
      };
      this.getSearch();
    },
    cancelForm() {
      this.isAddDialong = false;
    },
    newMessage() {
      this.isAddDialong = true;
      this.handleSystemType(1);
    },
    async getAllUserList(fuzzyFind = '') {
      const data = {
        'providerList': this.providerList,
        'siteList': this.siteList,
        'systemType': this.sysInfoObj.systemType,
        fuzzyFind: fuzzyFind
      };
      if (this.sysInfoObj.userList.length === 0) {
        this.sysInfoObj.isWarning = false;
      }
      const res = await getUserList(data);
      if (res.code === 200) {
        if (res.data.users) {
          this.accountList = res.data.users;
        }
        if (res.data.pmUsers) {
          this.accountList = res.data.pmUsers;
        }
      }
    },
    // 切换消息类型
    async handleSystemType(val) {
      this.sysInfoObj.userList = [];
      this.sysInfoObj.providerId = [];
      this.sysInfoObj.clientType = 0;
      this.sysInfoObj.isWarning = false;
      const data = {
        'providerList': this.providerList,
        'siteList': this.siteList,
        'systemType': val
      };

      const res = await getUserList(data);
      if (res.code === 200) {
        if (res.data.users) {
          this.accountList = res.data.users;
        }
        if (res.data.pmUsers) {
          this.accountList = res.data.pmUsers;
        }
      }
    },
    onEditorInput() {
      const that = this;
      const _myQuillEditor7 = this.$refs.myQuilEdit;
      this.wordCount = _myQuillEditor7.quill.getText().length - 1;

      if (that.wordCount > that.maxWordCount) {
        this.$message.error(this.$t('GlobalSetObj.not3000str'));
        const _text = _myQuillEditor7.quill
          .getText()
          .slice(0, that.maxWordCount);
        this.$refs.myQuilEdit.quill.setText(_text);
      }
    },
    async confirmDialog() {
      this.$refs['sysInfoRuleForm'].validate((valid) => {
        if (valid) {
          if (this.wordCount > this.maxWordCount) {
            this.$message.error(this.$t('GlobalSetObj.not200str'));
          } else {
            const messageUserList = [];
            if (this.sysInfoObj.providerId.length) {
              this.sysInfoObj.providerId.forEach(item => {
                messageUserList.push(item);
              });
            }
            // 定义图片数据的正则表达式，以'data:image'开头
            const imageDataRegex = /data:image/;
            if (imageDataRegex.test(this.sysInfoObj.msgData)) {
              this.$message.error(this.$t('GlobalSetObj.notInputImage'));
              return;
            }
            // return;
            const data = {
              providerList: this.providerList,
              siteList: this.siteList,
              messageBody: {
                msgType: this.sysInfoObj.msgType,
                title: this.sysInfoObj.title,
                msgData: this.sysInfoObj.msgData,
                isPop: this.sysInfoObj.isPop,
                isWarning: this.sysInfoObj.isWarning
              },
              systemType: this.sysInfoObj.systemType,
              clientType: this.sysInfoObj.clientType,
              userList: this.sysInfoObj.userList
            };
            sendMessage(data).then(res => {
              if (res.code===200) {
                this.isAddDialong = false;
                this.clearForm();
                this.getSearch();
                this.bus.$emit('getMessage');
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.clearForm();
      this.isAddDialong = false;
    },
    // 查询
    getSearch() {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.number;
      data.params = { ...this.queryForm.params };
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.createStartTime = this.timeValue[0];
        data.params.createEndTime = this.timeValue[1];
      }
      selectProviderMessage(data).then(res => {
        if (res.code===200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.number = val;
      this.getSearch();
    },
    // 清空表单
    clearForm() {
      this.$refs['sysInfoRuleForm'].resetFields();
      this.sysInfoObj = {
        msgType: 'TZ', // 更新提示
        providerId: [], // 投诉供应商
        isPop: true, // 是否弹框
        isWarning: false, // 虚假签收警告
        title: '', // 消息标题
        msgData: '', // 消息内容
        systemType: 0,
        clientType: 0,
        userList: []
      };
      this.isDisable = false;
    },
    // 获取最近一星期时间
    getTimer() {
      const today = dayjs().format('YYYY-MM-DD');
      const oneWeekAgo = dayjs().subtract(1, 'week').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    // 获取对应的供应商下的网点
    getSupperSite() {
      apiSearchProviderSite().then(res => {
        this.siteData = [];
        if (res.code===200) {
          res.data.forEach((item) => {
            item.label = item.providerName;
            item.value = item.id;
            if (item.siteList && item.siteList.length > 0) {
              item.siteList.forEach(item => {
                item.label = item.name;
                item.value = item.id;
              });
              item.children = item.siteList;
            }
          });
          this.siteData = [...res.data];
          this.siteData.unshift({
            label: this.$t('GlobalSetObj.all'),
            value: -1
          });
        }
      });
    },
    handleClientType(val) {
      if (val !== 1) {
        this.sysInfoObj.isPop = true;
      }
    },
    // 获取所有启用网点
    getAllSite() {
      this.siteListData = [];
      getAllsite().then(res => {
        if (res.code===200) {
          this.siteListData = res.data;
          this.siteListData.unshift({
            name: this.$t('GlobalSetObj.all'),
            id: -1
          });
        }
      });
    },
    // 获取入库人选项
    getUserManageOption() {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          accountState: '1'
        }
      };
      getUserPage(param).then((response) => {
        if (response.code===200) {
          const { records } = response.data;
          this.createrList = records;
        }
      });
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-dialog__body {
  padding: 0 20px 20px 20px;
}

.addInfo >>> .el-form-item {
  margin-bottom: 0;
}

.demo-drawer__content {
  height: calc(100vh - 120px);
  padding: 20px 20px 40px 20px;
  overflow-y: auto;
  position: relative;
}

.app-container /deep/ .el-drawer__header {
  margin-bottom: 0;
  color: #000000;
}

.sle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.demo-drawer__footer {
  position: absolute;
  display: flex;
  justify-content: right;
  padding: 10px;
  right: 10px;
  bottom: 10px;
}
</style>
<!--<style>-->
<!--.providerSite .el-input&#45;&#45;small {-->
<!--  height: 32px!important;-->
<!--}-->
<!--.providerSite .el-input__inner {-->
<!--  height: 32px!important;-->
<!--}-->
<!--</style>-->
