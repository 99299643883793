<template>
  <div class="Box">
    <div style="margin-bottom: 20px;">
      <el-form>
        <el-row
          type="flex" justify="start" style="flex-wrap: wrap;"
          class="selectClass"
        >
          <el-col class="col2">
            <el-select
              v-model="selectTime" :placeholder="$t('collectionCenter.pleaseChoose')" class="autoWidth"
              @change="changeTime"
            >
              <template slot="prefix">
                {{ (timeList.find(item => item.value === selectTime) || {}).time }}
              </template>
              <el-option
                v-for="(item, index) in timeList"
                :key="index"
                :label="item.time"
                :value="item.value"
              />
            </el-select>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <el-form-item>
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                :end-placeholder="$t('collectionCenter.EndTime')"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <el-row>
              <el-col class="col2">
                <el-select
                  v-model="changeNumber"
                  class="selectNumberClass autoWidth"
                  :placeholder="$t('collectionCenter.pleaseChoose')"
                  @change="changeNumberClick"
                >
                  <template slot="prefix">
                    {{ (numberList.find(item => item.value === changeNumber) || {}).label }}
                  </template>
                  <el-option
                    v-for="(item, index) in numberList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col v-show="changeNumber == 'num0'" style="width: auto;">
                <!-- 运单号： -->
                <el-form-item>
                  <div class="MaxHeight">
                    <el-input
                      v-model="queryForm.params.waybillNumber"
                      type="textarea"
                      autosize
                      :placeholder="$t('collectionCenter.BatchQuery')"
                      @blur="isNumber"
                      @keydown.enter.native="waybillNumberEnter"
                    /><!-- 支持批量查询(回车换行分割) -->
                  </div>
                </el-form-item>
              </el-col>
              <el-col v-show="changeNumber == 'num1'" style="width: auto;">
                <!-- 客户订单号： -->
                <el-form-item>
                  <div class="MaxHeight">
                    <el-input
                      v-model="queryForm.params.customerNumber"
                      type="textarea"
                      autosize
                      :placeholder="$t('collectionCenter.BatchQuery')"
                      @blur="isNumber"
                      @keydown.enter.native="customerOrderNumberEnter"
                    /><!-- 支持批量查询(回车换行分割) -->
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <el-col class="col2">
              <el-select
                v-model="changeSite"
                class="selectNumberClass autoWidth"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                @change="changeSiteClick"
              >
                <template slot="prefix">
                  {{ (siteChooseList.find(item => item.value === changeSite) || {}).label }}
                </template>
                <el-option
                  v-for="(item, index) in siteChooseList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-col>
            <el-col style="width: auto;">
              <el-select
                v-model="siteId" :placeholder="$t('operationCenter.PleaseSelect')" clearable
                filterable @focus="getSiteList" @change="putSite"
              >
                <el-option
                  v-for="(item, index) in siteIdList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <el-row>
      <el-col :span="24">
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="searchClick"
        >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
        <el-button
          icon="el-icon-refresh"
          size="mini"
          @click="resetClick"
        >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
        <el-button
          icon="el-icon-printer"
          size="mini"
          @click="print"
        >{{ $t('collectionCenter.Print') }}</el-button><!-- 打印 -->
        <el-button
          icon="el-icon-upload"
          size="mini"
          @click="downloadAll"
        >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
        <el-button
          icon="el-icon-upload2"
          size="mini"
          @click="download"
        >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 -->
      </el-col>
    </el-row>
    <el-divider />
    <el-table
      ref="tableRef"
      border
      :data="tableDate"
      :max-height="600"
      @select="batchSelect"
      @select-all="batchSelectAll"
    >
      <el-table-column
        type="selection"
        width="55"
      />
      <!-- 序号 -->
      <el-table-column
        :label="$t('collectionCenter.ind')" type="index" width="60"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="waybillNumber"
        align="center"
        :label="$t('collectionCenter.waybillNumber')"
      /><!-- 运单号 -->
      <el-table-column
        prop="customerNumber"
        align="center"
        :label="$t('collectionCenter.customerNum')"
      /><!-- 客户单号 -->
      <el-table-column
        prop="registerTime"
        align="center"
        :label="$t('collectionCenter.RegistrationT')"
      /><!-- 登记时间 -->
      <el-table-column
        prop="registerUserName"
        align="center"
        :label="$t('collectionCenter.registrar')"
      /><!-- 登记人 -->
      <el-table-column
        prop="registerSiteName"
        align="center"
        :label="$t('collectionCenter.registrationNetwork')"
      /><!-- 登记网点 -->
      <el-table-column
        prop="returnSiteName"
        align="center"
        :label="$t('collectionCenter.returnSite')"
      /><!-- 退回网点 -->
      <el-table-column
        prop="signTime"
        align="center"
        :label="$t('collectionCenter.signT')"
      /><!-- 签收时间 -->
      <el-table-column
        prop="signUserUserName"
        align="center"
        :label="$t('collectionCenter.signer')"
      /><!-- 签收人 -->
      <el-table-column
        prop="signSiteName"
        align="center"
        :label="$t('collectionCenter.signSite')"
      /><!-- 签收网点 -->
      <el-table-column
        prop="printsNumber"
        align="center"
        :label="$t('collectionCenter.printNumber')"
      /><!-- 打印次数 -->
      <el-table-column
        :label="$t('collectionCenter.operation')"
        fixed="right"
        width="160"
        align="center"
      ><!-- 操作 -->
        <template slot-scope="scope">
          <!-- 打印详情 -->
          <el-button
            style="white-space: normal;"
            size="mini"
            @click="cellClick(scope.row)"
          >{{ $t('collectionCenter.printDetails') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getTablePage"
        @size-change="getPageSize"
      />
    </div>

    <!--  打印详情  -->
    <div class="printClass">
      <el-drawer
        :title="$t('collectionCenter.printDetails')"
        :visible.sync="printDrawer"
        :direction="direction"
        size="60%"
      >
        <div class="printDetails-p">
          <!-- 运单号 -->
          <p>{{ $t('operatingPlatform.waybillNumber') }}：{{ printTopNum }}</p>

          <el-table
            ref="printTableRef"
            border
            :data="printTableDate"
            :max-height="600"
          >
            <el-table-column
              prop="createTime"
              align="center"
              :label="$t('collectionCenter.printTime')"
            /><!-- 打印时间 -->
            <!-- <el-table-column
              prop="printsNumber"
              align="center"
              :label="$t('collectionCenter.printNumber')"
            />打印次数 -->
            <el-table-column
              prop="createUserName"
              align="center"
              :label="$t('collectionCenter.printer')"
            /><!-- 打印人 -->
            <el-table-column
              prop="siteName"
              align="center"
              :label="$t('collectionCenter.printDot')"
            /><!-- 打印网点 -->
          </el-table>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {
  searchSiteIdList,
  waybillReturnPage,
  waybillReturnExport,
  waybillReturnExportAll,
  waybillReturnPrintList,
  printout
} from '@/api/lanshou';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

export default {
  data() {
    return {
      printTopNum: '',
      printTableDate: [],
      printDrawer: false,
      direction: 'ltr',
      selectTime: 'r0', // 选择退件登记时间 / 退件签收时间
      timeList: [
        { value: 'r0', time: this.$t('collectionCenter.returnRegistrationTime') }, // '退件登记时间'
        { value: 'r1', time: this.$t('collectionCenter.returnReceiptTime') } // '退件签收时间'
      ],
      changeNumber: 'num0',
      numberList: [
        { value: 'num0', label: this.$t('collectionCenter.waybillNumber') }, // '运单号'
        { value: 'num1', label: this.$t('collectionCenter.customerOrderNum') } // '客户订单号'
      ],
      changeSite: 's0',
      siteChooseList: [
        { value: 's0', label: this.$t('collectionCenter.returnRegistrationPoint') }, // '退件登记网点'
        { value: 's1', label: this.$t('collectionCenter.returnDignatureNetwork') } // '退件签收网点'
      ],
      timeValue: [],
      siteIdList: [],
      siteId: '', // 统一赋值网点参数
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          registrationStartTime: '', // 退件登记开始时间
          registrationEndTime: '', // 退件登记结束时间
          signStartTime: '', // 退件签收开始时间
          signEndTime: '', // 退件签收结束时间
          waybillNumber: '', // 运单号
          customerNumber: '', // 客户单号
          registrationSiteId: '', // 退件登记网点
          returnSignSiteId: '' // 退件签收网点
        }
      },
      total: 0,
      tableDate: [],
      idList: []
    };
  },
  watch: {
    'queryForm.params.waybillNumber'(val) {
      if (!val) return;
      const vL = val.split('\n');
      let NumL = [];
      if (vL.length >= 1000) {
        NumL = vL.slice(0, 1000).join('\n');
        this.queryForm.params.waybillNumber = NumL;
      }
      if (vL.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'queryForm.params.customerNumber'(val) {
      if (!val) return;
      const cvl = val.split('\n');
      let cNumL = [];
      if (cvl.length >= 1000) {
        cNumL = cvl.slice(0, 1000).join('\n');
        this.queryForm.params.customerNumber = cNumL;
      }
      if (cvl.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    }
  },
  created() {
    this.default();
    this.getSiteList();
    this.searchClick();
  },
  methods: {
    getSiteList() {
      searchSiteIdList().then(res => {
        if (res.code===200) {
          this.siteIdList = [];
          res.data.forEach(data => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    changeTime() {
      const d = new Date();
      const day = dayjs(d).subtract(1, 'week').format('YYYY-MM-DD 00:00:00');
      const today = dayjs(d).format('YYYY-MM-DD 23:59:59');
      this.timeValue = [day, today];

      if (this.selectTime === 'r0') {
        this.queryForm.params.registrationStartTime = day;
        this.queryForm.params.registrationEndTime = today;
        this.queryForm.params.signStartTime = '';
        this.queryForm.params.signEndTime = '';
      }
      if (this.selectTime === 'r1') {
        this.queryForm.params.signStartTime = day;
        this.queryForm.params.signEndTime = today;
        this.queryForm.params.registrationStartTime = '';
        this.queryForm.params.registrationEndTime = '';
      }
    },
    dateChange(val) {
      if (!val || val === null) {
        this.timeValue = [];
        this.queryForm.params.registrationStartTime = '';
        this.queryForm.params.registrationEndTime = '';
        this.queryForm.params.signStartTime = '';
        this.queryForm.params.signEndTime = '';
      }
      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-1, 'year').format('YYYY-MM-DD') + ' 23:59:59';
        if (val[1] > endTime) {
          this.$message({
            // 最多可查询到一年的数据
            message: this.$t('collectionCenter.moreYear'),
            type: 'warning'
          });
          const d = new Date();
          const day = dayjs(d).subtract(1, 'week').format('YYYY-MM-DD 00:00:00');
          const today = dayjs(d).format('YYYY-MM-DD 23:59:59');
          this.timeValue = [day, today];
          if (this.selectTime === 'r0') {
            this.queryForm.params.registrationStartTime = day;
            this.queryForm.params.registrationEndTime = today;
            this.queryForm.params.signStartTime = '';
            this.queryForm.params.signEndTime = '';
          }
          if (this.selectTime === 'r1') {
            this.queryForm.params.signStartTime = day;
            this.queryForm.params.signEndTime = today;
            this.queryForm.params.registrationStartTime = '';
            this.queryForm.params.registrationEndTime = '';
          }
        }
      }
    },
    changeNumberClick() {
      this.queryForm.params.waybillNumber = '';
      this.queryForm.params.customerNumber = '';
    },

    isNumber() {
      /**
       *  单号去除空值
       */
      if (this.changeNumber === 'num0') {
        let waybillNumberList = this.queryForm.params.waybillNumber.split('\n');
        waybillNumberList = waybillNumberList.filter(s => {
          return s && s.trim();
        });
        waybillNumberList = waybillNumberList.map(arr => {
          const ss = arr.replace(/\s/g, '');
          return ss;
        });
        this.queryForm.params.waybillNumber = waybillNumberList.join('\n');
      }
      if (this.changeNumber === 'num1') {
        let customerNumberList = this.queryForm.params.customerNumber.split('\n');
        customerNumberList = customerNumberList.filter(s => {
          return s && s.trim();
        });
        customerNumberList = customerNumberList.map(arr => {
          const cc = arr.replace(/\s/g, '');
          return cc;
        });
        this.queryForm.params.customerNumber = customerNumberList.join('\n');
      }
    },
    // 运单号——回车校验
    waybillNumberEnter() {
      const e1 = window.event || arguments[0];
      const numberLen1 = this.queryForm.params.waybillNumber.split('\n');
      if (numberLen1.length >= 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        e1.returnValue = false;
      }
      return true;
    },
    // 客户订单号——回车校验
    customerOrderNumberEnter() {
      const e2 = window.event || arguments[0];
      const numberLen2 = this.queryForm.params.customerNumber.split('\n');
      if (numberLen2.length >= 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        e2.returnValue = false;
      }
      return true;
    },
    changeSiteClick() {
      this.siteId = '';
      this.queryForm.params.registrationSiteId = '';
      this.queryForm.params.returnSignSiteId = '';
    },
    putSite() {
      this.queryForm.params.registrationSiteId = '';
      this.queryForm.params.returnSignSiteId = '';
      if (this.changeSite === 's0') {
        this.queryForm.params.registrationSiteId = this.siteId;
      }
      if (this.changeSite === 's1') {
        this.queryForm.params.returnSignSiteId = this.siteId;
      }
    },

    cellClick(row) {
      this.printTopNum = '';
      this.printTableDate = [];
      // 打印详情
      waybillReturnPrintList(row.id).then(res => {
        if (res.code===200) {
          this.printTopNum = row.waybillNumber;
          this.printDrawer = true;
          this.printTableDate = res.data;
        }
      }).catch({});
    },

    default() {
      const d = new Date();
      const day = dayjs(d).subtract(1, 'week').format('YYYY-MM-DD 00:00:00');
      const today = dayjs(d).format('YYYY-MM-DD 23:59:59');
      this.selectTime = 'r0';
      this.timeValue = [day, today];
      this.siteId = '';
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          registrationStartTime: day, // 退件登记开始时间
          registrationEndTime: today, // 退件登记结束时间
          signStartTime: '', // 退件签收开始时间
          signEndTime: '', // 退件签收结束时间
          waybillNumber: '', // 运单号
          customerNumber: '', // 客户单号
          registrationSiteId: '', // 退件登记网点
          returnSignSiteId: '' // 退件签收网点
        }
      };
    },
    resetClick() {
      this.default();
    },
    searchClick() {
      if (!this.timeValue && !this.queryForm.params.waybillNumber && !this.queryForm.params.customerNumber) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      this.queryForm.pageNumber = 1;
      localStorage.setItem('returnQueryForm', JSON.stringify(this.queryForm));
      this.getTablePage();
    },
    getTablePage() {
      this.idList = [];
      const queryForm = JSON.parse(localStorage.getItem('returnQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      queryForm.params.waybillNumber = queryForm.params.waybillNumber ? queryForm.params.waybillNumber.split('\n') : [];
      queryForm.params.customerNumber = queryForm.params.customerNumber ? queryForm.params.customerNumber.split('\n') : [];
      waybillReturnPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    getPageSize() {
      this.idList = [];
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('returnQueryForm')) || this.queryForm;
      query.pageNumber = 1;
      query.pageSize = this.queryForm.pageSize;
      query.params.waybillNumber = query.params.waybillNumber ? query.params.waybillNumber.split('\n') : [];
      query.params.customerNumber = query.params.customerNumber ? query.params.customerNumber.split('\n') : [];
      waybillReturnPage(query).then(res => {
        if (res.code===200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    selectRow(selection) {
      const idList = [];
      selection.forEach(list => {
        idList.push(list.id);
      });
      this.idList = idList;
    },
    batchSelect(selection) {
      this.selectRow(selection);
    },
    batchSelectAll(selection) {
      this.selectRow(selection);
    },
    download() {
      if (this.idList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
      } else {
        const idForm = {
          idList: this.idList
        };
        waybillReturnExport(idForm).then(res => {
          if (res.code===200) {
            this.idList = [];
            this.$refs.tableRef.clearSelection();
            goExportList(this);
          }
        }).catch(err => {
          console.log(err);
        });
      }
    },
    downloadAll() {
      const queryForm = JSON.parse(localStorage.getItem('returnQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      queryForm.params.waybillNumber = queryForm.params.waybillNumber ? queryForm.params.waybillNumber.split('\n') : [];
      queryForm.params.customerNumber = queryForm.params.customerNumber ? queryForm.params.customerNumber.split('\n') : [];
      waybillReturnExportAll(queryForm).then(res => {
        goExportList(this);
      }).catch({});
    },
    print() {
      if (this.idList.length <= 0) {
        // '请勾选表格中的数据'
        this.$message.warning(this.$t('GlobalSetObj.CheckForm'));
      } else {
        const idForm = {
          ids: this.idList
        };
        printout(idForm).then(res => {
          if (res.code===200) {
            res.data.forEach(arr => {
              this.$nextTick(() => {
                window.open(arr);
              });
            });
          }
        }).catch({});
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .printDetails-p{
    margin: 0 20px 20px 20px;
    p{padding: 20px 0;margin: 0; box-sizing: border-box;}
  }
  .printClass ::v-deep .el-drawer__header{
    margin-bottom: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebeef5;
    box-sizing: border-box;
  }
  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .selectClass ::v-deep .el-select{
    width: 100%;
  }
  .col2 {
    width: auto !important;
  }
  .autoWidth ::v-deep .el-input--prefix .el-input__inner{
    padding-left: 0 !important;
  }
  .autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
    position: relative;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    visibility: hidden;
    white-space: nowrap;
  }
  .autoWidth ::v-deep input {
    position: absolute;
  }
  .autoWidth ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
    padding-left: 0;
    box-sizing: border-box;
  }
  .MaxHeight ::v-deep textarea.el-textarea__inner {
    max-height: 150px !important;
  }
</style>
