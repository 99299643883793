<template>
  <div class="app-container">
    <div style="position: relative; min-height: 40px">
      <el-button
        v-if="queryBoxShow"
        class="putAway"
        size="small"
        @click="queryBoxShow = !queryBoxShow"
      >
        {{ $t('userManage.CollapseQuery') }}
      </el-button>
      <el-button
        v-else class="putAway" size="small"
        @click="queryBoxShow = !queryBoxShow"
      >
        {{ $t('userManage.ExpandQuery') }}
      </el-button>
      <div v-show="queryBoxShow" class="searchContainer">
        <el-form ref="queryFormRef" :model="queryForm" label-position="top">
          <el-row :gutter="10">
            <el-col :span="6" style="width: auto">
              <!-- 名称： -->
              <el-form-item :label="$t('userManage.realName')">
                <el-input
                  v-model.trim="queryForm.params.realName"
                  :placeholder="$t('userManage.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="6" style="width: auto">
              <!-- 账户名： -->
              <el-form-item :label="$t('userManage.username')">
                <el-input
                  v-model.trim="queryForm.params.username"
                  :placeholder="$t('userManage.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="6" style="width: auto">
              <!-- 电话： -->
              <el-form-item :label="$t('userManage.phone')">
                <el-input
                  v-model.trim="queryForm.params.phone"
                  :placeholder="$t('userManage.pleaseInput')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="6" style="width: auto">
              <!-- 角色： -->
              <el-form-item :label="$t('userManage.roleId')">
                <el-select
                  v-model="queryForm.params.roleId"
                  :placeholder="$t('userManage.pleaseChoose')"
                  clearable
                  filterable
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, index) in jsRolesList"
                    :key="index"
                    :label="item.roleName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="width: auto">
              <!-- 所属网点： -->
              <el-form-item :label="$t('collectionCenter.owningSite')">
                <el-select
                  v-model="queryForm.params.siteId"
                  :placeholder="$t('collectionCenter.pleaseChoose')"
                  clearable
                  filterable
                  @focus="getSiteList"
                >
                  <el-option
                    v-for="(item, index) in siteIdList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="width: auto">
              <!-- 所属部门： -->
              <el-form-item :label="$t('userManage.organizationName') + '：'">
                <div @click.stop>
                  <el-cascader
                    ref="cascader"
                    v-model="orgId"
                    :options="organizationTree"
                    :props="organizationTreeProps"
                    :show-all-levels="false"
                    clearable
                    filterable
                    @change="organizationTreeChange"
                  />
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="width: auto">
              <!-- 状态： -->
              <el-form-item :label="$t('userManage.accountState')" prop="accountState">
                <el-radio-group
                  v-model="queryForm.params.accountState"
                  size="mini"
                  @change="search"
                >
                  <!-- 全部 -->
                  <el-radio-button label>{{ $t('userManage.all') }}</el-radio-button>
                  <!-- 启用 -->
                  <el-radio-button label="1">{{ $t('userManage.enable') }}</el-radio-button>
                  <!-- 停用 -->
                  <el-radio-button label="0">{{ $t('userManage.Disable') }}</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row justify="start" type="flex">
          <el-col :span="8">
            <div>
              <!-- 查询 -->
              <el-button
                v-permit:remove="'btn:user-center:user:find'"
                icon="el-icon-search"
                size="mini"
                type="primary"
                @click="search"
              >{{ $t('userManage.search') }}
              </el-button>

              <!-- 重置 -->
              <el-button
                icon="el-icon-refresh" size="mini" @click="queryFormReset"
              >{{ $t('userManage.reset') }}
              </el-button>
            </div>
          </el-col>
        </el-row>
        <el-divider />
      </div>
    </div>
    <div style="display: flex; justify-content: space-between; margin: 10px 0">
      <span>
        <!-- 新增 -->
        <el-button
          v-permit:remove="'btn:user-center:user:add'"
          icon="el-icon-plus"
          size="small"
          type="primary"
          @click="addUser"
        >{{ $t('userManage.add') }}</el-button>

        <!-- 删除 -->
        <!-- <el-button
          v-permit:remove="'btn:user-center:user:delete'"
          size="small"
          type="danger"
          icon="el-icon-delete"
          @click="deleteUser"
        >{{ $t('userManage.Delete') }}</el-button> -->

        <!-- 用户导出 -->
        <el-button
          v-permit:remove="'btn:user-center:user:download'"
          icon="el-icon-download"
          size="small"
          type="info"
          @click="exportUser"
        >{{ $t('userManage.download') }}</el-button>
        <el-button icon="el-icon-upload" size="small" @click="downloadAll">{{
          $t('collectionCenter.allExport')
        }}</el-button><!-- 全部导出 -->
      </span>
      <span>
        <!-- 账号停用 -->
        <el-button
          v-permit:remove="'btn:user-center:user:stop'"
          size="small"
          type="warning"
          @click="isDisable(false)"
        >{{ $t('userManage.userStop') }}</el-button>

        <!-- 账号启用 -->
        <el-button
          v-permit:remove="'btn:user-center:user:start'"
          size="small"
          type="success"
          @click="isDisable(true)"
        >{{ $t('userManage.userStart') }}</el-button>
      </span>
    </div>
    <el-card>
      <div>
        <el-table
          :data="TableData"
          :max-height="600"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          border
          row-key="tableId"
          @selection-change="selectionChange"
        >
          <el-table-column
            :reserve-selection="false" align="center" type="selection"
            width="45"
          />
          <!-- 序号 -->
          <el-table-column
            :label="$t('userManage.ind')" align="center" type="index"
            width="55"
          >
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <!-- 名称 -->
          <el-table-column :label="$t('userManage.name')" prop="realName" />
          <!-- 英文名称 -->
          <el-table-column :label="$t('userManage.englishName')" prop="englishName" />
          <!-- 账户名 -->
          <el-table-column :label="$t('userManage.userNameL')" align="left" prop="username" />
          <!-- 电话 -->
          <el-table-column
            :label="$t('userManage.userPhone')"
            align="left"
            prop="phone"
            width="130px"
          />
          <!-- 角色 -->
          <el-table-column
            :label="$t('userManage.roleName')"
            align="center"
            prop="roleName"
            width="130px"
          >
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.roleName ? '' : 'info'"
              >{{ scope.row.roleName || $t('userManage.No') }}
              </el-tag>
            </template>
          </el-table-column>
          <!-- 所属部门 -->
          <el-table-column
            :label="$t('userManage.organizationName')"
            align="center"
            prop="organizationName"
            width="110px"
          />
          <!-- 所属网点 -->
          <el-table-column :label="$t('collectionCenter.belongD')" prop="siteName" />
          <!-- 账户状态 -->
          <el-table-column
            :label="$t('userManage.AccountStatus')"
            align="center"
            prop="accountState"
            width="90px"
          >
            <template slot-scope="scope">
              <!-- 启用 -->
              <el-tag
                v-if="scope.row.accountState === 1" type="success"
              >{{ $t('userManage.enable') }}
              </el-tag>
              <!-- 停用 -->
              <el-tag v-else type="info">{{ $t('userManage.Disable') }}</el-tag>
            </template>
          </el-table-column>
          <!-- 账户权限状态 -->
          <el-table-column
            :label="$t('userManage.permissionType')"
            align="center"
            prop="permissionType"
            width="120px"
          >
            <template slot-scope="scope">
              <!-- 正常 -->
              <el-tag
                v-if="scope.row.permissionType === 1" type="success"
              >{{ $t('userManage.normal') }}
              </el-tag>
              <!-- 个性化 -->
              <el-tag
                v-else-if="scope.row.permissionType === 2" type="warning"
              >{{ $t('userManage.personality') }}
              </el-tag>
              <!-- 未授权 -->
              <el-tag v-else type="info">{{ $t('userManage.unauthorized') }}</el-tag>
            </template>
          </el-table-column>
          <!-- 创建日期 -->
          <el-table-column
            :label="$t('userManage.createTime')"
            align="center"
            prop="createTime"
            width="160px"
          />
          <!-- 创建人 -->
          <el-table-column
            :label="$t('userManage.creator')"
            align="left"
            prop="createUserNameStr"
            width="120px"
          />
          <!--          &lt;!&ndash; 网点 &ndash;&gt;-->
          <!--          <el-table-column :label="$t('userManage.site')" prop="siteName" />-->
          <!-- 修改人 -->
          <el-table-column :label="$t('userManage.ModifyThePerson')" prop="updateUserName" />
          <!-- 修改时间 -->
          <el-table-column :label="$t('userManage.ModificationTime')" prop="updateTime" />
          <!-- 修改人所属网点 -->
          <el-table-column :label="$t('userManage.updateUserBelongs')" prop="updateUserSite" />
          <!-- documentNumber -->
          <el-table-column label="document number" prop="documentNumber" width="90px" />
          <!-- CPF（即个人税号） -->
          <el-table-column label="CPF" prop="cpf" />
          <!-- 签约CNPJ -->
          <el-table-column :label="$t('collectionCenter.QYcnpj')" prop="signCnpj" />
          <!-- 驾驶证 -->
          <el-table-column
            :label="$t('collectionCenter.modalDriverLicence')"
            prop="driverLicense"
          />
          <!-- 附件 -->
          <el-table-column
            :label="$t('collectionCenter.enclosureFJ')"
            align="center"
            prop="attachmentUrl"
          >
            <template slot-scope="scope">
              <el-popover placement="top" trigger="hover">
                <span>{{ scope.row.attachmentUrl }}</span>
                <span slot="reference" class="clearImageClass">{{ scope.row.attachmentUrl }}</span>
              </el-popover>
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column :label="$t('userManage.operation')" fixed="right" width="155">
            <template slot-scope="scope">
              <el-popover placement="bottom-start" trigger="click">
                <div>
                  <!-- 编辑 -->
                  <el-button
                    v-permit:remove="'btn:user-center:user:update'"
                    size="mini"
                    type="info"
                    @click="editUser(scope.row)"
                  >{{ $t('userManage.edit') }}
                  </el-button>
                  <!-- 授权 -->
                  <el-button
                    v-permit:remove="'btn:user-center:user:set'"
                    size="mini"
                    type="success"
                    @click="authorizationOpen(scope.row.id)"
                  >{{ $t('userManage.authorization') }}
                  </el-button>
                  <!-- 重置密码 -->
                  <el-button
                    v-permit:remove="'btn:user-center:user:updatePwd'"
                    size="mini"
                    type="danger"
                    @click="openUpdatePwd(scope.row)"
                  >{{ $t('userManage.resetPassword') }}
                  </el-button>
                </div>
                <el-button
                  slot="reference" size="mini"
                >{{ $t('operationCenter.MoreActions') }}
                </el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="getData"
          @size-change="getData"
        />
      </div>
    </el-card>
    <user-operation
      ref="userOperation"
      :organization-tree="organizationTree"
      :user-operation-pop="userOperationPop"
      @UserTableDataRefresh="getData"
      @closeUserOperationDialog="closeUserOperationDialog"
    />
    <!-- 用户授权 -->
    <el-dialog
      :title="$t('userManage.userSetPermission')"
      :visible.sync="authorizationVisible"
      width="30%"
    >
      <div class="set_authorization">
        <permission-tree
          :key="newKey"
          ref="permissionTreeRef"
          :permission-tree-pop="permissionTreePop"
        />
      </div>
      <el-row>
        <el-col :spam="12">
          <div style="font-size: 18px; margin-top: 15px; color: #303133">
            {{ $t('GlobalSetObj.characterPermission') }}
          </div>
          <StrPermissionTree
            :key="newKey"
            ref="permissionTreeRefStr"
            :permission-tree-pop="permissionTreePop"
          />
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <!-- 取 消 -->
        <el-button @click="authorizationVisible = false">{{ $t('userManage.cancel') }}</el-button>
        <!-- 确 定 -->
        <el-button type="primary" @click="editUserPermission">{{
          $t('userManage.confirm')
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 修改密码 -->
    <!-- 重置密码： -->
    <el-dialog
      :close-on-click-modal="true"
      :title="updataPwdObj.title"
      :visible.sync="updataPwdObj.dialogVisible"
      width="30%"
    >
      <el-form
        ref="ruleForm"
        :model="updataPwdObj.ruleForm"
        :rules="updataPwdObj.rules"
        label-width="100px"
      >
        <!-- 新密码 -->
        <el-form-item :label="$t('userManage.newPassword')" prop="newPassword">
          <el-input
            v-if="updataPwdObj.dialogVisible"
            v-model="updataPwdObj.ruleForm.newPassword"
            :placeholder="$t('userManage.pleaseInput')"
            maxlength="12"
            minlength="8"
            show-password
          />
          <!-- 必须由 8-12位小写或大写字母、数字组成，可选特殊符号 -->
          <span class="tips">{{ $t('userManage.passwordPrompt') }}</span><br>
        </el-form-item>
        <!-- 确认密码 -->
        <el-form-item :label="$t('userManage.checkPwd')" prop="newPasswordConfirm">
          <el-input
            v-if="updataPwdObj.dialogVisible"
            v-model="updataPwdObj.ruleForm.newPasswordConfirm"
            :placeholder="$t('userManage.inputPwdAgian')"
            maxlength="12"
            minlength="8"
            show-password
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- 取 消 -->
        <el-button @click="resetPasswordClose">{{ $t('userManage.cancel') }}</el-button>
        <!-- 确 定 -->
        <el-button type="primary" @click.stop="asyncSetPwd('ruleForm')">{{
          $t('userManage.confirm')
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import UserOperation from '@/views/user/userManage/components/userOperation';
// import PermissionTree from '@/views/user/positionManage/components/permissionTree';
import {
  apiExportUser,
  apiResetPassword,
  authorizationUser,
  getUserInfoById,
  getUserPage,
  handlerUserState,
  userExportAll
} from '@/api/user';
// import { strongPwd } from '@/utils/reg_util';
import { weakPwd } from '@/utils/validate';
import { getOrganizationTreeObj, getUserIdAndNameObj } from '@/enums/storageEnum';
import {
  asyncGetOrganizationTree,
  asyncGetUserAdditionalInformation,
  getUserName
} from '@/utils/asyncTools';
import { getRolePage } from '@/api/role';
// import sendMsg from '@/views/user/userManage/components/sendUserMsg';
// import store from '@/store';
import { getSiteByUser } from '@/api/lanshou';
import { cloneDeep } from 'lodash-es';
import md5 from 'js-md5';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'UserManage',
  components: {
    // 异步引入组件方式 异步引入是为了按需加载
    PermissionTree: () => import('@/views/user/positionManage/components/permissionTree'),
    UserOperation: () => import('@/views/user/userManage/components/newUserOperation'),
    StrPermissionTree: () => import('@/views/user/positionManage/components/StrPermissionTree')
  },
  data() {
    // 新密码
    const newPasswordCheck = (rule, value, callback) => {
      if (!weakPwd(value)) {
        // 请按照要求输入密码
        callback(new Error(this.$t('userManage.requestedInputCode')));
        return;
      }

      callback();
    };

    // 确认新密码
    const newPasswordConfirmCheck = (rule, value, callback) => {
      const { newPassword } = this.updataPwdObj.ruleForm;
      if (value === '') {
        // 请输入确认密码
        callback(new Error(this.$t('userManage.inputConfirmCode')));
        return;
      }
      if (newPassword !== value) {
        // 确认密码与新密码不相同
        callback(new Error(this.$t('userManage.checkPwdNotNewPwd')));
        return;
      }
      callback();
    };

    return {
      orgId: [],
      // // 组织架构树结构图
      organizationTreeProps: {
        checkStrictly: true,
        value: 'id',
        label: 'name'
      },
      siteIdList: [], // 网点编号列表
      hasChildren: true,
      userIdAndNameObj: {}, // 用户列表
      queryBoxShow: true,
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        orderProperty: 'id',
        orderDirection: 'DESC',
        params: {
          accountState: '1',
          phone: '',
          username: '',
          orgCodeLikeRight: '',
          realName: '',
          roleId: '',
          siteId: '',
          orgId: ''
        }
      },
      userOperationPop: {
        title: '',
        type: '',
        visible: false,
        user: ''
      },
      // 表格相关
      // tableData: [],
      TableData: [],
      total: 0,
      selectedList: [],
      // 单独授权
      permissionTreeShow: false,
      authorizationVisible: false,
      authorizationUserId: '',
      // 传递权限树数据
      permissionTreePop: {
        selectedPermissionList: {
          pc: [],
          pda: [],
          char: [],
          schedule: []
        }
      },
      // 修改密码
      updataPwdObj: {
        dialogVisible: false,
        title: '',

        ruleForm: {
          newPassword: '', // 新密码
          newPasswordConfirm: '', // 确认新密码
          id: ''
        },

        rules: {
          newPassword: [
            { required: true, validator: newPasswordCheck, trigger: ['blur', 'change'] }
          ],
          newPasswordConfirm: [
            { required: true, validator: newPasswordConfirmCheck, trigger: ['blur', 'change'] }
          ]
        }
      },
      // 组织结构
      organizationTree: [],
      sendMsgShow: false,
      newKey: new Date().getTime(),
      sendUserId: [],
      jsRolesList: []
    };
  },
  created() {
    this.getSiteList();
    // 获取角色
    this.getRoles();
    this.search();
    // 缓存中拿组织机构
    asyncGetOrganizationTree();
    setTimeout(() => {
      this.organizationTree = getOrganizationTreeObj(); // 单独拿不到树状结构，需要asyncGetOrganizationTree
      // console.log('缓存中拿组织机构', this.organizationTree);
    }, 1000);

    // 用户列表
    this.userIdAndNameObj = getUserIdAndNameObj();
  },
  methods: {
    downloadAll() {
      const queryFormParams =
        JSON.parse(localStorage.getItem('umQueryForm')) || this.queryForm.params;
      if (this.orgId.length > 0) {
        const orgId = this.orgId[this.orgId.length - 1];
        queryFormParams.orgId = orgId.toString();
      } else {
        queryFormParams.orgId = '';
      }
      userExportAll(queryFormParams).then((res) => {
        if (res.code === 200) {
          goExportList(this);
        }
      });
    },

    /**
     * 选择组织结构
     */
    organizationTreeChange(val) {
      // val length为2时，选中值为部门，否则提示并清空
      if (this.$refs['cascader']) {
        if (val.length < 2 && val.length !== 0) {
          this.queryForm.params.orgId = [];
          this.orgId = [];
          this.$message.warning(this.$t('jobManagement.pleaseChoose')); // '请选择部门'
        } else {
          this.orgId = val;
          this.queryForm.params.orgId = val;
        }
      }
    },

    // 所属网点
    getSiteList() {
      getSiteByUser().then((res) => {
        if (res.code === 200) {
          this.siteIdList = [];
          res.data.forEach((data) => {
            this.siteIdList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    // 获取角色
    getRoles() {
      const param = {
        pageSize: 1000,
        pageNumber: 1,
        orderProperty: 'id',
        orderDirection: 'DESC',
        roleName: ''
      };
      getRolePage(param)
        .then((response) => {
          if (response.code === 200) {
            // this.jsRolesList = response.data.records;
            const language = sessionStorage.getItem('language') || 'pu';
            this.jsRolesList = response.data.records.map((list) => {
              let roleName = '';
              const id = list.id;
              if (language === 'zh') {
                roleName = list.roleName;
              }
              if (language === 'pu') {
                roleName = list.portugal;
              }
              return {
                roleName,
                id
              };
            });
            // 根据授权提供对应的角色列表
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 打开发送消息窗口
    showSendContainer() {
      if (this.sendUserId.length > 0) {
        this.sendMsgShow = true;
      } else {
        // 请选择要发送消息的人员
        this.$message.warning(this.$t('userManage.SelectPerson'));
      }
      this.newKey = new Date().getTime();
    },
    getRowKeyOfTarget(row) {
      return row.id;
    },
    // 多选操作
    selectionChange(val) {
      this.selectedList = val;
      this.sendUserId = [];
      for (let i = 0; i < val.length; i++) {
        const selectUser = {
          receiveType: 'USER',
          receiveId: val[i].id
        };
        this.sendUserId.push(selectUser);
      }
    },
    // 重置表单
    queryFormReset() {
      this.queryForm.params.accountState = '';
      this.queryForm.params.username = '';
      this.queryForm.params.phone = '';
      this.queryForm.params.realName = '';
      this.queryForm.params.roleId = '';
      this.queryForm.params.siteId = '';
      this.queryForm.params.orgId = [];
      this.orgId = [];
      // this.getData();
    },
    search() {
      this.queryForm.pageNumber = 1;
      localStorage.setItem('umQueryForm', JSON.stringify(this.queryForm.params));
      this.getData();
    },
    getData() {
      const params = JSON.parse(localStorage.getItem('umQueryForm')) || this.queryForm.params;
      const queryForm = {
        pageSize: this.queryForm.pageSize,
        pageNumber: this.queryForm.pageNumber,
        params
      };
      if (this.orgId.length > 0) {
        const orgId = this.orgId[this.orgId.length - 1];
        queryForm.params.orgId = orgId.toString();
      } else {
        queryForm.params.orgId = '';
      }
      getUserPage(queryForm)
        .then((response) => {
          if (response.code === 200) {
            const { records, total } = response.data;
            this.total = total;
            this.TableData = records.map((item) => {
              const createUserNameStr = getUserName(item.createUser, this.userIdAndNameObj);
              return {
                ...item,
                createUserNameStr
              };
            });
          }
        })
        .then(() => {
          /**
           * 更新缓存数据
           * 页面进来首次可以不用执行此函数，考虑到一致性故一同执行
           */
          asyncGetUserAdditionalInformation(1);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addUser() {
      // 添加用户
      this.userOperationPop.visible = true;
      this.userOperationPop.type = 'add';
      this.userOperationPop.title = this.$t('userManage.addUser');
      this.$nextTick(() => {
        this.$refs.userOperation.getAreaList();
      });
    },
    editUser(user) {
      // 编辑用户
      this.userOperationPop.visible = true;
      this.userOperationPop.type = 'edit';
      this.userOperationPop.title = this.$t('userManage.editUser');
      // 判断兼容是否有cpf
      if (!('cpf' in user)) {
        user.cpf = '';
      }
      console.log(user, 'useruseruseruser');
      this.userOperationPop.user = { ...user };
    },
    closeUserOperationDialog() {
      this.userOperationPop.visible = false;
      this.userOperationPop.user = {};
      this.getData();
    },
    deleteUser() {
      const selectedSize = this.selectedList.length;
      if (selectedSize <= 0) {
        // 请正确选择需要操作的数据
        this.$message.warning(this.$t('userManage.rightChoiceData'));
      }
      // if (selectedSize > 0) {
      //   // Todo
      // } else {
      //   this.$message.warning('请正确选择需要操作的数据!');
      // }
    },
    isDisable(isDisable) {
      if (isDisable === 1 || isDisable === 0) {
        this.queryForm.pageNumber = 1;
      }
      if (this.selectedList.length <= 0) {
        // 请选择需要操作的数据
        this.$message.error(this.$t('userManage.selectData'));
        return;
      }

      const accountStateList = this.selectedList.map((item) => item.accountState);
      if (accountStateList.indexOf(isDisable ? 0 : 1) === -1) {
        // 选择数据用户状态不正确,请重新勾选
        this.$message.warning(this.$t('userManage.selectDataError'));
        return;
      }
      //                                  是否启用用户账户                      是否停用用户账户
      const message = isDisable
        ? this.$t('userManage.enableUser')
        : this.$t('userManage.disableUser');
      this.$confirm(message, this.$t('userManage.tips'), {
        confirmButtonText: this.$t('userManage.confirm'),
        cancelButtonText: this.$t('userManage.cancel'),
        type: 'warning'
      })
        .then(() => {
          const data = {
            type: 2,
            ids: this.selectedList.map((item) => item.id).toString(),
            value: isDisable ? 1 : 0
          };
          return handlerUserState(data);
        })
        .then((res) => {
          if (res.code !== 200) {
            return;
          }
          this.$message.success(this.$t('userManage.TheOperationSucceeded')); // 操作成功!
          this.getData();
        })
        .catch(() => {
          this.$message.info(this.$t('userManage.operationCancel')); // 操作取消
        });

      // if (this.selectedList.length > 0) {
      //   const accountStateList = this.selectedList.map((item) => item.accountState);
      //   if (accountStateList.indexOf(isDisable ? 0 : 1) === -1) {
      //     this.$message.warning('选择数据用户状态不正确,请重新勾选!');
      //     return;
      //   }
      //   const message = isDisable ? '是否启用用户账户?' : '是否停用用户账户?';
      //   this.$confirm(message, '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   })
      //     .then(() => {
      //       const data = {
      //         type: 2,
      //         ids: this.selectedList.map((item) => item.id).toString(),
      //         value: isDisable ? 1 : 0
      //       };
      //       handlerUserState(data).then((response) => {
      //         const { code } = response;
      //         if (code === 200) {
      //           this.$message.success('操作成功!');
      //           this.getData();
      //         }
      //       });
      //     })
      //     .catch(() => {
      //       this.$message.info('操作取消');
      //     });
      // } else {
      //   this.$message.error('请选择需要操作的数据!');
      // }
    },
    authorizationOpen(userId) {
      getUserInfoById(userId)
        .then((response) => {
          if (response.code === 200) {
            this.permissionTreePop.selectedPermissionList = {};
            const { permissionIdsChar, permissionIdsPc, permissionIdsPda, permissionIdsSchedule } =
              response.data;
            this.permissionTreePop.selectedPermissionList.char = permissionIdsChar;
            this.permissionTreePop.selectedPermissionList.pc = permissionIdsPc;
            this.permissionTreePop.selectedPermissionList.pda = permissionIdsPda;
            this.permissionTreePop.selectedPermissionList.schedule = permissionIdsSchedule;
            this.authorizationUserId = userId;
            this.authorizationVisible = true;
            this.newKey = new Date().getTime();
            // this.permissionTreeShow = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    authorizationClose() {
      this.authorizationUserId = '';
      this.permissionTreePop.selectedPermissionList = {};
      // this.permissionTreeShow = false;
      this.authorizationVisible = false;
    },
    editUserPermission() {
      this.$store.dispatch('user/getInfo');
      // 获取权限树子组件目前选中的所有节点Ids
      const selectedPermissionList = this.$refs.permissionTreeRef.getSelectedPermissionList();
      const permissionTreeRefStrList = this.$refs.permissionTreeRefStr.getSelectedPermissionList(); // 字符串权限
      const { buttonPermissions, permissionIds } = permissionTreeRefStrList;
      const id = parseInt(this.authorizationUserId);
      selectedPermissionList.buttonPermissions =
        selectedPermissionList.buttonPermissions.concat(buttonPermissions);
      selectedPermissionList.permissionIds =
        selectedPermissionList.permissionIds.concat(permissionIds);
      const permission = selectedPermissionList;
      const data = {
        permission,
        id
      };
      authorizationUser(data)
        .then((response) => {
          if (response.code === 200) {
            this.$message.success(this.$t('userManage.authorizationOperationSucceeded')); // 授权操作成功!
            this.authorizationVisible = false;
            this.getData();
            if (this.$store.state.user.id === this.authorizationUserId) {
              setTimeout(() => {
                location.reload();
              }, 1500);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    /**
     * 打开修改密码弹出框
     */
    openUpdatePwd(obj) {
      const { realName, id } = obj;
      this.updataPwdObj.title = this.$t('userManage.resetPasswordL') + realName;
      this.updataPwdObj.dialogVisible = true;
      this.updataPwdObj.ruleForm.id = id;
      this.updataPwdObj.ruleForm.newPassword = '';
      this.updataPwdObj.ruleForm.newPasswordConfirm = '';
    },
    // 取消修改密码
    resetPasswordClose() {
      this.updataPwdObj.dialogVisible = false;
      setTimeout(() => {
        this.$refs['ruleForm'].clearValidate();
      }, 10);
    },
    /**
     * 修改密码
     */
    asyncSetPwd(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }
        let { newPassword } = this.updataPwdObj.ruleForm;
        const id = this.updataPwdObj.ruleForm.id;
        newPassword = md5(md5(newPassword));
        const obj = {
          isVerification: 0, // 是否需要验证旧密码
          newPassword,
          oldPassword: '',
          id
        };

        apiResetPassword(obj)
          .then((data) => {
            if (data.code === 200) {
              this.$notify({
                title: this.$t('userManage.succeed'), // '成功'
                message: this.$t('userManage.resetSucceeded'), // '重置成功'
                type: 'success'
              });

              this.updataPwdObj.dialogVisible = false;
              this.search();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },

    /**
     * 导出用户
     */
    exportUser() {
      const arr = this.selectedList;
      if (!arr.length) {
        this.$message({
          message: this.$t('userManage.chooseExportUser'), // 请选择需要导出的用户
          type: 'warning'
        });
        return;
      }
      const idStr = cloneDeep(arr)
        .map((item) => {
          return item.id;
        })
        .join(',');
      const { id, realName } = this.$store.state.user;
      const obj = {
        operator: realName,
        operatorId: id,
        userId: idStr
      };
      this.asyncExportUser(obj);
    },

    /**
     * 导出
     */
    asyncExportUser(obj) {
      apiExportUser(obj)
        .then((res) => {
          if (res.code === 200) {
            goExportList(this);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.clearImageClass {
  color: #606266;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  word-wrap: break-word;
  word-break: break-all;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.clearImageClass:hover {
  color: #409eff;
}

.queryFormClass ::v-deep .el-form-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.queryFormClass ::v-deep .el-form-item__label {
  padding: 0;
  white-space: nowrap;
  margin-left: 20px;
}

.queryFormClass ::v-deep .el-form-item__content {
  margin-left: 0 !important;
  width: 100%;
}

.queryFormClass ::v-deep .el-input__inner {
  line-height: 40px;
  height: 40px;
}

.queryFormClass ::v-deep .el-select {
  width: 100%;
}

.tips {
  color: #b4b2b2;
  font-size: 12px;
  line-height: 13px;
  display: inline-block;
}

.putAway {
  position: absolute;
  right: 0;
  top: 0;
  color: #1989fa;
  cursor: pointer;
  z-index: 100;
}

.set_authorization {
  overflow-y: scroll;
  max-height: 50vh;
  min-width: 20vh;
}
</style>
