<template><!-- 商户下拉选择器 :remote-method="asyncQueryAllMerchantCode"-->
  <!-- <el-select
    v-model="value"
    :loading="loading"
    :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
    filterable
    remote
    clearable
    style="width: 100%;"
    @change="onChange"
    :popper-class="'merchant-select-popper'"
  >
    <el-option
      v-for="item in list"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select> -->
  <!-- :remote-method="asyncQueryAllMerchantCode" -->
  <!-- remote -->
  <ElSelectV2
    v-model="value"
    :loading="loading"
    filterable
    clearable
    :options="list"
    :placeholder="$t('operationCenter.PleaseSelect')"
    @change="onChange"
    style="width: 100%;"
  />
</template>

<script>
import ElSelectV2 from 'el-select-v2';
import { queryMerchantCodeAll } from '@/api/lanshou.js';

export default {
  name: '',

  props: {
    // 模糊查询
    val: {
      type: [String, Number],
      required: true,
      default: ''
    },
  },

  components: { ElSelectV2 },

  data() {
    return {
      loading: false,
      list: [],
      value: ''
    };
  },

  watch: {
    'val': {
      handler() {
        this.value = this.val;
      }
      // immediate: true
    }
  },

  created() {
    setTimeout(() => {
      this.asyncQueryAllMerchantCode();
      // for (let i = 0; i < 200; i++) {
      //   this.list.push({
      //     value: i,
      //     label: `选项${i}`
      //   });
      // }
    }, 500);
  },

  methods: {
    // 获取商户列表
    asyncQueryAllMerchantCode(name = '') {
      this.loading = true;
      queryMerchantCodeAll({
        merchantCodeOrName: name
      }, false).then(res => {
        const { data } = res;
        this.list =( data || []).map(item => {
          return {
            value: item.id,
            code: item.merchantCode,
            label: `${item.merchantName}(${item.merchantCode})`
          };
        });
        
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    /**
     * @param {number} 商户code
     */
    onChange(val) {
      const code = this.list.find(item => item.value === val);
      this.$emit('change', code);
    },
  }

};
</script>

<style lang="scss" scoped>
::v-deep .merchant-select-poppe {
  top: auto !important;
}
</style>
