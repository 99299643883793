<template>
  <div class="dictionaryBox">
    <el-tabs v-model="activeName" type="card">
      <!-- 字典列表 -->
      <el-tab-pane :label="$t('dictionary.dictionaryList')" name="f1">
        <div style="margin-bottom: 20px;">
          <el-form ref="queryFormRef" class="queryFormClass" :model="dictListForm">
            <el-row>
              <el-col :span="7">
                <!-- 字典代码： -->
                <el-form-item :label="$t('dictionary.dictionaryCode')">
                  <el-input v-model.trim="dictListForm.params.dictCode" clearable :placeholder="$t('dictionary.pleaseInput')" />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <!-- 字典值： -->
                <el-form-item :label="$t('dictionary.dictionaryValue')">
                  <el-input v-model.trim="dictListForm.params.dictValue" clearable :placeholder="$t('dictionary.pleaseInput')" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-row type="flex" justify="start">
            <el-col :span="12">
              <div>
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-search"
                  @click="searchTableData"
                >{{ $t('dictionary.search') }}</el-button><!-- 查询 -->
                <el-button
                  icon="el-icon-refresh"
                  size="mini"
                  @click="queryFormReset"
                >{{ $t('dictionary.reset') }}</el-button><!-- 重置 -->
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  icon="el-icon-plus"
                  @click="addDictionaryList"
                >{{ $t('dictionary.add') }}</el-button><!-- 新增 -->
              </div>
            </el-col>
          </el-row>
          <el-divider />
        </div>
        <el-table
          :data="dictTableData"
          style="width: 100%"
          :max-height="600"
          border
          @row-click="dictionaryRowClick"
        >
          <el-table-column
            prop="dictType"
            :label="$t('dictionary.dictionaryType')"
            width="180"
          /><!-- 字典类型 -->
          <el-table-column
            prop="dictCode"
            :label="$t('dictionary.dictionaryC')"
          /><!-- 字典代码 -->
          <el-table-column
            prop="dictName"
            :label="$t('dictionary.dictionaryName')"
          /><!-- 字典名称 -->
          <el-table-column
            prop="dictValue"
            :label="$t('dictionary.dictionaryV')"
          /><!-- 字典值 -->
          <el-table-column
            prop="createTime"
            :label="$t('dictionary.CreationTime')"
          /><!-- 创建时间 -->
          <el-table-column
            prop="updateTime"
            :label="$t('dictionary.ModificationTime')"
          /><!-- 修改时间 -->
          <!-- 创建人 -->
          <el-table-column :label="$t('roleManage.creator')" align="center" prop="createUserName" />
          <!-- 创建人所属网点 -->
          <el-table-column :label="$t('roleManage.createSite')" align="center" prop="createUserSiteName" />
          <!-- 修改人 -->
          <el-table-column :label="$t('roleManage.ModifiedBy')" align="center" prop="updateUserName" />
          <!-- 修改人所属网点 -->
          <el-table-column :label="$t('roleManage.updateUserBelongs')" align="center" prop="updateUserSiteName" />
          <el-table-column
            fixed="right"
            :label="$t('dictionary.operation')"
            width="100"
          ><!-- 操作 -->
            <template slot-scope="scope">
              <!-- 修改 -->
              <el-button
                type="primary" plain size="small"
                @click.stop="putClick(scope.row)"
              >{{ $t('dictionary.modify') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="dictListForm.pageNumber"
            :page-sizes="[10, 20, 50, 100]"
            :page-size.sync="dictListForm.pageSize"
            :total="totalDict"
            @size-change="getTableData"
            @current-change="getTableData"
          />
        </div>
        <el-drawer
          :title="dictionaryTitle"
          :visible.sync="dictionaryDrawer"
          :direction="direction"
        >
          <div style="padding: 20px;box-sizing: border-box;">
            <el-form
              ref="openDictionaryRef" :label-position="labelPosition" label-width="100px"
              :model="openDictionary" :rules="openDictionaryRules"
            >
              <el-form-item prop="dictType" :label="$t('dictionary.dictionaryT')">
                <!-- 字典类型： -->
                <el-select
                  v-model="openDictionary.dictType" :placeholder="$t('dictionary.pleaseChoose')" clearable
                  filterable :disabled="isDisabledDL"
                >
                  <el-option
                    v-for="(item, index) in dictTypeList"
                    :key="index"
                    :label="item.name"
                    :value="item.type"
                  />
                </el-select>
              </el-form-item>
              <el-form-item prop="dictCode" :label="$t('dictionary.dictionaryCode')">
                <!-- 字典代码： -->
                <el-input v-model="openDictionary.dictCode" :readonly="isDisabledDL" />
              </el-form-item>
              <el-form-item prop="dictName" :label="$t('dictionary.dictionaryN')">
                <!-- 字典名称： -->
                <el-input v-model="openDictionary.dictName" :readonly="isDisabledDL" />
              </el-form-item>
              <el-form-item :label="$t('dictionary.dictionaryValue')">
                <!-- 字典值： -->
                <el-input v-model="openDictionary.dictValue" :readonly="isDisabledDL" />
              </el-form-item>
            </el-form>
            <div v-if="!isDisabledDL">
              <!-- 提交 -->
              <el-button type="primary" @click="submitDLClick">{{ $t('dictionary.submit') }}</el-button>
            </div>
          </div>
        </el-drawer>
      </el-tab-pane>
      <!-- 字典多语言列表 -->
      <el-tab-pane :label="$t('dictionary.DictionaryMultilingualList')" name="f2">
        <div style="margin-bottom: 20px;">
          <el-form ref="queryFormRef" class="queryFormClass" :model="dictLangListForm">
            <el-row>
              <!--              <el-col :span="7">-->
              <!--                <el-form-item :label="$t('dictionary.DictionaryEnumerationValue')">-->
              <!--                  &lt;!&ndash; 字典枚举值： &ndash;&gt;-->
              <!--                  <el-input v-model.trim="dictLangListForm.params.dictId" clearable :placeholder="$t('dictionary.pleaseInput')" />-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <el-col :span="7">
                <el-form-item :label="$t('dictionary.dictionaryCode')">
                  <!-- 字典代码： -->
                  <el-input v-model.trim="dictLangListForm.params.dictCode" clearable :placeholder="$t('dictionary.pleaseInput')" />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item :label="$t('dictionary.dictionaryValue')">
                  <!-- 字典值： -->
                  <el-input v-model.trim="dictLangListForm.params.dictValue" clearable :placeholder="$t('dictionary.pleaseInput')" />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item :label="$t('dictionary.language')">
                  <!-- 语言： -->
                  <el-select v-model="dictLangListForm.params.language" :placeholder="$t('dictionary.pleaseChoose')" clearable>
                    <el-option
                      v-for="(item, index) in languageList"
                      :key="index"
                      :label="item.language"
                      :value="item.language"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-row type="flex" justify="start">
            <el-col :span="8">
              <div>
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-search"
                  @click="searchMoreLanguage"
                >{{ $t('dictionary.search') }}</el-button><!-- 查询 -->
                <el-button
                  icon="el-icon-refresh"
                  size="mini"
                  @click="queryFormResetM"
                >{{ $t('dictionary.reset') }}</el-button><!-- 重置 -->
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  icon="el-icon-plus"
                  @click="addMoreLanguage"
                >{{ $t('dictionary.add') }}</el-button><!-- 新增 -->
              </div>
            </el-col>
          </el-row>
          <el-divider />
        </div>
        <!-- :cell-style="cellStyle" -->
        <el-table
          :data="dictLangMoreList"
          border
          @cell-dblclick="MoreLanguageRowClick"
        >
          <el-table-column
            prop="dictType"
            :label="$t('dictionary.dictionaryType')"
            width="180"
          /><!-- 字典类型 -->
          <el-table-column
            prop="dictCode"
            :label="$t('dictionary.dictionaryC')"
          /><!-- 字典代码 -->
          <el-table-column
            prop="dictName"
            :label="$t('dictionary.dictionaryName')"
          /><!-- 字典名称 -->
          <el-table-column
            prop="dictValue"
            :label="$t('dictionary.dictionaryV')"
          /><!-- 字典值 -->
          <el-table-column
            prop="dictId"
            :label="$t('dictionary.DictionaryEnumerationV')"
          /><!-- 字典枚举值 -->
          <el-table-column
            prop="language"
            :label="$t('dictionary.yuyan')"
          /><!-- 语言 -->
          <el-table-column
            prop="dictDescribe"
            :label="$t('dictionary.describe')"
          /><!-- 描述 -->
          <el-table-column
            fixed="right"
            :label="$t('dictionary.operation')"
            width="100"
          ><!-- 操作 -->
            <template slot-scope="scope">
              <!-- 编辑 -->
              <el-button
                type="primary" plain size="small"
                @click.stop="editClick(scope.row)"
              >{{ $t('dictionary.edit') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            :current-page="dictLangListForm.pageNumber"
            :page-sizes="[10, 20, 50, 100]"
            :page-size.sync="dictLangListForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalMoreLanguage"
            @size-change="getMoreLanguage"
            @current-change="handleCurrentChange"
          />
        </div>
        <el-drawer
          :title="moreLanguageTitle"
          :visible.sync="moreLanguageDrawer"
          :direction="direction"
        >
          <div class="moreLangClass">
            <el-form
              ref="langMoreRef" :label-position="labelPosition" label-width="100px"
              :model="openLangMore" :rules="langMoreRules"
            >
              <el-form-item prop="dictId" :label="$t('dictionary.DictionaryEnumerationValue')">
                <!-- 字典枚举值 -->
                <el-select
                  v-model="openLangMore.dictId" :placeholder="$t('dictionary.pleaseChoose')" clearable
                  :disabled="isDisabledML" filterable
                >
                  <el-option
                    v-for="(item, index) in LangMoreIdList"
                    :key="index"
                    :label="item.name"
                    :value="item.dictId"
                  />
                </el-select>
              </el-form-item>
              <el-form-item prop="language" :label="$t('dictionary.language')">
                <!-- 语言 -->
                <el-select
                  v-model="openLangMore.language" :placeholder="$t('dictionary.pleaseChoose')" clearable
                  :disabled="isDisabledML"
                >
                  <el-option
                    v-for="(item, index) in languageList"
                    :key="index"
                    :label="item.language"
                    :value="item.language"
                  />
                </el-select>
              </el-form-item>
              <el-form-item prop="dictDescribe" :label="$t('dictionary.description')">
                <!-- 描述 -->
                <el-input v-model="openLangMore.dictDescribe" :readonly="isDisabledML" />
              </el-form-item>
            </el-form>
            <div v-if="isDisabledML === false" class="editClickBtn">
              <!-- 提交 -->
              <el-button type="primary" @click="submitMoreClick">{{ $t('dictionary.submit') }}</el-button>
            </div>
          </div>
        </el-drawer>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { dictLangList, dictLangMoreList, dictLangMoreEdit, LangMoreAdd, LangMoreId, dlAdd, dlEditAdd } from '@/api/orderApi';
import { isNumber } from '@/utils/validate';
export default {
  data() {
    return {
      langMoreRules: {
        dictId: [{ required: true, message: this.$t('dictionary.notNull'), trigger: ['blur', 'change'] }],
        language: [{ required: true, message: this.$t('dictionary.notNull'), trigger: ['blur', 'change'] }],
        dictDescribe: [{ required: true, message: this.$t('dictionary.notNull'), trigger: ['blur', 'change'] }]
      },
      openDictionaryRules: {
        dictType: [{ required: true, message: this.$t('dictionary.notNull'), trigger: ['blur', 'change'] }],
        dictCode: [{ required: true, message: this.$t('dictionary.notNull'), trigger: ['blur', 'change'] }],
        dictName: [{ required: true, message: this.$t('dictionary.notNull'), trigger: ['blur', 'change'] }]
      },
      activeName: 'f1',
      dictionaryTitle: '',
      dictionaryDrawer: false,
      openDictionary: {}, // 字典列表——详情
      openDictionaryID: '', // 字典列表——修改需要传递id
      dictTableData: [], // 字典列表
      dictListForm: { // 字典列表请求参数
        pageNumber: 1,
        pageSize: 10,
        params: {
          dictCode: '',
          dictValue: ''
        }
      },
      totalDict: 0,
      isDisabledDL: false, // 字典列表——操作是否禁用   false 可操作  true 不可操作
      dictTypeList: [
        { type: 'SYSTEM', name: '系统' },
        { type: 'FIELD', name: '字段' }
      ],
      // 多语言————————————————
      totalMoreLanguage: 1,
      languageList: [ // 语言列
        { language: 'ZH' },
        { language: 'EN' },
        { language: 'PU' }
      ],
      dictLangListForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          dictId: '', // 输入字典枚举值
          language: '', // 选择的语言
          dictCode: '',
          dictValue: ''
        }
      },
      dictLangMoreList: [], // 多语言列表
      labelPosition: 'right',
      moreLanguageTitle: '',
      moreLanguageDrawer: false,
      direction: 'ltr',
      isDisabledML: false, // 字典多语言列表——操作是否禁用   false 可操作  true 不可操作
      LangMoreIdList: [], // 新增——dictId输入框支持搜索
      LangMoreIdForm: {}, // dictId输入框 请求字典列表参数
      openLangMore: { // 多语言——操作
        dictId: '',
        language: '',
        dictDescribe: ''
      }
    };
  },
  created() {
    this.getTableData();
    this.getMoreLanguage();
  },
  methods: {
    putClick(row) { // 字典列表——修改
      this.isDisabledDL = false;
      this.dictionaryDrawer = true;
      this.dictionaryTitle = this.$t('dictionary.modify'); // '修改'
      this.openDictionary = {
        dictType: row.dictType,
        dictCode: row.dictCode,
        dictName: row.dictName,
        dictValue: row.dictValue
      };
      this.openDictionaryID = row.id;
    },
    addDictionaryList() { // 字典列表——新增
      setTimeout(() => {
        this.$refs['openDictionaryRef'].clearValidate();
      }, 0);
      this.isDisabledDL = false;
      this.dictionaryDrawer = true;
      this.dictionaryTitle = this.$t('dictionary.add'); // '新增'
      this.openDictionary = {
        dictType: '',
        dictCode: '',
        dictName: '',
        dictValue: ''
      };
    },
    submitDLClick() { // 字典列表——提交
      this.$refs['openDictionaryRef'].validate((valid) => {
        if (valid) {
          if (this.dictionaryTitle === this.$t('dictionary.add')) {
            // dlAdd
            dlAdd(this.openDictionary).then(res => {
              if (res.code===200) {
                this.dictionaryDrawer = false;
                this.getTableData();
              }
            }).catch({});
          }
          if (this.dictionaryTitle === this.$t('dictionary.modify')) {
            // dlEditAdd
            const openDictionaryForm = {
              id: this.openDictionaryID,
              dictType: this.openDictionary.dictType,
              dictCode: this.openDictionary.dictCode,
              dictName: this.openDictionary.dictName,
              dictValue: this.openDictionary.dictValue
            };
            dlEditAdd(openDictionaryForm).then(res => {
              if (res.code===200) {
                this.dictionaryDrawer = false;
                this.getTableData();
              }
            }).catch({});
          }
        }
      });
    },

    // // 表格行颜色
    // cellStyle({ row, column, rowIndex, columnIndex }) {
    //   if ((rowIndex % 3) === 0) {
    //     return 'background:#f3f7fd;';
    //   }
    // },
    queryFormReset() { // 字典列表——重置表单
      this.dictListForm.params.dictCode = '';
      this.dictListForm.params.dictValue = '';
    },
    searchTableData() { // 字典列表模糊查询
      this.dictListForm.pageNumber = 1;
      this.getTableData();
    },
    getTableData() { // 字典列表
      dictLangList(this.dictListForm).then(res => {
        if (res.code===200) {
          this.dictTableData = res.data.records; // 初始赋值
          this.totalDict = res.data.total;
        }
      });
    },
    dictionaryRowClick(row, column, event) { // 字典列表——详情
      this.isDisabledDL = true;
      this.dictionaryTitle = this.$t('dictionary.detail'); // '详情'
      this.dictionaryDrawer = true;
      this.openDictionary = {
        dictType: row.dictType,
        dictCode: row.dictCode,
        dictName: row.dictName,
        dictValue: row.dictValue
      };
    },

    searchMoreLanguage() {
      if (isNumber(this.dictLangListForm.params.dictId)) {
        this.dictLangListForm.pageNumber = 1;
        this.getMoreLanguage();
      } else {
        this.$message({
          message: this.$t('dictionary.inputNum'), // '请输入数字'
          type: 'warning'
        });
      }
    },
    queryFormResetM() { // 字典多语言列表_清空
      this.dictLangListForm.params = {
        dictId: '', // 输入字典枚举值
        language: '', // 选择的语言
        dictCode: '',
        dictValue: ''
      };
    },
    getMoreLanguage() { // 字典多语言列表
      dictLangMoreList(this.dictLangListForm).then(ress => {
        if (ress.code===200) {
          this.dictLangMoreList = ress.data.records;
          this.totalMoreLanguage = ress.data.total;
        }
      });
    },
    handleCurrentChange(val) {
      this.dictLangListForm.pageNumber = val;
      // this.queryFormResetM();
      this.getMoreLanguage();
    },
    MoreLanguageRowClick(row, column, event) { // 点击当前行
      this.isDisabledML = true;
      this.moreLanguageTitle = '详情';
      this.moreLanguageDrawer = true;
      this.openLangMore = {
        dictId: row.dictId,
        language: row.language,
        dictDescribe: row.dictDescribe
      };
    },
    getLangMoreIdList() { // 多语言新增——id下拉框
      LangMoreId(this.LangMoreIdForm).then(res => {
        if (res.code===200) {
          const LangMoreIdList = [];
          res.data.forEach(list => {
            LangMoreIdList.push({ dictId: list.id, name: list.id + ' - ' + list.dictName });
          });
          this.LangMoreIdList = LangMoreIdList;
        }
      });
    },
    addMoreLanguage() { // 多语言列表——新增
      this.isDisabledML = false;
      this.moreLanguageTitle = this.$t('dictionary.add'); // '新增'
      this.moreLanguageDrawer = true;
      this.openLangMore = {
        dictId: '',
        language: '',
        dictDescribe: ''
      };
      this.getLangMoreIdList();
    },
    editClick(row) { // 多语言列表——打开窗口并传值
      this.isDisabledML = false;
      this.moreLanguageTitle = this.$t('dictionary.edit'); // '编辑'
      this.moreLanguageDrawer = true;
      this.openLangMore = {
        id: row.id,
        dictId: row.dictId,
        language: row.language,
        dictDescribe: row.dictDescribe
      };
    },
    submitMoreClick() { // 多语言列表——提交
      // console.log('多语言列表——操作', this.moreLanguageTitle);
      this.$refs.langMoreRef.validate((valid) => {
        if (!valid) {
          return;
        }
        if (!isNumber(this.openLangMore.dictId)) {
          this.$message({
            message: '请输入数字类型', // this.$t('dictionary.pleaseInputNumber')
            type: 'warning'
          });
          return;
        }
        if (this.moreLanguageTitle === this.$t('dictionary.add')) { // '新增'
          LangMoreAdd(this.openLangMore).then(res => {
            if (res.code===200) {
              this.$message({
                message: this.$t('dictionary.newAddSuc'), // '新增成功'
                type: 'success'
              });
              this.moreLanguageDrawer = false;
              this.getMoreLanguage();
            } else {
              this.$message.error(res.msg);
            }
          }).catch(err => {
            this.$message.error(err.msg);
          });
        }
        if (this.moreLanguageTitle === this.$t('dictionary.edit')) { // '编辑'
          dictLangMoreEdit(this.openLangMore).then(response => {
            if (response.code===200) {
              this.$message({
                message: this.$t('dictionary.ModificationSuccessful'), // '修改成功'
                type: 'success'
              });
              this.moreLanguageDrawer = false;
              this.getMoreLanguage();
            } else {
              this.$message.error(response.msg);
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .queryFormClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .queryFormClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .dictionaryBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .moreLangClass{
    padding: 20px;
    box-sizing: border-box;
  }
  .moreLangClass ::v-deep .el-select, .dictionaryBox ::v-deep .el-select{
    width: 100%;
  }
  .moreLangClass ::v-deep .el-form-item__label{
    line-height: 22px;
  }
  .dictionaryBtn, .editClickBtn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .editClickBtn{
    margin-top: 50px;
  }
  .block{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

</style>
