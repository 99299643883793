import newRequest from '@/utils/new-request';

// 索赔账单

// 分页
export const apiDemageClaimBillPage = data => {
  return newRequest.post('/demageClaimBill/page', data);
};

// 付款
export const apiDemageClaimBillPayTodo = data => {
  return newRequest.post('fa/demageClaimBill/payTodo', data);
};

// 账单抵扣
export const apiDemageClaimBillDeduction = data => {
  return newRequest.post('/demageClaimBill/deduction', data);
};
