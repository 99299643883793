<template>
  <div class="app-container" style="height: calc(100vh - 170px);overflow-y: auto;">
    <el-divider>{{ $t('customerManagements.FTPInformation') }}</el-divider>
    <el-form ref="formDataFTP" :model="formData">
      <el-row :gutter="20">
        <el-col :span="12">
          <!--FTP地址-->
          <el-form-item
            :label="$t('customerManagements.FTPAddress')"
            prop="ftpHost"
            :rules="[
              { required: true, message: $t('customerManagements.pleaseInput'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-input v-model="formData.ftpHost" :placeholder="$t('customerManagements.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!--FTP账号-->
          <el-form-item
            :label="$t('customerManagements.FTPAccount')"
            prop="ftpUsername"
            :rules="[
              { required: true, message: $t('customerManagements.pleaseInput'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-input v-model="formData.ftpUsername" :placeholder="$t('customerManagements.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!--FTP密码-->
          <el-form-item
            :label="$t('customerManagements.FTPPassword')"
            prop="ftpPassword"
            :rules="[
              { required: true, message: $t('customerManagements.pleaseInput'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-input v-model="formData.ftpPassword" :placeholder="$t('customerManagements.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!--连接类型-->
          <el-form-item
            :label="$t('customerManagements.ConnectionType')"
            prop="ftpType"
            :rules="[
              { required: true, message: $t('customerManagements.pleaseInput'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-select v-model="formData.ftpType" :placeholder="$t('customerManagements.pleaseInput')" style="width: 100%;">
              <el-option :key="0" label="FTP" value="FTP" />
              <el-option :key="1" label="SFTP OVER SSH" value="SFTP OVER SSH" />
              <el-option :key="2" label="FTP using implicit SSL" value="FTP using implicit SSL" />
              <el-option :key="3" label="FTP using explicit SSL(Auth SSL)" value="FTP using explicit SSL(Auth SSL)" />
              <el-option :key="4" label="FTP using explicit SSL(Auth TLS)" value="FTP using explicit SSL(Auth TLS)" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!--端口-->
          <el-form-item
            :label="$t('customerManagements.FTPPort') + '(P)'"
            prop="ftpPort"
            :rules="[
              { required: true, message: $t('customerManagements.pleaseInput'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-input v-model="formData.ftpPort" type="number" :placeholder="$t('customerManagements.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!--文件读取路径-->
          <el-form-item
            :label="$t('customerManagements.fileReadingPath')"
            prop="ftpPath"
            :rules="[
              { required: true, message: $t('customerManagements.pleaseInput'), trigger: ['blur', 'change'] }
            ]"
          >
            <el-input v-model="formData.ftpPath" :placeholder="$t('customerManagements.pleaseInput')" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!--轨迹上传路径-->
          <el-form-item
            :label="$t('customerManagements.TrajectoryUploadPath')"
          >
            <el-input v-model.trim="formData.ftpUploadPath" :placeholder="$t('customerManagements.pleaseInput')" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div v-if="customer.type === 'edit' || customer.type === 'add'" slot="footer" class="dialog-footer" style="text-align: right">
      <el-button @click="clearData()">{{ $t('customerManagements.cancel') }}</el-button>
      <el-button type="primary" @click="onSubmit()">{{ $t('customerManagements.confirm') }}</el-button>
    </div>
  </div>
</template>

<script>
import { apiCustomerEditFtp } from '@/api/customer';

export default {
  name: 'FTPInformation',
  props: {
    customer: {
      type: Object,
      default: () => {
        return {};
      }
    },
    detailData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      formData: {
        ftpHost: '', // ftp地址
        ftpUsername: '', // ftp账号
        ftpPassword: '', // ftp密码
        ftpPort: '', // ftp端口
        ftpPath: '', // ftp路径
        ftpType: '', // 连接类型
        ftpUploadPath: ''
      }
    };
  },
  created() {
    console.log(this.detailData, 'detailData');
    const detailData = this.detailData || {};
    this.formData = {
      ftpHost: detailData.ftpHost, // ftp地址
      ftpUsername: detailData.ftpUsername, // ftp账号
      ftpPassword: detailData.ftpPassword, // ftp密码
      ftpPort: detailData.ftpPort ? parseInt(detailData.ftpPort) : null, // ftp端口
      ftpPath: detailData.ftpPath, // ftp路径
      ftpType: detailData.ftpType, // 连接类型
      ftpUploadPath: detailData.ftpUploadPath,
      id: this.customer.id
    };
  },
  methods: {
    // 取消
    clearData() {
      this.$emit('updataData', true);
    },
    // 提交
    onSubmit() {
      this.$refs['formDataFTP'].validate((valid) => {
        if (valid) {
          this.formData.id = this.customer.id;
          this.formData.ftpPort = parseInt(this.formData.ftpPort);
          apiCustomerEditFtp(this.formData).then(res => {
            if (res.code===200) {
              this.$message({
                message: this.$t('customerManagements.ModificationSuccessful'),
                type: 'success'
              });
              this.$emit('updataData');
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
