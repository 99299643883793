<template> <!-- 详情之基本信息 -->
  <el-card class="box-card detailStyle">
    <div slot="header" class="clearfix">
      <span>{{ $t('collectionCenter.basicInformation') }}</span>
    </div>
    <el-form label-width="auto" :label-position="$i18n.locale!='zh'?'top':'right'">
      <el-row>
        <el-col :span="8">
          <!--业务单号-->
          <el-form-item :label="$t('receivablePayable.TicketNumber') + '：'">
            <span>{{ addBatchFee.waybillNumber }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--客户-->
          <el-form-item :label="$t('receivablePayable.customer') + '：'">
            <span>{{ addBatchFee.customerAlias }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--应付主体-->
          <el-form-item :label="$t('basicData.PayablePrincipal') + '：'">
            <span>{{ addBatchFee.providerName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--网点-->
          <el-form-item :label="$t('userManage.site') + '：'">
            <span>{{ addBatchFee.siteName }}</span>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
          业务类型
          <el-form-item :label="$t('collectionCenter.businessT')">
            揽收
            <p v-if="addBatchFee.businessType === 2">{{ $t('collectionCenter.lanshou') }}</p>
            派送
            <p v-else-if="addBatchFee.businessType === 3">{{ $t('collectionCenter.dispatch') }}</p>
            转运
            <p v-else-if="addBatchFee.businessType === 1">{{ $t('collectionCenter.turnTransport') }}</p>
          </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <!--计费重量-->
          <el-form-item :label="$t('receivablePayable.BillableWeight') + '：'">
            <span>{{ addBatchFee.chargeWeight }}</span>
          </el-form-item>
        </el-col>
        <el-col v-if="payableType !== 5" :span="8">
          <!--揽收方式-->
          <el-form-item :label="$t('collectionCenter.lanshouType')">
            <span> {{ addBatchFee.collectType === 1 ? $t('collectionCenter.dropOff') : $t('collectionCenter.doorPickUp') }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--总应付费用-->
          <el-form-item :label="$t('receivablePayable.TotalFeesPayable') + '：'">
            <span>{{ addBatchFee.totalFee }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <!--备注-->
          <el-form-item :label="$t('receivablePayable.remark') + '：'">
            <span style="max-height: 100px; overflow-y: scroll; width: 100%;">{{ addBatchFee.remark }}</span>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
export default {
  name: '',

  props: {
    // 详情
    addBatchFee: {
      type: Object,
      required: true
    },
    payableType: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
    };
  },

  created() {

  },

  methods: {
  }

};
</script>

<style scoped>
.detailStyle {
  margin-bottom: 10px;
}

.detailStyle .el-form-item {
  margin-bottom: 0 !important;
}

p {
  margin-top: 0px;
}
</style>
