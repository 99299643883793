import { render, staticRenderFns } from "./DetailsCmp.vue?vue&type=template&id=26ff4673&scoped=true"
import script from "./DetailsCmp.vue?vue&type=script&lang=js"
export * from "./DetailsCmp.vue?vue&type=script&lang=js"
import style0 from "./DetailsCmp.vue?vue&type=style&index=0&id=26ff4673&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26ff4673",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/work/jenkins/workspace/重构-快递前端/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('26ff4673')) {
      api.createRecord('26ff4673', component.options)
    } else {
      api.reload('26ff4673', component.options)
    }
    module.hot.accept("./DetailsCmp.vue?vue&type=template&id=26ff4673&scoped=true", function () {
      api.rerender('26ff4673', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/finance/basicData/quotationManagement/cmp/DetailsCmp.vue"
export default component.exports