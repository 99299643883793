var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticStyle: { "margin-bottom": "5px" } },
        [
          _c("HeadCmp", {
            on: {
              change: _vm.onChange,
              query: _vm.onQuery,
              reset: _vm.onReset,
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: { type: "primary", size: "small" },
              on: {
                click: function () {
                  return _vm.onAddClaims(0)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("ClaimsManagement.ApplyForClaim")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "small" },
              on: { click: _vm.onExport },
            },
            [_vm._v(_vm._s(_vm.$t("CenterForSorting.export")))]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "min-height": "300px" },
              attrs: { data: _vm.tableData, border: "" },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operationCenter.ind"),
                  width: "60px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.query.pageNumber - 1) * _vm.query.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("customerManagements.title"),
                  prop: "title",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.ClaimNumber"),
                  prop: "claimsNumber",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.onOpen(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.claimsNumber))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.ClaimStatus"),
                  prop: "claimsStatus",
                  width: "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.claimsStatus ===
                        _vm.CLAIMS_STATUS_SELECT_OBJ["0"].value
                          ? _c("el-tag", [
                              _vm._v(
                                _vm._s(_vm.CLAIMS_STATUS_SELECT_OBJ["0"].name)
                              ),
                            ])
                          : scope.row.claimsStatus ===
                            _vm.CLAIMS_STATUS_SELECT_OBJ["1"].value
                          ? _c("el-tag", [
                              _vm._v(
                                _vm._s(_vm.CLAIMS_STATUS_SELECT_OBJ["1"].name)
                              ),
                            ])
                          : scope.row.claimsStatus ===
                            _vm.CLAIMS_STATUS_SELECT_OBJ["2"].value
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v(
                                _vm._s(_vm.CLAIMS_STATUS_SELECT_OBJ["2"].name)
                              ),
                            ])
                          : scope.row.claimsStatus ===
                            _vm.CLAIMS_STATUS_SELECT_OBJ["3"].value
                          ? _c("el-tag", [
                              _vm._v(
                                _vm._s(_vm.CLAIMS_STATUS_SELECT_OBJ["3"].name)
                              ),
                            ])
                          : scope.row.claimsStatus ===
                            _vm.CLAIMS_STATUS_SELECT_OBJ["4"].value
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v(
                                _vm._s(_vm.CLAIMS_STATUS_SELECT_OBJ["4"].name)
                              ),
                            ])
                          : scope.row.claimsStatus ===
                            _vm.CLAIMS_STATUS_SELECT_OBJ["5"].value
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v(
                                _vm._s(_vm.CLAIMS_STATUS_SELECT_OBJ["5"].name)
                              ),
                            ])
                          : _c("span"),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.ClaimsNode"),
                  prop: "claimsNodeStr",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.client"),
                  prop: "customerName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("placeOrder.CommercialOwner"),
                  prop: "merchantsName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.ApplicationTime"),
                  prop: "applicationTime",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.EndTime"),
                  prop: "finishTime",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.ApplyForClaimAmount"),
                  prop: "claimAmount",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.ActualClaimAmount"),
                  prop: "actualClaimAmount",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.CreatePeople"),
                  prop: "createUserStr",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("Resource.Reviewer"),
                  prop: "reviewerStr",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("receivablePayable.ReviewTime"),
                  prop: "auditingTime",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.Drawee"),
                  prop: "payerStr",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("receivablePayable.PaymentTime"),
                  prop: "paymentTime",
                  width: "170",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  "current-page": _vm.query.pageNumber,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.query.pageSize,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.query, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.query, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.query, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.query, "pageSize", $event)
                  },
                  "current-change": _vm.getCurrentTableData,
                  "size-change": _vm.getSizeTableData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("ClaimsManagement.ClaimApplication"),
            direction: "ltr",
            visible: _vm.addRow.isBool,
            size: "1400px",
            "z-index": "1000",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.addRow, "isBool", $event)
            },
          },
        },
        [
          _vm.addRow.isBool
            ? _c("AddCmp", {
                attrs: { ids: _vm.addRow.ids },
                on: { close: _vm.onAddClose },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.editRow.title,
            direction: "ltr",
            visible: _vm.editRow.isBool,
            size: "1300px",
            "z-index": "1000",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editRow, "isBool", $event)
            },
          },
        },
        [
          _vm.editRow.isBool
            ? _c("EditCmp", {
                attrs: { ids: _vm.editRow.ids },
                on: { close: _vm.onEditClose },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }