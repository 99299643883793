import newRequest from '@/utils/new-request';
const baseUserURL = process.env.VUE_APP_USER;
const baseURL2 = process.env.VUE_APP_ADMIN;
export const getPermissionStructureByScheduler = (data) => {
  return newRequest({
    method: 'get',
    url: '/permission/getPermissionStructureByScheduler',
    data,
    baseURL: baseUserURL
  });
};

export const getSchedulerUserPermissionStructure = (data) => {
  return newRequest({
    method: 'get',
    url: '/scheduler/user/getPermissionStructureByScheduler',
    data,
    baseURL: baseURL2
  });
};
