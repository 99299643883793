<template>
  <div class="Box">
    <!-- 寄件区域维护 -->
    <div class="searchClass search">
      <!-- 区域名称： -->
      <span>{{ $t('collectionCenter.regionName') }}</span>
      <el-input v-model="tableQueryForm.params.areaName" clearable :placeholder="$t('collectionCenter.pleaseInput')" />

      <!-- 邮编： -->
      <span style="margin-left: 20px;">
        <span>{{ $t('collectionCenter.zipCode') }}</span>
        <el-input v-model="tableQueryForm.params.startZipCode" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
      </span>
    </div>
    <el-row>
      <el-col :span="24">
        <el-button
          v-permit:remove="'btn:collection-center:finance:mailingArea:search'"
          type="primary"
          size="mini"
          icon="el-icon-search"
          :disabled="btnDisablad.searBtn"
          @click="searchClick"
        >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
        <el-button
          v-permit:remove="'btn:collection-center:finance:mailingArea:add'"
          type="primary"
          size="mini"
          icon="el-icon-plus"
          :disabled="btnDisablad.addBtn"
          @click="addClick"
        >{{ $t('collectionCenter.add') }}</el-button><!-- 新增 -->
        <el-button
          v-permit:remove="'btn:collection-center:finance:mailingArea:Save'"
          type="success"
          size="mini"
          :disabled="btnDisablad.saveBtn"
          @click="saveClick"
        >{{ $t('collectionCenter.Save') }}</el-button><!-- 保存 -->
        <el-button
          v-permit:remove="'btn:collection-center:finance:mailingArea:cancel'"
          type="info"
          size="mini"
          :disabled="btnDisablad.cancelBtn"
          @click="cancelClick"
        >{{ $t('collectionCenter.cancel') }}</el-button><!-- 取消 -->
      </el-col>
    </el-row>
    <div v-show="open" class="areaDateClass">
      <el-button @click="chooseDateClick">{{ $t('collectionCenter.selectiveData') }}</el-button><!-- 选择数据 -->
      <div class="betweenClass">
        <div class="zipcodeContentClass">
          <span v-for="(item, index) in areaDate.zipcode" :key="index" class="span">
            <span>{{ item }}<span class="el-icon-circle-close" style="margin-left: 2px;" @click="delZC(item, index)" /></span>
          </span>
        </div>
        <div class="btClass">
          <!-- 区域名称： -->
          <span>{{ $t('collectionCenter.regionName') }}</span>
          <el-input v-model="areaDate.name" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
        </div>
      </div>
    </div>
    <div v-if="dialogVisible">
      <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="50%"
        @close="closeDialog"
      >
        <!-- 分区邮编————start -->
        <div class="zipCodeParentClass">
          <div class="searchClass">
            <span>{{ $t('collectionCenter.query') }}</span><!-- 查询： -->
            <el-input v-model.trim="areaForm.name" clearable :placeholder="$t('collectionCenter.pleaseInput')" @input="onInput()" @keyup.enter.native="searchZipCode">
              <template slot="append">
                <div @click="searchZipCode">{{ $t('collectionCenter.confirm') }}</div>
              </template><!-- 确定 -->
            </el-input>
          </div>
          <div>
            <el-card class="scoll highlightClass">
              <el-tree
                ref="zipcodeRef"
                :data="zipcodeTreeData"
                show-checkbox
                :default-expand-all="true"
                :props="defaultProps"
                node-key="id"
                :current-node-key="currentNodekey"
                :default-expanded-keys="defaultOpen"
                :highlight-current="highlightCurrent"
                :default-checked-keys="defaultChecked"
                :render-content="renderContent"
              ><span slot-scope="{ data }" class="custom-tree-node">
                <span>{{ data.zipCode }}</span>
              </span>
              </el-tree>
            </el-card>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="zipcodeSave">{{ $t('collectionCenter.Save') }}</el-button>
            <el-button @click="closeDialog">{{ $t('collectionCenter.cancel') }}</el-button>
          </span>
        </div>
        <!-- 分区邮编————end -->
      </el-dialog>
    </div>
    <div style="margin-top:30px;">
      <el-table
        ref="tableRef"
        border
        :max-height="600"
        :data="tableDate"
      >
        <!-- 序号 -->
        <el-table-column :label="$t('collectionCenter.ind')" type="index" width="60" align="center">
          <template slot-scope="scope">
            <span>{{ (tableQueryForm.pageNumber - 1) * tableQueryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="id"
          align="center"
          :label="$t('collectionCenter.SerialNumber')"
          width="100"
        /><!-- 编号 -->
        <el-table-column
          prop="areaName"
          align="center"
          :label="$t('collectionCenter.regionN')"
        /><!-- 区域名称 -->
        <el-table-column
          prop="startEndzipCode"
          align="center"
          :label="$t('collectionCenter.sendRegional')"
        ><!-- 寄件区域 -->
          <template slot-scope="scope">
            <span>
              {{ scope.row.startEndzipCode }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="modify"
          align="center"
          :label="$t('collectionCenter.operation')"
          width="100"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit-outline"
              :disabled="btnDisablad.editBtn"
              @click="editClick(scope.row)"
            >{{ $t('collectionCenter.modify') }}</el-button><!-- 修改 -->
          </template>
        </el-table-column>
      </el-table>

      <div class="right">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="tableQueryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100, 500, 1000]"
          :page-size.sync="tableQueryForm.pageSize"
          :total="tableTotal"
          background
          style="margin: 10px 0"
          @current-change="getTableData"
          @size-change="getPageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  zipCodeTreeStructure,
  zipCodeDivisionAreaPage,
  zipCodeDivisionAreaAdd,
  zipCodeDivisionAreaEdit
} from '@/api/lanshou';
export default {
  name: 'MailingArea',
  data() {
    return {
      modify: this.$store.getters.button_permissions.includes('btn:collection-center:finance:mailingArea:modify'),
      highlightList: [], // 多个高亮
      currentNodekey: '',
      defaultOpen: [],
      highlightCurrent: true,
      defaultChecked: [], // 默认选中的分区邮编
      btnDisablad: {
        searBtn: false,
        addBtn: false,
        editBtn: false,
        saveBtn: true,
        cancelBtn: true
      },
      open: false,
      isAdd: false,
      dialogTitle: '',
      dialogVisible: false,
      areaForm: {},
      areaDate: {
        zipcode: [],
        name: ''
      },
      areaDateZipcode: [], // 默认勾选（为解决不同州有相同邮编导致未勾选上bug）
      zipcodeTreeData: [],
      defaultProps: {
        children: 'children', // 指定子树为节点对象的某个属性值
        label: 'zipCode' // 指定节点标签为节点对象的某个属性值
      },
      zipCodeIdList: [], // 更新邮编分区名称
      tableDate: [],
      tableTotal: 0,
      tableQueryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          areaName: '',
          startZipCode: ''
        }
      }
    };
  },
  created() {
    this.searchClick();
  },
  methods: {
    delZC(item, index) {
      this.zipCodeIdList.splice(index, 1);
      this.areaDate.zipcode.splice(index, 1);
      this.areaDateZipcode.splice(index, 1);
    },
    searchClick() {
      this.areaDate.name = '';
      this.areaDate.zipcode = [];
      this.zipCodeIdList = [];

      this.tableQueryForm.pageNumber = 1;
      localStorage.setItem('maQueryForm', JSON.stringify(this.tableQueryForm));
      this.getTableData();
    },
    getPageSize() {
      this.tableQueryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('maQueryForm')) || this.tableQueryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.tableQueryForm.pageSize,
        params: query.params
      };
      zipCodeDivisionAreaPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableTotal = res.data.total;
          this.tableDate = res.data.records;
        }
      });
    },
    getTableData() {
      const queryForm = JSON.parse(localStorage.getItem('maQueryForm')) || this.tableQueryForm;
      queryForm.pageNumber = this.tableQueryForm.pageNumber;
      queryForm.pageSize = this.tableQueryForm.pageSize;
      zipCodeDivisionAreaPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableTotal = res.data.total;
          this.tableDate = res.data.records;
        }
      });
    },
    addClick() {
      this.isAdd = true;
      this.open = true;
      this.areaDate.name = '';
      this.areaDate.zipcode = [];
      this.areaDateZipcode = [];
      this.zipCodeIdList = [];
      this.btnDisablad = {
        searBtn: true,
        addBtn: true,
        editBtn: true,
        saveBtn: false,
        cancelBtn: false
      };
    },
    editClick(row) {
      this.isAdd = false;
      this.open = true;
      this.btnDisablad = {
        searBtn: true,
        addBtn: true,
        editBtn: false,
        saveBtn: false,
        cancelBtn: false
      };
      this.areaDate.mailArea = row.mailArea;
      this.areaDate.name = row.areaName;
      this.areaDate.zipcode = row.startEndzipCode.split(',');
      this.areaDate.id = row.id;
      const zcIdList = JSON.parse(row.mailArea);
      this.zipCodeIdList = zcIdList.map(zlist => {
        return {
          id: zlist.id
        };
      });
      this.areaDateZipcode = this.zipCodeIdList;
    },
    cancelClick() {
      this.open = false;
      this.btnDisablad = {
        searBtn: false,
        addBtn: false,
        editBtn: false,
        saveBtn: true,
        cancelBtn: true
      };
    },
    saveClick() {
      // 区分新增 / 修改
      // 更新邮编分区的区域名称
      if (!this.areaDate.name) {
        this.$message.warning(this.$t('collectionCenter.pleaseFillAreaName')); // '请填写区域名称'
        return false;
      }
      if (this.zipCodeIdList.length <= 0 && this.areaDate.zipcode.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.pleaseSelectData')); // '请选择数据'
        return false;
      }
      if (this.areaDate.name && this.areaDate.zipcode.length > 0) {
        // areaDate.zipcode 所选的数据
        // this.zipCodeIdList 所选数据的id
        const zipCodeIdList = JSON.stringify(this.zipCodeIdList);
        // 区分新增 / 修改
        if (this.isAdd) {
          const addForm = {
            areaName: this.areaDate.name,
            mailArea: zipCodeIdList
          };
          zipCodeDivisionAreaAdd(addForm).then(res => {
            if (res.code===200) {
              this.searchClick();
              this.open = false;
              this.btnDisablad = {
                searBtn: false,
                addBtn: false,
                editBtn: false,
                saveBtn: true,
                cancelBtn: true
              };
            }
          });
        } else {
          const editForm = {
            id: this.areaDate.id,
            areaName: this.areaDate.name,
            mailArea: zipCodeIdList
          };
          zipCodeDivisionAreaEdit(editForm).then(res => {
            if (res.code===200) {
              this.$message({
                message: this.$t('collectionCenter.ModificationSuccessful'), // '编辑成功'
                type: 'success'
              });
              this.searchClick();
              this.open = false;
              this.btnDisablad = {
                searBtn: false,
                addBtn: false,
                editBtn: false,
                saveBtn: true,
                cancelBtn: true
              };
            }
          });
        }
      }
    },
    chooseDateClick() {
      this.currentNodekey = '';
      this.defaultOpen = [];
      this.dialogTitle = this.$t('collectionCenter.selectZonedZipCode'); // '选择分区邮编'
      this.dialogVisible = true;
      this.areaForm.name = '';
      const zipCodeForm = {
        zipCode: this.areaForm.name
      };
      zipCodeTreeStructure(zipCodeForm).then(res => {
        const zipcodeList = [];
        res.data.forEach((list, index) => {
          zipcodeList.push({
            zipCode: list.zipCode,
            children: list.zipCodeDivisionList
          });
          zipcodeList[index].children.forEach((zz, indexx) => {
            zipcodeList[index].children[indexx].zipCode = zz.startZipCode + '~' + zz.endZipCode;
          });
        });
        this.zipcodeTreeData = zipcodeList;
        // 默认选中
        const defaultChecked = [];
        this.areaDateZipcode.forEach(z => {
          defaultChecked.push(z.id);
        });
        this.defaultChecked = defaultChecked;
      });
    },
    closeDialog() {
      this.dialogVisible = false;
      this.areaForm.name = '';
      const delCode = document.querySelector('.hightli');
      delCode.classList.remove('hightli');
    },
    onInput() {
      this.$forceUpdate();
    },
    // 可能有多个高亮.....
    renderContent(h, { node, data }) {
      if (this.highlightList.indexOf(data.id) !== -1 && this.areaForm.name) {
        return <span class='hightli'>{data.zipCode}</span>;
      } else {
        return <span>{data.zipCode}</span>;
      }
    },
    searchZipCode() { // 选择分区邮编——查询
      const zipCodeForm = {
        zipCode: this.areaForm.name
      };
      zipCodeTreeStructure(zipCodeForm).then(res => {
        if (res.data.length <= 0) {
          this.$message.error(this.$t('collectionCenter.nullMsg')); // '暂无数据'
          return;
        }
        const zipcodeList = [];
        res.data.forEach((list, index) => {
          zipcodeList.push({
            zipCode: list.zipCode,
            children: list.zipCodeDivisionList
          });
          zipcodeList[index].children.forEach((zz, indexx) => {
            zipcodeList[index].children[indexx].zipCode = zz.startZipCode + '~' + zz.endZipCode;
          });
        });
        if (zipcodeList.length >= 1) {
          this.highlightList = [];
          zipcodeList.forEach(item => {
            this.highlightList.push(item.children[0].id);
          });
          this.defaultOpen = [];
          // 查出数据，仅高亮定位显示
          this.currentNodekey = zipcodeList[0].children[0].id;
          this.defaultOpen.push(zipcodeList[0].children[0].id);
          this.$nextTick(() => {
            this.$refs.zipcodeRef.setCurrentKey(this.currentNodekey);
          });
          setTimeout(() => {
            // 滑轮位置让高亮节点显示在框内（具体框内哪个位置只需更改 siteDom.offsetTop - 500 的数字）
            const siteDom = document.querySelector('.el-tree-node.is-current');
            const domScroll = document.querySelector('.highlightClass');
            const movePX = siteDom.offsetTop > 450 ? siteDom.offsetTop - 240 : 0;
            domScroll.scrollTop = movePX;
          }, 300);
        }
      });
    },
    zipcodeSave() {
      const selectedSite = this.$refs.zipcodeRef.getCheckedNodes();
      const idList = [];
      const zipCodeList = [];
      const areaDateZipcode = [];
      selectedSite.forEach(z => {
        if (!z.children) {
          idList.push({
            id: z.id
          });
          const zc = z.startZipCode + '~' + z.endZipCode;
          zipCodeList.push(zc);
          areaDateZipcode.push({
            id: z.id,
            zipCode: zc
          });
        }
      });
      this.areaDateZipcode = areaDateZipcode;
      this.areaDate.zipcode = zipCodeList;
      this.zipCodeIdList = idList;
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .zipCodeParentClass ::v-deep .el-tree-node{
    position: relative;
  }
  .zipCodeParentClass ::v-deep .hightli {
    background-color: #409eff !important;
    color: white;
    position: absolute;
    left: -18px;
    width: calc(100% + 18px);
    height: 100%;
    padding-left: 82px;
    box-sizing: border-box;
    line-height: 27px;
  }

  ::v-deep .el-tree--highlight-current .is-current.el-tree-node > .el-tree-node__content {
    background-color: #409eff !important;
    color: white;
  }
  .zipCodeClass{
    display: inline-block;
  }
  //网点结构数隐藏父节点选项框
  //.scoll .stafftree > ::v-deep .el-tree-node > .el-tree-node__content .el-checkbox{
  //  display: none;
  //}
  .scoll {
    overflow: auto;
    max-height: 450px;
  }
  .dialog-footer{
    text-align: center;
    margin-top: 20px;
    display: block;
  }
  .betweenClass{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .btClass{
      margin-left: 20px;
    }
    .zipcodeContentClass{
      overflow: hidden;
      resize: both;
      width: 400px;
      height: 300px;
      min-width: 350px;
      max-width: 650px;
      min-height: 300px;
      max-height: 500px;
      overflow: auto;
      border: 1px solid #c0c4cc;
      border-radius: 5px;
      padding: 10px;
      box-sizing: border-box;
      word-break: break-all;
      .span{
        line-height: 37px;
        padding: 5px;
        margin-right: 10px;
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.08);
        white-space: nowrap;
      }
    }
  }
  .areaDateClass{
    margin-top: 30px;
  }
  // .areaDateClass ::v-deep .el-textarea__inner{
  //   width: 30%;
  //   max-height: 200px;
  //   margin-right: 10px;
  // }
  .areaDateClass ::v-deep .el-input{
    width: auto;
  }
  .areaDateClass ::v-deep .el-select .el-input--suffix .el-input__inner{
    height: 400px;
  }
  .search.searchClass{
    display: flex;
    justify-content: flex-start;
    span{
      font-size: 14px;
      font-weight: bold;
      color: #606266;
      white-space: nowrap;
    }
  }
  .searchClass{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 17px;
  }
  .searchClass ::v-deep .el-input{
    width: auto;
  }
  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
</style>
