<template>
  <div class="Box">

    <div class="scanClass">
      <span class="span">{{ $t('GlobalSetObj.pleaseSelectLicenseNumber') }}：</span><!--  请选择车牌：  -->

      <el-select
        v-model="licenseNumber"
        clearable filterable
        @change="vehicleMsg"
        remote
        reserve-keyword
        :remote-method="remoteMethod"
      >
        <el-option
          v-for="(item, index) in numberList"
          :key="index"
          :label="item.vehicleNumber"
          :value="item.vehicleNumber"
          @click.stop
        />
      </el-select>
    </div>
    <!--  车辆信息  -->
    <el-dialog
      v-if="carList.vehicleNumber"
      title=""
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%"
      @close="handleClose"
    >
      <div class="dialogClass">
        <!--  车辆编号：  -->
        <span>{{ $t('collectionCenter.carNum') }}{{ carList.id }}</span>
        <!--  车牌号：  -->
        <span>{{ $t('collectionCenter.licensePlateNum') }}{{ carList.vehicleNumber }}</span>
        <!--  运营模式：  -->
        <span>{{ $t('collectionCenter.operationM') }}{{ carList.operationModeName }}</span>
        <!--  车辆类型：  -->
        <span>{{ $t('collectionCenter.carT') }}{{ carList.vehicleTypeName }}</span>
        <!--  驾驶人：  -->
        <span>{{ $t('collectionCenter.carDriver') }}{{ carList.vehicleDriver }}</span>
        <!--  所属网点：  -->
        <span>{{ $t('collectionCenter.belongToSite') }}{{ carList.siteIdName }}</span>
        <!--  装车总袋数：  -->
        <span>{{ $t('collectionCenter.totalNumberBagsLoaded') }}：{{ carList.loadBagNumber }}</span>
        <!--  装车总票数：  -->
        <span>{{ $t('collectionCenter.carLoadingVotes') }}：{{ carList.loadPackageNumber }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--  取消  -->
        <el-button @click="handleClose">{{ $t('collectionCenter.cancel') }}</el-button>
        <!--  确认  -->
        <el-button type="primary" @click="loadScanConfirm">{{ $t('collectionCenter.confirm') }}</el-button>
      </span>
    </el-dialog>

    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
import {
  queryVehicleValList,
  unloadVehicleConfirm,
  vehicleNumberFuzzyQuery
} from '@/api/lanshou';

const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';
export default {
  data() {
    return {
      okMp3,
      errorMp3,
      dialogVisible: false,
      carList: {},
      licenseNumber: '',
      numberList: [],
      valueForm: {}
    };
  },
  watch: {
    licenseNumber(val) {
      if (!val) {
        this.remoteMethod();
      }
    }
  },
  created() {
    this.remoteMethod();
  },
  methods: {
    remoteMethod(val) {
      const value = val || '';
      vehicleNumberFuzzyQuery(value).then(res => {
        if (res.code===200) {
          this.numberList = res.data;
        }
      });
    },

    // 输入查询，返回值有入参
    vehicleMsg(val) {
      if (!val) return;
      queryVehicleValList(val).then(res => {
        if (res.code===200) {
          this.carList = res.data;
          this.valueForm = res.data.unloadVehicleScanOperateLog;
          setTimeout(() => {
            this.dialogVisible = true;
          }, 500);
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.carList = {};
      this.licenseNumber = '';
    },
    loadScanConfirm() {
      unloadVehicleConfirm(this.valueForm).then(res => {
        if (res.code===200) {
          this.handleClose();
          this.$message({
            message: this.$t('collectionCenter.addSuccessful'), // '新增成功'
            type: 'success'
          });
          // setTimeout(() => {
          //   // 跳转到`转运中心车辆确认查询`
          //   this.$router.push({ path: '/collectionCenter/handle/transferCenterVehicleConfirmQuery' });
          // }, 2000);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.scanClass{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  .span{
    font-size: 20px;
    font-weight: bold;
  }
}
.scanClass ::v-deep .el-input .el-input__inner{
  border-width: 2px;
  height: 45px;
  font-size: 20px;
}
.scanClass ::v-deep .el-input .el-input__clear{
  transform: scale(1.5);
}
.dialogClass{
  padding: 20px;
  border: 1px solid #b1b3b7;
  border-radius: 10px;
  box-sizing: border-box;
  span{
    font-size: 18px;
    font-weight: bold;
    display: block;
    margin: 10px 0;
  }
}
</style>
