var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _c("p", [
          _vm._v(
            _vm._s(_vm.$t("routes.班次编号")) +
              ": " +
              _vm._s(_vm.row.scheduleNumber)
          ),
        ]),
        _c(
          "el-button",
          {
            attrs: { size: "mini", type: "primary", plain: "" },
            on: {
              click: function ($event) {
                return _vm.$emit("edit", _vm.row)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("GlobalSetObj.edit")))]
        ),
      ],
      1
    ),
    _c(
      "ul",
      _vm._l(_vm.row.collectTimeList, function (item, index) {
        return _c("li", { key: index }, [
          _c("p", [
            _vm._v(
              _vm._s(_vm.$t("GlobalSetObj.client")) +
                ": " +
                _vm._s(_vm.row.customerNameList)
            ),
          ]),
          _c("p", [
            _vm._v(
              _vm._s(_vm.$t("routes.对应揽收时间")) +
                ": " +
                _vm._s(item.collectTime[0]) +
                "-" +
                _vm._s(item.collectTime[1])
            ),
          ]),
          _c("p", [
            _vm._v(
              _vm._s(_vm.$t("routes.对应揽收装车完成时间")) +
                ": " +
                _vm._s(item.collectLoadFinishTime)
            ),
          ]),
          _c("p", [
            _vm._v(
              _vm._s(_vm.$t("routes.对应HUB1到件时间")) +
                " " +
                _vm._s(item.hub1ArrivalTime)
            ),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }