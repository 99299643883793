<template>
  <div class="app-container">
    <div style="margin-bottom: 10px;">
      <el-card class="box-card" style="background:#CEF5ED;margin-bottom: 10px;font-size: 16px">
        <div style="margin-bottom: 10px;"> {{ $t('GlobalSetObj.PackageScanningRemove') }}</div>
        <div>{{ $t('GlobalSetObj.beforeScannerTip') }}</div>
      </el-card>
      <el-card class="box-card">
        <div class="titleStyle" style="margin-bottom: 10px;">{{ $t('GlobalSetObj.ScanToRemove') }}</div>
        <div>
          <span style="font-size: 16px">{{ $t('GlobalSetObj.waybillNumber') }}：</span>
          <div style="display:inline-block;width: 50%;margin-right: 20px;">
            <el-input
              ref="inputVal"
              v-model.trim="waybillNumber"
              :autofocus="autofocus"
              :placeholder="$t('GlobalSetObj.ScanOrwaybillNumber')"
              clearable
              @blur="delShowErr"
              @keyup.enter.native="eventEnter()"
            />
          </div>
          <el-button type="primary" @click="remove">{{ $t('GlobalSetObj.RemoveOutBag') }}</el-button>
        </div>
        <!--        <div style="color:red;height: 30px;line-height: 30px;margin-left: 60px;font-size: 16px">-->
        <!--          <div v-if="showErr">{{ errorMsg }}</div>-->
        <!--        </div>-->
      </el-card>
    </div>
    <div>
      <el-row :gutter="10">
        <el-col :span="10">
          <div class="grid-content bg-purple">
            <el-card class="box-card" style="height: 625px">
              <div style="margin-bottom: 10px;">
                <span class="titleStyle">{{ $t('GlobalSetObj.CurrentlyRemoving') }}</span>
                <!--                <span-->
                <!--                  v-if="tableDataLeft.length >= 1"-->
                <!--                  style="color:#409eff;cursor: pointer;float:right;"-->
                <!--                  @click="MaintenancePocket"-->
                <!--                >-->
                <!--                  <i class="el-icon-refresh-left" />-->
                <!--                  {{ $t('GlobalSetObj.MaintenancePocket') }}</span>-->
              </div>
              <el-table
                :data="tableDataLeft"
                :height="height"
                border
                style="width: 100%"
              >
                <el-table-column
                  :label="$t('GlobalSetObj.waybillNumber')"
                  min-width="200"
                  prop="waybillNumber"
                />
                <el-table-column
                  :label="$t('GlobalSetObj.client')"
                  prop="customerName"
                />
                <el-table-column
                  :label="$t('GlobalSetObj.weight')"
                  prop="weight"
                />
              </el-table>
            </el-card>
          </div>
        </el-col>
        <el-col :span="14">
          <div class="grid-content bg-purple">
            <el-card class="box-card" style="height: 625px">
              <div style="margin-bottom: 10px;">
                <span class="titleStyle">{{ $t('GlobalSetObj.restTheBag') }}</span>
                {{ $t('GlobalSetObj.bagNumber') }}：{{ BagNumber || '' }}
                <span style="float:right;"> {{ $t('GlobalSetObj.package') }}：<span>{{ number }}</span></span>
              </div>
              <el-table
                :data="tableDataLeftShow"
                :height="height"
                border
                style="width: 100%"
              >
                <el-table-column
                  :label="$t('GlobalSetObj.serial')"
                  prop="index"
                  width="80"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.indexNo }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('GlobalSetObj.waybillNumber')"
                  min-width="200"
                  prop="waybillNumber"
                />
                <el-table-column
                  :label="$t('GlobalSetObj.weight')"
                  prop="weight"
                />
                <el-table-column
                  :label="$t('GlobalSetObj.operator')"
                  prop="create_user"
                />
                <el-table-column
                  :label="$t('GlobalSetObj.operationTime')"
                  prop="create_time"
                  width="160"
                />
              </el-table>
              <!--  分页  -->
              <el-pagination
                :current-page="page.pageNumber"
                :page-size="page.pageSize"
                :page-sizes="[10, 20, 50, 100]"
                :pager-count="5"
                :total="page.total"
                background
                layout="total, sizes, prev, pager, next"
                small
                style="margin-top: 10px; float: right;margin-bottom: 20px"
                @size-change="SizeChange"
                @current-change="CurrentChange"
              />
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
import { getByWayBillNumber, wmsDeleteBag, wmsSelectPackage } from '@/api/storeAdmin.js';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';

const okMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/su.wav';
const errorMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/print_error.wav';
export default {
  name: 'RemoveTheBag',
  data() {
    return {
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0
      },
      tableDataLeftShow: [],
      waybillNumber: '',
      tableDataLeft: [],
      tableDataRight: [],
      height: 320,
      showErr: false,
      number: 0, // 剩余包裹数
      BagNumber: '', // 袋号
      autofocus: true,
      errorMsg: '',
      okMp3,
      errorMp3,
      userIdAndNameObj: '',
      timer: 0
    };
  },
  watch: {
    waybillNumber: {
      handler: function(val) {
        this.showErr = false;
        this.errorMsg = '';
      }
    }
  },
  activated() {
    this.$nextTick(_ => {
      this.$refs.inputVal.$refs.input.focus();
    });
  },
  created() {
    this.height = window.document.body.offsetHeight - 460;
    this.autofocus = true; // 自动获取焦点
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.$nextTick(_ => {
      this.$refs.inputVal.$refs.input.focus();
    });
  },
  methods: {
    // 节流
    throttle(func, wait) {
      const _this = this;
      const now = new Date().getTime();
      console.log(now - _this.timer, 'now - _this.timer');
      if (now - _this.timer > wait) {
        _this.timer = now + wait;
        const tiem = setTimeout(function() {
          console.log(tiem, 'TIEM');
          clearTimeout(tiem);
          func();
        }, wait);
      }
    },
    // 触发回车事件使用节流
    eventEnter() {
      console.log('123');
      this.throttle(this.removeTheBagOperations, 300);
    },
    // 扫描移除出袋
    async removeTheBagOperations() {
      if (this.waybillNumber !== '') {
        await this.getByWayBillNumberFun({ waybillNumber: this.waybillNumber });
      } else {
        this.$message.warning(this.$t('GlobalSetObj.inputWaybillNumber'));
        this.showErr = false;
      }
    },
    getCurreentList() {
      this.page.total = this.tableDataRight.length;
      // 计算起始索引和结束索引
      this.tableDataRight.map((item, index) => {
        item.indexNo = this.page.total - index;
      });
      const startIndex = (this.page.pageNumber - 1) * this.page.pageSize;
      const endIndex = startIndex + this.page.pageSize;
      // 使用 slice 方法获取当前页的数据
      const currentPageData = this.tableDataRight.slice(startIndex, endIndex);
      console.log(currentPageData, 'currentPageData');
      this.tableDataLeftShow = currentPageData;
      // this.tableDataLeft = currentPageData;
    },
    // 点击分页
    SizeChange(val) {
      console.log(val, 'VALSizeChange');
      this.page.pageNumber = 1;
      this.page.pageSize = val;
      this.getCurreentList();
    },
    CurrentChange(val) {
      console.log(val, 'CurrentChange');
      this.page.pageNumber = val;
      this.getCurreentList();
    },
    // 查询包裹信息
    async getByWayBillNumberFun(data) {
      try {
        this.tableDataLeft = [];
        const result = await getByWayBillNumber(data);
        if (result.code===200) {
          this.BagNumber = result.data.transitBagNumber;
          this.tableDataLeft.push(result.data);
          const data = {
            waybillNumber: this.waybillNumber
          };
          const res = await wmsDeleteBag(data);
          if (res.code===200) {
            // debugger;
            await this.$refs.okRef.play();
            this.$message.success(this.$t('GlobalSetObj.RemovedSuccessfully'));
            const obj = {};
            obj.transitBagNumber = this.BagNumber;
            const res = await this.selectPackage(obj);
            console.log(res, '删除后的查询');
            this.waybillNumber = '';
            this.$nextTick(_ => {
              this.$refs.inputVal.$refs.input.focus();
            });
          }
        }
      } catch (e) {
        await this.$refs.errRef.play();
        this.$nextTick(_ => {
          this.$refs.inputVal.$refs.input.select();
        });
      }
    },
    // 查询剩余包裹
    async selectPackage(obj) {
      const res = await wmsSelectPackage(obj);
      if (res.code===200) {
        res.data.map(item => {
          item.create_user = getUserName(item.create_user, this.userIdAndNameObj);
        });
        this.tableDataRight = res.data;
        console.log(res.data, '查询剩余包裹');
        this.number = this.tableDataRight.length;
        this.showErr = false;
        this.getCurreentList();
      }
      this.waybillNumber = '';
      this.$nextTick(_ => {
        this.$refs.inputVal.$refs.input.focus();
      });
    },
    // 移除装袋
    async removeBag(data) {
      const res = await wmsDeleteBag(data);
      return res;
    },
    // 手动移除装袋
    async remove() {
      if (this.waybillNumber !== '') {
        const data = {};
        data.waybillNumber = this.waybillNumber;
        const data1 = {};
        if (this.tableDataLeft.length > 0) {
          const result = this.tableDataLeft.some(obj => Object.values(obj).includes(this.waybillNumber));
          console.log(this.tableDataLeft, 'this.tableDataLeftthis.tableDataLeftthis.tableDataLeft', result);
          if (result) {
            const data = {};
            data.waybillNumber = this.tableDataLeft[0].waybillNumber;
            const res = await wmsDeleteBag(data);
            if (res.code===200) {
              await this.$refs.okRef.play();
              this.$message.success(this.$t('GlobalSetObj.RemovedSuccessfully'));
              this.tableDataLeft.splice(0, 1);
              const obj = {};
              obj.transitBagNumber = this.BagNumber;
              const res = await this.selectPackage(obj);
              console.log(res, '删除后的查询');
              this.waybillNumber = '';
              this.$nextTick(_ => {
                this.$refs.inputVal.$refs.input.focus();
              });
              return;
            }
          }
          data1.waybillNumber = this.tableDataLeft[0].waybillNumber;
        } else {
          data1.waybillNumber = '';
        }
        try {
          try {
            if (data1.waybillNumber !== '') {
              const res = await this.removeBag(data1);
              if (res.code===200) {
                await this.$refs.okRef.play();
                this.tableDataLeft.splice(0, 1);
                this.tableDataRight = [];
                this.number = 0; // 剩余包裹数
                // this.BagNumber = ''; // 袋号
                const obj = {};
                obj.transitBagNumber = this.BagNumber;
                const res = await this.selectPackage(obj);
                console.log(res, '删除后的查询');
                this.waybillNumber = '';
                this.$nextTick(_ => {
                  this.$refs.inputVal.$refs.input.focus();
                });
              } else {
                this.tableDataLeft.splice(0, 1);
                this.tableDataRight = [];
                this.number = 0; // 剩余包裹数
                this.BagNumber = ''; // 袋号
              }
            }
          } catch (e) {
            await this.$refs.errRef.play();
            this.$nextTick(_ => {
              this.$refs.inputVal.$refs.input.select();
            });
          }
          const result = await wmsDeleteBag(data);
          if (result.code===200) {
            await this.$refs.okRef.play();
            this.$message.success(this.$t('GlobalSetObj.RemovedSuccessfully'));
            this.tableDataLeft = [];
            this.tableDataRight = [];
            this.number = 0; // 剩余包裹数
            // this.BagNumber = ''; // 袋号
            const obj = {};
            obj.transitBagNumber = this.BagNumber;
            const res = await this.selectPackage(obj);
            console.log(res, '删除后的查询');
            this.showErr = false;
            this.waybillNumber = '';
            this.$nextTick(_ => {
              this.$refs.inputVal.$refs.input.focus();
            });
          }
        } catch (e) {
          this.showErr = true;
          this.errorMsg = e.msg;
          await this.$refs.errRef.play();
          this.$nextTick(_ => {
            this.$refs.inputVal.$refs.input.select();
          });
        }
      } else {
        this.$message.warning(this.$t('GlobalSetObj.inputWaybillNumber'));
        this.showErr = false;
      }
    },
    // 维持原袋
    MaintenancePocket() {
      this.waybillNumber = '';
      this.tableDataLeft = [];
      // this.tableDataRight = [];
      // this.number = 0; // 剩余包裹数
      // this.BagNumber = ''; // 袋号
    },
    // 失去焦点进行判断
    delShowErr() {
      if (this.waybillNumber === '') {
        this.showErr = false;
        this.errorMsg = '';
      }
    }
  }
};
</script>

<style scoped>
.titleStyle {
  font-size: 18px;
  font-weight: bold;
}

.app-container >>> .el-pagination__total {
  margin-top: 4px;
}
</style>
