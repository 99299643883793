<template>
  <div class="Box">
    <el-form style="margin-bottom: 20px;" class="selectClass">
      <el-row type="flex" justify="start" style="flex-wrap: wrap;">
        <el-col :span="10" style="width: auto;">
          <el-form-item :label="$t('collectionCenter.vehicleMsgConfirmTime') + '：'">
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :range-separator="$t('collectionCenter.to')"
              :start-placeholder="$t('collectionCenter.StartTime')"
              :end-placeholder="$t('collectionCenter.EndTime')"
              @change="dateChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7" style="width: auto;">
          <!-- 确认网点： -->
          <el-form-item :label="$t('collectionCenter.verifyDot') + '：'">
            <el-select
              v-model="queryForm.params.siteIdList"
              multiple
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              filterable
              clearable
              style="width: 100%"
              @focus="getNewestSite"
            >
              <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7" style="width: auto;">
          <!-- 车牌号： -->
          <el-form-item :label="$t('collectionCenter.licensePlateNum')">
            <div class="BlockNewlineClass">
              <el-input
                v-model="queryForm.params.vehicleNumbers"
                type="textarea"
                :placeholder="$t('collectionCenter.BatchQuery')"
                @blur="ft"
                @keydown.enter.native="BlockNewline"
              />
            </div>
            <!-- 支持批量查询(回车换行分割) -->
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-col :span="12">
        <el-button
          v-permit:remove="'btn:collection-center:transfer-center-vehicle-confirm-query:find'"
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="searchClick"
        >{{ $t('collectionCenter.search') }}</el-button>
        <!-- 查询 -->
        <el-button
          v-permit:remove="'btn:collection-center:transfer-center-vehicle-confirm-query:reset'"
          icon="el-icon-refresh"
          size="mini"
          @click="resetClick"
        >{{ $t('collectionCenter.reset') }}</el-button>
        <!-- 重置 -->
        <el-button
          v-permit:remove="'btn:collection-center:transfer-center-vehicle-confirm-query:exportAll'"
          icon="el-icon-upload2"
          size="mini"
          type="primary"
          :class="isClickAll ? 'onlyClickOnce10' : ''"
          @click="download"
        >{{ $t('collectionCenter.allExport') }}</el-button>
        <!-- 全部导出 -->
      </el-col>
    </el-row>
    <el-table
      ref="tableList"
      :max-height="600"
      border
      :data="tableDate"
      style="margin-top: 20px;"
    >
      <el-table-column
        type="selection"
        width="55"
      />
      <!-- 序号 -->
      <el-table-column
      :label="$t('collectionCenter.ind')" type="index" width="60"
      align="center">
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="vehicleNumber"
        :label="$t('collectionCenter.licensePlateNumber')"
      /><!-- 车牌号 -->
      <el-table-column
        align="center"
        prop="driverName"
        :label="$t('collectionCenter.driverName')"
      /><!-- 司机姓名 -->
      <el-table-column
        align="center"
        prop="siteName"
        :label="$t('collectionCenter.verifyDot')"
      /><!-- 确认网点 -->
      <el-table-column
        align="center"
        prop="createTime"
        :label="$t('collectionCenter.verifyTime')"
      /><!-- 确认时间 -->
      <el-table-column
        align="center"
        prop="createUser"
        :label="$t('collectionCenter.verifyPerson')"
      /><!-- 确认人 -->
      <el-table-column
        align="center"
        prop="loadBagNumber"
        :label="$t('collectionCenter.totalNumberBagsLoaded')"
      /><!-- 装车总袋数 -->
      <el-table-column
        align="center"
        prop="loadPackageNumber"
        :label="$t('collectionCenter.carLoadingVotes')"
      /><!-- 装车总票数 -->
      <el-table-column
        align="center"
        prop="unloadBagNumber"
        :label="$t('collectionCenter.totalBagsUnloaded')"
      /><!-- 卸车总袋数 -->
      <el-table-column
        align="center"
        prop="unloadPackageNumber"
        :label="$t('collectionCenter.carUnloadingVotes')"
      /><!-- 卸车总票数 -->
      <el-table-column
        align="center"
        :label="$t('collectionCenter.operation')"
        width="120"
      >
        <template slot-scope="scope">
          <!-- 卸车签名图片 -->
          <div
            style="color: #26b3f1;cursor: pointer;"
            @click="unloadSignImg(scope.row)"
          >{{ $t('collectionCenter.unloadSignImg') }}</div>
          <div v-if="isDel" style="margin-bottom: 5px;">
            <el-popconfirm
              :title="$t('collectionCenter.deleteSure')"
              @confirm="delUnloadRow(scope.row)"
            ><!-- 确定删除吗？ -->
              <el-button slot="reference" size="mini" :class="scope.row.confirmStatus == 1 ? 'disBtn' : ''">{{ $t('collectionCenter.Delete') }}</el-button><!-- 删除 -->
            </el-popconfirm>
          </div>
          <el-button v-if="isConfirm" @click="confirmClick(scope.row)" size="mini" :class="scope.row.confirmStatus == 1 ? 'disBtn' : ''">
            {{ $t('collectionCenter.Confirm') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getTablePage"
        @size-change="getPageSize"
      />
    </div>

    <el-dialog :visible.sync="dialogVisible" @close="closeDialog">
      <div v-if="unloadPicture.length > 0" style="overflow-x: auto; white-space:nowrap;">
        <span v-for="(img, index) in unloadPicture" :key="index" style="margin: 0 10px;">
          <img :src="img" alt="" style="width: auto; height: 500px;">
        </span>
      </div>
      <div v-else style="text-align: center;font-size: 18px;">
        {{ $t('collectionCenter.noImageAvailable') }}
      </div>
    </el-dialog>

    <!--  确认弹窗  -->
    <el-dialog :visible.sync="confirmVisible" @close="closeConfirmDialog" class="confirmDialogClass">
      <div>是否确认？</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="handleGenerate">{{ $t('collectionCenter.Confirm') }}</el-button><!-- 确认 -->
        <el-button @click.stop="closeConfirmDialog">{{ $t('collectionCenter.cancel') }}</el-button><!-- 取消 -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  getSiteByUser,
  transitVehicleScanConfirmPage,
  transitVehicleScanConfirmExport,
  unloadVehicleCancleDel,
  queryUnloadImages,
  unloadVehicleManualConfirm
} from '@/api/lanshou';

export default {
  // name: 'TransferCenterVehicleConfirmQuery',
  data() {
    return {
      confirmId: '',
      confirmVisible: false,
      isConfirm: this.$store.getters.button_permissions.includes('btn:collection-center:transfer-center-vehicle-confirm-query:confirm'),
      isDel: this.$store.getters.button_permissions.includes('btn:collection-center:transfer-center-vehicle-confirm-query:del'),
      siteList: [],
      timeValue: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          confirmStartTime: '',
          confirmEndTime: '',
          siteIdList: [],
          vehicleNumbers: ''
        }
      },
      notEnter: true, // 是否允许换行
      isClickAll: false,
      total: 0,
      tableDate: [],
      dialogVisible: false,
      unloadPicture: []
    };
  },
  watch: {
    'queryForm.params.vehicleNumbers'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 1000) {
        number = bghTrim.slice(0, 1000).join('\n');
        this.queryForm.params.vehicleNumbers = number;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bghTrim.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    }
  },
  activated() {
    // 重新加载组件相关的数据或者执行其他操作
    this.isClickAll = false;
    this.resetClick();
    this.searchClick();
  },
  created() {
    this.isClickAll = false;
    this.resetClick();
    this.searchClick();
    this.getNewestSite();
  },
  methods: {
    confirmClick(row) {
      this.confirmVisible = true;
      this.confirmId = row.id;
    },
    handleGenerate() {
      unloadVehicleManualConfirm(this.confirmId).then(res => {
        if (res.code===200) {
          this.closeConfirmDialog();
          this.searchClick();
        }
      });
    },
    closeConfirmDialog() {
      this.confirmVisible = false;
    },
    getNewestSite() {
      getSiteByUser().then(res => {
        if (res.code===200) {
          this.siteList = [];
          res.data.forEach(data => {
            this.siteList.push({ id: data.id, name: data.name });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.confirmStartTime = '';
        this.queryForm.params.confirmEndTime = '';
        return;
      }
      this.queryForm.params.confirmStartTime = val[0];
      this.queryForm.params.confirmEndTime = val[1];
      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-1, 'year').format('YYYY-MM-DD') + ' 23:59:59';
        if (val[1] > endTime) {
          this.$message({
            // 最多可查询到一年的数据
            message: this.$t('collectionCenter.moreYear'),
            type: 'warning'
          });
          const d = new Date();
          const today = dayjs(d).format('YYYY-MM-DD');
          this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
          this.queryForm.params.confirmStartTime = today + ' 00:00:00';
          this.queryForm.params.confirmEndTime = today + ' 23:59:59';
        }
      }
    },
    ft() {
      const val = this.queryForm.params.vehicleNumbers.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.vehicleNumbers = bghTrim.join('\n');
    },
    BlockNewline() { // 文本域最大输入行数为1000
      const e = window.event || arguments[0];
      this.ft();
      const numberLen = this.queryForm.params.vehicleNumbers.split('\n');
      if (!this.notEnter) {
        if (numberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    resetClick() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params = {
        confirmStartTime: today + ' 00:00:00',
        confirmEndTime: today + ' 23:59:59',
        siteIdList: [],
        vehicleNumbers: ''
      };
    },
    searchClick() {
      if (!this.queryForm.params.confirmStartTime || !this.queryForm.params.confirmEndTime) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      this.queryForm.pageNumber = 1;
      localStorage.setItem('tcvcqForm', JSON.stringify(this.queryForm));
      this.getTablePage();
    },
    getTablePage() {
      const queryForm = JSON.parse(localStorage.getItem('tcvcqForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      transitVehicleScanConfirmPage(queryForm).then(res => {
        if (res.code===200) {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      });
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('tcvcqForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      transitVehicleScanConfirmPage(queryForm).then(res => {
        if (res.code===200) {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      });
    },
    closeDialog() {
      this.unloadPicture = [];
      this.dialogVisible = false;
    },
    unloadSignImg(row) {
      queryUnloadImages(row.id).then(res => {
        if (res.code===200) {
          this.unloadPicture = res.data;
          this.dialogVisible = true;
        }
      }).catch({});
    },
    download() {
      this.isClickAll = true;
      const query = JSON.parse(localStorage.getItem('tcvcqForm')) || this.queryForm;
      const queryForm = {
        params: query.params
      };
      transitVehicleScanConfirmExport(queryForm).then(res => {
        if (res.code===200) {
          this.isClickAll = false;
          const h = this.$createElement;
          this.$msgbox({
            // 提示
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
              h('span', this.$t('collectionCenter.exportChangeTip')),
              // 请勿重复导出!
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // 立即前往
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // 关闭
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/views/resource/exportTaskList' });
            }
          }).catch(() => {});
        }
      }).catch(err => {
        console.log(err);
        this.isClickAll = false;
      });
    },
    delUnloadRow(row) {
      unloadVehicleCancleDel(row.id).then(res => {
        if (res.code===200) {
          this.searchClick();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.confirmDialogClass {
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirmDialogClass ::v-deep .el-dialog {
  width: 260px;
  margin: 0 !important;
}
.confirmDialogClass ::v-deep .el-dialog .el-dialog__body {
  font-size: 20px;
}
.dialog-footer{
  text-align: center;
}
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.BlockNewlineClass ::v-deep textarea.el-textarea__inner {
  min-height: 40px !important;
  max-height: 120px !important;
}
// 防止按钮重复点击
@keyframes throttle{
  from{
    filter: opacity(40%);
    pointer-events: none;
  }
  to{
    pointer-events: all;
  }
}
.onlyClickOnce10{
  animation: throttle 10s step-end forwards;
}
.onlyClickOnce10:active{
  filter: opacity(40%);
  animation: none;
}

.selectClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selectClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.selectClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
  width: 100%;
}
.selectClass ::v-deep .el-select {
  width: 100%;
}
.disBtn{
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #dcdfe6;
  pointer-events: none;
  cursor: not-allowed;
}
</style>
