<template><!-- 导入快录 -->
  <el-dialog
    :title="$t('receivablePayable.BatchImportClips')"
    :visible.sync="isBool"
    width="400px"
  >
    <div class="flex-c">
      <el-button
        icon="el-icon-download"
        size="small"
        type="primary"
        :loading="loading"
        style="margin: 0 10px 10px 0"
        @click="DownloadTemplate"
      >{{ $t('receivablePayable.DownloadTheTemplate') }}</el-button>
      <upload-excel
        :on-success="importLogistics"
        :but-name="$t('receivablePayable.BatchImportClips')"
      />
    </div>
  </el-dialog>
</template>

<script>
import uploadExcel from '@/components/UploadExcel';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { goFileUploadManagement } from '@/utils/goExportList.js';
import {
  apiPayableSiteExpenseBatchFastInputFee
} from '@/api/finance/receivables';
import i18n from '@/lang';

const str1 = i18n.t('receivablePayable.ThirdPartyCollection'); // 第三方收款
const str2 = i18n.t('receivablePayable.AnjunCottage'); // 安俊
const str3 = i18n.t('receivablePayable.AJDDispatch'); // AJD
const str4 = i18n.t('receivablePayable.DSPDispatch'); // DSP

export default {
  name: 'ImportQuickRecording',

  components: {
    uploadExcel
  },
  props: {
    // 网点
    outletsList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 获取操作人
    operatorList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 费用类型
    feeTypeList: {
      type: Array,
      default: () => {
        return [];
      }
    },

    // 账单类型
    payableType: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      isBool: false
    };
  },
  methods: {
    open() {
      this.isBool = true;
    },
    // 下载模板
    DownloadTemplate() {
      if ([1, 2].includes(this.payableType)) {
        this.operationBtn();
      } else {
        this.operationBtn_two();
      }
    },
    // 第三方/安俊
    async operationBtn() {
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const Sheet2 = wb.addWorksheet('Sheet2'); // 费用类型
        const Sheet3 = wb.addWorksheet('Sheet3'); // 揽收类型
        const Sheet4 = wb.addWorksheet('Sheet4'); // 网点
        const Sheet5 = wb.addWorksheet('Sheet5'); // 操作人
        const Sheet6 = wb.addWorksheet('Sheet6'); // 运营模式
        // header表头
        Sheet1.columns = [
          {
            header: '*' + that.$t('receivablePayable.TicketNumber') + '(QR)', // 业务单号',
            key: 'waybillNumber',
            width: 40
          },
          {
            header: '*' + that.$t('orderCenterCont.customer'), // 客户',
            key: 'customerName',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.Outlets'), // 网点',
            key: 'siteId',
            width: 40
          },
          {
            header: '*' + that.$t('receivablePayable.BillableWeight'), // 计费重量',
            key: 'chargeWeight',
            width: 40
          },
          {
            header: '*' + that.$t('newOrder.CollectionType'), // '揽收类型',
            key: 'collectType',
            width: 40
          },
          {
            header: '*' + that.$t('collectionCenter.operationMode'), // '运营模式',
            key: 'operationMode',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.ExpenseType'), // 费用类型',
            key: 'feeCode',
            width: 40
          },
          {
            header: '*' + that.$t('receivablePayable.AmountDue'), // 应付金额',
            key: 'fee',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.BusinessTime') + '(' + that.$t('customerManagements.format') + '：2022-01-01 00:00:00)', // '业务时间(格式：2022-01-01 00:00:00)',
            key: 'operateTime',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.Operator') + '(' + that.$t('basicData.EmployeeAccountName') + ')', // 操作人',
            key: 'operator',
            width: 40
          },
          {
            header: that.$t('receivablePayable.remark'), // '备注',
            key: 'remark',
            width: 40
          }
        ];
        Sheet1.mergeCells('A2:J2');
        // 每次仅导入10w条  // 不可更改此模板;
        Sheet1.getCell('A2').value = '1、' + that.$t('receivablePayable.Only100000PiecesImport') + '  2、' + that.$t('receivablePayable.ThisTemplateCannotBeChanged');
        Sheet1.getRow(2).getCell(1).font = {
          color: { argb: 'ff0000' }
        };

        Sheet2.columns = [
          {
            header: that.$t('basicData.ExpenseType'), // '费用类型',
            key: 'feeCode',
            width: 40
          }
        ];
        const Sheet2_data = [];
        that.feeTypeList.map(item => {
          Sheet2_data.push({
            feeCode: item.displayName + '-' + item.code,
            width: 20
          });
        });
        Sheet2.addRows(Sheet2_data);

        Sheet3.columns = [
          {
            header: that.$t('newOrder.CollectionType'), // 揽收类型',
            key: 'collectType',
            width: 40
          }
        ];
        const Sheet3_data = [
          { collectType: that.$t('collectionCenter.dropOff') + '-' + 1 },
          { collectType: that.$t('collectionCenter.doorPickUp') + '-' + 2 }
        ];
        Sheet3.addRows(Sheet3_data);

        Sheet4.columns = [
          {
            header: that.$t('basicData.Outlets'), // 网点',
            key: 'siteId',
            width: 40
          }
        ];
        const Sheet4_data = [];
        that.outletsList.map(item => {
          Sheet4_data.push({
            siteId: item.name + '-' + item.id
          });
        });
        Sheet4.addRows(Sheet4_data);

        Sheet5.columns = [
          {
            header: that.$t('basicData.Operator'), // 操作人',
            key: 'operator',
            width: 40
          }
        ];
        const Sheet5_data = [];
        that.operatorList.map(item => {
          Sheet5_data.push({
            operator: item.username + '-' + item.id
          });
        });
        Sheet5.addRows(Sheet5_data);

        Sheet6.columns = [
          {
            header: that.$t('collectionCenter.operationMode'), // 运营模式',
            key: 'operationMode',
            width: 40
          }
        ];
        const Sheet6_data = [
          { operationMode: that.$t('collectionCenter.directOperation') + '-' + 0 },
          { operationMode: that.$t('collectionCenter.thirdPartyCooperation') + '-' + 1 }
        ];
        Sheet6.addRows(Sheet6_data);

        new Array(50000).fill(0).forEach((_, idx) => {
          const row = idx + 2;

          // 渲染网点下拉框
          Sheet1.getCell(row, 3).dataValidation = {
            type: 'list',
            formulae: [`=Sheet4!$A$2:$A${Sheet4_data.length + 1}`]
          };

          // 揽收类型
          Sheet1.getCell(row, 5).dataValidation = {
            type: 'list',
            formulae: [`=Sheet3!$A$2:$A${Sheet3_data.length + 1}`]
          };

          // 运营模式
          Sheet1.getCell(row, 6).dataValidation = {
            type: 'list',
            formulae: [`=Sheet6!$A$2:$A${Sheet6_data.length + 1}`]
          };

          // 费用类型
          Sheet1.getCell(row, 7).dataValidation = {
            type: 'list',
            formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
          };

          // 操作人
          Sheet1.getCell(row, 10).dataValidation = {
            type: 'list',
            formulae: [`=Sheet5!$A$2:$A${Sheet5_data.length + 1}`]
          };

          // 业务时间 设置单元格为纯文本格式
          Sheet1.getCell(row, 9).numFmt = '@';
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = that.setFileName();
        saveAs(blob, saveName);
        return;
      }

      try {
        await ddd();
      } catch (error) {
        console.error(error);
      }
    },
    // AJD/DSP
    async operationBtn_two() {
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const Sheet2 = wb.addWorksheet('Sheet2'); // 费用类型
        const Sheet4 = wb.addWorksheet('Sheet4'); // 网点
        const Sheet5 = wb.addWorksheet('Sheet5'); // 操作人
        // header表头
        Sheet1.columns = [
          {
            header: '*' + that.$t('receivablePayable.TicketNumber') + '(QR)', // 业务单号',
            key: 'waybillNumber',
            width: 40
          },
          {
            header: '*' + that.$t('orderCenterCont.customer'), // 客户',
            key: 'customerName',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.Outlets'), // 网点',
            key: 'siteId',
            width: 40
          },
          {
            header: '*' + that.$t('receivablePayable.BillableWeight'), // 计费重量',
            key: 'chargeWeight',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.ExpenseType'), // 费用类型',
            key: 'feeCode',
            width: 40
          },
          {
            header: '*' + that.$t('receivablePayable.AmountDue'), // 应付金额',
            key: 'fee',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.BusinessTime') + '(' + that.$t('customerManagements.format') + '：2022-01-01 00:00:00)', // '业务时间(格式：2022-01-01 00:00:00)',
            key: 'operateTime',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.Operator') + '(' + that.$t('basicData.EmployeeAccountName') + ')', // 操作人',
            key: 'operator',
            width: 40
          },
          {
            header: that.$t('receivablePayable.remark'), // '备注',
            key: 'remark',
            width: 40
          }
        ];
        Sheet1.mergeCells('A2:J2');
        // 每次仅导入10w条  // 不可更改此模板;
        Sheet1.getCell('A2').value = '1、' + that.$t('receivablePayable.Only100000PiecesImport') + '  2、' + that.$t('receivablePayable.ThisTemplateCannotBeChanged');
        Sheet1.getRow(2).getCell(1).font = {
          color: { argb: 'ff0000' }
        };

        Sheet2.columns = [
          {
            header: that.$t('basicData.ExpenseType'), // '费用类型',
            key: 'feeCode',
            width: 40
          }
        ];
        const Sheet2_data = [];
        that.feeTypeList.map(item => {
          Sheet2_data.push({
            feeCode: item.displayName + '-' + item.code,
            width: 20
          });
        });
        Sheet2.addRows(Sheet2_data);

        Sheet4.columns = [
          {
            header: that.$t('basicData.Outlets'), // 网点',
            key: 'siteId',
            width: 40
          }
        ];
        const Sheet4_data = [];
        that.outletsList.map(item => {
          Sheet4_data.push({
            siteId: item.name + '-' + item.id
          });
        });
        Sheet4.addRows(Sheet4_data);

        Sheet5.columns = [
          {
            header: that.$t('basicData.Operator'), // 操作人',
            key: 'operator',
            width: 40
          }
        ];
        const Sheet5_data = [];
        that.operatorList.map(item => {
          Sheet5_data.push({
            operator: item.username + '-' + item.id
          });
        });
        Sheet5.addRows(Sheet5_data);

        new Array(55000).fill(0).forEach((_, idx) => {
          const row = idx + 2;

          // 渲染网点下拉框
          Sheet1.getCell(row, 3).dataValidation = {
            type: 'list',
            formulae: [`=Sheet4!$A$2:$A${Sheet4_data.length + 1}`]
          };

          // 费用类型
          Sheet1.getCell(row, 5).dataValidation = {
            type: 'list',
            formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
          };

          // 操作人
          Sheet1.getCell(row, 8).dataValidation = {
            type: 'list',
            formulae: [`=Sheet5!$A$2:$A${Sheet5_data.length + 1}`]
          };

          // 业务时间 设置单元格为纯文本格式
          Sheet1.getCell(row, 7).numFmt = '@';
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = that.setFileName();
        saveAs(blob, saveName);
        return;
      }

      try {
        await ddd();
      } catch (error) {
        console.error(error);
      }
    },
    // 导入
    async importLogistics(importData) {
      const importList = importData[0].results.splice(1); // 解析数据
      const headObj = [1, 2].includes(this.payableType) ?
      [
        'waybillNumber', 'customerName', 'siteId', 'chargeWeight', 'collectType', 'operationMode', 'feeCode', 'fee', 'operateTime', 'operator', 'remark'
      ] : [
        'waybillNumber', 'customerName', 'siteId', 'chargeWeight', 'feeCode', 'fee', 'operateTime', 'operator', 'remark'
      ]

      const dataArr= importList.map(item => {
        const data = {};
        const headerList = Object.keys(item); // 表头列表
        for (let i = 0; i < headerList.length; i++) {
          const key = headerList[i];
          try {
            if( headObj[i] === 'siteId' || headObj[i] === 'feeCode' || headObj[i] === 'operationMode' || headObj[i] === 'collectType' || headObj[i] === 'operator') {
              data[headObj[i]] = item[key] ? item[key].split('-').slice(-1)[0] : '';;
            } else if(headObj[i] === 'operateTime') {
              // 此处可以添加时间格式校验逻辑
              data[headObj[i]] = item[key]
            } else {
              data[headObj[i]] = item[key] || '';
            }
          } catch (error) {
            // console.error(error, '数据格式错误');
            // 可以在此抛出错误或记录错误，根据实际需求处理
            this.$message.error(this.$t('receivablePayable.ImportTemplateIncorrect'));
            throw new Error(error);
          }
        }
        return data;
      });
      const list = dataArr.map(item => {
        item.businessType = [1, 2].includes(this.payableType) ? 2 : 3;
        return item;
      })
      // return;
      try {
        const res = await apiPayableSiteExpenseBatchFastInputFee({
          list: list,
          payableType: this.payableType
        });
        if (res.code === 200) {
          await goFileUploadManagement(this);
          this.isBool = false;
        }
      } catch (error) {
        console.error('导入费用时发生错误:', error);
      }
    },

    setFileName() {
      const saveName = (() => {
        switch (this.payableType) {
          case 1:
            return str2;
          case 2:
            return str1;
          case 3:
            return str3;
          default:
            return str4;
        }
      })() + this.$t('receivablePayable.BatchImportClips') + '(' + this.$t('basicData.payable') + ')' + '.xlsx';
      return saveName;
    },
  }
};
</script>

<style scoped>
.flex-c {
  display: flex;
  flex-wrap: wrap;
}
</style>
