<template>
  <div class="app-container job">
    <el-card>
      <div>
        <!-- <el-row type="flex" justify="start">
          <el-col :span="8">
            <div>
              <el-button v-permit:remove="'but:user-center:job-management:find'" type="primary" icon="el-icon-search" size="mini" @click="getData">查询</el-button>
            </div>
          </el-col>
        </el-row> -->

        <!-- <el-divider /> -->
        <div style="display: flex; justify-content: space-between; margin-bottom: 5px">
          <span>
            <el-button
              v-permit:remove="'but:user-center:job-management:add'"
              type="primary"
              icon="el-icon-plus"
              size="mini"
              @click="addOpen"
            >{{ $t('jobManagement.add') }}</el-button><!-- 新增 -->
          </span>
        </div>
      </div>
      <div>
        <el-table :data="tableData" border>
          <el-table-column type="index" width="50" />
          <!-- 名称 -->
          <el-table-column prop="name" :label="$t('jobManagement.name')" />
          <!-- 所属部门 -->
          <el-table-column prop="positionName" :label="$t('jobManagement.SubordinateDepartments')" />
          <!-- 操作 -->
          <el-table-column :label="$t('jobManagement.operation')" width="80" fixed="right">
            <template slot-scope="scope">
              <!-- 编辑 -->
              <el-button
                v-permit:remove="'but:user-center:job-management:edit'" type="info" size="mini"
                @click.stop="addOpen(scope.row)"
              >{{ $t('jobManagement.edit') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getData"
          @size-change="getData"
        />
      </div>
    </el-card>

    <!-- 新增/编辑 -->
    <div v-if="addDialogObj.dialogVisible">
      <el-dialog
        :title="addDialogObj.title"
        :visible.sync="addDialogObj.dialogVisible"
        width="30%"
        :close-on-click-modal="true"
      >
        <el-form
          ref="ruleForm" :model="addDialogObj.ruleForm" :rules="addDialogObj.rules"
          label-width="100px" class="demo-ruleForm"
        >
          <!-- 部门 -->
          <el-form-item :label="$t('jobManagement.department')" prop="orgIds">
            <el-cascader
              v-model.trim="addDialogObj.ruleForm.orgIds"
              :options="organizationTree"
              :show-all-levels="false"
              :props="{ checkStrictly: true, value: 'id', label: 'name' }"
              clearable
              :placeholder="$t('jobManagement.choose')"
              style="width:100%;"
            /><!-- 请选择部门 -->
          </el-form-item>
          <!-- 职位名称 -->
          <el-form-item :label="$t('jobManagement.JobTitle')" prop="name">
            <!-- 请输入名称 -->
            <el-input v-model.trim="addDialogObj.ruleForm.name" :placeholder="$t('jobManagement.input')" />
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogObj.dialogVisible = false">{{ $t('jobManagement.cancel') }}</el-button><!-- 取消 -->
          <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('jobManagement.confirm') }}</el-button><!-- 确定 -->
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiGetPositionPage, apiGetPositionEdit, apiGetPositionAdd } from '@/api/user';
import { asyncGetPositionList, getOrgNames } from '@/utils/asyncTools';
import { getOrgIdAndNameObj, getOrganizationTreeObj } from '@/enums/storageEnum';
export default {
  name: 'JobM',
  data() {
    return {
      total: 0,
      organizationTree: [], // 公司部门
      orgIdAndName: {}, // 所有职位
      tableData: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          id: ''
        }
      },

      // 弹框
      addDialogObj: {
        dialogVisible: false,
        title: '',
        ruleForm: {
          id: '',
          createUser: '', // 创建人ID
          updateUser: '', // 更新人ID
          name: '', // 当前职务
          orgId: '', // 部门
          orgIds: [] // 完整部门
        },

        rules: {
          // '请选择部门'
          orgIds: [{ required: true, message: this.$t('jobManagement.pleaseChoose'), trigger: 'change' }],
          // '请输入职位名称'
          name: [{ required: true, message: this.$t('jobManagement.pleaseInput'), trigger: 'blur' }]
        }
      }
    };
  },

  created() {
    this.organizationTree = getOrganizationTreeObj();
    this.orgIdAndName = getOrgIdAndNameObj();

    this.getData();
  },

  methods: {

    /**
     * 获取数据
     */
    getData() {
      apiGetPositionPage(this.queryForm).then(data => {
        if (data.code===200) {
          this.total = data.data.total;
          this.tableData = data.data.records.map(item => {
            return {
              ...item,
              positionName: getOrgNames(item.orgIds, this.orgIdAndName, '-')
            };
          });
        }
      }).catch(err => {
        console.log(err);
      });
    },

    /**
     * 新增弹框
     */
    addOpen(data) {
      // 重置
      for (const key in this.addDialogObj.ruleForm) {
        if (key === 'orgIds') {
          this.addDialogObj.ruleForm[key] = [];
        } else {
          this.addDialogObj.ruleForm[key] = '';
        }
      }

      if (data.orgIds) {
        const { id, name, orgIds } = data;

        this.addDialogObj.title = this.$t('jobManagement.edit'); // '编辑'
        this.addDialogObj.ruleForm.id = id;
        this.addDialogObj.ruleForm.name = name;
        this.addDialogObj.ruleForm.orgIds = orgIds.split(',').map(item => Number(item));
      } else {
        this.addDialogObj.title = this.$t('jobManagement.add'); // '新增'
      }
      this.addDialogObj.dialogVisible = true;
    },

    /**
     * 新增
     */
    asyncPositionAdd() {
      const { name, orgIds } = this.addDialogObj.ruleForm;
      const obj = {
        name,
        orgId: orgIds[orgIds.length - 1],
        orgIds: orgIds.join(',')
      };
      apiGetPositionAdd(obj).then(data => {
        if (data.code===200) {
          this.$message({
            message: this.$t('jobManagement.AddedSuccessfully'), // '添加成功'
            type: 'success'
          });
          this.addDialogObj.dialogVisible = false;
          // asyncGetPositionList().then(() => {
          //   this.getData();
          // });
          return asyncGetPositionList();
        }
      }).then(() => {
        this.getData();
      }).catch(err => {
        console.log(err);
      });
    },

    /**
     * 编辑
     */
    asyncPositionEdit() {
      const { name, orgIds, id } = this.addDialogObj.ruleForm;
      const obj = {
        id,
        name,
        orgId: orgIds[orgIds.length - 1],
        orgIds: orgIds.join(',')
      };
      apiGetPositionEdit(obj).then(data => {
        if (data.code===200) {
          this.$message({
            message: this.$t('jobManagement.EditSuccessful'), // '修改成功'
            type: 'success'
          });
          this.addDialogObj.dialogVisible = false;
          asyncGetPositionList().then(() => {
            this.getData();
          });
        }
      }).catch(err => {
        console.log(err);
      });
    },

    /**
     * 提交
     */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }
        if (this.addDialogObj.ruleForm.id) {
          // 修改
          this.asyncPositionEdit();
        } else {
          this.asyncPositionAdd();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>

.app-container.job ::v-deep .el-form-item__error{
  top: 40px;
}
</style>
