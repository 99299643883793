<template>
  <el-form
    ref="ruleForm" :model="ruleForm" :rules="rules"
    size="small"
    :label-width="'auto'" class="demo-ruleForm"
    :label-position="$i18n.locale !== 'zh'?'top':'right'"
  >
    <el-row :gutter="20">
      <el-col :span="8">
        <!-- 客户 -->
        <el-form-item :label="$t('GlobalSetObj.client')" prop="customerId">
          <CustomerSelectCmp
            v-model="ruleForm.customerId" :disabled="ids !== 0" style="width: 100%"
            @change="() => {onClearList(); onChangeCustomer();}"
          />
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item :label="$t('Resource.applicant')" prop="applicant">
          <el-input
            v-model.trim="ruleForm.applicant"
            maxlength="40"
            show-word-limit
            :placeholder="$t('GlobalSetObj.pleaseInputContent')"
            style="width: 100%"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 申请人联系方式 -->
        <el-form-item :label="$t('ClaimsManagement.ApplicantsContactInformation')" prop="applicantContactInformation">
          <el-input
            v-model.trim="ruleForm.applicantContactInformation"
            :placeholder="$t('GlobalSetObj.pleaseInputContent')"
            maxlength="40"
            show-word-limit
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 原 理赔节点-->
        <!-- 现 业务类型 -->
        <el-form-item :label="$t('collectionCenter.businessT')" prop="claimsNode">
          <el-select
            v-model="ruleForm.claimsNode" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%"
            @change="onClearList"
          >
            <!-- 揽收 -->
            <el-option
              :label="CLAIMSNODE_TYPE_OBJ['2'].name"
              :value="CLAIMSNODE_TYPE_OBJ['2'].value"
            />
            <!-- 派送 -->
            <el-option
              :label="CLAIMSNODE_TYPE_OBJ['3'].name"
              :value="CLAIMSNODE_TYPE_OBJ['3'].value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 理赔金额 -->
        <el-form-item :label="$t('ClaimsManagement.AmountOfClaim')" prop="claimAmount">
          <el-input-number
            v-model="ruleForm.claimAmount" :min="0" :precision="3"
            :controls="false" style="width: 100%;"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 审核人 -->
        <el-form-item :label="$t('Resource.Reviewer')" prop="reviewer">
          <UserSelectCmp
            ref="userSelectRef" v-model="ruleForm.reviewer" :user-name="userName"
            :is-organization-name="true" style="width: 100%"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 揽收类别 当 理赔节点选中“揽收”时显示以及必填，否则不显示 -->
        <el-form-item v-if="ruleForm.claimsNode === 2" :label="$t('basicData.PickupCategory')" prop="orderType">
          <el-select
            v-model="ruleForm.orderType" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%"
            @change="onClearList"
          >
            <!--普通揽收-->
            <!-- <el-option :label="$t('basicData.OrdinaryPickUp')" :value="2" /> -->
            <!--备货揽收-->
            <!-- <el-option :label="$t('basicData.StockingAndPickup')" :value="3" /> -->
            <el-option
              v-for="item in orderTypeList" :key="item.value" :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 理赔原因 -->
        <el-form-item :label="$t('ClaimsManagement.CauseOfClaim')" prop="claimReason">
          <el-input
            v-model="ruleForm.claimReason"
            style="width: 100%"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            :placeholder="$t('GlobalSetObj.pleaseInputContent')"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <!-- 商户 -->
        <el-form-item :label="$t('collectionCenter.CommercialOwner')" prop="merchantsId">
          <CommercialOwnerCmp
            style="width: 100%"
            :merchants-id="ruleForm.merchantsId"
            :merchants-name="ruleForm.merchantsName"
            :customer-id="ruleForm.customerId"
            @change="onChangeCommercial"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row class="upload">
      <el-col :span="16">
        <el-form-item :label="$t('customerManagements.enclosure')" prop="attachmentUrl">
          <UploadCmp
            :drag="true" :multiple="true" :file-arr="fileArr"
            :auto-upload="true" :limit="5" @change="onFileChange"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>

import CustomerSelectCmp from '@/components/CustomerSelectCmp/index.vue';
import CommercialOwnerCmp from './CommercialOwnerCmp.vue';
import UploadCmp from '@/views/claimsManagement/claimStatement/cmp/UploadCmp.vue';
import { apiOperationBigBagInfoPage } from '@/api/claimsManagement/rules';
import UserSelectCmp from '@/views/claimsManagement/claimsReviewRules/cmp/UserSelectCmp.vue';
import { cloneDeep } from 'lodash-es';
import { basicsRow } from './utils.js';
import { COLLECT_TYPE_OBJ, CLAIMSNODE_TYPE_OBJ } from '@/views/claimsManagement/claimList/utils.js';
import { getUserIdAndNameObj } from '@/enums/storageEnum';

// 默认审批人搜索
const reviewerQuery = { pageNumber: 1, pageSize: 10, params: { customerId: '' }};

export default {
  name: '',

  components: {
    CustomerSelectCmp,
    UploadCmp,
    UserSelectCmp,
    CommercialOwnerCmp
  },

  props: {
    ids: {
      type: Number,
      default: 0
    },

    // 最大金额
    totalValue: {
      type: Number,
      default: 0
    },

    // 总票数
    totalLen: {
      type: Number,
      default: 0
    }
  },

  data() {
    // 理赔金额校验
    const claimAmountCheck = (rule, value, callback) => {
      // 没有添加关联订单
      if (this.totalLen === 0) {
        return callback();
      }

      if (!value) {
        // return callback(new Error('请输入理赔金额'));
        return callback(new Error(this.$t('GlobalSetObj.pleaseInput')));
      }

      if (value > this.totalValue) {
        return callback(new Error(`理赔金额不能大于总金额(${this.totalValue})`));
      }

      callback();
    };

    // 附件校验
    const attachmentUrlCheck = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请上传附件'));
      }

      if (Array.isArray(value) && value.length === 0) {
        return callback(new Error('请上传附件'));
      }

      callback();
    };

    return {
      CLAIMSNODE_TYPE_OBJ,
      ruleForm: {
        // customerId: '', // 客户
        // applicant: '', // 申请人
        // applicantContactInformation: '', // 申请人联系方式
        // claimsNode: 2, // 理赔节点
        // claimAmount: '', // 理赔金额
        // reviewer: '', // 审批人
        // orderType: '', // 揽收类别
        // claimReason: '', // 理赔原因
        // attachmentUrl: ''// 附件
        ...basicsRow
      },

      userName: '', // 审批人，只为选择客户后设置默认审批人的时候才使用

      fileArr: [], // 附件回显

      index: 0,

      rules: {
        customerId: [
          {
            required: true,
            message: this.$t('GlobalSetObj.pleaseChoose'), // 请选择客户
            trigger: 'change'

          }
        ],
        applicant: [
          {
            required: true,
            message: this.$t('GlobalSetObj.pleaseInput'), // 请输入申请人
            trigger: 'blur'
          }
        ],
        applicantContactInformation: [
          {
            required: true,
            message: this.$t('GlobalSetObj.pleaseInput'), // 请输入联系人方式
            trigger: 'blur'
          }
        ],
        claimsNode: [
          {
            required: true,
            message: this.$t('GlobalSetObj.pleaseChoose'), // 业务类型
            trigger: 'change'
          }
        ],
        claimAmount: [
          {
            required: true,
            // validator: claimAmountCheck,
            trigger: ['change', 'blur']
          }
        ],
        reviewer: [
          {
            required: true,
            message: this.$t('GlobalSetObj.pleaseChoose'), // 请选择审批人
            trigger: 'change'
          }
        ],
        orderType: [
          {
            required: true,
            message: this.$t('GlobalSetObj.pleaseChoose'), // 请选择揽收类别
            trigger: 'change'
          }
        ],
        claimReason: [
          {
            required: true,
            message: this.$t('GlobalSetObj.pleaseInput'), // 请输入理赔原因
            trigger: 'blue'
          }
        ],

        merchantsId: [
          {
            required: false,
            message: this.$t('GlobalSetObj.pleaseChoose'), // 请选择
            trigger: 'change'
          }
        ],

        attachmentUrl: [{ required: true, validator: attachmentUrlCheck, trigger: 'change' }]
      }
    };
  },

  computed: {
    // 揽收类别
    orderTypeList() {
      return Object.values(COLLECT_TYPE_OBJ).filter(item => {
        // 暂时不显示 快递订单
        return item.value !== 1;
      });
    },

    getCustomerIdAndClaimsNode() {
      const { claimsNode, customerId } = this.ruleForm;
      return { claimsNode, customerId };
    }
  },

  watch: {
    'ruleForm': {
      deep: true,
      immediate: true,
      handler() {
        const row = cloneDeep(this.ruleForm);
        this.$emit('change', row);
      }
    },

    // 如果客户和理赔节点变了，重新设置默认审核人
    'getCustomerIdAndClaimsNode': {
      deep: true,
      // immediate: true,
      handler() {
        // 编辑
        if (this.ids && this.index === 0) {
          // 第一次
          return;
        }
        this.setDefaultReviewer();
      }
    }

  },

  created() {
    // this.CLAIMSNODE_TYPE_OBJ = CLAIMSNODE_TYPE_OBJ;
    // this.COLLECT_TYPE_OBJ = COLLECT_TYPE_OBJ;

    this.userObj = getUserIdAndNameObj();
  },

  methods: {

    // 表单提交
    submitForm() {
      return this.$refs['ruleForm'].validate().then((valid) => {
        return true;
      }).catch((e) => {
        console.error(e);
        return false;
      });
    },

    // 设置表单数据
    setFormData(val) {
      const { customerId, applicant, applicantContactInformation, claimsNode, claimAmount, reviewer, source, claimReason, attachmentUrl, reviewerUsername, merchantsId, merchantsName } = val;

      // const attachmentUrlArr = attachmentUrl ? attachmentUrl.split(',') : [];
      let attachmentUrlArr = [];
      if (attachmentUrl) {
        attachmentUrlArr = attachmentUrl.split(',').map((item, index) => {
          return { name: `file${index + 1}`, url: item };
        });

        this.fileArr = cloneDeep(attachmentUrlArr);
      }

      // 设置默认审批人
      if (reviewerUsername) {
        this.userName = reviewerUsername;
      }

      Object.assign(this.ruleForm, {
        customerId,
        applicant,
        applicantContactInformation,
        claimsNode,
        claimAmount,
        reviewer,
        orderType: source,
        claimReason,
        merchantsId,
        merchantsName,
        attachmentUrl: attachmentUrlArr
      });
    },

    // 获取表单数据（仅用于父级调用），数据已经处理，可以直接提交
    getFormData() {
      const obj = cloneDeep(this.ruleForm);
      obj.attachmentUrl = obj.attachmentUrl.map(item => item.url).join(','); // 附件处理

      return obj;
    },

    // 上传附件
    onFileChange(val) {
      this.ruleForm.attachmentUrl = val;
    },

    // 商户选择
    onChangeCommercial(val) {
      this.ruleForm.merchantsId = val;
      this.ruleForm.merchantsName = '';// 清空回显的商户名称
    },

    // 设置默认审批人
    setDefaultReviewer() {
      const { customerId, claimsNode } = this.ruleForm;

      // console.log('123', customerId, claimsNode);

      // 未选择
      if (!customerId || !claimsNode) {
        return;
      }

      const data = {
        ...reviewerQuery
      };
      data.params.customerId = customerId;

      // 未选择默认审批人
      apiOperationBigBagInfoPage(data, false).then(res => {
        const { records } = res.data;
        if (!records || records.length === 0) {
          return;
        }

        // 找到设置了默认审批人的第一条
        const row = records.find(item => {
          // 匹配客户 + 理赔节点 + 存在默认审核人
          return item.customerId === customerId && item.claimsNode === claimsNode && item.defaultReviewer;
        });

        // console.log(row, 'row');

        if (row) {
          const { defaultReviewer } = row;
          this.ruleForm.reviewer = defaultReviewer;
          this.userName = this.userObj[defaultReviewer];
          // console.log(this.userName, 'this.userName');
          this.$refs.userSelectRef.asyncGetUserPage(this.userName);
          return;
        }

        // 清空审批人
        this.ruleForm.reviewer = '';
        // 重置下拉列表
        this.$refs.userSelectRef.asyncGetUserPage();

        this.index += 1;
      }).catch(err => {
        console.error(err);
      });
    },

    // 客户变动了
    onChangeCustomer() {
      // console.log('客户变动了');
      this.ruleForm.merchantsId = '';
      this.ruleForm.merchantsName = '';
    },

    /**
     * 修改了 客户、理赔节点、揽收类别的时候，需要清空关联订单
     */
    onClearList() {
      // console.log('清空关联订单');

      // 如果揽收节点不为 2（揽收）
      if (this.ruleForm.claimsNode !== COLLECT_TYPE_OBJ[2].value) {
        this.ruleForm.orderType = undefined;
      }

      this.$emit('clearList');
    }
  }

};
</script>

<style scoped>
.upload /deep/.el-upload {
  width: 100%;
}
.upload /deep/.el-upload-dragger {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #000; */
}

.upload /deep/.el-upload-dragger .el-icon-upload {
  margin: 0 10px 0 0;
  font-size: 24px;
}
</style>
