<template>
  <div>
    <div class="app-container" style="height: calc(100vh - 130px);overflow-y: auto;">
      <el-form
        ref="form"
        :model="queryForm"
        label-width="160px"
        size="small"
        :label-position="$i18n.locale !== 'zh' ? 'top' : 'right'"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--应收主体-->
              <el-form-item :label="$t('basicData.businessPrincipal')">
                <el-select
                  v-model="queryForm.params.providerId"
                  :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                  :remote-method="getProviderPage"
                  clearable
                  filterable
                  remote
                  reserve-keyword
                  style="width: 100%;"
                  @change="providerInfoChange()"
                  @focus="getProviderPage()"
                >
                  <el-option
                    v-for="(item, ind) in providerList"
                    :key="ind"
                    :label="item.providerName + '（' + item.code + '）'"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--网点-->
              <el-form-item :label="$t('basicData.Outlets')">
                <el-select
                  v-model="queryForm.params.siteId"
                  :loading="isSiteId"
                  :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                  :remote-method="getSitePageList"
                  clearable
                  filterable
                  remote
                  reserve-keyword
                  style="width: 100%;"
                  @change="changeOutlets"
                  @focus="getSitePageList()"
                >
                  <!--请先选择应收主体-->
                  <el-option
                    v-for="item in outletsList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--客户-->
              <el-form-item :label="$t('receivablePayable.customer')">
                <el-select
                  v-model="queryForm.params.customerId"
                  :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                  :remote-method="getCustomerPage"
                  clearable
                  filterable
                  remote
                  reserve-keyword
                  style="width: 100%;"
                  @change="changeCustomer"
                  @focus="getCustomerPage()"
                >
                  <el-option
                    v-for="(item, ind) in customerList"
                    :key="ind"
                    :label="item.customerAlias + '(' + item.username + ')'"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <!--商户-->
            <el-form-item :label="$t('collectionCenter.CommercialOwner')">
              <el-input
                v-model="queryForm.params.merchantName"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
                clearable
                style="width:100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-form-item>
                <template slot="label">
                  <el-select v-model="queryForm.params.timeField" :placeholder="$t('operationCenter.PleaseSelect')">
                    <!--业务时间-->
                    <el-option :label="$t('basicData.BusinessTime')" value="operate_time" />
                    <!--揽收入库时间-->
                    <!--<el-option key="2" :label="$t('basicData.TimeCollectIntoTreasury')" :value="2" />-->
                    <!-- 客户出仓时间 -->
                    <el-option :label="$t('newOrder.clientExitTime')" value="customer_send_time" />
                  </el-select>
                </template>
                <!--查询时间-->
                <el-date-picker
                  v-model="queryDate"
                  :default-time="['00:00:00', '23:59:59']"
                  :end-placeholder="$t('operationCenter.endDate')"
                  :picker-options="dateLimit"
                  :range-separator="$t('operationCenter.to')"
                  :start-placeholder="$t('operationCenter.startDate')"
                  style="width: 100%"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-form-item>
                <template slot="label">
                  <el-select v-model="queryForm.params.numberField" :placeholder="$t('operationCenter.PleaseSelect')">
                    <!--业务单号-->
                    <el-option key="1" :label="$t('receivablePayable.TicketNumber')" value="waybill_number" />
                    <!--客户单号-->
                    <el-option key="2" :label="$t('receivablePayable.CustomerTrackingNumber')" value="customer_number" />
                  </el-select>
                </template>
                <el-input
                  v-model="numberList"
                  :placeholder="numberListTips"
                  :rows="5"
                  type="textarea"
                />
              </el-form-item>

            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--是否已支付-->
              <el-form-item :label="$t('receivablePayable.WhetherItHasBeenPaid')">
                <el-select
                  v-model="queryForm.params.isAccept"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <!--全部-->
                  <el-option key="0" :label="$t('basicData.all')" :value="null" />
                  <!--是-->
                  <el-option key="1" :label="$t('receivablePayable.Paid')" :value="true" />
                  <!--否-->
                  <el-option key="2" :label="$t('receivablePayable.NonPayment')" :value="false" />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--支付方式-->
              <el-form-item :label="$t('ClaimsManagement.PaymentMethod')">
                <el-select
                  v-model="queryForm.params.payType"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <!--全部-->
                  <el-option key="0" :label="$t('basicData.all')" :value="null" />
                  <!-- OnLine -->
                  <el-option key="1" :value="0" label="OnLine" />
                  <!-- OffLine -->
                  <el-option key="2" :value="1" label="OffLine" />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--是否生成对账单-->
              <el-form-item :label="$t('receivablePayable.WhetherToGenerateAStatement')">
                <el-select
                  v-model="queryForm.params.isGenBill"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <!--全部-->
                  <el-option key="0" :label="$t('basicData.all')" :value="null" />
                  <!--是 已生成-->
                  <el-option key="1" :label="$t('receivablePayable.Generated')" :value="true" />
                  <!--否 未生成-->
                  <el-option key="2" :label="$t('receivablePayable.NotGenerated')" :value="false" />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--业务类型-->
              <el-form-item :label="$t('collectionCenter.businessT')">
                <el-select
                  v-model="queryForm.params.businessType"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                  @change="changeBusinessType(queryForm.params.businessType)"
                >
                  <!--全部-->
                  <el-option key="0" :label="$t('basicData.all')" :value="null" />
                  <!--揽收订单-->
                  <el-option :label="BUSINESS_TYPE_ARR[1].label" :value="BUSINESS_TYPE_ARR[1].value" />
                  <!--快递订单-->
                  <el-option :label="BUSINESS_TYPE_ARR[0].label" :value="BUSINESS_TYPE_ARR[0].value" />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--明细业务类型-->
              <el-form-item :label="$t('basicData.明细业务类型')">
                <el-select
                  v-model="queryForm.params.subBusinessType"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <!--全部-->
                  <el-option :label="$t('basicData.all')" :value="null" />
                  <!--揽收订单-->
                  <el-option :label="$t('basicData.CollectionSystemBusiness')" :value="1" />
                  <!--快递订单-->
                  <el-option :label="`${$t('basicData.CourierSystemBusiness')}`" :value="0" />
                  <!--派送-->
                  <el-option :label="`${$t('collectionCenter.dispatch')}`" :value="2" />
                </el-select>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--揽收类别-->
              <el-form-item :label="$t('basicData.CollectionType')">
                <el-select
                  v-model="queryForm.params.collectionCategory"
                  :disabled="queryForm.params.businessType !== 1"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                  @change="changePush"
                >
                  <!--全部-->
                  <el-option key="0" :label="$t('basicData.all')" :value="null" />
                  <!--普通揽收-->
                  <el-option key="1" :label="$t('basicData.OrdinaryPickUp')" :value="0" />
                  <!--备货揽收-->
                  <el-option key="2" :label="$t('basicData.StockingAndPickup')" :value="1" />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col v-if="selectKey === 1" :span="24">
            <div class="grid-content bg-purple">
              <!--批次号-->
              <el-form-item :label="$t('basicData.BatchNo')" prop="billBatchNumber">
                <el-input v-model="queryForm.params.billBatchNumber" :readonly="isReadOnly" />
              </el-form-item>
            </div>
          </el-col>
          <el-col v-if="selectKey === 2" :span="24">
            <div class="grid-content bg-purple">
              <!--收款单号-->
              <el-form-item :label="$t('basicData.ReceiptNumber')" prop="receiptNumber">
                <el-input v-model="queryForm.params.receiptNumber" :readonly="isReadOnly" />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--费用类型-->
              <el-form-item :label="$t('basicData.ExpenseType')" prop="feeName">
                <el-select
                  v-model="queryForm.params.feeCode"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  remote
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in feeTypeOption"
                    :key="item.id"
                    :label="item.displayName"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--订单状态-->
              <el-form-item :label="$t('collectionCenter.OrderStatus')">
                <div v-if="queryForm.params.businessType === 0 || queryForm.params.businessType === 7">
                  <el-select
                    v-model="queryForm.params.status"
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    clearable
                    filterable
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="(item, ind) in WAYBILL_STATUS"
                      :key="ind"
                      :label="item.label"
                      :value="item.code"
                    />
                  </el-select>
                </div>
                <div v-else-if="queryForm.params.businessType === 1 && queryForm.params.collectionCategory === 0">
                  <el-select
                    v-model="queryForm.params.status"
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    clearable
                    filterable
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="(item, index) in ORDER_TYPE_LIST"
                      :key="index"
                      :label="item.collectStatusName"
                      :value="item.collectStatus"
                    />
                  </el-select>
                </div>
                <div v-else-if="queryForm.params.businessType === 1 && queryForm.params.collectionCategory === 1">
                  <el-select
                    v-model="queryForm.params.status"
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    clearable
                    filterable
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="(item, index) in BH_ORDER_TYPE_LIST"
                      :key="index"
                      :label="item.collectStatusName"
                      :value="item.collectStatus"
                    />
                  </el-select>
                </div>
                <div v-else>
                  <el-select
                    v-model="queryForm.params.status"
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    clearable
                    filterable
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="(item, index) in nullList"
                      :key="index"
                      :label="item.x"
                      :value="item.x"
                    />
                  </el-select>
                </div>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="24">
            <!-- 经办人 -->
            <el-form-item :label="$t('basicData.Handler')">
              <AgentCmp :disabled="isJurisdiction" :val="queryForm.params.handlerId" @change="agentChange" />
            </el-form-item>

          </el-col>

          <el-col :span="24">
            <!-- 收件人州 -->
            <el-form-item :label="$t('newOrder.RecipientState')">
              <el-input v-model.trim="queryForm.params.receiveState" :placeholder="$t('GlobalSetObj.pleaseInput')" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!-- 发件人州 -->
            <el-form-item :label="$t('newOrder.SenderState')">
              <el-input v-model.trim="queryForm.params.senderState" :placeholder="$t('GlobalSetObj.pleaseInput')" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div style="text-align: center;border-top: 1px solid #ccc;padding-top: 10px;">
      <!--查 询-->
      <el-button size="small" type="primary" @click="searchForm(1)">{{ $t('basicData.Search') }}</el-button>
      <!--重 置-->
      <el-button size="small" type="success" @click="searchForm(3)">{{ $t('basicData.reset') }}</el-button>
      <!--取 消-->
      <el-button size="small" @click="searchForm(2)">{{ $t('basicData.Cancel') }}</el-button>
    </div>
  </div>
</template>

<script>
import { feePage } from '@/api/finance/partition';
import { apiProviderPage } from '@/api/finance/basicData';
import { apiCustomerPage } from '@/api/customer';
import AgentCmp from './AgentCmp.vue';
import { cloneDeep } from 'lodash-es';
import { apiCommonSite } from '@/api/logisticsOrders';
import { getIsAuth } from '@/views/finance/utils.js';
import { WAYBILL_STATUS, ORDER_TYPE_LIST, BH_ORDER_TYPE_LIST } from '@/views/finance/enums.js';
import { getTextareaVal } from '@/views/finance/basicData/PriceInquiryLog/utils.js';
import { NUMBERLIST_MAX_LENGTH, BUSINESS_TYPE_ARR } from './utils';

/** @typedef {import('./types').IForm} IForm */

export default {
  name: 'SearchForm',

  components: {
    AgentCmp
  },

  props: {
    queryForm: {
      type: Object,
      default: () => {}
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    selectKey: {
      type: Number,
      default: 1
    },
    searchSelectArr: {
      type: Object,
      default: () => {
        return {
          providerItem: null,
          outletsItem: null,
          customerItem: null
        };
      }
    }
  },
  data() {
    return {
      nullList: [],
      feeTypeOption: [],
      selectNumber: 1,
      queryDate: [],
      numberList: '',
      jurisdiction: this.$store.getters.button_permissions,
      providerList: [], // 应收主体选项
      outletsList: [], // 站点选项
      customerList: [], // 客户列表
      searchSelect: {
        providerItem: null,
        outletsItem: null,
        customerItem: null
      },
      WAYBILL_STATUS,
      ORDER_TYPE_LIST,
      BH_ORDER_TYPE_LIST,
      BUSINESS_TYPE_ARR,
      isSiteId: false, // 网点加载动画
      // 日期限制
      pickerMinDate: '',
      pickerRange: 15 * 24 * 60 * 60 * 1000 // 可选择日期范围 为15天
    };
  },

  computed: {
    // 是否存在 仅查看当前经办人 权限
    isJurisdiction() {
      return getIsAuth('btn:aRGeneration:onlyAgent');
    },

    /**
     * 日期限制处理
     *    默认情况下限制15天
     *    如果输入了 业务单号 或 客户单号 或 批次号，则不限制时间
     */
    dateLimit() {
      const { numberList, pickerMinDate, pickerRange } = this;
      const { billBatchNumber } = this.queryForm.params;

      // 默认限制15天
      return {
        // 设置不能选择的日期
        onPick: ({ minDate }) => {
          // 输入了内容
          if (numberList || billBatchNumber) {
            // 取消限制
            this.pickerMinDate = null;
            return;
          }

          if (minDate && pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          // 输入了内容
          if (numberList || billBatchNumber) {
            // 取消时间限制
            return false;
          }

          if (this.pickerMinDate) {
            return (time.getTime() > (pickerMinDate + pickerRange)) || (time.getTime() < (pickerMinDate - pickerRange));
          }
          return false;
        }
      };
    }
  },
  created() {
    this.getProviderPage(''); // 应收主体
    this.getSitePageList(''); // 网点
    this.getCustomerPage(''); // 客户
    this.getExpenseType(''); // 费用类型

    // 输入框提示符
    this.numberListTips = this.$i18n.t('newOrder.PreciseSearch').replace('200', NUMBERLIST_MAX_LENGTH + '');
    this.searchSelect = cloneDeep(this.searchSelectArr);
    if (this.queryForm.params.numberList.length > 0) {
      this.numberList = this.queryForm.params.numberList.join('\n');
    }
    if (this.queryForm.params.startTime) {
      this.queryDate[0] = this.queryForm.params.startTime;
    }
    if (this.queryForm.params.endTime) {
      this.queryDate[1] = this.queryForm.params.endTime;
    }
  },

  methods: {
    changePush() {
      this.queryForm.params.status = '';
      this.$forceUpdate();
    },
    // 应收主体变化
    providerInfoChange(value) {
      // 清空网点
      this.queryForm.params.siteId = '';
      this.outletsList = [];
      this.providerList.map(item => {
        if (item.id === value) {
          this.searchSelect.providerItem = item;
        }
      });
      this.getSitePageList();
    },
    // 网点选项变化
    changeOutlets(value) {
      this.outletsList.map(item => {
        if (item.id === value) {
          this.searchSelect.outletsItem = item;
        }
      });
    },
    // 客户选项变化
    changeCustomer(value) {
      this.customerList.map(item => {
        if (item.id === value) {
          this.searchSelect.customerItem = item;
        }
      });
    },
    // 业务类型变化
    changeBusinessType(data) {
      this.queryForm.params.status = '';
      if (data !== 1) {
        this.queryForm.params.collectionCategory = null;
      }
      this.$forceUpdate();
    },
    // 获取应收主体选项
    getProviderPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          providerName: name,
          state: 1
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.code === 200) {
          const { records } = res.data;
          if (this.searchSelect.providerItem) {
            const flag = records.every(item => item.id !== this.searchSelect.providerItem.id);
            if (flag) {
              records.unshift(this.searchSelect.providerItem);
            }
          }
          this.providerList = records || [];
        }
      });
    },
    // 分页获取网点
    getSitePageList(name = '') {
      const param = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          siteStatus: undefined,
          name,
          providerId: this.queryForm.params.providerId
        }
      };
      this.isSiteId = true;
      apiCommonSite(param, false).then(res => {
        if (res.code === 200) {
          const { records } = res.data;
          if (this.searchSelect.outletsItem) {
            const flag = records.every(item => item.id !== this.searchSelect.outletsItem.id);
            if (flag) {
              records.unshift(this.searchSelect.outletsItem);
            }
          }
          this.outletsList = records || [];
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.isSiteId = false;
      });
    },
    // 分页搜索客户
    getCustomerPage(name = '') {
      const { handlerId } = this.queryForm.params;
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          quickSearch: name,
          handlerId: handlerId || undefined // 经办人
        }
      };
      apiCustomerPage(param).then((res) => {
        if (res.code===200) {
          const { records } = res.data;
          if (this.searchSelect.customerItem) {
            const flag = records.every(item => item.id !== this.searchSelect.customerItem.id);
            if (flag) {
              records.unshift(this.searchSelect.customerItem);
            }
          }
          this.customerList = records || [];
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 获取费用类型
    getExpenseType() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 100000,
        params: {
          state: 1, // 状态（1启用，0禁用）
          attribute: 1 // 1 应收费用 2 应付费用
        }
      };
      feePage(param, false).then(res => {
        // console.log(res, '费用');
        if (res.code === 200) {
          this.feeTypeOption = res.data.records;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 搜索、取消、重置
    searchForm(type) {
      if (type === 3) {
        this.numberList = '';
        this.queryDate = [];
        this.searchSelect = {
          providerItem: null,
          outletsItem: null,
          customerItem: null
        };
      }
      if (this.queryDate && this.queryDate.length > 1) {
        const start = new Date(this.queryDate[0]).getTime();
        const end = new Date(this.queryDate[1]).getTime();
        if (start > end) {
          this.$message.warning(this.$i18n.t('receivablePayable.TheStartTimeCannotBeGreaterThanTheEndTime')); // 起始时间不能大于结束时间
          return;
        }
        this.queryForm.params.startTime = this.queryDate[0];
        this.queryForm.params.endTime = this.queryDate[1];
      } else {
        this.queryForm.params.startTime = '';
        this.queryForm.params.endTime = '';
      }
      // 字段截取
      this.queryForm.params.numberList = getTextareaVal(this.numberList, NUMBERLIST_MAX_LENGTH);
      this.$emit('search', this.searchSelect, type);
    },

    // 经办人
    agentChange(val) {
      this.queryForm.params.handlerId = val;
      // 清空客户
      this.queryForm.params.customerId = '';
    }
  }
};
</script>

<style scoped>
.tips {
  margin: 0;
}
</style>
