<template>
  <div class="flex-c">
    <el-button
      icon="el-icon-download"
      size="small"
      type="primary"
      style="margin: 0 10px 10px 0"
      @click="operationBtn()"
    >{{ $t('receivablePayable.DownloadTheTemplate') }}</el-button>
    <upload-excel
      :on-success="importLogistics"
      :but-name="$t('receivablePayable.ImportCalculatePrices')"
    />
  </div>
</template>

<script>

const ExcelJS = require('exceljs');
import uploadExcel from '@/components/UploadExcel';
import { saveAs } from 'file-saver';

export default {
  name: 'ImportAddFee',
  components: {
    uploadExcel
  },
  props: {
    // 重算类型
    calculationType: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      outletsList: []
    };
  },
  methods: {
    // 下载模板
    operationBtn() {
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        if (that.calculationType === 2) {
          Sheet1.columns = [
            {
              header: '业务单号',
              key: 'waybillNumber',
              width: 40
            }
          ];
          Sheet1.getCell('A2').value = 'número do negócio';
        } else if (that.calculationType === 3) {
          Sheet1.columns = [
            {
              header: '业务单号',
              key: 'waybillNumber',
              width: 40
            },
            {
              header: '计费重量（KG）',
              key: 'weight',
              width: 40
            }
          ];
          Sheet1.getCell('A2').value = 'número do negócio';
          Sheet1.getCell('B2').value = 'Peso faturável（KG）';
          Sheet1.mergeCells('A3:B3');
        }
        Sheet1.getCell('A3').value = '每次仅导入100000条（Importar apenas 40000 itens de cada vez）';
        Sheet1.getRow(3).getCell(1).font = {
          color: { argb: 'ff0000' }
        };
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName =
          that.calculationType === 2 ? that.$t('receivablePayable.ImportRecalculatePricesTemplate') + '.xlsx'
            : that.$t('receivablePayable.ImportWeightRecalculationPriceTemplate') + '.xlsx';
        saveAs(blob, saveName);
        return;
      }
      ddd();
    },
    // 导入费用
    importLogistics(array) {
      const dataArr = [];
      const arrData = array[0].results.splice(2);
      let logisticsKey = {};
      if (this.calculationType === 2) {
        logisticsKey = {
          '业务单号': 'waybillNumber'
        };
      } else {
        logisticsKey = {
          '业务单号': 'waybillNumber',
          '计费重量（KG）': 'weight'
        };
      }
      arrData.map((item) => {
        const dataObj = {};
        for (const k in logisticsKey) {
          if (item[k]) {
            dataObj[logisticsKey[k]] = item[k];
          } else {
            dataObj[logisticsKey[k]] = '';
          }
        }
        dataArr.push(dataObj);
      });
      this.$emit('importPrice',dataArr);
    }
  }
};
</script>

<style scoped>
.flex-c {
  display: flex;
  flex-wrap: wrap;
}
</style>
