/**
 * 银行批量付款管理
 */

import request from '@/utils/request';
import newRequest from '@/utils/new-request';

const baseFinanceURL = process.env.VUE_APP_FINANCE;

/**
 * 分页查询
 * @param {*} data 查询参数
 * @param {*} isBool 是否显示加载中参数
 * @returns
 */
export const apiBradescoPage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payable/bradesco/getPage',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

/**
 * 批量导入新增
 * @param {*} data
 * @param {*} isBool
 * @returns
 */
export const apiBradescoImportAdd = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payable/bradesco/importAdd',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

/**
 * 批量导出
 * @param {*} data 查询参数
 * @param {*} isBool 是否显示加载中参数
 * @returns
 */
export const apiBradescoExport = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payable/bradesco/export',
    data,
    isLoading: isBool,
    baseURL: baseFinanceURL
  });
};

/**
 * 发起付款
 * @param {*} data 查询参数
 * @param {*} isBool 是否显示加载中参数
 * @returns
 */
export const apiPayment = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payable/bradesco/payment',
    data,
    isLoading: isBool,
    baseURL: baseFinanceURL
  });
};

/**
 * 付款结果查询
 * @param {*} data 条码号参数
 * @param {*} isBool 是否显示加载中参数
 * @returns
 */
export const apiQueryResult = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/payable/bradesco/queryResult',
    data,
    isLoading: isBool,
    baseURL: baseFinanceURL
  });
};
