var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-c" },
    [
      _c(
        "el-button",
        {
          staticStyle: { margin: "0 10px 10px 0" },
          attrs: { icon: "el-icon-download", size: "small", type: "primary" },
          on: { click: _vm.Download },
        },
        [_vm._v(_vm._s(_vm.$t("receivablePayable.DownloadTheTemplate")))]
      ),
      _c("upload-excel", {
        attrs: {
          "on-success": _vm.importLogistics,
          "but-name": _vm.$t("receivablePayable.ImportAddCharges"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }