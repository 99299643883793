<!-- 针对不同模块的失败原因，做不同的处理 -->
<template>
  <div>
    <!-- model为6、7、8 or 功能为AJD、DSP、安骏应付账户导入付款显示 -->
    <div v-if="[6, 7, 8].includes(row.model) || [9,10,11].includes(row.operateModule)">
      <!-- 只有处理失败才显示 按钮 -->
      <el-link v-if="row.status === IMPORT_STATUS_OBJ['2'].value" :href="row.failureDescription" target="_blank">{{ $t('GlobalSetObj.clickDown') }}</el-link>
      <!--<el-link v-else :href="row.failureDescription" target="_blank">{{ $t('GlobalSetObj.clickDown') }}</el-link>-->
    </div>

    <!-- 其它模块 -->
    <span v-else>{{ row.failureDescription }}</span>
  </div>
</template>

<script>
import { MODULE_OBJ, IMPORT_STATUS_OBJ } from './../utils';

export default {
  name: '',

  props: {
    row: {
      type: Object,
      required: true
    }
  },

  data() {
    return {

    };
  },

  created() {
    this.MODULE_OBJ = MODULE_OBJ;
    this.IMPORT_STATUS_OBJ = IMPORT_STATUS_OBJ;
  },

  methods: {

  }

};
</script>

<style scoped>
</style>
