<template>
  <div class="vehicleManagementBox">
    <div style="margin-bottom: 20px;">
      <el-form class="selectClass">
        <el-row
          type="flex" justify="start" style="flex-wrap: wrap;"
          class="selectClass"
        >
          <el-col :span="10" style="width: auto;">
            <!-- 登记时间： -->
            <el-form-item :label="$t('collectionCenter.RegistrationTime')">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                :end-placeholder="$t('collectionCenter.EndTime')"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- 车辆类型： -->
            <el-form-item :label="$t('collectionCenter.carT')">
              <el-select
                v-model="queryForm.params.vehicleTypeList" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable multiple
              >
                <el-option
                  v-for="(item, index) in vTypeList"
                  :key="index"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- 运营模式： -->
            <el-form-item :label="$t('collectionCenter.operationM')">
              <el-select
                v-model="queryForm.params.operationMode" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in operationModeList"
                  :key="index"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- 所属网点： -->
            <el-form-item :label="$t('collectionCenter.belongDot')">
              <el-select
                v-model="queryForm.params.siteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in siteList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- 驾照类型： -->
            <el-form-item :label="$t('collectionCenter.driversLicenseType')">
              <el-select v-model="queryForm.params.driversLicenseTypeList" :placeholder="$t('collectionCenter.pleaseChoose')" multiple>
                <el-option
                  v-for="(item, index) in driversLicenseTypeList"
                  :key="index"
                  :label="item.type"
                  :value="item.type"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- 车牌号： -->
            <el-form-item :label="$t('collectionCenter.licensePlateNum')">
              <div class="BlockNewlineClass">
                <el-input
                  v-model="queryForm.params.vehicleNumberList"
                  type="textarea"
                  :autosize="{ maxRows: 5}"
                  :placeholder="$t('collectionCenter.BatchQuery')"
                  @blur="ft"
                  @keydown.enter.native="BlockNewline"
                />
              </div>
              <!-- 支持批量查询(回车换行分割) -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:vehicleManagement:search'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:vehicleManagement:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:vehicleManagement:add'"
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="addClick"
          >{{ $t('collectionCenter.add') }}</el-button><!-- 新增 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:vehicleManagement:export'"
            icon="el-icon-upload2"
            size="mini"
            :class="vehicleIdsList.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="download"
          >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:vehicleManagement:allExport'"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll ? 'onlyClickOnce10' : ''"
            @click="downloadAll"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <div>
      <el-table
        ref="tableList"
        :max-height="600"
        border
        :data="tableDate"
        @select="batchSelect"
        @select-all="batchSelectAll"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column
          :label="$t('collectionCenter.ind')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="id"
          :label="$t('collectionCenter.carID')"
        /><!-- 车辆编号 -->
        <el-table-column
          align="center"
          prop="vehicleName"
          :label="$t('collectionCenter.carName')"
        /><!-- 车辆名称 -->
        <el-table-column
          align="center"
          prop="vehicleNumber"
          :label="$t('collectionCenter.licensePlateNumber')"
        /><!-- 车牌号 -->
        <el-table-column
          align="center"
          prop="vehicleType"
          :label="$t('collectionCenter.carType')"
        ><!-- 车辆类型 -->
          <template slot-scope="scope">
            <div>
              {{
                scope.row.vehicleType == 0 ? 'vuc' :
                scope.row.vehicleType == 1 ? '3/4' :
                scope.row.vehicleType == 3 ? 'ToCo' :
                scope.row.vehicleType == 4 ? 'van' :
                scope.row.vehicleType == 5 ? 'Fiorino' :
                scope.row.vehicleType == 6 ? 'Truck' :
                scope.row.vehicleType == 7 ? 'Carreta' :
                scope.row.vehicleType == 100 ? $t('collectionCenter.other') : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="vehicleServiceLife"
          :label="$t('collectionCenter.vehicleYear')"
        /><!-- 车辆使用年限 -->
        <el-table-column
          align="center"
          prop="siteName"
          :label="$t('collectionCenter.belongD')"
        /><!-- 所属网点 -->
        <el-table-column
          align="center"
          prop="vehicleDriver"
          :label="$t('collectionCenter.vehicleDriver')"
        /><!-- 车辆驾驶人 -->
        <el-table-column
          align="center"
          prop="operationMode"
          :label="$t('collectionCenter.operationMode')"
        ><!-- 运营模式  第三方/自营 -->
          <template slot-scope="scope">
            <div>
              <!-- 0 ? '直营' -->
              <!-- 1 ? '第三方合作' -->
              {{
                scope.row.operationMode == 0 ? $t('collectionCenter.directOperation') :
                scope.row.operationMode == 1 ? $t('collectionCenter.thirdPartyCooperation') : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="itin"
          :label="$t('collectionCenter.personalTaxNum')"
        /><!-- 个人税号 -->
        <el-table-column
          align="center"
          prop="driversLicenseNumber"
          :label="$t('collectionCenter.drivingLicenseNum')"
        /><!-- 驾驶证编号 -->
        <el-table-column
          align="center"
          prop="driversLicenseType"
          :label="$t('collectionCenter.driversLicenseT')"
        /><!-- 驾照类型 -->
        <el-table-column
          align="center"
          prop="enabled"
          :label="$t('collectionCenter.ifUse')"
        ><!-- 是否启用 -->
          <template slot-scope="scope">
            <div>
              <!-- 0 ? '启用' -->
              <!-- 1 ? '禁用' -->
              {{
                scope.row.enabled == 0 ? $t('collectionCenter.enable') :
                scope.row.enabled == 1 ? $t('collectionCenter.disable') : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="driversLicenseLimitedPeriod"
          :label="$t('collectionCenter.driveValidT')"
        /><!-- 驾驶证有效期限 -->
        <el-table-column
          align="center"
          prop="driversLicenseIssuingAgency"
          :label="$t('collectionCenter.driveIssuingA')"
        /><!-- 驾驶证颁发机构 -->
        <el-table-column
          align="center"
          prop="vehicleVolume"
          :label="$t('collectionCenter.Volume')"
        /><!-- 车辆容积 -->
        <el-table-column
          align="center"
          prop="vehiclePayload"
          :label="$t('collectionCenter.Payload')"
        /><!-- 车辆载重 -->
        <el-table-column
          align="center"
          prop="vehicleGrossWeight"
          :label="$t('collectionCenter.GrossWeight')"
        /><!-- 车辆毛重 -->
        <el-table-column
          align="center"
          prop="vehicleCNPJ"
          :label="$t('collectionCenter.CNPJ')"
        /><!-- 车辆所属公司税号 -->
        <el-table-column
          align="center"
          prop="vehicleTrailerPlates"
          :label="$t('collectionCenter.trailerPlates')"
        /><!-- 拖车车牌 -->
        <el-table-column
          align="center"
          prop="vehicleRegistration"
          :label="$t('collectionCenter.vehicleStateRgtNumber')"
        /><!-- 车辆州注册号 -->
        <el-table-column
          align="center"
          prop="vehicleRNTRC"
          :label="$t('collectionCenter.RNTRC')"
        /><!-- 巴西国家陆地运输局(ANTT)提供的RNTRC车辆文件编号 -->
        <el-table-column
          align="center"
          prop="vehicleDocumentation"
          :label="$t('collectionCenter.documentation')"
        /><!-- 车辆文件编号 -->
        <el-table-column
          align="center"
          prop="remark"
          :label="$t('collectionCenter.Note')"
        /><!-- 备注 -->
        <el-table-column
          align="center"
          prop="userName"
          :label="$t('collectionCenter.registrant')"
        /><!-- 登记人 -->
        <el-table-column
          align="center"
          prop="createTime"
          :label="$t('collectionCenter.RegistrationT')"
        /><!-- 登记时间 -->
        <el-table-column
          align="center"
          prop="updateName"
          :label="$t('collectionCenter.ModifiedBy')"
        /><!-- 修改人 -->
        <el-table-column
          align="center"
          prop="updateTime"
          :label="$t('collectionCenter.ModificationTime')"
        /><!-- 修改时间 -->
        <el-table-column
          v-if="codeDownload || edit"
          fixed="right"
          align="center"
          width="130"
          prop="collectSuccessRate"
          :label="$t('collectionCenter.operation')"
        >
          <template slot-scope="scope">
            <div class="widthClass">
              <!-- 一维码下载 -->
              <el-button v-if="codeDownload1" size="mini" @click="oneCodeDownloadClick(scope.row)">{{ $t('collectionCenter.codeDownload1') }}</el-button>
              <!-- 二维码下载 -->
              <el-button v-if="codeDownload" size="mini" @click="codeDownloadClick(scope.row)">{{ $t('collectionCenter.codeDownload') }}</el-button>
              <el-button
                v-if="edit"
                size="mini"
                @click="putClick(scope.row)"
              >{{ $t('collectionCenter.modify') }}</el-button><!-- 修改 -->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getDatePage"
        @size-change="getPageSize"
      />
    </div>

    <div v-if="drawerIsOpen" class="overflowClass">
      <el-drawer
        :title="drawerTitle"
        :visible.sync="drawerIsOpen"
        :direction="direction"
        size="50%"
        @close="closeReset"
      >
        <div class="drawerClass">
          <el-form ref="drawerFormRef" :model="drawerForm" :rules="rules">
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 车牌号： -->
                <el-form-item prop="vehicleNumber" :label="$t('collectionCenter.licensePlateNum')">
                  <el-input
                    v-model.trim="drawerForm.vehicleNumber" clearable :placeholder="$t('collectionCenter.pleaseInput')"
                    :disabled="drawerTitle===$t('collectionCenter.editVehicleRegistration')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 所属网点： -->
                <el-form-item prop="siteId" :label="$t('collectionCenter.belongDot')">
                  <el-select
                    v-model="drawerForm.siteId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in siteList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 车辆名称： -->
                <el-form-item prop="vehicleName" :label="$t('collectionCenter.carN')">
                  <el-input v-model="drawerForm.vehicleName" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 车辆类型： -->
                <el-form-item prop="vehicleType" :label="$t('collectionCenter.carT')">
                  <el-select
                    v-model="drawerForm.vehicleType" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in vTypeList"
                      :key="index"
                      :label="item.name"
                      :value="item.code"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 车辆驾驶人： -->
                <el-form-item prop="vehicleDriverId" :label="$t('collectionCenter.vehicleD')">
                  <el-select
                    v-model="drawerForm.vehicleDriverId" :placeholder="$t('collectionCenter.pleaseChoose')"
                    clearable
                    filterable
                    multiple
                    :multiple-limit="2"
                    @focus="getNewestScanUser"
                  >
                    <el-option
                      v-for="(item, index) in allDriverList"
                      :key="index"
                      :label="item.realName"
                      :value="item.id + '/' + item.realName"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 运营模式： -->
                <el-form-item prop="operationMode" :label="$t('collectionCenter.operationM')">
                  <el-select
                    v-model="drawerForm.operationMode" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in operationModeList"
                      :key="index"
                      :label="item.name"
                      :value="item.code"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 车辆使用年限： -->
                <el-form-item prop="vehicleServiceLife" :label="$t('collectionCenter.vehicleLife')">
                  <div class="yaerClass">
                    <el-date-picker
                      v-model="drawerForm.vehicleServiceLife"
                      type="year"
                      :placeholder="$t('collectionCenter.pleaseChoose')"
                      value-format="yyyy"
                    />
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 个人税号： -->
                <el-form-item prop="itin" :label="$t('collectionCenter.personalTaxNumber')">
                  <el-input
                    v-model.trim="drawerForm.itin" maxlength="11" show-word-limit
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    clearable
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 驾驶证编号： -->
                <el-form-item prop="driversLicenseNumber" :label="$t('collectionCenter.drivingLicenseNumber')">
                  <el-input
                    v-model.trim="drawerForm.driversLicenseNumber" maxlength="11" show-word-limit
                    :placeholder="$t('collectionCenter.pleaseInput')"
                    clearable
                  />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 驾照类型： -->
                <el-form-item prop="driversLicenseType" :label="$t('collectionCenter.driversLicenseType')">
                  <el-select v-model="drawerForm.driversLicenseType" :placeholder="$t('collectionCenter.pleaseChoose')" multiple>
                    <el-option
                      v-for="(item, index) in driversLicenseTypeList"
                      :key="index"
                      :label="item.type"
                      :value="item.type"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 驾驶证有效期限： -->
                <el-form-item prop="driversLicenseLimitedPeriod" :label="$t('collectionCenter.driveValidTime')">
                  <el-date-picker
                    v-model="drawerForm.driversLicenseLimitedPeriod"
                    type="daterange"
                    value-format="yyyy.MM.dd"
                    :range-separator="$t('collectionCenter.to')"
                    :start-placeholder="$t('collectionCenter.StartTime')"
                    :end-placeholder="$t('collectionCenter.EndTime')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 驾驶证颁发机构： -->
                <el-form-item prop="driversLicenseIssuingAgency" :label="$t('collectionCenter.driveIssuingAgency')">
                  <el-input
                    v-model="drawerForm.driversLicenseIssuingAgency" maxlength="100" show-word-limit
                    type="textarea" :placeholder="$t('collectionCenter.pleaseInput')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 是否启用： -->
                <el-form-item prop="enabled" :label="$t('collectionCenter.ifEnabled')">
                  <el-select v-model="drawerForm.enabled" :placeholder="$t('collectionCenter.pleaseChoose')" clearable>
                    <el-option :label="$t('collectionCenter.enable')" :value="0" />
                    <el-option :label="$t('collectionCenter.disable')" :value="1" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 车辆容积： -->
                <el-form-item prop="vehicleVolume" :label="$t('collectionCenter.Volume') + '：'">
                  <el-input
                    v-model="drawerForm.vehicleVolume" maxlength="7" show-word-limit
                    clearable :placeholder="$t('collectionCenter.pleaseInput')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <!-- 车辆载重： -->
                <el-form-item prop="vehiclePayload" :label="$t('collectionCenter.Payload') + '：'">
                  <el-input
                    v-model="drawerForm.vehiclePayload" maxlength="6" show-word-limit
                    clearable :placeholder="$t('collectionCenter.pleaseInput')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 车辆毛重： -->
                <el-form-item prop="vehicleGrossWeight" :label="$t('collectionCenter.GrossWeight') + '：'">
                  <el-input
                    v-model="drawerForm.vehicleGrossWeight" maxlength="6" show-word-limit
                    clearable :placeholder="$t('collectionCenter.pleaseInput')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11" class="overClass">
                <!-- 车辆所属公司税号： -->
                <el-form-item prop="vehicleCNPJ" :label="$t('collectionCenter.CNPJ') + '：'">
                  <el-input
                    v-model="drawerForm.vehicleCNPJ" clearable :placeholder="$t('collectionCenter.pleaseInput')"
                    maxlength="60" show-word-limit
                  />
                </el-form-item>
              </el-col>
              <el-col :span="11" class="overClass">
                <!-- 拖车车牌： -->
                <el-form-item prop="vehicleTrailerPlates" :label="$t('collectionCenter.trailerPlates') + '：'">
                  <el-input
                    v-model="drawerForm.vehicleTrailerPlates" clearable :placeholder="$t('collectionCenter.pleaseInput')"
                    maxlength="60" show-word-limit
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11" class="overClass">
                <!-- 车辆州注册号： -->
                <el-form-item prop="vehicleRegistration" :label="$t('collectionCenter.vehicleStateRgtNumber') + '：'">
                  <el-input
                    v-model="drawerForm.vehicleRegistration" clearable :placeholder="$t('collectionCenter.pleaseInput')"
                    maxlength="60" show-word-limit
                  />
                </el-form-item>
              </el-col>
              <el-col :span="11" class="overClass">
                <!-- RNTRC： -->
                <el-form-item prop="vehicleRNTRC" :label="$t('collectionCenter.RNTRC') + '：'">
                  <el-input
                    v-model="drawerForm.vehicleRNTRC" clearable :placeholder="$t('collectionCenter.pleaseInput')"
                    maxlength="60" show-word-limit
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11" class="overClass">
                <!-- documentation： -->
                <el-form-item prop="vehicleDocumentation" :label="$t('collectionCenter.documentation') + '：'">
                  <el-input
                    v-model="drawerForm.vehicleDocumentation" clearable :placeholder="$t('collectionCenter.pleaseInput')"
                    maxlength="60" show-word-limit
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" class="noteClass">
                <!-- 备注： -->
                <el-form-item prop="remark" :label="$t('collectionCenter.note')">
                  <el-input v-model="drawerForm.remark" type="textarea" :placeholder="$t('collectionCenter.pleaseInput')" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="enclosureClass">
                  <!-- 附件 -->
                  <el-form-item :label="$t('collectionCenter.driverLicenseAttachment')+'：'">
                    <div class="uploadClass">
                      <el-upload
                        v-show="!drawerForm.driversLicenseAccessory"
                        ref="demoUpload"
                        :before-upload="beforeUpload"
                        :http-request="upLoad"
                        :accept="'image/*'"
                        :limit="1"
                        action
                        class="upload-demo"
                        drag
                      >
                        <!-- * 仅支持png、bmp、jpg格式的图片 -->
                        <div slot="tip" class="el-upload__tip">{{
                          $t('collectionCenter.OnlyImageFormatIsSupported')+','+
                            $t('collectionCenter.max1Files') }}
                        </div>
                        <i class="el-icon-plus" style="margin: 50px auto 20px;" />
                        <!-- 点击或拖拽文件到此处上传 -->
                        <div class="el-upload__text">{{ $t('collectionCenter.cldrUpload') }}</div>
                      </el-upload>
                    </div>
                    <div v-if="drawerForm.driversLicenseAccessory">
                      <el-image
                        :src="drawerForm.driversLicenseAccessory" alt="" style="width: auto;height: 200px;"
                        :preview-src-list="[drawerForm.driversLicenseAccessory]"
                      >
                        <div slot="placeholder" class="image-slot">
                          <p>
                            {{ $t('collectionCenter.loading') }}
                          </p>
                        </div>
                      </el-image>
                      <div class="el-icon-delete-solid imgDelClass" @click="delImg" />
                    </div>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
          <div class="submitClickBtn">
            <el-button @click="registerClick">{{ $t('collectionCenter.register') }}</el-button><!-- 登记 -->
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {
  searchSiteIdList,
  collectVehiclePage,
  collectVehicleAdd,
  collectVehicleEdit,
  printVehicleData,
  vehicleExport,
  vehicleAllExport,
  printVehicleCodeData,
  scanUserList
} from '@/api/lanshou';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';
import { getTimes } from '@/utils/watermark/watermark_time';
import { uploadOos } from '@/utils/ali_oos_util';
import { Loading } from 'element-ui';
export default {
  name: 'VehicleManagement',
  data() {
    return {
      allDriverList: [],
      isClickAll: false,
      isClick: false,
      vehicleIdsList: [],
      notEnter: true, // 是否允许换行
      codeDownload1: this.$store.getters.button_permissions.includes('btn:collection-center:basicDate:vehicleManagement:codeDownload1'),
      codeDownload: this.$store.getters.button_permissions.includes('btn:collection-center:basicDate:vehicleManagement:codeDownload'),
      edit: this.$store.getters.button_permissions.includes('btn:collection-center:basicDate:vehicleManagement:edit'),
      timeValue: [],
      driversLicenseTypeList: [
        { type: 'A' },
        { type: 'B' },
        { type: 'C' },
        { type: 'D' },
        { type: 'E' }
      ],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          beginTime: '',
          endTime: '',
          vehicleTypeList: [],
          operationMode: '',
          vehicleNumberList: '',
          siteId: '',
          driversLicenseTypeList: []
        }
      },
      tableDate: [],
      total: 0,
      userIdAndNameObj: {}, // 用户表
      drawerIsOpen: false,
      drawerTitle: this.$t('collectionCenter.VehicleRegistration'), // '车辆登记'
      direction: 'ltr',
      drawerForm: {
        vehicleNumber: '', // 车牌号
        siteId: '', // 所属站点
        vehicleName: '', // 车辆名称
        vehicleType: '', // 车辆类型
        vehicleDriverId: [], // 车辆驾驶人
        vehicleDriver: [],
        operationMode: '', // 运营模式
        vehicleServiceLife: '', // 车辆使用年限
        itin: '', // 个人税号
        driversLicenseNumber: '', // 驾驶证编号
        driversLicenseType: [],
        enabled: '',
        driversLicenseLimitedPeriod: '', // 驾驶证有效期限
        driversLicenseIssuingAgency: '', // 驾驶证颁发机构
        remark: '', // 备注
        vehicleVolume: '',
        vehiclePayload: '',
        vehicleGrossWeight: '',
        vehicleCNPJ: '',
        vehicleTrailerPlates: '',
        vehicleRegistration: '',
        vehicleRNTRC: '',
        vehicleDocumentation: '',
        driversLicenseAccessory: ''// 驾照附件
      },
      rules: {
        vehicleNumber: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const reg = /^[A-Z]{3}(?:\d{4}|(\d)[A-Z](\d{2}))$/;
              if (!reg.test(value)) {
                // '格式为三个字母 + 四个数字（例如：ABC1234）或者三字母 + 一数字 + 一字母 + 两数字（例如：ABC1D23）'
                return callback(new Error(this.$t('collectionCenter.licensePlateRules')));
              } else {
                callback();
              }
            }
          }
        ],
        siteId: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        // vehicleName: [
        //   // '不能为空'
        //   { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        // ],
        vehicleType: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        vehicleDriverId: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        operationMode: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        driversLicenseIssuingAgency: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        driversLicenseLimitedPeriod: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        vehicleServiceLife: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        driversLicenseType: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        enabled: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }
        ],
        vehicleVolume: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        vehiclePayload: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        vehicleGrossWeight: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        vehicleRegistration: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        vehicleRNTRC: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        driversLicenseNumber: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const reg = /[0-9]{11}/;
              if (!reg.test(value)) {
                return callback(new Error(this.$t('GlobalSetObj.Insira11'))); // '请输入11位数值'
              } else {
                callback();
              }
            }
          }
        ],
        itin: [
          // '不能为空'
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              const reg = /[0-9]{11}/;
              if (!reg.test(value)) {
                return callback(new Error(this.$t('GlobalSetObj.Insira11'))); // '请输入11位数值'
              } else {
                callback();
              }
            }
          }
        ]
      },
      siteList: [],
      operationModeList: [
        { code: 0, name: this.$t('collectionCenter.directOperation') }, // '直营'
        { code: 1, name: this.$t('collectionCenter.thirdPartyCooperation') } // '第三方合作'
      ],
      vTypeList: [
        // { code: 4, name: this.$t('collectionCenter.lightweightEnclosedCar') }, // '轻型封闭式货车'
        // { code: 3, name: this.$t('collectionCenter.largeVan') }, // '大型厢式货车'
        // { code: 2, name: this.$t('collectionCenter.largeBoxTruck') }, // '大型半挂货车'
        // { code: 1, name: this.$t('collectionCenter.mediumBoxTruck') }, // '中型厢式货车'
        // { code: 0, name: this.$t('collectionCenter.smallBoxTruck') }, // '小型厢式货车'
        // { code: 100, name: this.$t('collectionCenter.other') } // '其他'
        { code: 0, name: 'vuc' },
        { code: 1, name: '3/4' },
        { code: 3, name: 'ToCo' },
        { code: 4, name: 'van' },
        { code: 5, name: 'Fiorino' },
        { code: 6, name: 'Truck' },
        { code: 7, name: 'Carreta' },
        { code: 100, name: this.$t('collectionCenter.other') } // '其他'
      ]
    };
  },
  watch: {
    'queryForm.params.vehicleNumberList'(val) {
      let bgh = val.split('\n');
      // 过滤空项
      bgh = bgh.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = bgh.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      let number = [];
      if (bghTrim.length >= 1000) {
        number = bghTrim.slice(0, 1000).join('\n');
        this.queryForm.params.vehicleNumberList = number;
        this.notEnter = false;
      } else {
        this.notEnter = true;
      }
      if (bghTrim.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    }
  },
  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.default();
    this.searchClick();
    searchSiteIdList().then(res => {
      if (res.code===200) {
        this.siteList = [];
        res.data.forEach(data => {
          this.siteList.push({ id: data.id, name: data.name });
        });
      }
    });
    this.isClick = false;
    this.isClickAll = false;
    this.getNewestScanUser();
  },
  methods: {
    getNewestScanUser() {
      const form = {};
      scanUserList(form).then(res => {
        if (res.code===200) {
          this.allDriverList = [];
          res.data.forEach(data => {
            this.allDriverList.push({ id: data.id, realName: data.realName });
          });
        }
      });
    },
    ft() {
      const val = this.queryForm.params.vehicleNumberList.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      this.queryForm.params.vehicleNumberList = bghTrim.join('\n');
    },
    BlockNewline() { // 文本域最大输入行数为1000
      const e = window.event || arguments[0];
      this.ft();
      console.log('vehicleNumberList', this.queryForm.params.vehicleNumberList);
      const numberLen = this.queryForm.params.vehicleNumberList.split('\n');
      if (!this.notEnter) {
        if (numberLen.length >= 1000) {
          this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        }
        e.returnValue = false;
        return true;
      }
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.beginTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.beginTime = val[0];
      this.queryForm.params.endTime = val[1];
    },
    codeDownloadClick(row) { // 下载二维码
      const code = {
        code: row.id,
        vehicleNumber: row.vehicleNumber
      };
      printVehicleData(code).then(res => {
        if (res.code===200 || res.data.code===200) {
          window.open(res.data);
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      }).catch({});
    },
    // 下载一维码
    oneCodeDownloadClick(row) {
      const code = {
        code: row.id,
        vehicleNumber: row.vehicleNumber
      };
      printVehicleCodeData(code).then(res => {
        if (res.code===200 || res.data.code===200) {
          window.open(res.data);
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      }).catch({});
    },
    default() { // 汇总默认查询条件
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD 23:59:59');
      const day = dayjs(d).subtract(1, 'year').format('YYYY-MM-DD 00:00:00');
      this.timeValue = [day, today];
      this.queryForm.params = {
        beginTime: day, // 开始时间
        endTime: today, // 结束时间
        vehicleTypeList: [],
        operationMode: '',
        vehicleNumberList: '',
        siteId: '',
        driversLicenseTypeList: []
      };
    },
    resetClick() {
      this.default();
    },
    searchClick() { // 查询
      if (this.queryForm.params.vehicleNumberList) {
        this.timeValue = [];
        this.queryForm.params.beginTime = '';
        this.queryForm.params.endTime = '';
        this.queryForm.params.vehicleTypeList = [];
        this.queryForm.params.operationMode = '';
        this.queryForm.params.siteId = '';
        this.queryForm.params.driversLicenseTypeList = [];
        this.queryForm.pageNumber = 1;
        localStorage.setItem('vmQueryForm', JSON.stringify(this.queryForm));
        this.getDatePage();
        return;
      }
      if (this.queryForm.params.beginTime && this.queryForm.params.endTime) {
        this.queryForm.pageNumber = 1;
        localStorage.setItem('vmQueryForm', JSON.stringify(this.queryForm));
        this.getDatePage();
      } else {
        this.$message({
          message: this.$t('collectionCenter.PleaseSelectTime'),
          type: 'warning'
        });
      }
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('vmQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      const vehicleNumberList = queryForm.params.vehicleNumberList ? queryForm.params.vehicleNumberList.split('\n') : [];
      queryForm.params.vehicleNumberList = vehicleNumberList;
      collectVehiclePage(queryForm).then(res => {
        if (res.code===200) {
          this.tableDate = res.data.records.map(item => {
            const updateUser = getUserName(item.updateUser, this.userIdAndNameObj);
            return {
              ...item,
              updateUser
            };
          });
          this.total = res.data.total;
        }
      }).catch({});
    },
    getDatePage() { // 表格page
      const queryForm = JSON.parse(localStorage.getItem('vmQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      const vehicleNumberList = queryForm.params.vehicleNumberList ? queryForm.params.vehicleNumberList.split('\n') : [];
      queryForm.params.vehicleNumberList = vehicleNumberList;
      collectVehiclePage(queryForm).then(res => {
        if (res.code===200) {
          this.tableDate = res.data.records.map(item => {
            const updateUser = getUserName(item.updateUser, this.userIdAndNameObj);
            return {
              ...item,
              updateUser
            };
          });
          this.total = res.data.total;
        }
      }).catch({});
    },
    addClick() { // 新增车辆
      this.drawerIsOpen = true;
      this.drawerTitle = this.$t('collectionCenter.addVehicleRegistration'); // '新增 - 车辆登记'
    },
    registerClick() { // 登记
      this.$refs['drawerFormRef'].validate((valid) => {
        if (!valid) {
          setTimeout(() => {
            if (document.querySelector('.el-form-item__error')) {
              // eslint-disable-next-line no-unused-expressions
              document.querySelectorAll('.el-form-item__error')[0]
                ?.parentNode?.scrollIntoView({
                  block: 'start',
                  behavior: 'smooth'
                });
            }
          }, 1);
          return false;
        } else {
          if (this.drawerForm.vehicleType === 2 && !this.drawerForm.vehicleTrailerPlates) {
            this.$message.warning(this.$t('collectionCenter.trailerPlatesNotNull')); // 车辆拖车车牌不能为空
            return;
          }
          const time1 = this.drawerForm.driversLicenseLimitedPeriod;
          const vehicleDriverId = [];
          const vehicleDriver = [];
          if (this.drawerForm.vehicleDriverId) {
            this.drawerForm.vehicleDriverId.forEach(arr => {
              const val1 = arr.split('/')[0];
              const val2 = arr.split('/')[1];
              vehicleDriverId.push(val1);
              vehicleDriver.push(val2);
            });
          }
          const drawerForm = {
            vehicleNumber: this.drawerForm.vehicleNumber, // 车牌号
            siteId: this.drawerForm.siteId, // 所属站点
            vehicleName: this.drawerForm.vehicleName, // 车辆名称
            vehicleType: this.drawerForm.vehicleType, // 车辆类型
            vehicleDriver: vehicleDriver.join(','), // 车辆驾驶人名称
            vehicleDriverId: vehicleDriverId.join(','), // 车辆驾驶人id
            operationMode: this.drawerForm.operationMode, // 运营模式
            vehicleServiceLife: this.drawerForm.vehicleServiceLife, // 车辆使用年限
            itin: this.drawerForm.itin, // 个人税号
            driversLicenseNumber: this.drawerForm.driversLicenseNumber, // 驾驶证编号
            driversLicenseType: cloneDeep(this.drawerForm.driversLicenseType.toString()),
            enabled: this.drawerForm.enabled,
            driversLicenseLimitedPeriod: time1[0] + '-' + time1[1], // 驾驶证有效期限
            driversLicenseIssuingAgency: this.drawerForm.driversLicenseIssuingAgency, // 驾驶证颁发机构
            remark: this.drawerForm.remark, // 备注
            vehicleVolume: this.drawerForm.vehicleVolume,
            vehiclePayload: this.drawerForm.vehiclePayload,
            vehicleGrossWeight: this.drawerForm.vehicleGrossWeight,
            vehicleCNPJ: this.drawerForm.vehicleCNPJ,
            vehicleTrailerPlates: this.drawerForm.vehicleTrailerPlates,
            vehicleRegistration: this.drawerForm.vehicleRegistration,
            vehicleRNTRC: this.drawerForm.vehicleRNTRC,
            vehicleDocumentation: this.drawerForm.vehicleDocumentation,
            driversLicenseAccessory: this.drawerForm.driversLicenseAccessory // 驾照附件图片
          };
          if (this.drawerTitle === this.$t('collectionCenter.addVehicleRegistration')) { // '新增 - 车辆登记'
            collectVehicleAdd(drawerForm).then(res => {
              if (res.code===200) {
                this.drawerIsOpen = false;
                this.getDatePage();
                this.closeReset();
                this.$message.success(this.$i18n.t('collectionCenter.SaveSuccessfully')); // '保存成功'
              }
            }).catch({});
          }
          if (this.drawerTitle === this.$t('collectionCenter.editVehicleRegistration')) { // '修改 - 车辆登记'
            drawerForm.id = this.drawerForm.id;
            collectVehicleEdit(drawerForm).then(res => {
              if (res.code===200) {
                this.drawerIsOpen = false;
                this.getDatePage();
                this.closeReset();
                this.$message.success(this.$i18n.t('collectionCenter.SaveSuccessfully')); // '保存成功'
              }
            }).catch({});
          }
        }
      });
    },
    putClick(row) { // 修改
      this.drawerIsOpen = true;
      this.drawerTitle = this.$t('collectionCenter.editVehicleRegistration'); // '修改 - 车辆登记'

      const driverNames = row.vehicleDriver ? row.vehicleDriver.split(',') : [];
      const driverId = row.vehicleDriverId ? row.vehicleDriverId.split(',') : [];
      const vehicleDriverId = [];
      if (row.vehicleDriverId) {
        driverId.forEach((arr, index) => {
          const a = arr + '/' + driverNames[index];
          vehicleDriverId.push(a);
        });
      }
      this.drawerForm = {
        id: row.id,
        vehicleNumber: row.vehicleNumber,
        siteId: row.siteId,
        vehicleName: row.vehicleName,
        vehicleType: row.vehicleType,
        vehicleDriverId: vehicleDriverId,
        vehicleDriver: row.vehicleDriver,
        operationMode: row.operationMode,
        remark: row.remark,
        vehicleServiceLife: row.vehicleServiceLife, // 车辆使用年限
        itin: row.itin ? row.itin : '', // 个人税号
        driversLicenseNumber: row.driversLicenseNumber ? row.driversLicenseNumber : '', // 驾驶证编号
        driversLicenseType: row.driversLicenseType.split(','),
        enabled: row.enabled,
        driversLicenseLimitedPeriod: row.driversLicenseLimitedPeriod ? row.driversLicenseLimitedPeriod.split('-') : '', // 驾驶证有效期限
        driversLicenseIssuingAgency: row.driversLicenseIssuingAgency ? row.driversLicenseIssuingAgency : '', // 驾驶证颁发机构
        vehicleVolume: row.vehicleVolume,
        vehiclePayload: row.vehiclePayload,
        vehicleGrossWeight: row.vehicleGrossWeight,
        vehicleCNPJ: row.vehicleCNPJ,
        vehicleTrailerPlates: row.vehicleTrailerPlates,
        vehicleRegistration: row.vehicleRegistration,
        vehicleRNTRC: row.vehicleRNTRC,
        vehicleDocumentation: row.vehicleDocumentation,
        driversLicenseAccessory: row.driversLicenseAccessory
      };
    },
    closeReset() { // 关闭弹窗
      this.drawerForm = {
        vehicleNumber: '', // 车牌号
        siteId: '', // 所属站点
        vehicleName: '', // 车辆名称
        vehicleType: '', // 车辆类型
        vehicleDriverId: [], // 车辆驾驶人
        vehicleDriver: [],
        operationMode: '', // 运营模式
        vehicleServiceLife: '', // 车辆使用年限
        itin: '', // 个人税号
        driversLicenseNumber: '', // 驾驶证编号
        driversLicenseType: [],
        enabled: '',
        driversLicenseLimitedPeriod: '', // 驾驶证有效期限
        driversLicenseIssuingAgency: '', // 驾驶证颁发机构
        remark: '', // 备注
        vehicleVolume: '',
        vehiclePayload: '',
        vehicleGrossWeight: '',
        vehicleCNPJ: '',
        vehicleTrailerPlates: '',
        vehicleRegistration: '',
        vehicleRNTRC: '',
        vehicleDocumentation: '',
        driversLicenseAccessory: '' // 驾照附件
      };
      // 清除上传的附件
      this.delImg();
      this.$refs['drawerFormRef']?.clearValidate();
    },
    batchSelect(selection, row) {
      const IdList = [];
      selection.forEach(list => {
        IdList.push(list.id);
      });
      this.vehicleIdsList = IdList;
    },
    batchSelectAll(selection) {
      const IdList = [];
      selection.forEach(list => {
        IdList.push(list.id);
      });
      this.vehicleIdsList = IdList;
    },
    download() {
      this.isClick = true;
      if (this.vehicleIdsList.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
      } else {
        const listForm = {
          idList: this.vehicleIdsList
        };
        vehicleExport(listForm).then(res => {
          if (res.code===200) {
            this.vehicleIdsList = [];
            this.$refs.tableList.clearSelection();
            goExportList(this);
          }
        }).catch({});
      }
    },
    downloadAll() {
      this.isClickAll = true;
      const queryForm = JSON.parse(localStorage.getItem('vmQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      const vehicleNumberList = queryForm.params.vehicleNumberList ? queryForm.params.vehicleNumberList.split('\n') : [];
      queryForm.params.vehicleNumberList = vehicleNumberList;
      vehicleAllExport(queryForm).then(res => {
        if (res.code===200) {
          goExportList(this);
        }
      }).catch({});
    },
    /**
     * @description 驾驶附件图片上传格式限制
     * @param file
     * @returns {boolean}
     */
    beforeUpload(file) {
      const type = ['image/bmp', 'image/jpeg', 'image/png', 'image/jpg'];
      const isJPG = file.type;
      const isType = type.includes(isJPG);
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isType) {
        this.$message.error(this.$i18n.t('basicData.IncorrectFormatting')); // '文件格式不正确'
      }
      if (!isLt2M) {
        this.$message.error(this.$i18n.t('basicData.UploadFileSizeCannotExceed5MB')); // '上传文件大小不能超过 5MB!'
      }
      return isType && isLt2M;
    },
    /**
     * @description 图片上传
     * @param option
     * @returns {Promise<void>}
     */
    async upLoad(option) {
      const files = option.file;
      // 增加loading
      const load = Loading.service({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$t('collectionCenter.pleaseWait'),
        background: 'rgba(250, 250, 250, 0.6)'
      });
      const res = await uploadOos(files, 'collectionCenter');

      if (res.code === 200) {
        load.close();
        this.drawerForm.driversLicenseAccessory = res.url;
      } else {
        load.close();
        this.$message.error(this.$t('placeOrder.uploadInvoiceErr'), res.msg);
      }
    },
    /**
     * @description 驾照附件图片删除
     */
    delImg() {
      this.drawerForm.driversLicenseAccessory = '';
      // 清空上传图片
      this.$refs.demoUpload?.clearFiles();
    }
  }
};
</script>

<style lang="scss" scoped>

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce2{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce2:active{
    filter: opacity(40%);
    animation: none;
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }

  .overClass ::v-deep .el-input__suffix {
    top: 11px;
    right: 2px;
  }
  .overClass ::v-deep .el-input__suffix-inner {
    position: relative;
  }
  .overClass ::v-deep .el-input .el-input__clear {
    position: absolute;
    top: -23px;
    right: 0;
  }
  .overClass ::v-deep .el-input .el-input__count .el-input__count-inner {
    background: none;
  }
  .BlockNewlineClass ::v-deep textarea.el-textarea__inner {
    min-height: 40px !important;
    max-height: 120px !important;
  }
  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
    width: 100%;
  }
  .yaerClass ::v-deep .el-date-editor{
    width: 100%;
  }
  .vehicleManagementBox, .drawerClass{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .vehicleManagementBox .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .widthClass ::v-deep .el-button{
    white-space: normal;
  }
  .widthClass ::v-deep .el-button:first-child, .widthClass ::v-deep .el-button:nth-child(2){
    margin-bottom: 10px;
  }
  .submitClickBtn{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .overflowClass ::v-deep .el-drawer{
    overflow: auto;
  }

  .drawerClass ::v-deep .el-date-editor .el-range-separator{
    width: auto !important;
  }
  .drawerClass ::v-deep textarea.el-textarea__inner {
    max-height: 100px !important;
  }
  .drawerClass ::v-deep .el-textarea .el-input__count{
    bottom: 3px !important;
    line-height: 12px;
    background: rgba(0,0,0,0);
  }
  .drawerClass ::v-deep .el-range-editor{
    width: auto;
  }
  .drawerClass ::v-deep .el-select{
    width: 100%;
  }
  .drawerClass ::v-deep .el-form-item__label{
    text-align: left;
    word-break: break-all;
  }
  .noteClass ::v-deep .el-textarea__inner{
    max-height: 150px;
  }
  .imgDelClass {
    cursor: pointer;
    margin-left: 30px;
    padding-bottom: 10px;
    transform: scale(1.5);

    &:hover {
      color: red;
    }
  }
  .image-slot{
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
