<template>
  <div class="billBox">
    <!-- 账单查询及推送 -->
    <div style="margin-bottom: 20px;">
      <el-form class="selectClass">
        <el-row>
          <el-col :span="10" style="width: auto;">
            <!-- 账单生成时间： -->
            <el-form-item :label="$t('collectionCenter.billGenerationTime')">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                :end-placeholder="$t('collectionCenter.EndTime')"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 客户名称： -->
            <el-form-item :label="$t('collectionCenter.CustomerN')">
              <el-select
                v-model="queryForm.params.customerName" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable @focus="getAllCustomer"
              >
                <el-option
                  v-for="(item, index) in customerNameList"
                  :key="index"
                  :label="item.username"
                  :value="item.username"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 推送状态： -->
            <el-form-item :label="$t('collectionCenter.pushStatus')">
              <el-select v-model="queryForm.params.pushType" :placeholder="$t('collectionCenter.pleaseChoose')" clearable>
                <el-option :label="$t('collectionCenter.unpushed')" :value="0" /><!-- 未推送 -->
                <el-option :label="$t('collectionCenter.pushed')" :value="1" /><!-- 已推送 -->
                <el-option :label="$t('collectionCenter.pushFailure')" :value="2" /><!-- 推送失败 -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <el-row>
              <el-col class="col2">
                <el-select
                  v-model="changeNumber"
                  class="selectNumberClass autoWidth"
                  :placeholder="$t('collectionCenter.pleaseChoose')"
                  @change="changeNumberClick"
                >
                  <template slot="prefix">
                    {{ (numberList.find(item => item.value === changeNumber) || {}).label }}
                  </template>
                  <el-option
                    v-for="(item, index) in numberList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  />
                  <!--                  <el-option :label="$t('collectionCenter.logisticsNum')" :value="0" />&lt;!&ndash; 物流号 &ndash;&gt;-->
                  <!--                  <el-option :label="$t('collectionCenter.customerOrderNum')" :value="1" />&lt;!&ndash; 客户订单号 &ndash;&gt;-->
                </el-select>
              </el-col>
              <el-col v-show="changeNumber === 0" style="width: auto;">
                <!-- 物流号： -->
                <el-form-item class="delMl">
                  <div class="MaxHeight">
                    <el-input
                      v-model="queryForm.params.deliveryNumber"
                      type="textarea"
                      autosize
                      :placeholder="$t('collectionCenter.BatchQuery')"
                      @keydown.enter.native="deliveryNumberEnter"
                    /><!-- 支持批量查询(回车换行分割) -->
                  </div>
                </el-form-item>
              </el-col>
              <el-col v-show="changeNumber === 1" style="width: auto;">
                <!-- 客户订单号： -->
                <el-form-item class="delMl">
                  <div class="MaxHeight">
                    <el-input
                      v-model="queryForm.params.customerOrderNumber"
                      type="textarea"
                      autosize
                      :placeholder="$t('collectionCenter.BatchQuery')"
                      @keydown.enter.native="customerOrderNumberEnter"
                    /><!-- 支持批量查询(回车换行分割) -->
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:finance:bill:search'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:finance:bill:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-permit:remove="'btn:collection-center:finance:bill:export'"
            icon="el-icon-upload2"
            size="mini"
            :class="idList.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="download"
          >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 -->
          <el-button
            v-permit:remove="'btn:collection-center:finance:bill:BulkExport'"
            size="mini"
            :class="isClickAll ? 'onlyClickOnce10' : ''"
            @click="downloadBatch"
          >{{ $t('collectionCenter.BulkExport') }}</el-button><!-- 批量导出 -->
          <el-button
            v-permit:remove="'btn:collection-center:finance:bill:BatchPush'"
            size="mini"
            :class="idList.length <=0 && isClick1 ? 'onlyClickOnce10' : ''"
            @click="batchPush"
          >{{ $t('collectionCenter.BatchPush') }}</el-button><!-- 批量补推 -->
          <el-popover
            class="popoverClass"
            placement="bottom-start"
            title=""
            trigger="hover"
            content="x"
          >
            <el-button
              slot="reference"
              v-permit:remove="'btn:collection-center:finance:bill:Import'"
              size="mini"
            >{{ $t('collectionCenter.Import') }}</el-button><!-- 导入 -->
            <div>
              <div>
                <el-button
                  size="mini"
                  style="margin:10px;"
                  @click="templateDownloadClick"
                >{{ $t('collectionCenter.TemplateDownload') }}</el-button><!-- 下载模版 -->
              </div>
              <div>
                <el-upload
                  class="uploadFileClass"
                  action
                  :limit="1"
                  :show-file-list="false"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  :http-request="uploadDataClick"
                >
                  <el-button
                    size="mini"
                  >{{ $t('collectionCenter.uploadData') }}</el-button>
                </el-upload><!-- 上传数据 -->
              </div>
            </div>
          </el-popover>
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <div>
      <el-table
        ref="tableRef"
        border
        :data="tableDate"
        :max-height="600"
        @select="batchSelect"
        @select-all="batchSelectAll"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column
          :label="$t('collectionCenter.ind')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="customerName"
          align="center"
          :label="$t('collectionCenter.CustomerName')"
        /><!-- 客户名称 :label="$t('collectionCenter.xxx')" -->
        <el-table-column
          prop="customerOrderNumber"
          align="center"
          :label="$t('collectionCenter.customerOrderNum')"
        /><!-- 客户订单号 -->
        <el-table-column
          prop="deliveryNumber"
          align="center"
          :label="$t('collectionCenter.logisticsNum')"
        /><!-- 物流号 -->
        <el-table-column
          prop="createTime"
          align="center"
          :label="$t('collectionCenter.billGenerationT')"
        /><!-- 账单生成时间 -->
        <el-table-column
          prop="locusPushTime"
          align="center"
          :label="$t('collectionCenter.trackPushTime')"
        /><!-- 轨迹推送时间 -->
        <el-table-column
          prop="collectFees"
          align="center"
          :label="$t('collectionCenter.collectCost')"
        /><!-- 揽收费用 -->
        <el-table-column
          prop="distributionFees"
          align="center"
          :label="$t('collectionCenter.distributionCost')"
        /><!-- 配送费用 -->
        <el-table-column
          prop="operatingFees"
          align="center"
          :label="$t('collectionCenter.operatingCost')"
        /><!-- 操作费用 -->
        <el-table-column
          prop="exceptionalFees"
          align="center"
          :label="$t('collectionCenter.warehouseAnomalyCharge')"
        /><!-- 仓库异常费用 -->
        <el-table-column
          prop="orderWeight"
          align="center"
          :label="$t('collectionCenter.orderWeight')"
        /><!-- 订单重量 -->
        <el-table-column
          prop="costWeight"
          align="center"
          :label="$t('collectionCenter.chargedWeight')"
        /><!-- 计费重量 -->
        <!-- <el-table-column
          prop="cost"
          align="center"
          :label="$t('collectionCenter.freight')"
        />运费 -->
        <el-table-column
          prop="pushTypeName"
          align="center"
          :label="$t('collectionCenter.stateZT')"
        /><!-- 状态 -->
        <el-table-column
          prop="pushCount"
          align="center"
          :label="$t('collectionCenter.NumberOfPushes')"
        /><!-- 推送次数 -->
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getTablePage"
        @size-change="getPageSize"
      />
    </div>
    <el-dialog
      width="90%" :show-close="false" :close-on-click-modal="false"
      :visible.sync="openDialog"
    >
      <Import :bill-import-list="billImportList" @closeDialog="closeImportDialog" @getTable="importToGetTable" />
    </el-dialog>
  </div>
</template>

<script>
import Import from '@/views/collectionCenter/Finance/import';
import {
  queryAllCustomer,
  billPage,
  billExport,
  billBatchExport,
  billImport,
  billOrderSuppPush
} from '@/api/lanshou';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';
import { saveAs } from 'file-saver';

export default {
  name: 'Bill',
  components: {
    Import
  },
  data() {
    return {
      isClickAll: false,
      isClick1: false,
      isClick: false,
      timeValue: [],
      numberList: [
        { value: 0, label: this.$t('collectionCenter.logisticsNum') }, // '物流号'
        { value: 1, label: this.$t('collectionCenter.customerOrderNum') } // '客户订单号'
      ],
      changeNumber: 0,
      customerNameList: [], // 客户列表
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '',
          endTime: '',
          customerName: '',
          pushType: '',
          deliveryNumber: '',
          customerOrderNumber: ''
        }
      },
      total: 0,
      tableDate: [],
      openDialog: false,
      idList: [],
      billZH: '/static/bill-zh.xlsx',
      billPU: '/static/bill-pu.xlsx',
      fileList: [], // 清空缓存
      billImportList: [] // 传给子组件的解析后的xlsx文件
    };
  },
  watch: {
    'queryForm.params.deliveryNumber'(val) {
      if (!val) return;
      const wuliu = val.split('\n');
      let wuliuNumber = [];
      if (wuliu.length >= 1000) {
        wuliuNumber = wuliu.slice(0, 1000).join('\n');
        this.queryForm.params.deliveryNumber = wuliuNumber;
      }
      if (wuliu.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'queryForm.params.customerOrderNumber'(val) {
      if (!val) return;
      const wuliu2 = val.split('\n');
      let wuliuNumber2 = [];
      if (wuliu2.length >= 1000) {
        wuliuNumber2 = wuliu2.slice(0, 1000).join('\n');
        this.queryForm.params.customerOrderNumber = wuliuNumber2;
      }
      if (wuliu2.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    }
  },
  created() {
    this.default();
    this.getAllCustomer();
    this.searchClick();
    this.isClick = false;
    this.isClick1 = false;
    this.isClickAll = false;
  },
  methods: {
    getAllCustomer() {
      // 查询所有客户信息
      queryAllCustomer().then(res => {
        if (res.code===200) {
          this.customerNameList = [];
          res.data.forEach((list) => {
            this.customerNameList.push({
              username: list.username
            });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.startTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.startTime = val[0];
      this.queryForm.params.endTime = val[1];

      if (this.queryForm.params.endTime) {
        const endTime = dayjs(this.queryForm.params.startTime).subtract(-1, 'month').format('YYYY-MM-DD') + ' 23:59:59';
        if (this.queryForm.params.endTime > endTime) {
          this.$message({
            // '只允许查1个月内的数据'
            message: this.$t('collectionCenter.OnlyAllowedOneMonthsOfData'),
            type: 'warning'
          });
          const d = new Date();
          const today = dayjs(d).format('YYYY-MM-DD');
          this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
          this.queryForm.params.startTime = today + ' 00:00:00';
          this.queryForm.params.endTime = today + ' 23:59:59';
        }
      }
    },
    closeImportDialog() {
      this.billImportList = [];
      this.openDialog = false;
    },
    importToGetTable() {
      this.closeImportDialog();
      this.default();
      this.searchClick();
      // 导入数据提交成功后，调用批量补推
      this.orderPush(this.idList);
    },
    changeNumberClick() {
      this.queryForm.params.deliveryNumber = '';
      this.queryForm.params.customerOrderNumber = '';
    },
    deliveryNumberEnter() { // 物流号——回车校验
      const e = window.event || arguments[0];
      const numberLen = this.queryForm.params.deliveryNumber.split('\n');
      if (numberLen.length >= 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        e.returnValue = false;
      }
      return true;
    },
    customerOrderNumberEnter() { // 客户订单号——回车校验
      const e2 = window.event || arguments[0];
      const numberLen2 = this.queryForm.params.customerOrderNumber.split('\n');
      if (numberLen2.length >= 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        e2.returnValue = false;
      }
      return true;
    },
    default() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm.params = {
        startTime: today + ' 00:00:00',
        endTime: today + ' 23:59:59',
        customerName: '',
        pushType: '',
        deliveryNumber: '',
        customerOrderNumber: ''
      };
    },
    resetClick() {
      this.default();
    },
    searchClick() {
      if (!this.queryForm.params.startTime || !this.queryForm.params.endTime) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
      } else {
        this.queryForm.pageNumber = 1;
        localStorage.setItem('billQueryForm', JSON.stringify(this.queryForm));
        this.getTablePage();
      }
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('billQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      billPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    getTablePage() {
      this.idList = [];
      const queryForm = JSON.parse(localStorage.getItem('billQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      billPage(queryForm).then(res => {
        if (res.code===200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    downloadBatch() { // 批量导出
      this.isClickAll = true;
      const queryForm = JSON.parse(localStorage.getItem('billQueryForm')) || this.queryForm;
      billBatchExport(queryForm.params).then(res => {
        if (res.code===200) {
          goExportList(this);
        }
      });
    },
    batchPush() { // 批量补推
      this.isClick1 = true;
      if (this.idList.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.checkMsg')); // '请勾选数据'
      } else {
        this.orderPush(this.idList);
      }
    },
    orderPush(idList) {
      const idListData = {
        idList: idList.length > 0 ? idList : []
      };
      billOrderSuppPush(idListData).then(res => {
        if (res.code===200 || res.data.code===200) {
          this.isClick1 = false;
          this.getTablePage();
        }
      }).catch(() => {
        this.isClick1 = false;
      });
    },
    templateDownloadClick() { // 下载模版
      // const link = document.createElement('a');
      // link.style.display = 'none';
      // 特殊情况：无法拿到存储语言时，判断是否是中文环境
      const navigatorLanguage = navigator.language.toLowerCase().indexOf('zh') > -1 ? 'zh' : 'pu';
      const language = sessionStorage.getItem('language') || navigatorLanguage;
      let href = '';
      let saveName = '';
      if (language === 'zh') {
        // link.href = this.billZH;
        // link.setAttribute('download', '配送费账单导入模板.xlsx');
        href = this.billZH;
        saveName = '配送费账单导入模板.xlsx';
      } else {
        // link.href = this.billPU;
        // link.setAttribute('download', 'Modelo importado de conta de valor de envio.xlsx');
        href = this.billPU;
        saveName = 'Modelo importado de conta de valor de envio.xlsx';
      }
      // document.body.appendChild(link);
      // link.click();
      // link.parentNode.removeChild(link);
      saveAs(href, saveName);
    },
    beforeUpload(file) {
      this.fileList = [];
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      // const whiteList = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];
      const whiteList = ['xls', 'xlsx'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        // 上传文件只能是xlsx格式
        this.$message.error(this.$t('collectionCenter.uploadOnlyXlsx'));
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        // 上传文件大小不能超过 2MB
        this.$message.error(this.$t('collectionCenter.uploadNot2MB'));
        return false;
      }
    },
    uploadDataClick(option) { // 导入xlsx模版 // 上传数据
      const files = option.file;
      const upLoadFile = new FormData();
      upLoadFile.append('file', files);
      if (files) {
        billImport(upLoadFile).then(response => {
          if (response.code===200) {
            this.billImportList = response.data;
            this.openDialog = true; // 上传完后，打开导入数据弹框
          }
        });
      }
    },
    download() {
      this.isClick = true;
      if (this.idList.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport')); // '请勾选要导出的数据'
      } else {
        const idListForm = {
          idList: this.idList
        };
        billExport(idListForm).then(res => {
          if (res.code===200) {
            this.idList = [];
            this.$refs.tableRef.clearSelection();
            goExportList(this);
          }
        });
      }
    },
    batchSelect(selection) {
      const IdList = [];
      selection.forEach(list => {
        IdList.push(list.id);
      });
      this.idList = IdList;
    },
    batchSelectAll(selection) {
      const IdListAll = [];
      selection.forEach(list => {
        IdListAll.push(list.id);
      });
      this.idList = IdListAll;
    }
  }
};
</script>

<style lang="scss" scoped>

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce2{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce2:active{
    filter: opacity(40%);
    animation: none;
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }

  .autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
    position: relative;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    visibility: hidden;
    white-space: nowrap;
  }
  .autoWidth ::v-deep input {
    position: absolute;
  }
  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
    width: 100%;
  }
  .col2 {
    width: auto !important;
  }
  .selectNumberClass ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
    padding: 0 25px;
    padding-left: 0;
  }
  .popoverClass{
    margin-left: 10px;
  }
  .billBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .MaxHeight ::v-deep textarea.el-textarea__inner {
    max-height: 150px !important;
  }
  .billBox ::v-deep .el-dialog{
    margin: 10vh auto !important;
  }
  .uploadFileClass{
    text-align: center;
    margin-right: 10px;
  }
</style>
