<template>
  <div class="app-container">
    <el-card class="box-card" style="margin-bottom: 10px">
      <el-form
        ref="queryForm"
        :model="queryForm"
        class="queryFormClass"
        label-position="top"
        size="small"
      >
        <el-row :gutter="10">
          <el-col :span="7">
            <!--设备名称-->
            <el-form-item :label="$t('GlobalSetObj.TheNameOfTheDevice')" prop="deviceName">
              <div slot="label">
                <el-select
                  v-model="queryForm.params.selectionType"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  size="mini"
                >
                  <!--启用-->
                  <el-option key="1" :label="$t('GlobalSetObj.TheNameOfTheDevice')" :value="1" />
                  <!--停用-->
                  <el-option key="2" :label="$t('GlobalSetObj.DeviceNumber')" :value="2" />
                </el-select>
              </div>
              <el-input
                v-model="queryForm.params.selectionCont"
                :placeholder="$t('orderDetails.pleaseInput')"
                clearable
              />
            </el-form-item>
          </el-col>
          <!--          <el-col :span="7">-->
          <!--            &lt;!&ndash;设备编号&ndash;&gt;-->
          <!--            <el-form-item :label="$t('GlobalSetObj.DeviceNumber')" prop="deviceNumber">-->
          <!--              <el-input v-model="queryForm.params.deviceNumber" :placeholder="$t('orderDetails.pleaseInput')" clearable />-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <el-col :span="7">
            <!--IP-->
            <el-form-item label="IP" prop="deviceIp">
              <el-input v-model="queryForm.params.deviceIp" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--所在中转仓-->
            <el-form-item :label="$t('GlobalSetObj.TheTransitWarehouseWhereLocated')" prop="transitSiteId">
              <el-select
                v-model="queryForm.params.transitSiteId"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 100%;"
              >
                <el-option key="01" :label="$t('collectionCenter.tudo')" :value="null" />
                <el-option
                  v-for="(item, ind) in transitOption" :key="ind" :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="7">
            <!--设备状态-->
            <el-form-item :label="$t('GlobalSetObj.DeviceStatus')" prop="deviceStatus">
              <el-select
                v-model="queryForm.params.deviceStatus"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 100%;"
              >
                <!--全部-->
                <el-option key="0" :label="$t('collectionCenter.tudo')" :value="0" />
                <!--启用-->
                <el-option key="1" :label="$t('GlobalSetObj.enable')" :value="1" />
                <!--停用-->
                <el-option key="2" :label="$t('GlobalSetObj.stop')" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row justify="start" type="flex">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              icon="el-icon-search"
              size="mini"
              type="primary"
              @click="getDataSearch(1)"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="getDataSearch(2)"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="box-card">
      <div style="margin-bottom: 10px;">
        <!--新增设备-->
        <el-button
          v-permit:remove="'btn:automatic-sorting:deviceManagement:addEdit'"
          size="mini"
          type="primary"
          @click="addEquipment(1)"
        >{{ $t('GlobalSetObj.AddNewDevice') }}
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
      >
        <!--序号-->
        <el-table-column
          :label="$t('basicData.serialNumber')" align="center" type="index"
          width="60"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--业务类型-->
        <el-table-column
          v-for="(item, ind) in columns"
          :key="ind"
          :align="item.align"
          :label="item.label"
          :min-width="item.width"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="item.prop === 'deviceName'">
              <el-popover
                placement="top-end"
                popper-class="copy"
                trigger="hover"
              >
                <!--复制-->
                <el-button type="text" @click="copy(scope.row.deviceName)">{{ $t('orderDetails.copy') }}</el-button>
                <span
                  slot="reference"
                  style="color: #409eff;cursor: pointer;"
                  @click="addEquipment(2, scope.row)"
                >{{ scope.row.deviceName }}</span>
              </el-popover>
            </div>
            <div v-else-if="item.prop === 'deviceStatus'">
              <el-tag v-if="scope.row.deviceStatus === 1" type="success">{{ $t('GlobalSetObj.enable') }}</el-tag>
              <el-tag v-if="scope.row.deviceStatus === 2" type="danger">{{ $t('GlobalSetObj.stop') }}</el-tag>
            </div>
            <div v-else>
              <span>{{ scope.row[item.prop] }}</span>
            </div>
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column
          :label="$t('basicData.operate')" align="center" fixed="right"
          width="140"
        >
          <template slot-scope="scope">
            <div>
              <!--    编辑        -->
              <el-tooltip
                :content="$t('GlobalSetObj.edit')" class="item" effect="dark"
                placement="top"
              >
                <el-button
                  v-permit:remove="'btn:automatic-sorting:deviceManagement:addEdit'"
                  size="mini"
                  style="color: #666"
                  type="text"
                  @click="addEquipment(2, scope.row)"
                ><i class="el-icon-edit-outline" style="font-size: 25px;" />
                </el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="getCurrentData"
          @size-change="getSizeData"
        />
      </div>
    </el-card>
    <!--新增设备-->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="addEditTitle"
      :visible.sync="showAddEdit"
      width="500px"
      @close="closeAddEdit()"
    >
      <div>
        <el-form
          ref="paramForm" :model="paramForm" label-position="top"
          size="small"
        >
          <!--设备名称-->
          <el-form-item
            :label="$t('GlobalSetObj.TheNameOfTheDevice')"
            :rules="{ required: true, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            prop="deviceName"
          >
            <el-input v-model="paramForm.deviceName" maxlength="100" show-word-limit />
          </el-form-item>
          <!--设备编号-->
          <el-form-item
            :label="$t('GlobalSetObj.DeviceNumber')"
            :rules="{ required: true, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            prop="deviceNumber"
          >
            <el-input
              v-model="paramForm.deviceNumber" :disabled="isDisable" maxlength="100"
              show-word-limit
            />
          </el-form-item>
          <!--IP-->
          <el-form-item
            :rules="{ required: true, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            label="IP"
            prop="deviceIp"
          >
            <el-input v-model="paramForm.deviceIp" maxlength="100" show-word-limit />
          </el-form-item>
          <!--所在中转仓-->
          <el-form-item
            :label="$t('GlobalSetObj.TheTransitWarehouseWhereLocated')"
            :rules="{ required: true, message: $t('orderCenterCont.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            prop="transitSiteId"
          >
            <el-select
              v-model="paramForm.transitSiteId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 100%;"
              @change="changeTransitSite(paramForm.transitSiteId)"
            >
              <el-option
                v-for="(item, ind) in transitOption" :key="ind" :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!-- 规则名称 -->
          <el-form-item
            v-if="addEditType === 2"
            :label="$t('GlobalSetObj.RuleName')"
            prop="ruleId"
          >
            <el-select
              v-model="paramForm.ruleId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 100%;"
            >
              <el-option
                v-for="(item, ind) in ruleNameList" :key="ind" :label="item.ruleName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!--状态-->
          <el-form-item :label="$t('GlobalSetObj.DeviceStatus')" prop="deviceStatus">
            <el-radio-group v-model="paramForm.deviceStatus" size="small">
              <el-radio-button :label="1">{{ $t('GlobalSetObj.enable') }}</el-radio-button>
              <el-radio-button :label="2">{{ $t('GlobalSetObj.stop') }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" v-permit:remove="'btn:automatic-sorting:deviceManagement:addEdit'" class="dialog-footer">
        <el-button @click="closeAddEdit()">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="saveAddEdit()">{{ $t('GlobalSetObj.Salve') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { copyData } from '@/utils/copyData';
import {
  apiDeviceManagementAdd,
  apiDeviceManagementEdit,
  apiDeviceManagementPage,
  apiSelectTransitCenter,
  querySortedRule
} from '@/api/automaticSorting';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'DeviceManagement',
  data() {
    return {
      isDisable: false,
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          selectionType: 1,
          selectionCont: '',
          deviceName: '',
          deviceNumber: '',
          deviceIp: '',
          transitSiteId: null,
          deviceStatus: 0
        }
      },
      total: 0,
      tableData: [],
      transitOption: [],
      columns: [
        // 设备名称
        { label: this.$t('GlobalSetObj.TheNameOfTheDevice'), prop: 'deviceName', width: 160, align: 'center' },
        // 设备编号
        { label: this.$t('GlobalSetObj.DeviceNumber'), prop: 'deviceNumber', width: 160, align: 'center' },
        // IP
        { label: 'IP', prop: 'deviceIp', width: 160, align: 'center' },
        // 所在中转仓
        {
          label: this.$t('GlobalSetObj.TheTransitWarehouseWhereLocated'),
          prop: 'transitCenter',
          width: 160,
          align: 'center'
        },
        // 规则名称
        {
          label: this.$t('GlobalSetObj.RuleName'),
          prop: 'ruleName',
          width: 160,
          align: 'center'
        },
        // 设备状态
        { label: this.$t('GlobalSetObj.DeviceStatus'), prop: 'deviceStatus', width: 160, align: 'center' }
      ],
      addEditTitle: '',
      addEditType: 1,
      showAddEdit: false,
      paramForm: {
        deviceName: '',
        deviceNumber: '',
        deviceIp: '',
        transitCenter: '',
        transitSiteId: '',
        deviceStatus: 1,
        ruleId: ''
      },
      ruleNameList: []
    };
  },
  created() {
    this.getTransitCenter();
    this.getDeviceTableList();
  },
  methods: {
    // 关闭弹窗
    closeAddEdit() {
      this.paramForm = {
        deviceName: '',
        deviceNumber: '',
        deviceIp: '',
        transitCenter: '',
        transitSiteId: '',
        deviceStatus: 1,
        id: '',
        ruleId: ''
      };
      this.$refs.paramForm.resetFields();
      this.showAddEdit = false;
    },
    // 新增设备
    saveAddEdit() {
      this.$refs.paramForm.validate((valid) => {
        if (valid) {
          if (this.addEditType === 1) {
            apiDeviceManagementAdd(this.paramForm).then(res => {
              if (res.code===200) {
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                this.getDeviceTableList();
                this.closeAddEdit();
              }
            });
          } else {
            apiDeviceManagementEdit(this.paramForm).then(res => {
              if (res.code===200) {
                this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
                this.getDeviceTableList();
                this.closeAddEdit();
              }
            });
          }
        }
      });
    },
    // 中转仓选项变化
    changeTransitSite(id) {
      this.transitOption.map(item => {
        if (item.id === id) {
          this.paramForm.transitCenter = item.name;
        }
      });
    },
    // 获取中转仓
    getTransitCenter() {
      apiSelectTransitCenter().then(res => {
        if (res.code===200) {
          this.transitOption = res.data;
        }
      });
    },
    // 搜索、重置
    getDataSearch(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.getDeviceTableList();
      } else {
        this.queryForm = {
          pageSize: 10,
          pageNumber: 1,
          params: {
            selectionType: 1,
            selectionCont: '',
            deviceName: '',
            deviceNumber: '',
            deviceIp: '',
            transitSiteId: null,
            deviceStatus: 0
          }
        };
      }
    },
    // 获取设备列表
    getDeviceTableList() {
      const param = {
        pageSize: this.queryForm.pageSize,
        pageNumber: this.queryForm.pageNumber,
        params: {
          deviceName: '',
          deviceNumber: '',
          deviceIp: this.queryForm.params.deviceIp,
          transitSiteId: this.queryForm.params.transitSiteId,
          deviceStatus: this.queryForm.params.deviceStatus
        }
      };
      if (this.queryForm.params.selectionType === 1) {
        param.params.deviceName = this.queryForm.params.selectionCont;
        param.params.deviceNumber = '';
      } else {
        param.params.deviceNumber = this.queryForm.params.selectionCont;
        param.params.deviceName = '';
      }
      apiDeviceManagementPage(param).then(res => {
        if (res.code===200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 添加编辑设备
    addEquipment(type, row) {
      this.paramForm = {
        deviceName: '',
        deviceNumber: '',
        deviceIp: '',
        transitCenter: '',
        transitSiteId: '',
        deviceStatus: 1,
        id: '',
        ruleId: ''
      };
      this.addEditType = type;
      if (type === 1) {
        this.addEditTitle = this.$t('GlobalSetObj.add');
        this.isDisable = false;
      } else {
        const data1 = {
          id: row.id
        };
        querySortedRule(data1).then(res => {
          if (res.code===200) {
            console.log(res, 'JLKJLKJL');
            this.ruleNameList = res.data;
          }
          this.addEditTitle = this.$t('GlobalSetObj.edit');
          this.isDisable = true;
          const detail = cloneDeep(row);
          this.paramForm = {
            deviceName: detail.deviceName,
            deviceNumber: detail.deviceNumber,
            deviceIp: detail.deviceIp,
            transitCenter: detail.transitCenter,
            transitSiteId: detail.transitSiteId,
            deviceStatus: detail.deviceStatus,
            id: detail.id,
            ruleId: detail.ruleId
          };
        });
      }
      this.showAddEdit = true;
    },
    // 翻页
    getCurrentData(val) {
      this.queryForm.pageNumber = val;
      this.getDeviceTableList();
    },
    // 每页显示数量
    getSizeData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getDeviceTableList();
    }
  }
};
</script>

<style scoped>

</style>
